import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { InputNumberProps } from 'antd/lib/input-number';
import * as Constants from '../../../constants';
import InputNumberSuffix from '../../integrated/input-number-suffix';
import { allowPosNumberOnlyKeys } from '../../../helpers/utilities';
import '../../../../static/css/components/patient-exam-iop.scss';
import ExamMetricValidationModal from '../../integrated/metrics/exam-metric-validation-modal';

type ComponentProps = {
    sideIOP: string | number | null;
    sideIopAim: string | number | null;
    onChange: (value: number | string | null) => void;
    gptResultState: string;
}

const GptPatientExamIOP = ({ sideIOP, sideIopAim, onChange, gptResultState }: ComponentProps) => {

    const isGlaucoma = useCare1AppSelector(store => store.examData.is_glaucoma);

    const validateIOPValue = () => {
        const value = sideIOP;
        if (value && typeof (value) === 'number' && (value < 0 || value > 70)) {
            ExamMetricValidationModal(Constants.IOP_VALIDATION_MSG);
        }
    };

    const getIopClassName = (iop: number) => {
        const iopClassName = ['patient-exam-iop'];

        const parsedSideIopAim = sideIopAim === 'XXX' ? 'XXX' : Number(sideIopAim);

        if (isGlaucoma && parsedSideIopAim !== 'XXX') {
            const iopAimDiff = iop - parsedSideIopAim;

            if (iopAimDiff >= 3) {
                iopClassName.push('iop-red-background');
            } else if (iopAimDiff < 3 && iopAimDiff > 0) {
                iopClassName.push('iop-yellow-background');
            } else if (iopAimDiff <= 0) {
                iopClassName.push('iop-green-background');
            }
        } else {
            if (iop >= 24) {
                iopClassName.push('iop-red-background');
            } else if (iop < 24 && iop > 21) {
                iopClassName.push('iop-yellow-background');
            } else if (iop <= 21) {
                iopClassName.push('iop-green-background');
            }
        }

        return iopClassName.join(' ');
    }

    // All IOP entries should be in the format of # or ##
    const formatValue: InputNumberProps['formatter'] = (value, info) => {
        const regex = /^([0-9]?)([0-9]?).*$/;
        const matches = String(value).match(regex);

        if (matches && matches[1] && matches[2]) {
            return String(Number(`${matches[1]}${matches[2]}`));
        } else if (matches && matches[1]) {
            return String(Number(matches[1]));
        } else {
            return '';
        }
    }

    return (
        <div className={`${gptResultState}`}>
            <div className={getIopClassName(Number(sideIOP))}>
                <InputNumberSuffix
                    formatter={formatValue}
                    onKeyPress={event => allowPosNumberOnlyKeys(event)}
                    size={'small'}
                    suffix={Constants.MM_OF_MERCURY_TEXT}
                    onBlur={validateIOPValue}
                    onChange={onChange}
                    value={sideIOP}
                />
            </div>
        </div>
    );
}

export default GptPatientExamIOP;