import { Fragment} from 'react';
import { useCare1AppSelector } from '../apps/care1-hooks';
import { Row, Card, Col } from 'antd';
import * as Constants from '../constants';

// Components
import AdminGPReferralDates from '../components/admin/admin-gp-referral-dates';
import AdminPreReview from '../components/admin/admin-pre-review';
import AdminSelectors from '../components/admin/admin-selectors';
import DeepMDBand from '../components/reviewer/deep-md/patient-exam-deep-md';
import OMDSubmitButtons from '../components/reviewer/omd-submit-buttons';
import PatientExamBCVA from '../components/retina/patient-exam-bcva';
import PatientExamBCVAGraph from '../components/integrated/patient-exam-bcva-graph';
import AdminOntarioBillingCode from '../components/admin/admin-ontario-billing-code';
import PatientExamChiefComplaint from '../components/retina/patient-exam-chief-complaint';
import PatientExamConversation from '../components/retina/patient-exam-conversation';
import PatientExamExtraImages from '../components/retina/patient-exam-extra-images';
import PatientExamFundus from '../components/retina/patient-exam-fundus';
import PatientExamFundusImageHistory from '../components/image-histories/patient-exam-fundus-image-history';
import PatientExamGPReferred from '../components/retina/patient-exam-gp-referred';
import PatientExamHeaderAdmin from '../components/admin/patient-exam-header-admin';
import PatientExamHistoryAndExam from '../components/retina/patient-exam-history-and-exam';
import PatientExamOCTMacula from '../components/retina/patient-exam-oct-macula';
import PatientExamOCTMaculaImageHistory from '../components/image-histories/patient-exam-oct-macula-image-history';
import PatientExamStandardPremiumSwitch from '../components/integrated/patient-exam-standard-premium-switch';
import PatientExamGlaucomaScore from '../components/integrated/patient-exam-glaucoma-score';

// Helper
import '../../static/css/pages/admin-patient-exam-page.scss';

import { localizedText } from '../localizedText';
import AdminPeiOmdrReRow from '../components/admin/admin-pei-omdr-re-row';

const ADMINStandardReferralLetterExamPage = () => {
    const { BCVA_GRAPH_TEXT, PHOTO_DROP_TEXT_SHORT, OMDR_ONTARIO_BILLING_CODE,
        OMDC_ONTARIO_BILLING_CODE } = localizedText;

    const exam_od_has_pei_toggle = useCare1AppSelector(store => store.examData.exam_od_has_pei_toggle);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const gptSavedValues = useCare1AppSelector(store => store.examData.gpt_saved_values);
    const gptNearMissFields = useCare1AppSelector(store => store.examData.gpt_near_miss_fields);

    const getGptSavedValue = (k: string) => {
        if (!userIsADMIN) {
            return false;
        }
        
        if (gptSavedValues && gptSavedValues !== '') {
            try {
                const gptSavedValuesJson = JSON.parse(gptSavedValues);
                return gptSavedValuesJson[k];
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }

    const getGptNearMissFields = (k: string) => {
        if (!userIsADMIN) {
            return false;
        }

        if (gptNearMissFields && gptNearMissFields !== '') {
            try {
                const gptNearMissFieldsJson = JSON.parse(gptNearMissFields);
                return gptNearMissFieldsJson[k];
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }

    return (
        <>
            <Row>
                <PatientExamHeaderAdmin />
            </Row>
            { exam_od_has_pei_toggle &&
                <Row>
                    <PatientExamStandardPremiumSwitch />
                </Row>
            }
            <Row gutter={8}>
                {/* START | Section 1 - Left images panel */}
                <Col span={8} className="dropzones-container">
                    <PatientExamFundus
                        disabled={false}
                        tabbed={false}
                        className="patient-exam-fundus"
                        side={Constants.RIGHT_SIDE_TEXT}
                    />
                    <Row gutter={8}>
                        <Col span={24}>
                            <PatientExamOCTMacula
                                dropText={PHOTO_DROP_TEXT_SHORT}
                                side={Constants.RIGHT_SIDE_TEXT}
                                tabbed={true}
                                disabled={false} />
                        </Col>
                    </Row>
                </Col>
                {/* END | Section 1 - Left images panel */}

                {/* START | Section 2 - Middle diagnostics panel */}
                <Col span={8}>
                    <Row gutter={8}>
                        <PatientExamGPReferred disabled={false} className="patient-exam-gp-referred" />
                    </Row>
                    <Row gutter={8}>
                        <PatientExamChiefComplaint disabled={false} />
                    </Row>
                    <Row>
                        <Card className={'patient-exam-re-bcva'} bordered={false}>
                            <Row>
                                <label className="heading">{'BCVA:'}</label>
                            </Row>
                            <Col span={12} className={'bcva-side'}>
                                <Row>
                                    <div className={'bcva-label'}>
                                        <label className="heading">{'Right Eye:'}</label>
                                    </div>
                                    <div className={'bcva-value'}>
                                        <PatientExamBCVA
                                            side='od'
                                            disabled={false}
                                            gptSaved={getGptSavedValue('od_bcva')} 
                                            gptNearMiss={getGptNearMissFields('od_bcva')}
                                        />
                                    </div>
                                </Row>
                            </Col>
                            <Col span={12} className={'bcva-side'}>
                                <Row>
                                    <div className={'bcva-label os'}>
                                        <label className="heading left-eye">{'Left Eye:'}</label>
                                    </div>
                                    <div className={'bcva-value os'}>
                                        <PatientExamBCVA
                                            side='os'
                                            disabled={false}
                                            gptSaved={getGptSavedValue('os_bcva')} 
                                            gptNearMiss={getGptNearMissFields('os_bcva')}
                                        />
                                    </div>
                                </Row>
                            </Col>
                        </Card>
                    </Row>
                    <Row gutter={8}>
                        <Col span={24}>
                            <Fragment>
                                <PatientExamHistoryAndExam disabled={false} />
                                <AdminOntarioBillingCode doctorType='omdr'
                                    doctorTypeLabel={OMDR_ONTARIO_BILLING_CODE} />
                                <AdminOntarioBillingCode doctorType='omdc'
                                    doctorTypeLabel={OMDC_ONTARIO_BILLING_CODE} />
                            </Fragment>
                        </Col>
                    </Row>
                </Col>

                {/* END | Section 2 - Middle diagnostics panel */}

                {/* START | Section 3 - Right images panel */}
                <Col span={8} className="dropzones-container">
                    <PatientExamFundus
                        disabled={false}
                        tabbed={false}
                        className="patient-exam-fundus"
                        side={Constants.LEFT_SIDE_TEXT}
                    />
                    <Row gutter={8}>
                        <Col span={24}>
                            <PatientExamOCTMacula
                                dropText={PHOTO_DROP_TEXT_SHORT}
                                side={Constants.LEFT_SIDE_TEXT}
                                disabled={false}
                                tabbed={false}
                            />
                        </Col>
                    </Row>
                </Col>
                {/* END | Section 3 - Left images panel */}
            </Row>

            {/* START | OMDR RE Reviewer band */}
            <AdminPeiOmdrReRow />
            {/* END | OMDR RE Reviewer band */}

            {/* START | ADMIN band */}
            <Row gutter={8}>
                <Col span={12}>
                    <AdminSelectors />
                </Col>
                <Col span={5}>
                    <AdminGPReferralDates />
                </Col>
                <Col span={4}>
                    <AdminPreReview />
                </Col>
                <Col span={3}>
                    <OMDSubmitButtons disabled={false} />
                </Col>
            </Row>
            {/* END | ADMIN band */}

            {/* START | OMDR IC DeepMD Band */}
            <Row gutter={8}>
                <DeepMDBand />
            </Row>
            {/* END | OMDR IC DeepMD  Band */}

            {/* START |PatientExamGlaucomaScore Band */}
            <Row gutter={8}>
                <PatientExamGlaucomaScore />
            </Row>
            {/* END | PatientExamGlaucomaScore  Band */}

            {/* END | Section 5 IC - OMDR IC Reviewer & DeepMD bands */}

            {/* START | Section 7 - Extra images band */}
            <PatientExamExtraImages className="patient-exam-extra-images" disabled={false} />
            {/* END | Section 7 - Extra Images band */}

            {/* START | Section 8a RE exam - Graph Bands */}
            <Row gutter={8}>
                <Col span={12}>
                    <Card className="patient-exam-bcva-graph" bordered={false}>
                        <div className="heading">{`${BCVA_GRAPH_TEXT} OD`}</div>
                        <Row>
                            <PatientExamBCVAGraph side={'od'} />
                        </Row>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card className="patient-exam-bcva-graph" bordered={false}>
                        <div className="heading">{`${BCVA_GRAPH_TEXT} OS`}</div>
                        <Row>
                            <PatientExamBCVAGraph side={'os'} />
                        </Row>
                    </Card>
                </Col>
            </Row>
            {/* END | Section 8a RE exam - Graph Bands */}

            {/* START | Section 9 - Conversation band */}
            <Row gutter={8}>
                <Col span={24}>
                    <PatientExamConversation />
                </Col>
            </Row>
            {/* END | Section 9 - Conversation band */}

            <Fragment>
                {/* START | Section 12 - Macula History band*/}
                <Row gutter={8}>
                    <PatientExamOCTMaculaImageHistory orderBy={'asc'} showHint={true} showBaselineBorder={true} showEmptyRow={true} />
                </Row>
                {/* END | Section 12 - Macula History band */}
                {/* START |  Section 13 - Fundus History band */}
                <Row gutter={8}>
                    <PatientExamFundusImageHistory />
                </Row>
                {/* END | Section 13 - Fundus History band */}
            </Fragment>
        </>
    );
}

export default ADMINStandardReferralLetterExamPage;