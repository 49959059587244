import { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { Checkbox, Col, Input, Select, Row, Spin, Form, Button } from 'antd';
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';
import '../../../static/css/components/admin-doctor-edit-form.scss';
import { defaultOMDDetail, IOMDDetails, useGetOmdCategoryQuery, useGetOmdDetailQuery, useGetOmdReceiveMethodQuery } from '../../services/doctor-api';
import { useCare1AppSelector, useCare1AppDispatch } from '../../apps/care1-hooks';
import { EMAIL_PLACEHOLDER_TEXT, ERROR_INVALID_PHONE_FORMAT, OPERATING_TEXT, PHONE_MASK_FORMAT, PHONE_PLACEHOLDER_TEXT } from '../../constants';
import { setCurrentStaffId, setCurrentUsername, setShowPasswordModal } from '../../reducers/doctor-slice';

const FormItem = Form.Item;

type AppProps = {
    title: string,
    buttonText: string,
    mode: string,
    onFinish: (values: IOMDDetails) => Promise<void>,
    onFinishFailed: () => void
}

const OMDEditForm = ({ title, buttonText, mode, onFinish, onFinishFailed }: AppProps) => {

    const provinces = useCare1AppSelector(store => store.options.provinces);
    const regions = useCare1AppSelector(store => store.options.regions);
    const omdNetworks = useCare1AppSelector(store => store.options.omd_networks);
    const currentOmdId = useCare1AppSelector(store => store.doctorSlice.currentOmd);
    const showOmdEditModal = useCare1AppSelector(store => store.doctorSlice.showOmdEditModal);
    const dispatch = useCare1AppDispatch();

    const skipQuery = currentOmdId && mode==='edit' && showOmdEditModal ? false : true;

    const {data = {omd: {...defaultOMDDetail}}, isFetching, isSuccess} = useGetOmdDetailQuery(currentOmdId as number, {skip: skipQuery });
    const {data: omdReceiveMethodOptions, isFetching: isOmdReceiveMethodFetching} = useGetOmdReceiveMethodQuery();
    const {data: omdCategoryOptions, isFetching: isOmdCategoryFetching} = useGetOmdCategoryQuery();


    const {omd: {first_name: firstName, last_name: lastName, username, email, id}} = data;

    const [form] = Form.useForm();

    const selectedReceiveMethod = Form.useWatch('receive_method', form);
    // make the column span the whole column witdh
    const fullColWidth = { span: 24 };

    useEffect(() => {
        // update the form values based on omd data just fetched from useGetOmdDetailQuery hook
        if (isSuccess) {
            form.setFieldsValue({
                ...data.omd,
            });
        }
    }, [data, form, isSuccess])


    const handlePasswordChange = () => {
        dispatch(setShowPasswordModal(true));
        dispatch(setCurrentStaffId(id));
        dispatch(setCurrentUsername(username));
    }

    return (
        <Form
            className={`admin-doctor-edit-form ${mode === 'edit' ? 'edit-omd' : 'add-omd'}`}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={defaultOMDDetail}
        >
            <Spin
                className="loading-spinner"
                spinning={isFetching || isOmdReceiveMethodFetching || isOmdCategoryFetching}
                size="large"
                tip={OPERATING_TEXT}
            >
                <Row className="header-section" align="middle">
                    <div className="modal-title">
                        {title}
                    </div>
                </Row>
                {mode === 'edit' &&
                    <Row className='read-only-details' gutter={24}>
                        <Col>
                            <div className="label-column">Username</div>
                        </Col>
                        <Col>
                            <div className="value-column">{username}</div>
                        </Col>
                        <Col>
                            <div className="label-column">Lastname</div>
                        </Col>
                        <Col>
                            <div className="value-column">{lastName}</div>
                        </Col>
                        <Col>
                            <div className="label-column">Firstname</div>
                        </Col>
                        <Col>
                            <div className="value-column">{firstName}</div>
                        </Col>
                        <Col>
                            <div className="label-column">Password Edit:</div>
                        </Col>
                        <Col>
                            <div className="value-column password-edit">
                                <Button
                                    className='btn-edit'
                                    onClick={handlePasswordChange}
                                    >
                                        Edit
                                    </Button>
                            </div>
                        </Col>
                    </Row>
                }
                {mode === 'add' &&
                    <>
                        <Row gutter={24}>
                            <Col span={8}>
                                <FormItem
                                    label={'Username'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'username'}
                                    rules={[{
                                        required: true,
                                        message: buildRequiredErrorMessage('Username')
                                    }]}
                                >
                                    <Input
                                        data-testid='username-input'
                                        className={"username-input"}
                                        placeholder={'Enter username'}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label={'First name'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'first_name'}
                                    rules={[{
                                        required: true,
                                        message: buildRequiredErrorMessage('First name')
                                    }]}
                                >
                                    <Input
                                        data-testid='first-name-input'
                                        className={"first-name-input"}
                                        placeholder={'Enter first name'}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label={'Last name'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'last_name'}
                                    rules={[{
                                        required: true,
                                        message: buildRequiredErrorMessage('Last name')
                                    }]}
                                >
                                    <Input
                                        data-testid='last-name-input'
                                        className={"last-name-input"}
                                        placeholder={'Enter last name'}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <FormItem
                                    label={'Password'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'password'}
                                    rules={[{
                                        required: true,
                                        message: buildRequiredErrorMessage('Password')
                                    }]}
                                >
                                    <Input.Password
                                        data-testid='password-input'
                                        className={"password-input"}
                                        placeholder={'Enter password'}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label={'Password confirmation'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'password_confirm'}
                                    dependencies={['password']}
                                    rules={[{
                                        required: true,
                                        message: 'Please confirm your password!'
                                    },
                                    ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                    })]}
                                >
                                    <Input.Password
                                        data-testid='password-confirm-input'
                                        className={"password-confirm-input"}
                                        placeholder={'Enter password again'}
                                    />
                                </FormItem>
                            </Col>
                        <Col span={8}>
                            {/* Test 006 */}
                            <FormItem
                                label={'Email'}
                                labelCol={fullColWidth}
                                hasFeedback
                                colon={false}
                                name={'email'}
                                rules={[{
                                    required: selectedReceiveMethod === 'email',
                                }]}
                            >
                                <Input
                                    data-testid='email-input'
                                    className={"email-address-input"}
                                    placeholder={EMAIL_PLACEHOLDER_TEXT}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                </>
            }
                <Row gutter={24}>
                    <Col span={mode === 'edit' ? 6 : 8}>
                        {/* PE 005 */}
                        <FormItem
                            label={"Telephone"}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'telephone'}
                            validateTrigger={'onBlur'}
                            rules={[{
                                pattern: /^[^_]+$/,
                                message: ERROR_INVALID_PHONE_FORMAT,
                            }]}
                        >
                            <InputMask
                                data-testid='phone-input'
                                className={'phone-number-input ant-input'}
                                mask={PHONE_MASK_FORMAT}
                                placeholder={PHONE_PLACEHOLDER_TEXT}
                            />
                        </FormItem>
                    </Col>
                    <Col span={mode === 'edit' ? 6 : 8}>
                        {/* PE 005 */}
                        <FormItem
                            label={'Fax'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'fax'}
                            validateTrigger={'onBlur'}
                            rules={[{
                                pattern: /^[^_]+$/,
                                message: ERROR_INVALID_PHONE_FORMAT,
                            }, {
                                required: selectedReceiveMethod === 'fax',
                            }]}
                        >
                            <InputMask
                                data-testid='fax-input'
                                className={'fax-number-input ant-input'}
                                mask={PHONE_MASK_FORMAT}
                                placeholder={'Enter fax number'}
                            />
                        </FormItem>
                    </Col>
                <Col span={mode === 'edit' ? 6 : 8}>
                    <FormItem
                        label={'Home province'}
                        labelCol={fullColWidth}
                        hasFeedback
                        colon={false}
                        name={'home_province'}
                        rules={[{
                            required: true,
                            message: buildRequiredErrorMessage('Province')
                        }]}
                    >
                        <Select
                            placeholder={'Select home province'}
                            className="home-province-input"
                            data-testid='home-province-select'
                        >
                            {provinces.length > 0 && provinces.map(province => {
                                return (
                                    <Select.Option key={province.id} value={province.id}>
                                        {province.name}
                                    </Select.Option>
                                );
                            })
                            }
                        </Select>
                    </FormItem>
                </Col>
                {mode === 'edit' &&
                    <Col span={6}>
                        <FormItem
                            label={'Email'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'email'}
                            rules={[{
                                required: selectedReceiveMethod === 'email',
                            }]}
                        >
                            <Input
                                data-testid='email-input'
                                className={"email-address-input"}
                                placeholder={EMAIL_PLACEHOLDER_TEXT}
                            />
                        </FormItem>
                    </Col>
                }
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        {/* Test 006 */}
                        <FormItem
                            label={'License number'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'license_number'}
                        >
                            <Input
                                data-testid='license-number-input'
                                className={"license-number-input"}
                                placeholder={'Enter license number'}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            label={'OMD network'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'omdnetwork'}
                            rules={[{
                                required: true,
                                message: buildRequiredErrorMessage('OMD network')
                            }]}
                        >
                            <Select
                                placeholder={'Select OMD network'}
                                className="omd-network-input"
                                data-testid='omd-network-select'
                            >
                                {omdNetworks.length > 0 && omdNetworks.map(on => {
                                    return (
                                        <Select.Option key={on.id} value={on.id}>
                                            {on.name}
                                        </Select.Option>
                                    );
                                })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                <Col span={8}>
                    <FormItem
                        label={'Region'}
                        labelCol={fullColWidth}
                        hasFeedback
                        colon={false}
                        name={'region'}
                        rules={[{
                            required: true,
                            message: buildRequiredErrorMessage('Region')
                        }]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder={'Select region'}
                            className="region-input"
                            data-testid='region-select'
                        >
                            {regions.length > 0 && regions.map(r => {
                                return (
                                    <Select.Option key={r.id} value={r.id}>
                                        {r.name}
                                    </Select.Option>
                                );
                            })
                            }
                        </Select>
                    </FormItem>
                </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem
                            label={'Agreed to review provinces'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'agreed_to_review_provinces'}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder={'Select agreed to review provinces'}
                                className="agreed-to-review-provinces-input"
                                data-testid='agreed-to-review-provinces-select'
                            >
                                {provinces.length > 0 && provinces.map(r => {
                                    return (
                                        <Select.Option key={r.id} value={r.id}>
                                            {r.name}
                                        </Select.Option>
                                    );
                                })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            label={'Licensed provinces'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'licensed_provinces'}
                            rules={[{
                                required: true,
                                message: buildRequiredErrorMessage('Licensed provinces')
                            }]}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder={'Select licensed provinces'}
                                className="licensed-provinces-input"
                                data-testid='licensed-provinces-select'
                            >
                                {provinces.length > 0 && provinces.map(r => {
                                    return (
                                        <Select.Option key={r.id} value={r.id}>
                                            {r.name}
                                        </Select.Option>
                                    );
                                })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            label={'Private Email'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'private_email'}
                            rules={[{
                                type: 'email',
                            }]}
                        >
                            <Input
                                data-testid='private-email-input'
                                className={"username-input"}
                                placeholder={'Enter private email'}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            label={'Preferred Method to Receive Referral '}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'receive_method'}
                        >
                            <Select
                                allowClear
                                placeholder={'Select Preferred Method to Receive Referral'}
                                className="agreed-to-review-provinces-input"
                                data-testid='preferred-method-to-receive-referral-select'
                                options={omdReceiveMethodOptions}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            label={'OMD Category'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'category'}
                        >
                            <Select
                                allowClear
                                placeholder={'Select OMD Category'}
                                className="licensed-provinces-input"
                                data-testid='licensed-provinces-select'
                                options={omdCategoryOptions}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem
                            label={'Email for internal reviews'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'internal_review_email'}
                            rules={[{
                                type: 'email',
                            }]}
                        >
                            <Input
                                data-testid='private-email-input'
                                className={"username-input"}
                                placeholder={'Enter Internal Review Email'}
                            />
                        </FormItem>
                    </Col>
                    
                    <Col span={12}>
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'receives_internal_reviews'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='send-gp-letters-input'
                                className={"send-reveiws-to-od"}
                            >
                                Receive internal reviews
                            </Checkbox>
                        </FormItem>
                    </Col>
                </Row>
                <Row className='checkbox-row' gutter={24}>
                    <Col span={6}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'is_migrated'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='is-migrated-input'
                                className={'is-migrated-input'}
                            >
                                Is migrated?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'us_only'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='us-only-input'
                                className={"us-only-input"}
                            >
                                US only?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'is_reviewer'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='is-reviewer-input'
                                className={"is-reviewer-input"}
                            >
                                Is reviewer?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'hidden_from_dropdown'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='hidden-from-dropdown-input'
                                className={"hidden-from-dropdown-input"}
                            >
                                Hidden from dropdown?
                            </Checkbox>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'is_power_user'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='is-power-user-input'
                                className={"is-power-user-input"}
                            >
                                Is power user?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'is_ret_specialist'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='is-ret-specialist-input'
                                className={"is-ret-specialist-input"}
                            >
                                Is ret specialist?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'is_generic'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='is-generic-input'
                                className={"is-generic-input"}
                            >
                                Is generic?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'other_omd_reviews_hidden'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='other-omd-reviews-hidden-input'
                                className={"is-generic-input"}
                            >
                                Hide reviews from other OMDs
                            </Checkbox>
                        </FormItem>
                    </Col>
                </Row>
                <Row justify='end'>
                    <Col span={6}>
                        <Button className='save-doctor-button' type="primary" htmlType="submit">
                            {buttonText}
                        </Button>
                    </Col>
                </Row>
            </Spin>
        </Form>
    );
}

export default OMDEditForm;