import { Button, Card, Col, Row } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import * as Constants from '../../constants';

// Components
import HistoryImage from '../retina/history-image';

// Helpers
import { convertTimeToShortFormatLabel } from '../../helpers/utilities';
import { calculateVFBorderColor, getOCTRNFLBorderColorClasses } from '../../helpers/patient-exam-convert';
import { getSideHistoryImages, getHistoryImageSlice } from '../../helpers/image-histories-convert';
import '../../../static/css/shared/images-history-card.scss';
import '../../../static/css/components/history-image-side.scss';
import { viewAll } from '../../reducers/history-image-slice';
import { IAllExamImages, IExamsWithLeftOctRnfl, IExamsWithLeftVf, IExamsWithRightOctRnfl, IExamsWithRightVf } from '../../reducers/patient-exam-slice';
import { HistoryDropzoneField } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch } from '../../apps/care1-hooks';

type ComponentProps = {
    side: 'left' | 'right',
    imageType: string,
    photoURL: HistoryDropzoneField,
    leftImages: IAllExamImages[],
    rightImages: IAllExamImages[],
    colWidth: number,
    imagesPerRow: number,
    maxDisplayCells: number,
    viewAllButtonText: string,
    orderBy: string,
    showBaselineBorder: boolean,
    showEmptyRow: boolean,
}

// Used for displaying the first row baseline testing followed by three rows
// of oldest to newest ending with the most recent image.

const HistoryImagesSide = ({ side, imageType, photoURL, leftImages, rightImages,
    colWidth, imagesPerRow, maxDisplayCells, viewAllButtonText, 
    orderBy, showBaselineBorder, showEmptyRow }: ComponentProps) => {
    const dispatch = useCare1AppDispatch();

    const getImageCards = (isFirstRow: string) => {

        let rowKey = 0;
        const imageExams = getSideHistoryImages(side, leftImages, rightImages, orderBy);
        const imageSlice = getHistoryImageSlice(imageExams, isFirstRow, imagesPerRow, maxDisplayCells);

        const sideImageCards = [];

        imageSlice.forEach((image, index) => {
            // Images that were not uploaded for a side have the exam id tagged as null
            const cellIndex = isFirstRow === 'isFirstRow' ? index : index + imagesPerRow;
            let borderClassName = '';
            if (imageType === Constants.OCT_RNFL_TEXT) {
                const currentImage = image as IExamsWithLeftOctRnfl | IExamsWithRightOctRnfl;
                borderClassName = getOCTRNFLBorderColorClasses(currentImage , side);
            } else if (imageType === Constants.OCT_VF_TEXT) {
                if (side === 'right') {
                    const currentImage = image as IExamsWithRightVf;
                    borderClassName = calculateVFBorderColor(currentImage.right_ght);
                } else {
                    const currentImage = image as IExamsWithLeftVf;
                    borderClassName = calculateVFBorderColor(currentImage.left_ght);
                }
            }
            if(image.exam_id !== null) {
                sideImageCards.push(
                    <Col key={rowKey++} className={'image-col'} span={colWidth}>
                        <Card bordered={false} data-testid={`image-${side}-cell-${cellIndex}`}>
                            <HistoryImage
                                style={{}}
                                type={imageType}
                                side={side}
                                photoUrl={image[photoURL]}
                                examDate={image.exam_date_utc}
                                idx={cellIndex}
                                borderClass={borderClassName}
                            />
                        </Card>
                    </Col>
                );
            } else {
                // If exam id is tagged as null display a message to indicate an image was not uploaded for that side
                sideImageCards.push(
                    <Col key={rowKey++} span={colWidth} data-testid={`image-${side}-cell-${cellIndex}`}>
                        <Card bordered={false}>
                            <div className='blank-history-image no-test-done'>
                                {`No Test Done`}
                            </div>
                            <div className="heading exam-date">{convertTimeToShortFormatLabel(image.exam_date_utc)}</div>
                        </Card>
                    </Col>
                );
            }
        });

        // Display a blank image to complete the last row for any cells that do not have an image.
        let emptyCells = imageSlice.length === 0 ? imagesPerRow
            : imageSlice.length === imagesPerRow || imageSlice.length%imagesPerRow === 0 ? 0
            : imagesPerRow - (imageSlice.length%imagesPerRow);

        if (!showEmptyRow && imageSlice.length === 0) {
            emptyCells = 0;
        }

        if (emptyCells) {
            for (let i = 0; i < emptyCells; i += 1) {
                sideImageCards.push(
                    <Col key={rowKey++} span={colWidth}>
                        <Card bordered={false}>
                            <div className='blank-history-image'>
                                <PictureOutlined />
                            </div>
                        </Card>
                    </Col>
                );
            }
        }

        return sideImageCards;
    }

    return (

        <Card bordered={false}>
            <Row className={`image-row ${showBaselineBorder ? 'baseline-images' : 'not-baseline-images'}`}>
                { getImageCards('isFirstRow') }
            </Row>
            <Row className='image-row'>
                { getImageCards('isNotFirstRow') }
            </Row>

            { ((side === 'right' && rightImages.length > maxDisplayCells)
                || (side === 'left' && leftImages.length > maxDisplayCells)) &&
                <Col span={24}>
                    <Button
                        className="button-view-all"
                        onClick={(event) => {
                            event.stopPropagation();
                            dispatch(viewAll({isVisible:true, imageType, imageSide: side}));
                        }}
                    >
                        {viewAllButtonText}
                    </Button>
                </Col>
            }
        </Card>
    );
}

export default HistoryImagesSide;