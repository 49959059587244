import { useState, useEffect } from 'react';
import { useCare1AppSelector, useCare1AppDispatch } from '../../apps/care1-hooks';
import { Col, Row, Tooltip, Button } from 'antd';
import * as Constants from '../../constants';
import '../../../static/css/components/macula-abnormality.scss';
import { getBackendMediaUrl } from '../../helpers/media-image-convert';
import ButtonNo from '../../../static/images/foveal-index-no.png';
import ButtonYes from '../../../static/images/foveal-index-yes.png';
import { openModal } from '../../reducers/macula-abnormality-slice';
import { setModalEditData, fetchMaculaAbnormality } from '../../reducers/macula-abnormality-slice';

const FovealAbnormalityScore = () => {

    const dispatch = useCare1AppDispatch();

    const examId = useCare1AppSelector(store => store.examData[Constants.EXAM_ID]);
    const maculaAbnormality = useCare1AppSelector(store => store.maculaAbnormality);

    const leftFundus = useCare1AppSelector(store => store.examData.photoUrls.left_fundus_photo);
    const rightFundus = useCare1AppSelector(store => store.examData.photoUrls.right_fundus_photo);

    const [hasRightScoreImage, setHasRightScoreImage] = useState(false);
    const [hasLeftScoreImage, setHasLeftScoreImage] = useState(false);

    const [rightScoreImage, setRightScoreImage] = useState(rightFundus);
    const [leftScoreImage, setLeftScoreImage] = useState(leftFundus);

    const [rightFovealIndex, setRightFovealIndex] = useState('');
    const [leftFovealIndex, setLeftFovealIndex] = useState('');

    useEffect(() => {
        if (examId) {
            dispatch(fetchMaculaAbnormality());
        }
    }, [examId, dispatch])

    useEffect(() => {
        if (maculaAbnormality.macula_abnormality_results) {
            if (maculaAbnormality.macula_abnormality_results.right) {
                const fovealIndexRes = maculaAbnormality.macula_abnormality_results.right.filter(o => o.point === 0);
                if (fovealIndexRes && fovealIndexRes.length === 1) {
                    setHasRightScoreImage(true);
                    setRightScoreImage(getBackendMediaUrl() + fovealIndexRes[0].image);
                    setRightFovealIndex(((1.0 - fovealIndexRes[0].num_blue_pixels / fovealIndexRes[0].num_all_pixels) * 100.0).toFixed(0) + '%');
                }
            }
            else {
                setHasRightScoreImage(false);

                setRightScoreImage(rightFundus);

                setRightFovealIndex('');
            }

            if (maculaAbnormality.macula_abnormality_results.left) {
                const fovealIndexRes = maculaAbnormality.macula_abnormality_results.left.filter(o => o.point === 0);
                if (fovealIndexRes && fovealIndexRes.length === 1) {
                    setHasLeftScoreImage(true);
                    setLeftScoreImage(getBackendMediaUrl() + fovealIndexRes[0].image);
                    setLeftFovealIndex(((1.0 - fovealIndexRes[0].num_blue_pixels / fovealIndexRes[0].num_all_pixels) * 100.0).toFixed(0) + '%');
                }
            }
            else {
                setHasLeftScoreImage(false);

                setLeftScoreImage(leftFundus);

                setLeftFovealIndex('');
            }
        }
        else {
            setHasRightScoreImage(false);
            setHasLeftScoreImage(false);

            setRightScoreImage(rightFundus);
            setLeftScoreImage(leftFundus);

            setRightFovealIndex('');
            setLeftFovealIndex('');
        }

    }, [maculaAbnormality.macula_abnormality_results, leftFundus, rightFundus])

    const openEditModal = () => {
        dispatch(setModalEditData({
            // sample action.payload {key:'memberId', value: '12345'}
            key: 'edit_exam_id',
            value: examId,
        }));
        dispatch(setModalEditData({
            // sample action.payload {key:'memberId', value: '12345'}
            key: 'edit_right_fundus_photo',
            value: rightFundus,
        }));
        dispatch(setModalEditData({
            // sample action.payload {key:'memberId', value: '12345'}
            key: 'edit_left_fundus_photo',
            value: leftFundus,
        }));
        dispatch(setModalEditData({
            // sample action.payload {key:'memberId', value: '12345'}
            key: 'edit_situational_awareness_ui_states',
            value: {...maculaAbnormality.situational_awareness_ui_states},
        }));
        dispatch(openModal());
    }

    const getStatusButton = (side: string) => {

        if (side === 'right') {
            if (hasRightScoreImage) {
                return <img
                    className='status-button'
                    onClick={openEditModal}
                    src={ButtonYes}
                    alt="Yes"
                />
            }
            else {
                return <Tooltip title='Situational awareness not set'><img
                    className='status-button'
                    onClick={openEditModal}
                    src={ButtonNo}
                    alt="No"
                /></Tooltip>
            }
        }
        else {
            if (hasLeftScoreImage) {
                return <img
                    className='status-button'
                    onClick={openEditModal}
                    src={ButtonYes}
                    alt="Yes"
                />
            }
            else {
                return <Tooltip title='Situational awareness not set'><img
                    className='status-button'
                    onClick={openEditModal}
                    src={ButtonNo}
                    alt="No"
                /></Tooltip>
            }
        }
    }

    return (
        <div className="macula-abnormality foveal-abnormality-score">
            <Row className='fovealImageWrapper'>
                <Col span={12}>
                    <div className="foveal-index-container foveal-index-container-right">
                        <div className="photo-container-border">
                            <div className="photo-container">
                                {rightScoreImage &&
                                    <img
                                        className='photo'
                                        src={rightScoreImage}
                                        alt='right score img'
                                    />
                                }
                            </div>
                        </div>
                        <div className="foveal-index-score">
                            <div className="foveal-index-score-value">Foveal Index {rightFovealIndex}</div>
                        </div>
                        <div>
                            {getStatusButton('right')}
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="foveal-index-container foveal-index-container-left">
                        <div className="photo-container-border">
                            <div className="photo-container">
                                {leftScoreImage &&
                                    <img
                                        className='photo'
                                        src={leftScoreImage}
                                        alt='left score img'

                                    />
                                }
                            </div>
                        </div>
                        <div className="foveal-index-score">
                            <div className="foveal-index-score-value">Foveal Index {leftFovealIndex}</div>
                        </div>
                        <div>
                            {getStatusButton('left')}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <div className='set-situational-awareness-btn'>
                    <Button
                        type='primary'
                        className='op-btn'
                        onClick={openEditModal}
                    >Set Situational Awareness</Button>
                </div>
            </Row>
        </div>
    );
}

export default FovealAbnormalityScore;
