import { useEffect, useRef } from 'react';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/patient-exam-glaucoma-score.scss';
import { calcOverallScore } from '../../helpers/glaucoma-score-graph-convert';
import { lengthEqual } from '../../reducers/patient-exam-slice';
import { GLAUCOMA_SCORE_NUMBER_DECIMAL } from '../../constants';

interface JqPlotObject {
    replot: () => void;
}

const PatientExamGlaucomaScoreGraph = ({ side, page }: { side: string, page: string }) => {

    const glaucomaScoreGraphData = useCare1AppSelector(store => store.examData.glaucoma_score_graph_data, lengthEqual);

    const jqChart = useRef<JqPlotObject | null>(null);

    const glaucomaScoreGraphId = `glaucoma_score_${side}_chart_${page}`;

    const generateRange = (minValue: number, maxValue: number) => {
        const range = [];
        const step = 1;

        // Adjust minValue to the nearest multiple of step
        minValue = Math.ceil(minValue / step) * step;

        for (let i = 0; ; i++) {
            const value = minValue + (i * step);
            if (value <= maxValue) {
                range.push(value);
            }
            else {
                break;
            }
        }

        return range;
    }

    useEffect(() => {
        const overallScores = [];
        for (let i=0; i<glaucomaScoreGraphData.length; i++) {
            const score = calcOverallScore(side, glaucomaScoreGraphData[i]);
            if (score !== null) {
                overallScores.push([glaucomaScoreGraphData[i].age, parseFloat(score.toFixed(GLAUCOMA_SCORE_NUMBER_DECIMAL))]);
            }
        }

        if (overallScores.length > 0) {
            const data = [
                [[0, 10], [200, 10]],
                [[0, 7.5], [200, 7.5]],
                [[0, 5.5], [200, 5.5]],
                [[0, 3], [200, 3]],
                [[0, 2], [200, 2]],
                [[0, 0], [200, 0]],
            ];
            data.push(overallScores);
            for (let i=0; i<overallScores.length; i++) {
                data.push([overallScores[i]]);
            }

            const $ = window.$;

            const buildOptions = () => {
                const optionSeries = [
                    { show: false, },
                    { show: false, },
                    { show: false, },
                    { show: false, },
                    { show: false, },
                    { show: false, },
                    { show: true, shadow: false, lineWidth: 2, color: "#BFDFFF", markerOptions: { color: "#BFDFFF" }},                    
                ]
                for (let i=0; i<overallScores.length; i++) {
                    optionSeries.push({ show: true, shadow: false, lineWidth: 1, color: "#BFDFFF", markerOptions: { color: "#2e9e41" }});
                }
                const gridBgColor = "rgba(18,22,26,0.33)";

                const ageTicks = generateRange(glaucomaScoreGraphData[0].age - 2, glaucomaScoreGraphData[glaucomaScoreGraphData.length-1].age + 2);
                console.log(`[PatientExamGlaucomaScoreGraph] side: ${side}, ageTicks: ${ageTicks}`);

                return {
                    series: optionSeries,
                    grid: {
                        background: gridBgColor,
                        gridLineColor: 'rgba(179,217,255,0.2)',
                        borderWidth: 0,
                        shadow: false,
                        gridLineWidth: 1,
                    },
                    axesDefaults: {
                        tickOptions: {
                            showMark: true,
                            textColor: '#BFDFFF',
                        },
                    },
                    seriesDefaults:{
                        pointLabels: {show: true, formatString: `%.${GLAUCOMA_SCORE_NUMBER_DECIMAL}f`},  
                    },
                    axes: {
                        xaxis: {
                            ticks: ageTicks,
                            tickOptions: {
                                formatter(formatString: string, value: number) {
                                    if (value % 1 === 0) return value; // only display integer value
                                    return '';
                                },
                                formatString: '%d',
                            },
                        },
                        yaxis: {
                            label: 'Risk Score',
                            min: 1,
                            max: 9,
                            numberTicks: 9,
                            tickOptions: {
                                formatter(formatString: string, value: number) {
                                    if (value % 2 === 1) return value; // only display odd value
                                    return '';
                                },
                                formatString: '%d',
                            },
                        },
                    },
                    fillBetween: {
                        series1: [0,1,2,3,4],
                        series2: [1,2,3,4,5],
                        color: ['rgba(255,77,79,0.8)', 'rgba(255,77,79,0.5)', 'rgba(229,160,24,0.77)', 'rgba(112,219,130,0.59)', 'rgba(112,219,130,0.21)'],
                        fill: true,
                    },
                };
            }

            if (data !== undefined) {
                if (data[0] && data[0].length > 0) {
                    jqChart.current = $.jqplot(glaucomaScoreGraphId, data, buildOptions());
                } else {
                    jqChart.current = $.jqplot(glaucomaScoreGraphId, [[null]], buildOptions());
                }

                if (jqChart.current) {
                    jqChart.current.replot();
                }
            }
        }

    }, [jqChart, side, glaucomaScoreGraphData, glaucomaScoreGraphId]);

    return <div id={glaucomaScoreGraphId} style={{ width: 393 + 68, height: 99 + 37 }} className={`glaucoma_score_chart`} />;

}

export default PatientExamGlaucomaScoreGraph;
