import { useEffect } from 'react';
import { InputNumber, Form } from 'antd';
import { IExamData, setExamDataValue } from '../../../reducers/patient-exam-slice';
import { updateGptSetValuesNearMissData } from '../../../reducers/gpt-set-values-slice';
import { allowPosNumberAndDecimalOnlyKeys } from '../../../helpers/utilities';
import { OD_CD_FIELD, OS_CD_FIELD } from '../../../constants';
import '../../../../static/css/components/patient-exam-vert-cd.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { FieldData } from 'rc-field-form/lib/interface';

type ComponentProps = {
    disabled?: boolean,
    side: 'od' | 'os',
    placeholderValue?: string,
    gptSaved?: boolean;
    gptNearMiss?: boolean;
}

const PatientExamVertCD = ({ side, placeholderValue, disabled, gptSaved, gptNearMiss }: ComponentProps) => {

    const os_cd = useCare1AppSelector(store => store.examData.os_cd);
    const od_cd = useCare1AppSelector(store => store.examData.od_cd);
    const sideValue = useCare1AppSelector(store => store.examData[`${side}_cd`]);
    const dispatch = useCare1AppDispatch();
    const [ form ] = Form.useForm();
    const sideField: 'od_cd' | 'os_cd' = `${side}_cd`;

    // Yellow warning border should display for OD users only
    const userIsOD = useCare1AppSelector(store => store.user.isOD);

    useEffect(()=> {
        // Get values for Ant Design fields from the store whenever values update.
        form.setFieldsValue({
            [sideField]: sideValue,
        });
    },[form, sideValue, sideField])

    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name.length && !field.validating) {
                switch (field.name[0]) {
                    case OD_CD_FIELD:
                    case OS_CD_FIELD:
                        // Format the value to '0.##'
                        dispatch(setExamDataValue(field.name[0], formatValue(field.value, {
                            userTyping: false,
                            input: '',
                        })));
                        dispatch(updateGptSetValuesNearMissData({id: [field.name[0]].toString() as keyof IExamData, value: field.value}));
                        break;
                    default:
                        break;
                }
            }
        })
    }

    // All vert CD entries should be in the format of '0.##'
    const formatValue = (value: string | number | undefined, info: {
            userTyping: boolean;
            input: string;
        }): string => {

        if (value === null) {
            return '';
        }

        const regex = /^(0?)(\.?)(([0-9][1-9])|([1-9])).*$/;
        const matches = String(value).match(regex);

        // Check length and supply decimal places only when user is not inputting
        // i.e onBlur (onBlur itself is overwritten by formatValue)
        // And when not enough decimal places
        if (!info.userTyping && value?.toString().length !== 4) {
            // when the value is empty string, it should stay empty string, not 0.00
            if (value === '') return value;
            const formattedValue = Number(value).toFixed(2).toString();
            return formattedValue;
        }

        let vertCD = '';

        if(matches){
            let match = matches[4] ? Number(`0.${matches[4]}`)
                : matches[5] ? Number(`0.${matches[5]}`)
                : Number(`${matches[1]}${matches[2]}`);

            vertCD = match.toString();
        }

        return vertCD;
    }

    const getClassname = () => {
        try {
            const f = parseFloat(sideValue);
            const fstr = `${f.toFixed(2)}`;

            if (fstr === '0.70' || fstr === '0.80') {
                return 'input-component yellow-value';
            }
            else if (f >= 0.9) {
                return 'input-component red-value';
            }
            else {
                return 'input-component';
            }
        }
        catch (e) {
            return 'input-component';
        }
    }

    const gptClassname = () => {
        if (gptNearMiss) {
            return 'exam-gpt-state-red';
        }
        if (gptSaved) {
            return 'exam-gpt-state-yellow';
        }
        return '';
    }

    return (
        <Form form={form} onFieldsChange={onFieldsChange}>
            <div className={gptClassname()}>
                <Form.Item
                    className='patient-exam-vert-cd'
                    help=""
                    validateStatus={ userIsOD && !od_cd && !os_cd ? 'warning' : '' }
                    name={sideField}
                    initialValue={sideValue}
                >
                    {/* VCD 001, VCD 002, VCD 003, VCD 004 */}
                    {/* Use onKeyPress event to prevent letters */}
                    {/* Use formatter to enforce 0.## pattern */}
                    <InputNumber
                        className={getClassname()}
                        data-testid={sideField}
                        size="small"
                        step={0.15}
                        placeholder={placeholderValue}
                        onKeyPress={event => allowPosNumberAndDecimalOnlyKeys(event)}
                        formatter={formatValue}
                        disabled={disabled}
                    />
                </Form.Item>
            </div>
        </Form>
    );
}

export default PatientExamVertCD;