import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ValueOf<T> = T[keyof T];
type ValueOfGlaucomaProgram = ValueOf<IGlaucomaProgram>;

export interface IGlaucomaProgramFilters {
    od?: number,
    next_visit?: string,
    booking_status?: string,
    iop_compare?: string,
    iop_number?: number,
    cd_compare?: string,
    cd_number?: number,
    vf?: string,
};

// Slice main interface and state
export interface IGlaucomaProgram {
    operating: boolean;
    odFilter: number | null;
    nextVisitFilter: string | null;
    bookingStatusFilter: string | null;
    iopCompareFilter: string | null;
    iopCompareNumberFilter: number | null;
    cdCompareFilter: string | null;
    cdCompareNumberFilter: number | null;
    vfFilter: string | null;
};

const initialState : IGlaucomaProgram = {
    operating: false,
    odFilter: null,
    nextVisitFilter: null,
    bookingStatusFilter: null,
    iopCompareFilter: null,
    iopCompareNumberFilter: null,
    cdCompareFilter: null,
    cdCompareNumberFilter: null,
    vfFilter: null,
};

export const glaucomaProgramSlice = createSlice({
    name: 'glaucomaProgram',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setGlaucomaProgramFieldData: (state, action: PayloadAction<{key: keyof IGlaucomaProgram, value: ValueOfGlaucomaProgram}>) => {
            return {
                ...state,
                [action.payload.key]:action.payload.value,
            }
        },
        clearAllFilters: (state, action: PayloadAction<void>) => {
            return {
                ...state,
                odFilter: null,
                nextVisitFilter: null,
                bookingStatusFilter: null,
                iopCompareFilter: null,
                iopCompareNumberFilter: null,
                cdCompareFilter: null,
                cdCompareNumberFilter: null,
                vfFilter: null,
            }
        },
    },
    extraReducers: (builder) => {
    }
});

export const { setGlaucomaProgramFieldData, clearAllFilters } = glaucomaProgramSlice.actions;

export default glaucomaProgramSlice.reducer;
