import { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'antd';
import { convertTimeToShortFormatLabel } from '../../helpers/utilities';
import { useCare1AppDispatch } from '../../apps/care1-hooks';
import { getBackendMediaUrl } from '../../helpers/media-image-convert';
import { IMaculaHistoricalAnalysisItem, setHistoricalAnalysisData, openSegmentationModal } from '../../reducers/macula-historical-analysis-slice';
import ButtonNo from '../../../static/images/foveal-index-no.png';
import ButtonYes from '../../../static/images/foveal-index-yes.png';
import { openModal } from '../../reducers/macula-abnormality-slice';
import { setModalEditData } from '../../reducers/macula-abnormality-slice';
import svgTag from '../../../static/images/trapizoid-tag-small.svg'


type ComponentProps = {
    isAwarenessSet: boolean,
    side: string,
    photoUrl: string,
    examDate: string,
    idx: number,
    borderClass: string,
    style: Record<string, string>,
    record: IMaculaHistoricalAnalysisItem,
}

// The preGeneratedThumbnail flag controls whether we use a pre-generated thumbnail. When set to false, we rely
// on the lightbox component to auto-generate for us. When set to true, we use a pre-generated thumbnail.
const MaculaHistoryImage = ({ isAwarenessSet, side, photoUrl, examDate, idx, borderClass, style, record }: ComponentProps) => {

    const dispatch = useCare1AppDispatch();

    const imageRef = useRef<HTMLImageElement>(null);

    const [fovealIndex, setFovealIndex] = useState('');

    const mainSrcUrl = getBackendMediaUrl() + photoUrl;

    const openEditModal = () => {
        dispatch(setModalEditData({
            // sample action.payload {key:'memberId', value: '12345'}
            key: 'edit_exam_id',
            value: record.exam_id,
        }));
        dispatch(setModalEditData({
            // sample action.payload {key:'memberId', value: '12345'}
            key: 'edit_right_fundus_photo',
            value: record.right_fundus_photo === '' ? '' : getBackendMediaUrl() + record.right_fundus_photo,
        }));
        dispatch(setModalEditData({
            // sample action.payload {key:'memberId', value: '12345'}
            key: 'edit_left_fundus_photo',
            value: record.left_fundus_photo === '' ? '' : getBackendMediaUrl() + record.left_fundus_photo,
        }));
        dispatch(setModalEditData({
            // sample action.payload {key:'memberId', value: '12345'}
            key: 'edit_situational_awareness_ui_states',
            value: {...record.situational_awareness_ui_states},
        }));
        dispatch(openModal());
    }

    const openSegmentationAnalysisModal = () => {
        dispatch(setHistoricalAnalysisData({
            key: 'selected_exam_id',
            value: record.exam_id,
        }));
        dispatch(setHistoricalAnalysisData({
            key: 'selected_side',
            value: side,
        }));
        dispatch(openSegmentationModal());
    }

    const getStatusButton = () => {

        if (isAwarenessSet) {
            return <img
                className='status-button'
                onClick={openEditModal}
                src={ButtonYes}
                alt="Yes"
            />
        }
        else {
            return <Tooltip title='Situational awareness not set'><img
                className='status-button'
                onClick={openEditModal}
                src={ButtonNo}
                alt="No"
            /></Tooltip>
        }
    }

    let macula_info = {
        x: record.situational_awareness_ui_states.right_macula_x,
        y: record.situational_awareness_ui_states.right_macula_y,
        width: record.situational_awareness_ui_states.right_macula_width,
        height: record.situational_awareness_ui_states.right_macula_height,
    };
    let optic_nerve_info = {
        x: record.situational_awareness_ui_states.right_optic_nerve_x,
        y: record.situational_awareness_ui_states.right_optic_nerve_y,
        width: record.situational_awareness_ui_states.right_optic_nerve_width,
        height: record.situational_awareness_ui_states.right_optic_nerve_height,
    };

    if (side === 'left') {
        macula_info = {
            x: record.situational_awareness_ui_states.left_macula_x,
            y: record.situational_awareness_ui_states.left_macula_y,
            width: record.situational_awareness_ui_states.left_macula_width,
            height: record.situational_awareness_ui_states.left_macula_height,
        };
        optic_nerve_info = {
            x: record.situational_awareness_ui_states.left_optic_nerve_x,
            y: record.situational_awareness_ui_states.left_optic_nerve_y,
            width: record.situational_awareness_ui_states.left_optic_nerve_width,
            height: record.situational_awareness_ui_states.left_optic_nerve_height,
        };
    }

    useEffect(() => {
        if (!isAwarenessSet) {
            return
        }

        const imageElement = imageRef.current;
        if (imageElement !== null) {
            if (imageElement.offsetWidth && imageElement.offsetHeight) {

                if (macula_info.x && macula_info.y && macula_info.width && macula_info.height &&
                    optic_nerve_info.x && optic_nerve_info.y && optic_nerve_info.width && optic_nerve_info.height) {

                    const imageWidth = imageElement.offsetWidth;
                    const imageHeight = imageElement.offsetHeight;

                    const macula_info_x = imageWidth * macula_info.x;
                    const macula_info_y = imageHeight * macula_info.y;

                    const optic_nerve_info_x = imageWidth * optic_nerve_info.x;
                    const optic_nerve_info_y = imageHeight * optic_nerve_info.y;

                    const radius = 0.66 * Math.sqrt((macula_info_x - optic_nerve_info_x) * (macula_info_x - optic_nerve_info_x) + (macula_info_y - optic_nerve_info_y) * (macula_info_y - optic_nerve_info_y));

                    // Calculate the scale factors for width and height
                    const scaleWidth = imageWidth / radius / 2.0;
                    const scaleHeight = imageHeight / radius / 2.0;
                    const scaleFactor = Math.min(scaleWidth, scaleHeight) * 0.9;

                    const translateX = macula_info_x - imageWidth / 2.0;
                    const translateY = macula_info_y - imageHeight / 2.0;

                    // Apply the transformation to the image
                    imageElement.style.transform = `scale(${scaleFactor}) translate(${-translateX}px, ${-translateY}px)`;
                }
            }
        }
        // eslint-disable-next-line
    }, [macula_info, optic_nerve_info]);

    useEffect(() => {
        if (record.macula_abnormality_results) {
            const macula_abnormality_results_side = (side === 'left') ? record.macula_abnormality_results.left :
                record.macula_abnormality_results.right;
            if (macula_abnormality_results_side) {
                const fovealIndexRes = macula_abnormality_results_side.filter(o => o.point === 0);
                if (fovealIndexRes && fovealIndexRes.length === 1) {
                    setFovealIndex(((1.0 - fovealIndexRes[0].num_blue_pixels / fovealIndexRes[0].num_all_pixels) * 100.0).toFixed(0) + '%');
                }
            }
            else {
                setFovealIndex('');
            }
        }
        else {
            setFovealIndex('');
        }

    }, [record.macula_abnormality_results, side])

    return (
        <div key={idx} className='macula-history-image'>
            <div id={idx.toString()} className={`zoomed-image extra-image-foveal ${borderClass ? borderClass : ''}`}>
                <img
                    ref={imageRef}
                    role="img"
                    alt=""
                    className=""
                    src={mainSrcUrl}
                    style={style}
                    onError={
                        (e) => {
                            // If the the thumbnail image can't be found, just use the full image.
                            if (e.currentTarget.src !== mainSrcUrl) {
                                e.currentTarget.src = mainSrcUrl;
                            }
                        }
                    }
                    onClick={openSegmentationAnalysisModal}
                />
            </div>
            <div className="foveal-index-score">
                <Tooltip title={fovealIndex ? `Foveal Index ${fovealIndex}` : ''}>
                   {fovealIndex &&
                        <div className="foveal-index-score-value">
                            {fovealIndex ? `FI ${fovealIndex}` : ''}
                        </div>
                    }
                    <div className='indexDate'>
                        {fovealIndex && <img className='svgDataTag' src={svgTag} alt='the foveal date' />}
                        <div className="indexExamDate">{convertTimeToShortFormatLabel(examDate)}</div>
                    </div>
                </Tooltip>    
            </div>
            <div>
                {getStatusButton()}
            </div>
        </div>
    );
}

export default MaculaHistoryImage;