import { Modal, Row, Col } from 'antd';
import '../../../static/css/components/admin-exam-metrics-info-modal.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import dayjs from 'dayjs';
import { closeSegmentationModal } from '../../reducers/ai-insights-historical-analysis-slice';
import AdminRetinaScan from './admin-retina-scan';


const AdminAIInsightsModal = () => {

    const showModal = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.show_modal);
    const slecetedExamId = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_exam_id);
    const selectedFundusPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_fundus_photo);
    const useLargeHemePigmentPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_use_large_heme_pigment_photo);
    const selectedMLLargeHemePigmentOverlayPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_large_heme_pigment_overlay_photo);
    const selectedMLLargeHemePigmentValue = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_large_heme_pigment_value);
    const selectedMLLargeHemePigmentSucceeded = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_large_heme_pigment_succeeded);
    const useMicroAneurysmsPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_use_micro_aneurysms_photo);
    const selectedMLMicroAneurysmsOverlayPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_micro_aneurysms_overlay_photo);
    const selectedMLMicroAneurysmsValue = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_micro_aneurysms_value);
    const selectedMLMicroAneurysmsSucceeded = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_micro_aneurysms_succeeded);
    const useHardExudatesPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_use_hard_exudates_photo);
    const selectedMLHardExudatesOverlayPhoto = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_hard_exudates_overlay_photo);
    const selectedMLHardExudatesValue = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_hard_exudates_value);
    const selectedMLHardExudatesSucceeded = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_ml_hard_exudates_succeeded);
    const selectedExamDate = useCare1AppSelector(store => store.aiInsightsHistoricalAnalysis.selected_exam_date);

    const dispatch = useCare1AppDispatch();

    const handleModalClose = () => {
        dispatch(closeSegmentationModal());
    };
    
    const disclaimer = 'Disclaimer: AI Retina scans run on Optomap images may see worse results.'

    return (
        <Modal
            className='ai-insights-modal'
            open={showModal}
            closable={true}
            footer={null}
            keyboard={true}
            onCancel={handleModalClose}
        >
        <Row className='ai-insights'>
            <Col span={24}>
                <div className='ai-insights-content'>
                <Row>
                    <Col span={24}>
                        <AdminRetinaScan                
                            viewMode={'2-view'}             
                            fundusPhoto={selectedFundusPhoto} 
                            useLargeHemePigmentPhoto={useLargeHemePigmentPhoto}
                            mlLargeHemePigmentOverlayPhoto={selectedMLLargeHemePigmentOverlayPhoto}
                            mlLargeHemePigmentValue={selectedMLLargeHemePigmentValue}
                            mlLargeHemePigmentSucceeded={selectedMLLargeHemePigmentSucceeded}
                            useMicroAneurysmsPhoto={useMicroAneurysmsPhoto}
                            mlMicroAneurysmsOverlayPhoto={selectedMLMicroAneurysmsOverlayPhoto}
                            mlMicroAneurysmsValue={selectedMLMicroAneurysmsValue}
                            mlMicroAneurysmsSucceeded={selectedMLMicroAneurysmsSucceeded}
                            useHardExudatesPhoto={useHardExudatesPhoto}
                            mlHardExudatesOverlayPhoto={selectedMLHardExudatesOverlayPhoto}
                            mlHardExudatesValue={selectedMLHardExudatesValue}
                            mlHardExudatesSucceeded={selectedMLHardExudatesSucceeded}
                            title={'FUNDUS'} 
                            titleTooltip={''}
                            examDate={dayjs(selectedExamDate).format('D MMM YYYY')} 
                        />
                    </Col>
                </Row>  
                <Row>
                    <Col span={24} className='disclaimer'>{disclaimer}</Col>
                </Row>
            </div>                  
            </Col>
        </Row>
        </Modal>
    );

}

export default AdminAIInsightsModal;
