import React from 'react';
import { Table, Row, Col } from 'antd';
import { getIGPNameByID } from '../../helpers/doctor-convert';
import '../../../static/css/shared/patient-list-table.scss';
import '../../../static/css/components/admin-igp-status-table.scss';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import { ColumnsType } from 'antd/es/table';
import { IIGPStatusListItem } from '../../reducers/admin-review-lists-slice';
import { localizedText } from '../../localizedText';

const IGPStatusTable = () => {
    const { ADMIN_REVIEWED_TOTAL, ADMIN_READY_TOTAL, ADMIN_IGP_TEXT, 
        ADMIN_IGP_STATUS, ADMIN_REFERRED_PAST } = localizedText;
    const tableList = useCare1AppSelector(store => store.adminReviewLists.igpStatusList);
    const igps = useCare1AppSelector(store => store.igpList.list);
    const igpStatusLoading = useCare1AppSelector(store => store.adminReviewLists.igpStatusLoading);

    interface IGPStatusTable extends IIGPStatusListItem {
        key: React.Key,
    }

    const columns : ColumnsType<IGPStatusTable> =
        [
            {
                className: 'igp category',
                title: ADMIN_IGP_TEXT,
                dataIndex: 'igp',
                render: (text) => {
                    return getIGPNameByID(Number(text), igps);
                }
            }, {
                className: 'ready-total category',
                title: ADMIN_READY_TOTAL,
                dataIndex: 'readyTotal',
                render: (text) => text || 0
            }, {
                className: 'reviewed-total category',
                title: ADMIN_REVIEWED_TOTAL,
                dataIndex: 'reviewedTotal',
                render: (text) => text ? (<span className='color-red'>{text}</span>) : 0
            }, {
                className: 'referred-total category',
                title: ADMIN_REFERRED_PAST,
                dataIndex: 'referredTotal',
                render: (text) => text || 0,
            }
        ]

    return (
        <div className={`patient-list-table igp-status`}>
            <div className="igp-status-table  patient-list-table-omdr">
                <div className="igp-status-table-title" data-testid='igp-status-table-title'>
                    {ADMIN_IGP_STATUS}
                </div>
                <Row>
                    <Col span={24}>
                        <Table
                            size='small'
                            columns={columns}
                            dataSource={tableList.map((item, index) => {
                                return { ...item, key: index };
                            })}
                            pagination={false}
                            loading={igpStatusLoading}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default IGPStatusTable;