import React, { useState } from 'react';
import { Layout, Tabs } from 'antd';


import NavigationControlBar from '../components/navigation-control-bar';

import '../../static/css/pages/performance-metrics-page.scss';
import LatencyMetricsPage from './latency-metrics-page';

const { Header, Content } = Layout;

const PerformanceMetricsPage: React.FC = () => {
    
    const defaultTab = 'item-latencymetricspage';

    const [activeKey, setActiveKey] = useState(defaultTab);

    const getLatencyTab = () => {
        return <LatencyMetricsPage />
    }

    const tabs = [{
        label: 'Latency', key: 'item-latencymetricspage',
        children: getLatencyTab()
    }];

    return (
        <Layout className='performance-metrics-page'>
            <Header>
                <NavigationControlBar
                    className="patient-list-navigation-control-bar"
                    breadcrumbItems={[]}
                />
            </Header>
            <Content>
                <div>
                    <Tabs
                        defaultActiveKey={defaultTab}
                        activeKey={activeKey}
                        items={tabs}
                        onTabClick={(key: string, e: React.KeyboardEvent | React.MouseEvent) => {
                            setActiveKey(key);
                        }}
                    />
                </div>
            </Content>
        </Layout>
    );
}

export default PerformanceMetricsPage;