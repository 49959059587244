import { useEffect, useState } from 'react';
import { Table, Modal } from 'antd';
import * as Constants from '../../constants';
import '../../../static/css/components/patient-list-table-omdc.scss';
import '../../../static/css/shared/patient-list-table.scss';

// Component imports
import LatestVisitButton from '../retina/button-latest-visit';
import PatientDetailsModal from '../retina/patient-details-modal';

// Action imports
import { getOMDCPatientListRequest, IOMDCPatientListItem, setPatientListFieldData
    } from '../../reducers/patient-list-slice';

// Helper imports
import { compareStrings, sortDates } from '../../helpers/sorting';
import { getOptionsRequest } from '../../reducers/options-slice';
import { clearPatientDetailsData, getPatientDetailsRequest, openPatientDetailsModal } from '../../reducers/patient-details-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { ColumnsType } from 'antd/es/table';
import { localizedText } from '../../localizedText';
import { toggleNotAuthorizedForExam } from '../../reducers/user-slice';

const PatientListTableOMDC = () => {
    const { FIRST_NAME_HEADING, LAST_NAME_HEADING, OF_TEXT, ITEMS_TEXT, DOB_HEADING, EXAM_DATE_TEXT,
        DOCTOR_HEADING, CHIEF_COMPLAINT_HEADING, NUMBER_VISITS_HEADING, EXAM_HEADING, 
        LATEST_VISIT_TEXT, OMDC_STATUS_TEXT } = localizedText;

    const tableList = useCare1AppSelector(store => store.patientList.omdcTableList);
    const showDetails = useCare1AppSelector(store => store.patientDetails.visible);
    const notAuthorizedForExam = useCare1AppSelector(store => store.user.notAuthorizedForExam);
    const dispatch = useCare1AppDispatch();

    const [scrollClass, setScrollClass] = useState('');

    // only runs when component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // Set the default filter values
        dispatch(setPatientListFieldData({ key: 'omdcStatus', value: 'ready' }));
        dispatch(setPatientListFieldData({ key: 'examPeriod', value:'all_time' }));
        dispatch(setPatientListFieldData({ key: 'searchQuery', value: '' }));

        // Make requests
        dispatch(getOptionsRequest());
        dispatch(getOMDCPatientListRequest());

        if (notAuthorizedForExam) {
            Modal.info({
                className: 'info-modal',
                title: Constants.UNAUTHORIZED_MODAL_TITLE,
                content: Constants.UNAUTHORIZED_MODAL_CONTENT,
                onOk: () => {
                    dispatch(toggleNotAuthorizedForExam(false));
                },
            });
        }
        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onTableRowClick = (record: IOMDCPatientListItem) => {
        dispatch(clearPatientDetailsData());
        dispatch(getPatientDetailsRequest(record.key))
            .then(() => dispatch(openPatientDetailsModal()));
    }

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll')
        } else {
            setScrollClass('')
        }
    }

    const columns: ColumnsType<IOMDCPatientListItem> = [{
        className: 'omdc-status-column',
        title: OMDC_STATUS_TEXT,
        dataIndex: 'omdc_status',
        sorter: (a, b) => compareStrings(a.omdc_status, b.omdc_status),
        render: (key) => {
            const omdcStatus = Constants.OMDC_STATUS_TYPES.find((entry) => entry.key === key);
            return omdcStatus ? omdcStatus.value : '';
        }
    },{
        className: 'last-name-column',
        title: LAST_NAME_HEADING,
        dataIndex: 'last_name',
        sorter: (a, b) => compareStrings(a.last_name, b.last_name),
    }, {
        className: 'first-name-column',
        title: FIRST_NAME_HEADING,
        dataIndex: 'first_name',
        sorter: (a, b) => compareStrings(a.first_name, b.first_name),
    }, {
        className: 'dob-column',
        title: DOB_HEADING,
        dataIndex: 'dob',
        sorter: (a, b) => compareStrings(a.dob, b.dob),
    }, {
        className: 'doctor-column',
        title: DOCTOR_HEADING,
        dataIndex: 'od',
        sorter: (a, b) => compareStrings(a.od, b.od),
    }, {
        className: 'complaint-column',
        title: CHIEF_COMPLAINT_HEADING,
        dataIndex: 'chief_complaint',
        sorter: (a, b) => compareStrings(a.chief_complaint, b.chief_complaint),
    }, {
        className: 'exam-date-column',
        title: EXAM_DATE_TEXT,
        dataIndex: 'exam_date',
        sorter: (a, b) => {
            const firstDate = a.exam_date ? a.exam_date : '';
            const secondDate = b.exam_date ? b.exam_date : '';
            return sortDates(firstDate, secondDate);
        },
    }, {
        className: 'visits-column',
        title: NUMBER_VISITS_HEADING,
        dataIndex: 'visits',
        sorter: (a, b) => a.visits - b.visits,
    }, {
        className: 'latest-visit-column',
        title: EXAM_HEADING,
        dataIndex: 'latest_exam_id',
        render: (text) => {
            if (text) {
                return <LatestVisitButton className="button-ready-consult" buttonText={LATEST_VISIT_TEXT}
                    visitID={text} newTab={true} />;
            }
        },
    }];

    return (
        <div className={'patient-list-table patient-list-table-omdc'}>
            <div className={scrollClass}>
                <Table
                    columns={columns}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 30,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    dataSource={tableList}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                onTableRowClick(record);
                            },
                        };
                    }}
                />
                {showDetails && <PatientDetailsModal />}
            </div>
        </div>
    );
}

export default PatientListTableOMDC;
