import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { AppDispatch, RootState } from "../stores/retina-enabled-store";
import { OMD_NOTE_TEMPLATE_DICT } from '../constants';
import { patientIsGlaucoma } from '../helpers/diagnosis-convert';
import { getCsrfToken, isStringArrayEqual } from '../helpers/utilities';
import { apiRequest } from '../services/api-request';
import { logout } from './user-slice';
import { IIopHistoryItem, setExamDataValue } from './patient-exam-slice';
import { isIopHistoryNoneSelected, isRluIopHistoryEmpty, isRluIopHistoryEntryEmpty, isRluUntilYesterdayEmpty, isRluUntilYesterdayEntryEmpty } from '../helpers/patient-exam-convert';

export interface IPreReview {
    examCarryover: string[];
    overrideActions: string[];
    prereviewButtonActions: string[];
    saveActions: string[];
    isDirty: boolean;
    status: string;
    error: string;
}

const initialState: IPreReview = {
    examCarryover: [],
    overrideActions: [],
    prereviewButtonActions: [],
    saveActions: [],
    isDirty: false,
    status: 'idle',
    error: '',
};


/**
 * Add/Remove an id from selected options based on optionChecked value
 * 
 * @param options  the selected options
 * @param id the id that needs to be added or removed
 * @param optionChecked if checked, add id to options, otherwise, remove id from options
 * @return updated selected options
 */
 const updateOmdNoteOptions = (options: number[], id: number, optionChecked: boolean) : number[] => {
    // remove the id first incase it's already in selected omd note options
    const cleanedOptions = options.filter(option => option !== id);
    return optionChecked ? cleanedOptions.concat(id): cleanedOptions;
}

/* 
 * Pre-review the iop history section
 */
export const preReviewIopHistory = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { examData: {iop_history, until_yesterday, od_iop, os_iop} } = getState();

    // If the None checkbox is selected in the IOP History component, that do not run any of the logic below.
    if (isIopHistoryNoneSelected(iop_history)) {
        return;
        // Until yesterday is required, either None checkbox is selected or a valid entry is added
    } else if (isRluUntilYesterdayEmpty(until_yesterday)) {
        return;
    } else {
        // remove empty entry of until yesterday
        const untilYesterday = until_yesterday.values.filter(entry => !isRluUntilYesterdayEntryEmpty(entry));
        if (isRluIopHistoryEmpty(iop_history)){
            // when until yesterday none checkbox is selected and iop history is empty
            if (untilYesterday && untilYesterday[0] && untilYesterday[0].disabled) {
                const iopValues: IIopHistoryItem[] = []
                // only add an entry when OD/OS IOP is not empty
                if (od_iop) {
                    iopValues.push({
                        low_iop: Number(od_iop),
                        high_iop: '',
                        iop_history_drops_select: ['off_gtts'],
                        eye_select: 'od',
                    })
                }
                if (os_iop) {
                    iopValues.push({
                        low_iop: Number(os_iop),
                        high_iop: '',
                        iop_history_drops_select: ['off_gtts'],
                        eye_select: 'os',
                    })
                }

                dispatch(setExamDataValue('iop_history', {...iop_history, values: iopValues }));

            } else {
                // when until yesterday none checkbox is not selected
                const iopValues: IIopHistoryItem[] = [];
                if (od_iop) {
                    // 'ou' eye select means both eyes, so when an entry has 'ou' selected, its 
                    // eye drops should be added to the eye drops list
                    const odDrops = untilYesterday.reduce((reducedArray, uyEntry) => {
                        if (uyEntry.glc_past_drops_eye_select === 'od' || uyEntry.glc_past_drops_eye_select === 'ou'){
                            // when eye drop is empty but eye select is not, use 'off_gtts' for eye drops
                            return reducedArray.concat(uyEntry.glc_past_drops_select ? uyEntry.glc_past_drops_select : 'off_gtts')
                        } else {
                            return reducedArray;
                        }
                    }, ([] as string[]));

                    const iopHistory = odDrops.length > 0 ? odDrops : ['off_gtts'];
                    
                    iopValues.push({
                        low_iop: Number(od_iop),
                        high_iop: '',
                        eye_select: 'od',
                        iop_history_drops_select: iopHistory,
                    })  
                }

                if (os_iop) {
                    // 'ou' eye select means both eyes, so when an entry has 'ou' selected, its 
                    // eye drops should be added to the eye drops list
                    const osDrops = untilYesterday.reduce((reducedArray, uyEntry) => {
                        if (uyEntry.glc_past_drops_eye_select === 'os' || uyEntry.glc_past_drops_eye_select === 'ou'){
                            // when eye drop is empty but eye select is not, use 'off_gtts' for eye drops
                            return reducedArray.concat(uyEntry.glc_past_drops_select ? uyEntry.glc_past_drops_select : 'off_gtts')
                        } else {
                            return reducedArray;
                        }
                    }, ([] as string[]))

                    const iopHistory = osDrops.length > 0 ? osDrops : ['off_gtts'];
                    iopValues.push({
                        low_iop: Number(os_iop),
                        high_iop: '',
                        eye_select: 'os',
                        iop_history_drops_select: iopHistory,
                    })  
                    
                }

                dispatch(setExamDataValue('iop_history', {...iop_history, values: [...iopValues, ...iop_history.values]}));   
            }
        } else {
            // When iop history is not empty
            let iopValues: IIopHistoryItem[] = iop_history.values.filter(entry => !isRluIopHistoryEntryEmpty(entry));
            if (untilYesterday && untilYesterday[0] && untilYesterday[0].disabled) {
                // eye select 'ou' is a special case and needs to be handled differently
                const iopOuIndex = iopValues.findIndex(entry => entry.eye_select === 'ou' && entry.iop_history_drops_select && entry.iop_history_drops_select[0] === 'off_gtts')
                if (iopOuIndex >=0) {
                    // when the current od_iop and os_iop are equal, keep the ou entry
                    if (od_iop && os_iop && Number(od_iop) === Number(os_iop)) {
                        iopValues = iopValues.map((value, innerIndex) => {
                            if (innerIndex === iopOuIndex) {
                                const lowIop = Math.min(Number(value.low_iop), Number(od_iop));
                                const highIop = Math.max(Number(value.low_iop), Number(od_iop), Number(value.high_iop));
                                return {
                                    ...value,
                                    low_iop: lowIop,
                                    high_iop: highIop === lowIop ? '' : highIop,
                                }
                            } else {
                                return value;
                            }
                        })
                    } else if (od_iop && os_iop) {
                        // when the new od_iop and os_iop are not equal, remove the 'ou' entry
                        const iopOuValue = iopValues[iopOuIndex];
                        iopValues = iopValues.filter((entry, index) => index !== iopOuIndex);
                        if (od_iop) {
                            const lowIop = Math.min(Number(od_iop), Number(iopOuValue.low_iop));
                            const highIop = Math.max(Number(od_iop), Number(iopOuValue.low_iop), Number(iopOuValue.high_iop));
                            iopValues.push({
                                low_iop: lowIop,
                                high_iop: highIop === lowIop ? '' : highIop,
                                iop_history_drops_select: iopOuValue.iop_history_drops_select,
                                eye_select: 'ou',
                            })
                        }

                        if (os_iop) {
                            const lowIop = Math.min(Number(os_iop), Number(iopOuValue.low_iop));
                            const highIop = Math.max(Number(os_iop), Number(iopOuValue.low_iop), Number(iopOuValue.high_iop));
                            iopValues.push({
                                low_iop: lowIop,
                                high_iop: highIop === lowIop ? '' : highIop,
                                iop_history_drops_select: iopOuValue.iop_history_drops_select,
                                eye_select: 'os',
                            })
                        } 
                    }
                } else {
                    // when there is no 'ou' entry
                    if (od_iop) {
                        const iopOdIndex = iopValues.findIndex(entry => entry.eye_select === 'od' && entry.iop_history_drops_select && entry.iop_history_drops_select[0] === 'off_gtts');
                        if (iopOdIndex >= 0) {
                            iopValues = iopValues.map((value, innerIndex) => {
                                if (innerIndex === iopOdIndex) {
                                    const lowIop = Math.min(Number(value.low_iop), Number(od_iop));
                                    const highIop = Math.max(Number(value.low_iop), Number(od_iop), Number(value.high_iop));
                                    return {
                                        ...value,
                                        low_iop: lowIop,
                                        high_iop: highIop === lowIop ? '' : highIop,
                                    }
                                } else {
                                    return value
                                }
                            })
                        } else if (iopOdIndex === -1) {
                            iopValues.push({
                                low_iop: Number(od_iop),
                                high_iop: '',
                                iop_history_drops_select: ['off_gtts'],
                                eye_select: 'od',
                            })
                        }
                    }
    
                    if (os_iop) {
                        const iopOsIndex = iopValues.findIndex(entry => entry.eye_select === 'os' && entry.iop_history_drops_select && entry.iop_history_drops_select[0] === 'off_gtts');
                        if (iopOsIndex >= 0) {
                            iopValues = iopValues.map((value, innerIndex) => {
                                if (innerIndex === iopOsIndex) {
                                    const lowIop = Math.min(Number(value.low_iop), Number(os_iop));
                                    const highIop = Math.max(Number(value.low_iop), Number(os_iop), Number(value.high_iop));
                                    return {
                                        ...value,
                                        low_iop: lowIop,
                                        high_iop: highIop === lowIop ? '' : highIop,
                                    }
                                } else {
                                    return value
                                }
                            })
                        } else if (iopOsIndex === -1) {
                            iopValues.push({
                                low_iop: Number(os_iop),
                                high_iop: '',
                                iop_history_drops_select: ['off_gtts'],
                                eye_select: 'os',
                            })
                        }
                    }
                }
            } else {
                untilYesterday.forEach(uy => {
                    if (uy.glc_past_drops_eye_select === 'ou'){
                        const iopOuIndex = iopValues.findIndex(entry => entry.eye_select === 'ou' &&
                            entry.iop_history_drops_select && (isStringArrayEqual(entry.iop_history_drops_select,
                            (uy.glc_past_drops_select ? [uy.glc_past_drops_select] : []))));

                        if (iopOuIndex >=0) {
                            if (od_iop && os_iop && Number(od_iop) === Number(os_iop)) {
                                iopValues = iopValues.map((value, innerIndex) => {
                                    if (innerIndex === iopOuIndex) {
                                        const lowIop = Math.min(Number(value.low_iop), Number(od_iop));
                                        const highIop = Math.max(Number(value.low_iop), Number(od_iop), Number(value.high_iop));
                                        return {
                                            ...value,
                                            low_iop: lowIop,
                                            high_iop: highIop === lowIop ? '' : highIop,
                                        }
                                    } else {
                                        return value;
                                    }
                                })
                            } else if (od_iop && os_iop) {
                                // when the new od_iop and os_iop are not equal, remove the 'ou' entry
                                const iopOuValue = iopValues[iopOuIndex];
                                iopValues = iopValues.filter((entry, index) => index !== iopOuIndex);
                                if (od_iop) {
                                    const lowIop = Math.min(Number(od_iop), Number(iopOuValue.low_iop));
                                    const highIop = Math.max(Number(od_iop), Number(iopOuValue.low_iop), Number(iopOuValue.high_iop));
                                    iopValues.push({
                                        low_iop: lowIop,
                                        high_iop: highIop === lowIop ? '' : highIop,
                                        iop_history_drops_select: iopOuValue.iop_history_drops_select,
                                        eye_select: 'ou',
                                    })
                                }

                                if (os_iop) {
                                    const lowIop = Math.min(Number(os_iop), Number(iopOuValue.low_iop));
                                    const highIop = Math.max(Number(os_iop), Number(iopOuValue.low_iop), Number(iopOuValue.high_iop));
                                    iopValues.push({
                                        low_iop: lowIop,
                                        high_iop: highIop === lowIop ? '' : highIop,
                                        iop_history_drops_select: iopOuValue.iop_history_drops_select,
                                        eye_select: 'os',
                                    })
                                } 
                            }

                        }
                        // Also, if the "IOP History" list contains values for both OS and OD together, that is also a match to
                        //  the OU selection in past drops. So if OU is not found, we still need to look for OS and OD together.
                        else {
                            const iopOdIndex = iopValues.findIndex(entry => entry.eye_select === 'od' &&
                            entry.iop_history_drops_select && (isStringArrayEqual(entry.iop_history_drops_select,
                            (uy.glc_past_drops_select ? [uy.glc_past_drops_select] : []))));

                            const iopOsIndex = iopValues.findIndex(entry => entry.eye_select === 'os' && 
                            entry.iop_history_drops_select && (isStringArrayEqual(entry.iop_history_drops_select,
                            (uy.glc_past_drops_select ? [uy.glc_past_drops_select] : []))));

                            if (iopOdIndex >= 0 && iopOsIndex >= 0 && Math.abs(iopOdIndex - iopOsIndex) === 1) {
                                // If the OD and OS are next to each other, that means this is OU.
                                // Replace both OD and OS values with the latest ones.
                                if (od_iop) {
                                    iopValues = iopValues.map((value, innerIndex) => {
                                        if (innerIndex === iopOdIndex) {
                                            const lowIop = Math.min(Number(value.low_iop), Number(od_iop));
                                            const highIop = Math.max(Number(value.low_iop), Number(od_iop), Number(value.high_iop));
                                            return {
                                                ...value,
                                                low_iop: lowIop,
                                                high_iop: highIop === lowIop ? '' : highIop,
                                            }
                                        } else {
                                            return value
                                        }
                                    })
                                }

                                if (os_iop) {
                                    iopValues = iopValues.map((value, innerIndex) => {
                                        if (innerIndex === iopOsIndex) {
                                            const lowIop = Math.min(Number(value.low_iop), Number(os_iop));
                                            const highIop = Math.max(Number(value.low_iop), Number(os_iop), Number(value.high_iop));
                                            return {
                                                ...value,
                                                low_iop: lowIop,
                                                high_iop: highIop === lowIop ? '' : highIop,
                                            }
                                        } else {
                                            return value
                                        }
                                    })
                                }
                            }
                        }

                    } else {
                        if (od_iop && uy.glc_past_drops_eye_select === 'od') {
                            const iopOdIndex = iopValues.findIndex(
                                entry => entry.eye_select === 'od' && entry.iop_history_drops_select &&
                                (isStringArrayEqual(entry.iop_history_drops_select,
                                    (uy.glc_past_drops_select ? [uy.glc_past_drops_select] : []))));
                            if (iopOdIndex >= 0) {
                                iopValues = iopValues.map((value, innerIndex) => {
                                    if (innerIndex === iopOdIndex) {
                                        const lowIop = Math.min(Number(value.low_iop), Number(od_iop));
                                        const highIop = Math.max(Number(value.low_iop), Number(od_iop), Number(value.high_iop));
                                        return {
                                            ...value,
                                            low_iop: lowIop,
                                            high_iop: highIop === lowIop ? '' : highIop,
                                        }
                                    } else {
                                        return value
                                    }
                                })
                            } else if (iopOdIndex === -1) {
                                iopValues.push({
                                    low_iop: Number(od_iop),
                                    high_iop: '',
                                    iop_history_drops_select: [uy.glc_past_drops_select as string],
                                    eye_select: 'od',
                                })
                            }
                        }
    
                        if (os_iop && uy.glc_past_drops_eye_select === 'os') {
                            const iopOsIndex = iopValues.findIndex(
                                entry => entry.eye_select === 'os' && entry.iop_history_drops_select &&
                                (isStringArrayEqual(entry.iop_history_drops_select,
                                    (uy.glc_past_drops_select ? [uy.glc_past_drops_select] : []))));
                            if (iopOsIndex >= 0) {
                                iopValues = iopValues.map((value, innerIndex) => {
                                    if (innerIndex === iopOsIndex) {
                                        const lowIop = Math.min(Number(value.low_iop), Number(os_iop));
                                        const highIop = Math.max(Number(value.low_iop), Number(os_iop), Number(value.high_iop));
                                        return {
                                            ...value,
                                            low_iop: lowIop,
                                            high_iop: highIop === lowIop ? '' : highIop,
                                        }
                                    } else {
                                        return value
                                    }
                                })
                            } else if (iopOsIndex === -1) {
                                iopValues.push({
                                    low_iop: Number(os_iop),
                                    high_iop: '',
                                    iop_history_drops_select: [uy.glc_past_drops_select as string],
                                    eye_select: 'os',
                                })
                            }
                        }
                    }   
                })
            }
            
            dispatch(setExamDataValue('iop_history', {...iop_history, values: iopValues}));
        }
    }
}

export const removeIopInstrumentData = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { examData: {iop_history} } = getState();

    if (isIopHistoryNoneSelected(iop_history)) {
        return;
    } else {
        if (iop_history && Array.isArray(iop_history.values)) {
            const newIopHistoryValues = iop_history.values.map(({low_iop, high_iop, eye_select, iop_history_drops_select}) => ({
                low_iop,
                high_iop,
                eye_select,
                iop_history_drops_select,
            }));
            dispatch(setExamDataValue('iop_history', {...iop_history, values: newIopHistoryValues}));
        }
    }
}

/* 
 * Pre-review assign first value
 */
export const setAssignFirstValue = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { examData: {fu_letter, fu_number, od_question, ipc, prereview_alert , od_iop, os_iop,
        exam_od_orientating, is_urgent, assign_first_admin_override, is_fu_unknown, is_outbound_referral} } = getState();
    
    if (assign_first_admin_override !== undefined) {
        return;
    } else {
        let assignFirstValue = false;

        const hasMultipleReady = (prereview_alert !== null && Array.isArray(prereview_alert) && 
            prereview_alert.some(alert => alert.toLowerCase() === 'multiple visits waiting for review'));
            
        if (!is_fu_unknown) {
            if (fu_letter === 'week' && Number(fu_number) <= 8) {
                assignFirstValue = true;
            } else if (fu_letter === 'month' && Number(fu_number) <= 2) {
                assignFirstValue = true;
            }
        }

        if (od_question || ipc === 'requested' || hasMultipleReady || exam_od_orientating) {
            assignFirstValue = true;
        }

        if ((od_iop && Number(od_iop) >= 25) || (os_iop && Number(os_iop) >= 25)) {
            assignFirstValue = true;
        }

        if (ipc === '' && is_urgent) {
            assignFirstValue = true;
        }
        if (is_outbound_referral) {
            // assign 1st should always be false for outbound referral exam
            dispatch(setExamDataValue('assign_first', false));
            dispatch(setExamDataValue('assign_first_admin_override', false));
        } else {
            dispatch(setExamDataValue('assign_first', assignFirstValue));
        }
    }
}

export const preReviewExam = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const {
        patientDetails: {details: { gp_number, gp_fax_number, age, gp2_number, has_no_gp }},
        examData: {selected_omd_note_options, cu_omd, is_gp_letter_sent_date_older_than_11_months,
            gp_letter_sent_date, od_is_integrated, fu_number, rr_glc: glc, rr_glc_suspect: glcS, rr_dm, rr_amd,
            rr_erm, rr_narrow_angles: narrowAngles, rr_cataract, od_bcva, os_bcva, fu_letter, od_question,
            od_baseline, od_req_omd, od_start_drops, exam_boct, exam_bfield, od_iop, os_iop, glc_type,
            visit_number, prev_exams_have_glc_base, od_cd, os_cd, rr_optic_nerve, last_omd_gp, omd_gp,
            omd_textbox0, last_exam_omd_textbox0, last_omd_od, exam_status, right_octav, right_octav_sup,
            right_octav_inf,  left_octav, left_octav_sup, left_octav_inf, initials_select, cu_omd_admin_override,
            omd_history, is_referral_letter_upload_pei, is_od_messaging_submission, is_outbound_referral,
            od_wants_omd_report
         },
        diagnosis: { entries },
    } = getState();
    const { teleFuId, gpLetterId, gpInfoId, omdFuId, octId, octVfId, noSurgId, catTmId, glcBaseId,
        alertId, dmId, agreeId, odReplyId } = OMD_NOTE_TEMPLATE_DICT;

    if (exam_status !== 'ready') {
        Modal.error({
            className: 'info-modal',
            title: 'Can only run a Pre-review on charts set to "Ready for OMD".',
        });
        return;
    }
    // Create prereview timestamp.
    dispatch(setPreReviewTimestampRequest());

    // Read VF Pattern Deviation Graph and evaluate PDmA and CIGTS
    dispatch(readVfPatternDeviationRequest());

    //Check "Pre-Reviewed" checkbox for all Pre-review
    dispatch(setExamDataValue('is_pre_reviewed', true));
    // automatically check avg OCT RNFL if both SUP and INF RNFL exists but average does now
    if (right_octav === '' && right_octav_sup !=='' && right_octav_inf !=='') {
        if (right_octav_sup === 'red' || right_octav_inf === 'red') {
            dispatch(setExamDataValue('right_octav', 'red'));
            dispatch(setExamDataValue('right_oct_autoset', true));
        } else if (right_octav_sup === 'yellow' || right_octav_inf === 'yellow') {
            dispatch(setExamDataValue('right_octav', 'yellow'));
            dispatch(setExamDataValue('right_oct_autoset', true));
        } else if (right_octav_sup === 'green' || right_octav_inf === 'green') {
            dispatch(setExamDataValue('right_octav', 'green'));
            dispatch(setExamDataValue('right_oct_autoset', true));
        }
    }
    if (left_octav === '' && left_octav_sup !=='' && left_octav_inf !=='') {
        if (left_octav_sup === 'red' || left_octav_inf === 'red') {
            dispatch(setExamDataValue('left_octav', 'red'));
            dispatch(setExamDataValue('left_oct_autoset', true));
        } else if (left_octav_sup === 'yellow' || left_octav_inf === 'yellow') {
            dispatch(setExamDataValue('left_octav', 'yellow'));
            dispatch(setExamDataValue('left_oct_autoset', true));
        } else if (left_octav_sup === 'green' || left_octav_inf === 'green') {
            dispatch(setExamDataValue('left_octav', 'green'));
            dispatch(setExamDataValue('left_oct_autoset', true));
        }
    }
    
    
    // selected_omd_note_options will be modified multiple times in pre-review actions, keep all the
    // changes in local variables and update selected_omd_note_options to Redux store at the end of
    // the funcion
    let teleFuChecked = selected_omd_note_options.includes(teleFuId);
    let gpLetterChecked = selected_omd_note_options.includes(gpLetterId);
    let octChecked = selected_omd_note_options.includes(octId);
    let octVfChecked = selected_omd_note_options.includes(octVfId);
    let noSurgChecked = selected_omd_note_options.includes(noSurgId);
    let catTmChecked = selected_omd_note_options.includes(catTmId);
    let agreeChecked = selected_omd_note_options.includes(agreeId);
    let dmChecked = selected_omd_note_options.includes(dmId);
    let glcBaseChecked = selected_omd_note_options.includes(glcBaseId);
    let omdFuChecked = selected_omd_note_options.includes(omdFuId);
    let alertChecked = selected_omd_note_options.includes(alertId);
    let gpInfoChecked = selected_omd_note_options.includes(gpInfoId);
    let odReplyChecked = selected_omd_note_options.includes(odReplyId);
    let updatedOptions: number[] = selected_omd_note_options;

    let updated_omd_gp = omd_gp;
    const {setPreReviewActions} = preReviewSlice.actions;

    // DM is selected, check OMD checkboxes AGREE, DM, OCT
    if (rr_dm) {
        octChecked = true;
        agreeChecked = true;
        dmChecked = true;
        dispatch(setPreReviewActions(['DM', true]));
        dispatch(setPreReviewActions(['AGREE', true]));
        dispatch(setPreReviewActions(['OCT', true]));
    }

    // AMD is selected, check OMD checkboxes AGREE, OCT
    if (rr_amd) {
        octChecked = true;
        agreeChecked = true;
        dispatch(setPreReviewActions(['AGREE', true]));
        dispatch(setPreReviewActions(['OCT', true]));
    }

    if (rr_cataract) {
        catTmChecked = true;
        dispatch(setPreReviewActions(['CatTM', true]));
    }

    // Check GP LTR and TELE F/U by default, then uncheck them if the conditions don't match.
    gpLetterChecked = teleFuChecked = true;
    dispatch(setPreReviewActions(['*GP LTR*', true]));
    dispatch(setPreReviewActions(['TELE F/U', true]));

    if (rr_erm) {
        octChecked = true;
        if (!agreeChecked) {
            agreeChecked = true;
            dispatch(setPreReviewActions(['AGREE', true]));
        }
        if (!octChecked) {
            octChecked = true;
            dispatch(setPreReviewActions(['OCT', true]));
        }

        //If referral reason is ERM and both eyes 40 or less, check no surg
        const LE_40_ARR = ["20","25","30","40"]
        if(LE_40_ARR.includes(od_bcva) && LE_40_ARR.includes(os_bcva)) {
            noSurgChecked = true;
            dispatch(setPreReviewActions(['NO SURG', true]));
        }
    }

    // if Plaquenil is selected in Chief Complaint, also select OCF/VF
    if (entries.some(entry => entry.value === 'plaquenil')) {
        octVfChecked = true;
    }
    // if patient is a Glaucoma patient
    if (patientIsGlaucoma(entries, {glc, glcS, narrowAngles })) {
         // Set the OD Baseline checkbox if the F/U number is below 6 months.
        if (fu_number) {
            if ((fu_letter === 'week' && Number(fu_number) < 24) || (fu_letter === 'month' && Number(fu_number) < 6) ) {
                dispatch(setExamDataValue('od_baseline', true));
                dispatch(setPreReviewActions(['OD Baseline', true]));
            }
        }


        // Glc or Glc suspect is selected, check OMD checkboxes *GP LTR*, TELE F/U, OCT/VF
        octVfChecked = true;
        dispatch(setPreReviewActions(['OCT/VF', true]));
        if (octChecked) {
            //if OCT/VF is already checked, do not need to check OCT
            octChecked = false;
            dispatch(setPreReviewActions(['OCT', false]));
        }
        if (!od_question && !od_start_drops && !od_req_omd) {
            if (exam_boct.match(/G(?=\()/g) != null && exam_boct.match(/G(?=\()/g)?.length === 2) {
                if (exam_bfield.match(/G(?=\()/g) != null && exam_bfield.match(/G(?=\()/g)?.length === 2) {
                    if (od_iop && os_iop && parseFloat(od_iop) < 21 && parseFloat(os_iop) < 21) {
                        dispatch(setPreReviewActions(['AGREE', true]));
                        agreeChecked = true;
                    }
                }
            }
        }
        if (glc_type.includes('green">P')) {
            dispatch(setPreReviewActions(['AGREE', true]));
            agreeChecked = true;
        }
        // For GLC BASE to be checked, there must not be any previous exams that have it checked.
        if (visit_number === 1 && !prev_exams_have_glc_base) {
            if (od_baseline || parseFloat(od_iop) > 25 || parseFloat(os_iop) > 25 || parseFloat(od_cd) >= 0.8 || parseFloat(os_cd) >= 0.8) {
                glcBaseChecked = true;
                dispatch(setPreReviewActions(['GLC BASE', true]));
            }
        }
        if (visit_number !==1) {
            if ( !rr_optic_nerve && !entries.some(entry => entry.value === 'optic_nerve')) {
                const suspicious_idx = last_omd_gp.indexOf("Suspicious ");
                const reassuring_idx = last_omd_gp.indexOf("Reassuring ");
                const suspicious_text = last_omd_gp.substring(suspicious_idx, reassuring_idx);
                const last_omd_gp_without_suspicious_text = last_omd_gp.substring(reassuring_idx);
                const end_idx = last_omd_gp_without_suspicious_text.indexOf(".") + 1;
                const reassuring_text = last_omd_gp_without_suspicious_text.substring(0, end_idx);
                const copied_text = suspicious_text + reassuring_text;

                updated_omd_gp += copied_text.replace(/OCT macula looks clean./ig, '');
                dispatch(addPreReviewAction(`Adding text ${copied_text} to OMDR textbox 2`));
            }
        }
        
    }
    //always copy over omdr textbox0 from previous exam regardless if referral reason is glc or glc susp or not at all.
    if (visit_number >=1) {
        if (!omd_textbox0) {
            //only copy if omd_textbox 0 is blank
            if (last_exam_omd_textbox0) {
                dispatch(setExamDataValue('omd_textbox0', omd_textbox0 + last_exam_omd_textbox0));
                dispatch(addPreReviewAction(`Copying OMD Textbox0 from most recent visit: ${last_exam_omd_textbox0}`));
            } else {
                if (!rr_optic_nerve && !entries.some(entry => entry.value === 'optic_nerve')) {
                    //if textbox0 from previous exam is empty, previous exam is likely before the cutoff date, then from the previous exam's textbox1,
                    // copy in text from "baseline" to "%)." if it doesn't have %)., then copy till the end.
                    // If they are not found, just don't copy.
                    const begin_idx = last_omd_od.indexOf("Baseline ");
                    const end_idx = last_omd_od.indexOf("%).") + 3;
                    let copied_text = '';
                    if (begin_idx !== -1) {
                        if (end_idx === 3) {
                            copied_text = last_omd_od.substring(begin_idx);
                        } else {
                            copied_text = last_omd_od.substring(begin_idx, end_idx);
                        }
                    }
                    dispatch(setExamDataValue('omd_textbox0', omd_textbox0 + copied_text));
                }
            }
        }
    }

    // if patient's age is less than 18, uncheck *GP LTR*
    if (age < 18) {
        gpLetterChecked = false;
        dispatch(setPreReviewActions(['*GP LTR*', false]));
    }
    //Only check *GP LTR* and ?GP Info if the Last GP Letter sent date is (None) or the date is >11 months between the date it was sent and the current exam visit date.
    // --> not check *GP LTR* if GP Letter sent date is less than 11 months between the date it was sent and the current exam visit date
    if (gp_letter_sent_date) {
        if (!is_gp_letter_sent_date_older_than_11_months) {
            gpLetterChecked = false;
            dispatch(setPreReviewActions(['*GP LTR*', false]));
        }
    }

    if (od_req_omd) {
        omdFuChecked = true;
        dispatch(setPreReviewActions(['OMD F/U', true]));
    }

    if (od_question) {
        alertChecked = true;
        dispatch(setPreReviewActions(['ALERT', true]));
    }

    // When there is nothing in the F/U number textbox and OMD F/U is on, turn off Tele F/U.
    if (!fu_number) {
        if (selected_omd_note_options.includes(omdFuId)) {
            teleFuChecked = false;
            dispatch(setPreReviewActions(['TELE F/U', false]));
        }
    }

    // If there is no fax number (NO FAX) at the top beside GP, then instead of *GP LTR*, ?GP Info
    // should be checked instead.
    if (!gp_number && !gp_fax_number && !gp2_number) {
        gpLetterChecked = false;
        dispatch(setPreReviewActions(['*GP LTR*', false]));
        gpInfoChecked = true;
        dispatch(setPreReviewActions(['?GP Info', true]));
    }

    // Checks off *GP LTR* if OD is not integrated.
    if (!od_is_integrated) {
        gpLetterChecked = false;
        dispatch(setPreReviewActions(['*GP LTR*', false]));
    }

    // if the patient has_no_gp (is true) then these GP Info and GP letter selector should be deselected
    // (if they are selected) and disabled: 
    if (has_no_gp) {
        if (gpInfoChecked) {
            gpInfoChecked = false;
        }
        if (gpLetterChecked) {
            gpLetterChecked = false;
        }
    }

    // if cu_omd is true, uncheck GP LTR and GP INFO
    if (cu_omd_admin_override ?? cu_omd) {
        if (gpLetterChecked) {
            gpLetterChecked = false;
            dispatch(setPreReviewActions(['*GP LTR*', false]));
        }
        if (gpInfoChecked) {
            gpInfoChecked = false;
            dispatch(setPreReviewActions(['?GP Info', false]));
        }
    }

    if (omd_history.values && omd_history.values.length && Object.prototype.hasOwnProperty.call(omd_history.values[0], 'history_omd_name') && omd_history.values[0].history_omd_name) {
        dispatch(setExamDataValue('past_omd', true));
    }

    // For OD messaging submitted exams, GP LTR is never checked, and OD REPLY is always checked.
    if (is_od_messaging_submission) {
        gpLetterChecked = false;
        dispatch(setPreReviewActions(['*GP LTR*', false]));
        odReplyChecked = true;
        dispatch(setPreReviewActions(['OD Reply', false]));
    }
    if(is_outbound_referral) {
        // GP LETTER, OD RQST OMD APPT should always be false
        gpLetterChecked = false;
        dispatch(setExamDataValue('od_req_omd', false));

        // Curt/Upcoming OMD should always be true
        dispatch(setExamDataValue('cu_omd', true));
        dispatch(setExamDataValue('cu_omd_admin_override', true));
    }

    if (od_wants_omd_report === false) {
        gpLetterChecked = false;
    }

    // check or uncheck *GP LTR*
    updatedOptions = updateOmdNoteOptions(updatedOptions, gpLetterId, gpLetterChecked);
    
    // check or uncheck ?GP Info
    updatedOptions = updateOmdNoteOptions(updatedOptions, gpInfoId, gpInfoChecked);

    // check or uncheck Tele F/U
    updatedOptions = updateOmdNoteOptions(updatedOptions, teleFuId, teleFuChecked);

    // check or uncheck OCT
    updatedOptions = updateOmdNoteOptions(updatedOptions, octId, octChecked);

    // check or uncheck OCT/VF
    updatedOptions = updateOmdNoteOptions(updatedOptions, octVfId, octVfChecked);

    // when both OCT/VF and OCT are checked, uncheck OCT
    updatedOptions = octVfChecked && octChecked ?
        updatedOptions.filter(option => option !== octId) : updatedOptions;
    
    // check or uncheck NO SURG
    updatedOptions = updateOmdNoteOptions(updatedOptions, noSurgId, noSurgChecked);

    // check or uncheck CAT TM
    updatedOptions = updateOmdNoteOptions(updatedOptions, catTmId, catTmChecked);

    // check or uncheck GLC BASE
    updatedOptions = updateOmdNoteOptions(updatedOptions, glcBaseId, glcBaseChecked);

    // check or uncheck ALERT
    updatedOptions = updateOmdNoteOptions(updatedOptions, alertId, alertChecked);
    
    // check or uncheck OMD F/U
    updatedOptions = updateOmdNoteOptions(updatedOptions, omdFuId, omdFuChecked);

    // check or uncheck DM
    updatedOptions = updateOmdNoteOptions(updatedOptions, dmId, dmChecked);

    // check or uncheck AGREE
    updatedOptions = updateOmdNoteOptions(updatedOptions, agreeId, agreeChecked);

    // check or uncheck OD REPLY
    updatedOptions = updateOmdNoteOptions(updatedOptions, odReplyId, odReplyChecked);

    dispatch(setExamDataValue('selected_omd_note_options', updatedOptions));
    dispatch(setExamDataValue('omd_gp', updated_omd_gp));

    dispatch(setPreReviewDataValue({key: 'isDirty', value: false}));

    if (is_referral_letter_upload_pei){
        dispatch(preReviewIopHistory());
        dispatch(removeIopInstrumentData())
    }

    dispatch(setAssignFirstValue());
   
    if (!initials_select) {
        Modal.error({
            className: 'info-modal',
            title: 'Please enter pre-reviewer\'s initials.',
        });
    }
}

export const setAutoPreReviewMessageRequest = createAsyncThunk(
    'prereview/setAutoPreReviewMessage',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }, examData: {id}, preReview: { examCarryover, overrideActions, prereviewButtonActions, saveActions} } = getState() as { user: {csrfToken: string}, examData: {id: number}, preReview: {examCarryover: string[], overrideActions: string[], prereviewButtonActions: string[], saveActions: string[]}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const autoValueMessages = {
            examCarryover,
            prereviewerOverride: overrideActions,
            prereview: prereviewButtonActions,
            save: saveActions,
        }
    
        const formData = new FormData();
        formData.append('id', id.toString());
        formData.append('auto_value_messages', JSON.stringify(autoValueMessages));

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/${id}/automessages/`;
        try {
            const response = await apiRequest.post(URL, csrfToken, formData);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const getPreReviewAutoMessageRequest = createAsyncThunk(
    'prereview/getPreReviewAutoMessage',
    async (id: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/${id}/automessages/`;
        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const setPreReviewTimestampRequest = createAsyncThunk(
    'prereview/setPreReviewTimestamp',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }, examData: {id} } = getState() as { user: {csrfToken: string}, examData: {id: number}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/${id}/prereview_timestamp/`;
        try {
            const response = await apiRequest.post(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const readVfPatternDeviationRequest = createAsyncThunk(
    'prereview/readVfPatternDeviation',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }, examData: {id} } = getState() as { user: {csrfToken: string}, examData: {id: number}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/${id}/read_vf_pattern_deviation/`;
        try {
            const response = await apiRequest.post(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const preReviewSlice = createSlice({
    name: 'prereview',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPreReviewDataValue: (state, action: PayloadAction<{key: string, value: boolean | string[] | string}>) => {
            // sample action.payload {key:'memberId', value: '12345'}
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }
        },
        resetPreReview: () => initialState,
        setOverrideActions: (state, action: PayloadAction<[string, boolean]>) => {
            const [buttonName, checked ] = action.payload;
            state.overrideActions = state.overrideActions.filter(
                overrideAction => !overrideAction.includes(buttonName)).concat(
                `'${buttonName}' checkbox ${checked ? 'checked' : 'unchecked'}`);
        },
        setPreReviewActions: (state, action: PayloadAction<[string, boolean]>) => {
            const [buttonName, checked ] = action.payload;
            state.prereviewButtonActions = state.prereviewButtonActions.filter(
                preReviewAction => !preReviewAction.includes(buttonName)).concat(
                `'${buttonName}' checkbox ${checked ? 'checked' : 'unchecked'}`);
        },
        addPreReviewAction: (state, action: PayloadAction<string>) => {
            state.prereviewButtonActions.push(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setPreReviewTimestampRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(setPreReviewTimestampRequest.fulfilled, (state) => {
            state.status = 'success';
        });
        builder.addCase(setPreReviewTimestampRequest.rejected, (state, action) => {
            state.status = 'failed';

            // handle the rejected case, the value was passed from rejecteWithValue
            Modal.error({
                className: 'info-modal',
                title: `Errors setting pre-review timestamp. ${action.payload}`,
            })
        });
        builder.addCase(getPreReviewAutoMessageRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getPreReviewAutoMessageRequest.fulfilled, (state, action) => {
            state.status = 'success';
            try {
                if (action.payload.data) {
                    const data = JSON.parse(action.payload.data);
                    state.examCarryover = data['examCarryover'] ?? [];
                    state.overrideActions = data['prereviewerOverride'] ?? [];
                    state.prereviewButtonActions = data['prereview'] ?? [];
                    state.saveActions = data['save'] ?? [];
                }
            } catch(e) {
                console.log(e);
            } 
        });
        builder.addCase(getPreReviewAutoMessageRequest.rejected, (state, action) => {
            state.status = 'failed';

            // handle the rejected case, the value was passed from rejecteWithValue
            Modal.error({
                className: 'info-modal',
                title: `Errors getting auto pre-review message. ${action.payload}`,
            })
        });
        builder.addCase(setAutoPreReviewMessageRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(setAutoPreReviewMessageRequest.fulfilled, (state) => {
            state.status = 'success';
        });
        builder.addCase(setAutoPreReviewMessageRequest.rejected, (state, action) => {
            state.status = 'failed';

            // handle the rejected case, the value was passed from rejecteWithValue
            Modal.error({
                className: 'info-modal',
                title: `Errors setting auto pre-review message. ${action.payload}`,
            })
        });
        builder.addCase(readVfPatternDeviationRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(readVfPatternDeviationRequest.fulfilled, (state) => {
            state.status = 'success';
        });
        builder.addCase(readVfPatternDeviationRequest.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload as string;
        });
    }
});

export const { setOverrideActions, setPreReviewActions, addPreReviewAction, setPreReviewDataValue } = preReviewSlice.actions;

export default preReviewSlice.reducer;