import { Fragment } from 'react';
import { Card, Col } from 'antd';

// Components
import HistoryImagesViewAllModal from './history-images-view-all-modal';
import HistoryImagesSide from './history-images-side';

// Actions
import { lengthEqual } from '../../reducers/patient-exam-slice';

// Helpers
import { getImageHistoryColWidth, getImageHistoryImagesPerRow, getImageHistoryImagesMaxCels,
    } from '../../helpers/image-histories-convert';
import '../../../static/css/shared/images-history-card.scss';
import '../../../static/css/components/patient-exam-oct-rnfl-history.scss';
import { localizedText  } from '../../localizedText';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import { OCT_RNFL_TEXT } from '../../constants';

const PatientExamOCTRNFLImageHistory = () => {
    const { RIGHT_OCT_RNFL_PHOTO_TITLE_TEXT, LEFT_OCT_RNFL_PHOTO_TITLE_TEXT, 
        BASELINE_TESTING_IMAGE_LABEL } = localizedText;

    const examsWithLeftRNFL = useCare1AppSelector(store => store.examData.exams_with_left_oct_rnfl, lengthEqual);
    const examsWithRightRNFL = useCare1AppSelector(store => store.examData.exams_with_right_oct_rnfl, lengthEqual);

    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);

    // Different platforms have different column widths, images per row and total images displayed
    const COL_WIDTH = getImageHistoryColWidth(userIsRE, userIsOD, userIsOMDC, userIsOMDR, userIsADMIN);
    const IMAGES_PER_ROW = getImageHistoryImagesPerRow(userIsRE, userIsOD, userIsOMDC, userIsOMDR, userIsADMIN);
    const MAX_DISPLAY_CELLS = getImageHistoryImagesMaxCels(userIsRE, userIsOD, userIsOMDC, userIsOMDR, userIsADMIN);

    return (
        <Fragment>
            {/* Left and Right view all Modals */}
            <HistoryImagesViewAllModal
                side={'right'}
                modalTitle={`VIEW ALL OCT RNFL OD`}
                imageType={OCT_RNFL_TEXT}
                photoURL={`right_oct_rnfl_photo`}
                images={[...examsWithRightRNFL]}
                colWidth={COL_WIDTH}
            />

            <HistoryImagesViewAllModal
                side={'left'}
                modalTitle={`VIEW ALL OCT RNFL OS`}
                imageType={OCT_RNFL_TEXT}
                photoURL={`left_oct_rnfl_photo`}
                images={[...examsWithLeftRNFL]}
                colWidth={COL_WIDTH}
            />

            {/* Right side history images */}
            <Col span={12}>
                <Card
                    className={`patient-exam-oct-rnfl-history images-history-card${
                        examsWithRightRNFL.length === 0 && examsWithLeftRNFL.length === 0? ` no-images` : ''}`}
                    bordered={false}
                >
                    <div className="heading image-section-heading">
                        {RIGHT_OCT_RNFL_PHOTO_TITLE_TEXT}
                        <span className='baseline-label'>{BASELINE_TESTING_IMAGE_LABEL}</span>
                    </div>
                    <HistoryImagesSide
                        side={`right`}
                        imageType={OCT_RNFL_TEXT}
                        photoURL={'right_oct_rnfl_photo'}
                        leftImages={[...examsWithLeftRNFL]}
                        rightImages={[...examsWithRightRNFL]}
                        colWidth={COL_WIDTH}
                        imagesPerRow={IMAGES_PER_ROW}
                        maxDisplayCells={MAX_DISPLAY_CELLS}
                        viewAllButtonText={`VIEW ALL OCT RNFL OD`}
                        orderBy='asc'
                        showBaselineBorder={true}
                        showEmptyRow={true}
                    />
                </Card>
            </Col>

            {/* Left side history images */}
            <Col span={12}>
                <Card
                    className={`patient-exam-oct-rnfl-history images-history-card${
                        examsWithRightRNFL.length === 0 && examsWithLeftRNFL.length === 0? ` no-images` : ''}`}
                    bordered={false}
                >
                    <div className="heading image-section-heading">
                        {LEFT_OCT_RNFL_PHOTO_TITLE_TEXT}
                        <span className='baseline-label'>{BASELINE_TESTING_IMAGE_LABEL}</span>
                    </div>
                    <HistoryImagesSide
                        side={`left`}
                        imageType={OCT_RNFL_TEXT}
                        photoURL={'left_oct_rnfl_photo'}
                        leftImages={[...examsWithLeftRNFL]}
                        rightImages={[...examsWithRightRNFL]}
                        colWidth={COL_WIDTH}
                        imagesPerRow={IMAGES_PER_ROW}
                        maxDisplayCells={MAX_DISPLAY_CELLS}
                        viewAllButtonText={`VIEW ALL OCT RNFL OS`}
                        orderBy='asc'
                        showBaselineBorder={true}
                        showEmptyRow={true}
                    />
                </Card>
            </Col>
        </Fragment>
    );
}

export default PatientExamOCTRNFLImageHistory;