import React, { useEffect } from 'react';
import { Layout, Spin } from 'antd';
import { useCare1AppSelector, useCare1AppDispatch } from '../apps/care1-hooks';

import { OPERATING_TEXT } from '../constants';

import NavigationControlBar from '../components/navigation-control-bar';
import DiseaseResourceList from '../components/admin/disease-resource-list';
import DiseaseResourceShareModal from '../components/admin/disease-resource-share-modal';
import HelpDeskModal from '../components/retina/help-desk-modal';

import { getDiseaseResourceList } from '../reducers/disease-resource-slice';
import '../../static/css/pages/disease-resource-page.scss';

const { Header, Content } = Layout;

const ResourcePage: React.FC = () => {

    const dispatch = useCare1AppDispatch();

    const diseaseResourceList = useCare1AppSelector(store => store.diseaseResource.list);

    useEffect(() => {
        dispatch(getDiseaseResourceList());
    }, [])

    return (
        <Layout className='diseaseResourcePage'>
            <Spin
                className="loading-spinner"
                spinning={false}
                size="large"
                tip={OPERATING_TEXT}
            >
                <Header>
                    <NavigationControlBar
                        className="patient-list-navigation-control-bar"
                        breadcrumbItems={[]}
                    />
                </Header>
                <Content>
                    <div className="page-title">Patient Resource Library</div>
                    <DiseaseResourceList listItems={diseaseResourceList} />
                    <DiseaseResourceShareModal />
                    <HelpDeskModal />
                </Content>
            </Spin>
        </Layout>
    );
}

export default ResourcePage;