
export const ItemTypes = {
    IMAGE: 'image',
}

export interface GptResultType {
    right_fundus_photo: number | null;
    left_fundus_photo: number | null;
    right_stereo_photo: number | null;
    left_stereo_photo: number | null;
    right_vf_photo: number | null;
    left_vf_photo: number | null;
    right_oct_rnfl_photo: number | null;
    left_oct_rnfl_photo: number | null;
    right_oct_photo: number | null;
    left_oct_photo: number | null;
    unclassified?: number[] | null;
}