import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCsrfToken } from '../helpers/utilities';
import { API_EDIT_PATIENT_URL, CAF_SERVICE_NUMBER_FIELD, CITY_FIELD, DOB_DAY_FIELD, DOB_FIELD, DOB_MONTH_FIELD, DOB_YEAR_FIELD,
    EMAIL_FIELD, FIRST_NAME_FIELD, GENDER_FIELD, GP2_FAX_NUMBER_FIELD, GP_CURRENT_FIELD, GP_FAX_NUMBER_FIELD, GP_FIELD,
    GP_SEARCH_FIELD, IS_CAF_PERSONNEL_FIELD, IS_ON_OLD_OHIP_FIELD, LAST_NAME_FIELD, PATIENT_ID_FIELD,
    PHN_DEFAULT_LABEL_TEXT, PHN_FIELD, PHN_NAME_FIELD, PHN_VALIDATION_FORMAT_FIELD, PHONE_FIELD,
    PLEASE_CONFIRM_FIELD, POSTAL_CODE_FIELD, PROVINCE_FIELD, SECONDARY_PHN_FIELD,
    SECONDARY_PHN_NAME_FIELD, SECONDARY_PHN_REQUIRED_FIELD, SECONDARY_PHN_VALIDATION_FORMAT_FIELD,
    STREET_FIELD } from '../constants';
import { AppDispatch, RootState } from "../stores/retina-enabled-store";
import { IProvinceOption } from './options-slice';
import { getHealthNumberData } from '../helpers/patient-edit-convert';
import { logout } from './user-slice';
import { apiRequest } from '../services/api-request';
import { closePatientDetailsModal } from './patient-details-slice';
import { buildPatientFromBackendData } from './../helpers/patient-edit-convert';
import { Dayjs } from 'dayjs';

export interface IPatientEdit {
    addModalOpen: boolean;
    editModalOpen: boolean;
    operating: boolean;
    error: string;
    info: string;
    patientDirty: boolean;
    details: IPatientDetail,
    addGPModalOpen: boolean;
    editGPModalOpen: boolean;
    addEditGpField: string;
    patientSelectModalOpen: boolean;
    selectNewExamDateModalOpen: boolean;
    selectedPatientId: string;
    referralLetterNewPatientModalOpen: boolean;
}

export interface IPatientEditResponse {
    address: string;
    caf_personnel: boolean;
    city: string;
    current_gp: number;
    current_gp2: number;
    dob: string;
    email: string;
    fax_number: string;
    first_name: string;
    gender: string;
    gp: string;
    gp2_fax_number: string;
    has_no_phn: boolean;
    id: number;
    is_test_patient: boolean;
    deleted: boolean;
    last_name: string;
    phn: string;
    postal_code: string;
    primary_phone: string;
    province: number;
    secondary_phn: string;
    service_number: string;
    has_no_gp: boolean;
    insurance_type: string;
}

export interface IPatientDetail {
    [PATIENT_ID_FIELD]: number | null;
    [FIRST_NAME_FIELD]: string;
    [LAST_NAME_FIELD]: string;
    [GENDER_FIELD]: string;
    [PHONE_FIELD]: string;
    [EMAIL_FIELD]: string;
    [DOB_FIELD]: Dayjs | string;
    [DOB_DAY_FIELD]: string;
    [DOB_MONTH_FIELD]: string;
    [DOB_YEAR_FIELD]: string;
    [PROVINCE_FIELD]: number | null;
    [PHN_FIELD]: string;
    [PHN_NAME_FIELD]: string;
    [PHN_VALIDATION_FORMAT_FIELD]: string;
    [SECONDARY_PHN_REQUIRED_FIELD]: boolean;
    [SECONDARY_PHN_FIELD]: string;
    [SECONDARY_PHN_NAME_FIELD]: string;
    [SECONDARY_PHN_VALIDATION_FORMAT_FIELD]: string;
    [STREET_FIELD]: string;
    [CITY_FIELD]: string;
    [POSTAL_CODE_FIELD]: string;
    [GP_FIELD]: string;
    [GP_CURRENT_FIELD]: number | null;
    [GP_FAX_NUMBER_FIELD]: string;
    [GP_SEARCH_FIELD]: string;
    [IS_CAF_PERSONNEL_FIELD]: boolean;
    [CAF_SERVICE_NUMBER_FIELD]: string;
    [PLEASE_CONFIRM_FIELD]: boolean;
    ['has_no_phn']: boolean;
    [IS_ON_OLD_OHIP_FIELD]: boolean;
    ['is_test_patient']: boolean;
    ['deleted']: boolean;
    current_gp2: number | null;
    [GP2_FAX_NUMBER_FIELD]: string;
    has_no_gp: boolean;
    insurance_type: string;
}

export interface IHealthNumberData {
    [PHN_NAME_FIELD]: string;
    [SECONDARY_PHN_NAME_FIELD]: string;
    [PHN_VALIDATION_FORMAT_FIELD]: string;
    [SECONDARY_PHN_VALIDATION_FORMAT_FIELD]: string;
    [SECONDARY_PHN_REQUIRED_FIELD]: boolean;
}

export const initialPatientDetails: IPatientDetail = {
    [PATIENT_ID_FIELD]: null,
    [FIRST_NAME_FIELD]: '',
    [LAST_NAME_FIELD]: '',
    [GENDER_FIELD]: '',
    [PHONE_FIELD]: '',
    [EMAIL_FIELD]: '',
    [DOB_FIELD]: '',
    [DOB_DAY_FIELD]: '',
    [DOB_MONTH_FIELD]: '',
    [DOB_YEAR_FIELD]: '',
    [PROVINCE_FIELD]: null,
    [PHN_FIELD]: '',
    [PHN_NAME_FIELD]: PHN_DEFAULT_LABEL_TEXT,
    [PHN_VALIDATION_FORMAT_FIELD]: '',
    [SECONDARY_PHN_REQUIRED_FIELD]: false,
    [SECONDARY_PHN_FIELD]: '',
    [SECONDARY_PHN_NAME_FIELD]: '',
    [SECONDARY_PHN_VALIDATION_FORMAT_FIELD]: '',
    [STREET_FIELD]: '',
    [CITY_FIELD]: '',
    [POSTAL_CODE_FIELD]: '',
    [GP_FIELD]: '',
    [GP_CURRENT_FIELD]: null,
    [GP_FAX_NUMBER_FIELD]: '',
    [GP_SEARCH_FIELD]: '',
    [IS_CAF_PERSONNEL_FIELD]: false,
    [CAF_SERVICE_NUMBER_FIELD]: '',
    [PLEASE_CONFIRM_FIELD]: false,
    has_no_phn: false,
    [IS_ON_OLD_OHIP_FIELD]: false,
    is_test_patient: false,
    deleted: false,
    current_gp2: null,
    [GP2_FAX_NUMBER_FIELD]: '',
    has_no_gp: false,
    insurance_type: '',
}

export const addPatientRequest = createAsyncThunk(
    'patientEditSlice/addPatientRequest',
    async (patientDetail: IPatientDetail, {dispatch, getState, rejectWithValue}) => {
        // rejectWithValue takes the error value so that the errors can be handled
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}, examData: {id: number}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const data = new FormData();
        data.append('patient', JSON.stringify(patientDetail));

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patient/add/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export type EditPatientOmitFields = 'dobDay' | 'dobMonth' | 'dobYear' | 'phn_name' | 'health_id_format' |
    'secondary_phn_required' | 'secondary_phn_name' | 'health_billing_id_format' |'gp' | 'gp_search' | 
    'please_confirm' | 'is_on_old_ohip';

export const editPatientRequest = createAsyncThunk(
    'patientEditSlice/editPatientRequest',
    async (patientDetail: Omit<IPatientDetail, EditPatientOmitFields>, {dispatch, getState, rejectWithValue}) => {
        // rejectWithValue takes the error value so that the errors can be handled
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const data = new FormData();
        data.append('patient', JSON.stringify(patientDetail));

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patient/edit/${patientDetail[PATIENT_ID_FIELD]}`;

        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const deactivatePatientRequest = createAsyncThunk(
    'patientEditSlice/deactivatePatientRequest',
    async (patientId: number, {dispatch, getState, rejectWithValue}) => {
        // rejectWithValue takes the error value so that the errors can be handled
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const data = new FormData();
        data.append('patient', patientId.toString());

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patient/deactivate/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const patientPHNExistsRequest = createAsyncThunk(
    'patientEditSlice/patientPHNExistsRequest',
    async ({patientId, phn, doctor} : {patientId: number | '', phn: string, doctor: number | null},
        {dispatch, getState, rejectWithValue}) => {
        // rejectWithValue takes the error value so that the errors can be handled
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const data = new FormData();
        data.append('patient', patientId.toString());
        data.append('phn', phn);
        data.append('doctor', doctor ? doctor.toString() : '');

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patient/phn_exists/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const getPatientRequest = createAsyncThunk(
    'patientEditSlice/getPatientRequest',
    async (patientId: number, {dispatch, getState, rejectWithValue}) => {
        // rejectWithValue takes the error value so that the errors can be handled
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}${API_EDIT_PATIENT_URL}${patientId}`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const openEditPatientModal = (patientID: number) => async (dispatch: AppDispatch) => {
        const { setPatientData } = patientEditSlice.actions;
        
        dispatch(closePatientDetailsModal());
        const res = await dispatch(getPatientRequest(patientID)).unwrap();
        const patientProvinceID = res[PROVINCE_FIELD];

        dispatch(setPatientData({key: 'editModalOpen', value: true}));
        dispatch(setHealthNumberData(patientProvinceID));
};

export const setHealthNumberData = (provinceId: number) => (dispatch: AppDispatch, getState: () => RootState) => {
    if (provinceId) {
        const {options: {provinces}} = getState() as {options: {provinces: IProvinceOption[]}};
        const healthNumberData: IHealthNumberData = getHealthNumberData(provinceId, provinces);
        dispatch(setPhnData(healthNumberData));
    }
}

export const getEditPatientData = (patientID: number) => async (dispatch: AppDispatch) => {
    const res = await dispatch(getPatientRequest(patientID)).unwrap();
    const patientProvinceID = res[PROVINCE_FIELD];

    dispatch(setHealthNumberData(patientProvinceID));
};

const initialState: IPatientEdit = {
    addModalOpen: false,
    editModalOpen: false,
    operating: false,
    error: '',
    info: '',
    patientDirty: false,
    details: initialPatientDetails,
    addGPModalOpen: false,
    editGPModalOpen: false,
    addEditGpField: '',
    patientSelectModalOpen: false,
    selectNewExamDateModalOpen: false,
    selectedPatientId: '',
    referralLetterNewPatientModalOpen: false,
};

export const patientEditSlice = createSlice({
    name: 'patientEditSlice',
    initialState,
    reducers: {
        clearPatientEditDetails: (state) => {
            return {
                ...state,
                details: initialPatientDetails,
            }
        },
        setPatientEditValue: (state, action: PayloadAction<{field: string, value: string | number | boolean | Date}>) => {
            return {
                ...state,
                details: {
                    ...state.details,
                    [action.payload.field]: action.payload.value,
                }
            }
        },
        setPatientData: (state, action: PayloadAction<{key: string, value: string | boolean}>) => {
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }
        },
        setPhnData: (state, action: PayloadAction<IHealthNumberData>) => {
            state.details[PHN_NAME_FIELD] = action.payload[PHN_NAME_FIELD];
            state.details[PHN_VALIDATION_FORMAT_FIELD] = action.payload[PHN_VALIDATION_FORMAT_FIELD];
            state.details[SECONDARY_PHN_NAME_FIELD] = action.payload[SECONDARY_PHN_NAME_FIELD];
            state.details[SECONDARY_PHN_VALIDATION_FORMAT_FIELD] = action.payload[SECONDARY_PHN_VALIDATION_FORMAT_FIELD];
            state.details[SECONDARY_PHN_REQUIRED_FIELD] = action.payload[SECONDARY_PHN_REQUIRED_FIELD];
        },
        openAddPatientModal: (state) => {
            state.details = initialPatientDetails;
            state.addModalOpen = true;
        },
        closeAddPatientModal: (state) => {
            state.addModalOpen = false;
        },
        closeEditPatientModal: (state) => {
            state.details = initialPatientDetails;
            state.editModalOpen = false;
        },
        openAddGPModal: (state) => {
            state.addGPModalOpen = true;
        },
        closeAddGPModal: (state) => {
            state.addGPModalOpen = false;
        },
        openEditGPModal: (state) => {
            state.editGPModalOpen = true;
        },
        closeEditGPModal: (state) => {
            state.editGPModalOpen = false;
        },
        setAddEditGpFieldData: (state, action) => {
            state.addEditGpField = action.payload;
        },
        openPatientSelectModal: (state) => {
            state.patientSelectModalOpen = true;
        },
        closePatientSelectModal: (state) => {
            state.patientSelectModalOpen = false;
        },
        openNewExamDateModal: (state, action: PayloadAction<string>) => {
            state.selectNewExamDateModalOpen = true;
            state.selectedPatientId = action.payload;
        },
        closeNewExamDateModal: (state) => {
            state.selectNewExamDateModalOpen = false;
        },
        openReferralLetterNewPatientModal: (state) => {
            state.referralLetterNewPatientModalOpen = true;
        },
        closeReferralLetterNewPatientModal: (state) => {
            state.referralLetterNewPatientModalOpen = false;
        },

    },
    extraReducers: (builder) => {
        builder.addCase(addPatientRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(addPatientRequest.fulfilled, (state, action) => {
            state.operating = false;
        });
        builder.addCase(addPatientRequest.rejected, (state, action) => {
            state.operating = false;
            state.error = action.payload as string;
        });
        builder.addCase(editPatientRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(editPatientRequest.fulfilled, (state, action) => {
            state.operating = false;
        });
        builder.addCase(editPatientRequest.rejected, (state, action) => {
            state.operating = false;
            state.error = action.payload as string;
        });
        builder.addCase(deactivatePatientRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(deactivatePatientRequest.fulfilled, (state, action) => {
            state.operating = false;
        });
        builder.addCase(deactivatePatientRequest.rejected, (state, action) => {
            state.operating = false;
            state.error = action.payload as string;
        });
        builder.addCase(patientPHNExistsRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(patientPHNExistsRequest.fulfilled, (state, action) => {
            state.operating = false;
        });
        builder.addCase(patientPHNExistsRequest.rejected, (state, action) => {
            state.operating = false;
            state.error = action.payload as string;
        });
        builder.addCase(getPatientRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(getPatientRequest.fulfilled, (state, action: PayloadAction<IPatientEditResponse>) => {
            return {
                ...state,
                operating: false,
                details: {
                    ...state.details,
                    ...buildPatientFromBackendData(action.payload),
                }
            }
        });
        builder.addCase(getPatientRequest.rejected, (state, action) => {
            state.operating = false;
            state.error = action.payload as string;
        });
        
    },
});

export const { setPhnData, clearPatientEditDetails, closeAddGPModal, closeAddPatientModal,
    closeEditGPModal, closeEditPatientModal, openAddGPModal, openAddPatientModal, openEditGPModal,
    setAddEditGpFieldData, setPatientData, setPatientEditValue, closePatientSelectModal, openPatientSelectModal,
    closeNewExamDateModal, openNewExamDateModal, closeReferralLetterNewPatientModal, openReferralLetterNewPatientModal
} = patientEditSlice.actions;

export default patientEditSlice.reducer;
