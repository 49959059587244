import { useCallback, useEffect, useState } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Tag, Row } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { localizedText } from '../../localizedText';
import '../../../static/css/components/patient-exam-verbalize.scss';

const { CheckableTag } = Tag;

const PatientExamVerbalize = () => {
    const { VERBALIZE_TEXT } = localizedText;
    const verbalizations = useCare1AppSelector(store => store.txalgo3.verbalizations);
    const omdGp = useCare1AppSelector(store => store.examData.omd_gp);
    const omdOd = useCare1AppSelector(store => store.examData.omd_od);

    const dispatch = useCare1AppDispatch();
    const [verbalizeClicked, setVerbalizeClicked] = useState(false);

    const getVerbalizationShootIn = useCallback(() => {

        let omdGpSentence = '';
        let omdOdSentence = '';
        /* verbalizations is an array of verbalization
        verbalization: Object
            textSentence: String, ****** ((key)) *******, e.g. This patient has C/D ((side_latin))
                asymmetry greater than 0.2.
            verbalization: Object,
                variables? (optional): an array of variable objects e.g. [{side_latin: "OD"}]
                    key: indicate which field in textSentece needs to be replaced
                    value: the actual value to replace the ((key)) in textSentence
                shoot_in: String, only 2 types, 'id_omd_gp' or 'id_omd_od'
                verbalization_id: String
                condition? (optional): String
        */
        verbalizations.forEach((value) => {
            const { textSentence, verbalization: { variables, shoot_in } = {} } = value;

            // verbalization: { variables } = {} makes sure no exception is throwed even backend
            // data does not include verbalization
            let verbalizationSentence = textSentence;
            if (variables) {
                variables.forEach( variable => {
                    const [key, value] = Object.entries(variable)[0];
                    const matchVariable = `((${key}))`;
                    const variableText = value;
                    verbalizationSentence = verbalizationSentence.replace(matchVariable, variableText);
                });
            }
            // all sentence will go into omd_gp(Notes to OMD and GP) field
            if (shoot_in === 'id_omd_od'){
                omdOdSentence += verbalizationSentence;
            } else if (shoot_in === 'id_omd_gp'){
                omdGpSentence += verbalizationSentence;
            }
        });
        return [omdOdSentence, omdGpSentence];
    }, [verbalizations])

    const onChange = (checked: boolean) => {
        const [omdOdSentence, omdGpSentence] = getVerbalizationShootIn();
        if(checked){
            if (!omdOd.includes(omdOdSentence)) {
                dispatch(setExamDataValue('omd_od', omdOd + omdOdSentence));
                setVerbalizeClicked(true);
            }
            if (!omdGp.includes(omdGpSentence)) {
                dispatch(setExamDataValue('omd_gp', omdGp + omdGpSentence));
                setVerbalizeClicked(true);
            }
        } else {
            dispatch(setExamDataValue('omd_od', omdOd.replace(omdOdSentence, '')));
            dispatch(setExamDataValue('omd_gp', omdGp.replace(omdGpSentence, '')));
            setVerbalizeClicked(false);
        }
    }

    useEffect(() => {
        const [omdOdSentence, omdGpSentence] = getVerbalizationShootIn();

        const isGpSentenceInOmdGp = omdGp && omdGp.trim() && omdGpSentence && omdGpSentence.trim() &&
        omdGp.includes(omdGpSentence);
        const isOdSentenceInOmdOd = omdOd && omdOd.trim() && omdOdSentence && omdOdSentence.trim() &&
        omdOd.includes(omdOdSentence)
        if( isGpSentenceInOmdGp || isOdSentenceInOmdOd ){
            setVerbalizeClicked(true);
        }
        return () => {
            setVerbalizeClicked(false);
        }

    }, [omdOd, omdGp, getVerbalizationShootIn])

    return (
        <Card className={'verbalize'} bordered={false}>
            <Row className='verbalize-row'>
                <CheckableTag
                    checked={verbalizeClicked}
                    onChange={checked => onChange(checked)}
                >
                    {VERBALIZE_TEXT}
                </CheckableTag>
            </Row>
        </Card>
    );
}

export default PatientExamVerbalize;