import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Spin, Input, DatePicker, Select } from 'antd';
import '../../../static/css/components/admin-letter-modal.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

// Helpers
import { localizedText } from '../../localizedText';

// Action imports
import { DownloadOutlined, MailOutlined, PrinterOutlined } from '@ant-design/icons';
import { getAdminLetterIpcInfoRequest, getIpcOmdcDataRequest, getLetterRequest, getOmdContactRequest, getOmdListRequest, getRluOmdLetterRequest, sendEmailOrFaxRequest, sendRluOmdEmailOrFaxRequest, setExamIpcOmdcRequest, setLetterDataValue, toggleLetterModal } from '../../reducers/letters-slice';
import { PHONE_MASK_FORMAT } from '../../constants';
import InputMask from 'react-input-mask';

const AdminLetterModal = () => {
    const { OMD_DATE_REQUIRED, OMD_REQUIRED_SET_IPC, IN_PERSON_CONSULT, FAX_TEXT, EMAIL_NOT_VALID, 
        EMAIL_REQUIRED, FAX_NUMBER_NOT_COMPLETE, FAX_REQUIRED, FAX_EMAIL_MESSAGE } = localizedText
    const isVisible = useCare1AppSelector(store => store.letter.letterModalOpen);
    const omdOptions = useCare1AppSelector(store => store.letter.omdList);
    const email = useCare1AppSelector(store => store.letter.email);
    const fax = useCare1AppSelector(store => store.letter.fax);
    const currentLetterLabel = useCare1AppSelector(store => store.letter.currentLetterLabel);
    const ipcOmdc = useCare1AppSelector(store => store.letter.ipcOmdc);
    const ipcDate = useCare1AppSelector(store => store.letter.ipcDate);
    const message = useCare1AppSelector(store => store.letter.message);
    const examId = useCare1AppSelector(store => store.examData.id);
    const status = useCare1AppSelector(store => store.letter.status);
    const actionType = useCare1AppSelector(store => store.letter.actionType);
    const currentLetterType = useCare1AppSelector(store => store.letter.currentLetterType);

    const [isEmailRequired, setIsEmailRequired] = useState(false);
    const [isFaxRequired, setIsFaxRequired] = useState(false);

    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const handleModalClose = () => {
        dispatch(toggleLetterModal());
    };


    const handleClick = (type: string) => {
        if (type === 'email'){
            setIsEmailRequired(true);
            setIsFaxRequired(false);
        } else if (type === 'fax') {
            setIsFaxRequired(true);
            setIsEmailRequired(false);
        } else {
            setIsFaxRequired(false);
            setIsEmailRequired(false);
        }
        dispatch(setLetterDataValue({key: 'actionType', value: type}));
        form.submit();
    };

    const closeButton = (
        <Button
            data-testid='close-button'
            key='close-button'
            onClick={handleModalClose}
            className='close-button'
        >
            Close
        </Button>
    );
    const emailButton = (
        <Button
            data-testid='email-button'
            key='email-button'
            onClick={() => handleClick('email')}
            className='email-button'
            icon={<MailOutlined />}
        >
            Email
        </Button>
    );
    const faxButton = (
        <Button
            data-testid='fax-button'
            key='fax-button'
            onClick={() => handleClick('fax')}
            className='fax-button'
            icon={<PrinterOutlined />}
        >
            Fax
        </Button>
    );
    const downloadButton = (
        <Button
            data-testid='download-button'
            key='download-button'
            onClick={() => handleClick('download')}
            className='download-button'
            icon={<DownloadOutlined />}
        >
            Download
        </Button>
    );

    const onValuesChange = (changedValues : {key: string | number, value: string}) => {
        const [key, value] = Object.entries(changedValues)[0];
        dispatch(setLetterDataValue({key, value}));
    };

    const onFinish = async () => {
        try {
            const result = await dispatch(setExamIpcOmdcRequest()).unwrap();
            if(result && result.success){
                if (currentLetterType === 'rlu_omd') {
                    if (actionType === 'email') {
                        try {
                            const res = await dispatch(sendRluOmdEmailOrFaxRequest('email')).unwrap();
                            if(res?.success){
                                Modal.info({
                                    className: 'info-modal',
                                    title: `Email successfully sent.`,
                                });
                            } else {
                                throw new Error(res?.error);
                            }
                        } catch (error) {
                            const message = (error instanceof Error) ?  error?.message : error;
                            Modal.error({
                                className: 'info-modal',
                                title: `Errors`,
                                content: message as string,
                            })
                        }
                    } else if (actionType === 'fax') {
                        try {
                            const res = await dispatch(sendRluOmdEmailOrFaxRequest('fax')).unwrap();
                            if(res?.success){
                                Modal.info({
                                    className: 'info-modal',
                                    title: `Fax successfully sent.`,
                                });
                            } else {
                                throw new Error(res?.error);
                            }
                        } catch (error) {
                            const message = (error instanceof Error) ?  error?.message : error;
                            Modal.error({
                                className: 'info-modal',
                                title: `Errors`,
                                content: message as string,
                            })
                        }
                    } else if (actionType === 'download') {
                        dispatch(getRluOmdLetterRequest());
                    }

                } else {
                    if (actionType === 'email') {
                        try {
                            const res = await dispatch(sendEmailOrFaxRequest('email')).unwrap();
                            if(res?.success){
                                Modal.info({
                                    className: 'info-modal',
                                    title: `Email successfully sent.`,
                                });
                            } else {
                                throw new Error(res?.error);
                            }
                        } catch (error) {
                            const message = (error instanceof Error) ?  error?.message : error;
                            Modal.error({
                                className: 'info-modal',
                                title: `Errors`,
                                content: message as string,
                            })
                        }
                    } else if (actionType === 'fax') {
                        try {
                            const res = await dispatch(sendEmailOrFaxRequest('fax')).unwrap();
                            if(res?.success){
                                Modal.info({
                                    className: 'info-modal',
                                    title: `Fax successfully sent.`,
                                });
                            } else {
                                throw new Error(res?.error);
                            }
                        } catch (error) {
                            const message = (error instanceof Error) ?  error?.message : error;
                            Modal.error({
                                className: 'info-modal',
                                title: `Errors`,
                                content: message as string,
                            })
                        }
                    } else if (actionType === 'download') {
                        dispatch(getLetterRequest(currentLetterType));
                    }
                }
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    const onFinishFailed = () => {
        dispatch(setLetterDataValue({key: 'actionType', value: ''}));
    };

    const handleOmdChange = (omdId: number) => {
        dispatch(getOmdContactRequest(omdId));
    };

    useEffect(() => {
        if (isVisible) {
            dispatch(getOmdListRequest());
            dispatch(getIpcOmdcDataRequest(examId!));
            dispatch(getAdminLetterIpcInfoRequest(examId!));
        }

    }, [dispatch, isVisible, examId]);

    useEffect(() => {
        form.setFieldsValue({
            email, fax, ipcOmdc, ipcDate, message
        });
    }, [form, email, fax, ipcOmdc, ipcDate, message]);

    return (
        <Modal
            className='admin-letter-modal'
            open={isVisible}
            closable={false}
            title={`${IN_PERSON_CONSULT} (${currentLetterLabel})`}
            footer={[emailButton, faxButton, downloadButton, closeButton]}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <Spin
                className='loading-spinner'
                size='large'
                spinning={status === 'loading'}
            >
                <Form
                    form={form}
                    onValuesChange={onValuesChange}
                    labelCol={{span:5}}
                    wrapperCol={{span: 18}}
                    className='admin-letter-form'
                    colon={false}
                    requiredMark={false}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label='OMD Consultant'
                        name='ipcOmdc'
                        rules={[
                            {required: true,
                             message: OMD_REQUIRED_SET_IPC}
                        ]}
                    >
                        <Select
                            data-testid='ipcOmdc-input'
                            onChange={handleOmdChange}
                        >
                            { omdOptions.map(({key, name}) =>
                                <Select.Option
                                    key={key}
                                    value={key}
                                >
                                    {name}
                                </Select.Option>)

                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        data-testid='referral-date-input'
                        label='Referral Date'
                        name='ipcDate'
                        rules={[
                            {required: true,
                             message: OMD_DATE_REQUIRED}
                        ]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        data-testid='email-input'
                        label='Email'
                        name='email'
                        rules={[{
                            type:'email',
                            message: EMAIL_NOT_VALID,
                        }, {
                            required: isEmailRequired,
                            message: EMAIL_REQUIRED,
                            validateTrigger: 'onSubmit',
                        }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        data-testid='fax-input'
                        label={FAX_TEXT}
                        name='fax'
                        rules={[{
                            pattern: /^[^_]+$/,
                            message: FAX_NUMBER_NOT_COMPLETE,
                        }, {
                            required: isFaxRequired,
                            message: FAX_REQUIRED,
                            validateTrigger: 'onSubmit',
                        }]}
                    >
                        <InputMask
                            className={'phone-number-input ant-input'}
                            mask={PHONE_MASK_FORMAT}
                        />
                    </Form.Item>
                    <Form.Item
                        data-testid='message-input'
                        label={FAX_EMAIL_MESSAGE}
                        name='message'
                    >
                        <Input.TextArea rows={5} />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );

}

export default AdminLetterModal;
