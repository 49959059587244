import { FullscreenOutlined } from '@ant-design/icons';
import { Card, Col, Modal, Row } from 'antd';
import { useState } from "react";
import '../../../../static/css/components/_patient-exam-extra-images.scss';
import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { localizedText } from '../../../localizedText';
import GptImageIdentifierDNDImg from './gpt-image-identifier-dnd-img';


const GptImageIdentifierDNDExtraImages = () => {

    const { EXTRA_IMAGES_TEXT, VIEW_ALL_TEXT } = localizedText;

    const extraImages = useCare1AppSelector(
        (store) => store.uploadReconciler.extra_images
    );

    const imagesToDisplay = 6;

    const [showAllImages, setShowAllImages] = useState(false);

    const getImageCards = (isModal = false) => {
        return extraImages.map((base64Image, index) => (
            <Col key={index} span={isModal ? 4 : 3}>
                <Card key={index} className={`extra-image extra-image-mt2 extra-image-${index}`} bordered={false}>
                    <div className="extra-image image">
                        <div className="img-wrapper">
                            <GptImageIdentifierDNDImg
                                className="img-thumbnail"
                                isModal={isModal}
                                field={`extra_images`}
                                index={index}
                            />
                        </div>
                    </div>
                </Card>
            </Col>
        )
        );

    }

    return (
        <Card className='patient-exam-extra-images' bordered={false}>
            <Modal
                className="view-all-modal"
                open={showAllImages}
                width="840"
                onCancel={() => { setShowAllImages(false); }}
                closable
                footer={null}
            >
                <Row gutter={8}>
                    {getImageCards(true)}
                </Row>
            </Modal>
            <Row gutter={8}>
                <Col span={3}>
                    <Card className="extra-image extra-image-new" bordered={false}>
                        <div className="heading">{EXTRA_IMAGES_TEXT}</div>
                        <GptImageIdentifierDNDImg
                            className="img-thumbnail"
                            field='extra_images'
                            index={-1}
                            />
                    </Card>
                </Col>
                {getImageCards().slice(0, imagesToDisplay)}
                {getImageCards().length > imagesToDisplay
                    && (
                        <Col span={3}>
                            <Card className="extra-image view-all" bordered={false}>
                                <div className="heading">&nbsp;</div>
                                <button className="view-all-text" onClick={() => { setShowAllImages(true); }}>
                                    <FullscreenOutlined className="fullscreen-icon" />
                                    <div className="text">
                                        {VIEW_ALL_TEXT}
                                    </div>
                                </button>
                            </Card>
                        </Col>
                    )}
            </Row>
        </Card>
    );
}

export default GptImageIdentifierDNDExtraImages;
