import { useEffect, useRef } from 'react';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/patient-exam-bcva-graph.scss';
import { IGraphProps } from '../../reducers/patient-exam-slice';

interface JqPlotObject {
    replot: () => void;
}

const PatientExamBCVAGraph = ({ side, page }: IGraphProps) => {
    const graphBCVA = useCare1AppSelector(store => store.examData.graph_bcva);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const examIsRE = useCare1AppSelector(store => store.examData.exam_is_retina_only);
    const isReferralLetterUploadPEI = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const rluExamPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);
    const jqChart = useRef<JqPlotObject | null>(null);

    const graphHeight = (userIsOD && !userIsRE) || userIsOMDC || userIsOMDR || userIsADMIN ? '310px' : '165px';
    const graphWidth = (userIsOMDR && examIsRE) || (userIsOMDR && isReferralLetterUploadPEI && !rluExamPremium) ? '620px' : '310px';

    const bcvaGraphId = `default_bcva_${side}_chart_${page}`;

    useEffect(() => {
        // Only consume graphBCVA if it is of type IGraphBcva
        if(graphBCVA){
            const data = side === 'od' ? graphBCVA.od_bcva_points : graphBCVA.os_bcva_points;

            const $ = window.$;

            const buildOptions = () => {
                const ageTicks = graphBCVA.ageTicks;
                const optionSeries = side === 'od' ? graphBCVA.od_series : graphBCVA.os_series;
                const gridBgColor = side === 'od' ? graphBCVA.od_grid_bg_color : graphBCVA.os_grid_bg_color;
                const bcvaTicks = side === 'od' ? graphBCVA.od_bcvaTicks : graphBCVA.os_bcvaTicks;

                return {
                    series: optionSeries,
                    grid: {
                        background: gridBgColor,
                        gridLineColor: 'rgba(179,217,255,0.2)',
                        borderWidth: 0,
                        shadow: false,
                        gridLineWidth: 1,
                    },
                    axesDefaults: {
                        tickOptions: {
                            showMark: true,
                            textColor: '#bfdfff',
                        },
                    },
                    axes: {
                        xaxis: {
                            ticks: ageTicks,
                            tickOptions: {
                                formatter(formatString: string, value: number) {
                                    if (value % 1 === 0) return value; // only display integer value
                                    return '';
                                },
                                formatString: '%d',
                            },
                        },
                        yaxis: {
                            ticks: bcvaTicks,
                            // tickRenderer: $.jqplot.CanvasAxisTickRenderer ,
                            tickOptions: {
                                // angle: -30,
                                // fontSize:'6pt',
                                formatter(formatString: string, value: number) {
                                    if (value === 0.00) return '20/20';
                                    else if (value === -0.10) return '20/25';
                                    else if (value === -0.18) return '20/30';
                                    else if (value === -0.30) return '20/40';
                                    else if (value === -0.40) return '20/50';
                                    else if (value === -0.48) return '20/60';
                                    else if (value === -0.54) return '20/70';
                                    else if (value === -0.60) return '20/80';
                                    else if (value === -0.70) return '20/100';
                                    else if (value === -0.88) return '20/150';
                                    else if (value === -1.00) return '20/200';
                                    else if (value === -1.30) return '20/400';
                                    else if (value === -1.50) return 'CF';
                                    else if (value === -1.75) return 'HM';
                                    else if (value === -2.00) return 'LP';
                                    else if (value === -2.25) return 'NLP';
                                    return '';
                                },
                            },
                        },
                    },
                    fillBetween: {
                        series1: [0, 1, 2],
                        series2: [1, 2, 3],
                        color: ['rgba(102,251,88, 0.5)', 'rgba(255,165,0, 0.8)', 'rgba(230,153,0,0.5)'], // green, yellow, orange
                        fill: true,
                    },
                };
            }

            if (data !== undefined) {
                if (data[0] && data[0].length > 0) {
                    jqChart.current = $.jqplot(bcvaGraphId, data, buildOptions());
                } else {
                    jqChart.current = $.jqplot(bcvaGraphId, [[null]], buildOptions());
                }

                if (jqChart.current) {
                    jqChart.current.replot();
                }
            }
        }
    }, [graphBCVA, jqChart, side, bcvaGraphId])

    /* Inline width/height is intentionally used to limit the graph size */
    // {BCVA-GRAPH 001, 002}
    return <div data-testid={`bcva-graph-${side}`} id={bcvaGraphId} style={{ width: graphWidth, height: graphHeight }} />;
}

export default PatientExamBCVAGraph;