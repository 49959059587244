import { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { Col, Input, Select, Row, Spin, Form, FormInstance } from 'antd';
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';
import '../../../static/css/components/admin-doctor-edit-form.scss';
import { defaultOutboundOMDDetails, IOutboundOMDDetails,  useGetOmdReceiveMethodQuery, useGetOutboundOmdDetailQuery } from '../../services/doctor-api';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import { EMAIL_PLACEHOLDER_TEXT, ERROR_INVALID_PHONE_FORMAT, OPERATING_TEXT, PHONE_MASK_FORMAT } from '../../constants';

const FormItem = Form.Item;

type AppProps = {
    onFinish: (values: IOutboundOMDDetails) => Promise<void>,
    form: FormInstance,
}

const OMDEditFormOutboundReferral = ({ onFinish, form }: AppProps) => {

    const referralOmd = useCare1AppSelector(store => store.examData.referral_omd);
    const showOutboundOmdEditModal = useCare1AppSelector(store => store.doctorSlice.showOutboundOmdEditModal);

    const skipQuery = !showOutboundOmdEditModal || !referralOmd ? true : false;

    const {data , isFetching, isSuccess} = useGetOutboundOmdDetailQuery(referralOmd as number, {skip: skipQuery });
    const {data: omdReceiveMethodOptions, isFetching: isOmdReceiveMethodFetching} = useGetOmdReceiveMethodQuery();

    const selectedReceiveMethod = Form.useWatch('receive_method', form);
    // make the column span the whole column witdh
    const fullColWidth = { span: 24 };

    useEffect(() => {
        // update the form values based on omd data just fetched from useGetOmdDetailQuery hook
        if (isSuccess) {
            form.setFieldsValue({
                ...data,
            });
        }
        return () => {
            form.resetFields();
        }
    }, [data, form, isSuccess])

    return (
        <Form
            className={`admin-doctor-edit-form`}
            form={form}
            onFinish={onFinish}
            initialValues={defaultOutboundOMDDetails}
        >
            <Spin
                className="loading-spinner"
                spinning={isFetching || isOmdReceiveMethodFetching}
                size="large"
                tip={OPERATING_TEXT}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem
                            label={'First name'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'first_name'}
                            rules={[{
                                required: true,
                                message: buildRequiredErrorMessage('First name')
                            }]}
                        >
                            <Input
                                data-testid='first-name-input'
                                className={"first-name-input"}
                                placeholder={'Enter first name'}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            label={'Last name'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'last_name'}
                            rules={[{
                                required: true,
                                message: buildRequiredErrorMessage('Last name')
                            }]}
                        >
                            <Input
                                data-testid='last-name-input'
                                className={"last-name-input"}
                                placeholder={'Enter last name'}
                            />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem
                            label={'Preferred Method to Receive Referral '}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'receive_method'}
                            rules={[{
                                required: true,
                            }]}
                        >
                            <Select
                                allowClear
                                placeholder={'Select Preferred Method to Receive Referral'}
                                className="agreed-to-review-provinces-input"
                                data-testid='preferred-method-to-receive-referral-select'
                                options={omdReceiveMethodOptions}
                            />
                        </FormItem>
                    </Col>
                    
                    <Col span={12}>
                            {/* Test 006 */}
                            <FormItem
                                label={'Email'}
                                labelCol={fullColWidth}
                                hasFeedback
                                colon={false}
                                name={'email'}
                                rules={[{
                                    required: selectedReceiveMethod === 'email',
                                }]}
                            >
                                <Input
                                    data-testid='email-input'
                                    className={"email-address-input"}
                                    placeholder={EMAIL_PLACEHOLDER_TEXT}
                                />
                            </FormItem>
                        </Col>
                    <Col span={12}>
                        {/* PE 005 */}
                        <FormItem
                            label={'Fax'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'fax'}
                            validateTrigger={'onBlur'}
                            rules={[{
                                pattern: /^[^_]+$/,
                                message: ERROR_INVALID_PHONE_FORMAT,
                            }, {
                                required: selectedReceiveMethod === 'fax',
                            }]}
                        >
                            <InputMask
                                data-testid='fax-input'
                                className={'fax-number-input ant-input'}
                                mask={PHONE_MASK_FORMAT}
                                placeholder={'Enter fax number'}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Spin>
        </Form>
    );
}

export default OMDEditFormOutboundReferral;