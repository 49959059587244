import { Card, Col, Flex, Row, Typography } from 'antd';
import '../../../../static/css/components/patient-exam-metrics.scss';
import styles from '../../../../static/css/components/smart-upload-modal.module.scss';
import SmartUploadDtcIopEntry from './smart-upload-dtc-iop-entry';
import { DtcIopField } from '../../../reducers/smart-upload-slice';
import SmartUploadDtcHour from './smart-upload-dtc-hour';


const SmartUploadMiniDtc = () => {
    return (
        <Card bordered={false} className={styles.miniDtc} size='small'>
            <Row gutter={[0, 32]}>
                <Col span={24}>
                    <Flex vertical>
                        <Typography.Text className={styles.dtcHourText}>
                            Time of 1st IOP
                        </Typography.Text>
                        <SmartUploadDtcHour />
                    </Flex>
                </Col>

                <Col span={24}>
                    <Row gutter={[0, 32]}>
                        {new Array(3).fill(null).map((_, index) => (
                            <Col span={24} key={`dtc_iop_${index}`}>
                                <Row gutter={[8, 0]}>
                                    <Col span={24}>
                                        <Typography.Text className={styles.dtcIopTitle}>
                                            IOP#{index + 1}
                                        </Typography.Text>
                                    </Col>
                                    <Col span={12}>
                                        <SmartUploadDtcIopEntry
                                            side='od'
                                            field={
                                                `od_iop_${
                                                    index + 1
                                                }` as DtcIopField
                                            }
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <SmartUploadDtcIopEntry
                                            side='os'
                                            field={
                                                `os_iop_${
                                                    index + 1
                                                }` as DtcIopField
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

export default SmartUploadMiniDtc;
