import React, { useState } from 'react';
import { Layout, Spin } from 'antd';
import NavigationControlBar from '../components/navigation-control-bar';

import * as Constants from '../constants';
import '../../static/css/pages/admin-doctors-list-page.scss'
import AdminReportsTable from '../components/admin/admin-reports-table';
import AdminReportsControlBar from '../components/admin/admin-reports-control-bar';

const { Header, Content } = Layout;

const AdminReportsDashboardPage: React.FC = () => {

    // Doctor API call to get the doctor list

    const [filter, setFileter] = useState({});

    return (
        <Layout>
            <Spin
                className="loading-spinner"
                spinning={false}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Header>
                    <NavigationControlBar
                        className="patient-list-navigation-control-bar"
                        breadcrumbItems={[]}
                    />
                </Header>
                <Content>
                    <div className='admin-doctors-list-page'>
                        <AdminReportsControlBar setFilter={setFileter} />
                        <AdminReportsTable filter={filter} />
                    </div>
                </Content>
            </Spin>
        </Layout>
    );
}

export default AdminReportsDashboardPage;