import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getCsrfToken } from "../helpers/utilities";
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";

export interface IGraphAIInsightsScoreProgression {
    min_year: string;
    max_year: string;
    min_value: number;
    max_value: number;
    od_value_points: Array<Array<number | null>>;
    od_series: [];
    od_grid_bg_color: string;
    os_value_points: Array<Array<number | null>>;
    os_series: [];
    os_grid_bg_color: string;
}

export interface IGraphAIInsightsRetinaHotspots {
    min_year: string;
    max_year: string;
    min_value: number;
    max_value: number;
    od_value_points: Array<Array<number | null>>;
    od_series: [];
    od_grid_bg_color: string;
    os_value_points: Array<Array<number | null>>;
    os_series: [];
    os_grid_bg_color: string;
}

export interface IAIInsightsHistoricalAnalysisItem {
    exam_id: number,
    exam_date: string,
    exam_date_utc: string,
    fundus_photo: string,
    ml_large_heme_pigment_background_photo: string,
    ml_large_heme_pigment_overlay_photo: string,
    ml_large_heme_pigment_value: string,
    ml_large_heme_pigment_succeeded: boolean,
    ml_micro_aneurysms_overlay_photo: string,
    ml_micro_aneurysms_value: string,
    ml_micro_aneurysms_succeeded: boolean,
    ml_hard_exudates_overlay_photo: string,
    ml_hard_exudates_value: string,
    ml_hard_exudates_succeeded: boolean,
}

export interface IAIInsightsHistoricalAnalysis {
    api_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    right_ai_insights_historical_analysis: IAIInsightsHistoricalAnalysisItem[],
    left_ai_insights_historical_analysis: IAIInsightsHistoricalAnalysisItem[],
    show_modal: boolean,
    selected_exam_id: number | null,
    selected_exam_date: string | null,
    selected_fundus_photo: string,
    selected_use_large_heme_pigment_photo: boolean,
    selected_ml_large_heme_pigment_background_photo: string,
    selected_ml_large_heme_pigment_overlay_photo: string,
    selected_ml_large_heme_pigment_value: number,
    selected_ml_large_heme_pigment_succeeded: boolean,
    selected_use_micro_aneurysms_photo: boolean,
    selected_ml_micro_aneurysms_overlay_photo: string,
    selected_ml_micro_aneurysms_value: number,
    selected_ml_micro_aneurysms_succeeded: boolean,
    selected_use_hard_exudates_photo: boolean,
    selected_ml_hard_exudates_overlay_photo: string,
    selected_ml_hard_exudates_value: number,
    selected_ml_hard_exudates_succeeded: boolean,
    score_progression_graph: IGraphAIInsightsScoreProgression | null,
    retina_hotspots_graph: IGraphAIInsightsRetinaHotspots | null,
}

const initialState: IAIInsightsHistoricalAnalysis = {
    api_status: 'idle',
    right_ai_insights_historical_analysis: [],
    left_ai_insights_historical_analysis: [],
    show_modal: false,
    selected_exam_id: null,
    selected_exam_date: null,
    selected_fundus_photo: '',
    selected_use_large_heme_pigment_photo: false,
    selected_ml_large_heme_pigment_background_photo: '',
    selected_ml_large_heme_pigment_overlay_photo: '',
    selected_ml_large_heme_pigment_value: 0,
    selected_ml_large_heme_pigment_succeeded: false,
    selected_use_micro_aneurysms_photo: false,
    selected_ml_micro_aneurysms_overlay_photo: '',
    selected_ml_micro_aneurysms_value: 0,
    selected_ml_micro_aneurysms_succeeded: false,
    selected_use_hard_exudates_photo: false,
    selected_ml_hard_exudates_overlay_photo: '',
    selected_ml_hard_exudates_value: 0,
    selected_ml_hard_exudates_succeeded: false,
    score_progression_graph: null,
    retina_hotspots_graph:  null,
};


export const fetchAIInsightsHistoricalAnalysis = createAsyncThunk(
    'aiInsightsHistoricalAnalysis/fetch',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/ai_insights_historical_analysis/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchAIInsightsScoreProgressionGraph = createAsyncThunk(
    'aiInsightsScoreProgressionGraph/fetch',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/ai_insights_score_progression_graph/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)


export const fetchAIInsightsRetinaHotspotsGraph = createAsyncThunk(
    'fetchAIInsightsRetinaHotspotsGraph/fetch',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/ai_insights_retina_hotspots_graph/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)


export const aiInsightsHistoricalAnalysisSlice = createSlice({
    name: 'ai_insightsHistoricalAnalysis',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setAIIngisghtsHistoricalAnalysisData: (state, action) => {
            // sample action.payload {key:'memberId', value: '12345'}
            if(action.payload){
                return {...state, [action.payload.key]: action.payload.value}
            }
            return {...state};
        },
        openSegmentationModal: (state) => {
            state.show_modal = true;
        },
        closeSegmentationModal: (state) => {
            state.show_modal = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAIInsightsHistoricalAnalysis.pending, (state) => {
                state.api_status = 'loading';
            })
            .addCase(fetchAIInsightsHistoricalAnalysis.fulfilled, (state, action) => {
                state.api_status = 'succeeded';

                state.right_ai_insights_historical_analysis = action.payload.right_ai_insights_historical_analysis
                state.left_ai_insights_historical_analysis = action.payload.left_ai_insights_historical_analysis
            })
            .addCase(fetchAIInsightsHistoricalAnalysis.rejected, (state) => {
                state.api_status = 'failed';
            })
            .addCase(fetchAIInsightsScoreProgressionGraph.pending, (state) => {
                state.api_status = 'loading';
            })
            .addCase(fetchAIInsightsScoreProgressionGraph.fulfilled, (state, action) => {
                state.api_status = 'succeeded';

                state.score_progression_graph = action.payload.score_progression_graph
            })
            .addCase(fetchAIInsightsScoreProgressionGraph.rejected, (state) => {
                state.api_status = 'failed';
            })
            .addCase(fetchAIInsightsRetinaHotspotsGraph.pending, (state) => {
                state.api_status = 'loading';
            })
            .addCase(fetchAIInsightsRetinaHotspotsGraph.fulfilled, (state, action) => {
                state.api_status = 'succeeded';

                state.retina_hotspots_graph = action.payload.retina_hotspots_graph
            })
            .addCase(fetchAIInsightsRetinaHotspotsGraph.rejected, (state) => {
                state.api_status = 'failed';
            });;
    },
});

export const { setAIIngisghtsHistoricalAnalysisData, openSegmentationModal, closeSegmentationModal } = aiInsightsHistoricalAnalysisSlice.actions;

export default aiInsightsHistoricalAnalysisSlice.reducer;