import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export interface IConversationHistory {
    showODNotes: boolean;
    showAdminView: boolean;
}

const initialState: IConversationHistory = {
    showODNotes: false,
    showAdminView: true,
}


export const conversationSlice = createSlice({
    name: 'conversationSlice',
    initialState,
    reducers: {
        toggleODNotes: (state, action: PayloadAction<boolean>) => {
            state.showODNotes = action.payload;
        },
        toggleAdminView: (state, action: PayloadAction<boolean>) => {
            state.showAdminView = action.payload;
        },
    },
});

export const { toggleODNotes, toggleAdminView } = conversationSlice.actions;

export default conversationSlice.reducer;
