import { Fragment } from 'react';
import { Col, Row, Divider, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { FRONTEND_SHORT_DATE_FORMAT} from '../../../constants';
import { localizedText } from '../../../localizedText';
import { getUsInsuranceListRequest, toggleInsuranceModal } from '../../../reducers/us-insurance-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { openEditPatientModal } from '../../../reducers/patient-edit-slice';

type ComponentProps = {
    onClick?: () => void,
}

const PatientExamHeaderPatientInfo = ({onClick}: ComponentProps) => {
    const { DOB_TEXT, TELEPHONE_TEXT } = localizedText;
    const firstName = useCare1AppSelector(store => store.patientDetails.details.first_name);
    const lastName = useCare1AppSelector(store => store.patientDetails.details.last_name);
    const dob = useCare1AppSelector(store => store.patientDetails.details.dob_date);
    const age = useCare1AppSelector(store => store.patientDetails.details.age);
    const sex = useCare1AppSelector(store => store.patientDetails.details.gender);
    const firstPhone = useCare1AppSelector(store => store.patientDetails.details.primary_phone);
    const phnName = useCare1AppSelector(store => store.patientDetails.details.phn_name);
    const phn = useCare1AppSelector(store => store.patientDetails.details.phn);
    const requireSecondaryPHN = useCare1AppSelector(store => store.patientDetails.details.require_secondary_phn);
    const secondaryPHNName = useCare1AppSelector(store => store.patientDetails.details.secondary_phn_name);
    const secondaryPHN = useCare1AppSelector(store => store.patientDetails.details.secondary_phn);
    const isUsOnly = useCare1AppSelector(store => store.user.isUsOnly);
    const patientID = useCare1AppSelector(store => store.patientDetails.details.id);
    const isAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const patientDeactivated = useCare1AppSelector(store => store.patientDetails.details.patient_deactivated);
    const email = useCare1AppSelector(store => store.patientDetails.details.email);

    // For display purposes, the birth date needs to be converted from full month names to abbreviated month names.
    const shortDOB = dob ? dayjs(dob).format(FRONTEND_SHORT_DATE_FORMAT): '';

    const dispatch = useCare1AppDispatch()
    
    const handleOpenEditPatientModal = () => {
        dispatch(openEditPatientModal(patientID!));
    }

    return (
        <Fragment>
            {/* EH 001 */}
            <Row>
                <Col span={22}>
                    <button
                        onClick={onClick}
                        type="button"
                        className={`${isAdmin && patientDeactivated ? 'patient-deactivated' : '' } patient-name`}
                    >
                        {`${lastName}, ${firstName}`}
                    </button>
                    <Button
                        data-testid='edit-patient-button'
                        className="edit-patient-button"
                        type="text"
                        size="small"
                        onClick={handleOpenEditPatientModal}
                        icon={<EditOutlined />}
                    >
                        Edit
                    </Button>
                    <Row>
                        <Col span={14}
                            onClick={onClick}
                        >
                            <Row className={'dob'}>
                                {/* EH 002 */}
                                <label htmlFor={'DOB'} className="patient-exam-header-label">
                                    {DOB_TEXT}
                                </label>
                                <label htmlFor={shortDOB} className="patient-exam-header-content dob-age-sex">
                                    {`${shortDOB} (${age}, ${sex})`}
                                </label>
                            </Row>
                            {
                                !isUsOnly &&
                                <Row className="phn">
                                    {/* EH 004 */}
                                    <label className="patient-exam-header-label phn">{`${phnName}:`}</label>
                                    <label className="patient-exam-header-content phn">{phn}</label>
                                </Row>
                            }
                        </Col>
                        <Col span={10}
                            onClick={onClick}
                        >
                            <Row className="telephone">
                                {/* EH 003 */}
                                <label className="patient-exam-header-label">{TELEPHONE_TEXT}</label>
                                <label className="patient-exam-header-content tel-number">{firstPhone}</label>
                            </Row>
                            <Row className="manitoba-health">
                                {/* EH 015 */}
                                { requireSecondaryPHN &&
                                    <>
                                        <label className="patient-exam-header-label secondary-phn">
                                                {`${secondaryPHNName}:`}
                                        </label>
                                        <label className="patient-exam-header-content secondary-phn">{secondaryPHN}</label>
                                    </>
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Row className='header-label email-row'><label>Email: {email} </label></Row>
                    {
                        isUsOnly &&
                        <Row>
                            <Button
                                className='edit-insurance-button'
                                onClick={() => {
                                    dispatch(toggleInsuranceModal());
                                    patientID && dispatch(getUsInsuranceListRequest(patientID));
                                }}
                            >
                                View or Edit Insurance
                            </Button>
                        </Row>
                    }
                </Col>
                <Col span={2}>
                    <Divider className={'divider-one'} type="vertical" />
                </Col>
            </Row>
        </Fragment>
    )
}

export default PatientExamHeaderPatientInfo;