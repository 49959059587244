import { Card, Col, Row, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

// Components
import MBPatientExamDtcIOP from './mb-patient-exam-dtc-iop';
import PatientExamDtcHour from './patient-exam-dtc-hour';
import PatientExamIOP from './patient-exam-iop';
import PatientExamIOPInstrument from './patient-exam-iop-instrument';

// Helpers
import { patientIsPlaquenil }
    from '../../../helpers/diagnosis-convert';
import * as Constants from '../../../constants';
import '../../../../static/css/components/patient-exam-metrics.scss';
import { localizedText } from '../../../localizedText';
import { useCare1AppSelector } from '../../../apps/care1-hooks';

const PatientExamMetricsIOPReferralLetter = ({ disabled }: { disabled: boolean }) => {

    const { IOP_TEXT } = localizedText;

    const diagnosisEntries = useCare1AppSelector(store => store.diagnosis.entries);
    const pastODIop = useCare1AppSelector(store => store.examData.past_od_iop);
    const pastOSIop = useCare1AppSelector(store => store.examData.past_os_iop);
    const pastApplanation = useCare1AppSelector(store => store.examData.past_applanation);
    const isPlaquenil = patientIsPlaquenil(diagnosisEntries);

    return (
        <Card className={'patient-exam-metrics patient-exam-metrics-iop-referral-letter mb-dtc-iop'} bordered={false}>
            <Row>
                <Col span={24}>
                    <Row className="heading-row">
                        <div className="heading">DTC</div>
                        <Tooltip
                            className='referral-letter-dtc-tooltip'
                            placement='right'
                            title={
                                <div className='referral-letter-dtc-tooltip-div'>
                                    A mini-DTC is required for a glaucoma upload to be eligible for study funding.
                                </div>
                            }
                        >
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Row>
                    <Row>
                        <Col span={24}>
                        <span className='mb-dtc-iop-header-text'>Time of 1st IOP</span>
                        </Col>
                    </Row>
                    <Row className={'metric-row'}>
                        {/* EM 005, 006 */}
                        <Col span={16}>
                            <PatientExamDtcHour disabled={disabled} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={12} className="dtc-iop-od">
                            <MBPatientExamDtcIOP side="od" disabled={disabled} />
                        </Col>
                        <Col span={12} className="dtc-iop-os">
                            <MBPatientExamDtcIOP side="os" disabled={disabled} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
}

export default PatientExamMetricsIOPReferralLetter;
