import React, { useState } from 'react';
import { Modal, Button, Col, Row, Form, Input, Spin } from 'antd';
import '../../../static/css/components/edit-pdf-modal.scss';
import { pdfjs, Page, Document } from 'react-pdf';
import { CheckOutlined, LeftOutlined, MinusCircleOutlined, PlusOutlined, RightOutlined, UndoOutlined } from '@ant-design/icons';
import { useReferralLetterPdfEditMutation, useReferralLetterPdfEditResetMutation } from '../../services/exam-api';

type ComponentProps = {
    examId: number | null,
    recipientRemovedPDFURL: string,
    editPdfModalOpen: boolean,
    setEditPdfModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    retrieveModifiedRLUPdfs: (examId: number) => void,
}

const options = {
    standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

const EditPdfModal = ({examId, recipientRemovedPDFURL, editPdfModalOpen, setEditPdfModalOpen, retrieveModifiedRLUPdfs}: ComponentProps) => {
    const [referralLetterPdfEditRequest, {isLoading: isPdfEditLoading}] = useReferralLetterPdfEditMutation();
    const [referralLetterPdfEditResetRequest, {isLoading: isPdfEditResetLoading}] = useReferralLetterPdfEditResetMutation();
   
    const [form] = Form.useForm();

    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfScale, setPdfScale] = useState(1);

    const handleModalClose = () => {
       setEditPdfModalOpen(false);
    }

    const onFinish = async (values: {keywords: string[]}) => {
        try {
            const result = await referralLetterPdfEditRequest({examId: examId!, keywords: values.keywords}).unwrap();
            if (result.success) {
                retrieveModifiedRLUPdfs(examId!);
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    const onStartOverButtonClick = () => {
        Modal.confirm({
            className: 'info-modal',
            title: 'Are you sure to undo all modifications and start from the original referral letter?',
            onOk: async () => {
                const res = await referralLetterPdfEditResetRequest(examId!).unwrap();
                if (res && res.success) {
                    retrieveModifiedRLUPdfs(examId!);
                    Modal.info({
                        className: 'info-modal',
                        title: 'Referral letter has been reset successfully.',
                    });
                }
            },
        })
    }

    const onPDFLoadSuccess = ({numPages}: {numPages: number}) => {
        setNumPages(numPages);
    }

    const goToPrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    const goToNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    }

    const onPdfPageLoad = ({originalWidth}: {originalWidth: number}) => {
        const parentDiv = document.querySelector('#admin-referral-letter-pdf-editor');
        if (parentDiv) {
            const pageScale = parentDiv.clientWidth / originalWidth;
            if (pdfScale !== pageScale) {
                setPdfScale(pageScale);
            }
        }
      }
    
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

    return (
        <Modal
            className={'edit-pdf-modal'}
            open={editPdfModalOpen}
            closable={true}
            title={null}
            footer={[]}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <Row className='edit-pdf-modal-body'>
                <Col span={14} className='edif-pdf-form-panel'>
                    <Spin spinning={isPdfEditLoading || isPdfEditResetLoading}>
                        <div id="admin-referral-letter-pdf-editor" className="pdfViewerContent">
                            <div className="pdfViewerControlsContainer">
                                <div className="pdfViewerControls">
                                    <LeftOutlined
                                        className="control-icon"
                                        onClick={goToPrevPage}
                                        disabled={pageNumber === 1} />
                                    <span className="page-num">
                                        {pageNumber}
                                        /
                                        {numPages}
                                    </span>
                                    <RightOutlined
                                        className="control-icon"
                                        onClick={goToNextPage}
                                        disabled={pageNumber === numPages} />
                                    
                                </div>
                            </div>
                            { recipientRemovedPDFURL && 
                            <Document 
                                options={options} 
                                file={recipientRemovedPDFURL} 
                                onLoadSuccess={onPDFLoadSuccess} >
                                <Page 
                                    pageNumber={pageNumber} 
                                    onLoadSuccess={onPdfPageLoad}
                                    scale={pdfScale}
                                />
                            </Document>
                            }
                        </div>
                    </Spin>
                </Col>
                <Col span={10} className='keywords-input-panel'>
                    <Row className='keywords-sub-title'>Keywords to be removed</Row>
                    <Form form={form} onFinish={onFinish} initialValues={{keywords:['']}}>
                        <Form.List
                            name="keywords"
                        >
                            {(fields, { add, remove }, { errors }) => (
                            <>
                                { fields.map((field, index) => (
                                <Form.Item
                                    wrapperCol={{span: 24}}
                                    required={false}
                                    key={field.key}
                                >
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                            required: true,
                                            whitespace: true,
                                            message: "Please input keywords to remove or delete this field.",
                                            },
                                        ]}
                                        noStyle
                                    >
                                    <Input placeholder="Keywords to remove" style={{width: '90%'}}/>
                                    </Form.Item>
                                    
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                    />
                                    
                                </Form.Item>
                                ))}

                                <Row className='buttons-row' justify='end'>
                                    
                                    <Button
                                        type="primary"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                        className='add-keywords-button'
                                    >
                                        Add keywords
                                    </Button>
                                </Row>

                                <Row className='buttons-row submit-button-row' justify='space-between'>
                                    <Button
                                        type='primary'
                                        icon={<UndoOutlined />}
                                        onClick={onStartOverButtonClick}
                                        loading={isPdfEditResetLoading}
                                    >
                                        Start from Original
                                    </Button>
                                    <Button
                                        type="primary"
                                        icon={<CheckOutlined />}
                                        loading={isPdfEditLoading}
                                        htmlType="submit">
                                        Submit
                                    </Button>
                                </Row>
                            </>
                            )}
                        </Form.List>
                    </Form>
                </Col>
            </Row>

        </Modal>
    );

}

export default EditPdfModal;