import { Fragment, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Checkbox, Col, Input, Select, Row, Spin, Form, Button } from 'antd';
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';
import * as Constants from '../../constants';
import '../../../static/css/components/admin-doctor-edit-form.scss';
import { PlusOutlined } from '@ant-design/icons';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { defaultODDetail, IODDetails, useGetOdDetailQuery, useGetOmdInternalReviewRecipientsQuery } from '../../services/doctor-api';
import { setCurrentGroupPracticeName, setCurrentOdGroupPractice, toggleClinicLocationAddModal, toggleOdGroupPracticeAddModal } from '../../reducers/doctor-slice';
import { IOdGroupPracticeOption, IOmdNetworkOption, IOmdOption, IProvinceOption, IODStatusOption
     } from '../../reducers/options-slice';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetClinicLocationOptionsQuery, useGetClinicTechnicianOptionsQuery } from '../../services/od-group-practice-api';

const FormItem = Form.Item;

type AppProps = {
    title: string,
    buttonText: string,
    mode: string,
    onFinish: (values: IODDetails) => Promise<void>,
    onFinishFailed: () => void
}

const ODEditForm = ({ title, buttonText, mode, onFinish, onFinishFailed } : AppProps) => {

    const provinces: IProvinceOption[] = useCare1AppSelector(store => store.options.provinces);
    const omds: IOmdOption[] = useCare1AppSelector(store => store.options.omds);
    const odgrouppracticesList: IOdGroupPracticeOption[] = useCare1AppSelector(store => store.options.odgrouppractices);
    const omdNetworks: IOmdNetworkOption[] = useCare1AppSelector(store => store.options.omd_networks);
    const odStatuses: IODStatusOption[] = useCare1AppSelector(store => store.options.odStatuses);

    // od edit form field values
    const currentOdId = useCare1AppSelector(store => store.doctorSlice.currentOd);
    const showOdEditModal = useCare1AppSelector(store => store.doctorSlice.showOdEditModal);
    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const selectedOdGroupPractice: number | null = Form.useWatch('odgrouppractice', form);

    const skipQuery = currentOdId && mode==='edit' && showOdEditModal ? false : true;

    const {data = {od: {...defaultODDetail}}, isFetching, isSuccess} = useGetOdDetailQuery(currentOdId as number, {skip: skipQuery });
    const {data: clinicOptionData} = useGetClinicLocationOptionsQuery(selectedOdGroupPractice!, {skip: !selectedOdGroupPractice});
    const {data: clinicTechnicianOptions} = useGetClinicTechnicianOptionsQuery(selectedOdGroupPractice!, {skip: !selectedOdGroupPractice});
    const {
        data: omdInternalReviewRecipientsOptions,
        isSuccess: isOmdInternalReviewRecipientsSuccess
    } = useGetOmdInternalReviewRecipientsQuery(currentOdId as number);

    const clinicLocationOptions = clinicOptionData ?? [];

    const {od: {first_name: firstName, last_name: lastName, username}} = data;
    // Need a local state version of send_reviews_to_od to set email requirement.
    const sendReviewsToOd = Form.useWatch('send_reviews_to_od', form);

    // make the column span the whole column witdh
    const fullColWidth = { span: 24 };


    useEffect(() => {
        if (isSuccess && isOmdInternalReviewRecipientsSuccess) {
            form.setFieldsValue({
                ...data.od,
            });
        }
    }, [data, form, isSuccess, isOmdInternalReviewRecipientsSuccess])

    const onIntegratedRetinaEssentialsChange = (field: string, e: CheckboxChangeEvent) => {
        const checked = e.target.checked;
        if (checked) {
            const allFields = ['integrated_only', 'retina_only', 'essentials_only'];
            const disableFields = allFields.filter(item => item !== field);

            disableFields.forEach(item => {
                form.setFieldsValue({
                    [item]: false
                });
            });
        }
    };

    return (
        <Form
            className="admin-doctor-edit-form"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={defaultODDetail}
        >
            <Spin
                className="loading-spinner"
                spinning={isFetching}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Row className="header-section" align="middle">
                    <div className="modal-title">
                        {title}
                    </div>
                </Row>
                { mode === 'edit' &&
                    <Row className='read-only-details' gutter={24}>
                        <Col span={16}>
                            <Row>
                                <Col>
                                    <div className="label-column">Username:</div>
                                </Col>
                                <Col>
                                    <div className="value-column">{username}</div>
                                </Col>
                                <Col>
                                    <div className="label-column">Lastname:</div>
                                </Col>
                                <Col>
                                    <div className="value-column">{lastName}</div>
                                </Col>
                                <Col>
                                    <div className="label-column">Firstname:</div>
                                </Col>
                                <Col>
                                    <div className="value-column">{firstName}</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8} className='email-edit'>
                            {/* Test 006 */}
                            <FormItem
                                label={'Email'}
                                hasFeedback
                                colon={false}
                                name={'email'}
                                rules={[{
                                    required: sendReviewsToOd || false,
                                    message: buildRequiredErrorMessage('Email')
                                }]}
                            >
                                <Input
                                    data-testid='email-input'
                                    className={"email-address-input edit"}
                                    placeholder={Constants.EMAIL_PLACEHOLDER_TEXT}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                }
                { mode === 'add' &&
                        <Fragment>
                        <Row gutter={24}>
                            <Col span={8}>
                                <FormItem
                                    label={'Username'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'username'}
                                    rules={[{
                                        required: true,
                                        message: buildRequiredErrorMessage('Username')
                                    }]}
                                >
                                    <Input
                                        data-testid='username-input'
                                        className={"username-input"}
                                        placeholder={'Enter username'}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label={'First name'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'first_name'}
                                    rules={[{
                                        required: true,
                                        message: buildRequiredErrorMessage('First name')
                                    }]}
                                >
                                    <Input
                                        data-testid='first-name-input'
                                        className={"first-name-input"}
                                        placeholder={'Enter first name'}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label={'Last name'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'last_name'}
                                    rules={[{
                                        required: true,
                                        message: buildRequiredErrorMessage('Last name')
                                    }]}
                                >
                                    <Input
                                        data-testid='last-name-input'
                                        className={"last-name-input"}
                                        placeholder={'Enter last name'}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <FormItem
                                    label={'Password'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'password'}
                                    rules={[{
                                        required: true,
                                        message: buildRequiredErrorMessage('Password')
                                    }]}
                                >
                                    <Input.Password
                                        data-testid='password-input'
                                        className={"password-input"}
                                        placeholder={'Enter password'}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label={'Password confirmation'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'password_confirm'}
                                    dependencies={['password']}
                                    rules={[{
                                        required: true,
                                        message: 'Please confirm your password!'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    })]}
                                >
                                    <Input.Password
                                        data-testid='password-confirm-input'
                                        className={"password-confirm-input"}
                                        placeholder={'Enter password again'}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                {/* Test 006 */}
                                <FormItem
                                    label={'Email'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'email'}
                                    rules={[{
                                        required: sendReviewsToOd || false,
                                        message: buildRequiredErrorMessage('Email')
                                    }]}
                                >
                                    <Input
                                        data-testid='email-input'
                                        className={"email-address-input"}
                                        placeholder={Constants.EMAIL_PLACEHOLDER_TEXT}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Fragment>
                }
                <Row gutter={24}>
                    <Col span={8}>
                        {/* PE 005 */}
                        <FormItem
                            label={"Telephone"}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'telephone'}
                            validateTrigger={'onBlur'}
                            rules={[{
                                pattern: /^[^_]+$/,
                                message: Constants.ERROR_INVALID_PHONE_FORMAT,
                            }]}
                        >
                            <InputMask
                                data-testid='phone-input'
                                className={'phone-number-input ant-input'}
                                mask={Constants.PHONE_MASK_FORMAT}
                                placeholder={Constants.PHONE_PLACEHOLDER_TEXT}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        {/* PE 005 */}
                        <FormItem
                            label={'Fax'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'fax'}
                            validateTrigger={'onBlur'}
                            rules={[{
                                pattern: /^[^_]+$/,
                                message: Constants.ERROR_INVALID_PHONE_FORMAT,
                            }]}
                        >
                            <InputMask
                                data-testid='fax-input'
                                className={'fax-number-input ant-input'}
                                mask={Constants.PHONE_MASK_FORMAT}
                                placeholder={'Enter fax number'}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            label={'Province'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'province'}
                            rules={[{
                                required: true,
                                message: buildRequiredErrorMessage('Province')
                            }]}
                        >
                            <Select
                                placeholder={'Select province'}
                                className="province-input"
                                data-testid='province-select'
                                allowClear
                            >
                                {provinces.length > 0 && provinces.map(on => {
                                    return (
                                        <Select.Option key={on.id} value={on.id}>
                                            {on.name}
                                        </Select.Option>
                                    );
                                })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        {/* Test 006 */}
                        <FormItem
                            label={'License number'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'license_number'}
                        >
                            <Input
                                data-testid='license-number-input'
                                className={"license-number-input"}
                                placeholder={'Enter license number'}
                            />
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem
                            label={'OMD network'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'omdnetwork'}
                            rules={[{
                                required: true,
                                message: buildRequiredErrorMessage('OMD network')
                            }]}
                        >
                            <Select
                                placeholder={'Select OMD network'}
                                className="omd-network-input"
                                data-testid='omd-network-select'
                            >
                                {omdNetworks.length > 0 && omdNetworks.map(on => {
                                    return (
                                        <Select.Option key={on.id} value={on.id}>
                                            {on.name}
                                        </Select.Option>
                                    );
                                })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem
                            label={<div className='group-practice-label'>
                                    <span className='label-text'>OD group practice</span>
                                    <PlusOutlined className='add-icon' onClick={() =>
                                        dispatch(toggleOdGroupPracticeAddModal())} />
                                  </div>}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'odgrouppractice'}
                            rules={[{
                                required: true,
                                message: buildRequiredErrorMessage('OD group practice')
                            }]}
                        >
                            <Select
                                placeholder={'Select OD group practice'}
                                className="odgrouppractice-input"
                                data-testid='odgrouppractice-select'
                                showSearch
                                optionFilterProp='label'
                                options={odgrouppracticesList}
                            />
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem
                            label={<div className='group-practice-label'>
                                    <span>Location</span>
                                    <PlusOutlined className='add-icon' onClick={() => {
                                        dispatch(toggleClinicLocationAddModal());
                                        dispatch(setCurrentOdGroupPractice(selectedOdGroupPractice));
                                        const selectedOdGroupPracticeName = odgrouppracticesList.find(item => item.value === selectedOdGroupPractice)?.label;
                                        dispatch(setCurrentGroupPracticeName(selectedOdGroupPracticeName))
                                    }
                                        } />
                                  </div>}
                            labelCol={fullColWidth}
                            colon={false}
                            name={'clinic_location'}
                        >
                            <Select
                                placeholder={'Select Clinic Location'}
                                className="odgrouppractice-input"
                                data-testid='clinic-location-select'
                                showSearch
                                optionFilterProp='label'
                                options={clinicLocationOptions}
                                allowClear
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row className='checkbox-row' gutter={24}>
                    <Col span={5}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'is_migrated'}
                            valuePropName="checked"
                        >
                             <Checkbox
                                data-testid='is-migrated-input'
                                className={"is-migrated-input"}
                            >
                                Is migrated to React?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={5}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'us_only'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='us-only-input'
                                className={"us-only-input"}
                            >
                                US only?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={5}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'hidden_from_dropdown'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='hidden-from-dropdown-input'
                                className={"hidden-from-dropdown-input"}
                            >
                               Hide from dropdown?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'uses_referral_letter_pei'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='uses-referral-letter-pei-input'
                                className={"uses-referral-letter-pei-input"}
                            >
                               Uses Referral Letter PEI
                            </Checkbox>
                        </FormItem>
                    </Col>
                </Row>
                <Row className='checkbox-row' gutter={24}>
                    <Col span={5}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'integrated_only'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='integrated-input'
                                className={'integrated-input'}
                                onChange={(e) => onIntegratedRetinaEssentialsChange('integrated_only', e)}
                            >
                                Integrated
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={5}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'retina_only'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='retina-only-input'
                                className={"retina-only-input"}
                                onChange={(e) => onIntegratedRetinaEssentialsChange('retina_only', e)}
                            >
                                Retina Enabled
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={5}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'essentials_only'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='essentials-only-input'
                                className={"essentials-only-input"}
                                onChange={(e) => onIntegratedRetinaEssentialsChange('essentials_only', e)}
                            >
                                Essentials Only
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={5}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'pei_toggle'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='pei-toggle-input'
                                className={"pei-toggle-input"}
                            >
                                PEI Toggle
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'has_smart_upload'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='smart-upload-input'
                                className={"smart-upload-input"}
                            >
                                Smart Upload
                            </Checkbox>
                        </FormItem>
                    </Col>
                </Row>
                <Row className='checkbox-row' gutter={24}>
                    <Col span={5}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'orientating'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='orientating-input'
                                className={'orientating-input'}
                            >
                                Orientating?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={5}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'special_attention'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='special-attention-input'
                                className={"special-attention-input"}
                            >
                                Special attention?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={5}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'reimburse'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='reimburse-input'
                                className={'reimburse-input'}
                            >
                                Reimburse?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={5}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'send_gp_letters'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='send-gp-letters-input'
                                className={"send-gp-letters-input"}
                            >
                                Send GP Letters?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'rural'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='rural-input'
                                className={"rural-input"}
                            >
                                Rural?
                            </Checkbox>
                        </FormItem>
                    </Col>
                </Row>
                <Row className='checkbox-row' gutter={24}>
                    <Col span={5}>
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'has_glaucoma_program'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='has-glaucoma-program-input'
                                className={'has-glaucoma-program-input'}
                            >
                                Glaucoma Dashboard
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={5}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'gets_pdf_reviews'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='gets-pdf-reviews-input'
                                className={"gets-pdf-reviews-input"}
                            >
                                Gets PDF reviews?
                            </Checkbox>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            label={'Default OMD'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'default_omd'}
                        >
                            <Select
                                placeholder={'Select default OMD'}
                                className="default-omd-input"
                                data-testid='default-omd-select'
                                allowClear
                            >
                                {omds.length > 0 && omds.map(on => {
                                    return (
                                        <Select.Option key={on.id} value={on.id}>
                                            {on.name}
                                        </Select.Option>
                                    );
                                })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            label={'OD Status'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'od_status'}
                            rules={[{
                                required: true,
                                message: buildRequiredErrorMessage('OD Status')
                            }]}
                        >
                            <Select
                                placeholder={'Select OD Status'}
                                className="od-status-input"
                                data-testid='od-status-select'
                                allowClear
                            >
                                {odStatuses.map(odStatus => {
                                    return (
                                        <Select.Option key={odStatus.value} value={odStatus.value}>
                                            {odStatus.label}
                                        </Select.Option>
                                    );
                                })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                    
                </Row>
                <Row gutter={24}>
                    <Col span={16}>
                        <FormItem
                            label={'Technicians to Receive Reviews'}
                            labelCol={fullColWidth}
                            colon={false}
                            name={'technicians_to_receive_reviews'}
                        >
                            <Select
                                placeholder={'Select technicians to reveive reviews'}
                                className="odgrouppractice-input"
                                data-testid='clinic-location-select'
                                showSearch
                                optionFilterProp='label'
                                options={clinicTechnicianOptions}
                                allowClear
                                mode='multiple'
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'send_reviews_to_od'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='send-gp-letters-input'
                                className={"send-reveiws-to-od"}
                            >
                                Send Reviews to OD
                            </Checkbox>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            labelCol={fullColWidth}
                            label='Email for internal reviews'
                            hasFeedback
                            colon={false}
                            name='internal_review_email'
                            rules={[
                                {type: 'email'},
                            ]}
                        >
                            <Input
                                placeholder={Constants.EMAIL_PLACEHOLDER_TEXT}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            label='Send internal reviews to'
                            labelCol={fullColWidth}
                            colon={false}
                            name='omd_internal_review_recipients'
                        >
                            <Select
                                placeholder={'Send internal reviews to'}
                                className="odgrouppractice-input"
                                showSearch
                                optionFilterProp='label'
                                options={omdInternalReviewRecipientsOptions}
                                allowClear
                                mode='multiple'
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <Button className='save-doctor-button' type="primary" htmlType="submit">
                            {buttonText}
                        </Button>
                    </Col>
                </Row>
            </Spin>
        </Form>
    );

}

export default ODEditForm;
