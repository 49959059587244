import { localizedText } from '../localizedText';

// Check if the optometrist user should be disabled for this exam.
export function isOptometristReadOnly(isOptometrist: boolean, examStatus: string, examStatusesAllowedForOptometrist: string[]) {
    // If this user is an optometrist and the exam status is not inside the exam statuses allowed for ODs,
    // the exam should be read-only.
    return (isOptometrist && !examStatusesAllowedForOptometrist.includes(examStatus));
}

// Return a key indacting the type of platform corresponding to the logged in user
export const getUserPlatformKey = (isRE: boolean, isOD: boolean, isOMDC: boolean, isOMDR: boolean, isADMIN: boolean) => {

    // Filter RE before OD (an RE is also an OD)
    return  isOD && isRE ? 'RE'
        : isOMDC ? 'OMDC'
        : !isRE && isOD ? 'IC'
        : isOMDR && isRE ? 'OMDR RE'
        : isOMDR && !isRE ? 'OMDR IC'
        : isADMIN ? 'ADMIN'
        : null;
}

// Return the platform title string based on the type of logged in user
export function getPlatformTitle(isRE: boolean, isOD: boolean, isOMDC: boolean, 
    isOMDR: boolean, isADMIN: boolean, isSCIENCEUSER : boolean){
    const { PLATFORM_RETINA_ENABLED_TITLE, PLATFORM_INTEGRATED_TITLE, PLATFORM_OMDC_TITLE, PLATFORM_OMDR_RE_TITLE,
        PLATFORM_OMDR_IC_TITLE, PLATFORM_ADMIN_TITLE } = localizedText;

    if (isSCIENCEUSER) {
        return 'Science';
    }
    
    let platformTitle = '';

    // Get user's platform key
    const userPlatformKey = getUserPlatformKey(isRE, isOD, isOMDC, isOMDR, isADMIN);

    switch(userPlatformKey){
        case 'RE':
            platformTitle = PLATFORM_RETINA_ENABLED_TITLE;
            break;
        case 'IC':
            platformTitle = PLATFORM_INTEGRATED_TITLE;
            break;
        case 'OMDC':
            platformTitle = PLATFORM_OMDC_TITLE;
            break;
        case 'OMDR RE':
            platformTitle = PLATFORM_OMDR_RE_TITLE;
            break;
        case 'OMDR IC':
            platformTitle = PLATFORM_OMDR_IC_TITLE;
            break;
        case 'ADMIN':
            platformTitle = PLATFORM_ADMIN_TITLE;
            break;
        default:
            break;
    }

    return platformTitle;
}