import dayjs from 'dayjs';
import * as Constants from '../constants';
import { localizedText } from '../localizedText';
import { IProvinceOption } from '../reducers/options-slice';
import { IHealthNumberData, IPatientDetail, IPatientEditResponse } from '../reducers/patient-edit-slice';
import { IGP } from '../services/doctor-api';

// These are fields used for dynamic front end functionality
// This includes utility fields that are no represented in backend data
export function createEmptyPatient() {
    return {
        [Constants.PATIENT_ID_FIELD]: '',
        [Constants.FIRST_NAME_FIELD]: '',
        [Constants.LAST_NAME_FIELD]: '',
        [Constants.GENDER_FIELD]: '',
        [Constants.PHONE_FIELD]: '',
        [Constants.EMAIL_FIELD]: '',
        [Constants.DOB_FIELD]: '',
        [Constants.DOB_DAY_FIELD]: '',
        [Constants.DOB_MONTH_FIELD]: '',
        [Constants.DOB_YEAR_FIELD]: '',
        [Constants.PROVINCE_FIELD]: '',
        [Constants.PHN_FIELD]: '',
        [Constants.PHN_NAME_FIELD]: Constants.PHN_DEFAULT_LABEL_TEXT,
        [Constants.PHN_VALIDATION_FORMAT_FIELD]: '',
        [Constants.SECONDARY_PHN_REQUIRED_FIELD]: false,
        [Constants.SECONDARY_PHN_FIELD]: '',
        [Constants.SECONDARY_PHN_NAME_FIELD]: '',
        [Constants.SECONDARY_PHN_VALIDATION_FORMAT_FIELD]: '',
        [Constants.STREET_FIELD]: '',
        [Constants.CITY_FIELD]: '',
        [Constants.POSTAL_CODE_FIELD]: '',
        [Constants.GP_FIELD]: '',
        [Constants.GP_CURRENT_FIELD]: '',
        [Constants.GP_FAX_NUMBER_FIELD]: '',
        [Constants.GP_SEARCH_FIELD]: '',
        [Constants.IS_CAF_PERSONNEL_FIELD]: false,
        [Constants.CAF_SERVICE_NUMBER_FIELD]: '',
        [Constants.PLEASE_CONFIRM_FIELD]: false,
        has_no_phn: false,
        [Constants.IS_ON_OLD_OHIP_FIELD]: false,
        is_test_patient: false,
        deleted: false,
    }
}

// Return a data object containing five PHN fields that are based on the selected province
export function getHealthNumberData(provinceId: number, provinces: IProvinceOption[]): IHealthNumberData {
    const province = provinces.find((entry) => entry.id === provinceId) as IProvinceOption;

    return {
        [Constants.PHN_NAME_FIELD]: province.health_id_code,
        [Constants.SECONDARY_PHN_NAME_FIELD]: province.health_billing_id_code,
        [Constants.PHN_VALIDATION_FORMAT_FIELD]: province[Constants.PHN_VALIDATION_FORMAT_FIELD],
        [Constants.SECONDARY_PHN_VALIDATION_FORMAT_FIELD]: province[Constants.SECONDARY_PHN_VALIDATION_FORMAT_FIELD],
        [Constants.SECONDARY_PHN_REQUIRED_FIELD]: !!(province.require_billing_id),
    };
}

// Create a display name for the GP dropdown option list
export function formatGPName(gp: IGP) {
    return `${Constants.DR_PREFIX_TEXT} ${gp.first_name} ${gp.last_name}`;
}

// Convert the backend format (ie, 999-9999?-aa) to Antd Mask Input
export function getMaskInputFormat(backendFormat: string) {
    let mask = '';

    if (backendFormat && backendFormat.length > 0) {
        backendFormat.split('').forEach((character) => {
            mask += (character === '?') ? '*' : character;
        });
    }

    return mask;
}

// Get the Antd Mask Input format for the given non-provincial insurance type.
export const getNonProvincialMaskInputFormat = (insuranceType: string) => {
    if (insuranceType === 'old_ohip') {
        return '9999-999-999';
    }
    else if (insuranceType === 'caf_personnel') {
        return Constants.CAF_MASK_FORMAT;
    }
    else if (insuranceType === 'rcmp') {
        return 'a99999999';
    }
    else if (insuranceType === 'ifhp') {
        return '9999999999';
    }
    else if (insuranceType === 'veteran') {
        return 'a9999999';
    }
    else {
        return '';
    }
}

// Convert backend patient data to patient state object
export function buildPatientFromBackendData(backendPatient: IPatientEditResponse) {
    const phn = backendPatient[Constants.PHN_FIELD];

    return {
        [Constants.PATIENT_ID_FIELD]: backendPatient[Constants.BACKEND_PATIENT_ID_FIELD],
        [Constants.FIRST_NAME_FIELD]: backendPatient[Constants.FIRST_NAME_FIELD],
        [Constants.LAST_NAME_FIELD]: backendPatient[Constants.LAST_NAME_FIELD],
        [Constants.GENDER_FIELD]: backendPatient[Constants.GENDER_FIELD],
        [Constants.PHONE_FIELD]: backendPatient[Constants.PHONE_FIELD],
        [Constants.EMAIL_FIELD]: backendPatient[Constants.EMAIL_FIELD],
        [Constants.DOB_FIELD]: dayjs(backendPatient[Constants.DOB_FIELD]),
        [Constants.DOB_DAY_FIELD]: dayjs(backendPatient[Constants.DOB_FIELD]).format('D'),
        [Constants.DOB_MONTH_FIELD]: dayjs(backendPatient[Constants.DOB_FIELD]).format('MMMM'),
        [Constants.DOB_YEAR_FIELD]: dayjs(backendPatient[Constants.DOB_FIELD]).format('YYYY'),
        [Constants.PROVINCE_FIELD]: backendPatient[Constants.PROVINCE_FIELD],
        [Constants.PHN_NAME_FIELD]: (!backendPatient[Constants.PHN_FIELD]) ? Constants.PHN_DEFAULT_LABEL_TEXT : '',
        [Constants.PHN_FIELD]: backendPatient[Constants.PHN_FIELD],
        [Constants.SECONDARY_PHN_FIELD]: backendPatient[Constants.SECONDARY_PHN_FIELD],
        has_no_phn: backendPatient['has_no_phn'],
        [Constants.STREET_FIELD]: backendPatient[Constants.STREET_FIELD],
        [Constants.CITY_FIELD]: backendPatient[Constants.CITY_FIELD],
        [Constants.POSTAL_CODE_FIELD]: backendPatient[Constants.POSTAL_CODE_FIELD],
        [Constants.GP_CURRENT_FIELD]: backendPatient[Constants.GP_CURRENT_FIELD],
        // We are going to deprecate using the gp fax number field on the patient model since we should be using the gp model
        [Constants.GP_FAX_NUMBER_FIELD]: backendPatient[Constants.GP_FAX_NUMBER_FIELD],
        [Constants.GP2_CURRENT_FIELD]: backendPatient[Constants.GP2_CURRENT_FIELD],
        [Constants.GP2_FAX_NUMBER_FIELD]: backendPatient[Constants.GP2_FAX_NUMBER_FIELD],
        [Constants.IS_CAF_PERSONNEL_FIELD]: backendPatient[Constants.IS_CAF_PERSONNEL_FIELD],
        [Constants.CAF_SERVICE_NUMBER_FIELD]: backendPatient[Constants.CAF_SERVICE_NUMBER_FIELD],
        // Set please confirm field to true since this was confirmed when patient was created
        [Constants.PLEASE_CONFIRM_FIELD]: true,
        [Constants.IS_ON_OLD_OHIP_FIELD]: backendPatient[Constants.PROVINCE_FIELD]=== Constants.ONTARIO_PROVINCE_ID
            && phn && phn.length === Constants.ONTARIO_OLD_OHIP_LENGTH ? true : false,
        is_test_patient: backendPatient['is_test_patient'],
        deleted: backendPatient['deleted'],
        has_no_gp: backendPatient['has_no_gp'],
        insurance_type: backendPatient.insurance_type,
    }
}

export function buildPatientFromState(statePatient: IPatientDetail) {
    let hasNoGp = statePatient['has_no_gp'];
    // Once OD add a GP or MD#2, uncheck has_no_gp automatically
    if ((statePatient[Constants.GP_CURRENT_FIELD] || statePatient[Constants.GP2_CURRENT_FIELD]) && hasNoGp ){
        hasNoGp = false;
    }
    return {
        [Constants.FIRST_NAME_FIELD]: statePatient[Constants.FIRST_NAME_FIELD],
        [Constants.LAST_NAME_FIELD]: statePatient[Constants.LAST_NAME_FIELD],
        [Constants.GENDER_FIELD]: statePatient[Constants.GENDER_FIELD],
        [Constants.PHONE_FIELD]: statePatient[Constants.PHONE_FIELD],
        [Constants.EMAIL_FIELD]: statePatient[Constants.EMAIL_FIELD],
        [Constants.DOB_FIELD]: dayjs(statePatient[Constants.DOB_FIELD]).format('YYYY-MM-DD'),
        [Constants.PROVINCE_FIELD]: statePatient[Constants.PROVINCE_FIELD],
        [Constants.PHN_FIELD]: statePatient[Constants.PHN_FIELD],
        [Constants.SECONDARY_PHN_FIELD]: statePatient[Constants.SECONDARY_PHN_FIELD],
        has_no_phn: statePatient['has_no_phn'],
        [Constants.STREET_FIELD]: statePatient[Constants.STREET_FIELD],
        [Constants.CITY_FIELD]: statePatient[Constants.CITY_FIELD],
        [Constants.POSTAL_CODE_FIELD]: statePatient[Constants.POSTAL_CODE_FIELD],
        [Constants.GP_CURRENT_FIELD]: statePatient[Constants.GP_CURRENT_FIELD],
        [Constants.FAX_NUMBER_FIELD]: statePatient[Constants.FAX_NUMBER_FIELD],
        [Constants.GP2_CURRENT_FIELD]: statePatient[Constants.GP2_CURRENT_FIELD],
        [Constants.GP2_FAX_NUMBER_FIELD]: statePatient[Constants.GP2_FAX_NUMBER_FIELD],
        [Constants.IS_CAF_PERSONNEL_FIELD]: statePatient[Constants.IS_CAF_PERSONNEL_FIELD],
        [Constants.CAF_SERVICE_NUMBER_FIELD]: statePatient[Constants.CAF_SERVICE_NUMBER_FIELD],
        is_test_patient: statePatient['is_test_patient'],
        deleted: statePatient['deleted'],
        has_no_gp: hasNoGp,
        insurance_type: statePatient.insurance_type,
    }
}

export function buildRequiredErrorMessage(field: string) {
    return `${field} is required`;
}

// Return first and last name from a doctor string
export const getGpNameFromString = (gpString: string) => {
    // Remove leading and trailing white space and split words into array.
    let gpName = gpString.trim().split(' ');

    // Check if the first word is Dr. or Dr, if it is then we want to remove it to only allow names.
    const gpLowerCase = gpName[0].toLowerCase();
    if (gpLowerCase === 'dr' || gpLowerCase === 'dr.') {
        gpName = gpName.slice(1);
    }

    if (gpName.length === 2) {
        // If the GP has a first and lastname, return the gpName in [firstName, lastName] format.
        return gpName;
    }
    return null;
};

// Returns true if the day is valid for the selected month and year
export function isDOBDayValid(day: string, month: string, year: string){
    const { MONTHS } = localizedText;

    const validDaysInMonth = [
        {month: MONTHS[0], days: 31},
        {month: MONTHS[1], days: 28},
        {month: MONTHS[2], days: 31},
        {month: MONTHS[3], days: 30},
        {month: MONTHS[4], days: 31},
        {month: MONTHS[5], days: 30},
        {month: MONTHS[6], days: 31},
        {month: MONTHS[7], days: 31},
        {month: MONTHS[8], days: 30},
        {month: MONTHS[9], days: 31},
        {month: MONTHS[10], days: 30},
        {month: MONTHS[11], days: 31},
    ];

    // Modify feb to 29 days if it is a leap year
    if ((!(Number(year) % 4) && Number(year) % 100) || !(Number(year) % 400)) {
        validDaysInMonth[1].days = 29;
    }

    // Check that the day is not greater than the valid days in the month
    const maxDaysInMonth = validDaysInMonth.find(m => m.month.toLowerCase() === month.toLowerCase());
    return Number(day) <= maxDaysInMonth!.days
        ? new Date(`${month} ${day}, ${year}`)
        : false;
}
