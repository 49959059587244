import { useCallback, useEffect } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import {Form,  Button, Modal, Col, Row, Input, Spin } from 'antd';
import dayjs from 'dayjs';
import * as Constants from '../../constants';
import '../../../static/css/components/_help-desk-modal.scss';

// Helpers
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';
import { IHelpDeskForm, setHelpDeskField, setHelpDeskModalOpen, submitHelpDeskRequest } from '../../reducers/help-desk-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { FieldData } from 'rc-field-form/lib/interface';
import { IProvinceOption } from '../../reducers/options-slice';
import { localizedText } from '../../localizedText';

const HelpDeskModal = () => {
    const { HELP_DESK_REQUEST, CANCEL_BUTTON_TEXT, SUBMIT_BUTTON_TEXT, 
        YOUR_NAME_TEXT, YOUR_EMAIL_TEXT, YOUR_NAME_PLACEHOLDER, CONTACT_EMAIL_TEXT,
        HELP_DESK_DETAILS, PROVINCE_TEXT, CLINIC_TEXT, DOCTOR_TEXT, DATE_TEXT } = localizedText;
    
    const requesterName = useCare1AppSelector(store => store.helpDesk.requesterName);
    const contactEmail = useCare1AppSelector(store => store.helpDesk.contactEmail);
    const helpRequest = useCare1AppSelector(store => store.helpDesk.helpRequest);
    const isVisible = useCare1AppSelector(store => store.helpDesk.isHelpDeskModalOpen);
    const isOperating = useCare1AppSelector(store => store.helpDesk.isOperating);
    const provinces = useCare1AppSelector(store => store.options.provinces);
    const doctor = useCare1AppSelector(store => store.user.displayName);
    const clinic = useCare1AppSelector(store => store.user.odPracticeName);
    const odProvinceCode = useCare1AppSelector(store => store.user.odProvince);
    const omdProvinceCode = useCare1AppSelector(store => store.user.omdProvince);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);

    const dispatch = useCare1AppDispatch();
    const [ form ] = Form.useForm();

    const handleCancel = () => {
        dispatch(setHelpDeskModalOpen(false));
        // Reset fields and clear any validation error messages so that if reopened the error
        // messages will not display
        form.resetFields();
    }

    const handleSubmit = useCallback(async () => {
        try {
            await form.validateFields();
            dispatch(submitHelpDeskRequest())
            .then(() => dispatch(setHelpDeskModalOpen(false)));
        } catch (error){
            console.log('Help Desk Request Error', error);
        }
    }, [dispatch, form]);

    useEffect(() => {
        let newValidationFieldValues = {} as IHelpDeskForm ;
        newValidationFieldValues.requesterName = requesterName;
        newValidationFieldValues.contactEmail = contactEmail;
        newValidationFieldValues.helpRequest = helpRequest;
        form.setFieldsValue(newValidationFieldValues);
    },  [requesterName, contactEmail, helpRequest, form]);

    const onFieldsChange = (fields: FieldData[]) => {
        // Step 1: Update the Patient Edit store
        fields.forEach((field: FieldData) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0] as keyof IHelpDeskForm) {
                    case 'helpRequest':
                    case 'requesterName':
                    case 'contactEmail':
                    case 'modalSource':
                        dispatch(setHelpDeskField({
                            field: field.name[0],
                            value: field.value,
                        }));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    const province = userIsOMDC
        ? provinces.find((e: IProvinceOption) => e.id === omdProvinceCode)
        : provinces.find((e: IProvinceOption) => e.code === odProvinceCode);

    const provinceDisplayName = province ? province.name : '';

    const date = dayjs().format('MMM D YYYY');

    return (

        <Modal
            className='help-desk-modal'
            open={isVisible}
            width="840"
            closable={false}
            footer={null}
        >
            <Spin
                className="loading-spinner"
                spinning={isOperating}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Row className="header-section" align="middle">
                    <div className="modal-title">
                    {HELP_DESK_REQUEST}
                    </div>

                    <Button
                        className="submit-request-button"
                        icon={<SaveOutlined />}
                        onClick={handleSubmit}
                    >
                        {SUBMIT_BUTTON_TEXT}
                    </Button>

                    <Button
                        className="cancel-button"
                        onClick={handleCancel}
                    >
                        {CANCEL_BUTTON_TEXT}
                    </Button>
                </Row>
                <Form
                    className='help-desk-form'
                    form={form}
                    onFieldsChange={onFieldsChange}
                    labelCol={{span: 24}}
                    wrapperCol={{span: 24}}
                >
                    <Row gutter={24}>
                        <Col span={10}>
                            <Form.Item
                                name={'requesterName'}
                                label={YOUR_NAME_TEXT}
                                hasFeedback
                                colon={false}
                                validateTrigger={'onBlur'}
                                rules={[{
                                    required: true,
                                    whitespace: true,
                                    type: 'string',
                                    message: buildRequiredErrorMessage('Requester\'s Name'),
                                }]}
                            >
                                <Input placeholder={YOUR_NAME_PLACEHOLDER} />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item
                                name={'contactEmail'}
                                label={YOUR_EMAIL_TEXT}
                                hasFeedback
                                colon={false}
                                validateTrigger={'onBlur'}
                                rules={[{
                                    required: true,
                                    whitespace: true,
                                    type: 'email',
                                    message: buildRequiredErrorMessage('Contact Email'),
                                }]}
                            >
                                <Input placeholder={CONTACT_EMAIL_TEXT} />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                label={DATE_TEXT}
                                hasFeedback
                                colon={false}
                            >
                                <Input
                                    disabled={true}
                                    value={date}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={10}>
                            <Form.Item
                                label={DOCTOR_TEXT}
                                hasFeedback
                                colon={false}
                            >
                                <Input
                                    disabled={true}
                                    value={doctor}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item
                                label={CLINIC_TEXT}
                                hasFeedback
                                colon={false}
                            >
                                <Input
                                    disabled={true}
                                    value={ userIsOMDC ? 'N/A' : clinic }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                label={PROVINCE_TEXT}
                                hasFeedback
                                colon={false}
                            >
                                <Input
                                    disabled={true}
                                    value={provinceDisplayName}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24} className='request-details'>
                            <Form.Item
                                name={'helpRequest'}
                                label={HELP_DESK_DETAILS}
                                hasFeedback
                                colon={false}
                                validateTrigger={'onBlur'}
                                rules={[{
                                    required: true,
                                    whitespace: true,
                                    message: buildRequiredErrorMessage('Help Request Information'),
                                }]}
                            >
                                <Input.TextArea
                                    rows={17}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}

export default HelpDeskModal;