import { Flex, Spin, Typography } from 'antd';
import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import styles from '../../../../static/css/components/smart-upload-loading-indicator.module.scss';

const SmartUploadLoadingIndicator = () => {
    const isSaving = useCare1AppSelector((store) => store.smartUpload.saving);

    return (
        <Flex gap={6}>
            {isSaving ? (
                <>
                    <Spin indicator={<LoadingOutlined spin />} />
                    <Typography.Text italic className={styles.loadingText}>
                        Saving...
                    </Typography.Text>
                </>
            ) : (
                <>
                    <CheckCircleFilled className={styles.saved} />
                    <Typography.Text italic className={styles.saved}>
                        Saved
                    </Typography.Text>
                </>
            )}
        </Flex>
    );
};

export default SmartUploadLoadingIndicator;
