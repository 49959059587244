import { Tag } from 'antd';
import { EXAM_STATUS_TYPES, SMART_UPLOAD_STATUS } from '../../constants';
import { localizedText } from '../../localizedText';
import { AlertOutlined, EyeOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useCare1AppSelector } from '../../apps/care1-hooks';

type ComponentProps = {
    examStatusKey: string,
    className: string,
    testId?: string,
    isSmartUpload? : boolean,
}

type TypeExamStatus = {
    key: string,
    value: string,
    color?: string
}

const ExamStatusTag = ({examStatusKey, className, testId, isSmartUpload}: ComponentProps) => {
    const { EXAM_STATUS_REFERRAL_LETTER_TYPES } = localizedText;
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const odUsesReferralLetterPei = useCare1AppSelector(store => store.user.odUsesReferralLetterPei);
    const odAndRLU = userIsOD && odUsesReferralLetterPei;

    const getExamStatusIcon = (examStatusKey: string) => {
        if (examStatusKey === 'not_ready_od_review' || examStatusKey === 'not_ready') {
            return <AlertOutlined />;
        } else if (examStatusKey === 'od_review' || (odAndRLU && examStatusKey === 'ready')) {
            return <EyeOutlined />;
        } else if (examStatusKey === 'ready' || examStatusKey === 'reviewed'){
            return <ShareAltOutlined />;
        } else {
            return null;
        }
    }

    const getExamStatusText = (examStatus: TypeExamStatus) => {
        if(userIsOMDR && !userIsRE){

            if(examStatus.key === 'ready'){
                return 'ready';
            } else if (examStatus.key === 'reviewed'){
                return 'reviewed';
            } else {
                return examStatus.value;
            }

        }
        else if (odAndRLU) {
            const referralLetterExamStatusKey = (examStatus.key === 'od_review' || examStatus.key === 'ready') ? 'od_review_ready' : examStatus.key;
            const referralLetterExamStatus =  EXAM_STATUS_REFERRAL_LETTER_TYPES.find((entry) => entry.key === referralLetterExamStatusKey);
            return referralLetterExamStatus?.label;
        }
        else if (odUsesReferralLetterPei && examStatus.key === 'od_review') {
            return 'submitted to care1';
        }
        else {
            return examStatus.value;
        }
    }

    const getExamStatusColor = (examStatus: TypeExamStatus) => {
        if (odAndRLU) {
            if (examStatus.key === 'od_review' || examStatus.key === 'ready') {
                const referralLetterExamStatus =  EXAM_STATUS_REFERRAL_LETTER_TYPES.find((entry) => entry.key === 'od_review_ready');
                return referralLetterExamStatus?.color;
            }
        }
        return examStatus?.color;
    }

    // Get exam status object from patient exam status options
    const examStatus = [...EXAM_STATUS_TYPES, ...SMART_UPLOAD_STATUS].find((entry) => entry.key === examStatusKey);

    return examStatus ? (
        isSmartUpload ? (
            <div className={`exam-status-tag ${examStatusKey}`}>
                <Tag
                    data-testid={testId ? testId : ''}
                    className={className}
                    color={examStatus.color}
                >

                    {examStatus.value.toUpperCase()}
                </Tag>
            </div>
        ) : (
            <div className={`exam-status-tag ${examStatusKey}`}>
                <Tag
                    data-testid={testId ? testId : ''}
                    className={className}
                    color={getExamStatusColor(examStatus)}
                >
                    {getExamStatusIcon(examStatusKey)}

                    {getExamStatusText(examStatus)?.toUpperCase()}
                </Tag>
            </div>
        )
    ) : null;
}

export default ExamStatusTag;
