import { useCare1AppSelector } from '../apps/care1-hooks';
import { Row } from 'antd';

// Components
import PatientExamHeader from '../components/retina/header/patient-exam-header';
import PatientExamStandardPremiumSwitch from '../components/integrated/patient-exam-standard-premium-switch';
import StandardReferralLetterPageContents from './standard-referral-letter-page-contents';


// Helper
import '../../static/css/pages/ic-patient-exam-page.scss';
import '../../static/css/components/_patient-exam-bcva.scss';

// Standard RLU Exam OD Page
const StandardReferralLetterPatientExamPage = () => {
    const exam_od_has_pei_toggle = useCare1AppSelector(store => store.examData.exam_od_has_pei_toggle);
    const examStatus = useCare1AppSelector(store => store.examData.exam_status);

    // RQ: If exam status is ready or reviewed, then components should be read only.
    const isReadOnly = examStatus !== 'not_ready';

    return (
        <div className="ic-patient-exam-page standard-referral-letter-pei">
            <Row>
                <PatientExamHeader />
            </Row>
            { exam_od_has_pei_toggle &&
                <Row>
                    <PatientExamStandardPremiumSwitch />
                </Row>
            }
            <StandardReferralLetterPageContents disabled={isReadOnly} />
        </div>
    );
};

export default StandardReferralLetterPatientExamPage;
