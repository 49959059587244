import React, { ChangeEvent, KeyboardEvent } from "react";
import { Input, Button } from 'antd';
import { SendOutlined } from "@ant-design/icons";
import '../../../../static/css/components/admin-gpt.scss';

const { TextArea } = Input;

interface ChatInputProps {
    inputMessage: string;
    setInputMessage: React.Dispatch<React.SetStateAction<string>>;
    sendMessage: () => void;
}

const GptInput: React.FC<ChatInputProps> = ({
    inputMessage,
    setInputMessage,
    sendMessage,
}) => {
    const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setInputMessage(e.target.value);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            if (inputMessage) {
                sendMessage();
            }
        }
    };

    return (
        <>
        <div className="chat-input chat-input-top">
            <TextArea
                placeholder="Send a message"
                value={inputMessage}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                autoSize
                bordered={false}
            />
        </div>
        <div className="chat-input chat-input-bottom">
            <Button
                className={'chat-input-send-button'}
                onClick={sendMessage}
                icon={<SendOutlined />}
            ></Button>
        </div>
        </>
    );
};

export default GptInput;
