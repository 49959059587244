import { createSlice } from '@reduxjs/toolkit';


export interface IVfNearMissList {
    info: string;
    error: string;
    operating: boolean;
    list: [];
    status: 'loading' | 'success' | 'failed' | '';
}

const initialState: IVfNearMissList = {
    info: '',
    error: '',
    operating: false,
    list: [],
    status: '',
}


export const vfNearMissSlice = createSlice({
    name: 'vfNearMissSlice',
    initialState,
    reducers: {
    },
});

export default vfNearMissSlice.reducer;
