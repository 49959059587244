import { useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Row, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import '../../../static/css/components/patient-exam-last-letter-gp.scss';
import { localizedText } from '../../localizedText';


const PatientExamLastLetterToGP = () => {
    const { GP_LETTER_TOOLTIP, GP_LETTER_TEXT, LAST_LETTER_TEXT } = localizedText;
    const gpLetterSentDate = useCare1AppSelector(store => store.examData.gp_letter_sent_date);

    return (
        <Card className={'last-letter-to-gp'} bordered={false}>
            <Row>
                <div className="heading">{GP_LETTER_TEXT}</div>
                <Tooltip
                    className="omdr-select-tooltip"
                    placement='topLeft'
                    title={GP_LETTER_TOOLTIP}
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </Row>
            <div className='component-body'>
                <Row>
                    <div className={`gp-letter-date`}>
                        {LAST_LETTER_TEXT}: <span data-testid='gp-letter-date'>{gpLetterSentDate ? gpLetterSentDate : 'None'}</span>
                    </div>
                </Row>
            </div>
        </Card>
    );
}

export default PatientExamLastLetterToGP;