import { Card, Input, Form } from 'antd';
import { setExamDataValue } from '../../../reducers/patient-exam-slice';
import '../../../../static/css/components/patient-exam-cpt.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { FieldData } from 'rc-field-form/lib/interface';



const PatientExamCpt = () => {

    const cpt = useCare1AppSelector(store => store.examData.cpt);
    const [form] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    form.setFieldsValue({cpt});

    // Ant Design handler. This is called whenever a field is changed by the user using Ant Design controls.
    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name.length && !field.validating) {
                switch (field.name[0]) {
                    case 'cpt':
                        dispatch(setExamDataValue(field.name[0], field.value));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    return (
        <Form form={form} onFieldsChange={onFieldsChange}>
            <Card className={`component-input-card cpt`} bordered={false}>
                <span className="heading">{'CPT'}</span>
                <Form.Item help='' name='cpt' initialValue={cpt}>
                    <Input.TextArea
                        data-testid='cpt'
                        className="component-input"
                        autoSize={false}
                        placeholder={''}
                    />
                </Form.Item>
            </Card>
        </Form>
    );
}

export default PatientExamCpt;