import { useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Form, Select } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { calculateBCVAWarningColor } from '../../helpers/patient-exam-convert';
import * as Constants from '../../constants';
import '../../helpers/utilities';
import '../../../static/css/components/_patient-exam-bcva.scss';
import { setExamDataValue, lengthEqual, IOptionItem, IExamData } from '../../reducers/patient-exam-slice';
import { updateGptSetValuesNearMissData } from '../../reducers/gpt-set-values-slice';


type ComponentProps = {
    side: string;
    disabled: boolean;
    narrowColumn?: boolean;
    shortDisplay?: boolean;
    gptSaved?: boolean;
    gptNearMiss?: boolean;
}

const PatientExamBCVA = ({ side, disabled, narrowColumn, shortDisplay, gptSaved, gptNearMiss }: ComponentProps ) => {

    const fieldName = side === 'od' ? 'od_bcva' : 'os_bcva';
    const otherSideFieldName = side === 'os' ? 'od_bcva' : 'os_bcva';

    const bcvaOptions: IOptionItem[] = useCare1AppSelector(store => store.examData.bcva_options, lengthEqual);
    const sideBCVA = useCare1AppSelector(store => store.examData[fieldName]);
    const otherSideBCVA = useCare1AppSelector(store => store.examData[otherSideFieldName]);
    const pastSideBCVA = useCare1AppSelector(store => store.examData[`past_${fieldName}`]);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);

    const bcvaWarningColorClassName = ((userIsOMDC || userIsOMDR || userIsADMIN) && calculateBCVAWarningColor(sideBCVA)) || '';

    // Yellow warning border should display for OD users only
    const userIsOD = useCare1AppSelector(store => store.user.isOD);

    const [ form ] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    // Side effects that we run after component render.
    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        // In the case of an "ND" value, use the past BCVA, if there is a past BCVA.
        if (sideBCVA !== 'ND' || !pastSideBCVA || pastSideBCVA === 'ND') {
            form.setFieldsValue({ [fieldName]: sideBCVA });
        }
        form.validateFields()
    }, [fieldName, sideBCVA, pastSideBCVA, form])

    // Ant Design handler. This is called whenever a field is changed by the user using Ant Design controls.
    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case fieldName:
                        if (field.value !== sideBCVA) {
                            dispatch(setExamDataValue(field.name[0], field.value));
                            dispatch(updateGptSetValuesNearMissData({id: [field.name[0]].toString() as keyof IExamData, value: field.value}));
                        }
                        break;
                    default:
                        break;
                }
            }
        });
    }

    // Set Enucleated option to last position
    const enucleatedOption = bcvaOptions.find(option => option.value === 'ENU');
    const updatedBcvaOptions = enucleatedOption ?
        [...bcvaOptions.filter(option => option.value !== 'ENU'), enucleatedOption] :
        bcvaOptions;

    const gptClassname = () => {
        if (gptNearMiss) {
            return 'exam-gpt-state-red';
        }
        if (gptSaved) {
            return 'exam-gpt-state-yellow';
        }
        return '';
    }
   
    return (
        <Form
            form={form}
            onFieldsChange={onFieldsChange}
            disabled={disabled}
        >
            <div className={`patient-exam-bcva ${narrowColumn ? 'narrow-column' : ''} ${gptClassname()}`}>
                {/* BCVA 001, 002, 003, 004, 005, 006 */}
                <Form.Item
                    name={fieldName}
                    help=""
                    validateStatus={ userIsOD && sideBCVA === 'ND' && otherSideBCVA === 'ND'  ? 'warning' : '' }
                    rules={[{ required: true }]}
                >
                    <Select
                        className={`bcva-select-${side}`}
                        data-testid={`bcva-${side}`}
                        showSearch
                        size="small"
                        disabled={disabled}
                        popupClassName="patient-list-dropdown"
                        placeholder={pastSideBCVA !== 'ND' ? `20/${pastSideBCVA}` : ''}
                    >
                        {updatedBcvaOptions && updatedBcvaOptions.map((option) => (
                            <Select.Option
                                key={option[Constants.REACT_DROPDOWN_VALUE]}
                                value={option[Constants.REACT_DROPDOWN_VALUE]}
                            >
                                <span className={bcvaWarningColorClassName}>
                                    { '0123456789'.indexOf(`${option[Constants.REACT_DROPDOWN_VALUE]}`.charAt(0)) !== -1 ? '20/' : ''}
                                    {shortDisplay
                                        ? option[Constants.REACT_DROPDOWN_VALUE]
                                        : option[Constants.REACT_DROPDOWN_LABEL]
                                    }
                                </span>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
        </Form>
    );
}

export default PatientExamBCVA;