import { getDocxFilename } from "../helpers/patient-exam-convert";
import { userService } from '../services/user-service';

export interface IRequestOptions {
    method: 'POST' | 'GET',
    headers: {'X-CSRFToken': string}
    credentials: 'include',
    body?: FormData | string,
}

export interface IRequest {
  get: (endpoint: string, csrfToken: string) => Promise<{
    status: number;
    data: any;
    headers: Headers;
    url: string;
  }>,
  post: (endpoint:string, csrfToken:string, body?: FormData|string) => Promise<{
      status: number;
      data: any;
      headers: Headers;
      url: string;
    }>
}

export async function httpFileRequest(requestMethod: 'POST' | 'GET', endpoint: string,
  csrfToken: string, body? :FormData|string) {
    const config: IRequestOptions = {
      method: requestMethod,
      credentials: 'include',
      headers: {
        'X-CSRFToken': csrfToken,
      },
    }

    if (body) {
      config.body = body;
    }
    try {
      const response = await fetch(endpoint, config)
      const contentType = response.headers.get("content-type");

      if (response.ok) {
        if (contentType && contentType.includes("application/json")) {
          const data = await response.json();
          if (data && data.success === false){
            throw new Error(data.error)
          }
        }
        const filename = getDocxFilename(response);
        const blob = await response.blob();
        return {
          filename,
          docx: blob,
        }
        // Return a result object similar to Axios
      }
      throw new Error(response.statusText)
    } catch (error) {
        let message = 'Unknown Error'
        if (error instanceof Error) message = error.message
         return Promise.reject(message ? message : 'Error downloading doc')
    }
}

export async function httpRequest(requestMethod: 'POST' | 'GET', endpoint:string,
  csrfToken: string, body?: FormData|string) {
    const config: IRequestOptions = {
      method: requestMethod,
      credentials: 'include',
      headers: {
        'X-CSRFToken': csrfToken,
      },
    }

   if (body) {
      config.body = body;
   }

    let data;
    try {
      const response = await fetch(endpoint, config)
      const contentType = response.headers.get("content-type");

      // Only decode if the response is actually JSON.
      if (contentType && contentType.indexOf("application/json") !== -1) {
        data = await response.json();
      }
      else {
        data = await response.text();
      }

      if (response.ok) {
        // Return a result object similar to Axios
        return {
          status: response.status,
          data,
          headers: response.headers,
          url: response.url,
        }
      }
      else if (response.status === 404) {
        // When backend is logged out, the API always returns a 404. So logout the frontend too.
        await userService.logout(csrfToken);
        window.location.href = '/';
      }
      throw new Error(response.statusText)
    } catch (error) {
        let message = 'Unknown Error'
        if (error instanceof Error) message = error.message
         return Promise.reject(message ? message : data)
    }
}

export const apiRequest:IRequest = {
	get: (endpoint, csrfToken) => httpRequest('GET', endpoint, csrfToken),
	post: (endpoint, csrfToken, body) => httpRequest('POST', endpoint, csrfToken, body)
}