import { KeyboardEvent } from 'react';
import InputNumberSuffix from '../../integrated/input-number-suffix';
import { allowPosNumberOnlyKeys } from '../../../helpers/utilities';
import * as Constants from '../../../constants';
import ExamMetricValidationModal from '../../integrated/metrics/exam-metric-validation-modal';
import { InputNumberProps } from 'antd/lib/input-number';

type ComponentProps = {
    sidePachs: string | number | null;
    onChange: (value: number | string | null) => void;
    gptResultState: string;
}

const GptPatientExamPachs = ({ sidePachs, onChange, gptResultState }: ComponentProps) => {

    const validatePachValue = () => {
        if (typeof (sidePachs) === 'number' && (sidePachs < 200 || sidePachs > 750)) {
            ExamMetricValidationModal(Constants.PACH_VALIDATION_MSG);
        }
    };

    // All Pachs entries should be in the format of #, ## or ###
    const formatValue: InputNumberProps['formatter'] = (value, info) => {
        const regex = /^([1-9]?[0-9]?[0-9]?).*$/;
        const matches = String(value).match(regex);
        return matches && matches[1]
            ? String(Number(`${matches[1]}`))
            : '';
    }

    return (
        <div className={`${gptResultState}`}>
            <InputNumberSuffix
                className="input-component"
                size="small"
                step={1}
                suffix="um"
                formatter={formatValue}
                onKeyPress={(event: KeyboardEvent<HTMLInputElement>): void => allowPosNumberOnlyKeys(event)}
                onBlur={validatePachValue}
                onChange={onChange}
                value={sidePachs}
            />
        </div>
    );
}

export default GptPatientExamPachs;
