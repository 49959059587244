import { Card, Col, Row } from 'antd';

// Components
import PatientExamBCVA from '../../retina/patient-exam-bcva';
import PatientExamRx from './patient-exam-rx';
import PatientExamDtcIOP from './patient-exam-dtc-iop';
import PatientExamDtcHour from './patient-exam-dtc-hour';
import PatientExamIOP from './patient-exam-iop';
import PatientExamIOPInstrument from './patient-exam-iop-instrument';
import PatientExamIshihara from './patient-exam-ishihara';
import PatientExamVertCD from './patient-exam-vert-cd';
import PatientExamPachs from './patient-exam-pachs';
import PatientExamDtcIopRange from './patient-exam-dtc-iop-range';

// Helpers
import { patientIsGlaucoma, patientIsPlaquenil, isOpticNervePatient, isCatPostOpSelected }
    from '../../../helpers/diagnosis-convert';
import * as Constants from '../../../constants';
import '../../../../static/css/components/patient-exam-metrics.scss';
import { localizedText } from '../../../localizedText';
import { useCare1AppSelector } from '../../../apps/care1-hooks';

const PatientExamMetrics = ({ disabled }: {disabled: boolean}) => {

    const { VERT_CD_TEXT, ISHIHARA_TEXT, INSTRUMENT_TEXT, PACHS_TEXT, RX_TEXT, 
        BCVA_TEXT, AUTOFRACTION_SUFFICIENT_TEXT, IOP_TEXT } = localizedText;
        
    const diagnosisEntries = useCare1AppSelector(store => store.diagnosis.entries);
    const odGroupPracticeProvince = useCare1AppSelector(store => store.examData.od_group_practice_province);
    const pastODPachs = useCare1AppSelector(store => store.examData.past_od_pachs);
    const pastOSPachs = useCare1AppSelector(store => store.examData.past_os_pachs);
    const pastODIop = useCare1AppSelector(store => store.examData.past_od_iop);
    const pastOSIop = useCare1AppSelector(store => store.examData.past_os_iop);
    const pastODIshihara = useCare1AppSelector(store => store.examData.past_od_ishihara);
    const pastODIshihara2 = useCare1AppSelector(store => store.examData.past_od_ishihara2);
    const pastOSIshihara = useCare1AppSelector(store => store.examData.past_os_ishihara);
    const pastOSIshihara2 = useCare1AppSelector(store => store.examData.past_os_ishihara2);
    const pastODCD = useCare1AppSelector(store => store.examData.past_od_cd);
    const pastOSCD = useCare1AppSelector(store => store.examData.past_os_cd);
    const pastApplanation = useCare1AppSelector(store => store.examData.past_applanation);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const opticNerve = useCare1AppSelector(store => store.examData.rr_optic_nerve);
    const catPostOp = useCare1AppSelector(store => store.examData.rr_cat_post_op);
    const glc = useCare1AppSelector(store => store.examData.rr_glc);
    const glcS = useCare1AppSelector(store => store.examData.rr_glc_suspect);
    const narrowAngles = useCare1AppSelector(store => store.examData.rr_narrow_angles);
    const gptSavedValues = useCare1AppSelector(store => store.examData.gpt_saved_values);
    const gptNearMissFields = useCare1AppSelector(store => store.examData.gpt_near_miss_fields);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const odIOP = useCare1AppSelector(store => store.examData.od_iop);
    const osIOP = useCare1AppSelector(store => store.examData.os_iop);


    const isGlaucoma = patientIsGlaucoma(diagnosisEntries, {glc, glcS, narrowAngles});
    const isPlaquenil = patientIsPlaquenil(diagnosisEntries);
    const isOpticNerve = isOpticNervePatient(diagnosisEntries, opticNerve);
    const isCatPostOp = isCatPostOpSelected(diagnosisEntries, catPostOp);
    const isGlaucomaManitoba = odGroupPracticeProvince === 'MB' && isGlaucoma;
    const catPostOpClassName = isCatPostOp ? 'cat-color' : ' ';

    // Field should be required if at least one iop has been selected
    const iopInstrumentRequired = (Boolean(odIOP) || Boolean(osIOP)) && userIsOD;

    const displayDtcIopRange = userIsOMDC || userIsOMDR ;

    const getGptSavedValue = (k: string) => {
        if (!userIsADMIN) {
            return false;
        }

        if (gptSavedValues && gptSavedValues !== '') {
            try {
                const gptSavedValuesJson = JSON.parse(gptSavedValues);
                return gptSavedValuesJson[k];
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }

    const getGptNearMissFields = (k: string) => {
        if (!userIsADMIN) {
            return false;
        }

        if (gptNearMissFields && gptNearMissFields !== '') {
            try {
                const gptNearMissFieldsJson = JSON.parse(gptNearMissFields);
                return gptNearMissFieldsJson[k];
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }

    return (
        <Card className={'patient-exam-metrics'} bordered={false}>
            <Row>
                <Col span={isGlaucomaManitoba ? 2 : 3}>
                    <Row className="heading-row">
                        { isGlaucomaManitoba
                            ? <div className="heading warning-asterisk">{IOP_TEXT}</div>
                            : <div className={`heading warning-asterisk ${catPostOpClassName}`}>{IOP_TEXT}</div>
                        }
                    </Row>
                    <Row className={'metric-row iop-entry'}>
                        <PatientExamIOP
                            side={Constants.OD_IOP}
                            disabled={disabled}
                            suffix={!isGlaucomaManitoba}
                            placeholderValue={pastODIop}
                            gptSaved={getGptSavedValue('od_iop')}
                            gptNearMiss={getGptNearMissFields('od_iop')}
                        />
                    </Row>
                    <Row className={'metric-row iop-entry'}>
                        <PatientExamIOP
                            side={Constants.OS_IOP}
                            disabled={disabled}
                            suffix={!isGlaucomaManitoba}
                            placeholderValue={pastOSIop}
                            gptSaved={getGptSavedValue('os_iop')}
                            gptNearMiss={getGptNearMissFields('os_iop')}
                        />
                    </Row>
                </Col>
                { isGlaucomaManitoba &&
                <Col span={ isGlaucomaManitoba && (isPlaquenil || isOpticNerve) ? 2 :  3 }>
                    <Row className="heading-row">
                        { isGlaucomaManitoba && (isPlaquenil || isOpticNerve)
                            ? <div className="heading warning-asterisk">DTC</div>
                            : <div className="heading warning-asterisk">DTC</div>
                        }
                    </Row>
                    <Row className={'metric-row'}>
                        {/* EM 005, 006 */}
                        <PatientExamDtcHour disabled={disabled} />
                    </Row>
                    <Row className={'metric-row'}>
                        <PatientExamIOPInstrument
                            disabled={disabled}
                            narrowColumn={isPlaquenil && isGlaucomaManitoba}
                            verbose={false}
                            gptSaved={getGptSavedValue('applanation')}
                            gptNearMiss={getGptNearMissFields('applanation')}
                            placeholderValue={pastApplanation}
                        />
                    </Row>
                </Col>
                }
                { isGlaucomaManitoba && !displayDtcIopRange &&
                    <Col span={5}>
                            {/* EM 007, 008 */}
                        <Row>
                            <Col span={8}>
                                <Row className="heading-row patient-exam-metrics">
                                    <div className="heading">IOP2</div>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row className="heading-row patient-exam-metrics">
                                    <div className="heading">IOP3</div>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row className="heading-row patient-exam-metrics">
                                    <div className="heading">IOP4</div>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="dtc-iop-od metric-row">
                            <PatientExamDtcIOP side="od" disabled={disabled} />
                        </Row>
                        <Row className={' dtc-iop-os metric-row'}>
                            <PatientExamDtcIOP side="os" disabled={disabled} />
                        </Row>
                    </Col>
                }
                { isGlaucomaManitoba && displayDtcIopRange &&
                    <Col span={5}>
                            {/* EM 004 */}
                        <Row>
                            <Col span={12}>
                                <Row className="heading-row">
                                    <div className="heading">DTC Low</div>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row className="heading-row">
                                    <div className="heading">DTC High</div>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="dtc-iop-range od">
                            <Col span={12}>
                                <PatientExamDtcIopRange side="od" isLow={true} />
                            </Col>
                            <Col span={12}>
                                <PatientExamDtcIopRange side="od" isLow={false} />
                            </Col>
                        </Row>
                        <Row className="dtc-iop-range os">
                            <Col span={12}>
                                <PatientExamDtcIopRange side="os" isLow={true} />
                            </Col>
                            <Col span={12}>
                                <PatientExamDtcIopRange side="os" isLow={false} />
                            </Col>
                        </Row>
                    </Col>
                }        
                { !isGlaucomaManitoba &&
                <Col span={4}>
                    <Row className="heading-row">
                        <div className={`heading ${iopInstrumentRequired ? 'warning-asterisk' : ''}`}>{INSTRUMENT_TEXT}</div>
                    </Row>
                    <Row className={'metric-row instrument-span-two-rows'}>
                        <PatientExamIOPInstrument
                            disabled={disabled}
                            verbose={true}
                            narrowColumn={false}
                            gptSaved={getGptSavedValue('applanation')}
                            gptNearMiss={getGptNearMissFields('applanation')}
                            placeholderValue={pastApplanation}
                        />
                    </Row>
                </Col>
                }
                <Col span={isOpticNerve || isPlaquenil || isGlaucomaManitoba ? 2 : 3}>
                    <Row className="heading-row">
                        <div className="heading warning-asterisk">{PACHS_TEXT}</div>
                    </Row>
                    <Row className={'metric-row'}>
                        <PatientExamPachs disabled={disabled} side={'od'} placeholderValue={pastODPachs} 
                            gptSaved={getGptSavedValue('od_pachs')} 
                            gptNearMiss={getGptNearMissFields('od_pachs')}/>
                    </Row>
                    <Row className={'metric-row'}>
                        <PatientExamPachs disabled={disabled} side={'os'} placeholderValue={pastOSPachs} 
                            gptSaved={getGptSavedValue('os_pachs')}
                            gptNearMiss={getGptNearMissFields('os_pachs')}/>
                    </Row>
                </Col>
                <Col span={isOpticNerve || isPlaquenil || isGlaucomaManitoba ? 2 : 3}>
                    <Row className="heading-row">
                        <div className="heading vert-cd warning-asterisk">{VERT_CD_TEXT}</div>
                    </Row>
                    <Row className={'metric-row'}>
                        <PatientExamVertCD disabled={disabled} side={'od'} placeholderValue={pastODCD} 
                            gptSaved={getGptSavedValue('od_cd')}
                            gptNearMiss={getGptNearMissFields('od_cd')}/>
                    </Row>
                    <Row className={'metric-row'}>
                        <PatientExamVertCD disabled={disabled} side={'os'} placeholderValue={pastOSCD} 
                            gptSaved={getGptSavedValue('os_cd')}
                            gptNearMiss={getGptNearMissFields('os_cd')}/>
                    </Row>
                </Col>
                { (isOpticNerve || isPlaquenil) &&
                    <Col span={3}>
                            {/* EM 001, 002, 003, 004 */}
                        <Row className="heading-row">
                            <div className="heading warning-asterisk">{ISHIHARA_TEXT}</div>
                        </Row>
                        <Row className={'metric-row'}>
                            <PatientExamIshihara side={Constants.OD} disabled={disabled}
                                ishiharaPlaceholderValue={pastODIshihara}
                                ishiharaPlaceholderValue2={pastODIshihara2}
                            />
                        </Row>
                        <Row className={'metric-row'}>
                            <PatientExamIshihara side={Constants.OS} disabled={disabled}
                                ishiharaPlaceholderValue={pastOSIshihara}
                                ishiharaPlaceholderValue2={pastOSIshihara2}
                            />
                        </Row>
                    </Col>
                }
                <Col
                    span={(isPlaquenil || isOpticNerve) && isGlaucomaManitoba ? 5 : 7 }
                    className={(isPlaquenil || isOpticNerve) && isGlaucomaManitoba ? 'narrow-rx-columns' : '' }
                >
                    <Row className="heading-row">
                        <div className="heading warning-asterisk">
                        {RX_TEXT}
                        {isCatPostOp &&
                            <span className={`rx ${catPostOpClassName}`}>
                                *{AUTOFRACTION_SUFFICIENT_TEXT}
                            </span>}
                        </div>
                        
                    </Row>
                    <Row className={'metric-row'}>
                        <PatientExamRx disabled={disabled} side='od' 
                            gptSavedSphere={getGptSavedValue('od_sphere')}
                            gptSavedCylinder={getGptSavedValue('od_cylinder')}
                            gptSavedAxis={getGptSavedValue('od_axis')}
                            gptNearMissSphere={getGptNearMissFields('od_sphere')}
                            gptNearMissCylinder={getGptNearMissFields('od_cylinder')}
                            gptNearMissAxis={getGptNearMissFields('od_axis')}/>
                    </Row>
                    <Row className={'metric-row'}>
                        <PatientExamRx disabled={disabled} side='os' 
                            gptSavedSphere={getGptSavedValue('os_sphere')}
                            gptSavedCylinder={getGptSavedValue('os_cylinder')}
                            gptSavedAxis={getGptSavedValue('os_axis')}
                            gptNearMissSphere={getGptNearMissFields('os_sphere')}
                            gptNearMissCylinder={getGptNearMissFields('os_cylinder')}
                            gptNearMissAxis={getGptNearMissFields('os_axis')}/>
                    </Row>
                </Col>
                <Col span={ (isPlaquenil || isOpticNerve) || isGlaucomaManitoba ? 3 : 4 }>
                    <Row className="heading-row">
                        <div className="heading warning-asterisk">{BCVA_TEXT}</div>
                    </Row>
                    <Row className={'metric-row'}>
                        <PatientExamBCVA
                            disabled={disabled}
                            side='od'
                            shortDisplay
                            narrowColumn={isPlaquenil || isGlaucomaManitoba}
                            gptSaved={getGptSavedValue('od_bcva')}
                            gptNearMiss={getGptNearMissFields('od_bcva')}
                        />
                    </Row>
                    <Row className={'metric-row'}>
                        <PatientExamBCVA
                            disabled={disabled}
                            side='os'
                            shortDisplay
                            narrowColumn={isPlaquenil || isGlaucomaManitoba}
                            gptSaved={getGptSavedValue('os_bcva')}
                            gptNearMiss={getGptNearMissFields('os_bcva')}
                        />
                    </Row>
                </Col>
            </Row>
        </Card>
    );
}

export default PatientExamMetrics;