import { useEffect, useState } from 'react';
import { useCare1AppDispatch } from '../apps/care1-hooks';
import { Layout, Spin, Row, Col, Modal, Button, Input } from 'antd';
import { useParams, useNavigate } from 'react-router';
import * as Constants from '../constants';
import { localizedText } from '../localizedText';
import {
    SearchOutlined
} from '@ant-design/icons';

// Components
import NavigationControlBar from '../components/navigation-control-bar';
import NavigationTopBar from '../components/navigation-top-bar';
import AdminPatientListTable from '../components/admin/upload-reconciler/patient-matching-list-table';
import {
    useLazyGetUploadReconcilerAdminPatientListQuery, useMatchReconcilerPatientPatientsMutation,
    IUploadReconcilerMatchPatientsRequest, IUploadReconcilerMatchPatientsRequestItem
} from '../services/upload-reconciler-api';

// Helpers
import '../../static/css/pages/_patient-list-page.scss';
import '../../static/css/pages/admin-patient-exam-page.scss';
import '../../static/css/pages/upload-reconciler-page.scss';
import '../../static/css/shared/patient-list-control-bar.scss';


// Actions

const { Header, Content } = Layout;

const AdminPatientMergePage = () => {

    const { MONTHS } = localizedText;

    // Redux and React Router Hooks
    const dispatch = useCare1AppDispatch();
    const params = useParams();
    const history = useNavigate();

    const [getUploadReconcilerAdminPatientListQuery, { data: patientMatchingList = [], isFetching: isAdminPatientListFetching }] = useLazyGetUploadReconcilerAdminPatientListQuery();

    const [matchReconcilerPatientPatients, { isLoading: isPatientMatchLoading }] = useMatchReconcilerPatientPatientsMutation();

    const [patientMatchingOps, setPatientMatchingOps] = useState<{ [v: number]: string }>({});

    useEffect(() => {
        setPatientMatchingOps({});
    }, [])

    const sendSearchPatientListRequest = async (value: string) => {
        if (value) {
            await getUploadReconcilerAdminPatientListQuery({
                name: value
            });
        }
    }

    const mergePatients = async () => {

        const items: IUploadReconcilerMatchPatientsRequestItem[] = [];
        for (const [key, value] of Object.entries(patientMatchingOps)) {
            items.push({
                id: Number(key),
                op: value,
            })
        }

        const patientData: IUploadReconcilerMatchPatientsRequest = {
            items: items
        }

        try {

            const response = await matchReconcilerPatientPatients(patientData).unwrap();
            if (response.success) {
                setPatientMatchingOps({});
                // await reMatch();
            } else {
                throw new Error(response?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    const shouldDisableMergingButton = () => {

        let targetCount = 0;
        let deleteCount = 0;
        for (const [key, value] of Object.entries(patientMatchingOps)) {
            if (value === 'target') {
                targetCount++;
            }
            if (value === 'delete') {
                deleteCount++;
            }
        }

        if (targetCount === 1 && deleteCount >= 1) {
            return false;
        }
        return true;
    }

    return (
        <Layout>
            <Spin
                className="loading-spinner"
                spinning={isAdminPatientListFetching}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Header>
                    <NavigationTopBar className="navigation-top-bar" />
                    <NavigationControlBar className="patient-list-navigation-control-bar" />
                </Header>
                <Content>
                    <div className='patient-list-page user-is-admin upload-reconciler-page'>
                        <div className='patient-list-control-bar'>
                            <div className='control-bar-elements '>
                                <Row>
                                    <Col span={22}>
                                        <Input.Search
                                            className="patient-list-search-input"
                                            allowClear
                                            placeholder={localizedText['PATIENT_LIST_SEARCH_PLACEHOLDER']}
                                            prefix={<SearchOutlined />}
                                            onSearch={sendSearchPatientListRequest}
                                        />
                                    </Col>
                                    <Col span={2}>
                                        <div className='patient-reconciliation'>
                                            <div className='button-container'>
                                                <Button
                                                    disabled={shouldDisableMergingButton()}
                                                    className='merge-patients-button'
                                                    onClick={mergePatients}
                                                >Merge Patients</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className='patient-reconciliation'>
                            <Row>
                                <Col span={24}>
                                    <AdminPatientListTable
                                        tableList={patientMatchingList}
                                        patientMatchingOps={patientMatchingOps}
                                        setPatientMatchingOps={setPatientMatchingOps}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Content>
            </Spin>
        </Layout>
    );

}

export default AdminPatientMergePage;