import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { logout } from './user-slice';
import { getBackendMediaUrl } from '../helpers/media-image-convert';
import { getCsrfToken } from '../helpers/utilities';
import { apiRequest } from '../services/api-request';
import { dropzoneSlice } from './dropzone-slice';

export interface IUSInsurance {
    usInsuranceModalOpen: boolean,
    billingAddress: string,
    payorId: string,
    memberId: string,
    groupId: string,
    copay: string,
    deductible: string,
    cardFrontImage: string,
    cardBackImage: string,
    insuranceList: IInsuranceListItem [],
    id: number | null,
    company: string,
    status: string,
    error: string,
    formOperating: boolean,
}

interface IInsuranceListItem {
    company: string,
    key: number,
    payor_id: string,
    status: string,
}

const initialState: IUSInsurance = {
    usInsuranceModalOpen: false,
    billingAddress: '',
    payorId: '',
    memberId: '',
    groupId: '',
    copay: '',
    deductible: '',
    cardFrontImage: '',
    cardBackImage: '',
    insuranceList: [],
    id: null,
    company: '',
    status: 'idle',
    error: '',
    formOperating: false,
}

// GET: Request a US Insurance object by patient ID
export const getUsInsuranceListRequest = createAsyncThunk(
    'usInsurance/getUsInsuranceList',
    async (patientId: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patient/${patientId.toString()}/us/insurance/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);

            if (response.data.insurance_list && response.data.insurance_list.length > 0) {
                // get the first insurance details from the insurance list
                dispatch(getUsInsuranceRequest(response.data.insurance_list[0].key));
            }

            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

// GET: Request a US Insurance object by us_insurance PK
export const getUsInsuranceRequest = createAsyncThunk(
    'usInsurance/getUsInsurance',
    async (insuranceId: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/us/insurance/${insuranceId.toString()}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

// GET: Same request as getUsInsuranceRequest with different logic in the success action
export const getUsInsuranceImageRequest = createAsyncThunk(
    'usInsurance/getUsInsuranceImage',
    async (insuranceId: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/us/insurance/${insuranceId.toString()}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

// POST: Update a us_insurance object by PK
export const editInsuranceRequest = createAsyncThunk(
    'usInsurance/editInsurance',
    async (editData: { insuranceId: number, value: IUSInsurance }, {dispatch, getState, rejectWithValue}) => {
        const { insuranceId, value } = editData;
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/us/insurance/${insuranceId.toString()}/`;
        const data = JSON.stringify(value);

        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

// POST: Create a us_insurance object by patient ID
export const createInsuranceRequest = createAsyncThunk(
    'usInsurance/createInsurance',
    async (patientId: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patient/${patientId.toString()}/us/insurance/add/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, '');
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

// POST: Set a us_insurance object 'is_active' field by insurance ID
export const setInsurancePolicyActiveRequest = createAsyncThunk(
    'usInsurance/setInsurancePolicyActive',
    async ( requestData : {
            patientId: number,
            insuranceId: number,
            makeActive: boolean
        }, {dispatch, getState, rejectWithValue}) => {
        const { patientId, insuranceId, makeActive } = requestData;
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/us/insurance/activate/`;
        const data = new FormData();
        data.append('id', insuranceId.toString());
        data.append('is_active', makeActive.toString());

        try {
            const response = await apiRequest.post(URL, csrfToken, data);

            if(response){
                dispatch(getUsInsuranceListRequest(patientId))
            }
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

// Open and close the Insurance modal.
// Clear the insurance images everytime the modal is opened or closed
export const toggleInsuranceModal = () => (dispatch: Dispatch) => {
    const cardFrontImage = dropzoneSlice['cardFrontImage']?.actions;
    const cardBackImage = dropzoneSlice['cardBackImage']?.actions;
    dispatch(toggleUsInsuranceModal());
    dispatch(cardFrontImage.clearDropzone());
    dispatch(cardBackImage.clearDropzone());
}

export const usInsuranceSlice = createSlice({
    name: 'usInsurance',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setUsInsuranceDataValue: (state, action) => {
            // sample action.payload {key:'memberId', value: '12345'}
            if(action.payload){
                state = { ...state, ...action.payload };
            }
        },
        resetUsInsurance: () => initialState,
        toggleUsInsuranceModal: (state) => {
            state.usInsuranceModalOpen = !state.usInsuranceModalOpen;
        },
    },
    extraReducers: (builder) => {

        // getUsInsuranceListRequest (by patient ID)
        builder.addCase(getUsInsuranceListRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getUsInsuranceListRequest.fulfilled, (state, action) => {
            state.status = 'success';
            state.insuranceList = action.payload['insurance_list'];
        });
        builder.addCase(getUsInsuranceListRequest.rejected, (state, action) => {
            state.status = 'failed';
            Modal.error({
                className: 'info-modal',
                title: `Errors getting US insurance list. ${action.payload}`,
            })
        });

        // getUsInsuranceRequest (by us_insurance PK)
        builder.addCase(getUsInsuranceRequest.pending, (state) => {
            state.status = 'loading';
            state.formOperating = true;
        });
        builder.addCase(getUsInsuranceRequest.fulfilled, (state, action) => {
            state.status = 'success';
            state.formOperating = false;
            const data = action.payload;
            state.id = data['id'];
            state.billingAddress = data['billing_address'];
            state.company = data['company'];
            state.copay = data['copay'];
            state.deductible = data['deductible'];
            state.groupId = data['group_id'];
            state.memberId = data['member_id'];
            state.payorId = data['payor_id'];
            state.cardBackImage = data['card_back_image'] ? getBackendMediaUrl() + data['card_back_image'] : '';
            state.cardFrontImage = data['card_front_image'] ? getBackendMediaUrl() + data['card_front_image']: '';
        });
        builder.addCase(getUsInsuranceRequest.rejected, (state, action) => {
            state.status = 'failed';
            state.formOperating = false;
            Modal.error({
                className: 'info-modal',
                title: `Errors getting US insurance. ${action.payload}`,
            })
        });

        // getUsInsuranceImageRequest (by us_insurance PK)
        builder.addCase(getUsInsuranceImageRequest.pending, (state) => {
            state.status = 'loading';
            state.formOperating = true;
        });
        builder.addCase(getUsInsuranceImageRequest.fulfilled, (state, action) => {
            state.status = 'success';
            state.formOperating = false;
            const data = action.payload;
            state.cardBackImage = data['card_back_image'] ? getBackendMediaUrl() + data['card_back_image'] : '';
            state.cardFrontImage = data['card_front_image'] ? getBackendMediaUrl() + data['card_front_image']: '';
        });
        builder.addCase(getUsInsuranceImageRequest.rejected, (state, action) => {
            state.status = 'failed';
            state.formOperating = false;
            Modal.error({
                className: 'info-modal',
                title: `Errors getting US insurance (for images). ${action.payload}`,
            })
        });

        // editInsuranceRequest (by us_insurance PK)
        builder.addCase(editInsuranceRequest.pending, (state) => {
            state.status = 'loading';
            state.formOperating = true;
        });
        builder.addCase(editInsuranceRequest.fulfilled, (state, action) => {
            state.status = 'success';
            state.formOperating = false;
            Modal.info({
                className: 'info-modal',
                title: 'Insurance is saved successfully!',
            });
        });
        builder.addCase(editInsuranceRequest.rejected, (state, action) => {
            state.status = 'failed';
            state.formOperating = false;
            Modal.error({
                className: 'info-modal',
                title: `Errors updating US insurance. ${action.payload}`,
            })
        });

        // createInsuranceRequest (by patient ID)
        builder.addCase(createInsuranceRequest.pending, (state) => {
            state.status = 'loading';
            state.formOperating = true;
        });
        builder.addCase(createInsuranceRequest.fulfilled, (state, action) => {
            state.status = 'success';
            state.formOperating = false;
            Modal.info({
                className: 'info-modal',
                title: 'Insurance created successfully!',
            });
        });
        builder.addCase(createInsuranceRequest.rejected, (state, action) => {
            state.status = 'failed';
            state.formOperating = false;
            Modal.error({
                className: 'info-modal',
                title: `Errors creating US insurance. ${action.payload}`,
            })
        });

        // setInsurancePolicyActiveRequest (by insurance ID)
        builder.addCase(setInsurancePolicyActiveRequest.pending, (state) => {
            state.status = 'loading';
            state.formOperating = true;
        });
        builder.addCase(setInsurancePolicyActiveRequest.fulfilled, (state) => {
            state.status = 'success';
            state.formOperating = false;
            Modal.info({
                className: 'info-modal',
                title: 'Insurance activation successfully modified!',
            });
        });
        builder.addCase(setInsurancePolicyActiveRequest.rejected, (state, action) => {
            state.status = 'failed';
            state.formOperating = false;
            Modal.error({
                className: 'info-modal',
                title: `Errors update US insurance is_active field. ${action.payload}`,
            })
        });
    }
});
// export the actions related to UsInsurance
export const { setUsInsuranceDataValue, resetUsInsurance, toggleUsInsuranceModal
    } = usInsuranceSlice.actions;

export default usInsuranceSlice.reducer;