import { useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { Select, Form } from 'antd';
import { IExamData, setExamDataValue } from '../../../reducers/patient-exam-slice';
import { updateGptSetValuesNearMissData } from '../../../reducers/gpt-set-values-slice';
import { useFormValidation } from '../../../context/useFormValidationContext';
import '../../../../static/css/components/patient-exam-iop-instrument.scss';
import { VERBOSE_APPLANATION_OPTIONS } from '../../../constants';
import { FieldData } from 'rc-field-form/lib/interface';

type ComponentProps = {
    disabled: boolean;
    narrowColumn: boolean;
    verbose: boolean;
    gptSaved?: boolean;
    gptNearMiss?: boolean;
    placeholderValue: string | undefined;
  }

const PatientExamIOPInstrument = ({disabled, narrowColumn, verbose, gptSaved, gptNearMiss, placeholderValue}: ComponentProps) => {
    const shortApplanationOptions = useCare1AppSelector(store => store.options.applanations);
    const applanationOptions = verbose ? VERBOSE_APPLANATION_OPTIONS : shortApplanationOptions;
    const odIOP = useCare1AppSelector(store => store.examData.od_iop);
    const osIOP = useCare1AppSelector(store => store.examData.os_iop);
    const applanation = useCare1AppSelector(store => store.examData.applanation);
    const [ form ] = Form.useForm();
    const dispatch = useCare1AppDispatch();
    const { setInstrumentForm } = useFormValidation();

    // Red required border should display for OD users only
    const userIsOD = useCare1AppSelector(store => store.user.isOD);

    // Side effects that we run after component render.
    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            applanation: applanation || null,
        };
        form.setFieldsValue(newValidationFieldValues);

        // The component should validate on every render
        form.validateFields();

        // pass the form instance to useFormValidation hooks
        setInstrumentForm(form);
        // clean up function when iop instrument component unmounts
        return () => {
            setInstrumentForm(null);
        }
    }, [applanation, form, setInstrumentForm]);

    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case 'applanation':
                        if (applanation !== field.value) {
                            dispatch(setExamDataValue(field.name[0], field.value));
                            dispatch(updateGptSetValuesNearMissData({id: [field.name[0]].toString() as keyof IExamData, value: field.value}));
                        }
                        break;
                    default:
                        break;
                }
            }
        });
    };

    // Field should be required if at least one iop has been selected
    const required = Boolean(odIOP) || Boolean(osIOP);

    const gptClassname = () => {
        if (gptNearMiss) {
            return 'exam-gpt-state-red';
        }
        if (gptSaved) {
            return 'exam-gpt-state-yellow';
        }
        return '';
    }

    return (
        <Form form={form} onFieldsChange={onFieldsChange} >
            <div className={gptClassname()}>
                <Form.Item className={`iop-instrument ${narrowColumn ? 'narrow-column' : ''}`} colon={false} help=""
                    name={'applanation'}
                    rules={[{
                        required: userIsOD && required,
                    }]} >
                    {/* IOP-INST 001, IOP-INST 002, IOP-INST 003, IOP-INST 004 */}
                        <Select
                            data-testid='iop-instrument-select'
                            size={'small'}
                            disabled={disabled}
                            popupClassName="applanation-list-dropdown"
                            showSearch
                            placeholder={applanationOptions ? applanationOptions.find(option => option.value === placeholderValue)?.label : ''}
                        >
                            {
                                applanationOptions && applanationOptions.map((option: {value: string, label: string}) => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                </Form.Item>
            </div>
        </Form>
    );
}

export default PatientExamIOPInstrument;
