import { Card, Col, Row } from 'antd';
import { useCare1AppSelector } from '../apps/care1-hooks';
import '../../static/css/components/glaucoma-worksheet.scss';
import DeepMDBand from './reviewer/deep-md/patient-exam-deep-md';
import PatientExamGlaucomaScore from './integrated/patient-exam-glaucoma-score';
import PatientExamStereoViewer from './integrated/patient-exam-stereo-viewer';
import IopBcvaGraphBand from './integrated/iop-bcva-graph-band';
import PatientExamVFImageHistory from './image-histories/patient-exam-vf-image-history';
import PatientExamOCTRNFLImageHistory from './image-histories/patient-exam-oct-rnfl-image-history';
import DeepMDBandOD from './integrated/deepMdOd/deepmd-od-band';


const GlaucomaWorksheet = () => {

    const firstName = useCare1AppSelector(store => store.patientDetails.details.first_name);
    const lastName = useCare1AppSelector(store => store.patientDetails.details.last_name);
    const dob = useCare1AppSelector(store => store.patientDetails.details.dob_date);
    const age = useCare1AppSelector(store => store.patientDetails.details.age);
    const isOD = useCare1AppSelector(store => store.user.isOD);

    return (
        <Row gutter={[16, 8]}>
            <Col span={24}>
                <Card
                    bordered={false}
                >
                    <Row gutter={[16, 8]}>
                        <Col className='patient-name' span={24}>
                            {firstName}, {lastName}
                        </Col>
                        <Col className='dob-label'>
                            {dob} ({age})
                        </Col>
                    </Row>
                </Card>
            </Col>
            
            <Col span={24}>
                {
                    isOD ? 
                    <DeepMDBandOD /> :
                    <DeepMDBand />
                }   
                
            </Col>
            <Col span={24}>
                <PatientExamGlaucomaScore page='glaucoma_worksheet_page' />
            </Col>
            <Col span={24}>
                <PatientExamStereoViewer inGlaucomaWorksheet={true} />
            </Col>
            <Col span={24}>
                <IopBcvaGraphBand page={'glaucoma_worksheet_page'} />
            </Col>
            <Col span={24}>
                <Row gutter={8}>
                    <PatientExamVFImageHistory />
                </Row>
                <Row gutter={8}>
                    <PatientExamOCTRNFLImageHistory />
                </Row>
            </Col>
        </Row>
    );
}

export default GlaucomaWorksheet;
