import { baseApi } from './base-api'

export interface IServicesHealthCheckSummaryResponse {
    health_check_list: {
        summary: {
            rows: [],
            days: [],
        }
    };
}

export const servicesHealthCheckApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getServicesHealthCheckSummary: builder.query<IServicesHealthCheckSummaryResponse, void>({
            query: () => 'admin/services_health_check/summary/',

            // Give a tag to staffs so that other API actions can invalidate ServicesHealthCheckSummary
            providesTags: [{ type: 'ServicesHealthCheckSummary', id: 'SUMMARY' }]
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const { useGetServicesHealthCheckSummaryQuery } = servicesHealthCheckApi;