import { Checkbox, Col, Flex, Row, Typography } from 'antd';
import styles from '../../../../static/css/components/smart-upload-modal.module.scss';

import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { PLEASE_CONFIRM_LABEL_TEXT } from '../../../constants';
import useSmartUploadOnChangeSave from '../../../hooks/useOnChangeSave';
import { localizedText } from '../../../localizedText';
import SmartUploadConfirmationModal from './smart-upload-confirmation-modal';
import GpEngagement from './smart-upload-gp-engagement';
import SmartUploadTypeCare1 from './smart-upload-upload-type-care1';

const SmartUploadStep3 = () => {
    const { REQUIRED_FIELDS_LABEL_TEXT } = localizedText;
    const odSendGpLetters = useCare1AppSelector(
        (store) => store.user.odSendGpLetters
    );
    const confirmChecked = useCare1AppSelector(
        (store) => store.smartUpload.please_confirm
    );
    const disabled = useCare1AppSelector((store) => store.smartUpload.disabled);

    const { handleValueChange } = useSmartUploadOnChangeSave();

    return (
        <>
            <Row gutter={[0, 8]}>
                <Col span={24}>
                    <Row className={styles.highPriorityRow} gutter={[0, 8]}>
                        <SmartUploadTypeCare1 />
                    </Row>
                </Col>
                {odSendGpLetters && (
                    <Col span={24}>
                        <GpEngagement />
                    </Col>
                )}

                <Col span={24}>
                    <Typography.Text italic className={styles.consentsText}>
                        Patient consents to digital communication such as email
                        to discuss care plans; shared care with other providers
                        such as OMDs and GPs; and use of de-identified chart
                        information for research and presentations.
                    </Typography.Text>
                </Col>
                <Col span={24}>
                    <Flex justify='space-between' align='center'>
                        <Checkbox
                            className={styles.confirmCheckbox}
                            data-testid='confirm-checkbox'
                            checked={confirmChecked}
                            disabled={disabled}
                            onChange={(e) =>
                                handleValueChange({
                                    please_confirm: e.target.checked,
                                })
                            }
                        >
                            {PLEASE_CONFIRM_LABEL_TEXT}
                        </Checkbox>
                        <Typography.Text className={styles.requiredFieldText}>
                            {REQUIRED_FIELDS_LABEL_TEXT}
                        </Typography.Text>
                    </Flex>
                </Col>
            </Row>
            <SmartUploadConfirmationModal />
        </>
    );
};

export default SmartUploadStep3;
