import { Checkbox, Col, Flex, Form, message, Row, Select, Space, Typography } from 'antd';
import styles from '../../../../static/css/components/gp-engagement.module.scss';

import { EditOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useMemo } from 'react';
import InputMask from 'react-input-mask';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { ERROR_INVALID_PHONE_FORMAT, GP_CURRENT_FIELD, GP_FAX_INFO_TEXT, GP_FAX_NUMBER_FIELD, GP_FAX_NUMBER_LABEL_TEXT, GP_FAX_PLACEHOLDER_TEXT, GP_LABEL_TEXT, GP_PLACEHOLDER_TEXT, PHONE_MASK_FORMAT } from '../../../constants';
import { formatGPName } from '../../../helpers/patient-edit-convert';
import useSmartUploadOnChangeSave from '../../../hooks/useOnChangeSave';
import { localizedText } from '../../../localizedText';
import { openAddGPModal, openEditGPModal, setAddEditGpFieldData } from '../../../reducers/patient-edit-slice';
import { updateSmartUploadGp } from '../../../reducers/smart-upload-slice';
import { useGetGpsByProvinceQuery } from '../../../services/doctor-api';
import PatientAddGPModal from '../../retina/patient-add-gp-modal';
import PatientEditGPModal from '../../retina/patient-edit-gp-modal';

const fullColWidth = {span: 24};

const GpEngagement = () => {
    const { NEW_GP_TEXT } = localizedText;
    const odProvince = useCare1AppSelector((store) => store.user.odProvince);
    const currentGp = useCare1AppSelector((store) => store.smartUpload.gp);
    const gpFax = useCare1AppSelector((store) => store.smartUpload.gp_fax);
    const smartUploadId = useCare1AppSelector((store) => store.smartUpload.id);
    const hasNoGp = useCare1AppSelector((store) => store.smartUpload.has_no_gp);
    const disabled = useCare1AppSelector((store) => store.smartUpload.disabled);


    const dispatch = useCare1AppDispatch();

    const [form] = Form.useForm();
    const gp = Form.useWatch(GP_CURRENT_FIELD, form);
    const { data: gpList = [] } = useGetGpsByProvinceQuery(odProvince, {
        skip: !odProvince,
    });
    const { handleValueChange } = useSmartUploadOnChangeSave();

    useEffect(() => {
        form.setFieldsValue({
            [GP_CURRENT_FIELD]: currentGp,
            [GP_FAX_NUMBER_FIELD]: gpFax,
            has_no_gp: hasNoGp,
        });
    }, [form, currentGp, gpFax, hasNoGp]);

    const gpOptions = useMemo(
        () =>
            gpList.map((gp) => ({
                id: gp.id,
                value: gp.id,
                label: formatGPName(gp),
            })),
        [gpList]
    );

    // gp is a special case and should be handled seprately
    const handleGpChange = async (gpId: number | null) => {
        try {
            const res = await dispatch(
                updateSmartUploadGp({
                    id: smartUploadId!,
                    gp_id: gpId,
                })
            ).unwrap();
            if (res?.error) {
                throw Error(res?.error);
            }
        } catch (error) {
            console.error(error);
            message.error('Errors updating GP');
        }
    };

    const GpLabel = (
        <Flex justify='space-between' align='center' className={styles.gpLabel}>
            <span>{GP_LABEL_TEXT}</span>
            <Flex align='center'>
                {!gp && (
                    <Form.Item name='has_no_gp' noStyle valuePropName='checked'>
                        <Checkbox
                            className={styles.hasNoGp}
                            onChange={(e) =>
                                handleValueChange({has_no_gp:e.target.checked})
                            }
                        >
                            No GP
                        </Checkbox>
                    </Form.Item>
                )}

                {gp && (
                    <div
                        data-testid='edit-gp-onclick'
                        className={styles.gpButton}
                        onClick={() => {
                            dispatch(setAddEditGpFieldData(GP_CURRENT_FIELD));
                            dispatch(openEditGPModal());
                        }}
                    >
                        <EditOutlined />
                        <span>Edit GP</span>
                    </div>
                )}
                <div
                    data-testid='new-gp-onclick'
                    className={styles.gpButton}
                    onClick={() => {
                        dispatch(setAddEditGpFieldData(GP_CURRENT_FIELD));
                        dispatch(openAddGPModal());
                    }}
                >
                    <PlusOutlined />
                    <span>{NEW_GP_TEXT}</span>
                </div>
            </Flex>
        </Flex>
    );

    return (
        <Form form={form} disabled={disabled}>
            <Flex className={styles.gpEngagement} vertical>
                <Space>
                    <Typography.Text>GP Engagement</Typography.Text>
                    <Typography.Text italic className={styles.optionalText}>
                        (optional)
                    </Typography.Text>
                </Space>
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        {/* PE 013, PE 017 */}
                        <Form.Item
                            label={GpLabel}
                            labelCol={fullColWidth}
                            hasFeedback
                            help=''
                            colon={false}
                            name={GP_CURRENT_FIELD}
                            initialValue={null}
                        >
                            <Select
                                data-testid='gp-input'
                                placeholder={GP_PLACEHOLDER_TEXT}
                                className='patient-gp-input'
                                showSearch
                                allowClear={true}
                                options={gpOptions}
                                optionFilterProp='label'
                                onChange={handleGpChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {/* PE 014, PE 018 */}
                        <Form.Item
                            label={GP_FAX_NUMBER_LABEL_TEXT}
                            className='gp-fax-number-form'
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            validateTrigger={'onBlur'}
                            name={GP_FAX_NUMBER_FIELD}
                            rules={[
                                {
                                    pattern: /^[^_]+$/,
                                    message: ERROR_INVALID_PHONE_FORMAT,
                                },
                            ]}
                            initialValue={''}
                            tooltip={{
                                title: GP_FAX_INFO_TEXT,
                                icon: <InfoCircleOutlined />,
                            }}
                        >
                            <InputMask
                                data-testid='gp-fax-input'
                                placeholder={GP_FAX_PLACEHOLDER_TEXT}
                                className={styles.gpFaxInput}
                                mask={PHONE_MASK_FORMAT}
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Flex>
            <PatientAddGPModal isSmartUpload />
            <PatientEditGPModal isSmartUpload />
        </Form>
    );
};

export default GpEngagement;
