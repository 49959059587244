import { Fragment} from 'react';
import { useCare1AppSelector } from '../apps/care1-hooks';
import { Row, Card, Col} from 'antd';
import * as Constants from '../constants';

// Components
import AdminGPReferralDates from '../components/admin/admin-gp-referral-dates';
import AdminOctRnflAverage from '../components/admin/admin-oct-rnfl-average';
import AdminPreReview from '../components/admin/admin-pre-review';
import AdminSelectors from '../components/admin/admin-selectors';
import AdminVfGht from '../components/admin/admin-vf-ght';
import DeepMDBand from '../components/reviewer/deep-md/patient-exam-deep-md';
import OMDSubmitButtons from '../components/reviewer/omd-submit-buttons';
import PatientExamAllergies from '../components/integrated/patient-exam-allergies';
import PatientExamBCVA from '../components/retina/patient-exam-bcva';
import PatientExamBCVAGraph from '../components/integrated/patient-exam-bcva-graph';
import AdminOntarioBillingCode from '../components/admin/admin-ontario-billing-code';
import PatientExamBillingRules from '../components/integrated/patient-exam-billing-rules';
import PatientExamChiefComplaint from '../components/retina/patient-exam-chief-complaint';
import PatientExamChiefComplaintIC from '../components/integrated/patient-exam-chief-complaint-ic';
import PatientExamComorbidities from '../components/integrated/patient-exam-comorbidities'
import PatientExamConversation from '../components/retina/patient-exam-conversation';
import PatientExamExtraImages from '../components/retina/patient-exam-extra-images';
import PatientExamFundus from '../components/retina/patient-exam-fundus';
import PatientExamFundusImageHistory from '../components/image-histories/patient-exam-fundus-image-history';
import PatientExamGPReferred from '../components/retina/patient-exam-gp-referred';
import PatientExamHeaderAdmin from '../components/admin/patient-exam-header-admin';
import PatientExamHistoryAndExam from '../components/retina/patient-exam-history-and-exam';
import PatientExamImpressions from '../components/integrated/patient-exam-impressions';
import PatientExamIOPHistory from '../components/integrated/patient-exam-iop-history';
import PatientExamMedicalHistory from '../components/integrated/patient-exam-medical-history';
import PatientExamMetrics from '../components/integrated/metrics/patient-exam-metrics';
import PatientExamNotesHistory from '../components/integrated/patient-exam-notes-history';
import PatientExamOCTMacula from '../components/retina/patient-exam-oct-macula';
import PatientExamOCTMaculaImageHistory from '../components/image-histories/patient-exam-oct-macula-image-history';
import PatientExamOCTRnfl from '../components/integrated/patient-exam-oct-rnfl';
import PatientExamOCTRNFLImageHistory from '../components/image-histories/patient-exam-oct-rnfl-image-history';
import PatientExamODNotes from '../components/integrated/patient-exam-od-notes';
import PatientExamOMDHistory from '../components/integrated/patient-exam-omd-history';
import PatientExamOMDRCDValuesBand from '../components/reviewer/patient-exam-omdr-cd-values-band';
import PatientExamOMDRIOPFinalRecommendations from '../components/reviewer/patient-exam-omdr-iop-final-recommendations';
import PatientExamOngoing from '../components/integrated/patient-exam-ongoing';
import PatientExamStereoViewer from '../components/integrated/patient-exam-stereo-viewer';
import PatientExamUntilYesterday from '../components/integrated/patient-exam-until-yesterday';
import PatientExamVF from '../components/integrated/patient-exam-vf';
import PatientExamVFImageHistory from '../components/image-histories/patient-exam-vf-image-history';
import PatientExamGlaucomaScore from '../components/integrated/patient-exam-glaucoma-score';

// Helpers
import '../../static/css/pages/admin-patient-exam-page.scss';
// TODO - This RE BCVA style sheet css should be moved to the patient-exam-bcva.scss as applicable, and this file deleted.
import '../../static/css/components/patient-exam-re-bcva.scss';
import { localizedText } from '../localizedText';

// Actions
import { patientIsGlaucoma } from '../helpers/diagnosis-convert';
import IopBcvaGraphBand from '../components/integrated/iop-bcva-graph-band';
import AdminPeiNotesRow from '../components/admin/admin-pei-notes-row';
import AdminPeiOmdrReRow from '../components/admin/admin-pei-omdr-re-row';


const ADMINLegacyPatientExamPage = () => {
    const { BCVA_GRAPH_TEXT, PHOTO_DROP_TEXT_SHORT, OMDR_ONTARIO_BILLING_CODE,
        OMDC_ONTARIO_BILLING_CODE } = localizedText;

    const examIsRE = useCare1AppSelector(store => store.examData.exam_is_retina_only);
    const glc = useCare1AppSelector(store => store.examData.rr_glc);
    const glcS = useCare1AppSelector(store => store.examData.rr_glc_suspect);
    const narrowAngles = useCare1AppSelector(store => store.examData.rr_narrow_angles);
    const diagnosisEntries = useCare1AppSelector(store => store.diagnosis.entries);
    const isReferralLetterUploadPEI = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const gptSavedValues = useCare1AppSelector(store => store.examData.gpt_saved_values);
    const gptNearMissFields = useCare1AppSelector(store => store.examData.gpt_near_miss_fields);



    const isGlaucoma = patientIsGlaucoma(diagnosisEntries, { glc, glcS, narrowAngles });

    const displayGlaucomaBand = () => {
        if (isReferralLetterUploadPEI) {
            return true;
        }
        else if (!examIsRE && isGlaucoma) {
            return true;
        }
        return false;
    }

    const getGptSavedValue = (k: string) => {
        if (!userIsADMIN) {
            return false;
        }
        
        if (gptSavedValues && gptSavedValues !== '') {
            try {
                const gptSavedValuesJson = JSON.parse(gptSavedValues);
                return gptSavedValuesJson[k];
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }

    const getGptNearMissFields = (k: string) => {
        if (!userIsADMIN) {
            return false;
        }

        if (gptNearMissFields && gptNearMissFields !== '') {
            try {
                const gptNearMissFieldsJson = JSON.parse(gptNearMissFields);
                return gptNearMissFieldsJson[k];
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }

    return (
        <>
            <Row>
                <PatientExamHeaderAdmin />
            </Row>

            <Row gutter={8}>
                {/* START | Section 1 - Left images panel */}
                <Col span={examIsRE ? 8 : 6} className="dropzones-container">
                    <PatientExamFundus
                        disabled={false}
                        tabbed={!examIsRE}
                        className="patient-exam-fundus"
                        side={Constants.RIGHT_SIDE_TEXT}
                    />
                    <Row gutter={8}>
                        {!examIsRE &&
                            <Fragment>
                                <Col span={12}>
                                    <PatientExamOCTMacula
                                        dropText={PHOTO_DROP_TEXT_SHORT}
                                        side={Constants.RIGHT_SIDE_TEXT}
                                        tabbed={true}
                                        disabled={false}
                                    />
                                    <PatientExamOCTRnfl side={Constants.RIGHT_SIDE_TEXT} disabled={false} />
                                </Col>
                                <Col span={12}>
                                    <PatientExamVF side={Constants.RIGHT_SIDE_TEXT} disabled={false} />
                                </Col>
                            </Fragment>
                        }
                        {examIsRE &&
                            <Col span={24}>
                                <PatientExamOCTMacula
                                    dropText={PHOTO_DROP_TEXT_SHORT}
                                    side={Constants.RIGHT_SIDE_TEXT}
                                    tabbed={true}
                                    disabled={false} />
                            </Col>
                        }
                    </Row>
                </Col>
                {/* END | Section 1 - Left images panel */}

                {/* START | Section 2 - Middle diagnostics panel */}
                {examIsRE &&
                    <Col span={8}>
                        <Row gutter={8}>
                            <PatientExamGPReferred disabled={false} className="patient-exam-gp-referred" />
                        </Row>
                        <Row gutter={8}>
                            <PatientExamChiefComplaint disabled={false} />
                        </Row>
                        <Row>
                            <Card className={'patient-exam-re-bcva'} bordered={false}>
                                <Row>
                                    <label className="heading">{'BCVA:'}</label>
                                </Row>
                                <Col span={12} className={'bcva-side'}>
                                    <Row>
                                        <div className={'bcva-label'}>
                                            <label className="heading">{'Right Eye:'}</label>
                                        </div>
                                        <div className={'bcva-value'}>
                                            <PatientExamBCVA
                                                side='od'
                                                disabled={false}
                                                gptSaved={getGptSavedValue('od_bcva')} 
                                                gptNearMiss={getGptNearMissFields('od_bcva')}
                                            />
                                        </div>
                                    </Row>
                                </Col>
                                <Col span={12} className={'bcva-side'}>
                                    <Row>
                                        <div className={'bcva-label os'}>
                                            <label className="heading left-eye">{'Left Eye:'}</label>
                                        </div>
                                        <div className={'bcva-value os'}>
                                            <PatientExamBCVA
                                                side='os'
                                                disabled={false}
                                                gptSaved={getGptSavedValue('os_bcva')} 
                                                gptNearMiss={getGptNearMissFields('os_bcva')}
                                            />
                                        </div>
                                    </Row>
                                </Col>
                            </Card>
                        </Row>
                        <Row gutter={8}>
                            <Col span={24}>
                                <Fragment>
                                    <PatientExamHistoryAndExam disabled={false} />
                                    <AdminOntarioBillingCode doctorType='omdr'
                                        doctorTypeLabel={OMDR_ONTARIO_BILLING_CODE} />
                                    <AdminOntarioBillingCode doctorType='omdc'
                                        doctorTypeLabel={OMDC_ONTARIO_BILLING_CODE} />
                                </Fragment>
                            </Col>
                        </Row>
                    </Col>
                }

                {!examIsRE &&
                    <Col span={12}>
                        <Row gutter={8}>
                            <Col span={14}>
                                <Row>
                                    <PatientExamGPReferred
                                        className="patient-exam-gp-referred"
                                        disabled={false}
                                    />
                                </Row>
                                <Row>
                                    <PatientExamChiefComplaintIC disabled={false} />
                                </Row>
                            </Col>
                            <Col span={10}>
                                <PatientExamOMDHistory disabled={false} />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={10}>
                                <PatientExamMedicalHistory disabled={false} />
                            </Col>
                            <Col span={10}>
                                <PatientExamNotesHistory disabled={false} />
                            </Col>
                            <Col span={4}>
                                <PatientExamAllergies
                                    disabled={false}
                                    gptSaved={getGptSavedValue('allergies')}
                                    gptNearMiss={getGptNearMissFields('allergies')}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <PatientExamMetrics disabled={false} />
                        </Row>
                        <Row gutter={8}>
                            <Col span={9}>
                                <PatientExamImpressions disabled={false} />
                            </Col>
                            <Col span={15}>
                                <Row>
                                    <PatientExamODNotes disabled={false} />
                                </Row>
                                <Row>
                                    <PatientExamBillingRules disabled={false} />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                }
                {/* END | Section 2 - Middle diagnostics panel */}

                {/* START | Section 3 - Right images panel */}
                <Col span={examIsRE ? 8 : 6} className="dropzones-container">
                    <PatientExamFundus
                        disabled={false}
                        tabbed={!examIsRE}
                        className="patient-exam-fundus"
                        side={Constants.LEFT_SIDE_TEXT}
                    />
                    <Row gutter={8}>
                        {!examIsRE &&
                            <Fragment>
                                <Col span={12}>
                                    <PatientExamOCTMacula
                                        dropText={PHOTO_DROP_TEXT_SHORT}
                                        side={Constants.LEFT_SIDE_TEXT}
                                        tabbed={true}
                                        disabled={false}

                                    />
                                    <PatientExamOCTRnfl side={Constants.LEFT_SIDE_TEXT} disabled={false} />
                                </Col>
                                <Col span={12}>
                                    <PatientExamVF side={Constants.LEFT_SIDE_TEXT} disabled={false} />
                                </Col>
                            </Fragment>
                        }
                        {examIsRE &&
                            <Col span={24}>
                                <PatientExamOCTMacula
                                    dropText={PHOTO_DROP_TEXT_SHORT}
                                    side={Constants.LEFT_SIDE_TEXT}
                                    disabled={false}
                                    tabbed={false}
                                />
                            </Col>
                        }
                    </Row>
                </Col>
                {/* END | Section 3 - Left images panel */}
            </Row>

            {/* START | Image Borders & Ont Billing Codes */}
            {!examIsRE &&
                <Row gutter={8}>
                    <Col span={3} className="pei-vf-ght">
                        <AdminOctRnflAverage side={'right'} />
                    </Col>
                    <Col span={3} className="pei-vf-ght">
                        <AdminVfGht side={'right'} />
                    </Col>
                    <Col span={6}>
                        <AdminOntarioBillingCode doctorType='omdr'
                            doctorTypeLabel='OMDR Ontario Billing Code' />
                    </Col>
                    <Col span={6}>
                        <AdminOntarioBillingCode doctorType='omdc'
                            doctorTypeLabel='OMDC Ontario Billing Code' />
                    </Col>
                    <Col span={3} className="pei-vf-ght">
                        <AdminOctRnflAverage side={'left'} />
                    </Col>
                    <Col span={3} className="pei-vf-ght">
                        <AdminVfGht side={'left'} />
                    </Col>
                </Row>
            }
            {/* END | Image Borders & Ont Billing Codes */}

            {/* START | Section 4 - Glaucoma band */}
            {displayGlaucomaBand() &&
                <Row gutter={8}>
                    <Col span={6} className="pei-iop-history-col">
                        <PatientExamIOPHistory disabled={false} />
                    </Col>
                    <Col span={6} className="pei-until-yesterday-col">
                        <PatientExamUntilYesterday disabled={false} />
                    </Col>
                    <Col span={6} className="pei-ongoing-col">
                        <PatientExamOngoing disabled={false} />
                    </Col>
                    <Col span={6} className="pei-comorbidities-col">
                        <PatientExamComorbidities disabled={false} />
                    </Col>
                </Row>
            }
            {/* END | Section 4 - Glaucoma band */}

            {/* START | OMDR RE Reviewer band */}
            {examIsRE ?
                <AdminPeiOmdrReRow />
                :
                <AdminPeiNotesRow disabled={false} />
            }
            {/* END | OMDR IC Revewiwer Band */}

            {/* START | ADMIN band */}
            <Row gutter={8}>
                <Col span={12}>
                    <AdminSelectors />
                </Col>
                <Col span={5}>
                    <AdminGPReferralDates />
                </Col>
                <Col span={4}>
                    <AdminPreReview />
                </Col>
                <Col span={3}>
                    <OMDSubmitButtons disabled={false} />
                </Col>
            </Row>
            {/* END | ADMIN band */}

            {/* START | OMDR IC DeepMD Band */}
            <Row gutter={8}>
                <DeepMDBand />
            </Row>
            {/* END | OMDR IC DeepMD  Band */}

            {/* START |PatientExamGlaucomaScore Band */}
            <Row gutter={8}>
                <PatientExamGlaucomaScore />
            </Row>
            {/* END | PatientExamGlaucomaScore  Band */}

            {/* END | Section 5 IC - OMDR IC Reviewer & DeepMD bands */}

            {/* START | CD Values */}
            {!examIsRE && isGlaucoma &&
                <Row>
                    <PatientExamOMDRCDValuesBand />
                </Row>
            }
            {/* END | CD Values */}

            {/* START | Section 6 - Stereo Viewer */}
            {!examIsRE &&
                <PatientExamStereoViewer inGlaucomaWorksheet={false} />
            }
            {/* END | Section 6 RE - Stereo Viewer */}

            {/* START | Final Recommendations Bands */}
            {!examIsRE && isGlaucoma &&
                <Row>
                    <PatientExamOMDRIOPFinalRecommendations />
                </Row>
            }
            {/* END | Final Recommendations Bands */}

            {/* START | Section 7 - Extra images band */}
            <PatientExamExtraImages className="patient-exam-extra-images" disabled={false} />
            {/* END | Section 7 - Extra Images band */}

            {/* START | Section 8a RE exam - Graph Bands */}
            {examIsRE &&
                <Row gutter={8}>
                    <Col span={12}>
                        <Card className="patient-exam-bcva-graph" bordered={false}>
                            <div className="heading">{`${BCVA_GRAPH_TEXT} OD`}</div>
                            <Row>
                                <PatientExamBCVAGraph side={'od'} />
                            </Row>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card className="patient-exam-bcva-graph" bordered={false}>
                            <div className="heading">{`${BCVA_GRAPH_TEXT} OS`}</div>
                            <Row>
                                <PatientExamBCVAGraph side={'os'} />
                            </Row>
                        </Card>
                    </Col>
                </Row>
            }
            {/* END | Section 8a RE exam - Graph Bands */}

            {/* START | Section 8b IC exam - Graph Bands */}
            {!examIsRE &&
                <IopBcvaGraphBand />
            }
            {/* END | Section 8 IC exam - Graph Bands */}

            {/* START | Section 9 - Conversation band */}
            <Row gutter={8}>
                <Col span={24}>
                    <PatientExamConversation />
                </Col>
            </Row>
            {/* END | Section 9 - Conversation band */}

            {!examIsRE &&
                <Fragment>
                    {/* START | Section 10 - VF History band */}
                    <Row gutter={8}>
                        <PatientExamVFImageHistory />
                    </Row>
                    {/* END | Section 10 - VF band */}

                    {/* START | Section 11 - RNFL History band */}
                    <Row gutter={8}>
                        <PatientExamOCTRNFLImageHistory />
                    </Row>
                    {/* END | Section 11 - RNFL History band */}

                    {/* START | Section 12 - Macula History band*/}
                    <Row gutter={8}>
                        <PatientExamOCTMaculaImageHistory orderBy={'asc'} showHint={true} showBaselineBorder={true} showEmptyRow={true} />
                    </Row>
                    {/* END | Section 12 - Macula History band */}
                    {/* START |  Section 13 - Fundus History band */}
                    <Row gutter={8}>
                        <PatientExamFundusImageHistory />
                    </Row>
                    {/* END | Section 13 - Fundus History band */}
                </Fragment>
            }
            {examIsRE &&
                <Fragment>
                    {/* START | Section 12 - Macula History band*/}
                    <Row gutter={8}>
                        <PatientExamOCTMaculaImageHistory orderBy={'asc'} showHint={true} showBaselineBorder={true} showEmptyRow={true} />
                    </Row>
                    {/* END | Section 12 - Macula History band */}
                    {/* START |  Section 13 - Fundus History band */}
                    <Row gutter={8}>
                        <PatientExamFundusImageHistory />
                    </Row>
                    {/* END | Section 13 - Fundus History band */}
                </Fragment>
            }
        </>
    );
}

export default ADMINLegacyPatientExamPage;