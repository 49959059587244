import { useCallback, useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';
import { Layout, Row, Spin, Tabs } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router';
import * as Constants from '../constants';

// Components
import { FormValidationProvider } from '../context/useFormValidationContext';
import NavigationControlBar from '../components/navigation-control-bar';
import NavigationTopBar from '../components/navigation-top-bar';
import PatientDetailsModal from '../components/retina/patient-details-modal';

import PatientExamPreviousVisits from '../components/patient-exam-previous-visits';

import AdminAIInsights from '../components/admin/admin-ai-insights';

// Helpers
import '../../static/css/pages/admin-patient-exam-page.scss';
// TODO - This RE BCVA style sheet css should be moved to the patient-exam-bcva.scss as applicable, and this file deleted.
import '../../static/css/components/patient-exam-re-bcva.scss';

// Actions
import ExamDateSelectModal from '../components/retina/exam-date-select-modal';
import { editExamRequest } from '../reducers/patient-exam-slice';
import GlaucomaWorksheet from '../components/glaucoma-worksheet';

const { Header, Content } = Layout;

const SciencePatientExamPage = () => {

    const examOperating = useCare1AppSelector(store => store.examData.operating);
    const showDetails = useCare1AppSelector(store => store.patientDetails.visible);
    const examIsRE = useCare1AppSelector(store => store.examData.exam_is_retina_only);
    const patientDetailsOperating = useCare1AppSelector(store => store.patientDetails.operating);
    const isReferralLetterUploadPEI = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const rluExamPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);

    // Redux and React Router Hooks
    const dispatch = useCare1AppDispatch();
    const params = useParams();
    const history = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const defaultTab = 'item-ai-insights';

    // send edit exam request when exam page first renders
    const editExam = useCallback(() => {
        const exam_id = Number(params[Constants.LATEST_EXAM_ID_FIELD]) || null;
        if (exam_id !== null) {
            dispatch(editExamRequest(exam_id, history));
        }
    }, [params, history, dispatch])

    useEffect(() => {
        editExam();
    }, [editExam])

    const getTabAIInsights = () => {

        return <>
            <AdminAIInsights />
        </>;
    }

    const getTabGlaucomaWorksheet = () => {

        return <>
            <GlaucomaWorksheet />
        </>;
    }

    const tabs = [];

    tabs.push({
        label: 'Retina Worksheet', key: 'item-ai-insights',
        children: getTabAIInsights()
    });

    tabs.push({
        label: 'Glaucoma Worksheet', key: 'item-glaucoma-worksheet',
        children: getTabGlaucomaWorksheet(),
    });

    return (
        <Layout>
            <Spin
                className="loading-spinner"
                spinning={examOperating || patientDetailsOperating}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Header>
                    <NavigationTopBar className="navigation-top-bar" />
                    <NavigationControlBar
                        className="admin-pei-navigation-control-bar"
                        breadcrumbItems={[{ key: 1, component: <PatientExamPreviousVisits /> }]}
                    />
                </Header>
                <Content>
                    <ExamDateSelectModal />
                    <FormValidationProvider>
                        <div className={`admin-patient-exam-page${examIsRE ? ' exam-is-re' : ' exam-is-ic'}${isReferralLetterUploadPEI ? rluExamPremium ? ' exam-is-rlu' : ' exam-is-rlu standard-referral-letter-pei' : ' exam-is-legacy'}`}>
                            {!(examOperating || patientDetailsOperating) &&
                                <Tabs
                                    defaultActiveKey={defaultTab}
                                    items={tabs}
                                />
                            }
                            <Row>
                                <br />
                                <br />
                            </Row>
                        </div>
                    </FormValidationProvider>
                </Content>
            </Spin>
        </Layout>
    );
}

export default SciencePatientExamPage;