
import { Card, Row, Col, Tag } from 'antd';
import '../../../static/css/components/admin-selectors.scss';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { OMD_NOTE_TEMPLATE_DICT } from '../../constants';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { localizedText } from '../../localizedText';
import { setOverrideActions, setPreReviewDataValue } from '../../reducers/pre-review-slice';
import { useEffect } from 'react';

const { CheckableTag } = Tag;

const AdminReferralLetterSelectors = () => {
    const { OCT_VF_TEXT, TELE_FU_TEXT, GP_LETTER_TEXT_ADMIN,
        CURT_UPCOMING_OMD, ISSUES_TEXT, OD_HAS_QUEST_ISSUE, OD_RQST_OMD_APPT, 
        PAST_OMD_TEXT, CATTM_TEXT, OCT_TEXT, NO_SURG_TEXT, GP_INFO_TEXT, 
        ADMIN_SELECTORS_TEXT, OD_BASELINE_TEXT, OD_REPLY_TEXT } = localizedText;
    
    const selectedOmdNoteOptions : number[] = useCare1AppSelector(store => store.examData.selected_omd_note_options);
    const pastOmd = useCare1AppSelector(store => store.examData.past_omd);
    const odBaseline = useCare1AppSelector(store => store.examData.od_baseline);
    const odQuestion = useCare1AppSelector(store => store.examData.od_question);
    const odReqOmd = useCare1AppSelector(store => store.examData.od_req_omd);
    const cuOmd = useCare1AppSelector(store => store.examData.cu_omd);
    const cuOmdAdminOverride = useCare1AppSelector(store => store.examData.cu_omd_admin_override);
    const retinaReferral = useCare1AppSelector(store => store.examData.retina_referral);
    const isPreReviewed = useCare1AppSelector(store => store.examData.is_pre_reviewed);
    const hasNoGp = useCare1AppSelector(store => store.patientDetails.details.has_no_gp);
    const assignFirst = useCare1AppSelector(store => store.examData.assign_first);
    const assignFirstAdminOverride = useCare1AppSelector(store => store.examData.assign_first_admin_override);
    const examIsODMessaging = useCare1AppSelector(store => store.examData.is_od_messaging_submission);

    const selectorDisabled = isPreReviewed && hasNoGp;
    
    const shouldCuOmdSelected = cuOmdAdminOverride ?? cuOmd;
    const shouldAssignFirstSelected = assignFirstAdminOverride ?? assignFirst;

    const dispatch = useCare1AppDispatch();

    const { teleFuId, gpLetterId, gpInfoId, octId, octVfId, noSurgId, issuesId, catTmId, odReplyId
        } = OMD_NOTE_TEMPLATE_DICT;

    const handleChange = (checked: boolean, value: number, field: string) => {
        if (checked) {
            dispatch(setExamDataValue('selected_omd_note_options', [...selectedOmdNoteOptions, value]));
        } else {
            dispatch(setExamDataValue('selected_omd_note_options', selectedOmdNoteOptions.filter(
                option => option !== value)));
        }
        dispatch(setOverrideActions([field, checked]));
        dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
    }

    const handleOctChange = (checked: boolean, value: number) => {
        if (checked) {
            if (value === octId) {
                dispatch(setExamDataValue('selected_omd_note_options', selectedOmdNoteOptions.filter(
                    option => option !== octVfId).concat(octId)));
                dispatch(setOverrideActions(['OCT', checked]));
            } else if (value === octVfId) {
                dispatch(setExamDataValue('selected_omd_note_options', selectedOmdNoteOptions.filter(
                    option => option !== octId).concat(octVfId)));
                dispatch(setOverrideActions(['OCT/VF', checked]));

            }
        } else {
            dispatch(setExamDataValue('selected_omd_note_options', selectedOmdNoteOptions.filter(
                option => option !== value)));
        }
        dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
    }

    useEffect(() => {
        if (retinaReferral === 'needed') {
            dispatch(setExamDataValue('cu_omd_admin_override', true));
            dispatch(setOverrideActions(['CURT/UPCOMING OMD', true]));
            dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
        }
    }, [retinaReferral, dispatch])

    return (
        <Card className={'component-input-card admin-selectors'} bordered={false}>
            <div className="heading">{ADMIN_SELECTORS_TEXT}</div>
            <div className='component-body'>
                <Row>
                    <Col>
                        <CheckableTag
                            checked={selectedOmdNoteOptions.includes(gpInfoId)}
                            onChange={(checked) => !selectorDisabled ? handleChange(checked, gpInfoId, '?GP INFO') : null}
                            className={`${selectorDisabled ? 'disabled' : ''}`}
                        >
                            ?{GP_INFO_TEXT}
                        </CheckableTag>
                    </Col>
                    <Col>
                        <CheckableTag
                            checked={selectedOmdNoteOptions.includes(noSurgId)}
                            onChange={(checked) => handleChange(checked, noSurgId, 'NO SURG')}
                        >
                            {NO_SURG_TEXT}
                        </CheckableTag>
                    </Col>
                    <Col>
                        <CheckableTag
                            className="oct"
                            checked={selectedOmdNoteOptions.includes(octId)}
                            onChange={(checked) => handleOctChange(checked, octId)}
                        >
                           {OCT_TEXT}
                        </CheckableTag>
                        <CheckableTag
                            className="oct-vf"
                            checked={selectedOmdNoteOptions.includes(octVfId)}
                            onChange={(checked) => handleOctChange(checked, octVfId)}
                        >
                            {OCT_VF_TEXT}
                        </CheckableTag>
                    </Col>

                    <Col>
                        <CheckableTag
                            checked={selectedOmdNoteOptions.includes(catTmId)}
                                onChange={(checked) => handleChange(checked, catTmId, 'CatTM' )}
                        >
                            {CATTM_TEXT}
                        </CheckableTag>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CheckableTag
                            checked={selectedOmdNoteOptions.includes(gpLetterId)}
                            onChange={(checked) => !selectorDisabled ? handleChange(checked, gpLetterId, 'GP LETTER') : null}
                            className={`${selectorDisabled ? 'disabled' : ''}`}
                        >
                            {GP_LETTER_TEXT_ADMIN}
                        </CheckableTag>
                    </Col>
                    <Col>
                    {examIsODMessaging ?
                        <CheckableTag
                            checked={selectedOmdNoteOptions.includes(odReplyId)}
                            onChange={(checked) => handleChange(checked, odReplyId, 'OD REPLY')}
                        >
                            {OD_REPLY_TEXT}
                        </CheckableTag>
                        :
                        <CheckableTag
                            checked={selectedOmdNoteOptions.includes(issuesId)}
                            onChange={(checked) => handleChange(checked, issuesId, 'ISSUES')}
                        >
                            {ISSUES_TEXT}
                        </CheckableTag>
                    }
                    </Col>
                    <Col>
                        <CheckableTag
                            checked={pastOmd}
                            onChange={(checked) => {
                                dispatch(setExamDataValue('past_omd', checked));
                                dispatch(setOverrideActions(['PAST OMD', checked]));
                                dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
                            }}
                        >
                           {PAST_OMD_TEXT}
                        </CheckableTag>
                    </Col>
                    <Col>
                        <CheckableTag
                            checked={odBaseline}
                            onChange={(checked)=> {
                                dispatch(setExamDataValue('od_baseline', checked));
                                dispatch(setOverrideActions(['OD Baseline', checked]));
                                dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
                            }}
                        >
                            {OD_BASELINE_TEXT}
                        </CheckableTag>
                    </Col>
                    <Col>
                        <CheckableTag
                            checked={selectedOmdNoteOptions.includes(teleFuId)}
                            onChange={(checked) => handleChange(checked, teleFuId, 'TELE F/U')}
                        >
                            {TELE_FU_TEXT}
                        </CheckableTag>
                    </Col>
                </Row>
                <Row className='row-three'>
                    <Col>
                        <CheckableTag
                            checked={odQuestion}
                            onChange={(checked)=> {
                                dispatch(setExamDataValue('od_question', checked));
                                dispatch(setOverrideActions(['OD HAS QUEST/ISSUE', checked]));
                                dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
                            }}
                        >
                            {OD_HAS_QUEST_ISSUE}
                        </CheckableTag>
                    </Col>
                    <Col>
                        <CheckableTag
                            checked={odReqOmd}
                            onChange={(checked)=> {
                                dispatch(setExamDataValue('od_req_omd', checked));
                                dispatch(setOverrideActions(['OD RQST OMD APPT', checked]));
                                dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
                            }}
                        >
                            {OD_RQST_OMD_APPT}
                        </CheckableTag>
                    </Col>
                    <Col>
                        <CheckableTag
                            checked={shouldAssignFirstSelected}
                            onChange={(checked)=> {
                                dispatch(setExamDataValue('assign_first_admin_override', checked));
                                dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
                            }}
                        >
                            Assign 1st
                        </CheckableTag>
                    </Col>
                    <Col>
                        <CheckableTag
                            checked={shouldCuOmdSelected}
                            onChange={(checked)=> {
                                dispatch(setExamDataValue('cu_omd_admin_override', checked));
                                dispatch(setOverrideActions(['CURT/UPCOMING OMD', checked]));
                                dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
                            }}
                        >
                            {CURT_UPCOMING_OMD}
                        </CheckableTag>
                    </Col>
                </Row>
            </div>
        </Card>
    )
}

export default AdminReferralLetterSelectors;
