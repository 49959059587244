import { AppDispatch, RootState } from "../stores/retina-enabled-store";
import { IExamData } from "../reducers/patient-exam-slice";
import { extractBcvaValue } from "./patient-exam-convert";

type ValueOf<T> = T[keyof T];
type ValueOfExamData = ValueOf<IExamData>;

export interface IUpdateNearMissDataParams {
    id: keyof IExamData,
    value: ValueOfExamData,
};

// Given an updated exam value, update the GPT near miss data to show which values have changed from the GPT saved data.
export const updateNearMissDataWithValue = (id: keyof IExamData, value: ValueOfExamData, gptSavedValues: string,
    gptNearMissFields: string, gptResponse: string) => (dispatch: AppDispatch, getState: () => RootState) => {

    const {examData: {bcva_options}} = getState();
    let updatedNearMissFields = gptNearMissFields;

    try {
        const gptSavedValuesJson = JSON.parse(gptSavedValues);
        const gptNearMissFieldsJson = JSON.parse(gptNearMissFields);
        const gptResponseJson = JSON.parse(gptResponse);

        if (id in gptNearMissFieldsJson && id in gptSavedValuesJson) {
            const jsonPattern = /{[^]*}/;
            const jsonMatches = gptResponseJson['message']['output'].match(jsonPattern);

            if (jsonMatches) {
                const jsonResponse = jsonMatches[0];
                const responseOutputJSON = JSON.parse(jsonResponse);
                let gptResponseValue = null;

                if (id in responseOutputJSON) {
                    gptResponseValue = responseOutputJSON[id];
                }

                // The following are special cases where the ID in the UI component has a different name from
                //  the ID in GPT reponse, or if the GPT response has a more complex data type.
                if ('chief_complaints' in responseOutputJSON) {
                    // A couple of GPT fields don't have the same name as our fields, so they have to be converted.
                    let gptId: string = id;
                    if (id === 'rr_cataract') {
                        gptId = 'rr_cat_ref';
                    }
                    else if (id === 'rr_glc_suspect') {
                        gptId = 'rr_glc_sus';
                    }

                    if (gptId in responseOutputJSON['chief_complaints']) {
                        gptResponseValue = responseOutputJSON['chief_complaints'][gptId];
                    }
                }

                if ('comorbidities' in responseOutputJSON && id in responseOutputJSON['comorbidities']) {
                    gptResponseValue = responseOutputJSON['comorbidities'][id];
                }

                if ('allergies' in responseOutputJSON && id === 'allergies') {
                    gptResponseValue = responseOutputJSON['allergies'];
                }

                if ('iop_instrument' in responseOutputJSON && id === 'applanation') {
                    gptResponseValue = responseOutputJSON['iop_instrument'];
                }

                // The BCVA values need to be converted to our values.
                if (id === 'od_bcva' || id === 'os_bcva') {
                    gptResponseValue = extractBcvaValue(gptResponseValue, bcva_options);
                }

                // The GPT follow-up value needs to be converted to month or week to be usable. Otherwise
                //  we don't recognize the period.
                if ('fu_period' in responseOutputJSON && id === 'fu_letter') {
                    if (responseOutputJSON['fu_period']?.includes('month')) {
                        gptResponseValue = 'month';
                    }
                    else if (responseOutputJSON['fu_period']?.includes('week')) {
                        gptResponseValue = 'week';
                    }
                }

                if ('pt_follow_up' in responseOutputJSON && id === 'is_fu_unknown') {
                    gptResponseValue = (responseOutputJSON['pt_follow_up'] === 'unknown');
                }

                // Since the GPT JSON non-boolean values are always string, some have to be converted.
                if (typeof value === 'number') {
                    gptResponseValue = Number(gptResponseValue);
                }

                // Only mark the near miss field if the given value has changed from the GPT values.
                if (((gptResponseValue != null && gptResponseValue !== '') || (value != null && value !== ''))
                    && gptResponseValue !== value) {
                    gptNearMissFieldsJson[id] = true;
                }
                else {
                    gptNearMissFieldsJson[id] = false;
                }

                updatedNearMissFields = JSON.stringify(gptNearMissFieldsJson);
            }
        }
    }
    catch (err) {
        // If anything goes wrong, just return the original near miss fields.
    }

    return updatedNearMissFields;
}