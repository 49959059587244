import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { NOTES_TO_MOA_FIELD, NOTES_TO_MOA_FIELD_CACHE } from '../../constants'
import '../../../static/css/components/patient-exam-notes-to-moa.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { localizedText } from '../../localizedText';
import ComponentTextareaCard from '../component-textarea-card';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';


const PatientExamOmdConsultantNotesToMOA = ({ disabled }: { disabled: boolean }) => {
    const { NOTES_MOA_TEXT, NOTES_MOA_TOOLTIP } = localizedText;
    const notesToMoa = useCare1AppSelector(store => store.examData.billing_note);

    const dispatch = useCare1AppDispatch();

    return (
        <ComponentTextareaCard
            disabled={disabled}
            title={
                <>
                    <span>{NOTES_MOA_TEXT}</span>
                    <Tooltip placement='topLeft' title={NOTES_MOA_TOOLTIP}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </>}
            testId='notes-to-moa'
            value={notesToMoa}
            handleChange={(e) => {
                dispatch(setExamDataValue(NOTES_TO_MOA_FIELD, e.target.value));
                dispatch(setExamDataValue(NOTES_TO_MOA_FIELD_CACHE, e.target.value));
            }}
        />
    );
}

export default PatientExamOmdConsultantNotesToMOA;