import { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { InfoCircleOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Checkbox, Col, Input, Select, Radio, Row, Spin, Tooltip, Form, Button } from 'antd';
import dayjs from 'dayjs';

// Helpers
import { buildRequiredErrorMessage, formatGPName, getMaskInputFormat, isDOBDayValid, getNonProvincialMaskInputFormat
    } from '../../helpers/patient-edit-convert';

// Style
import '../../../static/css/components/_patient-edit-form.scss';
import { CAF_SERVICE_NUMBER_FIELD, CITY_FIELD, DOB_DAY_FIELD, DOB_FIELD, 
    DOB_MONTH_FIELD, DOB_YEAR_FIELD, EMAIL_FIELD, ERROR_INVALID_EMAIL_FORMAT, ERROR_INVALID_HN_FORMAT, 
    ERROR_INVALID_PHONE_FORMAT, ERROR_INVALID_POSTAL_CODE_FORMAT, FIRST_NAME_FIELD, GENDER_FEMALE_FIELD,
    GENDER_MALE_FIELD, GP_CURRENT_FIELD, GP_FAX_NUMBER_FIELD, HAS_NO_PHN_FIELD, IS_CAF_PERSONNEL_FIELD, 
    LAST_NAME_FIELD, PHN_FIELD, PHN_NAME_FIELD, PHN_VALIDATION_FORMAT_FIELD, PHONE_FIELD, PHONE_MASK_FORMAT,
    PLEASE_CONFIRM_FIELD,  POSTAL_CODE_FIELD, PROVINCE_FIELD, SECONDARY_PHN_FIELD,
    SECONDARY_PHN_REQUIRED_FIELD, SECONDARY_PHN_VALIDATION_FORMAT_FIELD, STREET_FIELD, IS_ON_OLD_OHIP_FIELD, 
    ZIP_CODE_MASK, GENDER_FIELD,  GP2_CURRENT_FIELD, GP2_FAX_NUMBER_FIELD, PLEASE_ENSURE_TEXT } from '../../constants';
import { getUsInsuranceListRequest, toggleInsuranceModal } from '../../reducers/us-insurance-slice';
import { localizedText } from '../../localizedText';
import { useGetGpsByProvinceQuery } from '../../services/doctor-api';
import { closeEditPatientModal, openAddGPModal, openEditGPModal, patientPHNExistsRequest, setAddEditGpFieldData, setHealthNumberData, setPatientEditValue } from '../../reducers/patient-edit-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { FormInstance, RuleObject } from 'antd/es/form';
import { FieldData } from 'rc-field-form/lib/interface';
import { IInsuranceTypeOption } from '../../reducers/options-slice';


const { MD2_FAX_INFO_TEXT } = localizedText;

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { Option } = Select;

type ComponentProps = {
    form?: FormInstance,
    successAction?: () => void,
    patientID?: number | null,
}

const PatientEditForm = ({form:parentForm, successAction, patientID}: ComponentProps) => {
    const { CITY_LABEL_TEXT, CITY_PLACEHOLDER_TEXT, DOB_ERROR_MESSAGE, DOB_LABEL_TEXT,
        EMAIL_LABEL_TEXT, EMAIL_PLACEHOLDER_TEXT, ERROR_PHN_ALREADY_EXISTS,
        FIRST_NAME_LABEL_TEXT, FNAME_PLACEHOLDER_TEXT,
        GENDER_FEMALE_TEXT,  GENDER_LABEL_TEXT, GENDER_MALE_TEXT,
        GP2_FAX_NUMBER_LABEL_TEXT, GP2_FAX_PLACEHOLDER_TEXT,
        GP2_LABEL_TEXT, GP2_PLACEHOLDER_TEXT,GP_FAX_INFO_TEXT, GP_FAX_NUMBER_LABEL_TEXT,
        GP_FAX_PLACEHOLDER_TEXT, GP_LABEL_TEXT, GP_PLACEHOLDER_TEXT, LAST_NAME_LABEL_TEXT,
        LNAME_PLACEHOLDER_TEXT, MONTHS, OPERATING_TEXT, PHONE_LABEL_TEXT,
        PHONE_PLACEHOLDER_TEXT, PLEASE_CONFIRM_LABEL_TEXT, PLEASE_CONFIRM_LIST_TEXT,
        PLEASE_ENSURE_HEADING_TEXT, POSTAL_CODE_LABEL_TEXT, YEAR_TEXT, MONTH_TEXT, NEW_GP2_TEXT,
        POSTAL_CODE_MASK, POSTAL_CODE_PLACEHOLDER_TEXT, PROVINCE_LABEL_TEXT, NEW_GP_TEXT,
        PROVINCE_PLACEHOLDER_TEXT, STREET_LABEL_TEXT, STREET_PLACEHOLDER_TEXT, DAY_TEXT,
     } = localizedText;
    const isOperating = useCare1AppSelector(store => store.patientEdit.operating);
    const patientEditDetails = useCare1AppSelector(store => store.patientEdit.details);
    const provinces = useCare1AppSelector(store => store.options.provinces);
    const doctorID = useCare1AppSelector(store => store.user.doctorID);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);

    // patient edit form field values
    const firstName = useCare1AppSelector(store => store.patientEdit.details[FIRST_NAME_FIELD]);
    const lastName = useCare1AppSelector(store => store.patientEdit.details[LAST_NAME_FIELD]);
    const gender = useCare1AppSelector(store => store.patientEdit.details[GENDER_FIELD]);
    const phone = useCare1AppSelector(store => store.patientEdit.details[PHONE_FIELD]);
    const email = useCare1AppSelector(store => store.patientEdit.details[EMAIL_FIELD]);
    const dobDayValue = useCare1AppSelector(store => store.patientEdit.details[DOB_DAY_FIELD]);

    const dobMonthValue = useCare1AppSelector(store => store.patientEdit.details[DOB_MONTH_FIELD]) || undefined;
    const dobYearValue = useCare1AppSelector(store => store.patientEdit.details[DOB_YEAR_FIELD]);

    // When province is empty string, set it to null so that placeholder value is displayed
    const province = useCare1AppSelector(store => store.patientEdit.details[PROVINCE_FIELD]) || undefined;
    const PHN = useCare1AppSelector(store => store.patientEdit.details[PHN_FIELD]);
    const secondPHN = useCare1AppSelector(store => store.patientEdit.details[SECONDARY_PHN_FIELD]);
    const street = useCare1AppSelector(store => store.patientEdit.details[STREET_FIELD]);
    const city = useCare1AppSelector(store => store.patientEdit.details[CITY_FIELD]);
    const postalCode = useCare1AppSelector(store => store.patientEdit.details[POSTAL_CODE_FIELD]);
    const userIsUsOnly = useCare1AppSelector(store => store.user.isUsOnly);
    const examIsUsOnly = useCare1AppSelector(store => store.examData.exam_is_us_only);
    const odProvince = useCare1AppSelector(store => store.user.odProvince);
    const optometristProvince = useCare1AppSelector(store => store.examData.optometrist_province);
    const hasNoGp = useCare1AppSelector(store => store.patientEdit.details.has_no_gp);
    const insuranceType = useCare1AppSelector(store => store.patientEdit.details.insurance_type);

    const provinceCode = userIsADMIN ? optometristProvince : odProvince;

    const [localForm] = Form.useForm();

    const form = parentForm ?? localForm;

    const usOnly = userIsUsOnly || examIsUsOnly;

    const {data : gpList =[], isFetching} = useGetGpsByProvinceQuery(provinceCode, {skip: !provinceCode });

    // When gp is empty string, set it to null so that placeholder value is displayed
    const gp = useCare1AppSelector(store => store.patientEdit.details[GP_CURRENT_FIELD]) || undefined;
    let gpFax = useCare1AppSelector(store => store.patientEdit.details[GP_FAX_NUMBER_FIELD]);

    const isTestPatient = useCare1AppSelector(store => store.patientEdit.details['is_test_patient']);
    const isDeleted = useCare1AppSelector(store => store.patientEdit.details['deleted']);

    let gpObj = null;
    if (gp) {
        gpObj = gpList.find((entry) => entry.id === gp);
        if (gpObj) {
            gpFax = gpObj.fax_number;
        }
    }

    // When gp2 is empty string, set it to null so that placeholder value is displayed
    const gp2 = useCare1AppSelector(store => store.patientEdit.details[GP2_CURRENT_FIELD]) || undefined;
    let gp2Fax = useCare1AppSelector(store => store.patientEdit.details[GP2_FAX_NUMBER_FIELD]);

    let gp2Obj = null;
    if (gp2) {
        gp2Obj = gpList.find((entry) => entry.id === gp2);
        if (gp2Obj) {
            gp2Fax = gp2Obj.fax_number;
        }
    }

    const CAFServiceNumber = useCare1AppSelector(store => store.patientEdit.details[CAF_SERVICE_NUMBER_FIELD]);
    const pleaseConfirm = useCare1AppSelector(store => store.patientEdit.details[PLEASE_CONFIRM_FIELD]);
    const isCAF = useCare1AppSelector(store => store.patientEdit.details[IS_CAF_PERSONNEL_FIELD]);
    const insuranceTypeOptions: IInsuranceTypeOption[] = useCare1AppSelector(store => store.options.insuranceTypeOptions);

    const dispatch = useCare1AppDispatch();

    const details = patientEditDetails;
    const PHNLabel = details[PHN_NAME_FIELD];
    const PHNSecondaryRequired = details[SECONDARY_PHN_REQUIRED_FIELD];
    const isProvinceSelected = !!(details[PROVINCE_FIELD]);
    const PHNSecondaryMask = getMaskInputFormat(details[SECONDARY_PHN_VALIDATION_FORMAT_FIELD]);
    const isArmedForcesPersonnel = details[IS_CAF_PERSONNEL_FIELD];
    const isOntarioPatient = details[PROVINCE_FIELD] === 9;
    const noInsuranceText = isOntarioPatient ? 'No INS.' : 'No Insurance';
    const hasNoPHN = insuranceType === 'no_insurance';
    const insurancePlaceholder = hasNoPHN || !insuranceType ? 'No Insurance' : insuranceType === 'provincial' ? `Enter ${PHNLabel} Number` :
        `Enter ${insuranceTypeOptions.find(option => option.value === insuranceType)?.label} Number`;
    const insuranceMask = insuranceType === 'provincial' ? getMaskInputFormat(details[PHN_VALIDATION_FORMAT_FIELD]) : 
        getNonProvincialMaskInputFormat(insuranceType);
    const insuranceTypeDisplayOptions = insuranceTypeOptions.reduce((reducedOptions, insuranceTypeOption) => {
        // Don't display old OHIP if this is not in Ontario.
        if (isOntarioPatient || insuranceTypeOption.value !== 'old_ohip') {
            let displayLabel = insuranceTypeOption.label;
            if (insuranceTypeOption.value === 'provincial') {
                displayLabel = PHNLabel;
            }

            reducedOptions.push({
                value: insuranceTypeOption.value,
                label: displayLabel,
            });
        }

        return reducedOptions;
    }, [] as IInsuranceTypeOption[]);

    const provinceLabel = usOnly ? 'State' : PROVINCE_LABEL_TEXT;
    const provincePlaceholderText = usOnly ? 'Select state' : PROVINCE_PLACEHOLDER_TEXT;
    const postalCodeLabel = usOnly ? 'Zip Code' : POSTAL_CODE_LABEL_TEXT;
    const postalCodePlaceholderText = usOnly ? 'XXXXX' : POSTAL_CODE_PLACEHOLDER_TEXT;
    const postalCodeMask = usOnly ? ZIP_CODE_MASK : POSTAL_CODE_MASK;
    const countryCode = usOnly ? 'US' : 'CA';
    const provincesToDisplay = provinces.filter(entry => entry.country === countryCode);

    // make the column span the whole column witdh
    const fullColWidth = {span: 24};

    useEffect(()=> {
        // Get values for Ant Design fields from the store whenever values update.
        const newValidationFieldValues = {
            [FIRST_NAME_FIELD]: firstName,
            [LAST_NAME_FIELD]: lastName,
            [GENDER_FIELD]: gender,
            [PHONE_FIELD]: phone || '',
            [EMAIL_FIELD]: email,
            [DOB_DAY_FIELD]: dobDayValue,
            [DOB_MONTH_FIELD]: dobMonthValue,
            [DOB_YEAR_FIELD]: dobYearValue,
            [PROVINCE_FIELD]: province,
            [PHN_FIELD]: PHN,
            [SECONDARY_PHN_FIELD]: secondPHN,
            [STREET_FIELD]: street,
            [CITY_FIELD]: city,
            [POSTAL_CODE_FIELD]: postalCode,
            [GP_CURRENT_FIELD]: gp,
            [GP_FAX_NUMBER_FIELD]: gpFax,
            [GP2_CURRENT_FIELD]: gp2,
            [GP2_FAX_NUMBER_FIELD]: gp2Fax,
            [CAF_SERVICE_NUMBER_FIELD]: CAFServiceNumber,
            [PLEASE_CONFIRM_FIELD]: pleaseConfirm,
            [IS_CAF_PERSONNEL_FIELD]: isCAF,
            [HAS_NO_PHN_FIELD]: hasNoPHN,
            ha_no_gp: hasNoGp,
            insurance_type: insuranceType,
        };

        const formValues = userIsADMIN ? {
            ...newValidationFieldValues,
            is_test_patient: isTestPatient,
            deleted: isDeleted,
        } : newValidationFieldValues
        form.setFieldsValue(formValues);
    },[form, firstName, lastName, gender, phone, email, dobDayValue, dobMonthValue, dobYearValue,
        province, PHN, secondPHN, street, city, postalCode, gp, gpFax, gp2, gp2Fax, CAFServiceNumber,
        pleaseConfirm, isCAF, hasNoPHN, isTestPatient, isDeleted, userIsADMIN, hasNoGp, insuranceType])


    const validateUniquePHN = async (_: RuleObject, value: string) => {
        // We don't care about empty PHNs. It's considered valid.
        if (!value) {
            return Promise.resolve();
        }
        // Check if the PHN is already used on the backend.
        const res = await dispatch(patientPHNExistsRequest({
            patientId: patientID || '',
            phn: value,
            doctor: doctorID
        })).unwrap();
        return res && res.phnExist ? Promise.reject(new Error(ERROR_PHN_ALREADY_EXISTS)) : Promise.resolve();
    }

    const onFieldsChange = async (fields: FieldData[]) => {
        // DOB fields
        const { dob } = patientEditDetails;
        const dobDay = form.getFieldValue(DOB_DAY_FIELD);
        const dobMonth = form.getFieldValue(DOB_MONTH_FIELD);
        const dobYear = form.getFieldValue(DOB_YEAR_FIELD);
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name.length && !field.validating) {
                switch (field.name[0]) {
                    case FIRST_NAME_FIELD:
                    case LAST_NAME_FIELD:
                    case GENDER_FIELD:
                    case PHONE_FIELD:
                    case EMAIL_FIELD:
                    case PHN_FIELD:
                    case SECONDARY_PHN_FIELD:
                    case STREET_FIELD:
                    case CITY_FIELD:
                    case IS_CAF_PERSONNEL_FIELD:
                    case CAF_SERVICE_NUMBER_FIELD:
                    case PLEASE_CONFIRM_FIELD:
                    case 'is_test_patient':
                    case 'deleted':
                    case GP_FAX_NUMBER_FIELD:
                    case GP2_FAX_NUMBER_FIELD:
                    case 'has_no_gp':
                        dispatch(setPatientEditValue({
                            field: field.name[0],
                            value: field.value
                        }));
                        break;
                    case 'insurance_type':
                        // When "no insurance" is selected, clear the PHN field.
                        dispatch(setPatientEditValue({
                            field: field.name[0],
                            value: field.value
                        }));
                        if (field.value === 'no_insurance') {
                            dispatch(setPatientEditValue({
                                field: PHN_FIELD,
                                value: ''
                            }));
                        } else {
                            dispatch((dispatch, getState) => {
                                const phn = getState().patientDetails.details.phn;
                                dispatch(setPatientEditValue({
                                    field: PHN_FIELD,
                                    value: phn,
                                }));
                            })
                        }
                        break;
                    case POSTAL_CODE_FIELD:
                        const postal_code_value = field.value || '';
                        dispatch(setPatientEditValue({
                            field: field.name[0],
                            value: postal_code_value.toUpperCase()
                        }));
                        break;
                    case DOB_DAY_FIELD:
                        /*
                         * Ensure that the value inputted is either blank or a number. We don't want
                         * to allow any letters.
                         */
                        if (Number(field.value) || field.value === '') {
                            dispatch(setPatientEditValue({
                                field: DOB_DAY_FIELD,
                                value: field.value,
                            }));
                        }
                        // If both the other date fields are filled out, generate and validate DOB
                        if (field.value && dobMonth && dobYear) {
                            const newDOB = isDOBDayValid(field.value, dobMonth, dobYear);
                            /*
                             * Only need to update the DOB if it's both valid AND differs from
                             * the current DOB saved in the patientEditDetails
                             */
                            if(newDOB && !dayjs(dob).isSame(dayjs(newDOB), 'date')){
                                dispatch(setPatientEditValue({
                                    field: DOB_FIELD,
                                    value: newDOB,
                                }));
                            }
                        }
                        break;
                    case DOB_MONTH_FIELD:
                        dispatch(setPatientEditValue({
                            field: DOB_MONTH_FIELD,
                            value: field.value,
                        }));
                        // If both the other date fields are filled out, generate and validate DOB
                        if (field.value && dobDay && dobYear) {
                            const newDOB = isDOBDayValid(dobDay, field.value, dobYear);
                            /*
                            * Only need to update the DOB if it's both valid AND differs from
                            * the current DOB saved in the patientEditDetails
                            */
                            if(!newDOB){
                                form.setFields([{name:[DOB_DAY_FIELD], errors: [DOB_ERROR_MESSAGE]}]);
                            }
                            if(newDOB && !dayjs(dob).isSame(dayjs(newDOB), 'date')){
                                // If the date is valid unset the error on the day field
                                form.setFields([{name:[DOB_DAY_FIELD], errors: []}]);
                                dispatch(setPatientEditValue({
                                    field: DOB_FIELD,
                                    value: newDOB,
                                }));
                            }
                        }
                        break;
                    case DOB_YEAR_FIELD:
                        /*
                         * Ensure that the value inputted is either blank or a number. We don't want
                         * to allow any letters.
                         */
                        if ((Number(field.value) && Number(field.value) < 10000) || field.value === '') {
                            dispatch(setPatientEditValue({
                                field: DOB_YEAR_FIELD,
                                value: field.value
                            }));
                        }
                        // If both the other date fields are filled out, generate and validate DOB
                        if (field.value && dobDay && dobMonth) {
                            const newDOB = isDOBDayValid(dobDay, dobMonth, field.value);
                            /*
                             * Only need to update the DOB if it's both valid AND differs from
                             * the current DOB saved in the patientEditDetails
                             */
                            if(!newDOB){
                                form.setFields([{name:[DOB_DAY_FIELD], errors: [DOB_ERROR_MESSAGE]}]);
                            }
                            if(newDOB && !dayjs(dob).isSame(dayjs(newDOB), 'date')){
                                // If the date is valid unset the error on the day field
                                form.setFields([{name:[DOB_DAY_FIELD], errors: []}]);
                                dispatch(setPatientEditValue({
                                    field: DOB_FIELD,
                                    value: newDOB,
                                }));
                            }
                        }
                        break;

                    // Multiple actions required
                    case PROVINCE_FIELD:
                        if (field.value !== patientEditDetails[PROVINCE_FIELD]) {
                            dispatch(setPatientEditValue({
                                field: field.name[0],
                                value: field.value,
                            }));
                            // Set PHN related data
                            dispatch(setHealthNumberData(field.value));
                        }
                        break;
                    case GP_CURRENT_FIELD:
                        // Set the GP's fax number in patient edit details store
                        if (field.value === undefined) {
                            dispatch(setPatientEditValue({
                                field: GP_FAX_NUMBER_FIELD,
                                value: '',
                            }));
                            dispatch(setPatientEditValue({
                                field: field.name[0],
                                value: '',
                            }));
                        }
                        else {
                            dispatch(setPatientEditValue({
                                field: field.name[0],
                                value: field.value,
                            }));
                        }
                        break;
                    case GP2_CURRENT_FIELD:
                        // Set the GP2's fax number in patient edit details store
                        if (field.value === undefined) {
                            dispatch(setPatientEditValue({
                                field: GP2_FAX_NUMBER_FIELD,
                                value: '',
                            }));
                            dispatch(setPatientEditValue({
                                field: field.name[0],
                                value: '',
                            }));
                        }
                        else {
                            dispatch(setPatientEditValue({
                                field: field.name[0],
                                value: field.value,
                            }));
                        }
                        break;
                    default:
                        break;
                }
            }
        });
    }

    return (
        <Form
            className="patient-edit-form"
            form={form}
            onFieldsChange={onFieldsChange}
            onFinish={successAction}
        > {/* Test PE 001 */}
            <Spin
                className="loading-spinner"
                spinning={isOperating || isFetching}
                size="large"
                tip={OPERATING_TEXT}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        {/* PE 002 */}
                        <FormItem
                            label={FIRST_NAME_LABEL_TEXT}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={FIRST_NAME_FIELD}
                            validateTrigger={'onBlur'}
                            rules={[{
                                required: true,
                                whitespace: true,
                                message: buildRequiredErrorMessage(FIRST_NAME_LABEL_TEXT),
                            }]}
                            initialValue={firstName}
                        >
                            <Input
                                className={`first-name-input`}
                                placeholder={FNAME_PLACEHOLDER_TEXT}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        {/* PE 003 */}
                        <FormItem
                            label={LAST_NAME_LABEL_TEXT}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={LAST_NAME_FIELD}
                            validateTrigger={'onBlur'}
                            rules={[{
                                required: true,
                                whitespace: true,
                                message: buildRequiredErrorMessage(LAST_NAME_LABEL_TEXT),
                            }]}
                            initialValue={lastName}
                        >
                            <Input
                                className={`last-name-input`}
                                placeholder={LNAME_PLACEHOLDER_TEXT}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        {/* PE 007 */}
                        <Row gutter={5} className="dob">
                            <Col span={11}>
                                <FormItem
                                    hasFeedback
                                    help=""
                                    label={DOB_LABEL_TEXT}
                                    labelCol={fullColWidth}
                                    colon={false}
                                    name={DOB_MONTH_FIELD}
                                    rules={[{
                                        required: true,
                                    }]}
                                    initialValue={dobMonthValue}
                                >
                                    <Select data-testid='month-select' className={'dob-month'} placeholder={MONTH_TEXT}>
                                        {MONTHS.map((month, index) => {
                                            // Get the index of our ordered date list and add one
                                            // so the value is valid to use with JS Date() class.
                                            const monthInt = index + 1;
                                            return (
                                                <Option
                                                    key={monthInt}
                                                    value={month}
                                                >
                                                    {month}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    className="hide-title dob-day-form-item"
                                    hasFeedback
                                    label="&nbsp;"
                                    labelCol={fullColWidth}
                                    help=""
                                    name={DOB_DAY_FIELD}
                                    validateTrigger={'onBlur'}
                                    initialValue={dobDayValue}
                                    getValueFromEvent={ (e) => {
                                        const dobDay = Number(e.currentTarget.value);
                                        // If value is not a valid number return empty
                                        if(!dobDay){
                                            return '';
                                        }
                                        // In all cases the day can never be greater than 31
                                        else if(dobDay > 31){
                                            return form.getFieldValue(DOB_DAY_FIELD);
                                        }
                                        // Return value if it is valid number
                                        else {
                                            return e.currentTarget.value;
                                        }
                                    }}
                                    rules={[{
                                        required: true,
                                        pattern: /^[1-9][0-9]*$/,
                                        message: DOB_ERROR_MESSAGE,
                                        },
                                        ({getFieldValue})=>({
                                            validator(_,value){
                                                const dobMonth = getFieldValue(DOB_MONTH_FIELD);
                                                const dobYear = getFieldValue(DOB_YEAR_FIELD);

                                                // If all fields are complete check that the date is valid
                                                if(value && dobMonth && dobYear){
                                                    const isValid = isDOBDayValid(value, dobMonth, dobYear);
                                                    if (!isValid) {
                                                        return Promise.reject(new Error(DOB_ERROR_MESSAGE));
                                                    }
                                                }
                                                return Promise.resolve();
                                            }
                                        }),
                                    ]}
                                >
                                    <Input data-testid='day-select' className={'dob-day'} placeholder={DAY_TEXT} />
                                </FormItem>
                            </Col>
                            <Col span={7}>
                                <FormItem
                                    className="hide-title dob-year-form-item"
                                    label="&nbsp;"
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    help=""
                                    name={DOB_YEAR_FIELD}
                                    validateTrigger={'onBlur'}
                                    initialValue={dobYearValue}
                                    getValueFromEvent={ (e) => {
                                        const dobYear = Number(e.currentTarget.value);

                                            // If value is not a valid number return empty
                                            if(!dobYear){
                                                return '';
                                            }
                                            // Birth year can not be greater than this year or older than 1900
                                            else if(dobYear && e.currentTarget.value.length === 4 &&
                                                ((Number(dobYear) < 1900) || (Number(dobYear) > new Date().getFullYear()))){
                                                return form.getFieldValue(DOB_YEAR_FIELD);
                                            }
                                            else if (dobYear && e.currentTarget.value.length > 4) {
                                                return form.getFieldValue(DOB_YEAR_FIELD);
                                            }
                                            // Return value if it is a valid number
                                            else {
                                                return e.currentTarget.value;
                                            }
                                    }}
                                    rules={[{
                                        required: true,
                                        pattern: new RegExp('^[0-9]{4}$'),
                                    }]}
                                >
                                    <Input data-testid='year-select' className={'dob-year'} placeholder={YEAR_TEXT} />
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        {/* PE 008 */}
                        <FormItem
                            label={provinceLabel}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={PROVINCE_FIELD}
                            rules={[{
                                required: true,
                                message: buildRequiredErrorMessage(provinceLabel)
                            }]}
                            initialValue={dobYearValue}
                        >
                            <Select
                                placeholder={provincePlaceholderText}
                                className="patient-province-input"
                                data-testid='province-select'
                            >
                                {provincesToDisplay.length > 0 && provincesToDisplay.map(province => {
                                        return (
                                            <Select.Option key={province.id} value={province.id}>
                                                {province.name}
                                            </Select.Option>
                                        );
                                    })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        {/* PE 009 */}
                        { usOnly ?
                            <Row>
                                <Button
                                    className='edit-insurance-button'
                                    onClick={() => {
                                        dispatch(toggleInsuranceModal());
                                        dispatch(getUsInsuranceListRequest(patientID!));
                                        dispatch(closeEditPatientModal());
                                    }}
                                    // If there is no patient ID yet, that means there is no patient to add an insurance
                                    // policy to, so don't allow access to that yet.
                                    disabled={!patientID}
                                >
                                    View or Edit Insurance
                                </Button>
                                <Tooltip placement='right' className='view-us-insurance-tooltip' title={`View, add and edit insurance policies after the patient is created.`}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </Row>
                            :
                        <>
                            {/* PE 008 */}
                            <Form.Item
                                label={'Type of Insurance'}
                                labelCol={fullColWidth}
                                hasFeedback
                                colon={false}
                                name={'insurance_type'}
                                rules={[{
                                    required: true,
                                    message: buildRequiredErrorMessage('Insurance Type')
                                }]}
                                initialValue={''}
                            >
                                <Select
                                    placeholder={''}
                                    className="insurance-type-input"
                                    data-testid='insurance-type-select'
                                >
                                    {insuranceTypeDisplayOptions.length > 0 && insuranceTypeDisplayOptions.map(insuranceTypeOption => {
                                            return (
                                                <Select.Option key={insuranceTypeOption.value} value={insuranceTypeOption.value}>
                                                    {insuranceTypeOption.label}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </>
                        }
                    </Col>
                    { usOnly ?
                        <Col span={8}>
                            <Row>
                                <FormItem
                                    className="us-has-no-phn"
                                    name={HAS_NO_PHN_FIELD}
                                    valuePropName={'checked'}
                                    initialValue={hasNoPHN}
                                >
                                    <Checkbox data-testid='no-insurance-checkbox'>
                                        {noInsuranceText}
                                    </Checkbox>
                                </FormItem>
                            </Row>
                        </Col>
                        :
                    <>
                        <Col span={8}>
                            {/* PE 009 */}
                            <Form.Item
                                className={'patient-phin-label'}
                                label={'Insurance No.'}
                                labelCol={fullColWidth}
                                hasFeedback
                                colon={false}
                                name={PHN_FIELD}
                                validateTrigger={'onBlur'}
                                initialValue={''}
                                rules={[{
                                    pattern: !hasNoPHN ? /^[^_]+$/ : /^\s+$/,
                                    message: ERROR_INVALID_HN_FORMAT,
                                }, {
                                    required: (!hasNoPHN && !isArmedForcesPersonnel),
                                    message: buildRequiredErrorMessage(PHNLabel),
                                }, {
                                    validator: validateUniquePHN,
                                }]}
                            >
                                <InputMask
                                    data-testid='insurance-input'
                                    mask={insuranceMask.replace('?', '')}
                                    placeholder={insurancePlaceholder}
                                    className="patient-phin-input ant-input"
                                    disabled={!isProvinceSelected || hasNoPHN}
                                />
                            </Form.Item>
                        </Col>
                    </>}
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        {/* PE 013, PE 017 */}
                        <FormItem
                            label={
                                <div className='gp-label-row'>
                                    <span>{GP_LABEL_TEXT}</span>
                                    <div className='gp-buttons'>
                                        {
                                            !gp && !gp2 &&
                                            <FormItem
                                                name='has_no_gp'
                                                noStyle
                                                valuePropName='checked'
                                            >
                                                <Checkbox className='has-no-gp'>
                                                    No GP
                                                </Checkbox>
                                            </FormItem>
                                            
                                        }
                                       
                                        { gp && <div
                                            data-testid='edit-gp-onclick'
                                            className='patient-edit-gp-button'
                                            onClick={() => {
                                                dispatch(setAddEditGpFieldData(GP_CURRENT_FIELD));
                                                dispatch(openEditGPModal());}}
                                            >
                                            <EditOutlined />
                                            <span>Edit GP</span>
                                        </div>}
                                        <div
                                            data-testid='new-gp-onclick'
                                            className='patient-new-gp-button'
                                            onClick={() => {
                                                dispatch(setAddEditGpFieldData(GP_CURRENT_FIELD));
                                                dispatch(openAddGPModal());}}
                                        >
                                            <PlusOutlined />
                                            <span>{NEW_GP_TEXT}</span>
                                        </div>
                                    </div>
                                </div>}
                            labelCol={fullColWidth}
                            hasFeedback
                            help=""
                            colon={false}
                            name={GP_CURRENT_FIELD}
                            initialValue={gp}
                        >
                            <Select
                                data-testid='gp-input'
                                placeholder={GP_PLACEHOLDER_TEXT}
                                className="patient-gp-input"
                                showSearch
                                allowClear={true}
                                filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    gpList && gpList.map((gp) => {
                                        const displayName = formatGPName(gp);
                                        return (
                                            <Option key={gp.id} value={gp.id}>{displayName}</Option>
                                        );
                                    })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        {/* PE 014, PE 018 */}
                        <FormItem
                            label={GP_FAX_NUMBER_LABEL_TEXT}
                            className='gp-fax-number-form'
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            validateTrigger={'onBlur'}
                            name={GP_FAX_NUMBER_FIELD}
                            rules={[{
                                pattern: /^[^_]+$/,
                                message: ERROR_INVALID_PHONE_FORMAT,
                            }]}
                            initialValue={gpFax}
                            tooltip={{
                                title: GP_FAX_INFO_TEXT,
                                icon: <InfoCircleOutlined />,
                              }}
                        >
                            <InputMask
                                data-testid='gp-fax-input'
                                placeholder={GP_FAX_PLACEHOLDER_TEXT}
                                className="patient-gp-fax-input ant-input"
                                mask={PHONE_MASK_FORMAT}
                            />
                        </FormItem>
                    </Col>
                    {!usOnly &&
                        <Col span={8}>
                            <Form.Item
                                label={'Insurance No. #2'}
                                labelCol={fullColWidth}
                                hasFeedback
                                colon={false}
                                name={SECONDARY_PHN_FIELD}
                                validateTrigger={'onBlur'}
                                initialValue={''}
                                rules={[{
                                    pattern: /^[^_]+$/,
                                    message: ERROR_INVALID_HN_FORMAT,
                                }]}
                            >
                                <InputMask
                                    className="patient-mhsc-input"
                                    placeholder={PHNSecondaryRequired && isProvinceSelected && !hasNoPHN ? 'Enter MHSC Number' : ''}
                                    mask={PHNSecondaryMask}
                                    disabled={!(PHNSecondaryRequired && isProvinceSelected && !hasNoPHN)}
                                />
                            </Form.Item>
                        </Col>
                    }
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        {/* PE 005 */}
                        <FormItem
                            label={PHONE_LABEL_TEXT}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={PHONE_FIELD}
                            validateTrigger={'onBlur'}
                            rules={[{
                                pattern: /^[^_]+$/,
                                message: ERROR_INVALID_PHONE_FORMAT,
                            }]}
                            initialValue={phone || ''}
                        >
                            <InputMask
                                data-testid='phone-input'
                                className={'phone-number-input ant-input'}
                                mask={PHONE_MASK_FORMAT}
                                placeholder={PHONE_PLACEHOLDER_TEXT}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        {/* Test 006 */}
                        <FormItem
                            label={EMAIL_LABEL_TEXT}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={EMAIL_FIELD}
                            validateTrigger={'onBlur'}
                            rules={[{
                                type:'email',
                                message: ERROR_INVALID_EMAIL_FORMAT,
                            }]}
                            initialValue={email}
                        >
                            <Input
                                data-testid='email-input'
                                className={"email-address-input"}
                                placeholder={EMAIL_PLACEHOLDER_TEXT}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        {/* PE 004 */}
                        <FormItem
                            label={GENDER_LABEL_TEXT}
                            labelCol={fullColWidth}
                            name={GENDER_FIELD}
                            initialValue={gender}
                        >
                            <RadioGroup>
                                <Radio data-testid="male-gender-button" className="gender-radio-button male" value={GENDER_MALE_FIELD}>
                                    {GENDER_MALE_TEXT}
                                </Radio>
                                <Radio data-testid="female-gender-button" className="gender-radio-button female" value={GENDER_FEMALE_FIELD}>
                                    {GENDER_FEMALE_TEXT}
                                </Radio>
                            </RadioGroup>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        {/* PE 010 */}
                        <FormItem
                            label={STREET_LABEL_TEXT}
                            labelCol={fullColWidth}
                            help=""
                            colon={false}
                            name={STREET_FIELD}
                            initialValue={street}
                        >
                            <Input
                                data-testid='street-input'
                                placeholder={STREET_PLACEHOLDER_TEXT}
                                className="patient-street-input"
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        {/* PE 010 */}
                        <FormItem
                            label={CITY_LABEL_TEXT}
                            labelCol={fullColWidth}
                            help=""
                            colon={false}
                            name={CITY_FIELD}
                            initialValue={city}
                        >
                            <Input
                                data-testid='city-input'
                                placeholder={CITY_PLACEHOLDER_TEXT}
                                className="patient-city-input"
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        {/* PE 012 */}
                        <FormItem
                            label={postalCodeLabel}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={POSTAL_CODE_FIELD}
                            validateTrigger={'onBlur'}
                            rules={[{
                                pattern: /^[^_]+$/,
                                message: ERROR_INVALID_POSTAL_CODE_FORMAT,
                            }]}
                            initialValue={postalCode}
                        >
                            <InputMask
                                data-testid='postal-input'
                                placeholder={postalCodePlaceholderText}
                                className="patient-postal-code-input ant-input"
                                mask={postalCodeMask}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        {/* PE 019, PE 021 */}
                        <FormItem
                            label={
                                <div className='gp-label-row'>
                                <span>{GP2_LABEL_TEXT}</span>
                                <div className='gp-buttons'>
                                    { gp2 && <div
                                        data-testid='edit-gpfax-onclick'
                                        className='patient-edit-gp-button'
                                        onClick={() => {
                                            dispatch(setAddEditGpFieldData(GP2_CURRENT_FIELD));
                                            dispatch(openEditGPModal());}}
                                        >
                                        <EditOutlined />
                                        <span>Edit MD#2</span>
                                    </div>}
                                    <div
                                        data-testid='new-gp2-onclick'
                                        className='patient-new-gp-button'
                                        onClick={() => {
                                            dispatch(setAddEditGpFieldData(GP2_CURRENT_FIELD));
                                            dispatch(openAddGPModal());}}
                                    >
                                        <PlusOutlined />
                                        <span>{NEW_GP2_TEXT}</span>
                                    </div>
                                </div>
                            </div>
                            }
                            labelCol={fullColWidth}
                            hasFeedback
                            help=""
                            colon={false}
                            name={GP2_CURRENT_FIELD}
                            initialValue={gp2}
                        >
                            <Select
                                data-testid='gp2-input'
                                placeholder={GP2_PLACEHOLDER_TEXT}
                                className="patient-gp2-input"
                                showSearch
                                allowClear={true}
                                filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    gpList && gpList.map((gp) => {
                                        const displayName = formatGPName(gp);
                                        return (
                                            <Option key={gp.id} value={gp.id}>{displayName}</Option>
                                        );
                                    })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        {/* PE 020, PE 022 */}
                        <FormItem
                            className='gp-fax-number-form'
                            label={GP2_FAX_NUMBER_LABEL_TEXT}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            validateTrigger={'onBlur'}
                            name={GP2_FAX_NUMBER_FIELD}
                            rules={[{
                                pattern: /^[^_]+$/,
                                message: ERROR_INVALID_PHONE_FORMAT,
                            }]}
                            initialValue={gp2Fax}
                            tooltip={{
                                title: MD2_FAX_INFO_TEXT,
                                icon: <InfoCircleOutlined />,
                                }}
                        >
                            <InputMask
                                data-testid='gp2-fax-input'
                                placeholder={GP2_FAX_PLACEHOLDER_TEXT}
                                className="patient-gp2-fax-input ant-input"
                                mask={PHONE_MASK_FORMAT}
                            />
                        </FormItem>
                    </Col>
                </Row>
                { userIsADMIN &&
                    <>
                    <Row gutter={24}>
                        <Col span={8}>
                            <FormItem
                                className="is-test-patient"
                                help=""
                                valuePropName='checked'
                                name={'is_test_patient'}
                                initialValue={isTestPatient}
                            >
                                <Checkbox
                                    data-testid='is-test-patient-checkbox'
                                    className="is-test-patient-checkbox">
                                    This Patient is Test Patient
                                </Checkbox>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem
                                className="is-deleted-patient"
                                help=""
                                valuePropName='checked'
                                name={'deleted'}
                                initialValue={isDeleted}
                            >
                                <Checkbox
                                    data-testid='is-deleted-patient-checkbox'
                                    className="is-deleted-patient-checkbox">
                                    This Patient is Deceased
                                </Checkbox>
                            </FormItem>
                        </Col>
                    </Row>
                    </>
                }
                <div className="please-confirm-section">
                    <Row>
                        <Col span={24}>
                            {/* PE 016 */}
                            <FormItem
                                className="please-confirm-label"
                                hasFeedback
                                colon
                                name={PLEASE_CONFIRM_FIELD}
                                rules={[{
                                    type: 'boolean',
                                    required: true,
                                    message: buildRequiredErrorMessage(PLEASE_CONFIRM_LABEL_TEXT),
                                    transform: (checked) => (checked || undefined),
                                }]}
                                valuePropName={'checked'}
                                initialValue={pleaseConfirm}
                            >
                                <Checkbox
                                    data-testid='confirm-checkbox'
                                    className="please-confirm-checkbox">
                                    {PLEASE_CONFIRM_LABEL_TEXT}
                                </Checkbox>
                            </FormItem>
                        </Col>
                    </Row>
                    <div className="please-confirm-text-area">
                        <div className="please-confirm-list">
                            <ul>
                                {
                                    PLEASE_CONFIRM_LIST_TEXT.map((row) => (
                                        (!usOnly || row.key !== 1) && <li key={row.key}>
                                            {row.value}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        {!usOnly &&
                        <div className="please-ensure-text">
                            <span className="heading">{PLEASE_ENSURE_HEADING_TEXT}</span>
                            {PLEASE_ENSURE_TEXT}
                        </div>
                        }
                    </div>
                </div>

            </Spin>
        </Form>
    );

}

export default PatientEditForm;
