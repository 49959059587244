import { Col, Row } from 'antd';
import { useCare1AppSelector } from '../../../apps/care1-hooks';
import PatientExamHeaderSaveButton from './patient-exam-header-save-button';
import PatientExamHeaderGPLetterButton from './patient-exam-header-gp-letter-button';
import ReferralLetterButtons from './referral-letter-buttons';
import PatientHandoutButton from './patient-handout-button';


const PatientExamHeaderButtons = () => {

    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const odUsesRluPei = useCare1AppSelector(store => store.examData.od_uses_rlu_pei);


    return (
        <>
        { !userIsOMDC && !userIsOMDR &&
            <Col span={6} className={'buttons'}>
                {
                    !odUsesRluPei ? 
                    <Row gutter={8}>
                        <Col span={12}>
                            <PatientExamHeaderSaveButton shouldCloseExamPage={false} />
                        </Col>
                        <Col span={12}>
                            <PatientExamHeaderSaveButton shouldCloseExamPage={true} />
                        </Col>
                    </Row>
                    :
                    <ReferralLetterButtons />
                }
                
                <Row gutter={8}>
                    <Col span={12}>
                        <PatientExamHeaderGPLetterButton />
                    </Col>
                    <Col span={12}>
                        <PatientHandoutButton />
                    </Col>
                </Row>
            </Col>
        }
        </>
    )
}

export default PatientExamHeaderButtons;