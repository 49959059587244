import { baseApi } from './base-api'


export interface ILatencyMetricsCreateLogRequest {
    exam_id: string;
    metric_name: string;
    latency_ms: number;
}

export interface ILatencyMetricsCreateLogResponse {
    success: boolean;
}

export interface ILatencyMetricsSummaryResponse {
    latency_metrics_list: {
        summary: {
            rows: [],
            days: [],
        }
    };
}

export const latencyMetricsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        latencyMetricsCreateLog: builder.mutation<ILatencyMetricsCreateLogResponse, ILatencyMetricsCreateLogRequest>({
            query(requestData) {
                return {
                    url: 'performance/latency/',
                    method: 'POST',
                    body: requestData,
                }
            },
        }),
        getLatencyMetricsSummary: builder.query<ILatencyMetricsSummaryResponse, void>({
            query: () => 'performance/latency_metrics_summary/',

            // Give a tag to staffs so that other API actions can invalidate LatencyMetricsSummary
            providesTags: [{ type: 'LatencyMetricsSummary', id: 'SUMMARY' }]
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const { useLatencyMetricsCreateLogMutation, useGetLatencyMetricsSummaryQuery } = latencyMetricsApi;
