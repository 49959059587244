import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-18-image-lightbox/style.css'; // This only needs to be imported once in your app
import './static/css/shared/care1-styles.scss';
import './static/css/shared/locals.scss';
import App from './js/apps/care1-react-app';
import { store } from './js/stores/retina-enabled-store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from "react-cookie";
import { PersistGate } from 'redux-persist/integration/react';
import persistor from './js/stores/persist-store';
import { localizedText } from './js/localizedText';

import { ConfigProvider } from 'antd';
import frCA from 'antd/es/locale/fr_CA';
import enUS from 'antd/es/locale/en_US';


const container = document.getElementById('root') as Element;
const root = createRoot(container);

// For now, the language setting is based on the URL.
let lang = enUS;
if (window.location.host === 'debut.soins1.ca') {
  localizedText.setLanguage('fr');
  document.body.classList.add('lang-fr');
  lang = frCA
}

root.render(
  <React.StrictMode>
    <ConfigProvider locale={lang}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
