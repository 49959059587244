import { Button, Modal, Col, Row, Typography } from 'antd';
import globalColors from '../../../static/css/shared/_global-style.scss'; // Styling Audit - New stylesheet
import '../../../static/css/components/patient-select-modal.scss'; // Styling Audit - New modal stylesheet
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { useState } from 'react';
import { closePatientSelectModal, openReferralLetterNewPatientModal } from '../../reducers/patient-edit-slice';
import PatientSearchBar from './patient-search-bar';

const PatientSelectModal = () => {
    
    const isVisible = useCare1AppSelector(store => store.patientEdit.patientSelectModalOpen);

    const [value, setValue] = useState('');
    
    const dispatch = useCare1AppDispatch();
  
    const onClose = () => {
        setValue('');
        dispatch(closePatientSelectModal());
    }

    return (
        <Modal
            className='patient-select-modal'
            open={isVisible}
            width="840"
            closable={false}
            footer={null}
            title='Patient Upload'
            maskStyle={{ backgroundColor: globalColors['maskOverlayBlur'] }}
        >
            <Row>
                <Col span={18}>
                    <Typography.Paragraph>Search by name or health number</Typography.Paragraph>
                    <PatientSearchBar 
                        value={value}
                        setValue={setValue}
                    />                    
                </Col>
                <Col span={1}>
                <Typography.Paragraph className='or-text'>Or</Typography.Paragraph>

                </Col>
                <Col span={5}>
                    <Typography.Paragraph>Add a new patient</Typography.Paragraph>
                    <Row>
                        <Button
                            type='primary'
                            className='new-patient-button'
                            onClick={() => {
                                onClose();
                                dispatch(openReferralLetterNewPatientModal());
                            }}
                        >Add New Patient</Button>
                    </Row>
                    <Row className='close-button-row'>
                        <Button
                            className='close-button'
                            onClick={onClose}
                        >Close</Button>
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
}

export default PatientSelectModal;
