import {
    Checkbox,
    Col,
    Flex,
    Input,
    Radio,
    Row,
    Select,
    Typography,
} from 'antd';
import InputMask from 'react-input-mask';
import {
    useCare1AppDispatch,
    useCare1AppSelector,
} from '../../../apps/care1-hooks';
import { PHONE_MASK_FORMAT } from '../../../constants';
import {
    useGetAllGpsQuery,
    useGetGpsByProvinceIdQuery,
    useGetSmartUploadOdRequestedOmdOptionsQuery,
} from '../../../services/doctor-api';

import '../../../../static/css/components/patient-select-modal.scss'; // Styling Audit - New modal stylesheet
import {
    DtcIopField,
    setSmartUploadSliceDataValue,
} from '../../../reducers/smart-upload-slice';
import ComponentRadioGroup from '../../common/component-radio-group';
import { localizedText } from '../../../localizedText';
import SmartUploadDtcHour from '../../integrated/smartUpload/smart-upload-dtc-hour';
import SmartUploadDtcIopEntry from '../../integrated/smartUpload/smart-upload-dtc-iop-entry';

const SmartUploadRow = () => {
    const { NO_TEXT, YES_TEXT } = localizedText;
    const province = useCare1AppSelector(
        (store) => store.uploadReconciler.province
    );
    const odNotes = useCare1AppSelector((store) => store.smartUpload.od_notes);
    const gp = useCare1AppSelector((store) => store.smartUpload.gp);
    const gpFax = useCare1AppSelector((store) => store.smartUpload.gp_fax);
    const hasNoGp = useCare1AppSelector((store) => store.smartUpload.has_no_gp);
    const highPriority = useCare1AppSelector(
        (store) => store.smartUpload.high_priority
    );
    const odWantsOmdReport = useCare1AppSelector(
        (store) => store.smartUpload.od_wants_omd_report
    );
    const odRequestedOmd = useCare1AppSelector(
        (store) => store.smartUpload.od_requested_omd
    );
    const odProvinceCode = useCare1AppSelector(
        (store) => store.smartUpload.odProvinceCode
    );

    const isManitoba = odProvinceCode === 'MB';

    const odId = useCare1AppSelector((store) => store.smartUpload.odId);
    console.log('od Id is ', odId);

    const { data } = useGetSmartUploadOdRequestedOmdOptionsQuery(odId!, {skip: !odId});

    const dispatch = useCare1AppDispatch();

    const {
        data: gpByProvinceList = [],
        isFetching: isGpListByProvinceFetching,
    } = useGetGpsByProvinceIdQuery(province === null ? -1 : province, {
        skip: !province,
    });
    const { data: gpAllList = [], isFetching: isGpAllListFetching } =
        useGetAllGpsQuery();

    const getGpList = () => {
        if (province === null) {
            return gpAllList;
        } else {
            return gpByProvinceList;
        }
    };

    return (
        <Row gutter={16} className='patient-reconciliation'>
            <Col span={12}>
                <Row>
                    <Col span={24}>
                        <label
                            className='ant-form-item-no-colon'
                            title='OD Notes'
                        >
                            OD Notes
                        </label>
                    </Col>
                    <Col span={24}>
                        <Input.TextArea
                            rows={6}
                            className={'od-notes-input'}
                            value={odNotes}
                            onChange={(e) =>
                                dispatch(
                                    setSmartUploadSliceDataValue({
                                        key: 'od_notes',
                                        value: e.currentTarget.value,
                                    })
                                )
                            }
                        />
                    </Col>
                </Row>
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center'>
                            <Typography.Text>High Priority</Typography.Text>
                            <ComponentRadioGroup
                                isWarning
                                value={highPriority}
                                handleRadioGroupChange={(e) =>
                                    dispatch(
                                        setSmartUploadSliceDataValue({
                                            key: 'high_priority',
                                            value: e.target.value,
                                        })
                                    )
                                }
                            >
                                <Radio.Button value={false}>
                                    {NO_TEXT.toUpperCase()}
                                </Radio.Button>

                                <Radio.Button value={true}>
                                    {YES_TEXT.toUpperCase()}
                                </Radio.Button>
                            </ComponentRadioGroup>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Flex justify='space-between' align='center'>
                            <Typography.Text>Report Type</Typography.Text>
                            <ComponentRadioGroup
                                value={odWantsOmdReport}
                                handleRadioGroupChange={(e) =>
                                    dispatch(
                                        setSmartUploadSliceDataValue({
                                            key: 'od_wants_omd_report',
                                            value: e.target.value,
                                        })
                                    )
                                }
                            >
                                <Radio.Button
                                    value={false}
                                    style={{
                                        minWidth: '80px',
                                        textAlign: 'center',
                                    }}
                                >
                                    AI
                                </Radio.Button>

                                <Radio.Button value={true}>
                                    AI + OMD
                                </Radio.Button>
                            </ComponentRadioGroup>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Flex justify='space-between' align='center'>
                            <Col span={10}>
                                <Typography.Text>
                                    OD Requested OMD
                                </Typography.Text>
                            </Col>
                            <Col span={14}>
                                <Select
                                    placeholder='Next Available OMD'
                                    options={data}
                                    optionFilterProp='label'
                                    value={odRequestedOmd}
                                    allowClear
                                    onChange={(value) =>
                                        dispatch(
                                            setSmartUploadSliceDataValue({
                                                key: 'od_requested_omd',
                                                value,
                                            })
                                        )
                                    }
                                />
                            </Col>
                        </Flex>
                    </Col>
                </Row>
            </Col>
            <Col span={6}>
                <Row>
                    <Col span={24}>
                        <label className='ant-form-item-no-colon' title='GP'>
                            GP
                        </label>
                        <Checkbox
                            value={hasNoGp}
                            onChange={(e) =>
                                dispatch(
                                    setSmartUploadSliceDataValue({
                                        key: 'has_no_gp',
                                        value: e.target.checked,
                                    })
                                )
                            }
                        >
                            No GP
                        </Checkbox>
                    </Col>
                    <Col span={24}>
                        <Select
                            className='patient-exam-gp-select'
                            showSearch
                            allowClear={true}
                            optionFilterProp='label'
                            placeholder={'Select Gp'}
                            options={getGpList().map((gp, index) => {
                                return {
                                    value: gp.id,
                                    label: `${gp.first_name} ${gp.last_name}`,
                                };
                            })}
                            value={gp}
                            onChange={(value) =>
                                dispatch(
                                    setSmartUploadSliceDataValue({
                                        key: 'gp',
                                        value,
                                    })
                                )
                            }
                        />
                    </Col>
                    <Col span={24}>
                        {isManitoba && (
                            <Row gutter={[0, 16]}>
                                <Col span={24}>
                                    <Flex vertical>
                                        <Typography.Text>
                                            Time of 1st IOP
                                        </Typography.Text>
                                        <SmartUploadDtcHour
                                            onBlurToSave={false}
                                        />
                                    </Flex>
                                </Col>

                                <Col span={24}>
                                    <Row gutter={[0, 8]}>
                                        {new Array(3)
                                            .fill(null)
                                            .map((_, index) => (
                                                <Col
                                                    span={24}
                                                    key={`dtc_iop_${index}`}
                                                >
                                                    <Row gutter={[8, 0]}>
                                                        <Col span={24}>
                                                            <Typography.Text>
                                                                IOP#{index + 1}
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col span={12}>
                                                            <SmartUploadDtcIopEntry
                                                                side='od'
                                                                field={
                                                                    `od_iop_${
                                                                        index +
                                                                        1
                                                                    }` as DtcIopField
                                                                }
                                                                onBlurToSave={
                                                                    false
                                                                }
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <SmartUploadDtcIopEntry
                                                                side='os'
                                                                field={
                                                                    `os_iop_${
                                                                        index +
                                                                        1
                                                                    }` as DtcIopField
                                                                }
                                                                onBlurToSave={
                                                                    false
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ))}
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Col>
            <Col span={6}>
                <Row>
                    <Col span={24}>
                        <label
                            className='ant-form-item-no-colon'
                            title='GP Fax No'
                        >
                            GP Fax No
                        </label>
                    </Col>
                    <Col span={24}>
                        <InputMask
                            className={'gp-fax-number-input'}
                            mask={PHONE_MASK_FORMAT}
                            placeholder={PHONE_MASK_FORMAT}
                            value={gpFax}
                            onChange={(e) =>
                                dispatch(
                                    setSmartUploadSliceDataValue({
                                        key: 'gp_fax',
                                        value: e.target.value,
                                    })
                                )
                            }
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default SmartUploadRow;
