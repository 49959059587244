import React, { ChangeEvent, useState } from "react";
import { Input, Button, Modal } from 'antd';
import { SendOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import { useCare1AppDispatch } from '../../apps/care1-hooks';
import { getNewODMessagingExam } from "../../reducers/patient-exam-slice";
import '../../../static/css/components/patient-exam-od-omd-messaging.scss';

const { TextArea } = Input;

const PatientExamODOMDMessaging = ({ patientId } : {patientId: number}) => {
    const dispatch = useCare1AppDispatch();

    const [inputMessage, setInputMessage] = useState<string>("");

    const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setInputMessage(e.target.value);
    };

    const handleSendMessage = () => {
        // Use the current date for the created exam.
        const currentTime = dayjs().format();
        dispatch(getNewODMessagingExam(patientId, currentTime, inputMessage));

        Modal.info({
            className: 'info-modal',
            title: 'Message Sent',
        });
    };

    return (
        <div className="od-omd-messaging">
            <div className="chat-input chat-input-top">
                <TextArea
                    placeholder="Send a message"
                    value={inputMessage}
                    onChange={handleInputChange}
                    autoSize
                    bordered={false}
                />
            </div>
            <div className="chat-input chat-input-bottom">
                <Button
                    onClick={handleSendMessage}
                    icon={<SendOutlined />}
                    disabled={inputMessage.length === 0}
                ></Button>
            </div>
        </div>
    );
};

export default PatientExamODOMDMessaging;
