import { baseApi } from './base-api'

export interface ICheckedServicesResponse {
    log_list: {
        rows: [],
        detail: {
            service_name: string,
        },
    };
}

export const servicesHealthCheckApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getCheckedServicesLog: builder.query<ICheckedServicesResponse, number>({
            query: (pk) => `admin/services_health_check/checked_services_log/${pk}/`,

            // Give a tag to staffs so that other API actions can invalidate CheckedServices
            providesTags: [{ type: 'CheckedServicesLog', id: 'CHECKEDSERVICESLOG' }]
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const { useGetCheckedServicesLogQuery } = servicesHealthCheckApi;