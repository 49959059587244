import { AppDispatch, RootState } from "../stores/retina-enabled-store";
import { ALTERNATE_TREATMENT_DROP_OPTIONS, SIDE_EFFECT_DROP_OPTIONS } from '../constants';

// Check all the comorbidities in the given exam data and get a user-readable text string describing them.
// Only asthma, heart conditions, and uveitis are listed.
export const getComorbiditiesText = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { examData } = getState();
    let comorbiditiesText = 'None';
    const comorbiditiesNone = examData.comorbidities_none;

    if (!comorbiditiesNone) {
        const asthma = examData.asthma;
        const heartBlock = examData.heart_block;
        const uveitisOd = examData.uveitis_od;
        const uveitisOs = examData.uveitis_os;

        comorbiditiesText = `${asthma ? 'Asthma' : ''}${heartBlock ? ', Heart Block' : ''}${uveitisOd ? ', Uveitis OD' : ''}${uveitisOs ? ', Uveitis OS' : ''}`;

        // It's possible for there to be other comorbidities but not the ones above, in that case the text should
        // display "None".
        if (!comorbiditiesText) {
            comorbiditiesText = 'None';
        }
    }

    return comorbiditiesText;
};

// For the given drops value, get the new default values that are restricted by the drops value.
export const getDefaultForTreatmentDrops = (treatmentDrops: string) => {
    // Get truncated options lists for the values affected by drops.
    const sideEffectDropOptions = SIDE_EFFECT_DROP_OPTIONS.filter(
        entry=>entry.required_drops.includes(treatmentDrops));
    const alternateTreatmentDropOptions = ALTERNATE_TREATMENT_DROP_OPTIONS.filter(
        entry=>entry.required_drops.includes(treatmentDrops));

    return {
        side_effect_drop: sideEffectDropOptions.find(entry=>entry.is_default)?.value,
        alternate_treatment_drop: alternateTreatmentDropOptions.find(entry=>entry.is_default)?.value,
    }
};


// For the given selection value and possible custom value, get the text for that selection from the given options.
export const getCustomizableSelectionText = (selectionValue: string, selectionCustomValue: string, options: {value: string; label: string}[]) => {
    // A custom option just means that the custom value is the text.
    if (selectionValue === 'custom') {
        return selectionCustomValue;
    }
    else {
        return options.find(entry => entry.value===selectionValue)?.label;
    }
};