import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';


const ReviewProcessingFilterInfo = () => (
    <Tooltip
        placement='bottomRight'
        overlayInnerStyle={{width: '560px', height: '400px'}}
        title={
            <div className='admin-tooltip-div'>
                if selected:
                    <ul>
                        <li>In house only, plus</li>
                        <li>Not a Validation Review, plus</li>
                        <li>OD wants an OMD report = True; OR</li>
                        <li>High Priority = True; OR</li>
                        <li>OD has a quest/issue = True; OR</li>
                        <li>Alert = True; OR</li>
                        <li>IOP Status = Requested by OMD</li>
                        <li>Always exclude OMDR review labeled as ‘Validation Review’.</li>
                    </ul>
                If not selected:
                    <ul>
                        <li>In house only, plus</li>
                        <li>OD province is BC or MB, plus</li>
                        <li>both OD request reports and AI recommends reports = False, OR </li>
                        <li>a Validation Review.</li>
                    </ul>
            </div>
        }
    >
        <InfoCircleOutlined style={{color: '#bfdfff'}} />
    </Tooltip>
);

export default ReviewProcessingFilterInfo;