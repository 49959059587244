// the chat components codes mainly from 
// https://github.com/fatihbaltaci/chatgpt-clone

import { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Modal, Spin } from 'antd';
import { IMessageEvent, w3cwebsocket } from 'websocket';
import GptUI from './gpt-ui';
import '../../../../static/css/components/admin-gpt.scss';
import { v4 as uuidv4 } from 'uuid';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { fetchGptChatAISnapshotOcularResponse, fetchGptChatAISnapshotOcularPrompt,
    retrieveGptChatAiSnapshotBandValues } from '../../../reducers/gpt-ai-snapshot-slice';

interface Message {
    input: string;
    output: string;
    meta: string;
}

const GptChatAISnapshotOcular = () => {
    const dispatch = useCare1AppDispatch();

    const webSocketRef = useRef<w3cwebsocket | null>(null);
    const reconnectIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [messageHistory, setMessageHistory] = useState<Message[]>([]);
    const [inputMessage, setInputMessage] = useState<string>("");
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [isAssistantTyping, setIsAssistantTyping] = useState<boolean>(false);

    const apiStatus = useCare1AppSelector(store => store.referralLetterAnalysis.api_status);

    const examId = useCare1AppSelector(store => store.examData.id);

    const handleSocketOnMessage = (text: string) => {
        let outputStr = '';
        let metaStr = '';
        try {
            const obj = JSON.parse(text);
            outputStr = obj['message']['output'];
            metaStr = obj['message']['meta'];

            setMessageHistory(h => {
                const m = [...h];
                if (m.length > 0) {
                    m[m.length - 1].output = outputStr;
                    m[m.length - 1].meta = metaStr;
                    return m;
                }
                else {
                    return [{ input: inputMessage, output: outputStr, meta: metaStr }];
                }
            });

            setIsAssistantTyping(false);
        }
        catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting GPT output',
            });
        };
    }

    const connectWebSocket = () => {

        if (webSocketRef.current) {
            console.log(`${webSocketRef.current.readyState} : ${WebSocket.OPEN}`);
        }

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
            console.log('WebSocket already connected');
            return;
        }

        const uuid = uuidv4();
        console.log(uuid);

        const socket = new w3cwebsocket(`${process.env.REACT_APP_WEBSOCKETURL}/gpt/chat_ai_snapshot_ocular/${uuid}`);

        socket.onopen = (): void => {
            console.log('WebSocket connected');
            // Clear the reconnect interval if the connection is successfully established
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };

        socket.onmessage = (message: IMessageEvent): void => {
            console.log('WebSocket: onmessage');
            handleSocketOnMessage(message.data.toString());

            if (examId != null) {
                dispatch(retrieveGptChatAiSnapshotBandValues(examId));
            }
        };

        socket.onclose = () => {
            console.log('WebSocket closed');
            // Set up a reconnect interval to attempt reconnection
            setReconnectInterval();
        };

        webSocketRef.current = socket;
    };

    const setReconnectInterval = (): void => {
        // Clear any existing reconnect interval
        clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        // Set a new reconnect interval (e.g., 5 seconds)
        reconnectIntervalRef.current = setInterval(connectWebSocket, 5000);
    };

    useEffect(() => {
        connectWebSocket();

        // Function to update the window height on window resize
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        // Add event listener to window resize
        window.addEventListener("resize", handleResize);

        // Get deidentified Referral Letter
        handleReset();

        // Get Gpt response
        getAutoGptResponse();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);

            // Close the WebSocket connection when the component unmounts
            if (webSocketRef.current) {
                webSocketRef.current.close();
            }
            // Clear the reconnect interval when the component unmounts
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messageHistory]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    const formatMessageContent = (content: string): string => {
        return content;
    }

    const sendMessage = () => {

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN && examId !== null) {

            console.log('WebSocket: sendMessage');
            webSocketRef.current.send(JSON.stringify({
                'message': {
                    'input': inputMessage,
                    'history': [],
                    'exam_id': examId
                }
            }));
            setMessageHistory(() => {
                return [{ input: inputMessage, output: '', meta: '' }];
            });
            setIsAssistantTyping(true);
        }
    }

    const divStyle = {
        height: `${windowHeight - 100}px`,
    };

    const getAutoGptResponse = async () => {

        if (examId === null) {
            return
        }
        
        try {
            const result = await dispatch(fetchGptChatAISnapshotOcularResponse()).unwrap();
            if (result && result.success) {
                if (result.gpt_chat_ai_snapshot_ocular_response !== '') {
                    handleSocketOnMessage(result.gpt_chat_ai_snapshot_ocular_response);
                }
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {

        }
    }

    const handleReset = async () => {
        if (examId === null) {
            return
        }

        try {
            const result = await dispatch(fetchGptChatAISnapshotOcularPrompt()).unwrap();
            if (result && result.success) {
                setInputMessage(result.gpt_chat_ai_snapshot_ocular_prompts);
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting GPT Chat AI Snapshot prompts',
            });
        }
    }

    return (
        <Spin
            className='loading-spinner'
            size='large'
            spinning={apiStatus === 'loading'}
        >
            <div className="chat-container">
                <Row>
                    <Col
                        span={24}>
                        <GptUI
                            messageHistory={messageHistory}
                            inputMessage={inputMessage}
                            setInputMessage={setInputMessage}
                            sendMessage={sendMessage}
                            formatMessageContent={formatMessageContent}
                            isAssistantTyping={isAssistantTyping}
                            messagesEndRef={messagesEndRef}
                        />
                        <Row>
                            <Col>
                                <Button
                                    className={''}
                                    onClick={handleReset}
                                    type="primary"
                                    size="large"
                                >
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Spin>)
}

export default GptChatAISnapshotOcular;