import { IIgpListItem } from "../reducers/igp-list-slice";
import { IOmdOption } from "../reducers/options-slice";

// Get the OMDs name from id
export const getOMDNameByID = (omdID: number, omdStore: IOmdOption[]) => {
    const omd = omdStore && omdStore.find(omd => omd.id === omdID);
    return omd ? `Dr. ${omd.first_name} ${omd.last_name}` : '';
}

// Get the IGPs name from id
export const getIGPNameByID = (igpID: number, igpStore: IIgpListItem[]) => {
    const igp = igpStore && igpStore.find(igp => igp.id === igpID);
    return igp ? `Dr. ${igp.first_name} ${igp.last_name}` : '';
}