import { Col, Row, InputNumber } from 'antd';
import {
    padStringStart, allowPosAndNegNumberAndDecimalOnlyKeys, allowPosNumberOnlyKeys} from '../../../helpers/utilities';
import * as Constants from '../../../constants';
import '../../../../static/css/components/patient-exam-rx.scss';
import ExamMetricValidationModal from '../../integrated/metrics/exam-metric-validation-modal';
import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { IRxRange, IRxRangeOption } from '../../../reducers/options-slice';

type ComponentProps = {
    sideSphere: string | number | null,
    onSphereChange: (value: number | string | null) => void;
    sideCylinder: string | number | null,
    onCylinderChange: (value: number | string | null) => void;
    sideAxis: string | number | null,
    onAxisChange: (value: number | string | null) => void;
    gptResultState: { [x: string]: string };
}

const GptPatientExamRx = ({ sideSphere, onSphereChange, sideCylinder, onCylinderChange, sideAxis, onAxisChange, gptResultState }: ComponentProps) => {

    const rxRanges = useCare1AppSelector(store => store.options.rxRanges);

    const { SPHERE_VALIDATION_MSG, CYLINDER_VALIDATION_MSG, AXIS_VALIDATION_MSG } = Constants;
    const { sphere, cylinder, axis } = rxRanges as IRxRange;

    const validateRxValue = (rxEntry: IRxRangeOption, value: string, validationMsg: string) => {
        if (value && (Number(value) < rxEntry.min || Number(value) > rxEntry.max)) {
            ExamMetricValidationModal(validationMsg);
            return false;
        }
        return true;
    }

    return (
        <div className={'patient-exam-rx'}>
            <Row gutter={2}>
                <Col span={8} className={'rx-sphere'}>
                    <div className={`${gptResultState['sphere']}`}>
                        <InputNumber
                            parser={value => {
                                if (value) {
                                    return value.toString().replace('+', '');
                                }
                                else {
                                    return value ? value : '';
                                }
                            }}
                            className={`rx-sphere-select`}
                            size={'small'}
                            step={sphere.step}
                            onKeyPress={event => allowPosAndNegNumberAndDecimalOnlyKeys(event)}
                            formatter={(value, info: {
                                userTyping: boolean;
                                input: string;
                                }) => {
                                if (value) {
                                    try {
                                        const f = parseFloat(value.toString());
                                        if (!isNaN(f)){
                                            if (info.userTyping) {
                                                if (f < 0) {
                                                    return `${value}`;
                                                }
                                                else {
                                                    return `+${value}`;
                                                }
                                            }
                                            else {
                                                if (f < 0) {
                                                    return `${f.toFixed(2)}`;
                                                }
                                                else {
                                                    return `+${f.toFixed(2)}`;
                                                }
                                            }
                                        }
                                    }
                                    catch (exc) {
                                    }
                                }
                                return '';
                            }}
                            onBlur={() => {
                                if (sideSphere) {
                                    validateRxValue(sphere, sideSphere.toString(), SPHERE_VALIDATION_MSG);
                                }
                            }}
                            value={sideSphere}
                            onChange={onSphereChange}
                        />
                    </div>
                </Col>
                <Col span={8} className={'rx-cylinder'}>
                    <div className={`${gptResultState['cylinder']}`}>
                        <InputNumber
                            parser={value => {
                                const regex = /([-])?([0-9]\.)?([0-9]{1,2})?.*/;
                                const matches = value?.match(regex);
                                return matches ? `${matches[1] || ''}${matches[2] || ''}${matches[3] || ''}` : '';
                            }}
                            className={`rx-cylinder-select`}
                            size={'small'}
                            step={cylinder.step}
                            onKeyPress={event => allowPosAndNegNumberAndDecimalOnlyKeys(event)}
                            onBlur={() => {
                                if (sideCylinder) {
                                    validateRxValue(cylinder, sideCylinder.toString(), CYLINDER_VALIDATION_MSG);
                                }
                            }}
                            value={sideCylinder}
                            onChange={onCylinderChange}
                        />
                    </div>
                </Col>
                <Col span={1} className={`rx-x`}>
                    X
                </Col>
                <Col span={7} className={'rx-axis'}>
                    <div className={`${gptResultState['axis']}`}>
                        <InputNumber
                            parser={value => {
                                const parsed = value?.replace(/[a-z]/g, '');
                                const parsed2 = parsed?.replace(/^[0]+/, '');
                                return parsed2 ? parsed2.substring(0, 3) : '';
                            }}
                            className={`rx-axis-select`}
                            size={'small'}
                            step={axis.step}
                            onKeyPress={event => allowPosNumberOnlyKeys(event)}
                            formatter={value => padStringStart((value as string), Constants.AXIS_MASK)}
                            onBlur={() => {
                                if (sideAxis) {
                                    validateRxValue(axis, sideAxis.toString(), AXIS_VALIDATION_MSG);
                                }
                            }}
                            value={sideAxis}
                            onChange={onAxisChange}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default GptPatientExamRx;