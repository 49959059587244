import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { Card, Row, Col } from 'antd';
import { DEEPMD_RECOMMENDATION_TEXT_BY_USER } from '../../../constants';
import { localizedText } from '../../../localizedText';
import '../../../../static/css/components/patient-exam-omdr-deepmd-band.scss';


const PatientExamOMDRCounsellingRecommendations = () => {
    const { DEEPMD_COUNSELLING_RECOMMENDATIONS } = localizedText;
    const finalDeepMDRecommendations = useCare1AppSelector(store => store.txalgo3.final_deepmd_recommendations);
    const allLeftComparisons = useCare1AppSelector(store => store.txalgo3.left_comparisons);
    const allRightComparisons = useCare1AppSelector(store => store.txalgo3.right_comparisons);

    const getDeepMDTextFromKeyValue = (discussKeyValues: string | undefined) => {
        // Break up the string into keyvalue items, which should be delimited with commas, but may also have a semicolon.
        const deepMDKeyValues = discussKeyValues ? discussKeyValues.split(/,|;/) : [];
        const deepMDText = DEEPMD_RECOMMENDATION_TEXT_BY_USER['OMD'];

        const deepMDTextArray = deepMDKeyValues.map(entry => {
            const keyValue = entry.trim();
            return keyValue && keyValue in deepMDText ? deepMDText[keyValue].text : keyValue;
        });

        return deepMDTextArray.join(', ');
    }

    const getComparisonText = (side: string) => {

        const comparisonList = (side === 'right') ? allRightComparisons : allLeftComparisons;

        for (let index = 0; index < comparisonList.length; index++) {
            if (comparisonList[index].dx !== '-') {
                // Change each comparison keyvalue into text, if needed.
                const discuss = getDeepMDTextFromKeyValue(comparisonList[index].discuss);

                if (comparisonList[index].display_level) {
                    return (
                        <div className={`recommendation display-level-${comparisonList[index].display_level}`}>
                            &#x2022;  {discuss}
                        </div> );
                }
                else {
                    return (
                        <div className='recommendation'>
                            &#x2022;  {discuss}
                        </div>
                    );
                }
            }
        }

        return '';
    };


    const getSelectedFinalDeepMDRecommendationsText = (side: string) => {
        type SelectedRecommendations = {
            display_level?: string;
            discuss?: string;
        }

        const finalSelectedDeepMDRecommendations: SelectedRecommendations = getSelectedFinalDeepMDRecommendations(side);

        if (finalSelectedDeepMDRecommendations) {
            const discuss = getDeepMDTextFromKeyValue(finalSelectedDeepMDRecommendations.discuss);

            if (finalSelectedDeepMDRecommendations.display_level) {
                return (
                    <div className={`recommendation display-level-${finalSelectedDeepMDRecommendations.display_level}`}>
                        &#x2022;  {discuss}
                        </div>
                );
            }
            else {
                return (
                    <div className='recommendation'>
                        &#x2022;  {discuss}
                    </div>
                );
            }
        }

        return '';
    }

    const getSelectedFinalDeepMDRecommendations = (side: string) => {
        const updatedDeepMDRecommendations = updateDeepMDRecommendations();

        const finalDeepMDRecommendations = (side === 'right') ?
            updatedDeepMDRecommendations.allRightFinalDeepMDRecommendations :
            updatedDeepMDRecommendations.allLeftFinalDeepMDRecommendations;

        let selectedRecommendations  = {};
        for (let index = 0; index < finalDeepMDRecommendations.length; index++) {
            if (finalDeepMDRecommendations[index].selected === 'Y') {
                selectedRecommendations = finalDeepMDRecommendations[index];
            }
        }

        return selectedRecommendations;
    }

    const updateDeepMDRecommendations = () => {
        const leftFinalDeepMDRecommendations = [];
        const rightFinalDeepMDRecommendations = [];

        for (let index = 0; index < finalDeepMDRecommendations.length; index++) {
            if (finalDeepMDRecommendations[index].left) {
                leftFinalDeepMDRecommendations.push(finalDeepMDRecommendations[index].left);
            }
            if (finalDeepMDRecommendations[index].right) {
                rightFinalDeepMDRecommendations.push(finalDeepMDRecommendations[index].right);
            }
        }

        return {
            allLeftFinalDeepMDRecommendations: leftFinalDeepMDRecommendations,
            allRightFinalDeepMDRecommendations: rightFinalDeepMDRecommendations,
        }
    }

    return (
        <Card className={'component-input-card counselling-recommendations'} bordered={false}>
            <Row>
                <div className="heading">{DEEPMD_COUNSELLING_RECOMMENDATIONS}</div>
            </Row>
            <div className='component-body counselling-recommendations-deepmd'>
                <Row>
                    <Col span={12}>
                        <span className={'sub-heading'}><b>DeepMD (OD)</b></span>
                        <br />
                        <span className={'recommendation-od'} id="od_deepmd_shootin" data-testid='od_deepmd_shootin'>
                            {getSelectedFinalDeepMDRecommendationsText('right')}
                            {getComparisonText('right')}
                        </span>
                    </Col>
                    <Col span={12}>
                        <span className={'sub-heading'}><b>DeepMD (OS)</b></span>
                        <br />
                        <span className={'recommendation-os'} id="os_deepmd_shootin" data-testid='os_deepmd_shootin'>
                            {getSelectedFinalDeepMDRecommendationsText('left')}
                            {getComparisonText('left')}
                        </span>
                    </Col>
                </Row>
            </div>
        </Card>
    );

}

export default PatientExamOMDRCounsellingRecommendations;