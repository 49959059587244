import { useState } from 'react';
import axios from 'axios';
import Care1LightBox from './care1-light-box';
import { CloudUploadOutlined, EyeOutlined } from '@ant-design/icons';
import { Card, Col, Row, Radio, Upload, Button, Modal } from 'antd';
import { API_UPLOAD_IMG_URL, GP_REFERRAL_LETTER } from '../../constants';
import { getBackendMediaUrl } from '../../helpers/media-image-convert';
import '../../../static/css/components/_patient-exam-gp-referred.scss';
import PatientExamGpReferredPDFViewerModal from './patient-exam-gp-referred-pdf-viewer-modal';
import { setPreReviewDataValue } from '../../reducers/pre-review-slice';
import { localizedText } from '../../localizedText';
import { setGPReferralAndUrl, setGPReferralProgress, setGPReferralUrl, setPEISpinner } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { UploadRequestOption, UploadProgressEvent, UploadRequestError, RcFile }  from 'rc-upload/lib/interface'

type ComponentProps = {
    disabled?: boolean,
    className?: string,
}

type UploadResult = {
    success: string,
    file: {key: string, url: string}[],
    error?: string,
}

const PatientExamGPReferred = ({ disabled, className }: ComponentProps) => {
    const { GP_REFERRED_TEXT, SUCCESFULLY_UPDATED_TEXT, YES_TEXT, NO_TEXT,
        UPLOAD_PROGRESS_ABORTED, UPLOAD_TEXT } = localizedText;

    const dispatch = useCare1AppDispatch();

    const gpReferred = useCare1AppSelector(store => store.examData.gp_refed);
    const gpReferralLetter = useCare1AppSelector(store => store.examData.referral_letter);
    const gpReferralLabel = useCare1AppSelector(store => store.examData.gpReferralUploadLabel);
    const csrfToken = useCare1AppSelector(store => store.user.csrfToken);
    const examId = useCare1AppSelector(store => store.examData.id);
    const isAdmin = useCare1AppSelector(store => store.user.isADMIN);

    const [showLetter, setShowLetter] = useState(false)

    const onPDFViewClose = () => {
        setShowLetter(false)
    }

    const uploadReady = !gpReferralLabel || gpReferralLabel === '100';

    const uploadProps = {
        name: GP_REFERRAL_LETTER,
        disabled: !uploadReady,
        action: `${process.env.REACT_APP_BACKENDURL}${API_UPLOAD_IMG_URL}`,
        multiple: false,
        data: { exam: examId },
        withCredentials: true,
        headers: { 'X-CSRFToken': csrfToken },
        onStart() {
            dispatch(setPEISpinner(true));
            dispatch(setGPReferralProgress('0'));
        },
        onSuccess({result, file}: {result: UploadResult, file: RcFile}) {
            dispatch(setPEISpinner(false));
            Modal.info({
                className: 'info-modal',
                title: `${file.name} ${SUCCESFULLY_UPDATED_TEXT}`,
                onOk: () => {
                    if (result.file && result.file.length > 0) {
                        if (result.file[0]['key'] === 'referral_letter') {
                            dispatch(setGPReferralUrl(result.file[0]['url']));
                        }
                    }
                },
            });
        },
        onError(err: UploadRequestError) {
            dispatch(setPEISpinner(false));
            Modal.error({
                className: 'info-modal',
                title: err.message,
            });
        },
        onProgress({ percent }: UploadProgressEvent) {
            dispatch(setGPReferralProgress(percent ? percent.toString() : ''));
        },
        customRequest({
            action,
            data,
            file,
            filename,
            headers,
            onError,
            onProgress,
            onSuccess,
            withCredentials,
        }: UploadRequestOption) {
            const formData = new FormData();
            if (data) {
                Object.keys(data).forEach((key) => {
                    formData.append(key, (data[key] as number).toString());
                });
            }
            filename && formData.append(filename, file);
            axios
                .post(action, formData, {
                    withCredentials,
                    headers,
                    onUploadProgress: ({ total = 100, loaded }) => {
                        onProgress!({ percent: Math.round((loaded / total) * 100) });
                    },
                })
                .then(({ data: response }) => {
                    onSuccess!({result: response, file});
                })
                .catch(onError);

            return {
                abort() {
                    dispatch(setPEISpinner(false));
                    Modal.error({
                        className: 'info-modal',
                        title: UPLOAD_PROGRESS_ABORTED,
                    });
                },
            };
        },
    };

    let showLetterPdf = false;
    if (gpReferralLetter) {
        const gpReferralLetterNames = gpReferralLetter.split('.');
        if (gpReferralLetterNames.length > 0) {
            const gpReferralLetterExt = gpReferralLetterNames[gpReferralLetterNames.length - 1];
            showLetterPdf = gpReferralLetterExt.toUpperCase() === 'PDF';
        }
    }
    const showLetterLightbox = !showLetterPdf;

    return (
        <div>
            {showLetter && showLetterPdf &&
                <PatientExamGpReferredPDFViewerModal
                    className="pdfViewerModal"
                    file={getBackendMediaUrl() + gpReferralLetter}
                    onPDFViewClose={onPDFViewClose}
                />
            }
            <Care1LightBox
                field={`exam_gf_referred`}
                isOpen={showLetter && showLetterLightbox}
                mainSrc={getBackendMediaUrl() + gpReferralLetter}
                onCloseRequest={() => setShowLetter(false)}
            />
            <Card className={className} bordered={false}>
                {(() => {
                    if (!gpReferred) {
                        return (
                            <Row className="gpRefCard">
                                <Col className="gpRef-col1">
                                    <span className="gpRef-title">{GP_REFERRED_TEXT}</span>
                                </Col>
                                <Col className="gpRefRadio">
                                    <Radio.Group
                                        value={gpReferred.toString()}
                                        buttonStyle="solid"
                                        size="small"
                                        onChange={(e) => {
                                            dispatch(setGPReferralAndUrl(e.target.value));
                                            isAdmin && dispatch(setPreReviewDataValue({key: 'isDirty', value: true }));
                                        }}
                                    >
                                        <Radio.Button disabled={disabled && !gpReferred} value="true">{YES_TEXT.toUpperCase()}</Radio.Button>
                                        <Radio.Button disabled={disabled && gpReferred} value="false">{NO_TEXT.toUpperCase()}</Radio.Button>
                                    </Radio.Group>
                                </Col>
                            </Row>
                        );
                    }
                    if (gpReferralLetter) {
                        return (
                            <Row className="gpRefCard">
                                <Col span={11} className="gpRef-col1">
                                    <span className='gpRef-title'>{GP_REFERRED_TEXT}</span>
                                </Col>
                                <Col className="viewIcon">
                                    <Button className="viewEyeIcon" size="small" onClick={(e) => setShowLetter(true)}>
                                        <EyeOutlined />
                                    </Button>

                                </Col>
                                <Col className="gpRefReUpload">
                                    <Upload {...uploadProps}>
                                        <Button className="gpRefAfterUpload" disabled={disabled} size="small">
                                            {uploadReady ? 'ReUpload' : `${gpReferralLabel} %`}
                                        </Button>
                                    </Upload>
                                </Col>
                                <Col className="gpRefRadio-yes">
                                    <Radio.Group
                                        value={gpReferred.toString()}
                                        buttonStyle="solid" size="small"
                                        onChange={(e) => {
                                            dispatch(setGPReferralAndUrl(e.target.value));
                                            isAdmin && dispatch(setPreReviewDataValue({key: 'isDirty', value: true }));
                                        }}
                                    >
                                        <Radio.Button disabled={disabled && !gpReferred} value="true">{YES_TEXT.toUpperCase()}</Radio.Button>
                                        <Radio.Button disabled={disabled && gpReferred} value="false">{NO_TEXT.toUpperCase()}</Radio.Button>
                                    </Radio.Group>
                                </Col>
                            </Row>
                        );
                    }
                    else {
                        return (
                            <Row className="gpRefCard">
                                <Col span={11} className="label-heading gpRef-col1">
                                    <span className='gpRef-title'>{GP_REFERRED_TEXT}</span>
                                </Col>
                                <Col className="gpRefUpload">
                                    <Upload {...uploadProps}>
                                        <Button
                                            disabled={disabled}
                                            className={uploadReady ? 'upload-ready' : 'upload-not-ready'}
                                            size="small"
                                        >
                                            <CloudUploadOutlined />
                                            {uploadReady ? UPLOAD_TEXT : `${gpReferralLabel} %`}
                                        </Button>
                                    </Upload>
                                </Col>
                                <Col className="gpRefRadio-yes">
                                    <Radio.Group
                                        className='yes-radio'
                                        value={gpReferred.toString()}
                                        buttonStyle="solid"
                                        size="small"
                                        onChange={(e) => {
                                            dispatch(setGPReferralAndUrl(e.target.value));
                                            isAdmin && dispatch(setPreReviewDataValue({key: 'isDirty', value: true }));
                                        }}
                                    >
                                        <Radio.Button disabled={disabled && !gpReferred} value="true">{YES_TEXT.toUpperCase()}</Radio.Button>
                                        <Radio.Button disabled={disabled && gpReferred} value="false">{NO_TEXT.toUpperCase()}</Radio.Button>
                                    </Radio.Group>
                                </Col>
                            </Row>
                        );
                    }

                })()}
            </Card>
        </div>
    );
}

export default PatientExamGPReferred;