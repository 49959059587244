import * as Constants from '../constants';
import { Modal } from 'antd';
import { AppDispatch, RootState } from "../stores/retina-enabled-store";
import { IDiagnosisEntry, IDiagnosisListItem, IDiagnosisOption, IDiagnosisValue } from '../reducers/diagnosis-slice';
import { IExamData } from '../reducers/patient-exam-slice';

// Various conversion functions used for diagnosis data.



// Retrieve needed diagnosis data to initialize the diagnosis list for the chief complaint values.
export const getDiagnosisValues = () => (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const state = getState();
        if (state.examData[Constants.DIAGNOSTICS_VALUES]) {
            const diagnosisValues = JSON.parse(state.examData[Constants.DIAGNOSTICS_VALUES]);

            // Add the "others" data to diagnosis values if it exists.
            if (state.examData[Constants.DIAGNOSIS_OTHERS]) {
                diagnosisValues[state.examData[Constants.DIAGNOSIS_OTHERS]] = state.examData[Constants.DIAGNOSIS_OTHERS];
            }
            return diagnosisValues;
        } else {
            return {};
        }
        
    } catch (error) {
        Modal.error({
            className: 'info-modal',
            title: `Errors getting diagnosis values, ${error}`,
        })
    }
    return {};
}

// Given a list of backend diagnosis objects, convert to a list of option objects containing each value, label, and type.
// This is used for the frontend chief complaint dropdown search list.
export function convertBackendDiagnosisListToOptions(diagnosisList: IDiagnosisListItem[]) {
    const options: IDiagnosisOption[] = [];

    if (!diagnosisList) {
        return options;
    }

    for (let index = 0; index < diagnosisList.length; index += 1) {
        let keywordCategory = '';

        // Each diagnosis can have multiple keywords. Make each keyword into one options label value.
        for (let keywordIndex = 0; keywordIndex < diagnosisList[index].keywords.length; keywordIndex += 1) {
            const labelText = diagnosisList[index].keywords[keywordIndex];

            const option: IDiagnosisOption = {
                value: diagnosisList[index].id,
                label: labelText + keywordCategory,
                type: diagnosisList[index].diagnosisType,
            };
            if (diagnosisList[index].diagnosisType === Constants.DIAGNOSIS_DOSAGE_DATE_TYPE) {
                option[Constants.DIAGNOSIS_DOSAGE] = diagnosisList[index][Constants.DIAGNOSIS_DOSAGE];
            }
            options.push(option);

            // All subsequent keywords are counted as being within the category of the first keyword.
            if (!keywordCategory) {
                keywordCategory = ` (${Constants.DIAGNOSIS_CATEGORY_TEXT}${labelText})`;
            }
        }
    }

    return options;
}

// Add the simple boolean diagnoses from the backend into the diagnoses options. This is also used for the frontend
// chief complaint dropdown search list.
export function convertBackendBooleanDiagnosesToOptions(): IDiagnosisOption[] {
    // For now, only the ones available for retina exams are in the constant list.
    const options: IDiagnosisOption[] = [];

    Constants.RR_BOOLEAN_DIAGNOSIS_FIELDS.forEach((entry) => {
        options.push({
            value: entry.value,
            label: entry.label,
            type: Constants.DIAGNOSIS_BOOLEAN_TYPE,
        });
    });

    return options;
}

// Create a list of diagnosis options based on the given backend diagnosis list and the built-in boolean diagnoses.
// for IC users, there is no need to add the built-in boolean diagnoses
export function generateDiagnosisListOptions(backendDiagnosisList: IDiagnosisListItem[], shouldAddBooleanOptions: boolean): IDiagnosisOption[] {
    const options = convertBackendDiagnosisListToOptions(backendDiagnosisList);
    return shouldAddBooleanOptions ? options.concat(convertBackendBooleanDiagnosesToOptions()) : options ;
}

// sort a list of options alphabetically based on option's label
export function sortOptions(options: IDiagnosisOption[]) {
    const sortedOptions = options && options.length > 0 ? [...options].sort((option1, option2) => (
        option1.label.toUpperCase().localeCompare(option2.label.toUpperCase())
    )) : options;
    return sortedOptions;
}

// Take the given backend diagnosis data and convert into the front-end entries in the Redux store.
export function convertBackendDiagnosisDataToStateEntries(diagnosisData: IDiagnosisValue, diagnosisListOptions: IDiagnosisOption[]) {
    const newEntries: IDiagnosisOption[] = [];

    // Go through the loaded data entries and convert them into our entries.
    Object.keys(diagnosisData).forEach((key) => {
        if (!key.includes(Constants.EYE_SELECT_SUFFIX) && !key.includes(Constants.DIAGNOSIS_DOSAGE_SUFFIX)
            && !key.includes(Constants.DIAGNOSIS_DOSAGE_DATE_SUFFIX) && diagnosisData[key]
            && key !== 'plaquenil') {
            // For now, we only take true/false values, with nothing else.
            // Get all the data for the entry from diagnosis list options.
            let newEntry = diagnosisListOptions.find((optionEntry) => optionEntry.value === key);

            // If it's not in the options list, that means it's a custom item, so just use it straight up.
            if (!newEntry) {
                newEntry = {
                    value: key,
                    label: key,
                    type: Constants.DIAGNOSIS_BOOLEAN_TYPE,
                };
            }

            newEntries.push(newEntry);
        } else if (key === 'plaquenil' && diagnosisData['plaquenil']){
            const { plaquenil_dose, plaquenil_date } = diagnosisData;
            const plaquenilDosage = plaquenil_dose ? ` ${plaquenil_dose}mg/day` : '';
            const plaquenilDate = plaquenil_date ? ` ${plaquenil_date}` : '';
            newEntries.push({
                value: 'plaquenil',
                label: `Plaquenil${plaquenilDosage}${plaquenilDate}`,
                type: 'dosage_date',
            });
        }
    });

    return newEntries;
}

// Take the simple backend boolean diagnoses and convert into additional front-end entries in the Redux store.
export function convertBackendBooleanDiagnosesToStateEntries(examData: IExamData, diagnosisListOptions: IDiagnosisOption[] ) {
    const newEntries: IDiagnosisOption[] = [];
    const rrBooleanFields = Constants.RR_BOOLEAN_DIAGNOSIS_FIELDS as unknown as  {value: keyof IExamData, label: boolean}[];
    rrBooleanFields.forEach((entry) => {
        if (examData[entry.value]) {
            const newEntry = diagnosisListOptions.find((optionEntry) => optionEntry.value === entry.value);
            newEntry && newEntries.push(newEntry);
        }
    });

    return newEntries;
}

export function extractDosageDate(str = '') {
    let dose = '';
    let date = '';
    const [,strDosage,strDate] = str.split(' ');
    // For case that 'Plaquenil' splits to ['Plaquenil',undefined, undefined]
    if(!strDosage){
        dose = '';
        date = '';
    // For case that 'Plaquenil 2mg/day 2012' splits to ['Plaquenil', '2mg/day', '2012
    } else if (strDosage && strDate){
        dose = strDosage.slice(0,-6); //remove mg/day
        date = strDate;
    // For case that 'Plaquenil 2mg/day' splits to ['Plaquenil','2mg/day', undefined]
    } else if (strDosage && strDosage.length !== 4){
        dose = strDosage.slice(0,-6); //remove mg/day
        date = '';
    // For case that 'Plaquenil 2012' splits to ['Plaquenil','2012', undefined]
    } else if (strDosage && strDosage.length === 4){
        dose = '';
        date = strDosage;
    }
    return {dose,date};
}

export function saveDiagnosisEntriesToString(entries: IDiagnosisEntry[], shouldAddBooleanOptions: boolean) {
    const diagnosisValues : Record<string, boolean | string> = {};
    // Null check in case no chief complaint diagnoses have been selected
    if(entries){
        entries.forEach(entry => {
            if (entry.value === 'plaquenil'){
                const { dose, date } = extractDosageDate(entry.label);
                diagnosisValues[Constants.PLAQUENIL_DOSE] = dose;
                diagnosisValues[Constants.PLAQUENIL_DATE] = date;
            }
            if(shouldAddBooleanOptions){
                // Unfortunately, some of the entries in the list here are simple booleans, so they shouldn't be included.
                if (!Constants.RR_BOOLEAN_DIAGNOSIS_FIELDS_KEY_ARRAY.includes(entry.value)) {
                    diagnosisValues[entry.value] = true;
                }
            } else {
                // RR_BOOLEAN values are not included in diagnosis entries for IC user, no need to check
                diagnosisValues[entry.value] = true;
            }
        })
    }

    return JSON.stringify(diagnosisValues);
}

// Get the boolean values for the six diagnostic values
export function getSixDiagnosticBooleanValues(diagnosticValues: IDiagnosisEntry[]) {
    const examDataDiagnoses: Record<string, boolean> = {};

    // Check if the entries given contain any boolean diagnosis values
    Constants.SIX_DIAGNOSTIC_VALUES_BOOLEAN_FIELDS.forEach((diagnosticValue) => {
        // Null check in case no chief complaint diagnoses have been selected
        if (diagnosticValues && diagnosticValues.find((entry) => entry.value === diagnosticValue.value)) {
            examDataDiagnoses[diagnosticValue.value] = true;
        } else {
            examDataDiagnoses[diagnosticValue.value] = false;
        }
    });

    return examDataDiagnoses;
}

// Sets boolean value to the rr_ fields in the exam data store.
export function saveBooleanDiagnosisEntriesToObject(entries: IDiagnosisEntry[]) {
    const examDataDiagnoses: Record<string, boolean> = {};

    // Check if the entries given contain any boolean diagnosis values
    Constants.RR_BOOLEAN_DIAGNOSIS_FIELDS.forEach((booleanField) => {
        // Null check in case no chief complaint diagnoses have been selected
        if (entries && entries.find((entry) => entry.value === booleanField.value)) {
            examDataDiagnoses[booleanField.value] = true;
        } else {
            examDataDiagnoses[booleanField.value] = false;
        }
    });

    return examDataDiagnoses;
}

// Adds a new diagnosis to the array of selected diagnosis values using the value of a chief complaint selection
// Example: If user selects Hydroxychloroquine (categorized as Plaquenil), the persisting value should be Plaquenil
export function addDiagnosisValueFromKeyword(selectedDiagnosisValues: IDiagnosisEntry[], diagnosisList: IDiagnosisListItem[]){

    // Get the new diagnosis which is the last object of selectedDiagnosisValues
    let diagnosisValues = [...selectedDiagnosisValues];
    let newDiagnosis = diagnosisValues.pop();

    // If the new diagnosis is duplicated to any previously selected values then do not process the new diagnosis
    // and simply return the diagnosisValues
    if(diagnosisValues.some(diagnosis => diagnosis.value === newDiagnosis?.value)){
        return diagnosisValues;
    }

    // Get the diagnosis for the new entry from the disagnosis list and update the new entry label accordingly
    // The diagnosis label is always the first name in the keywords array
    const newEntryDiagnosis = diagnosisList.find(entry => entry.id === newDiagnosis?.value);

    // The rr_ type diagnosis are not part of the diagnosis list, and so they will not be found in diagnosisList.
    if(newEntryDiagnosis !== undefined && newEntryDiagnosis !== null ){
        newDiagnosis!.label = newEntryDiagnosis.keywords[0];
    }

    // Add new entry to previous entries array and return
    newDiagnosis && diagnosisValues.push(newDiagnosis);

    return diagnosisValues;
}

type GlaucomaEntry = {
    glc: boolean,
    glcS: boolean,
    narrowAngles: boolean,
}
export function patientIsGlaucoma(patientDiagnosisEntries: IDiagnosisEntry[], {glc, glcS, narrowAngles}: GlaucomaEntry ){

    // Array of all diagnoses that indicate a patient is categorically a Glaucoma patient
    const glaucomaDiagnoses = ['rr_glc', 'rr_glc_suspect', 'normal_tension_glaucoma', 'disc_drusen',
        'ocular_hypertension', 'pseudoexfoliation', 'pigment_dispersion_syndrome', 'narrow_angles',
        'prm_open_angle_glc'];

    return (patientDiagnosisEntries && patientDiagnosisEntries.some(
        entry => glaucomaDiagnoses.some(
            glc => entry.value === glc))) ||
            glc || glcS || narrowAngles;
}

// Return true if the patient is a Plaquenil patient based on the patient's diagnosis entries.
// Unit test [DIAGNOSIS-CONVERT 013-016]
export function patientIsPlaquenil(patientDiagnosisEntries: IDiagnosisEntry[]){

    // Array of all diagnoses that indicate a patient is categorically a Plaquenil patient
    const plaquenilDiagnoses = ['plaquenil', 'aralen'];

    return patientDiagnosisEntries && patientDiagnosisEntries.some(
        entry => plaquenilDiagnoses.some(
            plq => entry.value === plq));
}

// Return true if the patient has a Chief Complaint diagnosis that is a Retina Disease diagnosis
export const patientIsRetina = () => (dispatch:AppDispatch, getState: () => RootState) =>{

    const { examData, diagnosis: {entries: patientDiagnosisEntries }} = getState();
    // Return true if any of the following
    if(examData.rr_dm || examData.rr_amd || examData.rr_erm){
        return true;
    }

    const retinaDiagnoses = ['macular degeneration', 'drusen', 'pigment_epithelial_detachment', 'vmt',
        'nevus', 'peripheral_retinal_hemorrhages', 'central_serous_retinopathy', 'brvo', 'crvo',
        'hrvo', 'brao', 'crao', 'retinal_emboli'];

    return patientDiagnosisEntries && patientDiagnosisEntries.some(
        diagnosis => retinaDiagnoses.some(
            retina => diagnosis.value === retina));
}

export function isOpticNervePatient(patientDiagnosisEntries: IDiagnosisEntry[], rr_optic_nerve: boolean) {
    // Array of all diagnoses that indicate a patient has optic nerve diseases
    const opticNerveDiagnoses = ['optic_nerve', 'disc_drusen'];

    return (patientDiagnosisEntries && patientDiagnosisEntries.some(
        entry => opticNerveDiagnoses.some(
            optic_nerve => entry.value === optic_nerve))) || rr_optic_nerve;
}
export function isCatPostOpSelected(patientDiagnosisEntries: IDiagnosisEntry[], rr_cat_post_op: boolean) {
    // Array of all diagnoses that indicate a patient has optic nerve diseases
    return (patientDiagnosisEntries && patientDiagnosisEntries.some(
        entry => entry.value === 'rr_cat_post_op')) || rr_cat_post_op;
}

// Return true if the exam has any one of the several listed criteria.
// The criteria is based on Kenman's opinion of what constituetes an exam that should have the DeepMD band in the PEI.
export const patientIsDeepMdGlaucoma = () => (dispatch: AppDispatch, getState: () => RootState) => {

    const { examData, diagnosis: {entries: patientDiagnosisEntries }} = getState();

    const glc = examData.rr_glc;
    const glcS = examData.rr_glc_suspect;
    const narrowAngles = examData.rr_narrow_angles;
    const iopOD = examData.od_iop;
    const iopOS = examData.os_iop;
    const cdOD = Number(examData.od_cd);
    const cdOS = Number(examData.os_cd);
    const pdsOD = examData.pds_od;
    const pdsOS = examData.pds_os;
    const pxfOD = examData.pxf_od;
    const pxfOS = examData.pxf_os;
    const vfOD = examData.exams_with_right_vf;
    const vfOS = examData.exams_with_left_vf;
    const rnflOD = examData.exams_with_right_oct_rnfl;
    const rnflOS = examData.exams_with_left_oct_rnfl;
    const glcFHxComorbidity = examData.fhx;

    // Return true if patient has Glaucoma diagnosis
    const isGlaucoma = patientIsGlaucoma(patientDiagnosisEntries, {glc, glcS, narrowAngles} );

    // Return true if IOP is above 21 in either eye
    const isIOP = Number(iopOD) > 21 || Number(iopOS) > 21;

    // Return true if C/D asymmetry > 0.2 or > 0.5 in any eye
    const isCD = cdOD > 0.5 || cdOS > 0.5 || (cdOD > 0.2 && cdOS > 0.2);

    // Return true if patient has any of the following comorbidities
    const isComorbidity = pdsOD || pdsOS || pxfOD || pxfOS || glcFHxComorbidity;

    // Return true if patient's latest VF has a yellow or red border
    const vfAlertValues = ['onl', 'na', 'ahs', 'b', 'b-grs']
    const isVF = (vfOD && vfAlertValues.includes(vfOD.length ? vfOD.slice(-1)[0].right_ght : ''))
        || (vfOS && vfAlertValues.includes(vfOS.length ? vfOS.slice(-1)[0].left_ght : ''));

    const rightOctav = rnflOD.length ? rnflOD.slice(-1)[0].right_octav : '';
    const rightOctavSup = rnflOD.length ? rnflOD.slice(-1)[0].right_octav_sup : '';
    const rightOctavInf = rnflOD.length ? rnflOD.slice(-1)[0].right_octav_inf : '';

    const leftOctav = rnflOS.length ? rnflOS.slice(-1)[0].left_octav : '';
    const leftOctavSup = rnflOS.length ? rnflOS.slice(-1)[0].left_octav_sup : '';
    const leftOctavInf = rnflOS.length ? rnflOS.slice(-1)[0].left_octav_inf : '';
    // Return true if either of the latest OCT RNFLs have at least one yellow or red border
    const isRNFL = (rnflOD &&
        (
               (rightOctav === 'red' || rightOctav === 'yellow')
            || (rightOctavSup === 'red' || rightOctavSup === 'yellow')
            || (rightOctavInf === 'red' || rightOctavInf === 'yellow')
        )) || (rnflOS &&
        (
               (leftOctav === 'red' || leftOctav === 'yellow')
            || (leftOctavSup === 'red' || leftOctavSup === 'yellow')
            || (leftOctavInf === 'red' || leftOctavInf === 'yellow')
        ));

    // Will return true if any of the criteria is met
    return isGlaucoma || isIOP || isCD || isComorbidity || isVF || isRNFL;
}
