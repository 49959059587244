import { useParams, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import * as Constants from '../../constants';

const ResourceLibraryButton = () => {

    const navigate = useNavigate();

    function handleButtonClick(event: { stopPropagation: () => void; }): void {
        event.stopPropagation();
        navigate('/resource-library')
    }
    
    return (
        <Button
            className='resource-library-button'
            data-testid='resource-library-button'
            onClick={handleButtonClick}
            type='primary'
        >
            Resource Library
        </Button>
    )
}

export default ResourceLibraryButton;
