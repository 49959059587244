import type { CollapseProps } from 'antd';
import { Button, Modal, Col, Row, Form, Spin, Select, Input, Tooltip, Checkbox, Collapse } from 'antd';
// import globalColors from '../../../static/css/shared/global-colors.scss'; // Styling Audit - Deprecated stylesheet
import globalColors from '../../../static/css/shared/_global-style.scss'; // Styling Audit - New stylesheet
// import '../../../static/css/components/patient-add-modal.scss'; // Styling Audit - Deprecated modal stylesheet
import '../../../static/css/components/_patient-add-modal.scss'; // Styling Audit - New modal stylesheet
import { localizedText } from '../../localizedText';
import { buildRequiredErrorMessage, formatGPName, getHealthNumberData, getMaskInputFormat, isDOBDayValid, getNonProvincialMaskInputFormat } from '../../helpers/patient-edit-convert';
import { IHealthNumberData, closeReferralLetterNewPatientModal, patientPHNExistsRequest, openNewExamDateModal, setAddEditGpFieldData, openEditGPModal, openAddGPModal } from '../../reducers/patient-edit-slice';
import { DOB_DAY_FIELD, DOB_MONTH_FIELD, DOB_YEAR_FIELD, ADD_PATIENT_CONFIRMATION_TEXT, CAF_SERVICE_NUMBER_FIELD, ERROR_INVALID_HN_FORMAT, FIRST_NAME_FIELD, IS_CAF_PERSONNEL_FIELD, LAST_NAME_FIELD, PHN_DEFAULT_LABEL_TEXT, PHN_FIELD, PLEASE_CONFIRM_FIELD, PROVINCE_FIELD, SECONDARY_PHN_FIELD, GP_LABEL_TEXT, GP_CURRENT_FIELD, GP_PLACEHOLDER_TEXT, GP_FAX_NUMBER_LABEL_TEXT, GP_FAX_NUMBER_FIELD, ERROR_INVALID_PHONE_FORMAT, GP_FAX_INFO_TEXT, GP_FAX_PLACEHOLDER_TEXT, PHONE_MASK_FORMAT, GP2_LABEL_TEXT, GP2_CURRENT_FIELD, GP2_PLACEHOLDER_TEXT, GP2_FAX_PLACEHOLDER_TEXT, GP2_FAX_NUMBER_LABEL_TEXT, GP2_FAX_NUMBER_FIELD, EMAIL_LABEL_TEXT, EMAIL_FIELD, ERROR_INVALID_EMAIL_FORMAT, EMAIL_PLACEHOLDER_TEXT, PHONE_FIELD, ONTARIO_PROVINCE_ID } from '../../constants';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { useState, useEffect, useMemo } from 'react';
import { RuleObject } from 'antd/lib/form';
import InputMask from 'react-input-mask';
import { IReferralLetterPatientDetail, useAddReferralLetterPatientMutation } from '../../services/patient-api';
import dayjs from 'dayjs';
import { IInsuranceTypeOption, IProvinceOption } from '../../reducers/options-slice';
import { EditOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useAddGpMutation, useGetGpsByProvinceQuery, useUpdateGpMutation } from '../../services/doctor-api';
import PatientAddGPModal from '../retina/patient-add-gp-modal';
import PatientEditGPModal from '../retina/patient-edit-gp-modal';

const ReferralLetterPatientAddModal = () => {
    const { NEW_PATIENT_TEXT, DOB_ERROR_MESSAGE, DOB_LABEL_TEXT,
        ERROR_PHN_ALREADY_EXISTS,
        FIRST_NAME_LABEL_TEXT, FNAME_PLACEHOLDER_TEXT,
        LAST_NAME_LABEL_TEXT,
        LNAME_PLACEHOLDER_TEXT, MONTHS, OPERATING_TEXT, 
        PLEASE_CONFIRM_LABEL_TEXT, REQUIRED_FIELDS_LABEL_TEXT, YEAR_TEXT, MONTH_TEXT, 
        PROVINCE_LABEL_TEXT, 
        PROVINCE_PLACEHOLDER_TEXT, DAY_TEXT, MD2_FAX_INFO_TEXT, NEW_GP2_TEXT, NEW_GP_TEXT,
        PHONE_LABEL_TEXT, PHONE_PLACEHOLDER_TEXT,
     } = localizedText;

    const isVisible = useCare1AppSelector(store => store.patientEdit.referralLetterNewPatientModalOpen);
    const provinces = useCare1AppSelector(store => store.options.provinces);
    const doctorID = useCare1AppSelector(store => store.user.doctorID);
    const odProvince = useCare1AppSelector(store => store.user.odProvince);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const gpAddEditField = useCare1AppSelector(store => store.patientEdit.addEditGpField);
    const insuranceTypeOptions: IInsuranceTypeOption[] = useCare1AppSelector(store => store.options.insuranceTypeOptions);

    const province = userIsOD
        ? provinces.find((e: IProvinceOption) => e.code === odProvince)
        : null;
    const initialProvinceID = province && province.id ? province.id : null;
    const [healthNumberData, setHealthNumberData] = useState<IHealthNumberData>({
        phn_name: '',
        secondary_phn_name: '',
        health_id_format: '',
        health_billing_id_format: '',
        secondary_phn_required: false,
    });

    const initialInsuranceType = 'provincial';

    const [ form ] = Form.useForm();
    const gp = Form.useWatch(GP_CURRENT_FIELD, form);
    const gp2 = Form.useWatch(GP2_CURRENT_FIELD, form);
    const selectedProvince = Form.useWatch(PROVINCE_FIELD, form);
    const insuranceType = Form.useWatch('insurance_type', form);
    const isOntarioPatient = Form.useWatch('province', form) === ONTARIO_PROVINCE_ID;
    const isArmedForcesPersonnel = insuranceType === 'caf_personnel';

    const [addReferralLetterPatient, {isLoading}] = useAddReferralLetterPatientMutation();
    const {data : gpList =[]} = useGetGpsByProvinceQuery(odProvince, {skip: !odProvince });

    // Any useMutation hooks with the same fixedCacheKey string will share results between each
    // other when any of the trigger functions are called
    const [, {data: gpAddData, reset: resetAddGpHook, isLoading: isAddGpLoading}] = useAddGpMutation({
        fixedCacheKey: 'shared-add-gp',
    });
    const [, {data: gpEditData, reset: resetUpdateGpHook, isLoading: isEditGpLoading}] = useUpdateGpMutation({
        fixedCacheKey: 'shared-edit-gp',
    });

    const gpOptions = useMemo(() => gpList.map(gp => ({
        id: gp.id,
        value: gp.id,
        label: formatGPName(gp),
    })),[gpList]);


    if (gpAddData && gpAddData.success) {
        // When a new GP is added, automatically set the GP, GP Fax field to the newly added GP data
        const gpField = gpAddEditField === GP_CURRENT_FIELD ? GP_CURRENT_FIELD : GP2_CURRENT_FIELD;
        const gpFaxField = gpAddEditField === GP_CURRENT_FIELD ? GP_FAX_NUMBER_FIELD : GP2_FAX_NUMBER_FIELD;
        form.setFieldValue(gpField, gpAddData.id);
        form.setFieldValue(gpFaxField, gpAddData.fax_number)
    }

    if (gpEditData && gpEditData.success) {
        // When a new GP is updated, automatically set the GP, GP Fax field to the newly updated GP data
        const gpField = gpAddEditField === GP_CURRENT_FIELD ? GP_CURRENT_FIELD : GP2_CURRENT_FIELD;
        const gpFaxField = gpAddEditField === GP_CURRENT_FIELD ? GP_FAX_NUMBER_FIELD : GP2_FAX_NUMBER_FIELD;
        form.setFieldValue(gpField, gpEditData.id);
        form.setFieldValue(gpFaxField, gpEditData.fax_number)
    }

    const PHNSecondaryMask = getMaskInputFormat(healthNumberData.health_billing_id_format);
    const PHNSecondaryRequired = healthNumberData.secondary_phn_required;

    let PHNLabel = healthNumberData.phn_name ? healthNumberData.phn_name : PHN_DEFAULT_LABEL_TEXT;
    const hasNoPHN = insuranceType === 'no_insurance';

    const insurancePlaceholder = hasNoPHN || !insuranceType ? 'No Insurance' : insuranceType === 'provincial' ? `Enter ${PHNLabel} Number` :
        `Enter ${insuranceTypeOptions.find(option => option.value === insuranceType)?.label} Number`;
    const insuranceMask = insuranceType === 'provincial' ? getMaskInputFormat(healthNumberData.health_id_format) : 
        getNonProvincialMaskInputFormat(insuranceType);
    const isProvinceSelected = !!selectedProvince;

    const insuranceTypeDisplayOptions = insuranceTypeOptions.reduce((reducedOptions, insuranceTypeOption) => {
        // Don't display old OHIP if this is not in Ontario.
        if (isOntarioPatient || insuranceTypeOption.value !== 'old_ohip') {
            let displayLabel = insuranceTypeOption.label;
            if (insuranceTypeOption.value === 'provincial') {
                displayLabel = PHNLabel;
            }

            reducedOptions.push({
                value: insuranceTypeOption.value,
                label: displayLabel,
            });
        }

        return reducedOptions;
    }, [] as IInsuranceTypeOption[]);

    useEffect(() => {
        if (initialProvinceID) {
            const healthData = getHealthNumberData(Number(initialProvinceID), provinces);
            setHealthNumberData(healthData);
        }
        // eslint-disable-next-line
    }, [initialProvinceID, isVisible]);
    const validateUniquePHN = async (_: RuleObject, value: string) => {
        // We don't care about empty PHNs. It's considered valid.
        if (!value) {
            return Promise.resolve();
        }
        // Check if the PHN is already used on the backend.
        const res = await dispatch(patientPHNExistsRequest({
            patientId: '',
            phn: value,
            doctor: doctorID
        })).unwrap();
        return res && res.phnExist ? Promise.reject(new Error(ERROR_PHN_ALREADY_EXISTS)) : Promise.resolve();
    }
    

    const fullColWidth = {span: 24};

    const provincesToDisplay = provinces.filter(entry => entry.country === 'CA');

    const dispatch = useCare1AppDispatch();

    const handleCancel = () => {
        resetState();
        dispatch(closeReferralLetterNewPatientModal());
    }

    const resetState = () => {
        // reset the hook back to it's original state and remove the current result from the cache
        resetAddGpHook();
        resetUpdateGpHook();
        setHealthNumberData({
            phn_name: '',
            secondary_phn_name: '',
            health_id_format: '',
            health_billing_id_format: '',
            secondary_phn_required: false,
        });
        form.resetFields();
    }

    const onValuesChange = (changedValues: Record<string, string | boolean | number>) => {
        const fieldName = Object.keys(changedValues)[0];
        switch (fieldName) {
            case 'province':
                const healthNumberData = getHealthNumberData(Number(changedValues[fieldName]), provinces);
                setHealthNumberData(healthNumberData)
                break;
            case 'insurance_type':
                // When "no insurance" is selected, clear the PHN field.
                if (changedValues[fieldName] === 'no_insurance') {
                    form.setFieldValue(PHN_FIELD, '');
                }
                break;
            case IS_CAF_PERSONNEL_FIELD:
                form.setFieldValue(CAF_SERVICE_NUMBER_FIELD, '');
                break;
            case GP_CURRENT_FIELD:
                const selectedGp = gpList.find(gp => gp.id === changedValues[fieldName]);
                const gpFax = selectedGp ? selectedGp.fax_number : '';
                form.setFieldValue(GP_FAX_NUMBER_FIELD, gpFax);
                break;
            case GP2_CURRENT_FIELD:
                const selectedGp2 = gpList.find(gp => gp.id === changedValues[fieldName]);
                const gp2Fax = selectedGp2 ? selectedGp2.fax_number : '';
                form.setFieldValue(GP2_FAX_NUMBER_FIELD, gp2Fax)
                break;
            default:
                break;
        }
    }

    const onFinish = async (data: IReferralLetterPatientDetail ) => {
        try {
            const patientData = {
                ...data,
                dob: dayjs(`${data.dobDay} ${data.dobMonth} ${data.dobYear}`, 'D MMMM YYYY').format('YYYY-MM-DD'),
            }
            const response = await addReferralLetterPatient(patientData).unwrap();
            if (response.success) {
                dispatch(closeReferralLetterNewPatientModal());
                Modal.info({
                    className: 'info-modal',
                    title: ADD_PATIENT_CONFIRMATION_TEXT,
                    onOk: () => {
                        resetState();
                        const patientId = response.id;
                        dispatch(openNewExamDateModal(patientId.toString()));
                    },
                });
            } else {
                throw new Error(response?.error);
            }
            
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    const EmailField = () => (
        <Form.Item
            label={EMAIL_LABEL_TEXT}
            labelCol={fullColWidth}
            hasFeedback
            colon={false}
            name={EMAIL_FIELD}
            validateTrigger={'onBlur'}
            rules={[{
                type:'email',
                message: ERROR_INVALID_EMAIL_FORMAT,
            }]}
            initialValue={''}
        >
            <Input
                data-testid='email-input'
                className={"email-address-input"}
                placeholder={EMAIL_PLACEHOLDER_TEXT}
            />
        </Form.Item>
    )

    const additionalInformation: CollapseProps['items'] = [
        {
            key: '1',
            label: <span>Additional Information</span>,
            children: <>
                    <Row gutter={16}>
                        <Col span={12}>
                            {/* PE 005 */}
                            <Form.Item
                                label={PHONE_LABEL_TEXT}
                                labelCol={fullColWidth}
                                hasFeedback
                                colon={false}
                                name={PHONE_FIELD}
                                validateTrigger={'onBlur'}
                                rules={[{
                                    pattern: /^[^_]+$/,
                                    message: ERROR_INVALID_PHONE_FORMAT,
                                }]}
                                initialValue={''}
                            >
                                <InputMask
                                    data-testid='phone-input'
                                    className={'phone-number-input ant-input'}
                                    mask={PHONE_MASK_FORMAT}
                                    placeholder={PHONE_PLACEHOLDER_TEXT}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <EmailField />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            {/* PE 019, PE 021 */}
                            <Form.Item
                                label={
                                    <div className='gp-label-row'>
                                    <span>{GP2_LABEL_TEXT}</span>
                                    <div className='gp-buttons'>
                                        { gp2 && <div
                                            data-testid='edit-gpfax-onclick'
                                            className='patient-edit-gp-button'
                                            onClick={() => {
                                                dispatch(setAddEditGpFieldData(GP2_CURRENT_FIELD));
                                                dispatch(openEditGPModal());}}
                                            >
                                            <EditOutlined />
                                            <span>Edit MD#2</span>
                                        </div>}
                                        <div
                                            data-testid='new-gp2-onclick'
                                            className='patient-new-gp-button'
                                            onClick={() => {
                                                dispatch(setAddEditGpFieldData(GP2_CURRENT_FIELD));
                                                dispatch(openAddGPModal());}}
                                        >
                                            <PlusOutlined />
                                            <span>{NEW_GP2_TEXT}</span>
                                        </div>
                                    </div>
                                </div>
                                }
                                labelCol={fullColWidth}
                                hasFeedback
                                help=""
                                colon={false}
                                name={GP2_CURRENT_FIELD}
                                initialValue={null}
                            >
                                <Select
                                    data-testid='gp2-input'
                                    placeholder={GP2_PLACEHOLDER_TEXT}
                                    className="patient-gp2-input"
                                    showSearch
                                    allowClear={true}
                                    options={gpOptions}
                                    optionFilterProp='label'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {/* PE 020, PE 022 */}
                            <Form.Item
                                className='gp-fax-number-form'
                                label={GP2_FAX_NUMBER_LABEL_TEXT}
                                labelCol={fullColWidth}
                                hasFeedback
                                colon={false}
                                validateTrigger={'onBlur'}
                                name={GP2_FAX_NUMBER_FIELD}
                                rules={[{
                                    pattern: /^[^_]+$/,
                                    message: ERROR_INVALID_PHONE_FORMAT,
                                }]}
                                initialValue={''}
                                tooltip={{
                                    title: MD2_FAX_INFO_TEXT,
                                    icon: <InfoCircleOutlined />,
                                    }}
                            >
                                <InputMask
                                    data-testid='gp2-fax-input'
                                    placeholder={GP2_FAX_PLACEHOLDER_TEXT}
                                    className="patient-gp2-fax-input ant-input"
                                    mask={PHONE_MASK_FORMAT}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
            </>,
        },
    ];

    return (
        <Modal
            className={`add-patient-modal referral-letter-pei ${(PHNSecondaryRequired && isProvinceSelected && !hasNoPHN) ? 'second-phn-required' : ''}`}
            open={isVisible}
            width="840"
            closable={false}
            footer={null}
            maskStyle={{ backgroundColor: globalColors['maskOverlayBlur'] }}
        >
            <Row className="header-section" align="middle">
                <Col span={10}>
                    <div className="modal-title">
                        {NEW_PATIENT_TEXT}
                    </div>
                </Col>
            </Row>
            <Form
                className="patient-edit-form"
                form={form}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
            > {/* Test PE 001 */}
                <Spin
                    className="loading-spinner"
                    spinning={isLoading || isAddGpLoading || isEditGpLoading}
                    size="large"
                    tip={OPERATING_TEXT}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            {/* PE 002 */}
                            <Form.Item
                                label={FIRST_NAME_LABEL_TEXT}
                                labelCol={fullColWidth}
                                hasFeedback
                                colon={false}
                                name={FIRST_NAME_FIELD}
                                validateTrigger={'onBlur'}
                                rules={[{
                                    required: true,
                                    whitespace: true,
                                    message: buildRequiredErrorMessage(FIRST_NAME_LABEL_TEXT),
                                }]}
                                initialValue={''}
                            >
                                <Input
                                    className={`first-name-input`}
                                    placeholder={FNAME_PLACEHOLDER_TEXT}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            {/* PE 003 */}
                            <Form.Item
                                label={LAST_NAME_LABEL_TEXT}
                                labelCol={fullColWidth}
                                hasFeedback
                                colon={false}
                                name={LAST_NAME_FIELD}
                                validateTrigger={'onBlur'}
                                rules={[{
                                    required: true,
                                    whitespace: true,
                                    message: buildRequiredErrorMessage(LAST_NAME_LABEL_TEXT),
                                }]}
                                initialValue={''}
                            >
                                <Input
                                    className={`last-name-input`}
                                    placeholder={LNAME_PLACEHOLDER_TEXT}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            {/* PE 004 */}
                            <Row gutter={5} className="dob">
                                <Col span={11}>
                                    <Form.Item
                                        hasFeedback
                                        help=""
                                        label={DOB_LABEL_TEXT}
                                        labelCol={fullColWidth}
                                        colon={false}
                                        name={DOB_MONTH_FIELD}
                                        rules={[{
                                            required: true,
                                        }]}
                                        initialValue=''
                                    >
                                        <Select data-testid='month-select' className={'dob-month'} placeholder={MONTH_TEXT}>
                                            {MONTHS.map((month, index) => {
                                                // Get the index of our ordered date list and add one
                                                // so the value is valid to use with JS Date() class.
                                                const monthInt = index + 1;
                                                return (
                                                    <Select.Option
                                                        key={monthInt}
                                                        value={month}
                                                    >
                                                        {month}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        className="hide-title dob-day-form-item"
                                        hasFeedback
                                        label="&nbsp;"
                                        labelCol={fullColWidth}
                                        help=""
                                        name={DOB_DAY_FIELD}
                                        validateTrigger={'onBlur'}
                                        initialValue=''
                                        getValueFromEvent={ (e) => {
                                            const dobDay = Number(e.currentTarget.value);
                                            // If value is not a valid number return empty
                                            if(!dobDay){
                                                return '';
                                            }
                                            // In all cases the day can never be greater than 31
                                            else if(dobDay > 31){
                                                return form.getFieldValue(DOB_DAY_FIELD);
                                            }
                                            // Return value if it is valid number
                                            else {
                                                return e.currentTarget.value;
                                            }
                                        }}
                                        rules={[{
                                            required: true,
                                            pattern: /^[1-9][0-9]*$/,
                                            message: DOB_ERROR_MESSAGE,
                                            },
                                            ({getFieldValue})=>({
                                                validator(_,value){
                                                    const dobMonth = getFieldValue(DOB_MONTH_FIELD);
                                                    const dobYear = getFieldValue(DOB_YEAR_FIELD);

                                                    // If all fields are complete check that the date is valid
                                                    if(value && dobMonth && dobYear){
                                                        const isValid = isDOBDayValid(value, dobMonth, dobYear);
                                                        if (!isValid) {
                                                            return Promise.reject(new Error(DOB_ERROR_MESSAGE));
                                                        }
                                                    }
                                                    return Promise.resolve();
                                                }
                                            }),
                                        ]}
                                    >
                                        <Input data-testid='day-select' className={'dob-day'} placeholder={DAY_TEXT} />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        className="hide-title dob-year-form-item"
                                        label="&nbsp;"
                                        labelCol={fullColWidth}
                                        hasFeedback
                                        help=""
                                        name={DOB_YEAR_FIELD}
                                        validateTrigger={'onBlur'}
                                        initialValue=''
                                        getValueFromEvent={ (e) => {
                                            const dobYear = Number(e.currentTarget.value);

                                                // If value is not a valid number return empty
                                                if(!dobYear){
                                                    return '';
                                                }
                                                // Birth year can not be greater than this year or older than 1900
                                                else if(dobYear && e.currentTarget.value.length === 4 &&
                                                    ((Number(dobYear) < 1900) || (Number(dobYear) > new Date().getFullYear()))){
                                                    return form.getFieldValue(DOB_YEAR_FIELD);
                                                }
                                                else if (dobYear && e.currentTarget.value.length > 4) {
                                                    return form.getFieldValue(DOB_YEAR_FIELD);
                                                }
                                                // Return value if it is a valid number
                                                else {
                                                    return e.currentTarget.value;
                                                }
                                        }}
                                        rules={[{
                                            required: true,
                                            pattern: new RegExp('^[0-9]{4}$'),
                                        }]}
                                    >
                                        <Input data-testid='year-select' className={'dob-year'} placeholder={YEAR_TEXT} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    
                    <Row gutter={24}>
                        <Col span={8}>
                            {/* PE 008 */}
                            <Form.Item
                                label={PROVINCE_LABEL_TEXT}
                                labelCol={fullColWidth}
                                hasFeedback
                                colon={false}
                                name={PROVINCE_FIELD}
                                rules={[{
                                    required: true,
                                    message: buildRequiredErrorMessage(PROVINCE_LABEL_TEXT)
                                }]}
                                initialValue={initialProvinceID}
                            >
                                <Select
                                    placeholder={PROVINCE_PLACEHOLDER_TEXT}
                                    className="patient-province-input"
                                    data-testid='province-select'
                                >
                                    {provincesToDisplay.length > 0 && provincesToDisplay.map(province => {
                                            return (
                                                <Select.Option key={province.id} value={province.id}>
                                                    {province.name}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            {/* PE 008 */}
                            <Form.Item
                                label={'Type of Insurance'}
                                labelCol={fullColWidth}
                                hasFeedback
                                colon={false}
                                name={'insurance_type'}
                                rules={[{
                                    required: true,
                                    message: buildRequiredErrorMessage('Insurance Type')
                                }]}
                                initialValue={initialInsuranceType}
                            >
                                <Select
                                    placeholder={''}
                                    className="insurance-type-input"
                                    data-testid='insurance-type-select'
                                >
                                    {insuranceTypeDisplayOptions.length > 0 && insuranceTypeDisplayOptions.map(insuranceTypeOption => {
                                            return (
                                                <Select.Option key={insuranceTypeOption.value} value={insuranceTypeOption.value}>
                                                    {insuranceTypeOption.label}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            {/* PE 009 */}
                            <Form.Item
                                className={'patient-phin-label'}
                                label={'Insurance No.'}
                                labelCol={fullColWidth}
                                hasFeedback
                                colon={false}
                                name={PHN_FIELD}
                                validateTrigger={'onBlur'}
                                initialValue={''}
                                rules={[{
                                    pattern: !hasNoPHN ? /^[^_]+$/ : /^\s+$/,
                                    message: ERROR_INVALID_HN_FORMAT,
                                }, {
                                    required: (!hasNoPHN && !isArmedForcesPersonnel),
                                    message: buildRequiredErrorMessage(PHNLabel),
                                }, {
                                    validator: validateUniquePHN,
                                }]}
                            >
                                <InputMask
                                    data-testid='insurance-input'
                                    mask={insuranceMask.replace('?', '')}
                                    placeholder={insurancePlaceholder}
                                    className="patient-phin-input ant-input"
                                    disabled={!isProvinceSelected || hasNoPHN}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                    <Col span={8}>
                        {/* PE 013, PE 017 */}
                        <Form.Item
                            label={
                                <div className='gp-label-row'>
                                    <span>{GP_LABEL_TEXT}</span>
                                    <div className='gp-buttons'>
                                        {
                                            !gp && !gp2 &&
                                            <Form.Item
                                                name='has_no_gp'
                                                noStyle
                                                valuePropName='checked'
                                            >
                                                <Checkbox className='has-no-gp'>
                                                    No GP
                                                </Checkbox>
                                            </Form.Item>
                                        }
                                       
                                        { gp && <div
                                            data-testid='edit-gp-onclick'
                                            className='patient-edit-gp-button'
                                            onClick={() => {
                                                dispatch(setAddEditGpFieldData(GP_CURRENT_FIELD));
                                                dispatch(openEditGPModal());}}
                                            >
                                            <EditOutlined />
                                            <span>Edit GP</span>
                                        </div>}
                                        <div
                                            data-testid='new-gp-onclick'
                                            className='patient-new-gp-button'
                                            onClick={() => {
                                                dispatch(setAddEditGpFieldData(GP_CURRENT_FIELD));
                                                dispatch(openAddGPModal());}}
                                        >
                                            <PlusOutlined />
                                            <span>{NEW_GP_TEXT}</span>
                                        </div>
                                    </div>
                                </div>}
                            labelCol={fullColWidth}
                            hasFeedback
                            help=""
                            colon={false}
                            name={GP_CURRENT_FIELD}
                            initialValue={null}
                        >
                            <Select
                                data-testid='gp-input'
                                placeholder={GP_PLACEHOLDER_TEXT}
                                className="patient-gp-input"
                                showSearch
                                allowClear={true}
                                options={gpOptions}
                                optionFilterProp='label'
                            />
                               
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        {/* PE 014, PE 018 */}
                        <Form.Item
                            label={GP_FAX_NUMBER_LABEL_TEXT}
                            className='gp-fax-number-form'
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            validateTrigger={'onBlur'}
                            name={GP_FAX_NUMBER_FIELD}
                            rules={[{
                                pattern: /^[^_]+$/,
                                message: ERROR_INVALID_PHONE_FORMAT,
                            }]}
                            initialValue={''}
                            tooltip={{
                                title: GP_FAX_INFO_TEXT,
                                icon: <InfoCircleOutlined />,
                              }}
                        >
                            <InputMask
                                data-testid='gp-fax-input'
                                placeholder={GP_FAX_PLACEHOLDER_TEXT}
                                className="patient-gp-fax-input ant-input"
                                mask={PHONE_MASK_FORMAT}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={'Insurance No. #2'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={SECONDARY_PHN_FIELD}
                            validateTrigger={'onBlur'}
                            initialValue={''}
                            rules={[{
                                pattern: /^[^_]+$/,
                                message: ERROR_INVALID_HN_FORMAT,
                            }]}
                        >
                            <InputMask
                                className="patient-mhsc-input"
                                placeholder={PHNSecondaryRequired && isProvinceSelected && !hasNoPHN ? 'Enter MHSC Number' : ''}
                                mask={PHNSecondaryMask}
                                disabled={!(PHNSecondaryRequired && isProvinceSelected && !hasNoPHN)}
                            />
                        </Form.Item>
                    </Col>
                </Row>                
                <Row className='additional-information-section'>
                    <Collapse items={additionalInformation} />
                </Row>
                <Row className="please-confirm-section">
                    <div className="please-confirm-text-area">
                        <div className="please-ensure-text">
                            <span className='heading'>Patient consents to digital communication such as email to discuss care plans; shared care with other providers such as OMDs and GPs; and use of de-identified chart information for research and presentations.</span>
                        </div>
                    </div>
                </Row>
                <Row className='please-confirm-label-section'>
                    <Col span={12}>
                        {/* PE 016 */}
                        <Form.Item
                            className="please-confirm-label"
                            hasFeedback
                            colon
                            name={PLEASE_CONFIRM_FIELD}
                            rules={[{
                                type: 'boolean',
                                required: true,
                                message: buildRequiredErrorMessage(PLEASE_CONFIRM_LABEL_TEXT),
                                transform: (checked) => (checked || undefined),
                            }]}
                            valuePropName={'checked'}
                            initialValue={false}
                        >
                            <Checkbox
                                data-testid='confirm-checkbox'
                                className="please-confirm-checkbox">
                                {PLEASE_CONFIRM_LABEL_TEXT}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <span className='required-fields-label'>{REQUIRED_FIELDS_LABEL_TEXT}</span><span className='required-fields-star'>*&nbsp;</span>
                    </Col>
                </Row>
                <Row justify='end'>
                    <Button
                        className='cancel-button'
                        onClick={handleCancel}
                    >Cancel</Button>
                    <Button
                        type='primary'
                        htmlType='submit'
                    >Continue</Button>
                </Row>
            </Spin>
        </Form>
        <PatientAddGPModal />
        <PatientEditGPModal />
        </Modal>
    );
}

export default ReferralLetterPatientAddModal;
