import { FileSyncOutlined, CalendarOutlined, FolderOpenOutlined,InfoCircleOutlined } from '@ant-design/icons';
import { Row, Button, Input, Divider, Select, Tooltip } from 'antd';
import { PATIENT_EXAM_URL } from '../../constants';
import { getOMDCPatientListRequest, getOMDCSearchPatientListRequest, IOMDCPatientListItem, setPatientListFieldData
    } from '../../reducers/patient-list-slice';
import '../../../static/css/shared/patient-list-control-bar.scss';
import '../../../static/css/components/patient-list-control-bar-omdc.scss';
import { setPatientExamRoomsData } from '../../reducers/patient-exam-rooms-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { localizedText } from '../../localizedText';
import { goToExamInNewTabWithTarget } from '../../reducers/patient-exam-slice';

const PatientListControlBarOMDC = () => {

    const { EXAM_PERIOD_OPTIONS, PATIENT_LIST_SEARCH_PLACEHOLDER, PATIENT_LIST_TEXT, 
        OMDC_STATUS_TYPES, OPEN_SELECTED_VISITS_TOOLTIP, OPEN_ALL_PATIENTS } = localizedText;

    const tableList = useCare1AppSelector(store => store.patientList.omdcTableList);
    const examPeriod = useCare1AppSelector(store => store.patientList.examPeriod);
    const omdcStatus = useCare1AppSelector(store => store.patientList.omdcStatus);

    const dispatch = useCare1AppDispatch();

    const openAllMyPts = () => {
        // Go through all patients in the list, build the new exam rooms store, and open an exam tab to show all of it.
        let examRooms: {examId: number, patientName: string}[] = [];

        tableList.forEach((patientEntry: IOMDCPatientListItem) => {
            // We want to create a new room for each exam
            if (patientEntry.latest_exam_id) {
                examRooms.push({
                    examId: patientEntry.latest_exam_id,
                    patientName: `${patientEntry.last_name}, ${patientEntry.first_name}`,
                });
            }
        });

        dispatch(setPatientExamRoomsData({ rooms: examRooms }));

        for (let i=0; i<examRooms.length; i++) {
            const examUrl = `${PATIENT_EXAM_URL}/${examRooms[i].examId}`;
            const target = `target-${examRooms[i].examId}`;
            goToExamInNewTabWithTarget(examUrl, target);
        }
    }

    const handleExamPeriodSelection = (value: string) => {
        dispatch(setPatientListFieldData({ key: 'examPeriod', value }));
        dispatch(getOMDCPatientListRequest());
    }

    const handleOMDCStatusSelection = (value: string) => {
        dispatch(setPatientListFieldData({ key: 'omdcStatus', value }));
        dispatch(getOMDCPatientListRequest());
    }

    const sendSearchPatientListRequest = (value: string) => {
        if(value){
            // Search all statuses and all time period
            dispatch(setPatientListFieldData({ key: 'omdcStatus', value: 'all_statuses' }));
            dispatch(setPatientListFieldData({ key: 'examPeriod', value: 'all_time' }));

            // Set search value and send request
            dispatch(setPatientListFieldData({ key: 'searchQuery', value }));
            dispatch(getOMDCSearchPatientListRequest(value));
        } else {
            // Reset the default values
            dispatch(setPatientListFieldData({ key:'omdcStatus', value: 'ready' }));
            dispatch(setPatientListFieldData({ key: 'examPeriod', value: 'all_time' }));
        }
    }

    return (
        <Row className={'patient-list-control-bar patient-list-control-bar-omdc'}>
            <Row align="middle" className="control-bar-elements">
                {/* P-LIST-CTRL-BAR-OMDC 001, 002, 003, 004, 005, 006, 007 */}
                <div className="patient-list-title">
                    {PATIENT_LIST_TEXT}
                </div>

                <Button
                    className="open-all-my-pts-button"
                    icon={<FolderOpenOutlined />}
                    onClick={openAllMyPts}
                    type='primary'
                >
                    {OPEN_ALL_PATIENTS}
                    <Tooltip className="popup-tooltip" placement='topRight' title={OPEN_SELECTED_VISITS_TOOLTIP}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </Button>

                <Divider type="vertical" className="patient-list-vertical-divider" />

                <Select
                    className="dropdown-filter patient-list-exam-period-filter"
                    popupClassName="patient-list-dropdown"
                    defaultValue={examPeriod}
                    onSelect={handleExamPeriodSelection}
                    data-testid="exam-period-select"
                    value={examPeriod}
                >
                    {EXAM_PERIOD_OPTIONS && EXAM_PERIOD_OPTIONS.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            <CalendarOutlined /> {`${option.label}`}
                        </Select.Option>
                    ))}
                </Select>

                <Divider type="vertical" className="patient-list-vertical-divider" />

                <Select
                    className="dropdown-filter patient-list-exam-status-filter"
                    popupClassName="patient-list-dropdown"
                    defaultValue={'ready'}
                    onSelect={handleOMDCStatusSelection}
                    data-testid="exam-status-select"
                    value={omdcStatus}
                >
                    {OMDC_STATUS_TYPES && OMDC_STATUS_TYPES.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            <FileSyncOutlined /> {option.value}
                        </Select.Option>
                    ))}
                </Select>

                <Divider type="vertical" className="patient-list-vertical-divider" />

                <Input.Search
                    className="patient-list-search-input"
                    allowClear
                    placeholder={PATIENT_LIST_SEARCH_PLACEHOLDER}
                    onSearch={sendSearchPatientListRequest}
                />
            </Row>
        </Row>
    );
}

export default PatientListControlBarOMDC;