import { Typography } from 'antd';
import {
    useCare1AppDispatch,
    useCare1AppSelector,
} from '../../../apps/care1-hooks';
import ComponentTextareaCard from '../../component-textarea-card';
import styles from '../../../../static/css/components/smart-upload-modal.module.scss';
import { setSmartUploadSliceDataValue } from '../../../reducers/smart-upload-slice';
import useOnBlurSave from '../../../hooks/useOnBlurSave';

const SmartUploadOdNotes = () => {
    const smartUploadId = useCare1AppSelector(
        (store) => store.smartUpload.id
    );
    const odNotes = useCare1AppSelector((store) => store.smartUpload.od_notes);
    const disabled = useCare1AppSelector((store) => store.smartUpload.disabled);

    const {handleBlur} = useOnBlurSave({key: 'od_notes', value: odNotes })

    const dispatch = useCare1AppDispatch();

    return (
        <ComponentTextareaCard
            title={
                <>
                    <Typography.Text className={styles.odNotesHeading}>
                        OD Notes(Optional)
                    </Typography.Text>
                </>
            }
            testId='notes-to-moa'
            value={odNotes}
            handleChange={(e) =>
                dispatch(
                    setSmartUploadSliceDataValue({
                        key: 'od_notes',
                        value: e.target.value,
                    })
                )
            }
            placeholder='Add Additional Notes'
            disabled={!smartUploadId || disabled}
            handleBlur={handleBlur}
        />
    );
};

export default SmartUploadOdNotes;
