import { Row, Card, Col } from 'antd';
import * as Constants from '../constants';
import { useCare1AppSelector } from '../apps/care1-hooks';

// Components
import PatientExamFundus from '../components/retina/patient-exam-fundus';
import PatientExamChiefComplaint from '../components/retina/patient-exam-chief-complaint';
import PatientExamOCTMacula from '../components/retina/patient-exam-oct-macula';
import PatientExamConversation from '../components/retina/patient-exam-conversation';
import PatientExamExtraImages from '../components/retina/patient-exam-extra-images';
import PatientExamHighPriority from '../components/retina/patient-exam-high-priority';
import PatientExamImageHistory from '../components/retina/patient-exam-image-history';
import PatientExamBCVA from '../components/retina/patient-exam-bcva';
import PatientExamGPReferred from '../components/retina/patient-exam-gp-referred';

// Helper
import PatientExamHistoryAndExam from '../components/retina/patient-exam-history-and-exam';

const StandardReferralLetterPageContents = ({disabled}: {disabled: boolean}) => {

    const odUsesRluPei = useCare1AppSelector(store => store.examData.od_uses_rlu_pei);

    return (
        <>
            <Row gutter={8}>
                <Col span={8}>
                    <PatientExamFundus
                        tabbed={false}
                        disabled={disabled}
                        className="patient-exam-fundus"
                        side={Constants.RIGHT_SIDE_TEXT}
                    />
                </Col>
                <Col span={8}>
                    { odUsesRluPei &&
                        // Past OD RE exams do not display the high priority component.
                        <Row>
                            <PatientExamHighPriority disabled={disabled} />
                        </Row>
                    }
                    <Row>
                        <PatientExamGPReferred
                            disabled={disabled}
                            className="patient-exam-gp-referred"
                        />
                    </Row>
                    <Row>
                        <PatientExamChiefComplaint disabled={disabled} displayInfo={false} />
                    </Row>
                    <Row>
                        <Card className={'patient-exam-re-bcva'} bordered={false}>
                            <Row><label className="heading">{'BCVA:'}</label></Row>
                            <Row gutter={8}>
                                <Col className="bcvaRight" span={12}>
                                    <Row>
                                        <Col span={9}><label className="heading">{'Right Eye:'}</label></Col>
                                        <Col span={15}>
                                            <PatientExamBCVA side='od' disabled={disabled}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="bcvaLeft" span={12}>
                                    <Row>
                                        <Col span={9}><label className="heading left-eye">{'Left Eye:'}</label></Col>
                                        <Col span={15}>
                                            <PatientExamBCVA side='os' disabled={disabled}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Row>
                    <Row>
                        <Card className="empty-card" bordered={false} />
                    </Row>
                </Col>
                <Col span={8}>
                    <PatientExamFundus
                        tabbed={false}
                        disabled={disabled}
                        className="patient-exam-fundus"
                        side={Constants.LEFT_SIDE_TEXT}
                    />
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={8}>
                    <PatientExamOCTMacula
                        dropText={Constants.OCT_MACULA_PHOTO_DROP_TEXT}
                        disabled={disabled}
                        side={Constants.RIGHT_SIDE_TEXT}
                        tabbed={false}
                    />
                </Col>
                <Col span={8}>
                    <PatientExamHistoryAndExam disabled={disabled}/>
                </Col>
                <Col span={8}>
                    <PatientExamOCTMacula
                        dropText={Constants.OCT_MACULA_PHOTO_DROP_TEXT}
                        disabled={disabled}
                        side={Constants.LEFT_SIDE_TEXT}
                        tabbed={false}
                    />
                </Col>
            </Row>
            <PatientExamExtraImages
                disabled={disabled}
                className="patient-exam-extra-images"
            />
            <Row gutter={8}>
                <Col span={6}>
                    <PatientExamImageHistory
                        className="patient-exam-image-history"
                        side={Constants.RIGHT_SIDE_TEXT}
                        imageCount={4}
                        switched={false}
                    />
                </Col>
                <Col span={12}>
                    <PatientExamConversation />
                </Col>
                <Col span={6}>
                    <PatientExamImageHistory
                        className="patient-exam-image-history"
                        switched
                        side={Constants.LEFT_SIDE_TEXT}
                        imageCount={4}
                    />
                </Col>
            </Row>
            <Row>
                <br />
                <br />
            </Row>
        </>
    );
};

export default StandardReferralLetterPageContents;
