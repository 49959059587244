import React from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { Button, Modal, Row, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../../static/css/shared/patient-list-table.scss';
import '../../../../static/css/components/patient-insurance-table.scss';

// Helper imports
import { compareStrings } from '../../../helpers/sorting';
import { getUsInsuranceRequest, createInsuranceRequest, setInsurancePolicyActiveRequest, getUsInsuranceListRequest
    } from '../../../reducers/us-insurance-slice';
import { InfoCircleOutlined, PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';

import { ADD_US_INSURANCE_POLICY_HELP_TEXT } from '../../../constants';
import { dropzoneSlice } from '../../../reducers/dropzone-slice';

interface IPatientInsuranceTable {
    company: string,
    payor_id: string,
    status: string,
    key: number,
}

const PatientInsuranceTable = () => {
    const insuranceList = useCare1AppSelector(store => store.usInsurance.insuranceList);
    const currentInsuranceId = useCare1AppSelector(store => store.usInsurance.id);
    const isVisible = useCare1AppSelector(store => store.usInsurance.usInsuranceModalOpen);
    const patientId = useCare1AppSelector(store => store.patientDetails.details.id);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const patientID = useCare1AppSelector(store => store.patientDetails.details.id);

    // Only IC OD and Admin users can set the insurance policy to active or inactive.
    const canSetInsuranceInactive = (userIsOD && !userIsRE) || userIsADMIN;

    const dispatch = useCare1AppDispatch();
    const insuranceListEmpty = (insuranceList.length === 0);
    const cardFrontImage = dropzoneSlice['cardFrontImage']?.actions;
    const cardBackImage = dropzoneSlice['cardBackImage']?.actions;

    const columns : ColumnsType<IPatientInsuranceTable> = [
    {
        className: 'company-column',
        title: 'Insurance Company',
        dataIndex: 'company',
        key: 'company',
        width: '40%',
        sorter: (a, b) => compareStrings(a.company, b.company),
    }, {
        className: 'payor-id-column',
        title: 'Payor ID',
        dataIndex: 'payor_id',
        key: 'payor_id',
        width: '20%',
        sorter: (a, b) => compareStrings(a.payor_id, b.payor_id),
    }, {
        className: 'insurance-status-column',
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '20%',
        sorter: (a, b) => compareStrings(a.status, b.status),
        render: (_, record) => (
            <div>
                <span>{record.status}</span>
                {canSetInsuranceInactive && record.status === 'Active' &&
                    <CloseOutlined className='deactivate-policy'
                        onClick={() => {
                            handleDeactivatePolicy(record.key);
                        }} />
                }
                {canSetInsuranceInactive && record.status === 'Inactive' &&
                    <CheckOutlined className='activate-policy'
                        onClick={() => {
                            handleActivatePolicy(record.key);
                        }} />
                }
            </div>
        )
    }, {
        className: 'view-details-column',
        title: 'View Details',
        key: 'view_details',
        width: '20%',
        render: (_,record) => (
            <Button
                onClick={()=> {
                    if (currentInsuranceId !== record.key){
                        dispatch(cardFrontImage.clearDropzone());
                        dispatch(cardBackImage.clearDropzone());
                        dispatch(getUsInsuranceRequest(record.key));
                    }
                }}
                className='view-button'
            >
                View
            </Button>)
    }];

    const handleAddPolicy = () => {
        Modal.confirm({
            className: 'exam-exit-confirmation-modal',
            icon: <InfoCircleOutlined />,
            title: 'Are you sure you want to add a new insurance policy?',
            onOk: () => {
                dispatch(createInsuranceRequest(patientId!))
                .then(() => {
                    dispatch(cardFrontImage.clearDropzone());
                    dispatch(cardBackImage.clearDropzone());
                    dispatch(getUsInsuranceListRequest(patientID!));
                })
            },
        })
    }

    const handleActivatePolicy = (insuranceId: number) => {
        Modal.confirm({
            className: 'exam-exit-confirmation-modal',
            icon: <InfoCircleOutlined />,
            title: 'Are you sure you want to reactivate this insurance policy?',
            onOk: () => {
                dispatch(setInsurancePolicyActiveRequest(
                    { patientId: patientId!, insuranceId: insuranceId!, makeActive: true }
                ))
                .then(() => {
                    dispatch(getUsInsuranceListRequest(patientID!));
                });
            },
        });
    }

    const handleDeactivatePolicy = (insuranceId: number) => {
        Modal.confirm({
            className: 'exam-exit-confirmation-modal',
            icon: <InfoCircleOutlined />,
            title: 'Are you sure you want to deactivate this insurance policy?',
            onOk: () => {
                dispatch(setInsurancePolicyActiveRequest(
                    { patientId: patientId!, insuranceId: insuranceId, makeActive: false }
                ))
                .then(() => {
                    dispatch(getUsInsuranceListRequest(patientID!));
                });
            },
        });
    }

    return (
        <div className="patient-list-table insurance-table">
            <Row justify='space-between' align='middle' className='heading-row'>
                <h2>Insurance Policies</h2>
                <Tooltip title={ADD_US_INSURANCE_POLICY_HELP_TEXT} defaultOpen={insuranceListEmpty && isVisible}
                    open={insuranceListEmpty && isVisible} placement={'left'} >
                    <Button
                        icon={<PlusOutlined />}
                        className='add-insurance-button'
                        onClick={handleAddPolicy}
                    >
                        Add New Policy
                    </Button>
                </Tooltip>
            </Row>
            <Row>
                <Table
                    columns={columns}
                    dataSource={insuranceList}
                    pagination={false}
                    scroll={{ y: 300 }}
                />
            </Row>
        </div>
    );
}

export default PatientInsuranceTable;