import  { useEffect, useRef } from 'react';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/patient-exam-iop-graph.scss';
import { IGraphProps } from '../../reducers/patient-exam-slice';

interface JqPlotObject {
    replot: () => void;
}

const PatientExamIOPGraph = ({ side, page }: IGraphProps) => {
    const graphIOP = useCare1AppSelector(store => store.examData.graph_iop);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const examIsRE = useCare1AppSelector(store => store.examData.exam_is_retina_only);
    const isGlaucoma = useCare1AppSelector(store => store.examData.is_glaucoma);
    const iopAim = useCare1AppSelector(store => side === 'od'
        ? store.examData.od_iop_aim
        : store.examData.os_iop_aim
    );

    const jqChart = useRef<JqPlotObject | null>(null);

    const classname = `default_glc_${side}_chart_${page}`;

    const graphWidth = (userIsOD && !userIsRE) || userIsOMDC || (userIsOMDR && !examIsRE) ? '305px' : '254px';

    // dangerous iop BGC is already defined on server side, its value is stored in gridBgColor rgba(255,103,102,0.5)
    const NORMAL_IOP_BGC = 'rgba(102,251,88, 0.5)';
    const WARNING_IOP_BGC = 'rgba(255,165,0, 0.8)';

    useEffect(() => {
        // Only consume IGraphIop if it is of type IGraphBcva
        if(graphIOP){
            const data = side === 'od' ? graphIOP.od_iop_points : graphIOP.os_iop_points;
            const $ = window.$;

            const buildOptions = () => {
                const optionSeries = side === 'od' ? graphIOP.od_series : graphIOP.os_series;
                const gridBgColor = side === 'od' ? graphIOP.od_grid_bg_color : graphIOP.os_grid_bg_color;

                const ageTicks = graphIOP.ageTicks;
                const minIOP = graphIOP.min_iop;
                const maxIOP = graphIOP.max_iop;

                return {
                    series: optionSeries,
                    grid: {
                        background: gridBgColor,
                        gridLineColor: 'rgba(179,217,255,0.2)',
                        borderWidth: 0,
                        shadow: false,
                        gridLineWidth: 1,
                    },
                    axesDefaults: {
                        tickOptions: {
                            showMark: true,
                            textColor: '#bfdfff',
                        },
                    },
                    axes: {
                        xaxis: {
                            ticks: ageTicks,
                            tickOptions: {
                                formatter(formatString: string, value: number) {
                                    if (value % 1 === 0) return value; // only display integer value
                                    return '';
                                },
                                formatString: '%d',
                            },
                        },
                        yaxis: {
                            min: ((minIOP >= 0) ? minIOP : null),
                            max: ((maxIOP >= 0) ? maxIOP : null),
                            numberTicks: ((maxIOP - minIOP) / 10) + ((maxIOP - minIOP) % 10) + 1,
                        },
                    },
                    fillBetween: {
                        series1: [0,1],
                        series2: [1,2],
                        color: [WARNING_IOP_BGC, NORMAL_IOP_BGC],
                        fill: (isGlaucoma && iopAim && iopAim.toLowerCase() !== 'xxx') ? true: false,
                    }
                };
            }

            if (data !== undefined) {
                if (data[0] && data[0].length > 0) {
                    jqChart.current = $.jqplot(classname, data, buildOptions());
                } else {
                    jqChart.current = $.jqplot(classname, [[null]], buildOptions());
                }

                if (jqChart.current) {
                    jqChart.current.replot();
                }
            }
        }
    }, [jqChart, graphIOP, classname, isGlaucoma, iopAim, side])

    /* Inline width/height is intentionally used to limit the graph size */
    // {IOP-GRAPH 001, 002}
    return <div data-testid={`iop-graph-${side}`} id={classname} style={{ width: graphWidth, height: '310px' }} />;
}

export default PatientExamIOPGraph;