import { Fragment, useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Row, Col, Tag, Checkbox, Divider, Form } from 'antd';
import { FieldData, StoreValue  } from 'rc-field-form/lib/interface';
import { useFormValidation } from '../../context/useFormValidationContext';
import { setOverrideActions, setPreReviewDataValue } from '../../reducers/pre-review-slice';
import { localizedText } from '../../localizedText';
import { setExamDataValues } from '../../reducers/patient-exam-slice';
import '../../../static/css/components/patient-exam-comorbidities.scss';


const { CheckableTag } = Tag;
const FormItem = Form.Item

const PatientExamComorbidities = ({ disabled, inGptTab }: {disabled: boolean, inGptTab?: boolean}) => {
    const { COMORBIDITIES_TITLE, FHX_GLC_TEXT, HEART_BLOCK_TEXT, STROKE_TEXT, UVEITIS_OD_TEXT,
        UVEITIS_OS_TEXT, PXF_OD_TEXT, PXF_OS_TEXT, PDS_OD_TEXT, PDS_OS_TEXT,
        ASTHMA_TEXT, NONE_TEXT } = localizedText;

    const comorbidities_none = useCare1AppSelector(store => store.examData.comorbidities_none);
    const asthma = useCare1AppSelector(store => store.examData.asthma);
    let fhx = useCare1AppSelector(store => store.examData.fhx);
    const heart_block = useCare1AppSelector(store => store.examData.heart_block);
    const uveitis_od = useCare1AppSelector(store => store.examData.uveitis_od);
    const uveitis_os = useCare1AppSelector(store => store.examData.uveitis_os);
    let pds_od = useCare1AppSelector(store => store.examData.pds_od);
    let pds_os = useCare1AppSelector(store => store.examData.pds_os);
    const hx_stroke = useCare1AppSelector(store => store.examData.hx_stroke);
    let pxf_od = useCare1AppSelector(store => store.examData.pxf_od);
    let pxf_os = useCare1AppSelector(store => store.examData.pxf_os);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const isAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const gptSavedValues = useCare1AppSelector(store => store.examData.gpt_saved_values);
    const gptNearMissFields = useCare1AppSelector(store => store.examData.gpt_near_miss_fields);
    const isReferralLetterUploadPEI = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);

    const onlyDisplaySelectedComorbidities = userIsOMDC || userIsOMDR;

    const examIsODMessaging = useCare1AppSelector(store => store.examData.is_od_messaging_submission);
    const past_fhx = useCare1AppSelector(store => store.examData.past_fhx);
    const past_pds_od = useCare1AppSelector(store => store.examData.past_pds_od);
    const past_pds_os = useCare1AppSelector(store => store.examData.past_pds_os);
    const past_pxf_od = useCare1AppSelector(store => store.examData.past_pxf_od);
    const past_pxf_os = useCare1AppSelector(store => store.examData.past_pxf_os);

    // OD messaing exams display past exam comorbidities if there are no current ones.
    // Only the following values are dispalyed for OD messaging exams, so only they are modified this way.
    const usePastValues = examIsODMessaging && !fhx && !pds_od && !pds_os && !pxf_od && !pxf_os;

    if (usePastValues) {
        fhx = past_fhx;
        pds_od = past_pds_od;
        pds_os = past_pds_os;
        pxf_od = past_pxf_od;
        pxf_os = past_pxf_os;
    }

    const tagClassName = usePastValues ? 'past-tags' : '';

    const [ form ] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    const { setComorbiditiesForm } = useFormValidation();

    // This check is required in order to display the checkbox validation error message.
    const checkCheckBox = (value: StoreValue) => {
        if (!value && isComorbiditiesNoneRequired()) {
            return Promise.reject(new Error('Selection required!'));
        } else {
            return Promise.resolve();
        }
    };

    const getGptSavedValue = (k: string) => {
        if (!isAdmin) {
            return false;
        }
        if (inGptTab) {
            return false;
        }

        if (gptSavedValues && gptSavedValues !== '') {
            try {
                const gptSavedValuesJson = JSON.parse(gptSavedValues);
                return gptSavedValuesJson[k];
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }

    const getGptNearMissFields = (k: string) => {
        if (!isAdmin) {
            return false;
        }
        if (inGptTab) {
            return false;
        }

        if (gptNearMissFields && gptNearMissFields !== '') {
            try {
                const gptNearMissFieldsJson = JSON.parse(gptNearMissFields);
                return gptNearMissFieldsJson[k];
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }

    const gptClassname = (k: string) => {
        if (getGptNearMissFields(k)) {
            return 'exam-gpt-state-red';
        }
        if (getGptSavedValue(k)) {
            return 'exam-gpt-state-yellow';
        }
        return '';
    }

    const isComorbiditiesNoneRequired = () => {
        return !comorbidities_none
            && !asthma
            && !fhx
            && !heart_block
            && !uveitis_od
            && !uveitis_os
            && !pds_od
            && !pds_os
            && !hx_stroke
            && !pxf_od
            && !pxf_os;
    }

    // If there are no selections and none is not selected then the checkbox field should be required.
    const required = isComorbiditiesNoneRequired();
    const className = required && !isReferralLetterUploadPEI ? 'patient-exam-comorbidities required' : 'patient-exam-comorbidities';

    // Side effects that we run after component render.
    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            comorbidities_none: comorbidities_none,
            asthma: asthma,
            fhx: fhx,
            heart_block: heart_block,
            uveitis_od: uveitis_od,
            uveitis_os: uveitis_os,
            pds_od: pds_od,
            pds_os: pds_os,
            hx_stroke: hx_stroke,
            pxf_od: pxf_od,
            pxf_os: pxf_os,
        }

        form.setFieldsValue(newValidationFieldValues);

        // The component should validate on every render
        form.validateFields();

        // pass the form instance to useFormValidation hooks
        setComorbiditiesForm(form);
        // clean up function when comorbidities component unmounts
        return () => {
            setComorbiditiesForm(null);
        }
    }, [asthma, comorbidities_none, fhx, form, heart_block, hx_stroke, pds_od, pds_os, pxf_od, pxf_os, setComorbiditiesForm, uveitis_od, uveitis_os]);

    const ADMIN_SELECTORS = ['fhx', 'pxf_od', 'pxf_os', 'pds_od', 'pds_os'];
    const ADMIN_SELECTOR_LABELS = {
        'fhx': 'FHX',
        'pxf_od': 'PXF OD',
        'pxf_os': 'PXF OS',
        'pds_od': 'PDS OD',
        'pds_os': 'PDS OS',
    }

    const onFieldsChange = (fields: FieldData[]) => {
        // Step 1: Update the exam store data
        fields.forEach((field) => {
           if (Array.isArray(field.name) && field.name[0] && !field.validating) {
               switch (field.name[0]) {
                    case 'asthma':
                    case 'heart_block':
                    case 'uveitis_od':
                    case 'uveitis_os':
                    case 'hx_stroke':
                        dispatch(setExamDataValues({ [field.name[0]]: field.value }));
                        if(comorbidities_none){
                            dispatch(setExamDataValues({ 'comorbidities_none': false }));
                        }
                        break;
                    case 'fhx':
                    case 'pxf_od':
                    case 'pxf_os':
                    case 'pds_od':
                    case 'pds_os':
                        dispatch(setExamDataValues({ [field.name[0]]: field.value }));
                        if(comorbidities_none){
                            dispatch(setExamDataValues({ 'comorbidities_none': false }));
                        }
                        if (isAdmin && ADMIN_SELECTORS.includes(field.name[0])){
                            dispatch(setOverrideActions([ADMIN_SELECTOR_LABELS[field.name[0] as 'fhx' | 'pxf_od' | 'pxf_os' | 'pds_od' | 'pds_os'], field.value]));
                            dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
                        }
                       break;
                   case 'comorbidities_none':
                       // When none is selected clear all selections
                       if (comorbidities_none !== field.value) {
                           if(field.value){
                               dispatch(setExamDataValues({
                                   'asthma': false,
                                   'fhx': false,
                                   'heart_block': false,
                                   'uveitis_od': false,
                                   'uveitis_os': false,
                                   'pds_od': false,
                                   'pds_os': false,
                                   'hx_stroke': false,
                                   'pxf_od': false,
                                   'pxf_os': false,
                               }));
                           }
                           dispatch(setExamDataValues({ [field.name[0]]: field.value }));
                       }
                       break;
                   default:
                       break;
               }
           }
       });
   }

    return (
        <Card className={className} bordered={false}>
            {/* COMORBID 001 */}
            <Form form={form} disabled={disabled} onFieldsChange={onFieldsChange} >
                {!onlyDisplaySelectedComorbidities &&
                <Fragment>
                    <Row className={'heading-row'}>
                        {/* COMORBID 012 */}
                        <FormItem className={'comorbidity-checkbox'}
                            label={(<span className='heading'>{COMORBIDITIES_TITLE}</span>)}
                            colon={false}
                            name='comorbidities_none'
                            valuePropName='checked'
                            rules={[{
                                required: required,
                                validator: checkCheckBox,
                            }]}
                            >
                                <Checkbox disabled={disabled}>
                                    {NONE_TEXT}
                                </Checkbox>
                        </FormItem>
                    </Row>
                    <Row className={`tags-section ${tagClassName}`}>
                        <Col span={8} className={'first-column'}>
                            <Row className={disabled ? 'checkable-tag-disabled' : 'checkable-tag'}>
                                <div className={gptClassname('fhx')}>
                                    <FormItem name='fhx' valuePropName='checked' >
                                    {/* COMORBID 003 */}
                                        <CheckableTag checked={fhx} key={'fhx'}>
                                            {FHX_GLC_TEXT}
                                        </CheckableTag>
                                    </FormItem>
                                </div>
                            </Row>
                            <Row className={disabled ? 'checkable-tag-disabled' : 'checkable-tag'}>
                                <div className={gptClassname('asthma')}>
                                    <FormItem name='asthma'>
                                    {/* COMORBID 002 */}
                                        <CheckableTag checked={asthma} key={'asthma'}>
                                            {ASTHMA_TEXT}
                                        </CheckableTag>
                                    </FormItem>
                                </div>
                            </Row>
                            <Row className={disabled ? 'checkable-tag-disabled' : 'checkable-tag'}>
                                <div className={gptClassname('heart_block')}>
                                    <FormItem name='heart_block'>
                                    {/* COMORBID 004 */}
                                        <CheckableTag checked={heart_block} key={'heart_block'}>
                                            {HEART_BLOCK_TEXT}
                                        </CheckableTag>
                                    </FormItem>
                                </div>
                            </Row>
                            <Row className={disabled ? 'checkable-tag-disabled' : 'checkable-tag'}>
                                <div className={gptClassname('hx_stroke')}>
                                    <FormItem name={'hx_stroke'} >
                                    {/* COMORBID 007 */}
                                        <CheckableTag checked={hx_stroke} key={'hx_stroke'}>
                                            {STROKE_TEXT}
                                        </CheckableTag>
                                    </FormItem>
                                </div>
                            </Row>
                        </Col>
                        <Col span={1}>
                            <Divider type={'vertical'} />
                        </Col>
                        <Col span={8} className={'second-column'}>
                            <Row className={'empty-row'} />
                            <Row className={disabled ? 'checkable-tag-disabled' : 'checkable-tag'}>
                                <div className={gptClassname('uveitis_od')}>
                                    <FormItem name={'uveitis_od'}>
                                    {/* COMORBID 005 */}
                                        <CheckableTag checked={uveitis_od} key={'uveitis_od'}>
                                            {UVEITIS_OD_TEXT}
                                        </CheckableTag>
                                    </FormItem>
                                </div>
                            </Row>
                            <Row className={disabled ? 'checkable-tag-disabled' : 'checkable-tag'}>
                                <div className={gptClassname('pxf_od')}>
                                    <FormItem name={'pxf_od'}>
                                    {/* COMORBID 008 */}
                                        <CheckableTag checked={pxf_od} key={'pxf_od'}>
                                            { (userIsOMDC || userIsOMDR) &&
                                                <span className="yellow-color">{PXF_OD_TEXT}</span>
                                            }
                                            { !(userIsOMDC || userIsOMDR) &&
                                                PXF_OD_TEXT
                                            }
                                        </CheckableTag>
                                    </FormItem>
                                </div>
                            </Row>
                            <Row className={disabled ? 'checkable-tag-disabled' : 'checkable-tag'}>
                                <div className={gptClassname('pds_od')}>
                                    <FormItem name={'pds_od'}>
                                    {/* COMORBID 010 */}
                                        <CheckableTag checked={pds_od} key={'pds_od'}>
                                            { (userIsOMDC || userIsOMDR) &&
                                                <span className="yellow-color">{PDS_OD_TEXT}</span>
                                            }
                                            { !(userIsOMDC || userIsOMDR) &&
                                                PDS_OD_TEXT
                                            }
                                        </CheckableTag>
                                    </FormItem>
                                </div>
                            </Row>
                        </Col>
                        <Col span={7}>
                            <Row className={'empty-row'} />
                            <Row className={disabled ? 'checkable-tag-disabled' : 'checkable-tag'}>
                                <div className={gptClassname('uveitis_os')}>
                                    <FormItem name={'uveitis_os'}>
                                    {/* COMORBID 009 */}
                                        <CheckableTag checked={uveitis_os} key={'uveitis_os'}>
                                            {UVEITIS_OS_TEXT}
                                        </CheckableTag>
                                    </FormItem>
                                </div>
                            </Row>
                            <Row className={disabled ? 'checkable-tag-disabled' : 'checkable-tag'}>
                                <div className={gptClassname('pxf_os')}>
                                    <FormItem name={'pxf_os'}>
                                    {/* COMORBID 011 */}
                                        <CheckableTag checked={pxf_os} key={'pxf_os'}>
                                            { (userIsOMDC || userIsOMDR) &&
                                                <span className="yellow-color">{PXF_OS_TEXT}</span>
                                            }
                                            { !(userIsOMDC || userIsOMDR) &&
                                                PXF_OS_TEXT
                                            }
                                        </CheckableTag>
                                    </FormItem>
                                </div>
                            </Row>
                            <Row className={disabled ? 'checkable-tag-disabled' : 'checkable-tag'}>
                                <div className={gptClassname('pds_os')}>
                                    <FormItem name={'pds_os'}>
                                        <CheckableTag checked={pds_os} key={'pds_os'}>
                                            { (userIsOMDC || userIsOMDR) &&
                                                <span className="yellow-color">{PDS_OS_TEXT}</span>
                                            }
                                            { !(userIsOMDC || userIsOMDR) &&
                                                PDS_OS_TEXT
                                            }
                                        </CheckableTag>
                                    </FormItem>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Fragment>
                }
                { onlyDisplaySelectedComorbidities &&
                // OMDC, OMDR and OMDRP should only see the selected comorbidities
                <Fragment>
                    <Row className={'heading-row'}>
                        <FormItem
                            label={(<span className='heading'>{COMORBIDITIES_TITLE}</span>)}
                        ></FormItem>
                    </Row>
                    <Row className={`tags-section display-only-selected-tags ${tagClassName}`}>
                        { fhx &&
                            <CheckableTag checked={fhx}>
                                {FHX_GLC_TEXT}
                            </CheckableTag>
                        }
                        { asthma &&
                            <CheckableTag checked={asthma}>
                                {ASTHMA_TEXT}
                            </CheckableTag>
                        }
                        { heart_block &&
                            <CheckableTag checked={heart_block}>
                                {HEART_BLOCK_TEXT}
                            </CheckableTag>
                        }
                        { hx_stroke &&
                            <CheckableTag checked={hx_stroke}>
                                {STROKE_TEXT}
                            </CheckableTag>
                        }
                        { uveitis_od &&
                            <CheckableTag checked={uveitis_od}>
                                {UVEITIS_OD_TEXT}
                            </CheckableTag>
                        }
                        { pxf_od &&
                            <CheckableTag checked={pxf_od}>
                            { (userIsOMDC || userIsOMDR) &&
                                <span className="yellow-color"> {PXF_OD_TEXT}</span>
                            }
                            { !(userIsOMDC || userIsOMDR) &&
                                PXF_OD_TEXT
                            }
                            </CheckableTag>
                        }
                        { pds_od &&
                            <CheckableTag checked={pds_od}>
                            { (userIsOMDC || userIsOMDR) &&
                                <span className="yellow-color">{PDS_OD_TEXT}</span>
                            }
                            { !(userIsOMDC || userIsOMDR) &&
                                PDS_OD_TEXT
                            }
                            </CheckableTag>
                        }
                        { uveitis_os &&
                            <CheckableTag checked={uveitis_os}>
                                {UVEITIS_OS_TEXT}
                            </CheckableTag>
                        }
                        { pxf_os &&
                            <CheckableTag checked={pxf_os}>
                            { (userIsOMDC || userIsOMDR) &&
                                <span className="yellow-color">{PXF_OS_TEXT}</span>
                            }
                            { !(userIsOMDC || userIsOMDR) &&
                                PXF_OS_TEXT
                            }
                            </CheckableTag>
                        }
                        { pds_os &&
                            <CheckableTag checked={pds_os}>
                            { (userIsOMDC || userIsOMDR) &&
                                <span className="yellow-color">{PDS_OS_TEXT}</span>
                            }
                            { !(userIsOMDC || userIsOMDR) &&
                                PDS_OS_TEXT
                            }
                            </CheckableTag>
                        }
                    </Row>
                </Fragment>
                }
            </Form>
        </Card>
    );
}

export default PatientExamComorbidities;