import React, { useState } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import {
    CalendarOutlined, SearchOutlined, InfoCircleOutlined, GlobalOutlined, UserOutlined, ProfileOutlined,
    AuditOutlined, SnippetsOutlined, CloseCircleOutlined, SolutionOutlined, ApartmentOutlined, CloudSyncOutlined
} from '@ant-design/icons';
import { Row, Select, Input, Divider, Modal, Button, DatePicker, Checkbox, Tooltip } from 'antd';
import {
    ADMIN_UNFLAGGED_OPTIONS, ADMIN_FLAGGED_OPTIONS, ADMIN_MISC_OPTIONS,
    ADMIN_OD_PROGRAM_OPTIONS, ADMIN_BULK_OPERATIONS_OPTIONS, ADMIN_AUDIT_RECORD_OPTIONS, ERROR_MESSAGE_TITLE,
    OUTBOUND_OMDR_ID,
} from '../../constants';
import '../../../static/css/shared/patient-list-control-bar.scss';
import '../../../static/css/components/patient-list-control-bar-admin.scss';
import { IBulkFaxIGPLetterType, bulkSendFaxLetters, faxOMDRGPIGPLetters } from '../../reducers/letters-slice';
import { IPatientList, clearAllFilters, getAdminPatientListRequest, getAdminSearchPatientListRequest,
    setPatientListFieldData
} from '../../reducers/patient-list-slice';
import { clearPatientExamRoomsData, removeFromExamRooms } from '../../reducers/patient-exam-rooms-slice';
import { localizedText } from '../../localizedText';
import { useGetAllGpsQuery } from '../../services/doctor-api';
import { useBulkReviewOutboundExamsRequestMutation, useExamSetInternalGpAndStatusMutation, useSetExamOMDCRequestMutation, useSetExamOMDCStatusRequestMutation, useSetExamOMDRRequestMutation } from '../../services/exam-api';
import { setODReviewReminderEmailField, setODReviewReminderEmailModalOpen } from '../../reducers/od-review-reminder-email-slice';
import { IExamRoom } from '../../reducers/patient-exam-rooms-slice';
import { IOmdOption } from '../../reducers/options-slice';
import dayjs from 'dayjs';

const PatientListControlBarAdmin = () => {
    const { PATIENT_LIST_SEARCH_PLACEHOLDER, EXAM_VISIT_DATE, OD_PROGRAM_TEXT,
        UNFLAGGED_AND_READY_OMD, CLEAR_FILTERS, GET_FILTERED_LIST, SET_IGP, SET_OMDC,
        SET_OMDR, SELECT_BULK_ACTION, USING_START_END_DATES, SEARCH_FOR_GP_MD2,
        SEARCH_FOR_OD, SEARCH_FOR_OMD, SEARCH_FOR_IGP, MISCELLANEOUS_TEXT,
        FLAGGED_AND_READY_OMD, EXAM_PERIOD_OPTIONS } = localizedText;

    const odProgramFilter = useCare1AppSelector(store => store.patientList.adminOdProgramFilter);
    const periodFilter = useCare1AppSelector(store => store.patientList.adminPeriodFilter);
    const searchODFilter = useCare1AppSelector(store => store.patientList.adminSearchODFilter);
    const searchOMDFilter = useCare1AppSelector(store => store.patientList.adminSearchOMDFilter);
    const searchGPFilter = useCare1AppSelector(store => store.patientList.adminSearchGPFilter);
    const searchIGPFilter = useCare1AppSelector(store => store.patientList.adminSearchIGPFilter);
    const unflaggedFilter = useCare1AppSelector(store => store.patientList.adminUnflaggedFilter);
    const flaggedFilter = useCare1AppSelector(store => store.patientList.adminFlaggedFilter);
    const miscFilter = useCare1AppSelector(store => store.patientList.adminMiscFilter);
    const auditRecordFilter = useCare1AppSelector(store => store.patientList.adminAuditRecordFilter);
    const auditRecordStartDate = useCare1AppSelector(store => store.patientList.adminAuditRecordStartDate);
    const auditRecordEndDate = useCare1AppSelector(store => store.patientList.adminAuditRecordEndDate);
    const bulkIGP = useCare1AppSelector(store => store.patientList.adminBulkIGP);
    const bulkOMDR = useCare1AppSelector(store => store.patientList.adminBulkOMDR);
    const bulkOMDC = useCare1AppSelector(store => store.patientList.adminBulkOMDC);
    const bulkOperation = useCare1AppSelector(store => store.patientList.adminBulkOperation);
    const examRooms = useCare1AppSelector(store => store.patientExams.rooms);
    const igpList = useCare1AppSelector(store => store.igpList.list);
    const omdList = useCare1AppSelector(store => store.options.omds);
    const odList = useCare1AppSelector(store => store.options.ods);
    const inactivePatientChecked = useCare1AppSelector(store => store.patientList.inactivePatientChecked);
    const inactiveExamChecked = useCare1AppSelector(store => store.patientList.inactiveExamChecked);
    const testPatientChecked = useCare1AppSelector(store => store.patientList.testPatientChecked);

    const { data: gpList = [] } = useGetAllGpsQuery();

    const [setExamOMDCStatusRequest] = useSetExamOMDCStatusRequestMutation();
    const [setExamOMDCRequest] = useSetExamOMDCRequestMutation();
    const [setExamOMDRRequest] = useSetExamOMDRRequestMutation();
    const [examSetInternalGpAndStatus] = useExamSetInternalGpAndStatusMutation();
    const [bulkReviewOutboundExamsRequest] = useBulkReviewOutboundExamsRequestMutation();

    const dispatch = useCare1AppDispatch();
    const { RangePicker } = DatePicker;

    // Local state to track when a new filter selection is made.
    // A new filter selection will activate the "Get Filtered List" button.
    // Submitting "Get Filtered List" will deactivate the button.
    const [getFilteredListIsSubmitted, setGetFilteredListIsSubmitted] = useState(false);

    //const [bulkOperation, setBulkOperation] = useState(null);

    // Selecting at least on filter will activate the "Clear Filters" button
    // and the "Get Filter List" button (the latter if getFilteredListIsSubmitted is also true).
    const filterIsSelected = periodFilter || odProgramFilter || unflaggedFilter || flaggedFilter || miscFilter
        || searchIGPFilter || searchGPFilter || searchODFilter || searchOMDFilter
        || (auditRecordFilter && auditRecordStartDate && auditRecordEndDate) || inactivePatientChecked
        || inactiveExamChecked || testPatientChecked;

    const bulkFilterIsSelected = bulkIGP || bulkOMDR || bulkOMDC;

    const sendSearchPatientListRequest = (value: string) => {
        if (value) {
            handleClearAllFilters();
            dispatch(setPatientListFieldData({ key: 'searchQuery', value }));
            dispatch(getAdminSearchPatientListRequest(value));
        } else {
            dispatch(setPatientListFieldData({ key: 'searchQuery', value: ''}));
        }
    }

    const sendFilteredAdminPatientListRequest = () => {
            dispatch(getAdminPatientListRequest());
        setGetFilteredListIsSubmitted(true);
    }

    // Function to set a filter selection
    const setFilterSelection = (filter: keyof IPatientList, value: boolean | string | number) => {
        // Set a patientList reducer value
        dispatch(setPatientListFieldData({ key: filter,  value }));

        // Reset get filtered list
        setGetFilteredListIsSubmitted(false);
    }

    // Function to clear a single filter selection
    const clearFilterSelection = (filter: keyof IPatientList) => {
        // Set a patientList reducer value
        dispatch(setPatientListFieldData({ key: filter, value: null }));
    }

    // Function to handle the selection of the Unflagged, Flagged and Miscellaneous filters selection
    const handleFlaggedFiltersSelection = (filter: keyof IPatientList, value: string) => {
        // Clear filters
        dispatch(setPatientListFieldData({ key: 'adminUnflaggedFilter', value: null }));
        dispatch(setPatientListFieldData({ key: 'adminFlaggedFilter', value: null }));
        dispatch(setPatientListFieldData({ key: 'adminMiscFilter', value: null }));

        // Set the selected filter value
        dispatch(setPatientListFieldData({ key: filter, value }));
        setGetFilteredListIsSubmitted(false);
    }

    const handleStartEndDatesFilterSelection = (value: string | null) => {
        handleClearAllFilters();
        dispatch(setPatientListFieldData({ key: 'adminAuditRecordFilter', value }));
        setGetFilteredListIsSubmitted(false);
    }

    const handleRangePicker = (dateStrings: string[]) => {
        dispatch(setPatientListFieldData({ key: 'adminAuditRecordStartDate', value: dateStrings[0] }));
        dispatch(setPatientListFieldData({ key: 'adminAuditRecordEndDate', value: dateStrings[1] }));
    }

    const handleClearAllFilters = () => {        
        setGetFilteredListIsSubmitted(false);

        dispatch(clearAllFilters());

        // Clear Patient Exam Room data
        dispatch(clearPatientExamRoomsData());
    }

    /* Handle Bulk Operations - START */
    const setBulkDoctorFilter = (filter: keyof IPatientList, value: number) => {
        // Set the selected filter value
        dispatch(setPatientListFieldData({ key: filter, value }));
    }

    const handleFaxOMDRGPRELetters = () => {
        if (examRooms && examRooms.length) {
            dispatch(bulkSendFaxLetters('omdrgpenabled'));
        }
    }

    const handleFaxOMDRGPIGPLetters = () => {
        if (examRooms && examRooms.length) {
            const intLetterType = 'omdrintgp' as IBulkFaxIGPLetterType;
            dispatch(faxOMDRGPIGPLetters({examRooms, intLetterType}));
        }
    }

    const hanleFaxRLUOMDRGPIGPLetters = () => {
        if (examRooms && examRooms.length) {
            const intLetterType = 'rluomdrintgp' as IBulkFaxIGPLetterType;
            dispatch(faxOMDRGPIGPLetters({examRooms, intLetterType}));
        }
    }

    const handleFaxOMDCGPLetters = () => {
        if (examRooms && examRooms.length) {
            dispatch(bulkSendFaxLetters('omdcgp'));
        }
    }

    const handleFaxRluOMDCGPLetters = () => {
        if (examRooms && examRooms.length) {
            dispatch(bulkSendFaxLetters('rluomdcgp'));
        }
    }


    const handleReadyForInternalGPSetIGP = () => {
        if (examRooms && examRooms.length) {
            if (!bulkIGP) {
                const error = 'Please select an Internal GP first';
                Modal.error({
                    title: ERROR_MESSAGE_TITLE,
                    content: error,
                });
                return;
            }

            let promptMsg = 'Are you sure you want to set status of ' + examRooms.length + ' visits to ready for GP and set Internal GP?';

            Modal.confirm({
                className: 'bulk-operation-confirmation-modal',
                title: '',
                content: promptMsg,
                icon: <InfoCircleOutlined />,
                okText: 'OK',
                cancelText: 'CANCEL',
                cancelButtonProps: {
                    className: 'confirm-exit',
                },
                onOk: () => {
                    let problemPatients : Array<string> = [];
                    Promise.all(
                        examRooms.map((exam) => {
                            return new Promise<void>((resolve, reject) => {
                                examSetInternalGpAndStatus({examId: exam['examId'], gpStatus: 'ready', selectedIGP: bulkIGP})
                                    .unwrap()
                                    .then((data) => {
                                        if (data['success']) {
                                            dispatch(removeFromExamRooms(exam['examId']));
                                            resolve();
                                        }
                                        else {
                                            problemPatients.push(exam['patientName']);
                                            resolve();
                                        }
                                    }).catch((error) => {
                                        problemPatients.push(exam['patientName']);
                                        resolve();
                                    });
                            });

                        })
                    ).then(() => {

                        if (problemPatients.length > 0) {
                            const patientsNames = problemPatients.join(', ');
                            const error = 'There was an error setting the exam of the following patients: ' + patientsNames + '. Success setting GP status and assigned GP of other selected patients to "Ready for GP" and selected Internal GP, respectively.';
                            Modal.error({
                                title: ERROR_MESSAGE_TITLE,
                                content: error,
                            });
                        }
                    });
                },
            });
        }
    }

    const handleFaxOMDRGPLetters = () => {
        if (examRooms && examRooms.length) {
            dispatch(bulkSendFaxLetters('omdrgp'));
        }
    }

    const handleFaxRLUOMDRGPLetters = () => {
        if (examRooms && examRooms.length) {
            dispatch(bulkSendFaxLetters('rluomdrgp'));
        }
    }

    const setBulkOMDR = (examsToSet: IExamRoom[]) => {
        Modal.confirm({
            className: 'bulk-operation-confirmation-modal',
            title: 'Please Confirm Bulk Operation',
            content: `Are you sure you want to set OMDR for ${examsToSet.length} visits?`,
            icon: <InfoCircleOutlined />,
            okText: 'OK',
            cancelText: 'CANCEL',
            cancelButtonProps: {
                className: 'confirm-exit',
            },
            onOk: async () => {
                // Set all OMDRs, and afterwards, refresh the patients list.
                if (bulkOMDR === OUTBOUND_OMDR_ID) {
                    const res = await bulkReviewOutboundExamsRequest(examsToSet.map(exam => 
                        exam.examId
                    )).unwrap();

                    if (res && res.success) {
                        dispatch(getAdminPatientListRequest());
                    } else if (res && !res.success) {
                        if (res.data && res.data.length !== 0 ) {
                            Modal.info({
                                className: 'info-modal',
                                title: `Auto review failed exams, ${res.data.join(',')} `,
                            })
                        }
                    }
                } else {
                    Promise.all(
                        examsToSet.map((exam) => {
                            return setExamOMDRRequest({omdrId: bulkOMDR, examId: exam.examId});
                        })
                    ).then(() => {
                        dispatch(getAdminPatientListRequest());
                    });
                }  
            },
        });
    }

    const handleSetODReviewReminderEmail = () => {
        if (examRooms && examRooms.length) {
            dispatch(setODReviewReminderEmailModalOpen(true));
            dispatch(setODReviewReminderEmailField({field: 'examIds', value: examRooms.map((e) => e.examId)}));
        }
    }

    const handleSetOMDR = () => {
        if (examRooms && examRooms.length) {
            // Find the selected bulk OMDR's review provinces.
            const selectedOMDR: IOmdOption | undefined = omdList.find(omdr => omdr.id === bulkOMDR);
            const agreedToReviewProvinces = selectedOMDR?.agreed_to_review_provinces;
            const omdName = `${selectedOMDR?.first_name} ${selectedOMDR?.last_name}`;

            // Sort the selected exams into matched (in the same province as OMD) and mismatched (not in the same
            // province) lists.
            const matchedExams = examRooms.filter(exam => agreedToReviewProvinces?.includes(exam.patient.province));
            const mismatchedExams = examRooms.filter(exam => !agreedToReviewProvinces?.includes(exam.patient.province));

            if (mismatchedExams.length > 0) {
                Modal.confirm({
                    className: 'bulk-operation-confirmation-modal',
                    title: 'Please Confirm Bulk Operation',
                    content: `${mismatchedExams.length} patients are outside the 'agreed to review' provinces of Dr. \
                        ${omdName}, do you want to assign them anyway? Select [Yes] to assign all or [No] to only assign\
                         patients within OMDR's 'agreed to review' provinces.`,
                    icon: <InfoCircleOutlined />,
                    okText: 'YES',
                    cancelText: 'NO',
                    cancelButtonProps: {
                        className: 'confirm-exit',
                    },
                    onOk: () => {
                        setBulkOMDR(mismatchedExams.concat(matchedExams));
                    },
                    onCancel: () => {
                        setBulkOMDR(matchedExams);
                    },
                });
            }
            else {
                setBulkOMDR(matchedExams);
            }
        }
    }

    const setBulkOMDCReadyAndStatus = (examsToSet: IExamRoom[]) => {
        // Set all OMDC statuses, then the OMDCs themselves. Refresh the admin list after all that is done.
        Promise.all(
            examsToSet.map((exam) => {
                return setExamOMDCStatusRequest({examId: exam.examId, omdcStatus: 'ready'})
                    .then(()=>setExamOMDCRequest({omdcId: bulkOMDC, examId: exam.examId}));
            })
        )
            .then(()=>dispatch(getAdminPatientListRequest()))
            .catch((errors) => {
                // We need to show the errors to the user in a popup.
                Modal.error({
                    className: 'info-modal',
                    title: `Failed to set OMDC and OMDC status. ${errors}`,
                });
            });
    }

    const handleSetOMDCReady = () => {
        if (examRooms && examRooms.length) {
            // Find the selected bulk OMDC.
            const selectedOMDC = omdList.find(omdc => omdc.id === bulkOMDC);
            if (selectedOMDC) {
                // Confirm and set the OMDCs.
                Modal.confirm({
                    className: 'bulk-operation-confirmation-modal',
                    title: 'Please Confirm Bulk Operation',
                    content: `Are you sure you want to set status of ${examRooms.length} visits to ready for consultant \
                            and set OMDC?`,
                    icon: <InfoCircleOutlined />,
                    cancelButtonProps: {
                        className: 'confirm-exit',
                    },
                    onOk: () => {
                        setBulkOMDCReadyAndStatus(examRooms);
                    },
                });
            }
            else {
                Modal.info({
                    className: 'info-modal',
                    title: 'Please select an OMD Consultant first!',
                });
            }
        }
    }

    const setBulkOMDCProcessed = (examsToSet: IExamRoom[]) => {
        // Set all OMDC statuses. Refresh the admin list after all that is done.
        Promise.all(
            examsToSet.map((exam) => {
                return setExamOMDCStatusRequest({examId: exam.examId, omdcStatus: 'processed'});
            })
        )
            .then(()=>dispatch(getAdminPatientListRequest()))
            .catch((errors) => {
                // We need to show the errors to the user in a popup.
                Modal.error({
                    className: 'info-modal',
                    title: `Failed to set OMDC status. ${errors}`,
                });
            });
    }

    const handleSetOMDCProcessed = () => {
        if (examRooms && examRooms.length) {
            // Confirm and set the OMDC status
            Modal.confirm({
                className: 'bulk-operation-confirmation-modal',
                title: 'Please Confirm Bulk Operation',
                content: `Are you sure you want to set status of ${examRooms.length} patient visits to consultation \
                        processed?`,
                icon: <InfoCircleOutlined />,
                cancelButtonProps: {
                    className: 'confirm-exit',
                },
                onOk: () => {
                    setBulkOMDCProcessed(examRooms);
                },
            });
        }
    }

    const handleBulkOperationSelection = (key: string) => {
        // Confirm the bulk operation.
        const operation = ADMIN_BULK_OPERATIONS_OPTIONS!.find(entry => entry.key === key)?.value;

        Modal.confirm({
            className: 'bulk-operation-confirmation-modal',
            title: 'Please Confirm Bulk Operation',
            content: `Perform '${operation}'`,
            icon: <InfoCircleOutlined />,
            okText: 'OK',
            cancelText: 'CANCEL',
            cancelButtonProps: {
                className: 'confirm-exit',
            },
            onOk: () => {
                switch (key) {
                    case 'fax_rlu_omdr_gp':
                        handleFaxRLUOMDRGPLetters();
                        break;
                    case 'fax_rlu_omdc_gp':
                        handleFaxRluOMDCGPLetters();
                        break;
                    case 'fax_omdr_gp':
                        handleFaxOMDRGPLetters();
                        break;
                    case 'fax_omdr_gp_re':
                        handleFaxOMDRGPRELetters();
                        break;
                    case 'fax_rlu_omdr_igp':
                        hanleFaxRLUOMDRGPIGPLetters();
                        break;
                    case 'fax_omdr_igp':
                        handleFaxOMDRGPIGPLetters();
                        break;
                    case 'fax_omdc_gp':
                        handleFaxOMDCGPLetters();
                        break;
                    case 'set_omdc_processed':
                        handleSetOMDCProcessed();
                        break;
                    case 'set_omdc_ready':
                        handleSetOMDCReady();
                        break;
                    case 'set_igp':
                        handleReadyForInternalGPSetIGP();
                        break;
                    case 'set_omdr':
                        handleSetOMDR();
                        break;
                    case 'send_od_to_review_reminder_email':
                        handleSetODReviewReminderEmail();
                        break;
                    default:
                        break;
                }

                // After each bulk operation Clear Bulk Operation filters
                dispatch(setPatientListFieldData({ key: 'adminBulkOperation', value: null }));
                dispatch(setPatientListFieldData({ key: 'adminBulkIGP', value: null }));
                dispatch(setPatientListFieldData({ key: 'adminBulkOMDR', value: null }));
                dispatch(setPatientListFieldData({ key: 'adminBulkOMDC', value: null }));
            },
        });
    }
    /* Handle Bulk Operations - END */

    return (
        <Row className={'patient-list-control-bar patient-list-control-bar-admin'}>

            { /* Row One - Search, Exam Visit Date and Program Filters */}
            <Row align="middle" className="control-bar-elements first-row">
                <Select
                    data-testid='od-program-filter'
                    className={`dropdown-filter od-program-filter${odProgramFilter ? ' is-selected' : ''}`}
                    popupClassName="patient-list-dropdown"
                    placeholder={<div><GlobalOutlined /> {OD_PROGRAM_TEXT}</div>}
                    value={odProgramFilter}
                    allowClear={true}
                    onClear={() => clearFilterSelection('adminOdProgramFilter')}
                    onSelect={(value) => setFilterSelection('adminOdProgramFilter', value)}
                    filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {ADMIN_OD_PROGRAM_OPTIONS && ADMIN_OD_PROGRAM_OPTIONS.map((option) => (
                        <Select.Option className='option-selection' key={option.key} value={option.key}>
                            <GlobalOutlined /> {option.value}
                        </Select.Option>
                    ))}
                </Select>

                <Divider type="vertical" className="patient-list-vertical-divider" />

                <Select
                    data-testid='exam-period-selector'
                    className={`dropdown-filter exam-period-filter${periodFilter ? ' is-selected' : ''}`}
                    popupClassName="patient-list-dropdown"
                    placeholder={<div><CalendarOutlined /> {EXAM_VISIT_DATE}</div>}
                    value={periodFilter}
                    allowClear={true}
                    onClear={() => clearFilterSelection('adminPeriodFilter')}
                    onSelect={(value) => setFilterSelection('adminPeriodFilter', value)}
                    filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {EXAM_PERIOD_OPTIONS && EXAM_PERIOD_OPTIONS.map((option) => (
                        <Select.Option data-testid='exam-period-option' key={option.key} value={option.key}>
                            <CalendarOutlined /> {option.label}
                        </Select.Option>
                    ))}
                </Select>

                <Divider type="vertical" className="patient-list-vertical-divider" />

                <Input.Search
                    className="patient-list-search-input"
                    allowClear
                    placeholder={PATIENT_LIST_SEARCH_PLACEHOLDER}
                    prefix={<SearchOutlined />}
                    onSearch={sendSearchPatientListRequest}
                />
            </Row>

            { /* Row Two - Unflagged, Flagged and Miscellaneous filters */}
            <Row className="control-bar-elements second-row">
                <Select
                    data-testid='misc-filter'
                    className={`dropdown-filter misc-filter${miscFilter ? ' is-selected' : ''}`}
                    popupClassName="patient-list-dropdown"
                    placeholder={<div><ProfileOutlined /> {MISCELLANEOUS_TEXT}</div>}
                    value={miscFilter}
                    allowClear={true}
                    onClear={() => clearFilterSelection('adminPeriodFilter')}
                    onSelect={(value) => handleFlaggedFiltersSelection('adminMiscFilter', value)}
                    filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {ADMIN_MISC_OPTIONS && ADMIN_MISC_OPTIONS.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            <ProfileOutlined /> {option.value}
                        </Select.Option>
                    ))}
                </Select>

                <Divider type="vertical" className="patient-list-vertical-divider" />

                <Tooltip
                    className='unflagged-filter-tooltip'
                    placement='left'
                    overlayInnerStyle={{width: "530px"}}
                    title={
                        <div className='admin-tooltip-div'>
                            All patients (*also applied to the other filters):
                                <ul>
                                    <li>Pre-reviewed flag = TRUE</li>
                                    <li>Exam status is Ready for OMD</li>
                                    <li>Exam is Premium</li>
                                </ul>
                            Special Attention*:
                                <ul>
                                    <li>OD/OS IOP greater or equal to 28; OR</li>
                                    <li>Exam was uploaded by an OD who has orientating = TRUE; OR</li>
                                    <li>Exam was uploaded by an OD who has special_attention = TRUE; OR</li>
                                    <li>OD RQST. Care1 OMD Appt = TRUE; OR</li>
                                    <li>OD has question = TRUE; OR</li>
                                    <li>Second OMDR is not empty; OR</li>
                                    <li>Exam is high priority</li>
                                    <li>OD Messaging = TRUE</li>
                                    <li>Exam is Outbound Referral = FALSE</li>
                                </ul>
                            Assign First*:
                                <ul>
                                    <li>When f/u time is within 2 months; OR</li>
                                    <li>When OD has quest/issue is selected; OR</li>
                                    <li>When the IPC status is IPC Requested; OR</li>
                                    <li>When there are multiple Ready for OMD visits; OR</li>
                                    <li>When IOP &gt; 25 either eye; OR</li>
                                    <li>When OD is orientating; OR</li>
                                    <li>When the exam is high priority without IPC Requested; OR</li>
                                    <li>Exam is Outbound Referral = FALSE</li>
                                </ul>
                            Outbound Referral*:
                                <ul>
                                    <li>Exam is Outbound Referral = True</li>
                                </ul>
                            Retina*:
                                <ul>
                                    <li>Patient is NOT glaucoma patient; AND</li>
                                    <li>ERM, DM, AMD, Cataract or Cat Post OP is True; AND</li>
                                    <li>All Referral reasons are retina1; AND</li>
                                    <li>Referral type is not empty</li>
                                </ul>
                            Other*:
                                <ul>
                                    <li>Patient is NOT glaucoma patient; AND</li>
                                    <li>All of ERM, DM, AMD, Cataract or Cat Post OP are NOT True; AND</li>
                                    <li>Not all Referral reasons are retina1; AND</li>
                                    <li>Referral type is not empty</li>
                                </ul>
                            GLC-P*:
                                <ul>
                                    <li>Patient status is P (stable); AND</li>
                                    <li>Patient was glaucoma (determined by previous visits)</li>
                                </ul>
                            GLC-BO*:
                                <ul>
                                    <li>Patient status is BO (observe); AND</li>
                                    <li>Patient was glaucoma (determined by previous visits)</li>
                                </ul>
                            GLC-BT*:
                                <ul>
                                    <li>Patient status is BT (treat); AND</li>
                                    <li>Patient was glaucoma (determined by previous visits)</li>
                                </ul>
                            GDGVF*:
                                <ul>
                                    <li>Patient is Glaucoma; AND</li>
                                    <li>G is found in OS AND OD BDisc; AND</li>
                                    <li>G is found in OS AND OD BField</li>
                                </ul>
                            BDGVF*:
                                <ul>
                                    <li>Patient is Glaucoma; AND</li>
                                    <li>G is NOT found in OS OR OD BDisc; AND</li>
                                    <li>G is found in OS AND OD BField</li>
                                </ul>
                            GDBVF*:
                                <ul>
                                    <li>Patient is Glaucoma; AND</li>
                                    <li>G is found in OS AND OD BDisc; AND</li>
                                    <li>G is NOT found in OS OR OD BField</li>
                                </ul>
                            BCBVF*:
                                <ul>
                                    <li>Patient is Glaucoma; AND</li>
                                    <li>G is NOT found in OS OR OD BDisc; AND</li>
                                    <li>G is NOT found in OS OR OD BField</li>
                                </ul>
                        </div>
                    }
                >
                    <InfoCircleOutlined />
                </Tooltip>

                <Select
                    data-testid='flagged-filter'
                    className={`dropdown-filter flagged-filter${flaggedFilter ? ' is-selected' : ''}`}
                    popupClassName="patient-list-dropdown"
                    placeholder={<div><ProfileOutlined /> {FLAGGED_AND_READY_OMD}</div>}
                    value={flaggedFilter}
                    allowClear={true}
                    onClear={() => clearFilterSelection('adminFlaggedFilter')}
                    onSelect={(value) => handleFlaggedFiltersSelection('adminFlaggedFilter', value)}
                    filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {ADMIN_FLAGGED_OPTIONS && ADMIN_FLAGGED_OPTIONS.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            <ProfileOutlined /> {option.value}
                        </Select.Option>
                    ))}
                </Select>

                <Tooltip
                    className='unflagged-filter-tooltip'
                    placement='left'
                    overlayInnerStyle={{width: "550px"}}
                    title={
                        <div className='admin-tooltip-div'>
                            All IC Exams (*also applied to the other filters):
                                <ul>
                                    <li>Pre-reviewed flag = FALSE</li>
                                    <li>Exam status is Ready for OMD</li>
                                    <li>Exam was uploaded by an OD who has Uses Referral Letter PEI = FALSE</li>
                                    <li>Exam was uploaded by an OD who has Integrated? = TRUE</li>
                                </ul>
                            Retina*:
                                <ul>
                                    <li>Chief complaints does not contain “Cat Ref”</li>
                                    <li>Care1 to refer pt to in-person OMD = NO/FALSE</li>
                                    <li>Chief complaints contains one or a combination of only DM, ERM or AMD</li>
                                </ul>
                            Glaucoma*:
                                <ul>
                                    <li>Chief complaints does not contain “Cat Ref”</li>
                                    <li>Care1 to refer pt to in-person OMD = NO/FALSE</li>
                                    
                                    <li>Chief complaint contains one or more:
                                        <ul>
                                            <li>Glc; AND/OR</li>
                                            <li>Glc Suspect; AND/OR</li>
                                            <li>Optic Nerve; AND/OR</li>
                                            <li>Narrow Angles</li>
                                        </ul>
                                    </li>
                                </ul>
                            Other*:
                                <ul>
                                    <li>Care1 to refer pt to in-person OMD = NO/FALSE</li>
                                    <li>Chief Complaints can include DM, ERM and AMD, but there must be</li>
                                    another Chief Complaint selected in addition to one or more of these 3
                                    <li>Chief complaints does not contain any of the following:
                                        <ul>
                                            <li>Cat Ref; OR</li>
                                            <li>Glc; OR</li>
                                            <li>Glc Suspect; OR</li>
                                            <li>Optic Nerve; OR</li>
                                            <li>Narrow Angles</li>
                                        </ul>
                                    </li>
                                </ul>
                            All IC exams (Ref Ltr + Submit) (**also applied to the other filters):
                                <ul>
                                    <li>Exam was uploaded by an OD who has Uses Referral Letter PEI = TRUE</li>
                                    <li>Exam was uploaded by an OD who has Integrated? = TRUE <br/> or Essentials? = TRUE</li>
                                    <li>Exam status is OD to Review</li>
                                    <li>Exam is Premium</li>
                                </ul>
                            IPC (Ref Ltr + Submit)**:
                                <ul>
                                    <li>Care1 to refer pt to in-person OMD = YES/TRUE</li>
                                </ul>
                            High priority (Ref Ltr + Submit) **:
                                <ul>
                                    <li>Care1 to refer pt to in-person OMD = NO/FALSE</li>
                                    <li>High priority checkbox = TRUE</li>
                                </ul>
                            OD Messaging:
                                <ul>
                                    <li>High priority checkbox = FALSE</li>
                                    <li>OD Messaging = TRUE</li>
                                </ul>
                            Outbound Referral **:
                                <ul>
                                    <li>Outbound Referral checkbox = TRUE</li>
                                </ul>
                            Other (Ref Ltr + Submit)**:
                                <ul>
                                    <li>Care1 to refer pt to in-person OMD = NO/FALSE</li>
                                    <li>High priority checkbox = FALSE</li>
                                    <li>OD Messaging = FALSE</li>
                                    <li>Outbound Referral = FALSE</li>
                                </ul>
                        </div>
                    }
                >
                    <InfoCircleOutlined />
                </Tooltip>

                <Select
                    data-testid='unflagged-filter'
                    className={`dropdown-filter unflagged-filter${unflaggedFilter ? ' is-selected' : ''}`}
                    popupClassName="patient-list-dropdown"
                    placeholder={<div><ProfileOutlined /> {UNFLAGGED_AND_READY_OMD}</div>}
                    value={unflaggedFilter}
                    allowClear={true}
                    onClear={() => clearFilterSelection('adminUnflaggedFilter')}
                    onSelect={(value) => handleFlaggedFiltersSelection('adminUnflaggedFilter', value)}
                    filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {ADMIN_UNFLAGGED_OPTIONS && ADMIN_UNFLAGGED_OPTIONS.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            <ProfileOutlined /> {option.value}
                        </Select.Option>
                    ))}
                </Select>
            </Row>

            { /* Row Three - Doctor Filters */}
            <Row className='control-bar-elements third-row'>
                <Select
                    data-testid='omd-select'
                    className={`dropdown-filter omd-filter${searchOMDFilter ? ' is-selected' : ''}`}
                    placeholder={<div><UserOutlined /> {SEARCH_FOR_OMD}</div>}
                    showSearch
                    value={searchOMDFilter}
                    allowClear={true}
                    onClear={() => clearFilterSelection('adminSearchOMDFilter')}
                    onSelect={(value) => setFilterSelection('adminSearchOMDFilter', value)}
                    filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {
                        omdList && omdList.filter((omd) => !omd.hidden_from_dropdown).map((omd) => {
                            return (
                                <Select.Option key={omd.id} value={omd.id}>
                                    {`Dr. ${omd.first_name} ${omd.last_name}`}
                                </Select.Option>
                            );
                        })
                    }
                </Select>

                <Divider type="vertical" className="patient-list-vertical-divider doctor-row" />

                <Select
                    data-testid='od-select'
                    className={`dropdown-filter od-filter${searchODFilter ? ' is-selected' : ''}`}
                    placeholder={<div><UserOutlined /> {SEARCH_FOR_OD}</div>}
                    showSearch
                    options={odList}
                    value={searchODFilter}
                    allowClear={true}
                    onClear={() => clearFilterSelection('adminSearchODFilter')}
                    onSelect={(value) => setFilterSelection('adminSearchODFilter', value)}
                    optionFilterProp='label'
                />
                    
                <Divider type="vertical" className="patient-list-vertical-divider doctor-row" />

                <Select
                    data-testid='gp-select'
                    className={`dropdown-filter gp-filter${searchGPFilter ? ' is-selected' : ''}`}
                    placeholder={<div><UserOutlined /> {SEARCH_FOR_GP_MD2}</div>}
                    showSearch
                    value={searchGPFilter}
                    allowClear={true}
                    onClear={() => clearFilterSelection('adminSearchGPFilter')}
                    onSelect={(value) => setFilterSelection('adminSearchGPFilter', value)}
                    filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {
                        gpList && gpList.map((gp) => {
                            return (
                                <Select.Option key={gp.id} value={gp.id}>
                                    {`Dr. ${gp.first_name} ${gp.last_name}`}
                                </Select.Option>
                            );
                        })
                    }
                </Select>

                <Divider type="vertical" className="patient-list-vertical-divider doctor-row" />

                <Select
                    data-testid='igp-select'
                    className={`dropdown-filter igp-filter${searchIGPFilter ? ' is-selected' : ''}`}
                    placeholder={<div><UserOutlined /> {SEARCH_FOR_IGP}</div>}
                    showSearch
                    value={searchIGPFilter}
                    allowClear={true}
                    onClear={() => clearFilterSelection('adminSearchIGPFilter')}
                    onSelect={(value) => setFilterSelection('adminSearchIGPFilter', value)}
                    filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {
                        igpList && igpList.map((igp) => {
                            return (
                                <Select.Option key={igp.id} value={igp.id}>
                                    {`Dr. ${igp.first_name} ${igp.last_name}`}
                                </Select.Option>
                            );
                        })
                    }
                </Select>
            </Row>

            { /* Row Four - Start/End dates filter */}
            <Row className="control-bar-elements fourth-row">
                <RangePicker
                    className='filter-range-picker'
                    onChange={(dates, dateStrings) => handleRangePicker(dateStrings)}
                    value={[auditRecordStartDate ? dayjs(auditRecordStartDate) : null, auditRecordEndDate ? dayjs(auditRecordEndDate) : null]}
                    placeholder={['Start Date', 'End Date']}
                    disabled={!auditRecordFilter}
                />

                <Divider type="vertical" className="patient-list-vertical-divider" />

                <Select
                    data-testid='audit-record-filter'
                    className={`dropdown-filter audit-record-filter${auditRecordFilter ? ' is-selected' : ''}`}
                    popupClassName="patient-list-dropdown"
                    placeholder={<div><SnippetsOutlined /> {USING_START_END_DATES}</div>}
                    value={auditRecordFilter}
                    allowClear={true}
                    onClear={() => handleStartEndDatesFilterSelection(null)}
                    onSelect={(value) => handleStartEndDatesFilterSelection(value)}
                    filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {ADMIN_AUDIT_RECORD_OPTIONS && ADMIN_AUDIT_RECORD_OPTIONS.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            <AuditOutlined /> {option.value}
                        </Select.Option>
                    ))}
                </Select>

                <Divider type="vertical" className="patient-list-vertical-divider" />

                <Checkbox
                    className={'inactiveCheckbox patient'}
                    checked={inactivePatientChecked}
                    onChange={() => setFilterSelection('inactivePatientChecked', !inactivePatientChecked)}
                >
                    {`Inactive Patients`}
                </Checkbox>

                <Checkbox
                    className={'inactiveCheckbox exam'}
                    checked={inactiveExamChecked}
                    onChange={() => setFilterSelection('inactiveExamChecked', !inactiveExamChecked)}
                >
                    {`Inactive Exams`}
                </Checkbox>

                <Checkbox
                    className={'inactiveCheckbox test-patient'}
                    checked={testPatientChecked}
                    onChange={() => setFilterSelection('testPatientChecked', !testPatientChecked)}
                >
                    {`Test Patients`}
                </Checkbox>
            </Row>

            <Divider type="horizontal" className="patient-list-horizontal-divider bulk-operations" />

            { /* Row Five - Bulk Operations */}
            <Row className="control-bar-elements fifth-row">
                {/* Bulk Action Selector. Should be disabled if no patients are selected. */}
                <Select
                    data-testid='bulk-operation-selector'
                    className={`dropdown-filter bulk-operation-selector`}
                    placeholder={<div><ApartmentOutlined /> {SELECT_BULK_ACTION}</div>}
                    popupClassName="patient-list-dropdown"
                    value={bulkOperation}
                    onSelect={handleBulkOperationSelection}
                    disabled={examRooms.length === 0}
                >
                    {ADMIN_BULK_OPERATIONS_OPTIONS && ADMIN_BULK_OPERATIONS_OPTIONS.map((option) => {
                        // The RLU options should not be displayed for non-RLU filters.
                        if ((option.key === 'fax_rlu_omdr_igp' || option.key === 'fax_rlu_omdr_gp') &&
                            (auditRecordFilter !== 'rlu_ic_gp_letter_to_send' && auditRecordFilter !== 'rlu_igp_referred_audit')) {
                            return <></>
                        }
                        if (option.key === 'fax_rlu_omdc_gp'  && auditRecordFilter !== 'rlu_omdc_gp_letter_to_send' ) {
                            return <></>
                        }

                        return <Select.Option key={option.key} value={option.key}>
                            <ApartmentOutlined /> {option.value}
                        </Select.Option>
                    })}
                </Select>

                <Divider type="vertical" className="patient-list-vertical-divider batch-operation" />

                <Select
                    data-testid='batch-omdr-dropdown'
                    className={`dropdown-filter batch-omdr-dropdown${bulkOMDR ? ' is-selected' : ''}`}
                    popupClassName="patient-list-dropdown"
                    placeholder={<div><SolutionOutlined />{SET_OMDR}</div>}
                    value={bulkOMDR}
                    disabled={examRooms.length === 0}
                    allowClear={true}
                    onClear={() => clearFilterSelection('adminBulkOMDR')}
                    onSelect={(value) => setBulkDoctorFilter('adminBulkOMDR', value)}
                >
                    {omdList && omdList.filter(omd => !omd.hidden_from_dropdown && omd.is_reviewer).map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                            <SolutionOutlined /> {option.first_name} {option.last_name}
                        </Select.Option>
                    ))}
                </Select>

                <Select
                    data-testid='batch-omdc-dropdown'
                    className={`dropdown-filter batch-omdc-dropdown${bulkOMDC ? ' is-selected' : ''}`}
                    popupClassName="patient-list-dropdown"
                    placeholder={<div><SolutionOutlined /> {SET_OMDC}</div>}
                    value={bulkOMDC}
                    disabled={examRooms.length === 0}
                    allowClear={true}
                    onClear={() => clearFilterSelection('adminBulkOMDC')}
                    onSelect={(value) => setBulkDoctorFilter('adminBulkOMDC', value)}
                >
                    {omdList && omdList.filter(omd => !omd.hidden_from_dropdown && !omd.is_reviewer).map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                            <SolutionOutlined /> {option.first_name} {option.last_name}
                        </Select.Option>
                    ))}
                </Select>

                <Select
                    data-testid='batch-igp-dropdown'
                    className={`dropdown-filter batch-igp-dropdown${bulkIGP ? ' is-selected' : ''}`}
                    popupClassName="patient-list-dropdown"
                    placeholder={<div><SolutionOutlined /> {SET_IGP}</div>}
                    value={bulkIGP}
                    disabled={examRooms.length === 0}
                    allowClear={true}
                    onClear={() => clearFilterSelection('adminBulkIGP')}
                    onSelect={(value) => setBulkDoctorFilter('adminBulkIGP', value)}
                >
                    {igpList && igpList.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                            <SolutionOutlined /> {option.first_name} {option.last_name}
                        </Select.Option>
                    ))}
                </Select>
            </Row>

            <Divider type="horizontal" className="patient-list-horizontal-divider action-buttons" />

            { /* Row Five - Action Buttons */}
            <Row className="control-bar-elements sixth-row">
                <Button
                    type='primary'
                    className='get-filtered-list-button'
                    onClick={sendFilteredAdminPatientListRequest}
                    disabled={!filterIsSelected || getFilteredListIsSubmitted}
                >
                    <CloudSyncOutlined /> {GET_FILTERED_LIST}
                </Button>

                <Button
                    className='clear-all-filters-button'
                    type='primary'
                    onClick={handleClearAllFilters}
                    disabled={!filterIsSelected && !bulkFilterIsSelected}
                >
                    <CloseCircleOutlined /> {CLEAR_FILTERS}
                </Button>

                <Divider type="vertical" className="patient-list-vertical-divider buttons-divider" />

            </Row>
        </Row>
    );
}

export default PatientListControlBarAdmin;