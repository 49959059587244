import { Modal, Card, Col, Row } from 'antd';

// Components
import HistoryImage from '../retina/history-image';

// Helpers
import { calculateVFBorderColor } from '../../helpers/patient-exam-convert';
import '../../../static/css/shared/images-history-card.scss';
import '../../../static/css/components/patient-exam-vf-history.scss';
import { viewAll } from '../../reducers/history-image-slice';
import { HistoryDropzoneField, IAllExamImages, IExamsWithLeftVf, IExamsWithRightVf } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { NO_IMAGE_UPLOADED, OCT_VF_TEXT } from '../../constants';

type ComponentProps = {
    side: 'left' | 'right',
    imageType: string,
    photoURL: HistoryDropzoneField,
    images: IAllExamImages[],
    colWidth: number,
    modalTitle: string,
}

const HistoryImagesViewAllModal = ({ side, modalTitle, imageType, photoURL, images, colWidth }: ComponentProps) => {
    const dispatch = useCare1AppDispatch();

    const historyImage = useCare1AppSelector(store => store.historyImage);

    const getViewAllModalImages = () => (
        images.map((image, index) => (
            <Col key={index} className={'image-col'} span={colWidth}>
                <Card key={index} bordered={false}>
                    <HistoryImage
                        type={imageType}
                        side={side}
                        photoUrl={image[photoURL]}
                        examDate={image.exam_date_utc}
                        idx={index}
                        borderClass={imageType === OCT_VF_TEXT
                            ? side === 'right'
                                ? calculateVFBorderColor((image as IExamsWithRightVf).right_ght)
                                : calculateVFBorderColor((image as IExamsWithLeftVf).left_ght)
                            : ''}
                        style={{}}
                    />
                </Card>
            </Col>
        ))
    )

    return (
        <Modal
            className={`view-all-modal larger-modal`}
            open={
                historyImage.imageSide === side && historyImage.imageType === imageType && historyImage.viewAll
            }
            width="840"
            onCancel={() => { dispatch(viewAll({isVisible: false, imageType, imageSide: side})); }}
            closable
            footer={null}
            title={modalTitle}
        >
            <Row gutter={9}>
                {images && images.length > 0 ? getViewAllModalImages()
                    : <div className="blank-history-image">{NO_IMAGE_UPLOADED}</div>}
            </Row>
        </Modal>
    );
}

export default HistoryImagesViewAllModal;