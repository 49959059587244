import { Button, Modal} from 'antd';
import { SaveOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { BACKEND_EXAM_STATUS_FIELD, CONFIRMATION_CANCEL_TEXT, CONFIRMATION_OK_TEXT, READY_FOR_OMD_CONFIRMATION_TITLE,
    READY_FOR_OMD_CONTENT_TEXT } from '../../../constants';
import { checkEmptyRecommendedFields } from '../../../helpers/patient-exam-convert';

import { useNavigate } from "react-router-dom";
import { localizedText } from '../../../localizedText';
import { removeExamFromTab } from '../../../reducers/patient-exam-rooms-slice';
import { saveExamData, saveExamDataAndClose } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';

const PatientExamHeaderSaveButton = ({shouldCloseExamPage}: {shouldCloseExamPage: boolean}) => {
    const { SAVE_BUTTON_FIELD_REQUIRED, SAVE_BUTTON_ANYWAY, SAVE_BUTTON_EDITING, 
        SAVE_BUTTON_EMPTY_FIELD_WARNING, SAVE_AND_CLOSE_BUTTON_TEXT, SAVE_BUTTON_TEXT } = localizedText;

    const buttonClassName = shouldCloseExamPage ? 'save-and-close-button': 'save-button';
    const buttonText = shouldCloseExamPage ?  SAVE_AND_CLOSE_BUTTON_TEXT : SAVE_BUTTON_TEXT;
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const isOD = useCare1AppSelector(store => store.user.isOD);
    const examStatus = useCare1AppSelector(store => store.examData[BACKEND_EXAM_STATUS_FIELD]);
    const patientExamRooms = useCare1AppSelector(store => store.patientExams.rooms);
    const currentPatientExamRoom = useCare1AppSelector(store => store.patientExams.currentExamRoom);
    const shouldDisplayMultiTabs = useCare1AppSelector(store => store.patientExams.shouldDisplayMultiTabs);
    const examStatusPrevious = useCare1AppSelector(store => store.examData.exam_status_previous);

    const isDisabled = isOD && examStatusPrevious && (examStatusPrevious === 'ready' || examStatusPrevious === 'reviewed');

    const dispatch = useCare1AppDispatch();
    const history = useNavigate();

    const confirmOMDReady = (callback: (param: boolean) => void) => {
        if (isOD) {
            const CONFIRMED = true;
            callback(CONFIRMED);
        }
        else {
            Modal.confirm({
                className: 'ready-omd-confirmation-modal',
                title: READY_FOR_OMD_CONFIRMATION_TITLE,
                content: READY_FOR_OMD_CONTENT_TEXT,
                icon: <InfoCircleOutlined />,
                okText: CONFIRMATION_OK_TEXT,
                cancelText: CONFIRMATION_CANCEL_TEXT,
                cancelButtonProps: { className: 'confirm-exit' },
                onOk: () => {
                    const CONFIRMED = true;
                    callback(CONFIRMED);
                    // a callback function to indicate whether the 'Yes' button is clicked or not
                    // if 'Yes' button is clicked, other functions using this callback function will get
                    // a true value from this callback.
                } });
        }
    }

    // Older Browsers do not support multi-line string so leave the content string on one line.
    const confirmEmptyFieldsWarning = (callback: (param: boolean) => void) => {
        Modal.confirm({
            className: 'patient-exam-warning-modal',
            title: SAVE_BUTTON_EMPTY_FIELD_WARNING,
            content: SAVE_BUTTON_FIELD_REQUIRED,
            icon: <InfoCircleOutlined />,
            okText: SAVE_BUTTON_ANYWAY,
            cancelText: SAVE_BUTTON_EDITING,
            cancelButtonProps: { className: 'confirm-exit' },
            onOk: () => {
                callback(true);
            } });
    }

    const savePatientExam = () => {
        if (shouldCloseExamPage) {
            // if exam tabs are active, save and close the current tab, if it is the only one exam
            // left, go back to patient list page
            if(patientExamRooms && patientExamRooms.length>1 && shouldDisplayMultiTabs){
                dispatch(saveExamData());
                dispatch(removeExamFromTab(currentPatientExamRoom, history));
            } else {
                dispatch(saveExamDataAndClose(history));
            }            
        }
        else {
            dispatch(saveExamData());
        }
    }

    // Takes a boolean value that if true, the page should close after save
    const handleSave = () => {

        // Do not process warning modal message for the RE PEI
        if(!userIsRE){

            // Display recommended fields warning modal if any recommended fields are empty
            // isPlaqunil and isOpticNerve have the same effect, only 1 true value is enough for the
            // empty recommend fields check
            if(dispatch(checkEmptyRecommendedFields())){
                confirmEmptyFieldsWarning(SAVE_EXAM => {
                    if(SAVE_EXAM){
                        if (examStatus === 'ready') {
                            confirmOMDReady((CONFIRMED) => (
                                CONFIRMED && savePatientExam()
                            ));
                        } else {
                            savePatientExam();
                        }
                    }
                })
            } else {
                if (examStatus === 'ready') {
                    confirmOMDReady((CONFIRMED) => (
                        CONFIRMED && savePatientExam()
                    ));
                } else {
                    savePatientExam();
                }
            }
        } else if (examStatus === 'ready') {
                confirmOMDReady((CONFIRMED) => (
                    CONFIRMED && savePatientExam()
                ));
        } else {
            savePatientExam();
        }
    }

    return (
        <Button
            className={buttonClassName}
            onClick={handleSave}
            type="primary"
            icon={<SaveOutlined />}
            size="large"
            disabled={Boolean(isDisabled)}
        >
            {buttonText}
        </Button>
    )
}

export default PatientExamHeaderSaveButton;