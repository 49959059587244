import { Button, Modal, Col, Row, Tag, Divider, List, DatePicker } from 'antd';
import globalColors from '../../../static/css/shared/_global-style.scss'; // Styling Audit - New stylesheet
import '../../../static/css/components/select-new-exam-date-modal.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { useState } from 'react';
import { useGetNotReadyExamsQuery, useGetOdReferralPatientDetailsQuery } from '../../services/patient-api';
import { closeNewExamDateModal } from '../../reducers/patient-edit-slice';
import ExamStatusTag from '../retina/exam-status-tag';
import LatestVisitButton from '../retina/button-latest-visit';
import DeactivateVisitButton from '../retina/deactivate-visit-button';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router';
import { getNewPatientExam } from '../../reducers/patient-exam-slice';
import { addCurrentTimeToDate } from '../../helpers/utilities';



const SelectNewExamDateModal = () => {
    
    const isVisible = useCare1AppSelector(store => store.patientEdit.selectNewExamDateModalOpen);
    const selectedPatientId = useCare1AppSelector(store => store.patientEdit.selectedPatientId);

    const {data} = useGetNotReadyExamsQuery(selectedPatientId, {skip: !selectedPatientId});

    const {data: patientData} = useGetOdReferralPatientDetailsQuery(selectedPatientId, {skip: !selectedPatientId});

    const referralLetterExamDateAllowedToSet = patientData?.referral_letter_exam_date_allowed_to_set;

    const phnDisplay = patientData?.phn ? patientData?.phn : <Tag className='no-phn'>No Health #</Tag>;
    const secondaryPhnDisplay = patientData?.secondary_phn ? patientData?.secondary_phn : <Tag className='no-phn'>No Health #</Tag>;
    const serviceNumberDisplay = patientData?.service_number ? patientData?.service_number : <Tag className='no-phn'>No Health #</Tag>;


    const [createNewVisitClicked, setCreateNewVisitClicked] = useState(false);
    const [examDate, setExamDate] = useState<Dayjs | null>(dayjs());
    

    const dispatch = useCare1AppDispatch();
    const history = useNavigate();


    const handleDateChange = (date: Dayjs | null) => {
        const dateToCheck =referralLetterExamDateAllowedToSet;
        if(date && dateToCheck){
            if(date.isBefore(dayjs(dateToCheck))){
                Modal.info({
                    className: 'info-modal white-space-modal',
                    content: `New Exam date cannot be older than the most recent submitted exam date. \n
                        The most recent submitted exam for this patient has an exam date of ${dayjs(dateToCheck).format('YYYY-MM-DD')}. 
                        Please create a new exam with an exam date later than ${dayjs(dateToCheck).format('YYYY-MM-DD')}.`
                })
            } else {
                setExamDate(date);
            }
        } else {
            if(date){
                setExamDate(date);
            }
        }
    }

    const onCreateButtonClick = () => {
        dispatch(getNewPatientExam(Number(selectedPatientId)!, addCurrentTimeToDate(examDate!).format()!, history));
        dispatch(closeNewExamDateModal());
        setExamDate(null);
    }

    return (
        <Modal
            className='select-new-exam-date-modal'
            open={isVisible}
            width="840"
            closable={false}
            footer={null}
            maskStyle={{ backgroundColor: globalColors['maskOverlayBlur'] }}
        >
            <Row className='exam-date-modal-container'>
                <Col span={8} className='patient-info-panel'>
                    <h3>{patientData?.name}</h3>
                    <Row justify='space-between'>
                        <p className='label-text'>DOB</p>
                        <p className='content-text'>{patientData?.birthday}</p>
                    </Row>
                    <Divider />
                    { patientData?.caf_personnel ? 
                        <Row justify='space-between'>
                            <Col span={8}><p className='label-text'>Health # </p></Col>
                            <Col span={4}><Tag className='caf'>CAF</Tag></Col>
                            <Col span={10}><p className='content-text'>{serviceNumberDisplay}</p></Col>
                        </Row>
                        : patientData?.is_mb_patient ?
                        <>
                            <Row justify='space-between'>
                                <Col span={8}><p className='label-text'>Health # </p></Col>
                                <Col span={4}><Tag className='mb-patient'>MB</Tag></Col>
                                <Col span={10}><p className='content-text'>{phnDisplay}</p></Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={8}><p className='label-text'>Health # </p></Col>
                                <Col span={4}></Col>
                                <Col span={10}><p className='content-text'>{secondaryPhnDisplay}</p></Col>
                            </Row>
                        </>
                        :
                        <Row justify='space-between'>
                            <Col span={8}><p className='label-text'>Health # </p></Col>
                            <Col span={4}></Col>
                            <Col span={10}><p className='content-text'>{phnDisplay}</p></Col>
                        </Row>
                    }
                    
                </Col>
                <Col span={16} className='exam-info-panel'>
                    {
                        !createNewVisitClicked && data?.has_not_ready_exams ?
                        <>
                            <Row>
                                You have previous exam(s) not yet complete. Please click edit to resume, or create new visit.
                            </Row>
                            <Row className='exam-select-box'>
                                <List
                                    dataSource={data?.not_ready_exams}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Row justify='space-between' align='middle' className='item-row'>
                                                <Col className='exam-date-text'>{item.exam_date}</Col>
                                                <Col>
                                                    <Row justify='space-between' align='middle'>
                                                        <Col>
                                                            <ExamStatusTag
                                                                className="patient-exam-status-tag"
                                                                examStatusKey={'not_ready'} />
                                                        </Col>
                                                        <Col>
                                                            <LatestVisitButton
                                                                className="button-latest-visit"
                                                                buttonText='Edit'
                                                                visitID={item.id}
                                                                newTab={true}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <DeactivateVisitButton visitID={item.id} disabled={false} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}/>
                            </Row>
                            <Row className='buttons-row'>
                                <Button
                                    type='primary'
                                    className='create-button'
                                    onClick={() => {setCreateNewVisitClicked(true)}}
                                >Create New Visit</Button>
                                <Button
                                    className='cancel-button'
                                    onClick={() => {
                                        dispatch(closeNewExamDateModal());
                                        setCreateNewVisitClicked(false);
                                        setExamDate(null);
                                    }}
                                >Cancel</Button>
                            </Row>
                        </>
                        :
                        <>
                            <Row justify='center' className='new-exam-date-text'>Select New Exam Date</Row>
                            <Row justify='center'>
                                <Col>
                                    <DatePicker
                                        onChange={date => handleDateChange(date)}
                                        value={examDate}
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        type='primary'
                                        className='create-button'
                                        onClick={onCreateButtonClick}
                                    >Create New Visit</Button>
                                </Col>
                            </Row>
                            <Row justify='end' className='cancel-button-row'>
                                <Button
                                    className='cancel-button'
                                    onClick={() => {
                                        dispatch(closeNewExamDateModal());
                                        setCreateNewVisitClicked(false);
                                        setExamDate(null);
                                    }}
                                >Cancel</Button>
                            </Row>
                        </>
                    }
                    
                </Col>
            </Row>
        </Modal>
    );
}

export default SelectNewExamDateModal;
