import { useState } from 'react';
import { Layout, Row, Col, Tabs, Spin } from 'antd';

import { useCare1AppSelector } from '../apps/care1-hooks';
import NavigationControlBar from '../components/navigation-control-bar';
import NavigationTopBar from '../components/navigation-top-bar';

import {
    OPERATING_TEXT, PHONE_MASK_FORMAT
} from '../constants';
import '../../static/css/components/od-dashboard.scss';
import Close from '../../static/images/dashboard-close.png';
import Open from '../../static/images/dashboard-open.png';
import GlaucomaProgramContents from '../components/glaucoma-program/glaucoma-program-contents';
import VisitsUploaded from '../components/retina/visits-uploaded';
import VisitsUploadedGraph from '../components/retina/visits-uploaded-graph';

import { useGetOdDashboardTotalUploadedQuery, useGetOdDashboardCataractReferralsQuery, 
    useGetOdDashboardUploadsWithOctQuery, useGetOdDashboardUploadsWithMiniDtcQuery, 
    useGetOdDashboardLastSixMonthsUploadsQuery } from '../services/od-dashboard-api';

const { Header, Content } = Layout;
const { TabPane } = Tabs;

const ODDashboardPage = () => {

    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userHasGlaucomaProgram = useCare1AppSelector(store => store.user.hasGlaucomaProgram);
    const odProvince = useCare1AppSelector(store => store.user.odProvince);

    const { data: totalUploaded = { data: { previous_month_count: 0, this_month_count: 0 } }, isFetching: isTotalUploadedFetching } = useGetOdDashboardTotalUploadedQuery();
    const { data: cataractReferrals = { data: { previous_month_count: 0, this_month_count: 0 } }, isFetching: isCataractReferralsFetching } = useGetOdDashboardCataractReferralsQuery();
    const { data: uploadsWithOct = { data: { previous_month_count: 0, this_month_count: 0 } }, isFetching: isUploadsWithOctFetching } = useGetOdDashboardUploadsWithOctQuery();
    const { data: uploadsWithMiniDtc = { data: { previous_month_count: 0, this_month_count: 0 } }, isFetching: isUploadsWithMiniDtcFetching } = useGetOdDashboardUploadsWithMiniDtcQuery();
    const { data: lastSixMonthsUploads = { data: [] }, isFetching: isLastSixMonthsUploadsFetching } = useGetOdDashboardLastSixMonthsUploadsQuery();

    if (userIsOD) {
        return (
            <>
                <Spin
                    className="loading-spinner"
                    spinning={isTotalUploadedFetching || isCataractReferralsFetching || isUploadsWithOctFetching || isUploadsWithMiniDtcFetching || isLastSixMonthsUploadsFetching}
                    size="large"
                    tip={OPERATING_TEXT}
                >
                    <Header>
                        <NavigationTopBar className="navigation-top-bar" />
                        <NavigationControlBar className="patient-list-navigation-control-bar" />
                    </Header>
                    <Content>
                        <div className='od-dashboard'>
                            <Tabs type="card">
                                <TabPane tab='Clinic Dashboard' forceRender key="1">
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <VisitsUploaded
                                                title='Total Uploads'
                                                previous_month_count={totalUploaded.data.previous_month_count}
                                                this_month_count={totalUploaded.data.this_month_count}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <VisitsUploaded
                                                title='Cataract Outbound Referrals'
                                                previous_month_count={cataractReferrals.data.previous_month_count}
                                                this_month_count={cataractReferrals.data.this_month_count}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            {odProvince !== 'MB' && <VisitsUploaded
                                                title='Uploads with OCT'
                                                previous_month_count={uploadsWithOct.data.previous_month_count}
                                                this_month_count={uploadsWithOct.data.this_month_count}
                                            />}
                                            {odProvince === 'MB' && <VisitsUploaded
                                                title='Uploads with Mini-DTC'
                                                previous_month_count={uploadsWithMiniDtc.data.previous_month_count}
                                                this_month_count={uploadsWithMiniDtc.data.this_month_count}
                                            />}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <VisitsUploadedGraph 
                                                title='Monthly Uploads'
                                                data={lastSixMonthsUploads.data}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                                {userHasGlaucomaProgram &&
                                    <TabPane tab='Glaucoma Program' key="2">
                                        <GlaucomaProgramContents />
                                    </TabPane>
                                }
                            </Tabs>
                        </div>
                    </Content>
                </Spin>
            </>
        );
    }
    return null;
}

export default ODDashboardPage;
