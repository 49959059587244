import { useCallback, useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';
import { Layout, Row, Card, Col, Spin, Modal } from 'antd';
import { useParams, useNavigate } from 'react-router';
import * as Constants from '../constants';

// Components
import NavigationTopBar from '../components/navigation-top-bar';
import NavigationControlBar from '../components/navigation-control-bar';
import PatientExamHeader from '../components/retina/header/patient-exam-header';
import PatientExamFundus from '../components/retina/patient-exam-fundus';
import PatientExamChiefComplaint from '../components/retina/patient-exam-chief-complaint';
import PatientExamOCTMacula from '../components/retina/patient-exam-oct-macula';
import PatientExamConversation from '../components/retina/patient-exam-conversation';
import PatientExamExtraImages from '../components/retina/patient-exam-extra-images';
import PatientExamImageHistory from '../components/retina/patient-exam-image-history';
import PatientExamPreviousVisits from '../components/patient-exam-previous-visits';
import PatientExamBCVA from '../components/retina/patient-exam-bcva';
import PatientDetailsModal from '../components/retina/patient-details-modal';
import PatientEditModal from '../components/retina/patient-edit-modal';
import PatientExamGPReferred from '../components/retina/patient-exam-gp-referred';
import HelpDeskModal from '../components/retina/help-desk-modal';
import { FormValidationProvider } from '../context/useFormValidationContext';

// Actions

// Helper
import '../../static/css/pages/_re-patient-exam-page.scss';
import '../../static/css/components/_patient-exam-bcva.scss';
import PlaquenilModal from '../components/integrated/plaquenil-modal';
import ExamDateSelectModal from '../components/retina/exam-date-select-modal';
import PatientExamHistoryAndExam from '../components/retina/patient-exam-history-and-exam';
import { editExamRequest } from '../reducers/patient-exam-slice';


const { Header, Content } = Layout;

const REPatientExamPage = () => {

    const examOperating = useCare1AppSelector(store => store.examData.operating);
    const showDetails = useCare1AppSelector(store => store.patientDetails.visible);
    const examStatusPrevious = useCare1AppSelector(store => store.examData.exam_status_previous);
    const patientDetailsOperating = useCare1AppSelector(store => store.patientDetails.operating);
    const hideExamForOD = useCare1AppSelector(store => store.examData.hide_exam_for_od);

    // Redux and React Router Hooks
    const dispatch = useCare1AppDispatch();
    const params = useParams();
    const history = useNavigate();

    // send edit exam request when exam page first renders
    const editExam = useCallback(() => {
        const exam_id = Number(params[Constants.LATEST_EXAM_ID_FIELD]) || null;
        if(exam_id !== null){
            dispatch(editExamRequest(exam_id, history));
        }
        if (hideExamForOD) {
            Modal.info({
                className: 'info-modal',
                title: Constants.UNAUTHORIZED_MODAL_TITLE,
                content: Constants.UNAUTHORIZED_MODAL_CONTENT,
                onOk: () => {
                   history(Constants.ROOT_URL)
                },
            });
        }
    }, [params, history, dispatch, hideExamForOD])

    useEffect(() => {
        editExam();
    }, [editExam])

    // RQ: If exam status is ready or reviewed, then components should be ready only.
    const isOMDReadyOrReviewed = examStatusPrevious === 'ready' || examStatusPrevious === 'reviewed';

    return (
        <Layout className="re-patient-exam-page">
            {showDetails && <PatientDetailsModal />}
            <Spin
                className="loading-spinner"
                spinning={examOperating || patientDetailsOperating}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Header>
                    <NavigationTopBar className="navigation-top-bar" />
                    <NavigationControlBar
                        className="re-pei-navigation-control-bar"
                        breadcrumbItems={[{ key: 1, component: <PatientExamPreviousVisits /> }]}
                    />
                </Header>
                <Content>
                    <FormValidationProvider>
                        <div className="re-patient-exam-page-pei">
                            <PatientEditModal />
                            <HelpDeskModal />
                            <PlaquenilModal />
                            <ExamDateSelectModal />
                            <Row>
                                <PatientExamHeader />
                            </Row>
                            <Row gutter={8}>
                                <Col span={8}>
                                    <PatientExamFundus
                                        tabbed={false}
                                        disabled={isOMDReadyOrReviewed}
                                        className="patient-exam-fundus"
                                        side={Constants.RIGHT_SIDE_TEXT}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Row>
                                        <PatientExamGPReferred
                                            disabled={isOMDReadyOrReviewed}
                                            className="patient-exam-gp-referred"
                                        />
                                    </Row>
                                    <Row>
                                        <PatientExamChiefComplaint disabled={isOMDReadyOrReviewed} displayInfo={false} />
                                    </Row>
                                    <Row>
                                        <Card className={'patient-exam-re-bcva'} bordered={false}>
                                            <Row><label className="heading">{'BCVA:'}</label></Row>
                                            <Row gutter={8}>
                                                <Col className="bcvaRight" span={12}>
                                                    <Row>
                                                        <Col span={9}><label className="heading">{'Right Eye:'}</label></Col>
                                                        <Col span={15}>
                                                            <PatientExamBCVA side='od' disabled={isOMDReadyOrReviewed}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="bcvaLeft" span={12}>
                                                    <Row>
                                                        <Col span={9}><label className="heading left-eye">{'Left Eye:'}</label></Col>
                                                        <Col span={15}>
                                                            <PatientExamBCVA side='os' disabled={isOMDReadyOrReviewed}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                    <Row>
                                        <Card className="empty-card" bordered={false} />
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    <PatientExamFundus
                                        tabbed={false}
                                        disabled={isOMDReadyOrReviewed}
                                        className="patient-exam-fundus"
                                        side={Constants.LEFT_SIDE_TEXT}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={8}>
                                    <PatientExamOCTMacula
                                        dropText={Constants.OCT_MACULA_PHOTO_DROP_TEXT}
                                        disabled={isOMDReadyOrReviewed}
                                        side={Constants.RIGHT_SIDE_TEXT}
                                        tabbed={false}
                                    />
                                </Col>
                                <Col span={8}>
                                    <PatientExamHistoryAndExam disabled={isOMDReadyOrReviewed}/>
                                </Col>
                                <Col span={8}>
                                    <PatientExamOCTMacula
                                        dropText={Constants.OCT_MACULA_PHOTO_DROP_TEXT}
                                        disabled={isOMDReadyOrReviewed}
                                        side={Constants.LEFT_SIDE_TEXT}
                                        tabbed={false}
                                    />
                                </Col>
                            </Row>
                            <PatientExamExtraImages
                                disabled={isOMDReadyOrReviewed}
                                className="patient-exam-extra-images"
                            />
                            <Row gutter={8}>
                                <Col span={6}>
                                    <PatientExamImageHistory
                                        className="patient-exam-image-history"
                                        side={Constants.RIGHT_SIDE_TEXT}
                                        imageCount={4}
                                        switched={false}
                                    />
                                </Col>
                                <Col span={12}>
                                    <PatientExamConversation />
                                </Col>
                                <Col span={6}>
                                    <PatientExamImageHistory
                                        className="patient-exam-image-history"
                                        switched
                                        side={Constants.LEFT_SIDE_TEXT}
                                        imageCount={4}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <br />
                                <br />
                            </Row>
                        </div>
                    </FormValidationProvider>
                </Content>
            </Spin>
        </Layout>
    );
};

export default REPatientExamPage;