import React, { RefObject } from "react";
import GptMessage from "./gpt-message";
import GptInput from "./gpt-input";
import '../../../../static/css/components/admin-gpt.scss';

interface Message {
    input: string;
    output: string;
    meta: string;
}

interface GptUIProps {
    messageHistory: Message[];
    inputMessage: string;
    setInputMessage: React.Dispatch<React.SetStateAction<string>>;
    sendMessage: () => void;
    formatMessageContent: (content: string) => string;
    isAssistantTyping: boolean;
    messagesEndRef: RefObject<HTMLDivElement>;
}

const GptUI: React.FC<GptUIProps> = ({
    messageHistory,
    inputMessage,
    setInputMessage,
    sendMessage,
    formatMessageContent,
    isAssistantTyping,
    messagesEndRef,
}) => {
    return (
        <div className="chat-ui">
            <GptInput
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
                sendMessage={sendMessage}
            />
            <div className="chat-end" ref={messagesEndRef}></div>
            <div className="chat-messages">
                {messageHistory.map((message, index) => (
                    <GptMessage
                        key={index}
                        message={message}
                        isAssistantTyping={isAssistantTyping}
                        formatMessageContent={formatMessageContent}
                    />
                ))}
            </div>
        </div>
    );
};

export default GptUI;
