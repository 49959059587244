import { Modal, Spin, Alert } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/macula-abnormality.scss';
import MaculaAbnormalityForm from './macula-abnormality-form';
import { closeModal } from '../../reducers/macula-abnormality-slice';


const MaculaAbnormalityModal = () => {

    const dispatch = useCare1AppDispatch();

    const apiStatus = useCare1AppSelector(store => store.maculaAbnormality.api_status);
    const showModal = useCare1AppSelector(store => store.maculaAbnormality.show_modal);

    const examId = useCare1AppSelector(store => store.maculaAbnormality.edit_exam_id);
    const situationalAwarenessUiStates = useCare1AppSelector(store => store.maculaAbnormality.edit_situational_awareness_ui_states);

    const rightFundus = useCare1AppSelector(store => store.maculaAbnormality.edit_right_fundus_photo);
    const leftFundus = useCare1AppSelector(store => store.maculaAbnormality.edit_left_fundus_photo);

    const handleModalClose = () => {
        dispatch(closeModal());
    };

    if (situationalAwarenessUiStates === undefined) {
        return null;
    }
    else {
        return (
            <Modal
                className='macula-abnormality-modal'
                open={showModal}
                closable={true}
                footer={null}
                keyboard={true}
                onCancel={handleModalClose}
            >
                <Spin
                    className='loading-spinner'
                    size='large'
                    spinning={apiStatus === 'loading'}
                >
                    {apiStatus === 'loading' &&
                        <Alert
                            className='analyze-in-progress-info'
                            message="Analyze in progress"
                            type="info"
                        />
                    }
                    <MaculaAbnormalityForm
                        examId={examId}
                        rightFundus={rightFundus}
                        leftFundus={leftFundus}
                        situationalAwarenessUiStates={situationalAwarenessUiStates}
                    />
                </Spin>
            </Modal>
        );
    }
}

export default MaculaAbnormalityModal;