import React, { useState } from 'react';
import { Layout, Spin, Modal } from 'antd';
import NavigationControlBar from '../components/navigation-control-bar';
import AdminVfNearMissListTable from '../components/admin/admin-vf-near-miss-table';
import AdminGptChatNearMissTable from '../components/admin/admin-gpt-chat-near-miss-table';
import * as Constants from '../constants';
import '../../static/css/pages/admin-vf-near-miss-page.scss'
import { useGetVfNearMissListQuery, useGetVfNearMissSummaryQuery } from '../services/admin-vf-near-miss-api';

const { Header, Content } = Layout;

const AdminVfNearMissPage: React.FC = () => {

    const { data: vfNearMissData = { near_miss_list: [] }, isFetching: vfNearMissIsFetching } = useGetVfNearMissListQuery();

    const {
        data: gptChatNearMissData = {
            near_miss_list: {
                summary: {
                    rows: [],
                    days: [],
                },
            },
        },
        isFetching: gptChatNearMissIsFetching,
    } = useGetVfNearMissSummaryQuery();

    return (
        <Layout className='admin-vf-near-miss-page'>
            <Header>
                <NavigationControlBar
                    className="patient-list-navigation-control-bar"
                    breadcrumbItems={[]}
                />
            </Header>
            <Spin
                className="loading-spinner"
                spinning={gptChatNearMissIsFetching}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Content>
                    <div style={{padding: '1rem'}}>
                        <AdminGptChatNearMissTable
                            tableList={gptChatNearMissData.near_miss_list.summary.rows}
                            days={gptChatNearMissData.near_miss_list.summary.days}
                            title="VF GPT Vision Accuracy"
                        />
                    </div>
                </Content>
            </Spin>
            <Spin
                className="loading-spinner"
                spinning={vfNearMissIsFetching}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Content>
                    <div style={{padding: '1rem'}}>
                        <AdminVfNearMissListTable
                            tableList={vfNearMissData.near_miss_list}
                        />
                    </div>
                </Content>
            </Spin>
        </Layout>
    );
}

export default AdminVfNearMissPage;