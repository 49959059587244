import { Card, Col, Row } from "antd";
import { localizedText } from "../../localizedText";
import PatientExamIOPGraph from "./patient-exam-iop-graph";
import PatientExamBCVAGraph from "./patient-exam-bcva-graph";

// default page should be main exam PEI page
const IopBcvaGraphBand = ({page = 'main_page'} : {page?: string}) => {

    const { BCVA_GRAPH_TEXT, IOP_GRAPH_TEXT, DARK_BLUE_DTC_RANGE } = localizedText;

    return (
        <Row gutter={8}>
            <Col span={6}>
                <Card className="patient-exam-iop-graph" bordered={false}>
                    <div className="heading">{`${IOP_GRAPH_TEXT} OD (${DARK_BLUE_DTC_RANGE})`}</div>
                    <Row>
                        <PatientExamIOPGraph side={'od'} page={page} />
                    </Row>
                </Card>
            </Col>
            <Col span={6}>
                <Card className="patient-exam-bcva-graph" bordered={false}>
                    <div className="heading">{`${BCVA_GRAPH_TEXT} OD`}</div>
                    <Row>
                        <PatientExamBCVAGraph side={'od'} page={page} />
                    </Row>
                </Card>
            </Col>
            <Col span={6}>
                <Card className="patient-exam-bcva-graph" bordered={false}>
                    <div className="heading">{`${BCVA_GRAPH_TEXT} OS`}</div>
                    <Row>
                        <PatientExamBCVAGraph side={'os'} page={page} />
                    </Row>
                </Card>
            </Col>
            <Col span={6}>
                <Card className="patient-exam-iop-graph" bordered={false}>
                    <div className="heading">{`${IOP_GRAPH_TEXT} OS (${DARK_BLUE_DTC_RANGE})`}</div>
                    <Row>
                        <PatientExamIOPGraph side={'os'} page={page} />
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}

export default IopBcvaGraphBand;
