import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import ProtectedRoute from '../components/protected-route';
import * as Constants from '../constants';
import PatientListPage from '../pages/patient-list-page';
import REPatientExamPage from '../pages/re-patient-exam-page';
import ICPatientExamPage from '../pages/ic-patient-exam-page';
import OMDCPatientExamPage from '../pages/omdc-patient-exam-page';
import OMDRPatientExamPage from '../pages/omdr-patient-exam-page';
import ADMINPatientExamPage from '../pages/admin-patient-exam-page';
import AdminAlertListsPage from '../pages/admin-alert-lists-page';
import AdminDoctorsListPage from '../pages/admin-doctors-list-page';
import AdminOctRnflNearMissPage from  '../pages/admin-oct-rnfl-near-miss-page';
import AdminVfNearMissPage from  '../pages/admin-vf-near-miss-page';
import AdminCsvNearMissPage from  '../pages/admin-csv-near-miss-page';
import AdminGptChatNearMissPage from  '../pages/admin-gpt-chat-near-miss-page';
import SciencePatientExamPage from '../pages/science-patient-exam-page';
import ResourcePage from '../pages/resource-page';
import SalesPage from '../pages/sales-page';
import SciencePage from '../pages/science-page';
import LoginPage from '../pages/login-page';
import ForceLogout from '../components/force-logout';
import ReviewProcessingReplyPage from '../pages/review-processing-reply-page';
import ServicesHealthCheckPage from '../pages/services-health-check-page';
import CheckedServicesPage from '../pages/checked-services-page';
import UploadReconcilerPage from '../pages/upload-reconciler-page';
import DeepMdBandScreenshotPage from '../pages/deep-md-band-screenshot-page';
import AdminPatientMergePage from '../pages/admin-patient-merge-page';
import PerformanceMetricsPage from '../pages/performance-metrics-page';

import CacheBuster from '../cacheBuster';

import { useCare1AppSelector } from './care1-hooks';
import AdminReportsDashboardPage from '../pages/admin-reports-dashboard-page';
import ODDashboardPage from '../pages/od-dashboard-page';
import ReviewProcessingPage from '../pages/review-processing-page';

const Care1ReactApp = () => {

    const isUserLoggedIn = useCare1AppSelector(store => store.user.isLoggedIn);
    const isOD = useCare1AppSelector(store => store.user.isOD);
    const isOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const isRetinaEnabled = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const isOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const isADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const isSALES = useCare1AppSelector(store => store.user.isSALES);
    const isSCIENCEUSER = useCare1AppSelector(store => store.user.isSCIENCEUSER);
    
    const patientListPageBasedOnUser = () => {
        // For now all users are directed to one patient list page where the table and bar are rendered based on the user.
        return <PatientListPage />
    }

    const examPageBasedOnUser = () => {

        let PEI = null;

        const user =
            isRetinaEnabled && isOD ? 'OD RE'
                : !isRetinaEnabled && isOD ? 'OD IC'
                    : isOMDC ? 'OMDC'
                        : isOMDR ? 'OMDR'
                            : isADMIN ? 'ADMIN'
                                : isSCIENCEUSER ? 'SCIENCE'
                                    : null;

        switch (user) {
            case 'OD RE':
                PEI = <REPatientExamPage />;
                break;
            case 'OD IC':
                PEI = <ICPatientExamPage />;
                break;
            case 'OMDC':
                PEI = <OMDCPatientExamPage />;
                break;
            case 'OMDR':
                PEI = <OMDRPatientExamPage />;
                break;
            case 'ADMIN':
                PEI = <ADMINPatientExamPage />;
                break;
            case 'SCIENCE':
                PEI = <SciencePatientExamPage />;
                break;
            default:
                break
        }

        return PEI;
    }

    return (
        <div className="care1-react-app">
            <ForceLogout />
            <BrowserRouter>
                <div>
                    <Routes>
                        {/* Anyone coming here and is not logged in needs to go to the login page. */}
                        <Route
                            path={Constants.LOGIN_URL}
                            element={
                                <CacheBuster>
                                    <LoginPage />
                                </CacheBuster>
                            }
                        />
                        {/* Logged In */}
                        <Route
                            path={Constants.ROOT_URL}
                            element={
                                (isSALES && 
                                <Navigate to={'/sales-dashboard'} replace />)
                                ||
                                (isSCIENCEUSER && 
                                <Navigate to={'/science-dashboard'} replace />)
                                ||
                                ((!isSALES && !isSCIENCEUSER )&&
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        {patientListPageBasedOnUser()}
                                    </CacheBuster>
                                </ProtectedRoute>)
                            }
                        />
                        <Route
                            path={`${Constants.PATIENT_EXAM_URL}/:${Constants.LATEST_EXAM_ID_FIELD}`}
                            element={
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        {examPageBasedOnUser()}
                                    </CacheBuster>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`/alert-lists`}
                            element={
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <AdminAlertListsPage />
                                    </CacheBuster>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`/doctors`}
                            element={
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <AdminDoctorsListPage />
                                    </CacheBuster>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`/reports-dashboard`}
                            element={
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <AdminReportsDashboardPage />
                                    </CacheBuster>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`/od-dashboard`}
                            element={
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <ODDashboardPage />
                                    </CacheBuster>
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path={`/patient-list`}
                            element={
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <PatientListPage />
                                    </CacheBuster>
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path={`/sales-dashboard`}
                            element={
                                ((!isSALES && !isADMIN) && 
                                <Navigate to={'/'} replace />)
                                ||
                                ((isSALES || isADMIN) &&
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <SalesPage />
                                    </CacheBuster>
                                </ProtectedRoute>)
                            }
                        />

                        <Route
                            path={`/science-dashboard`}
                            element={
                                ((!isSCIENCEUSER && !isADMIN) && 
                                <Navigate to={'/'} replace />)
                                ||
                                ((isSCIENCEUSER || isADMIN) &&
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <SciencePage />
                                    </CacheBuster>
                                </ProtectedRoute>)
                            }
                        />

                        <Route
                            path={`/oct-rnfl-near-miss`}
                            element={
                                ((!isADMIN && !isSCIENCEUSER) && 
                                <Navigate to={'/'} replace />)
                                ||
                                ((isADMIN || isSCIENCEUSER) &&
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <AdminOctRnflNearMissPage />
                                    </CacheBuster>
                                </ProtectedRoute>)
                            }
                        />

                        <Route
                            path={`/vf-near-miss`}
                            element={
                                ((!isADMIN && !isSCIENCEUSER) && 
                                <Navigate to={'/'} replace />)
                                ||
                                ((isADMIN || isSCIENCEUSER) &&
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <AdminVfNearMissPage />
                                    </CacheBuster>
                                </ProtectedRoute>)
                            }
                        />

                        <Route
                            path={`/exam-metrics-near-miss`}
                            element={
                                ((!isADMIN && !isSCIENCEUSER) && 
                                <Navigate to={'/'} replace />)
                                ||
                                ((isADMIN || isSCIENCEUSER) &&
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <AdminGptChatNearMissPage />
                                    </CacheBuster>
                                </ProtectedRoute>)
                            }
                        />

                        <Route
                            path={`/csv-near-miss`}
                            element={
                                ((!isADMIN && !isSCIENCEUSER) && 
                                <Navigate to={'/'} replace />)
                                ||
                                ((isADMIN || isSCIENCEUSER) &&
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <AdminCsvNearMissPage />
                                    </CacheBuster>
                                </ProtectedRoute>)
                            }
                        />

                        <Route
                            path={`/admin-patient-merge`}
                            element={
                                ((!isADMIN) && 
                                <Navigate to={'/'} replace />)
                                ||
                                ((isADMIN) &&
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <AdminPatientMergePage />
                                    </CacheBuster>
                                </ProtectedRoute>)
                            }
                        />

                        
                        <Route
                            path={`/review-processing`}
                            element={
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <ReviewProcessingPage />
                                    </CacheBuster>
                                </ProtectedRoute>
                            }
                        />       
                        <Route
                            path={`/resource-library`}
                            element={
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <ResourcePage />
                                    </CacheBuster>
                                </ProtectedRoute>
                            }
                        />       
                        <Route
                            path={`/review-processing-reply/:guid/`}
                            element={
                                <ReviewProcessingReplyPage />
                            }
                        />      
                        <Route 
                            path={`/deep-md-band-screenshot/:${Constants.LATEST_EXAM_ID_FIELD}`}
                            element={
                                <DeepMdBandScreenshotPage />
                            }
                        />
                        <Route
                            path={`/services-health-check`}
                            element={
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <ServicesHealthCheckPage />
                                    </CacheBuster>
                                </ProtectedRoute>
                            }
                        />        
                        <Route
                            path={`/performance-metrics`}
                            element={
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <PerformanceMetricsPage />
                                    </CacheBuster>
                                </ProtectedRoute>
                            }
                        />       
                        
                        <Route
                            path={`/checked-services/:pk/`}
                            element={
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <CheckedServicesPage />
                                    </CacheBuster>
                                </ProtectedRoute>
                            }
                        />        
                        <Route
                            path={`upload-reconciler/:smart_upload_id`}
                            element={
                                <ProtectedRoute
                                    redirectPath={Constants.LOGIN_URL}
                                    isUserLoggedIn={isUserLoggedIn}
                                >
                                    <CacheBuster>
                                        <UploadReconcilerPage />
                                    </CacheBuster>
                                </ProtectedRoute>
                            }
                        />   
                    </Routes>
                </div>
            </BrowserRouter>
        </div>
    );
}

export default Care1ReactApp;
