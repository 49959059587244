import React from 'react';
import { Layout, Spin } from 'antd';
import { useCare1AppSelector } from '../apps/care1-hooks';
import { useParams, useNavigate } from 'react-router';

import { OPERATING_TEXT } from '../constants';

import NavigationControlBar from '../components/navigation-control-bar';
import AdminCheckedServicesTable from '../components/admin/admin-checked-services-table';
import { useGetCheckedServicesLogQuery } from '../services/admin-checked-services-api';

import '../../static/css/pages/checked-services-page.scss';

const { Header, Content } = Layout;

const CheckedServicesPage: React.FC = () => {

    const params = useParams();

    const pk = Number(params["pk"])

    const { data: data = {
        log_list: {
            rows: [],
            detail: {
                service_name: ''
            }
        }
    }, isFetching: isFetching } = useGetCheckedServicesLogQuery(pk);
    
    const getTabCheckedServicesLog = () => {
        return (
        <div style={{paddingTop: '1rem'}}>
            <AdminCheckedServicesTable
                tableList={data.log_list.rows}
                detail={data.log_list.detail}
            />
        </div>);
    }
    
    return (
        <Layout className='checked-services-page'>
            <Spin
                className="loading-spinner"
                spinning={isFetching}
                size="large"
                tip={OPERATING_TEXT}
            >
                <Header>
                    <NavigationControlBar
                        className="patient-list-navigation-control-bar"
                        breadcrumbItems={[]}
                    />
                </Header>
                <Content>
                    <div>
                        {getTabCheckedServicesLog()}
                    </div>
                </Content>
            </Spin>
        </Layout>
    );
}

export default CheckedServicesPage;