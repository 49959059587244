import React, { useEffect, useState } from 'react';
import { Row, Col, Layout, Spin, DatePicker, Button, ConfigProvider } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import UploadReportTable from '../../components/sales/upload-report-table';
import {
    IUploadReportItem,
    useLazyGetUploadReportPerExamTypeByMonthListQuery,
    useLazyGetUploadReportPerOmdTypeByMonthListQuery,
    useLazyGetUploadReportPerOmdByMonthListQuery
} from '../../services/upload-report-api';
import { getUploadReportExcelRequest } from '../../reducers/sales-integration-report-slice';

import '../../../static/css/pages/sales-page.scss';
import '../../../static/css/shared/date-picker.scss';

import * as Constants from '../../constants';

const { Header, Content } = Layout;

const { RangePicker } = DatePicker;

const SalesPageUploadReportTab: React.FC = () => {

    const dispatch = useCare1AppDispatch();

    const [getUploadReportPerExamTypeByMonthList, { data: perExamTypeData, isFetching: isExamTypeFetching }] = useLazyGetUploadReportPerExamTypeByMonthListQuery();
    const [getUploadReportPerOmdTypeByMonthList, { data: perOmdTypeData, isFetching: isOmdTypeFetching }] = useLazyGetUploadReportPerOmdTypeByMonthListQuery();
    const [getUploadReportPerOmdByMonthList, { data: perOmdData, isFetching: isOmdFetching }] = useLazyGetUploadReportPerOmdByMonthListQuery();

    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();

    const handleDateChange = (values: any, formatString: [string, string]) => {
        setStartDate(formatString[0]);
        setEndDate(formatString[1]);
    }

    const defaultEndDate = dayjs().format('YYYY-MM-DD');
    const defaultStartDate = dayjs().subtract(5, 'month').format('YYYY-MM-DD');

    // Set default values as initial values.
    if (!startDate && !endDate) {
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
    }

    useEffect(() => {
        if (startDate && endDate) {
            getUploadReportPerExamTypeByMonthList({ startDate, endDate });
        }

        if (startDate && endDate) {
            getUploadReportPerOmdTypeByMonthList({ startDate, endDate });
        }

        if (startDate && endDate) {
            getUploadReportPerOmdByMonthList({ startDate, endDate });
        }
    }, [dispatch, startDate, endDate])

    const getLastUpdated = (date: string | undefined) => {
        if (date === '') {
            return '';
        }
        else {
            return `Last updated: ${date} PST`;
        }
    }

    const handleSalesReportDownload = (_event: any): void => {
        if (startDate && endDate) {
            dispatch(getUploadReportExcelRequest({
                start_date: startDate as string,
                end_date: endDate as string,
            }));
        }
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    DatePicker: {
                        cellActiveWithRangeBg: "#283747",
                        cellHoverWithRangeBg: "#283747"
                    },
                },
            }}>
            <div>
                <Row className='reportFilter'>
                    <Col>
                        <RangePicker
                            popupClassName='sales-page'
                            picker="month"
                            value={[dayjs(startDate), dayjs(endDate)]}
                            onChange={handleDateChange}
                            format="YYYY-MM"
                        />
                    </Col>
                    <Col>
                        <Button
                            className={'download-button'}
                            onClick={handleSalesReportDownload}
                            type="primary"
                            size='small'
                        >
                            Download
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <div className="caseUploadStatus">
                        <Col className='titleWrapper'>
                            <span className="list-title">Upload Breakdown Per Exam Type</span>
                            <span className="last-updated">{getLastUpdated(perExamTypeData?.last_updated)}</span>
                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col span={24}>
                        <Spin
                            className="loading-spinner"
                            spinning={isExamTypeFetching}
                            size="large"
                            tip={Constants.OPERATING_TEXT}
                        >
                            <UploadReportTable
                                uploadReport={perExamTypeData?.report}
                                uploadMonthColumn={perExamTypeData?.month_column}
                                colName={perExamTypeData?.col_name}
                            />
                        </Spin>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className='v-space'></Col>
                </Row>
                <Row>
                    <div className="caseUploadStatus">
                        <Col className='titleWrapper'>
                            <span className="list-title">Upload Breakdown By OMD Type</span>
                            <span className="last-updated">{getLastUpdated(perOmdTypeData?.last_updated)}</span>
                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col span={24}>
                        <Spin
                            className="loading-spinner"
                            spinning={isOmdTypeFetching}
                            size="large"
                            tip={Constants.OPERATING_TEXT}
                        >
                            <UploadReportTable
                                uploadReport={perOmdTypeData?.report}
                                uploadMonthColumn={perOmdTypeData?.month_column}
                                colName={perOmdTypeData?.col_name}
                            />
                        </Spin>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className='v-space'></Col>
                </Row>
                <Row>
                    <div className="caseUploadStatus">
                        <Col className='titleWrapper'>
                            <span className="list-title">Upload Breakdown By OMD</span>
                            <span className="last-updated">{getLastUpdated(perOmdData?.last_updated)}</span>
                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col span={24}>
                        <Spin
                            className="loading-spinner"
                            spinning={isOmdFetching}
                            size="large"
                            tip={Constants.OPERATING_TEXT}
                        >
                            <UploadReportTable
                                uploadReport={perOmdData?.report}
                                uploadMonthColumn={perOmdData?.month_column}
                                colName={perOmdData?.col_name}
                            />
                        </Spin>
                    </Col>
                </Row>
            </div>
        </ConfigProvider>);
}

export default SalesPageUploadReportTab;