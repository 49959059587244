import { Fragment } from 'react';
import { Card, Col } from 'antd';
import * as Constants from '../../constants';

// Components
import HistoryImagesViewAllModal from './history-images-view-all-modal';
import HistoryImagesSide from './history-images-side';

// Actions
import { lengthEqual } from '../../reducers/patient-exam-slice';

// Helpers
import { getImageHistoryColWidth, getImageHistoryImagesPerRow, getImageHistoryImagesMaxCels,
    } from '../../helpers/image-histories-convert';
import '../../../static/css/shared/images-history-card.scss';
import '../../../static/css/components/patient-exam-vf-history.scss';
import { localizedText } from '../../localizedText';
import { useCare1AppSelector } from '../../apps/care1-hooks';

// Used for displaying the first row baseline testing followed by three rows
// of oldest to newest ending with the most recent image.
const PatientExamVFImageHistory = () => {
    const { BASELINE_TESTING_IMAGE_LABEL, LEFT_VF_PHOTO_TITLE_TEXT, RIGHT_VF_PHOTO_TITLE_TEXT } = localizedText;
    const examsWithLeftVF = useCare1AppSelector(store => store.examData.exams_with_left_vf, lengthEqual);
    const examsWithRightVF = useCare1AppSelector(store => store.examData.exams_with_right_vf, lengthEqual);

    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);

    // Different platforms have different column widths, images per row and total images displayed
    const COL_WIDTH = getImageHistoryColWidth(userIsRE, userIsOD, userIsOMDC, userIsOMDR, userIsADMIN);
    const IMAGES_PER_ROW = getImageHistoryImagesPerRow(userIsRE, userIsOD, userIsOMDC, userIsOMDR, userIsADMIN);
    const MAX_DISPLAY_CELLS = getImageHistoryImagesMaxCels(userIsRE, userIsOD, userIsOMDC, userIsOMDR, userIsADMIN);

    return (
        <Fragment>
            {/* Left and Right view all Modals */}
            <HistoryImagesViewAllModal
                side={'right'}
                modalTitle={`VIEW ALL VF OD`}
                imageType={Constants.OCT_VF_TEXT}
                photoURL={`right_vf_photo`}
                images={[...examsWithRightVF]}
                colWidth={COL_WIDTH}
            />

            <HistoryImagesViewAllModal
                side={'left'}
                modalTitle={`VIEW ALL VF OS`}
                imageType={Constants.OCT_VF_TEXT}
                photoURL={`left_vf_photo`}
                images={[...examsWithLeftVF]}
                colWidth={COL_WIDTH}
            />

            {/* Right side history images */}
            <Col span={12}>
                <Card
                    className={`patient-exam-vf-history images-history-card${
                        examsWithRightVF.length === 0 && examsWithLeftVF.length === 0 ? ` no-images` : ''}`}
                    bordered={false}
                >
                    <div className="heading image-section-heading">
                        {RIGHT_VF_PHOTO_TITLE_TEXT}
                        <span className='baseline-label'>{BASELINE_TESTING_IMAGE_LABEL}</span>
                    </div>
                    <HistoryImagesSide
                        side={`right`}
                        imageType={Constants.OCT_VF_TEXT}
                        photoURL={'right_vf_photo'}
                        leftImages={[...examsWithLeftVF]}
                        rightImages={[...examsWithRightVF]}
                        colWidth={COL_WIDTH}
                        imagesPerRow={IMAGES_PER_ROW}
                        maxDisplayCells={MAX_DISPLAY_CELLS}
                        viewAllButtonText={`VIEW ALL VF OD`}
                        orderBy='asc'
                        showBaselineBorder={true}
                        showEmptyRow={true}
                    />
                </Card>
            </Col>

            {/* Left side history images */}
            <Col span={12}>
                <Card
                    className={`patient-exam-vf-history images-history-card${
                        examsWithRightVF.length === 0 && examsWithLeftVF.length === 0 ? ` no-images` : ''}`}
                    bordered={false}
                >
                    <div className={`heading image-section-heading`}>
                        {LEFT_VF_PHOTO_TITLE_TEXT}
                        <span className='baseline-label'>{BASELINE_TESTING_IMAGE_LABEL}</span>
                    </div>
                    <HistoryImagesSide
                        side={`left`}
                        imageType={Constants.OCT_VF_TEXT}
                        photoURL={'left_vf_photo'}
                        leftImages={[...examsWithLeftVF]}
                        rightImages={[...examsWithRightVF]}
                        colWidth={COL_WIDTH}
                        imagesPerRow={IMAGES_PER_ROW}
                        maxDisplayCells={MAX_DISPLAY_CELLS}
                        viewAllButtonText={`VIEW ALL VF OS`}
                        orderBy='asc'
                        showBaselineBorder={true}
                        showEmptyRow={true}
                    />
                </Card>
            </Col>
        </Fragment>
    );
}

export default PatientExamVFImageHistory;