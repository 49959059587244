import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { convertAdminPatientsToReviewResponseToTableData, convertAdminOMDCStatusList, convertAdminIGPStatusList,
    convertOMDRPatientsToReviewResponseToTableData,
    } from '../helpers/patients-to-review-convert';
import { getCsrfToken } from '../helpers/utilities';
import { apiRequest } from '../services/api-request';
import { Modal } from 'antd';
import { logout } from './user-slice';


// Interfaces for typing initialState for adminReviewLists Redux store
export interface IPatientsToReviewListItem {
    category: string,
    patients_to_review: number | string,
    pre_review_alerts: number | null,
    key: number | string | bigint,
}
const emptyPatientsToReviewData: IAdminPatientsToReviewTable = {
    all_ic_exams: 0,
    all_ic_exams_od_orientating: 0,
    all_ic_exams_od_special_attention: 0,
    retina_exams: 0,
    retina_exams_od_orientating: 0,
    retina_exams_od_special_attention: 0,
    glaucoma_exams: 0,
    glaucoma_exams_od_orientating: 0,
    glaucoma_exams_od_special_attention: 0,
    other_exams: 0,
    other_exams_od_orientating: 0,
    other_exams_od_special_attention: 0,
    all_ic_ref_exams: 0,
    all_ic_ref_exams_od_orientating: 0,
    all_ic_ref_exams_od_special_attention: 0,
    all_ipc_ref_exams: 0,
    all_ipc_ref_exams_od_orientating: 0,
    all_ipc_ref_exams_od_special_attention: 0,
    all_high_priority_ref_exams: 0,
    all_high_priority_ref_exams_od_orientating: 0,
    all_high_priority_ref_exams_od_special_attention: 0,
    all_other_ref_exams: 0,
    all_other_ref_exams_od_orientating: 0,
    all_other_ref_exams_od_special_attention: 0,
    all_od_messaging_exams: 0,
    all_od_messaging_exams_od_orientating: 0,
    all_od_messaging_exams_od_special_attention: 0,
    all_outbound_ref_exams: 0,
    all_outbound_ref_exams_od_orientating: 0,
    all_outbound_ref_exams_od_special_attention: 0,
    no_od_assigned_exams: 0,
}
export interface IAdminReviewLists {
    patientsToReviewLoading: boolean,
    patientsToReviewAdminLoading: boolean,
    omdcStatusLoading: boolean,
    igpStatusLoading: boolean,
    patientsToReviewList: IPatientsToReviewListItem [],
    patientsToReviewAdmin: IAdminPatientsToReviewTable,
    standardizedReviewPointsList: IStandardizedReviewPointsList [],
    omdcStatusList: IOMDCStatusListItem [],
    igpStatusList: IIGPStatusListItem [],
    totalReviewMinutes: string | number,
}
const initialState: IAdminReviewLists = {
    patientsToReviewLoading: false,
    patientsToReviewAdminLoading: false,
    omdcStatusLoading: false,
    igpStatusLoading: false,
    patientsToReviewList: [],
    patientsToReviewAdmin: emptyPatientsToReviewData,
    standardizedReviewPointsList: [],
    omdcStatusList: [],
    igpStatusList: [],
    totalReviewMinutes: 0,
}

// Interface for typing the response object for the getAdminPatientsToReviewDataRequest
export type TypeKeyNumberValueNumber  = {
    [key: number] : number
}
export interface IAdminPatientsToReviewList  {
    bdbvf_list: TypeKeyNumberValueNumber,
    bdgvf_list: TypeKeyNumberValueNumber,
    gdbvf_list: TypeKeyNumberValueNumber,
    gdgvf_list: TypeKeyNumberValueNumber,
    glcbo_list: TypeKeyNumberValueNumber,
    glcbt_list: TypeKeyNumberValueNumber,
    glcp_list: TypeKeyNumberValueNumber,
    retina1_list: TypeKeyNumberValueNumber,
    retina2_list: TypeKeyNumberValueNumber,
    special_list: TypeKeyNumberValueNumber,
    bdbvfListSRP: TypeKeyNumberValueNumber,
    bdbvf_list_palert_count: number,
    bdgvfListSRP: TypeKeyNumberValueNumber,
    bdgvf_list_palert_count: number,
    gdbvfListSRP: TypeKeyNumberValueNumber,
    gdbvf_list_palert_count: number,
    gdgvfListSRP: TypeKeyNumberValueNumber,
    gdgvf_list_palert_count: number,
    glcboListSRP: TypeKeyNumberValueNumber,
    glcbo_list_palert_count: number,
    glcbtListSRP: TypeKeyNumberValueNumber,
    glcbt_list_palert_count: number,
    glcpListSRP: TypeKeyNumberValueNumber,
    glcp_list_palert_count: number,
    retina1ListSRP: TypeKeyNumberValueNumber,
    retina1_list_palert_count: number,
    retina2ListSRP: TypeKeyNumberValueNumber,
    retina2_list_palert_count: number,
    specialListSRP: TypeKeyNumberValueNumber,
    special_list_palert_count: number,
    total_exams_to_prereview: number,
    total_palert_count: number,
}

interface IAdminPatientsToReviewResponse {
    patients_to_review: IAdminPatientsToReviewList
}

// Interface for typing the response object for the getOMDRPatientsToReviewDataRequest
export interface IOMDRPatientsToReviewList {
    bdbvf_list: number,
    bdgvf_list: number,
    gdbvf_list: number,
    gdgvf_list: number,
    glcbo_list: number,
    glcbt_list: number,
    glcp_list: number,
    retina1_list: number,
    retina2_list: number,
    special_list: number,
    summed_count: number,
    total_review_minutes: number,
    total_standardized_points: string,
}

export interface IOMDRPatientsToReviewResponse {
    patients_to_review_list: IOMDRPatientsToReviewList,
}

// Interface for typing the standardizedReviewPointsList
export interface IStandardizedReviewPointsList {
    omdr: string,
    srp: number,
    bdbvf?: number,
    gdbvf?: number,
    gdgvf?: number,
    bdgvf?: number,
    glcBO?: number,
    glcBT?: number,
    glcP?: number,
    retina1?: number,
    retina2?: number,
    specialAttention?: number,
    key: string | number | bigint,
}

// Interface for typing the OMDC status list
export interface IOMDCDataResponse {
    processed_last_six_months: TypeKeyNumberValueNumber,
    ready_cataracts: TypeKeyNumberValueNumber,
    ready_early_retina: TypeKeyNumberValueNumber,
    ready_glc_sus: TypeKeyNumberValueNumber,
    ready_total: TypeKeyNumberValueNumber,
    reviewed_cataracts: TypeKeyNumberValueNumber,
    reviewed_early_retina: TypeKeyNumberValueNumber,
    reviewed_glc_sus: TypeKeyNumberValueNumber,
    reviewed_total: TypeKeyNumberValueNumber,
}

export interface IOMDCStatusList {
    omdc_status_list: IOMDCDataResponse
}

export interface IAdminPatientsToReviewTableItem {
    key: React.Key,
    category: string;
    exams_in_queue_all: number;
    exams_in_queue_orientating: number;
    exams_in_queue_special_attention: number;
}

export interface IAdminPatientsToReviewTable {
    all_ic_exams: number,
    all_ic_exams_od_orientating: number,
    all_ic_exams_od_special_attention: number,
    retina_exams: number,
    retina_exams_od_orientating: number,
    retina_exams_od_special_attention: number,
    glaucoma_exams: number,
    glaucoma_exams_od_orientating: number,
    glaucoma_exams_od_special_attention: number,
    other_exams: number,
    other_exams_od_orientating: number,
    other_exams_od_special_attention: number,
    all_ic_ref_exams: number,
    all_ic_ref_exams_od_orientating: number,
    all_ic_ref_exams_od_special_attention: number,
    all_ipc_ref_exams: number,
    all_ipc_ref_exams_od_orientating: number,
    all_ipc_ref_exams_od_special_attention: number,
    all_high_priority_ref_exams: number,
    all_high_priority_ref_exams_od_orientating: number,
    all_high_priority_ref_exams_od_special_attention: number,
    all_other_ref_exams: number,
    all_other_ref_exams_od_orientating: number,
    all_other_ref_exams_od_special_attention: number,
    all_od_messaging_exams: number,
    all_od_messaging_exams_od_orientating: number,
    all_od_messaging_exams_od_special_attention: number,
    all_outbound_ref_exams: number,
    all_outbound_ref_exams_od_orientating: number,
    all_outbound_ref_exams_od_special_attention: number,
    no_od_assigned_exams: number,
}

export interface IAdminPatientsToReviewTableResponse {
    success: boolean;
    data: IAdminPatientsToReviewTable;
    error?: boolean;
}

// Interface for type an OMDCStatusList item
export interface IOMDCStatusListItem {
    omdc: string,
    reviewedTotal?: number,
    readyTotal?: number,
    readyCataracts?: number,
    readyEarlyRetina?: number,
    readyGlcSus?: number,
    reviewedCataracts?: number,
    reviewedEarlyRetina?: number,
    reviewedGlcSus?: number,
    processedLastSixMonths?: number,
    key: number | string | bigint,
}

// Interface for typing the IGP status list Response
export interface IIGPDataResponse {
    ready_total: TypeKeyNumberValueNumber,
    referred_last_twelve_months: TypeKeyNumberValueNumber,
    reviewed_total: TypeKeyNumberValueNumber,
}
export interface IIGPStatusList {
    igp_status_list: IIGPDataResponse,
}

// Interface for type an IGPStatusList item
export interface IIGPStatusListItem {
    igp: string,
    readyTotal?: number
    reviewedTotal?: number,
    referredTotal?: number,
    key: string | number | bigint,
}

// GET: Request the ADMIN patients to review data
export const getAdminPatientsToReviewDataRequest = createAsyncThunk(
    'adminReviewLists/getAdminPatientsToReviewData',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patients/admin_patients_to_review/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

// GET: Request the OMDR patients to review data
export const getOMDRPatientsToReviewDataRequest = createAsyncThunk(
    'adminReviewLists/getOMDRPatientsToReviewData',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patients/omdr_patients_to_review/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

// GET: Request the OMDC status data
export const getOMDCStatusDataRequest = createAsyncThunk(
    'adminReviewLists/getOMDCStatusData',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patients/admin_omdc_status_list/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

// GET: Request the IGP status data
export const getIGPStatusDataRequest = createAsyncThunk(
    'adminReviewLists/getIGPStatusData',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patients/admin_igp_status_list/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

// GET: Request the IGP status data
export const getPatientsToReviewAdminRequest = createAsyncThunk(
    'adminReviewLists/getPatientsToReviewAdminData',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/admin/patients_to_review_admin/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            console.log('response is ', response);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

export const adminReviewLists = createSlice({
    name: 'adminReviewLists',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // getAdminPatientsToReviewDataRequest
        builder.addCase(getAdminPatientsToReviewDataRequest.pending, (state) => {
            state.patientsToReviewLoading = true;
        });
        builder.addCase(getAdminPatientsToReviewDataRequest.fulfilled,
            (state, action: PayloadAction<IAdminPatientsToReviewResponse>) => {
            const patientsToReviewConverted : {
                patientsToReviewTableList : IPatientsToReviewListItem[],
                standardizedReviewPointsTableList: IStandardizedReviewPointsList[],
            } = convertAdminPatientsToReviewResponseToTableData(action.payload.patients_to_review);
            state.patientsToReviewLoading = false;
            state.patientsToReviewList = patientsToReviewConverted.patientsToReviewTableList;
            state.standardizedReviewPointsList = patientsToReviewConverted.standardizedReviewPointsTableList;

        });
        builder.addCase(getAdminPatientsToReviewDataRequest.rejected, (state, action) => {
            state.patientsToReviewLoading = false;
            Modal.error({
                className: 'info-modal',
                title: `Errors getting Admin Patients to Review data. ${action.payload}`,
            })
        });

        // getOMDRPatientsToReviewDataRequest
        builder.addCase(getOMDRPatientsToReviewDataRequest.pending, (state) => {
            state.patientsToReviewLoading = true;
        });
        builder.addCase(getOMDRPatientsToReviewDataRequest.fulfilled,
            (state, action: PayloadAction<IOMDRPatientsToReviewResponse>) => {
            const patientsToReviewConverted : {
                tableList : IPatientsToReviewListItem[],
                totalReviewMinutes: string,
            } = convertOMDRPatientsToReviewResponseToTableData(action.payload.patients_to_review_list);
            state.patientsToReviewLoading = false;
            state.patientsToReviewList = patientsToReviewConverted.tableList;
            state.totalReviewMinutes = patientsToReviewConverted.totalReviewMinutes;

        });
        builder.addCase(getOMDRPatientsToReviewDataRequest.rejected, (state, action) => {
            state.patientsToReviewLoading = false;
            Modal.error({
                className: 'info-modal',
                title: `Errors getting OMDR Patients to Review data. ${action.payload}`,
            })
        });

        // getOMDCStatusDataRequest
        builder.addCase(getOMDCStatusDataRequest.pending, (state) => {
            state.omdcStatusLoading = true;
        });
        builder.addCase(getOMDCStatusDataRequest.fulfilled,
            (state, action: PayloadAction<IOMDCStatusList>) => {
            const omdcStatusList : IOMDCStatusListItem[] = convertAdminOMDCStatusList(action.payload.omdc_status_list);
            state.omdcStatusLoading = false;
            state.omdcStatusList = omdcStatusList;
        });
        builder.addCase(getOMDCStatusDataRequest.rejected, (state, action) => {
            state.omdcStatusLoading = false;
            Modal.error({
                className: 'info-modal',
                title: `Errors getting OMDC status data. ${action.payload}`,
            })
        });

         // getIGPStatusDataRequest
         builder.addCase(getIGPStatusDataRequest.pending, (state) => {
            state.igpStatusLoading = true;
        });
        builder.addCase(getIGPStatusDataRequest.fulfilled,
            (state, action: PayloadAction<IIGPStatusList>) => {
            const igpStatusList : IIGPStatusListItem[] = convertAdminIGPStatusList(action.payload.igp_status_list);
            state.igpStatusLoading = false;
            state.igpStatusList = igpStatusList;
        });
        builder.addCase(getIGPStatusDataRequest.rejected, (state, action) => {
            state.igpStatusLoading = false;
            Modal.error({
                className: 'info-modal',
                title: `Errors getting IGP status data. ${action.payload}`,
            })
        });

        // getPatientsToReviewAdminDataRequest
        builder.addCase(getPatientsToReviewAdminRequest.pending, (state) => {
            state.patientsToReviewAdminLoading = true;
        });
        builder.addCase(getPatientsToReviewAdminRequest.fulfilled,
            (state, action: PayloadAction<IAdminPatientsToReviewTableResponse>) => {
            console.log('action paylaod is ', action.payload);
            state.patientsToReviewAdminLoading = false;
            state.patientsToReviewAdmin = action.payload.data;
        });
        builder.addCase(getPatientsToReviewAdminRequest.rejected, (state, action) => {
            state.patientsToReviewAdminLoading = false;
            Modal.error({
                className: 'info-modal',
                title: `Errors getting patients to review data. ${action.payload}`,
            })
        });
    }
});

export default adminReviewLists.reducer;