import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_REMOVE_EXTRA_IMG_URL } from '../constants';
import { getCsrfToken } from '../helpers/utilities';
import { apiRequest } from '../services/api-request';
import { logout } from './user-slice';

export interface IImage {
    photo: string;
    examId: number;
    csrfToken: string;
}

export interface IPdf {
    numPages: number;
    pageNumber: number;
    scale: number;
    isOpen: boolean;
}

export interface IExtraImageInitialState {
    operationRunning: boolean;
    lightboxOnForIndex: number;
    lastOperationType: string;
    viewAll: boolean;
    pdf: IPdf;
    error: string;
}

const initialState: IExtraImageInitialState = {
    operationRunning: false,
    lightboxOnForIndex: -1,
    lastOperationType: '',
    viewAll: false,
    pdf: {
        numPages: 1,
        pageNumber: 1,
        scale: 1,
        isOpen: false,
    },
    error: '',
};

export const deletePhoto = createAsyncThunk(
    'extraImage/deleteExtraPhoto',
    async (photo: string, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }
        const data = new FormData();
        data.append('path', photo);
        const URL = `${process.env.REACT_APP_BACKENDURL}${API_REMOVE_EXTRA_IMG_URL}`;
        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const extraImageSlice = createSlice({
    name: 'extraImage',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        toggleLightbox: (state, action: PayloadAction<number>) => {
            state.lightboxOnForIndex = state.lightboxOnForIndex === action.payload ? -1 : action.payload;
            state.lastOperationType = action.type;
        },
        togglePdfViewer: (state, action: PayloadAction<number>) => {
            state.lightboxOnForIndex = state.lightboxOnForIndex === action.payload ? -1 : action.payload;
            state.lastOperationType = action.type;
            state.pdf.isOpen = !state.pdf.isOpen;
        },
        viewAll: (state, action: PayloadAction<boolean>) => {
            state.viewAll = action.payload;
        },
        deletePhotoConfirm: (state, action: PayloadAction<string>) => {
            state.lastOperationType = action.type;
        },
        deletePhotoCancelled: (state, action: PayloadAction<string>) => {
            state.lastOperationType = action.type;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deletePhoto.pending, (state, action) => {
            state.operationRunning = true;
            state.lastOperationType = action.type;
            state.error = '';
        });
        builder.addCase(deletePhoto.fulfilled, (state) => {
            state.operationRunning = false;
            state.error = '';
        });
        builder.addCase(deletePhoto.rejected, (state, action) => {
            state.operationRunning = false;
            state.error = action.payload as string;
        });
        
    },
});

export const { deletePhotoCancelled, deletePhotoConfirm, toggleLightbox, togglePdfViewer, viewAll
    } = extraImageSlice.actions;

export default extraImageSlice.reducer;