import { useState } from 'react';
import { Modal, Button, DatePicker, Divider, Col, Row, Checkbox } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { hideReportModal, getAdminReportsExamVisitDateRequest,
    getAdminReportsExamCreateDateRequest, getAdminReportsLastOmdrReviewedDateRequest,
    getAdminReportsLastOmdReviewsDateRequest, getAdminReportsIntegrationListReportRequest,
    getAdminReportsBillingReportRequest, getAdminReportsIGPReportRequest
} from '../../reducers/admin-reports-slice';
import '../../../static/css/components/admin-reports-modal.scss';
import { localizedText } from '../../localizedText';


const AdminReportsModal = () => {
    const { START_TEXT, END_TEXT, ADMIN_REPORTS_MODAL_TEXT, EXAM_VISIT_DATE, 
        EXAM_CREATED_DATE, GET_REPORT_TEXT, LAST_OMDR_REVIEW_DATE, OMD_REVIEWS_TEXT, 
        INTEGRATION_LIST_UPLOADS, BILLING_REPORT_EXAM_TEXT } = localizedText;

    const isVisible = useCare1AppSelector(store => store.adminReports.showModal);

    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [reportType, setReportType] = useState<string>('');

    const dispatch = useCare1AppDispatch();

    const handleModalClose = () => {
        dispatch(hideReportModal());
    }

    const handleSubmit = () => {
        if (reportType === 'by_visit') {
            getAdminReportsExamVisitDateRequest(startDate, endDate);
        }
        else if (reportType === 'by_create') {
            getAdminReportsExamCreateDateRequest(startDate, endDate);
        }
        else if (reportType === 'by_review') {
            getAdminReportsLastOmdrReviewedDateRequest(startDate, endDate);
        }
        else if (reportType === 'omd_reviews') {
            getAdminReportsLastOmdReviewsDateRequest(startDate, endDate);
        }
        else if (reportType === 'integration_list_report') {
            getAdminReportsIntegrationListReportRequest(startDate, endDate);
        }
        else if (reportType === 'billing_report') {
            getAdminReportsBillingReportRequest(startDate, endDate);
        }
        else if (reportType === 'igp_report') {
            getAdminReportsIGPReportRequest(startDate, endDate);
        }

        dispatch(hideReportModal());
    }

    return (
        <Modal
            className="admin-reports-modal"
            open={isVisible}
            closable={true}
            onCancel={(e) => handleModalClose() }
        >
            <Row>
                <Col span={24}>
                    <div className="modal-title">
                       {ADMIN_REPORTS_MODAL_TEXT}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={2}>
                    <div className='date-label'>
                        {START_TEXT}:
                    </div>
                </Col>
                <Col span={10}>
                    <DatePicker
                        className='start-date'
                        onChange={((date, dateString) => { setStartDate(`${dateString}`); })}
                    />
                </Col>
                <Col span={2}>
                    <div className='date-label'>
                        {END_TEXT}:
                    </div>
                </Col>
                <Col span={10}>
                    <DatePicker
                        className='end-date'
                        onChange={((date, dateString) => { setEndDate(`${dateString}`); })}
                    />
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={7}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "by_visit"}
                        onChange={ (e) => { setReportType("by_visit"); }}
                    >
                    {EXAM_VISIT_DATE}
                    </Checkbox>
                </Col>
                <Col span={8}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "by_create"}
                        onChange={ (e) => { setReportType("by_create"); }}
                    >
                    {EXAM_CREATED_DATE}
                    </Checkbox>
                </Col>
                <Col span={9}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "by_review"}
                        onChange={ (e) => { setReportType("by_review"); }}
                    >
                    {LAST_OMDR_REVIEW_DATE}
                    </Checkbox>
                </Col>
            </Row>
            <Row>
                <Col span={7}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "omd_reviews"}
                        onChange={ (e) => { setReportType("omd_reviews"); }}
                    >
                    {OMD_REVIEWS_TEXT}
                    </Checkbox>
                </Col>
                <Col span={17}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "integration_list_report"}
                        onChange={ (e) => { setReportType("integration_list_report"); }}
                    >
                    {INTEGRATION_LIST_UPLOADS}
                    </Checkbox>
                </Col>
                </Row>
                <Row>
                <Col span={12}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "billing_report"}
                        onChange={ (e) => { setReportType("billing_report"); }}
                    >
                    {BILLING_REPORT_EXAM_TEXT}
                    </Checkbox>
                </Col>
                <Col span={12}>
                    <Checkbox
                        className='report-checkbox'
                        checked={ reportType === "igp_report"}
                        onChange={ (e) => { setReportType("igp_report"); }}
                    >
                        IGP (based on Last OMDR Reviewed Date)
                    </Checkbox>
                </Col>
            </Row>
            <div className='footer'>
                < Divider />
                <Button
                    className="get-report-button"
                    onClick={handleSubmit}
                >
                    {GET_REPORT_TEXT}
                </Button>
            </div>
        </Modal>
    );
}

export default AdminReportsModal;