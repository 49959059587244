import React, { useEffect, useState, useCallback } from 'react';
import { Modal } from 'antd';

import { DELETE_CONFIRMATION_MODAL_TITLE, DELETE_CONFIRMATION_MODAL_TEXT, DELETE_CONFIRMATION_OK_TEXT,
    DELETE_CONFIRMATION_CANCEL_TEXT, FILE_UPLOAD_FAILED_TEXT, API_UPLOAD_IMG_URL
} from '../../constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import TrashButton from '../../../static/images/trash_button.svg';
import '../../../static/css/components/photo-dropzone.scss';

// Helpers
import { photoDropzoneValidityCheck } from '../../helpers/patient-exam-convert';

// Components
import { DropzoneComponent } from 'react-dropzone-component';
import { dropzoneSlice } from '../../reducers/dropzone-slice';
import { retrievePhotoUrls } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

import Dropzone from 'dropzone';
import * as ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';
import PatientExamGpReferredPDFViewerModal from '../retina/patient-exam-gp-referred-pdf-viewer-modal';


type ComponentProps = {
    disabled: boolean,
    field: 'od_referral_letter',
}


const displayNone = { display: 'none' };

  
const ReferralLetterDropzone = ({ disabled, field}: ComponentProps) => {
    const dispatch = useCare1AppDispatch();
    const examId = useCare1AppSelector(store => store.examData.id);
    const currentPhotoURL = useCare1AppSelector(store => store.examData.od_referral_letter);
    const pastPhotoURL = useCare1AppSelector(store => store.examData.past_od_referral_letter);
    const pastExamDate = useCare1AppSelector(store => store.examData.past_od_referral_letter_date);
    const examIsODMessaging = useCare1AppSelector(store => store.examData.is_od_messaging_submission);
    const usePastPhoto = examIsODMessaging && !currentPhotoURL && pastPhotoURL;
    const lightBoxPhotoURL = usePastPhoto ? pastPhotoURL : currentPhotoURL;
    const dropzones = useCare1AppSelector(store => store.dropzones);
    const csrfToken = useCare1AppSelector(store => store.user.csrfToken);
    const [dropzoneObject, setDropzoneObject] = useState<Dropzone | null>(null);

    const dropzoneInstance = dropzoneSlice[field];

    const { deletePhotoCancelled, deletePhotoConfirm, retrievePhotoRequest, retrievePhotoSuccess, setUploadProgress, uploadPhotoFailure, uploadPhotoRequest, uploadPhotoSuccess} = dropzoneInstance.actions;

    const { deletePhoto } = dropzoneInstance;

    const [showLetterPdf, setShowLetterPdf] = useState(false);

    const acceptFileFormat = 'application/pdf,application/x-pdf';

    const componentConfig = { postUrl: `${process.env.REACT_APP_BACKENDURL}${API_UPLOAD_IMG_URL}` };

    const onInit = (dropzone: Dropzone) => {
        setDropzoneObject(dropzone);
    }

    const sendUploadPhotoRequest = (e: DragEvent) => {
        // Do not allow photo upload if component is disabled as read only
        if (!disabled) {

            const file = (e?.dataTransfer?.files.length) ? e.dataTransfer.files[0] : '';
            if (file) {
                try {
                    const ext = file.name.split('.').pop();
                    if (ext) {
                        if (acceptFileFormat.indexOf(ext.toLowerCase()) !== -1) {
                            
                            // Clear the current file. Sorry, yes I know this makes the class stateful, but this is required by the way
                            // the dropzone component works.
                            if (dropzoneObject) {
                                dropzoneObject.removeAllFiles();
                            }
    
                            dispatch(uploadPhotoRequest(file));
                        }
                    }
                }
                catch {
    
                }

            }
        }
    }

    const sendRetrievePhotoSuccess = (file: Dropzone.DropzoneFile, dataUrl: string) => {
        // Dispatch a retrieve photo success, since the fact that there is a thumbnail means the retrieve
        // succeeded.
        dispatch(retrievePhotoSuccess({photo: file, thumbnailUrl: dataUrl}));
    }

    const sendUploadProgress = (_: Dropzone.DropzoneFile, percentage: number) => {
        dispatch(setUploadProgress(percentage));
    }

    const sendUploadPhotoFailure = (file: Dropzone.DropzoneFile, requestError: string | Error) => {

        try {
            const ext = file.name.split('.').pop();
            if (ext) {
                if (acceptFileFormat.indexOf(ext.toLowerCase()) === -1) {

                    const message = 'This photo is not supported, please upload PDF file only.';
                    Modal.error({
                        className: 'info-modal',
                        content: message,
                        title: 'Photo not supported',
                    });      
                    return;
                }
            }
        }
        catch {

        }

        // If this is an error from a request, use the request status.
        let error;
        if (requestError) {
            error = FILE_UPLOAD_FAILED_TEXT;
        } else {
            error = 'Errors uploading photos';
        }

        dispatch(uploadPhotoFailure(error));
    }

    const sendUploadPhotoSuccess = (_: Dropzone.DropzoneFile, __: Object) => {
        dispatch(uploadPhotoSuccess());

        // Also retrieve the updated exam data after uploading successfully.
        dispatch(retrievePhotoUrls(examId!));
    }

    const eventHandlers = {
        init: onInit,
        drop: sendUploadPhotoRequest,
        thumbnail: sendRetrievePhotoSuccess,
        uploadprogress: sendUploadProgress,
        error: sendUploadPhotoFailure,
        success: sendUploadPhotoSuccess,
    };

    const sendDeletePhotoConfirm = () => {
        dispatch(deletePhotoConfirm());
        Modal.confirm({
            className: 'delete-confirmation-modal',
            title: DELETE_CONFIRMATION_MODAL_TITLE,
            content: DELETE_CONFIRMATION_MODAL_TEXT,
            icon: <InfoCircleOutlined />,
            okText: DELETE_CONFIRMATION_OK_TEXT,
            cancelText: DELETE_CONFIRMATION_CANCEL_TEXT,
            cancelButtonProps: { className: 'confirm-exit' },
            onCancel: () => {
                sendDeletePhotoCancelled();
            },
            onOk: () => {
                sendDeletePhotoRequest();
            },
        });
    }

    const sendDeletePhotoCancelled = () => {
        // Send the action to close the confirmation dialog without doing anything.
        dispatch(deletePhotoCancelled());
    }

    const sendDeletePhotoRequest = async () => {
        try {
            await dispatch(deletePhoto());
            dispatch(retrievePhotoUrls(examId!));
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors deleting photo',
            });
        }
    }


    /*** IMAGE RENDER LOGIC START ***/
    // The useCallback function allows sendRetrievePhotoRequest to be reusable in two uses:
    // 1. when the component mounts; 2. for every re-render caused by a change to the currentPhotoURL
    const sendRetrievePhotoRequest = useCallback(() => {
        // Disable dropzone image caching by the browser
        const photoUrl = currentPhotoURL ? `${currentPhotoURL}?r=${Math.random()}` : currentPhotoURL;

        // With a retrieve request, we're just letting the dropzone component get the photo from the backend using
        // the photo URL.
        if (photoUrl && dropzoneObject) {

            dropzoneObject.removeAllFiles();

            const urlNamePortions = photoUrl.split('/');
            const file = { name: urlNamePortions[urlNamePortions.length - 1] } as File;
            dispatch(retrievePhotoRequest(file));
        }
    }, [dispatch, dropzoneObject, currentPhotoURL, retrievePhotoRequest]);

    // This useEffect is triggered when component mounts and then again when the image changes
    useEffect(() => {
        sendRetrievePhotoRequest();
    }, [sendRetrievePhotoRequest]);
    /*** RENDER IMAGE LOGIC END ***/

    const hasPhoto = dropzones[field].photo !== '';
    const photoName = hasPhoto ? (dropzones[field].photo as File).name : '';
    const hasError = dropzones[field].error !== '';
    const errorMessage = hasError ? dropzones[field].error : '';
    const inProgress = dropzones[field].operationRunning;
    const progressPercentage = dropzones[field].percentage;
    const isValid = photoDropzoneValidityCheck(dropzones[field]);
   


    const djsConfig = {
        acceptedFiles: acceptFileFormat,
        clickable: false,
        createImageThumbnails: false,
        withCredentials: true,
        headers: { 'X-CSRFToken': csrfToken },
        maxFiles: disabled ? 0 : 1,
        paramName: field,
        params: { exam: examId },
        // Some of the divs in the preview area are needed by the react dropzone component, so they are permanently
        // hidden, but are not used by us.
        previewTemplate: ReactDOMServer.renderToStaticMarkup(
            <div className="dz-preview dz-file-preview">
                <div className="dz-details" style={displayNone}>
                    <div className="dz-filename"><label data-dz-name="true" /></div>
                </div>
                <div className="dz-progress" style={displayNone}><label className="dz-upload" data-dz-uploadprogress="true" /></div>
                <div className="dz-error-message" style={displayNone}><label data-dz-errormessage="true" /></div>
            </div>,
        )
    };

    // Determine the classes used for the picture preview zone.
    // By default nothing is displayed, but in the case of an error, the error is displayed
    const errorMessageClass = classNames({
        'dropzone-error': true,
        'dz-hide': disabled || !hasError,
    });

    // If an operation is in progress, the progress bar is shown.
    const progressBarClass = classNames({
        'dropzone-progress-container': true,
        'dz-hide': !inProgress,
    });

    const progressWidth = { width: `${progressPercentage}%` };

    // The preview image is shown if there is no error and nothing is in progress.
    const detailsClass = classNames({
        'dropzone-details': true,
        'dz-hide': (!disabled && hasError) || !hasPhoto,
    });

    // Determine the classes used for the borders of the dropzone.
    // If there is a photo, the dropzone is considered valid, and it's considered invalid if there is no photo.
    // Validity causes a green or red border to show.
    const dropzoneBorderClass = classNames({
        'photo-dropzone': true,
        'photo-dropzone-valid': isValid,
        'photo-dropzone-invalid': !isValid,
        'photo-dropzone-disabled': disabled,
    });

    // The dropzone message (e.g. drog photos here...) is only displayed if there are no photos or errors.
    const dropzoneMessageClass = classNames({
        'dz-message': true,
        'dz-hide': hasPhoto || (!disabled && hasError),
    });

    const dropzoneDeleteClass = classNames({
        'dropzone-delete': true,
        'dz-hide': disabled,
    });

    return (
        examId ?
        /* @ts-ignore:next-line */
        <DropzoneComponent
            class={dropzoneBorderClass}
            id={field}
            config={componentConfig}
            djsConfig={djsConfig}
            eventHandlers={eventHandlers}
        >
            {usePastPhoto ? (
                <div className="dz-message past-photo past-referral-letter" onClick={() => setShowLetterPdf(true)} aria-hidden="true">
                    <img src={pastPhotoURL} data-testid='past-image' className="dropzone-past-image" alt="" />
                    <div className="dropzone-empty-overlay">
                        <div className='past-photo-date' data-testid='past-photo-date'>{pastExamDate}</div>
                    </div>
                </div>
            ) : (
                <div className={dropzoneMessageClass}>
                    <div className="dropzone-empty-image"></div>
                    <div className="dropzone-empty-text">Drag & Drop</div>
                    <div className="dropzone-empty-text">to upload</div>
                    <div className="dropzone-empty-formats" />
                </div>
            )}

            <div className={errorMessageClass}>
                <label>{errorMessage}</label>
                <p />
                <label data-testid='image-file' className="dropzone-filename">{photoName}</label>
            </div>
            <div className={detailsClass}>
                <img
                    className="dropzone-image"
                    src={currentPhotoURL}
                    alt={photoName}
                    onClick={() => setShowLetterPdf(true)}
                    onError={
                        (e)=>{
                            // If the the thumbnail image can't be found, just use the full image.
                            if (e.currentTarget.src !== currentPhotoURL) {
                                e.currentTarget.src = currentPhotoURL;
                            }
                        }
                    }
                />

                <img
                    className={dropzoneDeleteClass}
                    onClick={sendDeletePhotoConfirm}
                    src={TrashButton}
                    alt="Delete"
                />
            </div>
            <div className={progressBarClass}>
                <div className="dropzone-progress" style={progressWidth} />
            </div>
                {showLetterPdf &&
                    <PatientExamGpReferredPDFViewerModal
                        className="pdfViewerModal"
                        file={lightBoxPhotoURL.replace('png', 'pdf')}
                        onPDFViewClose={() => setShowLetterPdf(false)}
                    />
                }
        </DropzoneComponent>
        :
        null
    );
}

export default ReferralLetterDropzone;
