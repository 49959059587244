import { Fragment, useState, useEffect, useMemo } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Input, Form, Checkbox } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { getTxAlgoKeywordOptions, addTxAlgoKeywordColors, renderTxAlgoKeywordString
    } from '../../helpers/patient-exam-convert';
import { OD_NOTES_PLAN_FIELD, OD_NOTES_PLAN_PLACEHOLDER } from '../../constants';
import '../../../static/css/components/patient-exam-od-notes.scss';
import { localizedText } from '../../localizedText';
import { FieldData } from 'rc-field-form/lib/interface';


const PatientExamODNotes = ({ className='input-od-notes', disabled, odNotesTitle='' }: { className?:string, disabled?: boolean, odNotesTitle?: string }) => {
    const { OD_NOTES_PLAN_TITLE } = localizedText;

    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);

    const examODNotesPlan = useCare1AppSelector(store => store.examData.od_omd);
    const odQuestion = useCare1AppSelector(store => store.examData.od_question);
    const odReqOmd = useCare1AppSelector(store => store.examData.od_req_omd);
    const odStartDrops = useCare1AppSelector(store => store.examData.od_start_drops);
    const isReferralLetterUploadPei = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const rluExamisPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);
    const examStatus = useCare1AppSelector(store => store.examData.exam_status);

    const disabledRluOD = userIsOD && isReferralLetterUploadPei && rluExamisPremium && examStatus !== 'not_ready';

    const RLU_TITLE = 'Private Notes From OD';

    const examOdNotesTitle = isReferralLetterUploadPei ? RLU_TITLE : OD_NOTES_PLAN_TITLE;

    const [ form ] = Form.useForm();
    const dispatch = useCare1AppDispatch();

     // Checkbox for admin to toggle in and out of editing mode.
     const [adminIsEditing, setAdminIsEditing] = useState(false);

    // red border?
    const redBorder = (odQuestion || odReqOmd || odStartDrops) && !isReferralLetterUploadPei;
    const redBorderClass = redBorder ? "red-border" : "";

    // Get the color coded keywords for the component
    const txAlgo3Keywords = useCare1AppSelector(store => store.options.txAlgoKeywords);
    const txAlgoKeywordOptions = useMemo(() => {
        return getTxAlgoKeywordOptions(txAlgo3Keywords, 'odNotesAndProposedPlan');
    }, [txAlgo3Keywords]);
    const keywordOptions = userIsOMDR || userIsADMIN ? txAlgoKeywordOptions : null;

    // Add the color code tags to the content string
    const applyKeywordColors = useMemo(() => {
        if((userIsOMDR || userIsADMIN) && keywordOptions){
            return addTxAlgoKeywordColors(keywordOptions, examODNotesPlan)
        }
        return null;
    }, [keywordOptions, examODNotesPlan, userIsOMDR, userIsADMIN]);

    // The component's own state now holds Ant Design field properties, including values, errors, etc.
    const [validationFields, setValidationFields] = useState<FieldData[]>([]);

    // equivalent of componentDidUpdate in this case
    useEffect(()=>{
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            [OD_NOTES_PLAN_FIELD]: examODNotesPlan,
        }
        form.setFieldsValue(newValidationFieldValues);
    }, [examODNotesPlan, form])

    // Ant Design handler. This is called whenever a field is changed by the user using Ant Design controls.
    const onFieldsChange = (fields: FieldData[], allFields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name.length && !field.validating) {
                switch (field.name[0]) {
                    case OD_NOTES_PLAN_FIELD:
                        dispatch(setExamDataValue(field.name[0], field.value));
                        break;
                    default:
                        break;
                }
            }
        });

        // We can simply overwrite all fields to update the state to all current values.
        setValidationFields(allFields);
    }

    return (
        <Form form={form} onFieldsChange={onFieldsChange} fields={validationFields}>
            <Card className={`component-input-card ${className}`} bordered={false}>
                <div className="heading">
                    {odNotesTitle || examOdNotesTitle}
                    { userIsADMIN &&
                        <Checkbox
                            onChange={()=> setAdminIsEditing(!adminIsEditing)}
                            className='admin-edit-checkbox'
                        >
                            Admin Edit
                        </Checkbox>
                    }
                </div>
                { ( userIsOMDC || userIsOD || (userIsADMIN && adminIsEditing)) &&
                    <Form.Item help="" name={OD_NOTES_PLAN_FIELD} initialValue={examODNotesPlan} >
                        {/* OD-NTS 001, 002, 003 */}
                            <Input.TextArea
                                disabled={disabled || disabledRluOD}
                                data-testid='od-notes'
                                className={`component-input ${redBorderClass}`}
                                autoSize={false}
                                placeholder={OD_NOTES_PLAN_PLACEHOLDER}
                            />
                    </Form.Item>
                }
                { ( userIsOMDR || (userIsADMIN && !adminIsEditing)) &&
                    <Form.Item>
                        <div className={`component-input color-keyword-div ${redBorderClass}`} data-testid='od-notes'>
                            {
                                applyKeywordColors && applyKeywordColors.map(
                                    (string, index) => (<Fragment key={index}>{renderTxAlgoKeywordString(string)}</Fragment>)
                                )
                            }
                        </div>
                    </Form.Item>
                }
            </Card>
        </Form>
    );
}

export default PatientExamODNotes;