import { useMemo, useState } from 'react';
import { pdfjs, Page, Document } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import '../../../static/css/components/referral-letter-omdr-pdf-viewer.scss';
import { Col, Row } from 'antd';
import { useCare1AppSelector } from '../../apps/care1-hooks';


const ReferralLetterOmdrPdfViewer = () => {

    const referralLetterPath = useCare1AppSelector(store => store.examData.od_referral_letter);
    const currentHighlightedPDFURL = useCare1AppSelector(store => store.examData.highlight_omd_pdf);

    const examIsODMessaging = useCare1AppSelector(store => store.examData.is_od_messaging_submission);
    const pastReferralLetterPath = useCare1AppSelector(store => store.examData.past_od_referral_letter);
    const usePastReferralLetterPath = examIsODMessaging && !referralLetterPath && pastReferralLetterPath;

    const pastHighlightedPDFURL = useCare1AppSelector(store => store.examData.past_highlight_omd_pdf);
    const usePastHighlightedPDFURL = examIsODMessaging && !currentHighlightedPDFURL && pastHighlightedPDFURL;

    const referralLetterPathToUse = usePastReferralLetterPath ? pastReferralLetterPath : referralLetterPath;
    const highlightedPDFURLToUse = usePastHighlightedPDFURL ? pastHighlightedPDFURL : currentHighlightedPDFURL;

    // Use past exam's referral letter if the exam is OD messaging and the current letter is not available.
    const referralLetterSrc = highlightedPDFURLToUse || referralLetterPathToUse.replace('png', 'pdf');

    const [numPages, setNumPages] = useState(1);

    const [scale, setScale] = useState(1.0);

    const options = useMemo(() => {
        return {
            standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
          }
    }, []) ;

    const onPDFLoadSuccess = ({numPages}: {numPages: number}) => {
        setNumPages(numPages);
    }
    const onPDFPageLoadSuccess = (page: any) => {
        const parentDiv = document.querySelector('#referralLetterPdfDocument');

        if (parentDiv?.clientWidth && page?.originalWidth) {
            const pageScale = parentDiv?.clientWidth / page.originalWidth
            if (scale !== pageScale) {
                setScale(pageScale);
            }
        }
    }

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

    return (
        <Row className='referral-letter-omdr-pdf-viewer-container'>
            <Row className='referral-letter-omdr-pdf-viewer-heading'>Referral Letter</Row>
            <Row justify={'space-between'} className='pdf-container'>
                {Array.from(new Array(Math.round(numPages/2)), (element, index) => (
                    <Row className='referral-letter-display'>
                        <Col
                            span={12}
                            id={ index === 0 ? `referralLetterPdfDocument` : ''}
                            className='pdf-page-container'
                        >
                            { referralLetterSrc && 
                            <Document
                                options={options} 
                                file={referralLetterSrc}
                                onLoadSuccess={onPDFLoadSuccess}
                                noData={<div className='referral-letter-message'>
                                    <div className='referral-letter-empty-text'>No Referral Letter</div>
                                </div>}
                            >
                                <Page
                                    pageNumber={index*2+1}
                                    renderMode={ 'canvas'}
                                    onLoadSuccess={onPDFPageLoadSuccess}
                                    scale={scale}
                                />
                            </Document>
                        }
                        </Col>
                        <Col span={12} className='pdfPage2 pdf-page-container'>
                            { referralLetterSrc && (index*2+2 <= numPages) &&
                            <Document
                                file={referralLetterSrc}
                                onLoadSuccess={onPDFLoadSuccess}
                            >
                                <Page
                                    pageNumber={index*2+2}
                                    renderMode={ 'canvas'}
                                    scale={scale}
                                />
                            </Document>
                        }
                        </Col>
                    </Row>
                ))}
            </Row>
        </Row>
        
    );
}
export default ReferralLetterOmdrPdfViewer;
