import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Modal } from "antd";
import { getCsrfToken } from "../helpers/utilities";
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";

export interface IHelpDeskForm {
    contactEmail: string,
    helpRequest: string,
    requesterName: string,
    modalSource: string
}

export interface IHelpDesk extends IHelpDeskForm {
    isHelpDeskModalOpen: boolean,
    isOperating: boolean,
}

const initialState: IHelpDesk = {
    isHelpDeskModalOpen: false,
    isOperating: false,
    contactEmail: '',
    helpRequest: '',
    requesterName: '',
    modalSource: '',
}

type TypeHelpDeskForm = {
    helpDesk: IHelpDeskForm
}

export const submitHelpDeskRequest = createAsyncThunk(
    'helpDesk/submitHelpDeskRequest',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken, userID } } = getState() as { user: { csrfToken: string, userID: number }};
        const { helpDesk: { requesterName, contactEmail, helpRequest, modalSource } } = getState() as TypeHelpDeskForm;

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const requestData = {
            'requester_name': requesterName,
            'request': helpRequest,
            'contact_email': contactEmail,
            'user_id': userID,
            'modal_source': modalSource,
        }

        const data = new FormData();
        data.append('help_desk_request', JSON.stringify(requestData));

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/help_desk/request/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const helpDesk = createSlice({
    name: 'helpDesk',
    initialState,
    reducers: {
        setHelpDeskModalOpen: (state, action) => {
            state = initialState;
            return {
                ...state,
                isHelpDeskModalOpen: action.payload
            }
        },
        setHelpDeskField: (state, action) => {
            return {
                ...state,
                [action.payload.field]: action.payload.value
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submitHelpDeskRequest.pending, (state) => {
            state.isOperating = true;
        });
        builder.addCase(submitHelpDeskRequest.fulfilled, (state) => {
            state.isOperating = false;
            Modal.info({
                className: 'info-modal',
                title: 'Help Desk request submitted successfully!',
            });
        });
        builder.addCase(submitHelpDeskRequest.rejected, (state, action) => {
            state.isOperating = false;
            Modal.error({
                className: 'info-modal',
                title: `Errors submitting help desk request. ${action.payload}`,
            })
        });
    }
})

export const { setHelpDeskModalOpen, setHelpDeskField } = helpDesk.actions;

export default helpDesk.reducer;