import {  Row, Typography } from 'antd';
import { useCare1AppSelector } from '../../apps/care1-hooks';

const ExamsSelected = () => {

    const examRooms = useCare1AppSelector(store => store.patientExams.rooms);

    return (
        <Row className='number-exam-selected'>
            <Typography.Text>
                Number of exams selected: 
            </Typography.Text>
            <Typography.Text strong>
                {examRooms.length}
            </Typography.Text>
        </Row>
    )
}

export default ExamsSelected;