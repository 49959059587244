import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { Row, Col, Card, Tooltip } from 'antd';
import PatientExamOMDRAlerts from './patient-exam-omdr-alerts';
import PatientExamOMDRComfortZones from './patient-exam-omdr-comfort-zones'
import PatientExamOMDRCounsellingRecommendations from './patient-exam-omdr-counselling-recommendations';
import PatientExamOMDRReassuringGLC from './patient-exam-omdr-reassuring-glc';
import PatientExamOMDRSuspiciousGLC from './patient-exam-omdr-suspicious-glc';
import HelpDeskButton from '../../retina/button-help-desk';
import { convertTimeToLongFormatLabel } from '../../../helpers/utilities';
import { InfoCircleOutlined } from '@ant-design/icons';
import DeepMDLogo from '../../../../static/images/logo-deepmd-colored-and-white-text.svg';
import { localizedText } from '../../../localizedText';
import { setHelpDeskField } from '../../../reducers/help-desk-slice';

const DeepMDBand = () => {
    const { DEEPMD_HELP_TRAIN, DEEPMD_INACCURACY_TOOLTIP, DEEPMD_SUBHEADING_TEXT } = localizedText;
    const patientFirstname  = useCare1AppSelector(store => store.patientDetails.details.first_name);
    const patientLastname  = useCare1AppSelector(store => store.patientDetails.details.last_name);
    const examDate  = useCare1AppSelector(store => store.examData.exam_date);
    const examId  = useCare1AppSelector(store => store.examData.id);

    const dispatch = useCare1AppDispatch();

    const afterOpenEvent = () => {
        const value = 'Reporting DeepMD Possible Outlier\n\n' +
            `Patient Name: ${patientFirstname} ${patientLastname}\n` +
            `Visit Date: ${convertTimeToLongFormatLabel(examDate)}\n` +
            `Exam ID: ${examId}\n\n` +
            `Please proved details regarding possible inaccuracy:`;

        dispatch(setHelpDeskField({field: 'helpRequest', value: value}));
        dispatch(setHelpDeskField({field: 'modalSource', value: 'omdr_counselling_recommendations'}));
    }

    return (
        <Card className='deepmd-band' bordered={false}>
            <Row className="deepmd-heading">
                <Col span={2}>
                    <img src={DeepMDLogo} className='deepmd-logo' alt='Deep MD Logo' />
                </Col>
                <Col span={16}>
                    <div className='deepmd-subheading'>{DEEPMD_SUBHEADING_TEXT}</div>
                </Col>
                <Col span={6}>
                    <HelpDeskButton text={DEEPMD_HELP_TRAIN} afterOpenEvent={afterOpenEvent} />
                    <Tooltip placement='topLeft' title={DEEPMD_INACCURACY_TOOLTIP}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <PatientExamOMDRSuspiciousGLC />
                </Col>
                <Col span={4}>
                    <PatientExamOMDRReassuringGLC />
                </Col>
                <Col span={5}>
                    <PatientExamOMDRAlerts />
                </Col>
                <Col span={4}>
                    <PatientExamOMDRComfortZones />
                </Col>
                <Col span={7}>
                    <PatientExamOMDRCounsellingRecommendations/>
                </Col>
            </Row>

        </Card>
    )
}

export default DeepMDBand
