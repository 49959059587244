import { useState } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { BACKEND_EXAM_STATUS_FIELD } from '../../../constants';

import LetterModal from './letter-modal';

import { localizedText } from '../../../localizedText';
import { useCare1AppSelector } from '../../../apps/care1-hooks';

const PatientExamHeaderGPLetterButton = () => {
    const { GP_CONSULTATION_LETTER_TEXT } = localizedText;

    const examStatus = useCare1AppSelector(store=> store.examData[BACKEND_EXAM_STATUS_FIELD]);
    const [letterModalVisible, setLetterModalVisible] = useState(false);

    return (
        <>
            <LetterModal 
                letterModalVisible={letterModalVisible}
                setLetterModalVisible={setLetterModalVisible}
            />
            <Button
                className='gp-consult-letter-button'
                onClick={() => setLetterModalVisible(true)}
                type="primary"
                icon={<DownloadOutlined />}
                disabled={examStatus !== 'reviewed'}
                size="small"
            >
                {GP_CONSULTATION_LETTER_TEXT}
            </Button>

        </>
    )
}

export default PatientExamHeaderGPLetterButton;