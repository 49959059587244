import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Tag, Row } from 'antd';
import PatientExamInitiateTreatmentModal from './patient-exam-initiate-treatment-modal';
import { setInitiateTreatmentDataValue } from '../../reducers/patient-exam-initiate-treatment-slice';
import '../../../static/css/components/patient-exam-initiate-treatment.scss';
import { localizedText } from '../../localizedText';

const { CheckableTag } = Tag;

const PatientExamInitiateTreatment = () => {
    const { INITIATE_TREATMENT_TEXT } = localizedText;
    const isInitiateTreatmentModalOpen = useCare1AppSelector(store => store.initiateTreatment.isInitialTreatmentModalOpen);
    const dispatch = useCare1AppDispatch();

    const handleInitiateTreatment = () => {
        dispatch(setInitiateTreatmentDataValue({ key: 'isInitialTreatmentModalOpen', value: true }));
    }

    return (
        <Card className={'initiate-treatment'} bordered={false}>
            <Row className='initiate-treatment-row'>
                <CheckableTag
                    checked={isInitiateTreatmentModalOpen}
                    onChange={handleInitiateTreatment}
                >
                    {INITIATE_TREATMENT_TEXT}
                </CheckableTag>
                <PatientExamInitiateTreatmentModal />
            </Row>
        </Card>
    );
}

export default PatientExamInitiateTreatment;
