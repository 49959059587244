import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';
import { getCsrfToken } from '../helpers/utilities';
import { apiRequest } from '../services/api-request';
import { logout } from './user-slice';

type ValueOf<T> = T[keyof T];
type ValueOfUploadReconcilerData = ValueOf<IUploadReconcilerData>;
type DobFields = 'dob_day' | 'dob_month' | 'dob_year';

export type UploadReconcilerResponseData =
    | {
          success: true;
          error: never;
          data: Omit<IUploadReconcilerData, DobFields> | null;
      }
    | {
          success: false;
          error: string;
          data?: never;
      };

export type DiagnosisFile = {
    uid: string;
    id?: number;
    thumbUrl?: string;
    url?: string;
    name: string;
    type?: string;
};

export type UploadReconcilerImageField =
    | 'left_fundus_photo'
    | 'left_stereo_photo'
    | 'left_oct_photo'
    | 'left_vf_photo'
    | 'left_oct_rnfl_photo'
    | 'right_fundus_photo'
    | 'right_stereo_photo'
    | 'right_oct_photo'
    | 'right_vf_photo'
    | 'right_oct_rnfl_photo'
    | 'extra_images';

export interface IUploadReconcilerData {
    id: number | null;
    optometrist_name: string;
    optometrist_location: string;
    od_account: number | null;
    exam_date: Dayjs | null;
    dob: string;
    first_name: string;
    last_name: string;
    primary_phone: string;
    province: number | null;
    insurance_type: string;
    phn: string;
    left_fundus_photo: string;
    left_stereo_photo: string;
    left_oct_photo: string;
    left_vf_photo: string;
    left_oct_rnfl_photo: string;
    right_fundus_photo: string;
    right_stereo_photo: string;
    right_oct_photo: string;
    right_vf_photo: string;
    right_oct_rnfl_photo: string;
    extra_images: string[];
    loading: boolean;
    dob_month: string;
    dob_year: string;
    dob_day: string;
}

const initialState: IUploadReconcilerData = {
    id: null,
    optometrist_name: '',
    optometrist_location: '',
    od_account: null,
    exam_date: null,
    dob: '',
    first_name: '',
    last_name: '',
    primary_phone: '',
    province: null,
    insurance_type: '',
    phn: '',
    left_fundus_photo: '',
    left_stereo_photo: '',
    left_oct_photo: '',
    left_vf_photo: '',
    left_oct_rnfl_photo: '',
    right_fundus_photo: '',
    right_stereo_photo: '',
    right_oct_photo: '',
    right_vf_photo: '',
    right_oct_rnfl_photo: '',
    extra_images: [],
    loading: false,
    dob_month: '',
    dob_year: '',
    dob_day: '',
};

export const getUploadReconcilerData = createAsyncThunk(
    'smartUpload/getUploadReconcilerData',
    async (smartUploadId: number, { dispatch, getState, rejectWithValue }) => {
        const {
            user: { csrfToken },
        } = getState() as { user: { csrfToken: string } };
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/upload_reconciler/${smartUploadId}`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

export const uploadReconcilerSlice = createSlice({
    name: 'uploadReconcilerSlice',
    initialState,
    reducers: {
        setUploadReconcilerSliceDataValue: (
            state,
            action: PayloadAction<{
                key: keyof IUploadReconcilerData;
                value: ValueOfUploadReconcilerData;
            }>
        ) => {
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            };
        },
        setUploadReconcilerDataValues: (
            state,
            action: PayloadAction<Partial<IUploadReconcilerData>>
        ) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        setGptExtraImagesAtIndex: (
            state,
            action: PayloadAction<{ base64Image: string; idx: number }>
        ) => {
            const { base64Image, idx } = action.payload;
            if (base64Image) {
                if (idx === -1) {
                    state.extra_images = [base64Image, ...state.extra_images];
                } else {
                    const res: string[] = [...state.extra_images];
                    res[idx] = base64Image;
                    state.extra_images = res;
                }
            }
        },
        moveBase64Images: (
            state,
            action: PayloadAction<{
                fromField: UploadReconcilerImageField;
                toField: UploadReconcilerImageField;
            }>
        ) => {
            const { fromField, toField } = action.payload;
            if (toField === 'extra_images') {
                return;
            } else {
                if (fromField === 'extra_images') {
                    return {
                        ...state,
                        [toField]: state[fromField],
                    };
                }
                return {
                    ...state,
                    [fromField]: '',
                    [toField]: state[fromField],
                };
            }
        },
        moveBase64ExtraImages: (
            state,
            action: PayloadAction<{
                fromIndex: number;
                toField: UploadReconcilerImageField;
            }>
        ) => {
            const { fromIndex, toField } = action.payload;
            if (toField === 'extra_images') {
                return;
            } else {
                return {
                    ...state,
                    extra_images: state.extra_images.filter(
                        (_, index) => index !== fromIndex
                    ),
                    [toField]: state.extra_images[fromIndex],
                };
            }
        },
        deleteBase64Image: (
            state,
            action: PayloadAction<UploadReconcilerImageField>
        ) => {
            const imageToDelete = state[action.payload] as string;
            return {
                ...state,
                [action.payload]: '',
                extra_images: [imageToDelete, ...state.extra_images],
            };
        },
        deleteBase64ExtraImage: (state, action: PayloadAction<number>) => {
            state.extra_images = state.extra_images.filter(
                (_, index) => index !== action.payload
            );
        },
        resetUploadReconcilerData: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getUploadReconcilerData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getUploadReconcilerData.fulfilled,
            (state, action: PayloadAction<UploadReconcilerResponseData>) => {
                state.loading = false;
                if (action.payload.success && action.payload.data) {
                    const { data } = action.payload;

                    state.id = data.id;
                    state.optometrist_name = data.optometrist_name;
                    state.optometrist_location = data.optometrist_location;
                    state.od_account = data.od_account;
                    state.exam_date = dayjs(data.exam_date);
                    state.dob = data.dob;
                    const dayjsObj = dayjs(data.dob);
                    state.dob_year = dayjsObj.year().toString();
                    state.dob_month = dayjsObj.month().toString();
                    state.dob_day = dayjsObj.date().toString();

                    state.first_name = data.first_name;
                    state.last_name = data.last_name;
                    state.primary_phone = data.primary_phone;
                    state.province = data.province;
                    state.insurance_type = data.insurance_type;
                    state.phn = data.phn;

                    state.left_fundus_photo = data.left_fundus_photo
                        ? `data:image/png;base64,${data.left_fundus_photo}`
                        : '';
                    state.left_oct_photo = data.left_oct_photo
                        ? `data:image/png;base64,${data.left_oct_photo}`
                        : '';
                    state.left_stereo_photo = data.left_stereo_photo
                        ? `data:image/png;base64,${data.left_stereo_photo}`
                        : '';
                    state.left_oct_rnfl_photo = data.left_oct_rnfl_photo
                        ? `data:image/png;base64,${data.left_oct_rnfl_photo}`
                        : '';
                    state.left_vf_photo = data.left_vf_photo
                        ? `data:image/png;base64,${data.left_vf_photo}`
                        : '';
                    state.right_fundus_photo = data.right_fundus_photo
                        ? `data:image/png;base64,${data.right_fundus_photo}`
                        : '';
                    state.right_stereo_photo = data.right_stereo_photo
                        ? `data:image/png;base64,${data.right_stereo_photo}`
                        : '';
                    state.right_oct_photo = data.right_oct_photo
                        ? `data:image/png;base64,${data.right_oct_photo}`
                        : '';
                    state.right_vf_photo = data.right_vf_photo
                        ? `data:image/png;base64,${data.right_vf_photo}`
                        : '';
                    state.right_oct_rnfl_photo = data.right_oct_rnfl_photo
                        ? `data:image/png;base64,${data.right_oct_rnfl_photo}`
                        : '';
                    state.extra_images = data.extra_images;
                }
            }
        );
        builder.addCase(getUploadReconcilerData.rejected, (state, action) => {
            state.loading = false;
        });
    },
});

export const {
    setUploadReconcilerSliceDataValue,
    setUploadReconcilerDataValues,
    setGptExtraImagesAtIndex,
    moveBase64Images,
    deleteBase64Image,
    deleteBase64ExtraImage,
    moveBase64ExtraImages,
} = uploadReconcilerSlice.actions;

export default uploadReconcilerSlice.reducer;
