import React from "react";
import '../../../../static/css/components/admin-gpt.scss';
import ReactMarkdown from 'react-markdown';

interface GptMessageProps {
    message: {
        input: string;
        output: string;
        meta: string;
    };
    isAssistantTyping: boolean;
    formatMessageContent: (content: string) => string;
}

const GptMessage: React.FC<GptMessageProps> = ({ message, isAssistantTyping, formatMessageContent }) => {

    const showUserMessage = false;

    return (
        <>
            {showUserMessage &&
                <div
                    className="message user"
                >
                    <div className="who">User:
                    </div>
                    <div className="content">
                        {message.input !== '' && <ReactMarkdown>{`${message.input}`}</ReactMarkdown>}
                    </div>
                </div>

            }
            <div
                className="message assistant"
            >
                <div className="who">ChatGPT:
                </div>
                <div className="content">
                    {isAssistantTyping && (
                        <div className="message assistant">
                            <div className="typing-indicator">
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                            </div>
                        </div>
                    )}
                    {message.output !== '' && <ReactMarkdown>{`${message.output}`}</ReactMarkdown>}
                    {message.meta !== '' && <ReactMarkdown>{`${message.meta}`}</ReactMarkdown>}
                </div>
            </div>
        </>
    );
};

export default GptMessage;
