import { createSlice } from '@reduxjs/toolkit';


export interface IOctRnflNearMissList {
    info: string;
    error: string;
    operating: boolean;
    list: [];
    status: 'loading' | 'success' | 'failed' | '';
}

const initialState: IOctRnflNearMissList = {
    info: '',
    error: '',
    operating: false,
    list: [],
    status: '',
}


export const octRnflNearMissSlice = createSlice({
    name: 'octRnflNearMissSlice',
    initialState,
    reducers: {
    },
});

export default octRnflNearMissSlice.reducer;
