import { IAllExamImages } from "../reducers/patient-exam-slice";
import { getUserPlatformKey } from "./user-type-check";

// Return the correct column width based on the platform
export const getImageHistoryColWidth = (isRE: boolean, isOD: boolean, isOMDC: boolean, isOMDR: boolean, isADMIN: boolean) =>{

    // Get user platform key
    const userPlatformKey = getUserPlatformKey(isRE, isOD, isOMDC, isOMDR, isADMIN);

    switch(userPlatformKey) {
        case 'RE':
            return 8;
        case 'IC':
            return 6;
        case 'OMDC':
            return 8;
        case 'OMDR RE':
            return 8;
        case 'OMDR IC':
            return 6;
        case 'ADMIN':
            return 6;
        default:
            return 6;
    }
}

// Return the correct number of images per row  based on the platform
export const getImageHistoryImagesPerRow = (isRE: boolean, isOD: boolean, isOMDC: boolean, isOMDR: boolean, isADMIN: boolean) =>{

    // Get user platform key
    const userPlatformKey = getUserPlatformKey(isRE, isOD, isOMDC, isOMDR, isADMIN);

    switch(userPlatformKey) {
        case 'RE':
            return 3;
        case 'IC':
            return 4;
        case 'OMDC':
            return 3;
        case 'OMDR RE':
            return 3;
        case 'OMDR IC':
            return 4;
        case 'ADMIN':
            return 4;
        default:
            return 4;
    }
}

// Return the correct max number of displayed image cells based on the platform
export const getImageHistoryImagesMaxCels = (isRE: boolean, isOD: boolean, isOMDC: boolean, isOMDR: boolean, isADMIN: boolean) =>{

    // Get user platform key
    const userPlatformKey = getUserPlatformKey(isRE, isOD, isOMDC, isOMDR, isADMIN);

    switch(userPlatformKey) {
        case 'RE':
            return 12;
        case 'IC':
            return 16;
        case 'OMDC':
            return 12;
        case 'OMDR RE':
            return 12;
        case 'OMDR IC':
            return 16;
        case 'ADMIN':
            return 16;
        default:
            return 16;
    }
}

// Return a set of one side's history images.
// Both sides need to be processed in order to determine if there are any empty image cells in cases where only one
// side had an image uploaded for an exam. In these cases, 'No Test Done' will display in the UI. These images are
// tagged with null in the exam_id so they can be handled in the UI.

export const getSideHistoryImages = (side: 'left' | 'right', currentLeft: IAllExamImages[], currentRight: IAllExamImages[], orderBy: string ) => {

    // Build a unique set of all exams ids based on right and left side images
    const examIDs: number[] = []
    // Add exam ids for all right side images
    currentRight && currentRight.forEach(exam => {
        exam.exam_id && examIDs.push(exam.exam_id);
    })
    // Add any exam ids that have a left side image but no right side image
    currentLeft && currentLeft.forEach(exam => {
        const exists = examIDs.find(examID => examID === exam.exam_id);

        if(!exists){
            exam.exam_id && examIDs.push(exam.exam_id);
        }
    })

    // Build the left and right image lists
    const leftExamImages: IAllExamImages[] = [];
    const rightExamImages: IAllExamImages[] = [];
    examIDs && examIDs.forEach(examID => {
        const leftExists = currentLeft.find(exam => examID === exam.exam_id);
        const rightExists = currentRight.find(exam => examID === exam.exam_id);
        if(leftExists){
            leftExamImages.push(leftExists);
        } else {
            // If there is no left image, tag the exam_id as null so that it can be handled in the UI.
            leftExamImages.push({
                exam_date_utc: rightExists ? rightExists.exam_date_utc : '' ,
                exam_id: null,
                exam_date: rightExists ? rightExists.exam_date : ''
            });
        }

        if(rightExists){
            rightExamImages.push(rightExists);
        } else {
            // If there is no right image, tag the exam_id as null so that it can be handled in the UI.
            rightExamImages.push({
                exam_date_utc: leftExists ? leftExists.exam_date_utc : '',
                exam_id: null,
                exam_date: leftExists ? leftExists.exam_date : '',
            });
        }
    })

    let imageExams = side === 'right' ? rightExamImages : leftExamImages;

    imageExams.sort((a, b) => {
        if(a === null){
            return 1;
        } else if (b === null){
            return -1;
        }

        if (orderBy === 'desc') {
            return new Date(b.exam_date_utc).valueOf() - new Date(a.exam_date_utc).valueOf();
        }
        else {
            return new Date(a.exam_date_utc).valueOf() - new Date(b.exam_date_utc).valueOf();
        }
    })

    return imageExams;
}

// Return the slice of History Images
export const getHistoryImageSlice = (imageExams: IAllExamImages[], isFirstRow: string, imagesPerRow: number, maxDisplayCells: number) => {

    const imagesLTEtoImagesPerRow = imageExams && imageExams.length <= imagesPerRow;
    const imagesLTEtoMaxDisplayCells = imageExams && imageExams.length <= maxDisplayCells;

    // If the request is for the first row return the first row slice
    if(isFirstRow === 'isFirstRow'){
        return imageExams.slice(0, imagesPerRow)
    } else { // If the request is not for the first row
        // If there are not more than one rows length of images return empty image slice
        if(imagesLTEtoImagesPerRow){
            return [];
        } else if(imagesLTEtoMaxDisplayCells){
            // If total number of images is less than or equal to the max available cells return remaining images.
            return imageExams.slice(imagesPerRow, imageExams.length);
        } else {
            // If there are more images than max display cells slice out the mid history cells.
            // The second row should begin at the point that alllows enough space to run consecutively up to the last exam.
            const numOfIgnoredImages = imageExams.length - maxDisplayCells;
            return imageExams.slice(imagesPerRow + numOfIgnoredImages, imageExams.length)
        }
    }
}
