import { IBaseResponse, baseApi } from './base-api'
import { IGlaucomaProgramFilters } from '../reducers/glaucoma-program-slice';
import { IGlaucomaProgramPatientListItem, IGlaucomaProgramPatientListResponseData } from '../reducers/patient-list-slice';
import {  convertGlaucomaProgramPatientListToTable,
    } from '../helpers/patient-list-convert';

export interface IGlaucomaProgramDetails {
    exam_id: number,
    booking_status: string,
    comments: string,
}

export interface IGlaucomaProgramODListItem {
    id: number,
    od_name: string,
}

export interface IGlaucomaProgramODListResponse {
    od_list: IGlaucomaProgramODListItem[],
}

export const glaucomaProgramApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getGlaucomaProgramPatientList: builder.query<IGlaucomaProgramPatientListItem[], IGlaucomaProgramFilters>({
            query: (data) => ({
                url: '/glaucoma_program/patients/',
                params: data,
            }),
            transformResponse: (response: IBaseResponse & IGlaucomaProgramPatientListResponseData, meta, arg) =>
                convertGlaucomaProgramPatientListToTable(response.patients_data_list),
            // Give a tag to GlaucomaPatientList so that other API actions can invalidate GlaucomaPatientList
            providesTags: [{type: 'GlaucomaPatientList', id: 'LIST'}]
        }),
        getGlaucomaProgramODList: builder.query<IGlaucomaProgramODListItem[], void>({
            query: () => '/glaucoma_program/od_list/',
            transformResponse: (response: IBaseResponse & IGlaucomaProgramODListResponse) => response.od_list,
        }),
        editGlaucomaProgram: builder.mutation<{success: boolean, error?: string}, IGlaucomaProgramDetails>({
            query(requestData) {
                return {
                    url: 'glaucoma_program/edit/',
                    method: 'POST',
                    body: requestData,
                }
            },
            // Invalidate a specific OMD by its id so that the OMD tag is also updated, also invalidate
            // the Doctor List because one of the OMD(doctors) is updated
            invalidatesTags: ['GlaucomaPatientList']
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const { useGetGlaucomaProgramPatientListQuery, useGetGlaucomaProgramODListQuery, usePrefetch, useEditGlaucomaProgramMutation
} = glaucomaProgramApi;