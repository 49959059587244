import { useEffect } from 'react';
import { Card, Col, Row, Select, Form, Tag } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { useFormValidation } from '../../context/useFormValidationContext'
import '../../../static/css/components/admin-ont-billing-code.scss';
import { localizedText } from '../../localizedText';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { IPlannedBillingCodeOption } from '../../reducers/options-slice';
import { FieldData } from 'rc-field-form/lib/interface';

type ComponentProps = {
    doctorType: 'omdr' | 'omdc';
    doctorTypeLabel: string;
}

const AdminOntarioBillingCode = ({ doctorType, doctorTypeLabel }: ComponentProps) => {
    const { AUTOMATIC_CODE_TEXT, MANUAL_CODE_OVERRIDE } = localizedText;
    const autoPlannedBillingCodeFieldName: 'omdr_auto_planned_billing_code' | 'omdc_auto_planned_billing_code' = `${doctorType}_auto_planned_billing_code`;
    const manualPlannedBillingCodeFieldName: 'omdr_manual_planned_billing_code' | 'omdc_manual_planned_billing_code' = `${doctorType}_manual_planned_billing_code`;

    const plannedBillingCodeOptions = useCare1AppSelector(store => store.options.plannedBillingCodeOptions);
    const autoPlannedBillingCode = useCare1AppSelector(store => store.examData[autoPlannedBillingCodeFieldName]);
    const manualPlannedBillingCode = useCare1AppSelector(store => store.examData[manualPlannedBillingCodeFieldName]);
    const autoPlannedBillingCodeText = plannedBillingCodeOptions
        .find((entry: IPlannedBillingCodeOption) => entry.value === autoPlannedBillingCode)?.label;
    const patientProvince = useCare1AppSelector(store => store.patientDetails.details.province);

    const [form] = Form.useForm();
    const dispatch = useCare1AppDispatch();
    const { setBillingCodeForm } = useFormValidation();

    // Side effects that we run after component render.
    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            [autoPlannedBillingCodeFieldName]: autoPlannedBillingCode,
            [manualPlannedBillingCodeFieldName]: manualPlannedBillingCode,
        };
        form.setFieldsValue(newValidationFieldValues);

        // The component should validate on every render
        form.validateFields();
        setBillingCodeForm(form);

        // clean up function when billing code component unmounts
        return () => {
            setBillingCodeForm(null);
        }
    }, [autoPlannedBillingCode, manualPlannedBillingCode, autoPlannedBillingCodeFieldName,
        manualPlannedBillingCodeFieldName, form, setBillingCodeForm]);

    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case manualPlannedBillingCodeFieldName:
                        dispatch(setExamDataValue(manualPlannedBillingCodeFieldName, field.value));
                        break;
                    default:
                        break;
                }
            }
        });
    };

    return (
        <Form form={form} onFieldsChange={onFieldsChange}>
            <Card className={'component-input-card patient-exam-billing-code'} bordered={false}>
                <div className="heading">{doctorTypeLabel}</div>
                <div className='component-body'>
                    <Row>
                        <Col span={12}>
                            <Row>
                                <span className={'billing-code-label first'}>{AUTOMATIC_CODE_TEXT}:</span>
                            </Row>
                            <Row>
                                <Tag className={'billing-code-display'} data-testid='automatic-code'>
                                    {autoPlannedBillingCodeText}
                                </Tag>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <span className={'billing-code-label'}>{MANUAL_CODE_OVERRIDE}</span>
                            </Row>
                            <Row>
                                <Form.Item
                                    className={'manual-billing-code'}
                                    colon={false}
                                    help=""
                                    name={manualPlannedBillingCodeFieldName}
                                >
                                    <Select
                                        className={'manual-billing-code-select'}
                                        data-testid='manual-overide'
                                        size="small"
                                        disabled={patientProvince !== 'Ontario'}
                                    >
                                        {plannedBillingCodeOptions && plannedBillingCodeOptions
                                            .map((option: IPlannedBillingCodeOption) => (
                                                <Select.Option
                                                    key={option.value}
                                                    value={option.value}
                                                >{option.label}</Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Card>
        </Form>
    );
}

export default AdminOntarioBillingCode;
