import React from 'react';
import { Card, Row, Tag } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import '../../../../static/css/components/admin-vf-ght.scss';
import { localizedText } from '../../../localizedText';

const { CheckableTag } = Tag;

type ComponentProps = {
    ght: string,
    setGht: (val: string) => void,
}

const GptVfGht = ({ ght, setGht }: ComponentProps) => {
    const { WNL_TEXT, GRS_TEXT, BGRS_TEXT, B_TEXT, ONL_TEXT,
        AHS_TEXT, VF_GHT_TEXT, NA_TEXT } = localizedText;

    const dispatch = useCare1AppDispatch();

    const handleChange = (checked: boolean, val: string) => {
        if (checked) {
            setGht(val);
        }
        else {
            setGht('');
        }
    }
    return (
        <Card className={'component-input-card admin-vf-ght'} bordered={false}>
            <div className={`heading`}>{VF_GHT_TEXT}</div>
            <div className='component-body'>
                <Row className='row-one'>
                    <CheckableTag
                        data-testid='admin-vf-ght-wnl'
                        checked={ght === 'wnl'}
                        onChange={(checked) => handleChange(checked, 'wnl')}
                    >{WNL_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-vf-ght-grs'
                        checked={ght === 'grs'}
                        onChange={(checked) => handleChange(checked, 'grs')}
                        className={ght === 'grs' ? 'yellow' : ''}
                    >{GRS_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-vf-ght-b-grs'
                        checked={ght === 'b-grs'}
                        onChange={(checked) => handleChange(checked, 'b-grs')}
                        className={ght === 'b-grs' ? 'yellow' : ''}
                    >{BGRS_TEXT}</CheckableTag>
                </Row>
                <Row className='row-two'>
                    <CheckableTag
                        data-testid='admin-vf-ght-b'
                        checked={ght === 'b'}
                        onChange={(checked) => handleChange(checked, 'b')}
                        className={ght === 'b' ? 'yellow' : ''}
                    >{B_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-vf-ght-onl'
                        checked={ght === 'onl'}
                        onChange={(checked) => handleChange(checked, 'onl')}
                        className={ght === 'onl' ? 'red' : ''}
                    >{ONL_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-vf-ght-ahs'
                        checked={ght === 'ahs'}
                        onChange={(checked) => handleChange(checked, 'ahs')}
                        className={ght === 'ahs' ? 'yellow' : ''}
                    >{AHS_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-vf-ght-na'
                        checked={ght === 'na'}
                        onChange={(checked) => handleChange(checked, 'na')}
                        className={ght === 'na' ? 'yellow' : ''}
                    >{NA_TEXT}</CheckableTag>
                </Row>
            </div>
        </Card>
    )
}

export default GptVfGht;