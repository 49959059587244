import React, { useState } from 'react';
import { Layout, Spin, Row, Menu } from 'antd';
import NavigationControlBar from '../components/navigation-control-bar';
import AdminDoctorListTable from '../components/admin/admin-doctor-list-table';
import OMDEditModal from '../components/admin/omd-edit-modal';
import OMDAddModal from '../components/admin/omd-add-modal';
import ODEditModal from '../components/admin/od-edit-modal';
import ODAddModal from '../components/admin/od-add-modal';
import IGPEditModal from '../components/admin/igp-edit-modal';
import IGPAddModal from '../components/admin/igp-add-modal';
import DoctorListControlBarAdmin from '../components/admin/doctor-list-control-bar-admin';
import * as Constants from '../constants';
import '../../static/css/pages/admin-doctors-list-page.scss'
import ODGroupPracticeAddModal from '../components/admin/od-group-practice-add-modal';
import { useCare1AppSelector } from '../apps/care1-hooks';
import { useGetDoctorListQuery } from '../services/doctor-api';
import type { MenuProps } from 'antd';
import AdminStaffListTable from '../components/admin/admin-staff-list-table';
import NewStaffModal from '../components/admin/new-staff-modal';
import StaffEditModal from '../components/admin/staff-edit-modal';
import PasswordChangeModal from '../components/admin/password-change-modal';
import NewRegionModal from '../components/admin/new-region-modal';
import { DoctorListMenuType } from '../reducers/doctor-slice';
import OdGroupPracticeTable from '../components/admin/od-group-practice-table';
import ODGroupPracticeEditModal from '../components/admin/od-group-practice-edit-modal';
import ClinicLocationAddModal from '../components/admin/clinic-location-add-modal';
import ClinicLocationEditModal from '../components/admin/clinic-location-edit-modal';
import TechnicianTable from '../components/admin/technician-table';
import TechnicianAddModal from '../components/admin/technician-add-modal';
import TechnicianEditModal from '../components/admin/technician-edit-modeal';
import { usePrefetch } from '../services/od-group-practice-api';
import { usePrefetch as usePrefetchDoctor } from '../services/doctor-api';
import { usePrefetch as usePrefetchStaff } from '../services/staff-api';

const { Header, Content } = Layout;
type MenuItem = Required<MenuProps>['items'][number];

const AdminDoctorsListPage: React.FC = () => {

    const showOmdEditModal = useCare1AppSelector(store => store.doctorSlice.showOmdEditModal);
    const showOMDAddModal = useCare1AppSelector(store=> store.doctorSlice.showOmdAddModal);
    const showOdEditModal = useCare1AppSelector(store => store.doctorSlice.showOdEditModal);
    const showODAddModal = useCare1AppSelector(store => store.doctorSlice.showOdAddModal);
    const showIgpEditModal = useCare1AppSelector(store => store.doctorSlice.showIgpEditModal);
    const showIgpAddModal = useCare1AppSelector(store => store.doctorSlice.showIgpAddModal);
    const showGroupPracticeAddModal = useCare1AppSelector(store => store.doctorSlice.showGroupPracticeAddModal);
    const showGroupPracticeEditModal = useCare1AppSelector(store => store.doctorSlice.showGroupPracticeEditModal);
    const showAdminAddModal = useCare1AppSelector(store => store.doctorSlice.showStaffAddModal);
    const showAdminEditModal = useCare1AppSelector(store => store.doctorSlice.showStaffEditModal);
    const showPasswordChangeModal = useCare1AppSelector(store => store.doctorSlice.showPasswordChangeModal);
    const showNewRegionModal = useCare1AppSelector(store => store.doctorSlice.showNewRegionModal);
    const showClinicLocationAddModal = useCare1AppSelector(store => store.doctorSlice.showClinicLocationAddModal);
    const showClinicLocationEditModal = useCare1AppSelector(store => store.doctorSlice.showClinicLocationEditModal);
    const showTechnicianAddModal = useCare1AppSelector(store => store.doctorSlice.showTechnicianAddModal);
    const showTechnicianEditModal = useCare1AppSelector(store => store.doctorSlice.showTechnicianEditModal);

    const [ type, setType ] = useState<DoctorListMenuType>('od');

    const prefetchTechnician = usePrefetch('getTechnicianList');
    const prefetchOdGroupPractice = usePrefetch('getOdGroupPracticeList');
    const prefetchDoctor = usePrefetchDoctor('getDoctorList');
    const prefetchStaff = usePrefetchStaff('getStaffList');

    // Doctor API call to get the doctor list
    const {data = {doctors:[]}, isFetching} = useGetDoctorListQuery(type, {skip: (type === 'staff' || type === 'odgrouppractice' || type === 'technician')});


    const items: MenuItem[] = [
        {label: 'OD', key: 'od', onClick: () => setType('od'), onMouseEnter: () => prefetchDoctor('od', {ifOlderThan: 60})},
        {label: 'Technician', key: 'technician', onClick: () => setType('technician'), onMouseEnter: () => prefetchTechnician()},
        {label: 'OD Group Practice', key: 'odgrouppractice', onClick: () => setType('odgrouppractice'), onMouseEnter: () => prefetchOdGroupPractice()},
        {label: 'OMD', key: 'omd', onClick: () => setType('omd'), onMouseEnter: () => prefetchDoctor('omd')},
        {label: 'IGP', key: 'igp', onClick: () => setType('igp'), onMouseEnter: () => prefetchDoctor('igp')},
        {label: 'Staff', key: 'staff', onClick: () => setType('staff'), onMouseEnter: () => prefetchStaff()},
    ];
    return (
        <Layout>
            <Spin
                className="loading-spinner"
                spinning={isFetching}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Header>
                    <NavigationControlBar
                        className="patient-list-navigation-control-bar"
                        breadcrumbItems={[]}
                    />
                </Header>
                <Content>
                    <div className='admin-doctors-list-page'>
                        <Row className='patient-list-menu-admin'>
                            <Menu
                                mode='horizontal'
                                items={items}
                                selectedKeys={[type]}
                            />
                        </Row>
                        <DoctorListControlBarAdmin type={type} />
                        {
                            type === 'staff' ? 
                            <AdminStaffListTable /> :
                                type === 'odgrouppractice' ?
                                <OdGroupPracticeTable /> :
                                    type === 'technician' ?
                                    <TechnicianTable /> :
                                        <AdminDoctorListTable tableList={data.doctors} type={type} />

                        }
                        
                        {showOmdEditModal && <OMDEditModal /> }
                        {showOMDAddModal && <OMDAddModal /> }
                        {showOdEditModal && <ODEditModal /> }
                        {showODAddModal && <ODAddModal /> }
                        {showIgpEditModal && <IGPEditModal /> }
                        {showIgpAddModal && <IGPAddModal /> }
                        {showGroupPracticeAddModal && <ODGroupPracticeAddModal />}
                        {showGroupPracticeEditModal && <ODGroupPracticeEditModal />}
                        {showAdminAddModal && <NewStaffModal />}
                        {showAdminEditModal && <StaffEditModal />}
                        {showPasswordChangeModal && <PasswordChangeModal />}
                        {showNewRegionModal && <NewRegionModal />}
                        {showClinicLocationAddModal && <ClinicLocationAddModal />}
                        {showClinicLocationEditModal && <ClinicLocationEditModal />}
                        {showTechnicianAddModal && <TechnicianAddModal />}
                        {showTechnicianEditModal && <TechnicianEditModal />}
                    </div>
                </Content>
            </Spin>
        </Layout>
    );
}

export default AdminDoctorsListPage;