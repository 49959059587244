import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { Row, Col } from 'antd';
import * as Constants from '../../../constants';
import PatientExamDtcIopEntry from './patient-exam-dtc-iop-entry';
import { setDtcValues } from '../../../reducers/patient-exam-slice';

type DtcIOPEntry = {
    od: number | null,
    os: number | null,
    key: number;
};

type ComponentProps = {
    side: 'od' | 'os',
    disabled: boolean,
    isSmartUpload?: boolean,
}

const MBPatientExamDtcIOP = ({ side, disabled, isSmartUpload  }: ComponentProps) => {

    const dtcValues = useCare1AppSelector(store => store.examData.dtc_values);
    // const dtcHour = useCare1AppSelector(store => store.examData.dtc_hour);
    // const odIop = useCare1AppSelector(store => store.examData.od_iop);
    // const osIop = useCare1AppSelector(store => store.examData.osIop);

    const pastDtcValues = useCare1AppSelector(store => store.examData.past_dtc_values);

    const dispatch = useCare1AppDispatch();

    const onDtcValueChange = (column: number, valueAsNumber: number | null) => {

        // Build the full DTC values array from the new value.
        if (dtcValues.length > column) {
            const newDtcValues = dtcValues.map((dtcValue, index) => index === column ?
                { ...dtcValue, [side]: valueAsNumber } : dtcValue);
            dispatch(setDtcValues(newDtcValues));
        } else {
            const newDtcSize = column + 1;
            const newDtcValues = [...dtcValues, ...Array(Math.max(newDtcSize - dtcValues.length, 0))
                .fill({})].map((dtcValue, index) => index === column ?
                    { ...dtcValues[index], [side]: valueAsNumber } : dtcValue);
            dispatch(setDtcValues(newDtcValues));
        }
        // Not handled in exam reducer
        // Refresh the label for this value's side.
        // let iopToUse = odIop;
        // if (side === Constants.OS) {
        //     iopToUse = osIop;
        // }

        // Not handled in exam reducer
        // dispatch(setDtcSummaryLabel(side, generateDtcSummaryLabel(side, dtcHour, newDtcValues, iopToUse)));

        // // Refresh the validity for the entire DTC section.
        // dispatch(setDtcValidity(validityCheck(newDtcValues)));
    }

    // Create intial dtcIOPEntries value, [{od:'',os'',key:0},{od:'',os'',key:1},{od:'',os'',key:2}]
    const dtcIOPEntries: DtcIOPEntry[] = [];
    for (let i = 0; i < Constants.REQUIRED_DTC_NUMBER; i += 1) {
        dtcIOPEntries.push({ od: null, os: null, key: i });
    }
    return (
        <Row gutter={isSmartUpload ? [0, 24] : [0, 0]}>
            {/* DTC-IOP 001, DTC-IOP 002, DTC-IOP 003, DTC-IOP 004, DTC-IOP 005, DTC-IOP 006 */}
            {dtcIOPEntries.map((dtcIOPEntry, column) => {
                const currentValue = dtcValues && dtcValues.length > column && side in dtcValues[column]
                    ? dtcValues[column][side]!
                    : dtcIOPEntry[side];
                return (
                    <Col span={24}>
                        <span className={`mb-dtc-iop-text ${side === 'os' ? 'no-dtc-iop-text' : ''}`}>IOP #{column+1}</span>
                        <PatientExamDtcIopEntry
                            key={dtcIOPEntry.key}
                            currentValue={currentValue}
                            onDtcValueChange={onDtcValueChange}
                            column={column}
                            disabled={disabled}
                            side={side}
                            dispatch={dispatch}
                            dtcValues={dtcValues}
                            placeholder={pastDtcValues && pastDtcValues.length > column && side in pastDtcValues[column] ?
                                (pastDtcValues[column][side]?.toString() || '') : ''}
                            colSpan={24}
                            addonBefore={side.toUpperCase()}
                            isSmartUpload={isSmartUpload}
                        />
                    </Col>
                );
            })}
        </Row>
    );
}

export default MBPatientExamDtcIOP;
