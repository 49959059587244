import React, { useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../static/css/components/admin-services-health-check-list-table.scss';
import { EditOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { compareStrings } from '../../helpers/sorting';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { FIRST_NAME_FIELD, LAST_NAME_FIELD } from '../../constants';
import { localizedText } from '../../localizedText';
import slugify from 'react-slugify';


interface IServicesHealthCheckListTable {
    key: React.Key;
    id: string;
    'service_name': string,
    [x: string]: any,
}

type AppProps = {
    tableList: IServicesHealthCheckListTable[],
    days: string[],
    title: string,
}

const AdminServicesHealthCheckTable = ({ tableList, days, title }: AppProps) => {
    const { OF_TEXT, ITEMS_TEXT } = localizedText;

    const [scrollClass, setScrollClass] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const handleServiceSelection = (record: IServicesHealthCheckListTable) => {
        window.open(`${process.env.REACT_APP_FRONTENDURL}/checked-services/${record.id}`, '_blank')?.focus();
    }

    const nameColumns: ColumnsType<IServicesHealthCheckListTable> = [
        {
            className: '_name-column',
            title: 'Service Name',
            dataIndex: 'service_name',
            sorter: (a, b) => compareStrings(a['service_name'], b['service_name']),
            render: (text, record) => <div data-testid={`'service_name'-${record.key}`}><a onClick={() => handleServiceSelection(record)}>{text}</a></div>
        }
    ];

    days.forEach((d: string) => {
        nameColumns.push({
            className: `d${slugify(d)}-column`,
            title: `${d}`,
            dataIndex: d,
            sorter: (a, b) => { return a[`${d}`] - b[`${d}`] },
            render: (text, record) => {
                if (text === 'DOWN') {
                    return <Tooltip
                        className={'recommendations-tooltip'}
                        placement='topLeft'
                        title={record[`${d}_error`]}
                    >
                        <div className="status-red" data-testid={`${d}-${record.key}`}>{text}</div>
                    </Tooltip>
                }
                else if (text === 'INVALID') {
                    return <Tooltip
                        className={'recommendations-tooltip'}
                        placement='topLeft'
                        title={record[`${d}_error`]}
                    >
                        <div className="status-yellow" data-testid={`${d}-${record.key}`}>{text}</div>
                    </Tooltip>
                }
                else {
                    return <div className="status-green" data-testid={`${d}-${record.key}`}>{text}</div>;
                }
            }
        })
    });

    const renderTitle = () => {
        const title = 'AI Services Health Check';
        return (
            <div className='title-container'>
                <span className='title'>{title} </span>
                <span className='title-tooltip'>
                    <Tooltip
                        className={'recommendations-tooltip'}
                        placement='topLeft'
                        title="DOWN: indicates the service is not running. INVALID: indicates the service returned unexpected data."
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </span>
            </div>);
    }

    return (
        <div className={'services-health-check-list-table'}>
            <div>{renderTitle()}</div>
            <div className={scrollClass}>
                <Table
                    columns={nameColumns}
                    dataSource={tableList}
                    pagination={false}
                />
            </div>
        </div>
    );
}

export default AdminServicesHealthCheckTable;