import { message } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';
import {
    ISmartUpload,
    setSmartUploadDataValues,
    updateSmartUploadData,
} from '../reducers/smart-upload-slice';


const useSmartUploadOnChangeSave = () => {

    const id = useCare1AppSelector(store => store.smartUpload.id);
    const dispatch = useCare1AppDispatch();

    const handleValueChange = async (
        changedValues: Partial<ISmartUpload>
    ) => {
       
        dispatch(setSmartUploadDataValues(changedValues));
        try {
            const res = await dispatch(
                updateSmartUploadData({
                    id,
                   ...changedValues,
                })
            ).unwrap();
            if (res?.error) throw new Error(res?.error);
        } catch (error) {
            console.error(error);
            const errorMessage =
                error instanceof Error ? error?.message : (error as string);

            message.error(errorMessage);
        }
    };

    return {
        handleValueChange,
    };
};

export default useSmartUploadOnChangeSave;
