import { Col, Flex, Row } from "antd";
import AdminPrivateNotes from "./admin-private-notes";
import PatientExamNotesToODAndGP from "../reviewer/patient-exam-notes-to-od-and-gp";
import PatientExamSecondOpinionSelectors from "../reviewer/patient-exam-second-opinion-selectors";
import PatientExamNotesToMOA from "../reviewer/patient-exam-notes-to-moa";
import PatientExamFUOptions from "../consultant/patient-exam-fu-options";
import PatientExamOMDCNotes from "../consultant/patient-exam-omdc-notes";
import commonLayoutStyles from '../../../static/css/components/common-layout.module.scss';
import PatientExamOMDRRETextInjector from "../reviewer/patient-exam-omdr-re-text-injector";
import PatientExamFUOptionsOMDRRE from "../reviewer/patient-exam-fu-options-omdr-re";
import styles from '../../../static/css/components/admin-pei-omdr-re-row.module.scss'

const AdminPeiOmdrReRow = () => {

    return (
        <Row gutter={4} className={styles.adminPeiOmdrReRow}>
            <Col span={3}>
                <Flex vertical gap='small' className={commonLayoutStyles.verticalFlex}>
                    <Col span={24} flex='0 1 295px'>
                        <PatientExamOMDRRETextInjector disabled={false} />
                    </Col>
                    <Col span={24} flex='1 1 auto'>
                        <AdminPrivateNotes />
                    </Col>
                </Flex>
            </Col>
            <Col span={12}>
                <PatientExamNotesToODAndGP disabled={false} />
            </Col>
            <Col span={9}>
                <Flex vertical gap='small' className={commonLayoutStyles.verticalFlex}>
                    <Row>
                        <Col span={11}>
                            <PatientExamFUOptionsOMDRRE disabled={false} />
                        </Col>
                        <Col span={13}>
                            <PatientExamSecondOpinionSelectors disabled={false} />
                        </Col>
                    </Row>
                    <Row>
                    <Col span={11}>
                        <PatientExamNotesToMOA disabled={false} />
                    </Col>
                        <Col span={13}>
                            <PatientExamFUOptions disabled={false} />
                        </Col>
                    </Row>
                
                    <Col span={24} flex='1 1 auto'>
                        <PatientExamOMDCNotes disabled={false} />
                    </Col>
                </Flex>
            </Col>
        </Row>
    );
};

export default AdminPeiOmdrReRow;