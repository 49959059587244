
import Cookies from 'universal-cookie';
import { developmentMode, getCsrfToken } from '../helpers/utilities';
import * as Constants from '../constants';

// Check the HTTP status of the given response and perform the right action.
function handleLoginResponse(response: Response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function makeCSRFRequest() {
    const requestOptions: RequestInit = {
        method: 'GET',
        credentials: 'include',
    };

    return fetch(process.env.REACT_APP_BACKENDURL + Constants.API_CSRF_COOKIE_URL, requestOptions)
        .then(() => getCsrfToken())
        .catch((error) => Promise.reject(error));
}

function login(userName: string, password: string, rememberMe: boolean) {
    const data = new FormData();
    data.append('username', userName);
    data.append('password', password);
    data.append('rememberMe', rememberMe.toString());

    return makeCSRFRequest().then((csrfToken) => {
        const requestOptions: RequestInit = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'X-CSRFToken': csrfToken,
            },
            body: data,
        };

        return fetch(process.env.REACT_APP_BACKENDURL + Constants.API_LOGIN_URL, requestOptions)
            .then(handleLoginResponse)
            .then((response) => {
                if (response.success) {
                     // Check if User is migrated or if they are an Admin
                    const userIsKenman = response.user_id === 12 &&  response.username === 'kgan';
                    if (response.is_migrated || response.is_superuser || response.is_admin || response.is_sales || response.is_science_user || userIsKenman) {
                        return response;
                    }
                    return Promise.reject(Constants.LOGIN_MIGRATION_ERROR_MESSAGE);
                } else {
                    throw new Error(response.error)
                }
            })
            .catch((error) => Promise.reject(error));
    });
}

function logout(csrfToken: string) {
    const requestOptions: RequestInit = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'X-CSRFToken': csrfToken,
        },
    };

    return fetch(process.env.REACT_APP_BACKENDURL + Constants.API_LOGOUT_URL, requestOptions)
        .then((response) => {
            if (developmentMode) {
                new Cookies().remove('care1csrftoken');
            } else {
                new Cookies().remove('care1csrftoken', { path: '/', domain: '.care1.ca' });
            }
            return response;
        })
        .catch((error) => Promise.reject(error));
}

function getLoginClinicFromCookie() {
    const CookiesClinic = new Cookies().get('loginclinic');
    if (!CookiesClinic) {
        return 'retina';
    }
    else {
        return CookiesClinic;
    }
}

function setLoginClinicToCookie(c: string | null) {
    new Cookies().set('loginclinic', c);
}

export const userService = {
    login,
    logout,
    getLoginClinicFromCookie,
    setLoginClinicToCookie,
};
