import React, { Fragment, useEffect, useMemo } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Input, Form, Tooltip } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { getTxAlgoKeywordOptions, addTxAlgoKeywordColors, renderTxAlgoKeywordString
    } from '../../helpers/patient-exam-convert';
import * as Constants from '../../constants';
import '../../../static/css/components/patient-exam-history-and-exam.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FieldData } from 'rc-field-form/lib/interface';

const PatientExamHistoryAndExam = ({ disabled }: { disabled: boolean }) => {
    const historyAndExam = useCare1AppSelector(store => store.examData['history_and_exam']);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const [ form ] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    // Get the color coded keywords for the component
    const txAlgo3Keywords = useCare1AppSelector(store => store.options.txAlgoKeywords);
    const txAlgoKeywordOptions = useMemo(() => {
        return getTxAlgoKeywordOptions(txAlgo3Keywords, 'historyMedsExam');
    }, [txAlgo3Keywords]);
    const keywordOptions = userIsOMDR ? txAlgoKeywordOptions : null;

    // Add the color code tags to the content string
    const applyKeywordColors = useMemo(() => {
        if(txAlgoKeywordOptions){
            return addTxAlgoKeywordColors(txAlgoKeywordOptions, historyAndExam);
        }
        return null;
        // eslint-disable-next-line
    }, [keywordOptions, userIsOMDR, historyAndExam]);

    // Side effects that we run after component render.
    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            history_and_exam: historyAndExam
        };
        form.setFieldsValue({newValidationFieldValues});
    }, [historyAndExam, form])

    // Ant Design handler. This is called whenever a field is changed by the user using Ant Design controls.
    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case 'history_and_exam':
                        dispatch(setExamDataValue(field.name[0], field.value));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    return (
        <Form form={form} onFieldsChange={onFieldsChange} >
            <Card className={'component-input-card history-and-exam'} bordered={false}>
                {/* MH 001 */}
                <span className="heading">{Constants.EXAM_HISTORY_TITLE}</span>
                {!userIsOMDR &&
                <Tooltip
                    placement='topRight'
                    overlayStyle={{ whiteSpace: 'pre-line' }}
                    title={`${Constants.EXAM_HISTORY_SHORT_TEXT}\n${Constants.EXAM_HISTORY_COPY_TEXT}\n${Constants.EXAM_HISTORY_CONSULT_TEXT}}`}
                >
                    <InfoCircleOutlined />
                </Tooltip>
                }
                {/* MH 002, MH 003, MH 004, MH 005 */}
                   { !userIsOMDR &&
                        <Form.Item help="" name={'history_and_exam'} initialValue={historyAndExam}>
                            <Input.TextArea
                                disabled={disabled}
                                className='component-input'
                                autoSize={false}
                                placeholder={Constants.EXAM_HISTORY_PLACEHOLDER}
                                data-testid='history-and-exam'
                            />
                        </Form.Item>
                   }
                   { userIsOMDR &&
                        <Form.Item>
                            <div className="component-input color-keyword-div" data-testid='history-and-exam'>
                                {
                                    applyKeywordColors && applyKeywordColors.map(
                                        (string, index) => (<Fragment key={index}>{renderTxAlgoKeywordString(string)}</Fragment>)
                                    )
                                }
                            </div>
                        </Form.Item>
                    }
            </Card>
        </Form>
    );
}

export default PatientExamHistoryAndExam;