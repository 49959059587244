import { TERSE_APPLANATION_OPTIONS } from "../constants";
import { IApplanationOption } from "../reducers/options-slice";

export const convertBackendApplanationToFrontend = (applanations: IApplanationOption[]) : IApplanationOption[] => {
    if (applanations) {
        return applanations.map((option) => {
            const frontendOption = TERSE_APPLANATION_OPTIONS.find((entry) => entry.value === option.value);
            return {
                value: option.value,
                label: frontendOption ? frontendOption.label : option.label,
            }
        });
    }
    return applanations;
}
