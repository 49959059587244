import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Row, Col, Tag } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { OMDC_NOTE_TEMPLATES } from '../../constants';
import '../../../static/css/components/patient-exam-fu-options.scss';

type ComponentProps = {
    disabled: boolean;
}

const PatientExamFUOptions = ({disabled}: ComponentProps) => {
    const omdcFuOptions = useCare1AppSelector(store => store.options.omdcFuOptions);
    const omdcNoteTemplates = useCare1AppSelector(store => store.examData[OMDC_NOTE_TEMPLATES]);

    const omdcFuOptionsId = omdcFuOptions.map(item => item.id);

    const dispatch = useCare1AppDispatch();

    const handleChange = (checked: boolean, optionId: number) => {
        
        if (checked) {
            const selectedOmdcOptions = omdcNoteTemplates.filter(item => !omdcFuOptionsId.includes(item));
            dispatch(setExamDataValue(OMDC_NOTE_TEMPLATES, [...selectedOmdcOptions, optionId]))
        } else {
            const selectedOmdcOptions = omdcNoteTemplates.filter(item => item !== optionId);
            dispatch(setExamDataValue(OMDC_NOTE_TEMPLATES,selectedOmdcOptions));
        }
    }

    return (
        <Card className={'component-input-card fu-options'} bordered={false}>
            <div className="heading">{'Recommended Follow-Up'}</div>
            <Row gutter={[8,8]} className='fuOptionsRow'>
                {omdcFuOptions.map(option =>
                    <Col span={12} key={option.id}>
                        <Tag.CheckableTag
                            checked={omdcNoteTemplates.includes(option.id)}
                            onChange={(checked) => handleChange(checked, option.id)}
                            className={disabled ? 'is-disabled' : ''}
                        >
                            {option.name}
                        </Tag.CheckableTag>
                    </Col>
                )}
            </Row>
        </Card>
    );
}

export default PatientExamFUOptions;