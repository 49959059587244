import { createSlice } from '@reduxjs/toolkit';

interface IAdminReports {
    showModal: boolean,
}

const initialState: IAdminReports = {
    showModal: false,
}

export const getAdminReportsExamVisitDateRequest = (startDate: string, endDate: string) => {

    const url : string = process.env.REACT_APP_BACKENDURL + `/data/admin/reports/exam_visit_date/${startDate}/${endDate}/`;

    window.open(url, '_reports')?.focus();
}

export const getAdminReportsExamCreateDateRequest = (startDate: string, endDate: string) => {

    const url = process.env.REACT_APP_BACKENDURL + `/data/admin/reports/exam_created_date/${startDate}/${endDate}/`;

    window.open(url, '_reports')?.focus();
}

export const getAdminReportsLastOmdrReviewedDateRequest = (startDate: string, endDate: string) => {

    const url = process.env.REACT_APP_BACKENDURL + `/data/admin/reports/last_omdr_reviewed_date/${startDate}/${endDate}/`;

    window.open(url, '_reports')?.focus();
}

export const getAdminReportsLastOmdReviewsDateRequest = (startDate: string, endDate: string) => {

    const url = process.env.REACT_APP_BACKENDURL + `/data/admin/reports/omd_reviews_date/${startDate}/${endDate}/`;

    window.open(url, '_reports')?.focus();
}

export const getAdminReportsIntegrationListReportRequest = (startDate: string, endDate: string) => {

    const url = process.env.REACT_APP_BACKENDURL + `/data/admin/reports/integration_list_report/${startDate}/${endDate}/`;

    window.open(url, '_reports')?.focus();
}

export const getAdminReportsBillingReportRequest = (startDate: string, endDate: string) => {

    const url = process.env.REACT_APP_BACKENDURL + `/data/admin/reports/billing_report/${startDate}/${endDate}/`;

    window.open(url, '_reports')?.focus();
}

export const getAdminReportsIGPReportRequest = (startDate: string, endDate: string) => {

    const url = process.env.REACT_APP_BACKENDURL + `/data/admin/reports/igp_report/${startDate}/${endDate}/`;

    window.open(url, '_reports')?.focus();
}

export const adminReports = createSlice({
    name: 'adminReports',
    initialState,
    reducers: {
        showReportModal: (state) => {
            state.showModal = true;
        },
        hideReportModal: (state) => {
            state.showModal = false;
        },
    },
});

export const { showReportModal, hideReportModal } = adminReports.actions;

export default adminReports.reducer;