import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { DEACTIVATE_CONFIRMATION_OK_TEXT, DEACTIVATE_CONFIRMATION_CANCEL_TEXT, DEACTIVATE_CONFIRMATION_MODAL_TITLE,
    DEACTIVATE_CONFIRMATION_MODAL_TEXT } from '../../constants';
import { getPatientDetailsRequest, openPatientDetailsModal } from '../../reducers/patient-details-slice';
import { useDeactivatePatientVisitRequestMutation } from '../../services/exam-api';
import { useLazyGetNotReadyExamsQuery } from '../../services/patient-api';

type ComponentProps = {
    disabled: boolean;
    visitID: number;
}

const DeactivateVisitButton = ({ disabled, visitID }: ComponentProps) => {

    const patientID = useCare1AppSelector(store =>store.patientDetails.details.id);
    const dispatch = useCare1AppDispatch();
    const odUsesReferralLetterPei = useCare1AppSelector(store => store.user.odUsesReferralLetterPei);
    const selectedPatientId = useCare1AppSelector(store => store.patientEdit.selectedPatientId);

    const [getNotReadyExams] = useLazyGetNotReadyExamsQuery();


    const [deactivatePatientVisitRequest] = useDeactivatePatientVisitRequestMutation();

    const confirmDeactivate = () =>{
        Modal.confirm({
            className: 'delete-confirmation-modal',
            title: DEACTIVATE_CONFIRMATION_MODAL_TITLE,
            content: DEACTIVATE_CONFIRMATION_MODAL_TEXT,
            icon: <InfoCircleOutlined />,
            okText: DEACTIVATE_CONFIRMATION_OK_TEXT,
            cancelText: DEACTIVATE_CONFIRMATION_CANCEL_TEXT,
            cancelButtonProps: { className: 'confirm-exit' },
            onOk: () => { deactivateVisit(); },
        });
    }

    const deactivateVisit = async () => {
        try{
            const response = await deactivatePatientVisitRequest(visitID).unwrap();
            if (response && response.success) {
                Modal.info({
                    className: 'info-modal',
                    title: `${response.success}`,
                });
                if (odUsesReferralLetterPei) {
                    getNotReadyExams(selectedPatientId);
                } else {
                    patientID && dispatch(getPatientDetailsRequest(patientID));
                    dispatch(openPatientDetailsModal());
                }
                
            } else if (response && response.error) {
                throw new Error(response?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    return (
        <div className="deactivate-visit-cell">
            <Button
                disabled={disabled}
                className="deactivate-visit-button"
                icon={<DeleteOutlined />}
                onClick={(event) => {
                    event.stopPropagation();
                    confirmDeactivate();
                }}
            />
        </div>
    );
}

export default DeactivateVisitButton;
