import { useCare1AppSelector, useCare1AppDispatch } from '../../apps/care1-hooks';
import { Radio, Col, Typography, Tooltip, Flex } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { localizedText } from '../../localizedText';
// import '../../../static/css/components/patient-exam-billing-rules.scss';
import PatientExamReportToggle from './patient-exam-report-toggle';
import ComponentRadioGroup from '../common/component-radio-group';
import { RadioChangeEvent } from 'antd/lib';


const PatientExamUploadTypeCare1 = ({ disabled }: { disabled: boolean }) => {
    const { NO_TEXT, YES_TEXT} = localizedText;

    const isUrgent = useCare1AppSelector(store => store.examData.is_urgent);
    const odWantsOmdReport = useCare1AppSelector(store => store.examData.od_wants_omd_report);
    const dispatch = useCare1AppDispatch();

    const handleIsUrgentChange = (e : RadioChangeEvent) => {
        dispatch(setExamDataValue('is_urgent', e.target.value));
        if (e.target.value === true) {
            dispatch(setExamDataValue('od_wants_omd_report', true));
        }
    }

    return (
            <>
                <Col span={24}>
                    <Flex justify='space-between' align='center'>
                        <Typography.Text>High Priority</Typography.Text>
                        <ComponentRadioGroup
                            isWarning
                            value={isUrgent}
                            handleRadioGroupChange={handleIsUrgentChange}
                        >
                            <Radio.Button
                                disabled={disabled}
                                value={false}
                            >
                                {NO_TEXT.toUpperCase()}
                            </Radio.Button>
                            {
                                odWantsOmdReport === false ? 
                                <Tooltip
                                    placement='topLeft'
                                    title='AI-only reports cannot be marked as high priority.'
                                    >
                                    <Radio.Button
                                        disabled={true}
                                        value={true}
                                    >
                                        {YES_TEXT.toUpperCase()}
                                    </Radio.Button>
                                </Tooltip>
                                :
                                <Radio.Button
                                    disabled={disabled}
                                    value={true}
                                >
                                    {YES_TEXT.toUpperCase()}
                                </Radio.Button>
                            }
                        </ComponentRadioGroup>
                    </Flex>
                </Col>
                <Col span={24}>
                    <PatientExamReportToggle disabled={disabled} />
                </Col>
            </>
    );
}

export default PatientExamUploadTypeCare1;