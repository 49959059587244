import { useState } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import {
    InfoCircleOutlined, ProfileOutlined, CalendarOutlined, 
    CloseCircleOutlined, CloudSyncOutlined, SearchOutlined
} from '@ant-design/icons';
import { Row, Select, Divider, Button, Tooltip, Input } from 'antd';
import {
    SCIENCE_FLAGGED_OPTIONS
} from '../../constants';
import '../../../static/css/shared/patient-list-control-bar.scss';
import '../../../static/css/components/patient-list-control-bar-science.scss';
import { IPatientList, clearAllFilters, getAdminPatientListRequest, getAdminSearchPatientListRequest, 
    setPatientListFieldData
} from '../../reducers/patient-list-slice';
import { clearPatientExamRoomsData } from '../../reducers/patient-exam-rooms-slice';
import { localizedText } from '../../localizedText';

const PatientListControlBarScience = () => {
    const { 
        CLEAR_FILTERS, GET_FILTERED_LIST, PATIENT_LIST_SEARCH_PLACEHOLDER, EXAM_VISIT_DATE, 
        FLAGGED_AND_READY_OMD, EXAM_PERIOD_OPTIONS } = localizedText;

    const flaggedFilter = useCare1AppSelector(store => store.patientList.adminFlaggedFilter);
    const periodFilter = useCare1AppSelector(store => store.patientList.adminPeriodFilter);

    const dispatch = useCare1AppDispatch();

    // Local state to track when a new filter selection is made.
    // A new filter selection will activate the "Get Filtered List" button.
    // Submitting "Get Filtered List" will deactivate the button.
    const [getFilteredListIsSubmitted, setGetFilteredListIsSubmitted] = useState(false);

    //const [bulkOperation, setBulkOperation] = useState(null);

    // Selecting at least on filter will activate the "Clear Filters" button
    // and the "Get Filter List" button (the latter if getFilteredListIsSubmitted is also true).
    const filterIsSelected = flaggedFilter || periodFilter;

    const sendSearchPatientListRequest = (value: string) => {
        if (value) {
            handleClearAllFilters();
            dispatch(setPatientListFieldData({ key: 'searchQuery', value }));
            dispatch(getAdminSearchPatientListRequest(value));
        } else {
            dispatch(setPatientListFieldData({ key: 'searchQuery', value: ''}));
        }
    }

    const sendFilteredAdminPatientListRequest = () => {
        dispatch(getAdminPatientListRequest());
        setGetFilteredListIsSubmitted(true);
    }

    // Function to set a filter selection
    const setFilterSelection = (filter: keyof IPatientList, value: boolean | string | number) => {
        // Set a patientList reducer value
        dispatch(setPatientListFieldData({ key: filter,  value }));

        // Reset get filtered list
        setGetFilteredListIsSubmitted(false);
    }

    // Function to clear a single filter selection
    const clearFilterSelection = (filter: keyof IPatientList) => {
        // Set a patientList reducer value
        dispatch(setPatientListFieldData({ key: filter, value: null }));
    }

    // Function to handle the selection of the Unflagged, Flagged and Miscellaneous filters selection
    const handleFlaggedFiltersSelection = (filter: keyof IPatientList, value: string) => {
        // Clear filters
        dispatch(setPatientListFieldData({ key: 'adminUnflaggedFilter', value: null }));
        dispatch(setPatientListFieldData({ key: 'adminFlaggedFilter', value: null }));
        dispatch(setPatientListFieldData({ key: 'adminMiscFilter', value: null }));

        // Set the selected filter value
        dispatch(setPatientListFieldData({ key: filter, value }));
        setGetFilteredListIsSubmitted(false);
    }

    const handleClearAllFilters = () => {        
        setGetFilteredListIsSubmitted(false);

        dispatch(clearAllFilters());

        // Clear Patient Exam Room data
        dispatch(clearPatientExamRoomsData());
    }

    return (
        <Row className={'patient-list-control-bar patient-list-control-bar-science'}>
            <Row className="control-bar-elements">
                <Input.Search
                    className="patient-list-search-input"
                    allowClear
                    placeholder={PATIENT_LIST_SEARCH_PLACEHOLDER}
                    prefix={<SearchOutlined />}
                    onSearch={sendSearchPatientListRequest}
                />

                <Divider type="vertical" className="patient-list-vertical-divider" />

                <Select
                    data-testid='exam-period-selector'
                    className={`dropdown-filter exam-period-filter${periodFilter ? ' is-selected' : ''}`}
                    popupClassName="patient-list-dropdown"
                    placeholder={<div><CalendarOutlined /> {EXAM_VISIT_DATE}</div>}
                    value={periodFilter}
                    allowClear={true}
                    onClear={() => clearFilterSelection('adminPeriodFilter')}
                    onSelect={(value) => setFilterSelection('adminPeriodFilter', value)}
                    filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {EXAM_PERIOD_OPTIONS && EXAM_PERIOD_OPTIONS.map((option) => (
                        <Select.Option data-testid='exam-period-option' key={option.key} value={option.key}>
                            <CalendarOutlined /> {option.label}
                        </Select.Option>
                    ))}
                </Select>
            </Row>
            { /* Row Two - Unflagged, Flagged and Miscellaneous filters */}
            <Row className="control-bar-elements">
                <Tooltip
                    className='unflagged-filter-tooltip'
                    placement='left'
                    overlayInnerStyle={{width: "530px"}}
                    title={
                        <div className='admin-tooltip-div'>
                            Retina*:
                                <ul>
                                    <li>Patient is NOT glaucoma patient; AND</li>
                                    <li>ERM, DM, AMD, Cataract or Cat Post OP is True; AND</li>
                                    <li>All Referral reasons are retina1; AND</li>
                                    <li>Referral type is not empty</li>
                                </ul>
                            Other*:
                                <ul>
                                    <li>Patient is NOT glaucoma patient; AND</li>
                                    <li>All of ERM, DM, AMD, Cataract or Cat Post OP are NOT True; AND</li>
                                    <li>Not all Referral reasons are retina1; AND</li>
                                    <li>Referral type is not empty</li>
                                </ul>
                            GLC-P*:
                                <ul>
                                    <li>Patient status is P (stable); AND</li>
                                    <li>Patient was glaucoma (determined by previous visits)</li>
                                </ul>
                            GLC-BO*:
                                <ul>
                                    <li>Patient status is BO (observe); AND</li>
                                    <li>Patient was glaucoma (determined by previous visits)</li>
                                </ul>
                            GLC-BT*:
                                <ul>
                                    <li>Patient status is BT (treat); AND</li>
                                    <li>Patient was glaucoma (determined by previous visits)</li>
                                </ul>
                            GDGVF*:
                                <ul>
                                    <li>Patient is Glaucoma; AND</li>
                                    <li>G is found in OS AND OD BDisc; AND</li>
                                    <li>G is found in OS AND OD BField</li>
                                </ul>
                            BDGVF*:
                                <ul>
                                    <li>Patient is Glaucoma; AND</li>
                                    <li>G is NOT found in OS OR OD BDisc; AND</li>
                                    <li>G is found in OS AND OD BField</li>
                                </ul>
                            GDBVF*:
                                <ul>
                                    <li>Patient is Glaucoma; AND</li>
                                    <li>G is found in OS AND OD BDisc; AND</li>
                                    <li>G is NOT found in OS OR OD BField</li>
                                </ul>
                            BCBVF*:
                                <ul>
                                    <li>Patient is Glaucoma; AND</li>
                                    <li>G is NOT found in OS OR OD BDisc; AND</li>
                                    <li>G is NOT found in OS OR OD BField</li>
                                </ul>
                        </div>
                    }
                >
                    <InfoCircleOutlined />
                </Tooltip>

                <Select
                    data-testid='flagged-filter'
                    className={`dropdown-filter flagged-filter${flaggedFilter ? ' is-selected' : ''}`}
                    popupClassName="patient-list-dropdown"
                    placeholder={<div><ProfileOutlined /> {FLAGGED_AND_READY_OMD}</div>}
                    value={flaggedFilter}
                    allowClear={true}
                    onClear={() => clearFilterSelection('adminFlaggedFilter')}
                    onSelect={(value) => handleFlaggedFiltersSelection('adminFlaggedFilter', value)}
                    filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {SCIENCE_FLAGGED_OPTIONS && SCIENCE_FLAGGED_OPTIONS.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            <ProfileOutlined /> {option.value}
                        </Select.Option>
                    ))}
                </Select>
                
                <Button
                    type='primary'
                    className='get-filtered-list-button'
                    onClick={sendFilteredAdminPatientListRequest}
                    disabled={!filterIsSelected || getFilteredListIsSubmitted}
                >
                    <CloudSyncOutlined /> {GET_FILTERED_LIST}
                </Button>

                <Button
                    className='clear-all-filters-button'
                    type='primary'
                    onClick={handleClearAllFilters}
                    disabled={!filterIsSelected}
                >
                    <CloseCircleOutlined /> {CLEAR_FILTERS}
                </Button>
            </Row>
        </Row>
    );
}

export default PatientListControlBarScience;