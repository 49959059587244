import { useCare1AppSelector } from '../apps/care1-hooks';
import { Row, Col } from 'antd';
import * as Constants from '../constants';

// Components
import OMDSubmitButtons from '../components/reviewer/omd-submit-buttons';
import PatientExamAllergies from '../components/integrated/patient-exam-allergies';
import PatientExamConversation from '../components/retina/patient-exam-conversation';
import PatientExamExtraImages from '../components/retina/patient-exam-extra-images';
import PatientExamFundus from '../components/retina/patient-exam-fundus';
import PatientExamFundusImageHistory from '../components/image-histories/patient-exam-fundus-image-history';
import PatientExamHeader from '../components/retina/header/patient-exam-header';
import PatientExamOCTMacula from '../components/retina/patient-exam-oct-macula';
import PatientExamOCTRnfl from '../components/integrated/patient-exam-oct-rnfl';
import PatientExamODNotes from '../components/integrated/patient-exam-od-notes';
import PatientExamOMDHistory from '../components/integrated/patient-exam-omd-history';
import PatientExamOMDCNotes from '../components/consultant/patient-exam-omdc-notes';
import PatientExamOCTMaculaImageHistory from '../components/image-histories/patient-exam-oct-macula-image-history';
import PatientExamOCTRNFLImageHistory from '../components/image-histories/patient-exam-oct-rnfl-image-history';
import PatientExamStereoViewer from '../components/integrated/patient-exam-stereo-viewer';
import PatientExamVF from '../components/integrated/patient-exam-vf';
import PatientExamVFImageHistory from '../components/image-histories/patient-exam-vf-image-history';
import PatientExamIOPHistory from '../components/integrated/patient-exam-iop-history';
import PatientExamUntilYesterday from '../components/integrated/patient-exam-until-yesterday';
import PatientExamOngoing from '../components/integrated/patient-exam-ongoing';
import PatientExamComorbidities from '../components/integrated/patient-exam-comorbidities'
import PatientExamOMDReviewerNotes from '../components/consultant/patient-exam-omd-reviewer-notes';
import PatientExamFUOptions from '../components/consultant/patient-exam-fu-options';
import ReferralLetterOmdrPdfViewer from '../components/reviewer/referral-letter-omdr-pdf-viewer';

// Helpers
import '../../static/css/pages/omdc-patient-exam-page.scss';
import { localizedText } from '../localizedText';
import PatientExamChiefComplaint from '../components/retina/patient-exam-chief-complaint';
import ReferralLetterExamMetrics from '../components/integrated/metrics/referral-letter-exam-metrics';
import { patientIsGlaucoma } from '../helpers/diagnosis-convert';
import IopBcvaGraphBand from '../components/integrated/iop-bcva-graph-band';
import OmdcReviewerBand from '../components/consultant/patient-exam-omd-consultant-band';

// Actions

const OMDCReferralLetterExamPage = () => {
    const { PHOTO_DROP_TEXT_SHORT } = localizedText;

    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const examIsRE = useCare1AppSelector(store => store.examData.exam_is_retina_only);

    const diagnosisEntries = useCare1AppSelector(store => store.diagnosis.entries);
    const omdcStatus = useCare1AppSelector(store => store.examData.omdc_status);
    const glcS = useCare1AppSelector(store => store.examData.rr_glc_suspect);
    const glc = useCare1AppSelector(store => store.examData.rr_glc);
    const narrowAngles = useCare1AppSelector(store => store.examData.rr_narrow_angles);
    const examOMDC = useCare1AppSelector(store => store.examData.omdc);
    const omdcId = useCare1AppSelector(store => store.user.doctorID);
    
    const isReadOnly = userIsOMDC;
    const omdcInputComponentReadOnly = (omdcStatus !== 'ready' || examOMDC !== omdcId);

    const isGlaucoma = patientIsGlaucoma(diagnosisEntries, { glc, glcS, narrowAngles });

    return (
        <>
            <Row>
                <PatientExamHeader />
            </Row>

            <Row gutter={8} className='complaints-metrics-history-row'>
                <Col span={3}>
                    <PatientExamChiefComplaint disabled={isReadOnly} />
                </Col>
                <Col span={4}>
                    <PatientExamODNotes disabled={isReadOnly} />
                </Col>
                <Col span={12} className='exam-metrics'>
                    <ReferralLetterExamMetrics disabled={isReadOnly} />
                </Col>
                <Col span={3}>
                    <PatientExamOMDHistory disabled={isReadOnly} />
                </Col>
                <Col span={2}>
                    <PatientExamAllergies disabled={isReadOnly} />
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={6}>
                    <PatientExamFundus
                            tabbed={!examIsRE}
                            disabled={isReadOnly}
                            className="patient-exam-fundus"
                            side={Constants.RIGHT_SIDE_TEXT}
                        />
                </Col>
                <Col span={3}>
                    <PatientExamOCTMacula
                        dropText={PHOTO_DROP_TEXT_SHORT}
                        disabled={isReadOnly}
                        side={Constants.RIGHT_SIDE_TEXT}
                        tabbed={true}
                    />
                    <PatientExamOCTRnfl disabled={isReadOnly} side={Constants.RIGHT_SIDE_TEXT} />
                </Col>
                <Col span={3}>
                    <PatientExamVF disabled={isReadOnly} side={Constants.RIGHT_SIDE_TEXT} />
                </Col>
                <Col span={3}>
                    <PatientExamVF disabled={isReadOnly} side={Constants.LEFT_SIDE_TEXT} />
                </Col>
                <Col span={3}>
                    <PatientExamOCTMacula
                        dropText={PHOTO_DROP_TEXT_SHORT}
                        disabled={isReadOnly}
                        side={Constants.LEFT_SIDE_TEXT}
                        tabbed={true}
                    />
                    <PatientExamOCTRnfl disabled={isReadOnly} side={Constants.LEFT_SIDE_TEXT} />
                </Col>
                <Col span={6}>
                    <PatientExamFundus
                        tabbed={!examIsRE}
                        disabled={isReadOnly}
                        className="patient-exam-fundus"
                        side={Constants.LEFT_SIDE_TEXT}
                    />
                </Col>
            </Row>

            {isGlaucoma &&
                <Row gutter={8}>
                    <Col span={6} className="pei-iop-history-col">
                        <PatientExamIOPHistory disabled={isReadOnly} />
                    </Col>
                    <Col span={6} className="pei-until-yesterday-col">
                        <PatientExamUntilYesterday disabled={isReadOnly} />
                    </Col>
                    <Col span={6} className="pei-ongoing-col">
                        <PatientExamOngoing disabled={isReadOnly} />
                    </Col>
                    <Col span={6} className="pei-comorbidities-col">
                        <PatientExamComorbidities disabled={isReadOnly} />
                    </Col>
                </Row>
            }

            <OmdcReviewerBand disabled={omdcInputComponentReadOnly} />

            <PatientExamStereoViewer inGlaucomaWorksheet={false} />

            <ReferralLetterOmdrPdfViewer />

            <PatientExamExtraImages
                disabled={isReadOnly}
                className="patient-exam-extra-images"
            />
            <IopBcvaGraphBand />
            <Row gutter={8}>
                <Col span={24}>
                    <PatientExamConversation />
                </Col>
            </Row>
            {/* START | Section 10 - VF History band */}
            <Row gutter={8}>
                <PatientExamVFImageHistory />
            </Row>
            {/* END | Section 10 - VF band */}

            {/* START | Section 11 - RNFL History band */}
            <Row gutter={8}>
                <PatientExamOCTRNFLImageHistory />
            </Row>
            {/* END | Section 11 - RNFL History band */}

            {/* START | Section 12 - Macula History band*/}
            <Row gutter={8}>
                <PatientExamOCTMaculaImageHistory orderBy={'asc'} showHint={true} showBaselineBorder={true} showEmptyRow={true} />
            </Row>
            {/* END | Section 12 - Macula History band */}
            {/* START |  Section 13 - Fundus History band */}
            <Row gutter={8}>
                <PatientExamFundusImageHistory />
            </Row>
            {/* END | Section 13 - Fundus History band */}
            <Row>
                <br />
                <br />
            </Row>
        </>
    );
}

export default OMDCReferralLetterExamPage;
