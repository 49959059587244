
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { OMDC_REVIEW_NOTES_FIELD } from '../../constants';
import { localizedText } from '../../localizedText';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import ComponentTextareaCard from '../component-textarea-card';

type ComponentProps = {
    disabled?: boolean;
};

const PatientExamOMDCNotes = ({ disabled }: ComponentProps) => {
    const { OMDC_NOTES_HEADING } = localizedText;
    const OMDCNotes = useCare1AppSelector(store => store.examData[OMDC_REVIEW_NOTES_FIELD]);

    const dispatch = useCare1AppDispatch();
    return (
        <ComponentTextareaCard
            disabled={disabled}
            title={OMDC_NOTES_HEADING}
            testId='omdc-notes'
            value={OMDCNotes}
            handleChange={(e) => dispatch(
                setExamDataValue(OMDC_REVIEW_NOTES_FIELD, e.target.value)
            )}
        />
    );
};

export default PatientExamOMDCNotes;
