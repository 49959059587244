import { Card, Tag, Row, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IExamData, setExamDataValue } from '../../reducers/patient-exam-slice';
import { refreshTxAlgo3Request } from '../../reducers/txalgo3-slice';
import { localizedText } from '../../localizedText';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/patient-exam-omdr-cd-values-band.scss';

const { CheckableTag } = Tag;

const PatientExamOMDRCDValuesBand = () => {
    const { CD_VALUES_BAND_TOOLTIP_TEXT, VERTICAL_CD_VALUES } = localizedText;
    const rightCD = useCare1AppSelector(store => store.examData.right_cd);
    const leftCD = useCare1AppSelector(store => store.examData.left_cd);
    const osCD = useCare1AppSelector(store => store.examData.os_cd);
    const odCD = useCare1AppSelector(store => store.examData.od_cd);
    const pastRightCD = useCare1AppSelector(store => store.examData.past_right_cd);
    const pastLeftCD = useCare1AppSelector(store => store.examData.past_left_cd);
    const omdOD = useCare1AppSelector(store => store.examData.omd_od);
    const isGlaucoma = useCare1AppSelector(store => store.examData.is_glaucoma);
    const examId = useCare1AppSelector(store => store.examData.id);

    const dispatch = useCare1AppDispatch();

    const cdValues = ['', '0.1', '0.2', '0.3', '0.4', '0.5', '0.6', '0.7', '0.8', '0.9'];

    const handleChange = (checked: boolean, side: string, value: string) => {
        if (checked && examId){
            dispatch(setExamDataValue(`${side}_cd` as keyof IExamData, value));
            isGlaucoma && dispatch(refreshTxAlgo3Request({exam_id: examId, [`${side}_cd`]: value}));

            if(side === 'right' && omdOD){
                //omdOD="Baseline IOP = XX-XX OD, XX-XX OS off gtts. C/D could be XXX OD and XXX OS, XXX(F/U AIM q XXX mo, OD IOP AIM XXX, OS IOP AIM XXX, 5YR = XXX%)."
                const cdValue = value ? value : 'XXX';
                const text_after_shoot_in = omdOD.replace(/(Baseline IOP = )(.*)(C\/D could be )(.*)( OD and )(.*)/, `${RegExp.$1}${RegExp.$2}${RegExp.$3}${cdValue}${RegExp.$5}${RegExp.$6}`);
                dispatch(setExamDataValue(`omd_od`, text_after_shoot_in))
            } else if (side === 'left' && omdOD) {
                //omdOD="Baseline IOP = XX-XX OD, XX-XX OS off gtts. C/D could be XXX OD and XXX OS, XXX(F/U AIM q XXX mo, OD IOP AIM XXX, OS IOP AIM XXX, 5YR = XXX%)."
                const cdValue = value ? value : 'XXX';
                const text_after_shoot_in = omdOD.replace(/(Baseline IOP = )(.*)(C\/D could be )(.*)( OD and )(.*)( OS,)(.*)/, `${RegExp.$1}${RegExp.$2}${RegExp.$3}${RegExp.$4}${RegExp.$5}${cdValue}${RegExp.$7}${RegExp.$8}`);
                dispatch(setExamDataValue(`omd_od`, text_after_shoot_in))
            }
        }
    }

    // calculate different className for CD Values
    // underline -> current os/od CD value
    // past-cd-value (color: #a9a9a9) -> past left/right CD value
    const cdClassName = (side: string, cdValue: string) => {
        let underlineClassName = '';
        let pastCdClassName = '';
        const isDisabled = isGlaucoma ? '' : 'is-disabled';
        if (side === 'right') {
            underlineClassName = Number(odCD) === Number(cdValue) ? 'underline' : '';
            pastCdClassName = Number(pastRightCD) === Number(cdValue) ? 'past-cd-value' : '';
        } else {
            underlineClassName = Number(osCD) === Number(cdValue) ? 'underline' : '';
            pastCdClassName = Number(pastLeftCD) === Number(cdValue) ? 'past-cd-value' : '';
        }
        const notSelectedClassName = !cdValue ? 'not-selected-xxx' : '';
        return `${notSelectedClassName} ${underlineClassName} ${pastCdClassName} ${isDisabled}`
    }

    return (
        <Card className={'component-input-card cd-values-band'} bordered={false}>
            <Row>
                <div className="heading">
                    {VERTICAL_CD_VALUES}
                    <Tooltip placement='topRight' title={CD_VALUES_BAND_TOOLTIP_TEXT}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
            </Row>
            <div className='component-body'>

                <div className='right-cd'>
                    {
                        cdValues.map(cdValue =>
                            <CheckableTag
                                checked={Number(rightCD === 'None' ? '' : rightCD) === Number(cdValue)}
                                key={`key_right_${cdValue}`}
                                onChange={checked => handleChange(checked, 'right', cdValue)}
                                className={cdClassName('right', cdValue)}
                            >
                                {cdValue ? cdValue : 'XXX'}
                            </CheckableTag>)
                    }
                </div>
                <div className='left-cd'>
                    {
                        cdValues.map(cdValue =>
                            <CheckableTag
                                checked={Number(leftCD === 'None' ? '' : leftCD) === Number(cdValue)}
                                key={`key_left_${cdValue}`}
                                onChange={checked => handleChange(checked, 'left', cdValue)}
                                className={cdClassName('left', cdValue)}
                            >
                                {cdValue ? cdValue : 'XXX'}
                            </CheckableTag>)
                    }
                </div>


            </div>
        </Card>
    );

}

export default PatientExamOMDRCDValuesBand;