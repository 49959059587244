import { useEffect, useState } from 'react';
import { Table} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

import { localizedText } from '../../localizedText';
import { ISalesIntegrationReportItem } from '../../reducers/sales-integration-report-slice';
import '../../../static/css/components/sales-integration-report-table.scss';


interface ISalesIntegrationReportTableItemWithKey extends ISalesIntegrationReportItem {
    key: number;
}

interface ComponentProps {
    salesIntegrationReport: ISalesIntegrationReportItem[];
    salesIntegrationWeekColumn: string[];
}

const SalesIntegrationReportTable = ({ salesIntegrationReport, salesIntegrationWeekColumn }: ComponentProps) => {

    const { OF_TEXT, ITEMS_TEXT } = localizedText;

    const [scrollClass, setScrollClass] = useState('');

    const reports: ISalesIntegrationReportTableItemWithKey[] = salesIntegrationReport.map((v: ISalesIntegrationReportItem) => { return { ...v, key: v['id'] } });

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const formatDateRange = (sunday: string): string => {
        const startDate = dayjs(sunday).format('YYYY-MM-DD');
        const endDate = dayjs(sunday).add(6, 'days').format('YYYY-MM-DD');

        const startMoment = dayjs(startDate);
        const endMoment = dayjs(endDate);

        // Format start and end dates
        const startFormatted = startMoment.format('MMM D');
        const startFormattedYear = startMoment.format('MMM D YYYY');
        const endFormatted = endMoment.format('MMM D YYYY');

        // Check if the year is the same for start and end dates
        const isSameYear = startMoment.year() === endMoment.year();

        // Create the final formatted date range
        return isSameYear ? `${startFormatted} - ${endFormatted}` : `${startFormattedYear} - ${endFormatted}`;
    };

    const columns: ColumnsType<ISalesIntegrationReportTableItemWithKey> = [{
        fixed: 'left',
        className: 'program-column',
        title: 'Program',
        dataIndex: 'program',
        width: '5.5%',
        // sorter: (a, b) => compareStrings(a['program'], b['program']),
        render: (text, record) => <div data-testid={`program-${record.id}`}>{text}</div>
    }, {
        fixed: 'left',
        className: 'province-column',
        title: 'Province',
        dataIndex: 'province',
        width: '8%',
        // sorter: (a, b) => compareStrings(a['province'], b['province']),
        render: (text, record) => <div data-testid={`province-${record.id}`}>{text}</div>
    }, {
        fixed: 'left',
        className: 'region-column',
        title: 'Region',
        dataIndex: 'region',
        // sorter: (a, b) => compareStrings(a['region'], b['region']),
        render: (text, record) => <div data-testid={`region-${record.id}`}>{text}</div>
    }, {
        fixed: 'left',
        className: 'odgrouppractice-column',
        title: 'OD Group Practice',
        dataIndex: 'odgrouppractice',
        // sorter: (a, b) => compareStrings(a['odgrouppractice'], b['odgrouppractice']),
        render: (text, record) => <div data-testid={`odgrouppractice-${record.id}`}>{text}</div>
    }, {
        fixed: 'left',
        className: 'doctor-column',
        title: 'Doctor',
        dataIndex: 'doctor',
        // sorter: (a, b) => compareStrings(a['doctor'], b['doctor']),
        render: (text, record) => <div data-testid={`doctor-${record.id}`}>{text}</div>
    }];

    // add week column
    salesIntegrationWeekColumn.forEach((v, i) => {
        columns.push({
            className: `${v}-column`,
            title: formatDateRange(v),
            dataIndex: v,
            render: (text, record) => <div data-testid={`${v}-${record.id}`}>{text === 0 ? '' : text}</div>
        });
    });

    return (
        <div className={'patient-list-table sales-integration-report-table tableContent'}>
            <div className={scrollClass}>
                <Table
                    columns={columns}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 30,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    dataSource={reports}
                    scroll={{ x: 800 + salesIntegrationWeekColumn.length * 170 }}
                    sticky={true}
                />
            </div>
        </div>
    );

}

export default SalesIntegrationReportTable;
