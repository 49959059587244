import { Fragment } from 'react';
import { useCare1AppSelector } from '../apps/care1-hooks';
import { Row, Card, Col } from 'antd';
import * as Constants from '../constants';

// Components
import PatientExamBCVA from '../components/retina/patient-exam-bcva';
import PatientExamBCVAGraph from '../components/integrated/patient-exam-bcva-graph';
import PatientExamChiefComplaint from '../components/retina/patient-exam-chief-complaint';
import PatientExamConversation from '../components/retina/patient-exam-conversation';
import PatientExamExtraImages from '../components/retina/patient-exam-extra-images';
import PatientExamFundus from '../components/retina/patient-exam-fundus';
import PatientExamFundusImageHistory from '../components/image-histories/patient-exam-fundus-image-history';
import PatientExamHeader from '../components/retina/header/patient-exam-header';
import PatientExamHistoryAndExam from '../components/retina/patient-exam-history-and-exam';
import PatientExamOCTMacula from '../components/retina/patient-exam-oct-macula';
import PatientExamOCTMaculaImageHistory from '../components/image-histories/patient-exam-oct-macula-image-history';

// Helpers
import '../../static/css/pages/omdr-patient-exam-page.scss';
import { localizedText } from '../localizedText';
import OmdrReReviewerBand from '../components/reviewer/patient-exam-omdr-re-reviewer-band';

const OMDRStandardReferralLetterExamPage = () => {
    const { BCVA_GRAPH_TEXT, PHOTO_DROP_TEXT_SHORT } = localizedText;


    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const examStatus = useCare1AppSelector(store => store.examData.exam_status);

    const isReadOnly = userIsOMDR;
    const isOMDReviewed = examStatus === 'reviewed';

    return (
        <>
            <Row>
                <PatientExamHeader />
            </Row>

            {/* START | Section 1 - Left images panel */}
            <Row gutter={8}>
                <Col span={8} className="dropzones-container">
                    <PatientExamFundus
                        tabbed={false}
                        disabled={isReadOnly}
                        className="patient-exam-fundus"
                        side={Constants.RIGHT_SIDE_TEXT}
                    />
                    <Row gutter={8}>
                        <Col span={24}>
                            <PatientExamOCTMacula
                                dropText={PHOTO_DROP_TEXT_SHORT}
                                disabled={isReadOnly}
                                side={Constants.RIGHT_SIDE_TEXT}
                                tabbed={false} />

                        </Col>
                    </Row>
                </Col>
                {/* END | Section 1 - Left images panel */}

                {/* START | Section 2 - Middle diagnostics panel */}
                <Col span={8}>
                    <Fragment>
                        <Row gutter={8}>
                            <PatientExamChiefComplaint disabled={isReadOnly} />
                        </Row>
                        <Row>
                            <Card className={'patient-exam-re-bcva'} bordered={false}>
                                <Row>
                                    <label className="heading">{'BCVA:'}</label>
                                </Row>
                                <Col span={12} className={'bcva-side'}>
                                    <Row>
                                        <div className={'bcva-label'}>
                                            <label className="heading">{'Right Eye:'}</label>
                                        </div>
                                        <div className={'bcva-value'}>
                                            <PatientExamBCVA side='od' disabled={true}
                                            />
                                        </div>
                                    </Row>
                                </Col>
                                <Col span={12} className={'bcva-side'}>
                                    <Row>
                                        <div className={'bcva-label os'}>
                                            <label className="heading left-eye">{'Left Eye:'}</label>
                                        </div>
                                        <div className={'bcva-value os'}>
                                            <PatientExamBCVA side='os' disabled={true}
                                            />
                                        </div>
                                    </Row>
                                </Col>
                            </Card>
                        </Row>
                    </Fragment>
                    <Row gutter={8}>
                        <Col span={24}>
                            <Fragment>
                                <Card className="empty-diagnostics-card" bordered={false} />
                                <PatientExamHistoryAndExam disabled={isReadOnly} />
                            </Fragment>
                        </Col>
                    </Row>
                </Col>
                {/* END | Section 2 - Middle diagnostics panel */}

                {/* START | Section 3 - Right images panel */}
                <Col span={8} className="dropzones-container">
                    <PatientExamFundus
                        tabbed={false}
                        disabled={isReadOnly}
                        className="patient-exam-fundus"
                        side={Constants.LEFT_SIDE_TEXT}
                    />
                    <Row gutter={8}>
                        <Col span={24}>
                            <PatientExamOCTMacula
                                dropText={PHOTO_DROP_TEXT_SHORT}
                                disabled={isReadOnly}
                                side={Constants.LEFT_SIDE_TEXT}
                                tabbed={false}
                            />
                        </Col>
                    </Row>
                </Col>
                {/* END | Section 3 - Left images panel */}
            </Row>

            {/* START | Section 5 RE - OMDR RE Reviewer band */}
            <OmdrReReviewerBand isOMDReviewed={isOMDReviewed} />
            {/* END | Section 5 - OMDR RE Reviewer band */}

            {/* START | Section 7 - Extra images band */}
            <PatientExamExtraImages
                disabled={isReadOnly}
                className="patient-exam-extra-images"
            />
            {/* END | Section 7 - Extra Images band */}

            {/* START | Section 8a RE exam - Graph Bands */}
            <Row gutter={8}>
                <Col span={12}>
                    <Card className="patient-exam-bcva-graph" bordered={false}>
                        <div className="heading">{`${BCVA_GRAPH_TEXT} OD`}</div>
                        <Row>
                            <PatientExamBCVAGraph side={'od'} />
                        </Row>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card className="patient-exam-bcva-graph" bordered={false}>
                        <div className="heading">{`${BCVA_GRAPH_TEXT} OS`}</div>
                        <Row>
                            <PatientExamBCVAGraph side={'os'} />
                        </Row>
                    </Card>
                </Col>
            </Row>
            {/* END | Section 8a RE exam - Graph Bands */}

            {/* START | Section 9 - Conversation band */}
            <Row gutter={8}>
                <Col span={24}>
                    <PatientExamConversation />
                </Col>
            </Row>
            {/* END | Section 9 - Conversation band */}

            <Fragment>
                {/* START | Section 12 - Macula History band*/}
                <Row gutter={8}>
                    <PatientExamOCTMaculaImageHistory orderBy={'asc'} showHint={true} showBaselineBorder={true} showEmptyRow={true} />
                </Row>
                {/* END | Section 12 - Macula History band */}
                {/* START |  Section 13 - Fundus History band */}
                <Row gutter={8}>
                    <PatientExamFundusImageHistory />
                </Row>
                {/* END | Section 13 - Fundus History band */}
            </Fragment>
        </>
    );
}

export default OMDRStandardReferralLetterExamPage;
