import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_REMOVE_DROPZONE_IMG_URL, EXTRA_IMAGES, LEFT_FUNDUS_FIELD,  LEFT_OCT_MACULA_FIELD,
    LEFT_OCT_RNFL_FIELD, LEFT_STEREO_FIELD, LEFT_VF_FIELD, RIGHT_FUNDUS_FIELD,
    RIGHT_OCT_MACULA_FIELD, RIGHT_OCT_RNFL_FIELD, RIGHT_STEREO_FIELD, RIGHT_VF_FIELD
} from '../constants';

import { getCsrfToken } from '../helpers/utilities';
import { apiRequest } from '../services/api-request';
import { logout } from './user-slice';
import { AppDispatch } from "../stores/retina-enabled-store";

export interface IImage {
    photo: string;
    examId: number;
    csrfToken: string;
}

export const dropzoneNames = [EXTRA_IMAGES, LEFT_FUNDUS_FIELD,  LEFT_OCT_MACULA_FIELD,
    LEFT_OCT_RNFL_FIELD, LEFT_STEREO_FIELD, LEFT_VF_FIELD, RIGHT_FUNDUS_FIELD, 
    RIGHT_OCT_MACULA_FIELD, RIGHT_OCT_RNFL_FIELD, RIGHT_STEREO_FIELD, RIGHT_VF_FIELD,
    'cardBackImage', 'cardFrontImage', 'od_referral_letter',
    'smart_upload_referral_letter'] as const;

export type DropzoneT = typeof dropzoneNames[number]

export interface IDropzoneInitialState {
    operationRunning: boolean;
    lastOperationType: string;
    photo: File | '';
    error: string;
    response: string;
    thumbnailUrl: string;
    percentage: number;
    lightboxOn: boolean;
}

const initialState: IDropzoneInitialState = {
    operationRunning: false,
    lastOperationType: '',
    photo: '',
    error: '',
    response: '',
    thumbnailUrl: '',
    percentage: 0,
    lightboxOn: false,
};

export const clearDropzoneData = () => (dispatch: AppDispatch) => {

    const extraImages = dropzoneSlice[EXTRA_IMAGES]?.actions;
    const leftFundus = dropzoneSlice[LEFT_FUNDUS_FIELD]?.actions;
    const leftStereo = dropzoneSlice[LEFT_STEREO_FIELD]?.actions;
    const leftOctMacula = dropzoneSlice[LEFT_OCT_MACULA_FIELD]?.actions;
    const leftOctRnfl = dropzoneSlice[LEFT_OCT_RNFL_FIELD]?.actions;
    const leftVf = dropzoneSlice[LEFT_VF_FIELD]?.actions;
    const rightFundus = dropzoneSlice[RIGHT_FUNDUS_FIELD]?.actions;
    const rightStereo = dropzoneSlice[RIGHT_STEREO_FIELD]?.actions;
    const rightOctMacula = dropzoneSlice[RIGHT_OCT_MACULA_FIELD]?.actions;
    const rightOctRnfl = dropzoneSlice[RIGHT_OCT_RNFL_FIELD]?.actions;
    const rightVf = dropzoneSlice[RIGHT_VF_FIELD]?.actions;
    const odReferralLetter = dropzoneSlice.od_referral_letter?.actions;
    dispatch(extraImages.clearDropzone());
    dispatch(leftFundus.clearDropzone());
    dispatch(leftStereo.clearDropzone());
    dispatch(leftOctMacula.clearDropzone());
    dispatch(leftOctRnfl.clearDropzone());
    dispatch(leftVf.clearDropzone());
    dispatch(rightFundus.clearDropzone());
    dispatch(rightStereo.clearDropzone());
    dispatch(rightOctMacula.clearDropzone());
    dispatch(rightOctRnfl.clearDropzone());
    dispatch(rightVf.clearDropzone());
    dispatch(odReferralLetter.clearDropzone())

    // uncomment the following when these VF_10_2, OctAngle, GCC dropzone fields are added

    // const leftVf_10_2 = dropzoneSlice[LEFT_VF_10_2_FIELD]?.actions;
    // const leftOctAngle = dropzoneSlice[LEFT_OCT_ANGLE_FIELD]?.actions;
    // const leftGcc = dropzoneSlice[LEFT_GCC_FIELD]?.actions;
    // const rightVf_10_2 = dropzoneSlice[RIGHT_VF_10_2_FIELD]?.actions;
    // const rightOctAngle = dropzoneSlice[RIGHT_OCT_ANGLE_FIELD]?.actions;
    // const rightGcc = dropzoneSlice[RIGHT_GCC_FIELD]?.actions;
    // dispatch(leftVf_10_2.clearDropzone());
    // dispatch(leftOctAngle.clearDropzone());
    // dispatch(leftGcc.clearDropzone());
    // dispatch(rightVf_10_2.clearDropzone());
    // dispatch(rightOctAngle.clearDropzone());
    // dispatch(rightGcc.clearDropzone());
};

export const clearSmartUploadDropzone = () => (dispatch: AppDispatch) => {
    const smartUpload = dropzoneSlice['smart_upload_referral_letter']?.actions;
    dispatch(smartUpload.clearDropzone());
}

const createGenericSlice = (sliceName: DropzoneT) => {
    const deletePhoto = createAsyncThunk(
        `dropzone/${sliceName}/deletePhoto`,
        async (_: void, {dispatch, getState, rejectWithValue}) => {
            const { user: { csrfToken }, examData: {id} } = getState() as { user: {csrfToken: string}, examData: {id: number}};
            // Logout if tokens don't match.
            if (csrfToken !== getCsrfToken()) {
                dispatch(logout());
            }
            const data = new FormData();
            data.append('exam', id.toString());
            data.append('attr', sliceName);
            const URL = `${process.env.REACT_APP_BACKENDURL}${API_REMOVE_DROPZONE_IMG_URL}`;
            try {
                const response = await apiRequest.post(URL, csrfToken, data);
                return response.data;
            } catch (error) {
                if (error) {
                    return rejectWithValue(error);
                }
            }
        }
    )
    
    const deleteInsurancePhoto = createAsyncThunk(
        `dropzone/${sliceName}/deleteInsurancePhoto`,
        async (dropzone: DropzoneT, {dispatch, getState, rejectWithValue}) => {
            const { user: { csrfToken }, usInsurance: {id} } = getState() as { user: {csrfToken: string}, usInsurance: {id: number}};
            // Logout if tokens don't match.
            if (csrfToken !== getCsrfToken()) {
                dispatch(logout());
            }
            const backendField = dropzone === 'cardBackImage' ? 'card_back_image': 'card_front_image';
            const data = new FormData();
            data.append('insurance', id.toString());
            data.append('attr', backendField);
            const URL = `${process.env.REACT_APP_BACKENDURL}/data/us/insurance/image/remove/`;
            try {
                const response = await apiRequest.post(URL, csrfToken, data);
                return response.data;
            } catch (error) {
                if (error) {
                    return rejectWithValue(error);
                }
            }
        }
    )
    
    const dropzoneSlice = createSlice({
        name: `dropzone/${sliceName}`,
        initialState,
        // The `reducers` field lets us define reducers and generate associated actions
        reducers: {
            toggleLightbox: (state) => {
                state.lightboxOn = !state.lightboxOn;
            },
            deletePhotoConfirm: (state, action: PayloadAction<void>) => {
                state.lastOperationType = action.type;
            },
            deletePhotoCancelled: (state, action: PayloadAction<void>) => {
                state.lastOperationType = action.type;
            },
            setUploadProgress: (state, action: PayloadAction<number>) => {
                state.percentage = action.payload;
            },
            uploadPhotoRequest: (state, action: PayloadAction<File>) => {
                if (action.payload) {
                    state.percentage = 0;
                    state.operationRunning = true;
                    state.lastOperationType = action.type
                    state.photo = action.payload;
                    state.error = '';
                }
            },
            uploadPhotoSuccess: (state) => {
                state.percentage = 0;
                state.error = '';
                state.operationRunning = false;
            },
            uploadPhotoFailure: (state, action: PayloadAction<string>) => {
                state.percentage = 0;
                state.error = action.payload;
                state.operationRunning = false;
            },
            retrievePhotoRequest: (state, action: PayloadAction<File>) => {
                state.percentage = 0;
                state.operationRunning = false;
                state.lastOperationType = action.type
                state.photo = action.payload;
                state.error = '';
            },
            retrievePhotoSuccess: (state, action: PayloadAction<{photo: File, thumbnailUrl: string}>) => {
                state.percentage = 0;
                state.operationRunning = false;
                state.photo = action.payload.photo;
                state.error = '';
                state.thumbnailUrl = action.payload.thumbnailUrl;
            },
            retrievePhotoFailure: (state, action: PayloadAction<string>) => {
                state.percentage = 0;
                state.error = action.payload;
                state.operationRunning = false;
            },
            getPhotoUrlsRequest: (state, action: PayloadAction<string>) => {
                state.percentage = 0;
                state.error = action.payload;
                state.operationRunning = false;
            },
            getPhotoUrlsSuccess: (state, action: PayloadAction<string>) => {
                state.percentage = 0;
                state.error = action.payload;
                state.operationRunning = false;
            },
            getPhotoUrlsFailure: (state, action: PayloadAction<string>) => {
                state.percentage = 0;
                state.error = action.payload;
                state.operationRunning = false;
            },
            clearDropzone: () => initialState,
        },
        extraReducers: (builder) => {
            builder.addCase(deletePhoto.pending, (state, action) => {
                state.operationRunning = true;
                state.lastOperationType = action.type;
                state.error = '';
                state.percentage = 0;
            });
            builder.addCase(deletePhoto.fulfilled, (state) => {
                state.operationRunning = false;
                state.error = '';
                state.percentage = 0;
                state.photo = '';
            });
            builder.addCase(deletePhoto.rejected, (state, action) => {
                state.operationRunning = false;
                state.error = action.payload as string;
                state.percentage = 0;
            });
            builder.addCase(deleteInsurancePhoto.pending, (state, action) => {
                state.operationRunning = true;
                state.lastOperationType = action.type;
                state.error = '';
                state.percentage = 0;
            });
            builder.addCase(deleteInsurancePhoto.fulfilled, (state) => {
                state.operationRunning = false;
                state.error = '';
                state.percentage = 0;
                state.photo = '';
            });
            builder.addCase(deleteInsurancePhoto.rejected, (state, action) => {
                state.operationRunning = false;
                state.error = action.payload as string;
                state.percentage = 0;
            });
        },
    });
    return {
        deletePhoto, deleteInsurancePhoto,
        reducer: dropzoneSlice.reducer,
        actions: dropzoneSlice.actions
    }
}

export const dropzoneSlice = {
    cardFrontImage: createGenericSlice('cardFrontImage'),
    cardBackImage: createGenericSlice('cardBackImage'),
    [EXTRA_IMAGES]: createGenericSlice(EXTRA_IMAGES),
    [LEFT_FUNDUS_FIELD]: createGenericSlice(LEFT_FUNDUS_FIELD),
    [LEFT_OCT_MACULA_FIELD]: createGenericSlice(LEFT_OCT_MACULA_FIELD),
    [LEFT_OCT_RNFL_FIELD]: createGenericSlice(LEFT_OCT_RNFL_FIELD),
    [LEFT_STEREO_FIELD]: createGenericSlice(LEFT_STEREO_FIELD),
    [LEFT_VF_FIELD]: createGenericSlice(LEFT_VF_FIELD),
    [RIGHT_FUNDUS_FIELD]: createGenericSlice(RIGHT_FUNDUS_FIELD),
    [RIGHT_OCT_MACULA_FIELD]: createGenericSlice(RIGHT_OCT_MACULA_FIELD),
    [RIGHT_OCT_RNFL_FIELD]: createGenericSlice(RIGHT_OCT_RNFL_FIELD),
    [RIGHT_STEREO_FIELD]: createGenericSlice(RIGHT_STEREO_FIELD),
    [RIGHT_VF_FIELD]: createGenericSlice(RIGHT_VF_FIELD),
    od_referral_letter: createGenericSlice('od_referral_letter'),
    smart_upload_referral_letter: createGenericSlice('smart_upload_referral_letter'),
}
export const leftFundusSlice = createGenericSlice('left_fundus_photo');
