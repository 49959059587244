import { useState } from 'react';
import { Button } from 'antd';
import PatientHandoutEmailModal from '../../integrated/patient-handout-email-modal';
import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { BACKEND_EXAM_STATUS_FIELD } from '../../../constants';

const PatientHandoutButton = () => {
    const examStatus = useCare1AppSelector(store=> store.examData[BACKEND_EXAM_STATUS_FIELD]);
    const isIntegrated = useCare1AppSelector(store=> store.examData.od_is_integrated);

    const [isVisible, setIsVisible] = useState(false);

    return (
        <>
            <PatientHandoutEmailModal 
                isVisible={isVisible}
                setIsVisible={setIsVisible}
            />
            <Button
                className='patient-handout-button'
                onClick={() => setIsVisible(true)}
                type="primary"
                disabled={!(isIntegrated && examStatus === 'reviewed')}
            >
                Patient Handout
            </Button>
        </>
    )
}

export default PatientHandoutButton;
