import { createContext, useState, useContext, SetStateAction, Dispatch } from 'react';
import { FormInstance } from 'antd/es/form';

interface IFormValidationContext {
    untilYesterdayForm: FormInstance<any> | undefined;
    setUntilYesterdayForm: any;
    ongoingForm: FormInstance<any> | undefined;
    setOngoingForm: Dispatch<SetStateAction<any>>;
    comorbiditiesForm: FormInstance<any> | undefined;
    setComorbiditiesForm: Dispatch<SetStateAction<any>>;
    billingRulesForm: FormInstance<any> | undefined;
    setBillingRulesForm: Dispatch<SetStateAction<any>>;
    odSelectForm: FormInstance<any> | undefined;
    setODSelectForm: Dispatch<SetStateAction<any>>;
    instrumentForm: FormInstance<any> | undefined;
    setInstrumentForm: Dispatch<SetStateAction<any>>;
    chiefComplaintForm: FormInstance<any> | undefined;
    setChiefComplaintForm: Dispatch<SetStateAction<any>>;
    billingCodeForm: FormInstance<any> | undefined;
    setBillingCodeForm: Dispatch<SetStateAction<any>>;
    omdSelectForm: FormInstance<any> | undefined;
    setOmdSelectForm: Dispatch<SetStateAction<any>>;
}

export const FormValidationContext = createContext<IFormValidationContext>({
    untilYesterdayForm: undefined, setUntilYesterdayForm: () => {},
    ongoingForm: undefined, setOngoingForm: () => {},
    comorbiditiesForm: undefined, setComorbiditiesForm: () => {},
    billingRulesForm: undefined, setBillingRulesForm: () => {},
    odSelectForm: undefined, setODSelectForm: () => {},
    instrumentForm: undefined, setInstrumentForm: () => {},
    chiefComplaintForm: undefined, setChiefComplaintForm: () => {},
    billingCodeForm: undefined, setBillingCodeForm: () => {},
    omdSelectForm: undefined, setOmdSelectForm: () => {},
});

export const FormValidationProvider = ({children}:{children:any}) => {
    const [untilYesterdayForm, setUntilYesterdayForm] = useState(undefined);
    const [ongoingForm, setOngoingForm] = useState(undefined);
    const [comorbiditiesForm, setComorbiditiesForm] = useState(undefined);
    const [billingRulesForm, setBillingRulesForm] = useState(undefined);
    const [odSelectForm, setODSelectForm] = useState(undefined);
    const [instrumentForm, setInstrumentForm] = useState(undefined);
    const [chiefComplaintForm, setChiefComplaintForm] = useState(undefined);
    const [billingCodeForm, setBillingCodeForm] = useState(undefined);
    const [omdSelectForm, setOmdSelectForm] = useState(undefined);
    return (
        <FormValidationContext.Provider
            value = {{
                untilYesterdayForm, setUntilYesterdayForm,
                ongoingForm, setOngoingForm,
                comorbiditiesForm, setComorbiditiesForm,
                billingRulesForm, setBillingRulesForm,
                odSelectForm, setODSelectForm,
                instrumentForm, setInstrumentForm,
                chiefComplaintForm, setChiefComplaintForm,
                billingCodeForm, setBillingCodeForm,
                omdSelectForm, setOmdSelectForm,
            }}>
            {children}
        </FormValidationContext.Provider>
    )
}

export const useFormValidation = () => useContext(FormValidationContext);