import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Modal } from "antd";
import { getCsrfToken } from "../helpers/utilities";
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";



export interface IVisitsUploadedDrCountData {
    [name: string]: number[],
}

export interface IVisitsUploadedResponseData {
    isLoading: boolean,
    visitsUploaded: IVisitsUploadedDrCountData,
    studyFundingAllVisits: string,
}

const initialState : IVisitsUploadedResponseData = {
    isLoading: false,
    visitsUploaded: {},
    studyFundingAllVisits: '',
}

// GET: getVisitsUploadedRequest
export const getVisitsUploadedRequest = createAsyncThunk(
    'visitsUploaded/getVisitsUploaded',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/visits_uploaded/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const visitsUploadedSlice = createSlice({
    name: 'visitsUploaded',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVisitsUploadedRequest.pending, (state) => {
            state.isLoading = true;
            state.visitsUploaded = {};
            state.studyFundingAllVisits = '';
        });
        builder.addCase(getVisitsUploadedRequest.fulfilled, (state, action: PayloadAction<IVisitsUploadedResponseData>) => {
            const visitsUploaded = action.payload.visitsUploaded;
            const studyFundingAllVisits = action.payload.studyFundingAllVisits;

            return {
                ...state,
                isLoading: false,
                visitsUploaded: visitsUploaded,
                studyFundingAllVisits: studyFundingAllVisits,
            }
        });
        builder.addCase(getVisitsUploadedRequest.rejected, (state, action) => {
            state.isLoading = false
            Modal.error({
                className: 'info-modal',
                title: `Errors getting data. ${action.payload}`,
            })
        });
    }
});

export default visitsUploadedSlice.reducer;