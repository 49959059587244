import { Card, Col, Row, Typography } from 'antd';
import styles from '../../../../static/css/components/smart-upload-modal.module.scss';
import FileUploader from './file-uploader';
import { useCare1AppSelector } from '../../../apps/care1-hooks';
import SmartUploadMiniDtc from './smart-upload-mini-dtc';


const SmartUploadStep2 = () => {
    const odProvince = useCare1AppSelector((store) => store.user.odProvince);

    const isManitoba = odProvince === 'MB';

    return (
        <Col span={24}>
            <Row>
                <Col span={isManitoba ? 16 : 24}>
                    <Typography.Text className={styles.diagnosticsTitleText}>
                        Add Diagnostics
                    </Typography.Text>
                </Col>
                {isManitoba && (
                    <Col span={8}>
                        <Typography.Text
                            className={styles.diagnosticsTitleText}
                        >
                            Add mini-DTC
                        </Typography.Text>
                    </Col>
                )}
            </Row>
            <Row className={styles.diagnosticsRow} gutter={[8, 0]}>
                <Col span={isManitoba ? 16 : 24}>
                    <Card
                        className={styles.fileUploadCard}
                        bordered={false}
                        size='small'
                    >
                        <FileUploader />
                    </Card>
                </Col>
                {isManitoba && (
                    <Col span={8}>
                        <SmartUploadMiniDtc />
                    </Col>
                )}
            </Row>
        </Col>
    );
};

export default SmartUploadStep2;
