import { Col, Row } from 'antd';
import * as Constants from '../../constants';
import '../../../static/css/components/macula-abnormality.scss';
import MaculaAbnormalityModal from './macula-abnormality-modal';
import FovealAbnormalityScore from './foveal-abnormality-score';
import MaculaHistoricalAnalysis from './macula-historical-analysis';
import MaculaSegmentationAnalysisModal from './macula-segmentation-analysis-modal';

const AdminMaculaAbnormality = () => {

    return (
        <Row className='macula-abnormality'>
            <Col span={24}>
                <Row className='title'>
                    <p>Fovea Irregularity Score</p>
                </Row>
                <Row>
                    <FovealAbnormalityScore />
                </Row>
                <Row className='maculaHistoryImages'>
                    <Col span={12}>
                        <MaculaHistoricalAnalysis
                            side={Constants.RIGHT_SIDE_TEXT}
                            imageCount={4}
                        />
                    </Col>
                    <Col span={12}>
                        <MaculaHistoricalAnalysis
                            side={Constants.LEFT_SIDE_TEXT}
                            imageCount={4}
                        />
                    </Col>
                </Row>
                <Row>
                    <MaculaAbnormalityModal />
                </Row>
                <Row>
                    <MaculaSegmentationAnalysisModal />
                </Row>
            </Col>
        </Row>
    );
}

export default AdminMaculaAbnormality;
