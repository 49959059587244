import React from 'react';
import { Table, Row, Col } from 'antd';
import '../../../static/css/shared/patient-list-table.scss';
import '../../../static/css/components/patients-to-review-table.scss';
import { localizedText } from '../../localizedText';
import { twoDecimalsNotRounded } from '../../helpers/utilities';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import { ColumnsType } from 'antd/es/table';
import { IPatientsToReviewListItem } from '../../reducers/admin-review-lists-slice';

const PatientsToReviewTable = () => {
    const { REVIEW_TABLE_CATEGORY, TOTAL_PATIENTS, PATIENTS_TO_REVIEW,
            REVIEW_TABLE_PATIENTS, PRE_REVIEW_ALERTS } = localizedText
    const tableList = useCare1AppSelector(store => store.adminReviewLists.patientsToReviewList);
    const totalReviewMinutes = useCare1AppSelector(store => store.adminReviewLists.totalReviewMinutes);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);

    interface IPatientsToReviewTable extends IPatientsToReviewListItem {
        key: React.Key,
    }

    const columns : ColumnsType<IPatientsToReviewTable> = !userIsADMIN ?
        [
            {
                className: 'category',
                title: REVIEW_TABLE_CATEGORY,
                dataIndex: 'category'
            }, {
                className: 'patients_to_review',
                title: PATIENTS_TO_REVIEW,
                dataIndex: 'patients_to_review',
                render: (text, record) => {
                    if(record['category'] === 'Total Number of Reviews'){
                        return `${text} ${TOTAL_PATIENTS}`;
                    } else if(record['category'] === 'Total Standardized Review Points'){
                        return totalReviewMinutes;
                    } else {
                        return `${text} ${REVIEW_TABLE_PATIENTS}`;
                    }
                }
            }
        ] :
        [
            {
                className: 'category',
                title: REVIEW_TABLE_CATEGORY,
                dataIndex: 'category'
            }, {
                className: 'patients_to_review',
                title: PATIENTS_TO_REVIEW,
                dataIndex: 'patients_to_review',
                render: (text, record) => {
                    if(record['category'] === 'Total Number of Reviews'){
                        return `${text} ${TOTAL_PATIENTS}`;
                    } else if(record['category'] === 'Total Standardized Review Points'){
                        return twoDecimalsNotRounded(text);
                    } else {
                        return `${text} ${REVIEW_TABLE_PATIENTS}`;
                    }
                }
            },
            {
                className: 'prereview-alerts',
                title: PRE_REVIEW_ALERTS,
                dataIndex: 'pre_review_alerts',
                render: (text, record) => {
                    if(record['category'] === 'Total Number of Reviews'){
                        return `${text} ${TOTAL_PATIENTS}`;
                    } else if(record['category'] === 'Total Standardized Review Points'
                        || record['category'] === 'Total Charts to be Pre-Reviewed'){
                        return '';
                    } else {
                        return `${text} ${REVIEW_TABLE_PATIENTS}`;
                    }
                }
            }
        ]

    return (
        <div className={`patient-list-table patients-to-review patient-list-table-omdr${userIsADMIN ? ' user-is-admin' : ' user-is-omdr'}`}>
            <div className="patients-to-review-table">
                <div className="patient-list-title" data-testid='patient-list-title'>
                    {PATIENTS_TO_REVIEW}
                </div>
                <Row>
                    <Col span={userIsADMIN ? 12 : 7}>
                        <Table
                            size='small'
                            columns={columns}
                            dataSource={tableList.map((item, index) => {
                                return { ...item, key: index };
                            })}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default PatientsToReviewTable;