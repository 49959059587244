import { useRef } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Select } from 'antd';
import { localizedText } from '../../localizedText';
import { setExamDataValue, shootsIntoMoaOmdc } from '../../reducers/patient-exam-slice';
import '../../../static/css/components/patient-exam-second-opinion-selectors.scss';
import { useGetTeleConsultOptionsQuery } from '../../services/doctor-api';


const SecondOpinionTeleConsultSelectors = ({ disabled }: { disabled: boolean }) => {
    const { SELECT_REVIEWER_TEXT } = localizedText;
    const teleConsult = useCare1AppSelector(store => store.examData.second_opinion_teleconsult);
    const notesToMoaCache = useCare1AppSelector(store => store.examData.billing_note_cache);

    const {data} = useGetTeleConsultOptionsQuery();

    const omdrSelectionChanged = useRef(false);

    // Redux and React Router Hooks
    const dispatch = useCare1AppDispatch();

    
    const handleChange = (omdId: number) => {
        omdrSelectionChanged.current = true;
        dispatch(setExamDataValue('second_opinion_teleconsult', omdId));
        const teleConsultOption = data?.find(item => item.key === omdId);
        const teleConsultName = teleConsultOption ? teleConsultOption.label : '';
        dispatch(shootsIntoMoaOmdc(notesToMoaCache, omdrSelectionChanged.current, teleConsultName));
    }

    return (
        <Card styles={{body: {padding: '1px'}}} bordered={false}>
            <div className="heading" style={{textAlign: 'start'}}>2nd Opinion Teleconsult:</div>
            <div className='component-body'>
                <Select
                    data-testid='second-omdr-select'
                    disabled={disabled}
                    className="omdr-select"
                    placeholder={SELECT_REVIEWER_TEXT}
                    options={data}
                    optionFilterProp='label'
                    onChange={(value) => {
                        handleChange(value);
                    }}
                    value={teleConsult}
                />
            </div>
        </Card>
    );
}

export default SecondOpinionTeleConsultSelectors;