import React from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Tabs } from 'antd';
import PhotoDropzone from './photo-dropzone';
import { patientIsRetina } from '../../helpers/diagnosis-convert';
import * as Constants from '../../constants';
import '../../../static/css/components/_patient-exam-oct-macula.scss';
import { lengthEqual } from '../../reducers/patient-exam-slice';
import { localizedText } from '../../localizedText';

const { TabPane } = Tabs;

function PatientExamOCTMacula({ disabled, side, dropText, tabbed }: { disabled: boolean, side: string, dropText: string, tabbed: boolean }) {
    const { LEFT_OCT_MACULA_PHOTO_TITLE_TEXT, RIGHT_OCT_MACULA_PHOTO_TITLE_TEXT, 
        SUPPORTED_FORMAT_TEXT, OCT_MACULA_TEXT } = localizedText;

    const examsWithLeftOCT = useCare1AppSelector(store => store.examData[Constants.EXAMS_WITH_LEFT_OCT], lengthEqual);
    const examsWithRightOCT = useCare1AppSelector(store => store.examData[Constants.EXAMS_WITH_RIGHT_OCT], lengthEqual);
    const leftOCTMacula = useCare1AppSelector(store => store.examData.photoUrls.left_oct_photo);
    const rightOCTMacula = useCare1AppSelector(store => store.examData.photoUrls.right_oct_photo);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);

    const dispatch = useCare1AppDispatch();

    const ptIsRetina = dispatch(patientIsRetina());

    const emptyWarningClassName = ptIsRetina && userIsOD && side === 'left' && !leftOCTMacula
        ? ' no-oct-macula-uploaded' : ptIsRetina && userIsOD && side === 'right' && !rightOCTMacula
        ? ' no-oct-macula-uploaded' : '';

    return (
        <Card className={`patient-exam-oct-macula${emptyWarningClassName}`} bordered={false}>
            { tabbed
                ? (
                    <Tabs type="card">
                        <TabPane tab={OCT_MACULA_TEXT} forceRender key="1">
                            <PhotoDropzone

                                disabled={disabled}
                                field={side === Constants.RIGHT_SIDE_TEXT ? Constants.RIGHT_OCT_MACULA_FIELD : Constants.LEFT_OCT_MACULA_FIELD}
                                label={side === Constants.RIGHT_SIDE_TEXT ? RIGHT_OCT_MACULA_PHOTO_TITLE_TEXT : LEFT_OCT_MACULA_PHOTO_TITLE_TEXT}
                                dropText={dropText}
                                formatText={SUPPORTED_FORMAT_TEXT}
                                carousel={side === Constants.RIGHT_SIDE_TEXT ? examsWithRightOCT : examsWithLeftOCT }
                            />
                        </TabPane>

                        {/* We will hide the Angle until the OMDR and APR UIs are completed
                            <TabPane tab="Angle" forceRender key="2">
                                <PhotoDropzone
                                    disabled={disabled}
                                    field={side === Constants.RIGHT_SIDE_TEXT ? Constants.RIGHT_OCT_ANGLE_FIELD : Constants.LEFT_OCT_ANGLE_FIELD}
                                    label={side === Constants.RIGHT_SIDE_TEXT ? 'Right OCT Angle' : 'Left OCT Angle'}
                                    dropText={dropText}
                                    formatText={Constants.SUPPORTED_FORMAT_TEXT}
                                />
                            </TabPane>

                        */}
                    </Tabs>
                ) : (
                    <PhotoDropzone
                        disabled={disabled}
                        field={side === Constants.RIGHT_SIDE_TEXT ? Constants.RIGHT_OCT_MACULA_FIELD : Constants.LEFT_OCT_MACULA_FIELD}
                        label={side === Constants.RIGHT_SIDE_TEXT ? RIGHT_OCT_MACULA_PHOTO_TITLE_TEXT : LEFT_OCT_MACULA_PHOTO_TITLE_TEXT}
                        dropText={dropText}
                        formatText={SUPPORTED_FORMAT_TEXT}
                        carousel={side === Constants.RIGHT_SIDE_TEXT ? examsWithRightOCT : examsWithLeftOCT }
                    />
                )
            }
        </Card>
    );
}

export default PatientExamOCTMacula;
