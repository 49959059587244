import { convertTimeToShortFormatLabel } from '../../helpers/utilities';
import { useCare1AppDispatch } from '../../apps/care1-hooks';
import { getBackendMediaUrl } from '../../helpers/media-image-convert';
import { setAIIngisghtsHistoricalAnalysisData, openSegmentationModal } from '../../reducers/ai-insights-historical-analysis-slice';

type ComponentProps = {
    examId: number,
    fundusPhoto: string,
    useLargeHemePigmentPhoto: boolean,
    mlLargeHemePigmentOverlayPhoto: string,
    mlLargeHemePigmentValue: string,
    mlLargeHemePigmentSucceeded: boolean,
    useMicroAneurysmsPhoto: boolean,
    mlMicroAneurysmsOverlayPhoto: string,
    mlMicroAneurysmsValue: string,
    mlMicroAneurysmsSucceeded: boolean,
    useHardExudatesPhoto: boolean,
    mlHardExudatesOverlayPhoto: string,
    mlHardExudatesValue: string,
    mlHardExudatesSucceeded: boolean,
    examDate: string,
}

// The preGeneratedThumbnail flag controls whether we use a pre-generated thumbnail. When set to false, we rely
// on the lightbox component to auto-generate for us. When set to true, we use a pre-generated thumbnail.
const AdminAIInsightsHistoryImage = ({ examId, fundusPhoto, 
    useLargeHemePigmentPhoto, mlLargeHemePigmentOverlayPhoto, mlLargeHemePigmentValue, mlLargeHemePigmentSucceeded, 
    useMicroAneurysmsPhoto, mlMicroAneurysmsOverlayPhoto, mlMicroAneurysmsValue, mlMicroAneurysmsSucceeded,  
    useHardExudatesPhoto, mlHardExudatesOverlayPhoto, mlHardExudatesValue, mlHardExudatesSucceeded, 
    examDate }: ComponentProps) => {

    const dispatch = useCare1AppDispatch();

    const mainSrcUrl = getBackendMediaUrl() + fundusPhoto;

    const handleclick = () => {
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_exam_id', value: examId}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_exam_date', value: examDate}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_fundus_photo', value: fundusPhoto === '' ? '' : getBackendMediaUrl() + fundusPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_use_large_heme_pigment_photo', value: useLargeHemePigmentPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_large_heme_pigment_overlay_photo', value: mlLargeHemePigmentOverlayPhoto === '' ? '' : getBackendMediaUrl() + mlLargeHemePigmentOverlayPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_large_heme_pigment_value', value: mlLargeHemePigmentValue}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_large_heme_pigment_succeeded', value: mlLargeHemePigmentSucceeded}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_use_micro_aneurysms_photo', value: useMicroAneurysmsPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_micro_aneurysms_overlay_photo', value: mlMicroAneurysmsOverlayPhoto === '' ? '' : getBackendMediaUrl() + mlMicroAneurysmsOverlayPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_micro_aneurysms_value', value: mlMicroAneurysmsValue}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_micro_aneurysms_succeeded', value: mlMicroAneurysmsSucceeded}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_use_hard_exudates_photo', value: useHardExudatesPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_hard_exudates_overlay_photo', value: mlHardExudatesOverlayPhoto === '' ? '' : getBackendMediaUrl() + mlHardExudatesOverlayPhoto}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_hard_exudates_value', value: mlHardExudatesValue}));
        dispatch(setAIIngisghtsHistoricalAnalysisData({key: 'selected_ml_hard_exudates_succeeded', value: mlHardExudatesSucceeded}));
        dispatch(openSegmentationModal());
    }

    return (
        <div className='ai-insights-history-image'>
            <img
                role="img"
                alt=""
                className=""
                src={mainSrcUrl}
                onClick={handleclick}
            />
            <div className='indexDate'>
                <div className="indexExamDate">{convertTimeToShortFormatLabel(examDate)}</div>
            </div>
        </div>
    );
}

export default AdminAIInsightsHistoryImage;