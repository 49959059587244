import { useEffect, useRef } from 'react';
import { IMessageEvent, w3cwebsocket } from 'websocket';
import { useCare1AppDispatch } from '../apps/care1-hooks';
import { logout } from '../reducers/user-slice';

const ForceLogout = () => {
    const webSocketRef = useRef<w3cwebsocket | null>(null);
    const reconnectIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const dispatch = useCare1AppDispatch();

    const logoutAndReload = async () => {
        await dispatch(logout());
        // Perform a reload, clearing the cache
        window.location.reload();
    }

    const connectWebSocket = () => {
        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
          console.log('WebSocket already connected');
          return;
        }
        
        const socket = new w3cwebsocket(`${process.env.REACT_APP_WEBSOCKETURL}/session/`);

        socket.onopen = (): void => {
            console.log('WebSocket connected');
            // Clear the reconnect interval if the connection is successfully established
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };

        socket.onmessage = (message: IMessageEvent): void => {
            console.log('WebSocket: onmessage');
            try {
                const obj = JSON.parse(message.data.toString());
                if (obj.hasOwnProperty('message')) {
                    console.log(`WebSocket: onmessage; message: ${obj.message}`);
                    if (obj.message === 'logout') {
                        logoutAndReload();
                    }
                }
            }
            catch (err) {

            };
        };

        socket.onclose = () => {
            console.log('WebSocket closed');
            // Set up a reconnect interval to attempt reconnection
            setReconnectInterval();
        };

        webSocketRef.current = socket;
    };

    const setReconnectInterval = (): void => {
        // Clear any existing reconnect interval
        clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        // Set a new reconnect interval (e.g., 5 seconds)
        reconnectIntervalRef.current = setInterval(connectWebSocket, 5000);
    };

    useEffect(() => {
        connectWebSocket();

        return () => {
            // Close the WebSocket connection when the component unmounts
            if (webSocketRef.current) {
                webSocketRef.current.close();
            }
            // Clear the reconnect interval when the component unmounts
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };
        // eslint-disable-next-line
    }, []);

    return <div></div>;
}

export default ForceLogout;
