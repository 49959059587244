import { useEffect } from 'react';
import { Modal,  Form, Row, Button, Input, Radio } from 'antd';
import { CloseCircleOutlined, CloudDownloadOutlined, UserOutlined } from '@ant-design/icons';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { IGPLetter } from '../../../services/doctor-api';
import '../../../../static/css/components/letter-modal.scss';
import { localizedText } from '../../../localizedText';
import { getGPConsultLetter, getLetterRequest, getRluOmdLetterRequest } from '../../../reducers/letters-slice';

type ComponentProps = {
    letterModalVisible: boolean,
    setLetterModalVisible: (letterModalVisible: boolean) => void
}

const LetterModal = ({letterModalVisible, setLetterModalVisible} : ComponentProps) => {
    const { CONSULTATION_LETTER_TEXT, DOCTORS_NAME_TEXT, TO_OTHER_TEXT,
        TO_GP_TEXT, TO_MD2_TEXT, CANCEL_TEXT } = localizedText;
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const gp = useCare1AppSelector(store => store.patientDetails.details.gp);
    const md2 = useCare1AppSelector(store => store.patientDetails.details.gp2);
    const odUsesRluPei = useCare1AppSelector(store => store.examData.od_uses_rlu_pei);
    const rluExamPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);
    const dispatch = useCare1AppDispatch();
    const [ form ] = Form.useForm();

    const handleCancel = () => {
        setLetterModalVisible(false);
    }

    const onFinish = (formValues: IGPLetter) =>{
        if (odUsesRluPei && rluExamPremium) {
            // Premium RLU exams generate the RLU GP letter.
            dispatch(getLetterRequest('rluomdrgp'));
        } else {
            // RE exams or standard RLU exams generate the legacy consult letter.
            const { doctorName } = formValues;
            dispatch(getGPConsultLetter(doctorName));
        }
    }
    // Automatically fill GP/MD2 name when radio button is selected, 'other' is for custom doctor name
    const onRadioChange = (radioSelection : string) => {
        const gpName = gp ? gp.trim() : '';
        const md2Name = md2 ? md2.trim() : '';
        const drName = radioSelection === 'gp' ? gpName :
                       radioSelection === 'md2' ? md2Name :
                       '';
        form.setFieldsValue({
            doctorName: drName
        });
    }

    useEffect(() => {
        // reset all fields
        return () => {
            form.resetFields();
        }
    })

    return (
        <Modal
            open={letterModalVisible}
            className='letter-modal'
            footer={null}
            width={430}
            onCancel={handleCancel}
        >

        <Form form={form} onFinish={onFinish} requiredMark={false}>
            <Row className='header-row' justify='center'>
                <div className='modal-title'>{CONSULTATION_LETTER_TEXT}</div>
            </Row>
            <Row className='radio-button-row' justify='center'>
                <Form.Item name='radioButton' initialValue='other'>
                    <Radio.Group onChange={ e => onRadioChange(e.target.value)} buttonStyle='outline'>
                        <Radio.Button value='gp' disabled={!gp}>{TO_GP_TEXT}</Radio.Button>
                        {!userIsRE && <Radio.Button value='md2' disabled={!md2}>{TO_MD2_TEXT}</Radio.Button>}
                        <Radio.Button value='other'>{TO_OTHER_TEXT}</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            </Row>
            <Row className='entry-row' justify='center'>
                <Form.Item label='Dr Name' name='doctorName' rules={[{required: true}]}>
                    <Input
                        size='middle'
                        prefix={<UserOutlined />}
                        placeholder={DOCTORS_NAME_TEXT}
                    />
                </Form.Item>
            </Row>
            <Row className='buttons-row' justify='center'>
                <Button
                    className='cancel-button'
                    onClick={handleCancel}
                    icon={<CloseCircleOutlined />}
                >
                    {CANCEL_TEXT}
                </Button>
                <Form.Item>
                    <Button
                        className='generate-button'
                        icon={<CloudDownloadOutlined />}
                        htmlType=  "submit"
                    >
                        Download
                    </Button>
                </Form.Item>
            </Row>
            </Form>
        </Modal>
    )
}

export default LetterModal;