import { Card, Col, Row, Tabs } from 'antd';
import GptImageIdentifierDNDImg from './gpt-image-identifier-dnd-img';


const { TabPane } = Tabs;

const GptImageIdentifierDNDImages = () => {

    return (
        <div className="gpt-image-identifier-images">
            <Row>
                <Col span={12}>
                    <Row>
                        <Col span={12}>
                            <Card className='patient-exam-fundus' bordered={false}>
                                <Tabs type="card">
                                    <TabPane tab='Fundus Photo' forceRender key="1">
                                        <GptImageIdentifierDNDImg
                                            field='right_fundus_photo'
                                            className='dropzone-image'
                                        />
                                    </TabPane>
                                    <TabPane tab='Second Fundus Photo' forceRender key="2">
                                        <GptImageIdentifierDNDImg
                                            field='right_stereo_photo'
                                            className='dropzone-image'
                                        />
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-oct-macula' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='OCT Macula' forceRender key="1">
                                                <GptImageIdentifierDNDImg
                                                    field='right_oct_photo'
                                                    className='dropzone-image'
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-oct-rnfl' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='OCT RNFL' forceRender key="1">
                                                <GptImageIdentifierDNDImg
                                                    field='right_oct_rnfl_photo'
                                                    className='dropzone-image'
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-vf' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='VF' forceRender key="1">
                                                <GptImageIdentifierDNDImg
                                                    field='right_vf_photo'
                                                    className='dropzone-image'
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-vf' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='VF' forceRender key="1">
                                                <GptImageIdentifierDNDImg
                                                    field='left_vf_photo'
                                                    className='dropzone-image'
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-oct-macula' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='OCT Macula' forceRender key="1">
                                                <GptImageIdentifierDNDImg
                                                    field='left_oct_photo'
                                                    className='dropzone-image'
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-oct-rnfl' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='OCT RNFL' forceRender key="1">
                                                <GptImageIdentifierDNDImg
                                                    field='left_oct_rnfl_photo'
                                                    className='dropzone-image'
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Card className='patient-exam-fundus' bordered={false}>
                                <Tabs type="card">
                                    <TabPane tab='Fundus Photo' forceRender key="1">
                                        <GptImageIdentifierDNDImg
                                            field='left_fundus_photo'
                                            className='dropzone-image'
                                        />
                                    </TabPane>
                                    <TabPane tab='Second Fundus Photo' forceRender key="2">
                                        <GptImageIdentifierDNDImg
                                            field='left_stereo_photo'
                                            className='dropzone-image'
                                        />
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>);
}

export default GptImageIdentifierDNDImages;
