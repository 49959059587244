import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import * as Constants from '../constants';
import { getCsrfToken } from '../helpers/utilities';

type ComponentProps = {
    isUserLoggedIn: boolean,
    redirectPath: string,
    children: React.ReactElement,
}

const ProtectedRoute = ({
    isUserLoggedIn,
    redirectPath = Constants.LOGIN_URL,
    children,
}: ComponentProps) => {
    const csrftoken = getCsrfToken();

    if (!(isUserLoggedIn === true && csrftoken)) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;
