import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from '../reducers/retina-enabled-top-reducers'
import { developmentMode } from '../helpers/utilities';
import { createLogger } from 'redux-logger';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { baseApi } from '../services/base-api';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [],
    whitelist: ['user', 'options', 'patientExams'],
    stateReconciler: autoMergeLevel2,
};

if (developmentMode) {
    // persistConfig.stateReconciler = false;
}

export type RootReducer = ReturnType<typeof reducers>;
const persistedReducers = persistReducer<RootReducer, Action<any>>(persistConfig, reducers);

// TODO re-enable serializableCheck and immutableCheck after all states are converted to redux-toolkit
export const store = configureStore({
    reducer: persistedReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
    })
    .concat(baseApi.middleware)
    .concat(createLogger())
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

