import { Fragment, useEffect, useState, useMemo } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Input, Form, Checkbox } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { isCatPostOpSelected } from '../../helpers/diagnosis-convert';
import { getTxAlgoKeywordOptions, addTxAlgoKeywordColors, renderTxAlgoKeywordString
    } from '../../helpers/patient-exam-convert';
import { localizedText } from '../../localizedText';
import { EXAM_NOTES_FIELD, EXAM_NOTE_DEFAULT_TEXT } from '../../constants';
import '../../../static/css/components/patient-exam-notes-history.scss';
import '../../../static/css/components/component-input-card.scss';

const PatientExamNotesHistory = ({ disabled }: { disabled?: boolean }) => {
    const { EXAM_NOTES_TITLE } = localizedText;

    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);

    const examNotesHistory = useCare1AppSelector(store => store.examData[EXAM_NOTES_FIELD]);
    const diagnosisEntries = useCare1AppSelector(store => store.diagnosis.entries);
    const catPostOp = useCare1AppSelector(store => store.examData.rr_cat_post_op);
    const isCatPostOp = isCatPostOpSelected(diagnosisEntries, catPostOp);
    const catPostOpClassName = isCatPostOp ? 'cat-color' : ' ';

    const [ form ] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    // Checkbox for admin to toggle in and out of editing mode.
    const [adminIsEditing, setAdminIsEditing] = useState(false);

    // Get the color coded keywords for the component
    const txAlgo3Keywords = useCare1AppSelector(store => store.options.txAlgoKeywords);
    const txAlgoKeywordOptions = useMemo(() => {
        return getTxAlgoKeywordOptions(txAlgo3Keywords, 'odNotesAndProposedPlan');
    }, [txAlgo3Keywords]);
    const keywordOptions = userIsADMIN ? txAlgoKeywordOptions : null;

    // Add the color code tags to the content string
    const applyKeywordColors = useMemo(() => {
        if (userIsADMIN && keywordOptions) {
            return addTxAlgoKeywordColors(keywordOptions, examNotesHistory);
        }
        return null;
    }, [keywordOptions, examNotesHistory, userIsADMIN]);



    // equivalent of componentDidUpdate in this case
    useEffect(()=>{
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            [EXAM_NOTES_FIELD]:  examNotesHistory,
        }
        form.setFieldsValue(newValidationFieldValues);
    }, [examNotesHistory,form])

    const className = isCatPostOp ? 'component-input-card notes-history cat-post-op'
        : 'component-input-card notes-history';

    return (
        <Form form={form}>
            <Card className={className} bordered={false}>
                {/* NH 001, 002, 003 */}
                <div className="heading" data-testid='notes-history-title'>
                    {EXAM_NOTES_TITLE}
                    { userIsADMIN &&
                        <Checkbox
                            onChange={()=> setAdminIsEditing(!adminIsEditing)}
                            className='admin-edit-checkbox'
                        >
                            Admin Edit
                        </Checkbox>
                    }
                    {isCatPostOp &&
                        <div className={catPostOpClassName}>
                            &nbsp;&nbsp;*grade K edema and AC cells, 1-4+
                        </div>
                    }
                </div>
                { (!userIsADMIN || (userIsADMIN && adminIsEditing)) &&
                    <Form.Item name={EXAM_NOTES_FIELD} initialValue={examNotesHistory}>
                        <Input.TextArea
                            disabled={disabled}
                            data-testid='notes-history'
                            className="component-input"
                            autoSize={false}
                            allowClear={examNotesHistory === EXAM_NOTE_DEFAULT_TEXT}
                            onBlur={() => dispatch(setExamDataValue(EXAM_NOTES_FIELD, form.getFieldValue(EXAM_NOTES_FIELD)))}
                        />
                    </Form.Item>
                }
                { userIsADMIN && !adminIsEditing &&
                    <Form.Item>
                        <div className='component-input color-keyword-div' data-testid='notes-history'>
                            {
                                applyKeywordColors && applyKeywordColors.map(
                                    (string, index) => (<Fragment key={index}>{renderTxAlgoKeywordString(string)}</Fragment>)
                                )
                            }
                        </div>
                    </Form.Item>
                }
            </Card>
        </Form>
    );
}

export default PatientExamNotesHistory;