import React, {useEffect} from 'react';
import { Card, Input, Form, Tooltip } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { NOTES_TO_MOA_FIELD, NOTES_TO_MOA_FIELD_CACHE } from '../../constants'
import '../../../static/css/components/patient-exam-notes-to-moa.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import AdminBillingAddressed from '../admin/admin-billing-addressed';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { localizedText } from '../../localizedText';
import { FieldData } from 'rc-field-form/lib/interface';


const PatientExamNotesToMOA = ({ disabled }: { disabled: boolean }) => {
    const { NOTES_MOA_TEXT, NOTES_MOA_TOOLTIP } = localizedText;
    const notesToMoa = useCare1AppSelector(store => store.examData.billing_note);
    const isADMIN = useCare1AppSelector(store => store.user.isADMIN);

    const [form] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    // equivalent of componentDidUpdate in this case
    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            [NOTES_TO_MOA_FIELD]: notesToMoa,
        };
        form.setFieldsValue(newValidationFieldValues);
    }, [notesToMoa, form])


    // Ant Design handler. This is called whenever a field is changed by the user using Ant Design controls.
    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case NOTES_TO_MOA_FIELD:
                        dispatch(setExamDataValue(field.name[0], field.value));
                        dispatch(setExamDataValue(NOTES_TO_MOA_FIELD_CACHE, field.value));
                        break;
                    default:
                        break;
                }
            }
        });
    }


    return (
        <Form form={form} onFieldsChange={onFieldsChange}>
            <Card className={`component-input-card notes-to-moa`} bordered={false}>
                <span className="heading">{NOTES_MOA_TEXT}</span>
                <Tooltip placement='topLeft' title={NOTES_MOA_TOOLTIP}>
                    <InfoCircleOutlined />
                </Tooltip>
                <Form.Item name={NOTES_TO_MOA_FIELD} initialValue={notesToMoa}>
                    <Input.TextArea
                        data-testid='notes-to-moa'
                        disabled={disabled}
                        className="component-input"
                        autoSize={false}
                        placeholder={''}
                    />
                </Form.Item>
                { isADMIN &&
                    <AdminBillingAddressed />
                }
            </Card>
        </Form>
    );
}

export default PatientExamNotesToMOA;