import React from 'react';
import { Modal, Spin } from 'antd';
import '../../../static/css/components/admin-doctor-edit-modal.scss';

// Components
import ODEditForm from './od-edit-form';

// Action imports
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { IODDetails, useAddOdMutation } from '../../services/doctor-api';
import { setShowOdAddModal } from '../../reducers/doctor-slice';

const ODAddModal = () => {

    const showOdAddModal = useCare1AppSelector(store => store.doctorSlice.showOdAddModal);

    const dispatch = useCare1AppDispatch();

    const [addOd, {isLoading}] = useAddOdMutation();


    const handleModalClose = () => {
        dispatch(setShowOdAddModal(false));
    };

    const onFinish = async (values: IODDetails) => {
        try {
            const result = await addOd({
                ...values,
                clinic_location: values.clinic_location ?? null,
                }).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'OD created successfully.',
                    onOk: () => {
                        dispatch(setShowOdAddModal(false));
                    }
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    const onFinishFailed = () => {
        Modal.error({
            className: 'info-modal',
            content: 'Please fix the error to continue',
            title: 'Error',
        });
    };

    return (
        <Modal
            className='admin-doctor-edit-modal od'
            open={showOdAddModal}
            closable={true}
            footer={null}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <Spin
                className='loading-spinner'
                size='large'
                spinning={isLoading}
            >
                <ODEditForm
                    title={'Add New Optometrist'}
                    buttonText={'Add OD'}
                    mode={'add'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                />
            </Spin>
        </Modal>
    );

}

export default ODAddModal;
