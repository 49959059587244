import { Tag, Select } from 'antd';
import { localizedText } from '../../localizedText';

type ComponentProps = {
    bookingStatusKey: string,
    className: string,
    testId?: string,
    onEditStatus: (value: string) => void,
}

const GlaucomaProgramBookingStatusSelect = ({bookingStatusKey, className, testId, onEditStatus}: ComponentProps) => {
    const { GLAUCOMA_PROGRAM_BOOKING_STATUS_TYPES } = localizedText;

    // Get status object from booking status options
    const bookingStatus = GLAUCOMA_PROGRAM_BOOKING_STATUS_TYPES.find((entry) => entry.key === bookingStatusKey);

    return (bookingStatus
        && (
            <div className={`glaucoma-program-status-tag ${bookingStatusKey}`}>
                <Select
                    data-testid='glaucoma-program-status-select'
                    className={`glaucoma-program-status-select ${className} ${bookingStatus.key}-status`}
                    onChange={(value) => onEditStatus(value)}
                    value={bookingStatus.key}
                >
                    {
                        GLAUCOMA_PROGRAM_BOOKING_STATUS_TYPES.map((status) => (
                            <Select.Option key={status.key} value={status.key} className={`${status.key}-status`} >
                                {status.label}
                            </Select.Option>
                        ))
                    }
                </Select>
            </div>
        )
    ) || null;
}

export default GlaucomaProgramBookingStatusSelect;
