import { useEffect } from 'react';
import { Form, Col, Row, InputNumber } from 'antd';
import {
    padStringStart, allowPosAndNegNumberAndDecimalOnlyKeys, allowPosNumberOnlyKeys
} from '../../../helpers/utilities';
import * as Constants from '../../../constants';
import '../../../../static/css/components/patient-exam-rx.scss';
import ExamMetricValidationModal from './exam-metric-validation-modal';
import { RxField, setExamDataValue } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { IRxRange, IRxRangeOption } from '../../../reducers/options-slice';
import { FieldData } from 'rc-field-form/lib/interface';
import { IExamData, lengthEqual } from '../../../reducers/patient-exam-slice';
import { updateGptSetValuesNearMissData } from '../../../reducers/gpt-set-values-slice';
import { patientIsGlaucoma, isCatPostOpSelected }
    from '../../../helpers/diagnosis-convert';


const FormItem = Form.Item;

type ComponentProps = {
    disabled?: boolean,
    side: 'od' | 'os',
    gptSavedSphere?: boolean;
    gptSavedCylinder?: boolean;
    gptSavedAxis?: boolean;
    gptNearMissSphere?: boolean;
    gptNearMissCylinder?: boolean;
    gptNearMissAxis?: boolean;
}

const PatientExamRx = ({ disabled, side,
    gptSavedSphere, gptSavedCylinder, gptSavedAxis,
    gptNearMissSphere, gptNearMissCylinder, gptNearMissAxis }: ComponentProps) => {

    const sphereFieldName = side === 'od' ? 'od_sphere' : 'os_sphere';
    const otherSphereFieldName = side === 'os' ? 'od_sphere' : 'os_sphere';
    const cylinderFieldName = side === 'od' ? 'od_cylinder' : 'os_cylinder';
    const otherCylinderFieldName = side === 'os' ? 'od_cylinder' : 'os_cylinder';
    const axisFieldName = side === 'od' ? 'od_axis' : 'os_axis';
    const otherAxisFieldName = side === 'os' ? 'od_axis' : 'os_axis';

    const exams = useCare1AppSelector(store => store.examData.exams, lengthEqual);

    const rxRanges = useCare1AppSelector(store => store.options.rxRanges);
    const sideSphere = useCare1AppSelector(store => store.examData[sphereFieldName]);
    const otherSideSphere = useCare1AppSelector(store => store.examData[otherSphereFieldName]);
    const sideCylinder = useCare1AppSelector(store => store.examData[cylinderFieldName]);
    const otherSideCylinder = useCare1AppSelector(store => store.examData[otherCylinderFieldName]);
    const sideAxis = useCare1AppSelector(store => store.examData[axisFieldName]);
    const otherSideAxis = useCare1AppSelector(store => store.examData[otherAxisFieldName]);

    const pastSideSphere = useCare1AppSelector(store => store.examData[`past_${sphereFieldName}`]);
    const pastSideCylinder = useCare1AppSelector(store => store.examData[`past_${cylinderFieldName}`]);
    const pastSideAxis = useCare1AppSelector(store => store.examData[`past_${axisFieldName}`]);

    // Yellow warning border should display for OD users only
    const userIsOD = useCare1AppSelector(store => store.user.isOD);

    const [form] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    const { SPHERE_VALIDATION_MSG, CYLINDER_VALIDATION_MSG, AXIS_VALIDATION_MSG } = Constants;
    const { sphere, cylinder, axis } = rxRanges as IRxRange;

    const diagnosisEntries = useCare1AppSelector(store => store.diagnosis.entries);
    const odGroupPracticeProvince = useCare1AppSelector(store => store.examData.od_group_practice_province);
    const glc = useCare1AppSelector(store => store.examData.rr_glc);
    const glcS = useCare1AppSelector(store => store.examData.rr_glc_suspect);
    const narrowAngles = useCare1AppSelector(store => store.examData.rr_narrow_angles);
    const isGlaucoma = patientIsGlaucoma(diagnosisEntries, {glc, glcS, narrowAngles});
    const isGlaucomaManitoba = odGroupPracticeProvince === 'MB' && isGlaucoma;

    const isRxEmpty = (!sideSphere && Number(sideSphere) !== 0) &&
        (!otherSideSphere && Number(otherSideSphere) !== 0) &&
        (!sideCylinder && Number(sideCylinder) !== 0) &&
        (!otherSideCylinder && Number(otherSideCylinder) !== 0) &&
        (!sideAxis && Number(sideAxis) !== 0) &&
        (!otherSideAxis && Number(otherSideAxis) !== 0);

    const validateRxValue = (rxEntry: IRxRangeOption, field: RxField, value: string, validationMsg: string) => {
        if (value && (Number(value) < rxEntry.min || Number(value) > rxEntry.max)) {
            dispatch(setExamDataValue(field, ''));
            ExamMetricValidationModal(validationMsg);
            return false;
        }
        return true;
    }

    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        if (sideSphere) {
            form.setFieldsValue({
                [sphereFieldName]: sideSphere.toString().replace('+', ''),
                [cylinderFieldName]: sideCylinder,
                [axisFieldName]: sideAxis,
            });
        }
        else {
            form.setFieldsValue({
                [sphereFieldName]: sideSphere,
                [cylinderFieldName]: sideCylinder,
                [axisFieldName]: sideAxis,
            });
        }
    }, [sphereFieldName, cylinderFieldName, axisFieldName, sideSphere, sideCylinder, sideAxis, form])


    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name.length && !field.validating) {
                switch (field.name[0]) {
                    case Constants.OD_SPHERE:
                    case Constants.OS_SPHERE:
                    case Constants.OD_CYLINDER:
                    case Constants.OS_CYLINDER:
                    case Constants.OD_AXIS:
                    case Constants.OS_AXIS:
                        if (field.value === null || field.value === undefined) {
                            dispatch(setExamDataValue(field.name[0], ''));
                        } else {
                            dispatch(setExamDataValue(field.name[0], field.value));
                        }

                        dispatch(updateGptSetValuesNearMissData({id: [field.name[0]].toString() as keyof IExamData, value: field.value}));
                        break;
                    default:
                        break;
                }
            }
        })
    }

    const gptClassnameSphere = () => {
        const lastExam = exams && exams.length >= 2 ? exams[1] : null;
        if (lastExam) {
            if (!gptSavedSphere && !sideSphere && lastExam[sphereFieldName])   {
                return 'exam-gpt-state-placeholder';
            }
        }

        if (gptNearMissSphere) {
            return 'exam-gpt-state-red';
        }
        if (gptSavedSphere) {
            return 'exam-gpt-state-yellow';
        }
        return '';
    }

    const gptClassnameCylinder = () => {
        const lastExam = exams && exams.length >= 2 ? exams[1] : null;
        if (lastExam) {
            if (!gptSavedCylinder && !sideCylinder && lastExam[cylinderFieldName])   {
                return 'exam-gpt-state-placeholder';
            }
        }

        if (gptNearMissCylinder) {
            return 'exam-gpt-state-red';
        }
        if (gptSavedCylinder) {
            return 'exam-gpt-state-yellow';
        }
        return '';
    }

    const gptClassnameAxis = () => {
        const lastExam = exams && exams.length >= 2 ? exams[1] : null;
        if (lastExam) {
            if (!gptSavedAxis && !sideAxis && lastExam[axisFieldName])   {
                return 'exam-gpt-state-placeholder';
            }
        }

        if (gptNearMissAxis) {
            return 'exam-gpt-state-red';
        }
        if (gptSavedAxis) {
            return 'exam-gpt-state-yellow';
        }
        return '';
    }

    return (
        <div className={'patient-exam-rx'}>
            <Form form={form} onFieldsChange={onFieldsChange}>
                {/* RX 001, RX 002, RX 003, RX 004, RX 005, RX 006 */}
                <Row gutter={2}>
                    <Col span={isGlaucomaManitoba ? 7 : 8} className={'rx-sphere'}>
                        <div className={gptClassnameSphere()}>
                            <FormItem
                                help=""
                                validateStatus={userIsOD && isRxEmpty ? 'warning' : ''}
                                name={sphereFieldName}
                            >
                                {/* Use onKeyPress event to prevent letters */}
                                {/* Use parsing to prevent more digits than the max allowed value */}
                                <InputNumber
                                    parser={value => {
                                        if (value) {
                                            return value.toString().replace('+', '');
                                        }
                                        else {
                                            return value ? value : '';
                                        }
                                    }}
                                    className={`rx-sphere-select-${side}`}
                                    data-testid={`rx-sphere-${side}`}
                                    size={'small'}
                                    disabled={disabled}
                                    step={sphere.step}
                                    onKeyPress={event => allowPosAndNegNumberAndDecimalOnlyKeys(event)}
                                    formatter={(value, info: {
                                        userTyping: boolean;
                                        input: string;
                                    }) => {
                                        if (value) {
                                            try {
                                                const f = parseFloat(value.toString());
                                                if (!isNaN(f)) {
                                                    if (info.userTyping) {
                                                        if (f < 0) {
                                                            return `${value}`;
                                                        }
                                                        else {
                                                            return `+${value}`;
                                                        }
                                                    }
                                                    else {
                                                        if (f < 0) {
                                                            return `${f.toFixed(2)}`;
                                                        }
                                                        else {
                                                            return `+${f.toFixed(2)}`;
                                                        }
                                                    }
                                                }
                                            }
                                            catch (exc) {
                                            }
                                        }
                                        return '';
                                    }}
                                    onBlur={() => {
                                        const valid = validateRxValue(sphere, sphereFieldName, sideSphere, SPHERE_VALIDATION_MSG);
                                        if (valid) {
                                            try {
                                                const f = parseFloat(sideSphere);
                                                if (!isNaN(f)) {
                                                    dispatch(setExamDataValue(sphereFieldName, f.toFixed(2)));
                                                }

                                            }
                                            catch (exc) {

                                            }
                                        }
                                    }}
                                    placeholder={pastSideSphere}
                                />
                            </FormItem>
                        </div>
                    </Col>
                    <Col span={isGlaucomaManitoba ? 7 : 8} className={'rx-cylinder'}>
                        <div className={gptClassnameCylinder()}>
                            <FormItem
                                className="cylinder"
                                help=""
                                name={cylinderFieldName}>
                                {/* Use onKeyPress event to prevent letters */}
                                {/* Use parsing to prevent more digits than the max allowed value */}
                                <InputNumber
                                    parser={value => {
                                        const regex = /([-])?([0-9]\.)?([0-9]{1,2})?.*/;
                                        const matches = value?.match(regex);
                                        return matches ? `${matches[1] || ''}${matches[2] || ''}${matches[3] || ''}` : '';
                                    }}
                                    className={`rx-cylinder-select-${side}`}
                                    data-testid={`rx-cylinder-${side}`}
                                    size={'small'}
                                    disabled={disabled}
                                    step={cylinder.step}
                                    onKeyPress={event => allowPosAndNegNumberAndDecimalOnlyKeys(event)}
                                    onBlur={() => validateRxValue(cylinder, cylinderFieldName, sideCylinder, CYLINDER_VALIDATION_MSG)}
                                    placeholder={pastSideCylinder}
                                />
                            </FormItem>
                        </div>
                    </Col>
                    <Col span={1} className={`rx-x ${side}`}>
                        X
                    </Col>
                    <Col span={7} className={'rx-axis'}>
                        <div className={gptClassnameAxis()}>
                            <FormItem
                                help=""
                                name={axisFieldName}>
                                {/* Use onKeyPress event to prevent letters */}
                                {/* Use formatter to enforce a max of three digits and leading zeros */}
                                <InputNumber
                                    parser={value => {
                                        const parsed = value?.replace(/[a-z]/g, '');
                                        const parsed2 = parsed?.replace(/^[0]+/, '');
                                        return parsed2 ? parsed2.substring(0, 3) : '';
                                    }}
                                    className={`rx-axis-select-${side}`}
                                    data-testid={`rx-axis-${side}`}
                                    size={'small'}
                                    disabled={disabled}
                                    step={axis.step}
                                    onKeyPress={event => allowPosNumberOnlyKeys(event)}
                                    formatter={value => padStringStart((value as string), Constants.AXIS_MASK)}
                                    onBlur={() => validateRxValue(axis, axisFieldName, sideAxis, AXIS_VALIDATION_MSG)}
                                    placeholder={pastSideAxis}
                                />
                            </FormItem>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default PatientExamRx;