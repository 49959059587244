import { Tooltip } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { InfoCircleOutlined } from '@ant-design/icons';
import { localizedText } from '../../localizedText';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/patient-exam-notes-to-od-carry-over.scss';
import ComponentTextareaCard from '../component-textarea-card';

const PatientExamNotesToODCarryOver  = ({ disabled }: { disabled: boolean }) => {
    const { PATIENT_EXAM_NOTES_TOOLTIP, EXAM_NOTES_HISTORY_TITLE } = localizedText;
    const omdTextbox0 = useCare1AppSelector(store => store.examData.omd_textbox0);
    const cuOmd = useCare1AppSelector(store => store.examData.cu_omd);
    const isReferralLetterUploadPEI = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const dispatch = useCare1AppDispatch();

    // red border?
    const redBorder = cuOmd && !isReferralLetterUploadPEI;

    const HeadingComponent = () => (
        <>
            <span>{EXAM_NOTES_HISTORY_TITLE}</span>
            <Tooltip placement='topLeft' title={PATIENT_EXAM_NOTES_TOOLTIP}>
                <InfoCircleOutlined />
            </Tooltip>
        </>
    )

    return (
        <ComponentTextareaCard
            disabled={disabled}
            title={<HeadingComponent />}
            testId='omd_textbox0'
            value={omdTextbox0}
            lightBlueBackground={true}
            handleChange={(e) => dispatch(
                setExamDataValue('omd_textbox0', e.target.value)
            )}
            redBorder={redBorder}
        />
    );
}

export default PatientExamNotesToODCarryOver;