import { Col, Radio, Row, Form, Typography, Flex, Card } from 'antd';

import { localizedText } from '../../localizedText';
import { useCare1AppSelector, useCare1AppDispatch } from '../../apps/care1-hooks';
import '../../../static/css/components/admin-omd-report-options.scss'
import { setExamDataValue } from '../../reducers/patient-exam-slice';


const AdminOmdReportsOptions = () => {
    const { NO_TEXT, YES_TEXT } = localizedText;

    const aiRecommendsOmdReport = useCare1AppSelector(store => store.examData.ai_recommends_omd_report);
    const odWantsOmdReport = useCare1AppSelector(store => store.examData.od_wants_omd_report);
    const odRequestedOmdId = useCare1AppSelector(store => store.examData.od_requested_omd);
    const omds =  useCare1AppSelector(store => store.options.omds);

    const odRequestedOmd = omds.find(omd => omd.id === odRequestedOmdId)
    const doctorName = odRequestedOmd ? `Dr. ${odRequestedOmd.first_name} ${odRequestedOmd.last_name}` : '';

    const dispatch = useCare1AppDispatch();

    return (
        <Card bordered={false} className='admin-omd-reports-options'>
            <Row gutter={[0, 16]}>
                <Col span={24}>
                    <Flex justify='space-between'>
                        <Typography.Text>OD Requests report</Typography.Text>
                        <Radio.Group
                            className={'omd-requests-report'}
                            buttonStyle="solid"
                            size="small"
                            value={odWantsOmdReport}
                        >
                            <Radio.Button
                                value={true}
                            >
                                {YES_TEXT.toUpperCase()}
                            </Radio.Button>
                            <Radio.Button
                                value={false}
                            >
                                {NO_TEXT.toUpperCase()}
                            </Radio.Button>
                        </Radio.Group>
                    </Flex>
                </Col>
                <Col span={24}>
                    <Flex justify='space-between'>
                        <Typography.Text>AI recommends report</Typography.Text>
                        <Radio.Group
                            className={'ai-recommends-omd-report'}
                            buttonStyle="solid"
                            size="small"
                            value={aiRecommendsOmdReport}
                            onChange={(e) => dispatch(setExamDataValue('ai_recommends_omd_report', e.target.value === true))}
                        >
                            <Radio.Button
                                value={true}
                            >
                                {YES_TEXT.toUpperCase()}
                            </Radio.Button>
                            <Radio.Button
                                value={false}
                            >
                                {NO_TEXT.toUpperCase()}
                            </Radio.Button>
                        </Radio.Group>
                    </Flex>
                </Col>
                <Col span={24}>
                    <Typography.Text>OD Requested OMD: {doctorName}</Typography.Text>
                </Col>
            </Row>
        </Card>
    );
}

export default AdminOmdReportsOptions;