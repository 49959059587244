import React, { useState, useEffect } from 'react';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Tabs } from 'antd';
import PhotoDropzone from './photo-dropzone';
import * as Constants from '../../constants';
import '../../../static/css/components/_patient-exam-fundus.scss';
import { lengthEqual } from '../../reducers/patient-exam-slice';
import { localizedText } from '../../localizedText';

const { TabPane } = Tabs;

function PatientExamFundus({ className, tabbed, disabled, side } : 
    { className: string, tabbed: boolean, disabled: boolean, side: string }) {
        
    const { RIGHT_SIDE_TEXT,  DRAG_DROP_TEXT, SUPPORTED_FORMAT_TEXT, RIGHT_STEREOPAIR_TITLE_TEXT, RIGHT_STEREOPAIR_TITLE_TEXT_RE_OD, 
        RIGHT_FUNDUS_PHOTO_TITLE_TEXT, LEFT_STEREOPAIR_TITLE_TEXT, LEFT_STEREOPAIR_TITLE_TEXT_RE_OD,  LEFT_FUNDUS_PHOTO_TITLE_TEXT, 
        EXAM_PHOTO_STEREOPAIR, EXAM_PHOTO_STEREOPAIR_RE_OD, EXAM_PHOTO_FUNDUS } = localizedText;

    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const examIsRE = useCare1AppSelector(store => store.examData.exam_is_retina_only);
    const odAdb = useCare1AppSelector(store => store.examData.od_adb);
    const osAdb = useCare1AppSelector(store => store.examData.os_adb);
    const hasLeftFundus = useCare1AppSelector(store => store.examData.photoUrls.left_fundus_photo);
    const hasRightFundus = useCare1AppSelector(store => store.examData.photoUrls.right_fundus_photo);

    const examsWithLeftFundus = useCare1AppSelector(store => store.examData[Constants.EXAMS_WITH_LEFT_FUNDUS], lengthEqual);
    const examsWithRightFundus = useCare1AppSelector(store => store.examData[Constants.EXAMS_WITH_RIGHT_FUNDUS], lengthEqual);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);

    const rightCD = useCare1AppSelector(store => store.examData.right_cd);
    const leftCD = useCare1AppSelector(store => store.examData.left_cd);

    const [rightCdDisplay, setRightCdDisplay] = useState(rightCD);
    const [leftCdDisplay, setLeftCdDisplay] = useState(leftCD);

    useEffect(() => {
        setRightCdDisplay((rightCD === 'None' || rightCD === '') ? 'XXX' : rightCD);
        setLeftCdDisplay((leftCD === 'None' || leftCD === '') ? 'XXX' : leftCD);

    }, [rightCD, leftCD])

    const renderAssessmentInfo = () => {

        // Only render assessments for OMDR IC exams uploaded by OD IC
        if(userIsOMDR && !userIsRE && !examIsRE){
            if (side === 'right' && (hasRightFundus || examsWithRightFundus.length > 0)) {
                const cn = (odAdb === 'green') && `${rightCdDisplay}` !== 'XXX' ? "assessment-green" : "assessment-white";
                return <span
                    className={cn}
                    data-testid='assessment-info-right'
                >
                    {`C/D: ${rightCdDisplay}`}
                </span>;
            }
            else if (side === 'left' && (hasLeftFundus || examsWithLeftFundus.length > 0))  {
                const cn = (osAdb === 'green') && `${leftCdDisplay}` !== 'XXX' ? "assessment-green" : "assessment-white";
                return <span
                className={cn}
                    data-testid='assessment-info-left'
                >
                    {`C/D: ${leftCdDisplay}`}
                </span>;
            }
        } else {
            return '';
        }
    }

    let exam_photo_stereopair = EXAM_PHOTO_STEREOPAIR;
    let right_stereopair_title_text = RIGHT_STEREOPAIR_TITLE_TEXT;
    let left_stereopair_title_text = LEFT_STEREOPAIR_TITLE_TEXT;
    if (userIsOD && userIsRE) {
        exam_photo_stereopair = EXAM_PHOTO_STEREOPAIR_RE_OD;
        right_stereopair_title_text = RIGHT_STEREOPAIR_TITLE_TEXT_RE_OD;
        left_stereopair_title_text = LEFT_STEREOPAIR_TITLE_TEXT_RE_OD;
    }

    return (
        <Card className={className} bordered={false}>
            {
                tabbed
                    ? (
                        <Tabs type="card">
                            <TabPane tab={EXAM_PHOTO_FUNDUS} forceRender key="1">
                                <PhotoDropzone
                                    disabled={disabled}
                                    field={side === Constants.RIGHT_SIDE_TEXT ? Constants.RIGHT_FUNDUS_FIELD : Constants.LEFT_FUNDUS_FIELD}
                                    label={side === RIGHT_SIDE_TEXT ? RIGHT_FUNDUS_PHOTO_TITLE_TEXT : LEFT_FUNDUS_PHOTO_TITLE_TEXT}
                                    dropText={DRAG_DROP_TEXT}
                                    formatText={SUPPORTED_FORMAT_TEXT}
                                    carousel={side === RIGHT_SIDE_TEXT ? examsWithRightFundus : examsWithLeftFundus}
                                />
                                <div className="assessment-info">
                                    {renderAssessmentInfo()}
                                </div>
                            </TabPane>
                            <TabPane tab={exam_photo_stereopair} forceRender key="2">
                                <PhotoDropzone
                                    disabled={disabled}
                                    field={side === Constants.RIGHT_SIDE_TEXT ? Constants.RIGHT_STEREO_FIELD : Constants.LEFT_STEREO_FIELD}
                                    label={side === RIGHT_SIDE_TEXT ? right_stereopair_title_text : left_stereopair_title_text}
                                    dropText={DRAG_DROP_TEXT}
                                    formatText={SUPPORTED_FORMAT_TEXT}
                                    carousel={null}                                
                                />
                            </TabPane>
                        </Tabs>
                    ) : (
                        <>
                            <PhotoDropzone
                                disabled={disabled}
                                field={side === Constants.RIGHT_SIDE_TEXT ? Constants.RIGHT_FUNDUS_FIELD : Constants.LEFT_FUNDUS_FIELD}
                                label={side === RIGHT_SIDE_TEXT ? RIGHT_FUNDUS_PHOTO_TITLE_TEXT : LEFT_FUNDUS_PHOTO_TITLE_TEXT}
                                dropText={DRAG_DROP_TEXT}
                                formatText={SUPPORTED_FORMAT_TEXT}
                                carousel={side === RIGHT_SIDE_TEXT ? examsWithRightFundus : examsWithLeftFundus}
                            />
                            <div className="assessment-info">
                                {renderAssessmentInfo()}
                            </div>
                        </>
                    )
            }
        </Card>
    );
}

export default PatientExamFundus;
