import { baseApi } from './base-api'

export interface ICsvNearMissSummaryResponse {
    near_miss_list: {
        summary: {
            rows: [],
            days: [],
        }
    };
}

export interface ICsvNearMissListResponse {
    near_miss_list: [];
}

export interface ICsvNearMissByClinicLocationSummaryResponse {
    near_miss_list: {
        summary: {
            rows: [],
            days: [],
        }
    };
}

export interface ICsvNearMissListParam {
    historicalPeriodOption: string | undefined,
    fieldNameOption: string | undefined,
    clinicLocationFullNameOption: string | undefined,
}

export interface ICsvNearMissListFilterOptionsResponse {
    clinic_location_full_name_options: [],
    field_name_options: [],
}

export const gptChatNearMissApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getCsvNearMissSummary: builder.query<ICsvNearMissSummaryResponse, void>({
            query: () => 'admin/csv_near_miss/summary/',

            // Give a tag to staffs so that other API actions can invalidate CsvNearMissSummary
            providesTags: [{ type: 'CsvNearMissSummary', id: 'SUMMARY' }]
        }),
        getCsvNearMissList: builder.query<ICsvNearMissListResponse, ICsvNearMissListParam>({
            query: (param) => `admin/csv_near_miss/list/?historical_period=${param.historicalPeriodOption}&field_name=${param.fieldNameOption}&clinic_location_full_name=${param.clinicLocationFullNameOption}`,

            // Give a tag to staffs so that other API actions can invalidate CsvNearMissList
            providesTags: [{ type: 'CsvNearMissList', id: 'LIST' }]
        }),
        getCsvNearMissListFilterOptions: builder.query<ICsvNearMissListFilterOptionsResponse, void>({
            query: () => 'admin/csv_near_miss/list_filter_options/'
        }),
        getCsvNearMissByClinicLocationSummary: builder.query<ICsvNearMissByClinicLocationSummaryResponse, void>({
            query: () => 'admin/csv_near_miss/summary_by_clinic_location/',

            // Give a tag to staffs so that other API actions can invalidate CsvNearMissSummary
            providesTags: [{ type: 'CsvNearMissByClinicLocationSummary', id: 'BYCLINICLOCATIONSUMMARY' }]
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const { useGetCsvNearMissSummaryQuery, useGetCsvNearMissListQuery, useGetCsvNearMissByClinicLocationSummaryQuery, useGetCsvNearMissListFilterOptionsQuery } = gptChatNearMissApi;