import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { getCsrfToken } from '../helpers/utilities';
import { apiRequest } from '../services/api-request';
import { logout } from './user-slice';


export interface IIgpListItem {
    first_name: string;
    last_name: string;
    id: number;
}

export interface IIgpList {
    operating: boolean;
    list: IIgpListItem[];
}

interface IIgpListResponseData {
    igps: IIgpListItem[];
}

const initialState : IIgpList = {
    operating: false,
    list: [],
};

// GET: getIGPListRequest
export const getIGPListRequest = createAsyncThunk(
    'igpList/getIgpList',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/igp/list/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
);

export const igpListSlice = createSlice({
    name: 'igpList',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {

        // getUsInsuranceListRequest (by patient ID)
        builder.addCase(getIGPListRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(getIGPListRequest.fulfilled, (state, action : PayloadAction<IIgpListResponseData>) => {
            state.operating = false;
            state.list = action.payload.igps;
        });
        builder.addCase(getIGPListRequest.rejected, (state, action) => {
            state.operating = false;
            Modal.error({
                className: 'info-modal',
                title: `Errors getting US insurance list. ${action.payload}`,
            })
        });
    }
})

export default igpListSlice.reducer;
