import React from 'react';
import { Col, InputNumber, Popconfirm, Row, Select } from 'antd';
import { deleteIOPHistoryEntry, editIOPHistoryEntry } from '../../reducers/patient-exam-slice';
import { IIopHistoryItem } from '../../reducers/patient-exam-slice';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { DefaultOptionType } from 'antd/lib/select';

const { Option } = Select;

type ComponentProps = {
    data: IIopHistoryItem,
    index: number,
    glcDropsList: string[][],
    eyeTypes: string[][],
    size: SizeType,
    disabled: boolean,
    procedureList: string[][],
    machinesList: string[][],
}

const PatientExamIOPHistoryEntry = ({ data, index, glcDropsList, eyeTypes,
    size, disabled, procedureList, machinesList }: ComponentProps) => {
    
    const isOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const isOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const dispatch = useCare1AppDispatch();

    const handleChange = (field: keyof IIopHistoryItem, value: number | null | string) => {
        dispatch(editIOPHistoryEntry({
            editIndex:index, field, value: value ?? ''
        }));
    }

    const handleSelect = (value: string, event: DefaultOptionType) => {
        const field = event.props.rel;
        dispatch(editIOPHistoryEntry({
            editIndex:index, field, value
        }));
    }

    const handleMultiSelectChange = (field: keyof IIopHistoryItem, value: string[]) => {
        // dispatch(editIOPHistoryEntry(index, field, value));
        dispatch(editIOPHistoryEntry({
            editIndex:index, field, value
        }));
    }

    const omdrMachinesOptions = [['app_tono_icare', ' '], ['noncontact', 'NC']];

    const machinesOptions = (isOMDC || isOMDR) ? omdrMachinesOptions : machinesList;

    const isAllEntriesEmpty = !(data && (data.eye_select || data.low_iop || data.high_iop || (
        data.iop_history_drops_select && data.iop_history_drops_select.length > 0)
        || data.machine_select || data.iop_history_procedure_prefix || (data.iop_history_procedure_select
        && data.iop_history_procedure_select.length > 0)));

    // Apply red border for procedure prefix when
    // 1. procedure prefix is empty (nothing selected) AND
    // 2. procedure select it not empty (at least one entry is selected) AND
    // 3. procedure select does not equal to falsy value (undefined, null, '', false, 0) AND
    // 3. not all fields are empty
    const isProcedurePrefixRequired = !((data && data.iop_history_procedure_prefix)
        || (data && data.iop_history_procedure_select && data.iop_history_procedure_select.length === 0)
        || (!data.iop_history_procedure_select) || isAllEntriesEmpty);

    // Apply red border for procedure select when
    // 1. procedure prefix is not empty (pre- or post- is selected) AND
    // 2. procedure select it empty (nothing selected) AND
    // 3. not all fields are empty
    const isProcedureSelectRequired = !((data && !data.iop_history_procedure_prefix)
        || (data && data.iop_history_procedure_select && data.iop_history_procedure_select.length > 0)
        || isAllEntriesEmpty);

    return (
        <Row gutter={5} className="div-tag-entry">
            <Col span={2}>
                <Select
                    showSearch
                    size={size}
                    onSelect={(value, event) => handleSelect(value, event)}
                    value={(data && data.eye_select && data.eye_select === '') ? null : data.eye_select}
                    className={`eye-selection ${(data && data.eye_select) || isAllEntriesEmpty ? null : 'red-border'}`}
                >
                    {eyeTypes && eyeTypes.map((option) => (
                        <Option
                            rel="eye_select"
                            key={option[1]}
                            value={option[0]}
                        >
                            {option[1]}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col span={2}>
                <InputNumber
                    className="low-iop"
                    size={size}
                    disabled={disabled}
                    min={0}
                    max={70}
                    step={1}
                    value={data.low_iop}
                    onChange={(val) => handleChange('low_iop', val)}
                    style={(data && data.low_iop) || isAllEntriesEmpty ? {} : { border: '1px solid red' }}
                />
            </Col>
            <Col span={1}>-</Col>
            <Col span={2}>
                <InputNumber
                    className="high-iop"
                    size={size}
                    disabled={disabled}
                    min={0}
                    max={70}
                    step={1}
                    value={data.high_iop}
                    onChange={(val) => handleChange('high_iop', val)}
                />
            </Col>

            <Col span={5}>
                <Select
                    showSearch
                    mode="multiple"
                    size={size}
                    onChange={(val) => handleMultiSelectChange('iop_history_drops_select', val)}
                    value={data.iop_history_drops_select ? data.iop_history_drops_select : []}
                    className={(data && data.iop_history_drops_select && data.iop_history_drops_select.length > 0)
                        || isAllEntriesEmpty ? '' : 'red-border'}
                >
                    {glcDropsList && glcDropsList.map((option) => (
                        <Option
                            key={option[1]}
                            value={option[0]}
                        >
                            {option[1]}
                        </Option>
                    ))}
                </Select>
            </Col>

            <Col span={3}>
                <Select
                    showSearch
                    size={size}
                    onChange={(val) => handleChange('iop_history_procedure_prefix', val)}
                    value={data.iop_history_procedure_prefix}
                    className={isProcedurePrefixRequired ? 'red-border' : ''}
                    allowClear
                >
                    <Option value="pre-">pre-</Option>
                    <Option value="post-">post-</Option>
                </Select>
            </Col>
            <Col span={4}>
                <Select
                    showSearch
                    size={size}
                    mode="multiple"
                    onChange={(val) => handleMultiSelectChange('iop_history_procedure_select', val)}
                    value={data.iop_history_procedure_select ? data.iop_history_procedure_select : []}
                    className={isProcedureSelectRequired ? 'red-border' : ''}
                >
                    {procedureList && procedureList.map((option) => (
                        <Option
                            key={option[1]}
                            value={option[0]}
                        >
                            {option[1]}
                        </Option>
                    ))}
                </Select>
            </Col>

            <Col span={4}>
                <Select
                    showSearch
                    size={size}
                    onChange={(val) => handleChange('machine_select', val)}
                    value={data.machine_select}
                    className={((data && data.machine_select) || isAllEntriesEmpty || (isOMDC || isOMDR))? '' : 'red-border'}
                    allowClear
                >
                    {machinesOptions && machinesOptions.map((option) => (
                        <Option
                            key={option[1]}
                            value={option[0]}
                        >
                            {option[1]}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col span={1} className="delete">
                <Popconfirm title="Are you sure？" onConfirm={() => { dispatch(deleteIOPHistoryEntry(index)); }}>X</Popconfirm>
            </Col>
        </Row>
    );
}
export default PatientExamIOPHistoryEntry;