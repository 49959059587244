import { useEffect, useRef } from 'react';
import '../../../static/css/components/macula-abnormality.scss';
import { Rect, Transformer } from 'react-konva';


const MaculaAbnormalityOpticRect = ({ shapeProps, isSelected, onSelect, onChange }) => {
    const shapeRef = useRef(null);
    const trRef = useRef(null);

    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            if (trRef.current) {
                trRef.current.nodes([shapeRef.current]);
                trRef.current.getLayer().batchDraw();
            }
        }
    }, [isSelected]);

    return (
        <>
            <Rect
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    if (node) {
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();
    
                        // we will reset it back
                        node.scaleX(1);
                        node.scaleY(1);

                        const width = Math.max(5, node.width() * scaleX);
                        const height = Math.max(node.height() * scaleY);
                        onChange({
                            ...shapeProps,
                            x: node.x(),
                            y: node.y(),
                            // set minimal value
                            width: width,
                            height: height,
                            offsetX: width / 2,
                            offsetY: height / 2,
                        });
                    }
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={false}
                    keepRatio={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </>
    );
};

export default MaculaAbnormalityOpticRect;
