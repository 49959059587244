import { useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { FieldData } from 'rc-field-form/lib/interface';
import { InputNumberProps } from 'antd/lib/input-number';
import { InputNumber, Form } from 'antd';
import * as Constants from '../../../constants';
import InputNumberSuffix from '../input-number-suffix';
import { allowPosNumberOnlyKeys } from '../../../helpers/utilities';
import { IExamData, setExamDataValue } from '../../../reducers/patient-exam-slice';
import { updateGptSetValuesNearMissData } from '../../../reducers/gpt-set-values-slice';
import ExamMetricValidationModal from './exam-metric-validation-modal';
import { isCatPostOpSelected } from '../../../helpers/diagnosis-convert';
import { calculateIOPWarningColor } from '../../../helpers/patient-exam-convert';
import '../../../../static/css/components/patient-exam-iop.scss';

type ComponentProps = {
    side: 'od_iop' | 'os_iop';
    disabled: boolean;
    suffix: boolean;
    placeholderValue: string | undefined;
    gptSaved?: boolean;
    gptNearMiss?: boolean;
}

const PatientExamIOP = ({side, disabled, suffix, placeholderValue, gptSaved, gptNearMiss}: ComponentProps) => {
    const odIOP = useCare1AppSelector(store => store.examData.od_iop);
    const osIOP = useCare1AppSelector(store => store.examData.os_iop);
    const sideIOP = useCare1AppSelector(store => store.examData[side]);
    const diagnosisEntries = useCare1AppSelector(store => store.diagnosis.entries);
    const catPostOp = useCare1AppSelector(store => store.examData.rr_cat_post_op);
    const isCatPostOp = isCatPostOpSelected(diagnosisEntries, catPostOp);
    const isOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const isOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const isAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const isGlaucoma = useCare1AppSelector(store => store.examData.is_glaucoma);
    const sideIopAim = useCare1AppSelector(store => store.examData[`${side}_aim`]);

    // Yellow warning border should display for OD users only
    const userIsOD = useCare1AppSelector(store => store.user.isOD);

    const [ form ] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    const validateIOPValue = () => {
        const field = side;
        const value = sideIOP;
        if (value && typeof (value) === 'number' && (value < 0 || value > 70)) {
            dispatch(setExamDataValue(field, ''));
            ExamMetricValidationModal(Constants.IOP_VALIDATION_MSG);
        }
    };

    // Side effects that we run after component render.
    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            [side]: sideIOP
        };

        form.setFieldsValue(newValidationFieldValues);

        // The component should validate on every render
        const validateForm = () => {
            return form.validateFields();
        };
        validateForm();

    }, [side, sideIOP, form]);


    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case side:
                        dispatch(setExamDataValue(field.name[0], formatValue
                            ? formatValue(field.value, { userTyping: false, input: '' })
                            : field.value));
                        dispatch(updateGptSetValuesNearMissData({id: [field.name[0]].toString() as keyof IExamData, value: formatValue
                            ? formatValue(field.value, { userTyping: false, input: '' })
                            : field.value}));
                        break;
                    default:
                        break;
                }
            }
        });
    };

    const getIopClassName = () => {
        const iopClassName = [];
        isOMDC && iopClassName.push(calculateIOPWarningColor(sideIOP));
        if ((isOMDR || isAdmin)){

            const iop = sideIOP ? Number(sideIOP) : Number(placeholderValue);
            const parsedSideIopAim = sideIopAim === 'XXX' ? 'XXX' : Number(sideIopAim);

            if (isGlaucoma && parsedSideIopAim !== 'XXX') {
                const iop = sideIOP ? Number(sideIOP) : Number(placeholderValue);
                const iopAimDiff = iop - parsedSideIopAim;

                if (iopAimDiff >= 3) {
                    iopClassName.push('iop-red-background');
                } else if (iopAimDiff < 3 && iopAimDiff > 0) {
                    iopClassName.push('iop-yellow-background');
                } else if (iopAimDiff <= 0) {
                    iopClassName.push('iop-green-background');
                }
            } else {
                if (iop >= 24) {
                    iopClassName.push('iop-red-background');
                } else if (iop < 24 && iop > 21) {
                    iopClassName.push('iop-yellow-background');
                } else if (iop <= 21) {
                    iopClassName.push('iop-green-background');
                }
            }
        }
        return iopClassName.join(' ');
    }

    // All IOP entries should be in the format of # or ##
    const formatValue: InputNumberProps['formatter'] = (value, info) => {
        const regex = /^([0-9]?)([0-9]?).*$/;
        const matches = String(value).match(regex);

        if (matches && matches[1] && matches[2]) {
            return String(Number(`${matches[1]}${matches[2]}`));
        } else if (matches && matches[1]) {
            return String(Number(matches[1]));
        } else {
            return '';
        }
    }

    const gptClassname = () => {
        if (gptNearMiss) {
            return 'exam-gpt-state-red';
        }
        if (gptSaved) {
            return 'exam-gpt-state-yellow';
        }
        return '';
    }

    return (
        <Form form={form} onFieldsChange={onFieldsChange} >
            <div className={gptClassname()}>
                <Form.Item
                    className={`patient-exam-iop component-input ${!suffix ? 'narrow-iop' : ''} ${getIopClassName()}`}
                    validateStatus={ userIsOD && !odIOP && !osIOP ? 'warning' : '' }
                    name={side}
                >
                    {/* IOP 001, IOP 002, IOP 004, IOP 005, IOP 006, IOP 007 */}
                    {/* ISH 001, ISH 002, ISH 004 ISH 005, ISH 007 */}
                    {/* Use onKeyPress event to prevent letters */}
                    {/* Use formatter to enforce a max of two digits */}
                    { suffix
                        // Use parsing to prevent letters and to support UX for selecting two digit values
                        ? <InputNumberSuffix
                            formatter={formatValue}
                            onKeyPress={event => allowPosNumberOnlyKeys(event)}
                            size={'small'}
                            disabled={disabled}
                            suffix={Constants.MM_OF_MERCURY_TEXT}
                            placeholder={placeholderValue}
                            onBlur={validateIOPValue}
                            isCatPostOp={isCatPostOp}
                        />

                        // Use parsing to prevent letters and to support UX for selecting two digit values
                        : <InputNumber
                            formatter={formatValue}
                            onKeyPress={event => allowPosNumberOnlyKeys(event)}
                            size={'small'}
                            disabled={disabled}
                            placeholder={placeholderValue}
                            onBlur={validateIOPValue}
                        />
                    }
                </Form.Item>
            </div>
        </Form>
    );
}

export default PatientExamIOP;