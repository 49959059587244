import { Tag } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { refreshTxAlgo3Request} from '../../reducers/txalgo3-slice';
import '../../../static/css/components/patient-exam-vf-override.scss';

const { CheckableTag } = Tag;

const PatientExamVFOverride = ({ side }: { side: string }) => {

    const sideAbbreviation = side === 'right' ? 'od' : 'os';

    const VFOverride = useCare1AppSelector(store => store.examData[`${sideAbbreviation}_vf_override`]);
    const examId = useCare1AppSelector(store => store.examData.id);
    const isGlaucoma = useCare1AppSelector(store => store.examData.is_glaucoma)

    const dispatch = useCare1AppDispatch();

    const onChange = (checked: boolean, value: string) => {
        if(checked && examId) {
            dispatch(setExamDataValue(`${sideAbbreviation}_vf_override`, value));
            isGlaucoma && dispatch(refreshTxAlgo3Request(
                {
                    exam_id: examId,
                    [`${sideAbbreviation}_vf_override`]: value
                }
            ));
        }
    }

    return (
        <div className='vf-override'>
            <CheckableTag
                checked={VFOverride==='' || VFOverride==='xxx'}
                onChange={checked => onChange(checked, 'xxx')}
                className={ VFOverride === '' || VFOverride==='xxx' ? 'xxx-checked': ''}
            >
                XXX
            </CheckableTag>
            <CheckableTag
                checked={VFOverride==='wnl'}
                onChange={checked => onChange(checked, 'wnl')}
                className={ VFOverride==='wnl' ? 'wnl-checked': ''}
            >
                WNL
            </CheckableTag>
        </div>
    );
}

export default PatientExamVFOverride;