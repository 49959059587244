import { localizedText } from '../localizedText';
import { IAdminPatientsToReviewList, IIGPDataResponse, IIGPStatusListItem, IOMDCDataResponse, IOMDCStatusListItem,
    IOMDRPatientsToReviewList, IPatientsToReviewListItem, IStandardizedReviewPointsList, TypeKeyNumberValueNumber
    } from '../reducers/admin-review-lists-slice';
import { twoDecimalsNotRounded } from './utilities';

export function convertOMDRPatientsToReviewResponseToTableData(patientsToReviewList: IOMDRPatientsToReviewList) {
    const { SPECIAL_ATTENTION, RETINA_DM_AMD_ERM, OTHER_TEXT, GLC_PARAMETERS_SET, GLC_BORDERLINE_OBSERVE, 
        GLC_BORDERLINE_TREAT, GLC_GOOD_DISC_GOOD_VF, GLC_BAD_DISC_GOOD_VF, GLC_GOOD_DISC_BAD_VF, 
        GLC_BAD_DISC_BAD_VF, TOTAL_STANDARDIZED_POINTS, TOTAL_NUMBER_REVIEWS, } = localizedText;

    const tableListArray: {key: keyof IOMDRPatientsToReviewList, category: string}[] = [
        { key: 'special_list', category: SPECIAL_ATTENTION },
        { key: 'retina1_list', category: RETINA_DM_AMD_ERM },
        { key: 'retina2_list', category: OTHER_TEXT },
        { key: 'glcp_list', category: GLC_PARAMETERS_SET },
        { key: 'glcbo_list', category: GLC_BORDERLINE_OBSERVE },
        { key: 'glcbt_list', category: GLC_BORDERLINE_TREAT },
        { key: 'gdgvf_list', category: GLC_GOOD_DISC_GOOD_VF },
        { key: 'bdgvf_list', category: GLC_BAD_DISC_GOOD_VF },
        { key: 'gdbvf_list', category: GLC_GOOD_DISC_BAD_VF },
        { key: 'bdbvf_list', category: GLC_BAD_DISC_BAD_VF },
        { key: 'summed_count', category: TOTAL_NUMBER_REVIEWS },
    ]

    const tableList = tableListArray.map(({key, category}) => ({
        category,
        patients_to_review: patientsToReviewList[key],
        pre_review_alerts: null,
        key,
    }));

    tableList.push({
        'category': TOTAL_STANDARDIZED_POINTS,
        'patients_to_review': '',
        'pre_review_alerts':  null,
        'key': 'total_standardized_points',
    })

    return {
        tableList,
        totalReviewMinutes: twoDecimalsNotRounded(patientsToReviewList.total_review_minutes),
    };
}

// Return data for the Admin Patients to Review table and Standardized Review Points table
export function convertAdminPatientsToReviewResponseToTableData(patientsToReview : IAdminPatientsToReviewList) {
    const { SPECIAL_ATTENTION, RETINA_DM_AMD_ERM, OTHER_TEXT, GLC_PARAMETERS_SET, GLC_BORDERLINE_OBSERVE, 
        GLC_BORDERLINE_TREAT, GLC_GOOD_DISC_GOOD_VF, GLC_BAD_DISC_GOOD_VF, GLC_GOOD_DISC_BAD_VF, 
        GLC_BAD_DISC_BAD_VF, TOTAL_STANDARDIZED_POINTS, TOTAL_NUMBER_REVIEWS, TOTAL_CHARTS_PRE_REVIEWED } = localizedText;

    let patientsToReviewList : IPatientsToReviewListItem[] = [];
    let standardizedReviewPointsList : IStandardizedReviewPointsList[] = [];
    let specialListTotalPatients : {total : number} = { total: 0 }
    let retina1TotalPatients : {total : number} = { total: 0 }
    let retina2TotalPatients : {total : number} = { total: 0 }
    let glcpTotalPatients : {total : number} = { total: 0 }
    let glcboTotalPatients : {total : number} = { total: 0 }
    let glcbtListTotalPatients : {total : number} = { total: 0 }
    let gdgvfTotalPatients : {total : number} = { total: 0 }
    let bdgvfTotalPatients  : {total : number} = { total: 0 }
    let gdbvfTotalPatients : {total : number} = { total: 0 }
    let bdbvfTotalPatients : {total : number} = { total: 0 }
    let totalPatientsToReview : number = 0;
    let totalSRP : number = 0;

    // Build the patients to review totals for each OMDR based on filter type for the SRP Table
    const buildFilterTypeData = (filterDictionary: TypeKeyNumberValueNumber,
        filterTableKey: keyof IStandardizedReviewPointsList, filterTotalVariable: {total : number}) => {
        Object.entries(filterDictionary).forEach(([key, value]) => {
            if(key !== 'null'){
                const index = standardizedReviewPointsList.findIndex(entry => entry.omdr === key);
                if(index >= 0){
                    standardizedReviewPointsList[index] = {...standardizedReviewPointsList[index], [filterTableKey]: value};
                } else {
                    standardizedReviewPointsList.push({omdr: key, [filterTableKey]: value, srp: 0, key: filterTableKey })
                }
                filterTotalVariable.total += value;

                // We need a total of all patients to review
                totalPatientsToReview += value;
            }
        });
    }
    buildFilterTypeData(patientsToReview.special_list, 'specialAttention', specialListTotalPatients);
    buildFilterTypeData(patientsToReview.retina1_list, 'retina1', retina1TotalPatients);
    buildFilterTypeData(patientsToReview.retina2_list, 'retina2', retina2TotalPatients);
    buildFilterTypeData(patientsToReview.glcp_list, 'glcP', glcpTotalPatients);
    buildFilterTypeData(patientsToReview.glcbo_list, 'glcBO', glcboTotalPatients);
    buildFilterTypeData(patientsToReview.glcbt_list, 'glcBT', glcbtListTotalPatients);
    buildFilterTypeData(patientsToReview.gdgvf_list, 'gdgvf', gdgvfTotalPatients);
    buildFilterTypeData(patientsToReview.bdgvf_list, 'bdgvf', bdgvfTotalPatients);
    buildFilterTypeData(patientsToReview.gdbvf_list, 'gdbvf', gdbvfTotalPatients);
    buildFilterTypeData(patientsToReview.bdbvf_list, 'bdbvf', bdbvfTotalPatients);

    // Build the SRP totals for each OMDR for the SRP Table.
    const buildSRPData = (filterDictionary : TypeKeyNumberValueNumber) => {
        Object.entries(filterDictionary).forEach(([key, value]) => {
            if(key !== 'null'){
                const index = standardizedReviewPointsList.findIndex(entry => entry.omdr === key);
                if(index >= 0){
                    standardizedReviewPointsList[index].srp += value;
                    totalSRP += value;
                }
            }
        });
    }
    buildSRPData(patientsToReview.specialListSRP);
    buildSRPData(patientsToReview.retina1ListSRP);
    buildSRPData(patientsToReview.retina2ListSRP);
    buildSRPData(patientsToReview.glcpListSRP);
    buildSRPData(patientsToReview.glcboListSRP);
    buildSRPData(patientsToReview.glcbtListSRP);
    buildSRPData(patientsToReview.gdgvfListSRP);
    buildSRPData(patientsToReview.gdbvfListSRP);
    buildSRPData(patientsToReview.bdgvfListSRP);
    buildSRPData(patientsToReview.bdbvfListSRP);

    // Build data for the Patients to Review Table
    patientsToReviewList.push({
        'category': SPECIAL_ATTENTION,
        'patients_to_review': specialListTotalPatients.total,
        'pre_review_alerts':  patientsToReview.special_list_palert_count,
        'key': 'special_list',
    });
    patientsToReviewList.push({
        'category': RETINA_DM_AMD_ERM,
        'patients_to_review': retina1TotalPatients.total,
        'pre_review_alerts':  patientsToReview.retina1_list_palert_count,
        'key': 'retina1_list',
    });
    patientsToReviewList.push({
        'category': OTHER_TEXT,
        'patients_to_review': retina2TotalPatients.total,
        'pre_review_alerts':  patientsToReview.retina2_list_palert_count,
        'key': 'retina2_list',

    });
    patientsToReviewList.push({
        'category': GLC_PARAMETERS_SET,
        'patients_to_review': glcpTotalPatients.total,
        'pre_review_alerts':  patientsToReview.glcp_list_palert_count,
        'key': 'glcp_list',
    });
    patientsToReviewList.push({
        'category': GLC_BORDERLINE_OBSERVE,
        'patients_to_review': glcboTotalPatients.total,
        'pre_review_alerts':  patientsToReview.glcbo_list_palert_count,
        'key': 'glcbo_list',
    });
    patientsToReviewList.push({
        'category': GLC_BORDERLINE_TREAT,
        'patients_to_review': glcbtListTotalPatients.total,
        'pre_review_alerts':  patientsToReview.glcbt_list_palert_count,
        'key': 'glcbt_list',
    });
    patientsToReviewList.push({
        'category':  GLC_GOOD_DISC_GOOD_VF,
        'patients_to_review': gdgvfTotalPatients.total,
        'pre_review_alerts':  patientsToReview.gdgvf_list_palert_count,
        'key': 'gdgvf_list',
    });
    patientsToReviewList.push({
        'category': GLC_BAD_DISC_GOOD_VF,
        'patients_to_review': bdgvfTotalPatients.total,
        'pre_review_alerts':  patientsToReview.bdgvf_list_palert_count,
        'key': 'bdgvf_list',
    });
    patientsToReviewList.push({
        'category': GLC_GOOD_DISC_BAD_VF,
        'patients_to_review': gdbvfTotalPatients.total,
        'pre_review_alerts':  patientsToReview.gdbvf_list_palert_count,
        'key': 'gdbvf_list',
    });
    patientsToReviewList.push({
        'category': GLC_BAD_DISC_BAD_VF,
        'patients_to_review': bdbvfTotalPatients.total,
        'pre_review_alerts':  patientsToReview.bdbvf_list_palert_count,
        'key': 'bdbvf_list',
    });
    patientsToReviewList.push({
        'category': TOTAL_NUMBER_REVIEWS,
        'patients_to_review': totalPatientsToReview,
        'pre_review_alerts':  patientsToReview.total_palert_count,
        'key': 'total',
    });
    patientsToReviewList.push({
        'category': TOTAL_STANDARDIZED_POINTS,
        'patients_to_review': totalSRP,
        'pre_review_alerts': 0,
        'key': 'total_srp',
    });
    patientsToReviewList.push({
        'category': TOTAL_CHARTS_PRE_REVIEWED,
        'patients_to_review': patientsToReview.total_exams_to_prereview,
        'pre_review_alerts': 0,
        'key': 'total_exams'
    })

    return {
        patientsToReviewTableList: patientsToReviewList,
        standardizedReviewPointsTableList: standardizedReviewPointsList,
    };
}

// Build data for the Admin OMDC Status list table
export const convertAdminOMDCStatusList = (omdcStatusList : IOMDCDataResponse) => {

    let omdcStatusListConverted : IOMDCStatusListItem[] = []

    const buildData = (statusTypeDict: TypeKeyNumberValueNumber, statusKey: keyof IOMDCStatusListItem ) => {
        Object.entries(statusTypeDict).forEach(([key, value]) => {
            const index = omdcStatusListConverted.findIndex(entry => entry.omdc === key);
                if(index >= 0){
                    omdcStatusListConverted[index] = {...omdcStatusListConverted[index], [statusKey]: value};

                } else {
                    omdcStatusListConverted.push({omdc: key, [statusKey]: value, key})
                }
        });
    }

    buildData(omdcStatusList.ready_total, 'readyTotal');
    buildData(omdcStatusList.ready_cataracts, 'readyCataracts');
    buildData(omdcStatusList.ready_early_retina, 'readyEarlyRetina');
    buildData(omdcStatusList.ready_glc_sus, 'readyGlcSus');
    buildData(omdcStatusList.reviewed_total, 'reviewedTotal');
    buildData(omdcStatusList.reviewed_cataracts, 'reviewedCataracts');
    buildData(omdcStatusList.reviewed_early_retina, 'reviewedEarlyRetina');
    buildData(omdcStatusList.reviewed_glc_sus, 'reviewedGlcSus');
    buildData(omdcStatusList.processed_last_six_months, 'processedLastSixMonths');

    return omdcStatusListConverted;
}

// Build data for the Admin IGP Status list table
export const convertAdminIGPStatusList = (igpStatusList : IIGPDataResponse ) => {

    let igpStatusListConverted : IIGPStatusListItem[]= []

    const buildData = (statusTypeDict : TypeKeyNumberValueNumber, statusKey : keyof IIGPStatusListItem ) => {
        Object.entries(statusTypeDict).forEach(([key, value]) => {
            const index = igpStatusListConverted.findIndex(entry => entry.igp === key);
                if(index >= 0){
                    igpStatusListConverted[index] = {...igpStatusListConverted[index], [statusKey]: value};

                } else {
                    igpStatusListConverted.push({'igp': key, [statusKey]: value, key})
                }
        });
    }

    buildData(igpStatusList.ready_total, 'readyTotal');
    buildData(igpStatusList.reviewed_total, 'reviewedTotal');
    buildData(igpStatusList.referred_last_twelve_months, 'referredTotal');

    return igpStatusListConverted;
}