import { SaveOutlined } from '@ant-design/icons';
import { Button, Modal, Col, Row, Form } from 'antd';
import PatientEditForm from './patient-edit-form';
import globalColors from '../../../static/css/shared/_global-style.scss';
import '../../../static/css/components/_patient-add-modal.scss';
import { localizedText } from '../../localizedText';
import { buildPatientFromState } from '../../helpers/patient-edit-convert';
import { IPatientDetail, addPatientRequest, closeAddPatientModal } from '../../reducers/patient-edit-slice';
import { ADD_PATIENT_CONFIRMATION_TEXT } from '../../constants';
import { openExamDateSelectModal } from '../../reducers/patient-details-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import PatientAddGPModal from './patient-add-gp-modal';
import PatientEditGPModal from './patient-edit-gp-modal';

const PatientAddModal = () => {
    const { NEW_PATIENT_TEXT, CANCEL_BUTTON_TEXT, SAVE_BUTTON_TEXT } = localizedText;
    const isVisible = useCare1AppSelector(store => store.patientEdit.addModalOpen);
    const isRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const patientEditDetails = useCare1AppSelector(store => store.patientEdit.details);
    
    const [ form ] = Form.useForm();

    const dispatch = useCare1AppDispatch();

    const handleCancel = () => {
        dispatch(closeAddPatientModal());
    }

    const handleSubmit = () => {
        form.submit();
    }

    const onFinish = async () => {
        const newPatient = buildPatientFromState(patientEditDetails) as IPatientDetail;
        try {
            const response = await dispatch(addPatientRequest(newPatient)).unwrap();
             // Show the success message in a dialog.
            dispatch(closeAddPatientModal());
            Modal.info({
                className: 'info-modal',
                title: ADD_PATIENT_CONFIRMATION_TEXT,
                onOk: () => {
                    // Select Exam Date after new patient is added successfully
                    const patientId = response.patient;
                    dispatch(openExamDateSelectModal(patientId));
            },
        });

        } catch (error) {
            console.log('error is ',error);
        }
    }

    return (
        <Modal
            className={`add-patient-modal ${!isRE && 'ic-add-patient-modal'}`}
            open={isVisible}
            width="840"
            closable={false}
            footer={null}
            maskStyle={{ backgroundColor: globalColors['maskOverlayBlur'] }}
        >
            <Row className="header-section" align="middle">
                <Col span={10}>
                    <div className="modal-title">
                        {NEW_PATIENT_TEXT}
                    </div>
                </Col>
                <Col span={14} className={'buttons-column'}>
                    <Button
                        className="cancel-button"
                        onClick={handleCancel}
                    >
                        {CANCEL_BUTTON_TEXT}
                    </Button>
                    <Button
                        className="save-patient-button"
                        icon={<SaveOutlined />}
                        onClick={handleSubmit}
                    >
                        {SAVE_BUTTON_TEXT}
                    </Button>
                </Col>
            </Row>
            <PatientEditForm
                form={form}
                successAction={onFinish}
                patientID={null}
            />
            <PatientAddGPModal />
            <PatientEditGPModal />
        </Modal>
    );
}

export default PatientAddModal;
