import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getCsrfToken } from "../helpers/utilities";
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";
import { IMaculaAbnormalityUIStates, IMaculaAbnormalityResults } from './macula-abnormality-slice';

export interface IMaculaHistoricalAnalysisItem {
    exam_id: number,
    exam_date: string,
    exam_date_utc: string,
    right_fundus_photo: string,
    left_fundus_photo: string,
    photo: string,
    situational_awareness_status: string,
    situational_awareness_ui_states: IMaculaAbnormalityUIStates,
    macula_abnormality_results: IMaculaAbnormalityResults,
}

export interface IMaculaHistoricalAnalysis {
    api_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    right_macula_historical_analysis: IMaculaHistoricalAnalysisItem[],
    left_macula_historical_analysis: IMaculaHistoricalAnalysisItem[],
    show_modal: boolean,
    selected_exam_id: number | null,
    selected_side: string,
}

const initialState: IMaculaHistoricalAnalysis = {
    api_status: 'idle',
    right_macula_historical_analysis: [],
    left_macula_historical_analysis: [],
    show_modal: false,
    selected_exam_id: null,
    selected_side: '',
};


export const fetchMaculaHistoricalAnalysis = createAsyncThunk(
    'maculaHistoricalAnalysis/fetch',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/macula_historical_analysis/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const maculaHistoricalAnalysisSlice = createSlice({
    name: 'maculaHistoricalAnalysis',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setHistoricalAnalysisData: (state, action) => {
            // sample action.payload {key:'memberId', value: '12345'}
            if(action.payload){
                return {...state, [action.payload.key]: action.payload.value}
            }
            return {...state};
        },
        openSegmentationModal: (state) => {
            state.show_modal = true;
        },
        closeSegmentationModal: (state) => {
            state.show_modal = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMaculaHistoricalAnalysis.pending, (state) => {
                state.api_status = 'loading';
            })
            .addCase(fetchMaculaHistoricalAnalysis.fulfilled, (state, action) => {
                state.api_status = 'succeeded';

                state.right_macula_historical_analysis = action.payload.right_macula_historical_analysis
                state.left_macula_historical_analysis = action.payload.left_macula_historical_analysis
            })
            .addCase(fetchMaculaHistoricalAnalysis.rejected, (state) => {
                state.api_status = 'failed';
            });
    },
});

export const { setHistoricalAnalysisData, openSegmentationModal, closeSegmentationModal } = maculaHistoricalAnalysisSlice.actions;

export default maculaHistoricalAnalysisSlice.reducer;