import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { Card, Row } from 'antd';
import { localizedText } from '../../../localizedText';
import '../../../../static/css/components/patient-exam-omdr-deepmd-band.scss';


const PatientExamOMDRSuspiciousGLC = () => {
    const { DEEPMD_SUSPICIOUS_FEATURES_GLC } = localizedText;
    const isGlaucoma = useCare1AppSelector(store => store.examData.is_glaucoma);
    const suspFeatures = useCare1AppSelector(store => store.examData.susp_features);

    return (
        <Card className={'component-input-card suspicious-glc'} bordered={false}>
            <Row>
                <div className="heading">{DEEPMD_SUSPICIOUS_FEATURES_GLC}</div>
            </Row>
            <div className='component-body' data-testid='suspicious-glc'>
                {
                    isGlaucoma &&
                    <>
                        {
                            suspFeatures.map((sf, idx) =>
                                <div key={idx}>&#x2022;  {sf}</div>
                            )
                        }
                    </>
                }
                {
                    !isGlaucoma && <div>N/A - not glaucoma patient</div>
                }
            </div>
        </Card>
    );

}

export default PatientExamOMDRSuspiciousGLC;