import { Fragment } from 'react';
import { Col, Row, Divider } from 'antd';
import PatientExamHeaderODSelect from './patient-exam-header-od-select';
import { useCare1AppSelector } from '../../../apps/care1-hooks';

type ComponentProps = {
    onClick?: () => void,
}

const PatientExamHeaderDoctorInfo = ({ onClick }: ComponentProps) => {

    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const gp = useCare1AppSelector(store => store.patientDetails.details.gp);
    const gp2 = useCare1AppSelector(store => store.patientDetails.details.gp2);
    const omdr = useCare1AppSelector(store => store.examData.omdr);
    const isSameRegion = useCare1AppSelector(store => store.examData.is_same_region);
    const odUsesRluPei = useCare1AppSelector(store => store.examData.od_uses_rlu_pei);
    const hasNoGp = useCare1AppSelector(store => store.patientDetails.details.has_no_gp);

    const omdrDisplay = omdr && omdr !== 'None' ? omdr : null;

    return (
        <Fragment>
            {/* Column Two Labels */}
            <Row className={'doctors-row'}>
                <Col span={6} className={'doctors-labels'}
                    onClick={onClick}
                >
                    {/* Referral Letter only row */}
                    <Row className="header-label">
                    { odUsesRluPei && userIsOD &&
                        <div className="exam-od-label">
                            <label className="patient-exam-header-label od">{`OD:`}</label>
                        </div>
                    }
                    </Row>
                    {/* Row 1 */}
                    <Row className="header-label exam-gp-label">
                        <label className="patient-exam-header-label">{`GP:`}</label>
                    </Row>
                    {/* Row 2 */}
                    <Row className="header-label">
                    { (userIsOD && userIsRE) &&
                        <div className="exam-omd-label">
                            <label className="patient-exam-header-label omdr">{`OMDR:`}</label>
                        </div>
                    }
                    { ((userIsOD && !userIsRE) || userIsOMDR || userIsOMDC)  &&
                        <div className="exam-gp2-label">
                            <label className="patient-exam-header-label gp2">{`MD#2:`}</label>
                        </div>
                    }
                    { userIsOMDR && userIsRE &&
                        <div className="exam-in-my-region-label">
                            <label className="patient-exam-header-label in-my-region">{`Catchment:`}</label>
                        </div>
                    }
                    </Row>
                    {/* Row 3 */}
                    <Row className="header-label">
                    { !userIsOMDC && !userIsOMDR && !odUsesRluPei &&
                        <div className="exam-od-label-2">
                            <label className="patient-exam-header-label od">{`OD:`}</label>
                        </div>
                    }
                    { userIsOMDC &&
                        <div className="exam-omd-label">
                            <label className="patient-exam-header-label omdr">{`OMDR:`}</label>
                        </div>
                    }
                    </Row>
                </Col>

                {/* Column Two Data */}
                <Col span={16} className={'doctors-content'}>
                    {/* Referral Letter only row */}
                    <Row>
                        { odUsesRluPei && userIsOD &&
                            <div className="exam-od-main">
                                <PatientExamHeaderODSelect />
                            </div>
                        }
                    </Row>
                    {/* Row 1 */}
                    <Row className="exam-gp">
                        {/* EH 005 */}
                        <Col span={24}>
                            <label className="patient-exam-header-content gp">
                                {hasNoGp && !gp && !gp2 ? 'No GP' : gp && `Dr. ${gp}`}
                            </label>
                        </Col>
                    </Row>
                    {/* Row 2 */}
                    <Row className="doctor-two">
                        {/* EH 006, EH 016 */}
                        { (userIsOD && userIsRE) &&
                            <label className="patient-exam-header-content omdr">
                                {omdrDisplay && `Dr. ${omdrDisplay}`}
                            </label>
                        }
                        { ((userIsOD && !userIsRE) || userIsOMDR || userIsOMDC ) &&
                            <label className="patient-exam-header-content gp2">
                                {gp2 && `Dr. ${gp2}`}
                            </label>
                        }
                        {/* EH 007, EH 017 */}
                        {!userIsOMDC && !userIsOMDR && !odUsesRluPei &&
                            <div className="exam-od">
                                <PatientExamHeaderODSelect />
                            </div>
                        }
                        {userIsOMDC &&
                            <div className={'omdr-content'}>
                                <label className="patient-exam-header-content omdr">
                                    {omdrDisplay && `Dr. ${omdrDisplay}`}
                                </label>
                            </div>
                        }
                         { userIsOMDR && userIsRE &&
                            <div data-testid='in-my-region' className='in-my-region-content'>
                                <label className="patient-exam-header-content in-my-region">
                                    {isSameRegion ? 'Your referral region' : 'Not in your referral region'}
                                </label>
                            </div>
                        }
                    </Row>
                </Col>
                <Col span={2}>
                    <Divider className={'divider-two'} type="vertical" />
                </Col>
            </Row>
        </Fragment>

    )
}

export default PatientExamHeaderDoctorInfo