import { ReactNode } from 'react';
import { Button, Col, Divider, Row } from 'antd';
import * as Constants from '../../constants';
import '../../../static/css/components/div-tag-input.scss';
import '../../../static/css/shared/date-picker.scss';

type ComponentProps = {
    disabled: boolean;
    values: (string | never[])[];
    onClick?: () => void;
    placeholder?: string | undefined;
    entries?: ReactNode;
    drawer?: boolean;
    onClose?: () => void;
    emptyValue?: string;
    required?: boolean;
    isBaselineString?: boolean;
  }

const DivTagInputControls = ({ onClose }: { onClose?: () => void }) => {
    return (
        <Row className={'close-button'}>
            <Col>
                <Button
                    className="close"
                    size="small"
                    onClick={onClose}
                >
                    {Constants.CLOSE_BUTTON_TEXT}
                </Button>
            </Col>
        </Row>
    );
}

const DivTagInput = ({ disabled, onClick, placeholder, values, entries, drawer, onClose, emptyValue,
    required, isBaselineString }: ComponentProps) => {

    const disabledClass = disabled ? ' disabled' : '';
    const className = required ? `div-tag-input required${disabledClass}` : `div-tag-input${disabledClass}`;

    return (
        <>
            <div
                role="textbox"
                className={className}
                onClick={onClick}
            >
                {!isBaselineString &&
                    <div className="container">
                        {(() => {
                            if (values && values.length > 0) {
                                return values.map((value, index) => {

                                    let component = <div key={index} className="value"></div>;

                                    const isEmptyValues = (value === emptyValue);
                                    if (isEmptyValues) {
                                        component = <div key={index} className="value empty-value">{'\xa0'}</div>
                                    }
                                    if (!isEmptyValues) {
                                        component = <div key={index} className="value">{value}</div>
                                    }

                                    return component;
                                });
                            }

                            return <span className="placeholder">{placeholder}</span>;
                        })()}
                    </div>
                }
                {isBaselineString &&
                    <div className="container baseline">
                        {(() => {
                            if (values && values.length > 0) {
                                return values.map((value, index) => {
                                    let component = null;
                                    const isEmptyValues = (typeof value === 'string' && value === emptyValue) || (value && value.length === 0);

                                    if (isEmptyValues) {
                                        component = <span key={index} className={`value baseline-string`}>{'\xa0'}</span>
                                    } else {
                                        if (index === 0) {
                                            component = <span key={index} className={'value baseline-string'}>{`Baseline IOP = ${value}`}{index === values.length - 2 ? '.' : ''}</span>
                                        } else {
                                            component = <span key={index} className={'value baseline-string'}>; {value}{index === values.length - 2 ? '.' : ''}</span>
                                        }
                                    }

                                    return component;
                                });
                            }

                            return <span className="placeholder">{placeholder}</span>;
                        })()}
                    </div>
                }
            </div>
            {
                !disabled && drawer && (
                    <div className="div-tag-entries">
                        <div className="scroll" />
                        {entries}
                        <div>
                            <Divider />
                            <DivTagInputControls onClose={onClose} />
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default DivTagInput;