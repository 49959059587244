export const getBackendMediaUrl = () => {
    return process.env.REACT_APP_DEBUG === 'true' ? process.env.REACT_APP_BACKENDURL + '/media/' :
        process.env.REACT_APP_PUBLIC_MEDIA_URL;
}

export const getFilepathFromBackendMediaUrl = (url: string) => {
    const devHost = process.env.REACT_APP_BACKENDURL + '/media/';
    const s3Host = process.env.REACT_APP_PUBLIC_MEDIA_URL;

    const s1 = url.replace(devHost, "");
    if (s3Host) {
        return s1.replace(s3Host, "");
    }
    return s1;
}
