import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { localizedText } from "../localizedText";

const ConfirmExitModal = (callback: (b: boolean) => void) => {
    const { CONFIRMATION_CANCEL_TEXT, EXIT_CONFIRMATION_TITLE, 
        CONFIRMATION_OK_TEXT, EXIT_CONTENT_TEXT} = localizedText;
    
    Modal.confirm({
        className: 'exam-exit-confirmation-modal',
        title: EXIT_CONFIRMATION_TITLE,
        content: EXIT_CONTENT_TEXT,
        icon: <InfoCircleOutlined />,
        okText: CONFIRMATION_OK_TEXT,
        cancelText: CONFIRMATION_CANCEL_TEXT,
        cancelButtonProps: {
            className: 'confirm-exit',
        },
        onOk: () => {
            // used a callback function here so that removeExamFromTab and 
            // switchCurrentPatientExamRoom can use this modal
            callback(true);
        },
    });
}

export default ConfirmExitModal;