import { Modal, Button, Form } from 'antd';
import '../../../static/css/components/od-group-practice-modal.scss';

// Action imports
import { SaveOutlined } from '@ant-design/icons';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { ITechnicianDetail, useAddTechnicianMutation } from '../../services/od-group-practice-api';
import { toggleTechnicianAddModal } from '../../reducers/doctor-slice';
import TechnicianForm from './technician-form';

const TechnicianAddModal = () => {

    const isVisible = useCare1AppSelector(store => store.doctorSlice.showTechnicianAddModal);

    const [addTechnician] = useAddTechnicianMutation();

    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const handleModalClose = () => {
        dispatch(toggleTechnicianAddModal());
    };

    const closeButton = (
        <Button
            key='close-button'
            onClick={handleModalClose}
            className='close-button'
        >
            Close
        </Button>
    );
    const saveButton = (
        <Button
            key='save-button'
            onClick={() => form.submit()}
            className='save-button'
            icon={<SaveOutlined />}
        >
            Save
        </Button>
    );

    const onFinish = async (values: ITechnicianDetail) => {
        
        try {
            const result = await addTechnician(values).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'Technician successfully added.',
                    onOk: () => {
                        dispatch(toggleTechnicianAddModal());
                    }
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    return (
        <Modal
            className='od-group-practice-modal clinic-location-modal'
            open={isVisible}
            closable={false}
            title={`Add New Technician`}
            footer={[closeButton, saveButton]}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <TechnicianForm onFinish={onFinish} form={form} />
        </Modal>
    );
}

export default TechnicianAddModal;
