import { Select } from 'antd'
import { OMDC_STATUS_TYPES } from '../../../constants';

import { setExamDataValue } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';

const { Option } = Select;

const PatientExamHeaderOMDCStatus = () => {
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const isADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const omdcStatus = useCare1AppSelector(store => store.examData['omdc_status']);
    const status = OMDC_STATUS_TYPES && OMDC_STATUS_TYPES.find(status => status.key === omdcStatus);
    const displayOnly = status ? status.value : '';

    const dispatch = useCare1AppDispatch();

    const handleSelectOMDCStatus = (key: string) => {
        if (isADMIN) {
            dispatch(setExamDataValue('omdc_status', key));
        }
    }

    const handleClearOMDCStatus = () => {
        if (isADMIN) {
            dispatch(setExamDataValue('omdc_status', ""));
        }
    }

    const filteredChoices = OMDC_STATUS_TYPES.filter((v) => {
        if (v.key !== 'all_statuses') {
            return true;
        }
        return false;
    });

    return (
        <>
            { (userIsOMDC && !isADMIN) &&
                <div className="patient-exam-header-content omdc-status">
                   <label className="patient-exam-omdc-status-select" data-testid='omdc-status-select'>
                       {displayOnly.toUpperCase()}
                   </label>
               </div>
            }
            { (!userIsOMDC && !isADMIN) &&
                <Select
                    data-testid='omdc-status-select'
                    size="small"
                    className="patient-exam-omdc-status-select"
                    disabled
                    value={omdcStatus}
                >
                    {
                        OMDC_STATUS_TYPES && OMDC_STATUS_TYPES.map(({key, value}) => (
                            <Option
                                data-testid={`omdc-status-option-${key}`}
                                key={key}
                                value={key}
                            >
                                {value.toUpperCase()}
                            </Option>
                        ))
                    }
                </Select>
            }
            {
                isADMIN &&
                <Select
                    className="dropdown-filter"
                    popupClassName="patient-list-dropdown"
                    value={omdcStatus || null}
                    onSelect={handleSelectOMDCStatus}
                    onClear={handleClearOMDCStatus}
                    placeholder='Select OMDC Status'
                    allowClear={true}
                >
                    {filteredChoices && filteredChoices.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            {option.value}
                        </Select.Option>
                    ))}
                </Select>
            }
        </>
    )
}

export default PatientExamHeaderOMDCStatus;