import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { InputNumber, Form } from 'antd';

type ComponentProps = {
    side: 'od' | 'os';
    isLow: boolean;
};

const PatientExamDtcIopRange = ({side, isLow}: ComponentProps) => {

    const dtcValues = useCare1AppSelector(store => store.examData.dtc_values);
    const odIOP = useCare1AppSelector(store => store.examData.od_iop);
    const osIOP = useCare1AppSelector(store => store.examData.os_iop);

    // Return either the lowest or highest IOP value for the designated side
    const getDtcIOPValue = () => {

        if(dtcValues && dtcValues.length > 0){
            // Get the IOP 2-4 values for the designated side
            const sideIopValue = side === 'od' ? odIOP : osIOP;
            // Add the IOP1 value for the designated side to the array
            const completeDtcValues = [...dtcValues, {[side]: sideIopValue}];
            // extract out iop values so that side values become an array of numbers
            const sideValues = completeDtcValues.reduce((accu, dtcValue) => {
                if (dtcValue[side] || dtcValue[side] === 0) {
                    return [...accu, (dtcValue[side] as number)];
                } else {
                    return [...accu];
                }
            }, ([] as number[]))
            
            // Return either the low or high IOP value
            return isLow ? Math.min(...sideValues) : Math.max(...sideValues);
        } else {
            return null;
        }
    }

    const [ form ] = Form.useForm();

    return (
        <Form form={form}>
            <Form.Item className='patient-exam-dtc-iop-range component-input'>
                {/* IOP-RANGE 001, 002, 003, 004 */}
                <InputNumber
                    data-testid={`dtc-iop-range-${side}-${isLow ? 'low' : 'high'}`}
                    size={'small'}
                    disabled={true}
                    value={getDtcIOPValue()}
                />
            </Form.Item>
        </Form>
    );
}

export default PatientExamDtcIopRange;