import { useEffect, useState } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';
import { Button, Radio, Row } from 'antd';
import { SendOutlined, CheckCircleOutlined, CloudSyncOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import PatientListTableIgp from '../components/igp/patient-list-table-igp';
import { compareStrings } from '../helpers/sorting';
import { getReferredPatientListRequest, getReferralRequiredPatientListRequest, submitPatientsReferralRequest,
    IIgpListItem,
    } from '../reducers/igp-patient-list-slice';
import { PATIENTS_REQUIRING_REFERRALS_TEXT, PREVIOUSLY_REFERRED_PATIENTS_TEXT,
    REFER_BUTTON_TEXT,
    } from '../constants';
import '../../static/css/pages/igp-patient-list-page.scss';

const columns : ColumnsType<IIgpListItem> = [{
    className: 'province-column',
    title: 'Prov',
    dataIndex: 'province',
    key: 'province',
    sorter: (a, b) => compareStrings(a.province, b.province),
},{
    className: 'phn-column',
    title: 'Health Card',
    dataIndex: 'phn',
    key: 'phn',
    sorter: (a, b) => compareStrings(a.phn, b.phn),
}, {
    className: 'last-name-column',
    title: 'Last Name',
    dataIndex: 'last_name',
    key: 'last_name',
    sorter: (a, b) => compareStrings(a.last_name, b.last_name),
}, {
    className: 'first-name-column',
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'first_name',
    sorter: (a, b) => compareStrings(a.first_name, b.first_name),
},{
    className: 'dob-column',
    title: 'DOB',
    dataIndex: 'dob',
    key: 'dob',
    sorter: (a, b) => compareStrings(a.dob, b.dob),
}, {
    className: 'gender-column',
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender',
    sorter: (a, b) => compareStrings(a.gender, b.gender),
}, {
    className: 'complaint-column',
    title: 'Referral Reason',
    dataIndex: 'complaint',
    key: 'complaint',
    sorter: (a, b) => compareStrings(a.complaint, b.complaint),
}, {
    className: 'patient-history-column',
    title: 'Patient History',
    dataIndex: 'patient_history',
    key: 'patient_history',
    sorter: (a, b) => compareStrings(a.patient_history, b.patient_history),
}, {
    className: 'od-bcva-column',
    title: 'Vision OD',
    dataIndex: 'vision_od',
    key: 'vision_od',
    sorter: (a, b) => compareStrings(a.vision_od, b.vision_od),
}, {
    className: 'os-bcva-column',
    title: 'Vision OS',
    dataIndex: 'vision_os',
    key: 'vision_os',
    sorter: (a, b) => compareStrings(a.vision_os, b.vision_os),
}, ];


const IgpPatientListPage = () => {
    const requiringReferralList = useCare1AppSelector(store => store.igpPatientList.requiringReferralList);
    const previouslyReferredList = useCare1AppSelector(store => store.igpPatientList.previouslyReferredList);
    const patientsSelectedForReferral = useCare1AppSelector(store => store.igpPatientList.selectedPatients);

    const userId = useCare1AppSelector(store => store.user.userID);

    const dispatch = useCare1AppDispatch();

    const [referralSelection, setReferralSelection] = useState('current');

    const referralDateColumn = {
        className: 'date-referred-column',
        title: 'E-Consult Ref',
        dataIndex: 'date_referred',
        key: 'date_referred',
        sorter: (a: {date_referred: string}, b: {date_referred: string}) => compareStrings(a.date_referred, b.date_referred),
    }

    const referredColumns = [...columns, referralDateColumn];

    useEffect(() => {
        userId && dispatch(getReferredPatientListRequest(userId));
        userId && dispatch(getReferralRequiredPatientListRequest(userId));
    }, [dispatch, userId])


    return (
        <div className='igp-patient-list-page'>
            <Row className='buttons-row referral-status-row'>
                <Radio.Group
                    size='large'
                    defaultValue={'current'}
                    buttonStyle="solid"
                    onChange={e => setReferralSelection(e.target.value)}
                >
                    <Radio.Button className='current' value='current'>
                        <CheckCircleOutlined /> {PATIENTS_REQUIRING_REFERRALS_TEXT}
                    </Radio.Button>
                    <Radio.Button className='previous' value='previous'>
                        <CloudSyncOutlined /> {PREVIOUSLY_REFERRED_PATIENTS_TEXT}
                    </Radio.Button>
                </Radio.Group>
            </Row>
            <Row className='buttons-row' align='bottom'>
                <Button
                    className='refer-button'
                    type='primary'
                    icon={<SendOutlined />}
                    onClick={() => dispatch(submitPatientsReferralRequest())}
                    disabled={referralSelection === 'previous' || patientsSelectedForReferral.length === 0}
                >
                    {REFER_BUTTON_TEXT}
                </Button>
            </Row>
            <Row>
                {referralSelection === 'current' ?
                    <PatientListTableIgp
                        className='current-table'
                        dataSource={requiringReferralList}
                        columns={columns}
                        referralSelection={referralSelection}
                    />
                    :
                    <PatientListTableIgp
                        className='previous-table'
                        dataSource={previouslyReferredList}
                        columns={referredColumns}
                        referralSelection={referralSelection}
                    />
                }
            </Row>
        </div>
    )
}

export default IgpPatientListPage;