import { useState, useEffect, useRef } from 'react';
import { useCare1AppDispatch } from '../../apps/care1-hooks';
import { Button, Col, Row, Modal, Tooltip } from 'antd';
import '../../../static/css/components/macula-abnormality.scss';
import { Stage, Layer } from 'react-konva';
import MaculaAbnormalityOpticRect from './macula-abnormality-optic-rect';
import MaculaAbnormalityMaculaCircle from './macula-abnormality-macula-circle';
import { IMaculaAbnormalityUIStates, IMaculaAbnormalityUI, fetchMaculaAbnormality, submitMaculaAbnormalityRequest, closeModal } from '../../reducers/macula-abnormality-slice';
import { fetchMaculaHistoricalAnalysis } from '../../reducers/macula-historical-analysis-slice';

const initRightOpticRect: IMaculaAbnormalityUI = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    stroke: 'green',
    id: 'right-optic-rect',
};

const initRightMaculaCircle: IMaculaAbnormalityUI = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    stroke: 'red',
    id: 'right-macula-circle',
};

const initLeftOpticRect: IMaculaAbnormalityUI = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    stroke: 'green',
    id: 'left-optic-rect',
};

const initLeftMaculaCircle: IMaculaAbnormalityUI = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    stroke: 'red',
    id: 'left-macula-circle',
};

interface IMoveHistory {
    x: number;
    y: number;
    width: number;
    height: number;
    stroke: string;
    id: string;
}

const MaculaAbnormalityForm = ({ examId, rightFundus, leftFundus, situationalAwarenessUiStates }: { examId: string, rightFundus: string, leftFundus: string, situationalAwarenessUiStates: IMaculaAbnormalityUIStates }) => {

    const dispatch = useCare1AppDispatch();

    const imageRightFundusRef = useRef(null);
    const imageLeftFundusRef = useRef(null);

    const [rightFundusWidth, setRightFundusWidth] = useState(0);
    const [leftFundusWidth, setLeftFundusWidth] = useState(0);

    const [rightOpticRect, setRightOpticRect] = useState<IMoveHistory>(initRightOpticRect);
    const [leftOpticRect, setLeftOpticRect] = useState<IMoveHistory>(initLeftOpticRect);

    const [rightMaculaCircle, setRightMaculaCircle] = useState<IMoveHistory>(initRightMaculaCircle);
    const [leftMaculaCircle, setLeftMaculaCircle] = useState<IMoveHistory>(initLeftMaculaCircle);

    const [hasRightOpticRect, setHasRightOpticRect] = useState(false);
    const [hasLeftOpticRect, setHasLeftOpticRect] = useState(false);

    const [hasRightMaculaCircle, setHasRightMaculaCircle] = useState(false);
    const [hasLeftMaculaCircle, setHasLeftMaculaCircle] = useState(false);

    const [selectedRightId, selectRightShape] = useState('');
    const [selectedLeftId, selectLeftShape] = useState('');

    const [moveHistory, setMoveHistory] = useState<IMoveHistory[]>([]);

    const [opButtonMode, setOpButtonMode] = useState('select');
    const [drawingOnRightStage, setDrawingOnRightStage] = useState(false);
    const [drawingOnLeftStage, setDrawingOnLeftStage] = useState(false);

    const constrainDiagonal = (width: number, height: number, side: string) => {
        let diagonal = Math.sqrt(width * width + height * height);

        if (imageRightFundusRef.current && imageLeftFundusRef.current) {
            const { clientWidth: rightClientWidth, clientHeight: rightClientHeight } = imageRightFundusRef.current;
            const { clientWidth: leftClientWidth, clientHeight: leftClientHeight } = imageLeftFundusRef.current;
            
            if (side === 'left') {
                const containerDiagonal = Math.sqrt(leftClientWidth * leftClientWidth + leftClientHeight * leftClientHeight);
                if (diagonal > containerDiagonal * 0.3) {
                    diagonal = containerDiagonal * 0.3;
                }
            }
            else {
                const containerDiagonal = Math.sqrt(rightClientWidth * rightClientWidth + rightClientHeight * rightClientHeight);
                if (diagonal > containerDiagonal * 0.3) {
                    diagonal = containerDiagonal * 0.3;
                }
            }
        }
        return diagonal;
    }

    const rightStageOnMouseDown = (e: { target: { getStage: () => any; }; evt: { offsetX: any; offsetY: any; }; }) => {
        if (opButtonMode === 'select') {
            // deselect when clicked on empty area
            const clickedOnEmpty = e.target === e.target.getStage();
            if (clickedOnEmpty) {
                selectRightShape('');
            }
        }
        else if (opButtonMode === 'setOpticNerve') {
            if (hasRightOpticRect) {
                return;
            }
            else {
                const { offsetX, offsetY } = e.evt;
                const rect = {
                    ...initRightOpticRect,
                    x: offsetX,
                    y: offsetY,
                }
                setRightOpticRect(rect);
                setHasRightOpticRect(true);
                setDrawingOnRightStage(true);
            }
        }
        else if (opButtonMode === 'setFovea') {
            if (hasRightMaculaCircle) {
                return;
            }
            else {
                const { offsetX, offsetY } = e.evt;
                const rect = {
                    ...initRightMaculaCircle,
                    x: offsetX,
                    y: offsetY,
                }
                setRightMaculaCircle(rect);
                setHasRightMaculaCircle(true);
                setDrawingOnRightStage(true);
            }
        }
    };

    const rightStageOnMouseMove = (e: { evt: { offsetX: any; offsetY: any; }; }) => {
        if (opButtonMode === 'setOpticNerve') {
            if (!hasRightOpticRect) {
                return;
            }
            else {
                if (drawingOnRightStage) {
                    const { offsetX, offsetY } = e.evt;
                    const width = offsetX - rightOpticRect.x;
                    const height = offsetY - rightOpticRect.y;
                    const rect = {
                        ...rightOpticRect,
                        width: width,
                        height: height,
                    }
                    setRightOpticRect(rect);
                    setHasRightOpticRect(true);
                }
            }
        }
        else if (opButtonMode === 'setFovea') {
            if (!hasRightMaculaCircle) {
                return;
            }
            else {
                if (drawingOnRightStage) {
                    const { offsetX, offsetY } = e.evt;
                    const width = offsetX - rightMaculaCircle.x;
                    const height = offsetY - rightMaculaCircle.y;
                    const diagonal = constrainDiagonal(width, height, 'right');
                        
                    const rect = {
                        ...rightMaculaCircle,
                        width: diagonal / Math.sqrt(2),
                        height: diagonal / Math.sqrt(2),
                    }
                    setRightMaculaCircle(rect);
                    setHasRightMaculaCircle(true);
                }
            }
        }
    }

    const rightStageOnMouseUp = (e: any) => {
        if (opButtonMode === 'setOpticNerve') {
            setOpButtonMode('select');

            if (drawingOnRightStage) {
                setDrawingOnRightStage(false);
            }
        }
        else if (opButtonMode === 'setFovea') {
            setOpButtonMode('select');

            if (drawingOnRightStage) {
                setDrawingOnRightStage(false);
            }
        }
    }


    const leftStageOnMouseDown = (e: { target: { getStage: () => any; }; evt: { offsetX: any; offsetY: any; }; }) => {
        if (opButtonMode === 'select') {
            // deselect when clicked on empty area
            const clickedOnEmpty = e.target === e.target.getStage();
            if (clickedOnEmpty) {
                selectLeftShape('');
            }
        }
        else if (opButtonMode === 'setOpticNerve') {
            if (hasLeftOpticRect) {
                return;
            }
            else {
                const { offsetX, offsetY } = e.evt;
                const rect = {
                    ...initLeftOpticRect,
                    x: offsetX,
                    y: offsetY,
                }
                setLeftOpticRect(rect);
                setHasLeftOpticRect(true);
                setDrawingOnLeftStage(true);
            }
        }
        else if (opButtonMode === 'setFovea') {
            if (hasLeftMaculaCircle) {
                return;
            }
            else {
                const { offsetX, offsetY } = e.evt;
                const rect = {
                    ...initLeftMaculaCircle,
                    x: offsetX,
                    y: offsetY,
                }
                setLeftMaculaCircle(rect);
                setHasLeftMaculaCircle(true);
                setDrawingOnLeftStage(true);
            }
        }
    };

    const leftStageOnMouseMove = (e: { evt: { offsetX: any; offsetY: any; }; }) => {
        if (opButtonMode === 'setOpticNerve') {
            if (!hasLeftOpticRect) {
                return;
            }
            else {
                if (drawingOnLeftStage) {
                    const { offsetX, offsetY } = e.evt;
                    const width = offsetX - leftOpticRect.x;
                    const height = offsetY - leftOpticRect.y;
                    const rect = {
                        ...leftOpticRect,
                        width: width,
                        height: height,
                    }
                    setLeftOpticRect(rect);
                    setHasLeftOpticRect(true);
                }
            }
        }
        else if (opButtonMode === 'setFovea') {
            if (!hasLeftMaculaCircle) {
                return;
            }
            else {
                if (drawingOnLeftStage) {
                    const { offsetX, offsetY } = e.evt;
                    const width = offsetX - leftMaculaCircle.x;
                    const height = offsetY - leftMaculaCircle.y;
                    const diagonal = constrainDiagonal(width, height, 'left');
                        
                    const rect = {
                        ...leftMaculaCircle,
                        width: diagonal / Math.sqrt(2),
                        height: diagonal / Math.sqrt(2),
                    }
                    setLeftMaculaCircle(rect);
                    setHasLeftMaculaCircle(true);
                }
            }
        }
    }

    const leftStageOnMouseUp = (e: any) => {
        if (opButtonMode === 'setOpticNerve') {
            setOpButtonMode('select');

            if (drawingOnLeftStage) {
                setDrawingOnLeftStage(false);
            }
        }
        else if (opButtonMode === 'setFovea') {
            setOpButtonMode('select');

            if (drawingOnLeftStage) {
                setDrawingOnLeftStage(false);
            }
        }
    }

    const handleRightFundusLoad = (event: any) => {
        if (imageRightFundusRef.current) {
            const { clientWidth } = imageRightFundusRef.current;
            setRightFundusWidth(clientWidth);
        }
    }

    const handleLeftFundusLoad = (event: any) => {
        if (imageLeftFundusRef.current) {
            const { clientWidth } = imageLeftFundusRef.current;
            setLeftFundusWidth(clientWidth);
        }
    }

    const setOpticNerve = () => {
        setOpButtonMode('setOpticNerve');
    }

    const setFovea = () => {
        setOpButtonMode('setFovea');
    }

    const undo = () => {

        if (moveHistory.length === 0) {
            return;
        }

        const newMoveHistory = [...moveHistory];
        const move = newMoveHistory.pop() as IMoveHistory;
        if (move.id === 'right-optic-rect') {
            setRightOpticRect(move);
        }
        if (move.id === 'right-macula-circle') {
            setRightMaculaCircle(move);
        }
        if (move.id === 'left-optic-rect') {
            setLeftOpticRect(move);
        }
        if (move.id === 'left-macula-circle') {
            setLeftMaculaCircle(move);
        }
        setMoveHistory(newMoveHistory);
    }

    const reset = () => {
        setRightOpticRect(initRightOpticRect);
        setLeftOpticRect(initLeftOpticRect);

        setRightMaculaCircle(initRightMaculaCircle);
        setLeftMaculaCircle(initLeftMaculaCircle);

        setHasRightOpticRect(false);
        setHasLeftOpticRect(false);
        setHasRightMaculaCircle(false);
        setHasLeftMaculaCircle(false);

        setMoveHistory([]);
    }

    const cancel = () => {
        dispatch(closeModal());
    }

    const save = async () => {
        if (examId !== null) {
            if (imageRightFundusRef.current && imageLeftFundusRef.current) {
                const { clientWidth: rightClientWidth, clientHeight: rightClientHeight } = imageRightFundusRef.current;
                const { clientWidth: leftClientWidth, clientHeight: leftClientHeight } = imageLeftFundusRef.current;

                const rightOpticRectCenter = [rightOpticRect.x + rightOpticRect.width / 2, rightOpticRect.y + rightOpticRect.height / 2];
                const leftOpticRectCenter = [leftOpticRect.x + leftOpticRect.width / 2, leftOpticRect.y + leftOpticRect.height / 2];

                const f = {
                    exam_id: examId,
                    right_optic_nerve_x: hasRightOpticRect ? (rightOpticRectCenter[0]) / rightClientWidth : undefined,
                    right_optic_nerve_y: hasRightOpticRect ? (rightOpticRectCenter[1]) / rightClientHeight : undefined,
                    right_optic_nerve_width: hasRightOpticRect ? rightOpticRect.width / rightClientWidth : undefined,
                    right_optic_nerve_height: hasRightOpticRect ? rightOpticRect.height / rightClientHeight : undefined,
                    right_macula_x: hasRightMaculaCircle ? (rightMaculaCircle.x) / rightClientWidth : undefined,
                    right_macula_y: hasRightMaculaCircle ? (rightMaculaCircle.y) / rightClientHeight : undefined,
                    right_macula_width: hasRightOpticRect ? rightMaculaCircle.width / rightClientWidth : undefined,
                    right_macula_height: hasRightOpticRect ? rightMaculaCircle.height / rightClientHeight : undefined,
                    left_optic_nerve_x: hasLeftOpticRect ? (leftOpticRectCenter[0]) / leftClientWidth : undefined,
                    left_optic_nerve_y: hasLeftOpticRect ? (leftOpticRectCenter[1]) / leftClientHeight : undefined,
                    left_optic_nerve_width: hasLeftOpticRect ? leftOpticRect.width / leftClientWidth : undefined,
                    left_optic_nerve_height: hasLeftOpticRect ? leftOpticRect.height / leftClientHeight : undefined,
                    left_macula_x: hasLeftMaculaCircle ? (leftMaculaCircle.x) / leftClientWidth : undefined,
                    left_macula_y: hasLeftMaculaCircle ? (leftMaculaCircle.y) / leftClientHeight : undefined,
                    left_macula_width: hasLeftOpticRect ? leftMaculaCircle.width / leftClientWidth : undefined,
                    left_macula_height: hasLeftOpticRect ? leftMaculaCircle.height / leftClientHeight : undefined,
                };


                try {
                    const originalPromiseResult = await dispatch(submitMaculaAbnormalityRequest(f)).unwrap()
                    if (originalPromiseResult.success) {
                        // handle result here
                        dispatch(fetchMaculaAbnormality());
                        dispatch(fetchMaculaHistoricalAnalysis());
                    } else {
                        throw new Error(originalPromiseResult?.error);
                    }
                } catch (error) {
                    const message = (error instanceof Error) ? error?.message : error;
                    Modal.error({
                        className: 'info-modal',
                        content: message as string,
                        title: 'Error',
                    });
                }

                dispatch(closeModal());
            }
        }
    }

    let rightClientWidth = 0;
    let rightClientHeight = 0;
    if (imageRightFundusRef.current) {
        const { clientWidth, clientHeight } = imageRightFundusRef.current;
        rightClientWidth = clientWidth;
        rightClientHeight = clientHeight;
        if (clientWidth !== 0 && rightFundusWidth !== clientWidth) {
            setRightFundusWidth(clientWidth);
        }
    }

    let leftClientWidth = 0;
    let leftClientHeight = 0;
    if (imageLeftFundusRef.current) {
        const { clientWidth, clientHeight } = imageLeftFundusRef.current;
        leftClientWidth = clientWidth;
        leftClientHeight = clientHeight;
        if (clientWidth !== 0 && leftFundusWidth !== clientWidth) {
            setLeftFundusWidth(clientWidth);
        }
    }

    useEffect(() => {
        reset();

        if (situationalAwarenessUiStates) {
            if (imageRightFundusRef.current && imageLeftFundusRef.current) {

                if (rightClientWidth && leftClientWidth) {

                    if (situationalAwarenessUiStates.right_optic_nerve_x === undefined ||
                        situationalAwarenessUiStates.right_optic_nerve_y === undefined ||
                        situationalAwarenessUiStates.right_optic_nerve_width === undefined ||
                        situationalAwarenessUiStates.right_optic_nerve_height === undefined) {
                        setHasRightOpticRect(false);
                    }
                    else if (situationalAwarenessUiStates.right_optic_nerve_x === null ||
                        situationalAwarenessUiStates.right_optic_nerve_y === null ||
                        situationalAwarenessUiStates.right_optic_nerve_width === null ||
                        situationalAwarenessUiStates.right_optic_nerve_height === null) {
                        setHasRightOpticRect(false);
                    }
                    else {
                        setHasRightOpticRect(true);
                        const width = situationalAwarenessUiStates.right_optic_nerve_width * rightClientWidth;
                        const height = situationalAwarenessUiStates.right_optic_nerve_height * rightClientHeight;
                        const rect = {
                            ...initRightOpticRect,
                            x: situationalAwarenessUiStates.right_optic_nerve_x * rightClientWidth - width / 2.0,
                            y: situationalAwarenessUiStates.right_optic_nerve_y * rightClientHeight - height / 2.0,
                            width: width,
                            height: height,
                        };
                        setRightOpticRect(rect);
                    }

                    if (situationalAwarenessUiStates.right_macula_x === undefined ||
                        situationalAwarenessUiStates.right_macula_y === undefined ||
                        situationalAwarenessUiStates.right_macula_width === undefined ||
                        situationalAwarenessUiStates.right_macula_height === undefined) {
                        setHasRightMaculaCircle(false);
                    }
                    else if (situationalAwarenessUiStates.right_macula_x === null ||
                        situationalAwarenessUiStates.right_macula_y === null ||
                        situationalAwarenessUiStates.right_macula_width === null ||
                        situationalAwarenessUiStates.right_macula_height === null) {
                        setHasRightMaculaCircle(false);
                    }
                    else {
                        setHasRightMaculaCircle(true);
                        let width = situationalAwarenessUiStates.right_macula_width * rightClientWidth;
                        let height = situationalAwarenessUiStates.right_macula_height * rightClientHeight;

                        const diagonal = constrainDiagonal(width, height, 'right');
                        width = diagonal / Math.sqrt(2);
                        height = diagonal / Math.sqrt(2);

                        const rect = {
                            ...initRightMaculaCircle,
                            x: situationalAwarenessUiStates.right_macula_x * rightClientWidth,
                            y: situationalAwarenessUiStates.right_macula_y * rightClientHeight,
                            width: width,
                            height: height,
                        };
                        setRightMaculaCircle(rect);
                    }

                    if (situationalAwarenessUiStates.left_optic_nerve_x === undefined ||
                        situationalAwarenessUiStates.left_optic_nerve_y === undefined ||
                        situationalAwarenessUiStates.left_optic_nerve_width === undefined ||
                        situationalAwarenessUiStates.left_optic_nerve_height === undefined) {
                        setHasLeftOpticRect(false);
                    }
                    else if (situationalAwarenessUiStates.left_optic_nerve_x === null ||
                        situationalAwarenessUiStates.left_optic_nerve_y === null ||
                        situationalAwarenessUiStates.left_optic_nerve_width === null ||
                        situationalAwarenessUiStates.left_optic_nerve_height === null) {
                        setHasLeftOpticRect(false);
                    }
                    else {
                        setHasLeftOpticRect(true);
                        const width = situationalAwarenessUiStates.left_optic_nerve_width * leftClientWidth;
                        const height = situationalAwarenessUiStates.left_optic_nerve_height * leftClientHeight;
                        const rect = {
                            ...initLeftOpticRect,
                            x: situationalAwarenessUiStates.left_optic_nerve_x * leftClientWidth - width / 2.0,
                            y: situationalAwarenessUiStates.left_optic_nerve_y * leftClientHeight - height / 2.0,
                            width: width,
                            height: height,
                        };
                        setLeftOpticRect(rect);
                    }

                    if (situationalAwarenessUiStates.left_macula_x === undefined ||
                        situationalAwarenessUiStates.left_macula_y === undefined ||
                        situationalAwarenessUiStates.left_macula_width === undefined ||
                        situationalAwarenessUiStates.left_macula_height === undefined) {
                        setHasLeftMaculaCircle(false);
                    }
                    else if (situationalAwarenessUiStates.left_macula_x === null ||
                        situationalAwarenessUiStates.left_macula_y === null ||
                        situationalAwarenessUiStates.left_macula_width === null ||
                        situationalAwarenessUiStates.left_macula_height === null) {
                        setHasLeftMaculaCircle(false);
                    }
                    else {
                        setHasLeftMaculaCircle(true);
                        let width = situationalAwarenessUiStates.left_macula_width * leftClientWidth;
                        let height = situationalAwarenessUiStates.left_macula_height * leftClientHeight;

                        const diagonal = constrainDiagonal(width, height, 'left');
                        width = diagonal / Math.sqrt(2);
                        height = diagonal / Math.sqrt(2);

                        const rect = {
                            ...initLeftMaculaCircle,
                            x: situationalAwarenessUiStates.left_macula_x * leftClientWidth,
                            y: situationalAwarenessUiStates.left_macula_y * leftClientHeight,
                            width: width,
                            height: height,
                        };
                        setLeftMaculaCircle(rect);
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [situationalAwarenessUiStates, rightClientWidth, leftClientWidth]);

    return (
        <div className="macula-abnormality">
            <Row className='mb-2'>
                <Col>
                    <Tooltip title='Please outline around the edges of the optic nerve'>
                        <Button
                            className='op-btn'
                            onClick={setOpticNerve}
                            disabled={hasRightOpticRect && hasLeftOpticRect}
                        >Set optic nerve</Button>
                    </Tooltip>
                </Col>
                <Col>
                    <Tooltip title='Please outline the center of the fovea'>
                        <Button
                            className='op-btn'
                            onClick={setFovea}
                            disabled={hasRightMaculaCircle && hasLeftMaculaCircle}
                        >Set fovea</Button>
                    </Tooltip>

                </Col>
                <Col>
                    <Button
                        className='op-btn'
                        onClick={undo}
                        disabled={moveHistory.length === 0}
                    >Undo</Button>
                </Col>
                <Col>
                    <Button
                        className='op-btn'
                        onClick={reset}
                        disabled={!(hasRightOpticRect || hasLeftOpticRect || hasRightMaculaCircle || hasLeftMaculaCircle)}
                    >Reset</Button>
                </Col>
                <Col>
                    <Button
                        className='op-btn'
                        onClick={save}
                        disabled={!((hasRightOpticRect && hasRightMaculaCircle) || (hasLeftOpticRect && hasLeftMaculaCircle))}
                    >Save</Button>
                </Col>
                <Col>
                    <Button
                        className='cancel-button'
                        onClick={cancel}
                    >Cancal</Button>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    {rightFundus &&
                        <div className="photo-col">
                            <div className="photo-container">
                                <img
                                    ref={imageRightFundusRef}
                                    className="photo" src={rightFundus}
                                    onLoad={handleRightFundusLoad}
                                    alt='right fundus'
                                />
                                <Stage
                                    className="stage"
                                    width={rightFundusWidth}
                                    height={646}
                                    onMouseDown={rightStageOnMouseDown}
                                    onMouseUp={rightStageOnMouseUp}
                                    onMouseMove={rightStageOnMouseMove}
                                >
                                    <Layer>
                                        {hasRightOpticRect &&
                                            <MaculaAbnormalityOpticRect
                                                key={rightOpticRect.id}
                                                shapeProps={rightOpticRect}
                                                isSelected={rightOpticRect.id === selectedRightId}
                                                onSelect={() => {
                                                    selectRightShape(rightOpticRect.id);
                                                }}
                                                onChange={(newAttrs: IMaculaAbnormalityUI) => {
                                                    const newMoveHistory = [...moveHistory];
                                                    newMoveHistory.push({
                                                        x: rightOpticRect.x,
                                                        y: rightOpticRect.y,
                                                        width: rightOpticRect.width,
                                                        height: rightOpticRect.height,
                                                        stroke: rightOpticRect.stroke,
                                                        id: rightOpticRect.id
                                                    })
                                                    setMoveHistory(newMoveHistory);

                                                    const rect = {
                                                        ...newAttrs,
                                                        offsetX: 0,
                                                        offsetY: 0,
                                                    };
                                                    setRightOpticRect(rect);
                                                }}
                                            />
                                        }
                                        {hasRightMaculaCircle &&
                                            <MaculaAbnormalityMaculaCircle
                                                key={rightMaculaCircle.id}
                                                shapeProps={rightMaculaCircle}
                                                isSelected={rightMaculaCircle.id === selectedRightId}
                                                onSelect={() => {
                                                    selectRightShape(rightMaculaCircle.id);
                                                }}
                                                onChange={(newAttrs: IMaculaAbnormalityUI) => {
                                                    const newMoveHistory = [...moveHistory];
                                                    newMoveHistory.push({
                                                        x: rightMaculaCircle.x,
                                                        y: rightMaculaCircle.y,
                                                        width: rightMaculaCircle.width,
                                                        height: rightMaculaCircle.height,
                                                        stroke: rightMaculaCircle.stroke,
                                                        id: rightMaculaCircle.id
                                                    })
                                                    setMoveHistory(newMoveHistory);

                                                    const diagonal = constrainDiagonal(newAttrs.width, newAttrs.height, 'right');
                                                    newAttrs.width = diagonal / Math.sqrt(2);
                                                    newAttrs.height = diagonal / Math.sqrt(2);

                                                    const rect = {
                                                        ...newAttrs,
                                                        offsetX: 0,
                                                        offsetY: 0,
                                                    };
                                                    setRightMaculaCircle(rect);
                                                }}
                                            />
                                        }
                                    </Layer>
                                </Stage>
                            </div>
                        </div>
                    }
                </Col>
                <Col span={12}>
                    {leftFundus &&
                        <div className="photo-col">
                            <div className="photo-container">
                                <img
                                    ref={imageLeftFundusRef}
                                    className="photo"
                                    src={leftFundus}
                                    onLoad={handleLeftFundusLoad}
                                    alt='left fundus'
                                />
                                <Stage
                                    className="stage"
                                    width={leftFundusWidth}
                                    height={646}
                                    onMouseDown={leftStageOnMouseDown}
                                    onMouseUp={leftStageOnMouseUp}
                                    onMouseMove={leftStageOnMouseMove}
                                >
                                    <Layer>
                                        {hasLeftOpticRect &&
                                            <MaculaAbnormalityOpticRect
                                                key={leftOpticRect.id}
                                                shapeProps={leftOpticRect}
                                                isSelected={leftOpticRect.id === selectedLeftId}
                                                onSelect={() => {
                                                    selectLeftShape(leftOpticRect.id);
                                                }}
                                                onChange={(newAttrs: IMaculaAbnormalityUI) => {
                                                    const newMoveHistory = [...moveHistory];
                                                    newMoveHistory.push({
                                                        x: leftOpticRect.x,
                                                        y: leftOpticRect.y,
                                                        width: leftOpticRect.width,
                                                        height: leftOpticRect.height,
                                                        stroke: leftOpticRect.stroke,
                                                        id: leftOpticRect.id
                                                    })
                                                    setMoveHistory(newMoveHistory);

                                                    const rect = {
                                                        ...newAttrs,
                                                        offsetX: 0,
                                                        offsetY: 0,
                                                    };
                                                    setLeftOpticRect(rect);
                                                }}
                                            />
                                        }
                                        {hasLeftMaculaCircle &&
                                            <MaculaAbnormalityMaculaCircle
                                                key={leftMaculaCircle.id}
                                                shapeProps={leftMaculaCircle}
                                                isSelected={leftMaculaCircle.id === selectedLeftId}
                                                onSelect={() => {
                                                    selectLeftShape(leftMaculaCircle.id);
                                                }}
                                                onChange={(newAttrs: IMaculaAbnormalityUI) => {
                                                    const newMoveHistory = [...moveHistory];
                                                    newMoveHistory.push({
                                                        x: leftMaculaCircle.x,
                                                        y: leftMaculaCircle.y,
                                                        width: leftMaculaCircle.width,
                                                        height: leftMaculaCircle.height,
                                                        stroke: leftMaculaCircle.stroke,
                                                        id: leftMaculaCircle.id
                                                    })
                                                    setMoveHistory(newMoveHistory);

                                                    const diagonal = constrainDiagonal(newAttrs.width, newAttrs.height, 'left');
                                                    newAttrs.width = diagonal / Math.sqrt(2);
                                                    newAttrs.height = diagonal / Math.sqrt(2);

                                                    const rect = {
                                                        ...newAttrs,
                                                        offsetX: 0,
                                                        offsetY: 0,
                                                    };
                                                    setLeftMaculaCircle(rect);
                                                }}
                                            />
                                        }
                                    </Layer>
                                </Stage>
                            </div>
                        </div>
                    }
                </Col>
            </Row>
        </div >
    );
}

export default MaculaAbnormalityForm;
