import React, { useEffect } from 'react';
import { Modal, DatePicker, Form, Row, Col, Select, Button } from 'antd';
import InputNumberSuffix from './input-number-suffix';
import dayjs from 'dayjs';

import '../../../static/css/components/plaquenil-modal.scss';
import { PLAQUENIL_DATE, PLAQUENIL_DOSE } from '../../constants';
import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { InputNumberProps } from 'antd/lib/input-number';
import { allowPosNumberOnlyKeys } from '../../helpers/utilities';
import { closePlaquenilModal, setDiagnosisData, setPlaquenilDate, setPlaquenilDose } from '../../reducers/diagnosis-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { FieldData } from 'rc-field-form/lib/interface';

const PlaquenilModal = () => {

    const isPlaquenilModalOpen = useCare1AppSelector(store => store.diagnosis.isPlaquenilModalOpen);
    const diagnosisEntries = useCare1AppSelector(store => store.diagnosis.entries);
    const plaquenilDose = useCare1AppSelector(store => store.diagnosis[PLAQUENIL_DOSE]);
    const plaquenilDate = useCare1AppSelector(store => store.diagnosis[PLAQUENIL_DATE]);

    const dispatch = useCare1AppDispatch();
    const [ form ] = Form.useForm();

    useEffect(() => {
        let newValidationFieldValues = {
            [PLAQUENIL_DOSE]: plaquenilDose,
            [PLAQUENIL_DATE]: plaquenilDate ? dayjs(plaquenilDate): '',
        };
        form.setFieldsValue(newValidationFieldValues);
    }, [plaquenilDose,plaquenilDate,form])

    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case PLAQUENIL_DOSE:
                        dispatch(setPlaquenilDose(field.value));
                        break;
                    case PLAQUENIL_DATE:
                        const yearSelected = field.value ? field.value.format('YYYY') : '';
                        dispatch(setPlaquenilDate(yearSelected));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    const savePlaquenDiagnosis = () => {
        if(!diagnosisEntries.find(entry => entry.value === 'plaquenil')){
            const values = [...diagnosisEntries];
            // add dose and date to plaquenil, e.g. 'Plaquenil 2mg/day 2012'
            const doseText = plaquenilDose ? ` ${plaquenilDose}mg/day` : '';
            const dateText = plaquenilDate ? ` ${plaquenilDate}`: '';
            const plaquenilText = 'Plaquenil' + doseText + dateText;

            // display the doseText and doseDate by modifying the label text below
            values.push({
                value: 'plaquenil', label: plaquenilText, type: 'dosage_date', dosageText: 'mg/day'
            });

            dispatch(setDiagnosisData(values));
        }
        dispatch(closePlaquenilModal());
    }

    const handleCancel = () => {
        // if cancel button is clicked, the plaquenil will not be saved
        const values = [...diagnosisEntries];
        dispatch(setDiagnosisData(values));
        dispatch(closePlaquenilModal());
    }

    const handleReset = () => {
        // reset both plaquenil_dose and plaquenil_date
        dispatch(setPlaquenilDose(''));
        dispatch(setPlaquenilDate(''));
    }

    // All dose entries should be in the format of #, ## or ###
    const formatValue: InputNumberProps['formatter'] = (value, info) => {
        const regex = /^([1-9]?[0-9]?[0-9]?).*$/;
        const matches = String(value).match(regex);
        return matches && matches[1]
            ? String(Number(`${matches[1]}`))
            : '';
    }

    const shouldOpenModal = () => {
        if (plaquenilDose === '' && plaquenilDate === '') {
            return false;
        }
        return isPlaquenilModalOpen;
    }

    return (
        <Modal
            open={shouldOpenModal()}
            className='plaquenil-modal'
            footer={null}
            width={830}
        >
            <Row className='header-row' align='middle'>
                <div className='modal-title'>Plaquenil Diagnosis</div>
            </Row>
            <Form form={form} onFieldsChange={onFieldsChange}>
                <Row className='entry-row'>
                    <Form.Item className='plaquenil-selector'>
                        <Select defaultValue='plaquenil' disabled>
                            <Select.Option value='plaquenil'>
                                Plaquenil
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={PLAQUENIL_DOSE}
                        label="avg dose"
                        className='dose'
                        colon={false}
                        validateStatus={plaquenilDose ? '' : 'warning'}
                    >
                        <InputNumberSuffix
                            data-testid='plaquenil-dose'
                            suffix='mg/day'
                            onKeyPress={event => allowPosNumberOnlyKeys(event)}
                            formatter={formatValue}
                        />
                    </Form.Item>
                    <Form.Item
                        name={PLAQUENIL_DATE}
                        className='year-selector'
                        label="since"
                        colon={false}
                        validateStatus={plaquenilDate ? '' : 'warning'}
                    >
                        <DatePicker
                            picker="year"
                            allowClear={true}
                        />
                    </Form.Item>
                </Row>
                <Row className='buttons-row' justify='space-between'>
                    <Col span={5}>
                        <Button
                            className='reset-button'
                            onClick={handleReset}
                        >
                            {`Reset`}
                        </Button>
                    </Col>
                    <Col span={10}>
                        <Button
                            className='cancel-button'
                            onClick={handleCancel}
                            icon={<CloseCircleOutlined />}
                        >
                            {`Cancel`}
                        </Button>
                        <Button
                            className='save-button'
                            onClick={savePlaquenDiagnosis}
                            icon={<SaveOutlined />}
                        >
                            {`Save`}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default PlaquenilModal;