import { useState } from 'react';
import { Card, Row, Tag } from 'antd';
import classNames from 'classnames';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import '../../../static/css/components/admin-oct-rnfl-avg.scss';
import { localizedText } from '../../localizedText';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

const { CheckableTag } = Tag;


const AdminOctRnflAverage = ({ side }: { side: string }) => {
    const { NA_TEXT, IA_TEXT, G_TEXT, Y_TEXT, R_TEXT, OCT_RNFL_AVERAGE } = localizedText;
    const rightOctavSup = useCare1AppSelector(store => store.examData.right_octav_sup);
    const rightOctav = useCare1AppSelector(store => store.examData.right_octav);
    const rightOctavInf = useCare1AppSelector(store => store.examData.right_octav_inf);
    const leftOctavSup = useCare1AppSelector(store => store.examData.left_octav_sup);
    const leftOctav = useCare1AppSelector(store => store.examData.left_octav);
    const leftOctavInf = useCare1AppSelector(store => store.examData.left_octav_inf);

    const rightRnflAnalysisAvg = useCare1AppSelector(store => store.examData.od_rnfl_analysis_avg);
    const rightRnflAnalysisSup = useCare1AppSelector(store => store.examData.od_rnfl_analysis_sup);
    const rightRnflAnalysisInf = useCare1AppSelector(store => store.examData.od_rnfl_analysis_inf);

    const leftRnflAnalysisAvg = useCare1AppSelector(store => store.examData.os_rnfl_analysis_avg);
    const leftRnflAnalysisSup = useCare1AppSelector(store => store.examData.os_rnfl_analysis_sup);
    const leftRnflAnalysisInf = useCare1AppSelector(store => store.examData.os_rnfl_analysis_inf);

    const examStatus = useCare1AppSelector(store => store.examData.exam_status);

    const isOctRnflOdCategoryNearMiss = useCare1AppSelector(store => store.examData.is_oct_rnfl_od_category_near_miss);
    const isOctRnflOsCategoryNearMiss = useCare1AppSelector(store => store.examData.is_oct_rnfl_os_category_near_miss);
    const isVfOdCategoryNearMiss = useCare1AppSelector(store => store.examData.is_vf_od_category_near_miss);
    const isVfOsCategoryNearMiss = useCare1AppSelector(store => store.examData.is_vf_os_category_near_miss);

    const odGptRnflSavedValues = useCare1AppSelector(store => store.examData.od_gpt_rnfl_saved_values);
    const odGptRnflNearMiss = useCare1AppSelector(store => store.examData.od_gpt_rnfl_near_miss);

    const osGptRnflSavedValues = useCare1AppSelector(store => store.examData.os_gpt_rnfl_saved_values);
    const osGptRnflNearMiss = useCare1AppSelector(store => store.examData.os_gpt_rnfl_near_miss);

    const [octavSupCheckedSet] = useState(new Set());
    const [octavCheckedSet] = useState(new Set());
    const [octavInfCheckedSet] = useState(new Set());

    const dispatch = useCare1AppDispatch();

    const isOctRnflCategoryNearMiss = side === 'right' ? isOctRnflOdCategoryNearMiss : isOctRnflOsCategoryNearMiss;
    const isVfCategoryNearMiss = side === 'right' ? isVfOdCategoryNearMiss : isVfOsCategoryNearMiss;

    const isNearMiss = examStatus !== 'reviewed' && isOctRnflCategoryNearMiss;

    const octavSup = side === 'right' ? rightOctavSup : leftOctavSup;
    const octav = side === 'right' ? rightOctav : leftOctav;
    const octavInf = side === 'right' ? rightOctavInf : leftOctavInf;

    const rnflAnalysisAvg = side === 'right' ? rightRnflAnalysisAvg : leftRnflAnalysisAvg;
    const rnflAnalysisSup = side === 'right' ? rightRnflAnalysisSup : leftRnflAnalysisSup;
    const rnflAnalysisInf = side === 'right' ? rightRnflAnalysisInf : leftRnflAnalysisInf;

    const gptRnflSavedValues = side === 'right' ? odGptRnflSavedValues : osGptRnflSavedValues;
    const gptRnflNearMiss = side === 'right' ? odGptRnflNearMiss : osGptRnflNearMiss;

    const getRnflString = () => {
        return `S:${rnflAnalysisSup}  A:${rnflAnalysisAvg}  I:${rnflAnalysisInf}`;
    }

    const handleOctavSupChange = (checked: boolean, val: string) => {
        octavSupCheckedSet.add(octavSup);
        if (side === 'right') {
            if (checked) {
                dispatch(setExamDataValue('right_octav_sup', val));
            }
            else {
                dispatch(setExamDataValue('right_octav_sup', ''));
            }
        }
        else {
            if (checked) {
                dispatch(setExamDataValue('left_octav_sup', val));
            }
            else {
                dispatch(setExamDataValue('left_octav_sup', ''));
            }
        }
    }

    const handleOctavChange = (checked: boolean, val: string) => {
        octavCheckedSet.add(octav);
        if (side === 'right') {
            dispatch(setExamDataValue('right_oct_autoset', false));

            if (checked) {
                dispatch(setExamDataValue('right_octav', val));
            }
            else {
                dispatch(setExamDataValue('right_octav', ''));
            }
        }
        else {
            dispatch(setExamDataValue('left_oct_autoset', false));

            if (checked) {
                dispatch(setExamDataValue('left_octav', val));
            }
            else {
                dispatch(setExamDataValue('left_octav', ''));
            }
        }
    }

    const handleOctavInfChange = (checked: boolean, val: string) => {
        octavInfCheckedSet.add(octavInf);
        if (side === 'right') {
            if (checked) {
                dispatch(setExamDataValue('right_octav_inf', val));
            }
            else {
                dispatch(setExamDataValue('right_octav_inf', ''));
            }
        }
        else {
            if (checked) {
                dispatch(setExamDataValue('left_octav_inf', val));
            }
            else {
                dispatch(setExamDataValue('left_octav_inf', ''));
            }
        }
    }

    const getGptVisionRnflOctavSupClass = (val: string) => {
        if (gptRnflSavedValues !== '') {
            try {
                const ghtJson = JSON.parse(gptRnflSavedValues);
                if (ghtJson['superior'] === val && octavSup === val) {
                    return 'vf-gpt-vision-yellow';
                }

                if (ghtJson['superior'] !== val && octavSup === val) {
                    return 'vf-gpt-vision-red';
                }
            }
            catch (err) {

            }
        }
        return 'vf-gpt-vision-empty';
    }

    const getGptVisionRnflOctavInfClass = (val: string) => {
        if (gptRnflSavedValues !== '') {
            try {
                const ghtJson = JSON.parse(gptRnflSavedValues);
                if (ghtJson['inferior'] === val && octavInf === val) {
                    return 'vf-gpt-vision-yellow';
                }

                if (ghtJson['inferior'] !== val && octavInf === val) {
                    return 'vf-gpt-vision-red';
                }
            }
            catch (err) {

            }
        }
        return 'vf-gpt-vision-empty';
    }

    const getGptVisionRnflOctavClass = (val: string) => {
        if (gptRnflSavedValues !== '') {
            try {
                const ghtJson = JSON.parse(gptRnflSavedValues);
                if (ghtJson['average'] === val && octav === val) {
                    return 'vf-gpt-vision-yellow';
                }

                if (ghtJson['average'] !== val && octav === val) {
                    return 'vf-gpt-vision-red';
                }
            }
            catch (err) {

            }
        }
        return 'vf-gpt-vision-empty';
    }

    return (
        <Card className={'component-input-card oct-rnfl-avg'} bordered={false}>
            <div className={`heading ${isNearMiss ? 'near-miss-heading' : ''}`}>{OCT_RNFL_AVERAGE}</div>
            <div className={`component-body`}>
                <Row>
                    <div className={getGptVisionRnflOctavSupClass('')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-sup-'
                            className={classNames({
                                wide: true,
                                yellow: true,
                                clicked: octavSupCheckedSet.has('')
                            })}
                            checked={octavSup === ''}
                            onChange={(checked) => handleOctavSupChange(checked, '')}
                        >XXX</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavSupClass('invalid')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-sup-invalid'
                            className={classNames({
                                medium: true,
                                yellow: true,
                                clicked: octavSupCheckedSet.has('invalid')
                            })}
                            checked={octavSup === 'invalid'}
                            onChange={(checked) => handleOctavSupChange(checked, 'invalid')}
                        >{IA_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavSupClass('green')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-sup-green'
                            className={classNames({
                                green: true,
                                clicked: octavSupCheckedSet.has('green')
                            })}
                            checked={octavSup === 'green'}
                            onChange={(checked) => handleOctavSupChange(checked, 'green')}
                        >{G_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavSupClass('yellow')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-sup-yellow'
                            className={classNames({
                                yellow: true,
                                clicked: octavSupCheckedSet.has('yellow')
                            })}
                            checked={octavSup === 'yellow'}
                            onChange={(checked) => handleOctavSupChange(checked, 'yellow')}
                        >{Y_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavSupClass('red')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-sup-red'
                            className={classNames({
                                red: true,
                                clicked: octavSupCheckedSet.has('red')
                            })}
                            checked={octavSup === 'red'}
                            onChange={(checked) => handleOctavSupChange(checked, 'red')}
                        >{R_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavSupClass('na')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-sup-na'
                            className={classNames({
                                wide: true,
                                yellow: true,
                                clicked: octavSupCheckedSet.has('na')
                            })}
                            checked={octavSup === 'na'}
                            onChange={(checked) => handleOctavSupChange(checked, 'na')}
                        >{NA_TEXT}</CheckableTag>
                    </div>
                </Row>
                <Row>
                    <div className={getGptVisionRnflOctavClass('')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-'
                            className={classNames({
                                wide: true,
                                yellow: true,
                                clicked: octavCheckedSet.has('')
                            })}
                            checked={octav === ''}
                            onChange={(checked) => handleOctavChange(checked, '')}
                        >XXX</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavClass('invalid')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-invalid'
                            className={classNames({
                                medium: true,
                                yellow: true,
                                clicked: octavCheckedSet.has('invalid')
                            })}
                            checked={octav === 'invalid'}
                            onChange={(checked) => handleOctavChange(checked, 'invalid')}
                        >{IA_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavClass('green')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-green'
                            className={classNames({
                                green: true,
                                clicked: octavCheckedSet.has('green')
                            })}
                            checked={octav === 'green'}
                            onChange={(checked) => handleOctavChange(checked, 'green')}
                        >{G_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavClass('yellow')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-yellow'
                            className={classNames({
                                yellow: true,
                                clicked: octavCheckedSet.has('yellow')
                            })}
                            checked={octav === 'yellow'}
                            onChange={(checked) => handleOctavChange(checked, 'yellow')}
                        >{Y_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavClass('red')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-red'
                            className={classNames({
                                red: true,
                                clicked: octavCheckedSet.has('red')
                            })}
                            checked={octav === 'red'}
                            onChange={(checked) => handleOctavChange(checked, 'red')}
                        >{R_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavClass('na')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-na'
                            className={classNames({
                                wide: true,
                                yellow: true,
                                clicked: octavCheckedSet.has('na')
                            })}
                            checked={octav === 'na'}
                            onChange={(checked) => handleOctavChange(checked, 'na')}
                        >{NA_TEXT}</CheckableTag>
                    </div>
                </Row>
                <Row>
                    <div className={getGptVisionRnflOctavInfClass('')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-inf-'
                            className={classNames({
                                wide: true,
                                yellow: true,
                                clicked: octavInfCheckedSet.has('')
                            })}
                            checked={octavInf === ''}
                            onChange={(checked) => handleOctavInfChange(checked, '')}
                        >XXX</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavInfClass('invalid')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-inf-invalid'
                            className={classNames({
                                medium: true,
                                yellow: true,
                                clicked: octavInfCheckedSet.has('invalid')
                            })}
                            checked={octavInf === 'invalid'}
                            onChange={(checked) => handleOctavInfChange(checked, 'invalid')}
                        >{IA_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavInfClass('green')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-inf-green'
                            className={classNames({
                                green: true,
                                clicked: octavInfCheckedSet.has('green')
                            })}
                            checked={octavInf === 'green'}
                            onChange={(checked) => handleOctavInfChange(checked, 'green')}
                        >{G_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavInfClass('yellow')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-inf-yellow'
                            className={classNames({
                                yellow: true,
                                clicked: octavInfCheckedSet.has('yellow')
                            })}
                            checked={octavInf === 'yellow'}
                            onChange={(checked) => handleOctavInfChange(checked, 'yellow')}
                        >{Y_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavInfClass('red')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-inf-red'
                            className={classNames({
                                red: true,
                                clicked: octavInfCheckedSet.has('red')
                            })}
                            checked={octavInf === 'red'}
                            onChange={(checked) => handleOctavInfChange(checked, 'red')}
                        >{R_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionRnflOctavInfClass('na')}>
                        <CheckableTag
                            data-testid='admin-oct-rnfl-average-octav-inf-na'
                            className={classNames({
                                wide: true,
                                yellow: true,
                                clicked: octavInfCheckedSet.has('na')
                            })}
                            checked={octavInf === 'na'}
                            onChange={(checked) => handleOctavInfChange(checked, 'na')}
                        >{NA_TEXT}</CheckableTag>
                    </div>
                </Row>
            </div>
            <div
                className="heading"
                data-testid='admin-rnfl-analysis-s-a-i'>
                {getRnflString()}</div>
        </Card>
    )
}

export default AdminOctRnflAverage;