import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { DTC_HOUR_SUFFIX, DTC_HOUR_VALIDATION_MSG } from '../../../constants';
import useOnBlurSave from '../../../hooks/useOnBlurSave';
import { setSmartUploadSliceDataValue } from '../../../reducers/smart-upload-slice';
import InputNumberSuffix from '../input-number-suffix';
import ExamMetricValidationModal from '../metrics/exam-metric-validation-modal';
import styles from '../../../../static/css/components/smart-upload-modal.module.scss';



const SmartUploadDtcHour = ({onBlurToSave=true} : {onBlurToSave?: boolean}) => {

    const dtcHour = useCare1AppSelector(store => store.smartUpload.mini_dtc_time);
    const disabled = useCare1AppSelector(store => store.smartUpload.disabled);

    const { handleBlur } = useOnBlurSave({
        key: 'mini_dtc_time',
        value: dtcHour,
    });
   
    const dispatch = useCare1AppDispatch();

    const validateDTCHourValue = () => {
        const value = dtcHour;

        if (value && typeof (value) === 'number' && (value < 7 || value > 18)) {
            dispatch(setSmartUploadSliceDataValue({key:'mini_dtc_time' ,value: null }));
            ExamMetricValidationModal(DTC_HOUR_VALIDATION_MSG);
        } else {
            handleBlur();
        }
    };

    const handleBeforeInput = (
        event: React.CompositionEvent<HTMLInputElement>
    ) => {
        const { data } = event.nativeEvent;
        // Allow only numeric input, prevent any non-numeric characters
        if (!/^\d$/.test(data)) {
            event.preventDefault();
        }
    };

    const handleChange = (val: number|string | null) => {
        if (val === null) {
            dispatch(
                setSmartUploadSliceDataValue({ key: 'mini_dtc_time', value: null })
            )
        } else {
            // All dtc hour entries should be in the format of # or ##
            const updatedValue = `${val}`.replace(/\D/g, '').slice(0, 2);
            dispatch(
                setSmartUploadSliceDataValue({ key: 'mini_dtc_time', value: Number(updatedValue) })
            )
        }
        
    }

    return (
        
        <InputNumberSuffix
            className={`${styles.dtcIopHour} ${!dtcHour ? styles.hasWarning : ''}`}
            data-testid='dtc-hour'
            size={'large'}
            suffix={DTC_HOUR_SUFFIX}
            onBlur={onBlurToSave ? validateDTCHourValue: ()=>{}}
            controls={false}
            onBeforeInput={handleBeforeInput}
            onChange={(val) => handleChange(val)}
            value={dtcHour}
            disabled={disabled}
        />
           
    );
}

export default SmartUploadDtcHour;