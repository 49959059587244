import {
    useCare1AppDispatch,
    useCare1AppSelector,
} from '../../../apps/care1-hooks';
import { Row, Col, Select, Input, DatePicker } from 'antd';
import {
    OD_SELECT_PLACEHOLDER_TEXT,
    FRONTEND_LONG_DATE_FORMAT,
    PHONE_MASK_FORMAT,
    PHN_NAME_FIELD,
} from '../../../constants';
import InputMask from 'react-input-mask';

import { localizedText } from '../../../localizedText';
import { IInsuranceTypeOption } from '../../../reducers/options-slice';

import '../../../../static/css/components/_patient-edit-form.scss';
import { setUploadReconcilerSliceDataValue } from '../../../reducers/upload-reconciler-slice';
import { getHealthNumberData } from '../../../helpers/patient-edit-convert';
import { IHealthNumberData } from '../../../reducers/patient-edit-slice';

const PatientValidation = () => {
    const {
        FNAME_PLACEHOLDER_TEXT,
        LNAME_PLACEHOLDER_TEXT,
        MONTHS,
        YEAR_TEXT,
        MONTH_TEXT,
        DAY_TEXT,
        PROVINCE_PLACEHOLDER_TEXT,
    } = localizedText;

    const dispatch = useCare1AppDispatch();

    const optometristOptions = useCare1AppSelector(
        (store) => store.options.ods
    );
    const provinces = useCare1AppSelector((store) => store.options.provinces);

    const provincePlaceholderText = PROVINCE_PLACEHOLDER_TEXT;
    const countryCode = 'CA';
    const provincesToDisplay = provinces.filter(
        (entry) => entry.country === countryCode
    );

    const optometristODgrouppracticeName = useCare1AppSelector(
        (store) => store.smartUpload.odgrouppractice
    );
    const submissionDate = useCare1AppSelector(
        (store) => store.smartUpload.submissionDate
    );
    const optometristFullname = useCare1AppSelector(
        (store) => store.smartUpload.odFullName
    );
    const refLtrOdName = useCare1AppSelector(
        (store) => store.uploadReconciler.optometrist_name
    );
    const refLtrOdLocation = useCare1AppSelector(
        (store) => store.uploadReconciler.optometrist_location
    );
    const odAccount = useCare1AppSelector(
        (store) => store.uploadReconciler.od_account
    );
    const examDate = useCare1AppSelector(
        (store) => store.uploadReconciler.exam_date
    );
    const dobYear = useCare1AppSelector(
        (store) => store.uploadReconciler.dob_year
    );
    const dobMonth = useCare1AppSelector(
        (store) => store.uploadReconciler.dob_month
    );
    const dobDay = useCare1AppSelector(
        (store) => store.uploadReconciler.dob_day
    );
    const firstName = useCare1AppSelector(
        (store) => store.uploadReconciler.first_name
    );
    const lastName = useCare1AppSelector(
        (store) => store.uploadReconciler.last_name
    );
    const primaryPhone = useCare1AppSelector(
        (store) => store.uploadReconciler.primary_phone
    );
    const province = useCare1AppSelector(
        (store) => store.uploadReconciler.province
    );
    const insuranceType = useCare1AppSelector(
        (store) => store.uploadReconciler.insurance_type
    );
    const phn = useCare1AppSelector((store) => store.uploadReconciler.phn);

    const healthData = province
        ? getHealthNumberData(Number(province), provinces)
        : ({} as IHealthNumberData);

    const PHNLabel = healthData[PHN_NAME_FIELD];
    const isOntarioPatient = province === 9;
    const insuranceTypeOptions = useCare1AppSelector(
        (store) => store.options.insuranceTypeOptions
    );
    const insuranceTypeDisplayOptions = insuranceTypeOptions.reduce(
        (reducedOptions, insuranceTypeOption) => {
            // Don't display old OHIP if this is not in Ontario.
            if (isOntarioPatient || insuranceTypeOption.value !== 'old_ohip') {
                let displayLabel = insuranceTypeOption.label;
                if (insuranceTypeOption.value === 'provincial') {
                    displayLabel = PHNLabel;
                }

                reducedOptions.push({
                    value: insuranceTypeOption.value,
                    label: displayLabel,
                });
            }

            return reducedOptions;
        },
        [] as IInsuranceTypeOption[]
    );

    return (
        <div className='patient-validation'>
            <Row gutter={[16, 12]}>
                <Col span={8}>
                    OD Group Prx: {optometristODgrouppracticeName}
                </Col>
                <Col span={8}>User: {`Dr. ${optometristFullname}`}</Col>
                <Col span={8}>Upload Date: {submissionDate}</Col>
                <Col span={8}>Ref Ltr OD: {refLtrOdName}</Col>
                <Col span={8}>Ref Ltr Location: {refLtrOdLocation}</Col>
                <Col span={8}></Col>
            </Row>

            <Row>
                <Col span={24} className='divider'></Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <div className='title'>Patient Validation</div>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <label
                                className='ant-form-item-no-colon'
                                title='OD Account'
                            >
                                OD Account
                            </label>
                        </Col>
                        <Col span={24}>
                            <Select
                                className='patient-exam-od-select'
                                showSearch
                                allowClear={true}
                                optionFilterProp='label'
                                placeholder={OD_SELECT_PLACEHOLDER_TEXT}
                                options={optometristOptions}
                                value={odAccount}
                                onChange={(value) =>
                                    dispatch(
                                        setUploadReconcilerSliceDataValue({
                                            key: 'od_account',
                                            value,
                                        })
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <label
                                className='ant-form-item-no-colon'
                                title='Exam Visit Date'
                            >
                                Exam Visit Date
                            </label>
                        </Col>
                        <Col span={24}>
                            <DatePicker
                                data-testid='exam-date-picker'
                                className='patient-exam-date-input'
                                showNow={false}
                                format={FRONTEND_LONG_DATE_FORMAT}
                                value={examDate}
                                onChange={(date, dateString) =>
                                    dispatch(
                                        setUploadReconcilerSliceDataValue({
                                            key: 'exam_date',
                                            value: date,
                                        })
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row gutter={2}>
                        <Col span={24}>
                            <label
                                className='ant-form-item-no-colon'
                                title='Date of Birth'
                            >
                                Date of Birth
                            </label>
                        </Col>
                        <Col span={11}>
                            <Select
                                className={'dob-month'}
                                showSearch
                                allowClear={true}
                                optionFilterProp='label'
                                placeholder={MONTH_TEXT}
                                options={MONTHS.map((month, index) => {
                                    return { value: month, label: month };
                                })}
                                value={dobMonth}
                                onChange={(value) =>
                                    dispatch(
                                        setUploadReconcilerSliceDataValue({
                                            key: 'dob_month',
                                            value,
                                        })
                                    )
                                }
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                className={'dob-day'}
                                placeholder={DAY_TEXT}
                                value={dobDay}
                                onChange={(e) =>
                                    dispatch(
                                        setUploadReconcilerSliceDataValue({
                                            key: 'dob_day',
                                            value: e.target.value,
                                        })
                                    )
                                }
                            />
                        </Col>
                        <Col span={7}>
                            <Input
                                className={'dob-year'}
                                placeholder={YEAR_TEXT}
                                value={dobYear}
                                onChange={(e) =>
                                    dispatch(
                                        setUploadReconcilerSliceDataValue({
                                            key: 'dob_year',
                                            value: e.target.value,
                                        })
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <label
                                className='ant-form-item-no-colon'
                                title='First Name'
                            >
                                First Name
                            </label>
                        </Col>
                        <Col span={24}>
                            <Input
                                className={'first-name-input'}
                                placeholder={FNAME_PLACEHOLDER_TEXT}
                                value={firstName}
                                onChange={(e) =>
                                    dispatch(
                                        setUploadReconcilerSliceDataValue({
                                            key: 'first_name',
                                            value: e.target.value,
                                        })
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <label
                                className='ant-form-item-no-colon'
                                title='Last Name'
                            >
                                Last Name
                            </label>
                        </Col>
                        <Col span={24}>
                            <Input
                                className={'last-name-input'}
                                placeholder={LNAME_PLACEHOLDER_TEXT}
                                value={lastName}
                                onChange={(e) =>
                                    dispatch(
                                        setUploadReconcilerSliceDataValue({
                                            key: 'last_name',
                                            value: e.target.value,
                                        })
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <label
                                className='ant-form-item-no-colon'
                                title='Phone'
                            >
                                Phone
                            </label>
                        </Col>
                        <Col span={24}>
                            <InputMask
                                className={'phone-number-input'}
                                mask={PHONE_MASK_FORMAT}
                                placeholder={PHONE_MASK_FORMAT}
                                value={primaryPhone}
                                onChange={(e) =>
                                    dispatch(
                                        setUploadReconcilerSliceDataValue({
                                            key: 'primary_phone',
                                            value: e.target.value,
                                        })
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <label
                                className='ant-form-item-no-colon'
                                title='Province'
                            >
                                Province
                            </label>
                        </Col>
                        <Col span={24}>
                            <Select
                                placeholder={provincePlaceholderText}
                                showSearch
                                allowClear={true}
                                optionFilterProp='label'
                                className='patient-province-input'
                                options={provincesToDisplay.map((p, index) => {
                                    return { value: p.id, label: p.name };
                                })}
                                value={province}
                                onChange={(value) =>
                                    dispatch(
                                        setUploadReconcilerSliceDataValue({
                                            key: 'province',
                                            value,
                                        })
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <label
                                className='ant-form-item-no-colon'
                                title='Type of Insurance'
                            >
                                Type of Insurance
                            </label>
                        </Col>
                        <Col span={24}>
                            <Select
                                placeholder={'Select Insurance Type'}
                                showSearch
                                allowClear={true}
                                optionFilterProp='label'
                                className='insurance-type-input'
                                data-testid='insurance-type-select'
                                options={insuranceTypeDisplayOptions.map(
                                    (insuranceTypeOption, index) => {
                                        return {
                                            value: insuranceTypeOption.value,
                                            label: insuranceTypeOption.label,
                                        };
                                    }
                                )}
                                value={insuranceType}
                                onChange={(value) =>
                                    dispatch(
                                        setUploadReconcilerSliceDataValue({
                                            key: 'insurance_type',
                                            value,
                                        })
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <label
                                className='ant-form-item-no-colon'
                                title='Insurace No'
                            >
                                Insurace No
                            </label>
                        </Col>
                        <Col span={24}>
                            <Input
                                className={'insurance-input'}
                                placeholder={'Enter insurance No'}
                                value={phn}
                                onChange={(e) =>
                                    dispatch(
                                        setUploadReconcilerSliceDataValue({
                                            key: 'phn',
                                            value: e.target.value,
                                        })
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={24} className='divider'></Col>
            </Row>
        </div>
    );
};

export default PatientValidation;
