import React from 'react';
import { Card, Row, Tag } from 'antd';

import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

import '../../../static/css/components/admin-vf-ght.scss';
import { localizedText } from '../../localizedText';

const { CheckableTag } = Tag;

const AdminVfGht = ({ side }: { side: string }) => {
    const { WNL_TEXT, GRS_TEXT, BGRS_TEXT, B_TEXT, ONL_TEXT,
        AHS_TEXT, VF_GHT_TEXT, NA_TEXT } = localizedText;

    const hasRightVF = useCare1AppSelector(store => store.examData.photoUrls.right_vf_photo);
    const hasLeftVF = useCare1AppSelector(store => store.examData.photoUrls.left_vf_photo);

    const rightGht = useCare1AppSelector(store => store.examData.right_ght);
    const leftGht = useCare1AppSelector(store => store.examData.left_ght);

    const rightVfMd = useCare1AppSelector(store => store.examData.right_vf_md);
    const leftVfMd = useCare1AppSelector(store => store.examData.left_vf_md);

    const rightVfPsd = useCare1AppSelector(store => store.examData.right_vf_psd);
    const leftVfPsd = useCare1AppSelector(store => store.examData.left_vf_psd);

    const rightVfAnalysisVfi = useCare1AppSelector(store => store.examData.od_vf_analysis_vfi);
    const leftVfAnalysisVfi = useCare1AppSelector(store => store.examData.os_vf_analysis_vfi);

    const rightVfAnalysisVqi = useCare1AppSelector(store => store.examData.od_vf_analysis_vqi);
    const leftVfAnalysisVqi = useCare1AppSelector(store => store.examData.os_vf_analysis_vqi);

    const examStatus = useCare1AppSelector(store => store.examData.exam_status);

    const isOctRnflOdCategoryNearMiss = useCare1AppSelector(store => store.examData.is_oct_rnfl_od_category_near_miss);
    const isOctRnflOsCategoryNearMiss = useCare1AppSelector(store => store.examData.is_oct_rnfl_os_category_near_miss);
    const isVfOdCategoryNearMiss = useCare1AppSelector(store => store.examData.is_vf_od_category_near_miss);
    const isVfOsCategoryNearMiss = useCare1AppSelector(store => store.examData.is_vf_os_category_near_miss);

    const odGptVfGhtSavedValues = useCare1AppSelector(store => store.examData.od_gpt_vf_ght_saved_values);
    const odGptVfGhtNearMiss = useCare1AppSelector(store => store.examData.od_gpt_vf_ght_near_miss);

    const osGptVfGhtSavedValues = useCare1AppSelector(store => store.examData.os_gpt_vf_ght_saved_values);
    const osGptVfGhtNearMiss = useCare1AppSelector(store => store.examData.os_gpt_vf_ght_near_miss);

    const dispatch = useCare1AppDispatch();

    const isOctRnflCategoryNearMiss = side === 'right' ? isOctRnflOdCategoryNearMiss : isOctRnflOsCategoryNearMiss;
    const isVfCategoryNearMiss = side === 'right' ? isVfOdCategoryNearMiss : isVfOsCategoryNearMiss;

    const isNearMiss = examStatus !== 'reviewed' && isVfCategoryNearMiss;

    const ght = side === 'right' ? rightGht : leftGht;
    const vfMd = side === 'right' ? rightVfMd : leftVfMd;
    const vfPsd = side === 'right' ? rightVfPsd : leftVfPsd;
    const hasVF = side === 'right' ? (hasRightVF && hasRightVF !== '') : (hasLeftVF && hasLeftVF !== '');
    const vfAnalysisVfi = side === 'right' ? rightVfAnalysisVfi : leftVfAnalysisVfi;
    const vfAnalysisVqi = side === 'right' ? rightVfAnalysisVqi : leftVfAnalysisVqi;

    const gptVfGhtSavedValues = side === 'right' ? odGptVfGhtSavedValues : osGptVfGhtSavedValues;
    const gptVfGhtNearMiss = side === 'right' ? odGptVfGhtNearMiss : osGptVfGhtNearMiss;

    const getVfiString = () => {
        if (vfAnalysisVfi === 'na') {
            return "N/A";
        }
        else {
            return `${vfAnalysisVfi}%`;
        }
    }

    const getVqiString = () => {
        if (vfAnalysisVqi === 'na') {
            return "N/A";
        }
        else {
            return `${vfAnalysisVqi}%`;
        }
    }

    const getVfMdString = () => {
        if (vfMd === 'na') {
            return "";
        }
        else {
            return `${vfMd} dB`;
        }
    }

    const getVfPsdString = () => {
        if (vfPsd === 'na') {
            return "";
        }
        else {
            return `${vfPsd} dB`;
        }
    }

    const handleChange = (checked: boolean, val: string) => {
        if (!hasVF) {
            return;
        }

        if (side === 'right') {
            if (checked) {
                dispatch(setExamDataValue('right_ght', val));
            }
            else {
                dispatch(setExamDataValue('right_ght', ''));
            }
        }
        else {
            if (checked) {
                dispatch(setExamDataValue('left_ght', val));
            }
            else {
                dispatch(setExamDataValue('left_ght', ''));
            }
        }
    }

    const getGptVisionVfGhtClass = (val: string) => {
        if (gptVfGhtSavedValues !== '') {
            try {
                const ghtJson = JSON.parse(gptVfGhtSavedValues);
                if (ghtJson['ght'] === val && ght === val) {
                    return 'vf-gpt-vision-yellow';
                }

                if (ghtJson['ght'] !== val && ght === val) {
                    return 'vf-gpt-vision-red';
                }
            }
            catch (err) {

            }
        }
        return 'vf-gpt-vision-empty';
    }

    return (
        <Card className={'component-input-card admin-vf-ght'} bordered={false}>
            <div className={`heading ${isNearMiss ? 'near-miss-heading' : ''}`}>{VF_GHT_TEXT}</div>
            <div className={`component-body`}>
                <Row className='row-one'>
                    <div className={getGptVisionVfGhtClass('wnl')}>
                        <CheckableTag
                            data-testid='admin-vf-ght-wnl'
                            checked={ght === 'wnl'}
                            onChange={(checked) => handleChange(checked, 'wnl')}
                        >{WNL_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionVfGhtClass('grs')}>
                        <CheckableTag
                            data-testid='admin-vf-ght-grs'
                            checked={ght === 'grs'}
                            onChange={(checked) => handleChange(checked, 'grs')}
                            className={ght === 'grs' ? 'yellow' : ''}
                        >{GRS_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionVfGhtClass('b-grs')}>
                        <CheckableTag
                            data-testid='admin-vf-ght-b-grs'
                            checked={ght === 'b-grs'}
                            onChange={(checked) => handleChange(checked, 'b-grs')}
                            className={ght === 'b-grs' ? 'yellow' : ''}
                        >{BGRS_TEXT}</CheckableTag>
                    </div>
                </Row>
                <Row className='row-two'>
                    <div className={getGptVisionVfGhtClass('b')}>
                        <CheckableTag
                            data-testid='admin-vf-ght-b'
                            checked={ght === 'b'}
                            onChange={(checked) => handleChange(checked, 'b')}
                            className={ght === 'b' ? 'yellow' : ''}
                        >{B_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionVfGhtClass('onl')}>
                        <CheckableTag
                            data-testid='admin-vf-ght-onl'
                            checked={ght === 'onl'}
                            onChange={(checked) => handleChange(checked, 'onl')}
                            className={ght === 'onl' ? 'red' : ''}
                        >{ONL_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionVfGhtClass('ahs')}>
                        <CheckableTag
                            data-testid='admin-vf-ght-ahs'
                            checked={ght === 'ahs'}
                            onChange={(checked) => handleChange(checked, 'ahs')}
                            className={ght === 'ahs' ? 'yellow' : ''}
                        >{AHS_TEXT}</CheckableTag>
                    </div>
                    <div className={getGptVisionVfGhtClass('na')}>
                        <CheckableTag
                            data-testid='admin-vf-ght-na'
                            checked={ght === 'na'}
                            onChange={(checked) => handleChange(checked, 'na')}
                            className={ght === 'na' ? 'yellow' : ''}
                        >{NA_TEXT}</CheckableTag>
                    </div>
                </Row>
            </div>
            <div data-testid='admin-vf-ght-vfi-vqi-md-psd' className="heading vf-ght-text">VFI: {getVfiString()} VQI: {getVqiString()} MD: {getVfMdString()} PSD: {getVfPsdString()}</div>
        </Card>
    )
}

export default AdminVfGht;