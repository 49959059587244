import { baseApi, IBaseResponse } from './base-api'
import { IClinicLocationOption } from './od-group-practice-api';
import { IReviewProcessingTaskItem } from '../reducers/review-processing-slice';
import { ReportType } from '../reducers/patient-exam-slice';
import { IPatientReviewInfoText } from '../reducers/review-processing-slice';

export interface IReviewProcessingTableItem {
    key: string;
    patient_id: number;
    exam_id: number;
    exam_status: string;
    first_name: string;
    last_name: string;
    od_name: string;
    od_id: number | null;
    exam_date: string;
    omdr_name: string;
    omdc_name: string;
    alert: string;
    omd_notes: string;
    od_province: string;
    od_group_practice_name: string;
    last_omd_reviewed_date: string;
    rlu_exam_is_premium: boolean;
    is_omdr_reviewed: boolean;
    is_review_processed: boolean;
    od_wants_omd_report: boolean;
    ai_recommends_omd_report: boolean;
    report_type: ReportType;
    ai_report: string;
    omd_report: string;
    deepmd_screenshot: string;
    email_status: string;
}

export interface IReviewProcessingRequest {
    od_id: number | '',
    start_date: string,
    end_date: string,
    od_ai_requests_report: boolean;
}

export interface IReviewProcessingEmailRequest {
    subject: string;
    patient_review_info: IPatientReviewInfoText[];
    recipients: string[];
    od_id: number;
    template_text: string;
    task_items: IReviewProcessingTaskItem[];
}

export interface IReviewProcessingReplyResponse {
    exam_id: number;
    patient_id: number;
    first_name: string;
    last_name: string;
    exam_date: string;
    is_clinic_grand_rounds: boolean;
}

export interface IReviewProcessingCreateOMDMessagingExamRequest {
    taskGuid: string,
    patientId: number;
    examDate: string;
    odMessage: string;
    fromExamId: number;
}

export const reviewProcessingApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getReviewProcessingList: builder.query<IReviewProcessingTableItem[], IReviewProcessingRequest>({
            query: ({od_id, start_date, end_date, od_ai_requests_report}) => {
                return {
                    url: `review_processing/list/`,
                    params: {
                        start_date,
                        end_date,
                        od_id,
                        od_ai_requests_report,
                    },
                }
            } ,
            transformResponse: (response: IBaseResponse & {data: IReviewProcessingTableItem[]}, meta, arg) => response.data,
        }),
        getReviewProcessingOdList: builder.query<IClinicLocationOption[], Omit<IReviewProcessingRequest, 'od_id'>>({
            query: ({start_date, end_date, od_ai_requests_report}) => {
                return {
                    url: `review_processing/od_list/`,
                    params: {
                        start_date,
                        end_date,
                        od_ai_requests_report,
                    },
                }
            } ,
            transformResponse: (response: IBaseResponse & {data: IClinicLocationOption[]}, meta, arg) => response.data,
        }),
        getReviewProcessingTemplate: builder.query<IBaseResponse & {data: {body: string, subject: string}}, number>({
            query: (odId) => {
                return {
                    url: `review_processing/template/${odId}`,
                }
            } ,
        }),
        getReviewProcessingOdEmails: builder.query<IBaseResponse & {data: string[]}, number>({
            query: (odId) => {
                return {
                    url: `review_processing/od_emails/${odId}`,
                }
            } ,
        }),
        getReviewProcessingOmdInternalReviewEmails: builder.query<IBaseResponse & {data: string[]}, number>({
            query: (odId) => {
                return {
                    url: `review_processing/omd_internal_review_emails/${odId}`,
                }
            } ,
        }),
        sendReviewEmail: builder.mutation<IBaseResponse, IReviewProcessingEmailRequest>({
            query(requestData) {
                return {
                    url: 'review_processing/send_review_email',
                    method: 'POST',
                    body: requestData,
                }
            },
        }),
        bulkSetExamReviewProcessed: builder.mutation<IBaseResponse, {processedExams: {examId: number, reviewType: string}[]}>({
            query(requestData) {
                return {
                    url: 'review_processing/bulk_set_exam_review_processed',
                    method: 'POST',
                    body: requestData,
                }
            },
        }),
        getReviewProcessingReplyData: builder.query<IBaseResponse & {data: IReviewProcessingReplyResponse}, string>({
            query: (guid) => {
                return {
                    url: `review_processing_reply/data/${guid}/`,
                }
            } ,
        }),
        createOMDMessagingExam: builder.mutation<IBaseResponse, IReviewProcessingCreateOMDMessagingExamRequest>({
            query(requestData) {
                return {
                    url: 'review_processing_reply/create_omd_messaging_exam/',
                    method: 'POST',
                    body: requestData,
                }
            },
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const {
    useLazyGetReviewProcessingListQuery,
    useGetReviewProcessingListQuery,
    useLazyGetReviewProcessingOdListQuery,
    useLazyGetReviewProcessingOdEmailsQuery,
    useSendReviewEmailMutation,
    useBulkSetExamReviewProcessedMutation,
    useGetReviewProcessingReplyDataQuery,
    useCreateOMDMessagingExamMutation,
    useLazyGetReviewProcessingOmdInternalReviewEmailsQuery,
} = reviewProcessingApi;

export const useGetReivewProcessingListQueryState = reviewProcessingApi.endpoints.getReviewProcessingList.useQueryState;