import { baseApi, IBaseResponse } from './base-api'

export interface IUploadReportItem {
    id: number;
    [monthkey: string]: string | number | string[];
}

export interface IUploadReportResponse {
    report: IUploadReportItem[];
    last_updated: string;
    col_name: string;
    month_column: string[];
}

export const uploadReportApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getUploadReportPerExamTypeByMonthList: builder.query<IUploadReportResponse, { startDate: string, endDate: string }>({
            query: ({ startDate, endDate }) => {
                return {
                    url: `admin/upload_report/report_per_exam_type_by_month/`,
                    params: {
                        start_date: startDate,
                        end_date: endDate
                    },
                }
            },
        }),
        getUploadReportPerOmdTypeByMonthList: builder.query<IUploadReportResponse, { startDate: string, endDate: string }>({
            query: ({ startDate, endDate }) => {
                return {
                    url: `admin/upload_report/report_per_omd_type_by_month/`,
                    params: {
                        start_date: startDate,
                        end_date: endDate
                    },
                }
            },
        }),
        getUploadReportPerOmdByMonthList: builder.query<IUploadReportResponse, { startDate: string, endDate: string }>({
            query: ({ startDate, endDate }) => {
                return {
                    url: `admin/upload_report/report_per_omd_by_month/`,
                    params: {
                        start_date: startDate,
                        end_date: endDate
                    },
                }
            },
        })
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const {
    useLazyGetUploadReportPerExamTypeByMonthListQuery,
    useLazyGetUploadReportPerOmdTypeByMonthListQuery,
    useLazyGetUploadReportPerOmdByMonthListQuery
} = uploadReportApi;
