import React from 'react';
import { Card, Col, Row } from 'antd';
import PatientExamFundusHistory from './patient-exam-fundus-history';
import PatientExamOCTMaculaHistory from './patient-exam-oct-macula-history';
import '../../../static/css/components/_patient-exam-image-history.scss';

function PatientExamImageHistory({ switched, className, side, imageCount }: 
    { switched: boolean, className: string, side: string, imageCount: number }) {

    let leftImageHistory;
    let rightImageHistory;
    if (switched) {
        leftImageHistory = <PatientExamOCTMaculaHistory side={side} imageCount={imageCount} />;
        rightImageHistory = <PatientExamFundusHistory side={side} imageCount={imageCount} />;
    } else {
        leftImageHistory = <PatientExamFundusHistory side={side} imageCount={imageCount} />;
        rightImageHistory = <PatientExamOCTMaculaHistory side={side} imageCount={imageCount} />;
    }

    return (
        <Card className={className} bordered={false}>
            <Row gutter={8}>
                <Col span={12}>{leftImageHistory}</Col>
                <Col span={12}>{rightImageHistory}</Col>
            </Row>
        </Card>
    );
}

export default PatientExamImageHistory;
