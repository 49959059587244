import { useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Tabs } from 'antd';
import PhotoDropzone from '../retina/photo-dropzone';
import * as Constants from '../../constants';
import '../../../static/css/components/patient-exam-oct-rnfl.scss';
import { lengthEqual } from '../../reducers/patient-exam-slice';
import { localizedText } from '../../localizedText';

const { TabPane } = Tabs;


const PatientExamOCTRnfl = ({ disabled, side }: { disabled: boolean, side: string }) => {
    const { PHOTO_DROP_TEXT_SHORT, RIGHT_OCT_RNFL_PHOTO_TITLE_TEXT,
         LEFT_OCT_RNFL_PHOTO_TITLE_TEXT } = localizedText;

    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const isADMIN = useCare1AppSelector(store => store.user.isADMIN);

    const APPLY_BORDER_COLOR = userIsOMDC || userIsOMDR || isADMIN;

    // Border colors are based on the 'octavg' values
    const fieldNameAvg = side === 'right' ? 'right_octav' : 'left_octav';
    const fieldNameSup = side === 'right' ? 'right_octav_sup' : 'left_octav_sup';
    const fieldNameInf = side === 'right' ? 'right_octav_inf' : 'left_octav_inf';
    const sideAvg = useCare1AppSelector(store => store.examData[fieldNameAvg]);
    const sideSup = useCare1AppSelector(store => store.examData[fieldNameSup]);
    const sideInf = useCare1AppSelector(store => store.examData[fieldNameInf]);
    const topBorderColor = APPLY_BORDER_COLOR && sideSup ? `has-border has-top-border top-border-${sideSup}` : '';
    const bottomBorderColor = APPLY_BORDER_COLOR && sideInf ? `has-border bottom-border-${sideInf}` : '';
    const sidesBorderColor = APPLY_BORDER_COLOR && sideAvg ? `has-border sides-border-${sideAvg}` : '';

    const exams_with_left_oct_rnfl = useCare1AppSelector(store => store.examData[Constants.EXAMS_WITH_LEFT_OCT_RNFL], lengthEqual);
    const exams_with_right_oct_rnfl = useCare1AppSelector(store => store.examData[Constants.EXAMS_WITH_RIGHT_OCT_RNFL], lengthEqual);

    return (
        <Card className={`patient-exam-oct-rnfl ${topBorderColor} ${bottomBorderColor} ${sidesBorderColor}`} bordered={false}>
            <Tabs type="card">
                <TabPane tab="OCT RNFL" forceRender key="1">
                    <PhotoDropzone
                        disabled={disabled}
                        field={side === Constants.RIGHT_SIDE_TEXT ? Constants.RIGHT_OCT_RNFL_FIELD : Constants.LEFT_OCT_RNFL_FIELD}
                        label={side === Constants.RIGHT_SIDE_TEXT ? RIGHT_OCT_RNFL_PHOTO_TITLE_TEXT : LEFT_OCT_RNFL_PHOTO_TITLE_TEXT}
                        dropText={PHOTO_DROP_TEXT_SHORT}
                        formatText={Constants.SUPPORTED_FORMAT_TEXT}
                        carousel={side === Constants.RIGHT_SIDE_TEXT ?exams_with_right_oct_rnfl : exams_with_left_oct_rnfl }
                    />
                </TabPane>

                {/* We will hide the GCC until the OMDR and APR UIs are completed */}
                { false &&
                    <TabPane tab="GCC" forceRender key="2">
                        {/* TODO: Add new PhotoDropzone component for VF 10-2 */}
                        {/* <PhotoDropzone
                            disabled={disabled}
                            field={side === Constants.RIGHT_SIDE_TEXT ? Constants.RIGHT_GCC_FIELD : Constants.LEFT_GCC_FIELD}
                            label={side === Constants.RIGHT_SIDE_TEXT ? 'Right GCC' : 'Left GCC'}
                            dropText={PHOTO_DROP_TEXT_SHORT}
                            formatText={Constants.SUPPORTED_FORMAT_TEXT}
                            carousel={side === Constants.RIGHT_SIDE_TEXT ?exams_with_right_oct_rnfl : exams_with_left_oct_rnfl }
                        /> */}
                    </TabPane>
                }
            </Tabs>
        </Card>
    );
}

export default PatientExamOCTRnfl;