import { Card, Col, Row, Typography, Select, InputNumber, Radio } from 'antd';

// Helpers
import '../../../../static/css/components/patient-exam-billing-rules.scss';
import { localizedText } from '../../../localizedText';
import { allowPosNumberOnlyKeys } from '../../../helpers/utilities';

type ComponentProps = {
    disabled: boolean;
    fuNumber: string | null;
    onFuNumberChange: (value: string | null) => void;
    fuPeriod: string | null;
    onFuPeriodSelect: (value: string) => void;
    isFuUnknown: boolean;
    onFuUnknownChange: (value: boolean ) => void;
    gptResultState: {[x: string]: string};
}

const GptFollowUp = ({ disabled, fuNumber, onFuNumberChange, fuPeriod, onFuPeriodSelect, gptResultState, isFuUnknown, onFuUnknownChange  }: ComponentProps) => {

    const { MONTHS_TEXT, WEEKS_TEXT } = localizedText;

    console.log('gpt result stat is ', gptResultState);

    // // max digits of month/weeks value should be 5 based on exam model fu_number field
    const formatValue = (value: string | undefined, info: {
        userTyping: boolean;
        input: string;
      }): string => {
        const regex = /^([1-9]?[0-9]?[0-9]?[0-9]?[0-9]?).*$/;
        const matches = String(value).match(regex);
        return matches && matches[1] ? matches[1] : '';
    }

    return (
        <Row className='patient-exam-billing-rules gpt-follow-up'>
            <Card bordered={false}>
            <Row className="billing-rule-row other-provinces-row-1" gutter={3}>
                <Col span={12}>
                    <Typography.Text>FU Unknown</Typography.Text>
                </Col>
                <Col span={6}>
                    <Radio.Group
                        className={'sees-omd'}
                        buttonStyle="solid"
                        size="small"
                        value={isFuUnknown}
                        onChange={(e) => onFuUnknownChange(e.target.checked)}
                    >
                        <Radio.Button
                            disabled={disabled}
                            value={true}
                        >
                            Yes
                        </Radio.Button>
                        <Radio.Button
                            disabled={disabled}
                            value={false}
                        >
                            No
                        </Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
                <Row justify='space-between' className='billing-rule-row'>
                    <Col span={12}>
                        <Typography.Text>Patient is returning for a follow-up in</Typography.Text>
                    </Col>
                    <Col span={6}>
                        <Row gutter={[12,0]}>
                            <Col className={gptResultState['fuNumber']}>
                                <InputNumber
                                    data-testid='fu-number'
                                    size="small"
                                    disabled={disabled}
                                    onKeyDown={event => allowPosNumberOnlyKeys(event)}
                                    formatter={formatValue}
                                    controls={false}
                                    bordered={false}
                                    className='fu-number fu-number-referral-letter'
                                    value={fuNumber}
                                    onChange={onFuNumberChange}
                                />
                            </Col>
                            <Col flex={1} className={gptResultState['fuPeriod']}>
                                <Select
                                    data-testid='fu-period'
                                    className={'fu-period fu-period-referral-letter'}
                                    size="small"
                                    popupClassName="fu-letter"
                                    disabled={disabled}
                                    value={fuPeriod}
                                    onChange={onFuPeriodSelect}
                                >
                                    <Select.Option value="month">{MONTHS_TEXT}</Select.Option>
                                    <Select.Option value="week">{WEEKS_TEXT}</Select.Option>
                                </Select>
                            </Col>

                        </Row>
                    </Col>
                    {/* <Space>
                        
                    </Space> */}
                </Row>
            </Card>
        </Row>
    );
}

export default GptFollowUp;