import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from "../stores/retina-enabled-store";
import { FUNDUS_HISTORY_TEXT, OCT_MACULA_TEXT, OCT_RNFL_TEXT, OCT_VF_TEXT } from '../constants';
import { HistoryDropzoneField, IAllExamImages } from './patient-exam-slice';

export interface IHistoryImage {
    operationRunning: boolean;
    lightboxOnForIndex: number;
    lastOperationType: string;
    imageType: string;
    imageSide: string;
    viewAll: boolean;
    error: string;
    imageHistoryLightboxOn: boolean;
    currentLightboxImage: string;
    prevLightboxImage: string;
    nextLightboxImage: string;
    lightboxTitle: string;
}

const initialState: IHistoryImage  = {
    operationRunning: false,
    lightboxOnForIndex: -1,
    lastOperationType: '',
    imageType: '',
    imageSide: '',
    viewAll: false,
    error: '',
    imageHistoryLightboxOn: false,
    currentLightboxImage: '',
    prevLightboxImage: '',
    nextLightboxImage: '',
    lightboxTitle: '',
}

export interface IToggleHistoryImageLightboxParams {
    side: 'left' | 'right',
    type: string;
    url: string;
}

export const setHistoryImageLightboxData = ({side, type, url} : IToggleHistoryImageLightboxParams) => (dispatch: AppDispatch, getState: () => RootState) => {
    const {examData: {
        exams_with_left_fundus, exams_with_right_fundus, exams_with_left_vf, exams_with_right_vf,
        exams_with_left_oct, exams_with_right_oct, exams_with_left_oct_rnfl, exams_with_right_oct_rnfl
    }} = getState();

    let images: IAllExamImages[] = [];
    let field: HistoryDropzoneField = 'left_oct_photo' ;
    console.log('side is ', side);
    console.log('type is ', type);
    if (side === 'left') {
        switch(type) {
            case OCT_VF_TEXT:
                images = exams_with_left_vf;
                field = 'left_vf_photo';
                break;
            case FUNDUS_HISTORY_TEXT:
                images = exams_with_left_fundus;
                field = 'left_fundus_photo';
                break;
            case OCT_MACULA_TEXT:
                images = exams_with_left_oct;
                field = 'left_oct_photo';
                break;
            case OCT_RNFL_TEXT:
                images = exams_with_left_oct_rnfl;
                field = 'left_oct_rnfl_photo';
                break;
            default:
                images = [];
        }
    } else if (side === 'right') {
        switch(type) {
            case OCT_VF_TEXT:
                images = exams_with_right_vf;
                field = 'right_vf_photo';
                break;
            case FUNDUS_HISTORY_TEXT:
                images = exams_with_right_fundus;
                field = 'right_fundus_photo';
                break;
            case OCT_MACULA_TEXT:
                images = exams_with_right_oct;
                field = 'right_oct_photo';
                break;
            case OCT_RNFL_TEXT:
                images = exams_with_right_oct_rnfl;
                field = 'right_oct_rnfl_photo';
                break;
            default:
                images = [];
        }
    }
    const sortedImages = [...images].sort((a,b) => {
        return new Date(a.exam_date_utc).getTime() - new Date(b.exam_date_utc).getTime();
    })
    const currentImageIndex = sortedImages.findIndex(image => image[field] === url);
    
    const imagesLength = sortedImages.length;
    dispatch(setLightboxImage({
        currentImage: url,
        nextImage: sortedImages[(currentImageIndex + 1) % imagesLength]?.[field],
        prevImage: sortedImages[(currentImageIndex + imagesLength -1) % imagesLength]?.[field],
    }));
    dispatch(setLightboxImageTitle(sortedImages[currentImageIndex]?.exam_date_utc));
}

export const historyImageSlice = createSlice({
    name: 'historyImageSlice',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        toggleLightbox: (state, action) => {
            const {imageType, imageSide, index} = action.payload;
            return {
                ...state,
                imageType,
                imageSide,
                lightboxOnForIndex: state.lightboxOnForIndex === index ? -1 : index,
            }
        },
        viewAll: (state, action) => {
            const {isVisible, imageType, imageSide} = action.payload;
            return {
                ...state,
                viewAll: isVisible,
                imageType,
                imageSide
            }
        },
        setLightboxImage: (state, action) => {
            const {currentImage, nextImage, prevImage} = action.payload;
            state.currentLightboxImage = currentImage;
            state.nextLightboxImage = nextImage;
            state.prevLightboxImage = prevImage;
        },
        setLightboxImageTitle: (state, action) => {
            state.lightboxTitle = action.payload;
        },
        clearHistoryImageData: () => initialState,
    },
   
});
// export the actions related to UsInsurance
export const { toggleLightbox, viewAll, setLightboxImage, setLightboxImageTitle, clearHistoryImageData } = historyImageSlice.actions;

export default historyImageSlice.reducer;