
import InputMask from 'react-input-mask';
import { Modal, Col, Row, Input, Form, message } from 'antd';
import '../../../static/css/components/_patient-add-gp-modal.scss';
// Helper imports
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';

import { FIRST_NAME_LABEL_TEXT, FNAME_PLACEHOLDER_TEXT, GP_FAX_PLACEHOLDER_TEXT, LAST_NAME_LABEL_TEXT,
    LNAME_PLACEHOLDER_TEXT, PHONE_MASK_FORMAT } from '../../constants';
import { IGP, useAddGpMutation } from '../../services/doctor-api';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { closeAddGPModal, setPatientEditValue } from '../../reducers/patient-edit-slice';
import { setSmartUploadSliceDataValue, updateSmartUploadGp } from '../../reducers/smart-upload-slice';

const FormItem = Form.Item;


const PatientAddGPModal = ({isSmartUpload=false}) => {

    const patientAddGPModalOpen = useCare1AppSelector(store => store.patientEdit.addGPModalOpen);
    const isADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const optometristProvince = useCare1AppSelector(store => store.examData.optometrist_province);
    const odProvince = useCare1AppSelector(store => store.user.odProvince);
    const gpField = useCare1AppSelector(store => store.patientEdit.addEditGpField);
    const smartUploadId = useCare1AppSelector(store => store.smartUpload.id);

    const provinceCode = isADMIN ? optometristProvince : odProvince;

    const [addGp] = useAddGpMutation({
        fixedCacheKey: 'shared-add-gp',
    });

    const dispatch = useCare1AppDispatch();
    const [ form ] = Form.useForm();

    const onCancel = () => {
        // Clear the data and close.
        dispatch(closeAddGPModal());
        form.resetFields();
    }

    const handleSmartUploadAddGp = async(gpId: number) => {
        try {
            dispatch(setSmartUploadSliceDataValue({key: 'gp', value: gpId}));
            if (gpId && smartUploadId) {
                const res = await dispatch(updateSmartUploadGp({
                    id: smartUploadId,
                    gp_id: gpId,
                })).unwrap();
                if (res?.error) throw Error(res?.error)
            }
        } catch (error) {
            console.error(error);
            message.error('Errors updating GP');
        }
    }

    const onFinish = async (values: IGP) => {
        const gpData: Required<Omit<IGP, 'province_id' | 'id'>> = {...values, province: provinceCode };

        try {
            const result = await addGp(gpData).unwrap();
            if (result && result.is_gp_duplicated) {
                Modal.info({
                    className: 'info-modal',
                    title:  'This doctor already exists and is now selected. You can find this doctor again by searching the dropdown.'
                });
            } else if (result.id) {
                if (isSmartUpload) {
                    handleSmartUploadAddGp(result.id as number)
                } else {
                    dispatch(setPatientEditValue({field: gpField, value: result.id}));
                }
                Modal.info({
                    className: 'info-modal',
                    title: 'New Doctor Successfully Added!',
                });
            }

            form.resetFields();
            dispatch(closeAddGPModal());

        } catch(error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    return (
        <Modal
            className="patient-add-gp-modal"
            open={patientAddGPModalOpen}
            closable={false}
            onOk={() => form.submit()}
            onCancel={onCancel}
            okText={'Save'}
            cancelText={'Cancel'}
            okButtonProps={{className: 'save-gp-button'}}
            cancelButtonProps={{className: 'cancel-gp-button'}}
        >
            <Form
                form={form}
                className="patient-add-gp-modal-content"
                onFinish={onFinish}
            >
                <Row className="header-section" align="middle">
                    <div className="modal-title">
                        Add GP
                    </div>
                </Row>
                <Row gutter={24} className={'input-row-one'}>
                    <Col span={12}>
                        <FormItem
                            label={FIRST_NAME_LABEL_TEXT}
                            hasFeedback
                            colon={false}
                            validateTrigger={'onBlur'}
                            name={'first_name'}
                            initialValue=''
                            rules={[{
                                required: true,
                                whitespace: true,
                                message: buildRequiredErrorMessage(FIRST_NAME_LABEL_TEXT),
                            }]}
                        >
                            <Input
                                className={`first-name-input`}
                                placeholder={FNAME_PLACEHOLDER_TEXT}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            label={LAST_NAME_LABEL_TEXT}
                            hasFeedback
                            colon={false}
                            validateTrigger={'onBlur'}
                            name={'last_name'}
                            initialValue=''
                            rules={[{
                                required: true,
                                whitespace: true,
                                message: buildRequiredErrorMessage(LAST_NAME_LABEL_TEXT),
                            }]}
                        >
                            <Input
                                className={`last-name-input`}
                                placeholder={LNAME_PLACEHOLDER_TEXT}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24} className={'input-row-two'}>
                    <Col span={12}>
                        <FormItem
                            label={'GP Fax No.'}
                            hasFeedback
                            colon={false}
                            validateTrigger={'onBlur'}
                            name={'fax_number'}
                            initialValue=''
                            rules={[{
                                required: true,
                                pattern: /^[^_]+$/,
                                message: buildRequiredErrorMessage('GP Fax No.'),
                            }]}
                        >
                            <InputMask
                                placeholder={GP_FAX_PLACEHOLDER_TEXT}
                                className="patient-gp-fax-input"
                                mask={PHONE_MASK_FORMAT}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default PatientAddGPModal;