import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Modal } from "antd";
import { getCsrfToken } from "../helpers/utilities";
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";

export interface IODReviewReminderEmailForm {
    tos: string,
    body: string,
    subject: string,
}

export interface IODReviewReminderEmail extends IODReviewReminderEmailForm {
    isODReviewReminderEmailModalOpen: boolean,
    isOperating: boolean,
    examIds: string[],
}

const initialState: IODReviewReminderEmail = {
    isODReviewReminderEmailModalOpen: false,
    isOperating: false,
    examIds: [],
    tos: '',
    body: '',
    subject: '',
}

type TypeODReviewReminderEmailForm = {
    odReviewReminderEmail: IODReviewReminderEmailForm
}

export const getODReviewReminderEmailExams = createAsyncThunk(
    'odReviewReminderEmail/getODReviewReminderEmailExams',
    async (examIds : string[], {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: { csrfToken: string }};

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const requestData = examIds

        const data = new FormData();
        data.append('examIds', JSON.stringify(requestData));

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/od_to_review_reminder_exams/list/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const submitODReviewReminderEmailRequest = createAsyncThunk(
    'odReviewReminderEmail/submitODReviewReminderEmailRequest',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: { csrfToken: string }};
        const { odReviewReminderEmail: { tos, body, subject } } = getState() as TypeODReviewReminderEmailForm;

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const requestData = {
            'tos': tos,
            'body': body,
            'subject': subject,
        }

        const data = new FormData();
        data.append('send_od_to_review_reminder_email_request', JSON.stringify(requestData));

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/od_to_review_reminder_email/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const odReviewReminderEmail = createSlice({
    name: 'odReviewReminderEmail',
    initialState,
    reducers: {
        setODReviewReminderEmailModalOpen: (state, action) => {
            state = initialState;
            return {
                ...state,
                subject: 'Reminder - older visits on Care1', 
                isODReviewReminderEmailModalOpen: action.payload
            }
        },
        setODReviewReminderEmailField: (state, action) => {
            return {
                ...state,
                [action.payload.field]: action.payload.value
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getODReviewReminderEmailExams.fulfilled, (state, action) => {
            
            const data = action.payload.exams;
            let examUrls = '';
            for (let i=0; i<data.length; i++) {
                examUrls = examUrls + 'https://start.care1.ca/patient-exam/' + data[i] + '\n';
            }

            const template =    "Hello!\n\n" +
                                "Just a friendly reminder that you currently have patients on Care1 whose exam visit dates are older than 30 days, and whose chart statuses are set to \"Not ready for OMD\" or \"OD to review\".\n\n" +             
                                "We would appreciate if you could find some time over the next few days to review these patients, and update their chart statuses to \"Ready for OMD\". I have included the links to each of these patients today. You should also be able to find all of these patients by selecting \"All time\", \"Not Ready/OD to Review\", and \"Clinic Pts\" in the filters on the patient list page.\n\n" +             
                                "{examUrls}\n\n" +             
                                "Please let us know if you have any questions, concerns or feedback. Thank you for your assistance!\n\n"

            state.body = template.replaceAll('{examUrls}', examUrls);
        });
        builder.addCase(submitODReviewReminderEmailRequest.pending, (state) => {
            state.isOperating = true;
        });
        builder.addCase(submitODReviewReminderEmailRequest.fulfilled, (state) => {
            state.isOperating = false;
            Modal.info({
                className: 'info-modal',
                title: 'OD Review Reminder Email request submitted successfully!',
            });
        });
        builder.addCase(submitODReviewReminderEmailRequest.rejected, (state, action) => {
            state.isOperating = false;
            Modal.error({
                className: 'info-modal',
                title: `Errors submitting OD Review Reminder Email request. ${action.payload}`,
            })
        });
    }
})

export const { setODReviewReminderEmailModalOpen, setODReviewReminderEmailField } = odReviewReminderEmail.actions;

export default odReviewReminderEmail.reducer;