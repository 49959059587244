import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FOLLOW_UP_AIM } from '../constants';
import { getCsrfToken } from '../helpers/utilities';
import { logout } from './user-slice';
import { apiRequest } from '../services/api-request';
import { Modal } from 'antd';
import { AppDispatch, RootState } from "../stores/retina-enabled-store";
import { setExamDataValue } from './patient-exam-slice';

export interface IFinalDeepmdRecommendationsDetails {
    iop_zone: string;
    discuss_with_display: string;
    selected: string;
    discuss: string;
    chkbx: string;
    dx: string;
    fu_zone: string;
    display_level: string;
    class: string;
    factor_name: string;
}

export interface IFinalDeepmdRecommendations {
    left: IFinalDeepmdRecommendationsDetails;
    right: IFinalDeepmdRecommendationsDetails;
}

export interface ITxalgo3Comparisons {
    iop_zone: string;
    discuss: string;
    chkbx: string;
    dx: string;
    fu_zone: string;
    class: string;
    factor_name: string;
    display_level?: string;
}

export interface IVerbalization {
    condition: string;
    shoot_in: string;
    verbalization_id: string;
    variables: {
        side_text: string;
    }[];
}
export interface IVerbalizations {
    textSentence: String;
    verbalization: IVerbalization;
}

export interface ITxalgo3 {
    class_aim: string;
    final_deepmd_recommendations: IFinalDeepmdRecommendations[];
    followup_aim: string;
    od_deepmd_discuss: string;
    od_deepmd_dx: string;
    od_iop_aim: string;
    os_deepmd_dx: string;
    os_deepmd_discuss: string;
    os_iop_aim: string;
    left_comparisons: ITxalgo3Comparisons[];
    right_comparisons: ITxalgo3Comparisons[];
    verbalizations: IVerbalizations[];
    left_iop: string;
    right_iop: string;
    left_ciop: string;
    right_ciop: string;
    status: string,
    error: string;
}

const initialState : ITxalgo3 = {
    class_aim: '',
    final_deepmd_recommendations: [],
    followup_aim: '',
    od_deepmd_discuss: '',
    od_deepmd_dx: '',
    od_iop_aim: '',
    os_deepmd_dx: '',
    os_deepmd_discuss: '',
    os_iop_aim: '',
    left_comparisons: [],
    right_comparisons: [],
    verbalizations: [],
    left_iop: '',
    right_iop: '',
    left_ciop: '',
    right_ciop: '',
    status: 'idle',
    error: '',
}


export const refreshTxAlgo3Request = createAsyncThunk(
    'txalgo3/refreshTxAlgo3',
    async ({exam_id, ...requestData} : {exam_id: number, od_iop_aim?: string, os_iop_aim?: string}, {dispatch, getState, rejectWithValue}) => {
        // use 'as' here to type the csrf token
        // rejectWithValue takes the error value so that the errors can be handled
        // in deleteDoctorRequest.rejected, the value is passed as action.payload
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/txalgo3/${exam_id}`;
        const data = JSON.stringify(requestData);

        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

const getConditionalValueFromPatientStatus = (originalValue: string, patient_status: string ) => {
    let conditionalValue = originalValue;
        if (conditionalValue.includes("if")) {
            if (conditionalValue.includes(patient_status)) {
                // Set the F/U value to what's indicated by the conditional status.
                conditionalValue = conditionalValue.substring(1, conditionalValue.indexOf("if")-1);
            }
        }
        return conditionalValue;
}

export const handleTxalgo3ButtonClick = () => (dispatch: AppDispatch, getState: () => RootState) => {

    const { txalgo3, examData: {patient_status, od_iop_aim, os_iop_aim, id} } = getState();
    const { od_iop_aim: rightIopComfortZone, os_iop_aim: leftIopComfortZone,
        class_aim: worstRecClass, followup_aim: shortestRecFu} = txalgo3;

    if (worstRecClass){
        if (worstRecClass.includes('*')){
            dispatch(setExamDataValue('auto_patient_status', true));
            dispatch(setExamDataValue('patient_status', worstRecClass.replace('*','')));
        }
        else {
            dispatch(setExamDataValue('patient_status', worstRecClass));
        }
    }
    if (rightIopComfortZone) {
        const iop_aim = getConditionalValueFromPatientStatus(rightIopComfortZone, patient_status);
        if (iop_aim !== od_iop_aim){
            dispatch(setExamDataValue('od_iop_aim', iop_aim));
            dispatch(refreshTxAlgo3Request({exam_id: id as number, od_iop_aim : iop_aim}));
        }

    }
    if (leftIopComfortZone) {
        const iop_aim = getConditionalValueFromPatientStatus(leftIopComfortZone, patient_status);
        if (iop_aim !== os_iop_aim){
            dispatch(setExamDataValue('os_iop_aim', iop_aim));
            dispatch(refreshTxAlgo3Request({exam_id: id as number,  os_iop_aim : iop_aim}));
        }
    }
    if (shortestRecFu) {
        let follow_up = (shortestRecFu === '0.5' || shortestRecFu === '0.25') ? '0.25-0.5' : shortestRecFu;
        follow_up = getConditionalValueFromPatientStatus(follow_up, patient_status);

        // The new value needs to be in the selectable followup list.
        if (FOLLOW_UP_AIM.find(entry => entry.key === follow_up)) {
            dispatch(setExamDataValue('followup_aim', follow_up));
        }
    }
}

export const getTxAlgo3Request = createAsyncThunk(
    'txalgo3/getTxAlgo3',
    async (exam_id: number, {dispatch, getState, rejectWithValue}) => {
        // use 'as' here to type the csrf token
        // rejectWithValue takes the error value so that the errors can be handled
        // in deleteDoctorRequest.rejected, the value is passed as action.payload
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/txalgo3/${exam_id}`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const txalgo3Slice = createSlice({
    name: 'txalgo3',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetTxAlgo3: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getTxAlgo3Request.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getTxAlgo3Request.fulfilled, (state, action) => {
            return {...state,...action.payload.data, status: 'success'}
        });
        builder.addCase(getTxAlgo3Request.rejected, (state, action) => {
            state.status = 'failed';

            // handle the rejected case, the value was passed from rejecteWithValue
            Modal.error({
                className: 'info-modal',
                title: `Errors getting txalgo3 data. ${action.payload}`,
            })
        });
        builder.addCase(refreshTxAlgo3Request.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(refreshTxAlgo3Request.fulfilled, (state, action) => {
            return {...state,...action.payload.data, status: 'success'}
        });
        builder.addCase(refreshTxAlgo3Request.rejected, (state, action) => {
            state.status = 'failed';

            // handle the rejected case, the value was passed from rejecteWithValue
            Modal.error({
                className: 'info-modal',
                title: `Errors refreshing txalgo3 data. ${action.payload}`,
            })
        });
    }
});
// export the actions related to TxAlgo3
export const { resetTxAlgo3 } = txalgo3Slice.actions;

export default txalgo3Slice.reducer;