import { Row, Card, Col } from 'antd';
import PatientExamHeaderPatientInfo from './patient-exam-header-patient-info';
import PatientExamHeaderDoctorInfo from './patient-exam-header-doctor-info';
import PatientExamHeaderExamDateStatus from './patient-exam-header-exam-date-status';
import PatientExamHeaderButtons from './patient-exam-header-buttons';
import PatientExamPremiumHeaderFlagsIndicator from './patient-exam-premium-header-flags-indicator';
import '../../../../static/css/components/_patient-exam-header.scss';
import '../../../../static/css/shared/date-picker.scss';
import { openPatientDetailsModal } from '../../../reducers/patient-details-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';

const PatientExamPremiumHeader = () => {
    const shouldDisplayMultiTabs = useCare1AppSelector(store => store.patientExams.shouldDisplayMultiTabs);

    const pehHeaderClassName = `patient-exam-header patient-exam-premium-header ${shouldDisplayMultiTabs ? 'pei-exam-room-border' : ''}`;

    const dispatch = useCare1AppDispatch()

    const onPatientNameClick = () => {
        dispatch(openPatientDetailsModal());
    }

    return (
        <Card className={pehHeaderClassName} bordered={false}>
            <Row>
                <Col span={6} className="patient-info">
                    <PatientExamHeaderPatientInfo
                        onClick={onPatientNameClick}
                    />
                </Col>

                <Col span={4} className={'doctors'}>
                    <PatientExamHeaderDoctorInfo
                        onClick={onPatientNameClick}
                    />
                </Col>

                <PatientExamHeaderExamDateStatus
                    columnSpan={5}
                    onClick={onPatientNameClick}
                />

                {/* Premium exams have the flags indicator */}
                <Col span={4} className={'premium-header-flags'}>
                    <PatientExamPremiumHeaderFlagsIndicator />
                </Col>
            </Row>
        </Card>
    );

}

export default PatientExamPremiumHeader;