import { useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { Form } from 'antd';
import InputNumberSuffix from '../input-number-suffix';
import { IExamData, setExamDataValue } from '../../../reducers/patient-exam-slice';
import { updateGptSetValuesNearMissData } from '../../../reducers/gpt-set-values-slice';
import { allowPosNumberOnlyKeys } from '../../../helpers/utilities';
import * as Constants from '../../../constants';
import ExamMetricValidationModal from './exam-metric-validation-modal';
import { FieldData } from 'rc-field-form/lib/interface';
import { InputNumberProps } from 'antd/lib/input-number';

type ComponentProps = {
    disabled?: boolean;
    side: string;
    placeholderValue: string;
    gptSaved?: boolean;
    gptNearMiss?: boolean;
}

const PatientExamPachs = ({ disabled, side, placeholderValue, gptSaved, gptNearMiss }: ComponentProps) => {

    const pachsFieldName = side === 'od' ? 'od_pachs' : 'os_pachs';
    const otherPachsFieldName = side === 'os' ? 'od_pachs' : 'os_pachs';
    const pastPachsFieldName = side === 'od' ? 'past_od_pachs' : 'past_os_pachs';
    const otherPastPachsFieldName = side === 'os' ? 'past_od_pachs' : 'past_os_pachs';
    const sidePachs = useCare1AppSelector(store => store.examData[pachsFieldName]);
    const otherSidePachs = useCare1AppSelector(store => store.examData[otherPachsFieldName]);
    const sidePastPachs = useCare1AppSelector(store => store.examData[pastPachsFieldName]);
    const otherSidePastPachs = useCare1AppSelector(store => store.examData[otherPastPachsFieldName]);

    // Yellow warning border should display for OD users only
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const areFieldsEmpty = !sidePachs && !sidePastPachs && !otherSidePachs && !otherSidePastPachs;

    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const validatePachValue = () => {
        if (typeof (sidePachs) === 'number' && (sidePachs < 200 || sidePachs > 750)) {
            dispatch(setExamDataValue(pachsFieldName, ''));
            ExamMetricValidationModal(Constants.PACH_VALIDATION_MSG);
        }
    };

    useEffect(()=>{
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            [pachsFieldName]: sidePachs
        };
        form.setFieldsValue(newValidationFieldValues);
    }, [pachsFieldName, sidePachs, form])

    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case 'od_pachs':
                    case 'os_pachs':
                        // Format value to #, ## or ###
                        dispatch(setExamDataValue(field.name[0], formatValue
                            ? formatValue(field.value, { userTyping: false, input: '' })
                            : field.value));
                        dispatch(updateGptSetValuesNearMissData({id: [field.name[0]].toString() as keyof IExamData, value: formatValue
                            ? formatValue(field.value, { userTyping: false, input: '' })
                            : field.value}));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    // All Pachs entries should be in the format of #, ## or ###
    const formatValue: InputNumberProps['formatter'] = (value, info) => {
        const regex = /^([1-9]?[0-9]?[0-9]?).*$/;
        const matches = String(value).match(regex);
        return matches && matches[1]
            ? String(Number(`${matches[1]}`))
            : '';
    }

    const gptClassname = () => {
        if (gptNearMiss) {
            return 'exam-gpt-state-red';
        }
        if (gptSaved) {
            return 'exam-gpt-state-yellow';
        }
        return '';
    }

    return (
        <Form form={form} onFieldsChange={onFieldsChange}>
            <div className={gptClassname()}>
                {/* PACH 001, 002, 003, 004 */}
                <Form.Item
                    className='patient-exam-pachs'
                    help=""
                    validateStatus={userIsOD && areFieldsEmpty ? 'warning' : ''}
                    name={`${side}_pachs`}
                >
                    {/* Use onKeyPress event to prevent letters */}
                    {/* Use formatter to enforce a max of three digits */}
                    <InputNumberSuffix
                        className="input-component"
                        data-testid={`${side}_pachs`}
                        size="small"
                        step={1}
                        suffix="um"
                        placeholder={placeholderValue}
                        formatter={formatValue}
                        onKeyPress={event => allowPosNumberOnlyKeys(event)}
                        onBlur={validatePachValue}
                        disabled={disabled}
                    />
                </Form.Item>
            </div>
        </Form>
    );
}

export default PatientExamPachs;
