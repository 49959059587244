import { Fragment, useEffect, useMemo } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Checkbox, Col, Input, Row, Form } from 'antd';
import { ALLERGIES_NONE_FIELD, ALLERGIES_FIELD } from '../../constants';
import {
    getTxAlgoKeywordOptions, addTxAlgoKeywordColors, renderTxAlgoKeywordString
} from '../../helpers/patient-exam-convert';
import { localizedText } from '../../localizedText';
import { IExamData, setExamDataValue } from '../../reducers/patient-exam-slice';
import { updateGptSetValuesNearMissData } from '../../reducers/gpt-set-values-slice';
import { FieldData } from 'rc-field-form/lib/interface';
import '../../../static/css/components/patient-exam-allergies.scss';

const PatientExamAllergies = ({ disabled, gptSaved, gptNearMiss }: { disabled?: boolean, gptSaved?: boolean, gptNearMiss?: boolean }) => {
    const { ALLERGIES_PLACEHOLDER, ALLERGIES_TITLE, NKDA_TEXT } = localizedText;

    const allergies = useCare1AppSelector(store => store.examData[ALLERGIES_FIELD]);
    const currentAllergiesNone = useCare1AppSelector(store => store.examData[ALLERGIES_NONE_FIELD]);
    const examIsODMessaging = useCare1AppSelector(store => store.examData.is_od_messaging_submission);
    const pastAllergies = useCare1AppSelector(store => store.examData.past_allergies);
    const pastAllergiesNone = useCare1AppSelector(store => store.examData.past_allergies_none);

    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsOMD = userIsOMDC || userIsOMDR;

    const [form] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    // OD messaging exams should just always look at past values.
    const allergiesNone = examIsODMessaging ? pastAllergiesNone : currentAllergiesNone;
    const placeholderText = examIsODMessaging ? pastAllergies : ALLERGIES_PLACEHOLDER;

    const placeholder = allergiesNone ? '' : placeholderText;

    // If user is OMDC
    let omdAllergies = '';
    if (userIsOMD) {
        if (allergiesNone) {
            omdAllergies = 'None';
        } else {
            omdAllergies = examIsODMessaging && !allergies ? placeholderText : allergies;
        }
    }

    // Get the color coded keywords for the component
    const txAlgo3Keywords = useCare1AppSelector(store => store.options.txAlgoKeywords);
    const txAlgoKeywordOptions = useMemo(() => {
        return getTxAlgoKeywordOptions(txAlgo3Keywords, 'allergies');
    }, [txAlgo3Keywords]);

    // Add the color code tags to the content string
    const applyKeywordColors = useMemo(() => {
        if (txAlgoKeywordOptions) {
            return addTxAlgoKeywordColors(txAlgoKeywordOptions, omdAllergies);
        }
        return null;
    }, [txAlgoKeywordOptions, omdAllergies]);

    // equivalent of componentDidUpdate in this case
    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            [ALLERGIES_FIELD]: examIsODMessaging && !allergies ? null : allergies,
            [ALLERGIES_NONE_FIELD]: allergiesNone,
        }
        form.setFieldsValue(newValidationFieldValues);
    }, [allergies, allergiesNone, examIsODMessaging, form])

    // Ant Design handler. This is called whenever a field is changed by the user using Ant Design controls.
    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case ALLERGIES_FIELD:
                    case ALLERGIES_NONE_FIELD:
                        dispatch(setExamDataValue(field.name[0], field.value));
                        dispatch(updateGptSetValuesNearMissData({id: [field.name[0]].toString() as keyof IExamData, value: field.value}));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    const gptClassname = () => {
        if (gptNearMiss) {
            return 'exam-gpt-state-red';
        }
        if (gptSaved) {
            return 'exam-gpt-state-yellow';
        }
        return '';
    }

    return (
        <Form form={form} onFieldsChange={onFieldsChange}>
            <div className={gptClassname()}>
                <Card className={'component-input-card patient-exam-allergies'} bordered={false}>
                    {/* ALRG 001 */}
                    {(userIsOD || userIsADMIN) &&
                        <Fragment>
                            <Row className="checkboxes">
                                <Col className="col-heading">
                                    <div className="heading">{ALLERGIES_TITLE}</div>
                                </Col>
                                <Col className="col-nkda">
                                    {/* ALRG 002 */}
                                    <Form.Item
                                        className={'allergies-none'}
                                        name={ALLERGIES_NONE_FIELD}
                                        initialValue={allergiesNone}
                                        valuePropName="checked"
                                    >
                                        <Checkbox
                                            data-testid='none-checkbox'
                                            disabled={disabled || allergies.length > 0}
                                        >
                                            {NKDA_TEXT}
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Item help="" name={ALLERGIES_FIELD} initialValue={allergies}>
                                    {/* ALRG 003, ALRG 005 */}
                                    <Input.TextArea
                                        data-testid='allergies-input'
                                        disabled={disabled || allergiesNone}
                                        className="component-input"
                                        autoSize={false}
                                        placeholder={placeholder}
                                    />
                                </Form.Item>
                            </Row>
                        </Fragment>
                    }
                    {userIsOMDC &&
                        <Fragment>
                            <Row className="checkboxes">
                                <Col className="col-heading">
                                    <div className="heading">{ALLERGIES_TITLE}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Item help="" initialValue={omdAllergies}>
                                    {/* ALRG 003, ALRG 005 */}
                                    <Input.TextArea
                                        data-testid='omdc-allergies-input'
                                        disabled={disabled || allergiesNone}
                                        className="component-input"
                                        autoSize={false}
                                        placeholder={placeholder}
                                        value={omdAllergies}
                                    />
                                </Form.Item>
                            </Row>
                        </Fragment>
                    }
                    {userIsOMDR &&
                        <Fragment>
                            <div className="heading">{ALLERGIES_TITLE}</div>
                            <Form.Item>
                                <div className="component-input color-keyword-div" data-testid='allergies'>
                                    {
                                        applyKeywordColors && applyKeywordColors.map(
                                            (string, index) => (<Fragment key={index}>{renderTxAlgoKeywordString(string)}</Fragment>)
                                        )
                                    }
                                </div>
                            </Form.Item>
                        </Fragment>
                    }
                </Card>
            </div>
        </Form>
    );
}

export default PatientExamAllergies;
