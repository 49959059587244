import { baseApi } from './base-api'

export interface IStaffResponse {
    success?: boolean;
    id?: number | null;
    error? : string;
}

export interface IStaffListResponse {
    staff_list: IStaffDetails[];
    success: boolean;
    error?: string;
}

export interface IStaffDetailResponse {
    success: boolean;
    staff: IEditStaffData;
}

export interface IStaffDetails {
    key: number;
    first_name: string;
    last_name: string;
    username: string;
    user_type: 'Admin' | 'Admin superuser' | 'Sales' | 'Science';
    status: 'Active' | 'Inactive';
}

export interface IStaffFormItems {
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    user_status: string[];
}

export interface INewStaffFormItems extends IStaffFormItems {
    password: string;
    confirm_password: string;
}

export interface INewStaffData {
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    password: string;
    is_active: boolean;
    is_superuser: boolean;
    is_sales_user: boolean;
    is_science_user: boolean;
}

export interface IEditStaffData {
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    is_active: boolean;
    is_superuser: boolean;
    key: number;
}

export interface IPasswordData {
    password: string;
    key: number;
}

export type IStaffEditFormItems = Omit<INewStaffFormItems, 'password' | 'confirm_password'>

export const staffApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getStaffList: builder.query<IStaffDetails[], void>({
            query: () => 'staffs/',
            transformResponse: (response: IStaffListResponse, meta, arg) => response.staff_list,

            // Give a tag to staffs so that other API actions can invalidate StaffList
            providesTags: [{type: 'StaffList', id: 'LIST'}]
        }),
        addStaff: builder.mutation<IStaffResponse, INewStaffData>({
            query(requestData) {
                return {
                    url: 'staff/add/',
                    method: 'POST',
                    body: requestData,
                }
            },
            invalidatesTags: ['StaffList']
        }),

        deleteStaff: builder.mutation<IStaffResponse, number>({
            query(id) {
                return {
                    url: `staff/delete/${id}`,
                    method: 'POST',
                    body: {},
                }
            },
            invalidatesTags: ['StaffList']
        }),
       
        editStaff: builder.mutation<IStaffResponse, IEditStaffData>({
            query(requestData) {
                return {
                    url: 'staff/edit/',
                    method: 'POST',
                    body: requestData,
                }
            },
            // Invalidate a specific OMD by its id so that the OMD tag is also updated, also invalidate
            // the Doctor List because one of the OMD(doctors) is updated
            invalidatesTags: (result) => (
                result && result.success
                ? [{type: 'Staff', id: (result.id)?.toString()}, 'StaffList']
                : ['StaffList']
            )
        }),
        getStaffDetail: builder.query<IStaffFormItems, number>({
            query: (id) => `staff/${id}`,
            // give a tag to a specific OD so that when this OD is updated by other API calls, its
            // tag(OD Details) is also updated (by refetching), note that _result and _error are not
            // used here, only id is used
            transformResponse: (response: IStaffDetailResponse, meta, arg) => {
                const {is_active, is_superuser, ...rest} = response.staff;
                const userStatus = [];
                is_active && userStatus.push('active');
                is_superuser && userStatus.push('superuser');
                return {
                    ...rest,
                    user_status: userStatus,
                }
            },

            providesTags: (_result, _error, id) => [{type: 'Staff', id}],
        }),
        changePassword: builder.mutation<IStaffResponse, IPasswordData>({
            query(requestData) {
                return {
                    url: 'staff/password/',
                    method: 'POST',
                    body: requestData,
                }
            }
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const { useAddStaffMutation, useGetStaffListQuery, useEditStaffMutation,
    useGetStaffDetailQuery, useChangePasswordMutation, useDeleteStaffMutation,
    usePrefetch
} = staffApi;