import { useEffect, useState } from 'react';
import { Table, Button, TableColumnsType, Space, Tooltip, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../static/css/components/admin-doctor-list-table.scss';
import { EditOutlined, PlusOutlined, DeleteOutlined, UpCircleOutlined, DownCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { compareStrings } from '../../helpers/sorting';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { setCurrentClinicLocation, setCurrentGroupPracticeName, setCurrentOdGroupPractice, toggleClinicLocationAddModal, toggleClinicLocationEditModal, toggleOdGroupPracticeEditModal } from '../../reducers/doctor-slice';
import { localizedText } from '../../localizedText';
import { IClinicLocationTableItem, IOdGroupPracticeTableItems, useDeleteClinicLocationMutation, useGetOdGroupPracticeListQuery } from '../../services/od-group-practice-api';

const OdGroupPracticeTable = () => {
    const { DOCTORS_LIST_ACTION, OF_TEXT, ITEMS_TEXT } = localizedText;
    const searchQuery = useCare1AppSelector(store => store.doctorSlice.odGroupPracticeSearchQuery);

    const dispatch = useCare1AppDispatch();
    
    const [scrollClass, setScrollClass] = useState('');

    const { data, isLoading } = useGetOdGroupPracticeListQuery();
    const [deleteClinicLocation] = useDeleteClinicLocationMutation();

    const onEditClinicLocationClick = (clinicLocationKey: number, odGroupPracticeId: number) => {
        dispatch(toggleClinicLocationEditModal());
        dispatch(setCurrentClinicLocation(clinicLocationKey));
        dispatch(setCurrentOdGroupPractice(odGroupPracticeId));
    }

    const onDeleteClinicLocationClick = (clinicLocationKey: number) => {
        Modal.confirm({
            className: 'delete-doctor-confirmation-modal',
            icon: <InfoCircleOutlined />,
            title: 'Deleting a Clinic Location will remove all the information. Are you sure you want to delete this location?',
            onOk: async () => {
                try {
                    const result = await deleteClinicLocation(clinicLocationKey).unwrap();
                    if (result.success) {
                        Modal.info({
                            className: 'info-modal',
                            title: 'Location is successfully deleted.',
                        });
                    } else {
                        throw new Error(result?.error);
                    }
                } catch (error) {
                    const message = (error instanceof Error) ?  error?.message : error;
                    Modal.error({
                        className: 'info-modal',
                        content: message as string,
                        title: 'Error',
                    });
                }
                
            },
        })
    }

    const expandedRowRender = (record: IOdGroupPracticeTableItems, index: number) => {
        const expandedColumns: TableColumnsType<IClinicLocationTableItem> = [
          { title: <div>Location Name 
                <Tooltip title="Location with empty name field is displayed as Single"
                >
                    <InfoCircleOutlined />
                </Tooltip>
                </div>,
            dataIndex: 'location_name',
            key: 'location_name',
            className: 'location-name-column' },
          { title: 'Location Full Name',
            dataIndex: 'location_full_name',
            key: 'location_name',
            className: 'location-name-column' },
          {
            title: 'Action',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, clinicLocationRecord) => (
                <Space>
                    <Button
                        className="edit-doctor-button"
                        onClick={() => onEditClinicLocationClick(clinicLocationRecord.key, record.key)}
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        className="edit-doctor-button"
                        onClick={() => onDeleteClinicLocationClick(clinicLocationRecord.key)}
                    >
                        <DeleteOutlined />
                    </Button>
           </Space>
            ),
          },
        ];
       
        return (
            <Table
                columns={expandedColumns}
                dataSource={record.locations}
                pagination={false} />
            );
    };

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const groupPracticeList = data?.filter(({od_group_practice_name}: {od_group_practice_name: string}) => (
        od_group_practice_name.toLowerCase().includes(searchQuery)
    ));

    const onTableRowEditClick = (record: IOdGroupPracticeTableItems, mode: 'add' | 'edit') => {
        mode === 'edit' ?
            dispatch(toggleOdGroupPracticeEditModal()) :
            dispatch(toggleClinicLocationAddModal())
        
        mode === 'add' &&
            dispatch(setCurrentGroupPracticeName(record.od_group_practice_name));
            
        dispatch(setCurrentOdGroupPractice(record.key));
    }

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }
    const columns: ColumnsType<IOdGroupPracticeTableItems> = [
        {
            className: 'group-practice-name-column',
            title: 'Group Practice Name',
            dataIndex: 'od_group_practice_name',
            sorter: (a, b) => compareStrings(a['od_group_practice_name'], b['od_group_practice_name']),
            render: (text, record) => <div>{text}</div>
        }, {
            className: 'province-column',
            title: 'Province',
            dataIndex: 'province',
            sorter: (a, b) => compareStrings(a['province'], b['province']),
            render: (text, record) => <div data-testid={`first-name-${record.key}`}>{text}</div>
        }, {
            className: 'action-column',
            title: DOCTORS_LIST_ACTION,
            key: 'action',
            render: (_, record) => (
                <Space>
                    <Tooltip title='Add New Location'>
                        <Button
                            className="edit-doctor-button"
                            onClick={() => onTableRowEditClick(record, 'add')}
                        >
                            <PlusOutlined />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Edit Current Group Practice'>
                        <Button
                            className="edit-doctor-button"
                            onClick={(e) => onTableRowEditClick(record, 'edit')}
                        >
                            <EditOutlined />
                        </Button>
                    </Tooltip>
                </Space>
            ),
        }
    ];
    
    return (
        <div className={'doctor-list-table doctor-list-table-re'}>
            <div className={scrollClass}>
                <Table
                    columns={columns}
                    expandable={{
                        expandedRowRender,
                        defaultExpandedRowKeys: ['0'],
                        expandIcon: ({expanded, onExpand, record}) => (
                            record.locations && Boolean(record.locations.length) ?
                                expanded ? 
                                    <UpCircleOutlined onClick={e => onExpand(record, e)} /> :
                                    <DownCircleOutlined onClick={e => onExpand(record, e)} /> :
                                null
                        ),
                        rowExpandable: (record) => Boolean(record.locations && record.locations.length),
                     }}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 30,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    loading={isLoading}
                    dataSource={groupPracticeList}
                />
            </div>
        </div>
    );
}

export default OdGroupPracticeTable;