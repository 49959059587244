import React from 'react';
import { Layout, Spin } from 'antd';
import { useCare1AppSelector } from '../apps/care1-hooks';

import { OPERATING_TEXT } from '../constants';

import NavigationControlBar from '../components/navigation-control-bar';
import PatientListMenuScience from '../components/science/patient-list-menu-science';
import PatientListTableScience from '../components/science/patient-list-table-sciencce';
import PatientListControlBarScience from '../components/science/patient-list-control-bar-science';

import '../../static/css/pages/science-page.scss';
import '../../static/css/shared/date-picker.scss';

const { Header, Content } = Layout;

const SciencePage: React.FC = () => {

    const patientListOperating = useCare1AppSelector(store => store.patientList.operating);

    return (
        <Layout className='scienceReportPage'>
            <Spin
                className="loading-spinner"
                spinning={patientListOperating}
                size="large"
                tip={OPERATING_TEXT}
            >
                <Header>
                    <NavigationControlBar
                        className="patient-list-navigation-control-bar"
                        breadcrumbItems={[]}
                    />
                </Header>
                <Content>
                    <div className='science-page'>
                        <div>
                        <PatientListMenuScience />
                        </div>
                        <div>
                        <PatientListControlBarScience />
                        </div>
                        <div>
                        <PatientListTableScience />
                        </div>
                    </div>
                </Content>
            </Spin>
        </Layout>
    );
}

export default SciencePage;