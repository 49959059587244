import React from 'react';
import { Layout, Spin } from 'antd';
import { useCare1AppSelector } from '../apps/care1-hooks';

import { OPERATING_TEXT } from '../constants';

import NavigationControlBar from '../components/navigation-control-bar';
import AdminServicesHealthCheckTable from '../components/admin/admin-services-health-check-table';
import { useGetServicesHealthCheckSummaryQuery } from '../services/admin-services-health-check-api';

import '../../static/css/pages/services-health-check-page.scss';

const { Header, Content } = Layout;

const ServicesHealthCheckPage: React.FC = () => {

    const { data: data = {
        health_check_list: {
            summary: {
                rows: [],
                days: []
            }
        }
    }, isFetching: isFetching } = useGetServicesHealthCheckSummaryQuery();
    
    const getTabServicesHealthCheckSummary = () => {
        return (
        <div style={{paddingTop: '1rem'}}>
            <AdminServicesHealthCheckTable
                tableList={data.health_check_list.summary.rows}
                days={data.health_check_list.summary.days}
                title=""
            />
        </div>);
    }
    
    return (
        <Layout className='services-health-check-page'>
            <Spin
                className="loading-spinner"
                spinning={isFetching}
                size="large"
                tip={OPERATING_TEXT}
            >
                <Header>
                    <NavigationControlBar
                        className="patient-list-navigation-control-bar"
                        breadcrumbItems={[]}
                    />
                </Header>
                <Content>
                    <div>
                        {getTabServicesHealthCheckSummary()}
                    </div>
                </Content>
            </Spin>
        </Layout>
    );
}

export default ServicesHealthCheckPage;