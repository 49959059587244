import React from 'react';
import { Table, Row, Col } from 'antd';
import { getOMDNameByID } from '../../helpers/doctor-convert';
import { twoDecimalsNotRounded } from '../../helpers/utilities';
import '../../../static/css/shared/patient-list-table.scss';
import '../../../static/css/components/patients-to-review-table.scss';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import { ColumnsType } from 'antd/es/table';
import { IStandardizedReviewPointsList } from '../../reducers/admin-review-lists-slice';
import { localizedText } from '../../localizedText';

const SRPTable = () => {
    const { ADMIN_STANDARDIZED_REVIEW_POINTS, ADMIN_OMDR, ADMIN_SPECIAL_ATTENTION,    
        ADMIN_RETINA, ADMIN_OTHER, ADMIN_GLCP, ADMIN_GLCBO, ADMIN_GLCBT, ADMIN_GOOD_DISC_GOOD_VF,
        ADMIN_BAD_DISC_GOOD_VF, ADMIN_GOOD_DISC_BAD_VF, ADMIN_BAD_DISC_BAD_VF,
        ADMIN_TOTAL_SRP } = localizedText;
    const tableList = useCare1AppSelector(store => store.adminReviewLists.standardizedReviewPointsList);
    const omds = useCare1AppSelector(store => store.options.omds);
    const patientsToReviewLoading = useCare1AppSelector(store => store.adminReviewLists.patientsToReviewLoading);

    interface IPatientsToReviewTable extends IStandardizedReviewPointsList {
        key: React.Key,
    }

    const columns : ColumnsType<IPatientsToReviewTable>  =
        [
            {
                className: 'omdr',
                title: ADMIN_OMDR,
                dataIndex: 'omdr',
                render: (text) => {
                    return getOMDNameByID(Number(text), omds);
                }
            }, {
                className: 'specialAttention',
                title: ADMIN_SPECIAL_ATTENTION,
                dataIndex: 'specialAttention',
                render: (text) => text || 0
            }, {
                className: 'retina1',
                title: ADMIN_RETINA,
                dataIndex: 'retina1',
                render: (text) => text || 0
            }, {
                className: 'retina2',
                title: ADMIN_OTHER,
                dataIndex: 'retina2',
                render: (text) => text || 0,
            }, {
                className: 'glcP',
                title: ADMIN_GLCP,
                dataIndex: 'glcP',
                render: (text) => text || 0,
            }, {
                className: 'glcBO',
                title: ADMIN_GLCBO,
                dataIndex: 'glcBO',
                render: (text) => text || 0,
            }, {
                className: 'glcBT',
                title: ADMIN_GLCBT,
                dataIndex: 'glcBT',
                render: (text) => text || 0,
            }, {
                className: 'gdgvf',
                title: ADMIN_GOOD_DISC_GOOD_VF,
                dataIndex: 'gdgvf',
                render: (text) => text || 0,
            }, {
                className: 'bdgvf',
                title: ADMIN_BAD_DISC_GOOD_VF,
                dataIndex: 'bdgvf',
                render: (text) => text || 0,
            }, {
                className: 'gdbvf',
                title: ADMIN_GOOD_DISC_BAD_VF,
                dataIndex: 'gdbvf',
                render: (text) => text || 0,
            }, {
                className: 'bdbvf',
                title: ADMIN_BAD_DISC_BAD_VF,
                dataIndex: 'bdbvf',
                render: (text) => text || 0,
            }, {
                className: 'srp',
                title: ADMIN_TOTAL_SRP,
                dataIndex: 'srp',
                render: (text) => twoDecimalsNotRounded(text)
            }
        ]

    return (
        <div className={`patient-list-table srp`}>
            <div className="standardized-review-points-table  patient-list-table-omdr">
                <div className="patient-list-title" data-testid='patient-list-title'>
                    {ADMIN_STANDARDIZED_REVIEW_POINTS}
                </div>
                <Row>
                    <Col span={24}>
                        <Table
                            size='small'
                            columns={columns}
                            dataSource={tableList.map((item, index) => {
                                return { ...item, key: index };
                            })}
                            pagination={false}
                            loading={patientsToReviewLoading}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default SRPTable;