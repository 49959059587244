import React, { useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../static/css/components/admin-gpt-chat-near-miss-list-table.scss';
import { EditOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { compareStrings } from '../../helpers/sorting';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { FIRST_NAME_FIELD, LAST_NAME_FIELD } from '../../constants';
import { localizedText } from '../../localizedText';

interface IGptChatNearMissListTable {
    key: React.Key;
    id: string;
    'field_name': string,
    [x: string]: any,
}

type AppProps = {
    tableList: IGptChatNearMissListTable[],
    days: string[],
    title: string,
}

const AdminGptChatNearMissTable = ({ tableList, days, title }: AppProps) => {
    const { OF_TEXT, ITEMS_TEXT } = localizedText;

    const [scrollClass, setScrollClass] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const nameColumns: ColumnsType<IGptChatNearMissListTable> = [
        {
            className: 'field_name-column',
            title: 'Field Name',
            dataIndex: 'field_name',
            sorter: (a, b) => compareStrings(a['field_name'], b['field_name']),
            render: (text, record) => <div data-testid={`field_name-${record.key}`}>{text}</div>
        }
    ];

    days.forEach((d: string) => {
        nameColumns.push({
            className: `d${d}-column`,
            title: `Last ${d} days`,
            dataIndex: d,
            sorter: (a, b) => { return a[`${d}_accuracy`] - b[`${d}_accuracy`]},
            render: (text, record) => <div data-testid={`${d}-${record.key}`}>{text}</div>
        })
    });

    const renderTitle = () => {
        if (title !== '') {
            return (
                <div>
                <span className='title'>{title} </span>
                <span className='title-tooltip'>
                    <Tooltip
                        className={'recommendations-tooltip'}
                        placement='topLeft'
                        title="For each cumulative period, the accuracy is calculated based on the number of near misses for the field in question, divided by the the number of exams pre-reviewed in that period, excluding exams that do not have a GPT Vision suggested value for the field in question."
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </span>
                </div>);
        }
    }

    return (
        <div className={'gpt-chat-near-miss-list-table'}>
            <div>{renderTitle()}</div>
            <div className={scrollClass}>
                <Table
                    columns={nameColumns}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 50,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    dataSource={tableList}
                />
            </div>
        </div>
    );
}

export default AdminGptChatNearMissTable;