import { Fragment } from 'react';
import { Card, Col } from 'antd';

// Components
import HistoryImagesViewAllModal from './history-images-view-all-modal';
import HistoryImagesSide from './history-images-side';

// Actions
import { lengthEqual } from '../../reducers/patient-exam-slice';

// Helpers
import { getImageHistoryColWidth, getImageHistoryImagesPerRow, getImageHistoryImagesMaxCels,
    } from '../../helpers/image-histories-convert';
import '../../../static/css/shared/images-history-card.scss';
import '../../../static/css/components/_patient-exam-oct-macula-history.scss';
import { localizedText } from '../../localizedText';
import { useCare1AppSelector } from '../../apps/care1-hooks';

type ComponentProps = {
    orderBy: string,
    showHint: boolean,
    showBaselineBorder: boolean,
    showEmptyRow: boolean,
}

const PatientExamOCTMaculaImageHistory = ({orderBy, showHint, showBaselineBorder, showEmptyRow} :ComponentProps) => {
    const { BASELINE_TESTING_IMAGE_LABEL, OCT_MACULA_TEXT, RIGHT_OCT_MACULA_TEXT, LEFT_OCT_MACULA_TEXT } = localizedText;

    const examsWithLeftMacula = useCare1AppSelector(store => store.examData.exams_with_left_oct, lengthEqual);
    const examsWithRightMacula = useCare1AppSelector(store => store.examData.exams_with_right_oct, lengthEqual);

    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);

    // Different platforms have different column widths, images per row and total images displayed
    const COL_WIDTH = getImageHistoryColWidth(userIsRE, userIsOD, userIsOMDC, userIsOMDR, userIsADMIN);
    const IMAGES_PER_ROW = getImageHistoryImagesPerRow(userIsRE, userIsOD, userIsOMDC, userIsOMDR, userIsADMIN);
    const MAX_DISPLAY_CELLS = getImageHistoryImagesMaxCels(userIsRE, userIsOD, userIsOMDC, userIsOMDR, userIsADMIN);

    return (
        <Fragment>
            {/* Left and Right view all Modals */}
            <HistoryImagesViewAllModal
                side={'right'}
                modalTitle={`VIEW ALL OCT MACULA OD`}
                imageType={OCT_MACULA_TEXT}
                photoURL={`right_oct_photo`}
                images={[...examsWithRightMacula]}
                colWidth={COL_WIDTH}
            />

            <HistoryImagesViewAllModal
                side={'left'}
                modalTitle={`VIEW ALL OCT MACULA OS`}
                imageType={OCT_MACULA_TEXT}
                photoURL={`left_oct_photo`}
                images={[...examsWithLeftMacula]}
                colWidth={COL_WIDTH}
            />

            {/* Right side history images */}
            <Col span={12}>
                <Card
                    className={`patient-exam-oct-macula-history images-history-card${
                        examsWithRightMacula.length === 0 && examsWithLeftMacula.length === 0 ? ` no-images` : ''}`}
                    bordered={false}
                >
                    <div className="heading image-section-heading">
                        {RIGHT_OCT_MACULA_TEXT}
                        {showHint && 
                            <span className='baseline-label'>{BASELINE_TESTING_IMAGE_LABEL}</span>
                        }
                    </div>
                    <HistoryImagesSide
                        side={`right`}
                        imageType={OCT_MACULA_TEXT}
                        photoURL={'right_oct_photo'}
                        leftImages={[...examsWithLeftMacula]}
                        rightImages={[...examsWithRightMacula]}
                        colWidth={COL_WIDTH}
                        imagesPerRow={IMAGES_PER_ROW}
                        maxDisplayCells={MAX_DISPLAY_CELLS}
                        viewAllButtonText={`VIEW ALL OCT MACULA OD`}
                        orderBy={orderBy}
                        showBaselineBorder={showBaselineBorder}
                        showEmptyRow={showEmptyRow}
                    />
                </Card>
            </Col>

            {/* Left side history images */}
            <Col span={12}>
                <Card
                    className={`patient-exam-oct-macula-history images-history-card${
                        examsWithRightMacula.length === 0 && examsWithLeftMacula.length === 0 ? ` no-images` : ''}`}
                    bordered={false}
                >
                    <div className="heading image-section-heading">
                        {LEFT_OCT_MACULA_TEXT}
                        {showHint && 
                            <span className='baseline-label'>{BASELINE_TESTING_IMAGE_LABEL}</span>
                        }
                    </div>
                    <HistoryImagesSide
                        side={`left`}
                        imageType={OCT_MACULA_TEXT}
                        photoURL={'left_oct_photo'}
                        leftImages={[...examsWithLeftMacula]}
                        rightImages={[...examsWithRightMacula]}
                        colWidth={COL_WIDTH}
                        imagesPerRow={IMAGES_PER_ROW}
                        maxDisplayCells={MAX_DISPLAY_CELLS}
                        viewAllButtonText={`VIEW ALL OCT MACULA OS`}
                        orderBy={orderBy}
                        showBaselineBorder={showBaselineBorder}
                        showEmptyRow={showEmptyRow}
                    />
                </Card>
            </Col>
        </Fragment>
    );
}

export default PatientExamOCTMaculaImageHistory;