import { Card, Col, Row, Radio } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
// Actions
import { setExamDataValue } from '../../reducers/patient-exam-slice';

import { localizedText } from '../../localizedText';

const PatientExamHighPriority = ({ disabled } : {disabled: boolean}) => {
    const { YES_TEXT, NO_TEXT } = localizedText;

    const dispatch = useCare1AppDispatch();

    const highPriority = useCare1AppSelector(store => store.examData.is_urgent);

    return (
        <Card className='patient-exam-high-priority' bordered={false}>
            <Row>
                <Col className="high-priority-label">
                    <span className="high-priority-title">High Priority</span>
                </Col>
                <Col className="high-priority-radio">
                    <Radio.Group
                        value={highPriority}
                        buttonStyle="solid"
                        size="small"
                        onChange={(e) => {
                            dispatch(setExamDataValue('is_urgent', e.target.value));
                        }}
                    >
                        <Radio.Button disabled={disabled && !highPriority} value={true}>{YES_TEXT.toUpperCase()}</Radio.Button>
                        <Radio.Button disabled={disabled && highPriority} value={false}>{NO_TEXT.toUpperCase()}</Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
        </Card>
    )
}

export default PatientExamHighPriority;
