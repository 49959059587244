import { Card, Checkbox, Col, Row } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { EXAM_STATUS, EXAM_DATE, OMDC_NOTE_STRING, OMD_NOTE_STRING, OMDC_NOTE_STR_ADMIN, OMD_NOTE_STR_ADMIN,
    OD_NAME,
} from '../../constants';
import ConversationBubble from './conversation-bubble';
import PatientExamODOMDMessaging from '../integrated/patient-exam-od-omd-messaging';
import { convertTimeToShortFormatLabel } from '../../helpers/utilities';
import '../../../static/css/components/_patient-exam-conversation.scss';
import { lengthEqual, ReportType } from '../../reducers/patient-exam-slice';
import { localizedText } from '../../localizedText';
import { toggleAdminView, toggleODNotes } from '../../reducers/conversation-history-slice';

type Note = {
    color: string;
    content: string;
}

const PatientExamConversation = ({allowODMessaging = false} : {allowODMessaging?: boolean}) => {
    const { SHOW_ADMIN_VIEW, OD_NOTES_TEXT } = localizedText;
    const exams = useCare1AppSelector(store => store.examData.exams, lengthEqual);
    const isOptometrist = useCare1AppSelector(store => store.user.isOD);
    const conversationHistory = useCare1AppSelector(store => store.conversationHistory);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const patientId = useCare1AppSelector(store => store.examData.patient);
    const isOutboundReferral = useCare1AppSelector(store => store.examData.is_outbound_referral);

    // Determine if the user has the option to display the Show Admin View.
    const userHasAdminViewOption = (userIsOMDR && !userIsRE) || userIsADMIN;

    const dispatch = useCare1AppDispatch();

    const setToggleAdminView = () => {
        dispatch(toggleAdminView(!conversationHistory.showAdminView));
    }

    const setToggleODNotes = () => {
        dispatch(toggleODNotes(!conversationHistory.showODNotes));
    }

    const renderNoteBubbles = () => {
        const conversationHistoryBubbles: JSX.Element[] = [];
        const { showAdminView, showODNotes } = conversationHistory;

        exams && exams.forEach((exam, index) => {

            if (!exam.is_inactive) {
                const examDate = convertTimeToShortFormatLabel(exam[EXAM_DATE]);

                // In Person Consult: string stating the date in which Admin sent an in-person consult request to the OMD
                if(exam['ipc_notes']){
                    const noteIdx = `ipcNotes${index}`;
                    conversationHistoryBubbles.push(<ConversationBubble key={noteIdx} type="ADMIN" side="LEFT"
                        date={examDate} adminNotesText={exam['ipc_notes']} />
                    )
                }

                const omdcReviewDate = exam.omdc_review_date || examDate;
                const omdrReviewDate = exam.omdr_review_date || examDate;

                // For outbound referral exams, we only display the OMD note string itself, and not the full set of text.
                const examOMDReport = isOutboundReferral ? exam['omd_note_string'] : exam['omd_report'];

                // OMDC Notes: String based on the OMDC Consultant notes
                // Option A: without Admin notes
                const omdcDisplayName = exam['omdc_is_outside_licensed_provinces']
                    ? `Dr. Kenman Gan (discussed with Dr. ${exam['omdc_name']})` : `Dr. ${exam['omdc_name']}`;
                if ((!userHasAdminViewOption || !showAdminView) && exam[OMDC_NOTE_STRING]) {
                    const noteIdx = `OMDCNote${index}`;
                    conversationHistoryBubbles.push(<ConversationBubble key={noteIdx} type="OMDC" side="RIGHT"
                        omdNotesText={exam[OMDC_NOTE_STRING] as string} date={omdcReviewDate} omdcName={omdcDisplayName}/>
                    );

                } else if (userHasAdminViewOption && showAdminView && Array.isArray(exam[OMDC_NOTE_STR_ADMIN]) && exam[OMDC_NOTE_STR_ADMIN].length) {
                    // Option B: with Admin notes
                    const noteIdx = `OMDCNoteAdmin${index}`;
                    conversationHistoryBubbles.push(<ConversationBubble key={noteIdx} type="OMDC" side="RIGHT"
                        showAdminView omdNotesText={exam[OMDC_NOTE_STR_ADMIN] as Note[]} date={omdcReviewDate} omdcName={omdcDisplayName}/>);
                }

                // OMDR Notes: String based on the OMDR Notes (constructed on the backend from multiple fields)
                // Option A: without Admin notes
                const omdrDisplayName = exam['omdr_is_outside_licensed_provinces']
                    ? `Dr. Kenman Gan (discussed with Dr. ${exam['omdr_name']})` : `Dr. ${exam['omdr_name']}`;
                if ((!userHasAdminViewOption || !showAdminView) && exam[OMD_NOTE_STRING]) {
                    
                    if (!isOptometrist || exam[EXAM_STATUS] === 'reviewed') {
                        if (exam['report_type'] === ReportType.AI_AND_OMD) {
                            const omdReportKey = `OMDReport${index}`;
                            const aiReportKey = `aiReport${index}`;
                            conversationHistoryBubbles.push(
                                <ConversationBubble
                                    key={aiReportKey}
                                    type="AI_REPORT"
                                    side="RIGHT"
                                    aiReportText={exam['ai_report']}
                                    date={omdrReviewDate}
                                />);
                            conversationHistoryBubbles.push(
                                <ConversationBubble
                                    key={omdReportKey}
                                    type="OMD_REPORT"
                                    side="RIGHT"
                                    omdrName={omdrDisplayName}
                                    omdReportText={examOMDReport}
                                    date={omdrReviewDate}
                                    odWantsOmdReport={exam['od_wants_omd_report']}
                                    examIsValidationReview={exam['exam_is_validation_review']}
                                />);
                        
                        } else if (exam['report_type'] === ReportType.OMD_ONLY){
                            const omdReportKey = `OMDReport${index}`;
                            conversationHistoryBubbles.push(
                                <ConversationBubble
                                    key={omdReportKey}
                                    type="OMD_REPORT"
                                    side="RIGHT"
                                    omdrName={omdrDisplayName}
                                    omdReportText={examOMDReport}
                                    date={omdrReviewDate}
                                    odWantsOmdReport={exam['od_wants_omd_report']}
                                    examIsValidationReview={exam['exam_is_validation_review']}
                                />);

                        } else {
                            const noteIdx = `OMDNote${index}`;
                            conversationHistoryBubbles.push(
                                <ConversationBubble
                                    key={noteIdx}
                                    type="OMDR"
                                    side="RIGHT"
                                    omdrName={omdrDisplayName}
                                    omdNotesText={exam[OMD_NOTE_STRING]}
                                    date={omdrReviewDate}
                                    odWantsOmdReport={exam['od_wants_omd_report']}
                                    examIsValidationReview={exam['exam_is_validation_review']}
                                />);
                        }
                        
                    }
                } else if (userHasAdminViewOption && showAdminView && exam[OMD_NOTE_STR_ADMIN] && exam[OMD_NOTE_STR_ADMIN].length) {
                    if(exam[OMD_NOTE_STR_ADMIN].length === 1 && exam[OMD_NOTE_STR_ADMIN][0].content === ''){
                        // Do not display bubble if there is only one entry and it has empty content
                    } else {
                        // Option B: with Admin notes
                        const noteIdx = `OMDNoteAdmin${index}`;
                        conversationHistoryBubbles.push(<ConversationBubble key={noteIdx} type="OMDR" side="RIGHT"
                            showAdminView omdrName={omdrDisplayName} omdNotesText={exam[OMD_NOTE_STR_ADMIN]} date={omdrReviewDate}/>
                        );
                    }
                }

                if (exam.exam_impression) {
                    conversationHistoryBubbles.push(
                        <ConversationBubble
                            key={`impression${index}`}
                            type="OD"
                            side="LEFT"
                            odName={`${exam[OD_NAME]} impressions`}
                            isProposedPlan
                            odProposedPlan={exam.exam_impression}
                            date={examDate}
                        />
                    );
                }


                // OD Notes: String based on the OD Notes and proposed plan
                if(showODNotes) {
                    // If exam was uploaded by RE then it will have history_and_exam data
                    if (exam['history_and_exam']) {
                        const noteIdx = `ODExamHistoryNote${index}`;
                        conversationHistoryBubbles.push(<ConversationBubble key={noteIdx} type="OD" side="LEFT"
                            odName={exam[OD_NAME] as string} odOMDText={exam['history_and_exam'] as string} date={examDate} />);
                    } else if (exam['is_outbound_referral'] && exam['exam_status'] !== 'not_ready') {
                         // Otherwise display the OD Notes and Proposed Plan field uploaded by IC
                         const noteIdx = `ODPlan${index}`;
                         const odProposedPlan = `(Outbound Referral Request) ${exam['od_omd']}`
                         conversationHistoryBubbles.push(
                             <ConversationBubble key={noteIdx} type="OD" side="LEFT" odName={exam[OD_NAME] as string} isProposedPlan
                                 odProposedPlan={odProposedPlan} date={examDate}
                             />
                         );

                    } else if (exam['od_omd']) {
                        // Otherwise display the OD Notes and Proposed Plan field uploaded by IC
                        const noteIdx = `ODPlan${index}`;
                        conversationHistoryBubbles.push(
                            <ConversationBubble key={noteIdx} type="OD" side="LEFT" odName={exam[OD_NAME] as string} isProposedPlan
                                odProposedPlan={exam['od_omd'] as string} date={examDate}
                            />
                        );
                    }
                }
            }
        });


        return conversationHistoryBubbles;
    }

    return (
        <Card className={"patient-exam-conversation"} bordered={false}>
            <Row className="conversation-header">
                <Col span={18} className="heading">Conversation Log</Col>
                <Col span={6}>
                    <Checkbox checked={conversationHistory.showODNotes} onChange={setToggleODNotes}>
                        {OD_NOTES_TEXT}
                    </Checkbox>
                    { (userIsADMIN || (userIsOMDR && !userIsRE)) &&
                        <Checkbox checked={conversationHistory.showAdminView} onChange={setToggleAdminView}>
                            {SHOW_ADMIN_VIEW}
                        </Checkbox>
                    }
                </Col>
            </Row>
            { allowODMessaging &&
                <Row>
                    <PatientExamODOMDMessaging patientId={Number(patientId)!}/>
                </Row>
            }
            <Row className="conversation-bubbles" gutter={[0, 12]}>
                {renderNoteBubbles()}
            </Row>
        </Card>
    );
}

export default PatientExamConversation;