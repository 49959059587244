import { useDispatch } from 'react-redux';
import { Col, Popconfirm, Row, Select } from 'antd';
import { IUntilYesterdayItem, deleteUntilYesterdayEntry, editUntilYesterdayEntry } from '../../reducers/patient-exam-slice';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

const { Option } = Select;

type ComponentProps = {
    eyeTypes: string[][],
    index: number,
    data: IUntilYesterdayItem,
    glcDropsList: string[][],
    size: SizeType,
}

const PatientExamUntilYesterdayEntryReferralLetter = ({eyeTypes, index, data,
    glcDropsList, size}: ComponentProps) => {

    const dispatch = useDispatch();

    const handleChange = (field: keyof IUntilYesterdayItem, value: string) => {
        dispatch(editUntilYesterdayEntry({
            editIndex: index, field, value
        }));
    }

    const handleSelect = (value: string, field: keyof IUntilYesterdayItem) => {
        dispatch(editUntilYesterdayEntry({
            editIndex: index, field, value
        }));
    }

    // when all the fields are empty/false, isAllEntriesEmpty evaluates to true
    const isAllEntriesEmpty = !(data && (data.glc_past_drops_eye_select || data.glc_past_drops_select));

    const glcDropClassName = isAllEntriesEmpty ? '' :
                                (data && data.glc_past_drops_select === '' && data.glc_past_drops_eye_select !== '')
                                ? 'red-border' : '';

    return (
        <Row gutter={5} className="div-tag-entry">
            <Col span={11}>
                <Select
                    showSearch
                    size={size}
                    onChange={(val) => handleChange('glc_past_drops_select', val)}
                    value={(data && data.glc_past_drops_select && data.glc_past_drops_select === '') ? null : data.glc_past_drops_select}
                    className={glcDropClassName}
                >
                    {glcDropsList && glcDropsList.map((option) => (
                        <Option
                            key={option[1]}
                            value={option[0]}
                        >
                            {option[1]}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col span={5}>
                <Select
                    showSearch
                    size={size}
                    onSelect={(value) => handleSelect(value, 'glc_past_drops_eye_select' )}
                    value={(data && data.glc_past_drops_eye_select && data.glc_past_drops_eye_select === '') ? null : data.glc_past_drops_eye_select}
                >
                    {eyeTypes && eyeTypes.map((option) => (
                        <Option
                            rel="glc_past_drops_eye_select"
                            key={option[1]}
                            value={option[0]}
                        >
                            {option[1]}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col span={1} className="delete">
                <Popconfirm title="Are you sure？" onConfirm={() => { dispatch(deleteUntilYesterdayEntry(index)); }}>X</Popconfirm>
            </Col>
        </Row>
    );
}
export default PatientExamUntilYesterdayEntryReferralLetter;