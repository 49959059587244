import { Button, Col, Popover, Row } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { getLetterRequest, sendFaxRequest, setLetterDataValue, toggleLetterModal } from '../../reducers/letters-slice';
import '../../../static/css/components/admin-letter-dropdown.scss';
import { localizedText } from '../../localizedText';
import PatientHandoutButton from '../retina/header/patient-handout-button';

type adminLetterOptions = {
    label: string,
    modalOpen: boolean,
    fax: boolean,
    key: string,
};

const AdminLetterDropdown = () => {
    const { GENERATE_LETTERS } = localizedText;
    const md2FaxNumber = useCare1AppSelector(store => store.patientDetails.details.gp2_number);
    const examId = useCare1AppSelector(store => store.examData.id);
    const isReferralLetterUploadPei = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const isExamOdEssentialsOnly = useCare1AppSelector(store => store.examData.exam_od_essentials_only);
    const rluExamPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);

    const dispatch = useCare1AppDispatch();

    const odEsDisabledKeys = ['omdrgp', 'omdrgp2', 'omdcgp', 'omdcgp2'];

    const md2DisabledKeys = ['omdrgp2', 'omdcgp2', 'omdrgpenabled2', 'rluomdrgp2'];

    const isDisabled = (key: string) => (!md2FaxNumber && md2DisabledKeys.includes(key)) ||
        (isExamOdEssentialsOnly && odEsDisabledKeys.includes(key));

    let options: adminLetterOptions[] = isReferralLetterUploadPei && rluExamPremium ?
    [{
        // RLU letter options are only for premium RLU.
        label: 'RLU OMDR-GP', fax: true, modalOpen: false, key: 'rluomdrgp' }, {
        label: 'RLU OMDR-MD2', fax: true, modalOpen: false, key: 'rluomdrgp2' }, {
        label: 'RLU OMDC-GP', fax: true, modalOpen: false, key: 'rluomdcgp' }, {
        label: 'RLU OMDC-MD2', fax: true, modalOpen: false, key: 'rluomdcgp2' }, {
        label: 'RLU OMDR-IGP', fax: true, modalOpen: false, key: 'rluomdrintgp' },{
        label: 'RLU OMD', fax: false, modalOpen: true, key: 'rlu_omd'}, {
        label: 'RLU OMDSX',fax: false, modalOpen: true, key: 'rluomdsx' },
    ] :
    [{
        // In addition to legacy exams, standard RLU also uses these options.
        label: 'OMDR-GP', fax: true, modalOpen: false, key: 'omdrgp' }, {
        label: 'OMDR-MD2', fax: true, modalOpen: false, key: 'omdrgp2' }, {
        label: 'OMDR-GP(RE)', fax: true, modalOpen: false, key: 'omdrgpenabled' }, {
        label: 'OMDR-MD2(RE)', fax: true, modalOpen: false, key: 'omdrgpenabled2' }, {
        label: 'OMDR-IGP', fax: true, modalOpen: false, key: 'omdrintgp' }, {
        label: 'OMDR-IGP(RE)', fax: true, modalOpen: false, key: 'omdrintgpenabled' }, {
        label: 'OMDC-GP', fax: true, modalOpen: false, key: 'omdcgp' }, {
        label: 'OMDC-MD2', fax: true, modalOpen: false, key: 'omdcgp2' }, {
        label: 'OD-OMDR',fax: false, modalOpen: false, key: 'odomdr' }, {
        label: 'OD-OMDC',fax: false, modalOpen: true, key: 'odomdc' }, {
        label: 'OMDR-OMDC',fax: false, modalOpen: true, key: 'omdrc' }, {
        label: 'OMDR-OMDC Cat',fax: false, modalOpen: true, key: 'omdrccat' }, {
        label: 'OD-OMDC Cat',fax: false, modalOpen: true, key: 'odomdccat' },
    ];

    if (isReferralLetterUploadPei && !rluExamPremium) {
        // For standard RLU, remove some letter options.
        options = options.filter(letterOption => letterOption.key !== 'omdrgp' && 
            letterOption.key !== 'omdrgp2' && letterOption.key !== 'omdrintgp' &&
            letterOption.key !== 'omdcgp' && letterOption.key !== 'omdcgp2');
    }

    const handleClick = (key: string, modalOpen: boolean, label: string) => {
        if(modalOpen) {
            // some options require OMDC modal, email/fax request will be handled in that modal
            dispatch(toggleLetterModal());
            dispatch(setLetterDataValue({key:'currentLetterType', value: key}));
            dispatch(setLetterDataValue({key:'currentLetterLabel', value: label}));
        } else {
            // generate document request
            dispatch(getLetterRequest(key));
        }
    };

    const handleFax = (key: string) => {
        // Fax
        dispatch(sendFaxRequest({examId: examId!, type: key}));
    }

    const content = (
        <div>
            {options.map(({label, fax, modalOpen, key}) =>{
                return (
                    <Row key={key} justify='end' className='option-row'>
                        <Button
                            className={`label-button${fax ? ' with-fax' : ' without-fax'}`}
                            onClick={() => handleClick(key, modalOpen, label)}
                            disabled={isDisabled(key)}
                        >
                            {label}
                        </Button>
                        {fax &&
                        <Button
                            className='fax-button'
                            onClick={() => handleFax(key)}
                            disabled={isDisabled(key)}
                        >
                            FAX
                        </Button>}
                    </Row>)
            })}
            <Row key={'patient-handout-button'} className='option-row'>
                <Col span={24}>
                    <PatientHandoutButton />
                </Col>
            </Row>
        </div>
    )

    return(
        <Popover
            title={null}
            content={content}
            trigger='click'
            placement='bottom'
            overlayClassName='admin-letter-dropdown'
        >
            <Button className='generate-letter-button' type='primary'>{GENERATE_LETTERS}</Button>
        </Popover>
    )
}

export default AdminLetterDropdown;