import { IGlaucomaScoreGraphData } from '../reducers/patient-exam-slice';

export const calcIopScore = (iop: string) => {
    const iopNumber = parseInt(iop);
    if (iopNumber >= 0 && iopNumber < 10) {
        return 1;
    }
    else if (iopNumber >= 10 && iopNumber < 15) {
        return 3;
    }
    else if (iopNumber >= 15 && iopNumber < 20) {
        return 4;
    }
    else if (iopNumber >= 20 && iopNumber < 25) {
        return 7;
    }
    else if (iopNumber >= 25 && iopNumber < 30) {
        return 8;
    }
    else if (iopNumber >= 30) {
        return 9;
    }
    return null;
}

export const calcOctavScore = (octav: string) => {
    if (octav === 'green') {
        return 2;
    }
    else if (octav === 'yellow') {
        return 5;
    }
    else if (octav === 'red') {
        return 8;
    }
    return null;
}

export const calcGhtScore = (ght: string) => {
    if (ght === 'wnl') {
        return 2;
    }
    else if (ght === 'grs' || ght === 'b-grs' || ght === 'b' || ght === 'ahs') {
        return 5;
    }
    else if (ght === 'onl') {
        return 8;
    }
    return null;
}

export const calcOverallScore = (side: string, data: IGlaucomaScoreGraphData) => {
    let validItemCount = 0;
    let overallScore = 0;

    const odIop = data.od_iop;
    const osIop = data.os_iop;

    const rightOctav = data.right_octav;
    const leftOctav = data.left_octav;

    const rightGht = data.right_ght;
    const leftGht = data.left_ght;

    const iop = side === 'right' ? odIop : osIop;
    const octav = side === 'right' ? rightOctav : leftOctav;
    const ght = side === 'right' ? rightGht : leftGht;       
    
    const iopScore = calcIopScore(iop);
    if (iopScore !== null) {
        validItemCount += 1;
        overallScore += iopScore;
    }

    const octavScore = calcOctavScore(octav);
    if (octavScore !== null) {
        validItemCount += 1;
        overallScore += octavScore;
    }

    const ghtScore = calcGhtScore(ght);
    if (ghtScore !== null) {
        validItemCount += 1;
        overallScore += ghtScore;
    }

    if (validItemCount > 0) {
        return overallScore / validItemCount;
    }
    else {
        return null;
    }
}
