import { useEffect, useState } from 'react';
import { Table, Space, Button, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../static/css/components/admin-doctor-list-table.scss';
import { EditOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { compareStrings } from '../../helpers/sorting';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { FIRST_NAME_FIELD, LAST_NAME_FIELD } from '../../constants';
import { IStaffDetails, useDeleteStaffMutation, useGetStaffListQuery } from '../../services/staff-api';
import { setCurrentStaffId, setCurrentUsername, setIsSalesUser, setIsScienceUser, setShowStaffEditModal,
    setStaffSearchQuery } from '../../reducers/doctor-slice';
import { localizedText } from '../../localizedText';


interface IStaffListSearch {
    username: string;
    first_name: string;
    last_name: string;
}

const AdminStaffListTable = () => {
    const { DOCTORS_LIST_ACTION, FIRST_NAME_HEADING, LAST_NAME_HEADING, 
         OF_TEXT, 
        ITEMS_TEXT, USERNAME_HEADING } = localizedText;
    const searchQuery:string = useCare1AppSelector(store => store.doctorSlice.staffSearchQuery);

    const [deleteStaff] = useDeleteStaffMutation();
    const { data } = useGetStaffListQuery();

    const dispatch = useCare1AppDispatch();
    
    const [scrollClass, setScrollClass] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    useEffect(() => {
        // cleanup function
        return () => {
            setStaffSearchQuery('');
        }
    }, [])
    
    const staffList = data ? data.filter(({username, first_name, last_name}: IStaffListSearch) => (
        username.toLowerCase().includes(searchQuery) ||
        first_name.toLowerCase().includes(searchQuery) ||
        last_name.toLowerCase().includes(searchQuery)
    )) : [];

    const onTableRowEditClick = (record: IStaffDetails) => {

        dispatch(setCurrentStaffId(record.key));
        dispatch(setCurrentUsername(record.username));
        dispatch(setShowStaffEditModal(true));
        if (record.user_type === 'Sales') {
            dispatch(setIsSalesUser(true));
        }
        else if (record.user_type === 'Science') { 
            dispatch(setIsScienceUser(true));
        }
    }

    const onDeleteClick = (record: IStaffDetails) => {
        Modal.confirm({
            className: 'delete-doctor-confirmation-modal',
            icon: <InfoCircleOutlined />,
            title: 'Deleting an user will remove all their information. Are you sure you want to delete this user?',
            onOk: async () => {
                try {
                    const result = await deleteStaff(record.key).unwrap();
                    if (result.success) {
                        Modal.info({
                            className: 'info-modal',
                            title: 'User is successfully deleted.',
                        });
                    } else {
                        throw new Error(result?.error);
                    }
                } catch (error) {
                    const message = (error instanceof Error) ?  error?.message : error;
                    Modal.error({
                        className: 'info-modal',
                        content: message as string,
                        title: 'Error',
                    });
                }
                
            },
        })
    }

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const columns : ColumnsType<IStaffDetails> = [{
        className: 'l-name-column',
        title: LAST_NAME_HEADING,
        dataIndex: LAST_NAME_FIELD,
        sorter: (a, b) => compareStrings(a[LAST_NAME_FIELD], b[LAST_NAME_FIELD]),
        render: (text, record) => <div data-testid={`last-name-${record.key}`}>{text}</div>
    }, {
        className: 'f-name-column',
        title: FIRST_NAME_HEADING,
        dataIndex: FIRST_NAME_FIELD,
        sorter: (a, b) => compareStrings(a[FIRST_NAME_FIELD], b[FIRST_NAME_FIELD]),
        render: (text, record) => <div data-testid={`first-name-${record.key}`}>{text}</div>
    }, {
        className: 'user-column',
        title: USERNAME_HEADING,
        dataIndex: 'username',
        sorter: (a, b) => compareStrings(a['username'], b['username']),
        render: (text, record) => <div data-testid={`username-${record.key}`}>{text}</div>
    }, {
        className: 'user-type-column',
        title: 'User Type',
        dataIndex: 'user_type',
        sorter: (a, b) => compareStrings(a['user_type'], b['user_type']),
        render: (text, record) => <div data-testid={`user_type-${record.key}`}>{text}</div>
    }, {
        className: 'status-column',
        title: 'Status',
        dataIndex: 'status',
        sorter: (a, b) => compareStrings(a['status'], b['status']),
        render: (text, record) => <div data-testid={`status-${record.key}`}>{text}</div>
    },{
        className: 'action-column',
        title: DOCTORS_LIST_ACTION,
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Button
                    className="edit-doctor-button"
                    onClick={(e) => onTableRowEditClick(record)}
                >
                    <EditOutlined />
                </Button>
                <Button
                    className='delete-doctor-button'
                    onClick={(e) => onDeleteClick(record)}
                >
                    <DeleteOutlined />
                </Button>
            </Space>
        ),
    },];

    return (
        <div className={'doctor-list-table doctor-list-table-re'}>
            <div className={scrollClass}>
                <Table
                    columns={columns}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 30,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    dataSource={staffList}
                    tableLayout='fixed'
                />
            </div>
        </div>
    );
}

export default AdminStaffListTable;