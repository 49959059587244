import { useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Tag, Row, Form } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { setOverrideActions, setPreReviewDataValue } from '../../reducers/pre-review-slice';
import { localizedText } from '../../localizedText';
import { FieldData } from 'rc-field-form/lib/interface';
import '../../../static/css/components/patient-exam-fu-with-omd.scss';

const { CheckableTag } = Tag;

const PatientExamFollowUpWithOMD = () => {
    const { OMD_FOLLOWUP_OD_WILL_SEE, OMD_FOLLOWUP_APPT, FOLLOWUP_TEXT } = localizedText;
    const selectedOmdNoteOptions = useCare1AppSelector(store => store.examData.selected_omd_note_options);
    const fuNumber = useCare1AppSelector(store => store.examData.fu_number);
    const fuLetter = useCare1AppSelector(store => store.examData.fu_letter);
    const userIsAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const isFuUnknown = useCare1AppSelector(store => store.examData.is_fu_unknown);

    const [form] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    const TELE_FU = 15;
    const OMD_FU = 16;

    let fuButtonChecked: boolean = Boolean(selectedOmdNoteOptions.find(element => element === OMD_FU));

    useEffect(() => {
        if (!fuButtonChecked && !selectedOmdNoteOptions.find(element => element === TELE_FU)) {
            const arr = selectedOmdNoteOptions ? [...selectedOmdNoteOptions] : [];
            arr.push(TELE_FU);
            dispatch(setExamDataValue('selected_omd_note_options', arr));
        }
    }, [selectedOmdNoteOptions, dispatch, fuButtonChecked])


    // Ant Design handler. This is called whenever a field is changed by the user using Ant Design controls.
    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case 'selected_omd_note_options_fu':
                        if (field.value) {
                            // If the button is selected than it is the same OMD Note Template as selecting the Legacy OMD F/U
                            const arr = selectedOmdNoteOptions ? [...selectedOmdNoteOptions] : [];
                            for (let i = 0; i < arr.length; i++) {
                                if (arr[i] === TELE_FU) {
                                    arr.splice(i, 1);
                                }
                            }
                            arr.push(OMD_FU);
                            dispatch(setExamDataValue('selected_omd_note_options', arr));
                            userIsAdmin && dispatch(setOverrideActions(['OMD F/U', true]));
                            userIsAdmin && dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
                        }
                        else {
                            // If the button is not selected when the exam is submitted than it is the same OMD Note Template as selecting Legacy Tele F/U
                            const arr = selectedOmdNoteOptions ? [...selectedOmdNoteOptions] : [];
                            for (let i = 0; i < arr.length; i++) {
                                if (arr[i] === OMD_FU) {
                                    arr.splice(i, 1);
                                }
                            }
                            arr.push(TELE_FU);
                            dispatch(setExamDataValue('selected_omd_note_options', arr));
                            userIsAdmin && dispatch(setOverrideActions(['Tele F/U', true]));
                            userIsAdmin && dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
                        }
                        break;
                    default:
                        break;
                }
            }
        });
    }
    const timeTextMap = fuLetter === 'week' ?
        { short: 'wk.', long: 'weeks.' }
        :
        { short: 'mo.', long: 'months.' }

    const followUpText = ` ${fuNumber} ${userIsAdmin ? timeTextMap.short : timeTextMap.long}`;
    return (
        <Card className={'fu-with-omd'} bordered={false}>
            <Row>
                <div className="heading">{FOLLOWUP_TEXT}</div>
            </Row>
            <div className='component-body'>
                <Row>
                    {
                        isFuUnknown && !userIsAdmin ?
                        <div className="gp-letter-date">Next appointment unspecified</div>
                        :
                        <>
                            <div className="gp-letter-date">{OMD_FOLLOWUP_OD_WILL_SEE}</div>
                            <div className='months-suffix' data-testid='gp-letter-date'>{followUpText}</div>
                        </>
                    }
                    
                </Row>
                <Row>
                    <Form form={form} onFieldsChange={onFieldsChange}>
                        <Form.Item help={''} name={'selected_omd_note_options_fu'}>
                            <CheckableTag
                                data-testid='selected-omd-note-options-fu'
                                checked={fuButtonChecked}
                            >
                                {OMD_FOLLOWUP_APPT}
                            </CheckableTag>
                        </Form.Item>
                    </Form>
                </Row>
            </div>
        </Card>
    );
}

export default PatientExamFollowUpWithOMD;