import { useMemo, useEffect, useState } from 'react';
import { Form,  Input, Select, FormInstance, Row, Col } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { getBackendMediaUrl } from '../../helpers/media-image-convert';
import '../../../static/css/components/od-group-practice-modal.scss';

// Action imports
import { useCare1AppSelector } from '../../apps/care1-hooks';
import InputMask from 'react-input-mask';
import { ERROR_INVALID_PHONE_FORMAT, PHONE_MASK_FORMAT } from '../../constants';
import { IClinicLocationDetails, useGetClinicLocationDetailQuery } from '../../services/od-group-practice-api';

type AppProps = {
    onFinish: (values: IClinicLocationDetails) => Promise<void>,
    form: FormInstance,
    edit: boolean,
}

const ClinicLocationForm = ({onFinish, form, edit}: AppProps) => {

    const showClinicLocationEditModal = useCare1AppSelector(store => store.doctorSlice.showClinicLocationEditModal);
    const provinces = useCare1AppSelector(store => store.options.provinces);
    const currentClinicLocationId = useCare1AppSelector(store => store.doctorSlice.currentClinicLocation);
    const clinicLocationHeaders = useCare1AppSelector(store => store.options.clinicLocationHeaders);

    const skipQuery = currentClinicLocationId && showClinicLocationEditModal ? false : true;

    const {data} = useGetClinicLocationDetailQuery(currentClinicLocationId!, {skip: skipQuery});

    const provinceOptions = useMemo(() =>
        provinces.map(({code, name }) => ({value: code, label: name, key: code }))
    , [provinces])

    const clinicLocationHeaderOptions = useMemo(() =>
        clinicLocationHeaders.map(({image_file_name, image_file_path}) => ({value: image_file_path, label: image_file_name, key: image_file_path}))
        , [clinicLocationHeaders])

    const getFullUrlPath = (url: string | undefined) => {
        return url ? getBackendMediaUrl() + url : '';
    }

    const [clinicLocationFullUrlPath, setClinicLocationFullUrlPath] = useState('');

    useEffect(() => {
        if (data) {
            const {key, od_group_practice_id, ...formData} = data;
            form.setFieldsValue({
                ...formData,
            });
        }
        
    }, [data, form])

    useEffect(() => {
        if (data?.logo_url) {
            setClinicLocationFullUrlPath(getFullUrlPath(data.logo_url));
        }
    }, [data?.logo_url])

    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case 'logo_url':
                        setClinicLocationFullUrlPath(getFullUrlPath(field.value));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    return (
        <Form
            form={form}
            labelCol={{span:5}}
            wrapperCol={{span: 18}}
            className='od-group-practice-form'
            colon={false}
            requiredMark={false}
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
        >
            { edit ?
                <Row>
                    <Col span={2}/>
                    <Col span={15}>
                        <Form.Item
                            label='Name'
                            name='name'
                            initialValue={''}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label='ID'
                            name='location_id'
                            initialValue={currentClinicLocationId}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                :
                <Form.Item
                    label='Name'
                    name='name'
                    initialValue={''}>
                    <Input />
                </Form.Item>
            }
            <Form.Item
                label='Full Name'
                name='full_name'
                initialValue={''}>
                <Input />
            </Form.Item>
            <Form.Item label='Address' name='address' initialValue={''}>
                <Input />
            </Form.Item>
            <Form.Item label='City' name='city' initialValue={''}>
                <Input />
            </Form.Item>
            <Form.Item label='Province' name='province' initialValue={null}>
                <Select
                    showSearch
                    allowClear
                    optionFilterProp='label'
                    options={provinceOptions}
                />
            </Form.Item>
            <Form.Item label='Postal Code' name='postal_code' initialValue={''}>
                <Input />
            </Form.Item>
            <Form.Item
                    label={'Phone Number'}
                    hasFeedback
                    name={'phone_number'}
                    validateTrigger={'onBlur'}
                    rules={[{
                        pattern: /^[^_]+$/,
                        message: ERROR_INVALID_PHONE_FORMAT,
                    }]}
                    initialValue={''}
                >
                    <InputMask
                        data-testid='fax-input'
                        className={'phone-number-input ant-input'}
                        mask={PHONE_MASK_FORMAT}
                    />
                </Form.Item>
            
            <Form.Item
                label={'Fax'}
                hasFeedback
                name={'fax'}
                validateTrigger={'onBlur'}
                rules={[{
                    pattern: /^[^_]+$/,
                    message: ERROR_INVALID_PHONE_FORMAT,
                }]}
                initialValue={''}
            >
                <InputMask
                    data-testid='fax-input'
                    className={'fax-number-input ant-input'}
                    mask={PHONE_MASK_FORMAT}
                />
            </Form.Item>

            { edit &&
                <div>
                    <Form.Item label='Clinic Logo' name='logo_url' initialValue={null}>
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp='label'
                            options={clinicLocationHeaderOptions}
                        />
                    </Form.Item>
                    { clinicLocationFullUrlPath &&
                        <div className='clinic-logo'>
                            <img
                                alt='logo preview'
                                className='clinic-logo-img'
                                src={clinicLocationFullUrlPath}
                            />
                        </div>
                    }
                </div>
            }
        </Form>
    );
}

export default ClinicLocationForm;
