import { Button, Col, Modal, Row, Spin } from 'antd';
import { useState } from 'react';
import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { OPERATING_TEXT } from '../../../constants';
import {
    IUploadReconcilerAddPatientRequest,
    IUploadReconcilerMatchPatientsRequest,
    IUploadReconcilerMatchPatientsRequestItem,
    useAddReconcilerPatientPatientMutation,
    useLazyGetUploadReconcilerPatientMatchingListQuery,
    useMatchReconcilerPatientPatientsMutation
} from '../../../services/upload-reconciler-api';
import PatientMatchingListTable from './patient-matching-list-table';

import dayjs from 'dayjs';
import '../../../../static/css/components/patient-select-modal.scss'; // Styling Audit - New modal stylesheet

const PatientReconciliation = () => {
    const province = useCare1AppSelector(
        (store) => store.uploadReconciler.province
    );
    const gp = useCare1AppSelector((store) => store.smartUpload.gp);
    const gpFax = useCare1AppSelector((store) => store.smartUpload.gp_fax);
    const dobYear = useCare1AppSelector(
        (store) => store.uploadReconciler.dob_year
    );
    const dobMonth = useCare1AppSelector(
        (store) => store.uploadReconciler.dob_month
    );
    const dobDay = useCare1AppSelector(
        (store) => store.uploadReconciler.dob_day
    );
    const firstName = useCare1AppSelector(
        (store) => store.uploadReconciler.first_name
    );
    const lastName = useCare1AppSelector(
        (store) => store.uploadReconciler.last_name
    );
    const phn = useCare1AppSelector((store) => store.uploadReconciler.phn);
    const optometristODgrouppracticeName = useCare1AppSelector(
        (store) => store.smartUpload.odgrouppractice
    );
    const odAccount = useCare1AppSelector(
        (store) => store.uploadReconciler.od_account
    );
    const insuranceType = useCare1AppSelector(
        (store) => store.uploadReconciler.insurance_type
    );
    const primaryPhone = useCare1AppSelector(
        (store) => store.uploadReconciler.primary_phone
    );

    const [addReconcilerPatientPatient, { isLoading: isAddPatientLoading }] =
        useAddReconcilerPatientPatientMutation();

    const [
        matchReconcilerPatientPatients,
        { isLoading: isPatientMatchLoading },
    ] = useMatchReconcilerPatientPatientsMutation();

    const [
        getUploadReconcilerPatientMatchingListQuery,
        {
            data: patientMatchingList = [],
            isFetching: isPatientMatchingListFetching,
        },
    ] = useLazyGetUploadReconcilerPatientMatchingListQuery();


    const [patientMatchingOps, setPatientMatchingOps] = useState<{
        [v: number]: string;
    }>({});

    const reMatch = async () => {
        if (
            dobYear &&
            dobMonth &&
            dobDay &&
            firstName !== null &&
            lastName !== null
        ) {
            const dob = dayjs(
                `${dobDay} ${dobMonth} ${dobYear}`,
                'D MMMM YYYY'
            );

            await getUploadReconcilerPatientMatchingListQuery({
                dob: dob.format('YYYY-MM-DD'),
                first_name: firstName,
                last_name: lastName,
                insurance: phn,
                od_group_practice_name: optometristODgrouppracticeName,
            });
        }
    };

    const createNewPatient = async () => {
        if (
            odAccount &&
            dobYear &&
            dobMonth &&
            dobDay &&
            firstName !== null &&
            lastName !== null &&
            insuranceType
        ) {
            const patientData: IUploadReconcilerAddPatientRequest = {
                first_name: firstName,
                last_name: lastName,
                province: province,
                phn: phn,
                dob: dayjs(
                    `${dobDay} ${dobMonth} ${dobYear}`,
                    'D MMMM YYYY'
                ).format('YYYY-MM-DD'),
                primary_phone: primaryPhone,
                insurance_type: insuranceType,
                od_id: odAccount,
                gp_id: gp,
                gp_fax_number: gpFax,
            };

            try {
                const response = await addReconcilerPatientPatient(
                    patientData
                ).unwrap();
                if (response.success) {
                    await reMatch();
                } else {
                    throw new Error(response?.error);
                }
            } catch (error) {
                const message = error instanceof Error ? error?.message : error;
                Modal.error({
                    className: 'info-modal',
                    content: message as string,
                    title: 'Error',
                });
            }
        }
    };

    const mergePatients = async () => {
        const items: IUploadReconcilerMatchPatientsRequestItem[] = [];
        for (const [key, value] of Object.entries(patientMatchingOps)) {
            items.push({
                id: Number(key),
                op: value,
            });
        }

        const patientData: IUploadReconcilerMatchPatientsRequest = {
            items: items,
        };

        try {
            const response = await matchReconcilerPatientPatients(
                patientData
            ).unwrap();
            if (response.success) {
                setPatientMatchingOps({});
                await reMatch();
            } else {
                throw new Error(response?.error);
            }
        } catch (error) {
            const message = error instanceof Error ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };


    const shouldDisableMergingButton = () => {
        let targetCount = 0;
        let deleteCount = 0;
        for (const [key, value] of Object.entries(patientMatchingOps)) {
            if (value === 'target') {
                targetCount++;
            }
            if (value === 'delete') {
                deleteCount++;
            }
        }

        if (targetCount === 1 && deleteCount >= 1) {
            return false;
        }
        return true;
    };

    return (
        <Spin
            className='loading-spinner'
            spinning={
                isAddPatientLoading ||
                isPatientMatchLoading ||
                isPatientMatchingListFetching
            }
            size='large'
            tip={OPERATING_TEXT}
        >
            <div className='patient-reconciliation'>
                <Row gutter={16}>
                    <Col span={6}>
                        <div className='title'>Patient Reconciliation</div>
                    </Col>
                    <Col span={18}>
                        <div className='button-container'>
                            <Button
                                disabled={shouldDisableMergingButton()}
                                className='merge-patients-button'
                                onClick={mergePatients}
                            >
                                Merge Patients
                            </Button>

                            <Button
                                className='create-new-patient-button'
                                onClick={createNewPatient}
                            >
                                Create a new Patient
                            </Button>

                            <Button
                                className='re-match-button'
                                onClick={reMatch}
                            >
                                Re-match
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <div className='sub-title'>
                            Existing Patient(s) Matched:{' '}
                            {patientMatchingList.length}
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <PatientMatchingListTable
                            tableList={patientMatchingList}
                            patientMatchingOps={patientMatchingOps}
                            setPatientMatchingOps={setPatientMatchingOps}
                        />
                    </Col>
                </Row>
                
                <Row gutter={16}>
                    <Col span={24} className='divider'></Col>
                </Row>
            </div>
        </Spin>
    );
};

export default PatientReconciliation;
