import { IPatientDetails } from '../reducers/patient-details-slice';
import { convertUtcToLocal, convertTimeToShortFormatLabel } from './utilities';

interface IHistoryDetailsExamData {
   id: number,
   status: string,
   visit_date: string,
}

export interface IExamHistoryDetails {
    key: number;
    visit_date: string;
    review_status: string;
    view: number;
    delete: number;
}

export interface IAdminExamHistoryDetails {
    key: number;
    visit_date: string;
    review_status: string;
    view: number;
    delete: number;
    is_inactive: boolean;
}
// Builds the rows for the left and right patient details panels
export function buildPatientDetailsTableData(patientDetails: IPatientDetails, isRE: boolean, isUsOnly=false) {
    if (!patientDetails) {
        return [[], []];
    }

    const provinceLabel = isUsOnly ? 'State' : 'Province';
    const postalCodeLabel = isUsOnly ? 'Zip Code' : 'Postal Code';

    // STEP 1: Build left panel patient details
    const leftPanel = [];

    // React complains if there is not a unique key for each row (child)
    let key = 1;

    leftPanel.push({
        key: key += 1,
        label: 'Gender',
        value: patientDetails.gender ? patientDetails.gender === 'F' ? 'Female' : 'Male' : 'N/A',
    });

    leftPanel.push({
        key: key += 1,
        label: 'DOB',
        value: patientDetails.dob,
    });

    // If patient has no phn then replace phn value with 'no insurance'
    // In the case of an US user, the PHN gets replaced by the insurance button.
    const phnValue = patientDetails.has_no_phn ? "No Insurance" : patientDetails.phn

    leftPanel.push({
        key: key += 1,
        label: patientDetails.phn_name,
        value: phnValue,
    });

    if (!isUsOnly) {
        // Does patient have a secondary phn?
        if (patientDetails.require_secondary_phn && !patientDetails.has_no_phn) {
            leftPanel.push({
                key: key += 1,
                label: patientDetails.secondary_phn_name,
                value: patientDetails.secondary_phn ?? '',
            });
        }

        // Is patient CAF?
        if (patientDetails.caf_personnel) {
            leftPanel.push({
                key: key += 1,
                label: 'CAF Personnel',
                value: 'Yes',
            });
            leftPanel.push({
                key: key += 1,
                label: 'Service Number',
                value: patientDetails.service_number,
            });
        } else {
            leftPanel.push({
                key: key += 1,
                label: 'CAF Personnel',
                value: 'No',
            });
        }
    }

    // Doctor
    const gp = patientDetails.gp ? `Dr. ${patientDetails.gp}` : '';
    leftPanel.push({
        key: key += 1,
        label: 'GP',
        value: gp,
    });

    // Doctor contact
    leftPanel.push({
        key: key += 1,
        label: 'GP Fax No.',
        value: patientDetails.gp_fax_number,
    });

    // MD#2 fields should not be visible to RE users
    const gp2 = patientDetails.gp2 ? `Dr. ${patientDetails.gp2}` : '';
    // MD#2
    leftPanel.push({
        key: key += 1,
        label: 'MD#2',
        value: gp2,
    });

    // MD#2 contact
    leftPanel.push({
        key: key += 1,
        label: 'MD#2 Fax No.',
        value: patientDetails.gp2_number,
    });

    // STEP: 2 Build the right panel
    const rightPanel = [];

    rightPanel.push({
        key: key += 1,
        label: 'Phone',
        value: patientDetails.primary_phone,
    });

    rightPanel.push({
        key: key += 1,
        label: 'Email',
        value: patientDetails.email,
    });

    rightPanel.push({
        key: key += 1,
        label: 'Street',
        value: patientDetails.address,
    });

    rightPanel.push({
        key: key += 1,
        label: 'City',
        value: patientDetails.city,
    });

    rightPanel.push({
        key: key += 1,
        label: provinceLabel,
        value: patientDetails.province,
    });

    rightPanel.push({
        key: key += 1,
        label: postalCodeLabel,
        value: patientDetails.postal_code,
    });

    return [leftPanel, rightPanel];
}

// Build the exam history table for the patient details modal
export function buildHistoryDetailsTableData(exams: string): IExamHistoryDetails[] {
    if (!exams) {
        return [];
    }

    const parsedExams: IHistoryDetailsExamData[] = JSON.parse(exams);

    if (Array.isArray(parsedExams) && parsedExams.length > 0) {

        return parsedExams.map(exam => ({
            // Unique key
            key: exam.id,
            // Visit date. Convert the date from UTC to local time.
            visit_date: convertTimeToShortFormatLabel(convertUtcToLocal(exam.visit_date)),
            // Review status
            review_status: exam.status,
            // Exam visit ID
            view: exam.id,
            // Exam visit ID
            delete: exam.id,
        }));
    }

    return [];
}

interface IAdminHistoryDetailsExamData extends IHistoryDetailsExamData {
    is_inactive: boolean;
}

// Build the exam history table for the patient details modal
export function buildAdminHistoryDetailsTableData(exams: string): IAdminExamHistoryDetails[] {
    if (!exams) {
        return [];
    }

    const parsedExams: IAdminHistoryDetailsExamData[] = JSON.parse(exams);

    if (Array.isArray(parsedExams) && parsedExams.length > 0) {
        return parsedExams.map(exam => ({
            // Unique key
            key: exam.id,
            // Visit date. Convert the date from UTC to local time.
            visit_date: convertTimeToShortFormatLabel(convertUtcToLocal(exam.visit_date)),
            // Review status
            review_status: exam.status,
            // Exam visit ID
            view: exam.id,
            // Exam visit ID
            delete: exam.id,
            // Is Exam inactive
            is_inactive: exam.is_inactive
        }))
    }

    return [];
}
