import { useCare1AppSelector } from '../apps/care1-hooks';
import { Row, Card, Col } from 'antd';
import * as Constants from '../constants';

// Components
import DeepMDBandOD from '../components/integrated/deepMdOd/deepmd-od-band';

import PatientExamExtraImages from '../components/retina/patient-exam-extra-images';
import PatientExamFundus from '../components/retina/patient-exam-fundus';
import PatientExamHeader from '../components/retina/header/patient-exam-header';

import PatientExamOCTMacula from '../components/retina/patient-exam-oct-macula';
import PatientExamOCTRnfl from '../components/integrated/patient-exam-oct-rnfl';
import PatientExamVF from '../components/integrated/patient-exam-vf';
import PatientExamGlaucomaScore from '../components/integrated/patient-exam-glaucoma-score';
import PatientExamODNotes from '../components/integrated/patient-exam-od-notes';
import PatientExamMetricsIOPReferralLetter from '../components/integrated/metrics/patient-exam-metrics-iop-referral-letter';
import PatientExamStandardPremiumSwitch from '../components/integrated/patient-exam-standard-premium-switch';

// Helpers
import '../../static/css/pages/ic-patient-exam-page.scss';

// Actions
import { localizedText } from '../localizedText'
import ReferralLetterDropzone from '../components/integrated/referral-letter-dropzone';
import PatientExamConversation from '../components/retina/patient-exam-conversation';
import PatientExamVFImageHistory from '../components/image-histories/patient-exam-vf-image-history';
import PatientExamOCTRNFLImageHistory from '../components/image-histories/patient-exam-oct-rnfl-image-history';
import PatientExamOCTMaculaImageHistory from '../components/image-histories/patient-exam-oct-macula-image-history';
import PatientExamFundusImageHistory from '../components/image-histories/patient-exam-fundus-image-history';
import IopBcvaGraphBand from '../components/integrated/iop-bcva-graph-band';
import PatientExamUploadType from '../components/integrated/patient-exam-upload-type';


const PatientExamReferralLetterPage = () => {
    const { PHOTO_DROP_TEXT_SHORT } = localizedText;

    const examStatus = useCare1AppSelector(store => store.examData.exam_status);
    const examStatusPrevious = useCare1AppSelector(store => store.examData.exam_status_previous);
    const odGroupPracticeProvince = useCare1AppSelector(store => store.examData.od_group_practice_province);
    const exam_od_has_pei_toggle = useCare1AppSelector(store => store.examData.exam_od_has_pei_toggle);
    const visitNumber = useCare1AppSelector(store => store.examData.visit_number);
    const totalVisit = useCare1AppSelector(store => store.examData.exam_count);
    const isPrereviewed = useCare1AppSelector(store => store.examData.is_pre_reviewed);

    // RQ: If exam status is ready or reviewed, then components should be read only.
    const isReadOnly = examStatus !== 'not_ready';

    // RQ: if exam status is od_review or later in process, show some additional components
    const isODReviewOrLater = ['od_review', 'ready', 'reviewed'].includes(examStatus);

    const isOMDReadyOrReviewed = examStatusPrevious === 'ready' || examStatusPrevious === 'reviewed';

    const isManitoba = odGroupPracticeProvince === 'MB';

    // For the latest reviewed RLU premium exam for the patient, allow OD to OMD messaging.
    const allowODMessaging = (visitNumber === totalVisit) && examStatus === 'reviewed';

    return (
        <div>
            <Row>
                <PatientExamHeader />
            </Row>
            { exam_od_has_pei_toggle &&
                <Row>
                    <PatientExamStandardPremiumSwitch />
                </Row>
            }
            <Row gutter={8}>
                <Col span={6} className="dropzones-container">
                    <PatientExamFundus
                        tabbed
                        disabled={isReadOnly}
                        className="patient-exam-fundus"
                        side={Constants.RIGHT_SIDE_TEXT}
                    />
                    <Row gutter={8}>
                        <Col span={12}>
                            <PatientExamOCTMacula
                                dropText={PHOTO_DROP_TEXT_SHORT}
                                disabled={isReadOnly}
                                side={Constants.RIGHT_SIDE_TEXT}
                                tabbed={true}
                            />
                            <PatientExamOCTRnfl
                                disabled={isReadOnly}
                                side={Constants.RIGHT_SIDE_TEXT}
                            />
                        </Col>
                        <Col span={12}>
                            <PatientExamVF disabled={isReadOnly} side={Constants.RIGHT_SIDE_TEXT} />
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Card className='referral-letter-container' bordered={false}>
                        <PatientExamUploadType disabled={isReadOnly} />
                        <Row>
                            <Col span={16}>
                                <Card
                                    className='referral-letter-dropzone-container'
                                    bordered={false}
                                >   
                                    <h3>Letter</h3>
                                    <ReferralLetterDropzone field='od_referral_letter' disabled={isReadOnly} />
                                </Card>
                            </Col>
                            <Col span={8}>
                                {
                                    isManitoba &&
                                    <Row>
                                        <PatientExamMetricsIOPReferralLetter disabled={isReadOnly} />
                                    </Row>
                                }
                                <Row>
                                    <PatientExamODNotes className={isManitoba ? 'manitoba-od-notes input-od-notes' : 'input-od-notes'} disabled={isOMDReadyOrReviewed}
                                        odNotesTitle={'OD Notes (Optional)'} />
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={6} className="dropzones-container">
                    <PatientExamFundus
                        tabbed
                        disabled={isReadOnly}
                        className="patient-exam-fundus"
                        side={Constants.LEFT_SIDE_TEXT}
                    />
                    <Row gutter={8}>
                        <Col span={12}>
                            <PatientExamOCTMacula
                                dropText={PHOTO_DROP_TEXT_SHORT}
                                disabled={isReadOnly}
                                side={Constants.LEFT_SIDE_TEXT}
                                tabbed={true}
                            />
                            <PatientExamOCTRnfl
                                disabled={isReadOnly}
                                side={Constants.LEFT_SIDE_TEXT}
                            />
                        </Col>
                        <Col span={12}>
                            <PatientExamVF disabled={isReadOnly} side={Constants.LEFT_SIDE_TEXT} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <PatientExamExtraImages
                disabled={isReadOnly}
                className="patient-exam-extra-images"
            />
            {/* DeepMD is now available for all IC OD users */}
            { isODReviewOrLater && 
                <DeepMDBandOD />
            }
            
            {/* START |PatientExamGlaucomaScore Band */}
            { isPrereviewed &&
            <Row gutter={8}>
                <PatientExamGlaucomaScore />
            </Row> 
            }
            {/* END | PatientExamGlaucomaScore  Band */}
            { isReadOnly &&
                <>
                    <IopBcvaGraphBand />
                    <Row gutter={8}>
                        <Col span={24}>
                            <PatientExamConversation allowODMessaging={allowODMessaging} />
                        </Col>
                    </Row>
                    {/* START | Section 10 - VF History band */}
                        <Row gutter={8}>
                            <PatientExamVFImageHistory />
                        </Row>
                    {/* END | Section 10 - VF band */}

                    {/* START | Section 11 - RNFL History band */}
                        <Row gutter={8}>
                            <PatientExamOCTRNFLImageHistory />
                        </Row>
                    {/* END | Section 11 - RNFL History band */}

                    {/* START | Section 12 - Macula History band*/}
                        <Row gutter={8}>
                            <PatientExamOCTMaculaImageHistory orderBy={'asc'} showHint={true} showBaselineBorder={true} showEmptyRow={true} />
                        </Row>
                    {/* END | Section 12 - Macula History band */}
                    {/* START |  Section 13 - Fundus History band */}
                        <Row gutter={8}>
                            <PatientExamFundusImageHistory />
                        </Row>
                    {/* END | Section 13 - Fundus History band */}
                </>
            }
            <Row>
                <br />
                <br />
            </Row>
        </div>             
    );
}

export default PatientExamReferralLetterPage;