import { Fragment, useRef } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Select, Row, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { localizedText } from '../../localizedText';
import { setExamDataValue, shootsIntoMOA } from '../../reducers/patient-exam-slice';
import '../../../static/css/components/patient-exam-second-opinion-selectors.scss';


const PatientExamSecondOpinionSelectors = ({ disabled }: { disabled: boolean }) => {
    const { SELECT_REVIEWER_TEXT, SECOND_OPINIONS_TEXT, SECOND_OPINION_OPTION } = localizedText;
    const userID = useCare1AppSelector(store => store.user.userID);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const omds = useCare1AppSelector(store => store.options.omds);
    const secondOmdr = useCare1AppSelector(store => store.examData['second_omdr']);
    const omdc = useCare1AppSelector(store => store.examData['omdc']);
    const notesToMoaCache = useCare1AppSelector(store => store.examData.billing_note_cache);
    const omdcTravelTimes = useCare1AppSelector(store => store.examData.omdctraveltime);
    const odGroupPracticeId = useCare1AppSelector(store => store.examData.od_group_practice);

    const omdrSelectionChanged = useRef(false);
    const omdcSelectionChanged = useRef(false);

    // Redux and React Router Hooks
    const dispatch = useCare1AppDispatch();

    const filteredOmdcs = [];

    for (let i = 0; i < omds.length; i++) {
        const omd = omds[i];
        if (!omd.is_reviewer) {
            if (!omd.hidden_from_dropdown) {
                let displayName = '';

                if (omd.is_ret_specialist) {
                    displayName += 'RET ';
                }

                displayName += omd.first_name + ' ';
                displayName += omd.last_name + ' ';


                if (omdcTravelTimes) {
                    for (let t = 0; t < omdcTravelTimes.length; t++) {
                        const time = omdcTravelTimes[t];

                        if (time.omdc_id === omd.id && time.reachable_clinic_id === odGroupPracticeId) {
                            displayName += time.travel_time + 'h away';
                        }
                    }
                }

                filteredOmdcs.push({
                    ...omd,
                    displayName
                });
            }
        }
    }

    const updateOmdc = (omdcId: number) => {
        omdcSelectionChanged.current = true;
        dispatch(setExamDataValue('omdc', omdcId));
        dispatch(shootsIntoMOA(notesToMoaCache, omdrSelectionChanged.current, omdcSelectionChanged.current));
        dispatch(setExamDataValue('omdc_status', 'ready'));
    }

    const filteredSecondOmds = [];
    for (let i = 0; i < omds.length; i++) {
        if (omds[i].is_reviewer) {
            if (omds[i].user_id !== userID) {
                if (!omds[i].hidden_from_dropdown) {
                    filteredSecondOmds.push(omds[i]);
                }
            }
        }
    }

    const updateSecondOmdr = (omdId: number) => {
        omdrSelectionChanged.current = true;
        dispatch(setExamDataValue('second_omdr', omdId));
        dispatch(shootsIntoMOA(notesToMoaCache, omdrSelectionChanged.current, omdcSelectionChanged.current));
        // unflag second omdr alert check
        dispatch(setExamDataValue('second_omdr_reviewed', false));
    }

    return (
        <Card className={'component-input-card second-opinions'} bordered={false}>
            { !userIsRE &&
                <Fragment>
                    {<Row>
                        <div className="heading">{SECOND_OPINIONS_TEXT}</div>
                        <Tooltip className="omdr-select-tooltip" placement='topLeft' title={SECOND_OPINION_OPTION}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Row>}

                    <div className='component-body'>
                        <Row wrap={false}>
                            <div className="heading">{'OMDR:'}</div>
                            <Select
                                data-testid='second-omdr-select'
                                disabled={disabled}
                                className="omdr-select"
                                placeholder={SELECT_REVIEWER_TEXT}
                                onChange={(value) => {
                                    updateSecondOmdr(value);
                                }}
                                value={secondOmdr}
                            >
                                {
                                    filteredSecondOmds && filteredSecondOmds.map((omd) => (
                                        <Select.Option key={omd.id} value={omd.id}>
                                            {`${omd.first_name} ${omd.last_name}`}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Row>
                        <Row wrap={false}>
                            <div className="heading">{'OMDC:'}</div>
                            <Select
                                data-testid='omdc-select'
                                disabled={disabled}
                                className="omdc-select"
                                placeholder={`Select Consultant`}
                                onChange={(value) => {
                                    updateOmdc(value);
                                }}
                                value={omdc}
                            >
                                {
                                    filteredOmdcs && filteredOmdcs.map((omd) => (
                                        <Select.Option key={omd.id} value={omd.id}>
                                            {omd.displayName}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Row>
                    </div>
                </Fragment>
            }
            { userIsRE &&
                <Fragment>
                    <Row>
                        <div className="heading">{'OMDR 2nd Opinion:'}</div>
                        <Tooltip className="omdr-select-tooltip" placement='topLeft' title={SECOND_OPINION_OPTION}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Row>
                    <Row>
                        <Select
                            data-testid='second-omdr-select'
                            disabled={disabled}
                            className="omdr-select"
                            placeholder={SELECT_REVIEWER_TEXT}
                            onChange={(value) => {
                                updateSecondOmdr(value);
                            }}
                            value={secondOmdr}
                        >
                            {
                                filteredSecondOmds && filteredSecondOmds.map((omd) => (
                                    <Select.Option key={omd.id} value={omd.id}>
                                        {`${omd.first_name} ${omd.last_name}`}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Row>
                </Fragment>
            }
        </Card>
    );
}

export default PatientExamSecondOpinionSelectors;