import { useState } from 'react';
import { Card, Row, Tag } from 'antd';
import classNames from 'classnames';
import '../../../../static/css/components/admin-oct-rnfl-avg.scss';
import { localizedText } from '../../../localizedText';
import { useCare1AppDispatch } from '../../../apps/care1-hooks';

const { CheckableTag } = Tag;

type ComponentProps = {
    octavSup: string,
    setOctavSup: (val: string) => void,
    octav: string, 
    setOctav: (val: string) => void,
    octavInf: string, 
    setOctavInf: (val: string) => void,
}


const GptOctRnflAverage = ({octavSup, setOctavSup, octav, setOctav, octavInf, setOctavInf}: ComponentProps) => {
    const { NA_TEXT, IA_TEXT, G_TEXT, Y_TEXT, R_TEXT, OCT_RNFL_AVERAGE } = localizedText;

    const [octavSupCheckedSet] = useState(new Set());
    const [octavCheckedSet] = useState(new Set());
    const [octavInfCheckedSet] = useState(new Set());

    const dispatch = useCare1AppDispatch();

    const handleOctavSupChange = (checked: boolean, val: string) => {
        octavSupCheckedSet.add(octavSup);
        if (checked) {
            setOctavSup(val);
        }
        else {
            setOctavSup('');
        }
    }

    const handleOctavChange = (checked: boolean, val: string) => {
        octavCheckedSet.add(octav);
        if (checked) {
            setOctav(val);
        }
        else {
            setOctav('');
        }
    }

    const handleOctavInfChange = (checked: boolean, val: string) => {
        octavInfCheckedSet.add(octavInf);

        if (checked) {
            setOctavInf(val);
        }
        else {
            setOctavInf('');
        }
    }

    return (
        <Card className={'component-input-card oct-rnfl-avg'} bordered={false}>
            <div className={`heading`}>{OCT_RNFL_AVERAGE}</div>
            <div className='component-body'>
                <Row>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-sup-'
                        className={classNames({
                            wide: true,
                            yellow: true,
                            clicked: octavSupCheckedSet.has('')
                        })}
                        checked={octavSup === ''}
                        onChange={(checked) => handleOctavSupChange(checked, '')}
                    >XXX</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-sup-invalid'
                        className={classNames({
                            medium: true,
                            yellow: true,
                            clicked: octavSupCheckedSet.has('invalid')
                        })}
                        checked={octavSup === 'invalid'}
                        onChange={(checked) => handleOctavSupChange(checked, 'invalid')}
                    >{IA_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-sup-green'
                        className={classNames({
                            green: true,
                            clicked: octavSupCheckedSet.has('green')
                        })}
                        checked={octavSup === 'green'}
                        onChange={(checked) => handleOctavSupChange(checked, 'green')}
                    >{G_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-sup-yellow'
                        className={classNames({
                            yellow: true,
                            clicked: octavSupCheckedSet.has('yellow')
                        })}
                        checked={octavSup === 'yellow'}
                        onChange={(checked) => handleOctavSupChange(checked, 'yellow')}
                    >{Y_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-sup-red'
                        className={classNames({
                            red: true,
                            clicked: octavSupCheckedSet.has('red')
                        })}
                        checked={octavSup === 'red'}
                        onChange={(checked) => handleOctavSupChange(checked, 'red')}
                    >{R_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-sup-na'
                        className={classNames({
                            wide: true,
                            yellow: true,
                            clicked: octavSupCheckedSet.has('na')
                        })}
                        checked={octavSup === 'na'}
                        onChange={(checked) => handleOctavSupChange(checked, 'na')}
                    >{NA_TEXT}</CheckableTag>
                </Row>
                <Row>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-'
                        className={classNames({
                            wide: true,
                            yellow: true,
                            clicked: octavCheckedSet.has('')
                        })}
                        checked={octav === ''}
                        onChange={(checked) => handleOctavChange(checked, '')}
                    >XXX</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-invalid'
                        className={classNames({
                            medium: true,
                            yellow: true,
                            clicked: octavCheckedSet.has('invalid')
                        })}
                        checked={octav === 'invalid'}
                        onChange={(checked) => handleOctavChange(checked, 'invalid')}
                    >{IA_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-green'
                        className={classNames({
                            green: true,
                            clicked: octavCheckedSet.has('green')
                        })}
                        checked={octav === 'green'}
                        onChange={(checked) => handleOctavChange(checked, 'green')}
                    >{G_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-yellow'
                        className={classNames({
                            yellow: true,
                            clicked: octavCheckedSet.has('yellow')
                        })}
                        checked={octav === 'yellow'}
                        onChange={(checked) => handleOctavChange(checked, 'yellow')}
                    >{Y_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-red'
                        className={classNames({
                            red: true,
                            clicked: octavCheckedSet.has('red')
                        })}
                        checked={octav === 'red'}
                        onChange={(checked) => handleOctavChange(checked, 'red')}
                    >{R_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-na'
                        className={classNames({
                            wide: true,
                            yellow: true,
                            clicked: octavCheckedSet.has('na')
                        })}
                        checked={octav === 'na'}
                        onChange={(checked) => handleOctavChange(checked, 'na')}
                    >{NA_TEXT}</CheckableTag>
                </Row>
                <Row>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-inf-'
                        className={classNames({
                            wide: true,
                            yellow: true,
                            clicked: octavInfCheckedSet.has('')
                        })}
                        checked={octavInf === ''}
                        onChange={(checked) => handleOctavInfChange(checked, '')}
                    >XXX</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-inf-invalid'
                        className={classNames({
                            medium: true,
                            yellow: true,
                            clicked: octavInfCheckedSet.has('invalid')
                        })}
                        checked={octavInf === 'invalid'}
                        onChange={(checked) => handleOctavInfChange(checked, 'invalid')}
                    >{IA_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-inf-green'
                        className={classNames({
                            green: true,
                            clicked: octavInfCheckedSet.has('green')
                        })}
                        checked={octavInf === 'green'}
                        onChange={(checked) => handleOctavInfChange(checked, 'green')}
                    >{G_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-inf-yellow'
                        className={classNames({
                            yellow: true,
                            clicked: octavInfCheckedSet.has('yellow')
                        })}
                        checked={octavInf === 'yellow'}
                        onChange={(checked) => handleOctavInfChange(checked, 'yellow')}
                    >{Y_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-inf-red'
                        className={classNames({
                            red: true,
                            clicked: octavInfCheckedSet.has('red')
                        })}
                        checked={octavInf === 'red'}
                        onChange={(checked) => handleOctavInfChange(checked, 'red')}
                    >{R_TEXT}</CheckableTag>
                    <CheckableTag
                        data-testid='admin-oct-rnfl-average-octav-inf-na'
                        className={classNames({
                            wide: true,
                            yellow: true,
                            clicked: octavInfCheckedSet.has('na')
                        })}
                        checked={octavInf === 'na'}
                        onChange={(checked) => handleOctavInfChange(checked, 'na')}
                    >{NA_TEXT}</CheckableTag>
                </Row>
            </div>
        </Card>
    )
}

export default GptOctRnflAverage;