import { useCare1AppSelector, useCare1AppDispatch } from '../../apps/care1-hooks';
import { Card, Switch, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

// Components

// Actions
import { resetOdSelection, setExamDataValue } from '../../reducers/patient-exam-slice';

// Helpers
import '../../../static/css/components/patient-exam-standard-premium-switch.scss';
import { getDiagnosisValues } from '../../helpers/diagnosis-convert';
import { IDiagnosisValue, loadDiagnosisData } from '../../reducers/diagnosis-slice';

const PatientExamStandardPremiumSwitch = () => {
    const dispatch = useCare1AppDispatch();
    const rluExamPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);
    const exam_status = useCare1AppSelector(store => store.examData.exam_status);
    const userIsOd = useCare1AppSelector(store => store.user.isOD);

    const toggleStandardPremium = (checked: boolean) => {
        dispatch(setExamDataValue('rlu_exam_is_premium', checked));
        
        if (userIsOd) {
            dispatch(resetOdSelection());
        }
        const diagnosisValues: IDiagnosisValue = dispatch((getDiagnosisValues()));
        dispatch(loadDiagnosisData(diagnosisValues));
    }

    return (
        <Card className="standard-premium-switch" bordered={false}>
            <Switch
                checked={rluExamPremium}
                onChange={(checked, e) => toggleStandardPremium(checked)}
                disabled={exam_status !== 'not_ready'}
            />
            <span className={`standard-premium-switch-label ${rluExamPremium ? 'premium-label-selected' : 'standard-label-selected'}`}>
                {rluExamPremium ? 'Comprehensive' : 'Retina'}
            </span>
            <Tooltip
                className='exam-switch-tooltip'
                placement='right'
                overlayInnerStyle={{width: "530px"}}
                title={
                    <div className='exam-switch-tooltip-div'>
                        <b>Retina uploads will be sent to Dr. Tom Lee for review. Comprehensive uploads may be sent to other Care1 ophthalmology reviewers.</b>
                    </div>
                }
            >
                <InfoCircleOutlined />
            </Tooltip>
        </Card>             
    );
}

export default PatientExamStandardPremiumSwitch;