// the chat components codes mainly from 
// https://github.com/fatihbaltaci/chatgpt-clone

import { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Modal, Spin } from 'antd';
import { IMessageEvent, w3cwebsocket } from 'websocket';
import GptUI from './gpt-ui';
import '../../../../static/css/components/admin-gpt.scss';
import { v4 as uuidv4 } from 'uuid';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import PDFViewer from "../../retina/pdf-viewer";
import { fetchReferralLetterAnalysis, postReferralLetterAnalysisResults, 
    generateDeidentifiedReferralLetter, fetchAutoGptResponse } from '../../../reducers/referral-letter-analysis-slice';
import { IUntilYesterdayItem, IOmdHistoryItem, IOngoingItem } from '../../../reducers/patient-exam-slice';
import GptPatientExamMetrics from "./gpt-patient-exam-metrics";
import PatientExamComorbidities from "../../integrated/patient-exam-comorbidities";
import { editExamRequest } from '../../../reducers/patient-exam-slice';
import PatientExamAllergies from "../../integrated/patient-exam-allergies";
import GptPatientExamAllergies from "./gpt-patient-exam-allergies";
import GptPatientExamComorbidities  from "./gpt-patient-exam-comorbidities";
import GptPatientExamChiefComplaintIC  from "./gpt-patient-exam-chief-complaint-ic";
import PatientExamChiefComplaintIC from "../../integrated/patient-exam-chief-complaint-ic";
import { ALLERGIES_FIELD, ALLERGIES_NONE_FIELD } from '../../../constants';
import { lengthEqual, IOptionItem } from '../../../reducers/patient-exam-slice';
import ReadonlyAdminSelectors from './readonly-admin-selectors';
import GptAdminSelectors from './gpt-admin-selectors';
import { isNumeric } from "../../../helpers/utilities";
import GptFollowUp from "./gpt-follow-up";
import { extractBcvaValue } from "../../../helpers/patient-exam-convert";
import PatientExamUntilYesterday from "../../integrated/patient-exam-until-yesterday";
import GptPatientExamUntilYesterday from "./gpt-patient-exam-until-yesterday";
import PatientExamOMDHistory from "../../integrated/patient-exam-omd-history";
import GptPatientExamOMDHistory from "./gpt-patient-exam-omd-history";
import PatientExamOngoing from "../../integrated/patient-exam-ongoing";
import GptPatientExamOngoing from "./gpt-patient-exam-ongoing";

interface Message {
    input: string;
    output: string;
    meta: string;
}

type chiefComplaintsType = {
    rr_cat_post_op: boolean, 
    rr_cataract: boolean, 
    rr_erm: boolean, 
    rr_amd: boolean, 
    rr_dm: boolean,  
    rr_optic_nerve: boolean, 
    rr_glc_suspect: boolean, 
    rr_glc: boolean,  
    rr_narrow_angles: boolean,
}

const initialChiefComplaints = {
    rr_cat_post_op: false, 
    rr_cataract: false, 
    rr_erm: false, 
    rr_amd: false, 
    rr_dm: false,  
    rr_optic_nerve: false, 
    rr_glc_suspect: false, 
    rr_glc: false,  
    rr_narrow_angles: false,
}

type comorbiditiesType = {
    fhx: boolean, 
    asthma: boolean, 
    heart_block: boolean, 
    uveitis_od: boolean, 
    uveitis_os: boolean,  
    pds_od: boolean, 
    pds_os: boolean, 
    hx_stroke: boolean,  
    pxf_od: boolean,  
    pxf_os: boolean, 
    
}

const initialComorbidities = {
    fhx: false, 
    asthma: false,
    heart_block: false,
    uveitis_od: false,
    uveitis_os: false, 
    pds_od: false,
    pds_os: false,
    hx_stroke: false,  
    pxf_od: false,
    pxf_os: false,
}

const Gpt = () => {
    const odGroupPracticeProvince = useCare1AppSelector(store => store.examData.od_group_practice_province);
    const currentOdIop = useCare1AppSelector(store => store.examData.od_iop);
    const currentOsIop = useCare1AppSelector(store => store.examData.os_iop);
    const currentApplanaction = useCare1AppSelector(store => store.examData.applanation);
    const isManitoba = odGroupPracticeProvince === 'MB';

    const dispatch = useCare1AppDispatch();
    const history = useNavigate();

    const webSocketRef = useRef<w3cwebsocket | null>(null);
    const reconnectIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [messageHistory, setMessageHistory] = useState<Message[]>([]);
    const [inputMessage, setInputMessage] = useState<string>("");
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [isAssistantTyping, setIsAssistantTyping] = useState<boolean>(false);

    const examId = useCare1AppSelector(store => store.examData.id);

    const currentPhotoURL = useCare1AppSelector(store => store.examData.od_referral_letter);
    const apiStatus = useCare1AppSelector(store => store.referralLetterAnalysis.api_status);

    const bcvaOptions: IOptionItem[] = useCare1AppSelector(store => store.examData.bcva_options, lengthEqual);

    const [iopRight, setIopRight] = useState<string | number | null>('');
    const [iopLeft, setIopLeft] = useState<string | number | null>('');

    const [fuNumber, setFuNumber] = useState<string | null>(null);
    const [fuPeriod, setFuPeriod] = useState<string | null>('week');
    const [isFuUnknown, setIsFuUnknown] = useState<boolean>(false);

    const sideIopRightAim = useCare1AppSelector(store => store.examData['od_iop_aim']);
    const sideIopLeftAim = useCare1AppSelector(store => store.examData['os_iop_aim']);

    const [applanation, setApplanation] = useState<string>('');

    const [sideRightBCVA, setSideRightBCVA] = useState<string>('');
    const [sideLeftBCVA, setSideLeftBCVA] = useState<string>('');

    const [sideRightVertCD, setSideRightVertCD] = useState<string | number | null>('');
    const [sideLeftVertCD, setSideLeftVertCD] = useState<string | number | null>('');

    const [pachsRight, setPachsRight] = useState<string | number | null>('');
    const [pachsLeft, setPachsLeft] = useState<string | number | null>('');

    const [sphereRight, setSphereRight] = useState<string | number | null>('');
    const [sphereLeft, setSphereLeft] = useState<string | number | null>('');

    const [cylinderRight, setCylinderRight] = useState<string | number | null>('');
    const [cylinderLeft, setCylinderLeft] = useState<string | number | null>('');

    const [axisRight, setAxisRight] = useState<string | number | null>('');
    const [axisLeft, setAxisLeft] = useState<string | number | null>('');

    const [allergies, setAllergies] = useState<string>('');
    const [allergiesNone, setAllergiesNone] = useState<boolean>(false);
    const [comorbidities, setComorbidities] = useState<comorbiditiesType>(initialComorbidities);
    const [comorbiditiesNone, setComorbiditiesNone] = useState<boolean>(false);
    const [chiefComplaints, setChiefComplaints] = useState<chiefComplaintsType>(initialChiefComplaints);

    const [glcPastDropsValues, setGlcPastDropsValues] = useState<IUntilYesterdayItem[]>([]);
    const [omdHistoryValues, setOmdHistoryValues] = useState<IOmdHistoryItem[]>([]);
    const [ongoingValues, setOngoingValues] = useState<IOngoingItem[]>([]);
    const [ongoingHasSameDrops, setOngoingHasSameDrops] = useState(false);
    const [ongoingLetterIsNotClear, setOngoingLetterIsNotClear] = useState(false);
    
    const asthma = useCare1AppSelector(store => store.examData.asthma);
    const fhx = useCare1AppSelector(store => store.examData.fhx);
    const heartBlock = useCare1AppSelector(store => store.examData.heart_block);
    const uveitisOd = useCare1AppSelector(store => store.examData.uveitis_od);
    const uveitisOs = useCare1AppSelector(store => store.examData.uveitis_os);
    const pdsOd = useCare1AppSelector(store => store.examData.pds_od);
    const pdsOs = useCare1AppSelector(store => store.examData.pds_os);
    const hxStroke = useCare1AppSelector(store => store.examData.hx_stroke);
    const pxfOd = useCare1AppSelector(store => store.examData.pxf_od);
    const pxfOs = useCare1AppSelector(store => store.examData.pxf_os);
    const glc = useCare1AppSelector(store => store.examData.rr_glc);
    const glcSuspect = useCare1AppSelector(store => store.examData.rr_glc_suspect);
    const opticNerve = useCare1AppSelector(store => store.examData.rr_optic_nerve);
    const dm = useCare1AppSelector(store => store.examData.rr_dm);
    const amd = useCare1AppSelector(store => store.examData.rr_amd);
    const erm = useCare1AppSelector(store => store.examData.rr_erm);
    const cataract = useCare1AppSelector(store => store.examData.rr_cataract);
    const catPostOp = useCare1AppSelector(store => store.examData.rr_cat_post_op);
    const narrowAngles = useCare1AppSelector(store => store.examData.rr_narrow_angles);
    
    const [dhxOd, setDhxOd] = useState<boolean>(false);
    const [dhxOs, setDhxOs] = useState<boolean>(false);
    const [odQuestion, setOdQuestion] = useState<boolean>(false);
    const [cuOmd, setCuOmd]= useState<boolean>(false);

    const examAllergies = useCare1AppSelector(store => store.examData[ALLERGIES_FIELD]);
    const examAllergiesNone = useCare1AppSelector(store => store.examData[ALLERGIES_NONE_FIELD]);

    const examAllergiesList = useRef<string[]>([]);

    const untilYesterday = useCare1AppSelector(store => store.examData.until_yesterday);
    const omdHistory = useCare1AppSelector(store => store.examData.omd_history);
    const ongoing = useCare1AppSelector(store => store.examData.ongoing);

    const [gptResultState, setGptResultState] = useState<{[x: string]: string}>({
        iopRight: '',
        iopLeft: '',
        applanation: '',
        sideRightBCVA: '',
        sideLeftBCVA: '',
        sideRightVertCD: '',
        sideLeftVertCD: '',
        pachsRight: '',
        pachsLeft: '',
        sphereRight: '',
        sphereLeft: '', 
        cylinderRight: '',
        cylinderLeft: '',
        axisRight: '',
        axisLeft: '',
        allergies: '',
        allergiesNone: '',
        glcPastDropsValues: '',
        omdHistoryValues: '',
        ongoingValues: '',
        ongoingHasSameDrops: '',
        ongoingLetterIsNotClear: '',
        dhxOd: '',
        dhxOs: '',
        odQuestion: '',
        cuOmd: '',
        fhx: '', 
        asthma: '',
        heart_block: '',
        uveitis_od: '',
        uveitis_os: '',
        pds_od: '',
        pds_os: '',
        hx_stroke: '',  
        pxf_od: '',
        pxf_os: '',
        comorbiditiesNone: '',
        fuNumber: '',
        fuPeriod: '',
        isFuUnknown: '',
    });

    const setAdminSelectorsValues = (results: { [x: string]: string; }) => {
        if (results['dhx_od'] === null) {
            setDhxOd(false);
        }
        setDhxOd(results['dhx_od'].toString() === 'true' ? true : false);


        if (results['dhx_os'] === null) {
            setDhxOs(false);
        }
        setDhxOs(results['dhx_os'].toString() === 'true' ? true : false);


        if (results['od_question'] === null) {
            setOdQuestion(false);
        }
        setOdQuestion(results['od_question'].toString() === 'true' ? true : false);

        if (results['cu_omd'] === null) {
            setCuOmd(false);
        }
        setCuOmd(results['cu_omd'].toString() === 'true' ? true : false);
    }
    
    useEffect(() => {
        if (examAllergies !== null) {
            examAllergiesList.current = examAllergies.split(',');
        }
        else {
            examAllergiesList.current = [];
        }

    }, [examAllergies])

    const setAllergiesValues = (results: { [x: string]: string; }) => {

        if (examAllergiesNone && results['allergies'] === null) {
            setAllergiesNone(true);
            setAllergies('');
            return false;
        }
        else {
            const allergiesSet = new Set();
            examAllergiesList.current.forEach(item => {
                if (item.trim() !== '') {
                    allergiesSet.add(item.trim().toLowerCase());
                }
            });

            if (results['allergies'] !== null) {
                const allergiesList = results['allergies'].split(',');
                allergiesList.forEach(item => {
                    if (item.trim() !== '') {
                        allergiesSet.add(item.trim().toLowerCase());
                    }
                });
            }

            setAllergies(Array.from(allergiesSet).join(', '));
            return true;
        }
    }
    
    const extractComorbidities = (comorb: {[x: string]: string}) => {
        if (comorb === null) {
            setComorbiditiesNone(true)
            setComorbidities(initialComorbidities);
        }
        else if (comorb){
            const comorbToSet = comorb as unknown as comorbiditiesType;
            setComorbidities(comorbToSet);
        }
    }

    const extractChiefComplaints = (chiefComplaints: chiefComplaintsType) => {
        if (chiefComplaints === null) {
            setChiefComplaints(initialChiefComplaints);
        }
        else if (chiefComplaints){
            setChiefComplaints(chiefComplaints);
        }
    }

    const setExamDataValues = (results: { [x: string]: string; }) => {

        setSideRightBCVA(extractBcvaValue(results['od_bcva'], bcvaOptions));
        setSideLeftBCVA(extractBcvaValue(results['os_bcva'], bcvaOptions));

        setIopRight(results['od_iop']);
        setIopLeft(results['os_iop']);

        setFuNumber(results['fu_number']);
        if (results['fu_period'] && results['fu_period'].toLowerCase().includes('week')) {
            setFuPeriod('week')
        } else {
            setFuPeriod('month')
        }
        setIsFuUnknown(results['pt_follow_up'] === 'unknown');

        setApplanation(extractApplanationValue(results['iop_instrument']));

        setSideRightVertCD(extractVertCD(results['od_cd']));
        setSideLeftVertCD(extractVertCD(results['os_cd']));

        setPachsRight(extractPachs(results['od_pachs']));
        setPachsLeft(extractPachs(results['os_pachs']));

        setSphereRight(extractRx(results['od_sphere']));
        setSphereLeft(extractRx(results['os_sphere']));

        setCylinderRight(extractRx(results['od_cylinder']));
        setCylinderLeft(extractRx(results['os_cylinder']));

        setAxisRight(extractAxis(results['od_axis']));
        setAxisLeft(extractAxis(results['os_axis']));

        let glcPastDropsValuesUpdated = false;
        if ('glc_past_drops_values' in results) {
            try {
                const glcPastDropsValuesLocal : IUntilYesterdayItem[] = results['glc_past_drops_values'] as unknown as IUntilYesterdayItem[];
                const glcPastDropsValuesExtracted = extractGlcPastDropsValues(glcPastDropsValuesLocal);

                // Combine lists and remove duplicates
                const glcPastDropsValuesMerged = [...glcPastDropsValuesExtracted, ...untilYesterday.values].filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.glc_past_drops_eye_select === item.glc_past_drops_eye_select &&
                        t.glc_past_drops_select === item.glc_past_drops_select
                    ))
                );                
                setGlcPastDropsValues(glcPastDropsValuesMerged);
                glcPastDropsValuesUpdated = true;
            }
            catch (e) {
                // do something
            }
        }
        else {
            setGlcPastDropsValues(untilYesterday.values);
        }

        let omdHistoryValuesUpdated = false;
        if ('omd_history_values' in results) {
            try {
                const omdHistoryValuesLocal : IOmdHistoryItem[] = results['omd_history_values'] as unknown as IOmdHistoryItem[];
                const omdHistoryValuesExtracted = extractOmdHistoryValues(omdHistoryValuesLocal);

                // Combine lists and remove duplicates
                const omdHistoryValuesMerged = [...omdHistoryValuesExtracted, ...omdHistory.values].filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.history_omd_name === item.history_omd_name &&
                        t.history_diagnosis === item.history_diagnosis &&
                        t.eye_select === item.eye_select &&
                        t.history_date === item.history_date &&
                        t.history_end_date === item.history_end_date
                    ))
                );                
                setOmdHistoryValues(omdHistoryValuesMerged);
                omdHistoryValuesUpdated = true;
            }
            catch (e) {
                // do something
            }
        }
        else {
            setOmdHistoryValues(omdHistory.values);
        }
        
        let ongoingValuesResults: IOngoingItem[] = [];

        let ongoingValuesUpdated = false;
        if ('glc_current_drops_values' in results) {
            try {
                const ongoingValuesLocal : IOngoingItem[] = results['glc_current_drops_values'] as unknown as IOngoingItem[];
                const ongoingValuesExtracted = extractOngoingValues(ongoingValuesLocal);
                ongoingValuesResults = [...ongoingValuesResults, ...ongoingValuesExtracted];
                ongoingValuesUpdated = true;
            }
            catch (e) {
                // do something
            }
        }

        const letterIsNotClear = results['letter_is_not_clear'] as unknown as boolean;
        setOngoingLetterIsNotClear(letterIsNotClear);
        if (letterIsNotClear) {
            ongoingValuesResults = [...ongoingValuesResults, {
                glc_current_drops_select: 'letter_is_not_clear', 
                glc_current_drops_eye_select: ''
            }];
        }

        setOngoingValues(ongoingValuesResults);

        const hasSameDrops = results['has_same_drops'] as unknown as boolean
        setOngoingHasSameDrops(hasSameDrops);
        if (hasSameDrops) {
            setOngoingValues([{ disabled: true }]);
        }

        const comorb =  results['comorbidities'] as unknown as {[x: string] : string};
        extractComorbidities(comorb);
        const chiefComplaints = results['chief_complaints'] as unknown as chiefComplaintsType;
        extractChiefComplaints(chiefComplaints);
        setAdminSelectorsValues(results);

        const allergiesNoneUpdated = (examAllergiesNone && (results['allergies'] === null)) || (!examAllergiesNone && (results['allergies'] !== null));
        const allergiesUpdated = results['allergies'] !== null ? examAllergies !== results['allergies'] : false;
        const asthmaUpdated = comorb && comorb.hasOwnProperty('asthma') && comorb['asthma'] !== null ? asthma.toString() !== comorb['asthma'].toString() : false;
        const fhxUpdated = comorb && comorb.hasOwnProperty('fhx') && comorb['fhx'] !== null ? fhx.toString() !== comorb['fhx'].toString() : false;;
        const heartBlockUpdated = comorb && comorb.hasOwnProperty('heart_block') && comorb['heart_block'] !== null ? heartBlock.toString() !== comorb['heart_block'].toString() : false;
        const uveitisOdUpdated = comorb && comorb.hasOwnProperty('uveitis_od') && comorb['uveitis_od'] !== null ? uveitisOd.toString() !== comorb['uveitis_od'].toString() : false;
        const uveitisOsUpdated = comorb && comorb.hasOwnProperty('uveitis_os') && comorb['uveitis_os'] !== null ? uveitisOs.toString() !== comorb['uveitis_os'].toString() : false;
        const pdsOdUpdated = comorb && comorb.hasOwnProperty('pds_od') && comorb['pds_od'] !== null ? pdsOd.toString() !== comorb['pds_od'].toString() : false;
        const pdsOsUpdated = comorb && comorb.hasOwnProperty('pds_os') && comorb['pds_os'] !== null ? pdsOs.toString() !== comorb['pds_os'].toString() : false;
        const hxStrokeUpdated = comorb && comorb.hasOwnProperty('hx_stroke') && comorb['hx_stroke'] !== null ? hxStroke.toString() !== comorb['hx_stroke'].toString() : false;
        const pxfOdUpdated = comorb && comorb.hasOwnProperty('pxf_od') && comorb['pxf_od'] !== null ? pxfOd.toString() !== comorb['pxf_od'].toString() : false;
        const pxfOsUpdated = comorb && comorb.hasOwnProperty('pxf_os') && comorb['pxf_os'] !== null ? pxfOs.toString() !== comorb['pxf_os'].toString() : false;

        const catPostOpUpdated = chiefComplaints && chiefComplaints.hasOwnProperty('rr_cat_post_op') && chiefComplaints['rr_cat_post_op'] !== null ? catPostOp.toString() !== chiefComplaints['rr_cat_post_op'].toString() : false;
        const cataractUpdated = chiefComplaints && chiefComplaints.hasOwnProperty('rr_cataract') && chiefComplaints['rr_cataract'] !== null ? cataract.toString() !== chiefComplaints['rr_cataract'].toString() : false;
        const amdUpdated = chiefComplaints && chiefComplaints.hasOwnProperty('rr_amd') && chiefComplaints['rr_amd'] !== null ? amd.toString() !== chiefComplaints['rr_amd'].toString() : false;

        const ermUpdated = chiefComplaints && chiefComplaints.hasOwnProperty('rr_erm') && chiefComplaints['rr_erm'] !== null ? erm.toString() !== chiefComplaints['rr_erm'].toString() : false;
        const dmUpdated = chiefComplaints && chiefComplaints.hasOwnProperty('rr_dm') && chiefComplaints['rr_dm'] !== null ? dm.toString() !== chiefComplaints['rr_dm'].toString() : false;
        const opticNerveUpdated = chiefComplaints && chiefComplaints.hasOwnProperty('rr_optic_nerve') && chiefComplaints['rr_optic_nerve'] !== null ? opticNerve.toString() !== chiefComplaints['rr_optic_nerve'].toString() : false;
        const glcSuspectUpdated = chiefComplaints && chiefComplaints.hasOwnProperty('rr_glc_suspect') && chiefComplaints['rr_glc_suspect'] !== null ? glcSuspect.toString() !== chiefComplaints['rr_glc_suspect'].toString() : false;
        const glcUpdated = chiefComplaints && chiefComplaints.hasOwnProperty('rr_glc') && chiefComplaints['rr_glc'] !== null ? glc.toString() !== chiefComplaints['rr_glc'].toString() : false;
        const narrowAnglesUpdated = chiefComplaints && chiefComplaints.hasOwnProperty('rr_narrow_angles') && chiefComplaints['rr_narrow_angles'] !== null ? narrowAngles.toString() !== chiefComplaints['rr_narrow_angles'].toString() : false;
        
        setAllergiesValues(results);

        setGptResultState({
            iopRight: results['od_iop'] === null ? '' : 'gpt-state-yellow',
            iopLeft: results['os_iop'] === null ? '' : 'gpt-state-yellow',
            applanation: results['iop_instrument'] === null ? '' : 'gpt-state-yellow',
            sideRightBCVA: results['od_bcva'] === null ? '' : 'gpt-state-yellow',
            sideLeftBCVA: results['os_bcva'] === null ? '' : 'gpt-state-yellow',
            sideRightVertCD: results['od_cd'] === null ? '' : 'gpt-state-yellow',
            sideLeftVertCD: results['os_cd'] === null ? '' : 'gpt-state-yellow',
            pachsRight: results['od_pachs'] === null ? '' : 'gpt-state-yellow',
            pachsLeft: results['os_pachs'] === null ? '' : 'gpt-state-yellow',
            sphereRight: results['od_sphere'] === null ? '' : 'gpt-state-yellow',
            sphereLeft: results['os_sphere'] === null ? '' : 'gpt-state-yellow',
            cylinderRight: results['od_cylinder'] === null ? '' : 'gpt-state-yellow',
            cylinderLeft: results['os_cylinder'] === null ? '' : 'gpt-state-yellow',
            axisRight: results['od_axis'] === null ? '' : 'gpt-state-yellow',
            axisLeft: results['os_axis'] === null ? '' : 'gpt-state-yellow',
            allergies: allergiesUpdated ? 'gpt-state-yellow' : '',
            allergiesNone: allergiesNoneUpdated ? 'gpt-state-yellow' : '',
            glcPastDropsValues: glcPastDropsValuesUpdated ? 'gpt-state-yellow' : '',
            omdHistoryValues: omdHistoryValuesUpdated ? 'gpt-state-yellow' : '',
            ongoingValues: ongoingValuesUpdated ? 'gpt-state-yellow' : '',
            ongoingHasSameDrops: hasSameDrops === false ? '' : 'gpt-state-yellow',
            ongoingLetterIsNotClear: letterIsNotClear === false ? '' : 'gpt-state-yellow',
            dhxOd: (results['dhx_od'] && results['dhx_od'].toString() === 'true') ? 'gpt-state-yellow' : '',
            dhxOs: (results['dhx_os'] && results['dhx_os'].toString() === 'true') ? 'gpt-state-yellow' : '',
            odQuestion: (results['od_question'] && results['od_question'].toString() === 'true') ? 'gpt-state-yellow' : '',
            cuOmd: (results['cu_omd'] && results['cu_omd'].toString() === 'true') ? 'gpt-state-yellow' : '',
            fhx: fhxUpdated ? 'gpt-state-yellow' : '',
            asthma: asthmaUpdated ? 'gpt-state-yellow' : '',
            heart_block: heartBlockUpdated ? 'gpt-state-yellow' : '',
            uveitis_od: uveitisOdUpdated ? 'gpt-state-yellow' : '',
            uveitis_os: uveitisOsUpdated ? 'gpt-state-yellow' : '',
            pds_od: pdsOdUpdated ? 'gpt-state-yellow' : '',
            pds_os: pdsOsUpdated ? 'gpt-state-yellow' : '',
            hx_stroke: hxStrokeUpdated ? 'gpt-state-yellow' : '',
            pxf_od: pxfOdUpdated ? 'gpt-state-yellow' : '',
            pxf_os: pxfOsUpdated ? 'gpt-state-yellow' : '',
            rr_cat_post_op: catPostOpUpdated ? 'gpt-state-yellow': '',
            rr_cataract: cataractUpdated ? 'gpt-state-yellow': '',
            rr_erm: ermUpdated ? 'gpt-state-yellow': '',
            rr_amd: amdUpdated ? 'gpt-state-yellow': '',
            rr_dm: dmUpdated ? 'gpt-state-yellow': '',
            rr_optic_nerve: opticNerveUpdated ? 'gpt-state-yellow': '',
            rr_glc_suspect: glcSuspectUpdated ? 'gpt-state-yellow': '',
            rr_glc: glcUpdated ? 'gpt-state-yellow': '',
            rr_narrow_angles: narrowAnglesUpdated ? 'gpt-state-yellow': '',
            fuNumber: results['fu_number'] === null ? '' : 'gpt-state-yellow',
            fuPeriod: results['fu_period'] === null ? '' : 'gpt-state-yellow',
            isFuUnknown: results['pt_follow_up'] !== 'unknown' ? '' : 'gpt-state-yellow',
        })
    }

    const handleSocketOnMessage = (text: string) => {
        let outputStr = '';
        let metaStr = '';
        try {
            const obj = JSON.parse(text);
            outputStr = obj['message']['output'];
            metaStr = obj['message']['meta'];

            setMessageHistory(h => {
                const m = [...h];
                if (m.length > 0) {
                    m[m.length - 1].output = outputStr;
                    m[m.length - 1].meta = metaStr;
                    return m;
                }
                else {
                    return [{ input: inputMessage, output: outputStr, meta: metaStr }];
                }
            });

            setIsAssistantTyping(false);
        }
        catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting GPT output',
            });
        };

        if (outputStr !== '') {
            try {
                // Use regex to extract the JSON object part
                const jsonPattern = /{[^]*}/;
                const jsonMatches = outputStr.match(jsonPattern);

                if (jsonMatches) {
                    const jsonResponse = jsonMatches[0];
                    const outputObj = JSON.parse(jsonResponse);
                    setExamDataValues(outputObj);

                } else {
                    console.log("No JSON object found in the text.");
                    throw new Error("No JSON object found in the text.");
                }
            }
            catch (error) {
                const message = (error instanceof Error) ? error?.message : error;
                Modal.error({
                    className: 'info-modal',
                    content: message as string,
                    title: 'Errors parsing GPT output',
                });
            };
        }
    }

    const connectWebSocket = () => {

        if (webSocketRef.current) {
            console.log(`${webSocketRef.current.readyState} : ${WebSocket.OPEN}`);
        }

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
            console.log('WebSocket already connected');
            return;
        }

        const uuid = uuidv4();
        console.log(uuid);

        const socket = new w3cwebsocket(`${process.env.REACT_APP_WEBSOCKETURL}/gpt/chat/${uuid}`);

        socket.onopen = (): void => {
            console.log('WebSocket connected');
            // Clear the reconnect interval if the connection is successfully established
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };

        socket.onmessage = (message: IMessageEvent): void => {
            console.log('WebSocket: onmessage');
            handleSocketOnMessage(message.data.toString());
        };

        socket.onclose = () => {
            console.log('WebSocket closed');
            // Set up a reconnect interval to attempt reconnection
            setReconnectInterval();
        };

        webSocketRef.current = socket;
    };

    const setReconnectInterval = (): void => {
        // Clear any existing reconnect interval
        clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        // Set a new reconnect interval (e.g., 5 seconds)
        reconnectIntervalRef.current = setInterval(connectWebSocket, 5000);
    };

    useEffect(() => {
        connectWebSocket();

        // Function to update the window height on window resize
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        // Add event listener to window resize
        window.addEventListener("resize", handleResize);

        // Get deidentified Referral Letter
        handleReset();

        // Get Gpt response
        getAutoGptResponse();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);

            // Close the WebSocket connection when the component unmounts
            if (webSocketRef.current) {
                webSocketRef.current.close();
            }
            // Clear the reconnect interval when the component unmounts
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messageHistory]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    const formatMessageContent = (content: string): string => {
        return content;
    }

    const sendMessage = () => {

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {

            console.log('WebSocket: sendMessage');
            webSocketRef.current.send(JSON.stringify({
                'message': {
                    'exam_id': examId,
                    'input': inputMessage,
                    'history': []
                }
            }));
            setMessageHistory(h => {
                return [{ input: inputMessage, output: '', meta: '' }];
            });
            setIsAssistantTyping(true);
            setGptResultState({
                iopRight: '',
                iopLeft: '',
                applanation: '',
                sideRightBCVA: '',
                sideLeftBCVA: '',
                sideRightVertCD: '',
                sideLeftVertCD: '',
                pachsRight: '',
                pachsLeft: '',
                sphereRight: '',
                sphereLeft: '',
                cylinderRight: '',
                cylinderLeft: '',
                axisRight: '',
                axisLeft: '',
                allergies: '',
                allergiesNone: '',
                glcPastDropsValues: '',
                omdHistoryValues: '',
                ongoingValues: '',
                ongoingHasSameDrops: '',
                ongoingLetterIsNotClear: '',
                fhx: '', 
                asthma: '',
                heart_block: '',
                uveitis_od: '',
                uveitis_os: '',
                pds_od: '',
                pds_os: '',
                hx_stroke: '',  
                pxf_od: '',
                pxf_os: '',
                comorbiditiesNone: '',
                rr_cat_post_op: '',
                rr_cataract: '',
                rr_erm: '',
                rr_amd: '',
                rr_dm: '',
                rr_optic_nerve: '',
                rr_glc_suspect: '',
                rr_glc: '',
                rr_narrow_angles: '',
                fuNumber: '',
                fuPeriod: '',
                isFuUnknown: '',
            })
        }
    }

    const onPDFLoadSuccess = (numPages: number) => {
    }

    const onPDFLoadError = () => {
    }

    const onClose = () => {
    }

    const divStyle = {
        height: `${windowHeight - 100}px`,
    };

    const handleGenerate = async () => {
        try {
            const result = await dispatch(generateDeidentifiedReferralLetter()).unwrap();
            if (result && result.success) {
                // Get deidentified Referral Letter
                handleReset();
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors generating deidentified referral letter',
            });
        }
    }

    const getAutoGptResponse = async () => {

        try {
            const result = await dispatch(fetchAutoGptResponse()).unwrap();
            if (result && result.success) {
                if (result.gpt_response !== '') {
                    handleSocketOnMessage(result.gpt_response);
                }
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {
            
        }
    }

    const handleReset = async () => {

        try {
            const result = await dispatch(fetchReferralLetterAnalysis()).unwrap();
            if (result && result.success) {
                setInputMessage(result.deidentified_referral_letter);
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting deidentified referral letter',
            });
        }
    }

    const handleAddGPTGeneratedValuesToExam = async () => {
        console.log('handleAddGPTGeneratedValuesToExam: messageHistory');
        console.log(messageHistory);
        if (messageHistory.length > 0) {
            // When an MB OD/Tech adds specific values in the 1st set of IOP fields, and RCT+additional
            // IOP values are entered in the upload interface, we want those 1st values to persist and
            // overwrite any values that GPT generate.
            // When OD/Tech does not manually enter the 1st set of IOP values, we want to use the GPT
            // response from the referral letter to populate the values into PEI.
            const odIopValue = (isManitoba && currentOdIop) ? currentOdIop : iopRight ?? '';
            const osIopValue = (isManitoba && currentOsIop) ? currentOsIop : iopLeft ?? '';
            const applanationValue = (isManitoba && currentApplanaction) ? currentApplanaction : applanation ?? '';
            if (messageHistory[0].output !== '') {
                try {
                    const result = await dispatch(postReferralLetterAnalysisResults({
                        value: {
                            od_bcva: sideRightBCVA,
                            os_bcva: sideLeftBCVA,
                            od_iop: odIopValue,
                            os_iop: osIopValue,
                            iop_instrument: applanationValue,
                            od_cd: sideRightVertCD ?? '',
                            os_cd: sideLeftVertCD ?? '',
                            od_pachs: pachsRight ?? '',
                            os_pachs: pachsLeft ?? '',
                            od_sphere: isNumeric(sphereRight) ? Number.parseFloat(`${sphereRight}`).toFixed(2) : '',
                            os_sphere: isNumeric(sphereLeft) ? Number.parseFloat(`${sphereLeft}`).toFixed(2) : '',
                            od_cylinder: isNumeric(cylinderRight) ? Number.parseFloat(`${cylinderRight}`).toFixed(2) : '',
                            os_cylinder: isNumeric(cylinderLeft) ? Number.parseFloat(`${cylinderLeft}`).toFixed(2) : '',
                            od_axis: axisRight ?? '',
                            os_axis: axisLeft ?? '',
                            allergies: allergies,
                            allergies_none: String(allergiesNone) === 'true',
                            comorbidities_none: String(comorbiditiesNone) === 'true',
                            fhx: comorbidities && comorbidities.hasOwnProperty('fhx') ? String(comorbidities.fhx) === 'true'  : false,
                            asthma: comorbidities && comorbidities.hasOwnProperty('asthma') ? String(comorbidities.asthma ) === 'true': false,
                            heart_block: comorbidities && comorbidities.hasOwnProperty('heart_block') ? String(comorbidities.heart_block) === 'true' : false,
                            uveitis_od: comorbidities && comorbidities.hasOwnProperty('uveitis_od') ? String(comorbidities.uveitis_od) === 'true' : false,
                            uveitis_os: comorbidities && comorbidities.hasOwnProperty('uveitis_os') ? String(comorbidities.uveitis_os) === 'true' : false,
                            pds_od: comorbidities && comorbidities.hasOwnProperty('pds_od') ? String(comorbidities.pds_od) === 'true' : false,
                            pds_os: comorbidities && comorbidities.hasOwnProperty('pds_os') ? String(comorbidities.pds_os) === 'true' : false,
                            hx_stroke: comorbidities && comorbidities.hasOwnProperty('hx_stroke') ? String(comorbidities.hx_stroke) === 'true' : false, 
                            pxf_od: comorbidities && comorbidities.hasOwnProperty('pxf_od') ? String(comorbidities.pxf_od) === 'true' : false,
                            pxf_os: comorbidities && comorbidities.hasOwnProperty('pxf_os') ? String(comorbidities.pxf_os) === 'true' : false,
                            dhx_od: String(dhxOd) === 'true',
                            dhx_os: String(dhxOs) === 'true',
                            od_question: String(odQuestion) === 'true',
                            cu_omd: String(cuOmd) === 'true',
                            rr_cat_post_op: chiefComplaints && chiefComplaints.hasOwnProperty('rr_cat_post_op') ? String(chiefComplaints.rr_cat_post_op) === 'true' : false,
                            rr_cataract: chiefComplaints && chiefComplaints.hasOwnProperty('rr_cataract')  ? String(chiefComplaints.rr_cataract) === 'true' : false,
                            rr_erm: chiefComplaints && chiefComplaints.hasOwnProperty('rr_erm')  ? String(chiefComplaints.rr_erm) === 'true' : false, 
                            rr_amd: chiefComplaints && chiefComplaints.hasOwnProperty('rr_amd')  ? String(chiefComplaints.rr_amd) === 'true' : false,
                            rr_dm: chiefComplaints && chiefComplaints.hasOwnProperty('rr_dm') ? String(chiefComplaints.rr_dm) === 'true' : false,
                            rr_optic_nerve: chiefComplaints && chiefComplaints.hasOwnProperty('rr_optic_nerve') ? String(chiefComplaints.rr_optic_nerve) === 'true' : false,
                            rr_glc_suspect: chiefComplaints && chiefComplaints.hasOwnProperty('rr_glc_suspect') ? String(chiefComplaints.rr_glc_suspect) === 'true' : false,
                            rr_glc: chiefComplaints && chiefComplaints.hasOwnProperty('rr_glc') ? String(chiefComplaints.rr_glc) === 'true' : false,
                            rr_narrow_angles: chiefComplaints && chiefComplaints.hasOwnProperty('rr_narrow_angles') ? String(chiefComplaints.rr_narrow_angles) === 'true' : false,
                            fu_number: fuNumber ?? '',
                            fu_letter: fuPeriod === 'month' ? false: true,
                            is_fu_unknown: isFuUnknown,
                        }
                    })).unwrap();
                    if (result && result.success) {
                        Modal.info({
                            className: 'info-modal',
                            content: 'Setting deidentified referral letter results succeeded',
                            title: 'Info',
                        });
                        if (examId !== null) {

                            setGptResultState(s => {
                                const ns = {
                                    iopRight: s['iopRight'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['iopRight'],
                                    iopLeft: s['iopLeft'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['iopLeft'],
                                    applanation: s['applanation'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['applanation'],
                                    sideRightBCVA: s['sideRightBCVA'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['sideRightBCVA'],
                                    sideLeftBCVA: s['sideLeftBCVA'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['sideLeftBCVA'],
                                    sideRightVertCD: s['sideRightVertCD'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['sideRightVertCD'],
                                    sideLeftVertCD: s['sideLeftVertCD'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['sideLeftVertCD'],
                                    pachsRight: s['pachsRight'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['pachsRight'],
                                    pachsLeft: s['pachsLeft'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['pachsLeft'],
                                    sphereRight: s['sphereRight'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['sphereRight'],
                                    sphereLeft: s['sphereLeft'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['sphereLeft'],
                                    cylinderRight: s['cylinderRight'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['cylinderRight'],
                                    cylinderLeft: s['cylinderLeft'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['cylinderLeft'],
                                    axisRight: s['axisRight'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['axisRight'],
                                    axisLeft: s['axisLeft'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['axisLeft'],
                                    allergies: s['allergies'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['allergies'],
                                    allergiesNone: s['allergiesNone'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['allergiesNone'],
                                    glcPastDropsValues: s['glcPastDropsValues'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['glcPastDropsValues'],
                                    omdHistoryValues: s['omdHistoryValues'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['omdHistoryValues'],
                                    ongoingValues: s['ongoingValues'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['ongoingValues'],
                                    ongoingHasSameDrops: s['ongoingHasSameDrops'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['ongoingHasSameDrops'],
                                    ongoingLetterIsNotClear: s['ongoingLetterIsNotClear'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['ongoingLetterIsNotClear'],
                                    dhxOd: s['dhxOd'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['dhxOd'],
                                    dhxOs: s['dhxOs'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['dhxOs'],
                                    odQuestion: s['odQuestion'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['odQuestion'],
                                    cuOmd: s['cuOmd'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['cuOmd'],
                                    fhx: s['fhx'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['fhx'],
                                    asthma: s['asthma'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['asthma'],
                                    heart_block: s['heart_block'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['heart_block'],
                                    uveitis_od: s['uveitis_od'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['uveitis_od'],
                                    uveitis_os: s['uveitis_os'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['uveitis_os'],
                                    pds_od: s['pds_od'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['pds_od'],
                                    pds_os: s['pds_os'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['pds_os'],
                                    hx_stroke: s['hx_stroke'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['hx_stroke'],
                                    pxf_od: s['pxf_od'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['pxf_od'],
                                    comorbiditiesNone: s['comorbiditiesNone'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['comorbiditiesNone'],
                                    rr_cat_post_op: s['rr_cat_post_op'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['rr_cat_post_op'],
                                    rr_cataract: s['rr_cataract'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['rr_cataract'],
                                    rr_erm: s['rr_erm'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['rr_erm'],
                                    rr_amd: s['rr_amd'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['rr_amd'],
                                    rr_dm: s['rr_dm'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['rr_dm'],
                                    rr_optic_nerve: s['rr_optic_nerve'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['rr_optic_nerve'],
                                    rr_glc_suspect: s['rr_glc_suspect'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['rr_glc_suspect'],
                                    rr_glc: s['rr_glc'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['rr_glc'],
                                    rr_narrow_angles: s['rr_narrow_angles'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['rr_narrow_angles'],
                                    fuNumber: s['fuNumber'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['fuNumber'],
                                    fuPeriod: s['fuPeriod'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['fuPeriod'],
                                    isFuUnknown: s['isFuUnknown'] === 'gpt-state-yellow' ? 'gpt-state-green' : s['isFuUnknown'],
                                }
                                return ns;
                            });
            
                            await dispatch(editExamRequest(examId, history));
                        }
                    }
                    else {
                        throw new Error(result?.error);
                    }

                } catch (error) {

                    setGptResultState(s => {
                        const ns = {
                            iopRight: s['iopRight'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['iopRight'],
                            iopLeft: s['iopLeft'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['iopLeft'],
                            applanation: s['applanation'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['applanation'],
                            sideRightBCVA: s['sideRightBCVA'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['sideRightBCVA'],
                            sideLeftBCVA: s['sideLeftBCVA'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['sideLeftBCVA'],
                            sideRightVertCD: s['sideRightVertCD'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['sideRightVertCD'],
                            sideLeftVertCD: s['sideLeftVertCD'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['sideLeftVertCD'],
                            pachsRight: s['pachsRight'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['pachsRight'],
                            pachsLeft: s['pachsLeft'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['pachsLeft'],
                            sphereRight: s['sphereRight'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['sphereRight'],
                            sphereLeft: s['sphereLeft'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['sphereLeft'],
                            cylinderRight: s['cylinderRight'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['cylinderRight'],
                            cylinderLeft: s['cylinderLeft'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['cylinderLeft'],
                            axisRight: s['axisRight'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['axisRight'],
                            axisLeft: s['axisLeft'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['axisLeft'],
                            allergies: s['allergies'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['allergies'],
                            allergiesNone: s['allergiesNone'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['allergiesNone'],
                            glcPastDropsValues: s['glcPastDropsValues'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['glcPastDropsValues'],
                            omdHistoryValues: s['omdHistoryValues'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['omdHistoryValues'],
                            ongoingValues: s['ongoingValues'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['ongoingValues'],
                            ongoingHasSameDrops: s['ongoingHasSameDrops'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['ongoingHasSameDrops'],
                            ongoingLetterIsNotClear: s['ongoingLetterIsNotClear'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['ongoingLetterIsNotClear'],
                            dhxOd: s['dhxOd'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['dhxOd'],
                            dhxOs: s['dhxOs'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['dhxOs'],
                            odQuestion: s['odQuestion'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['odQuestion'],
                            cuOmd: s['cuOmd'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['cuOmd'],
                            fhx: s['fhx'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['fhx'],
                            asthma: s['asthma'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['asthma'],
                            heart_block: s['heart_block'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['heart_block'],
                            uveitis_od: s['uveitis_od'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['uveitis_od'],
                            uveitis_os: s['uveitis_os'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['uveitis_os'],
                            pds_od: s['pds_od'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['pds_od'],
                            pds_os: s['pds_os'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['pds_os'],
                            hx_stroke: s['hx_stroke'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['hx_stroke'],
                            pxf_od: s['pxf_od'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['pxf_od'],
                            comorbiditiesNone: s['comorbiditiesNone'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['comorbiditiesNone'],
                            rr_cat_post_op: s['rr_cat_post_op'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['rr_cat_post_op'],
                            rr_cataract: s['rr_cataract'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['rr_cataract'],
                            rr_erm: s['rr_erm'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['rr_erm'], 
                            rr_amd: s['rr_amd'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['rr_amd'], 
                            rr_dm: s['rr_dm'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['rr_dm'],  
                            rr_optic_nerve: s['rr_optic_nerve'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['rr_optic_nerve'], 
                            rr_glc_suspect: s['rr_glc_suspect'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['rr_glc_suspect'],
                            rr_glc: s['rr_glc'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['rr_glc'],  
                            rr_narrow_angles: s['rr_narrow_angles'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['rr_narrow_angles'],
                            fuNumber: s['fuNumber'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['fuNumber'],
                            fuPeriod: s['fuPeriod'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['fuPeriod'],
                            isFuUnknown: s['isFuUnknown'] === 'gpt-state-yellow' ? 'gpt-state-red' : s['isFuUnknown'],
                        }
                        return ns;
                    });

                    const message = (error instanceof Error) ? error?.message : error;
                    Modal.error({
                        className: 'info-modal',
                        content: message as string,
                        title: 'Errors setting deidentified referral letter results',
                    });
                }
            }
        }
    }

    const extractRx = (inputString: string) => {
        if (inputString !== null) {
            try {
                const f = parseFloat(inputString)
                return f;
            }
            catch (error) {

            }
        }
        return '';
    }

    const extractAxis = (inputString: string) => {
        return inputString;
    }

    const extractGlcPastDropsValues = (input: IUntilYesterdayItem[]) => {
        return [...input];
    }

    const extractOmdHistoryValues = (input: IOmdHistoryItem[]) => {
        return [...input];
    }

    const extractOngoingValues = (input: IOngoingItem[]) => {
        return [...input];
    }

    const extractPachs = (inputString: string) => {
        if (Array.isArray(inputString)) {
            if (inputString.length > 0) {
                return inputString[0];
            }
        }
        if (typeof inputString == "string" || typeof inputString == "number") {
            return inputString;
        }
        return '';
    }

    const extractVertCD = (inputString: string) => {
        if (inputString !== null) {
            try {
                const f = parseFloat(inputString)
                return f;
            }
            catch (error) {

            }
        }
        return null;
    }

    const extractApplanationValue = (inputString: string) => {
        if (inputString) {
            // APPLANATION_OPTIONS = (('applanation', 'App'), ('tonopen', 'Tono'), ('noncontact', 'NC'), ('icare', 'Icare'), ('other', 'Other'))
            const pattern = /[^a-zA-Z0-9]/g;
            const cleanString = inputString.replace(pattern, '').toLowerCase();
    
            const applanationOptions = ['applanation', 'tonopen', 'noncontact', 'icare', 'other'];
            for (const value of applanationOptions) {
                if (cleanString.includes(value)) {
                    return value;
                }
            }
            return 'other';
        }
        return '';
    }

    const setComorbiditiesProperty = (field: string, val: boolean) => {
        setComorbidities(prevComorbidities => ({
            ...prevComorbidities,
            [field]: val,
        }))
    }

    const setChiefComplaintsProperty = (field: string, val: boolean) => {
        setChiefComplaints(prevChiefComplaints => ({
            ...prevChiefComplaints,
            [field]: val,
        }))
    }

    return (
        <Spin
            className='loading-spinner'
            size='large'
            spinning={apiStatus === 'loading'}
        >
            <div className="chat-container" style={divStyle}>
                <Row>
                    <Col
                        span={12}
                        className={'pdfViewerModal no-overflow padding-col'}>
                        <PDFViewer
                            file={currentPhotoURL.replace('png', 'pdf')}
                            onPDFLoadSuccess={onPDFLoadSuccess}
                            onPDFLoadError={onPDFLoadError}
                            onClose={onClose}
                            isGPTTab={true}
                        />
                    </Col>
                    <Col
                        span={12}>
                        <GptUI
                            messageHistory={messageHistory}
                            inputMessage={inputMessage}
                            setInputMessage={setInputMessage}
                            sendMessage={sendMessage}
                            formatMessageContent={formatMessageContent}
                            isAssistantTyping={isAssistantTyping}
                            messagesEndRef={messagesEndRef}
                        />
                        <PatientExamChiefComplaintIC
                            disabled={true}
                            shouldAddBooleanOptions={true}
                        />
                        <GptPatientExamChiefComplaintIC
                            chiefComplaints={chiefComplaints}
                            onChiefComplaintsChange={(field: string, val: boolean) => { setChiefComplaintsProperty(field, val); }}
                            gptResultState={gptResultState}
                        />
                        <GptPatientExamMetrics
                            disabled={false}
                            iopRight={iopRight}
                            iopLeft={iopLeft}
                            sideIopRightAim={sideIopRightAim}
                            sideIopLeftAim={sideIopLeftAim}
                            onIopRightChange={setIopRight}
                            onIopLeftChange={setIopLeft}
                            applanation={applanation}
                            onApplanationSelect={setApplanation}
                            sideRightBCVA={sideRightBCVA}
                            sideLeftBCVA={sideLeftBCVA}
                            onSideRightBCVASelect={setSideRightBCVA}
                            onSideLeftBCVASelect={setSideLeftBCVA}
                            sideRightVertCD={sideRightVertCD}
                            sideLeftVertCD={sideLeftVertCD}
                            onVertCDRightChange={setSideRightVertCD}
                            onVertCDLeftChange={setSideLeftVertCD}
                            pachsRight={pachsRight}
                            pachsLeft={pachsLeft}
                            onPachsRightChange={setPachsRight}
                            onPachsLeftChange={setPachsLeft}
                            sideRightSphere={sphereRight}
                            sideLeftSphere={sphereLeft}
                            onSphereRightChange={setSphereRight}
                            onSphereLeftChange={setSphereLeft}
                            sideRightCylinder={cylinderRight}
                            sideLeftCylinder={cylinderLeft}
                            onCylinderRightChange={setCylinderRight}
                            onCylinderLeftChange={setCylinderLeft}
                            sideRightAxis={axisRight}
                            sideLeftAxis={axisLeft}
                            onAxisRightChange={setAxisRight}
                            onAxisLeftChange={setAxisLeft}
                            gptResultState={gptResultState}
                        />
                        <GptFollowUp
                            disabled={false}
                            fuNumber={fuNumber}
                            onFuNumberChange={setFuNumber}
                            fuPeriod={fuPeriod}
                            onFuPeriodSelect={setFuPeriod}
                            isFuUnknown={isFuUnknown}
                            onFuUnknownChange={setIsFuUnknown}
                            gptResultState={gptResultState}
                        />
                        <Row>
                            <Col span={12} className='padding-col'>
                                <div>PEI</div>
                                <PatientExamOMDHistory disabled={true} />
                            </Col>
                            <Col span={12} className='padding-col'>
                                <div>GPT</div>
                                <GptPatientExamOMDHistory 
                                    omdHistoryValues={omdHistoryValues}
                                    gptResultState={gptResultState}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} className='padding-col'>
                                <div>PEI</div>
                                <PatientExamUntilYesterday disabled={true} />
                            </Col>
                            <Col span={12} className='padding-col'>
                                <div>GPT</div>
                                <GptPatientExamUntilYesterday 
                                    untilYesterdayValues={glcPastDropsValues}
                                    gptResultState={gptResultState}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} className='padding-col'>
                                <div>PEI</div>
                                <PatientExamOngoing disabled={true} />
                            </Col>
                            <Col span={12} className='padding-col'>
                                <div>GPT</div>
                                <GptPatientExamOngoing
                                    ongoingValues={ongoingValues}
                                    ongoingHasSameDrops={true}
                                    ongoingLetterIsNotClear={ongoingLetterIsNotClear}
                                    gptResultState={gptResultState}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} className='padding-col'>
                                <div>PEI</div>
                                <PatientExamAllergies disabled={true} />
                                <ReadonlyAdminSelectors />
                            </Col>
                            <Col span={12} className='padding-col'>
                                <div>GPT</div>
                                <GptPatientExamAllergies
                                    allergies={allergies}
                                    onAllergiesChange={(v) => { setAllergies(v.target.value); }}
                                    allergiesNone={allergiesNone}
                                    onAllergiesNoneChange={(v) => { setAllergiesNone(v.target.checked); }}
                                    gptResultState={gptResultState}
                                />
                                <GptAdminSelectors 
                                    dhxOd={dhxOd}
                                    onDhxOdChange={(v) => { setDhxOd(v); }}
                                    dhxOs={dhxOs}
                                    onDhxOsChange={(v) => { setDhxOs(v); }}
                                    odQuestion={odQuestion}
                                    onOdQuestionChange={(v) => { setOdQuestion(v); }}
                                    cuOmd={cuOmd}
                                    onCuOmdChange={(v) => { setCuOmd(v); }}
                                    gptResultState={gptResultState}
                                />
                            </Col>
                        </Row>
                        <Row >
                            <Col span={12} className='pei-comorbidities-col'>
                                <PatientExamComorbidities disabled={true} inGptTab={true} />
                            </Col>
                            <Col span={12} className='pei-comorbidities-col'>
                                <GptPatientExamComorbidities
                                    comorbidities={comorbidities}
                                    onComorbiditiesChange={(field: string, val: boolean) => { setComorbiditiesProperty(field, val); }}
                                    comorbiditiesNone={comorbiditiesNone}
                                    onComorbiditiesNoneChange={(v) => { setComorbiditiesNone(v.target.checked); }}
                                    gptResultState={gptResultState}
                                />
                                

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    className={''}
                                    onClick={handleGenerate}
                                    size="large"
                                >
                                    Generate
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className={''}
                                    onClick={handleReset}
                                    type="primary"
                                    size="large"
                                >
                                    Reset
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className={''}
                                    onClick={handleAddGPTGeneratedValuesToExam}
                                    type="primary"
                                    size="large"
                                >
                                    Add GPT Generated Values to Exam
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Spin>)
}

export default Gpt;