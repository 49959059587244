import { Tag, Card } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { setOverrideActions, setPreReviewDataValue } from '../../reducers/pre-review-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/patient-exam-omdr-re-text-injector.scss';

const { CheckableTag } = Tag;

const PatientExamOMDRRETextInjector = ({ disabled }: { disabled: boolean }) => {
    const omdNoteOptions = useCare1AppSelector(store => store.options.omdNoteOptions);
    const selectedOmdNoteOptions = useCare1AppSelector(store => store.examData.selected_omd_note_options);
    const notesToOdGp = useCare1AppSelector(store => store.examData.omd_gp);
    const isAdmin = useCare1AppSelector(store => store.user.isADMIN);

    const ALERT_ID = 26;

    const dispatch = useCare1AppDispatch();

    const handleChange = (id: number, note_to_od: string, checked: boolean, name: string) => {
        if(checked){
            dispatch(setExamDataValue('selected_omd_note_options', [...selectedOmdNoteOptions, id]));
            if(id === ALERT_ID){
                !isAdmin && dispatch(setExamDataValue('omd_gp', `${note_to_od.trim()} ${notesToOdGp.trim()}`.trim()));
            } else {
                !isAdmin && dispatch(setExamDataValue('omd_gp', `${notesToOdGp.trim()} ${note_to_od.trim()}`.trim()));
            }
        } else {
            dispatch(setExamDataValue('selected_omd_note_options', selectedOmdNoteOptions.filter(option => option !== id)));
            !isAdmin && dispatch(setExamDataValue('omd_gp', notesToOdGp.replace(note_to_od, '').trim()));
        }
        isAdmin && name === 'AGREE' && dispatch(setOverrideActions(['AGREE', checked]));
        isAdmin && name === 'ALERT' && dispatch(setOverrideActions(['ALERT', checked]));
        isAdmin && dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
    }

    return (
        <Card className={`component-input-card text-injector${disabled ? ' is-disabled' : ''}`} bordered={false}>
            <div className='heading'>{`Text Injector`}</div>
            <div className='tags-section'>
                {
                    omdNoteOptions.map(({id, name, note_to_od}) =>
                        <CheckableTag
                            key={id}
                            className={`tag-${id}${disabled ? ' is-disabled' : ''}`}
                            checked={selectedOmdNoteOptions.indexOf(id) > -1}
                            onChange={(checked) => handleChange(id, note_to_od, checked, name)}
                        >
                            {name}
                        </CheckableTag>
                    )
                }
            </div>
        </Card>
    );
}

export default PatientExamOMDRRETextInjector ;