import * as Constants from '../constants';
import { saveAs } from 'file-saver';
import { isOpticNervePatient, patientIsGlaucoma, patientIsPlaquenil } from './diagnosis-convert';
import { getBackendMediaUrl } from './media-image-convert';
import { IDropzoneInitialState } from '../reducers/dropzone-slice';
import { HistoryDropzoneField, IExamsWithLeftOctRnfl, IExamsWithRightOctRnfl, IIopHistoryItem, IOmdHistoryItem, IOmdReiewerOption, IOngoingItem,
    IUntilYesterdayItem, IOptionItem } from '../reducers/patient-exam-slice';
import { IUser } from '../reducers/user-slice';
import { AppDispatch, RootState } from "../stores/retina-enabled-store";
import { IOmdOption, ITxalgo3Option } from '../reducers/options-slice';

type MarkUp = {
    word: string,
    color: null | string,
}

/*  Parse filename from content-disposition header. If none exists -->
    use default letter.docx filename, returns a string */
export function getDocxFilename(response: Response){
    let filename = "";
    let filenameRegex = /.*filename= *"([^"\r]+)"/;
    let contentDisposition = response.headers.get('content-disposition');
    let match = contentDisposition ? filenameRegex.exec(contentDisposition) : null;
    if(match != null && match[1]) {
        filename = match[1];
    } else {
        filename = "letter.docx";
    }
    return filename;
}

// Use file-saver to bring blob to DOM, and prompt download
export function downloadBinaryFile(file: string | Blob, filename: string) {
    saveAs(file, filename);
}

export function createEmptyPhotoUrls() {
    return {
        [Constants.RIGHT_FUNDUS_FIELD]: '',
        [Constants.RIGHT_STEREO_FIELD]: '',
        [Constants.RIGHT_OCT_MACULA_FIELD]: '',
        [Constants.RIGHT_OCT_RNFL_FIELD]: '',
        [Constants.RIGHT_VF_FIELD]: '',
        [Constants.LEFT_FUNDUS_FIELD]: '',
        [Constants.LEFT_STEREO_FIELD]: '',
        [Constants.LEFT_OCT_MACULA_FIELD]: '',
        [Constants.LEFT_OCT_RNFL_FIELD]: '',
        [Constants.LEFT_VF_FIELD]: '',
    };
}

export function createEmptyPastPhotos() {
    const { PAST_LEFT_FUNDUS_PHOTO, PAST_LEFT_FUNDUS_PHOTO_DATE, PAST_LEFT_STEREO_PHOTO,PAST_LEFT_STEREO_PHOTO_DATE,
        PAST_LEFT_OCT_PHOTO, PAST_LEFT_OCT_PHOTO_DATE, PAST_LEFT_OCT_RNFL_PHOTO, PAST_LEFT_OCT_RNFL_PHOTO_DATE,
        PAST_LEFT_VF_PHOTO, PAST_LEFT_VF_PHOTO_DATE, PAST_RIGHT_FUNDUS_PHOTO, PAST_RIGHT_FUNDUS_PHOTO_DATE,
        PAST_RIGHT_STEREO_PHOTO,PAST_RIGHT_STEREO_PHOTO_DATE, PAST_RIGHT_OCT_PHOTO, PAST_RIGHT_OCT_PHOTO_DATE,
        PAST_RIGHT_OCT_RNFL_PHOTO, PAST_RIGHT_OCT_RNFL_PHOTO_DATE, PAST_RIGHT_VF_PHOTO, PAST_RIGHT_VF_PHOTO_DATE,
    } = Constants;

    return {
        [PAST_LEFT_FUNDUS_PHOTO]: '',
        [PAST_LEFT_FUNDUS_PHOTO_DATE]: '',
        [PAST_LEFT_STEREO_PHOTO]: '',
        [PAST_LEFT_STEREO_PHOTO_DATE]: '',
        [PAST_LEFT_OCT_PHOTO]: '',
        [PAST_LEFT_OCT_PHOTO_DATE]: '',
        [PAST_LEFT_OCT_RNFL_PHOTO]: '',
        [PAST_LEFT_OCT_RNFL_PHOTO_DATE]: '',
        [PAST_LEFT_VF_PHOTO]: '',
        [PAST_LEFT_VF_PHOTO_DATE]: '',

        [PAST_RIGHT_FUNDUS_PHOTO]: '',
        [PAST_RIGHT_FUNDUS_PHOTO_DATE]: '',
        [PAST_RIGHT_STEREO_PHOTO]: '',
        [PAST_RIGHT_STEREO_PHOTO_DATE]: '',
        [PAST_RIGHT_OCT_PHOTO]: '',
        [PAST_RIGHT_OCT_PHOTO_DATE]: '',
        [PAST_RIGHT_OCT_RNFL_PHOTO]: '',
        [PAST_RIGHT_OCT_RNFL_PHOTO_DATE]: '',
        [PAST_RIGHT_VF_PHOTO]: '',
        [PAST_RIGHT_VF_PHOTO_DATE]: '',
    };
}

export function photoDropzoneValidityCheck(photoDropzoneState: IDropzoneInitialState) {
    // The state is the dropzone's portion of the overall state.
    // A dropzone is considered valid if it has a photo and no errors.
    return photoDropzoneState.photo !== '' && !photoDropzoneState.error;
}

// Only RE and IC should filter out the hidden_from_dropdown ods
// OMDC, OMDR and PR should not call this function
export function getODDropdownFiltered(options: IOmdReiewerOption[], od: number | null) {
    return options ? options.filter(
        (option) => option.hidden_from_dropdown === false || option.value === od)
        : [];
}

// Return the OD name (label) in the optometrist options based on the key id (value)
export function getODNameFromOdOptions(odOptions:IOmdReiewerOption[], val: number) {
    const od = odOptions.find(option => option.value === val);
    return od ? od.label : null;
}

export function isOMDHistoryValueAllEmpty(entry: IOmdHistoryItem) {
    let omdNameEmpty = false;
    let diagnosisEmpty = false;

    if (entry && 'history_omd_name' in entry && entry.history_omd_name != null && entry.history_omd_name === '')
        omdNameEmpty = true;

    if (entry && 'history_diagnosis' in entry && entry.history_diagnosis != null && entry.history_diagnosis.length === 0)
        diagnosisEmpty = true;

    return omdNameEmpty && diagnosisEmpty;
}

export function isOMDHistoryValueEmpty(entry: IOmdHistoryItem) {
    if (entry && 'history_omd_name' in entry && entry.history_omd_name != null && entry.history_omd_name === '')
        return true;

    if (entry && 'history_diagnosis' in entry && entry.history_diagnosis != null && entry.history_diagnosis.length === 0)
        return true;

    return false;
}

export function getOMDHistoryValue(user: IUser, historyEntries: IOmdHistoryItem[]) {

    return historyEntries.map((entry) => {
        // if None is selected then the value is {disabled:true}
        if (entry.disabled) {
            return '';
        }

        let omdHistoryStr = `${entry.history_omd_name} ${entry.history_diagnosis} ` +
            `${entry.eye_select ? entry.eye_select.toUpperCase() : ''} ` +
            `${entry.history_date ? entry.history_date : ''} ` +
            `${entry.history_end_date ? entry.history_end_date : ''}`;

        // if all entries are empty, return an empty string, it was ' undefined  ;' before
        if (omdHistoryStr.trim().length === 0) {
            omdHistoryStr = '';
        }
        else {
            if (user.isOMDC || user.isOMDR) {
                omdHistoryStr = `Has seen Dr. ${entry.history_omd_name} in the past for ${entry.history_diagnosis} ` +
                    `${entry.eye_select ? entry.eye_select.toUpperCase() : ''} ` +
                    `(${entry.history_date ? entry.history_date : ''}` +
                    `${entry.history_end_date ? ` - ${entry.history_end_date}` : ''})`;

                omdHistoryStr = omdHistoryStr.replace('()', '');
            }
        }

        return omdHistoryStr;
    })
}

export function isIOPHistoryValueEmpty(entry: IIopHistoryItem) {
    if (entry && 'machine_select' in entry && entry.machine_select != null && entry.machine_select === '')
        return true;

    if (entry && 'iop_history_drops_select' in entry && entry.iop_history_drops_select && entry.iop_history_drops_select.length === 0)
        return true;

    if (entry && 'low_iop' in entry && entry.low_iop != null && entry.low_iop === '')
        return true;

    if (entry && 'eye_select' in entry && entry.eye_select != null && entry.eye_select === '')
        return true;

    return false;
}

export function isRluIOPHistoryValueEmpty(entry: IIopHistoryItem) {

    if (entry && 'iop_history_drops_select' in entry && entry.iop_history_drops_select && entry.iop_history_drops_select.length === 0)
        return true;

    if (entry && 'low_iop' in entry && entry.low_iop != null && entry.low_iop === '')
        return true;

    if (entry && 'eye_select' in entry && entry.eye_select != null && entry.eye_select === '')
        return true;

    return false;
}

export function getIOPHistoryValues(entries: IIopHistoryItem[], dropOptions: string[][],
    procedureOptions: string[][], machineOptions: string[][], isOmd: boolean, isAdmin: boolean) {
    return dropOptions && procedureOptions && machineOptions && entries && entries?.map((entry) => {
        // if None is selected then the value is {disabled:true}
        if (entry.disabled) {
            return [];
        }

        // 1. Find the procedure option
        const selectedProcedures = entry && entry.iop_history_procedure_select ? entry.iop_history_procedure_select?.map((value) => {
            const selectedItem = procedureOptions.find((option) => option[0] === value);
            return `${entry.iop_history_procedure_prefix}${selectedItem ? selectedItem[1] : ''}`;
        }) : [];

        // 2. Find the drops option
        const selectedOptions = entry?.iop_history_drops_select?.map((value) => {
            const selectedItem = dropOptions.find((option) => option[0] === value);
            return `${selectedItem ? selectedItem[1]: ''}`;
        });

        // 3. Find the machine option
        const selectedMachineOption = machineOptions.find((option) => option[0] === entry.machine_select);
        const machineSelect = selectedMachineOption && selectedMachineOption.length > 0 ? selectedMachineOption[1] : '';
        let baselineStringMachineSelect = ''
        if(machineSelect === 'NC'){
            baselineStringMachineSelect = ' via non-contact';
        } else if (machineSelect === 'Other/Unknown' && !isOmd){
            baselineStringMachineSelect = ' via unknown method';
        } else {
            baselineStringMachineSelect = '';
        }

        // 4. Return either the regular or baseline string
        if(!entry.low_iop && !entry.high_iop && !entry.eye_select && !selectedOptions && !machineSelect){
            return '';
        }

        if (isOmd || isAdmin) {
            let iopStr = '';
            let iopValue = ''
            if (entry && entry.low_iop) {
                iopValue += entry.low_iop;
                if (entry && entry.high_iop) {
                    iopValue += `-${entry.high_iop}`;
                }
                iopStr += iopValue;
            }

            if (entry && entry.eye_select) {
                iopStr += ` ${entry.eye_select.toUpperCase()},`
            }

            if (baselineStringMachineSelect) {
                iopStr = iopStr.replace(',', '') + ` ${baselineStringMachineSelect}`;
            }

            if (selectedOptions && selectedOptions.length >=1){
                iopStr += ` on ${selectedOptions.join(' and ')}`;
            }

            if (selectedProcedures && selectedProcedures.length >=1) {
                iopStr += ` ${selectedProcedures.join(', ')}`
            }

            return iopStr;
        } else {
            return `${entry.low_iop}-${entry.high_iop} mmHg ${entry?.eye_select?.toUpperCase()} (${selectedOptions?.join(', ')}) ${selectedProcedures.join(', ')} ${machineSelect}`;
        }
    });
}

export function isUntilYesterdayValueAllEmpty(entry: IUntilYesterdayItem) {
    let selectEmpty = false;
    let eyeSelectEmpty = false;

    if (entry && 'glc_past_drops_select' in entry && entry.glc_past_drops_select != null
        && entry.glc_past_drops_select === '')
        selectEmpty = true;

    if (entry && 'glc_past_drops_eye_select' in entry && entry.glc_past_drops_eye_select != null
        && entry.glc_past_drops_eye_select === '')
        eyeSelectEmpty = true;

    return selectEmpty && eyeSelectEmpty;
}

export function isUntilYesterdayValueEmpty(entry: IUntilYesterdayItem) {
    if (entry && 'glc_past_drops_select' in entry && entry.glc_past_drops_select != null
        && entry.glc_past_drops_select === '')
        return true;

    if (entry && 'glc_past_drops_eye_select' in entry && entry.glc_past_drops_eye_select != null
        && entry.glc_past_drops_eye_select === '')
        return true;

    return false;
}

export function getUntilYesterdayValues(entries: IUntilYesterdayItem[], dropsList: string[][], complianceList: string[][]) {
    return entries && entries?.map((entry) => {
        // if None is selected then the value is {disabled:true}
        if (entry.disabled) {
            return [];
        }

        const selectedDropItem = dropsList.find((option) => option[0] === entry.glc_past_drops_select);
        const selectedComplianceItem = complianceList.find((option) => option[0] === entry.glc_past_drops_compliance_select);
        
        return `${selectedDropItem && selectedDropItem[1]} ${'\xa0'}${entry.glc_past_drops_eye_select && entry.glc_past_drops_eye_select.toUpperCase()} ${'\xa0'}${selectedComplianceItem && selectedComplianceItem[1]}`;
    });
}

export function getUntilYesterdayValuesRlu(entries: IUntilYesterdayItem[], dropsList: string[][]) {
    return entries && entries?.map((entry) => {
        // if None is selected then the value is {disabled:true}
        if (entry.disabled) {
            return [];
        }

        const selectedDropItem = dropsList.find((option) => option[0] === entry.glc_past_drops_select);
        if (!selectedDropItem?.[1] && !entry?.glc_past_drops_eye_select) {
            return [];
        }

        return `${selectedDropItem && selectedDropItem[1] ? selectedDropItem[1] : ''} ${'\xa0'}${entry.glc_past_drops_eye_select && entry.glc_past_drops_eye_select.toUpperCase()}`;
    });
}

export function isOngoingValueAllEmpty(entry: IOngoingItem) {
    let dropSelectEmpty = false;
    let dropEyeSelectEmpty = false;
    
    if (entry && 'glc_current_drops_select' in entry && entry.glc_current_drops_select != null && entry.glc_current_drops_select === '')
        dropSelectEmpty = true;

    if (entry && 'glc_current_drops_eye_select' in entry && entry.glc_current_drops_eye_select != null && entry.glc_current_drops_eye_select === '')
        dropEyeSelectEmpty = true;

    return dropSelectEmpty && dropEyeSelectEmpty;
}

export function isOngoingValueEmpty(entry: IOngoingItem) {
    if (entry && 'glc_current_drops_select' in entry && entry.glc_current_drops_select != null && entry.glc_current_drops_select === '')
        return true;

    if (entry && 'glc_current_drops_eye_select' in entry && entry.glc_current_drops_eye_select != null && entry.glc_current_drops_eye_select === '')
        return true;

    return false;
}

export function isRluOngoingValueEmpty(entry: IOngoingItem) {
    if (entry && 'glc_current_drops_select' in entry && entry.glc_current_drops_select != null && entry.glc_current_drops_select === '')
        return true;

    return false;
}

export const isRluOngoingEmpty = (ongoing: {show: boolean, values: IOngoingItem[]}) =>
    ongoing && Array.isArray(ongoing.values) &&
        ((ongoing.values.length === 1 &&
            isRluOngoingValueEmpty(ongoing.values[0])
        ) || ongoing.values.length === 0)


export function getOngoingValues(selectedVals: IUntilYesterdayItem[] | IOngoingItem[], options: string[][], sameMeds: boolean | undefined) {
    // Use UntilYesterday values if "The same Meds" is selected
    if (sameMeds) {
        const entries = selectedVals as IUntilYesterdayItem[];
        return entries && entries?.map((entry) => {
            // if None is selected then the value is {disabled:true}
            if (entry.disabled) {
                return [];
            }

            const selectedItem = options.find((option) => option[0] === entry.glc_past_drops_select);
            return `${selectedItem && selectedItem[1]} \xa0${entry.glc_past_drops_eye_select && entry.glc_past_drops_eye_select.toUpperCase()}`;
        });
    }
    const entries = selectedVals as IOngoingItem[];

    return entries && entries?.map((entry) => {
        // if None is selected then the value is {disabled:true}
        if (entry.disabled) {
            return [];
        }

        const selectedItem = options.find((option) => option[0] === entry.glc_current_drops_select);
        return `${selectedItem && selectedItem[1]} \xa0${entry.glc_current_drops_eye_select && entry.glc_current_drops_eye_select.toUpperCase()}`;
    });
}

// Return true if any of the recommended fields are empty as per requirements (i.e., at least one eye is filled out)
export const checkEmptyRecommendedFields = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const {examData, diagnosis} = getState();
    const { od_group_practice_province } = examData;
    const opticNerve = examData.rr_optic_nerve;
    const glc = examData.rr_glc;
    const glcS = examData.rr_glc_suspect;
    const narrowAngles = examData.rr_narrow_angles;

    const diagnosisEntries = diagnosis.entries;
    const isOpticNerve = isOpticNervePatient(diagnosisEntries, opticNerve);
    const isPlaquenil = patientIsPlaquenil(diagnosisEntries) || isOpticNerve;

    const isGlaucomaManitoba = od_group_practice_province === 'MB'
                && patientIsGlaucoma(diagnosisEntries, {glc, glcS, narrowAngles});

    return (!examData.od_cd && !examData.os_cd) ||
        (!examData.od_pachs && !examData.os_pachs && !examData.past_od_pachs && !examData.past_os_pachs) ||
        (!examData.od_ishihara && !examData.os_ishihara && isPlaquenil) ||
        (!examData.od_ishihara2 && !examData.os_ishihara2 && isPlaquenil) ||
        (!isGlaucomaManitoba && !examData.od_iop && !examData.os_iop) ||
        (isGlaucomaManitoba &&
            ((examData.od_iop && examData.dtc_values && examData.dtc_values[0] && examData.dtc_values[1] && examData.dtc_values[2]
                && !examData.dtc_values[0].od && !examData.dtc_values[1].od && !examData.dtc_values[2].od)
            || (examData.os_iop && examData.dtc_values && examData.dtc_values[0] && examData.dtc_values[1] && examData.dtc_values[2]
                && !examData.dtc_values[0].os && !examData.dtc_values[1].os && !examData.dtc_values[2].os))) ||
        (!examData.dtc_hour && isGlaucomaManitoba) ||
        (!examData.od_sphere && !examData.os_sphere) ||
        (examData.od_bcva === 'ND' && examData.os_bcva === 'ND') ||
        (examData.gp_refed && !(examData.referral_letter &&
            (!examData.gpReferralUploadLabel || examData.gpReferralUploadLabel === '100')));
}

// Return the warning severity of the BCVA denominator based on its value
export function calculateBCVAWarningColor(bcva: string) {
    if(['ND', '20', '25'].includes(bcva)){
        return null;
    } else if (['30', '40'].includes(bcva)){
        return 'bcva-low-warning';
    } else if(['50', '60', '70', '80'].includes(bcva)){
        return 'bcva-med-warning';
    } else {
        return 'bcva-high-warning';
    }
}

// Determine VF image border color (for OMDC/OMDR/PRvwr)
export function calculateVFBorderColor(ghtValue: string){
    if(!ghtValue){
        return '';
    } else if ( ghtValue === 'wnl'){
        return 'has-border border-green';
    } else if ( ghtValue === 'onl'){
        return 'has-border border-red';
    } else {
        return 'has-border border-yellow';
    }
}

// Determine the OCT RNFL border color
export const getOCTRNFLBorderColorClasses = (currentImage: IExamsWithLeftOctRnfl | IExamsWithRightOctRnfl, side: 'left' | 'right') => {
    let imageSup: null | string = null;
    let imageInf: null | string = null;
    let imageAvg: null | string = null;

    if (side === 'right') {
        const image = currentImage as IExamsWithRightOctRnfl;
        imageSup = image['right_octav_sup'];
        imageInf = image['right_octav_inf'];
        imageAvg = image['right_octav'];
    } else {
        const image = currentImage as IExamsWithLeftOctRnfl;
        imageSup = image['left_octav_sup'];
        imageInf = image['left_octav_inf'];
        imageAvg = image['left_octav'];
    }


    const topBorderColor = imageSup && imageSup !== 'na'
        ? `has-top-border top-border-${imageSup}` : '';
    const bottomBorderColor = imageInf && imageInf !== 'na'
        ? `bottom-border-${imageInf}` : '';
    const sidesBorderColor = imageAvg && imageAvg !== 'na'
        ? `has-side-borders sides-border-${imageAvg}` : '';

    return `${topBorderColor} ${bottomBorderColor} ${sidesBorderColor}`;
}

// Return the warning severity of the IOP based on its value
export function calculateIOPWarningColor(value: string) {
    const iopValue = Number(value);
    const HIGH_IOP_THRESHOLD = 25;
    const MEDIUM_IOP_LOWER_THRESHOULD = 22;
    const MEDIUM_IOP_HIGHER_THRESHOULD = 24;
    if (iopValue >= MEDIUM_IOP_LOWER_THRESHOULD && iopValue <= MEDIUM_IOP_HIGHER_THRESHOULD){
        return 'iop-med-warning';
    } else if (iopValue >= HIGH_IOP_THRESHOLD){
        return 'iop-high-warning';
    } else {
        return '';
    }
}

// Return an array of keyword options that are applicable to the requesting component
export function getTxAlgoKeywordOptions(storeTxAlgoKeyowrds: ITxalgo3Option[], component: string){
    // Note: case option.textfield_od_proposed_plan is not required since there is no longer a
    // component for exam.od_proposed_plan (the field has not been used since 2018).

    switch(component){
        case 'impressions':
            return storeTxAlgoKeyowrds.filter(option => option.textfield_impression === true);
        case 'allergies':
            return storeTxAlgoKeyowrds.filter(option => option.textfield_allergies === true);
        case 'historyMedsExam':
            return storeTxAlgoKeyowrds.filter(
                option => option.textfield_exam_notes === true || option.textfield_pmh === true);
        case 'odNotesAndProposedPlan':
            return storeTxAlgoKeyowrds.filter(option => option.textfield_od_notes === true);
        default:
            return null;
    }
}

// Function to added color coded classnames to keywords in a string.
// This function is called from any component that implements the colored keywords
export function addTxAlgoKeywordColors(keywordOptions: ITxalgo3Option[] | undefined, text: string){

    // Safe guard in case keywordOptions or text is empty or undefined.
    if(!text || !keywordOptions || keywordOptions.length === 0){
        return [];
    }

    let markedUpString: MarkUp[] = [];

    // Create an array of all of the words and non-words
    const wordsArray = text.split(/( |[,.:;!?]|-|\r\n|\n)/);

    // Search every word of the array in the list of keywords and markup any matches.
    wordsArray.forEach(word => {

        // Continue to next word in loop if the char is empty
        if(word === ''){
            return;
        }

        let markUp: MarkUp = {
            word: word,
            color: null,
        }

        // Do not attempt markup if the word is a non-word
        const nonWords = [' ', ',', '.', ':', ';', '!', '-', '\r\n', '\n']
        if(nonWords.indexOf(word) !== -1) {
            // Add the non-word to the string and return to the next word.
            markedUpString.push(markUp);
            return;
        }

        // Attempt to markup the word based on the keyword options
        for(const option of keywordOptions){

            // Create the array of Keyword and synonyms
            const synonyms = option.synonyms.split(';');
            const optionKeyword = option.keyword === '\\\\?' ? '?' : option.keyword;
            option.keyword && synonyms.push(optionKeyword);
            // Search the array for a match to the word
            const found = synonyms.find(synonym => synonym.toLowerCase() === word.toLowerCase());

            // If there is a match add the keyword color code to the word object and exit the loop
            if(found){
                markUp.color = option.inline_color;
                break;
            }
        }

        // Add the word to the string
        markedUpString.push(markUp);
    })

    return markedUpString;
}

// Function to render keyword strings.
// This function is called from any component that implements the colored keywords.
export function renderTxAlgoKeywordString(string: MarkUp){

    // Markup new line if word is a new line
    if(string.word === '\r\n' || string.word === '\n'){
        return ' ';
    }

    // Markup non breaking space if word is a space
    if(string.word === ' '){
        return (<span>&nbsp;</span>)
    }

    return (
        <>
            { string.color
                ?   <>
                        <div className={`tx-algo-keyword ${string.color}`}>
                            {string.word}
                        </div>
                    </>
                : `${string.word}`
            }
        </>
    )
}

export const getUserInitials = (user: IUser) => {
    const firstName = user.firstName;
    const lastName = user.lastName;
    const userName = user.userName;
    let res = "";

    if (!firstName || !lastName) {
        res = (userName.length > 2) ? userName.substring(0, 2) : userName;
    }
    else {
        res = firstName.substring(0, 1) + lastName.substring(0, 1);
    }

    return res.toUpperCase();
}


export const isDiffOngoingDrops = (ongoing: {show: boolean, values: IOngoingItem[]}) => ongoing &&
    ongoing.values && ongoing.values.length > 0 && !('disabled' in ongoing.values[0]);

export const getDropzoneImageData = <T,>(field: HistoryDropzoneField, jsonData: T[]): T[] => {
    try {
        const data = jsonData;
        if (data && data.length > 0) {
            return data.map(exam => ({...exam, [field]: getBackendMediaUrl()! + exam[field as keyof typeof exam]}))
        } else {
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}

// Before &&, use 'or' logic operator to check whether there is one or more empty fields, these fields
// are required fields. After &&, check whether all entires are empty, if they are all empty, that
// means this entry is the default start entry, do not apply red border
export function checkEmptyOMDHistoryFields(entry: IOmdHistoryItem) {
    if ((entry.history_omd_name === '' || entry.history_diagnosis === '') &&
        !Object.values(entry).every((value) => value === '')) {
        return true;
    }
    return false;
}

// Before &&, use 'or' logic operator to check whether there is one or more empty fields, these fields
// are required fields. After &&, use 'and' logic operator to check whether all these fields are empty,
// if they are all empty, that means this entry is the default start entry, do not apply red border
export function checkEmptyIOPHistoryFields(entry: IIopHistoryItem) {
    const { eye_select, low_iop, high_iop, iop_history_drops_select, machine_select,
        iop_history_procedure_prefix, iop_history_procedure_select } = entry;
    const eyeSelectEmpty = eye_select === '';
    const lowIOPEmpty = low_iop === '';
    const highIOPEmpty = high_iop === '';
    const historyDropEmpty = iop_history_drops_select && iop_history_drops_select.length === 0;
    const procedurePrefixEmpty = iop_history_procedure_prefix === '';
    const procedureSelectEmpty = iop_history_procedure_select && iop_history_procedure_select.length === 0;
    const machineEmpty = machine_select === '';

    const procedurePrefixRequired = iop_history_procedure_select && iop_history_procedure_select.length > 0
        && procedurePrefixEmpty;
    const procedureSelectRequired = iop_history_procedure_prefix && procedureSelectEmpty;

    const allFieldsEmpty = eyeSelectEmpty && lowIOPEmpty && highIOPEmpty && historyDropEmpty
        && procedurePrefixEmpty && procedureSelectEmpty && machineEmpty;

    if ((eyeSelectEmpty || lowIOPEmpty || historyDropEmpty || machineEmpty || procedurePrefixRequired
        || procedureSelectRequired) && !allFieldsEmpty) {
        return true;
    }
    return false;
}

export function checkRLUEmptyIOPHistoryFields(entry: IIopHistoryItem) {
    const { eye_select, low_iop, high_iop, iop_history_drops_select} = entry;
    const eyeSelectEmpty = eye_select === '';
    const lowIOPEmpty = low_iop === '';
    const highIOPEmpty = high_iop === '';
    const historyDropEmpty = iop_history_drops_select && iop_history_drops_select.length === 0;    

    const allFieldsEmpty = eyeSelectEmpty && lowIOPEmpty && highIOPEmpty && historyDropEmpty;
    if ((eyeSelectEmpty || lowIOPEmpty || historyDropEmpty) && !allFieldsEmpty) {
        return true;
    }
    return false;
}

export const getOmdName = (omds: IOmdOption[], omdId: number | null) => {
    const secondOmd = omds.find(omd => omd.id === omdId)
    return secondOmd ? `${secondOmd.first_name} ${secondOmd.last_name}` : '';
}

export const isRluIopHistoryEntryEmpty = (entry: IIopHistoryItem) => 
    entry.low_iop === '' &&
    entry.high_iop === '' &&
    entry.eye_select === '' &&
    Array.isArray(entry.iop_history_drops_select) &&
    entry.iop_history_drops_select.length === 0

export const isIopHistoryNoneSelected = (iopHistory: {show: boolean, values: IIopHistoryItem[]} ) =>
    iopHistory && iopHistory.values && iopHistory.values[0] && iopHistory.values[0].disabled


export const isRluIopHistoryEmpty = (iopHistory: {show: boolean, values: IIopHistoryItem[]}) =>
    iopHistory && Array.isArray(iopHistory.values) &&
        ((iopHistory.values.length === 1 &&
            isRluIopHistoryEntryEmpty(iopHistory.values[0])
        ) || iopHistory.values.length === 0)

export const isRluUntilYesterdayEmpty = (untilYesterday: {show: boolean, values: IUntilYesterdayItem[]}) =>
untilYesterday && Array.isArray(untilYesterday.values) &&
    ((untilYesterday.values.length === 1 &&
        isRluUntilYesterdayEntryEmpty(untilYesterday.values[0])
    ) || untilYesterday.values.length === 0)

export const isRluUntilYesterdayEntryEmpty = (entry: IUntilYesterdayItem) => 
    entry.glc_past_drops_select === '' &&
    entry.glc_past_drops_eye_select === ''

export const isOngoingEntryEmpty = (entry: IOngoingItem) => {
    return Object.values(entry).every(value => value === '');
}

export const isUntilYesterdayEntryEmpty = (entry: IUntilYesterdayItem) => {
    return Object.values(entry).every(value => value === '');
}

export const isOmdHistoryEntryEmpty = (entry: IOmdHistoryItem) => {
    return Object.values(entry).every(value => value === '')
}

export const isIopHistoryEntryEmpty = (entry: IIopHistoryItem) => {
    return Object.values(entry).every(value => {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === '';
        }
    })
}

export const extractBcvaValue = (inputString: string, bcvaOptions: IOptionItem[]) => {
    // Define the regex pattern to match the value
    const pattern = /(\d+\/\d+)/;

    // Use RegExp.exec() to find the match in the input string
    const match = pattern.exec(inputString);

    if (match) {
        const m =  match[1].split('/')[1];
        if (bcvaOptions.find(option => option.value === m)) {
            return m;
        }
    } else {
        const bcvaOptions = ['ND', 'CF', 'HM', 'ENU', 'NLP', 'LP'];
        for (const value of bcvaOptions) {
            if (inputString && inputString.toUpperCase().includes(value)) {
                return value;
            }
        }
    }
    return 'ND';
}

// Get the referral text from the given retina referral option value.
export const getDefaultReferralText = (retinaReferral: string, isSameRegion: boolean) => {
    if (retinaReferral === ''){
        return Constants.NA_TEXT;
    }
    if (isSameRegion) {
        if (retinaReferral === 'optional') {
            return Constants.SAME_REGION_OPTIONAL_TEXT;
        } else if (retinaReferral === 'needed') {
            return Constants.SAME_REGION_NEEDED_TEXT;
        }
    } else {
        if (retinaReferral === 'optional') {
            return Constants.DIFF_REGION_OPTIONAL_TEXT;
        } else if (retinaReferral === 'needed') {
            return Constants.DIFF_REGION_NEEDED_TEXT;
        }
    }

    return '';
};