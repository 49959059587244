import { FullscreenOutlined } from '@ant-design/icons';
import { Card, Col, Modal, Row } from 'antd';
import PhotoDropzoneExtraImage from '../../../components/retina/photo-dropzone-extra-image';
import '../../../../static/css/components/_patient-exam-extra-images.scss';
import { localizedText } from '../../../localizedText';
import { viewAll } from '../../../reducers/extra-image-slice';
import { IExtraImage } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { getBackendMediaUrl } from '../../../helpers/media-image-convert';
import { convertUNIXTimeToShortFormatLabel } from '../../../helpers/utilities';
import GptImageIdentifierImg from './gpt-image-identifier-img';

type ComponentProps = {
    extraImages: IExtraImage[],
}

const GptImageIdentifierExtraImages = ({ extraImages }: ComponentProps) => {

    const { EXTRA_IMAGES_TEXT, VIEW_ALL_TEXT, UPLOADED_TEXT } = localizedText;
    const dispatch = useCare1AppDispatch();

    const showModal = useCare1AppSelector(store => store.extraImage.viewAll);
    const imagesToDisplay = 6;

    const getImageCards = (isModal = false) => {
        return extraImages.map((image, index) => (
            <Col key={index} span={isModal ? 4 : 3}>
                <Card key={index} className="extra-image extra-image-mt2" bordered={false}>
                    <div className="extra-image image">
                        <div className="img-wrapper">
                            <GptImageIdentifierImg
                                className="img-thumbnail"
                                photoUrl={`${getBackendMediaUrl()}${image.filename}`}
                            />
                            <div className="heading exam-date">{`${UPLOADED_TEXT} ${convertUNIXTimeToShortFormatLabel(image.date)}`}</div>
                        </div>
                    </div>
                </Card>
            </Col>
        )
        );

    }

    return (
        <Card className='patient-exam-extra-images' bordered={false}>
            <Modal
                className="view-all-modal"
                open={showModal}
                width="840"
                onCancel={() => { dispatch(viewAll(false)); }}
                closable
                footer={null}
            >
                <Row gutter={8}>
                    {getImageCards(true)}
                </Row>
            </Modal>
            <Row gutter={8}>
                <Col span={3}>
                    <Card className="extra-image" bordered={false}>
                        <div className="heading">{EXTRA_IMAGES_TEXT}</div>
                        <PhotoDropzoneExtraImage disabled={true} isODReadOnly={true} />
                    </Card>
                </Col>
                {getImageCards().slice(0, imagesToDisplay)}
                {getImageCards().length > imagesToDisplay
                    && (
                        <Col span={3}>
                            <Card className="extra-image view-all" bordered={false}>
                                <div className="heading">&nbsp;</div>
                                <button className="view-all-text" onClick={() => { dispatch(viewAll(true)); }}>
                                    <FullscreenOutlined className="fullscreen-icon" />
                                    <div className="text">
                                        {VIEW_ALL_TEXT}
                                    </div>
                                </button>
                            </Card>
                        </Col>
                    )}
            </Row>
        </Card>
    );
}

export default GptImageIdentifierExtraImages;
