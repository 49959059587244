import { Card, Col, Row, Typography } from 'antd';
import styles from '../../../../static/css/components/smart-upload-modal.module.scss';
import SmartUploadDropzone from './smart-upload-dropzone';
import SmartUploadOdNotes from './smart-upload-od-notes';


const SmartUploadStep1 = () => {
    return (
        <>
            <Col span={24}>
                <Row className={styles.dropzoneRow} gutter={[8, 0]}>
                    <Col span={16}>
                        <Card
                            className='referral-letter-dropzone-container'
                            bordered={false}
                        >
                            <Typography.Text className={styles.letterTitle}>
                                Add Letter
                            </Typography.Text>
                            <SmartUploadDropzone />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <SmartUploadOdNotes />
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default SmartUploadStep1;
