import { Modal, Spin } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/macula-abnormality.scss';
import MaculaSegmentationAnalysisForm from './macula-segmentation-analysis-form';
import { closeSegmentationModal } from '../../reducers/macula-historical-analysis-slice';


const MaculaSegmentationAnalysisModal = () => {

    const dispatch = useCare1AppDispatch();

    const apiStatus = useCare1AppSelector(store => store.maculaHistoricalAnalysis.api_status);
    const showModal = useCare1AppSelector(store => store.maculaHistoricalAnalysis.show_modal);

    const handleModalClose = () => {
        dispatch(closeSegmentationModal());
    };

    return (
        <Modal
            className='macula-segmentation-analysis-modal'
            open={showModal}
            closable={true}
            footer={null}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <Spin
                className='loading-spinner'
                size='large'
                spinning={apiStatus === 'loading'}
            >
                <MaculaSegmentationAnalysisForm />
            </Spin>
        </Modal>
    );
}

export default MaculaSegmentationAnalysisModal;