import React from 'react';
import { Row } from 'antd';

import '../../static/css/components/navigation-top-bar.scss';


const NavigationTopBar = ({ className }: { className: string }) => {
        return (
            <Row className={className}>
            {/* CD-127-TEMPORARILY DISABLED
                <Col span={6}>
                    <a class="logo" href={ROOT_URL}>
                        <img alt="logo" width="89" height="32" src={Logo} />
                    </a>
                </Col>
                <Col span={18}>
                    <Menu
                        mode="horizontal"
                        defaultSelectedKeys={['1']}
                    >
                        {NAVIGATION_URLS && NAVIGATION_URLS.map((navItem, i) => {
                            return (
                                <Menu.Item key={i}>
                                    <a href={navItem.url}>{navItem.title}</a>
                                </Menu.Item>
                            )
                        })}
                    </Menu>
                </Col>

            */}
            </Row>
        );
}

export default NavigationTopBar;
