import React, { useState } from 'react';
import { Layout, Spin, Tabs } from 'antd';
import NavigationControlBar from '../components/navigation-control-bar';
import AdminCsvNearMissLogTable from '../components/admin/admin-csv-near-miss-log-table';
import AdminCsvNearMissTable from '../components/admin/admin-csv-near-miss-table';
import AdminCsvNearMissByClinicLocationTable from '../components/admin/admin-csv-near-miss-by-clinic-location-table';
import * as Constants from '../constants';
import '../../static/css/pages/admin-csv-near-miss-page.scss'
import { useGetCsvNearMissSummaryQuery, useGetCsvNearMissByClinicLocationSummaryQuery, useGetCsvNearMissListFilterOptionsQuery } from '../services/admin-csv-near-miss-api';

const { Header, Content } = Layout;

const AdminCsvNearMissPage: React.FC = () => {

    const { data = {
        near_miss_list: {
            summary: {
                rows: [],
                days: []
            }
        }
    }, isFetching } = useGetCsvNearMissSummaryQuery();
    
    const { data: filterOptionsData = { 
        clinic_location_full_name_options: [],
        field_name_options: [] 
    }, isFetching: filterOptionsDataIsFetching } = useGetCsvNearMissListFilterOptionsQuery();

    const { data: dataByClinicLocation = {
        near_miss_list: {
            summary: {
                rows: [],
                days: []
            }
        }
    }, isFetching: byClinicLocationIsFetching } = useGetCsvNearMissByClinicLocationSummaryQuery();
    
    const getTabCsvNearMissSummary = () => {
        return (
        <div style={{padding: '1rem'}}>
            <AdminCsvNearMissTable
                tableList={data.near_miss_list.summary.rows}
                days={data.near_miss_list.summary.days}
                title=""
            />
        </div>);
    }

    const getTabCsvNearMissList = () => {
        return (
        <div style={{padding: '1rem'}}>
            <AdminCsvNearMissLogTable
                filterFieldNameOption={filterOptionsData.field_name_options}
                filterClinicLocationFullNameOption={filterOptionsData.clinic_location_full_name_options}
                filterHistoricalPeriodOption={Constants.ADMIN_GPT_NEAR_MISS_HISTORICAL_PERIOD_OPTIONS}
            />
        </div>);
    }

    const getTabCsvNearMissByClinicLocationSummary = () => {
        return (
        <div style={{padding: '1rem'}}>
            <AdminCsvNearMissByClinicLocationTable
                tableList={dataByClinicLocation.near_miss_list.summary.rows}
                days={dataByClinicLocation.near_miss_list.summary.days}
                title=""
            />
        </div>);
    }

    const tabs = [{
        label: 'Historical Near Misses', key: 'exam-metrics-near-miss',
        children: getTabCsvNearMissSummary()
    }, {
        label: 'Logs', key: 'logs',
        children: getTabCsvNearMissList()
    }, {
        label: 'Clinic Accuracy', key: 'clinic-accuracy',
        children: getTabCsvNearMissByClinicLocationSummary()
    }];

    return (
        <Layout>
            <Spin
                className="loading-spinner"
                spinning={isFetching || filterOptionsDataIsFetching || byClinicLocationIsFetching}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Header>
                    <NavigationControlBar
                        className="patient-list-navigation-control-bar"
                        breadcrumbItems={[]}
                    />
                </Header>
                <Content>
                    <div className='admin-csv-near-miss-list-page'>
                    <Tabs
                        defaultActiveKey={'exam-metrics-near-miss'}
                        items={tabs}
                    />               
                    </div>  
                </Content>
            </Spin>
        </Layout>
    );
}

export default AdminCsvNearMissPage;