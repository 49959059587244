
import { IBaseResponse, baseApi } from './base-api';

export interface IExamApiResponse {
    success: boolean;
    exams: [{id: number, exam_date: string}];
    error?: string;
    result?: 'true' | 'false';
    exam?: number;
    exists?: boolean;
}

export interface IStereoViewerImagesPosition {
    left: number | string;
    top: number | string;
    width: number | string;
    height: number | string;
}

export interface IStereoViewerImagesPositionRequest {
    examId: number;
    csvLeftFundus: IStereoViewerImagesPosition;
    csvLeftStereo: IStereoViewerImagesPosition;
    csvRightFundus: IStereoViewerImagesPosition;
    csvRightStereo: IStereoViewerImagesPosition;
    leftSwitched: boolean;
    rightSwitched: boolean;
    saveEmptyValues: boolean;
}

export interface IMetricsInfo {
    key: number;
    metrics_type: string;
    datetime: string;
    user: string;
    igp: string;
    od: string;
    omdc: string;
    omdr: string;
}

export interface IExamMetricsInfoResponse {
    success: boolean;
    data?: IMetricsInfo[];
    error?: string;
}

export interface IOutboundReferralOmdInfo {
    omd_contact: string;
    omd_name: string;
    ipc_addressed_date: string;
}

export const examApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        checkPreviousExamStatus: builder.query<IExamApiResponse, number> ({
            query: (id) => `exam/${id}/check_previous_exam_status/`,
        }),
        setPreviousExamsReady: builder.mutation<IExamApiResponse, number[]>({
            query(exams) {
                return {
                    url: `exam/set_previous_exams_ready/`,
                    method: 'POST',
                    body: exams,
                }
            },
        }),
        setExamOMDCStatusRequest: builder.mutation<IExamApiResponse, {examId: number, omdcStatus: string}>({
            query({examId, omdcStatus}) {
                const formData = new FormData();
                formData.append('exam_id', examId.toString());
                formData.append('omdc_status_to', omdcStatus);
                return {
                    url: `exam/set_omdc_status/`,
                    method: 'POST',
                    body: formData,
                }
            }
        }),
        setExamOMDCRequest: builder.mutation<IExamApiResponse, {examId: number, omdcId: number | string | null}>({
            query({examId, omdcId}) {
                const formData = new FormData();
                formData.append('exam_id', examId.toString());
                if (omdcId) {
                    formData.append('omdc_id', omdcId.toString());
                }
                return {
                    url: `exam/set_omdc/`,
                    method: 'POST',
                    body: formData,
                }
            }
        }),
        setExamOMDRRequest: builder.mutation<IExamApiResponse, {examId: number, omdrId: number | string | null}>({
            query({examId, omdrId}) {
                const formData = new FormData();
                formData.append('exam_id', examId.toString());
                if (omdrId) {
                    formData.append('omdr_id', omdrId.toString());
                }
                return {
                    url: `exam/set_omdr/`,
                    method: 'POST',
                    body: formData,
                }
            }
        }),
        examSetInternalGpAndStatus: builder.mutation<IExamApiResponse, {examId: number, gpStatus: string, selectedIGP: number | string}>({
            query({examId, gpStatus, selectedIGP}) {
                const formData = new FormData();
                formData.append('exam', examId.toString());
                formData.append('gp_status', gpStatus);
                formData.append('igp', selectedIGP.toString());
                return {
                    url: `exam/set_internal_gp_and_status/`,
                    method: 'POST',
                    body: formData,
                }
            }
        }),
        examSetInternalGpStatus: builder.mutation<IExamApiResponse, {examId: number, gpStatus: string}>({
            query({examId, gpStatus}) {
                const formData = new FormData();
                formData.append('exam', examId.toString());
                formData.append('gp_status', gpStatus);
                return {
                    url: `exam/set_internal_gp_status/`,
                    method: 'POST',
                    body: formData,
                }
            }
        }),
        saveExamDataRrGlcSuspectRequest: builder.mutation<IExamApiResponse, {examId: number, rrGlcSuspect: string}>({
            query({examId, rrGlcSuspect}) {
                const formData = new FormData();
                formData.append('exam_id', examId.toString());
                formData.append('rr_glc_suspect', rrGlcSuspect);
                return {
                    url: `exam/save_glc_suspect/`,
                    method: 'POST',
                    body: formData,
                }
            }
        }),
        setExamStereoviewerImagesPosition: builder.mutation<IExamApiResponse, IStereoViewerImagesPositionRequest>({
            query({examId, csvLeftFundus, csvLeftStereo, csvRightFundus, csvRightStereo, leftSwitched, rightSwitched, saveEmptyValues}) {
                const formData = new FormData();
                formData.append('exam', examId.toString());
                formData.append('left_fundus_left', csvLeftFundus.left ? csvLeftFundus.left.toString() : '');
                formData.append('left_fundus_top', csvLeftFundus.top ? csvLeftFundus.top.toString(): '');
                formData.append('left_fundus_width', csvLeftFundus.width ? csvLeftFundus.width.toString() : '');
                formData.append('left_fundus_height', csvLeftFundus.height ? csvLeftFundus.height.toString() : '');
                formData.append('left_stereo_left', csvLeftStereo.left ? csvLeftStereo.left.toString() : '');
                formData.append('left_stereo_top', csvLeftStereo.top ? csvLeftStereo.top.toString() : '');
                formData.append('left_stereo_width', csvLeftStereo.width ? csvLeftStereo.width.toString() : '');
                formData.append('left_stereo_height', csvLeftStereo.height ? csvLeftStereo.height.toString() : '');
                formData.append('left_switched', leftSwitched ? '1' : '0');
                formData.append('right_fundus_left', csvRightFundus.left ? csvRightFundus.left.toString() : '');
                formData.append('right_fundus_top', csvRightFundus.top ? csvRightFundus.top.toString() : '');
                formData.append('right_fundus_width', csvRightFundus.width ? csvRightFundus.width.toString() : '');
                formData.append('right_fundus_height', csvRightFundus.height ? csvRightFundus.height.toString() : '');
                formData.append('right_stereo_left', csvRightStereo.left ? csvRightStereo.left.toString() : '');
                formData.append('right_stereo_top', csvRightStereo.top ? csvRightStereo.top.toString() : '');
                formData.append('right_stereo_width', csvRightStereo.width ? csvRightStereo.width.toString() : '');
                formData.append('right_stereo_height', csvRightStereo.height ? csvRightStereo.height.toString() : '');
                formData.append('right_switched', rightSwitched ? '1' : '0');
                formData.append('save_empty_values', saveEmptyValues ? 'true' : 'false');
                return {
                    url: `exam/update_stereoviewer_images_position/`,
                    method: 'POST',
                    body: formData,
                }
            }
        }),
        setExamReviewedTimestamp: builder.mutation<IExamApiResponse, number>({
            query(examId) {
                return {
                    url: `exam/review_timestamp/${examId}`,
                    method: 'POST',
                    body: {},
                }
            }
        }),
        clearSecondOmdrAlertOnPrevExams: builder.mutation<IExamApiResponse, number>({
            query(examId) {
                return {
                    url: `exam/clear_second_omdr_alert_on_prev_exams/${examId}`,
                    method: 'POST',
                    body: {},
                }
            }
        }),
        patientHasExam: builder.mutation<IExamApiResponse, {patientId: number, examDate: string}>({
            query({patientId, examDate}) {
                const formData = new FormData();
                formData.append('exam_date', examDate);
                formData.append('patient_id', patientId.toString());
                return {
                    url: `patient/exam_date_exists/`,
                    method: 'POST',
                    body: formData,
                }
            }
        }),
        addNewExam: builder.mutation<IExamApiResponse, {patientId: number, examDate: string}>({
            query({patientId, examDate}) {
                const formData = new FormData();
                formData.append('exam_date', examDate);
                return {
                    url: `patient/exam/add/${patientId}`,
                    method: 'POST',
                    body: formData,
                }
            }
        }),
        deactivatePatientVisitRequest: builder.mutation<IExamApiResponse, number>({
            query(visidId) {
                const formData = new FormData();
                formData.append('exam', visidId.toString());
                return {
                    url: `exam/deactivate/`,
                    method: 'POST',
                    body: formData,
                }
            }
        }),
        swapImage: builder.mutation<IExamApiResponse, {examId: number, photo1: string, photo2: string}>({
            query({examId, photo1, photo2}) {
                const formData = new FormData();
                formData.append('exam', examId.toString());
                formData.append('photo1', photo1);
                formData.append('photo2', photo2);
                return {
                    url: `exam/swap_image/`,
                    method: 'POST',
                    body: formData,
                }
            }
        }),
        getExamMetricsInfo: builder.query<IMetricsInfo[], number> ({
            query: (examId) => `exam/metrics_info/${examId}`,
            transformResponse: (response: IExamMetricsInfoResponse, meta, arg) => response.data ?? [],
        }),
        referralLetterModifictionRequest: builder.mutation<IExamApiResponse, {examId:number, confirmed: boolean}>({
            query({examId, confirmed }) {
                return {
                    url: `referral_letter_modification/${examId}`,
                    method: 'POST',
                    body: {confirmed},
                }
            }
        }),
        referralLetterPdfEdit: builder.mutation<IExamApiResponse, {examId:number, keywords: string[]}>({
            query({examId, keywords }) {
                return {
                    url: `referral_letter_pdf_edit/${examId}`,
                    method: 'POST',
                    body: {keywords},
                }
            }
        }),
        referralLetterPdfEditReset: builder.mutation<IExamApiResponse, number>({
            query(examId) {
                return {
                    url: `referral_letter_pdf_edit_reset/${examId}`,
                    method: 'POST',
                    body: {},
                }
            }
        }),
        sendPatientHandout: builder.mutation<IExamMetricsInfoResponse, {examId: number, email: string}>({
            query: ({examId, email}) => {
                return {
                    url: `patient_handout_email/${examId}`,
                    method: 'POST',
                    body: {email}
                }
            },
        }),
        checkShouldDisableSubmitButton: builder.query<boolean, number> ({
            query: (id) => `exam/${id}/should_disable_submit_button`,
            transformResponse: (response: IBaseResponse & {data: boolean}, meta, arg) => response.data,
        }),
        sendOutboundReferralEmailOrFax: builder.query<IBaseResponse, number> ({
            query: (id) => `admin/exam/${id}/outbound_omd_email_or_fax/`,
        }),
        getOutboundReferralOmdInfo: builder.query<IOutboundReferralOmdInfo, number> ({
            query: (id) => `admin/exam/${id}/outbound_referral_omd_info/`,
            transformResponse: (response: IBaseResponse & {data: IOutboundReferralOmdInfo}, meta, arg) => response.data,
        }),
        bulkReviewOutboundExamsRequest: builder.mutation<IBaseResponse & {data: number[]}, number[]>({
            query(exam_ids) {
                return {
                    url: `exam/bulk_review_outbound_exams/`,
                    method: 'POST',
                    body: {exam_ids},
                }
            }
        }),
        setExamValidationReview: builder.mutation<IExamApiResponse, number>({
            query(examId) {
                return {
                    url: `exam/set_validation_review/${examId}`,
                    method: 'POST',
                    body: {},
                }
            }
        }),
    }),   
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const { useLazyCheckPreviousExamStatusQuery, useSetPreviousExamsReadyMutation, useSetExamOMDCStatusRequestMutation, useSetExamOMDCRequestMutation, useSetExamOMDRRequestMutation, useExamSetInternalGpAndStatusMutation, useSaveExamDataRrGlcSuspectRequestMutation, useSetExamStereoviewerImagesPositionMutation, useDeactivatePatientVisitRequestMutation, useSwapImageMutation, useGetExamMetricsInfoQuery, useReferralLetterModifictionRequestMutation,
useReferralLetterPdfEditMutation, useReferralLetterPdfEditResetMutation, useSendPatientHandoutMutation, useCheckShouldDisableSubmitButtonQuery, useGetOutboundReferralOmdInfoQuery, useBulkReviewOutboundExamsRequestMutation,
useLazySendOutboundReferralEmailOrFaxQuery,
useSetExamValidationReviewMutation } = examApi;