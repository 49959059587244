import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../stores/retina-enabled-store";

export interface IBaseResponse {
    success: boolean;
    error?: string;
}

export const baseApi = createApi({
    reducerPath: "baseApi",
    // the base query, set the base url, e.g. app.care1.ca:8000/data
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKENDURL}/data`,
        // attach csrf token if there is one
        prepareHeaders: (headers, {getState}) => {
            const {user: { csrfToken }} = getState() as RootState;
            if (csrfToken) {
                headers.set('X-CSRFToken', csrfToken );
            }
            return headers;
        },
        credentials: 'include',
    }),
    // all the posssilbe types of api
    tagTypes: ['OD', 'DoctorList', 'OMD', 'IGP', 'GP', 'AllGP', 'Report', 'StaffList', 'Staff', 
    'OctRnflNearMissList', 'VfNearMissList', 'CsvStereoViwerNearMissList', 'GptChatNearMissSummary', 
    'CsvNearMissByClinicLocationSummary', 
    'GptChatNearMissList', 'OdGroupPracticeList', 'OdGroupPractice', 'LocationOptions', 
    'CsvNearMissList', 'CsvNearMissSummary', 'Technicians', 'TechnicianOptions', 'Location',
    'OutboundOmd', 'ServicesHealthCheckSummary', 'CheckedServicesLog', 'InternalReviewRecipients',
    'GlaucomaPatientList', 'LatencyMetricsSummary'],
    endpoints: () => ({}),
});