import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getCsrfToken } from "../helpers/utilities";
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";


export interface IGptVisionPrompt {
    gpt_image_identifier_chat_api_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    gpt_image_identifier_chat_prompts: string,
}

const initialState: IGptVisionPrompt = {
    gpt_image_identifier_chat_api_status: 'idle',
    gpt_image_identifier_chat_prompts: '',
};


export const fetchGptImageIdentifierChatPrompt = createAsyncThunk(
    'gptImageIdentifier/fetchGptImageIdentifierChatPrompt',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_image_identifier_chat_prompts/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)


export const gptImageIdentifierSlice = createSlice({
    name: 'gptImageIdentifier',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGptImageIdentifierChatPrompt.pending, (state) => {
                state.gpt_image_identifier_chat_api_status = 'loading';
                state.gpt_image_identifier_chat_prompts = '';
            })
            .addCase(fetchGptImageIdentifierChatPrompt.fulfilled, (state, action) => {
                state.gpt_image_identifier_chat_api_status = 'succeeded';
                state.gpt_image_identifier_chat_prompts = action.payload.gpt_image_identifier_chat_prompts;
            })
            .addCase(fetchGptImageIdentifierChatPrompt.rejected, (state) => {
                state.gpt_image_identifier_chat_api_status = 'failed';
                state.gpt_image_identifier_chat_prompts = '';
            })
            
    },
});

export default gptImageIdentifierSlice.reducer;


