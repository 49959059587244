import { useMemo } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import update from 'immutability-helper';
import CreatableSelect from 'react-select/creatable';
import { Card, Row, Form } from 'antd';
import { generateDiagnosisListOptions, sortOptions } from '../../../helpers/diagnosis-convert';
import { IExamData, lengthEqual } from '../../../reducers/patient-exam-slice';
import { localizedText } from '../../../localizedText';
import { IDiagnosisEntry, setDiagnosisValues } from '../../../reducers/diagnosis-slice';
import * as Constants from '../../../constants';
import '../../../../static/css/components/_patient-exam-chief-complaint.scss';

const FormItem = Form.Item;

type chiefComplaintsType = {
    rr_cat_post_op: boolean, 
    rr_cataract: boolean, 
    rr_erm: boolean, 
    rr_amd: boolean, 
    rr_dm: boolean,  
    rr_optic_nerve: boolean, 
    rr_glc_suspect: boolean, 
    rr_glc: boolean,  
    rr_narrow_angles: boolean,
}

type ComponentProps = {
    chiefComplaints: chiefComplaintsType;
    onChiefComplaintsChange: (field: string, val: boolean) => void;
    gptResultState: { [x: string]: string };
}

const GptPatientExamChiefComplaintIC = ({ chiefComplaints, onChiefComplaintsChange, gptResultState }: ComponentProps) => {
    const { CHIEF_COMPLAINT_PLACEHOLDER_TEXT, CHIEF_COMPLAINT_GLC_LABEL, CHIEF_COMPLAINT_OPTIC_NERVE_LABEL,
        CHIEF_COMPLAINT_GLCS_LABEL, CHIEF_COMPLAINT_DM_LABEL, CHIEF_COMPLAINT_AMD_LABEL,
        CHIEF_COMPLAINT_ERM_LABEL, CHIEF_COMPLAINT_CAT_REF_LABEL,
        CHIEF_COMPLAINT_NAR_ANG_LABEL, CHIEF_COMPLAINT_TEXT } = localizedText;

    const diagnosisList = useCare1AppSelector(store => store.examData[Constants.DIAGNOSIS_LIST], lengthEqual);
    const glc = chiefComplaints.rr_glc;
    const glcSuspect = chiefComplaints.rr_glc_suspect;
    const opticNerve = chiefComplaints.rr_optic_nerve;
    const dm = chiefComplaints.rr_dm;
    const amd = chiefComplaints.rr_amd;
    const erm = chiefComplaints.rr_erm;
    const cataract = chiefComplaints.rr_cataract;
    const catPostOp = chiefComplaints.rr_cat_post_op;
    const narrowAngles = chiefComplaints.rr_narrow_angles;

    const dispatch = useCare1AppDispatch();

    interface ICommonDiagnosis {
        id: keyof IExamData;
        label?: string;
        value: boolean;
    }

    const commonDiagnosis: ICommonDiagnosis[] = [
        {id: 'rr_glc', label: CHIEF_COMPLAINT_GLC_LABEL, value: glc},
        {id: 'rr_glc_suspect', label: CHIEF_COMPLAINT_GLCS_LABEL, value: glcSuspect},
        {id: 'rr_optic_nerve', label: CHIEF_COMPLAINT_OPTIC_NERVE_LABEL, value: opticNerve},
        {id: 'rr_dm', label: CHIEF_COMPLAINT_DM_LABEL, value: dm},
        {id: 'rr_amd', label: CHIEF_COMPLAINT_AMD_LABEL, value: amd },
        {id: 'rr_erm', label: CHIEF_COMPLAINT_ERM_LABEL, value: erm },
        {id: 'rr_cataract', label: CHIEF_COMPLAINT_CAT_REF_LABEL, value: cataract},
        {id: 'rr_cat_post_op', label: 'Cat Post Op', value: catPostOp},
        {id: 'rr_narrow_angles', label: CHIEF_COMPLAINT_NAR_ANG_LABEL, value: narrowAngles},
    ];

    // Add the diagnosis list selectable options into the dropdown.
    // useMemo hook is used here because diagnosisList usually does not change once all exam data is
    // loaded, hence the diagnosisListOptions does not change. Before changes, all the diagnosisOptions
    // will be calculated again after a complaint is added/removed. Now the diagnosisOptions will be
    // calculated only once
    const diagnosisListOptions = useMemo(() => {
        const isIC = true;
        return sortOptions(generateDiagnosisListOptions(diagnosisList, isIC));
    }, [diagnosisList]);

    // To remove the defaultValue props of the component
    const creatableSelectBase = <CreatableSelect
        className="chief-complaint-select"
        classNamePrefix="chief-complaint"
        isMulti
        options={diagnosisListOptions}
        isClearable={false}
        placeholder={CHIEF_COMPLAINT_PLACEHOLDER_TEXT}
        onChange={(event, actionType) => {
            const selectedDiagnosisValues = event as IDiagnosisEntry[];
            dispatch(setDiagnosisValues(selectedDiagnosisValues, actionType.action, diagnosisList));
        }}
    />;
    const creatableSelectBaseProps = creatableSelectBase.props;
    let creatableSelectProps = {
        ...creatableSelectBaseProps,
    };

    delete creatableSelectProps.defaultValue;
    const creatableSelect = update(creatableSelectBase, {props: {$set: {...creatableSelectProps}}});

    const handleChanges = ({id, value}: ICommonDiagnosis) => {
        const diagnosis = id as string;
        onChiefComplaintsChange(diagnosis, !value)
    }

    return (

        <Card className={'patient-exam-chief-complaint'} bordered={false}>
            {/* COMPLAINT 001, COMPLAINT 002, COMPLAINT 003, COMPLAINT 004 */}
            <div className='title-row' >
                <div className='chief-complaint-title'>{`${CHIEF_COMPLAINT_TEXT} GPT`}</div>
            </div>

            <div className='common-diagnosis' >
            {
                commonDiagnosis.map(({id, label, value}) =>
                    <div
                        className={`${gptResultState[id]} common-diagnosis-entry${value ? ' common-diagnosis-entry-selected': ''}`}
                        onClick={() => handleChanges({id, value})}
                        key={id}
                    >
                        {label}
                    </div>
                )
            }
            </div>

            <Row className='other-diagnosis-row'>
                <FormItem>

                    {creatableSelect}
                </FormItem>
            </Row>
        </Card>

    );
}

export default GptPatientExamChiefComplaintIC;
