import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import Care1LightBox from './care1-light-box';
import { DELETE_CONFIRMATION_MODAL_TITLE, DELETE_CONFIRMATION_MODAL_TEXT, DELETE_CONFIRMATION_OK_TEXT,
    DELETE_CONFIRMATION_CANCEL_TEXT,
    ERROR_MESSAGE_TITLE,
    DELETE_EXTRA_IMAGE_ERROR
    } from '../../constants';
import { getBackendMediaUrl } from '../../helpers/media-image-convert';
import { convertUNIXTimeToShortFormatLabel } from '../../helpers/utilities';
import PDFViewerModal from './pdf-viewer-modal';
import TrashButton from '../../../static/images/trash_button.svg';
import '../../../static/css/components/extra-image.scss';
import { localizedText } from '../../localizedText';
import { deletePhoto, deletePhotoCancelled, deletePhotoConfirm, toggleLightbox, togglePdfViewer } from '../../reducers/extra-image-slice';
import { IExtraImage, retrievePhotoUrls } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

type ComponentProps = {
    isModal: boolean,
    disabled: boolean,
    image: IExtraImage,
    idx: number,
}

const ExtraImage = ({ isModal, disabled, image, idx }: ComponentProps) => {
    const { UPLOADED_TEXT } = localizedText;
    const dispatch = useCare1AppDispatch();
    const examId = useCare1AppSelector(store => store.examData.id);
    const extraImage = useCare1AppSelector(store => store.extraImage);

    const sendToggleLightbox = () => {
        dispatch(toggleLightbox(idx));
    }

    const sendTogglePdfViewer = () => {
        dispatch(togglePdfViewer(idx));
    }

    const sendDeletePhotoConfirm = () => {
        dispatch(deletePhotoConfirm(image.filename));
        Modal.confirm({
            className: 'delete-confirmation-modal',
            title: DELETE_CONFIRMATION_MODAL_TITLE,
            content: DELETE_CONFIRMATION_MODAL_TEXT,
            icon: <InfoCircleOutlined />,
            okText: DELETE_CONFIRMATION_OK_TEXT,
            cancelText: DELETE_CONFIRMATION_CANCEL_TEXT,
            cancelButtonProps: { className: 'confirm-exit' },
            onCancel: () => { sendDeletePhotoCancelled(); },
            onOk: () => { sendDeletePhotoRequest(); },
        });
    }

    const sendDeletePhotoCancelled = () => {
        // Send the action to close the confirmation dialog without doing anything.
        dispatch(deletePhotoCancelled(image.filename));
    }

    const sendDeletePhotoRequest = async () => {
        try {
            const res = await dispatch(deletePhoto(image.filename)).unwrap();
            if (res && res.success) {
                dispatch(retrievePhotoUrls(examId!));
            } else {
                throw new Error(res?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                title: ERROR_MESSAGE_TITLE,
                className: 'info-modal',
                content: `${DELETE_EXTRA_IMAGE_ERROR}: ${message}`,
            });
        }
    }

    const mainSrcUrl = `${getBackendMediaUrl()}${image.filename}`;
    const extensionIndex = mainSrcUrl.lastIndexOf(".");
    const mainSrcThumbnailUrl = `${mainSrcUrl.substring(0, extensionIndex)}_thumb${mainSrcUrl.substring(extensionIndex)}`;
    const pdfPNGThumbnailUrl = mainSrcUrl.replace('.pdf', '.png');

    return (
        <div>
            { !isModal &&
                <div className="heading">&nbsp;</div>
            }

            <div id={idx.toString()} key={idx} className="extra-image image">
                { image.type !== '.PDF' &&
                    <div className='img-wrapper'>
                        <img
                            className="img-thumbnail"
                            src={mainSrcThumbnailUrl}
                            onClick={() => sendToggleLightbox()}
                            alt=""
                            onError={
                                (e)=>{
                                    // If the the thumbnail image can't be found, just use the full image.
                                    if (e.currentTarget.src !== mainSrcUrl) {
                                        e.currentTarget.src=mainSrcUrl;
                                    }
                                }
                            }
                        />
                    </div>
                }

                { image.type === '.PDF' &&
                    <img
                        className="img-thumbnail"
                        src={pdfPNGThumbnailUrl}
                        onClick={() => sendTogglePdfViewer()}
                        alt=""
                    />
                }

                { !disabled &&
                    <img
                        className="trash_button"
                        src={TrashButton}
                        onClick={sendDeletePhotoConfirm}
                        alt=""
                    />
                }

                <div className="heading exam-date">{`${UPLOADED_TEXT} ${convertUNIXTimeToShortFormatLabel(image.date)}`}</div>

                <Care1LightBox
                    field={`extra_image_${idx}`}
                    isOpen={extraImage.lightboxOnForIndex === idx && image.type !== '.PDF'}
                    mainSrc={mainSrcUrl}
                    mainSrcThumbnail={mainSrcThumbnailUrl}
                    onCloseRequest={() => sendToggleLightbox()}
                    closeLabel="CLOSE"
                    wrapperClassName="LightboxImage"
                />

                { extraImage.lightboxOnForIndex === idx && image.type === '.PDF' &&
                    <PDFViewerModal idx={idx} file={mainSrcUrl} />
                }
            </div>
        </div>
    );
}

export default ExtraImage;