import { Modal,  Spin } from 'antd';
import IGPEditForm from './igp-edit-form';
import '../../../static/css/components/admin-doctor-edit-modal.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { IIGPDetails, useAddIgpMutation } from '../../services/doctor-api';
import { setShowIgpAddModal } from '../../reducers/doctor-slice';

const IGPAddModal = () => {

    const showIgpAddModal = useCare1AppSelector(store => store.doctorSlice.showIgpAddModal);

    const dispatch = useCare1AppDispatch();

    const [addIgp, {isLoading}] = useAddIgpMutation();


    const handleModalClose = () => {
        dispatch(setShowIgpAddModal(false));
    };

    const onFinish = async (values: IIGPDetails) => {
        try {
            // we use the addOmd function after the form is complete and submit/save button is clicked
            // unwrap() gives the access to error or success payload
            const result = await addIgp(values).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'IGP added successfully.',
                    onOk: () => {
                        dispatch(setShowIgpAddModal(false));
                    }
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    const onFinishFailed = () => {
        Modal.error({
            className: 'info-modal',
            content: 'Please fix the error to continue',
            title: 'Error',
        });
    };

    return (
        <Modal
            className='admin-doctor-edit-modal igp'
            open={showIgpAddModal}
            closable={true}
            footer={null}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <Spin
                className='loading-spinner'
                size='large'
                spinning={isLoading}
            >
                <IGPEditForm
                    title={'Add New Internal GP'}
                    buttonText={'Add IGP'}
                    mode={'add'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                />
            </Spin>
        </Modal>
    );

}

export default IGPAddModal;
