import { ExportOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { closePatientDetailsModal, openExamDateSelectModal } from '../../reducers/patient-details-slice';
import { useCare1AppDispatch } from '../../apps/care1-hooks';

type ComponentProps = {
    className: string,
    patientID: number,
    buttonText: string,
    disabled?: boolean,
}

const NewVisitButton = ({ className, patientID, buttonText, disabled}: ComponentProps) => {

    const dispatch = useCare1AppDispatch();

    // Leo to set up custom Icon
    // const customIcon = (<Icon component={newTabIcon}/>)
    return (
        <div className="latest-visit-cell">
            <Button
                data-testid='new-visit-button'
                disabled={disabled}
                className={className}
                icon={<ExportOutlined />}
                onClick={(event) => {
                    event.stopPropagation();
                    dispatch(closePatientDetailsModal());
                    dispatch(openExamDateSelectModal(patientID));
                }}
            >
                {buttonText}
            </Button>
        </div>
    );
}

export default NewVisitButton;
