import { Fragment, useEffect } from 'react';
import { Form, Card, Checkbox, Col, Row, Modal } from 'antd';
import PatientExamOMDHistoryEntry from './patient-exam-omd-history-entry';
import { getOMDHistoryValue, isOMDHistoryValueEmpty, checkEmptyOMDHistoryFields } from '../../helpers/patient-exam-convert';
import DivTagInput from './div-tag-input';
import '../../../static/css/components/patient-exam-omd-history.scss';
import { localizedText } from '../../localizedText';
import * as Constants from '../../constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import { addOMDHistoryEntry, deleteOMDHistoryEntry, setOMDHistoryNone, toggleOMDHistoryEntries } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';


const PatientExamOMDHistory = ({disabled}: {disabled: boolean}) => {
    const { EXAM_OMD_HISTORY_TITLE, NO_HISTORY_TEXT, NONE_TEXT } = localizedText;

    const dispatch = useCare1AppDispatch();

    const user = useCare1AppSelector(store => store.user);
    const omdHistory = useCare1AppSelector(store => store.examData.omd_history);
    const eyeTypes = useCare1AppSelector(store => store.options.eyeTypes);
    const inReferralLetterPei = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);

    // OMD users should not see the checkbox,
    // If there are no OMD History entries then display an entry of "No history"
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsOMD = userIsOMDC || userIsOMDR ;

    const closeOMDHistoryEntries = () => {
        const { INCOMPLETE_ROW_CONFIRMATION_TITLE, INCOMPLETE_ROW_CONTENT_TEXT, INCOMPLETE_ROW_OK_TEXT,
            INCOMPLETE_ROW_CANCEL_TEXT } = Constants;

        const omdHistoryValues = omdHistory.values;

        let isRowCompleted = true;
        let selectedRow: null | number = null;
        // set isRowCompleted to false only when there are some unfilled fileds, when all fields of
        // a row are empty, it is considered a complete row
        omdHistoryValues.forEach((entry, index) => {
            if (checkEmptyOMDHistoryFields(entry)) {
                isRowCompleted = false;
                selectedRow = index;
            }
        });

        if (inReferralLetterPei !== undefined && inReferralLetterPei) {
            dispatch(toggleOMDHistoryEntries());
        }
        else {
            if (!isRowCompleted) {
                Modal.confirm({
                    className: 'div-tag-row-incomplete-modal',
                    title: INCOMPLETE_ROW_CONFIRMATION_TITLE,
                    content: INCOMPLETE_ROW_CONTENT_TEXT,
                    icon: <InfoCircleOutlined />,
                    okText: INCOMPLETE_ROW_OK_TEXT,
                    cancelText: INCOMPLETE_ROW_CANCEL_TEXT,
                    cancelButtonProps: { className: 'continue-editing' },
                    onOk: () => {
                        dispatch(deleteOMDHistoryEntry(selectedRow!));
                        dispatch(toggleOMDHistoryEntries());
                    },
                });
            } else {
                dispatch(toggleOMDHistoryEntries());
            }
        }
    }

    useEffect(() => {
        const values = getOMDHistoryValue(user, omdHistory.values);
        if (values && values.length === 0) {
            dispatch(addOMDHistoryEntry());
        }
        else {
            const isNone = omdHistory.values[0] && omdHistory.values[0].disabled;
            if (!isNone) {
                const entry = omdHistory.values[omdHistory.values.length - 1];
                if (!isOMDHistoryValueEmpty(entry)) {
                    dispatch(addOMDHistoryEntry());
                }
            }
        }
    })

    const values: string[] = getOMDHistoryValue(user, omdHistory.values);
    const isNone = omdHistory.values[0] && omdHistory.values[0].disabled;
    const isNoEntries = values.length === 0;
    const entries = omdHistory.values.map((entry, index) => (
        <PatientExamOMDHistoryEntry
            eyeTypes={eyeTypes}
            key={index}
            index={index}
            data={entry}
        />
    ));

    // Remove empty value from array for the OMD display string
    const OmdDisplayValues = values.filter(value => {
        return value !== '';
    })

    return (
        <Card bordered={false} className="patient-exam-omd-history">
            { !userIsOMD &&
            <Fragment>
                <Row>
                    <Col span={18}>
                        <div className="heading">{EXAM_OMD_HISTORY_TITLE}</div>
                    </Col>
                    <Col span={6}>
                            {/* OMD-HIST 004 */}
                        <Checkbox
                            checked={isNone}
                            onChange={(e) => dispatch(setOMDHistoryNone(e.target.checked))}
                            disabled={disabled}
                        >
                            {NONE_TEXT}
                        </Checkbox>
                    </Col>
                </Row>
                <Row className={disabled ? 'div-tag-disabled' : ''}>
                        {/* OMD-HIST 001, OMD-HIST 002, OMD-HIST 003 */}
                    <DivTagInput
                        disabled={disabled || !!isNone}
                        onClick={() => {
                            if (omdHistory.values.length === 0) {
                                dispatch(addOMDHistoryEntry());
                            }
                            dispatch(toggleOMDHistoryEntries());
                        }}
                        values={values}
                        drawer={!isNone && omdHistory.show && omdHistory.values.length > 0}
                        onClose={() => { closeOMDHistoryEntries(); }}
                        entries={entries}
                        emptyValue=""
                    />
                </Row>
            </Fragment>
            }
            { userIsOMD &&
                <Fragment>
                    <Row className={'heading-row'}>
                        <div className="heading">{EXAM_OMD_HISTORY_TITLE}</div>
                    </Row>
                    <Row>
                        { !isNoEntries &&
                            <Form.Item
                                data-testid='omd-history'
                            >
                                {OmdDisplayValues && OmdDisplayValues.map(entry =>{
                                    return entry;
                                }).join('; ')}
                            </Form.Item>
                        }
                        { isNoEntries &&
                            <Form.Item
                                data-testid='omd-no-history'
                            >
                            {NO_HISTORY_TEXT}
                        </Form.Item>
                        }
                    </Row>
                </Fragment>
            }
        </Card>
    );
}

export default PatientExamOMDHistory;
