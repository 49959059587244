import { message, Modal } from 'antd';
import { useState } from 'react';

import '../../../../static/css/components/photo-dropzone.scss';
import { FILE_UPLOAD_FAILED_TEXT } from '../../../constants';

// Helpers

// Components
import { DropzoneComponent } from 'react-dropzone-component';

import classNames from 'classnames';
import Dropzone from 'dropzone';
import * as ReactDOMServer from 'react-dom/server';
import {
    useCare1AppDispatch,
    useCare1AppSelector,
} from '../../../apps/care1-hooks';
import { photoDropzoneValidityCheck } from '../../../helpers/patient-exam-convert';
import { dropzoneSlice } from '../../../reducers/dropzone-slice';
import {
    createNewSmartUpload,
    setSmartUploadSliceDataValue,
    UploadFileResponse,
} from '../../../reducers/smart-upload-slice';
import PatientExamGpReferredPDFViewerModal from '../../retina/patient-exam-gp-referred-pdf-viewer-modal';

const displayNone = { display: 'none' };

const SmartUploadDropzone = () => {
    const dispatch = useCare1AppDispatch();
    const photoUrl = useCare1AppSelector(
        (store) => store.smartUpload.referralLetterThumbnailUrl
    );
    const pdfUrl = useCare1AppSelector(
        (store) => store.smartUpload.referralLetterUrl
    );
    const disabled = useCare1AppSelector((store) => store.smartUpload.disabled);

    const dropzones = useCare1AppSelector((store) => store.dropzones);
    const csrfToken = useCare1AppSelector((store) => store.user.csrfToken);
    const [dropzoneObject, setDropzoneObject] = useState<Dropzone | null>(null);
    const odId = useCare1AppSelector((store) => store.user.doctorID);
    const smartUploadId = useCare1AppSelector((store) => store.smartUpload.id);

    const field = 'smart_upload_referral_letter';

    const dropzoneInstance = dropzoneSlice['smart_upload_referral_letter'];

    const {
        retrievePhotoSuccess,
        setUploadProgress,
        uploadPhotoFailure,
        uploadPhotoRequest,
        uploadPhotoSuccess,
    } = dropzoneInstance.actions;

    const [showLetterPdf, setShowLetterPdf] = useState(false);

    const acceptFileFormat = 'application/pdf,application/x-pdf';

    const componentConfig = {
        postUrl: `${process.env.REACT_APP_BACKENDURL}/data/smart_upload/create/`,
    };

    const onInit = (dropzone: Dropzone) => {
        setDropzoneObject(dropzone);
    };

    const sendUploadPhotoRequest = (e: DragEvent) => {
        // Do not allow photo upload if component is disabled as read only
        if (!disabled) {
            const file = e?.dataTransfer?.files.length
                ? e.dataTransfer.files[0]
                : '';
            if (file) {
                try {
                    const ext = file.name.split('.').pop();
                    if (ext) {
                        if (
                            acceptFileFormat.indexOf(ext.toLowerCase()) !== -1
                        ) {
                            // Clear the current file. Sorry, yes I know this makes the class stateful, but this is required by the way
                            // the dropzone component works.
                            if (dropzoneObject) {
                                dropzoneObject.removeAllFiles();
                            }

                            dispatch(uploadPhotoRequest(file));
                            dispatch(
                                setSmartUploadSliceDataValue({
                                    key: 'displayLoadingIndicator',
                                    value: true,
                                })
                            );
                            dispatch(
                                setSmartUploadSliceDataValue({
                                    key: 'saving',
                                    value: true,
                                })
                            );
                        }
                    }
                } catch {}
            }
        }
    };

    const sendRetrievePhotoSuccess = (
        file: Dropzone.DropzoneFile,
        dataUrl: string
    ) => {
        // Dispatch a retrieve photo success, since the fact that there is a thumbnail means the retrieve
        // succeeded.
        dispatch(retrievePhotoSuccess({ photo: file, thumbnailUrl: dataUrl }));
    };

    const sendUploadProgress = (
        _: Dropzone.DropzoneFile,
        percentage: number
    ) => {
        dispatch(setUploadProgress(percentage));
    };

    const sendUploadPhotoFailure = (
        file: Dropzone.DropzoneFile,
        requestError: string | Error
    ) => {
        try {
            const ext = file.name.split('.').pop();
            if (ext) {
                if (acceptFileFormat.indexOf(ext.toLowerCase()) === -1) {
                    const message =
                        'This photo is not supported, please upload PDF file only.';
                    Modal.error({
                        className: 'info-modal',
                        content: message,
                        title: 'Photo not supported',
                    });
                    return;
                }
            }
        } catch {}

        // If this is an error from a request, use the request status.
        let error;
        if (requestError) {
            error = FILE_UPLOAD_FAILED_TEXT;
        } else {
            error = 'Errors uploading photos';
        }
        dispatch(setSmartUploadSliceDataValue({ key: 'saving', value: false }));
        dispatch(uploadPhotoFailure(error));
    };

    const sendUploadPhotoSuccess = (
        file: Dropzone.DropzoneFile,
        response: Object
    ) => {
        dispatch(uploadPhotoSuccess());
        const res = response as UploadFileResponse;
        if (res.success) {
            dispatch(createNewSmartUpload(res));
        } else {
            dispatch(setSmartUploadSliceDataValue({ key: 'saving', value: false }));
            message.error(`Errors creating new smart upload, ${res.error}`);
        }
        // Also retrieve the updated exam data after uploading successfully.
        // dispatch(retrieveReferralLetterUrls(odId!));
    };

    const eventHandlers = {
        init: onInit,
        drop: sendUploadPhotoRequest,
        uploadprogress: sendUploadProgress,
        error: sendUploadPhotoFailure,
        thumbnail: sendRetrievePhotoSuccess,
        success: sendUploadPhotoSuccess,
    };

    const hasPhoto = photoUrl !== '';
    const photoName = hasPhoto ? (dropzones[field].photo as File).name : '';
    const hasError = dropzones[field].error !== '';
    const errorMessage = hasError ? dropzones[field].error : '';
    const inProgress = dropzones[field].operationRunning;
    const progressPercentage = dropzones[field].percentage;
    const isValid = photoDropzoneValidityCheck(dropzones[field]);

    const djsConfig = {
        acceptedFiles: acceptFileFormat,
        clickable: false,
        createImageThumbnails: false,
        withCredentials: true,
        headers: { 'X-CSRFToken': csrfToken },
        maxFiles: disabled ? 0 : undefined,
        params: { od_id: odId, smart_upload_id: smartUploadId ?? '' },
        // Some of the divs in the preview area are needed by the react dropzone component, so they are permanently
        // hidden, but are not used by us.
        previewTemplate: ReactDOMServer.renderToStaticMarkup(
            <div className='dz-preview dz-file-preview'>
                <div className='dz-details' style={displayNone}>
                    <div className='dz-filename'>
                        <label data-dz-name='true' />
                    </div>
                </div>
                <div className='dz-progress' style={displayNone}>
                    <label
                        className='dz-upload'
                        data-dz-uploadprogress='true'
                    />
                </div>
                <div className='dz-error-message' style={displayNone}>
                    <label data-dz-errormessage='true' />
                </div>
            </div>
        ),
    };

    // Determine the classes used for the picture preview zone.
    // By default nothing is displayed, but in the case of an error, the error is displayed
    const errorMessageClass = classNames({
        'dropzone-error': true,
        'dz-hide': disabled || !hasError,
    });

    // If an operation is in progress, the progress bar is shown.
    const progressBarClass = classNames({
        'dropzone-progress-container': true,
        'dz-hide': !inProgress,
    });

    const progressWidth = { width: `${progressPercentage}%` };

    // The preview image is shown if there is no error and nothing is in progress.
    const detailsClass = classNames({
        'dropzone-details': true,
        'dz-hide': (!disabled && hasError) || !hasPhoto,
    });

    // Determine the classes used for the borders of the dropzone.
    // If there is a photo, the dropzone is considered valid, and it's considered invalid if there is no photo.
    // Validity causes a green or red border to show.
    const dropzoneBorderClass = classNames({
        'photo-dropzone': true,
        'photo-dropzone-valid': isValid,
        'photo-dropzone-invalid': !isValid,
        'photo-dropzone-disabled': disabled,
    });

    // The dropzone message (e.g. drog photos here...) is only displayed if there are no photos or errors.
    const dropzoneMessageClass = classNames({
        'dz-message': !hasPhoto,
        'dz-hide': hasPhoto || (!disabled && hasError),
    });

    return (
        /* @ts-ignore:next-line */
        <DropzoneComponent
            class={dropzoneBorderClass}
            id={field}
            config={componentConfig}
            djsConfig={djsConfig}
            eventHandlers={eventHandlers}
        >
            <div className={dropzoneMessageClass}>
                <div className='dropzone-empty-image'></div>
                <div className='dropzone-empty-text'>Drag & Drop</div>
                <div className='dropzone-empty-text'>to upload</div>
                <div className='dropzone-empty-formats' />
            </div>

            <div className={errorMessageClass}>
                <label>{errorMessage}</label>
                <p />
                <label data-testid='image-file' className='dropzone-filename'>
                    {photoName}
                </label>
            </div>
            <div className={detailsClass}>
                <img
                    className='dropzone-image'
                    src={photoUrl}
                    alt={photoName}
                    onClick={() => setShowLetterPdf(true)}
                    onError={(e) => {
                        // If the the thumbnail image can't be found, just use the full image.
                        if (e.currentTarget.src !== photoUrl) {
                            e.currentTarget.src = photoUrl;
                        }
                    }}
                />
            </div>
            <div className={progressBarClass}>
                <div className='dropzone-progress' style={progressWidth} />
            </div>
            {showLetterPdf && (
                <PatientExamGpReferredPDFViewerModal
                    className='pdfViewerModal'
                    file={pdfUrl}
                    onPDFViewClose={() => setShowLetterPdf(false)}
                />
            )}
        </DropzoneComponent>
    );
};

export default SmartUploadDropzone;
