import { useCallback, useEffect, useRef } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';
import { Layout, Row, Spin, Tabs } from 'antd';
import { useParams, useNavigate } from 'react-router';
import * as Constants from '../constants';

// Components
import { FormValidationProvider } from '../context/useFormValidationContext';
import ExamTabs from '../components/exam-tabs';
import HelpDeskModal from '../components/retina/help-desk-modal';
import NavigationControlBar from '../components/navigation-control-bar';
import NavigationTopBar from '../components/navigation-top-bar';
import PatientDetailsModal from '../components/retina/patient-details-modal';
import PatientEditModal from '../components/retina/patient-edit-modal';
import PatientInsuranceModal from '../components/integrated/us/patient-insurance-modal';
import classNames from 'classnames';

import LatencyMetrics, { LatencyMetricsHandle } from '../latency-metrics';

// Helpers
import '../../static/css/pages/omdc-patient-exam-page.scss';
import { editExamRequest } from '../reducers/patient-exam-slice';
import OMDCReferralLetterExamPage from './omdc-referral-letter-exam-page';
import OMDCLegacyExamPage from './omdc-legacy-exam-page';


// Actions

const { Header, Content } = Layout;

const OMDCPatientExamPage = () => {

    const latencyMetricsRef = useRef<LatencyMetricsHandle>(null);
    if (latencyMetricsRef.current) {
        latencyMetricsRef.current.processLog(performance.timeOrigin + performance.now(), "logic", "OMDCPatientExamPage::trigger");
    }

    const examOperating = useCare1AppSelector(store => store.examData.operating);
    const showDetails = useCare1AppSelector(store => store.patientDetails.visible);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const examIsRE = useCare1AppSelector(store => store.examData.exam_is_retina_only);
    const patientDetailsOperating = useCare1AppSelector(store => store.patientDetails.operating);
    const isReferralLetterUploadPEI = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);

    // Redux and React Router Hooks
    const dispatch = useCare1AppDispatch();
    const params = useParams();
    const history = useNavigate();

    const examId = Number(params[Constants.LATEST_EXAM_ID_FIELD]) || null;

    // send edit exam request when exam page first renders
    const editExam = useCallback(() => {
        if (examId !== null) {
            if (latencyMetricsRef.current) {
                latencyMetricsRef.current.processLog(performance.timeOrigin + performance.now(), "logic", "OMDCPatientExamPage::editExam");            
            }
            dispatch(editExamRequest(examId, history));
        }
    }, [params, history, dispatch])

    useEffect(() => {
        editExam();
    }, [editExam])

    useEffect(() => {
        if (latencyMetricsRef.current) {
            latencyMetricsRef.current.startTimerCheck(!examOperating && !patientDetailsOperating);
        }
    }, [examOperating, patientDetailsOperating])


    const getLatenctMetricsName = () => {
        return isReferralLetterUploadPEI ? 'omdc_rlu_premium_pei_load' : 'omdc_legacy_pei_load';
    }

    const getTabOrginal = () => {
        return isReferralLetterUploadPEI ?
            <OMDCReferralLetterExamPage /> :
            <OMDCLegacyExamPage />
    }

    const omdcPEIClassNames = classNames({
        'omdc-patient-exam-page': true,
        'user-is-re': userIsRE,
        'user-is-ic': !userIsRE,
        'exam-is-re': examIsRE,
        'exam-is-ic': !examIsRE,
        'referral-letter-exam': isReferralLetterUploadPEI
    });

    return (
        <Layout>
        {getLatenctMetricsName() === 'omdc_rlu_premium_pei_load' && 
            <LatencyMetrics key={getLatenctMetricsName()} 
                ref={latencyMetricsRef} 
                examId={`${examId}`} 
                metricsName={getLatenctMetricsName()} 
                timerMs={5000} 
                consloeLog={true} 
            />
        }
            {showDetails && <PatientDetailsModal />}
            <Spin
                className="loading-spinner"
                spinning={examOperating || patientDetailsOperating}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Header>
                    <NavigationTopBar className="navigation-top-bar" />
                    <NavigationControlBar className="omdc-pei-navigation-control-bar" />
                </Header>
                <Content>
                    <PatientEditModal />
                    <PatientInsuranceModal />
                    <HelpDeskModal />
                    <ExamTabs />
                    <FormValidationProvider>
                        <div className={omdcPEIClassNames}>
                        { !(examOperating || patientDetailsOperating) &&
                            <Tabs items={[{
                                label: 'PEI', key: 'item-pei',
                                children: getTabOrginal()
                            }]} />
                        }
                            <Row>
                                <br />
                                <br />
                            </Row>
                        </div>
                    </FormValidationProvider>
                </Content>
            </Spin>
        </Layout>
    );
}

export default OMDCPatientExamPage;
