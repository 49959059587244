import { useEffect, useRef, useState } from 'react';
import '../../../static/css/components/macula-abnormality.scss';
import { Circle, Transformer, Line } from 'react-konva';


const MaculaAbnormalityMaculaCircle = ({ shapeProps, isSelected, onSelect, onChange }) => {
    const shapeRef = useRef(null);
    const trRef = useRef(null);

    const [lineLowerLeftToUpperRight, setLineLowerLeftToUpperRight] = useState([shapeProps.x, shapeProps.y, shapeProps.x, shapeProps.y])
    const [lineUpperLeftToLowerRight, setLineUpperLeftToLowerRight] = useState([shapeProps.x, shapeProps.y, shapeProps.x, shapeProps.y])

    const moveLines = (movementX, movementY) => {
        const [bottomLeftX, bottomLeftY, topRightX, topRightY] = lineLowerLeftToUpperRight;
        setLineLowerLeftToUpperRight([bottomLeftX + movementX, bottomLeftY + movementY, topRightX + movementX, topRightY + movementY]);

        const [topLeftX, topLeftY, bottomRightX, bottomRightY] = lineUpperLeftToLowerRight;
        setLineUpperLeftToLowerRight([topLeftX + movementX, topLeftY + movementY, bottomRightX + movementX, bottomRightY + movementY]);
    }

    // Function to calculate the intersection point of a line with the oval's circumference
    const calculateIntersection = (start, end, a, b) => {
        const dx = end.x - start.x;
        const dy = end.y - start.y;
        const m = dy / dx;
        // c must be zero because line and oval are centered at (0,0)
        // const c = end.y - m * end.x;

        const denominator = Math.sqrt(a*a * dy*dy + b*b * dx*dx);
        const x1 = (a * b) / denominator * dx;
        const x2 = -x1;

        const y1 = m * x1;
        const y2 = m * x2;

        return [x1, y1, x2, y2];
    }

    const updateLines = (props) => {

        const centerX = props.x;
        const centerY = props.y;

        const width = props.width;
        const height = props.height;

        // Calculate the coordinates of the four corners of the bounding box
        const topLeft = { x: - width / 2, y: - height / 2 };
        const topRight = { x: + width / 2, y: - height / 2 };
        const bottomLeft = { x: - width / 2, y: + height / 2 };
        const bottomRight = { x: + width / 2, y: + height / 2 };

        // Calculate the semi-major and semi-minor axes
        const semiMajorAxis = width / 2;
        const semiMinorAxis = height / 2;

        // Calculate the intersection points with the oval's circumference
        const pt1 = calculateIntersection(topLeft, bottomRight, semiMajorAxis, semiMinorAxis);
        const pt2 = calculateIntersection(bottomLeft, topRight, semiMajorAxis, semiMinorAxis);

        const line1 = [pt1[0] + centerX, pt1[1] + centerY, pt1[2] + centerX, pt1[3] + centerY];
        const line2 = [pt2[0] + centerX, pt2[1] + centerY, pt2[2] + centerX, pt2[3] + centerY];

        setLineUpperLeftToLowerRight(line1);
        setLineLowerLeftToUpperRight(line2);
    }

    useEffect(() => {
        updateLines(shapeProps);
        // eslint-disable-next-line
    }, [shapeProps]);

    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            if (trRef.current) {
                trRef.current.nodes([shapeRef.current]);
                trRef.current.getLayer().batchDraw();
            }
        }
    }, [isSelected]);

    return (
        <>
            <Line
                points={lineLowerLeftToUpperRight}
                stroke="red"
            />
            <Line
                points={lineUpperLeftToLowerRight}
                stroke="red"
            />
            <Circle
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable
                onDragMove={(e) => {
                    moveLines(e.evt.movementX, e.evt.movementY);
                }}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                onTransform={() => {
                    const node = shapeRef.current;
                    if (node) {
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();

                        const newAttrs = {
                            ...shapeProps,
                            x: node.x(),
                            y: node.y(),
                            // set minimal value
                            width: Math.max(5, node.width() * scaleX),
                            height: Math.max(5, node.height() * scaleY),
                            offsetX: 0,
                            offsetY: 0,
                        }

                        updateLines(newAttrs);
                    }
                }}
                onTransformEnd={() => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    if (node) {
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();

                        // we will reset it back
                        node.scaleX(1);
                        node.scaleY(1);

                        const newAttrs = {
                            ...shapeProps,
                            x: node.x(),
                            y: node.y(),
                            // set minimal value
                            width: Math.max(5, node.width() * scaleX),
                            height: Math.max(5, node.height() * scaleY),
                            offsetX: 0,
                            offsetY: 0,
                        }

                        onChange(newAttrs);
                    }
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={false}
                    keepRatio={true}
                    centeredScaling={true}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </>
    );
};

export default MaculaAbnormalityMaculaCircle;
