import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { logout } from './user-slice';
import { getCsrfToken } from '../helpers/utilities';
import { apiRequest } from '../services/api-request';


// IGP Patient List slice intefaces and initial state
export interface IIgpListItem {
    complaint: string,
    date_referred: string,
    dob: string,
    first_name: string,
    gender: string,
    key: number,
    last_name: string,
    patient_history: string,
    phn: string,
    province: string,
    vision_od: string,
    vision_os: string,
    id: number,
}
export interface IIGPPatientList {
    isLoading: boolean,
    requiringReferralList: IIgpListItem [],
    previouslyReferredList: IIgpListItem [],
    selectedPatients: number[],
}
const initialState : IIGPPatientList = {
    isLoading: false,
    requiringReferralList: [],
    previouslyReferredList: [],
    selectedPatients: [],
}

// Response Data Interfaces
interface IIGPReferredPatientsResponseData {
    data: {
        doctor_image_url: string,
        doctor_name: string,
        referred_patients_list: IIgpListItem[],
    }
}
interface IIGPReferralRequiredResponseData {
    data: {
        doctor_image_url: string,
        doctor_name: string,
        requiring_referral_list: IIgpListItem[],
    }
}


// GET: getReferredPatientListRequest
export const getReferredPatientListRequest = createAsyncThunk(
    'igp/getReferredPatients',
    async (id: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/igp/${id.toString()}/referred_patients/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

// GET: getReferralRequiredPatientListRequest
export const getReferralRequiredPatientListRequest = createAsyncThunk(
    'igp/getReferralRequiredPatients',
    async (id: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/igp/${id.toString()}/patients/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

// POST: submitPatientsReferralRequest
export const submitPatientsReferralRequest = createAsyncThunk(
    'igp/submitPatientsReferralRequest',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken, userID } } = getState() as { user: { csrfToken: string, userID: number }};
        const {igpPatientList: { selectedPatients }} = getState() as { igpPatientList: { selectedPatients: number[] }};

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const data = new FormData();
        data.append('exams', JSON.stringify(selectedPatients));

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/set_exam_gp_referrals/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, data);

            // Request the updated lists
            if(response){
                userID && dispatch(getReferredPatientListRequest(userID));
                userID && dispatch(getReferralRequiredPatientListRequest(userID));
            }

            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const igpPatientListSlice = createSlice({
    name: 'igpPatientList',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setSelectedPatients: (state, action) => {
            state.selectedPatients = action.payload;
        },
    },
    extraReducers: (builder) => {
        //getReferredPatientListRequest
        builder.addCase(getReferredPatientListRequest.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getReferredPatientListRequest.fulfilled, (state,
            action: PayloadAction<IIGPReferredPatientsResponseData>) => {

            return {
                ...state,
                isLoading: false,
                previouslyReferredList: action.payload.data.referred_patients_list,
            }
        });
        builder.addCase(getReferredPatientListRequest.rejected, (state, action) => {
            state.isLoading = false
            Modal.error({
                className: 'info-modal',
                title: `Errors getting IGP patients already referred. ${action.payload}`,
            })
        });

        //getReferralRequiredPatientListRequest
        builder.addCase(getReferralRequiredPatientListRequest.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getReferralRequiredPatientListRequest.fulfilled, (state,
            action: PayloadAction<IIGPReferralRequiredResponseData>) => {
            return {
                ...state,
                isLoading: false,
                requiringReferralList: action.payload.data.requiring_referral_list,
            }
        });
        builder.addCase(getReferralRequiredPatientListRequest.rejected, (state, action) => {
            state.isLoading = false
            Modal.error({
                className: 'info-modal',
                title: `Errors getting IGP patients requiring referral. ${action.payload}`,
            })
        });

        // submitPatientsReferralRequest
        builder.addCase(submitPatientsReferralRequest.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(submitPatientsReferralRequest.fulfilled, (state) => {
            state.isLoading = false;
            Modal.info({
                className: 'info-modal',
                title: 'Patient referrals request submitted successfully!',
            });
        });
        builder.addCase(submitPatientsReferralRequest.rejected, (state, action) => {
            state.isLoading = false;
            Modal.error({
                className: 'info-modal',
                title: `Errors submitting patient referrals request. ${action.payload}`,
            })
        });
    }
});
// export the actions related to IGP
export const { setSelectedPatients } = igpPatientListSlice.actions;

export default igpPatientListSlice.reducer;