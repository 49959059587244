import { Button, Col, Flex, Modal, Row, Typography } from 'antd';
import styles from '../../../../static/css/components/smart-upload-confirmation-modal.module.scss';
import { useCare1AppDispatch, useCare1AppSelector} from '../../../apps/care1-hooks';
import { deleteSmartUpload, toggleSmartUploadDeleteConfirmationModal } from '../../../reducers/smart-upload-slice';

const SmartUploadDeleteConfirmationModal = () => {
    const isVisible = useCare1AppSelector(
        (store) => store.smartUpload.deleteConfirmationModalOpen
    );
    const smartUploadId = useCare1AppSelector(
        (store) => store.smartUpload.id
    );

    const dispatch = useCare1AppDispatch();

    const handleModalClose = () => {
        dispatch(toggleSmartUploadDeleteConfirmationModal());
    };

    return (
        <Modal
            className={`${styles.confirmationModal} ${styles.deleteConfirmationModal}`}
            open={isVisible}
            footer={null}
            closeIcon={null}
            keyboard={true}
            onCancel={handleModalClose}
            width={439}
        >
            <Row gutter={[0, 24]} justify='center'>
                <Col span={24}>
                    <Typography.Text className={styles.deleteConfirmationModalText}>
                    Are you sure you want to delete this upload?
                    </Typography.Text>
                </Col>

                <Col span={24}>
                <Flex justify='center' align='center' gap={16}>
                    <Button
                        className={styles.button}
                        onClick={handleModalClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        type='primary'
                        className={styles.button}
                        onClick={() =>
                            dispatch(deleteSmartUpload(smartUploadId!))}
                    >
                        Delete
                    </Button>
                    </Flex>
                </Col>
            </Row>
        </Modal>
    );
};

export default SmartUploadDeleteConfirmationModal;
