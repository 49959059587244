import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { Modal, Button, Col, Row, Form } from 'antd';
import '../../../../static/css/components/patient-insurance-modal.scss';

// Action imports
import { resetUsInsurance, toggleInsuranceModal } from '../../../reducers/us-insurance-slice';
import PatientInsuranceTable from './patient-insurance-table';
import PatientInsuranceForm from './patient-insurance-form';
import { InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';

const PatientInsuranceModal = () => {

    const isVisible = useCare1AppSelector(store => store.usInsurance.usInsuranceModalOpen);
    const insuranceList = useCare1AppSelector(store => store.usInsurance.insuranceList);

    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const insuranceListEmpty = (insuranceList.length === 0);

    const handleModalClose = () => {
        dispatch(toggleInsuranceModal());
        dispatch(resetUsInsurance());
    }

    const handleSave = () => {
        Modal.confirm({
            className: 'exam-exit-confirmation-modal',
            icon: <InfoCircleOutlined />,
            title: 'Are you sure you want to save changes to this insurance policy?',
            onOk: () => {form.submit()},
        });
    }

    const closeButton = (
        <Button
            key='close-button'
            onClick={handleModalClose}
            className='close-button'
        >
            Close
        </Button>
    )
    const saveButton = (
        <Button
            key='save-button'
            onClick={handleSave}
            className='save-button'
            icon={<SaveOutlined />}
            // If the insurance list is empty, disable the Save button. We require the user to add a policy before
            // saving it.
            disabled={insuranceListEmpty}
        >
            Save
        </Button>
    )

    return (
        <Modal
            className={'patient-insurance-modal'}
            open={isVisible}
            closable={false}
            title={null}
            footer={[saveButton, closeButton]}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <Row className='patient-insurance-modal-body'>
                <Col span={12} className='insurance-form-panel'>
                    <PatientInsuranceForm form={form} />
                </Col>
                <Col span={12}>
                    <PatientInsuranceTable />
                </Col>
            </Row>

        </Modal>
    );

}

export default PatientInsuranceModal;