import { forwardRef, useImperativeHandle, useEffect, useState, useRef } from 'react';
import { ILatencyMetricsCreateLogRequest, useLatencyMetricsCreateLogMutation } from './services/latency-metrics-api';


export interface LatencyMetricsEngineHandle {
    processLog: (timestamp: number, category: string, resource: string) => void;
    startTimerCheck: (start: boolean) => void;
}

interface LatencyMetricsEngineProps {
    metricsName: string;
    examId: string;
    timerMs: number;
    consloeLog: boolean;
}

const LatencyMetricsEngine = forwardRef<LatencyMetricsEngineHandle, LatencyMetricsEngineProps>(function LatencyMetricsEngine({metricsName, examId, timerMs, consloeLog}, ref) {

    const [latencyMetricsCreateLog] = useLatencyMetricsCreateLogMutation();

    const logTimer = useRef<any>();
    const firstLogTime = useRef(0);
    const lastLogTime = useRef(0);
    const firstLogResource = useRef("");
    const lastLogResource = useRef("");
    const timerCheckStarted = useRef(false);
    const metricsSent = useRef(false);

    const createLog = async (request: ILatencyMetricsCreateLogRequest) => {
        try {
            const result = await latencyMetricsCreateLog(request).unwrap();
            if (result.success) {
                // do something
            } else {
                throw new Error('cannot create latency metrics log');
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            console.log(`${message as string}`);
        }
    };

    useImperativeHandle(ref, () => {
        return {
            processLog(timestamp: number, category: string, resource: string) {
    
                if (consloeLog) {
                    console.log(`Profile ${metricsName} ${examId} ${timestamp} ${category} ${resource}`);
                }
              
                // Clear previous timeout
                if (logTimer.current) {
                  clearTimeout(logTimer.current);
                  logTimer.current = null;
                }
              
                if (!firstLogTime.current) {
                    firstLogTime.current = timestamp;
                    firstLogResource.current = resource;
                }
        
                // Set last log time to the current log's timestamp
                lastLogTime.current = timestamp;
                lastLogResource.current = resource;
              
                // Set a new timeout to check for the final log
                if (timerCheckStarted.current === true) {
                    logTimer.current = setTimeout(() => {
                        if (metricsSent.current === false) {
                            metricsSent.current = true;
                            if (consloeLog) {
                                console.log(`Profile ${metricsName} ${examId} finished total: ${lastLogTime.current - firstLogTime.current}, firstLogTime: ${firstLogTime.current}, lastLogTime: ${lastLogTime.current}`);
                            }
                            createLog({
                                exam_id: examId,
                                metric_name: metricsName,
                                latency_ms: lastLogTime.current - firstLogTime.current,
                            });
                        }
                    }, timerMs);
                }
            },
            startTimerCheck(start: boolean) {
                if (start === false) {
                    if (logTimer.current) {
                      clearTimeout(logTimer.current);
                      logTimer.current = null;
                    }
                }
                timerCheckStarted.current = start;                
            }
        }
    }, []);


    return <>
        <div></div>
    </>;
});


export interface LatencyMetricsHandle {
    processLog: (timestamp: number, category: string, resource: string) => void;
    startTimerCheck: (start: boolean) => void;
}

interface LatencyMetricsProps {
    metricsName: string;
    examId: string;
    timerMs: number;
    consloeLog: boolean;
}

const LatencyMetrics = forwardRef<LatencyMetricsHandle, LatencyMetricsProps>(function LatencyMetrics({metricsName, examId, timerMs, consloeLog}, ref) {

    const latencyMetricsEngineRef = useRef<LatencyMetricsEngineHandle>(null);

    if (latencyMetricsEngineRef.current) {
        latencyMetricsEngineRef.current.processLog(performance.timeOrigin + performance.now(), "logic", "LatencyMetrics::trigger");      
    }

    useImperativeHandle(ref, () => {
        return {
            processLog(timestamp: number, category: string, resource: string) {
                if (latencyMetricsEngineRef.current) {
                    latencyMetricsEngineRef.current.processLog(timestamp, category, resource);      
                }
            },
            startTimerCheck(start: boolean) {
                if (latencyMetricsEngineRef.current) {
                    latencyMetricsEngineRef.current.processLog(performance.timeOrigin + performance.now(), 'logic', `LatencyMetrics::startTimerCheck(${start})`); 
                    latencyMetricsEngineRef.current.startTimerCheck(start);      
                }
            }
        }
    }, []);

    useEffect(() => {
        if (latencyMetricsEngineRef.current) {
            latencyMetricsEngineRef.current.processLog(performance.timeOrigin + performance.now(), "logic", "LatencyMetrics::useEffect");      
                
            // Set up the performance observer to capture resource timings (including AJAX requests)
            const resourceObserver = new PerformanceObserver((list) => {
                const entries = list.getEntries();
                entries.forEach((entry) => {
                    if (latencyMetricsEngineRef.current) {
                        latencyMetricsEngineRef.current.processLog(performance.timeOrigin + performance.now(), "resource", entry.name);      
                    }
                }); 
            });
        
            // Start observing resource entries
            resourceObserver.observe({ entryTypes: ['resource'] });
    
            // Cleanup the observer when component unmounts
            return () => {
                resourceObserver.disconnect();
            };
        }
    }, [latencyMetricsEngineRef.current])

    return (<LatencyMetricsEngine 
                ref={latencyMetricsEngineRef} 
                metricsName={metricsName} 
                examId={examId} 
                timerMs={timerMs} 
                consloeLog={consloeLog} 
        />);
});


export default LatencyMetrics;

