import { useEffect, useState } from 'react';
import { Table, Button, Space, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../static/css/components/admin-doctor-list-table.scss';
import { EditOutlined,  DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { compareStrings } from '../../helpers/sorting';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { setCurrentTechnician, toggleTechnicianEditModal } from '../../reducers/doctor-slice';
import { localizedText } from '../../localizedText';
import { ITechnicianTableItem, useDeleteTechnicianMutation,  useGetTechnicianListQuery } from '../../services/od-group-practice-api';

const TechnicianTable = () => {
    const { DOCTORS_LIST_ACTION, OF_TEXT, ITEMS_TEXT } = localizedText;
    const searchQuery = useCare1AppSelector(store => store.doctorSlice.technicianSearchQuery);

    const dispatch = useCare1AppDispatch();
    
    const [scrollClass, setScrollClass] = useState('');

    const { data, isLoading } = useGetTechnicianListQuery();
    const [deleteTechnician] = useDeleteTechnicianMutation();

    const onDeleteTechnicianClick = (id: number) => {
        Modal.confirm({
            className: 'delete-doctor-confirmation-modal',
            icon: <InfoCircleOutlined />,
            title: 'Deleting a Technician will remove all the information. Are you sure you want to delete this technician?',
            onOk: async () => {
                try {
                    const result = await deleteTechnician(id).unwrap();
                    if (result.success) {
                        Modal.info({
                            className: 'info-modal',
                            title: 'Technician is successfully deleted.',
                        });
                    } else {
                        throw new Error(result?.error);
                    }
                } catch (error) {
                    const message = (error instanceof Error) ?  error?.message : error;
                    Modal.error({
                        className: 'info-modal',
                        content: message as string,
                        title: 'Error',
                    });
                }
                
            },
        })
    }

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const technicianList = data?.filter(({first_name, last_name, email}: {first_name: string, last_name: string, email: string}) => (
        first_name.toLowerCase().includes(searchQuery) ||
        last_name.toLowerCase().includes(searchQuery) || 
        email.toLowerCase().includes(searchQuery)
    ));

    const onTableRowEditClick = (record: ITechnicianTableItem) => {
        
        dispatch(toggleTechnicianEditModal())       
        dispatch(setCurrentTechnician(record.key));
    }

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }
    const columns: ColumnsType<ITechnicianTableItem> = [
        {
            className: 'group-practice-name-column',
            title: 'First Name',
            dataIndex: 'first_name',
            sorter: (a, b) => compareStrings(a['first_name'], b['first_name']),
        }, {
            className: 'group-practice-name-column',
            title: 'Last Name',
            dataIndex: 'last_name',
            sorter: (a, b) => compareStrings(a['last_name'], b['last_name']),
        }, {
            className: 'group-practice-name-column',
            title: 'OD Group Practice',
            dataIndex: 'od_group_practice_name',
            sorter: (a, b) => compareStrings(a['od_group_practice_name'], b['od_group_practice_name']),
        }, {
            className: 'group-practice-name-column',
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => compareStrings(a['email'], b['email']),
        }, {
            className: 'group-practice-name-column',
            title: 'Status',
            dataIndex: 'status',
            sorter: (a, b) => compareStrings(a['status'], b['status']),
        }, {
            className: 'action-column',
            title: DOCTORS_LIST_ACTION,
            key: 'action',
            render: (_, record) => (
                <Space>
                    <Button
                        className="edit-doctor-button"
                        onClick={() => onTableRowEditClick(record)}
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        className="delete-doctor-button"
                        onClick={() => onDeleteTechnicianClick(record.key)}
                    >
                        <DeleteOutlined />
                    </Button>
                </Space>
            ),
        }
    ];
    
    return (
        <div className={'doctor-list-table doctor-list-table-re'}>
            <div className={scrollClass}>
                <Table
                    columns={columns}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 30,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    loading={isLoading}
                    dataSource={technicianList}
                />
            </div>
        </div>
    );
}

export default TechnicianTable;