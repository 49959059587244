import { useCallback, useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { Card, Row, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { handleTxalgo3ButtonClick } from '../../../reducers/txalgo3-slice';
import { localizedText } from '../../../localizedText';
import '../../../../static/css/components/patient-exam-omdr-deepmd-band.scss';

const PatientExamOMDRComfortZones = () => {
    const { DEEPMD_COMFORT_ZONES, DEEPMD_CURRENT_COMFORT } = localizedText;
    const rightIopComfortZone = useCare1AppSelector(store => store.txalgo3.od_iop_aim);
    const worstRecClass = useCare1AppSelector(store => store.txalgo3.class_aim);
    const shortestRecFu = useCare1AppSelector(store => store.txalgo3.followup_aim);
    const leftIopComfortZone = useCare1AppSelector(store => store.txalgo3.os_iop_aim);

    const odIopAim = useCare1AppSelector(store => store.examData.od_iop_aim);
    const patientStatus = useCare1AppSelector(store => store.examData.patient_status);
    const followupAim = useCare1AppSelector(store => store.examData.followup_aim);
    const osIopAim = useCare1AppSelector(store => store.examData.os_iop_aim);

    const dispatch = useCare1AppDispatch();

    const currentComfortZonesClassname = (odIopAim === 'XXX' || patientStatus === 'XXX' || followupAim === 'XXX'
        || osIopAim === 'XXX') ? 'xxx' : 'not-xxx';

    const handleKeyPress = useCallback((e: KeyboardEvent) => {
        // when Ctrl + Shift + Z are clicked , trigger txalgo3 shoot in text
        const ctrlShiftZPressed = e.code === 'KeyZ' && e.ctrlKey && e.shiftKey;
        const ctrl1Pressed = e.code === 'Digit1' && e.ctrlKey;
        if (ctrlShiftZPressed || ctrl1Pressed) {
            dispatch(handleTxalgo3ButtonClick());
        }
    },[dispatch])

    useEffect(() => {
        // add event listener to handle keyboard shortcut
        window.addEventListener('keydown', handleKeyPress);

        return () => {
            // remove keypress event listener
            window.removeEventListener('keydown', handleKeyPress);
        }
    }, [handleKeyPress])

    return (
        <Card className={'component-input-card comfort-zones'} bordered={false}>
            <Row>
                <div className="heading">
                    {DEEPMD_COMFORT_ZONES}
                    <Tooltip placement='topRight' title='Ctrl + Shift + Z or Ctrl + 1'>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
            </Row>
            <div className='component-body deepmd-comfort-zones'>
                <span>
                    <span id="omdr_txalgo3_od_prev" data-testid='omdr_txalgo3_od_prev'>
                        {`${ rightIopComfortZone ? rightIopComfortZone : '- ' }`}, </span>
                    <span id="omdr_txalgo3_class_prev" data-testid='omdr_txalgo3_class_prev'>
                        {`${ worstRecClass ? worstRecClass : '- ' }`}, </span>
                    <span id="omdr_txalgo3_fu_prev" data-testid='omdr_txalgo3_fu_prev'>
                        {`${ shortestRecFu ? shortestRecFu : '- ' }`}, </span>
                    <span id="omdr_txalgo3_os_prev" data-testid='omdr_txalgo3_os_prev'>
                        {`${ leftIopComfortZone ? leftIopComfortZone : '- ' }`}</span>
                </span>
            </div>
            <Row>
                <div className="heading">{DEEPMD_CURRENT_COMFORT}</div>
            </Row>
            <div className='component-body current-comfort-zones'>
                <span className={currentComfortZonesClassname}>
                    <span id="omdr_radio_od_prev" data-testid='omdr_radio_od_prev'>
                        {`${ odIopAim ? odIopAim : '- ' }`}, </span>
                    <span id="omdr_radio_class_prev" data-testid='omdr_radio_class_prev'>
                        {`${ patientStatus ? patientStatus : '- ' }`}, </span>
                    <span id="omdr_radio_fu_prev" data-testid='omdr_radio_fu_prev'>
                        {`${ followupAim ? followupAim : '- ' }`}, </span>
                    <span id="omdr_radio_os_prev"data-testid='omdr_radio_os_prev'>
                        {`${ osIopAim ? osIopAim : '- ' }`}</span>
                </span>
            </div>
        </Card>
    );
}

export default PatientExamOMDRComfortZones;