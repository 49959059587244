import { InputNumber } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
// import ExamMetricValidationModal from './exam-metric-validation-modal';
import React from 'react';
import styles from '../../../../static/css/components/smart-upload-modal.module.scss';
import { IOP_VALIDATION_MSG } from '../../../constants';
import useOnBlurSave from '../../../hooks/useOnBlurSave';
import { DtcIopField, setSmartUploadSliceDataValue } from '../../../reducers/smart-upload-slice';
import ExamMetricValidationModal from '../metrics/exam-metric-validation-modal';

type ComponentProps = {
    side: 'od' | 'os';
    field: DtcIopField;
    onBlurToSave?: boolean;
}

export default function SmartUploadDtcIopEntry({
    side,
    field,
    onBlurToSave=true,
}: ComponentProps) {
    // Yellow warning border should display for OD users only
    const userIsOD = useCare1AppSelector((store) => store.user.isOD);

    const value = useCare1AppSelector((store) => store.smartUpload[field]);
    const disabled = useCare1AppSelector((store) => store.smartUpload.disabled);
    const otherSide = side === 'od' ? 'os' : 'od';
    const otherSideKey = field.replace(side, otherSide) as DtcIopField;
    const otherSideValue = useCare1AppSelector(
        (store) => store.smartUpload[otherSideKey]
    );

    const dispatch = useCare1AppDispatch();

    const { handleBlur } = useOnBlurSave({
        key: field,
        value: value ? value.toString() : '',
    });

    const validateDtcIopValue = () => {
        if (value && typeof value === 'number' && (value < 0 || value > 70)) {
            ExamMetricValidationModal(IOP_VALIDATION_MSG);
        } else {
            handleBlur();
        }
    };

    // Field has warning if both sides are empty.
    const hasWarning = userIsOD && !value && !otherSideValue;

    const handleBeforeInput = (
        event: React.CompositionEvent<HTMLInputElement>
    ) => {
        const { data } = event.nativeEvent;
        // Allow only numeric input, prevent any non-numeric characters
        if (!/^\d$/.test(data)) {
            event.preventDefault();
        }
    };

    return (
        <InputNumber
            size={'large'}
            controls={false}
            onChange={(val) =>
                dispatch(
                    setSmartUploadSliceDataValue({ key: field, value: val })
                )
            }
            value={value}
            onBeforeInput={handleBeforeInput}
            onBlur={onBlurToSave ? validateDtcIopValue: ()=>{}}
            addonBefore={side.toUpperCase()}
            className={hasWarning ? styles.hasWarning : ''}
            disabled={disabled}
        />
    );
}
