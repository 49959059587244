import { Modal } from 'antd';
import { togglePdfViewer } from '../../reducers/extra-image-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import PDFViewer from './pdf-viewer';

type ComponentProps = {
    idx: number,
    file: string,
}

const PDFViewerModal = ({ idx, file }: ComponentProps) => {
    const dispatch = useCare1AppDispatch();

    const pdfState = useCare1AppSelector(store => store.extraImage.pdf);

    const onPDFLoadSuccess = () => {
    }

    const onPDFLoadError = () => {
        return Modal.error({
            className: 'info-modal',
            title: 'GP Referral letter was not uploaded!',
        });
    }

    const onClose = () => {
        dispatch(togglePdfViewer(idx));
    }

    return (
        <Modal
            className={'pdfViewerModal'}
            open={pdfState.isOpen}
            width="540"
            onCancel={onClose}
            closable={false}
            footer={null}
        >
            <PDFViewer
                file={file}
                onPDFLoadSuccess={onPDFLoadSuccess}
                onPDFLoadError={onPDFLoadError}
                onClose={onClose}
            />
        </Modal>
    );
}
export default PDFViewerModal;
