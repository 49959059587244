import {
    CalendarOutlined, FileSyncOutlined, FolderOpenOutlined,
    InfoCircleOutlined,
    PlusOutlined, SearchOutlined
} from '@ant-design/icons';
import { Button, Col, Divider, Input, Modal, Radio, RadioChangeEvent, Row, Select, Space, Tooltip } from 'antd';
import '../../../static/css/components/patient-list-control-bar-od.scss';
import '../../../static/css/shared/patient-list-control-bar.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { localizedText } from '../../localizedText';
import { openAddPatientModal, openPatientSelectModal } from '../../reducers/patient-edit-slice';
import { openMultiTabsView, setPatientExamRoomsData } from '../../reducers/patient-exam-rooms-slice';
import { goToExamInNewTab, goToExamInNewTabWithTarget } from '../../reducers/patient-exam-slice';
import { getODPatientListRequest, setPatientListFieldData } from '../../reducers/patient-list-slice';
import { toggleSmartUploadModal } from '../../reducers/smart-upload-slice';


const PatientListControlBar = () => {
    const { EXAM_PERIOD_OPTIONS, NEW_PATIENT_TEXT, PATIENT_GROUPING_OPTIONS, PATIENT_LIST_SEARCH_PLACEHOLDER,
        PATIENT_LIST_TEXT, EXAM_STATUS_TYPES, EXAM_STATUS_REFERRAL_LETTER_TYPES, PATIENT_EXAM_URL, OPEN_SELECTED_VISITS, 
        OPEN_SELECTED_VISITS_TOOLTIP } = localizedText;

    const examPeriod = useCare1AppSelector(store => store.patientList.examPeriod);
    const examStatus = useCare1AppSelector(store => store.patientList.examStatus);
    const patientGrouping = useCare1AppSelector(store => store.patientList.patientGrouping);
    const examRooms = useCare1AppSelector(store => store.patientExams.rooms);
    const isRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const isOD = useCare1AppSelector(store => store.user.isOD);
    const odHasSmartUpload = useCare1AppSelector(store => store.user.odHasSmartUpload);
    const odUsesReferralLetterPei = useCare1AppSelector(store => store.user.odUsesReferralLetterPei);

    const examStatusOptions = odUsesReferralLetterPei ? EXAM_STATUS_REFERRAL_LETTER_TYPES : EXAM_STATUS_TYPES;

    const smartUploadText = 'No patient creation or selection required. This option uses an AI ' + 
        'assisted process to match the upload to a new or existing patient based on the demographics' + 
        ' contained in the OD letter.';
    
    const manualUploadtext = 'This option requires manual selection of an existing patient or ' +
        'manual entry of data for creating a new Care1 patient.';

    const dispatch = useCare1AppDispatch();

    const controlBarOpenAddPatientModal = () => {
        dispatch(openAddPatientModal());
    }

    const handleExamPeriodSelection = (value: string) => {
        dispatch(setPatientListFieldData({ key:'examPeriod', value }));
        dispatch(getODPatientListRequest());
    }

    const handleExamStatusSelection = (value: string) => {
        dispatch(setPatientListFieldData({ key:'examStatus', value }));
        dispatch(getODPatientListRequest());
    }

    const handlePatientGroupingSelection = (event: RadioChangeEvent) => {
        dispatch(setPatientListFieldData({ key:'patientGrouping', value: event.target.value }));
        dispatch(getODPatientListRequest());
    }

    const sendSearchPatientListRequest = (value: string) => {
        if(value){
            // Search all statuses, all time and all clinic patients
            dispatch(setPatientListFieldData({ key: 'examStatus', value: 'all_statuses' }));
            dispatch(setPatientListFieldData({ key: 'examPeriod', value: 'all_time' }));
            dispatch(setPatientListFieldData({ key: 'patientGrouping', value: 'clinic' }));

            // Set search value and send request
            dispatch(setPatientListFieldData({ key: 'searchQuery', value }));
            dispatch(getODPatientListRequest());
        } else {
            // Reset the default values
            dispatch(setPatientListFieldData({ key: 'examStatus', value: 'not_ready_od_review' }));
            dispatch(setPatientListFieldData({ key: 'examPeriod', value: '12_months' }));
            dispatch(setPatientListFieldData({ key: 'patientGrouping', value: 'doctor' }));
            dispatch(setPatientListFieldData({ key: 'searchQuery', value: '' }));
            dispatch(getODPatientListRequest());
        }
    }

    const handleOpenSelectedPatients = () => {
        if(examRooms && examRooms.length){
            if (isOD) {
                for (let i=0; i<examRooms.length; i++) {
                    const examUrl = `${PATIENT_EXAM_URL}/${examRooms[i].examId}`;
                    const target = `target-${examRooms[i].examId}`;
                    goToExamInNewTabWithTarget(examUrl, target);
                }
            }
            else {
                const examUrl = `${PATIENT_EXAM_URL}/${examRooms[0].examId}`;
                dispatch(openMultiTabsView());
                dispatch(setPatientExamRoomsData({ currentExamRoom: examRooms[0].examId.toString() }));
                goToExamInNewTab(examUrl);
            }
        } else{
            Modal.info({
                className: 'info-modal',
                title: `No exam selected`,
            })
        }
    }

    return (
        <Row
            align='middle'
            justify='space-between'
            className={'patient-list-control-bar-od control-bar-elements'}
            wrap={false}
        >
                {/* PT-LIST-CTRL-BAR 001 */}
                <Col span={6} className="patient-list-title">
                    {PATIENT_LIST_TEXT}
                {!isRE &&
                    <Button
                        className="open-selected-patients-button"
                        icon={<FolderOpenOutlined />}
                        onClick={handleOpenSelectedPatients}
                        type='primary'
                    >
                    {OPEN_SELECTED_VISITS}
                    <Tooltip className="popup-tooltip" placement='topRight' title={OPEN_SELECTED_VISITS_TOOLTIP}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Button>
                }
                </Col>
                {/* PT-LIST-CTRL-BAR 002 */}
                <Col span={14} className='patient-filters'>
                    <Input.Search
                        className="patient-list-search-input"
                        allowClear
                        placeholder={PATIENT_LIST_SEARCH_PLACEHOLDER}
                        prefix={<SearchOutlined />}
                        onSearch={sendSearchPatientListRequest}
                        size='large'
                    />

                    <Divider type="vertical" className="patient-list-vertical-divider-od"  />
                    {/* PT-LIST-CTRL-BAR 003 */}

                    <Select
                    className="dropdown-filter patient-list-exam-status-filter"
                    popupClassName="patient-list-dropdown"
                    defaultValue={odUsesReferralLetterPei ? 'not_ready_od_review' : 'not_ready'}
                    onSelect={handleExamStatusSelection}
                    data-testid="exam-status-select"
                    value={examStatus}
                    size='large'
                >
                    {examStatusOptions && examStatusOptions.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            <FileSyncOutlined /> {option.label}
                        </Select.Option>
                    ))}
                </Select>
                {/* PT-LIST-CTRL-BAR 005, 008 */}
                <Select
                    className="dropdown-filter patient-list-exam-period-filter"
                    popupClassName="patient-list-dropdown"
                    defaultValue={examPeriod}
                    onSelect={handleExamPeriodSelection}
                    data-testid="exam-period-select"
                    value={examPeriod}
                    size='large'
                >
                    {EXAM_PERIOD_OPTIONS && EXAM_PERIOD_OPTIONS.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            <CalendarOutlined /> {option.label}
                        </Select.Option>
                    ))}
                </Select>
                <Divider type="vertical" className="patient-list-vertical-divider-od" />

                    <Radio.Group
                        defaultValue="doctor"
                        className="patient-list-group-type"
                        onChange={handlePatientGroupingSelection}
                        value={patientGrouping}
                    >
                        {PATIENT_GROUPING_OPTIONS && PATIENT_GROUPING_OPTIONS.map((option) => (
                            <Radio.Button
                                className="radio-group-button patient-list-group-type-button"
                                key={option.key}
                                value={option.key}
                            >
                                {option.value}
                            </Radio.Button>
                        ))}
                    </Radio.Group>

                {/* PT-LIST-CTRL-BAR 004, 007 */}
                
               
                </Col>
                <Col span={4}>

                {/* PT-LIST-CTRL-BAR 006 */}
                {
                    odUsesReferralLetterPei ?
                    <Space>
                        <Tooltip title={odHasSmartUpload ? smartUploadText : null} placement='topRight'>
                            <Button
                                className="patient-list-new-patient-button smart-upload"
                                type='primary'
                                onClick={() => {
                                    dispatch(toggleSmartUploadModal());
                                }}
                                disabled={!odHasSmartUpload}
                            >Smart Upload</Button>
                        </Tooltip>
                        
                        <Tooltip title={odHasSmartUpload ? manualUploadtext : null} placement='topRight'>
                            <Button
                                className="patient-list-new-patient-button"
                                onClick={() => dispatch(openPatientSelectModal())}
                                type='primary'
                            >
                                Manual Upload
                            </Button>
                        </Tooltip>
                    </Space>
                    :
                    <Button
                        className="patient-list-new-patient-button"
                        icon={<PlusOutlined />}
                        onClick={controlBarOpenAddPatientModal}
                        type='primary'
                    >
                        {NEW_PATIENT_TEXT}
                    </Button>
                }
                </Col>
        </Row>
    );
}

export default PatientListControlBar;
