import Care1LightBox from './care1-light-box';
import { convertTimeToShortFormatLabel } from '../../helpers/utilities';
import { clearHistoryImageData, setHistoryImageLightboxData, toggleLightbox } from '../../reducers/history-image-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

type ComponentProps = {
    type: string,
    side: string,
    photoUrl: string | undefined,
    examDate: string,
    idx: number,
    borderClass: string,
    style: Record<string,string>,
    preGeneratedThumbnail?: boolean,
}

// The preGeneratedThumbnail flag controls whether we use a pre-generated thumbnail. When set to false, we rely
// on the lightbox component to auto-generate for us. When set to true, we use a pre-generated thumbnail.
const HistoryImage = ({ type, side, photoUrl, examDate, idx, borderClass, style, preGeneratedThumbnail=true }: ComponentProps) => {

    const dispatch = useCare1AppDispatch();

    const historyImage = useCare1AppSelector(store => store.historyImage);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const examIsRE = useCare1AppSelector(store => store.examData.exam_is_retina_only);
    const currentImageUrl = historyImage.currentLightboxImage;
    const nextImageUrl = historyImage.nextLightboxImage;
    const prevImageUrl = historyImage.prevLightboxImage;
    const lightboxTitle = historyImage.lightboxTitle;
  
    const thumbnailSize = userIsOMDC || (userIsOMDR && !examIsRE)  ? '_largethumb' : '_thumb';

    const sendToggleLightbox = () => {
        // dispatch(toggleHistoryImageLightbox())
        dispatch(setHistoryImageLightboxData({
            side: side as 'left'|'right',
            type,
            url: photoUrl ?? '',
        }));
        dispatch(toggleLightbox({
            index: idx,
            imageType: type,
            imageSide: side,
        }));
    }

    const onNextLightboxImage = () => {
        dispatch(setHistoryImageLightboxData({
            side: side as 'left'|'right',
            type,
            url: nextImageUrl ?? '',
        }))
    }

    const onPrevLightboxImage = () => {
        dispatch(setHistoryImageLightboxData({
            side: side as 'left'|'right',
            type,
            url: prevImageUrl ?? '',
        }))
    }

    const mainSrcUrl = photoUrl as string;

    // The thumbnail url file name is just the main image file name with a "_thumb" ending.
    const extensionIndex = mainSrcUrl.lastIndexOf(".");
    const mainSrcThumbnailUrl = preGeneratedThumbnail ?
        `${mainSrcUrl.substring(0, extensionIndex)}${thumbnailSize}${mainSrcUrl.substring(extensionIndex)}` :
        mainSrcUrl;

    return (
        <div id={idx.toString()} key={idx} className={`extra-image image ${borderClass ? borderClass : ''}`}>
            <img
                role="img"
                alt=""
                className="img-thumbnail"
                src={mainSrcThumbnailUrl}
                onClick={() => sendToggleLightbox()}
                style={style}
                onError={
                    (e)=>{
                        // If the the thumbnail image can't be found, just use the full image.
                        if (e.currentTarget.src !== mainSrcUrl) {
                            e.currentTarget.src=mainSrcUrl;
                        }
                    }
                }
            />
            <div className="heading exam-date">{convertTimeToShortFormatLabel(examDate)}</div>
            {            
                <Care1LightBox
                    field={`history_image_${idx}_${type}_${side}`}
                    isOpen={historyImage.lightboxOnForIndex === idx && historyImage.imageType === type && historyImage.imageSide === side}
                    mainSrc={currentImageUrl}
                    nextSrc={nextImageUrl}
                    prevSrc={prevImageUrl}
                    onMoveNextRequest={onNextLightboxImage}
                    onMovePrevRequest={onPrevLightboxImage}
                    mainSrcThumbnail={mainSrcThumbnailUrl}
                    onCloseRequest={() => dispatch(clearHistoryImageData())}
                    closeLabel="CLOSE"
                    wrapperClassName="LightboxImage"
                    imageTitle={lightboxTitle}
                />                
            }
        </div>
    );
}

export default HistoryImage;