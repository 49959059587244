import {
    useCare1AppSelector,
    useCare1AppDispatch,
} from '../../apps/care1-hooks';
import { Card, Radio, Row, Col, Typography, Tooltip, Flex } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import styles from '../../../static/css/components/patient-exam-upload-type.module.scss';

import ComponentRadioGroup from '../common/component-radio-group';
import PatientExamUploadTypeCare1 from './patient-exam-upload-type-care1';
import PatientExamUploadTypeOutboundReferral from './patient-exam-upload-type-outbound-referral';
import { RadioChangeEvent } from 'antd/lib';

const PatientExamUploadType = ({ disabled }: { disabled: boolean }) => {
    const isOutboundReferral = useCare1AppSelector(
        (store) => store.examData.is_outbound_referral
    );
    const dispatch = useCare1AppDispatch();

    const handleRadioGroupChange = (e: RadioChangeEvent) => {
        dispatch(setExamDataValue('is_outbound_referral', e.target.value));
    };

    return (
        <Card
            bordered={false}
            className={styles.uploadType}
            styles={{ body: { padding: '24px 0' } }}
        >
            <Row gutter={[0, 16]}>
                <Col span={24}>
                    <Flex justify='space-between' align='center'>
                        <Typography.Text>Type of Upload</Typography.Text>
                        <ComponentRadioGroup
                            value={isOutboundReferral}
                            handleRadioGroupChange={handleRadioGroupChange}
                        >
                            <Tooltip
                                placement='topLeft'
                                title='This option allows you to upload a patient encounter intended for a virtual consultation.'
                            >
                                <Radio.Button disabled={disabled} value={false}>
                                    Care1 Upload
                                </Radio.Button>
                            </Tooltip>
                            <Tooltip
                                placement='topLeft'
                                title='This option allows you to upload a patient encounter intended for an in-person referral to an OMD specialist. The referral will be instantly e-faxed to the OMD office and a virtual OMD consultation will not be provided.'
                            >
                                <Radio.Button disabled={disabled} value={true}>
                                    Outbound Referral
                                </Radio.Button>
                            </Tooltip>
                        </ComponentRadioGroup>
                    </Flex>
                </Col>
                {!isOutboundReferral ? (
                    <PatientExamUploadTypeCare1 disabled={disabled} />
                ) : (
                    <PatientExamUploadTypeOutboundReferral
                        disabled={disabled}
                    />
                )}
            </Row>
        </Card>
    );
};

export default PatientExamUploadType;
