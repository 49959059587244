import { InputNumber } from 'antd';
import { allowPosNumberAndDecimalOnlyKeys } from '../../../helpers/utilities';
import '../../../../static/css/components/patient-exam-vert-cd.scss';

type ComponentProps = {
    sideValue: string | number | null,
    onChange: (value: number | string | null) => void;
    gptResultState: string;
}

const GptPatientExamVertCD = ({ sideValue, onChange, gptResultState }: ComponentProps) => {

    // All vert CD entries should be in the format of '0.##'
    const formatValue = (value: string | number | undefined, info: {
        userTyping: boolean;
        input: string;
    }): string => {
        const regex = /^(0?)(\.?)(([0-9][1-9])|([1-9])).*$/;
        const matches = String(value).match(regex);

        // Check length and supply decimal places only when user is not inputting
        // i.e onBlur (onBlur itself is overwritten by formatValue)
        // And when not enough decimal places
        if (!info.userTyping && value?.toString().length !== 4) {
            // when the value is empty string, it should stay empty string, not 0.00
            if (value === '') return value;
            const formattedValue = Number(value).toFixed(2).toString();
            return formattedValue;
        }

        let vertCD = '';

        if (matches) {
            let match = matches[4] ? Number(`0.${matches[4]}`)
                : matches[5] ? Number(`0.${matches[5]}`)
                    : Number(`${matches[1]}${matches[2]}`);

            vertCD = match.toString();
        }

        return vertCD;
    }

    const getClassname = () => {
        if (sideValue) {
            try {
                const f = parseFloat(sideValue.toString());
                const fstr = `${f.toFixed(2)}`;

                if (fstr === '0.70' || fstr === '0.80') {
                    return 'input-component yellow-value';
                }
                else if (f >= 0.9) {
                    return 'input-component red-value';
                }
                else {
                    return 'input-component';
                }
            }
            catch (e) {
            }
        }
        return 'input-component';
    }

    return (
        <div className={`${gptResultState}`}>
            <InputNumber
                className={getClassname()}
                size="small"
                step={0.15}
                onKeyPress={event => allowPosNumberAndDecimalOnlyKeys(event)}
                formatter={formatValue}
                onChange={onChange}
                value={sideValue}
            />
        </div>
    );
}

export default GptPatientExamVertCD;