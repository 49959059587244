import { useCare1AppSelector } from '../apps/care1-hooks';
import { Row, Col } from 'antd';
import * as Constants from '../constants';

// Components
import PatientExamAllergies from '../components/integrated/patient-exam-allergies';
import PatientExamChiefComplaint from '../components/retina/patient-exam-chief-complaint';
import PatientExamConversation from '../components/retina/patient-exam-conversation';
import PatientExamExtraImages from '../components/retina/patient-exam-extra-images';
import PatientExamFundus from '../components/retina/patient-exam-fundus';
import PatientExamHeader from '../components/retina/header/patient-exam-header';
import PatientExamOCTMacula from '../components/retina/patient-exam-oct-macula';
import PatientExamMetrics from '../components/integrated/metrics/patient-exam-metrics';
import PatientExamVF from '../components/integrated/patient-exam-vf';
import PatientExamOCTRnfl from '../components/integrated/patient-exam-oct-rnfl';
import PatientExamOMDHistory from '../components/integrated/patient-exam-omd-history';
import PatientExamImpressions from '../components/integrated/patient-exam-impressions';
import PatientExamHistoryMedsExams from '../components/reviewer/patient-exam-history-meds-exams';
import PatientExamODNotes from '../components/integrated/patient-exam-od-notes';
import PatientExamIOPHistory from '../components/integrated/patient-exam-iop-history';
import PatientExamUntilYesterday from '../components/integrated/patient-exam-until-yesterday';
import PatientExamOngoing from '../components/integrated/patient-exam-ongoing';
import PatientExamComorbidities from '../components/integrated/patient-exam-comorbidities'
import PatientExamStereoViewer from '../components/integrated/patient-exam-stereo-viewer';
import PatientExamVFImageHistory from '../components/image-histories/patient-exam-vf-image-history';
import PatientExamOCTRNFLImageHistory from '../components/image-histories/patient-exam-oct-rnfl-image-history';
import PatientExamOCTMaculaImageHistory from '../components/image-histories/patient-exam-oct-macula-image-history';
import PatientExamFundusImageHistory from '../components/image-histories/patient-exam-fundus-image-history';

// Helpers
import { patientIsGlaucoma } from '../helpers/diagnosis-convert';
import { localizedText } from '../localizedText'
import '../../static/css/pages/omdc-patient-exam-page.scss';
import '../../static/css/shared/pei-exam-room-tabs.scss';
import IopBcvaGraphBand from '../components/integrated/iop-bcva-graph-band';
import OmdcReviewerBand from '../components/consultant/patient-exam-omd-consultant-band';


const OMDCLegacyExamPage = () => {
    const { PHOTO_DROP_TEXT_SHORT } = localizedText;

    const diagnosisEntries = useCare1AppSelector(store => store.diagnosis.entries);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const omdcStatus = useCare1AppSelector(store => store.examData.omdc_status);
    const glc = useCare1AppSelector(store => store.examData.rr_glc);
    const glcS = useCare1AppSelector(store => store.examData.rr_glc_suspect);
    const narrowAngles = useCare1AppSelector(store => store.examData.rr_narrow_angles);
    const examOMDC = useCare1AppSelector(store => store.examData.omdc);
    const omdcId = useCare1AppSelector(store => store.user.doctorID);
     
    // Redux and React Router Hooks

    const isReadOnly = userIsOMDC;
    const isGlaucoma = patientIsGlaucoma(diagnosisEntries, { glc, glcS, narrowAngles });

    const omdcInputComponentReadOnly = (omdcStatus !== 'ready' || examOMDC !== omdcId);

    return (
        <div className="omdc-patient-exam-page">
            <Row>
                <PatientExamHeader />
            </Row>
            <Row gutter={8}>
                <Col span={6} className="dropzones-container">
                    <PatientExamFundus
                        tabbed
                        disabled={isReadOnly}
                        className="patient-exam-fundus"
                        side={Constants.RIGHT_SIDE_TEXT}
                    />
                    <Row gutter={8}>
                        <Col span={12}>
                            <PatientExamOCTMacula
                                dropText={PHOTO_DROP_TEXT_SHORT}
                                disabled={isReadOnly}
                                side={Constants.RIGHT_SIDE_TEXT}
                                tabbed={true}
                            />
                            <PatientExamOCTRnfl
                                disabled={isReadOnly}
                                side={Constants.RIGHT_SIDE_TEXT}
                            />
                        </Col>
                        <Col span={12}>
                            <PatientExamVF disabled={isReadOnly} side={Constants.RIGHT_SIDE_TEXT} />
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row gutter={8}>
                        <Col span={6}>
                            <Row>
                                <PatientExamChiefComplaint disabled={isReadOnly} />
                            </Row>
                        </Col>
                        <Col span={14}>
                            <PatientExamOMDHistory disabled={isReadOnly} />
                        </Col>
                        <Col span={4}>
                            <PatientExamAllergies disabled={isReadOnly} />
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={24}>
                            <PatientExamHistoryMedsExams disabled={isReadOnly} />
                        </Col>
                    </Row>
                    <Row>
                        <PatientExamMetrics disabled={isReadOnly} />
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <PatientExamImpressions disabled={isReadOnly} />
                        </Col>
                        <Col span={12}>
                            <PatientExamODNotes disabled={isReadOnly} />
                        </Col>
                    </Row>
                </Col>
                <Col span={6} className="dropzones-container">
                    <PatientExamFundus
                        tabbed
                        disabled={isReadOnly}
                        className="patient-exam-fundus"
                        side={Constants.LEFT_SIDE_TEXT}
                    />
                    <Row gutter={8}>
                        <Col span={12}>
                            <PatientExamOCTMacula
                                dropText={PHOTO_DROP_TEXT_SHORT}
                                disabled={isReadOnly}
                                side={Constants.LEFT_SIDE_TEXT}
                                tabbed={true}
                            />
                            <PatientExamOCTRnfl
                                disabled={isReadOnly}
                                side={Constants.LEFT_SIDE_TEXT}
                            />
                        </Col>
                        <Col span={12}>
                            <PatientExamVF disabled={isReadOnly} side={Constants.LEFT_SIDE_TEXT} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {isGlaucoma &&
                <Row gutter={8}>
                    <Col span={6} className="pei-iop-history-col">
                        <PatientExamIOPHistory disabled={isReadOnly} />
                    </Col>
                    <Col span={6} className="pei-until-yesterday-col">
                        <PatientExamUntilYesterday disabled={isReadOnly} />
                    </Col>
                    <Col span={6} className="pei-ongoing-col">
                        <PatientExamOngoing disabled={isReadOnly} />
                    </Col>
                    <Col span={6} className="pei-comorbidities-col">
                        <PatientExamComorbidities disabled={isReadOnly} />
                    </Col>
                </Row>
            }
            <OmdcReviewerBand disabled={omdcInputComponentReadOnly} />
            <PatientExamStereoViewer inGlaucomaWorksheet={false} />
            <PatientExamExtraImages
                disabled={isReadOnly}
                className="patient-exam-extra-images"
            />
            <IopBcvaGraphBand />
            <Row gutter={8}>
                <Col span={24}>
                    <PatientExamConversation />
                </Col>
            </Row>
            {/* START | Section 10 - VF History band */}
            <Row gutter={8}>
                <PatientExamVFImageHistory />
            </Row>
            {/* END | Section 10 - VF band */}

            {/* START | Section 11 - RNFL History band */}
            <Row gutter={8}>
                <PatientExamOCTRNFLImageHistory />
            </Row>
            {/* END | Section 11 - RNFL History band */}

            {/* START | Section 12 - Macula History band*/}
            <Row gutter={8}>
                <PatientExamOCTMaculaImageHistory orderBy={'asc'} showHint={true} showBaselineBorder={true} showEmptyRow={true} />
            </Row>
            {/* END | Section 12 - Macula History band */}
            {/* START |  Section 13 - Fundus History band */}
            <Row gutter={8}>
                <PatientExamFundusImageHistory />
            </Row>
            {/* END | Section 13 - Fundus History band */}
            <Row>
                <br />
                <br />
            </Row>
        </div>
    );
}

export default OMDCLegacyExamPage;