import { Fragment, useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';
import { Row, Col, Collapse, Flex, Card, Tooltip } from 'antd';
import * as Constants from '../constants';
import { InfoCircleOutlined } from '@ant-design/icons';

// Components
import DeepMDBand from '../components/reviewer/deep-md/patient-exam-deep-md';
import OMDSubmitButtons from '../components/reviewer/omd-submit-buttons';
import PatientExamConversation from '../components/retina/patient-exam-conversation';
import PatientExamExtraImages from '../components/retina/patient-exam-extra-images';
import PatientExamFollowUpWithOMD from '../components/reviewer/patient-exam-fu-with-omd';
import PatientExamFundus from '../components/retina/patient-exam-fundus';
import PatientExamFundusImageHistory from '../components/image-histories/patient-exam-fundus-image-history';
import PatientExamHeader from '../components/retina/header/patient-exam-header';
import PatientExamInitiateTreatment from '../components/reviewer/patient-exam-initiate-treatment';
import PatientExamLastLetterToGP from '../components/reviewer/patient-exam-last-letter-gp';
import PatientExamNotesToMOA from '../components/reviewer/patient-exam-notes-to-moa';
import PatientExamNotesToODAndGP from '../components/reviewer/patient-exam-notes-to-od-and-gp';
import PatientExamNotesToODCarryOver from '../components/reviewer/patient-exam-notes-to-od-carry-over';
import PatientExamOCTMacula from '../components/retina/patient-exam-oct-macula';
import PatientExamOCTRnfl from '../components/integrated/patient-exam-oct-rnfl';
import PatientExamOMDRCDValuesBand from '../components/reviewer/patient-exam-omdr-cd-values-band';
import PatientExamOMDReviewerSelectors from '../components/reviewer/patient-exam-omd-reviewer-selectors';
import PatientExamOMDRIOPFinalRecommendations from '../components/reviewer/patient-exam-omdr-iop-final-recommendations';
import PatientExamOMDRNotes from '../components/reviewer/patient-exam-omdr-notes';
import PatientExamOCTMaculaImageHistory from '../components/image-histories/patient-exam-oct-macula-image-history';
import PatientExamOCTRNFLImageHistory from '../components/image-histories/patient-exam-oct-rnfl-image-history';
import PatientExamPremiumHeader from '../components/retina/header/patient-exam-premium-header';
import PatientExamSecondOpinionSelectors from '../components/reviewer/patient-exam-second-opinion-selectors';
import PatientExamStereoViewer from '../components/integrated/patient-exam-stereo-viewer';
import PatientExamVerbalize from '../components/reviewer/patient-exam-verbalize';
import PatientExamVF from '../components/integrated/patient-exam-vf';
import PatientExamVFImageHistory from '../components/image-histories/patient-exam-vf-image-history';
import PatientExamCpt from '../components/reviewer/us/patient-exam-cpt';
import PatientExamIcd10 from '../components/reviewer/us/patient-exam-icd-10';
import PatientExamODNotes from '../components/integrated/patient-exam-od-notes';

// Helpers
import '../../static/css/pages/omdr-patient-exam-page.scss';
import { localizedText } from '../localizedText';
import ReferralLetterOmdrPdfViewer from '../components/reviewer/referral-letter-omdr-pdf-viewer';
import PatientExamUntilYesterday from '../components/integrated/patient-exam-until-yesterday';
import PatientExamComorbidities from '../components/integrated/patient-exam-comorbidities';
import PatientExamIOPHistoryReferralLetter from '../components/integrated/patient-exam-iop-history-referral-letter';
import PatientExamOngoing from '../components/integrated/patient-exam-ongoing';
import IopBcvaGraphBand from '../components/integrated/iop-bcva-graph-band';
import OmdrGptAISnapshotBand from '../components/admin/gpt/omdr-gpt-ai-snapshot-band';
import { retrieveGptAiSnapshotBandValues } from '../reducers/gpt-ai-snapshot-slice';
import OmdrReReviewerBand from '../components/reviewer/patient-exam-omdr-re-reviewer-band';
import commonLayoutStyles from '../../static/css/components/common-layout.module.scss';


// Actions

const OMDRReferralLetterExamPage = () => {
    const { PHOTO_DROP_TEXT_SHORT } = localizedText;

    const dispatch = useCare1AppDispatch();

    const examId = useCare1AppSelector(store => store.examData.id);

    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const examIsRE = useCare1AppSelector(store => store.examData.exam_is_retina_only);
    const examStatus = useCare1AppSelector(store => store.examData.exam_status);
    const isUsOnly = useCare1AppSelector(store => store.user.isUsOnly);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const examStatusPrevious = useCare1AppSelector(store => store.examData.exam_status_previous);

    const glc = useCare1AppSelector(store => store.examData.rr_glc);
    const glcSuspect = useCare1AppSelector(store => store.examData.rr_glc_suspect);
    const narrowAngles = useCare1AppSelector(store => store.examData.rr_narrow_angles);

    const isReadOnly = userIsOMDR;
    const isOMDReviewed = examStatus === 'reviewed';

    const visionImpressions = useCare1AppSelector(store => store.gptAiSnapshot.gpt_ai_snapshot_band_impressions);
    const visionOdAssessment = useCare1AppSelector(store => store.gptAiSnapshot.gpt_ai_snapshot_band_od_assessment);
    const visionOdQuestion = useCare1AppSelector(store => store.gptAiSnapshot.gpt_ai_snapshot_band_od_question);

    const userIsOmdrRE = userIsOMDR && userIsRE;

    const isOMDReadyOrReviewed = examStatusPrevious === 'ready' || examStatusPrevious === 'reviewed';

    const odGroupPracticeProvince = useCare1AppSelector(store => store.examData.od_group_practice_province);
    const isManitoba = odGroupPracticeProvince === 'MB';

    const rluExamPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);

    useEffect(() => {
        if (examId != null) {
            dispatch(retrieveGptAiSnapshotBandValues(examId));
        }
    }, [])

    const visionAISnapshotBandProps = {
        impressions: visionImpressions,
        odAssessment: visionOdAssessment,
        odQuestion: visionOdQuestion,
    };

    const gptAISnapshotBandItems = [
        {
          key: '1',
          label: <div>
            <span className='title'>Care1 AI Snapshot (In beta testing)</span>
            <span className='title-tooltip'>
                <Tooltip
                    className={'recommendations-tooltip'}
                    placement='right'
                    title="This section is generated entirely by AI reading the uploaded referral letter, and may not be accurate. Please utilize at your discretion."
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </span>
          </div>,
          children: <>
            <Row>
                <Col span={24}>
                    <OmdrGptAISnapshotBand {...visionAISnapshotBandProps}/>
                </Col>
            </Row>
          </>,
        },]

    return (
        <>
            <Row>
                {/* Premium RLU OMDR exams should use the premium header */}
                {rluExamPremium ?
                    <PatientExamPremiumHeader />
                    :
                    <PatientExamHeader />
                }
            </Row>

            <Row className="gpt-ai-snapshot-band">
                <Col span={24}>
                    <div className='omdr-patient-exam-od-notes'>
                        <PatientExamODNotes className={isManitoba ? 'manitoba-od-notes input-od-notes' : 'input-od-notes'} disabled={isOMDReadyOrReviewed}
                                                odNotesTitle={'OD Notes'} />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Collapse items={gptAISnapshotBandItems} defaultActiveKey={['1']} />;
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={6}>
                    <PatientExamFundus
                            tabbed={!examIsRE}
                            disabled={isReadOnly}
                            className="patient-exam-fundus"
                            side={Constants.RIGHT_SIDE_TEXT}
                        />
                </Col>
                <Col span={3}>
                    <PatientExamOCTMacula
                        dropText={PHOTO_DROP_TEXT_SHORT}
                        disabled={isReadOnly}
                        side={Constants.RIGHT_SIDE_TEXT}
                        tabbed={true}
                    />
                    <PatientExamOCTRnfl disabled={isReadOnly} side={Constants.RIGHT_SIDE_TEXT} />
                </Col>
                <Col span={3}>
                    <PatientExamVF disabled={isReadOnly} side={Constants.RIGHT_SIDE_TEXT} />
                </Col>
                <Col span={3}>
                    <PatientExamVF disabled={isReadOnly} side={Constants.LEFT_SIDE_TEXT} />
                </Col>
                <Col span={3}>
                    <PatientExamOCTMacula
                        dropText={PHOTO_DROP_TEXT_SHORT}
                        disabled={isReadOnly}
                        side={Constants.LEFT_SIDE_TEXT}
                        tabbed={true}
                    />
                    <PatientExamOCTRnfl disabled={isReadOnly} side={Constants.LEFT_SIDE_TEXT} />
                </Col>
                <Col span={6}>
                    <PatientExamFundus
                        tabbed={!examIsRE}
                        disabled={isReadOnly}
                        className="patient-exam-fundus"
                        side={Constants.LEFT_SIDE_TEXT}
                    />
                </Col>
            </Row>

            { (glc || glcSuspect || narrowAngles) && 
                (<Row gutter={8}>
                <Col span={6} className="pei-iop-history-col">
                    <PatientExamIOPHistoryReferralLetter disabled={false} />
                </Col>
                <Col span={6} className="pei-until-yesterday-col">
                    <PatientExamUntilYesterday disabled={isReadOnly} />
                </Col>
                <Col span={6} className="pei-ongoing-col">
                    <PatientExamOngoing disabled={isReadOnly} />
                </Col>
                <Col span={6} className="pei-comorbidities-col">
                    <PatientExamComorbidities disabled={isReadOnly} />
                </Col>
                </Row>)
            }

            { !userIsOmdrRE ? 

                <Row gutter={4} className={`${isUsOnly ? 'is-us-only' : ''}`}>
                    <Col span={5}>
                        <PatientExamNotesToODCarryOver disabled={false} />
                    </Col>
                    <Col span={12}>
                        <Flex vertical gap='small' className={commonLayoutStyles.verticalFlex}>
                        <Col span={24} flex={'0 1 86px'}>
                            <PatientExamOMDReviewerSelectors />
                        </Col>
                        <Col span={24} flex={'1 1 100%'}>
                            <Flex className={commonLayoutStyles.verticalFlex}>
                                <Col span={12}>
                                    <PatientExamOMDRNotes disabled={false} />
                                </Col>
                                <Col span={12}>
                                    <PatientExamNotesToODAndGP disabled={false} />
                                </Col>
                            </Flex>
                        </Col>
                    </Flex>
                        {isUsOnly &&
                            <Row gutter={4}>
                                <Col span={12}>
                                    <PatientExamCpt />
                                </Col>
                                <Col span={12}>
                                    <PatientExamIcd10 />
                                </Col>
                            </Row>
                        }

                    </Col>
                    <Col span={3}>
                        <PatientExamInitiateTreatment />
                        <PatientExamVerbalize />
                        <PatientExamNotesToMOA disabled={false} />
                    </Col>
                    <Col span={4}>
                        <PatientExamFollowUpWithOMD />
                        <PatientExamLastLetterToGP />
                        <OMDSubmitButtons disabled={isOMDReviewed} />
                        <PatientExamSecondOpinionSelectors disabled={false} />
                    </Col>
                </Row>
                :
                <OmdrReReviewerBand isOMDReviewed={isOMDReviewed} />
            }

            { !userIsOmdrRE &&
            <>
                {/* START | OMDR IC DeepMD Band */}
                <Row gutter={8}>
                    <DeepMDBand />
                </Row>
                {/* END | OMDR IC DeepMD  Band */}

                <Row>
                    <PatientExamOMDRCDValuesBand />
                </Row>

                <PatientExamStereoViewer inGlaucomaWorksheet={false} />

                <Row>
                    <PatientExamOMDRIOPFinalRecommendations />
                </Row>
            </>
            }

            <ReferralLetterOmdrPdfViewer />

            <PatientExamExtraImages
                disabled={isReadOnly}
                className="patient-exam-extra-images"
            />

            <IopBcvaGraphBand />

            <Row gutter={8}>
                <Col span={24}>
                    <PatientExamConversation />
                </Col>
            </Row>

            <Fragment>
                {/* START | Section 10 - VF History band */}
                <Row gutter={8}>
                    <PatientExamVFImageHistory />
                </Row>
                {/* END | Section 10 - VF band */}

                {/* START | Section 11 - RNFL History band */}
                <Row gutter={8}>
                    <PatientExamOCTRNFLImageHistory />
                </Row>
                {/* END | Section 11 - RNFL History band */}

                {/* START | Section 12 - Macula History band*/}
                <Row gutter={8}>
                    <PatientExamOCTMaculaImageHistory orderBy={'asc'} showHint={true} showBaselineBorder={true} showEmptyRow={true} />
                </Row>
                {/* END | Section 12 - Macula History band */}
                {/* START |  Section 13 - Fundus History band */}
                <Row gutter={8}>
                    <PatientExamFundusImageHistory />
                </Row>
                {/* END | Section 13 - Fundus History band */}
            </Fragment>

        </>
    );
}

export default OMDRReferralLetterExamPage;
