import { InputNumber, Select } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import { ReactNode, useState } from 'react';

type ComponentProps = {
    compareTypeValue: string | null,
    compareNumberValue: number | null,
    className: string,
    placeholder: ReactNode,
    onSelect: (compareType: string | null, compareNumber: number | null) => void,
    formatValue: InputNumberProps['formatter'],
}

const GlaucomaProgramValueSelect = ({compareTypeValue, compareNumberValue, className, placeholder, onSelect, formatValue }: ComponentProps) => {
    const [showComponent, setShowComponent] = useState(false);

    const compareSelect = (
        <Select className='glaucoma-program-value-select-selector'
            value={compareTypeValue}
            onSelect={(value) => onSelect(value, compareNumberValue)}>
            <Select.Option className='glaucoma-program-value-select-option' value="greater">Greater Than</Select.Option>
            <Select.Option className='glaucoma-program-value-select-option' value="less">Less Than</Select.Option>
        </Select>
    );

    return (
        <div className={className}>
            <div
                role="textbox"
                onClick={() => setShowComponent(!showComponent)}
            >
                <span className="placeholder">{placeholder}</span>
            </div>
            { showComponent &&
                <div className={`glaucoma-program-value-select`}>
                    <InputNumber 
                        type='number'
                        addonBefore={compareSelect}
                        value={compareNumberValue ? compareNumberValue!.toString() : null}
                        placeholder='Enter value'
                        onChange={(value) => onSelect(compareTypeValue, Number(value))} 
                        formatter={formatValue}
                    />
                </div>
            }
        </div>
    );
}

export default GlaucomaProgramValueSelect;
