import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Input, Form } from 'antd';
import { setExamDataValue } from '../../../reducers/patient-exam-slice';
import '../../../../static/css/components/patient-exam-icd-10.scss';


const PatientExamIcd10 = () => {

    const icd_10 = useSelector(store => store.examData.icd_10);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    form.setFieldsValue({icd_10});


    // Ant Design handler. This is called whenever a field is changed by the user using Ant Design controls.
    const onFieldsChange = (fields, allFields) => {
        fields.forEach((field) => {
            if (field.name && field.name.length && !field.validating) {
                switch (field.name[0]) {
                    case 'icd_10':
                        dispatch(setExamDataValue(field.name[0], field.value));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    return (
        <Form form={form} onFieldsChange={onFieldsChange}>
            <Card className={`component-input-card icd-10`} bordered={false}>
                <span className="heading">{'ICD 10'}</span>
                <Form.Item help='' name='icd_10' initialValue={icd_10}>
                    <Input.TextArea
                        data-testid='icd_10'
                        className="component-input"
                        autoSize={false}
                        placeholder={''}
                    />
                </Form.Item>
            </Card>
        </Form>
    );
}

export default PatientExamIcd10;