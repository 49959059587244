
import { baseApi } from './base-api'

export interface IOdReferralPatientsResponse {
    success: boolean;
    patients_data_list: IOdReferralPatients[];
    error?: string;
}

export interface IOdReferralPatientDetailsResponse {
    success: boolean;
    patient: IOdReferralPatients;
    error?: string;
}
export interface INotReadyExamsResponse {
    success: boolean;
    not_ready_exams: {id: number, exam_date: string}[];
    has_not_ready_exams: boolean;
    error?: string;
}
export interface IAdminReportRequest {
    type: string;
    start_date: string;
    end_date: string;
}

export interface IPatientResponse {
    success: boolean;
    id: number;
    error?: string;
}

export interface IAdminLetterIpcInfo {
    name: string;
    od_name: string;
    od_grouppractice_name: string;
    od_grouppractice_phone: string;
    od_grouppractice_fax: string;
}

export interface IAdminLetterIpcInfoResponse {
    success: boolean;
    error?: string;
    data: IAdminLetterIpcInfo;
}

export interface IReferralLetterPatientDetail {
    first_name: string;
    last_name: string;
    caf_personnel: boolean;
    service_number: string;
    province: number | null;
    has_no_phn: boolean;
    phn: string;
    please_confirm: boolean;
    dobDay: string;
    dobMonth: string;
    dobYear: string;
    current_gp: number | null;
    current_gp2: number | null;
    email: string;
    insurance_type: string;
}

export interface IOdReferralPatients {
    id: number;
    name: string;
    phn: string;
    caf_personnel: boolean;
    service_number: string;
    is_mb_patient: boolean;
    birthday: string;
    secondary_phn: string;
    referral_letter_exam_date_allowed_to_set?: string;
}

export interface IPatientParams {
    name: string;
}


export const patientApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getPatients: builder.query<IOdReferralPatients[], IPatientParams> ({
            query: (data) => ({
                url: 'od_referral_patients/',
                params: data,
            }),
            transformResponse: (response: IOdReferralPatientsResponse, meta, arg) => response.patients_data_list,
        }),
        getNotReadyExams: builder.query<INotReadyExamsResponse, string> ({
            query: (patientId) => `patients/not_ready_exams/${patientId}`,
        }),
        getOdReferralPatientDetails: builder.query<IOdReferralPatients, string> ({
            query: (patientId) => `patients/od_referral/${patientId}`,
            transformResponse: (response: IOdReferralPatientDetailsResponse, meta, arg) => response.patient,
        }),
        addReferralLetterPatient: builder.mutation<IPatientResponse, IReferralLetterPatientDetail>({
            query(requestData) {
                return {
                    url: 'patients/od_referral/add/',
                    method: 'POST',
                    body: requestData,
                }
            },
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const { useGetPatientsQuery, useLazyGetPatientsQuery, useGetNotReadyExamsQuery,
    useGetOdReferralPatientDetailsQuery, useAddReferralLetterPatientMutation, useLazyGetNotReadyExamsQuery } = patientApi;