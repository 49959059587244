import { Fragment, useEffect, useMemo, useState } from 'react';
import { Table, Modal } from 'antd';
import * as Constants from '../../constants';
import '../../../static/css/components/patient-list-table-omdr-ic.scss';
import '../../../static/css/shared/patient-list-table.scss';
import { localizedText } from '../../localizedText';

// Component imports
import ExamStatusTag from '../retina/exam-status-tag';
import PatientDetailsModal from '../retina/patient-details-modal';

// Action imports
import { goToExamInNewTab } from '../../reducers/patient-exam-slice';
import { getOMDRPatientsToReviewDataRequest } from '../../reducers/admin-review-lists-slice';
import { getOMDRPatientListRequest, IOMDRPatientListItem, setPatientListFieldData } from '../../reducers/patient-list-slice';

// Helper imports
import { sortOMDRReferralLetterPatientList } from '../../helpers/sorting';
import { getPreReviewAlertColor, getIopClassName, getBiopColor, getGlcTypeColor
    } from '../../helpers/patient-list-convert';
import { getOptionsRequest } from '../../reducers/options-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { ColumnsType } from 'antd/es/table';
import { toggleNotAuthorizedForExam } from '../../reducers/user-slice';

const PatientListTableOMDRIC = () => {
    const { UNAUTHORIZED_MODAL_TITLE, STATUS_HEADING, FIRST_NAME_HEADING,
        LAST_NAME_HEADING, PROVINCE_HEADING, OD_HEADING, DOB_HEADING, CHIEF_COMPLAINT_HEADING,
        GLC_CLASS_HEADING, IOP_HEADING, BIOP_HEADING, BDISC_HEADING, BFIELD_HEADING, LATEST_VISIT_HEADING,
        COMMENTS_HEADING, NUMBER_OF_VISITS_HEADING, OF_TEXT, ITEMS_TEXT } = localizedText;
    const tableList = useCare1AppSelector(store => store.patientList.omdrTableList);
    const showDetails = useCare1AppSelector(store => store.patientDetails.visible);
    const notAuthorizedForExam = useCare1AppSelector(store => store.user.notAuthorizedForExam);
    const prereviewAlertOptions = useCare1AppSelector(store => store.options.prereviewAlerts);
    const omdrStatus = useCare1AppSelector(store => store.patientList.omdrStatus);

    const dispatch = useCare1AppDispatch();

    const [ scrollClass, setScrollClass ] = useState('');

    const getOdClassName = (record: IOMDRPatientListItem) => {
        const {isOdOrientating, isOdSpecialAttention, isOdIntegrated} = record;

        if (isOdOrientating) {
            return 'od-orientating';
        } else if (isOdSpecialAttention) {
            return 'od-special-attention';
        } else if ( !isOdIntegrated) {
            return 'od-not-integrated';
        } else {
            return '';
        }
    }

    const omdrListData = useMemo(() => {
        return sortOMDRReferralLetterPatientList(tableList, omdrStatus)
    }, [tableList, omdrStatus])

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // Set the default filter values
        dispatch(setPatientListFieldData({ key: 'omdrStatus', value: 'special_attention' }));
        dispatch(setPatientListFieldData({ key: 'omdrPeriodFilter', value: 'none' }));
        dispatch(setPatientListFieldData({ key: 'searchQuery', value: '' }));

        // Make requests
        dispatch(getOptionsRequest());
        dispatch(getOMDRPatientListRequest());
        dispatch(getOMDRPatientsToReviewDataRequest());

        if (notAuthorizedForExam) {
            Modal.info({
                className: 'info-modal',
                title: UNAUTHORIZED_MODAL_TITLE,
                content: Constants.UNAUTHORIZED_MODAL_CONTENT,
                onOk: () => {
                    dispatch(toggleNotAuthorizedForExam(false));
                },
            });
        }

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onTableRowClick = (record: IOMDRPatientListItem) => {
        const examUrl = `${Constants.PATIENT_EXAM_URL}/${record.latest_visit_id}`;
        goToExamInNewTab(examUrl);
    }

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    enum bfieldLoopUpTable {
        G = 'normal-bfield',
        R = 'high-bfield',
        Y = 'warning-bfield',
        x = '',
    };

    const columns: ColumnsType<IOMDRPatientListItem> = [
    {
        className: 'status-column',
        title: STATUS_HEADING,
        dataIndex: 'status',
        render: (text) => <ExamStatusTag className="patient-exam-status-tag" examStatusKey={text} />,
    }, {
        className: 'last-name-column',
        title: LAST_NAME_HEADING,
        dataIndex: 'last_name',
    }, {
        className: 'first-name-column',
        title: FIRST_NAME_HEADING,
        dataIndex: 'first_name',
    }, {
        className: 'province-column',
        title: PROVINCE_HEADING,
        dataIndex: 'province',
    }, {
        className: 'od-column',
        title: OD_HEADING,
        dataIndex: 'od',
        render: (data, record) => <span className={getOdClassName(record)}>{data}</span>,
    }, {
        className: 'dob-column',
        title: DOB_HEADING,
        dataIndex: 'dob',
    }, {
        className: 'complaint-column',
        title: CHIEF_COMPLAINT_HEADING,
        dataIndex: 'chief_complaint',
    }, {
        className: 'glc-class-column',
        title: GLC_CLASS_HEADING,
        dataIndex: 'glc_class',
        render: (data) => (
            <Fragment>
                <span className={getGlcTypeColor(data.od_iop, data.od_iop_aim)}>{data.glc_status}</span>
                <br />
                <span className={getGlcTypeColor(data.os_iop, data.os_iop_aim)}>{data.glc_status}</span>
            </Fragment>
        ),
    }, {
        className: 'iop-column',
        title: IOP_HEADING,
        dataIndex: 'iop',
        render: (data) => (
            <>
                <span className={getIopClassName(data.od_iop)}>{data.od_iop ? data.od_iop : ''}</span>
                <span className='iop-alert-char'>{data.od_alert ? 'A' : ''}</span>
                <br />
                <span className={getIopClassName(data.os_iop)}>{data.os_iop ? data.os_iop : ''}</span>
                <span className='iop-alert-char'>{data.os_alert ? 'A' : ''}</span>
            </>
        ),
    }, {
        className: 'b-iop-column',
        title: BIOP_HEADING,
        dataIndex: 'b_iop',
        render: (data) => (
            <Fragment>
                {data && Array.isArray(data) && data.map(({iop_mean, prev2_iop, prev_iop, current_iop, type})=>
                    <Fragment key={type}>
                        <span className={getBiopColor(iop_mean)}>{`${iop_mean}(`}</span>
                        <span className={getBiopColor(prev2_iop)}>{`${prev2_iop},`}</span>
                        <span className={getBiopColor(prev_iop)}>{`${prev_iop},`}</span>
                        <span className={getBiopColor(current_iop)}>{`${current_iop})`}</span>
                        <br />
                    </Fragment>
                )}
            </Fragment>
        ),
    }, {
        className: 'b-disc-column',
        title: BDISC_HEADING,
        dataIndex: 'b_disc',
        render: (data) => (
            <Fragment>
                {data && Array.isArray(data) && data.map(({field_name, mode_disc, prev2, prev, current})=>(
                    <Fragment key={field_name}>
                        {/* HTML Entity  &#40;->(  &#41;->) &#44;->,  */}
                        <span className={bfieldLoopUpTable[mode_disc as keyof typeof bfieldLoopUpTable]}>{mode_disc}</span>&#40;
                        <span className={bfieldLoopUpTable[prev2 as keyof typeof bfieldLoopUpTable]}>{prev2}</span>&#44;
                        <span className={bfieldLoopUpTable[prev as keyof typeof bfieldLoopUpTable]}>{prev}</span>&#44;
                        <span className={bfieldLoopUpTable[current as keyof typeof bfieldLoopUpTable]}>{current}</span>&#41;
                        <br />
                    </Fragment>
                ))}
            </Fragment>
        ),
    }, {
        className: 'b-field-column',
        title: BFIELD_HEADING,
        dataIndex: 'b_field',
        render: (data) => (
            <Fragment>
                {data && Array.isArray(data) && data.map(({field_name, mode_disc, prev2, prev, current})=>(
                    <Fragment key={field_name}>
                        {/* HTML Entity  &#40;->(  &#41;->) &#44;->,  */}
                        <span className={bfieldLoopUpTable[mode_disc as keyof typeof bfieldLoopUpTable]}>{mode_disc}</span>&#40;
                        <span className={bfieldLoopUpTable[prev2 as keyof typeof bfieldLoopUpTable]}>{prev2}</span>&#44;
                        <span className={bfieldLoopUpTable[prev as keyof typeof bfieldLoopUpTable]}>{prev}</span>&#44;
                        <span className={bfieldLoopUpTable[current as keyof typeof bfieldLoopUpTable]}>{current}</span>&#41;
                        <br />
                    </Fragment>
                ))}
            </Fragment>
        ),
    }, {
        className: 'latest-visit-column',
        title: LATEST_VISIT_HEADING,
        dataIndex: 'latest_visit',
    }, {
        className: 'pre-review-alerts-column',
        title: COMMENTS_HEADING,
        dataIndex: 'pr_alerts',
        render: (data) => {
            return data && Array.isArray(data) && data.map((alert, index) => {

                // Get the prereview alert color and assign it as a class name
                const color = getPreReviewAlertColor(alert, prereviewAlertOptions);

                return (
                    <span
                        className={`${color}`}
                        key={`pre_review_alert_${index}`}
                    >
                        {/* add comma between each alert */}
                        {`${index ? ', ' : ''}${alert}`}
                    </span>
                )
            })
        },
    }, {
        className: 'visits-column',
        title: NUMBER_OF_VISITS_HEADING,
        dataIndex: 'visits',
    }];

    return (
        <div className="patient-list-table patient-list-table-omdr user-is-ic">
            <div className={scrollClass}>
                <Table
                    columns={columns}
                    scroll={{ x: true }}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 30,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    dataSource={omdrListData}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                onTableRowClick(record);
                            },
                        };
                    }}
                />
                {showDetails && <PatientDetailsModal />}
            </div>
        </div>
    );
}

export default PatientListTableOMDRIC;