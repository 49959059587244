import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Row, Button, Divider, Input } from 'antd';
import '../../../static/css/components/admin-doctor-list-control-bar.scss';
import { setDoctorSearchQuery, setShowStaffAddModal, setShowIgpAddModal, setShowOdAddModal,
    setShowOmdAddModal, setIsSalesUser, setIsScienceUser, setStaffSearchQuery, toggleOdGroupPracticeAddModal,
    DoctorListMenuType, setOdGroupPracticeSearchQuery, toggleTechnicianAddModal, setTechnicianSearchQuery } from '../../reducers/doctor-slice';
import { useCare1AppDispatch } from '../../apps/care1-hooks';
import { localizedText } from '../../localizedText';

const DoctorListControlBarAdmin = ({type} : { type: DoctorListMenuType }) => {
    const { DOCTORS_LIST_SEARCH_PLACEHOLDER, DOCTORS_LIST_ADD_NEW_OMD, 
        DOCTORS_LIST_ADD_NEW_OD, DOCTORS_LIST_ADD_NEW_GP, 
        DOCTORS_LIST_TEXT, GROUP_PRACTICE_LIST_SEARCH_PLACEHOLDER } = localizedText;
    const dispatch = useCare1AppDispatch();
    
    const controlBarOpenAddOMDModal = () => {
        dispatch(setShowOmdAddModal(true));
    }

    const controlBarOpenAddODModal = () => {
        dispatch(setShowOdAddModal(true));
    }

    const controlBarOpenAddIGPModal = () => {
        dispatch(setShowIgpAddModal(true));
    }

    const controlBarOpenAddAdminModal = () => {
        dispatch(setShowStaffAddModal(true));
    }

    const controlBarOpenAddSalesModal = () => {
        dispatch(setIsSalesUser(true));
        dispatch(setShowStaffAddModal(true));
    }
    
    const controlBarOpenAddScienceUserModal = () => {
        dispatch(setIsScienceUser(true));
        dispatch(setShowStaffAddModal(true));
    }

    const controlBarOpenAddTechnicianModal = () => {
        dispatch(toggleTechnicianAddModal());
    }

    const controlBarOpenOdGroupPracticeAddModal = () => {
        dispatch(toggleOdGroupPracticeAddModal());
    }

    const isOMDTable = type === 'omd';
    const isODTable = type === 'od';
    const isIGPTable = type === 'igp';
    const isStaffTable = type === 'staff';
    const isGroupPracticeTable = type === 'odgrouppractice';
    const isTechnicianTable = type === 'technician';

    const listTitle = isStaffTable ? 
                      'Staffs List' :
                        isGroupPracticeTable ?
                        'Group Practice List' :
                            isTechnicianTable ? 
                            'Technician List':
                                DOCTORS_LIST_TEXT;
    
    const searchBarPlaceHolderText = isGroupPracticeTable ?
                                     GROUP_PRACTICE_LIST_SEARCH_PLACEHOLDER :
                                     DOCTORS_LIST_SEARCH_PLACEHOLDER;

    return (
        <Row className={'doctor-list-control-bar'}>
            <Row align="middle" className="control-bar-elements">
                <div className="doctor-list-title">
                    {listTitle}
                </div>
                { isIGPTable && 
                    <Button
                        className="doctor-list-new-doctor-button"
                        icon={<PlusOutlined />}
                        onClick={controlBarOpenAddIGPModal}
                        type='primary'
                    >
                        {DOCTORS_LIST_ADD_NEW_GP}
                    </Button>
                }
                { isODTable && 
                    <Button
                        className="doctor-list-new-doctor-button"
                        icon={<PlusOutlined />}
                        onClick={controlBarOpenAddODModal}
                        type='primary'
                    >
                        {DOCTORS_LIST_ADD_NEW_OD}
                    </Button>
                }
                { isOMDTable && 
                    <Button
                        className="doctor-list-new-doctor-button"
                        icon={<PlusOutlined />}
                        onClick={controlBarOpenAddOMDModal}
                        type='primary'
                    >
                        {DOCTORS_LIST_ADD_NEW_OMD}
                    </Button>
                }
                { isStaffTable &&
                    <>
                        <Button
                            className="doctor-list-new-doctor-button staff-list"
                            icon={<PlusOutlined />}
                            onClick={controlBarOpenAddScienceUserModal}
                            type='primary'
                        >
                            Add New Science User
                        </Button>
                        <Button
                            className="doctor-list-new-doctor-button staff-list"
                            icon={<PlusOutlined />}
                            onClick={controlBarOpenAddSalesModal}
                            type='primary'
                        >
                            Add New Sales
                        </Button>
                        <Button
                            className="doctor-list-new-doctor-button staff-list"
                            icon={<PlusOutlined />}
                            onClick={controlBarOpenAddAdminModal}
                            type='primary'
                        >
                            Add New Admin
                        </Button>
                    </>        
                }
                { isGroupPracticeTable && 
                    <Button
                        className="doctor-list-new-doctor-button"
                        icon={<PlusOutlined />}
                        onClick={controlBarOpenOdGroupPracticeAddModal}
                        type='primary'
                    >
                        Add New OD Group Practice
                    </Button>
                }
                { isTechnicianTable && 
                    <Button
                        className="doctor-list-new-doctor-button"
                        icon={<PlusOutlined />}
                        onClick={controlBarOpenAddTechnicianModal}
                        type='primary'
                    >
                        Add New Technician
                    </Button>
                }
                <Divider type="vertical" className="doctor-list-vertical-divider" />
                <Input.Search
                    className='doctor-list-search-input'
                    allowClear
                    placeholder={searchBarPlaceHolderText}
                    prefix={<SearchOutlined />}
                    onChange={(e) => {
                        isStaffTable ?
                        dispatch(setStaffSearchQuery(e.target.value)) :
                            isGroupPracticeTable ? 
                            dispatch(setOdGroupPracticeSearchQuery(e.target.value)) :
                                isTechnicianTable ? 
                                dispatch(setTechnicianSearchQuery(e.target.value)):
                                    dispatch(setDoctorSearchQuery(e.target.value));
                        }
                    }
                />
            </Row>
        </Row>
    );
}

export default DoctorListControlBarAdmin;