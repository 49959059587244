import { ConfigProvider, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib';

type ComponentProps = {
    children: React.ReactNode;
    isWarning?: boolean;
    value?: boolean | null;
    className?: string;
    handleRadioGroupChange?: (e: RadioChangeEvent) => void;
};

const ComponentRadioGroup = ({
    children,
    isWarning,
    value,
    className,
    handleRadioGroupChange,
}: ComponentProps) => {
    const warningColor = '#FF3B30';

    const radioTheme = {
        buttonBg: 'transparent',
        buttonSolidCheckedBg: '#0E78E7',
        buttonSolidCheckedColor: '#FFFFFF',
        colorBorder: '#1890FF',
        colorText: '#FFFFFF',
        fontSize: 11,
        borderRadius: 4,
        buttonCheckedBgDisabled: '#0E78E7',
        buttonCheckedColorDisabled: 'rgba(255,255,255,0.75)',
    };

    const warningRadioTheme = {
        ...radioTheme,
        buttonSolidCheckedBg: warningColor,
        buttonSolidCheckedHoverBg: warningColor,
        colorBorder: warningColor,
        colorPrimaryTextHover: warningColor,
        buttonCheckedBgDisabled: warningColor,
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Radio: isWarning ? warningRadioTheme : radioTheme,
                },
            }}
        >
            <Radio.Group
                buttonStyle='solid'
                value={value}
                onChange={handleRadioGroupChange}
                className={className}
            >
                {children}
            </Radio.Group>
        </ConfigProvider>
    );
};

export default ComponentRadioGroup;
