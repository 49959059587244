import { useCare1AppSelector } from '../../apps/care1-hooks';
import { Row, Col, Card, Tooltip,Divider } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { localizedText } from '../../localizedText';
import PatientExamGlaucomaScoreGraph from './patient-exam-glaucoma-score-graph';
import '../../../static/css/components/patient-exam-glaucoma-score.scss';
import { calcOverallScore, calcOctavScore, calcGhtScore } from '../../helpers/glaucoma-score-graph-convert';
import { lengthEqual } from '../../reducers/patient-exam-slice';
import { GLAUCOMA_SCORE_NUMBER_DECIMAL } from '../../constants';

const PatientExamGlaucomaScore = ({page='main_page'}: {page?: string}) => {

    const { PATIENTEXAMGLAUCOMASCORE_SUBHEADING_TEXT } = localizedText;

    const age = useCare1AppSelector(store => store.patientDetails.details.age);
    const fuNumber = useCare1AppSelector(store => store.examData.fu_number);
    const examId = useCare1AppSelector(store => store.examData.id);

    const glaucomaScoreGraphData = useCare1AppSelector(store => store.examData.glaucoma_score_graph_data, lengthEqual);

    // Get the relevant data from the glaucoma score entry that matches our exam.
    const glaucomaScoreEntry = glaucomaScoreGraphData.find(entry => entry.exam_id === examId);

    const odIop = glaucomaScoreEntry?.od_iop || '';
    const osIop = glaucomaScoreEntry?.os_iop || '';

    const rightOctav = glaucomaScoreEntry?.right_octav || '';
    const leftOctav = glaucomaScoreEntry?.left_octav || '';

    const rightGht = glaucomaScoreEntry?.right_ght || '';
    const leftGht = glaucomaScoreEntry?.left_ght || '';

    const generateLine3 = () => {
        return `The follow-up is recommended in ${fuNumber} months.`;
    }

    const generateLine2 = () => {
        const rightEyeIopScore = parseInt(odIop);
        const leftEyeIopScore = parseInt(osIop);

        const rightEyeOctavScore = calcOctavScore(rightOctav);
        const leftEyeOctavScore = calcOctavScore(leftOctav);

        const rightEyeGhtScore = calcGhtScore(rightGht);
        const leftEyeGhtScore = calcGhtScore(leftGht);

        const rightScores = [];
        if (rightEyeIopScore !== null) {
            rightScores.push(`IOP ${rightEyeIopScore.toFixed(GLAUCOMA_SCORE_NUMBER_DECIMAL)}`);
        }
        if (rightEyeOctavScore !== null) {
            rightScores.push(`Octav ${rightEyeOctavScore.toFixed(GLAUCOMA_SCORE_NUMBER_DECIMAL)}`);
        }
        if (rightEyeGhtScore !== null) {
            rightScores.push(`Ght ${rightEyeGhtScore.toFixed(GLAUCOMA_SCORE_NUMBER_DECIMAL)}`);
        }

        const leftScores = [];
        if (leftEyeIopScore !== null) {
            leftScores.push(`IOP ${leftEyeIopScore.toFixed(GLAUCOMA_SCORE_NUMBER_DECIMAL)}`);
        }
        if (leftEyeOctavScore !== null) {
            leftScores.push(`Octav ${leftEyeOctavScore.toFixed(GLAUCOMA_SCORE_NUMBER_DECIMAL)}`);
        }
        if (leftEyeGhtScore !== null) {
            leftScores.push(`Ght ${leftEyeGhtScore.toFixed(GLAUCOMA_SCORE_NUMBER_DECIMAL)}`);
        }

        const eyes : string[] = [];
        if (rightScores.length > 0) {
            eyes.push(`${rightScores.join(', ')} for the right eye`)
        }
        if (leftScores.length > 0) {
            eyes.push(`${leftScores.join(', ')} for the left eye`)
        }

        return `Your risk factors for glaucoma are ${eyes.join(' and ')}.`;
    }

    const generateLine1 = () => {
        const rightEyeScore = calcOverallScore('right', {
            age: age,
            od_iop: odIop,
            os_iop: osIop,
            right_octav: rightOctav,
            left_octav: leftOctav,
            right_ght: rightGht,
            left_ght: leftGht,
            exam_id: examId,
        })

        const leftEyeScore = calcOverallScore('left', {
            age: age,
            od_iop: odIop,
            os_iop: osIop,
            right_octav: rightOctav,
            left_octav: leftOctav,
            right_ght: rightGht,
            left_ght: leftGht,
            exam_id: examId,
        })

        const scores = [];
        const risks = [];

        if (rightEyeScore !== null) {
            scores.push(`${rightEyeScore.toFixed(GLAUCOMA_SCORE_NUMBER_DECIMAL)} for the right eye`);

            let risk = 'high';
            if (rightEyeScore < 7.0) {
                risk = 'medium';
            }
            if (rightEyeScore < 4.0) {
                risk = 'low';
            }
            risks.push(`${risk} for the right eye`);
        }
        if (leftEyeScore !== null) {
            scores.push(`${leftEyeScore.toFixed(GLAUCOMA_SCORE_NUMBER_DECIMAL)} for the left eye`);

            let risk = 'high';
            if (leftEyeScore < 7.0) {
                risk = 'medium';
            }
            if (leftEyeScore < 4.0) {
                risk = 'low';
            }
            risks.push(`${risk} for the left eye`);
        }

        const text = `Your glaucoma risk score at the visit is ${scores.join(' and ')}. 
                        This means that your glaucoma risk is ${risks.join(' and ')}.`

        return text;
    };

    const generateTooltipInfo = () => {
        // eslint-disable-next-line
        return 'The glaucoma risk score summarizes the patient’s global glaucoma risk on a scale of 1 (low risk) to 9 (high risk). Glaucoma risk score is *only* available on an experimental research basis and should not be utilized for patient care.';
    }

    return (
        <Card className='patient-exam-glaucoma-score-band' bordered={false}>
            <Row className="patient-exam-glaucoma-score-heading">
                <Col>
                    <div className='patient-exam-glaucoma-score-subheading'>{PATIENTEXAMGLAUCOMASCORE_SUBHEADING_TEXT}</div>
                </Col>
                <Col>
                    <Tooltip
                        placement='right'
                        overlayClassName='patient-exam-glaucoma-score-tooltip'
                        title={generateTooltipInfo()}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </Col>
            </Row>
            <Row className='graph' gutter={[0, 24]} justify={'space-between'}>
                <Col span={7}>
                    <PatientExamGlaucomaScoreGraph
                        side='right'
                        page={page}
                    />
                </Col>
                <Col span={1}>
                    <Divider className={'divider'} type="vertical" />
                </Col>
                <Col className='text-container' span={8}>
                    <div className='text'>
                        <div className='line'>{generateLine1()}</div>
                        <div className='line'>{generateLine2()}</div>
                        <div>{generateLine3()}</div>
                    </div>
                </Col>
                <Col span={1}>
                    <Divider className={'divider2'} type="vertical" />
                </Col>
                <Col span={7}>
                    <PatientExamGlaucomaScoreGraph
                        side='left'
                        page={page}
                    />
                </Col>
            </Row>
        </Card>
    )
}

export default PatientExamGlaucomaScore;
