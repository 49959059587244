import React from 'react';
import { Modal, Spin } from 'antd';
import OMDEditForm from './omd-edit-form';
import '../../../static/css/components/admin-doctor-edit-modal.scss';
import { setShowOmdAddModal } from '../../reducers/doctor-slice';
import { IOMDDetails, useAddOmdMutation } from '../../services/doctor-api';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';


const OMDAddModal = () => {

    const showOmdAddModal = useCare1AppSelector(store => store.doctorSlice.showOmdAddModal);

    // addOmd is the mutation trigger, we defined addOmd in doctorApi
    // the second argument is the mutation result, we destruct it to get the isLoading value
    const [addOmd, {isLoading}] = useAddOmdMutation();

    const dispatch = useCare1AppDispatch();

    const handleModalClose = () => {
        dispatch(setShowOmdAddModal(false));
    };

    const onFinish = async (values: IOMDDetails) => {
        try {
            // we use the addOmd function after the form is complete and submit/save button is clicked
            // unwrap() gives the access to error or success payload
            const result = await addOmd(values).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'OMD added successfully.',
                    onOk: () => {
                        dispatch(setShowOmdAddModal(false));
                    }
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    const onFinishFailed = () => {
        Modal.error({
            className: 'info-modal',
            content: 'Please fix the error to continue',
            title: 'Error',
        });
    };

    return (
        <Modal
            className='admin-doctor-edit-modal add'
            open={showOmdAddModal}
            closable={true}
            footer={null}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <Spin
                className='loading-spinner'
                size='large'
                spinning={isLoading}
            >
                <OMDEditForm
                    title={'Add New Ophthalmologist'}
                    buttonText={'Add OMD'}
                    mode={'add'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                />
            </Spin>
        </Modal>
    );

}

export default OMDAddModal;
