import { Col, Flex, Radio, Row, Select, Tooltip, Typography } from 'antd';
import styles from '../../../../static/css/components/report-toggle.module.scss';
import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { localizedText } from '../../../localizedText';


import { RadioChangeEvent } from 'antd/lib';
import useSmartUploadOnChangeSave from '../../../hooks/useOnChangeSave';
import { isOmdc } from '../../../reducers/doctor-slice';
import { useGetSmartUploadOdRequestedOmdOptionsQuery } from '../../../services/doctor-api';
import ComponentRadioGroup from '../../common/component-radio-group';


const SmartUploadTypeCare1 = () => {
    const { NO_TEXT, YES_TEXT} = localizedText;

    const highPriority = useCare1AppSelector(store => store.smartUpload.high_priority);
    const odId = useCare1AppSelector(store => store.user.doctorID);
    const odWantsOmdReport = useCare1AppSelector(store => store.smartUpload.od_wants_omd_report);
    const odRequestedOmd = useCare1AppSelector(
        (store) => store.smartUpload.od_requested_omd
    );
    const omds = useCare1AppSelector((store) => store.options.omds);
    const disabled = useCare1AppSelector((store) => store.smartUpload.disabled);


    const {handleValueChange} = useSmartUploadOnChangeSave();

    const handleHighPriorityChange = (e : RadioChangeEvent) => {
        if (e.target.value === true) {
            handleValueChange({
                high_priority: true,
                od_wants_omd_report: true,
            })
        } else {
            handleValueChange({
                high_priority: false,
            })
        }
    }

    const { data } = useGetSmartUploadOdRequestedOmdOptionsQuery(odId!);

    const highPriorityAiText =
        'High priority cases will always receive an OMD Consult ';

    const message = highPriority
        ? 'Patient chart will be sent to the chosen OMD or next available OMD for review.'
        : isOmdc(omds, odRequestedOmd)
        ? 'If the requested OMD is not available in the next 7 days, a review from the next available OMD will be provided in the meantime. The requested OMD will still review when they have time.'
        : '';
    
    const handleReportTypeChange = (e: RadioChangeEvent) => {
        handleValueChange({
            od_wants_omd_report: e.target.value,
            od_requested_omd: null,
            high_priority: false,
        })
    }

    return (
            <>
                <Col span={24}>
                    <Flex justify='space-between' align='center'>
                        <Typography.Text>High Priority</Typography.Text>
                        <ComponentRadioGroup
                            isWarning
                            value={highPriority}
                            handleRadioGroupChange={handleHighPriorityChange}
                        >
                            <Radio.Button
                                value={false}
                                disabled={disabled}
                            >
                                {NO_TEXT.toUpperCase()}
                            </Radio.Button>
                            {
                                odWantsOmdReport === false ? 
                                <Tooltip
                                    placement='topLeft'
                                    title='AI-only reports cannot be marked as high priority.'
                                    >
                                    <Radio.Button
                                        disabled={true}
                                        value={true}
                                    >
                                        {YES_TEXT.toUpperCase()}
                                    </Radio.Button>
                                </Tooltip>
                                :
                                <Radio.Button
                                    value={true}
                                    disabled={disabled}
                                >
                                    {YES_TEXT.toUpperCase()}
                                </Radio.Button>
                            }
                        </ComponentRadioGroup>
                    </Flex>
                </Col>
                <Col span={24}>
                <Row
                justify='space-between'
                align='middle'
                className={styles.reportToggle}
            >
                <Col span={13}>
                    <Typography.Text>Report Type</Typography.Text>
                    <ComponentRadioGroup
                        value={odWantsOmdReport}
                        handleRadioGroupChange={handleReportTypeChange}
                        className={styles.reportType}
                        isWarning={highPriority}
                    >
                        <Tooltip
                            placement='topLeft'
                            title={highPriority ?
                                highPriorityAiText :
                                'An AI Report will provide only Care1 AI services and may include an Ophthalmology review as validation of the Care1 AI.'
                            }
                        >
                            <Radio.Button
                                disabled={highPriority || disabled}
                                value={false}
                                style={{minWidth: '80px', textAlign: 'center'}}
                            >
                                AI
                            </Radio.Button>
                        </Tooltip>
                        <Tooltip
                            placement='topLeft'
                            title={'An OMD + AI Report will provide an Ophthalmology consult along with Care1’s AI services.'}
                        >
                            <Radio.Button
                                value={true}
                                disabled={disabled}
                            >
                                AI + OMD
                            </Radio.Button>
                        </Tooltip>
                    </ComponentRadioGroup>
                </Col>
                <Col span={10}>
                    <Select
                        placeholder='Next Available OMD'
                        options={data}
                        optionFilterProp='label'
                        value={odRequestedOmd}
                        allowClear
                        disabled={odWantsOmdReport === false || disabled}
                        onChange={(value) => handleValueChange({'od_requested_omd': value})
                            
                        }
                    />
                </Col>
                <Col span={24}>
                    <Typography.Text className={styles.odRequestedOmdMessage}>
                        {message}
                    </Typography.Text>
                </Col>
            </Row>
                </Col>
            </>
    );
}

export default SmartUploadTypeCare1;