import { Select } from 'antd';
import { OMD_SELECT_PLACEHOLDER_TEXT } from '../../constants';
import { lengthEqual } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { setExamDataValue } from '../../reducers/patient-exam-slice';

const { Option } = Select;


const PatientExamHeaderOMDRSelect = () => {
    const omdReviewerOptions = useCare1AppSelector(store => store.examData.omd_reviewer_options, lengthEqual);
    const omdReviewer = useCare1AppSelector(store => store.examData.omd_reviewer);

    // Get the list of OMDRs for the dropdown.
    const omdReviewerList = omdReviewerOptions.filter((option) => option.hidden_from_dropdown === false);

    const dispatch = useCare1AppDispatch();

    const handleSelectOMDR = (key: number) => {
        dispatch(setExamDataValue('omd_reviewer', key));
    }

    const handleClearOMDR = () => {
        dispatch(setExamDataValue('omd_reviewer', ''));
    }

    return (
        <Select<number, { value: string, children: string }> // Solution for using filterOption
            className="patient-exam-omdr-select"
            showSearch
            size="small"
            optionFilterProp="children"
            allowClear={Boolean(omdReviewer)}
            value={omdReviewer || null}
            onSelect={handleSelectOMDR}
            onClear={handleClearOMDR}
            placeholder={OMD_SELECT_PLACEHOLDER_TEXT}
            filterOption={(input, option) =>       
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                omdReviewerList && omdReviewerList.map((option) => (
                    <Option
                        key={option.value}
                        value={option.value}
                    >
                        {`Dr. ${option.label}`}
                    </Option>
                ))
            }
        </Select>
    )
}

export default PatientExamHeaderOMDRSelect;
