import { Table } from 'antd';
import { IIgpListItem, setSelectedPatients } from '../../reducers/igp-patient-list-slice';
import { useCare1AppDispatch } from '../../apps/care1-hooks';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import '../../../static/css/shared/patient-list-table.scss';
import '../../../static/css/components/patient-list-table-igp.scss';
import { localizedText } from '../../localizedText';

type ComponentProps = {
    className: string,
    columns: ColumnsType<IIgpListItem>,
    dataSource: IIgpListItem[],
    referralSelection: string,
}

const PatientListTableIgp = ({className, columns, dataSource, referralSelection } : ComponentProps) => {
    const { OF_TEXT, ITEMS_TEXT } = localizedText;

    const dispatch = useCare1AppDispatch();

    const rowSelection: TableRowSelection<IIgpListItem> =  {
            type: 'checkbox',
            onChange: (selectedRowKeys) => dispatch(setSelectedPatients(selectedRowKeys))
        };

    return (
        <div className={`patient-list-table patient-list-table-igp ${className}`}>
            <div>
                { referralSelection === 'current' &&
                    <Table
                        columns={columns}
                        pagination={false}
                        dataSource={dataSource}
                        rowSelection={rowSelection}
                    />
                }

                { referralSelection !== 'current' &&
                    <Table
                        columns={columns}
                        pagination={{
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '30', '50', '100', '200'],
                            defaultPageSize: 30,
                            showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                        }}
                        dataSource={dataSource}
                    />
                }
            </div>
        </div>
    );
}

export default PatientListTableIgp;
