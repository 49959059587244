import { InfoCircleOutlined, SwapOutlined } from '@ant-design/icons';
import { Modal, Dropdown } from 'antd';
import { SWITCH_IMAGE_CONFIRMATION_MODAL_TITLE, SWITCH_IMAGE_CONFIRMATION_MODAL_TEXT, SWITCH_IMAGE_CONFIRMATION_OK_TEXT,
    SWITCH_IMAGE_CONFIRMATION_CANCEL_TEXT, 
    SWITCH_IMAGE_SUCCEED,
    ERROR_MESSAGE_TITLE,
    SWITCH_IMAGE_ERROR} from '../../constants';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { useSwapImageMutation } from '../../services/exam-api';
import { retrievePhotoUrls } from '../../reducers/patient-exam-slice';
import type { MenuProps } from 'antd';

const SwapImageDropdown = ({ field } : { field: string }) => {
    const dispatch = useCare1AppDispatch();
    const examId = useCare1AppSelector(store => store.examData.id);

    const [switchPhoto] = useSwapImageMutation();

    const swappableImages: MenuProps['items'] = [
        { key: 'right_fundus_photo', label: 'OD Fundus' },
        { key: 'right_stereo_photo', label: 'OD Stereo' },
        { key: 'right_vf_photo', label: 'OD VF' },
        { key: 'right_oct_photo', label: 'OD OCT Macula' },
        { key: 'right_oct_rnfl_photo', label: 'OD OCT RNFL' },
        { key: 'left_fundus_photo', label: 'OS Fundus' },
        { key: 'left_stereo_photo', label: 'OS Stereo' },
        { key: 'left_vf_photo', label: 'OS VF' },
        { key: 'left_oct_photo', label: 'OS OCT Macula' },
        { key: 'left_oct_rnfl_photo', label: 'OS OCT RNFL' },
    ]

    const menuItems = swappableImages.filter(obj => {
        return obj?.key !== field;
    });

    const onDropdownPhotoSelect: MenuProps['onClick'] = (e) => {
        Modal.confirm({
            className: 'delete-confirmation-modal',
            title: SWITCH_IMAGE_CONFIRMATION_MODAL_TITLE,
            content: SWITCH_IMAGE_CONFIRMATION_MODAL_TEXT,
            icon: <InfoCircleOutlined />,
            okText: SWITCH_IMAGE_CONFIRMATION_OK_TEXT,
            cancelText: SWITCH_IMAGE_CONFIRMATION_CANCEL_TEXT,
            cancelButtonProps: { className: 'confirm-exit' },
            onCancel: () => {
            },
            onOk: async () => {
                try {
                    const res = await switchPhoto({
                        examId: examId!,
                        photo1: field,
                        photo2: e.key,
                    }).unwrap();
                    if (res.success) {
                        dispatch(retrievePhotoUrls(examId!));

                        // Show the success message in a dialog.
                        Modal.info({
                            className: 'info-modal',
                            title: SWITCH_IMAGE_SUCCEED,
                        });
                    } else {
                        throw new Error(res.error);
                    }
                } catch(error) {
                    const message = (error instanceof Error) ?  error?.message : error;
                    Modal.error({
                        title: ERROR_MESSAGE_TITLE,
                        content: `${SWITCH_IMAGE_ERROR}: ${message}`,
                    });
                }
                
            },
        });
    }


    const menuProps = {
        items: menuItems,
        className: 'image-swap-menu',
        onClick: onDropdownPhotoSelect,
    }

    return <Dropdown
                className='swap-image-icon'
                overlayClassName='swap-image-dropdown'
                menu={menuProps}
                trigger={['click']}
            >
                {/* eslint-disable-next-line */}
                <a onClick={e => e.preventDefault()}>
                    <SwapOutlined />
                </a>
            </Dropdown>
}

export default SwapImageDropdown;