import React from 'react';
import { Col, Input, Popconfirm, Row, Select, DatePicker, AutoComplete } from 'antd';
import { LRS_TYPE_OPTIONS } from '../../constants';
import { IOmdHistoryItem, deleteOMDHistoryEntry, editOMDHistoryEntry } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch } from '../../apps/care1-hooks';
import dayjs from 'dayjs';

const { Option } = Select;
const yearFormat = 'YYYY';

type ComponentProps = {
    eyeTypes: string[][],
    index: number,
    data: IOmdHistoryItem,
}

const PatientExamOMDHistoryEntry = ({eyeTypes, index, data}: ComponentProps) => {

    const dispatch = useCare1AppDispatch();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof IOmdHistoryItem) => {
        const { value } = event.target;
        dispatch(editOMDHistoryEntry({
            editIndex: index, field, value
        }));
    }

    const handleSelect = (value: string, field: keyof IOmdHistoryItem) => {
        dispatch(editOMDHistoryEntry({
            editIndex: index, field, value
        }));
    }

    const handleDateChange = (dateString: string, field: keyof IOmdHistoryItem) => {
        // console.log(date, dateString);
        dispatch(editOMDHistoryEntry({
            editIndex: index, field, value: dateString
        }));
      }

    const handleDiagonsisChange = (value: string, field: keyof IOmdHistoryItem) => {
        const lrsTypeOption = LRS_TYPE_OPTIONS.find(option => option.value === value);
        const diagnosisValue = lrsTypeOption ? lrsTypeOption.label : value
        dispatch(editOMDHistoryEntry({
            editIndex: index, field, value: diagnosisValue
        }));
    }

    // manually clear the year selection when 'x' is clicked
    const clearValue = (field: keyof IOmdHistoryItem) => {
        dispatch(editOMDHistoryEntry({
            editIndex: index, field, value: ''
        }));
    }

    const isAllEntriesEmpty = !(data && (data.history_omd_name || data.history_diagnosis ||
        data.history_diagnosis || data.eye_select || data.history_date || data.history_end_date));

    const historyDate = data && data.history_date;

    const cleanedHistoryDate = historyDate && historyDate.includes('/') ? historyDate.substring(3): historyDate;

    const startDate = cleanedHistoryDate && cleanedHistoryDate !== '0000'
        ? dayjs(cleanedHistoryDate, yearFormat)
        : null;

    const endDate = data && data.history_end_date && data.history_end_date !== '0000'
        ? dayjs((data && data.history_end_date), yearFormat)
        : null;

    return (
        <Row gutter={5} className="div-tag-entry">
            <Col span={3}>Pt saw Dr.</Col>
            <Col span={4}>
                <Input
                    size="small"
                    onChange={(e) => handleChange(e, 'history_omd_name')}
                    value={data && data.history_omd_name}
                />
            </Col>
            <Col span={1}>for</Col>

            <Col span={4}>
                <AutoComplete
                    size="small"
                    placeholder="Diagnosis"
                    filterOption
                    onChange={(value) => handleDiagonsisChange(value, 'history_diagnosis')}
                    value={data.history_diagnosis}
                    options={LRS_TYPE_OPTIONS}
                    allowClear
                />
            </Col>
            <Col span={3}>
                <Select
                    showSearch
                    size="small"
                    onSelect={(value) => handleSelect(value, 'eye_select')}
                    value={(data && data.eye_select && data.eye_select === '') ? null : data.eye_select}
                    allowClear
                    onChange={() => clearValue('eye_select')}
                >
                    {eyeTypes && eyeTypes.map((option) => (
                        <Option
                            rel="eye_select"
                            key={option[1]}
                            value={option[0]}
                        >
                            {option[1]}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col span={4}>
                <span className="inline history_date">
                    {' '}
                    in&nbsp;
                    <DatePicker
                        placeholder="Select"
                        size="small"
                        onChange={(_, dateString) => handleDateChange(`${dateString}`, 'history_date')}
                        value={startDate || null}
                        picker="year"
                    />
                </span>
            </Col>
            <Col span={4}>
                <span className="inline history_end_date">
                    -&nbsp;&nbsp;
                    <DatePicker
                        placeholder="Select"
                        size="small"
                        onChange={(_, dateString) => handleDateChange(`${dateString}`, 'history_end_date')}
                        value={endDate || null}
                        picker="year"
                    />
                </span>
            </Col>
            <Col span={1} className="delete">
                <Popconfirm title="Are you sure？" onConfirm={() => { dispatch(deleteOMDHistoryEntry(index)); }}>X</Popconfirm>
            </Col>
        </Row>
    );
}
export default PatientExamOMDHistoryEntry;
