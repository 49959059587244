import { Fragment } from 'react';
import { Card, Row, Col, Tag, Checkbox, Divider } from 'antd';
import { localizedText } from '../../../localizedText';
import '../../../../static/css/components/patient-exam-comorbidities.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type comorbidities = {
    fhx: boolean, 
    asthma: boolean, 
    heart_block: boolean, 
    uveitis_od: boolean, 
    uveitis_os: boolean,  
    pds_od: boolean, 
    pds_os: boolean, 
    hx_stroke: boolean,  
    pxf_od: boolean,  
    pxf_os: boolean, 
}

type ComponentProps = {
    comorbidities: comorbidities;
    onComorbiditiesChange: (field: string, val: boolean) => void;
    comorbiditiesNone: boolean;
    onComorbiditiesNoneChange: (e: CheckboxChangeEvent) => void;
    gptResultState: { [x: string]: string };
}

const { CheckableTag } = Tag;

const GptPatientExamComorbidities = ({ comorbidities, onComorbiditiesChange, comorbiditiesNone, 
    onComorbiditiesNoneChange, gptResultState  }: ComponentProps) => {

    const { FHX_GLC_TEXT, HEART_BLOCK_TEXT, STROKE_TEXT, UVEITIS_OD_TEXT,
        UVEITIS_OS_TEXT, PXF_OD_TEXT, PXF_OS_TEXT, PDS_OD_TEXT, PDS_OS_TEXT,
        ASTHMA_TEXT, NONE_TEXT } = localizedText;

    // If there are no selections and none is not selected then the checkbox field should be required.
    const className = 'patient-exam-comorbidities';

    const { fhx, asthma, heart_block, uveitis_od, uveitis_os, pds_od, pds_os,
        hx_stroke, pxf_od, pxf_os } = comorbidities; 

    const handleChanges = (field: string, val: boolean) => {
        onComorbiditiesChange(field, val)
    }
    return (
        <Card className={className} bordered={false}>
            {/* COMORBID 001 */}
                <Fragment>
                    <Row className={`${gptResultState['comorbiditiesNone']} heading-row`}>
                        <span className='heading'>GPT</span>
                        <Checkbox
                            checked={comorbiditiesNone}
                            onChange={onComorbiditiesNoneChange}
                            >
                            {NONE_TEXT}
                        </Checkbox>
                    </Row>
                    <Row className={'tags-section'}>
                        <Col span={8} className={'first-column'}>
                            <Row className={`${gptResultState['fhx']}`}>
                                {/* COMORBID 003 */}
                                    <CheckableTag onChange={checked => handleChanges('fhx', checked)} checked={fhx} key={'fhx'}>
                                        {FHX_GLC_TEXT}
                                    </CheckableTag>
                            </Row>
                            <Row className={`${gptResultState['asthma']}`}>
                                {/* COMORBID 002 */}
                                    <CheckableTag onChange={checked => handleChanges('asthma', checked)} checked={asthma} key={'asthma'}>
                                        {ASTHMA_TEXT}
                                    </CheckableTag>
                            </Row>
                            <Row className={`${gptResultState['heart_block']}`}>
                                {/* COMORBID 004 */}
                                    <CheckableTag onChange={checked => handleChanges('heart_block', checked)} checked={heart_block} key={'heart_block'}>
                                        {HEART_BLOCK_TEXT}
                                    </CheckableTag>
                            </Row>
                            <Row className={`${gptResultState['hx_stroke']}`}>
                                {/* COMORBID 007 */}
                                    <CheckableTag onChange={checked => handleChanges('hx_stroke', checked)} checked={hx_stroke} key={'hx_stroke'}>
                                        {STROKE_TEXT}
                                    </CheckableTag>
                            </Row>
                        </Col>
                        <Col span={1}>
                            <Divider type={'vertical'} />
                        </Col>
                        <Col span={8} className={'second-column'}>
                            <Row className={'empty-row'} />
                            <Row className={`${gptResultState['uveitis_od']}`}>
                                {/* COMORBID 005 */}
                                    <CheckableTag onChange={checked => handleChanges('uveitis_od', checked)} checked={uveitis_od} key={'uveitis_od'}>
                                        {UVEITIS_OD_TEXT}
                                    </CheckableTag>
                            </Row>
                            <Row className={`${gptResultState['pxf_od']}`}>
                                {/* COMORBID 008 */}
                                    <CheckableTag onChange={checked => handleChanges('pxf_od', checked)} checked={pxf_od} key={'pxf_od'}>
                                            {PXF_OD_TEXT}
                                    </CheckableTag>
                            </Row>
                            <Row className={`${gptResultState['pds_od']}`}>
                                {/* COMORBID 010 */}
                                    <CheckableTag onChange={checked => handleChanges('pds_od', checked)} checked={pds_od} key={'pds_od'}>                                        
                                            {PDS_OD_TEXT}
                                    </CheckableTag>
                            </Row>
                        </Col>
                        <Col span={7}>
                            <Row className={'empty-row'} />
                            <Row className={`${gptResultState['uveitis_os']}`}>
                                {/* COMORBID 009 */}
                                    <CheckableTag onChange={checked => handleChanges('uveitis_os', checked)} checked={uveitis_os} key={'uveitis_os'}>
                                        {UVEITIS_OS_TEXT}
                                    </CheckableTag>
                            </Row>
                            <Row className={`${gptResultState['pxf_os']}`}>
                                {/* COMORBID 011 */}
                                    <CheckableTag onChange={checked => handleChanges('pxf_os', checked)} checked={pxf_os} key={'pxf_os'}>
                                            {PXF_OS_TEXT}
                                    </CheckableTag>
                            </Row>
                            <Row className={`${gptResultState['pds_os']}`}>
                                    <CheckableTag onChange={checked => handleChanges('pds_os', checked)} checked={pds_os} key={'pds_os'}>
                                            {PDS_OS_TEXT}
                                    </CheckableTag>
                            </Row>
                        </Col>
                    </Row>
                </Fragment>
            </Card>
    );
}

export default GptPatientExamComorbidities;