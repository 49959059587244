import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom'
import { FileSyncOutlined, CalendarOutlined, FolderOpenOutlined, SearchOutlined, InfoCircleOutlined
    } from '@ant-design/icons';
import { Row, DatePicker, Select, Input, Divider, Button, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { goToExamInNewTab } from '../../reducers/patient-exam-slice';
import { OMDR_RE_STATUS_TYPES, PATIENT_EXAM_URL } from '../../constants';
import { getOMDRPatientListRequest, getOMDRSearchPatientListRequest, IOMDRPatientListItem,
    setPatientListFieldData
} from '../../reducers/patient-list-slice';
import '../../../static/css/shared/patient-list-control-bar.scss';
import '../../../static/css/components/patient-list-control-bar-omdr.scss';
import { localizedText } from '../../localizedText';
import { openMultiTabsView, setPatientExamRoomsData } from '../../reducers/patient-exam-rooms-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { goToExam } from '../../reducers/patient-exam-slice';


const PatientListControlBarOMDR = () => {
    const { INSTALL_EXTENSION, FOLLOW_STEPS, DISABLE_POPUP_BLOCKER, OPEN_ALL_PATIENTS, PATIENT_LIST_TEXT,
        OMDR_IC_STATUS_TYPES, EXAM_ROOMS_FUNCTIONALITY, NEW_TABS, FORCE_BACKGROUND, LOAD_FIVE_PATIENTS,
        PATIENT_LIST_SEARCH_PLACEHOLDER, EXAM_PERIOD_OPTIONS } = localizedText;

    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const omdrPeriodFilter = useCare1AppSelector(store => store.patientList.omdrPeriodFilter);
    const examStatus = useCare1AppSelector(store => store.patientList.omdrStatus);
    const patientList = useCare1AppSelector(store => store.patientList.omdrTableList);
    const omdrStartDate = useCare1AppSelector(store => store.patientList.omdrStartDate);
    const omdrEndDate = useCare1AppSelector(store => store.patientList.omdrEndDate);

    const dispatch = useCare1AppDispatch();
    const history = useNavigate();

    const { RangePicker } = DatePicker;

    const getTooltipText = () => {
        return (
            <Fragment>
            <div className='load-patients-tooltip-item'>
                {EXAM_ROOMS_FUNCTIONALITY}
            </div>
            <div className='load-patients-tooltip-item'>
                {`1. ${DISABLE_POPUP_BLOCKER}`}
                <a
                    href='https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop'
                    target="_blank"
                    rel="noreferrer"
                >
                   {FOLLOW_STEPS}
                </a>
            </div>
            <div className='load-patients-tooltip-item'>
                 {`2. ${INSTALL_EXTENSION}`}
                <a
                    href='https://chrome.google.com/webstore/detail/new-tabs-at-end-3000/bboielpmccpfkhggghgpimeblacofahc'
                    target="_blank"
                    rel="noreferrer"
                >
                   {NEW_TABS}
                </a>
            </div>
            <div className='load-patients-tooltip-item'>
            {`3. ${INSTALL_EXTENSION}`}
                <a
                    href='https://chrome.google.com/webstore/detail/force-background-tab/gidlfommnbibbmegmgajdbikelkdcmcl'
                    target="_blank"
                    rel="noreferrer"
                >
                    {FORCE_BACKGROUND}
                </a>
            </div>
            </Fragment>
        )
    }

    // OMDR RE & OMDR IC Handlers
    const handleExamPeriodSelection = (value: string) => {
        dispatch(setPatientListFieldData({ key:'omdrPeriodFilter', value }));
        dispatch(getOMDRPatientListRequest());
    }

    const handleOMDRStatusSelection = (value: string) => {
        dispatch(setPatientListFieldData({ key:'omdrStatus', value }));

        // When OMDR IC selects a status set the reviewed period to 'none'
        if(!userIsRE){
            dispatch(setPatientListFieldData({ key: 'omdrPeriodFilter', value: 'none' }));
        }

        dispatch(getOMDRPatientListRequest());
    }

    const sendSearchPatientListRequest = (value: string) => {
        if(value){
            if(userIsRE){
                // Search all statuses and all time period
                dispatch(setPatientListFieldData({ key: 'omdrStatus', value: 'ready_or_reviewed' }));
                dispatch(setPatientListFieldData({ key: 'omdrPeriodFilter', value: 'all_time' }));
            } else {
                dispatch(setPatientListFieldData({ key: 'omdrStatus', value: 'none' }));
                dispatch(setPatientListFieldData({ key: 'omdrPeriodFilter', value: 'none' }));
            }

            dispatch(setPatientListFieldData({ key:'searchQuery', value }));
            dispatch(getOMDRSearchPatientListRequest(value));
        } else {
            // Reset the default values
            if(userIsRE){
                dispatch(setPatientListFieldData({ key: 'omdrStatus', value: 'ready' }));
                dispatch(setPatientListFieldData({ key:'omdrPeriodFilter', value: 'all_time' }));
            } else {
                dispatch(setPatientListFieldData({ key: 'omdrPeriodFilter', value: 'none' }));
            }
            dispatch(setPatientListFieldData({ key:'searchQuery', value: '' }));
            dispatch(getOMDRPatientListRequest());
        }
    }

    const handleLoadExamRoomsBrowserTabs = () => {
        const EXAMS_TO_LOAD = 5;
        const examIds = patientList.map((patient: IOMDRPatientListItem) => {
            return patient.latest_visit_id ? patient.latest_visit_id.toString() : ''
        });
        const startingExams = examIds.slice(0,EXAMS_TO_LOAD);
        const remaningExams = examIds.slice(EXAMS_TO_LOAD);

        localStorage.setItem("exam_ids", JSON.stringify(remaningExams));

        if(startingExams && startingExams.length){
            startingExams.forEach((examId: string) => {
                const examUrl = `${PATIENT_EXAM_URL}/${examId}`;
                goToExamInNewTab(examUrl);
            })
        }
    }

    const handleLoadExamRoomsInternalTabs = () => {
        // Go through all patients in the list, build the new exam rooms store, and open an exam tab to show all of it.
        let examRooms: {examId: number, patientName: string}[] = [];

        patientList.forEach((patientEntry: IOMDRPatientListItem) => {
            // We want to create a new room for each exam
            if (patientEntry.latest_visit_id) {
                examRooms.push({
                    examId: patientEntry.latest_visit_id,
                    patientName: `${patientEntry.last_name}, ${patientEntry.first_name}`,
                });
            }
        });

        dispatch(setPatientExamRoomsData({ rooms: examRooms }));
        if (examRooms.length) {
            // Open a new browser tab with focus on the first exam in the list.
            dispatch(openMultiTabsView());
            dispatch(setPatientExamRoomsData({ currentExamRoom: examRooms[0].examId.toString() }));
            goToExam(examRooms[0].examId, history);
        }
    }

    const handleRangePicker = (dateStrings: string[]) => {
        dispatch(setPatientListFieldData({ key: 'omdrStartDate', value: dateStrings[0] }));
        dispatch(setPatientListFieldData({ key: 'omdrEndDate', value: dateStrings[1] }));

        // When OMDR IC selects a reviewed period set status to 'none'
        if(!userIsRE){
            dispatch(setPatientListFieldData({ key:'omdrStatus', value: 'none' }));
        }
        dispatch(getOMDRPatientListRequest());
    }

    return (
        <Row className={`patient-list-control-bar patient-list-control-bar-omdr ${userIsRE ? `user-is-re` : `user-is-ic`}`}>
            <Row align="middle" className="control-bar-elements">
                {/* PT-LIST-CTRL-BAR-OMDR 001, 002, 003, 004, 005, 006 */}
                <div className="patient-list-title">
                    {`${userIsRE ? `${PATIENT_LIST_TEXT}` : ''}`}
                </div>

                {/* Retina Enabled - load exams to internal tabs */}
                { userIsRE &&
                    <Button
                    data-testid='open-patients-button'
                    className="load-exam-rooms-button"
                        icon={<FolderOpenOutlined />}
                        onClick={handleLoadExamRoomsInternalTabs}
                        type='primary'
                    >
                        {OPEN_ALL_PATIENTS}
                    </Button>
                }

                {/* Integrated Clinic - load exams to external browser tabs */}
                { !userIsRE &&
                    <Button
                        className="load-exam-rooms-button"
                        icon={<FolderOpenOutlined />}
                        onClick={handleLoadExamRoomsBrowserTabs}
                        type='primary'
                    >
                        {LOAD_FIVE_PATIENTS}
                        <Tooltip overlayClassName="load-five-patients-tooltip" placement='topRight' title={getTooltipText}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Button>
                }

                <Divider type="vertical" className="patient-list-vertical-divider" />

                { userIsRE &&
                    <Select
                        data-testid='exam-period-selector'
                        className="dropdown-filter exam-period-filter"
                        popupClassName="patient-list-dropdown"
                        defaultValue={userIsRE ? omdrPeriodFilter : 'none'}
                        value={omdrPeriodFilter}
                        listHeight={400}
                        onSelect={handleExamPeriodSelection}
                    >
                        {userIsRE && EXAM_PERIOD_OPTIONS && EXAM_PERIOD_OPTIONS.map((option) => (
                            <Select.Option data-testid='exam-period-option' key={option.key} value={option.key}>
                                <CalendarOutlined />{option.label}
                            </Select.Option>
                        ))}
                    </Select>
                }

                { !userIsRE &&
                    <RangePicker
                        className='filter-range-picker exam-period-filter'
                        onChange={(dates, dateStrings) => handleRangePicker(dateStrings)}
                        value={[omdrStartDate ? dayjs(omdrStartDate) : null, omdrEndDate ? dayjs(omdrEndDate) : null]}
                        placeholder={['Start Date', 'End Date']}
                    />
                }

                <Divider type="vertical" className="patient-list-vertical-divider" />
                <Select
                    data-testid='exam-status-filter'
                    className="dropdown-filter exam-status-filter"
                    popupClassName="patient-list-dropdown"
                    defaultValue={'ready'}
                    value={examStatus}
                    listHeight={400}
                    onSelect={handleOMDRStatusSelection}
                >
                    {userIsRE && OMDR_RE_STATUS_TYPES && OMDR_RE_STATUS_TYPES.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            <FileSyncOutlined /> {option.value}
                        </Select.Option>
                    ))}
                    {!userIsRE && OMDR_IC_STATUS_TYPES && OMDR_IC_STATUS_TYPES.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                            <FileSyncOutlined /> {option.label}
                        </Select.Option>
                    ))}
                </Select>
                <Divider type="vertical" className="patient-list-vertical-divider" />

                <Input.Search
                    className="patient-list-search-input"
                    allowClear
                    placeholder={PATIENT_LIST_SEARCH_PLACEHOLDER}
                    prefix={<SearchOutlined />}
                    onSearch={sendSearchPatientListRequest}
                />
            </Row>
        </Row>
    );
}

export default PatientListControlBarOMDR;