import { useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { Col, Input, Row, Spin, Form } from 'antd';
import { editInsuranceRequest, getUsInsuranceRequest, IUSInsurance, setUsInsuranceDataValue
    } from '../../../reducers/us-insurance-slice';
import '../../../../static/css/components/patient-insurance-form.scss';
import PatientInsuranceImages from './patient-insurance-images';
import { FormInstance } from 'antd/es/form';


const PatientInsuranceForm = ({form} : {form : FormInstance}) => {
    const company = useCare1AppSelector(store => store.usInsurance.company);
    const payorId = useCare1AppSelector(store => store.usInsurance.payorId);
    const memberId = useCare1AppSelector(store => store.usInsurance.memberId);
    const groupId = useCare1AppSelector(store => store.usInsurance.groupId);
    const copay = useCare1AppSelector(store => store.usInsurance.copay);
    const deductible = useCare1AppSelector(store => store.usInsurance.deductible);
    const billingAddress = useCare1AppSelector(store => store.usInsurance.billingAddress);
    const id = useCare1AppSelector(store => store.usInsurance.id);
    const formOperating = useCare1AppSelector(store => store.usInsurance.formOperating);

    const dispatch = useCare1AppDispatch();

    useEffect(()=>{
        form.setFieldsValue({
            company, payorId, memberId, groupId, copay, deductible, billingAddress, id
        });
    }, [company, payorId, memberId, groupId, copay, deductible, billingAddress, id, form])

    const onValueschange = (changedValues : {key: string | number, value: string}) => {
        const [key, value] = Object.entries(changedValues)[0];
        dispatch(setUsInsuranceDataValue({key, value}));
    }

    const onFinish = (value : IUSInsurance) => {
        dispatch(editInsuranceRequest({insuranceId: id!, value: value}))
        .then(() =>{
            dispatch(getUsInsuranceRequest(id!));
        })
    }

    return (
        <Form
            form={form}
            onValuesChange={onValueschange}
            labelCol={{span:4}}
            wrapperCol={{span: 18}}
            className='insurance-form'
            onFinish={onFinish}
        >
            <Spin
                className='loading-spinner'
                size='large'
                spinning={formOperating}
            >
                <Form.Item
                    label='Insurance Company'
                    name='company'
                    labelCol={{span:24}}
                    wrapperCol={{span: 22}}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Billing Address'
                    name='billingAddress'
                    initialValue={billingAddress}
                >
                    <Input.TextArea rows={5} />
                </Form.Item>
                <Form.Item
                    label='Payor ID #'
                    name='payorId'
                    initialValue={payorId}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Member ID #'
                    name='memberId'
                    initialValue={memberId}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Group #'
                    name='groupId'
                    initialValue={groupId}
                >
                    <Input />
                </Form.Item>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label='Copay'
                            name='copay'
                            initialValue={copay}
                            labelCol={{span:8}}
                            wrapperCol={{span: 15}}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label='Deductible'
                            name='deductible'
                            initialValue={deductible}
                            labelCol={{span:8}}
                            wrapperCol={{span: 16}}
                        >
                            <Input />
                        </Form.Item>

                    </Col>
                </Row>
                <Row className='images-row'>
                    <Col span={8} offset={2}>
                        <PatientInsuranceImages field='cardFrontImage' />
                    </Col>
                    <Col span={8} offset={3}>
                        <PatientInsuranceImages field='cardBackImage' />
                    </Col>
                </Row>
                <Row>
                    <Col span={8} offset={2}>
                        <p className='image-caption'>Front Image</p>
                    </Col>
                    <Col span={8} offset={3}>
                        <p className='image-caption'>Back Image</p>
                    </Col>
                </Row>
            </Spin>
        </Form>

    );
}

export default PatientInsuranceForm;
