import { useEffect, useMemo, useState } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import update from 'immutability-helper';
import CreatableSelect from 'react-select/creatable';
import { Card, Row, Form } from 'antd';
import { generateDiagnosisListOptions, sortOptions } from '../../helpers/diagnosis-convert';
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';
import { useFormValidation } from '../../context/useFormValidationContext';
import { IExamData, lengthEqual } from '../../reducers/patient-exam-slice';
import { setPreReviewDataValue } from '../../reducers/pre-review-slice';
import { localizedText } from '../../localizedText';
import { IDiagnosisEntry, setDiagnosisValues } from '../../reducers/diagnosis-slice';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { updateGptSetValuesNearMissData } from '../../reducers/gpt-set-values-slice';
import { FieldData } from 'rc-field-form/lib/interface';
import * as Constants from '../../constants';
import '../../../static/css/components/_patient-exam-chief-complaint.scss';

const FormItem = Form.Item;

const PatientExamChiefComplaintIC = ({ disabled, shouldAddBooleanOptions=false }: { disabled: boolean, shouldAddBooleanOptions?: boolean}) => {
    const { CHIEF_COMPLAINT_PLACEHOLDER_TEXT, CHIEF_COMPLAINT_GLC_LABEL, CHIEF_COMPLAINT_OPTIC_NERVE_LABEL,
        CHIEF_COMPLAINT_GLCS_LABEL, CHIEF_COMPLAINT_DM_LABEL, CHIEF_COMPLAINT_AMD_LABEL,
        CHIEF_COMPLAINT_ERM_LABEL, CHIEF_COMPLAINT_CAT_REF_LABEL, CHIEF_COMPLAINT_POST_OP_LABEL,
        CHIEF_COMPLAINT_NAR_ANG_LABEL, CHIEF_COMPLAINT_TEXT } = localizedText;

    const diagnosisList = useCare1AppSelector(store => store.examData[Constants.DIAGNOSIS_LIST], lengthEqual);
    const diagnosisEntries = useCare1AppSelector(store => store.diagnosis.entries);
    const glc = useCare1AppSelector(store => store.examData.rr_glc);
    const glcSuspect = useCare1AppSelector(store => store.examData.rr_glc_suspect);
    const opticNerve = useCare1AppSelector(store => store.examData.rr_optic_nerve);
    const dm = useCare1AppSelector(store => store.examData.rr_dm);
    const amd = useCare1AppSelector(store => store.examData.rr_amd);
    const erm = useCare1AppSelector(store => store.examData.rr_erm);
    const cataract = useCare1AppSelector(store => store.examData.rr_cataract);
    const catPostOp = useCare1AppSelector(store => store.examData.rr_cat_post_op);
    const narrowAngles = useCare1AppSelector(store => store.examData.rr_narrow_angles);
    const isAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const isReferralLetterUploadPei = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const gptSavedValues = useCare1AppSelector(store => store.gptSetValues.gpt_saved_values);
    const gptNearMissFields = useCare1AppSelector(store => store.gptSetValues.gpt_near_miss_fields);

    const [errorMessage, setErrorMessage] = useState('');

    const [ form ] = Form.useForm();
    const dispatch = useCare1AppDispatch();
    const { setChiefComplaintForm } = useFormValidation();

    // Side effects that we run after component render.
    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            diagnosisEntries: diagnosisEntries,
            rr_glc: glc,
            rr_glc_suspect: glcSuspect,
            rr_optic_nerve: opticNerve,
            rr_dm: dm,
            rr_amd: amd,
            rr_erm: erm,
            rr_cataract: cataract,
            rr_cat_post_op: catPostOp,
            rr_narrow_angles: narrowAngles,
        }
        form.setFieldsValue(newValidationFieldValues);

        // The component should validate on every render
        form.validateFields();

        // pass the form instance to useFormValidation hooks
        setChiefComplaintForm(form);
        // clean up function when chief complaint component unmounts
        return () => {
            setChiefComplaintForm(null);
            setErrorMessage('');
        }
    }, [amd, catPostOp, cataract, diagnosisEntries, dm, erm, form, glc, glcSuspect, narrowAngles,
        opticNerve, setChiefComplaintForm])

    // Ant Design handler. This is called whenever a field is changed by the user using Ant Design controls.
    const onFieldsChange = (fields: FieldData[]) => {
        if(fields && fields[0] && fields[0].errors && fields[0].errors.length){
            setErrorMessage(buildRequiredErrorMessage('A diagnosis'));
        } else {
            setErrorMessage('');
        }
    }

    interface ICommonDiagnosis {
        id: keyof IExamData;
        label?: string;
        value: boolean;
    }

    const commonDiagnosis: ICommonDiagnosis[] = [
        {id: 'rr_glc', label: CHIEF_COMPLAINT_GLC_LABEL, value: glc},
        {id: 'rr_glc_suspect', label: CHIEF_COMPLAINT_GLCS_LABEL, value: glcSuspect},
        {id: 'rr_optic_nerve', label: CHIEF_COMPLAINT_OPTIC_NERVE_LABEL, value: opticNerve},
        {id: 'rr_dm', label: CHIEF_COMPLAINT_DM_LABEL, value: dm},
        {id: 'rr_amd', label: CHIEF_COMPLAINT_AMD_LABEL, value: amd },
        {id: 'rr_erm', label: CHIEF_COMPLAINT_ERM_LABEL, value: erm },
        {id: 'rr_cataract', label: CHIEF_COMPLAINT_CAT_REF_LABEL, value: cataract},        
        {id: 'rr_narrow_angles', label: CHIEF_COMPLAINT_NAR_ANG_LABEL, value: narrowAngles},
    ];

    if (!isReferralLetterUploadPei) {
        commonDiagnosis.push({id: 'rr_cat_post_op', label: CHIEF_COMPLAINT_POST_OP_LABEL, value: catPostOp });
    }

    // Add the diagnosis list selectable options into the dropdown.
    // useMemo hook is used here because diagnosisList usually does not change once all exam data is
    // loaded, hence the diagnosisListOptions does not change. Before changes, all the diagnosisOptions
    // will be calculated again after a complaint is added/removed. Now the diagnosisOptions will be
    // calculated only once
    const diagnosisListOptions = useMemo(() => {
        return sortOptions(generateDiagnosisListOptions(diagnosisList, shouldAddBooleanOptions));
    }, [diagnosisList, shouldAddBooleanOptions]);

    // To remove the defaultValue props of the component
    const creatableSelectBase = <CreatableSelect
        isDisabled={disabled}
        className="chief-complaint-select"
        classNamePrefix="chief-complaint"
        isMulti
        options={diagnosisListOptions}
        isClearable={false}
        placeholder={CHIEF_COMPLAINT_PLACEHOLDER_TEXT}
        onChange={(event, actionType) => {
            const selectedDiagnosisValues = event as IDiagnosisEntry[];
            dispatch(setDiagnosisValues(selectedDiagnosisValues, actionType.action, diagnosisList));
        }}
    />;
    const creatableSelectBaseProps = creatableSelectBase.props;
    let creatableSelectProps = {
        ...creatableSelectBaseProps,
    };

    delete creatableSelectProps.defaultValue;
    const creatableSelect = update(creatableSelectBase, {props: {$set: {...creatableSelectProps}}});

    const handleClick = ({id, value}: ICommonDiagnosis) => {
        if (!disabled) {
            dispatch(setExamDataValue(id, !value));
            dispatch(updateGptSetValuesNearMissData({id, value: !value}));
            isAdmin && dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
        }
    }

    const getGptSavedValue = (k: string) => {
        if (!isAdmin) {
            return false;
        }

        if (gptSavedValues && gptSavedValues !== '') {
            try {
                const gptSavedValuesJson = JSON.parse(gptSavedValues);
                return gptSavedValuesJson[k];
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }

    const getGptNearMissField = (k: string) => {
        if (!isAdmin) {
            return false;
        }

        if (gptNearMissFields && gptNearMissFields !== '') {
            try {
                const gptNearMissFieldsJson = JSON.parse(gptNearMissFields);
                return gptNearMissFieldsJson[k];
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }

    const getGptClassName = (id: string, value: boolean) => {
        if (getGptNearMissField(id)) {
            return 'gpt-state-red';
        }

        if (value && getGptSavedValue(id)) {
            return 'gpt-state-yellow';
        }

        return '';
    }

    return (

        <Card className={'patient-exam-chief-complaint'} bordered={false}>
        <Form form={form} onFieldsChange={onFieldsChange} >
            {/* COMPLAINT 001, COMPLAINT 002, COMPLAINT 003, COMPLAINT 004 */}
            <div className='title-row' >
                <div className='chief-complaint-title'>{CHIEF_COMPLAINT_TEXT}</div>
                <span>{errorMessage}</span>
            </div>

            <div className='common-diagnosis' >
            {
                commonDiagnosis.map(({id, label, value}) =>
                    <span
                        className={`${getGptClassName(id, value)} common-diagnosis-entry${value ? ' common-diagnosis-entry-selected': ''}${disabled ? ' disabled' : ''}`}
                        onClick={() => handleClick({id, value})}
                        key={id}
                    >
                        {label}
                    </span>
                )
            }
            </div>

            <Row className='other-diagnosis-row'>
                <FormItem
                    colon={false}
                    name={'diagnosisEntries'}
                    rules={[{
                        validator: (_, otherDiagnoses) => {
                            // at least one diagonsis is required
                            if((otherDiagnoses && otherDiagnoses.length) || glc || glcSuspect ||
                                opticNerve || dm || amd || erm || cataract || catPostOp || narrowAngles){
                                return Promise.resolve();
                            }
                            return Promise.reject();
                        }
                    }]}
                >
                    {creatableSelect}
                </FormItem>
            </Row>
            </Form>
        </Card>

    );
}

export default PatientExamChiefComplaintIC;
