import { ExportOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router';
import { goToExamInNewTab } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch } from '../../apps/care1-hooks';
import * as Constants from '../../constants';
import { closePatientDetailsModal } from '../../reducers/patient-details-slice';
import { clearPatientExamRoomsData } from '../../reducers/patient-exam-rooms-slice';
import { goToExam } from '../../reducers/patient-exam-slice';

type ComponentProps = {
    className: string,
    visitID: number,
    buttonText: string,
    newTab: boolean
}

const LatestVisitButton = ({ className, visitID, buttonText, newTab}: ComponentProps) => {
    const dispatch = useCare1AppDispatch();
    const navigate = useNavigate();

    return (
        <div className="latest-visit-cell">
            <Button
                disabled={(visitID !== null && visitID !== undefined && `${visitID}` !== '') ? false : true}
                className={className}
                icon={<ExportOutlined />}
                onClick={(event) => {
                    event.stopPropagation();
                    dispatch(closePatientDetailsModal());
                    dispatch(clearPatientExamRoomsData());
                    if (newTab) {
                        const examUrl = `${Constants.PATIENT_EXAM_URL}/${visitID}`;
                        goToExamInNewTab(examUrl);
                    }
                    else {
                        goToExam(visitID, navigate);
                    }
                }}
            >
                {buttonText}
            </Button>
        </div>
    );
}

export default LatestVisitButton;
