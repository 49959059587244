import React from 'react';
import { Tag } from 'antd';
import { useCare1AppSelector } from '../../apps/care1-hooks';

import '../../../static/css/components/patient-exam-header-admin.scss';
import { localizedText } from '../../localizedText';

const PatientExamHeaderMultipleExamsReadyTag = () => {
    const { PATIENT_HAS_MULTIPLE_EXAMS, PATIENT_HAS_NO_MULTIPLE_EXAMS } = localizedText;
    const prereviewAlert =  useCare1AppSelector(store => store.examData.prereview_alert);

    const hasMultipleReady = (prereviewAlert !== null && Array.isArray(prereviewAlert) && 
        prereviewAlert.some(alert => alert.toLowerCase() === 'multiple visits waiting for review'))
        ? true : false;

    return (
        <>
        {hasMultipleReady &&
            <Tag
                className="multiple-ready-exams-exist"
            >
                {PATIENT_HAS_MULTIPLE_EXAMS}
            </Tag>
        }
        {!hasMultipleReady &&
             <Tag
                className="multiple-ready-exams-do-not-exist"
            >
                {PATIENT_HAS_NO_MULTIPLE_EXAMS}
            </Tag>
        }
        </>
    );
}

export default PatientExamHeaderMultipleExamsReadyTag;