import React from 'react';
import { Modal,  Spin } from 'antd';
import IGPEditForm from './igp-edit-form';
import '../../../static/css/components/admin-doctor-edit-modal.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { IIGPDetails, useEditIgpMutation } from '../../services/doctor-api';
import { setCurrentStaffId, setCurrentUsername, setShowIgpEditModal } from '../../reducers/doctor-slice';


const IGPEditModal = () => {
    const showIgpEditModal = useCare1AppSelector(store => store.doctorSlice.showIgpEditModal);
    const currentIgp = useCare1AppSelector(store => store.doctorSlice.currentIgp);


    const dispatch = useCare1AppDispatch();

    const [editIgp, {isLoading}] = useEditIgpMutation();


    const handleModalClose = () => {
        dispatch(setShowIgpEditModal(false));
        dispatch(setCurrentStaffId(null));
        dispatch(setCurrentUsername(''));
    };

    const onFinish = async (values: IIGPDetails) => {
        try {
            // we use the editIgp function after the form is complete and submit/save button is clicked
            // unwrap() gives the access to error or success payload
            const result = await editIgp({...values, id: currentIgp}).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'IGP updated successfully.',
                    onOk: () => {
                        dispatch(setShowIgpEditModal(false));
                    }
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    const onFinishFailed = () => {
        Modal.error({
            className: 'info-modal',
            content: 'Please fix the error to continue',
            title: 'Error',
        });
    };

    return (
        <Modal
            className='admin-doctor-edit-modal igp'
            open={showIgpEditModal}
            closable={true}
            footer={null}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <Spin
                className='loading-spinner'
                size='large'
                spinning={isLoading}
            >
                <IGPEditForm
                    title={'Edit Internal GP'}
                    buttonText={'Save IGP'}
                    mode={'edit'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                />
            </Spin>
        </Modal>
    );

}

export default IGPEditModal;
