import { DatePicker, Modal } from 'antd';
import { FRONTEND_LONG_DATE_FORMAT, EXAM_DATE } from '../../../constants';
import { lengthEqual } from '../../../reducers/patient-exam-slice';
import dayjs, {Dayjs} from 'dayjs';
import { setExamDataValue } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';

const PatientExamHeaderExamDatePicker = () => {

    const userIsAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const examDate = useCare1AppSelector(store => store.examData[EXAM_DATE]);
    const exams = useCare1AppSelector(store => store.examData.exams, lengthEqual);
    const id = useCare1AppSelector(store => store.examData.id);
    const lastOmdReviewedOrReadyVisitDate = useCare1AppSelector(store => store.patientDetails.details.last_omd_reviewed_or_ready_visit_date);
    const examStatusPrevious = useCare1AppSelector(store => store.examData.exam_status_previous);
    const odUsesReferralLetterPei = useCare1AppSelector(store => store.user.odUsesReferralLetterPei);
    const referralLetterExamDateAllowedToSet = useCare1AppSelector(store => store.patientDetails.details.referral_letter_exam_date_allowed_to_set);
    
    // As per RQs the component should be disabled if the previous status is 'ready' or 'reviewed'
    // However, if the user is Admin, then the component should not be disabled
    // EH-EXAM-DATE-STATUS 019, 020, 021, 022
    const isDisabled = !userIsAdmin && examStatusPrevious && (examStatusPrevious === 'ready' || examStatusPrevious === 'reviewed');

    const dispatch = useCare1AppDispatch();

    const handleDateChange = (date: Dayjs | null) => {
        // other exam data for the same patients are already avaliable in examData.exams,
        // no need to make another network request. Filter out current exam
        const otherExams = exams && exams.filter(exam => (
            exam.id !== id
        ));
        // check wheter the selected exam date exists in other exams
        const sameDayExist = otherExams && otherExams.find(exam => (
            dayjs(exam.exam_date).isSame(date, 'date')
        ));

        const dateToCheck = odUsesReferralLetterPei ? referralLetterExamDateAllowedToSet : lastOmdReviewedOrReadyVisitDate;
        const examText = odUsesReferralLetterPei ? 'Submitted to Care1/OMD reviewed/Ready' : 'OMD reviewed/Ready'

        const isDatePriorToLastReviewedExam = dateToCheck && date?.isBefore(dayjs(dateToCheck));

        if (sameDayExist) {
            Modal.info({
                className: 'info-modal',
                title: `A patient encounter for this date already exists`,
            });
        } else if (isDatePriorToLastReviewedExam) {
            Modal.info({
                className: 'info-modal',
                title: `Cannot change exam to a date that is prior to the ${examText} exam, the ${examText} exam date is ${dayjs(dateToCheck).format('YYYY-MM-DD')}`,
            });
        } else {
            // The date needs to be converted from dayjs, used by the form field, to a string used by the
            // store. The string is in ISO format.
            const formattedValue = date ? date.format() : '';
            if (formattedValue && formattedValue !== examDate) {
                dispatch(setExamDataValue(EXAM_DATE, formattedValue));
            }
        }
    }

    return (
        <DatePicker
            data-testid='exam-date-picker'
            size="small"
            disabled={Boolean(isDisabled)}
            className="patient-exam-date-input"
            showToday={false}
            format={FRONTEND_LONG_DATE_FORMAT}
            value={dayjs(examDate)}
            onChange={(date, dateString) => { handleDateChange(date)}}
        />
    )
}

export default PatientExamHeaderExamDatePicker;