import { EditOutlined } from '@ant-design/icons';
import { Modal, Table, Button, Divider, Col, Row } from 'antd';
import ExamStatusTag from './exam-status-tag';
import LatestVisitButton from './button-latest-visit';
import DeactivateVisitButton from './deactivate-visit-button';
import { EDIT_BUTTON_TEXT, NEW_VISIT_BUTTON_TEXT, VIEW_VISIT_TEXT } from '../../constants';
import NewVisitButton from './button-new-visit';
import '../../../static/css/components/_patient-details-modal.scss';
import type { ColumnsType } from 'antd/es/table';

// Helper imports
import { IAdminExamHistoryDetails, IExamHistoryDetails, buildAdminHistoryDetailsTableData, buildHistoryDetailsTableData, buildPatientDetailsTableData } from '../../helpers/patient-details-convert';

// Action imports
import { getUsInsuranceListRequest, toggleInsuranceModal } from '../../reducers/us-insurance-slice';
import { closePatientDetailsModal } from '../../reducers/patient-details-slice';
import { openEditPatientModal } from '../../reducers/patient-edit-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

const PatientDetailsModal = () => {

    const patientDetails = useCare1AppSelector(store => store.patientDetails);
    const patientID = useCare1AppSelector(store => store.patientDetails.details.id);
    const isVisible = useCare1AppSelector(store => store.patientDetails.visible);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsUsOnly = useCare1AppSelector(store => store.user.isUsOnly);
    const examIsUsOnly = useCare1AppSelector(store => store.examData.exam_is_us_only);
    const isAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const usOnly = examIsUsOnly || userIsUsOnly;
    const patientDeactivated = useCare1AppSelector(store => store.patientDetails.details.patient_deactivated);

    const dispatch = useCare1AppDispatch();

    const handleModalClose = () => {
        dispatch(closePatientDetailsModal());
    }

    const handleOpenEditPatientModal = () => {
        dispatch(openEditPatientModal(patientID!));
    }

    const [leftPanelDetailsData, rightPanelDetailsData] = buildPatientDetailsTableData(patientDetails.details,
        userIsRE, usOnly);
    const patientHistoryData: IExamHistoryDetails[] | IAdminExamHistoryDetails[] = isAdmin ?
        buildAdminHistoryDetailsTableData(patientDetails.details.exams) :
        buildHistoryDetailsTableData(patientDetails.details.exams);

    const patientHistoryColumns: ColumnsType<IExamHistoryDetails | IAdminExamHistoryDetails> = [{
        className: 'date-column',
        dataIndex: 'visit_date',
        render: (text, record, index) =>
            <div
                data-testid={`row-${index}-date`}
                className={`${isAdmin && (record as IAdminExamHistoryDetails).is_inactive ? 'patient-details-inactive-visit': ''}`}
            >
                {text}
            </div>
    }, {
        className: 'status-column',
        title: '',
        dataIndex: 'review_status',
        render: (text, record, index) => <ExamStatusTag testId={`row-${index}-status`} className="patient-exam-status-tag" examStatusKey={text} />,
    }, {
        className: 'view-column',
        title: '',
        dataIndex: 'view',
        render: (text) =>   <LatestVisitButton
                                className="button-latest-visit"
                                buttonText={VIEW_VISIT_TEXT}
                                visitID={text}
                                newTab={true}
                            />,
    }, {
        className: 'delete-column',
        title: '',
        dataIndex: 'delete',
        render: (text) => {
            return !userIsOMDC && !userIsOMDR && <DeactivateVisitButton visitID={text} disabled={userIsOMDC} />
        },
    }];

   

    return (
        <Modal
            className={`patient-details-modal ${!userIsRE ? 'ic-patient-details-modal' : ''}`}
            open={isVisible}
            width="1272"
            closable={false}
        >
            <div className="patient-details-modal-content">
                <div className="patient-details-section">
                    <div className="section-header">
                        {/* PD 001, 002, 003, 004 */}
                        <div
                            data-testid='patient-name'
                            className={`${isAdmin && patientDeactivated ? 'patient-deactivated' : '' } section-title`}
                        >
                            {`${patientDetails.details.last_name}, ${patientDetails.details.first_name}`}
                        </div>
                        {/* Hide the Edit Patient button for the OMDC and OMDR */}
                        {!userIsOMDC && !userIsOMDR &&
                            <Button
                                data-testid='edit-patient-button'
                                disabled={userIsOMDC}
                                className="patient-details-header-button"
                                icon={<EditOutlined />}
                                onClick={handleOpenEditPatientModal}
                            >
                                {EDIT_BUTTON_TEXT}
                            </Button>
                        }
                    </div>

                    <Divider />

                    <Row>
                    <Col span={12} className="patient-details-left-panel">
                        {/* PD 005, 006, 007, 008 */}
                        {
                            leftPanelDetailsData && leftPanelDetailsData.map((row) => (
                                (usOnly && row.label === 'INS #') ?
                                <Row key={row.key} className="patient-details-row">
                                    <Button
                                        className='edit-insurance-button'
                                        onClick={() => {
                                            dispatch(toggleInsuranceModal());
                                            dispatch(getUsInsuranceListRequest(patientID!));
                                            dispatch(closePatientDetailsModal());
                                        }}
                                    >
                                        View or Edit Insurance
                                    </Button>
                                </Row>
                                :
                                <Row key={row.key} className="patient-details-row">
                                    <Col data-testid={row && row.label ? `label-${(row.label).toLowerCase()}` : ''} className="label-column">{row.label}</Col>
                                    <Col data-testid={row && row.label ? `value-${(row.label).toLowerCase()}`: ''} className="value-column">{row.value}</Col>
                                </Row>
                            ))
                        }
                    </Col>


                    <Col span={12} className="patient-details-right-panel">
                        {/* PD 009, 010, 011, 012 */}
                        {
                            rightPanelDetailsData && rightPanelDetailsData.map((row) => (
                                <Row key={row.key} className="patient-details-row">
                                    <Col data-testid={row && row.label ? `label-${(row.label).toLowerCase()}`: ''} className="label-column">{row.label}</Col>
                                    <Col data-testid={row && row.label ? `value-${(row.label).toLowerCase()}`: ''} className="value-column">{row.value}</Col>
                                </Row>
                            ))
                        }
                    </Col>
                    </Row>
                </div>

                <div className="patient-history-section">
                    <Row>
                        <Col span={24}>
                            {/* PD 013, 014, 015, 016 */}
                            <div className="section-header">
                                <div data-testid='visit-table-title' className="section-title">Visit History</div>
                                {/* Hide the New Visit button for the OMDC and OMDR */}
                                {!userIsOMDC && !userIsOMDR &&
                                    <NewVisitButton
                                        className="patient-details-header-button"
                                        buttonText={NEW_VISIT_BUTTON_TEXT}
                                        patientID={patientID!}
                                        disabled={userIsOMDC}
                                    />
                                }
                            </div>
                            <Divider />
                            <Table
                                rowClassName={(record, index) => `row-test-id-${index}`}
                                className="exam-history-table"
                                columns={patientHistoryColumns}
                                dataSource={patientHistoryData}
                                pagination={{
                                    simple: true,
                                    showLessItems: true,
                                    pageSize: 5,
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                <div className='footer'>
                    < Divider />
                    <Button
                        className="close-button"
                        onClick={handleModalClose}
                    >
                        {`Close`}
                    </Button>
                </div>

            </div>
        </Modal>
    );

}

export default PatientDetailsModal;
