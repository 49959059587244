import  { useEffect, useMemo } from 'react';
import { Modal, Form, Row, Col, Radio, Button, Input, InputNumber } from 'antd';
import { CloseCircleOutlined, ImportOutlined } from '@ant-design/icons';
import { getComorbiditiesText, getDefaultForTreatmentDrops, getCustomizableSelectionText
    } from '../../helpers/initiate-treatment-convert';
import { setInitiateTreatmentDataValue,resetInitiateTreatment, initiateTreatmentTimestampRequest, IRandomSentences, IInitiateTreatment
    } from '../../reducers/patient-exam-initiate-treatment-slice';
import { localizedText } from '../../localizedText';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { ALTERNATE_TREATMENT_DROP_OPTIONS, ALTERNATE_TREATMENT_FOLLOW_UP_OPTIONS, TREATMENT_FOLLOW_UP_OPTIONS, TREATMENT_SUCCESS_DROP_OPTIONS, TREATMENT_SUCCESS_FOLLOW_UP_OPTIONS } from '../../constants';
import { FieldData } from 'rc-field-form/lib/interface';
import { setExamDataValue, setExamDataValues } from '../../reducers/patient-exam-slice';


const PatientExamInitiateTreatmentModal = () => {
    const { TREATMENT_DROP_OPTIONS, START_TEXT, IOP_ZONE_TX, TREATMENT_IOP_OPTIONS,
        TREATMENT_INITIATION_TEXT, INITIATE_TREATMENT_TEXT, SIDE_EFFECT_DROP_OPTIONS,
        IF_EFFECTIVE_TEXT, SWITCH_TO_TEXT, DISCUSS_ARTIFICIAL_TEARS, IF_NOT_ENOUGH_EFFECT,
        DISCUSS_REFRIDGERATION, FELLOW_EYE_START, FOLLOW_UP_EVERY, 
        IF_SIDE_EFFECTS, CANCEL_TEXT, FOLLOW_UP_TEXT } = localizedText;
    const dispatch = useCare1AppDispatch();
    const [ form ] = Form.useForm();

    const allergies = useCare1AppSelector(store => store.examData.allergies);
    const allergiesNone = useCare1AppSelector(store => store.examData.allergies_none);
    const patientGpFaxNumber = useCare1AppSelector(store => store.patientDetails.details.gp_fax_number);
    const initiateTreatmentVariables = useCare1AppSelector(store => store.initiateTreatment);
    const isInitiateTreatmentModalOpen = useCare1AppSelector(store => store.initiateTreatment.isInitialTreatmentModalOpen);
    const treatment_drop = useCare1AppSelector(store => store.initiateTreatment.treatment_drop);
    const treatment_fu = useCare1AppSelector(store => store.initiateTreatment.treatment_fu);
    const treatment_iop_od = useCare1AppSelector(store => store.initiateTreatment.treatment_iop_od);
    const treatment_iop_od_custom = useCare1AppSelector(store => store.initiateTreatment.treatment_iop_od_custom);
    const treatment_iop_os = useCare1AppSelector(store => store.initiateTreatment.treatment_iop_os);
    const treatment_iop_os_custom = useCare1AppSelector(store => store.initiateTreatment.treatment_iop_os_custom);
    const side_effect_drop = useCare1AppSelector(store => store.initiateTreatment.side_effect_drop);
    const alternate_treatment_drop = useCare1AppSelector(store => store.initiateTreatment.alternate_treatment_drop);
    const alternate_treatment_fu = useCare1AppSelector(store => store.initiateTreatment.alternate_treatment_fu);
    const alternate_treatment_fu_custom = useCare1AppSelector(store => store.initiateTreatment.alternate_treatment_fu_custom);
    const treatment_success_drop = useCare1AppSelector(store => store.initiateTreatment.treatment_success_drop);
    const treatment_success_fu = useCare1AppSelector(store => store.initiateTreatment.treatment_success_fu);
    const treatment_success_fu_custom = useCare1AppSelector(store => store.initiateTreatment.treatment_success_fu_custom);
    const randomSentences = useCare1AppSelector(store => store.initiateTreatment.randomSentences);

    const omdOd = useCare1AppSelector(store => store.examData.omd_od);
    const omdGp = useCare1AppSelector(store => store.examData.omd_gp);
    const omdTextbox0 = useCare1AppSelector(store => store.examData.omd_textbox0);
    const examId = useCare1AppSelector(store => store.examData.id);
    const selectedOmdNoteOptions = useCare1AppSelector(store => store.examData.selected_omd_note_options);
    const cuOmd = useCare1AppSelector(store => store.examData.cu_omd);
    const examIsRetinaOnly = useCare1AppSelector(store => store.examData.exam_is_retina_only);

    const emptyEffectiveFollowUp = treatment_success_fu === 'custom' && treatment_success_fu_custom === '';

    const emptyNotEnoughEffectFollowUp = alternate_treatment_fu === 'custom' && alternate_treatment_fu_custom === '';

    const comorbiditiesText = dispatch(getComorbiditiesText());
    const allergiesText = allergiesNone || !allergies ? 'None' : allergies;
    const treatmentMonitorText = "We will let the family physician know that this is a patient that we may be starting " +
            "glaucoma topical therapy on, and that we will continue to monitor over telemedicine. Ongoing management " +
            "of glaucoma depends on high compliance with medications, in addition to regular diagnostic testing to " +
            "detect disease progression.";
    const PGBBText = "We will let the family physician know that we may make a change to the patient's drop " +
                     "regimen. We will continue to monitor over telemedicine. Effective management of glaucoma " +
                     "relies on high compliance with medications, in addition to regular diagnostic testing.";
    const AlertSelectorId = 26;
    const GpLtrId = 11;

    // Use custom classnames so scss can set the red borders on the IOP Zone inputs
    // immediately when the modal opens.
    const iopZoneODClassName = useMemo(() => {
        return !treatment_iop_od_custom ? 'iop-zone-od required' : 'iop-zone-od';
    }, [treatment_iop_od_custom]);
    const iopZoneOSClassName = useMemo(() => {
        return !treatment_iop_os_custom ? 'iop-zone-os required' : 'iop-zone-os';
    }, [treatment_iop_os_custom]);

    const getRandomSentence = (key: keyof IRandomSentences) => {
        let finalSentence = '';

        if (key in randomSentences && randomSentences[key]) {
            let baseSentence = randomSentences[key];

            // Get variables from the base sentence, so we can replace them with user-selected values.
            const variableMatchRegex = /\(\((.*?)\)\)/g;
            let variables = baseSentence.match(variableMatchRegex);

            if (variables && variables.length > 0) {
                // Go through each variable and try to resolve it.
                variables.forEach(variableItem => {
                    // See if the variable has a corresponding value in initiate treatment.
                    const variable = variableItem.replace('((', '').replace('))', '') as keyof IInitiateTreatment;

                    if (variable in initiateTreatmentVariables) {
                        let variableValue: keyof IRandomSentences | 'custom'= initiateTreatmentVariables[variable] as keyof IRandomSentences | 'custom';

                        // For custom values, get the actual custom value instead.
                        if (variableValue === 'custom') {
                            const customVariable = variable + '_custom' as keyof IInitiateTreatment;
                            variableValue = initiateTreatmentVariables[customVariable] as keyof IRandomSentences;
                        }

                        let variableLabel = ((variableValue in randomSentences) ?
                                                randomSentences[variableValue] : variableValue) || variableValue;

                        baseSentence = baseSentence.replace(variableItem, variableLabel);
                    }
                })
            }

            finalSentence = baseSentence;
        }

        return finalSentence;
    };

    const onFinish = () => {
        let treatmentFollowupText = TREATMENT_FOLLOW_UP_OPTIONS.find(entry=>entry.value===treatment_fu)?.label;
        const treatmentIopOdText = getCustomizableSelectionText(treatment_iop_od, treatment_iop_od_custom,
            TREATMENT_IOP_OPTIONS);
        const treatmentIopOsText = getCustomizableSelectionText(treatment_iop_os, treatment_iop_os_custom,
            TREATMENT_IOP_OPTIONS);

        if (treatmentFollowupText && treatmentIopOdText && treatmentIopOsText) {
            const startDropSentence = getRandomSentence('start_drop_sentence');
            const startIopSentence = getRandomSentence('start_iop_zone_sentence');
            const startFuSentence = getRandomSentence('start_fu_sentence');
            const sideEffectsDropSentence = getRandomSentence('side_effects_drop_sentence');
            const notEffectiveDropSentence = getRandomSentence('not_effective_drop_sentence');
            const notEffectiveFuSentence = getRandomSentence('not_effective_fu_sentence');
            const effectiveDropSentence = getRandomSentence('effective_drop_sentence');
            const effectiveFuSentence = getRandomSentence('effective_fu_sentence');
            const refrigerationArtificialTearsSentence = getRandomSentence('refrigeration_or_artificial_tears_sentence');

            const treatmentText = startDropSentence + ' ' + startIopSentence + ' ' + startFuSentence + ' ' +
                sideEffectsDropSentence + ' ' + refrigerationArtificialTearsSentence + ' ' + notEffectiveDropSentence +
                ' ' + notEffectiveFuSentence + ' ' + effectiveDropSentence + ' ' + effectiveFuSentence;

            // Fill OMD-OD notes.
            let omdOdText = '';
            if (omdOd) {
                omdOdText = omdOd + ' ';
            }

            // Fill OMD-GP notes.
            let omdGpText = '';
            if (omdGp) {
                omdGpText = omdGp + ' ';
            }

            if (alternate_treatment_drop === 'pg_bb') {
                omdGpText += PGBBText;
            }
            else {
                omdGpText += treatmentMonitorText;
            }

            // Shoot into OMDR textbox 0 the text regarding topical and SLT if SLT options checked.
            const currentDate = new Date();
            const month = currentDate.toLocaleString('default', { month: 'long' });
            const topicalDateSentence = getRandomSentence('topical_date_sentence') + month + ', ' +
                currentDate.getFullYear() + '.';
            const sltDateSentence = getRandomSentence('slt_date_sentence') + month + ', ' +
                currentDate.getFullYear() + '.';

            let textbox0Text = topicalDateSentence;
            if (omdTextbox0) {
                textbox0Text = omdTextbox0 + ' ' + topicalDateSentence;
            }

            if (alternate_treatment_drop === 'add_slt') {
                textbox0Text += ' ' + sltDateSentence;
            }

            // Convert easy treatment initiation F/U values into regular F/U values, which are always month based.
            // Then update the easy treatment IOP and F/U zone variables.
            switch(treatmentFollowupText) {
                case '1-2d':
                    treatmentFollowupText = '0.03-0.07';
                    break;
                case '1-2w':
                    treatmentFollowupText = '0.25-0.5';
                    break;
                default:
                    treatmentFollowupText = treatmentFollowupText.replace('m', '');
                    break;
            }

            // Change all the values on the exam data that we calculated from above.
            dispatch(setExamDataValues({
                'omd_od': omdOdText + treatmentText,
                'omd_gp': omdGpText,
                'patient_status': 'BT',
                'omd_textbox0': textbox0Text,
                'od_treatment_iop_zone': treatmentIopOdText,
                'os_treatment_iop_zone': treatmentIopOsText,
                'treatment_fu_zone': treatmentFollowupText,
            }));

            // Also set the alert checkbox if needed.
            if (!selectedOmdNoteOptions.includes(AlertSelectorId)) {
                dispatch(setExamDataValue('selected_omd_note_options',
                    [...selectedOmdNoteOptions, AlertSelectorId]));
            }

            // Also check GP LTR box when treatment algorithm is shot in only if Current/Upcoming OMD checkbox is OFF
            //  AND there is a fax number AND the OD is integrated.
            if (cuOmd && patientGpFaxNumber && !examIsRetinaOnly
                && !selectedOmdNoteOptions.includes(GpLtrId)){
                dispatch(setExamDataValue('selected_omd_note_options',
                    [...selectedOmdNoteOptions, GpLtrId]));
            }

            // Set the timestamp for initiate treatment.
            examId && dispatch(initiateTreatmentTimestampRequest(examId));
        }

        // Close the dialog.
        dispatch(setInitiateTreatmentDataValue({ key: 'isInitialTreatmentModalOpen', value: false }));
    };

    const handleCancel = () => {
        dispatch(setInitiateTreatmentDataValue({ key: 'isInitialTreatmentModalOpen', value: false }));
    };

    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            'treatment_drop': treatment_drop,
            'treatment_fu': treatment_fu,
            'treatment_iop_od': treatment_iop_od,
            'treatment_iop_od_custom': treatment_iop_od_custom,
            'treatment_iop_os': treatment_iop_os,
            'treatment_iop_os_custom': treatment_iop_os_custom,
            'side_effect_drop': side_effect_drop,
            'alternate_treatment_drop': alternate_treatment_drop,
            'alternate_treatment_fu': alternate_treatment_fu,
            'alternate_treatment_fu_custom': alternate_treatment_fu_custom,
            'treatment_success_drop': treatment_success_drop,
            'treatment_success_fu': treatment_success_fu,
            'treatment_success_fu_custom': treatment_success_fu_custom,
        };
        form.setFieldsValue(newValidationFieldValues);

        // The component should validate on every render
        form.validateFields();
    }, [treatment_drop, treatment_fu, treatment_iop_od, treatment_iop_od_custom, treatment_iop_os,
        treatment_iop_os_custom, side_effect_drop, alternate_treatment_drop, alternate_treatment_fu,
        alternate_treatment_fu_custom, treatment_success_drop, treatment_success_fu, treatment_success_fu_custom, form]);

    // Always reset to default at the very beginning of the dialog.
    useEffect(() => {
        dispatch(resetInitiateTreatment());
    }, [dispatch]);

    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case 'treatment_fu':
                    case 'side_effect_drop':
                    case 'alternate_treatment_drop':
                    case 'treatment_success_drop':
                        dispatch(setInitiateTreatmentDataValue({ key: field.name[0], value: field.value}));
                        break;
                    case 'treatment_iop_od':
                    case 'treatment_iop_os':
                    case 'alternate_treatment_fu':
                    case 'treatment_success_fu':
                        dispatch(setInitiateTreatmentDataValue({ key: field.name[0], value: field.value}));

                        // Other than the specific custom selection, set the custom value to the regular value.
                        if (field.value !== 'custom') {
                            dispatch(setInitiateTreatmentDataValue({ key: field.name[0] + '_custom', value: field.value}));
                        }
                        break;
                    case 'treatment_iop_od_custom':
                    case 'treatment_iop_os_custom':
                    case 'alternate_treatment_fu_custom':
                    case 'treatment_success_fu_custom':
                        dispatch(setInitiateTreatmentDataValue({ key: field.name[0], value: field.value}));

                        // When the custom value is actively entered, always set the corresponding selection to "custom"
                        let fieldName = field.name[0];
                        dispatch(setInitiateTreatmentDataValue({ key: fieldName.replace('_custom', ''),
                            value: 'custom'}));
                        break;
                    case 'treatment_drop':
                        // Start drops changes the default value of other selections.
                        // This updates those other values.
                        if (field.value !== treatment_drop) {
                            dispatch(setInitiateTreatmentDataValue({ key: field.name[0], value: field.value}));

                            const defaultUpdatedValues = getDefaultForTreatmentDrops(field.value);
                            if (defaultUpdatedValues && Object.keys(defaultUpdatedValues).length) {
                                for (const [key, value] of Object.entries(defaultUpdatedValues)) {
                                    dispatch(setInitiateTreatmentDataValue({
                                        key: key,
                                        value,
                                    }));
                                }
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        });
    };

    return (
        <Modal
            open={isInitiateTreatmentModalOpen}
            closable={false}
            className='initiate-treatment-modal'
            title={TREATMENT_INITIATION_TEXT}
            footer={null}
            centered
        >
            <Form form={form} onFieldsChange={onFieldsChange} onFinish={onFinish}>
                <Row className='current-information-row'>
                    <Col>
                        <Row>
                            <label className={`info-display ${comorbiditiesText !== 'None' ? 'alert-level' : ''}`}>
                                Comorbidities: {comorbiditiesText}
                            </label>
                        </Row>
                        <Row>
                            <label className={`info-display ${allergiesText !== 'None' ? 'alert-level' : ''}`}>
                                Allergies: {allergiesText}
                            </label>
                        </Row>
                    </Col>
                </Row>
                <Row className='initiate-treatment-row'>
                    <span className='section-title'>{INITIATE_TREATMENT_TEXT}</span>
                    <Row className='option-sub-row'>
                        <Col span={5}>
                            <div className='section-label'>
                                <label>{START_TEXT}</label>
                            </div>
                        </Col>
                        <Col span={19}>
                            <Form.Item className={'treatment-drop'} colon={false} help=""
                                name={'treatment_drop'}>
                                <Radio.Group
                                    className={'treatment-drops'}
                                    buttonStyle='solid'
                                    size='small'
                                >
                                    {
                                        TREATMENT_DROP_OPTIONS.map((startDrop, idx) =>
                                            <Radio.Button key={idx} value={startDrop.value}>
                                                {startDrop.label}
                                            </Radio.Button>)
                                    }
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className='option-sub-row'>
                        <Col span={5}>
                            <div className='section-label'>
                                <label>Follow Up</label>
                            </div>
                        </Col>
                        <Col span={19}>
                            <Form.Item className={'treatment-followup'} colon={false} help=""
                                name={'treatment_fu'}>
                                <Radio.Group
                                    className={'treatment-followup'}
                                    buttonStyle='solid'
                                    size='small'
                                >
                                    {
                                        TREATMENT_FOLLOW_UP_OPTIONS.map((followUp, idx) =>
                                            <Radio.Button key={idx} value={followUp.value}>
                                                {followUp.label}
                                            </Radio.Button>)
                                    }
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className='option-sub-row'>
                        <Col span={5}>
                            <div className='section-label'>
                                <label>{IOP_ZONE_TX}</label>
                            </div>
                        </Col>
                        <Col span={19}>
                            <Row>
                                <Col span={12}>
                                    <div className={iopZoneODClassName}>
                                        <Form.Item className={'treatment-iop-od'} colon={false}
                                            name={'treatment_iop_od'}
                                            initialValue={treatment_iop_od}
                                            label={`OD`}
                                            rules={
                                                [{ required: true, whitespace: true, }]
                                            }
                                        >
                                            <Radio.Group
                                                className={'treatment-iop-od'}
                                                buttonStyle='solid'
                                                size='small'
                                            >
                                                {
                                                    TREATMENT_IOP_OPTIONS.map((iopZone, idx) =>
                                                        <Radio.Button key={idx} value={iopZone.value}>
                                                            {iopZone.label}
                                                        </Radio.Button>)
                                                }
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item className={'treatment-iop-od-custom'} colon={false}
                                            name={'treatment_iop_od_custom'}>
                                            <InputNumber
                                                className={'treatment-iop-od-custom'}
                                                size='small'
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className={iopZoneOSClassName}>
                                        <Form.Item className={'treatment-iop-os'} colon={false}
                                            name={'treatment_iop_os'}
                                            initialValue={treatment_iop_os}
                                            label={`OS`}
                                            rules={
                                                [{ required: true, whitespace: true, }]
                                            }
                                        >
                                            <Radio.Group
                                                className={'treatment-iop-os'}
                                                buttonStyle='solid'
                                                size='small'
                                            >
                                                {
                                                    TREATMENT_IOP_OPTIONS.map((iopZone, idx) =>
                                                        <Radio.Button key={idx} value={iopZone.value}>
                                                            {iopZone.label}
                                                        </Radio.Button>)
                                                }
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item className={'treatment-iop-os-custom'} colon={false}
                                            name={'treatment_iop_os_custom'}>
                                            <InputNumber
                                                className={'treatment-iop-os-custom'}
                                                size='small'
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
                <Row className='if-side-effects-row'>
                    <span className='section-title'>{IF_SIDE_EFFECTS}</span>
                    <Row className='option-sub-row'>
                        <Col span={5}>
                            <div className='section-label'>
                                <label>Switch To</label>
                            </div>
                        </Col>
                        <Col span={19}>
                            <Form.Item className={'side-effect-drop'} colon={false} help=""
                                name={'side_effect_drop'}>
                                <Radio.Group
                                    className={'side-effect-drop'}
                                    buttonStyle='solid'
                                    size='small'
                                >
                                    {
                                        SIDE_EFFECT_DROP_OPTIONS.map((switchTo, idx) =>
                                            <Radio.Button
                                                key={idx}
                                                value={switchTo.value}
                                                disabled={!switchTo.required_drops.includes(treatment_drop)}
                                            >
                                                {switchTo.label}
                                            </Radio.Button>)
                                    }
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className='option-sub-row'>
                        <Col span={5}>
                            <div className='section-label discuss'>
                                <label>{`+ ${DISCUSS_REFRIDGERATION}`}</label>
                            </div>
                        </Col>
                        <Col span={19}/>
                    </Row>
                    <Row className='option-sub-row'>
                        <Col span={5}>
                            <div className='section-label discuss'>
                                <label>{`+ ${DISCUSS_ARTIFICIAL_TEARS}`}</label>
                            </div>
                        </Col>
                        <Col span={19}/>
                    </Row>
                </Row>
                <Row className='if-not-enough-effect-row'>
                    <span className='section-title'>{IF_NOT_ENOUGH_EFFECT}</span>
                    <Row className='option-sub-row'>
                        <Col span={5}>
                            <div className='section-label'>
                                <label>{SWITCH_TO_TEXT}</label>
                            </div>
                        </Col>
                        <Col span={19}>
                            <Form.Item className={'alternate-treatment-drop'} colon={false} help=""
                                name={'alternate_treatment_drop'}>
                                <Radio.Group
                                    className={'alternate-treatment-drop'}
                                    buttonStyle='solid'
                                    size='small'
                                >
                                    {
                                        ALTERNATE_TREATMENT_DROP_OPTIONS.map((switchTo, idx) =>
                                            <Radio.Button
                                                key={idx}
                                                value={switchTo.value}
                                                disabled={!switchTo.required_drops.includes(treatment_drop)}
                                            >
                                                {switchTo.label}
                                            </Radio.Button>)
                                    }
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className='option-sub-row'>
                        <Col span={5}>
                            <div className='section-label'>
                                <label>{FOLLOW_UP_TEXT}</label>
                            </div>
                        </Col>
                        <Col span={9} className={`${emptyNotEnoughEffectFollowUp ? 'initial-treatment-fu-inputs' : ''}`}>
                            <Form.Item className={'alternate-treatment-follow-up'} colon={false} help=""
                                name={'alternate_treatment_fu'}>
                                <Radio.Group
                                    className={'alternate-treatment-follow-up'}
                                    buttonStyle='solid'
                                    size='small'
                                >
                                    {
                                        ALTERNATE_TREATMENT_FOLLOW_UP_OPTIONS.map((followUp, idx) =>
                                            <Radio.Button key={idx} value={followUp.value}>
                                                {followUp.label}
                                            </Radio.Button>)
                                    }
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item className={'alternate-treatment-follow-up-custom'} colon={false} help=""
                                name={'alternate_treatment_fu_custom'}>
                                <Input
                                    className={'alternate-treatment-follow-up-custom'}
                                    size='small'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Row>
                <Row className='if-effective-row'>
                    <span className='section-title'>{IF_EFFECTIVE_TEXT}</span>
                    <Row className='option-sub-row'>
                        <Col span={5}>
                            <div className='section-label'>
                                <label>{FELLOW_EYE_START}</label>
                            </div>
                        </Col>
                        <Col span={19}>
                            <Form.Item className={'treatment-success-drop'} colon={false} help=""
                                name={'treatment_success_drop'}>
                                <Radio.Group
                                    className={'treatment-success-drop'}
                                    buttonStyle='solid'
                                    size='small'
                                >
                                    {
                                        TREATMENT_SUCCESS_DROP_OPTIONS.map((switchTo, idx) =>
                                            <Radio.Button key={idx} value={switchTo.value}>
                                                {switchTo.label}
                                            </Radio.Button>)
                                    }
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className='option-sub-row'>
                        <Col span={5}>
                            <div className='section-label'>
                                <label>{FOLLOW_UP_EVERY}</label>
                            </div>
                        </Col>
                        <Col span={10} className={`${emptyEffectiveFollowUp ? 'initial-treatment-fu-inputs' : ''}`}>
                            <Form.Item className={'treatment-success-follow-up'} colon={false} help=""
                                name={'treatment_success_fu'}>
                                <Radio.Group
                                    className={'treatment-success-follow-up'}
                                    buttonStyle='solid'
                                    size='small'
                                >
                                    {
                                        TREATMENT_SUCCESS_FOLLOW_UP_OPTIONS.map((followUp, idx) =>
                                            <Radio.Button key={idx} value={followUp.value}>
                                                {followUp.label}
                                            </Radio.Button>)
                                    }
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item className={'treatment-success-follow-up-custom'} colon={false}
                                name={'treatment_success_fu_custom'}>
                                <Input
                                    className={'treatment-success-follow-up-custom'}
                                    size='small'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Row>
                <Row className='buttons-row'>
                    <Col span={3} />
                    <Col span={6} className='left'>
                        <Button
                            className='shoot-in-button'
                            icon={<ImportOutlined />}
                            htmlType="submit"
                        >
                            Shoot-In
                        </Button>
                    </Col>
                    <Col span={6} />
                    <Col span={6} className='right'>
                        <Button
                            className='cancel-button'
                            onClick={handleCancel}
                            icon={<CloseCircleOutlined />}
                        >
                            {CANCEL_TEXT}
                        </Button>
                    </Col>
                    <Col span={3} />
                </Row>
            </Form>
        </Modal>
    )
}

export default PatientExamInitiateTreatmentModal;