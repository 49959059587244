import { Col, Flex, Tooltip } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import '../../../static/css/components/patient-exam-notes-to-moa.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import CheckableTag from 'antd/es/tag/CheckableTag';
import ComponentTextareaCard from '../component-textarea-card';
import classnames from 'classnames';
import checkableTagStyles from '../../../static/css/components/checkable-tag.module.scss';
import textareaCardstyles from '../../../static/css/components/component-textarea-card.module.scss';


const AdminPrivateNotes = () => {
   
    const adminPrivateNotesAddressed = useCare1AppSelector(store => store.examData.admin_private_notes_addressed);
    const adminPrivateNotes = useCare1AppSelector(store => store.examData.admin_private_notes);
    const examIsRE = useCare1AppSelector(store => store.examData.exam_is_retina_only);
    const isReferralLetterUploadPEI = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);

    const dispatch = useCare1AppDispatch();

    const heading = examIsRE || isReferralLetterUploadPEI ? 'APN': 'Admin Private Notes';

    const checkableTagClassNames = classnames({
        [checkableTagStyles.checkableTag]: true,
        [checkableTagStyles.checkableTagChecked]: adminPrivateNotesAddressed,
    })

    const HeadingComponent = () => (
        <>
            <Flex justify='space-between' align='center'>
                <Col span={examIsRE || isReferralLetterUploadPEI ? 12 : 15}>
                    <span className={textareaCardstyles.heading}>{heading}
                        <Tooltip placement='topLeft' title={'Admin Private Notes, Only visible to admins'}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </span>
                </Col>
                <Col span={examIsRE || isReferralLetterUploadPEI ? 12 : 9}>
                    <CheckableTag
                        checked={adminPrivateNotesAddressed}
                        onChange={(checked) => dispatch(setExamDataValue(
                            'admin_private_notes_addressed', checked
                        ))}
                        className={checkableTagClassNames}
                    >
                        Addressed
                    </CheckableTag>
                </Col>
            </Flex>
        </>
    )

    return (
        <ComponentTextareaCard
            disabled={false}
            title={<HeadingComponent />}
            testId='admin-private-notes'
            value={adminPrivateNotes}
            lightBlueBackground={true}
            handleChange={(e) => dispatch(
                setExamDataValue('admin_private_notes', e.target.value)
            )}
            />
    );
}

export default AdminPrivateNotes;