import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../static/css/components/admin-services-health-check-list-table.scss';
import { compareStrings } from '../../helpers/sorting';
import { localizedText } from '../../localizedText';


interface ICheckedServicesListTable {
    key: React.Key;
    id: string;
    'service_name': string,
    'health_status': string,
    'error_message': string,
    'invalid_message': string,
    'notification_status': string,
}

type AppProps = {
    tableList: ICheckedServicesListTable[],
    detail: {
        service_name: string,
    }
}

const AdminCheckedServicesTable = ({ tableList, detail }: AppProps) => {
    const { OF_TEXT, ITEMS_TEXT } = localizedText;

    const [scrollClass, setScrollClass] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const nameColumns: ColumnsType<ICheckedServicesListTable> = [
        {
            className: 'checked_at-column',
            title: 'Checked at',
            dataIndex: 'checked_at',
            sorter: (a, b) => compareStrings(`${a}`, `${b}`),
            render: (text, record) => <div data-testid={`'checked_at'-${record.key}`}>{text}</div>
        },
        {
            className: `health_status-column`,
            title: `Health status`,
            dataIndex: 'health_status',
            sorter: (a, b) => compareStrings(`${a}`, `${b}`),
            render: (text, record) => {
                if (text === 'DOWN') {
                    return <div className="status-red">{text}</div>
                }
                else if (text === 'INVALID') {
                    return <div className="status-yellow">{text}</div>
                }
                else {
                    return <div className="status-green">{text}</div>
                }
            }
        },
        {
            className: 'error_message-column',
            title: 'Error message',
            dataIndex: 'error_message',
            sorter: (a, b) => compareStrings(`${a}`, `${b}`),
            render: (text, record) => <div data-testid={`'error_message'-${record.key}`}>{text}</div>
        },
        {
            className: 'invalid_message-column',
            title: 'Invalid message',
            dataIndex: 'invalid_message',
            sorter: (a, b) => compareStrings(`${a}`, `${b}`),
            render: (text, record) => <div data-testid={`'invalid_message'-${record.key}`}>{text}</div>
        },
        {
            className: 'notification_status-column',
            title: 'Notification status',
            dataIndex: 'notification_status',
            sorter: (a, b) => compareStrings(`${a}`, `${b}`),
            render: (text, record) => {
                if (text === 'FAILED') {
                    return <div className="status-red">{text}</div>
                }
                else if (text === 'SENT') {
                    return <div className="status-green">{text}</div>;
                }
                else {
                    return <div>{text}</div>;
                }
            }
        }
    ];

    const renderTitle = () => {
        return (
            <div className='title-container'>
                <span className='title'>{detail.service_name}</span>
            </div>);
    }

    return (
        <div className={'services-health-check-list-table'}>
            <div>{renderTitle()}</div>
            <div className={scrollClass}>
                <Table
                    columns={nameColumns}
                    dataSource={tableList}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 50,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                />
            </div>
        </div>
    );
}

export default AdminCheckedServicesTable;