import { useCare1AppSelector } from '../apps/care1-hooks';
import { Row, Col } from 'antd';
import * as Constants from '../constants';

// Components
import DeepMDBandOD from '../components/integrated/deepMdOd/deepmd-od-band';
import PatientExamAllergies from '../components/integrated/patient-exam-allergies';
import PatientExamBillingRules from '../components/integrated/patient-exam-billing-rules';
import PatientExamChiefComplaintIC from '../components/integrated/patient-exam-chief-complaint-ic';
import PatientExamComorbidities from '../components/integrated/patient-exam-comorbidities';
import PatientExamConversation from '../components/retina/patient-exam-conversation';
import PatientExamExtraImages from '../components/retina/patient-exam-extra-images';
import PatientExamFundus from '../components/retina/patient-exam-fundus';
import PatientExamGPReferred from '../components/retina/patient-exam-gp-referred';
import PatientExamHeader from '../components/retina/header/patient-exam-header';
import PatientExamImpressions from '../components/integrated/patient-exam-impressions';
import PatientExamIOPHistory from '../components/integrated/patient-exam-iop-history';
import PatientExamMedicalHistory from '../components/integrated/patient-exam-medical-history';
import PatientExamMetrics from '../components/integrated/metrics/patient-exam-metrics';
import PatientExamNotesHistory from '../components/integrated/patient-exam-notes-history';
import PatientExamOCTMacula from '../components/retina/patient-exam-oct-macula';
import PatientExamOCTRnfl from '../components/integrated/patient-exam-oct-rnfl';
import PatientExamODNotes from '../components/integrated/patient-exam-od-notes';
import PatientExamOMDHistory from '../components/integrated/patient-exam-omd-history';
import PatientExamOngoing from '../components/integrated/patient-exam-ongoing';
import PatientExamStereoViewer from '../components/integrated/patient-exam-stereo-viewer';
import PatientExamUntilYesterday from '../components/integrated/patient-exam-until-yesterday';
import PatientExamVF from '../components/integrated/patient-exam-vf';
import PatientExamVFImageHistory from '../components/image-histories/patient-exam-vf-image-history';
import PatientExamOCTRNFLImageHistory from '../components/image-histories/patient-exam-oct-rnfl-image-history';
import PatientExamOCTMaculaImageHistory from '../components/image-histories/patient-exam-oct-macula-image-history';
import PatientExamFundusImageHistory from '../components/image-histories/patient-exam-fundus-image-history';
import PatientExamGlaucomaScore from '../components/integrated/patient-exam-glaucoma-score';

// Helpers
import { patientIsGlaucoma } from '../helpers/diagnosis-convert';
import '../../static/css/pages/ic-patient-exam-page.scss';

// Actions
import { localizedText } from '../localizedText'
import IopBcvaGraphBand from '../components/integrated/iop-bcva-graph-band';


const ICLegacyExamPage = () => {
    const { PHOTO_DROP_TEXT_SHORT } = localizedText;
    const diagnosisEntries = useCare1AppSelector(store => store.diagnosis.entries);
    const examStatusPrevious = useCare1AppSelector(store => store.examData.exam_status_previous);
    const glc = useCare1AppSelector(store => store.examData.rr_glc);
    const glcS = useCare1AppSelector(store => store.examData.rr_glc_suspect);
    const narrowAngles = useCare1AppSelector(store => store.examData.rr_narrow_angles);
    const examPrereviewDateAfterCutoff = useCare1AppSelector(store => store.examData.exam_prereview_date_after_cutoff);
    const examStatus = useCare1AppSelector(store => store.examData.exam_status);
    const isPrereviewed = useCare1AppSelector(store => store.examData.is_pre_reviewed);

    const isGlaucoma = patientIsGlaucoma(diagnosisEntries, {glc, glcS, narrowAngles});

    // RQ: If exam status is ready or reviewed, then components should be ready only.
    const isOMDReadyOrReviewed = examStatusPrevious === 'ready' || examStatusPrevious === 'reviewed';

    return (
        <div>
            <Row>
                <PatientExamHeader />
            </Row>
            <Row gutter={8}>
                <Col span={6} className="dropzones-container">
                    <PatientExamFundus
                        tabbed
                        disabled={isOMDReadyOrReviewed}
                        className="patient-exam-fundus"
                        side={Constants.RIGHT_SIDE_TEXT}
                    />
                    <Row gutter={8}>
                        <Col span={12}>
                            <PatientExamOCTMacula
                                dropText={PHOTO_DROP_TEXT_SHORT}
                                disabled={isOMDReadyOrReviewed}
                                side={Constants.RIGHT_SIDE_TEXT}
                                tabbed={true}
                            />
                            <PatientExamOCTRnfl
                                disabled={isOMDReadyOrReviewed}
                                side={Constants.RIGHT_SIDE_TEXT}
                            />
                        </Col>
                        <Col span={12}>
                            <PatientExamVF disabled={isOMDReadyOrReviewed} side={Constants.RIGHT_SIDE_TEXT} />
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row gutter={8}>
                        <Col span={14}>
                            <Row>
                                <PatientExamGPReferred
                                    disabled={isOMDReadyOrReviewed}
                                    className="patient-exam-gp-referred"
                                />
                            </Row>
                            <Row>
                                <PatientExamChiefComplaintIC disabled={isOMDReadyOrReviewed} />
                            </Row>
                        </Col>
                        <Col span={10}>
                            <PatientExamOMDHistory disabled={isOMDReadyOrReviewed}/>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={10}>
                            <PatientExamMedicalHistory disabled={isOMDReadyOrReviewed} />
                        </Col>
                        <Col span={10}>
                            <PatientExamNotesHistory disabled={isOMDReadyOrReviewed} />
                        </Col>
                        <Col span={4}>
                            <PatientExamAllergies disabled={isOMDReadyOrReviewed} />
                        </Col>
                    </Row>
                    <Row>
                        <PatientExamMetrics disabled={isOMDReadyOrReviewed} />
                    </Row>
                    <Row gutter={8}>
                        <Col span={9}>
                            <PatientExamImpressions disabled={isOMDReadyOrReviewed} />
                        </Col>
                        <Col span={15}>
                            <Row>
                                <PatientExamODNotes disabled={isOMDReadyOrReviewed} />
                            </Row>
                            <Row>
                                <PatientExamBillingRules disabled={isOMDReadyOrReviewed} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={6} className="dropzones-container">
                    <PatientExamFundus
                        tabbed
                        disabled={isOMDReadyOrReviewed}
                        className="patient-exam-fundus"
                        side={Constants.LEFT_SIDE_TEXT}
                    />
                    <Row gutter={8}>
                        <Col span={12}>
                            <PatientExamOCTMacula
                                dropText={PHOTO_DROP_TEXT_SHORT}
                                disabled={isOMDReadyOrReviewed}
                                side={Constants.LEFT_SIDE_TEXT}
                                tabbed={true}
                            />
                            <PatientExamOCTRnfl
                                disabled={isOMDReadyOrReviewed}
                                side={Constants.LEFT_SIDE_TEXT}
                            />
                        </Col>
                        <Col span={12}>
                            <PatientExamVF disabled={isOMDReadyOrReviewed} side={Constants.LEFT_SIDE_TEXT} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {isGlaucoma &&
            <Row gutter={8}>
                <Col span={6} className="pei-iop-history-col">
                    <PatientExamIOPHistory disabled={isOMDReadyOrReviewed} />
                </Col>
                <Col span={6} className="pei-until-yesterday-col">
                    <PatientExamUntilYesterday disabled={isOMDReadyOrReviewed} />
                </Col>
                <Col span={6} className="pei-ongoing-col">
                    <PatientExamOngoing disabled={isOMDReadyOrReviewed} />
                </Col>
                <Col span={6} className="pei-comorbidities-col">
                    <PatientExamComorbidities disabled={isOMDReadyOrReviewed} />
                </Col>
            </Row>
            }

            {/* DeepMD is now available for all IC OD users if exam is ready or reviewed */}
            { isOMDReadyOrReviewed && <DeepMDBandOD /> }
            
            {/* START |PatientExamGlaucomaScore Band */}
            { isPrereviewed &&
            <Row gutter={8}>
                <PatientExamGlaucomaScore />
            </Row>
            }
            {/* END | PatientExamGlaucomaScore  Band */}

            { ((examStatus ==='reviewed') || examPrereviewDateAfterCutoff) && 
                <PatientExamStereoViewer inGlaucomaWorksheet={false} />
            }
            <PatientExamExtraImages
                disabled={false}
                className="patient-exam-extra-images"
            />
            <IopBcvaGraphBand />
            <Row gutter={8}>
                <Col span={24}>
                    <PatientExamConversation />
                </Col>
            </Row>
            {/* START | Section 10 - VF History band */}
                <Row gutter={8}>
                    <PatientExamVFImageHistory />
                </Row>
            {/* END | Section 10 - VF band */}

            {/* START | Section 11 - RNFL History band */}
                <Row gutter={8}>
                    <PatientExamOCTRNFLImageHistory />
                </Row>
            {/* END | Section 11 - RNFL History band */}

            {/* START | Section 12 - Macula History band*/}
                <Row gutter={8}>
                    <PatientExamOCTMaculaImageHistory orderBy={'asc'} showHint={true} showBaselineBorder={true} showEmptyRow={true} />
                </Row>
            {/* END | Section 12 - Macula History band */}
            {/* START |  Section 13 - Fundus History band */}
                <Row gutter={8}>
                    <PatientExamFundusImageHistory />
                </Row>
            {/* END | Section 13 - Fundus History band */}
            <Row>
                <br />
                <br />
            </Row>
        </div>
                   
    );
}

export default ICLegacyExamPage;