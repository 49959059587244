import { Select, Modal } from 'antd'
import { BACKEND_EXAM_STATUS_FIELD, CONFIRMATION_CANCEL_TEXT, CONFIRMATION_OK_TEXT, EXAM_STATUS_OD_CAN_SET,
    EXAM_STATUS_OPTIONS, REACT_DROPDOWN_LABEL, REACT_DROPDOWN_VALUE, READY_FOR_OMD_CONFIRMATION_TITLE,
    READY_FOR_OMD_CONTENT_TEXT } from '../../../constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import { isOptometristReadOnly } from '../../../helpers/user-type-check';
import { patientIsRetina } from '../../../helpers/diagnosis-convert';
import { lengthEqual } from '../../../reducers/patient-exam-slice';
import { shallowEqual } from 'react-redux';
import { useFormValidation } from '../../../context/useFormValidationContext';
import { useLazyCheckPreviousExamStatusQuery, useSetPreviousExamsReadyMutation } from '../../../services/exam-api';
import { getPatientDetailsRequest } from '../../../reducers/patient-details-slice';
import { setExamDataValue } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import dayjs from 'dayjs';

const { Option } = Select;

const fieldMap = {
    'fu_number': 'Pt is returning for follow-up in',
    'other_omd': 'Pt currently also sees an OMD',
    'refer_in_person_omd_appt': 'Care1 to refer pt to in-person OMD',
    'diagnosisEntries': 'Chief Complaint',
    'ongoing': 'Ongoing',
    'until_yesterday': 'Until Yesterday',
    'applanation': 'Instrument',
}

type errorType = {
    errors: string[],
    name: string[],
    warning: string[]
}

const PatientExamHeaderExamStatus = ({ disabled }: {disabled: boolean}) => {

    const statusOptions = useCare1AppSelector(store=> store.examData[EXAM_STATUS_OPTIONS], lengthEqual);
    const isOD = useCare1AppSelector(store => store.user.isOD);
    const isADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const examStatusesCanSet = useCare1AppSelector(store => store.examData[EXAM_STATUS_OD_CAN_SET], shallowEqual);
    const examStatus = useCare1AppSelector(store => store.examData[BACKEND_EXAM_STATUS_FIELD]);
    const leftOCTMacula = useCare1AppSelector(store => store.examData.photoUrls.left_oct_photo);
    const rightOCTMacula = useCare1AppSelector(store => store.examData.photoUrls.right_oct_photo);
    const examId = useCare1AppSelector(store => store.examData.id);
    const patientId = useCare1AppSelector(store => store.patientDetails.details.id);
    const isReferralLetterUploadPEI = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const isReviewProcessed = useCare1AppSelector(store => store.examData.is_review_processed);
    const examDate = useCare1AppSelector(store => store.examData.exam_date);

    const examStatusOptions = isReferralLetterUploadPEI ? 
        isOD ? 
        statusOptions.map((option) => 
            option.value === 'od_review' ? {
                ...option,
                label: 'Submitted'
            } : (option.value === 'ready' ? {
                ...option,
                label: 'Submitted'
            } : (option.value === 'not_ready' ? {
                ...option,
                label: 'Not Submitted'
            } : (option.value === 'reviewed' ? {
                ...option,
                label: 'Reviewed'
            } : option))))
        : statusOptions.map((option) => 
            option.value === 'od_review' ? {
                ...option,
                label: 'Submitted to Care1'
            } : (option.value === 'not_ready' ? {
                ...option,
                label: 'Not Ready for Care1'
            } : option))
        : statusOptions;

    // RLU ODs do not get the review processed restriction.
    const nonAdminExamOptions = isReviewProcessed && !(isOD && isReferralLetterUploadPEI) ?
        examStatusOptions.filter(option => option.value !== 'reviewed').concat({value:'reviewed', label: 'Review Processed'}) :
        examStatusOptions;

    const adminExamOptions = examStatusOptions.concat({value: 'review_processed', label: 'Review Processed'});

    

    const examStatusOption =  examStatusOptions && examStatusOptions.find(status => status.value === examStatus);

    const [checkPreviousExamStatus] = useLazyCheckPreviousExamStatusQuery();
    const [setPreviousExamsReady] = useSetPreviousExamsReadyMutation();

    const dispatch = useCare1AppDispatch();
    const { untilYesterdayForm, ongoingForm, comorbiditiesForm, billingRulesForm, odSelectForm,
        instrumentForm, chiefComplaintForm } = useFormValidation();
    

    // If a new left or right OCT Macula has not been uploaded then open the AI information modal to encourage
    // the user to upload an OCT Macula photo
    const checkIfOCTMaculaIploaded = (value: string) => {
        const ptIsRetina = dispatch(patientIsRetina());
        // If there is no photo uploaded and the patient has a Retina disease
        if ((!leftOCTMacula || !rightOCTMacula) && ptIsRetina && value !== examStatus
            && (value === 'od_review' || value === 'ready')) {
            Modal.confirm({
                className: 'upload-oct-macula-modal',
                title: 'Upload OCT Macula for AI Development',
                content: `Care1 DeepMD is currently developing Machine Learning algorithms for detecting macular \
                    conditions. Every time you upload a normal or abnormal OCT Macula you are making a valuable \
                    contribution to this project. Thank you for partnering with us in one of many new horizons of \
                    AI driven patient care`,
                icon: <InfoCircleOutlined />,
                width: 600,
                okText: 'Continue to Set Exam Status',
                cancelText: 'Go Back and Upload OCT Macula',
                cancelButtonProps: {
                    className: 'upload-oct-button',
                },
                onOk: () => {
                    handleStatusChange(value);
                },
            });

        } else {
            handleStatusChange(value);
        }
    }

    const handleSelectExamStatus = (key: string) => {
        if (isADMIN) {
            if (key === 'review_processed') {
                dispatch(setExamDataValue(BACKEND_EXAM_STATUS_FIELD, 'reviewed'));
                dispatch(setExamDataValue('is_review_processed', true));
            } else {
                dispatch(setExamDataValue(BACKEND_EXAM_STATUS_FIELD, key));
            }
        } else {
            dispatch(setExamDataValue(BACKEND_EXAM_STATUS_FIELD, key));
        }
    }

    const handlePreviousExamStatus = async (selectedExamStatus: string) => {
        const res = await checkPreviousExamStatus(examId!).unwrap();
        if (res.success) {
            // If there are previous exam statuses, pop up a dialog.
            // If not, just perform a normal exam status set.
            if (res.exams.length > 0) {
                const examTotal = res.exams.length;
                const dateText = res.exams.reduce((text, exam) => `${text} ${exam.exam_date}`, '');
                const warningText = `You have ${examTotal} older visits (${dateText}) that are not set to Ready for OMD. Would you like to set those exams to Ready for OMD now?`;
                Modal.confirm({
                    className: 'ready-omd-confirmation-modal',
                    content: warningText,
                    icon: <InfoCircleOutlined />,
                    okText: CONFIRMATION_OK_TEXT,
                    cancelText: CONFIRMATION_CANCEL_TEXT,
                    cancelButtonProps: {
                        className: 'confirm-exit',
                    },
                    onOk: async () => {
                        const previousExamIds = res.exams.map(exam => exam.id);
                        const response = await setPreviousExamsReady(previousExamIds).unwrap();
                        if (response.success) {
                            dispatch(setExamDataValue(BACKEND_EXAM_STATUS_FIELD, selectedExamStatus));
                            patientId && dispatch(getPatientDetailsRequest(patientId));
                        }
                    },
                });
            }
            else {
                dispatch(setExamDataValue(BACKEND_EXAM_STATUS_FIELD, selectedExamStatus));
            }
        }
    }

    const handleStatusChange = (value: string) => {

        // Only set anything if the value is actually changed.
        if (value !== examStatus) {
            if (isOD && value === 'ready' && dayjs().isBefore(dayjs(examDate)) ) {
                 // Check whether today is before exam date, an exam with a future date cannot be submitted
                 Modal.error({
                    className: 'info-modal',
                    title: `An exam with a future date cannot be submitted. Please submit this exam on or after ${dayjs(examDate).format('MMM DD YYYY')}`,
                })
            }
            else if (value === 'ready') {
                // When the Ready for OMD status is first selected, we need to validate the page, and
                // display a dialog with errors if the page is not valid.
                Promise.all([
                    untilYesterdayForm && untilYesterdayForm.validateFields(),
                    ongoingForm && ongoingForm.validateFields(),
                    comorbiditiesForm && comorbiditiesForm.validateFields(),
                    billingRulesForm && billingRulesForm.validateFields(),
                    odSelectForm && odSelectForm.validateFields(),
                    instrumentForm && instrumentForm.validateFields(),
                    chiefComplaintForm && chiefComplaintForm.validateFields(),
                ])
                .then(() => {
                    if (isOD) {
                        handlePreviousExamStatus(value);
                    }
                    else {
                        // In the case of a valid page, we still have a warning popup to go to the new status.
                        Modal.confirm({
                            className: 'ready-omd-confirmation-modal',
                            title: READY_FOR_OMD_CONFIRMATION_TITLE,
                            content: READY_FOR_OMD_CONTENT_TEXT,
                            icon: <InfoCircleOutlined />,
                            okText: CONFIRMATION_OK_TEXT,
                            cancelText: CONFIRMATION_CANCEL_TEXT,
                            cancelButtonProps: {
                                className: 'confirm-exit',
                            },
                            onOk: () => {
                                dispatch(setExamDataValue(BACKEND_EXAM_STATUS_FIELD, value));
                            },
                        });
                    }
                })
                .catch((e) => {
                    // We need to show the errors to the user in a popup.
                    console.error('error is ', e);

                    const missingFieldList = e?.errorFields?.map((field: errorType)=>{
                        return field.name[0]
                    });
                    const missingFieldListMessages = missingFieldList.map((field: string)=>fieldMap[field as keyof typeof fieldMap]);
                    const listElement = 
                        <div>
                            <p>Please fill out required field(s).</p>
                            <ul>
                                {missingFieldListMessages.map((msg: string)=><li key={msg}>{msg}</li>)}
                            </ul>
                        </div>

                    Modal.error({
                        className: 'info-modal',
                        title: 'Chart status cannot be set to “Ready for OMD” until all required fields ' +
                                'are completed.',
                        content: listElement
                    });
                });
            } else {
                dispatch(setExamDataValue(BACKEND_EXAM_STATUS_FIELD, value));
            }
        }
    }

    return (
        <>
            { (isOD && !isADMIN) &&
                <Select
                    data-testid='chart-status-select'
                    size="small"
                    className="patient-exam-chart-status-select"
                    disabled={disabled}
                    onChange={checkIfOCTMaculaIploaded}
                    value={examStatus}
                >
                    {
                        nonAdminExamOptions && nonAdminExamOptions.map((option) => (
                            <Option
                                data-testid={`chart-status-option-${option[REACT_DROPDOWN_VALUE]}`}
                                key={option[REACT_DROPDOWN_VALUE]}
                                value={option[REACT_DROPDOWN_VALUE]}
                                disabled={isOptometristReadOnly(isOD,
                                    (option[REACT_DROPDOWN_VALUE]).toString(),
                                    examStatusesCanSet)}
                            >
                                {option[REACT_DROPDOWN_LABEL].toUpperCase()}
                            </Option>
                        ))
                    }
                </Select>
            }
            { (!isOD && !isADMIN) &&
                <div data-testid='chart-status-select' className="patient-exam-header-content omdc-status">
                    <label className="patient-exam-omdc-status-select" data-testid='omdc-status-select'>
                        {examStatusOption && examStatusOption.label.toUpperCase()}
                    </label>
                </div>
            }
            { isADMIN &&
                <Select
                    data-testid='chart-status-select'
                    size="small"
                    className="patient-exam-chart-status-select"
                    onSelect={handleSelectExamStatus}
                    value={isReviewProcessed && examStatus === 'reviewed' ? 'review_processed' : examStatus}
                >
                    {
                        adminExamOptions && adminExamOptions.map((option) => (
                            <Option
                                data-testid={`chart-status-option-${option[REACT_DROPDOWN_VALUE]}`}
                                key={option[REACT_DROPDOWN_VALUE]}
                                value={option[REACT_DROPDOWN_VALUE]}
                            >
                                {option[REACT_DROPDOWN_LABEL].toUpperCase()}
                            </Option>
                        ))
                    }
                </Select>
            }
        </>
    )
}

export default PatientExamHeaderExamStatus;