import { useState, useEffect, ReactNode } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { CopyrightOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Col, Divider, Row, Carousel, Form, Modal } from 'antd';
import * as Constants from '../constants';
import { localizedText } from '../localizedText';
import care1Logo from '../../static/images/logo-care1.png';
import retinaEnabledLogo from '../../static/images/retina-enabled-logo2x.png';
import dryEyeEnabledLogo from '../../static/images/dry-eye-enabled-logo2x.png';
import integratedClinicLogo from '../../static/images/integrated-clinic-logo2x.png';
import returnArrow from '../../static/images/return-arrow.png';
import { userService } from '../services/user-service';
import { ILogin, login } from '../reducers/user-slice';
import '../../static/css/pages/_login-page.scss';


const FormItem = Form.Item;

const LoginPage = () => {
    const { LOGIN_BANNER_RETINA_ENABLED_TITLE, LOGIN_BANNER_RETINA_ENABLED_SUBTITLE, LOGIN_BANNER_DRY_EYE_ENABLED_TITLE,
        LOGIN_BANNER_DRY_EYE_ENABLED_SUBTITLE, LOGIN_BANNER_INTEGRATED_CLINICS_TITLE,
        LOGIN_BANNER_INTEGRATED_CLINICS_SUBTITLE, LOGIN_BANNER_OMD_CONSULTANT_TITLE, LOGIN_BANNER_OMD_REVIEWER_TITLE,
        LOGIN_HEADING_TEXT, LOGIN_INVITATION_TEXT, LOGIN_INVITATION_SUBJECT, LOGIN_OR_TEXT, LOGIN_MISSING_USER_MESSAGE,
        LOGIN_PLACEHOLDER_USERNAME, LOGIN_MISSING_PASSWORD_MESSAGE, LOGIN_PLACEHOLDER_PASSWORD, LOGIN_KEEP_ME,
        LOGIN_FORGOT_PASSWORD, LOGIN_TEXT, LOGIN_RETURN_TO_PRODUCT_MENU_TEXT, LOGIN_FOOTER_COMPANY_NAME,
        LOGIN_CAROUSEL_AVOCADO_TITLE, LOGIN_CAROUSEL_AVOCADO_SUBTEXT, LOGIN_CAROUSEL_RETINA_TITLE,
        LOGIN_CAROUSEL_RETINA_SUBTEXT, LOGIN_CAROUSEL_DRY_EYE_TITLE, LOGIN_CAROUSEL_DRY_EYE_SUBTEXT,
        LOGIN_CAROUSEL_INTEGRATED_TITLE, LOGIN_CAROUSEL_INTEGRATED_SUBTEXT } = localizedText;

    const userName = useCare1AppSelector(store => store.user.userName);
    const user = useCare1AppSelector(store => store.user);

    const [clinic, setClinic] = useState(userService.getLoginClinicFromCookie());

    // Redux and React Router Hooks
    const dispatch = useCare1AppDispatch();
    const history = useNavigate();
    const location = useLocation();

    const [ form ] = Form.useForm();

    useEffect(() => {
        let params = new URLSearchParams(location.search);
        let c = params.get("clinic");
        if (!c) {
            c = userService.getLoginClinicFromCookie();
        }
        userService.setLoginClinicToCookie(c);
        setClinic(c);
    }, [location.search])

    const onFinish = async (values: ILogin) => {
        const {userName, password, saveUserAsDefault} = values;
        try {
            const response = await dispatch(login({
                userName,
                password,
                saveUserAsDefault,
            } )).unwrap();
            if (response.success) {
                if (response.is_sales) {
                    history('/sales-dashboard');
                }
                else if (response.is_science_user) {
                    history('/science-dashboard');
                }
                else {
                    history(Constants.ROOT_URL);
                }
            } else {
                throw new Error(response?.error)
            }
        } catch (error){
            const message: ReactNode = (error instanceof Error) ?  error?.message : error as string;
            Modal.error({
                className: 'info-modal',
                content: message,
                title: Constants.USER_ERROR_MESSAGE_AREA,
            });
        }
    }

    const currentYear = new Date().getFullYear();

    const c = clinic;
    let clinicLogo = retinaEnabledLogo;
    let clinicLogoAlt = 'Retina Enabled Logo';
    let clinicTitle = LOGIN_BANNER_RETINA_ENABLED_TITLE;
    let clinicSubTitle = LOGIN_BANNER_RETINA_ENABLED_SUBTITLE;
    if (c === 'dryeye') {
        clinicLogo = dryEyeEnabledLogo;
        clinicLogoAlt = 'Dry Eye Enabled Logo';
        clinicTitle = LOGIN_BANNER_DRY_EYE_ENABLED_TITLE;
        clinicSubTitle = LOGIN_BANNER_DRY_EYE_ENABLED_SUBTITLE;
    }
    else if (c === 'ic') {
        clinicLogo = integratedClinicLogo;
        clinicLogoAlt = 'Inegrated Clinics Logo';
        clinicTitle = LOGIN_BANNER_INTEGRATED_CLINICS_TITLE;
        clinicSubTitle = LOGIN_BANNER_INTEGRATED_CLINICS_SUBTITLE;
    }

    if (user && user.isRetinaEnabled) {
        document.title = LOGIN_BANNER_RETINA_ENABLED_TITLE;
    }
    else if (user && user.isOMDC) {
        document.title = LOGIN_BANNER_OMD_CONSULTANT_TITLE;
    }
    else if (user && user.isOMDR) {
        document.title = LOGIN_BANNER_OMD_REVIEWER_TITLE;
    }
    else if (user && user.isOD) {
        document.title = LOGIN_BANNER_INTEGRATED_CLINICS_TITLE;
    }
    else {
        document.title = LOGIN_BANNER_RETINA_ENABLED_TITLE;
    }

    return (
        <Row className="login-page">
            <Col span={12} flex="1 1 auto" className="login-section">
                <div className="login-panel">
                    <div className="login-company-logo">
                        <a href={Constants.CARE1_HOMEPAGE}>
                            <img src={care1Logo} alt="Care1 Logo" />
                        </a>
                    </div>
                    <div className="login-heading">
                        <div className="login-heading-text">{LOGIN_HEADING_TEXT}</div>
                    </div>
                    <div className="login-invitation">
                        <span className="login-invitation-text">{LOGIN_INVITATION_TEXT}</span>
                        <a
                            href={`mailto:${Constants.LOGIN_INVITATION_EMAIL}?subject=${LOGIN_INVITATION_SUBJECT}`}
                        >
                            {Constants.LOGIN_INVITATION_EMAIL}
                        </a>
                    </div>

                    <Divider className="login-divider-top">
                        <span className="login-divider-text">{LOGIN_OR_TEXT}</span>
                    </Divider>

                    <div className="login-banner-product">
                        <Row>
                            <Col span={6}>
                                <img src={clinicLogo} alt={clinicLogoAlt} />
                            </Col>
                            <Col span={18}>
                                <Row>
                                    <div className="login-banner-product-title">
                                        {clinicTitle}
                                    </div>
                                    <div className="login-banner-product-subtitle">
                                        {clinicSubTitle}
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    <Form className="login-form" form={form} onFinish={onFinish} >
                        <FormItem
                            name='userName'
                            rules={[{ required: true, message: LOGIN_MISSING_USER_MESSAGE }]}
                            initialValue={userName}
                        >
                            <Input
                                className="login-form-input"
                                size="large"
                                placeholder={LOGIN_PLACEHOLDER_USERNAME}
                                autoComplete="username"
                                addonBefore={(
                                    <UserOutlined className="login-form-input-icon" />
                                )}
                            />
                        </FormItem>
                        <FormItem
                            className="login-input-password"
                            name='password'
                            rules={[{ required: true, message: LOGIN_MISSING_PASSWORD_MESSAGE }]}
                        >
                            <Input
                                className="login-form-input"
                                size="large"
                                type="password"
                                placeholder={LOGIN_PLACEHOLDER_PASSWORD}
                                autoComplete="current-password"
                                addonBefore={(
                                    <LockOutlined className="login-form-input-icon" />
                                )}
                            />
                        </FormItem>
                        <FormItem
                            className="login-keep-and-forgot"
                            name='saveUserAsDefault'
                            valuePropName='checked'
                            initialValue={true}
                        >
                            <Row>
                                <Col span={14} >
                                    <Checkbox className="login-form-remember"><span className="login-remember-text">{LOGIN_KEEP_ME}</span></Checkbox>
                                </Col>
                                <Col span={10} style={{ textAlign: 'right' }}>
                                    <a href={Constants.API_PASSWORD_RESET_URL}>
                                        <span className="login-forget-text">{LOGIN_FORGOT_PASSWORD}</span>
                                    </a>
                                </Col>
                            </Row>
                        </FormItem>
                        <FormItem>
                            <Button type="primary" size="large" htmlType="submit" className="login-form-button">
                                {LOGIN_TEXT}
                            </Button>
                        </FormItem>
                    </Form>

                    <Divider className="login-divider-bottom" />

                    <div className="login-return-to-product-menu">
                        <Row align="middle">
                            <img src={returnArrow} alt="Return arrow" />
                            <span className="login-return-message">
                                {LOGIN_RETURN_TO_PRODUCT_MENU_TEXT}
                            </span>
                        </Row>
                    </div>
                </div>
                <div className="login-form-footer">
                    <CopyrightOutlined className="copright-icon" />
                    <span>{currentYear}</span>
                    <span>{LOGIN_FOOTER_COMPANY_NAME}</span>
                </div>
            </Col>

            <Col span={12} flex="0 0 $login-carousel-width" className="carousel-section">
                <Carousel autoplay autoplaySpeed={3000} speed={700}>
                    <div className="carousel-section-product">
                        <div className="carousel-section-text">
                            <div className="carousel-section-image-title">{LOGIN_CAROUSEL_AVOCADO_TITLE}</div>
                            <div className="carousel-section-image-subtitle">{LOGIN_CAROUSEL_AVOCADO_SUBTEXT}</div>
                        </div>
                        <div className="gallery-image avocado" />
                    </div>
                    <div className="carousel-section-product">
                        <div className="carousel-section-text retina">
                            <div className="carousel-section-image-title">{LOGIN_CAROUSEL_RETINA_TITLE}</div>
                            <div className="carousel-section-image-subtitle">{LOGIN_CAROUSEL_RETINA_SUBTEXT}</div>
                        </div>
                        <div className="gallery-image retina" />
                    </div>
                    <div className="carousel-section-product">
                        <div className="carousel-section-text">
                            <div className="carousel-section-image-title dry-eye-title">{LOGIN_CAROUSEL_DRY_EYE_TITLE}</div>
                            <div className="carousel-section-image-subtitle">{LOGIN_CAROUSEL_DRY_EYE_SUBTEXT}</div>
                        </div>
                        <div className="gallery-image dryeye" />
                    </div>
                    <div className="carousel-section-product">
                        <div className="carousel-section-text integrated">
                            <div className="carousel-section-image-title">{LOGIN_CAROUSEL_INTEGRATED_TITLE}</div>
                            <div className="carousel-section-image-subtitle">{LOGIN_CAROUSEL_INTEGRATED_SUBTEXT}</div>
                        </div>
                        <div className="gallery-image integrated" />
                    </div>
                </Carousel>
            </Col>
        </Row>
    );
}

export default LoginPage;
