import { baseApi } from './base-api'

export interface IOctRnflNearMissListResponse {
    near_miss_list: [];
}

export interface IGptVisionChatIOctRnflNearMissListResponse {
    near_miss_list: {
        summary: {
            rows: [],
            days: [],
        }
    };
}

export const vfNearMissApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getOctRnflNearMissList: builder.query<IOctRnflNearMissListResponse, void>({
            query: () => 'admin/oct_rnfl_near_miss/list/',

            // Give a tag to staffs so that other API actions can invalidate IOctRnflNearMissList
            providesTags: [{type: 'OctRnflNearMissList', id: 'LIST'}]
        }),
        getOctRnflNearMissSummary: builder.query<IGptVisionChatIOctRnflNearMissListResponse, void>({
            query: () => 'admin/oct_rnfl_near_miss/summary/'
        }),
    }),
});



// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const {useGetOctRnflNearMissListQuery, useGetOctRnflNearMissSummaryQuery} = vfNearMissApi;