import { Fragment, useMemo, ReactNode } from 'react';
import { Card, Tabs } from 'antd';
import PatientExamVFOverride from '../reviewer/patient-exam-vf-override';
import PhotoDropzone from '../retina/photo-dropzone';
import { calculateVFBorderColor } from '../../helpers/patient-exam-convert';
import * as Constants from '../../constants';
import '../../../static/css/components/patient-exam-vf.scss';
import { lengthEqual } from '../../reducers/patient-exam-slice';
import { localizedText } from '../../localizedText';
import { useCare1AppSelector } from '../../apps/care1-hooks';

type vfItem = {
    label: string, 
    type: string, 
    key: string, 
    children: ReactNode,
}

const PatientExamVF = ({ disabled, side }: { disabled: boolean, side: string }) => {
    const { PHOTO_DROP_TEXT_SHORT, VF_TEXT, RIGHT_VF_PHOTO_TITLE_TEXT,
        LEFT_VF_PHOTO_TITLE_TEXT, } = localizedText;

    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const hasRightVF = useCare1AppSelector(store => store.examData.photoUrls.right_vf_photo);
    const hasLeftVF = useCare1AppSelector(store => store.examData.photoUrls.left_vf_photo);
    const isADMIN = useCare1AppSelector(store => store.user.isADMIN);

    // Border color is based on the 'ght' value
    const fieldName = side === 'right' ? 'right_ght' : 'left_ght';
    const sideGHT = useCare1AppSelector(store => store.examData[fieldName]);
    const borderColorClassname = ((userIsOMDC || userIsOMDR || isADMIN) && calculateVFBorderColor(sideGHT)) || '';

    const exams_with_left_vf = useCare1AppSelector(store => store.examData[Constants.EXAMS_WITH_LEFT_VF], lengthEqual);
    const exams_with_right_vf = useCare1AppSelector(store => store.examData[Constants.EXAMS_WITH_RIGHT_VF], lengthEqual);

    const sideHasImage = useMemo(() => {
        if(side === 'right'){
            return exams_with_right_vf.length > 0 || hasRightVF;
        } else if (side === 'left') {
            return exams_with_left_vf.length > 0 || hasLeftVF;
        }
    }, [exams_with_right_vf, exams_with_left_vf, hasRightVF, hasLeftVF, side]);

    const hasImageClassName = sideHasImage ? ' has-image' : ' does-not-have-image';

    // The tab for the FV 24-2 image
    const tabOne = () => {
        if(sideHasImage && userIsOMDR && !userIsRE){
            return (
                <Fragment>
                    <PhotoDropzone
                        disabled={disabled}
                        field={side === Constants.RIGHT_SIDE_TEXT ? Constants.RIGHT_VF_FIELD : Constants.LEFT_VF_FIELD}
                        label={side === Constants.RIGHT_SIDE_TEXT ? RIGHT_VF_PHOTO_TITLE_TEXT : LEFT_VF_PHOTO_TITLE_TEXT}
                        dropText={PHOTO_DROP_TEXT_SHORT}
                        formatText={Constants.SUPPORTED_FORMAT_TEXT}
                        carousel={side === Constants.RIGHT_SIDE_TEXT ? exams_with_right_vf : exams_with_left_vf }
                    />
                    <PatientExamVFOverride side={side} />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <PhotoDropzone
                        disabled={disabled}
                        field={side === Constants.RIGHT_SIDE_TEXT ? Constants.RIGHT_VF_FIELD : Constants.LEFT_VF_FIELD}
                        label={side === Constants.RIGHT_SIDE_TEXT ? RIGHT_VF_PHOTO_TITLE_TEXT : LEFT_VF_PHOTO_TITLE_TEXT}
                        dropText={PHOTO_DROP_TEXT_SHORT}
                        formatText={Constants.SUPPORTED_FORMAT_TEXT}
                        carousel={side === Constants.RIGHT_SIDE_TEXT ? exams_with_right_vf : exams_with_left_vf }
                    />
                </Fragment>
            )
        }
    }

    // The tab for the VF 10-2.
    // We will hide the VF 10-2 until the OMDR and APR UIs are completed
    // const tabTwo = () => {
    //     return (
    //         <PhotoDropzone
    //             disabled={disabled}
    //             field={side === Constants.RIGHT_SIDE_TEXT ? Constants.RIGHT_VF_10_2_FIELD : Constants.LEFT_VF_10_2_FIELD}
    //             label={side === Constants.RIGHT_SIDE_TEXT ? 'Right VF 10-2' : 'Left VF 10-2'}
    //             dropText={PHOTO_DROP_TEXT_SHORT}
    //             formatText={Constants.SUPPORTED_FORMAT_TEXT}
    //             carousel={side === Constants.RIGHT_SIDE_TEXT ? exams_with_right_vf : exams_with_left_vf }
    //         />
    //     )
    // }

    // List of tab items
    const tabsItems: vfItem[] = [
        { label: VF_TEXT, type:"card", key:"1", children: tabOne() },
        // The tab for the VF 10-2.
        // We will hide the VF 10-2 until the OMDR and APR UIs are completed
        // { label:"10-2", type:"card", key:"2", children: tabTwo() }
    ]

    return (
        <Card className={`patient-exam-vf${hasImageClassName} ${borderColorClassname}`} bordered={false}>
            <Tabs items={tabsItems} />
        </Card>
    );
}

export default PatientExamVF;