import { FullscreenOutlined } from '@ant-design/icons';
import { Card, Col, Modal, Row } from 'antd';
import ExtraImage from './extra-image';
import PhotoDropzoneExtraImage from './photo-dropzone-extra-image';
import * as Constants from '../../constants';
import '../../../static/css/components/_patient-exam-extra-images.scss';
import { lengthEqual } from '../../reducers/patient-exam-slice';
import { localizedText } from '../../localizedText';
import { viewAll } from '../../reducers/extra-image-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

type ComponentProps = {
    disabled: boolean,
    className: string,
}

const PatientExamExtraImages = ({ disabled, className }: ComponentProps) => {

    const { EXTRA_IMAGES_TEXT, VIEW_ALL_TEXT } = localizedText;
    const dispatch = useCare1AppDispatch();

    const extraImages = useCare1AppSelector(store => store.examData[Constants.EXTRA_IMAGES], lengthEqual);
    const showModal = useCare1AppSelector(store => store.extraImage.viewAll);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const imagesToDisplay = userIsOD || userIsAdmin ? 6 : 7;
    const examStatus = useCare1AppSelector(store => store.examData.exam_status);
    const isImageDropzoneDisabled = !['od_review', 'ready', 'reviewed'].includes(examStatus) && disabled;

    const getImageCards = (isModal = false) => {
        // Sort the images by their creation dates
        return [...extraImages].sort((a, b) => b.date - a.date)
            .map((image, index) => (
                    <Col key={index} span={isModal ? 4 : 3}>
                        <Card key={index} className="extra-image" bordered={false}>
                            <ExtraImage
                                isModal={isModal}
                                disabled={disabled}
                                image={image}
                                idx={index} />
                        </Card>
                    </Col>
                )
            );

    }

    return (
        <Card className={className} bordered={false}>
            <Modal
                className="view-all-modal"
                open={showModal}
                width="840"
                onCancel={() => { dispatch(viewAll(false)); }}
                closable
                footer={null}
            >
                <Row gutter={8}>
                    {getImageCards(true)}
                </Row>
            </Modal>
            <Row gutter={8}>
                { (userIsOD || userIsAdmin) &&
                    <Col span={3}>
                        <Card className="extra-image" bordered={false}>
                            <div className="heading">{EXTRA_IMAGES_TEXT}</div>
                            <PhotoDropzoneExtraImage disabled={isImageDropzoneDisabled} isODReadOnly={false} />
                        </Card>
                    </Col>
                }
                {getImageCards().slice(0, imagesToDisplay)}
                {getImageCards().length > imagesToDisplay
                    && (
                        <Col span={3}>
                            <Card className="extra-image view-all" bordered={false}>
                                <div className="heading">&nbsp;</div>
                                <button className="view-all-text" onClick={() => { dispatch(viewAll(true)); }}>
                                    <FullscreenOutlined className="fullscreen-icon" />
                                    <div className="text">
                                        {VIEW_ALL_TEXT}
                                    </div>
                                </button>
                            </Card>
                        </Col>
                    )}
            </Row>
        </Card>
    );
}

export default PatientExamExtraImages;
