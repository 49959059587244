import { InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Modal, Col, Row, Form } from 'antd';
import PatientEditForm from './patient-edit-form';
import '../../../static/css/components/_patient-edit-modal.scss';
import { clearPatientDetailsData, getPatientDetailsRequest, openPatientDetailsModal } from '../../reducers/patient-details-slice';
import { localizedText } from '../../localizedText';
import { EDIT_PATIENT_CONFIRMATION_TEXT, PATIENT_ID_FIELD } from '../../constants';
import { buildPatientFromState } from '../../helpers/patient-edit-convert';
import { clearPatientEditDetails, closeEditPatientModal, deactivatePatientRequest, editPatientRequest } from '../../reducers/patient-edit-slice';
import { getODPatientListRequest } from '../../reducers/patient-list-slice';
import PatientAddGPModal from './patient-add-gp-modal';
import PatientEditGPModal from './patient-edit-gp-modal';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { useNavigate } from 'react-router';

const PatientEditModal = () =>  {
    const { CANCEL_BUTTON_TEXT, DEACTIVATE_CONFIRMATION_CANCEL_TEXT, DEACTIVATE_CONFIRMATION_OK_TEXT, DELETE_BUTTON_TEXT,
        DEACTIVATE_CONFIRMATION_MODAL_TEXT, DEACTIVATE_PATIENT_CONFIRMATION_MODAL_TITLE, EDIT_PATIENT_TEXT,
        SAVE_BUTTON_TEXT } = localizedText;
    const isVisible = useCare1AppSelector(store => store.patientEdit.editModalOpen);
    const patientID = useCare1AppSelector(store => store.patientEdit.details[PATIENT_ID_FIELD]);
    const isRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const examId = useCare1AppSelector(store => store.examData.id);
    const patientDetails = useCare1AppSelector(store => store.patientDetails);
    const patientEditDetails = useCare1AppSelector(store => store.patientEdit.details);
    const examPatientId = useCare1AppSelector(store => store.examData.patient);

    const [ form ] = Form.useForm();

    const navigate = useNavigate();

    const dispatch = useCare1AppDispatch();

    const handleCancel = () => {
        dispatch(closeEditPatientModal());

        const inPEI = examId;

        // If in the PEI reopen the patient details modal. (This is user requested so that the user can perform other
        // actions on the patient without needing to search again for the patient again in the patient list page).
        if(!inPEI){
            dispatch(clearPatientDetailsData());
            dispatch(getPatientDetailsRequest(patientDetails.details.id!))
                .then(() => dispatch(openPatientDetailsModal()));
        }
    }

    const handleSave = () => {
        form.submit();
    }

    const onFinish = async () => {
        const editedPatient = buildPatientFromState(patientEditDetails);
        try {
            await dispatch(editPatientRequest({...editedPatient, patient_id: patientID })).unwrap();
            dispatch(closeEditPatientModal());

            // Currently, edit-patient-modal is used in patient-list page and exam-detail page. When a
            // patient is saved, its subsequent actions should be different depedning on where the user
            // opens the edit-patient-modal. If it is in exam page, the PEI header needs to be updated,
            // so the subsequent action shoulde be retrieve exam data(Patient details of PEI header is
            // saved in examData); if it is in patient-list page, the patient list needs to be updated,
            // so the subsequent action shoulde be get the filtered patient list
            const isInExamPage = examId;
            // if not in exam page, exam id is empty string, therefore, exam id is used to determine
            // which page the user is currently in
            if (isInExamPage) {
                // Show the success message in a dialog.
                dispatch(getPatientDetailsRequest(examPatientId!));

                // Show the success message in a dialog.
                Modal.info({
                    className: 'info-modal',
                    title: EDIT_PATIENT_CONFIRMATION_TEXT,
                });
            } else {
                // Reload the patient list
                dispatch(getODPatientListRequest());

                // If in the PEI reopen the patient details modal. (This is user requested so that the user can perform other
                // actions on the patient without needing to search again for the patient again in the patient list page).
                dispatch(clearPatientDetailsData());
                await dispatch(getPatientDetailsRequest(patientDetails.details.id!))
                dispatch(openPatientDetailsModal());

                // Show the success message in a dialog.
                Modal.info({
                    className: 'info-modal',
                    title: EDIT_PATIENT_CONFIRMATION_TEXT,
                });
            }

            // Clear the patient edit details store after the patient edit form modal is closed.
            dispatch(clearPatientEditDetails())

        } catch(error) {
            console.log('error is ', error);
        }
    }

    const deactivatePatient = async () => {
        try {
            const res = await dispatch(deactivatePatientRequest(patientID!)).unwrap();

            if (res?.success) {
                Modal.info({
                    className: 'info-modal',
                    title: `Patient successfully deactivated.`,
                });
                dispatch(closeEditPatientModal());
                dispatch(getODPatientListRequest());
                navigate('/');
            } else {
                throw new Error(res?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    const confirmDeactivate = () => {
        Modal.confirm({
            className: 'delete-confirmation-modal',
            title: DEACTIVATE_PATIENT_CONFIRMATION_MODAL_TITLE,
            content: DEACTIVATE_CONFIRMATION_MODAL_TEXT,
            icon: <InfoCircleOutlined />,
            okText: DEACTIVATE_CONFIRMATION_OK_TEXT,
            cancelText: DEACTIVATE_CONFIRMATION_CANCEL_TEXT,
            cancelButtonProps: {
                className: 'confirm-exit',
            },
            onOk: () => {
                deactivatePatient();
            },
        });
    }

    return (
        <Modal
            className={`edit-patient-modal ${!isRE && 'ic-edit-patient-modal'}`}
            open={isVisible}
            width="840"
            closable={false}
            footer={null}
        >
            <Row className="header-section" align="middle">
                <Col span={10}>
                    <div className="modal-title">
                        {EDIT_PATIENT_TEXT}
                    </div>
                </Col>
                <Col span={14} className={'buttons-column'}>

                    <Button
                        className="delete-patient-button"
                        onClick={(event) => {
                            event.preventDefault();
                            confirmDeactivate();
                        }}
                    >
                        {DELETE_BUTTON_TEXT}
                    </Button>

                    <Button
                        className="cancel-button"
                        onClick={handleCancel}
                    >
                        {CANCEL_BUTTON_TEXT}
                    </Button>

                    <Button
                        className="save-patient-button"
                        icon={<SaveOutlined />}
                        onClick={handleSave}
                    >
                        {SAVE_BUTTON_TEXT}
                    </Button>
                </Col>
            </Row>
            <PatientEditForm
                form={form}
                successAction={onFinish}
                patientID={patientID}
            />
            <PatientAddGPModal />
            <PatientEditGPModal />
        </Modal>
    );

}

export default PatientEditModal;