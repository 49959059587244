import { Dispatch, SetStateAction, useState } from 'react';
import { Button, DatePicker, Row, Select, Form } from 'antd';
import '../../../static/css/components/admin-reports-control-bar.scss';
import AdminReportsGenerateModal from './admin-reports-generate-modal';
import { IReportsFilter, useGetAdminUsersQuery, useLazyGetAdminReportsQuery } from '../../services/admin-reports-api';
import { FileTextOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const reportsOptions = [{
    label: 'Exam Visit Date', value: 'by_visit'
}, {
    label: 'Exam Create Date', value: 'by_create'
}, {
    label: 'OMDR Review Date', value: 'by_review'
}, {
    label: 'OMD Reviews', value: 'omd_reviews'
}, {
    label: 'Integration List', value: 'integration_list_report'
}, {
    label: 'Billing Report', value: 'billing_report'
}, {
    label: 'Pre Reviews Report', value: 'pre_reviews_report'
}, {
    label: 'Pre Reviews Time Report', value: 'pre_reviews_time_report'
}
]

type AppProps = {
    setFilter: Dispatch<SetStateAction<IReportsFilter>>
}

const AdminReportsControlBar = ({setFilter}: AppProps) => {

    const [getAdminReport] = useLazyGetAdminReportsQuery();
    const { data: userList } = useGetAdminUsersQuery();

    const [isVisible, setIsVisible] = useState<boolean>(false);

    const filterReportsTable = (values: IReportsFilter) => {

        const filterData = {
            ...values,
            start_date: values.start_date ? dayjs(values.start_date).format('YYYY-MM-DD') : '',
            end_date: values.end_date ? dayjs(values.end_date).format('YYYY-MM-DD'): '',
        }
        // only include non-empty filter
        const requestData = Object.entries(filterData).reduce(
            (object, [key,value]) => value ? {...object, [key]:value} : object, {});
        
        setFilter(requestData)
    }

    const [form] = Form.useForm();

    const clearFilter = () => {
        setFilter({});
        form.resetFields();

    }

    return (
        <Row className={'admin-reports-control-bar'} justify='space-between'>
            <Row>
                <Button 
                    type='primary'
                    onClick={() => setIsVisible(true)}
                    className='open-report-modal-button'
                >
                    Generate Reports
                </Button>
                <Button
                    type='primary'
                    onClick={() => getAdminReport({})}
                    className='refresh-report-table-button'
                >
                    Refresh Table
                </Button>
            </Row>
            <Row>
                <Row justify='space-between'>
                    <Form
                        form={form}
                        layout='inline'
                        onFinish={filterReportsTable}
                    >
                        <Form.Item name='name' initialValue={null}>
                            <Select
                                className={`dropdown-filter report-type-filter`}
                                popupClassName="patient-list-dropdown"
                                placeholder={<div><FileTextOutlined /> Report Type</div>}
                                options={reportsOptions}
                                showSearch
                                optionFilterProp='label'
                            />
                        </Form.Item>
                        <Form.Item name='start_date' initialValue={''}>
                            <DatePicker
                                placeholder='Start Date'
                                className='reports-date-picker'
                            />
                        </Form.Item>
                        <Form.Item name='end_date' initialValue={''}>
                            <DatePicker
                                placeholder='End Date'
                                className='reports-date-picker'
                            />
                        </Form.Item>
                        <Form.Item name='user' initialValue={null}>
                            <Select
                                className={`dropdown-filter report-user-filter`}
                                popupClassName="patient-list-dropdown"
                                placeholder={<div><UserOutlined /> Users</div>}
                                showSearch
                                optionFilterProp='label'
                                options={userList?.users}
                            />
                        </Form.Item>
                    </Form>
                </Row>
                <Row>
                    <Button 
                        type='primary'
                        onClick={clearFilter}
                    >
                        Clear Filters
                    </Button>
                    <Button
                        type='primary'
                        onClick={() => form.submit()}
                        className='filter-report-button'
                    >
                        Filter Table
                    </Button>
                </Row>
            </Row>
            
            <AdminReportsGenerateModal isVisible={isVisible} setIsVisible={setIsVisible} />
        </Row>
        
    );
}

export default AdminReportsControlBar;