import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { Card, Row } from 'antd';
import '../../../../static/css/components/patient-exam-omdr-deepmd-band.scss';
import { localizedText } from '../../../localizedText';


const PatientExamOMDRAlerts = () => {
    const { DEEPMD_ALERTS } = localizedText;
    const prereviewAlert = useCare1AppSelector(store => store.examData.prereview_alert);

    const yellowAlerts = new Set(['Past OMD', 'Multiple Retina 1 Pathologies']);

    return (
        <Card className={'component-input-card alerts'} bordered={false}>
            <Row>
                <div className="heading">{DEEPMD_ALERTS}</div>
            </Row>
            <div className='component-body' data-testid='omdr-alerts'>
                {prereviewAlert &&

                    prereviewAlert.map((pa, idx) =>
                        < div
                            key={idx}
                            data-testid={`omdr-alerts-item-${idx}`}
                            className={yellowAlerts.has(pa) ? 'alert-yellow' : 'alert-red'}
                        >&#x2022; {pa}</div>
                    )
                }
                {
                    ((prereviewAlert === undefined) || (prereviewAlert.length === 0)) &&
                    <div>No alerts for this patient</div>
                }
            </div>
        </Card >
    );
}

export default PatientExamOMDRAlerts;