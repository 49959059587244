import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { Select } from 'antd';
import { calculateBCVAWarningColor } from '../../../helpers/patient-exam-convert';
import * as Constants from '../../../constants';
import '../../../helpers/utilities';
import '../../../../static/css/components/_patient-exam-bcva.scss';
import { lengthEqual, IOptionItem } from '../../../reducers/patient-exam-slice';


type ComponentProps = {
    side: string;
    sideBCVA: string;
    onSelect: (value: string) => void;
    disabled: boolean;
    narrowColumn?: boolean;
    shortDisplay?: boolean;
    gptResultState: string;
}

const GptPatientExamBCVA = ({ side, sideBCVA, onSelect, disabled, narrowColumn, shortDisplay, gptResultState }: ComponentProps) => {

    const bcvaOptions: IOptionItem[] = useCare1AppSelector(store => store.examData.bcva_options, lengthEqual);

    const bcvaWarningColorClassName = calculateBCVAWarningColor(sideBCVA);

    // Set Enucleated option to last position
    const enucleatedOption = bcvaOptions.find(option => option.value === 'ENU');
    const updatedBcvaOptions = enucleatedOption ?
        [...bcvaOptions.filter(option => option.value !== 'ENU'), enucleatedOption] :
        bcvaOptions;

    return (
        <div className={`${gptResultState}`}>
            <div className={`patient-exam-bcva ${narrowColumn ? 'narrow-column' : ''}`}>
                <Select
                    className={`bcva-select-${side}`}
                    data-testid={`bcva-${side}`}
                    showSearch
                    size="small"
                    disabled={disabled}
                    popupClassName="patient-list-dropdown"
                    value={sideBCVA}
                    onSelect={onSelect}
                >
                    {updatedBcvaOptions && updatedBcvaOptions.map((option) => (
                        <Select.Option
                            key={option[Constants.REACT_DROPDOWN_VALUE]}
                            value={option[Constants.REACT_DROPDOWN_VALUE]}
                        >
                            <span className={bcvaWarningColorClassName === null ? '' : bcvaWarningColorClassName}>
                                {'0123456789'.indexOf(`${option[Constants.REACT_DROPDOWN_VALUE]}`.charAt(0)) !== -1 ? '20/' : ''}
                                {shortDisplay
                                    ? option[Constants.REACT_DROPDOWN_VALUE]
                                    : option[Constants.REACT_DROPDOWN_LABEL]
                                }
                            </span>
                        </Select.Option>
                    ))}
                </Select>
            </div>
        </div>
    );
}

export default GptPatientExamBCVA;