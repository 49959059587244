
import { Card, Row, Col, Tag } from 'antd';
import '../../../../static/css/components/admin-selectors.scss';
import { localizedText } from '../../../localizedText';

const { CheckableTag } = Tag;

type ComponentProps = {
    dhxOd: boolean;
    onDhxOdChange: (v: boolean) => void;
    dhxOs: boolean;
    onDhxOsChange: (v: boolean) => void;
    odQuestion: boolean;
    onOdQuestionChange: (v: boolean) => void;
    cuOmd: boolean;
    onCuOmdChange: (v: boolean) => void;
    gptResultState: { [x: string]: string };
}

const GptAdminSelectors = ({dhxOd, onDhxOdChange, dhxOs, onDhxOsChange, 
    odQuestion, onOdQuestionChange, cuOmd, onCuOmdChange, gptResultState}: ComponentProps) => {
    const { 
        CURT_UPCOMING_OMD, OD_HAS_QUEST_ISSUE,         
        ADMIN_SELECTORS_TEXT, HXDHX_OD_TEXT, HXDHX_OS_TEXT } = localizedText;
    
    return (
        <Card className={'component-input-card admin-selectors'} bordered={false}>
            <div className="heading">GPT {ADMIN_SELECTORS_TEXT}</div>
            <div className='component-body'>
                <Row>
                    <div className={`${gptResultState['dhxOd']}`}>
                    <Col>
                        <CheckableTag
                            checked={dhxOd}
                            onChange={(checked)=> {
                                onDhxOdChange(checked)
                            }}
                        >
                            {HXDHX_OD_TEXT}
                        </CheckableTag>
                    </Col>
                    </div>
                    <div className={`${gptResultState['odQuestion']}`}>
                    <Col>
                        <CheckableTag
                            checked={odQuestion}
                            onChange={(checked)=> {
                                onOdQuestionChange(checked)
                            }}
                        >
                            {OD_HAS_QUEST_ISSUE}
                        </CheckableTag>
                    </Col>
                    </div>
                </Row>
                <Row>
                    <div className={`${gptResultState['dhxOs']}`}>
                    <Col>
                        <CheckableTag
                            checked={dhxOs}
                            onChange={(checked)=> {
                                onDhxOsChange(checked)
                            }}
                        >
                            {HXDHX_OS_TEXT}
                        </CheckableTag>
                    </Col>
                    </div>
                    <div className={`${gptResultState['cuOmd']}`}>
                    <Col>
                        <CheckableTag
                            checked={cuOmd}
                            onChange={(checked)=> {
                                onCuOmdChange(checked)
                            }}
                        >
                            {CURT_UPCOMING_OMD}
                        </CheckableTag>
                    </Col>
                    </div>
                </Row>
            </div>
        </Card>
    )
}

export default GptAdminSelectors;