import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppDispatch } from '../stores/retina-enabled-store';
import { IUpdateNearMissDataParams, updateNearMissDataWithValue } from "../helpers/gpt-set-values-convert";

export interface IGptSetValues {
    gpt_saved_values: string;
    gpt_near_miss_fields: string;
    gpt_response: string;
};

const initialState: IGptSetValues = {
    gpt_saved_values: '',
    gpt_near_miss_fields: '',
    gpt_response: '',
};

export const updateGptSetValuesNearMissData = (params: IUpdateNearMissDataParams ) =>
    (dispatch: AppDispatch, getState: () => RootState) => {
    const currentGptSavedValues = getState().gptSetValues.gpt_saved_values;
    const currentGptNearMissFields = getState().gptSetValues.gpt_near_miss_fields;
    const currentGptResponse = getState().gptSetValues.gpt_response;

    dispatch(setGptSetValuesData({gpt_saved_values: currentGptSavedValues,
        gpt_near_miss_fields: dispatch(updateNearMissDataWithValue(params.id, params.value, currentGptSavedValues, currentGptNearMissFields, currentGptResponse)),
        gpt_response: currentGptResponse}));
}

export const gptSetValuesSlice = createSlice({
    name: 'gptSetValues',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setGptSetValuesData: (state, action: PayloadAction<IGptSetValues>) => {
            return {
                ...state,
                ...action.payload
            }
        },
    },
    extraReducers: (builder) => {
    },
});

export const { setGptSetValuesData,
    } = gptSetValuesSlice.actions;

export default gptSetValuesSlice.reducer;
