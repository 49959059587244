import React from 'react';
import { Layout, Spin } from 'antd';
import { useCare1AppSelector } from '../apps/care1-hooks';

import { OPERATING_TEXT } from '../constants';

import NavigationControlBar from '../components/navigation-control-bar';
import AdminLatencyMetricsTable from '../components/admin/admin-latency-metrics-table';
import { useGetLatencyMetricsSummaryQuery } from '../services/latency-metrics-api';

import '../../static/css/pages/latency-metrics-page.scss';

const { Header, Content } = Layout;

const LatencyMetricsPage: React.FC = () => {

    const { data: data = {
        latency_metrics_list: {
            summary: {
                rows: [],
                days: []
            }
        }
    }, isFetching: isFetching } = useGetLatencyMetricsSummaryQuery();
    
    const getTabLatencyMetricsSummary = () => {
        return (
        <div style={{paddingTop: '1rem'}}>
            <AdminLatencyMetricsTable
                tableList={data.latency_metrics_list.summary.rows}
                days={data.latency_metrics_list.summary.days}
                title=""
            />
        </div>);
    }
    
    return (
        <Layout className='latency-metrics-page'>
            <Spin
                className="loading-spinner"
                spinning={isFetching}
                size="large"
                tip={OPERATING_TEXT}
            >
                <div>
                    {getTabLatencyMetricsSummary()}
                </div>
            </Spin>
        </Layout>
    );
}

export default LatencyMetricsPage;