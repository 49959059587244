import { baseApi } from './base-api'

export interface IOdDashboardVisitsUploadedResponse {
    data: {
        previous_month_count: number;
        this_month_count: number;
    }
}

export interface IOdDashboardLastSixMonthsUploadsResponseItem {
    month: string,
    total_uploaded: number,
    cataract_referrals: number,
    uploads_with_oct: number,
    uploads_with_mini_dtc: number,
    od_odgrouppractice_province: string,
}

export interface IOdDashboardLastSixMonthsUploadsResponse {
    data: IOdDashboardLastSixMonthsUploadsResponseItem[]
}

export const odDashboardApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getOdDashboardTotalUploaded: builder.query<IOdDashboardVisitsUploadedResponse, void>({
            query: () => {
                return {
                    url: `od_dashboard/total_uploaded/`
                }
            },
        }),
        getOdDashboardCataractReferrals: builder.query<IOdDashboardVisitsUploadedResponse, void>({
            query: () => {
                return {
                    url: `od_dashboard/cataract_referrals/`
                }
            },
        }),
        getOdDashboardUploadsWithOct: builder.query<IOdDashboardVisitsUploadedResponse, void>({
            query: () => {
                return {
                    url: `od_dashboard/uploads_with_oct/`
                }
            },
        }),
        getOdDashboardUploadsWithMiniDtc: builder.query<IOdDashboardVisitsUploadedResponse, void>({
            query: () => {
                return {
                    url: `od_dashboard/uploads_with_mini_dtc/`
                }
            },
        }),
        getOdDashboardLastSixMonthsUploads: builder.query<IOdDashboardLastSixMonthsUploadsResponse, void>({
            query: () => {
                return {
                    url: `od_dashboard/last_six_months_uploads/`
                }
            },
        })
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const {
    useGetOdDashboardTotalUploadedQuery, useGetOdDashboardCataractReferralsQuery, 
    useGetOdDashboardUploadsWithOctQuery, useGetOdDashboardUploadsWithMiniDtcQuery,
    useGetOdDashboardLastSixMonthsUploadsQuery } = odDashboardApi;
