// the chat components codes mainly from 
// https://github.com/fatihbaltaci/chatgpt-clone

import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Modal } from 'antd';
import '../../../../static/css/components/admin-gpt.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { editExamRequest } from '../../../reducers/patient-exam-slice';
import GptVisionOctRfnl from "./gpt-vision-oct-rnfl";
import GptVisionVfGht from "./gpt-vision-vf-ght";
import { postGptVisionResults } from '../../../reducers/gpt-vision-slice';

const GptVision = () => {

    const dispatch = useCare1AppDispatch();
    const history = useNavigate();

    const examId = useCare1AppSelector(store => store.examData.id);

    const rightVfPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_vf_photo);
    const leftVfPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_vf_photo);
    const rightOctRnflPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_oct_rnfl_photo);
    const leftOctRnflPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_oct_rnfl_photo);

    const [octavSupRight, setOctavSupRight] = useState('');
    const [octavRight, setOctavRight] = useState('');
    const [octavInfRight, setOctavInfRight] = useState('');

    const [octavSupLeft, setOctavSupLeft] = useState('');
    const [octavLeft, setOctavLeft] = useState('');
    const [octavInfLeft, setOctavInfLeft] = useState('');

    const [ghtRight, setGhtRight] = useState('');
    const [ghtLeft, setGhtLeft] = useState('');

    const handleAddAllValuesToExam = async () => {
        console.log('handleAddAllValuesToExam');

        try {
            const result = await dispatch(postGptVisionResults({
                value: {
                    right_octav: octavRight,
                    left_octav: octavLeft,
                    right_octav_sup: octavSupRight,
                    left_octav_sup: octavSupLeft,
                    right_octav_inf: octavInfRight,
                    left_octav_inf: octavInfLeft,
                    right_ght: ghtRight,
                    left_ght: ghtLeft,
                }

            })).unwrap();
            if (result && result.success) {
                Modal.info({
                    className: 'info-modal',
                    content: 'Setting GPT Vision results succeeded',
                    title: 'Info',
                });

                if (examId) {
                    await dispatch(editExamRequest(examId, history));
                }
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors setting GPT Vision results',
            });
        }
    }

    return (
        <div>
            <Row>
                <Col span={12}>
                    <GptVisionOctRfnl
                        side={'right'}
                        photo={rightOctRnflPhoto}
                        octavSup={octavSupRight}
                        setOctavSup={setOctavSupRight}
                        octav={octavRight}
                        setOctav={setOctavRight}
                        octavInf={octavInfRight}
                        setOctavInf={setOctavInfRight}
                    />
                </Col>
                <Col span={12}>
                    <GptVisionOctRfnl
                        side={'left'}
                        photo={leftOctRnflPhoto}
                        octavSup={octavSupLeft}
                        setOctavSup={setOctavSupLeft}
                        octav={octavLeft}
                        setOctav={setOctavLeft}
                        octavInf={octavInfLeft}
                        setOctavInf={setOctavInfLeft}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <GptVisionVfGht
                        side={'right'}
                        photo={rightVfPhoto}
                        ght={ghtRight}
                        setGht={setGhtRight}
                    />
                </Col>
                <Col span={12}>
                    <GptVisionVfGht
                        side={'left'}
                        photo={leftVfPhoto}
                        ght={ghtLeft}
                        setGht={setGhtLeft}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={12} offset={6}>
                    <Button
                        block
                        className={''}
                        onClick={handleAddAllValuesToExam}
                        type="primary"
                        size="large"
                    >
                        Add All Values to Exam
                    </Button>
                </Col>
            </Row>
        </div>)
}

export default GptVision;
