import { Row, Col, Tabs, Card } from 'antd';
import GptImageIdentifierImg from './gpt-image-identifier-img';

const { TabPane } = Tabs;

type ComponentProps = {
    rightFundus: string,
    leftFundus: string,
    rightStereoFundus: string,
    leftStereoFundus: string,
    rightVfPhoto: string,
    leftVfPhoto: string,
    rightOctRnflPhoto: string,
    leftOctRnflPhoto: string,
    rightOCTMacula: string,
    leftOCTMacula: string,
}


const GptImageIdentifierImages = ({ rightFundus, leftFundus, rightStereoFundus, leftStereoFundus,
    rightVfPhoto, leftVfPhoto, rightOctRnflPhoto, leftOctRnflPhoto,
    rightOCTMacula, leftOCTMacula }: ComponentProps) => {

    return (
        <div className="gpt-image-identifier-images">
            <Row>
                <Col span={12}>
                    <Row>
                        <Col span={12}>
                            <Card className='patient-exam-fundus' bordered={false}>
                                <Tabs type="card">
                                    <TabPane tab='Fundus Photo' forceRender key="1">
                                        {rightFundus !== '' && <GptImageIdentifierImg
                                            className='dropzone-image'
                                            photoUrl={rightFundus}
                                        />}
                                    </TabPane>
                                    <TabPane tab='Second Fundus Photo' forceRender key="2">
                                        {rightStereoFundus !== '' && <GptImageIdentifierImg
                                            className='dropzone-image'
                                            photoUrl={rightStereoFundus}
                                        />}
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-oct-macula' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='OCT Macula' forceRender key="1">
                                                {rightOCTMacula !== '' && <GptImageIdentifierImg
                                                    className='dropzone-image'
                                                    photoUrl={rightOCTMacula}
                                                />}
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-oct-rnfl' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='OCT RNFL' forceRender key="1">
                                                {rightOctRnflPhoto !== '' && <GptImageIdentifierImg
                                                    className='dropzone-image'
                                                    photoUrl={rightOctRnflPhoto}
                                                />}
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-vf' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='VF' forceRender key="1">
                                                {rightVfPhoto !== '' && <GptImageIdentifierImg
                                                    className='dropzone-image'
                                                    photoUrl={rightVfPhoto}
                                                />}
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-vf' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='VF' forceRender key="1">
                                                {leftVfPhoto !== '' && <GptImageIdentifierImg
                                                    className='dropzone-image'
                                                    photoUrl={leftVfPhoto}
                                                />}
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-oct-macula' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='OCT Macula' forceRender key="1">
                                                {leftOCTMacula !== '' && <GptImageIdentifierImg
                                                    className='dropzone-image'
                                                    photoUrl={leftOCTMacula}
                                                />}
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Card className='patient-exam-oct-rnfl' bordered={false}>
                                        <Tabs type="card">
                                            <TabPane tab='OCT RNFL' forceRender key="1">
                                                {leftOctRnflPhoto !== '' && <GptImageIdentifierImg
                                                    className='dropzone-image'
                                                    photoUrl={leftOctRnflPhoto}
                                                />}
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Card className='patient-exam-fundus' bordered={false}>
                                <Tabs type="card">
                                    <TabPane tab='Fundus Photo' forceRender key="1">
                                        {leftFundus !== '' && <GptImageIdentifierImg
                                            className='dropzone-image'
                                            photoUrl={leftFundus}
                                        />}
                                    </TabPane>
                                    <TabPane tab='Second Fundus Photo' forceRender key="2">
                                        {leftStereoFundus !== '' && <GptImageIdentifierImg
                                            className='dropzone-image'
                                            photoUrl={leftStereoFundus}
                                        />}
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>);
}

export default GptImageIdentifierImages;
