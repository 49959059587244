import React from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Button, Card, Col, Modal, Row, } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import HistoryImage from './history-image';
import * as Constants from '../../constants';
import '../../../static/css/components/_patient-exam-fundus-history.scss';
import { lengthEqual } from '../../reducers/patient-exam-slice';
import { localizedText } from '../../localizedText';
import { viewAll } from '../../reducers/history-image-slice';
import { IAllExamImages } from "../../reducers/patient-exam-slice";

const PatientExamFundusHistory = ({ side, imageCount }: { side: string, imageCount: number }) => {
    const { RIGHT_FUNDUS_PHOTO_TITLE_TEXT, LEFT_FUNDUS_PHOTO_TITLE_TEXT } = localizedText;

    const dispatch = useCare1AppDispatch();

    const exams_with_left_fundus = useCare1AppSelector(store => store.examData[Constants.EXAMS_WITH_LEFT_FUNDUS], lengthEqual);
    const exams_with_right_fundus = useCare1AppSelector(store => store.examData[Constants.EXAMS_WITH_RIGHT_FUNDUS], lengthEqual);
    const historyImage = useCare1AppSelector(store => store.historyImage);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const examIsRE = useCare1AppSelector(store => store.examData.exam_is_retina_only);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const isReferralLetterUploadPEI = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const rluExamPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);

    // A standard RLU user uses an RLU PEI and is not premium.
    const userIsStandard = isReferralLetterUploadPEI && !rluExamPremium;

    // Different platforms have different number of images per side and therefore different column widths
    const bandColumnWidth = ((((userIsOD && !userIsRE) || userIsADMIN) && !userIsStandard) || (userIsOMDR && !userIsRE))
        ? 6 : (userIsOMDC || (userIsOMDR && userIsRE)) ? 8 : 24;

    // OD gets 4 images per side and OMDs get 3 images per side
    const IMAGES_PER_ROW = userIsOD || userIsADMIN  || (userIsOMDR && !userIsRE) ? 4 : 3;

    const getOldestImageCards = (currentImages: IAllExamImages[], side: string, isModal: boolean) => {

        const newest = [...currentImages];
        const oldest = [];

        // Use pop() to remove the last (i.e. oldest) elements from currentImages
        // Pop() a total equal to the IMAGES_PER_ROW
        for (let i = 0; i < IMAGES_PER_ROW; i++) {
            const c = newest.pop();
            if (c) {
                oldest.push(c);
            }
        }

        const oldestCards = [];

        oldest.forEach((image, index) => {
            const photoUrl = side === Constants.RIGHT_SIDE_TEXT ? image.right_fundus_photo : image.left_fundus_photo;
            oldestCards.push(
                <Col key={index} className={'image-col'} span={ (isModal && userIsOMDC) ? 6 : isModal ? 4 : bandColumnWidth}>
                    <Card key={index} bordered={false}>
                        <HistoryImage
                            type={Constants.FUNDUS_HISTORY}
                            side={side}
                            photoUrl={photoUrl}
                            examDate={image.exam_date_utc}
                            idx={index}
                            style={{}}
                            borderClass={''}
                        />
                    </Card>
                </Col>
            );
        });

        // Display blank image for empty images
        if (!isModal) {
            const numImages = oldestCards.length;
            for (let i = numImages; i < IMAGES_PER_ROW; i += 1) {
                oldestCards.push(
                    <Col key={i} span={bandColumnWidth}>
                        <Card key={i} bordered={false}>
                            <div className='blank-history-image'>
                                <PictureOutlined />
                            </div>
                        </Card>
                    </Col>
                );
            }
        }

        return oldestCards;
    }

    const getNewestImageCards = (currentImages: IAllExamImages[], side: string, isModal: boolean) => {

        const newest = [...currentImages];

        // Remove the oldest cards since they are already displayed in the top row.
        for (let i = 0; i < IMAGES_PER_ROW; i++) {
            newest.pop();
        }

        const newestCards = [];

        for (let index = 0; index < IMAGES_PER_ROW; index++) {
            if (index < newest.length) {
                const image = newest[index];
                const cellIndex = index + IMAGES_PER_ROW;

                const photoUrl = side === Constants.RIGHT_SIDE_TEXT ? image.right_fundus_photo : image.left_fundus_photo;
                newestCards.push(
                    <Col key={cellIndex} className={'image-col'} span={ (isModal && userIsOMDC) ? 6 : isModal ? 4 : bandColumnWidth}>
                        <Card key={cellIndex} bordered={false}>
                            <HistoryImage
                                type={Constants.FUNDUS_HISTORY}
                                side={side}
                                photoUrl={photoUrl}
                                examDate={image.exam_date_utc}
                                idx={cellIndex}
                                style={{}}
                                borderClass={''}
                            />
                        </Card>
                    </Col>
                );
            }
        };

        // Display blank image for empty images
        if (!isModal) {
            const numImages = newestCards.length;
            for (let i = numImages; i < IMAGES_PER_ROW; i += 1) {
                newestCards.push(
                    <Col key={i} span={bandColumnWidth}>
                        <Card key={i} bordered={false}>
                            <div className='blank-history-image'>
                                <PictureOutlined />
                            </div>
                        </Card>
                    </Col>
                );
            }
        }

        return newestCards;
    }

    const getImageCards = (currentImages: IAllExamImages[], side: string, isModal: boolean) => {
        const cards = [];
        currentImages.forEach((image, index) => {
            const photoUrl = side === Constants.RIGHT_SIDE_TEXT ? image.right_fundus_photo : image.left_fundus_photo;
            cards.push(
                <Col
                    key={index}
                    className={'image-col'}
                    span={ (isModal && (userIsOMDC || userIsOMDR)) ? 6 : isModal ? 4 : bandColumnWidth}
                >
                    <Card key={index} bordered={false}>
                        <HistoryImage
                            type={Constants.FUNDUS_HISTORY}
                            side={side}
                            photoUrl={photoUrl}
                            examDate={image.exam_date_utc}
                            idx={index}
                            style={{}}
                            borderClass={''}
                        />
                    </Card>
                </Col>
            );
        });

        if (!isModal && !userIsOMDC && !(userIsOMDR && !examIsRE)) {
            const numImages = cards.length;
            for (let i = numImages; i <= imageCount; i += 1) {
                cards.push(
                    <Col key={i} span={24}>
                        <Card key={i} bordered={false}>
                            <div
                                className={(() => {
                                    if (numImages === imageCount - 1) {
                                        return 'blank-history-image single';
                                    } else if (i === numImages) {
                                        return 'blank-history-image first';
                                    } else if (i === imageCount - 1) {
                                        return 'blank-history-image last';
                                    }
                                    return 'blank-history-image';
                                })()}
                            >
                                {Constants.NO_IMAGE_UPLOADED}
                            </div>
                        </Card>
                    </Col>
                );
            }
        }

        return cards;
    }

    let currentImages: IAllExamImages[];

    if (side === Constants.RIGHT_SIDE_TEXT) {
        currentImages = exams_with_right_fundus || [];
    } else {
        currentImages = exams_with_left_fundus || [];
    }
    const heading = side === 'right' ? RIGHT_FUNDUS_PHOTO_TITLE_TEXT : LEFT_FUNDUS_PHOTO_TITLE_TEXT;

    return (
        <Card className={`patient-exam-fundus-history ${userIsOMDC || (userIsOMDR && !examIsRE) ? ' images-history-card' : ''}`}  bordered={false}>

            { /* View All Modal */}
            <Modal
                className={`view-all-modal${userIsOMDC || (userIsOMDR && !examIsRE) ? ' fundus larger-modal' : ''}`}
                open={historyImage.imageSide === side
                    && historyImage.imageType === Constants.FUNDUS_HISTORY && historyImage.viewAll}
                width="840"
                onCancel={() => { dispatch(viewAll({
                    isVisible: false,
                    imageType: Constants.FUNDUS_HISTORY,
                    imageSide: side})); }}
                closable
                footer={null}
                title={`View All Fundus ${side === 'right' ? 'OD' : 'OS'}`}
            >
                <Row gutter={9}>
                    {currentImages.length > 0 ? getImageCards(currentImages, side, true) : <div className="blank-history-image">{Constants.NO_IMAGE_UPLOADED}</div>}
                </Row>
            </Modal>

            <div className="heading image-section-heading">
                {heading}
            </div>

            <Card bordered={false}>
                {/* OMDs top row should be oldest images and remaining rows should be newest images */}
                { ((((userIsOD && !userIsRE) || userIsADMIN) && !userIsStandard) || userIsOMDC || userIsOMDR) &&
                    <>
                        <Row className='image-row'>
                            { getOldestImageCards(currentImages, side, false).slice(0, imageCount) }
                        </Row>
                        <Row className='image-row'>
                            { getNewestImageCards(currentImages, side, false).slice(0, imageCount) }
                        </Row>
                    </>
                }
                { ((userIsOD && userIsRE) || userIsStandard) && getImageCards(currentImages, side, false).slice(0, imageCount)}

                {/* Always display view-all button if user is RE or standard RLU */}
                {/* Also, display view-all button if IC, OMDC or OMDR and there are more image then imageCount */}
                {
                    ((userIsOD && userIsRE) || userIsStandard ||
                            (((userIsOD && !userIsRE) || userIsADMIN || userIsOMDC || userIsOMDR)
                                && (currentImages.length > imageCount))) &&
                    <Col span={24}>
                        <Button
                            className="button-view-all"
                            onClick={(event) => {
                                event.stopPropagation();
                                dispatch(viewAll({
                                    isVisible: true,
                                    imageType: Constants.FUNDUS_HISTORY,
                                    imageSide: side}));
                            }}
                        >
                            {((userIsOD && userIsRE) || userIsStandard) ? 'VIEW ALL' : `View All Fundus ${side === 'right' ? 'OD' : 'OS'}`}
                        </Button>
                    </Col>
                }
            </Card>
        </Card>
    );
}

export default PatientExamFundusHistory;