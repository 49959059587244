
import { Card, Col, Row, Tag, Input, Button } from 'antd';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { getPreReviewAutoMessageRequest, preReviewExam, setAutoPreReviewMessageRequest, setPreReviewDataValue } from '../../reducers/pre-review-slice';
import { getUserInitials } from '../../helpers/patient-exam-convert';
import '../../../static/css/components/admin-pre-review.scss';
import { localizedText } from '../../localizedText';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

const { CheckableTag } = Tag;

const AdminPreReview = () => {
    const { PREREVIEW_INITIALS, SECOND_REVIEW, PREREVIEWED_TEXT, PREREVIEW_TEXT } = localizedText;
    const user = useCare1AppSelector(store => store.user);
    const id = useCare1AppSelector(store => store.examData.id);
    const initialsSelect = useCare1AppSelector(store => store.examData.initials_select);
    const lastPrereviewDate = useCare1AppSelector(store => store.examData.last_prereview_date);
    const isPreReviewed = useCare1AppSelector(store => store.examData.is_pre_reviewed);
    const examPrereviewDateAfterCutoff = useCare1AppSelector(store => store.examData.exam_prereview_date_after_cutoff);

    const dispatch = useCare1AppDispatch();

    const prereviewDate = lastPrereviewDate ? dayjs(lastPrereviewDate).format('MMM DD, YYYY') : '';

    const handleClick = () => {

        dispatch(preReviewExam());
        dispatch(setAutoPreReviewMessageRequest());
        dispatch(setExamDataValue('exam_prereview_date_after_cutoff', true));
    }

    const addReviewerInitials = (originalInitials: string) => {
        const currentInitials = getUserInitials(user);
        if (originalInitials && originalInitials.toUpperCase() !== currentInitials.toUpperCase()) {
            // Remove original bracket contents if there are any.
            originalInitials = originalInitials.split("(")[0];
            return originalInitials + "(" + currentInitials + ")";
        }

        return originalInitials;
    }

    useEffect(() => {
        if (id) {
            dispatch(getPreReviewAutoMessageRequest(id));
        }

    }, [dispatch, id])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        dispatch(setExamDataValue('initials_select', value));
    }

    const handle2ndReviewCick = () => {
        const res = addReviewerInitials(initialsSelect);
        dispatch(setExamDataValue('initials_select', res));
    }


    return (
        <Card className={'component-input-card admin-pre-review'} bordered={false}>
            <div className="heading">{PREREVIEW_INITIALS}</div>
            <div className='component-body'>
                <Row>
                    <div
                        className='date-label'
                        data-testid='pre-review-date'
                    >
                        {PREREVIEWED_TEXT}: {prereviewDate}
                    </div>
                </Row>
                <Row>
                    <Col span={12}>
                        <CheckableTag
                            checked={isPreReviewed}
                            data-testid='is-pre-reviewed'
                            onChange={(checked) => {
                                dispatch(setExamDataValue('is_pre_reviewed', checked));
                                dispatch(setPreReviewDataValue({ key: 'isDirty', value: true }));
                            }}
                        >
                            {PREREVIEWED_TEXT}
                        </CheckableTag>
                    </Col>
                    <Col span={12}>
                        <CheckableTag
                            onClick={handleClick}
                            checked={examPrereviewDateAfterCutoff}
                            data-testid='pre-review'
                        >
                            {PREREVIEW_TEXT}
                        </CheckableTag>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Input
                            className='initial-input'
                            data-testid='initial-input'
                            value={initialsSelect}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col span={12}>
                        <Button
                            className='pr2-button'
                            onClick={handle2ndReviewCick}
                            data-testid='second-review'
                        >{SECOND_REVIEW}
                        </Button>
                    </Col>
                </Row>
            </div>
        </Card>
    )
}

export default AdminPreReview;