import React, { useEffect, useMemo } from 'react';
import { Form, Row, Card, Col, Checkbox, Select, Button } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

// Components
import PatientExamHeaderPatientInfo from '../retina/header/patient-exam-header-patient-info';
import PatientExamHeaderODSelect from '../retina/header/patient-exam-header-od-select';
import OMDSubmitButtons from '../reviewer/omd-submit-buttons';
import PatientExamHeaderExamStatus from '../retina/header/patient-exam-header-exam-status';
import PatientExamHeaderOMDCStatus from '../retina/header/patient-exam-header-omdc-status';
import PatientExamHeaderExamDatePicker from '../retina/header/patient-exam-header-exam-date-picker';
import PatientExamHeaderMultipleExamsReadyTag from './patient-exam-header-multiple-exams-ready-tag';
import PatientExamHeaderOMDRSelect from './patient-exam-header-omdr-select';

// Actions
import { getIGPListRequest } from '../../reducers/igp-list-slice';

import { EXAM_HIGH_PRIORITY, ALERT_ID
    } from '../../constants';

import '../../../static/css/components/patient-exam-header-admin.scss';
import '../../../static/css/shared/date-picker.scss';
import AdminLetterDropdown from './admin-letter-dropdown';
import { openPatientDetailsModal } from '../../reducers/patient-details-slice';
import { localizedText } from '../../localizedText';
import { lengthEqual, setExamDataValue } from '../../reducers/patient-exam-slice';
import { FieldData } from 'rc-field-form/lib/interface';
import { InfoCircleOutlined } from '@ant-design/icons';


const PatientExamHeaderAdmin = () => {
    const { GP_TEXT, GP2_TEXT, IGP_LABEL, IGP_STATUS_LABEL, EXTERNAL_GP_STATUS_CHOICES,
        SELECT_IGP_TEXT, SELECT_IGP_STATUS, INTERNAL_GP_STATUS_CHOICES, IPC_STATUS_CHOICES,
        HIGH_PRIORITY_TEXT } = localizedText;

    const gp = useCare1AppSelector(store => store.patientDetails.details.gp);
    const gp2 = useCare1AppSelector(store => store.patientDetails.details.gp2);
    const shouldDisplayMultiTabs = useCare1AppSelector(store => store.patientExams.shouldDisplayMultiTabs);
    const omdc = useCare1AppSelector(store => store.examData.omdc);
    const externalGPStatus = useCare1AppSelector(store => store.examData.external_gp_status);
    const isUrgent = useCare1AppSelector(store => store.examData.is_urgent);
    const internalGP = useCare1AppSelector(store => store.examData.internal_gp);
    const internalGPStatus = useCare1AppSelector(store => store.examData.internal_gp_status);
    const ipc = useCare1AppSelector(store => store.examData.ipc);
    const selectedOMDNoteOptions = useCare1AppSelector(store => store.examData.selected_omd_note_options, lengthEqual);
    const omds = useCare1AppSelector(store => store.options.omds, lengthEqual);
    const igps = useCare1AppSelector(store => store.igpList.list, lengthEqual);
    const hasNoGp = useCare1AppSelector(store => store.patientDetails.details.has_no_gp);

    const pehHeaderClassName = `patient-exam-header ${shouldDisplayMultiTabs ? 'pei-exam-room-border' : ''}`;
    const ipcStatus = (ipc !== null && ipc !== 'null' && ipc !== '') ? ipc : null;

    // only run when omds change
    const omdcList = useMemo(() =>
        omds.filter(option => option.hidden_from_dropdown === false && option.is_reviewer === false)
        , [omds]);

    // only run when omdc, omdcList, omds change
    const hiddenOmdc = useMemo(() =>
        omdcList.find(omd => omd.id === omdc) ? null : omds.find(omd => omd.id === omdc)
        , [omdc, omdcList, omds]);

    const dispatch = useCare1AppDispatch();
    const [ form ] = Form.useForm();

    const onPatientNameClick = () => {
        dispatch(openPatientDetailsModal());
    }

    const handleSelectIPCStatus = (key: string) => {
        dispatch(setExamDataValue('ipc', key));
    }

    const handleClearIPCStatus = () => {
        dispatch(setExamDataValue('ipc', ''));
    }

    const handleSetIGPSelection = (key: string) => {
        dispatch(setExamDataValue('internal_gp', key));
    }

    const handleClearIGPSelection = () => {
        dispatch(setExamDataValue('internal_gp', ''));
    }

    const handleSelectOMDC = (key: number) => {
        dispatch(setExamDataValue('omdc', key));
    }

    const handleClearOMDC = () => {
        dispatch(setExamDataValue('omdc', ''));
    }

    const handleSelectEGPStatus = (key: string) => {
        dispatch(setExamDataValue('external_gp_status', key));
    }

    const handleClearEGPStatus = () => {
        dispatch(setExamDataValue('external_gp_status', ''));
    }

    const handleSelectIGPStatus = (key: string) => {
        dispatch(setExamDataValue('internal_gp_status', key));
    }

    const handleClearIGPStatus = () => {
        dispatch(setExamDataValue('internal_gp_status', ''));
    }

    // only run when the component first mount
    useEffect(() => {
        dispatch(getIGPListRequest());
    }, [dispatch])

    useEffect(() => {
        // If the exam is urgent, also set the Alert OMD note.
        if (isUrgent && !selectedOMDNoteOptions.includes(ALERT_ID)) {
            dispatch(setExamDataValue('selected_omd_note_options', [...selectedOMDNoteOptions, ALERT_ID]));
        };
    }, [dispatch, isUrgent, selectedOMDNoteOptions])

    // equivalent of componentDidUpdate in this case
     useEffect(()=>{
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            [EXAM_HIGH_PRIORITY] : isUrgent
        };
        form.setFieldsValue(newValidationFieldValues);
    }, [isUrgent, form])


    // Ant Design handler. This is called whenever a field is changed by the user using Ant Design controls.
    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case EXAM_HIGH_PRIORITY:
                        dispatch(setExamDataValue(field.name[0], field.value));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    return (
        <Card className={pehHeaderClassName} bordered={false}>
            <Form form={form} onFieldsChange={onFieldsChange}>
            <Row>
                {/* Column One */}
                <Col span={7} className="patient-info">
                    <PatientExamHeaderPatientInfo
                        onClick={onPatientNameClick}
                    />
                    <Row className="header-value">
                        <PatientExamHeaderMultipleExamsReadyTag />
                    </Row>
                </Col>
                <Col span={4} className="column-two">
                    <Row>
                        <Col span={6} className="labels">
                            <Row className="header-label">
                                <label className="gp">{GP_TEXT}</label>
                            </Row>
                            <Row className="header-label">
                                <label className="gp2">{GP2_TEXT}</label>
                            </Row>
                            <Row className="header-label">
                                <label className="igp">{IGP_LABEL}</label>
                            </Row>
                            <Row className="header-label">
                                <label className="igp-status">{IGP_STATUS_LABEL}</label>
                            </Row>
                        </Col>
                        <Col span={18} className="values">
                            <Row className="header-value">
                                <label className="gp">{hasNoGp && !gp && !gp2 ? 'No GP' : gp ? `Dr. ${gp}` : 'N/A'}</label>
                            </Row>
                            <Row className="header-value">
                                <label className="gp2">{gp2 ? `Dr. ${gp2}` : 'N/A'}</label>
                            </Row>
                            <Row className="header-value">
                                <div className="pei-header-selector igp">
                                    <Select
                                        size="small"
                                        data-testid='igp-value'
                                        className="dropdown-filter patient-exam-igp-select"
                                        popupClassName="patient-list-dropdown"
                                        value={internalGP || null}
                                        allowClear={true}
                                        onClear={handleClearIGPSelection}
                                        onSelect={handleSetIGPSelection}
                                        placeholder={SELECT_IGP_TEXT}
                                    >
                                        {igps && igps.map((option) => (
                                            <Select.Option key={option.id} value={option.id}>
                                                {option.first_name} {option.last_name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </Row>
                            <Row className="header-value">
                                <div className="pei-header-selector igp-status">
                                    <Select
                                        size="small"
                                        className="dropdown-filter"
                                        popupClassName="patient-list-dropdown"
                                        value={internalGPStatus || null}
                                        allowClear={true}
                                        onClear={handleClearIGPStatus}
                                        onSelect={handleSelectIGPStatus}
                                        placeholder={SELECT_IGP_STATUS}
                                        disabled={internalGP == null}
                                    >
                                        {INTERNAL_GP_STATUS_CHOICES && INTERNAL_GP_STATUS_CHOICES.map((option) => (
                                            <Select.Option key={option.key} value={option.key}>
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={5} className="column-three">
                    <Row>
                        <Col span={6} className="labels">
                            <Row className="header-label">
                                <label className="od">{`OD:`}</label>
                            </Row>
                            <Row className="header-label">
                                <label className="omdr">{`OMDR:`}</label>
                            </Row>
                            <Row className="header-label">
                                <label className="omdc">{`OMDC:`}</label>
                            </Row>
                            <Row className="header-label">
                                <label className="egp-status">{`EGP St:`}</label>
                            </Row>
                        </Col>
                        <Col span={18} className="values">
                            <Row className="header-value">
                                <div className="pei-header-selector od">
                                    <PatientExamHeaderODSelect />
                                </div>
                            </Row>
                            <Row className="header-value">
                                <div className="pei-header-selector omdr">
                                    <PatientExamHeaderOMDRSelect />
                                </div>
                            </Row>
                            <Row className="header-value">
                                <div className="pei-header-selector omdc">
                                    <Select
                                        className={`patient-exam-omdc-select ${hiddenOmdc ? 'hidden-omdc': ''}`}
                                        showSearch
                                        size="small"
                                        value={omdc || null}
                                        allowClear={Boolean(omdc)}
                                        onSelect={handleSelectOMDC}
                                        onClear={handleClearOMDC}
                                        placeholder={`Select OMDC`}
                                        filterOption={(input, option) => option!.props.children.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            omdcList && omdcList.map((option) => (
                                                <Select.Option
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {`Dr. ${option.name}`}
                                                </Select.Option>
                                            ))
                                        }
                                        {hiddenOmdc ?
                                            <Select.Option
                                                key={hiddenOmdc.id}
                                                value={hiddenOmdc.id}
                                            >
                                                {`Dr. ${hiddenOmdc.name}`}
                                            </Select.Option> : null}
                                    </Select>
                                </div>
                            </Row>
                            <Row className="header-value">
                                <div className="pei-header-selector egp-status">
                                    <Select
                                        size="small"
                                        className="dropdown-filter patient-exam-egp-select"
                                        popupClassName="patient-list-dropdown"
                                        value={externalGPStatus || null}
                                        allowClear={true}
                                        onClear={handleClearEGPStatus}
                                        onSelect={handleSelectEGPStatus}
                                        placeholder='Select EGP Status'
                                    >
                                        {EXTERNAL_GP_STATUS_CHOICES && EXTERNAL_GP_STATUS_CHOICES.map((option) => (
                                            <Select.Option key={option.key} value={option.key}>
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={5} className="column-four">
                    <Row>
                        <Col span={6} className="labels">
                            <Row className="header-label">
                                <label className="exam-date">{`Date:`}</label>
                            </Row>
                            <Row className="header-label">
                                <label className="chart-status">{`Exam St:`}</label>
                            </Row>
                            <Row className="header-label">
                                <label className="omdc-status">{`OMDC St:`}</label>
                            </Row>
                            <Row className="header-label">
                                <label className="ipc-status">{`IPC St:`}</label>
                            </Row>
                        </Col>
                        <Col span={18} className="values">
                            <Row className="header-value">
                                <div className="pei-header-selector exam-date">
                                    <PatientExamHeaderExamDatePicker />
                                </div>
                            </Row>
                            <Row className="header-value">
                                <div className="pei-header-selector chart-status">
                                    <PatientExamHeaderExamStatus disabled={false} />
                                </div>
                            </Row>
                            <Row className="header-value">
                                <div className="pei-header-selector omdc-status">
                                    <PatientExamHeaderOMDCStatus />
                                </div>
                            </Row>
                            <Row className="header-value">
                                <div className="pei-header-selector ipc-status">
                                    <Select
                                        size="small"
                                        className="dropdown-filter ipc-status-select"
                                        popupClassName="patient-list-dropdown"
                                        value={ipcStatus}
                                        onSelect={handleSelectIPCStatus}
                                        onClear={handleClearIPCStatus}
                                        placeholder='Select IPC Status'
                                        allowClear={true}
                                    >
                                        {IPC_STATUS_CHOICES && IPC_STATUS_CHOICES.map((option) => (
                                            <Select.Option key={option.key} value={option.key}>
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={3} className="column-five">
                    <Row className="header-value">
                        <OMDSubmitButtons disabled={false} />
                        </Row>
                        <Row className="header-value">
                            <div className="pei-header-selector admin-consult-letter">
                                <AdminLetterDropdown />
                            </div>
                        </Row>
                        <Row className="header-value" align={'middle'}>
                            <Col span={17}>
                                <Form.Item
                                    className={'high-priority'}
                                    name={EXAM_HIGH_PRIORITY}
                                    initialValue={isUrgent}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Checkbox
                                        data-testid='high-priority-checkbox'
                                        name={EXAM_HIGH_PRIORITY}
                                    >
                                        <span className='exam-high-priority-label'>{HIGH_PRIORITY_TEXT}</span>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Button
                                    className='btn-info'
                                    icon={<InfoCircleOutlined />}
                                    onClick={() => dispatch(setExamDataValue('examMetricsInfoModalOpen', true))}
                                >Info</Button>
                            </Col>
                            
                        </Row>
                </Col>
            </Row>
            </Form>
        </Card>
    );
}

export default PatientExamHeaderAdmin;