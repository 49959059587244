import { Modal, Button, Form,  Input, Checkbox, Col, Row } from 'antd';
import '../../../static/css/components/new-staff-modal.scss';

// Action imports
import { SaveOutlined } from '@ant-design/icons';
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { IEditStaffData, INewStaffFormItems, useEditStaffMutation, useGetStaffDetailQuery
    } from '../../services/staff-api';
import { setIsSalesUser, setIsScienceUser, setShowPasswordModal, setShowStaffEditModal
    } from '../../reducers/doctor-slice';
import { useEffect } from 'react';

const StaffEditModal = () => {

    const isVisible = useCare1AppSelector(store => store.doctorSlice.showStaffEditModal);
    const id = useCare1AppSelector(store => store.doctorSlice.currentStaffId);
    const isSalesUser = useCare1AppSelector(store => store.doctorSlice.isSalesUser);
    const isScienceUser = useCare1AppSelector(store => store.doctorSlice.isScienceUser);

    const editButtonText = isSalesUser ? 'Edit Sales' : isScienceUser ? 'Edit Science User' :  'Edit Admin';

    const skipQuery = id && isVisible ? false : true;

    const {data} = useGetStaffDetailQuery(id!, {skip: skipQuery });
   
    const [editAdmin] = useEditStaffMutation();

    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const handleModalClose = () => {
        dispatch(setShowStaffEditModal(false));
        dispatch(setIsSalesUser(false));
        dispatch(setIsScienceUser(false));
    };

    const editButton = (
        <Button
            key='save-button'
            onClick={() => form.submit()}
            className='save-button'
            icon={<SaveOutlined />}
        >
            {editButtonText}
        </Button>
    );

    const closeButton = (
        <Button
            key='close-button'
            onClick={handleModalClose}
            className='close-button'
        >
            Cancel
        </Button>
    );

    useEffect(() => {
        // update the form values based on omd data just fetched from useGetOmdDetailQuery hook
        form.setFieldsValue({
            ...data,
        });
    }, [data, form])

    const onFinish = async (values: Omit<INewStaffFormItems, 'confirm_password'>) => {
        try {
            const { user_status, ...rest } = values;
            const adminData: IEditStaffData = {
                ...rest,
                is_active: values.user_status.includes('active'),
                is_superuser: values.user_status.includes('superuser'),
                key: id!,
            }
            const result = await editAdmin(adminData).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: `${isSalesUser ? 'Sales' : isScienceUser ? 'Science User' : 'Admin'} successfully edited.`,
                    onOk: () => {
                        handleModalClose();
                    }
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    return (
        <Modal
            className='new-staff-modal staff-edit-modal'
            open={isVisible}
            closable={false}
            title={editButtonText}
            footer={[closeButton, editButton]}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <Form
                form={form}
                labelCol={{span:5}}
                wrapperCol={{span: 18}}
                className='new-staff-modal-form'
                colon={false}
                requiredMark={false}
                onFinish={onFinish}
            >
                <Form.Item
                    label={`First Name`}
                    name='first_name'
                    initialValue={''}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={`Last Name`}
                    name='last_name'
                    initialValue={''}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Email'
                    name='email'
                    rules={[{
                        type:'email',
                        message: 'Email is not valid',
                    }]}
                    initialValue={''}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={`User Name`}
                    name='username'
                    rules={[{
                        required: true,
                        message: buildRequiredErrorMessage('User Name') }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={`Password`}
                >
                    <Button
                        type='primary'
                        onClick={() => dispatch(setShowPasswordModal(true))}
                    >Edit Password</Button>
                </Form.Item>
                
                <Form.Item
                    label='User Status/Type'
                    name='user_status'
                    initialValue={['active']}
                >
                    <Checkbox.Group className='user-status-type'>
                        <Row>
                            <Col span={12}>
                                <Checkbox value="active">
                                Active
                                </Checkbox>
                            </Col>
                            {
                                (!isSalesUser && !isScienceUser) && 
                                <Col span={12}>
                                    <Checkbox value="superuser">
                                    Superuser
                                    </Checkbox>
                                </Col>
                            }
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default StaffEditModal;
