import React from 'react';
import { Col, InputNumber, Popconfirm, Row, Select } from 'antd';
import { deleteIOPHistoryEntry, editIOPHistoryEntry } from '../../reducers/patient-exam-slice';
import { IIopHistoryItem } from '../../reducers/patient-exam-slice';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useCare1AppDispatch } from '../../apps/care1-hooks';
import { DefaultOptionType } from 'antd/lib/select';

const { Option } = Select;

type ComponentProps = {
    data: IIopHistoryItem,
    index: number,
    glcDropsList: string[][],
    eyeTypes: string[][],
    size: SizeType,
    disabled: boolean,
    procedureList: string[][],
    machinesList: string[][],
}

const PatientExamIOPHistoryEntryReferralLetter = ({ data, index, glcDropsList, eyeTypes,
    size, disabled }: ComponentProps) => {
    
    const dispatch = useCare1AppDispatch();

    const handleChange = (field: keyof IIopHistoryItem, value: number | null | string) => {
        dispatch(editIOPHistoryEntry({
            editIndex:index, field, value: value ?? ''
        }));
    }

    const handleSelect = (value: string, event: DefaultOptionType) => {
        const field = event.props.rel;
        dispatch(editIOPHistoryEntry({
            editIndex:index, field, value
        }));
    }

    const handleMultiSelectChange = (field: keyof IIopHistoryItem, value: string[]) => {
        // dispatch(editIOPHistoryEntry(index, field, value));
        dispatch(editIOPHistoryEntry({
            editIndex:index, field, value
        }));
    }

    const isAllEntriesEmpty = !(data && (data.eye_select || data.low_iop || data.high_iop || (
        data.iop_history_drops_select && data.iop_history_drops_select.length > 0)
        || data.machine_select || data.iop_history_procedure_prefix || (data.iop_history_procedure_select
        && data.iop_history_procedure_select.length > 0)));

    return (
        <Row gutter={5} className="div-tag-entry">
            <Col span={4}>
                <Select
                    showSearch
                    size={size}
                    onSelect={(value, event) => handleSelect(value, event)}
                    value={(data && data.eye_select && data.eye_select === '') ? null : data.eye_select}
                >
                    {eyeTypes && eyeTypes.map((option) => (
                        <Option
                            rel="eye_select"
                            key={option[1]}
                            value={option[0]}
                        >
                            {option[1]}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col span={4}>
                <InputNumber
                    className="low-iop"
                    size={size}
                    disabled={disabled}
                    min={0}
                    max={70}
                    step={1}
                    value={data.low_iop}
                    onChange={(val) => handleChange('low_iop', val)}
                    style={(data && data.low_iop) || isAllEntriesEmpty ? {} : { border: '1px solid red' }}
                />
            </Col>
            <Col span={1}>-</Col>
            <Col span={4}>
                <InputNumber
                    className="high-iop"
                    size={size}
                    disabled={disabled}
                    min={0}
                    max={70}
                    step={1}
                    value={data.high_iop}
                    onChange={(val) => handleChange('high_iop', val)}
                />
            </Col>

            <Col span={7}>
                <Select
                    showSearch
                    mode="multiple"
                    size={size}
                    onChange={(val) => handleMultiSelectChange('iop_history_drops_select', val)}
                    value={data.iop_history_drops_select ? data.iop_history_drops_select : []}
                >
                    {glcDropsList && glcDropsList.map((option) => (
                        <Option
                            key={option[1]}
                            value={option[0]}
                        >
                            {option[1]}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col span={1} className="delete">
                <Popconfirm title="Are you sure？" onConfirm={() => { dispatch(deleteIOPHistoryEntry(index)); }}>X</Popconfirm>
            </Col>
        </Row>
    );
}
export default PatientExamIOPHistoryEntryReferralLetter;