import { ReactNode, useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import dayjs from 'dayjs';

import { localizedText } from '../../localizedText';
import { ISalesIntegrationReportSummary, ISalesIntegrationReportSummaryGroupBy } from '../../reducers/sales-integration-report-slice';
import '../../../static/css/components/sales-integration-report-table.scss';


interface ISalesIntegrationReportSummaryGroupByWithKey extends ISalesIntegrationReportSummaryGroupBy {
    key: string | number;
}

interface ISalesIntegrationReportSummaryGroupByProvinceWithKey extends ISalesIntegrationReportSummaryGroupByWithKey {
    province: string;
}

interface ISalesIntegrationReportSummaryGroupByProvinceOdGroupPracticeWithKey extends ISalesIntegrationReportSummaryGroupByProvinceWithKey {
    odgrouppractice: string;
}

interface ComponentProps {
    salesIntegrationReportSummary: ISalesIntegrationReportSummary | undefined;
    salesIntegrationWeekColumn: string[];
}

const SalesIntegrationReportSummaryTable = ({ salesIntegrationReportSummary, salesIntegrationWeekColumn }: ComponentProps) => {

    const { OF_TEXT, ITEMS_TEXT } = localizedText;

    const [scrollClass, setScrollClass] = useState('');

    const getFirstLevelData = () => {
        const data: ISalesIntegrationReportSummaryGroupByWithKey[] = [];
        if (salesIntegrationReportSummary !== undefined && salesIntegrationReportSummary && salesIntegrationReportSummary.group_by) {
            if ('id' in salesIntegrationReportSummary.group_by) {
                data.push({
                    ...salesIntegrationReportSummary.group_by, 
                    key:salesIntegrationReportSummary.group_by.id
                });
            }
        }
        return data;
    }

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const formatDateRange = (sunday: string): string => {
        const startDate = dayjs(sunday).format('YYYY-MM-DD');
        const endDate = dayjs(sunday).add(6, 'days').format('YYYY-MM-DD');

        const startMoment = dayjs(startDate);
        const endMoment = dayjs(endDate);

        // Format start and end dates
        const startFormatted = startMoment.format('MMM D');
        const startFormattedYear = startMoment.format('MMM D YYYY');
        const endFormatted = endMoment.format('MMM D YYYY');

        // Check if the year is the same for start and end dates
        const isSameYear = startMoment.year() === endMoment.year();

        // Create the final formatted date range
        return isSameYear ? `${startFormatted} - ${endFormatted}` : `${startFormattedYear} - ${endFormatted}`;
    };

    const columns: TableColumnsType<ISalesIntegrationReportSummaryGroupByWithKey> = [];

    // add week column
    salesIntegrationWeekColumn.forEach((v, i) => {
        columns.push({
            className: `${v}-column childColumn`,
            title: formatDateRange(v),
            dataIndex: v,
            width: '17%',
            render: (text, record) => <div data-testid={`${v}-${record.id}`}>{text === 0 ? '' : text}</div>
        });
    });


    const expandedGroupByProvinceOdGroupPracticeRender = (record: ISalesIntegrationReportSummaryGroupByProvinceWithKey, index: number, indent: number, expanded: boolean): ReactNode => {

        const groupByProvinceOdGroupPracticeColumns: TableColumnsType<ISalesIntegrationReportSummaryGroupByProvinceOdGroupPracticeWithKey> = [{
            fixed: 'left',
            className: 'province-column',
            title: 'Province',
            dataIndex: 'province',
            width: '8%',
            // sorter: (a, b) => compareStrings(a['province'], b['province']),
            render: (text, record) => <div data-testid={`province-${record.id}`}>{text}</div>
        }, {
            fixed: 'left',
            className: 'odgrouppractice-column',
            title: 'OD Group Practice',
            dataIndex: 'odgrouppractice',
            width: '20%',
            // sorter: (a, b) => compareStrings(a['odgrouppractice'], b['odgrouppractice']),
            render: (text, record) => <div data-testid={`odgrouppractice-${record.id}`}>{text}</div>
        }];

        // add week column
        salesIntegrationWeekColumn.forEach((v, i) => {
            groupByProvinceOdGroupPracticeColumns.push({
                className: `${v}-column`,
                title: formatDateRange(v),
                dataIndex: v,
                render: (text, record) => <div data-testid={`${v}-${record.id}`}>{text === 0 ? '' : text}</div>
            });
        });

        const groupByProvinceOdGroupPracticeData : ISalesIntegrationReportSummaryGroupByProvinceOdGroupPracticeWithKey[] = [];
        if (salesIntegrationReportSummary !== undefined) {

            for (const province in salesIntegrationReportSummary.group_by_province_odgrouppractice) {
                if (province === record['province'] && expanded) {
                    for (const odgrouppractice in salesIntegrationReportSummary.group_by_province_odgrouppractice[province]) {
                        groupByProvinceOdGroupPracticeData.push({
                            province,
                            odgrouppractice,
                            ...salesIntegrationReportSummary.group_by_province_odgrouppractice[province][odgrouppractice],
                            key: odgrouppractice
                        });
                    }
                }
            }            
        }

        return <Table columns={groupByProvinceOdGroupPracticeColumns} dataSource={groupByProvinceOdGroupPracticeData} pagination={false}
                    sticky={true} />;
    }

    const expandedGroupByProvinceRender = (record: ISalesIntegrationReportSummaryGroupByWithKey, index: number, indent: number, expanded: boolean): ReactNode => {

        const groupByProvinceColumns: TableColumnsType<ISalesIntegrationReportSummaryGroupByProvinceWithKey> = [{
            fixed: 'left',
            className: 'province-column',
            title: 'Province',
            dataIndex: 'province',
            width: '27.2%',
            // sorter: (a, b) => compareStrings(a['province'], b['province']),
            render: (text, record) => <div data-testid={`province-${record.id}`}>{text}</div>
        }];

        // add week column
        salesIntegrationWeekColumn.forEach((v, i) => {
            groupByProvinceColumns.push({
                className: `${v}-column`,
                title: formatDateRange(v),
                dataIndex: v,
                render: (text, record) => <div data-testid={`${v}-${record.id}`}>{text === 0 ? '' : text}</div>
            });
        });

        const groupByProvinceData : ISalesIntegrationReportSummaryGroupByProvinceWithKey[] = [];
        if (salesIntegrationReportSummary !== undefined) {

            for (const province in salesIntegrationReportSummary.group_by_province) {
                if ('id' in salesIntegrationReportSummary.group_by_province[province]) {
                    groupByProvinceData.push({
                        province: province,
                        ...salesIntegrationReportSummary.group_by_province[province], 
                        key: province
                    });
                }
            }            
        }

        return <Table 
            columns={groupByProvinceColumns} 
            expandable={{ expandedRowRender: expandedGroupByProvinceOdGroupPracticeRender }}
            dataSource={groupByProvinceData} pagination={false} sticky={true} />;
    }

    return (
        <div className={'patient-list-table sales-integration-report-table salesReportTable'}>
            <div className={scrollClass}>
                <Table className='tableContent'
                    columns={columns}
                    expandable={{ expandedRowRender: expandedGroupByProvinceRender }}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 30,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    dataSource={getFirstLevelData()}
                    scroll={{ x: salesIntegrationWeekColumn.length * 170 }}
                    sticky={true}
                />
            </div>
        </div>
    );

}

export default SalesIntegrationReportSummaryTable;
