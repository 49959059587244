import { Card, Col, Row } from 'antd';

// Components
import GptPatientExamBCVA from './gpt-patient-exam-bcva';
import GptPatientExamIOP from './gpt-patient-exam-iop';
import GptPatientExamIOPInstrument from './gpt-patient-exam-iop-instrument';
import GptPatientExamVertCD from './gpt-patient-exam-vert-cd';
import GptPatientExamPachs from './gpt-patient-exam-pachs';
import GptPatientExamRx from './gpt-patient-exam-rx';

// Helpers
import '../../../../static/css/components/patient-exam-metrics.scss';
import { localizedText } from '../../../localizedText';

type ComponentProps = {
    disabled: boolean;
    iopRight: string | number | null;
    iopLeft: string | number | null;
    sideIopRightAim: string | number | null;
    sideIopLeftAim: string | number | null;
    onIopRightChange: (value: number | string | null) => void;
    onIopLeftChange: (value: number | string | null) => void;
    applanation: string;
    onApplanationSelect: (value: string) => void;
    sideRightBCVA: string;
    sideLeftBCVA: string;
    onSideRightBCVASelect: (value: string) => void;
    onSideLeftBCVASelect: (value: string) => void;
    sideRightVertCD: string | number | null;
    sideLeftVertCD: string | number | null;
    onVertCDRightChange: (value: number | string | null) => void;
    onVertCDLeftChange: (value: number | string | null) => void;
    pachsRight: string | number | null;
    pachsLeft: string | number | null;
    onPachsRightChange: (value: number | string | null) => void;
    onPachsLeftChange: (value: number | string | null) => void;
    sideRightSphere: string | number | null,
    onSphereRightChange: (value: number | string | null) => void;
    sideRightCylinder: string | number | null,
    onCylinderRightChange: (value: number | string | null) => void;
    sideRightAxis: string | number | null,
    onAxisRightChange: (value: number | string | null) => void;
    sideLeftSphere: string | number | null,
    onSphereLeftChange: (value: number | string | null) => void;
    sideLeftCylinder: string | number | null,
    onCylinderLeftChange: (value: number | string | null) => void;
    sideLeftAxis: string | number | null,
    onAxisLeftChange: (value: number | string | null) => void;
    gptResultState: {[x: string]: string};
}

const GptPatientExamMetrics = ({ disabled, iopRight, iopLeft, sideIopRightAim, sideIopLeftAim, onIopRightChange, onIopLeftChange,
    applanation, onApplanationSelect,
    sideRightBCVA, sideLeftBCVA, onSideRightBCVASelect, onSideLeftBCVASelect,
    sideRightVertCD, sideLeftVertCD, onVertCDRightChange, onVertCDLeftChange,
    pachsRight, pachsLeft, onPachsRightChange, onPachsLeftChange,
    sideRightSphere, onSphereRightChange, sideRightCylinder, onCylinderRightChange, sideRightAxis, onAxisRightChange,
    sideLeftSphere, onSphereLeftChange, sideLeftCylinder, onCylinderLeftChange, sideLeftAxis, onAxisLeftChange,
    gptResultState }: ComponentProps) => {

    const { INSTRUMENT_TEXT, BCVA_TEXT, IOP_TEXT, PACHS_TEXT, VERT_CD_TEXT, RX_TEXT } = localizedText;

    return (
        <Card className={'patient-exam-metrics gpt-patient-exam-metrics'} bordered={false}>
            <Row>
                <Col span={3}>
                    <Row className="heading-row">
                        <p className={`heading warning-asterisk`}>{IOP_TEXT}</p>
                    </Row>
                    <Row className={'metric-row iop-entry'}>
                        <GptPatientExamIOP
                            sideIOP={iopRight}
                            sideIopAim={sideIopRightAim}
                            onChange={onIopRightChange}
                            gptResultState={gptResultState['iopRight']}
                        />
                    </Row>
                    <Row className={'metric-row iop-entry'}>
                        <GptPatientExamIOP
                            sideIOP={iopLeft}
                            sideIopAim={sideIopLeftAim}
                            onChange={onIopLeftChange}
                            gptResultState={gptResultState['iopLeft']}
                        />
                    </Row>
                </Col>
                <Col span={4}>
                    <Row className="heading-row">
                        <p className="heading">{INSTRUMENT_TEXT}</p>
                    </Row>
                    <Row className={'metric-row mt-12px'}>
                        <GptPatientExamIOPInstrument
                            applanation={applanation}
                            onSelect={onApplanationSelect}
                            disabled={disabled}
                            verbose={true}
                            narrowColumn={false}
                            gptResultState={gptResultState['applanation']}
                        />
                    </Row>
                </Col>                

                <Col span={3}>
                    <Row className="heading-row">
                            <p className="heading warning-asterisk">{PACHS_TEXT}</p>
                        </Row>
                        <Row className={'metric-row'}>
                            <GptPatientExamPachs 
                                sidePachs={pachsRight}
                                onChange={onPachsRightChange}
                                gptResultState={gptResultState['pachsRight']}
                            />
                        </Row>
                        <Row className={'metric-row'}>
                            <GptPatientExamPachs 
                                sidePachs={pachsLeft}
                                onChange={onPachsLeftChange}
                                gptResultState={gptResultState['pachsLeft']}
                            />
                        </Row>                    
                </Col>

                <Col span={3}>
                    <Row className="heading-row">
                        <p className="heading vert-cd warning-asterisk">{VERT_CD_TEXT}</p>
                    </Row>
                    <Row className={'metric-row'}>
                        <GptPatientExamVertCD
                            sideValue={sideRightVertCD}
                            onChange={onVertCDRightChange}
                            gptResultState={gptResultState['sideRightVertCD']}
                        />
                    </Row>
                    <Row className={'metric-row'}>
                        <GptPatientExamVertCD
                            sideValue={sideLeftVertCD}
                            onChange={onVertCDLeftChange}
                            gptResultState={gptResultState['sideLeftVertCD']}
                        />
                    </Row>
                </Col>

                <Col span={7}>
                    <Row className="heading-row">
                        <p className="heading warning-asterisk">
                        {RX_TEXT}
                        </p>                        
                    </Row>
                    <Row className={'metric-row'}>
                        <GptPatientExamRx 
                            sideSphere={sideRightSphere}
                            onSphereChange={onSphereRightChange}
                            sideCylinder={sideRightCylinder}
                            onCylinderChange={onCylinderRightChange}
                            sideAxis={sideRightAxis}
                            onAxisChange={onAxisRightChange}
                            gptResultState={{
                                sphere: gptResultState['sphereRight'],
                                cylinder: gptResultState['cylinderRight'],
                                axis: gptResultState['axisRight'],
                            }}
                        />
                    </Row>
                    <Row className={'metric-row'}>
                        <GptPatientExamRx 
                            sideSphere={sideLeftSphere}
                            onSphereChange={onSphereLeftChange}
                            sideCylinder={sideLeftCylinder}
                            onCylinderChange={onCylinderLeftChange}
                            sideAxis={sideLeftAxis}
                            onAxisChange={onAxisLeftChange}
                            gptResultState={{
                                sphere: gptResultState['sphereLeft'],
                                cylinder: gptResultState['cylinderLeft'],
                                axis: gptResultState['axisLeft'],
                            }}
                        />
                    </Row>
                </Col>

                <Col span={3}>
                    <Row className="heading-row">
                        <p className="heading warning-asterisk">{BCVA_TEXT}</p>
                    </Row>
                    <Row className={'metric-row'}>
                        <GptPatientExamBCVA
                            disabled={disabled}
                            side='od'
                            sideBCVA={sideRightBCVA}
                            onSelect={onSideRightBCVASelect}
                            shortDisplay
                            gptResultState={gptResultState['sideRightBCVA']}
                        />
                    </Row>
                    <Row className={'metric-row'}>
                        <GptPatientExamBCVA
                            disabled={disabled}
                            side='os'
                            sideBCVA={sideLeftBCVA}
                            onSelect={onSideLeftBCVASelect}
                            shortDisplay
                            gptResultState={gptResultState['sideLeftBCVA']}
                        />
                    </Row>
                </Col>
            </Row>
        </Card>
    );
}

export default GptPatientExamMetrics;