import { useCare1AppSelector, useCare1AppDispatch } from '../../apps/care1-hooks';
import { Typography, Select, Flex, Tooltip, Row, Col } from 'antd';

import { useGetDefaultOmdQuery, useGetOmdOptionsQuery } from '../../services/doctor-api';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import OutboundOmdEditModal from '../admin/outbound-omd-edit-modal';
import { toggleOutboundOmdAddModal, toggleOutboundOmdEditModal } from '../../reducers/doctor-slice';
import OutboundOmdAddModal from '../admin/outbound-omd-add-modal';
import styles from '../../../static/css/components/patient-exam-upload-type.module.scss';
import { setExamDataValue } from '../../reducers/patient-exam-slice';


const OutboundReferralOmd = ({ disabled }: { disabled: boolean }) => {

    const isOutboundReferral = useCare1AppSelector(store => store.examData.is_outbound_referral);
    const referralOmd = useCare1AppSelector(store => store.examData.referral_omd);
    const showOutboundOmdEditModal = useCare1AppSelector(store => store.doctorSlice.showOutboundOmdEditModal);
    const showOutboundOmdAddModal = useCare1AppSelector(store => store.doctorSlice.showOutboundOmdAddModal);


    const examId = useCare1AppSelector(store => store.examData.id);
    const odId = useCare1AppSelector(store => store.examData.optometrist);
    const dispatch = useCare1AppDispatch();

    const {data: omdOptions} = useGetOmdOptionsQuery(examId!, {skip: !isOutboundReferral});
    useGetDefaultOmdQuery(odId!, {skip: (!isOutboundReferral || !odId || Boolean(referralOmd))});


    return (
        <>
            <Row justify='space-between' align='middle'>
                <Col span={10}>
                    <Flex gap='small' align='center'>
                        <Typography.Text>Send Referral To</Typography.Text>
                        <Tooltip title='Add New OMD'>
                            <PlusOutlined
                                className={styles.referralOmdAddButton}
                                onClick={() => dispatch(toggleOutboundOmdAddModal())}
                            />
                        </Tooltip>
                        { referralOmd && !disabled &&
                            <Tooltip title='Edit OMD'>
                                <EditOutlined
                                    className={styles.referralOmdEditButton}
                                    onClick={() => dispatch(toggleOutboundOmdEditModal())} 
                                />
                            </Tooltip>
                        }
                    </Flex>
                </Col>
                
                <Col span={13}>
                    <Select
                        placeholder='Search or Enter OMD'
                        options={omdOptions}
                        showSearch
                        allowClear
                        optionFilterProp='label'
                        disabled={disabled}
                        onChange={(value) => dispatch(setExamDataValue('referral_omd', value))}
                        value={referralOmd}
                        notFoundContent={
                            <Typography.Text style={{color: '#bfdfff'}}>
                                No matching ophthalmologist found. Consider adding a new OMD.
                            </Typography.Text>}
                        />
                </Col>
                
            </Row>
            {showOutboundOmdEditModal && <OutboundOmdEditModal />}
            {showOutboundOmdAddModal && <OutboundOmdAddModal />}
        </>    
    );
}

export default OutboundReferralOmd;