import { useRef } from 'react';
import { Card, Input, Row, Tag, Tooltip, Divider } from 'antd';
import { IExamData, setExamDataValue } from '../../reducers/patient-exam-slice';
import { refreshTxAlgo3Request } from '../../reducers/txalgo3-slice';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IOP_AIM_VALUES, PATIENT_STATUS, FOLLOW_UP_AIM } from '../../constants';
import { localizedText } from '../../localizedText';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/patient-exam-omdr-iop-final-recommendations.scss';

const { CheckableTag } = Tag;

const PatientExamOMDRIOPFinalRecommendations = ()  => {
    const { IOP_FOLLOWUP_TEXT, IOP_AIM_TOOLTIP_TEXT } = localizedText;
    const osIopAim = useCare1AppSelector(store => store.examData.os_iop_aim);
    const odIopAim = useCare1AppSelector(store => store.examData.od_iop_aim);
    const patientStatus = useCare1AppSelector(store => store.examData.patient_status);
    const fuAim = useCare1AppSelector(store => store.examData.followup_aim);
    const examId = useCare1AppSelector(store => store.examData.id);
    const isGlaucoma = useCare1AppSelector(store => store.examData.is_glaucoma);
    const pastODIopAim = useCare1AppSelector(store => store.examData.past_od_iop_aim);
    const pastOSIopAim = useCare1AppSelector(store => store.examData.past_os_iop_aim);
    const odIop = useCare1AppSelector(store => store.examData.od_iop);
    const osIop = useCare1AppSelector(store => store.examData.os_iop);
    const pastODIop = useCare1AppSelector(store => store.examData.past_od_iop);
    const pastOSIop = useCare1AppSelector(store => store.examData.past_os_iop);
    const pastPatientStatus = useCare1AppSelector(store => store.examData.past_patient_status);
    const pastFuAim = useCare1AppSelector(store => store.examData.past_followup_aim);
    const autoPatientStatus = useCare1AppSelector(store => store.examData.auto_patient_status);
    const lastExamAutoPatientStatus = useCare1AppSelector(store => store.examData.last_exam_auto_patient_status);
    const examNumber = useCare1AppSelector(store => store.examData.visit_number);


    // A reference variable to indicate whether current page load is the initial load
    // it is then used to determine the BG color of 'XXX' of iop aim and follow up aim
    const initialODIopAimLoad = useRef(true);
    const initialOSIopAimLoad = useRef(true);
    const initialFuAimLoad = useRef(true);

    const dispatch = useCare1AppDispatch();

    const shouldDisplayAutoPatientStatus = (field: string) => {
        let shouldDisplayAutoPatientStatus = false;
        if (examNumber === 1){
            if (field === 'BO' && autoPatientStatus) {
                shouldDisplayAutoPatientStatus = true;
            }
        } else {
            if (patientStatus) {
                if ((field === 'BT' || field === 'BO') && autoPatientStatus){
                    shouldDisplayAutoPatientStatus = true;
                }
            } else {
                if ((field === 'BT' || field === 'BO') && lastExamAutoPatientStatus){
                    shouldDisplayAutoPatientStatus = true;
                }
            }
        }
        return shouldDisplayAutoPatientStatus;
    }

    const getIopAimClassName = (side: string, field: string) => {
        const iopClassName = [];
        const iopAimValue = side === 'od' ? odIopAim : osIopAim;
        const pastIopAimValue = side === 'od' ? pastODIopAim : pastOSIopAim;
        const initialLoad = side === 'od' ? initialODIopAimLoad : initialOSIopAimLoad;
        // Iop Aim is disabled for NON-glaucoma exam
        !isGlaucoma && iopClassName.push('is-disabled');

        /*
        The background color for XXX and other iop aim selections (14,17,21,24)
        1. When the page first loads (i.e. iop aim has not changed yet), if the iop aim is 'XXX', the
           background-color of 'XXX' should be yellow, other selections are '#52db9d'
        2. once iop aim value changes, ALL the selections should have background-color '#52db9d'
        3. font-color for iop aim
           3.1 selected -> #192013
           3.2 not-selected -> #52db9d
           3.3 past-iop-aim -> #a9a9a9
        */
        if(initialLoad.current && field === 'XXX' && (iopAimValue === 'XXX' || iopAimValue === '')) {
            iopClassName.push('empty-iop-aim')
        }
        if(pastIopAimValue && (iopAimValue !== pastIopAimValue) && (field === pastIopAimValue)) {
            iopClassName.push('past-value')
        }
        if(field === 'XXX'){
            iopClassName.push('not-selected-xxx')
        }

        return iopClassName.join(' ');
    }

    const getPatientStatusClassName = (field: string) => {
        const patientStatusClassName = [];
        // patient status is disabled for NON-glaucoma exam
        !isGlaucoma && patientStatusClassName.push('is-disabled');

        if(pastPatientStatus && (patientStatus !== pastPatientStatus) && (pastPatientStatus === field)){
            patientStatusClassName.push('past-value')
        }

        if (patientStatus === 'XXX') {
            patientStatusClassName.push('patient-status-empty');
        } else if (patientStatus === 'P') {
            const odIopValue = odIop ? odIop : pastODIop;
            const osIopValue = osIop ? osIop : pastOSIop;
            if (odIopValue === '' || osIopValue === '') {
                patientStatusClassName.push('patient-status-alert');
            } else {
                patientStatusClassName.push('patient-status-normal');
            }
        } else {
            patientStatusClassName.push('patient-status-alert');
        }
        return patientStatusClassName.join(' ');
    }

    const getFollowUpAimClassName = (field: string) => {
        const followUpAimClassName = [];
        // follow up aim is disabled for NON-glaucoma exam
        !isGlaucoma && followUpAimClassName.push('is-disabled');
        // same logic as iop aim 'XXX' value
        if(initialFuAimLoad.current && field === 'XXX' && (fuAim === 'XXX' || fuAim === '')) {
            followUpAimClassName.push('followup-aim-empty');
        }

        if(pastFuAim && (pastFuAim !== fuAim) && (pastFuAim === field)){
            followUpAimClassName.push('past-value');
        }
        return followUpAimClassName.join(' ');
    }

    const onIopAimSelectionChange = (checked: boolean, field: 'od_iop_aim' | 'os_iop_aim', value: string) => {
        const initialLoad = field === 'od_iop_aim' ? initialODIopAimLoad : initialOSIopAimLoad;
        if(checked && examId){
            initialLoad.current = false;
            dispatch(setExamDataValue(field, value));
            dispatch(refreshTxAlgo3Request({exam_id: examId, [field] : value}));
        }
    }

    const onIopAimInputChange = (field: string, { target: { value }}: {target: {value: string}}) => {

        const initialLoad = field === 'od_iop_aim' ? initialODIopAimLoad : initialOSIopAimLoad;
        // when OD/OS iop aim is 'XXX', press a number input or BACKSPACE will remove all 'X' in
        // OD/OS iop aim
        const nonDigitRegex = /\D/g
        if (field === 'od_iop_aim' && odIopAim === 'XXX'){
            initialLoad.current = false;
            dispatch(setExamDataValue(field, value.replace(nonDigitRegex, '')));
        }
        if (field === 'os_iop_aim' && osIopAim === 'XXX'){
            initialLoad.current = false;
            dispatch(setExamDataValue(field, value.replace(nonDigitRegex, '')));
        }

        // accepted inputs are 1 or 2 digits integer number
        const re = /^\d{1,2}$/;
        if (re.test(value)){
            initialLoad.current = false;
            dispatch(setExamDataValue(field as keyof IExamData, value));
        } else if ( value === ''){
            initialLoad.current = false;
            dispatch(setExamDataValue(field as keyof IExamData, value));
        }
    }

    const handleBlur = (field: string, { target: { value }}: {target: {value: string}}) => {
        if(examId){
            dispatch(refreshTxAlgo3Request({exam_id: examId, [field] : value}));
        }
    }

    const onPatientStatusChange = (checked: boolean, value: string) => {
        if(checked) {
            dispatch(setExamDataValue('patient_status', value));
        }
    }

    const onFollowupChange = (checked: boolean, value: string) => {
        if (checked) {
            initialFuAimLoad.current = false;
            dispatch(setExamDataValue('followup_aim', value));
        }
    }
    return (
        <Card className={'component-input-card iop-final-recommendations'} bordered={false}>
            <Row>
                <div className="heading">
                    {IOP_FOLLOWUP_TEXT}
                    <Tooltip placement='topRight' title={IOP_AIM_TOOLTIP_TEXT}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
            </Row>
            <div className='component-body'>
                <div className='iop-aim'>
                    {
                        IOP_AIM_VALUES.map((iop_aim) =>
                            <CheckableTag
                                checked={odIopAim === iop_aim}
                                key={`od_iop_aim_${iop_aim}`}
                                onChange={checked => onIopAimSelectionChange(checked, 'od_iop_aim', iop_aim)}
                                className={getIopAimClassName('od', iop_aim)}
                                data-testid={`od_iop_aim_${iop_aim}`}
                            >
                                {iop_aim ? iop_aim : 'XXX'}
                            </CheckableTag>)
                    }
                    <Input
                        className={'selected-iop-input'}
                        value={odIopAim}
                        onChange={e => onIopAimInputChange('od_iop_aim', e)}
                        bordered={false}
                        onBlur={e => handleBlur('os_iop_aim', e)}
                        disabled={!isGlaucoma}
                        data-testid='od_iop_aim_input'
                    />
                </div>

                <Divider className={'divider divider-one'} type="vertical" />

                <div className='patient-status'>
                    {PATIENT_STATUS.map(status =>
                        <CheckableTag
                            checked={status === patientStatus}
                            key={`patient_status_${status}`}
                            onChange={checked => onPatientStatusChange(checked, status)}
                            className={getPatientStatusClassName(status)}
                            data-testid={`patient_status_${status}`}
                        >
                            {status}
                            {shouldDisplayAutoPatientStatus(status) && '*'}
                        </CheckableTag>)
                    }
                </div>

                <Divider className={'divider divider-two'} type="vertical" />

                <div className='followup-aim'>
                    {FOLLOW_UP_AIM.map(({key,display}) =>
                        <CheckableTag
                            checked={key === fuAim}
                            key={`followup_aim_${key}`}
                            onChange={checked => onFollowupChange(checked, key)}
                            className={getFollowUpAimClassName(key)}
                            data-testid={`followup_aim_${key}`}
                        >
                            {display}
                        </CheckableTag>)
                    }
                </div>

                <Divider className={'divider divider-three'} type="vertical" />

                <div className='iop-aim'>
                    {
                        IOP_AIM_VALUES.map((iop_aim) =>
                            <CheckableTag
                                checked={osIopAim === iop_aim}
                                key={`os_iop_aim_${iop_aim}`}
                                onChange={checked => onIopAimSelectionChange(checked, 'os_iop_aim', iop_aim)}
                                className={getIopAimClassName('os', iop_aim)}
                                data-testid={`os_iop_aim_${iop_aim}`}
                            >
                                {iop_aim ? iop_aim : 'XXX'}
                            </CheckableTag>)
                    }
                    <Input
                        className={'selected-iop-input'}
                        value={osIopAim}
                        onChange={e => onIopAimInputChange('os_iop_aim', e)}
                        bordered={false}
                        onBlur={e => handleBlur('os_iop_aim', e)}
                        disabled={!isGlaucoma}
                        data-testid='os_iop_aim_input'
                    />
                </div>
            </div>
        </Card>
    );

}

export default PatientExamOMDRIOPFinalRecommendations;