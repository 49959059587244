import React, { useEffect, useState } from 'react';
import { Table, Space, Button, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../static/css/components/admin-doctor-list-table.scss';
import { EditOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { compareStrings } from '../../helpers/sorting';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { FIRST_NAME_FIELD, LAST_NAME_FIELD } from '../../constants';
import { useDeleteDoctorMutation } from '../../services/doctor-api';
import { DoctorListMenuType, setCurrentIgp, setCurrentOd, setCurrentOmd, setShowIgpEditModal, setShowOdEditModal, setShowOmdEditModal } from '../../reducers/doctor-slice';
import { localizedText } from '../../localizedText';

interface IDoctorListTable {
    key: React.Key;
    [LAST_NAME_FIELD]: string;
    [FIRST_NAME_FIELD]: string;
    username: string;
    odgrouppractice: string;
    omdnetwork: string;
    telephone: string;
    group?: string;
    id: React.Key;
    fax?: string;
    region?: string;
    hidden_from_dropdown?: boolean,
    clinic_location?: string;
}

type AppProps = {
    tableList: IDoctorListTable[],
    type: Exclude<DoctorListMenuType, 'staff' | 'technician'>,
}

interface IDoctorListSearch {
    username: string;
    first_name: string;
    last_name: string;
}

const AdminDoctorListTable = ({tableList, type} : AppProps) => {
    const { DOCTORS_LIST_ACTION, FIRST_NAME_HEADING, LAST_NAME_HEADING, DOCTORS_LIST_OMD_NETWORK,
        DOCTORS_LIST_PRACTICES_GROUP, OF_TEXT, ITEMS_TEXT, USERNAME_HEADING, PHONE_NUMBER_TEXT
    } = localizedText;
    const searchQuery:string = useCare1AppSelector(store => store.doctorSlice.searchQuery);

    const [deleteDoctor] = useDeleteDoctorMutation();

    const dispatch = useCare1AppDispatch();
    
    const [scrollClass, setScrollClass] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const doctorList = tableList?.filter(({username, first_name, last_name}: IDoctorListSearch) => (
        username.toLowerCase().includes(searchQuery) ||
        first_name.toLowerCase().includes(searchQuery) ||
        last_name.toLowerCase().includes(searchQuery)
    ));

    const onTableRowEditClick = (record: IDoctorListTable) => {

        if (record.group === 'ophthalmologists') {
            dispatch(setCurrentOmd(record.id));
            dispatch(setShowOmdEditModal(true));
        }
        else if (record.group === 'optometrists') {
            dispatch(setCurrentOd(record.id));
            dispatch(setShowOdEditModal(true));
        }
        else if (record.group === 'internal_gps') {
            dispatch(setCurrentIgp(record.id));
            dispatch(setShowIgpEditModal(true));
        }
    }

    const onDeleteDoctorClick = (record: IDoctorListTable) => {
        Modal.confirm({
            className: 'delete-doctor-confirmation-modal',
            icon: <InfoCircleOutlined />,
            title: 'Deleting a doctor will remove all their information. Are you sure you want to delete this doctor?',
            onOk: async () => {
                try {
                    const result = await deleteDoctor(record.id).unwrap();
                    if (result.success) {
                        Modal.info({
                            className: 'info-modal',
                            title: 'Doctor is successfully deleted.',
                        });
                    } else {
                        throw new Error(result?.error);
                    }
                } catch (error) {
                    const message = (error instanceof Error) ?  error?.message : error;
                    Modal.error({
                        className: 'info-modal',
                        content: message as string,
                        title: 'Error',
                    });
                }
                
            },
        })
    }

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }
    const nameColumns: ColumnsType<IDoctorListTable> = [
        {
            className: 'last-name-column',
            title: LAST_NAME_HEADING,
            dataIndex: LAST_NAME_FIELD,
            sorter: (a, b) => compareStrings(a[LAST_NAME_FIELD], b[LAST_NAME_FIELD]),
            render: (text, record) => <div data-testid={`last-name-${record.key}`}>{text}</div>
        }, {
            className: 'first-name-column',
            title: FIRST_NAME_HEADING,
            dataIndex: FIRST_NAME_FIELD,
            sorter: (a, b) => compareStrings(a[FIRST_NAME_FIELD], b[FIRST_NAME_FIELD]),
            render: (text, record) => <div data-testid={`first-name-${record.key}`}>{text}</div>
        }, {
            className: 'username-column',
            title: USERNAME_HEADING,
            dataIndex: 'username',
            sorter: (a, b) => compareStrings(a['username'], b['username']),
            render: (text, record) => <div data-testid={`username-${record.key}`}>{text}</div>
        }
    ];
    const actionColumn: ColumnsType<IDoctorListTable> = [
        {
            className: 'action-column',
            title: DOCTORS_LIST_ACTION,
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        className="edit-doctor-button"
                        onClick={(e) => onTableRowEditClick(record)}
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        className='delete-doctor-button'
                        onClick={(e) => onDeleteDoctorClick(record)}
                    >
                        <DeleteOutlined />
                    </Button>
                </Space>
            ),
        }
    ];

    const odColumns: ColumnsType<IDoctorListTable> = [
        ...nameColumns,
        {
            className: 'odgrouppractice-column',
            title: DOCTORS_LIST_PRACTICES_GROUP,
            dataIndex: 'odgrouppractice',
            sorter: (a, b) => compareStrings(a['odgrouppractice'], b['odgrouppractice']),
            render: (text, record) => <div data-testid={`odgrouppractice-${record.key}`}>{text}</div>
        }, {
            className: 'clinic-location-column',
            title: 'Clinic Location',
            dataIndex: 'clinic_location',
            sorter: (a, b) => compareStrings(a['clinic_location'], b['clinic_location']),
            render: (text, record) => <div data-testid={`clinic_location-${record.key}`}>{text}</div>
        }, {
            className: 'omdnetwork-column',
            title: DOCTORS_LIST_OMD_NETWORK,
            dataIndex: 'omdnetwork',
            sorter: (a, b) => compareStrings(a['omdnetwork'], b['omdnetwork']),
            render: (text, record) => <div data-testid={`omdnetwork-${record.key}`}>{text}</div>
        }, {
            className: 'telephone-column',
            title: PHONE_NUMBER_TEXT,
            dataIndex: 'telephone',
            sorter: (a, b) => compareStrings(a['telephone'], b['telephone']),
            render: (text, record) => <div data-testid={`total-telephone-${record.key}`}>{text}</div>
        },

        ...actionColumn
    ];

    const omdColumns: ColumnsType<IDoctorListTable> = [
        ...nameColumns,
        {
            className: 'email-column',
            title: 'Email',
            dataIndex: 'email',
            render: (text, record) => <div data-testid={`email-${record.key}`}>{text}</div>
        },{
            className: 'fax-column',
            title: 'Fax',
            dataIndex: 'fax',
            sorter: (a, b) => compareStrings(a['fax'], b['fax']),
            render: (text, record) => <div data-testid={`fax-${record.key}`}>{text}</div>
        }, {
            className: 'region-column',
            title: 'Region',
            dataIndex: 'region',
            sorter: (a, b) => compareStrings(a['region'], b['region']),
            render: (text, record) => <div data-testid={`region-${record.key}`}>{text}</div>
        }, {
            className: 'hidden-column',
            title: 'Hidden From Dropdown',
            dataIndex: 'hidden_from_dropdown',
            render: (text, record) => {
                const hiddenDisplay = !text ? 'Not Hidden': 'Hidden'; 
                return(
                    <div data-testid={`hidden_from_dropdown-${record.key}`}>{hiddenDisplay}</div>
                )
            }
        }, {
            className: 'ret-column',    
            title: 'Is Ret Specialist',
            dataIndex: 'is_ret_specialist',
            render: (text, record) => {
                const retDisplay = !text ? 'No': 'Yes'; 
                return(
                    <div data-testid={`hidden_from_dropdown-${record.key}`}>{retDisplay}</div>
                )
            }        
        }, 

        ...actionColumn
    ];

    const igpColumns: ColumnsType<IDoctorListTable> = [
        ...nameColumns,
        {
            className: 'email-column',
            title: 'Email',
            dataIndex: 'email',
            render: (text, record) => <div data-testid={`email-${record.key}`}>{text}</div>
        }, {
            className: 'telephone-column',
            title: PHONE_NUMBER_TEXT,
            dataIndex: 'telephone',
            sorter: (a, b) => compareStrings(a['telephone'], b['telephone']),
            render: (text, record) => <div data-testid={`total-telephone-${record.key}`}>{text}</div>
        },{
            className: 'fax-column',
            title: 'Fax Number',
            dataIndex: 'fax',
            sorter: (a, b) => compareStrings(a['fax'], b['fax']),
            render: (text, record) => <div data-testid={`fax-${record.key}`}>{text}</div>
        },{
            className: 'omdnetwork-column',
            title: DOCTORS_LIST_OMD_NETWORK,
            dataIndex: 'omdnetwork',
            sorter: (a, b) => compareStrings(a['omdnetwork'], b['omdnetwork']),
            render: (text, record) => <div data-testid={`omdnetwork-${record.key}`}>{text}</div>
        },

        ...actionColumn
    ];

    const columnsDict: Record<Exclude<DoctorListMenuType, 'staff' | 'technician'>, ColumnsType<IDoctorListTable>> = {
        od: odColumns,
        omd: omdColumns,
        igp: igpColumns,
        odgrouppractice: odColumns,
    }
    
    return (
        <div className={'doctor-list-table doctor-list-table-re'}>
            <div className={scrollClass}>
                <Table
                    columns={columnsDict[type]}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 30,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    dataSource={doctorList}
                />
            </div>
        </div>
    );
}

export default AdminDoctorListTable;