import React from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { ROOT_URL } from '../constants';
import { localizedText } from '../localizedText';

/*
 * The breadcrumbs component accepts a list of objects called `breadcrumbItems`
 * as a prop and renders them. Props don't need to be passed for the root page.
 * Each item can also have an optional component to be displayed in place of the title/url.
 *
 * ex:
 *     <Breadcrumb
 *         breadcrumbItems={ [{title: "Patient Page", url: "/patients", component: ReactNode}] }
 *     />
 */

export interface IBreadcrumbItem {
    key?: number;
    title?: string;
    url?: string;
    component?: React.ReactNode;
}

type BreadcrumbsProps = {
    breadcrumbItems?: IBreadcrumbItem[];
}

const Breadcrumbs = ({ breadcrumbItems }: BreadcrumbsProps = {}) => {
    const { PATIENT_LIST_TEXT } = localizedText;
    return (
    <Breadcrumb separator=">">
        <Breadcrumb.Item href="">
            <HomeOutlined className={`home-breadcrumb`} data-testid='home-breadcrumb'/>
        </Breadcrumb.Item>
        {/* NavBar 005 */}
        <Breadcrumb.Item>
            <Link className={`patient-list-breadcrumb`} data-testid='patient-list-breadcrumb' to={ROOT_URL}>{PATIENT_LIST_TEXT}</Link>
        </Breadcrumb.Item>
        {
            breadcrumbItems
            && breadcrumbItems.map((breadcrumb) => (
                <Breadcrumb.Item className="additional-breadcrumb-item" key={breadcrumb.key}>
                    {
                        breadcrumb.component
                            ? breadcrumb.component
                            : <Link to={breadcrumb.url ?? ''}>{breadcrumb.title}</Link>
                    }
                </Breadcrumb.Item>
            ))
        }
    </Breadcrumb>
    )
}

export default Breadcrumbs;