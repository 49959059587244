import React, { forwardRef, useRef, useImperativeHandle, useState, SyntheticEvent } from 'react';
import { Button } from 'antd';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import { getBackendMediaUrl } from '../../helpers/media-image-convert';
import * as Constants from '../../constants';

type ComponentProps = {
    photoUrl: string, 
    overlayUrl: string, 
    idx: string | undefined,
    left: string | number, 
    top: string |  number, 
    width: string | number, 
    height: string | number, 
    isAdmin: boolean,
    wheelEnabled: boolean,
    aiButtonEnabled: boolean,
}

const StereoViewerImage = forwardRef(({ photoUrl, overlayUrl, idx, left, top, width, height, isAdmin, wheelEnabled, aiButtonEnabled } : ComponentProps, ref) => {
    const mainSrcUrl = (photoUrl === undefined) ? '' : getBackendMediaUrl() + photoUrl;
    const overlaySrcUrl = (overlayUrl === '') ? '' : getBackendMediaUrl() + overlayUrl;
    const imageWrapperRef = useRef<ReactZoomPanPinchRef | null>(null);
    const imageRef = useRef<HTMLDivElement>(null);
    const [fullImageSize, setFullImageSize] = useState({ width: 0, height: 0 });
    const [useAiPhoto, setUseAiPhoto] = useState(true);

    // these metrics are used to determine whehter the mouse click is a clicking or dragging event
    const [mousePositionX, setMousePositionX] = useState(0);
    const [mousePositionY, setMousePositionY] = useState(0);
    const [isClick, setIsClick] = useState(false);
    const MOUSE_MOVE_THRESHOLD = 5;

    const toFixedScale = (scale: number) => parseFloat(String(scale)).toFixed(2);

    useImperativeHandle(ref, () => ({
        // Convert our saved transform values into react-zoom-pan-pinch's transform values.
        loadSavedTransform: () => {
            const target = {clientHeight: fullImageSize.height, clientWidth: fullImageSize.width};
            handleImageLoad(target as HTMLImageElement);
        },
        // Set the zoom back to full size for the image.
        resetDefaultSize: () => {
            imageWrapperRef!.current!.resetTransform();
        },
        // Get the current height, width, left, and top data for the zoomed image, using legacy application style data.
        getImageZoomData: () => {
            const positionX = imageWrapperRef!.current!.instance!.transformState.positionX;
            const positionY = imageWrapperRef!.current!.instance!.transformState.positionY;
            const scale = imageWrapperRef!.current!.instance!.transformState.scale;

            // Calculate the height and width used in legacy application data from the scale.
            const zoomedWidth = fullImageSize.width*scale;
            const zoomedHeight = fullImageSize.height*scale;
            return { left: positionX, top: positionY, width: zoomedWidth, height: zoomedHeight};
        },
    }));

    const tooglePhoto = () => {
        setUseAiPhoto(!useAiPhoto);
    }

    const handleImageLoad = (target: HTMLImageElement) => {
        // On load of the image, set our location and zoom.

        // Set the full image size so it can be used later.
        const fullImage = target
        let fullImageWidth = fullImage.clientWidth;
        let fullImageHeight = fullImage.clientHeight;
        if (fullImageWidth !== 0 && fullImageHeight !== 0) {
            setFullImageSize({width: fullImageWidth, height: fullImageHeight});
        }
        else {
            fullImageWidth = fullImageSize.width;
            fullImageHeight = fullImageSize.height;
        }
        // The scale value is the ratio of saved zoom data image size to full image size.
        const scale = width ? Number(width)/fullImageWidth : Constants.CSV_STEREO_VIEWER_WIDTH/fullImageWidth;
        const fixedScale = Number(toFixedScale(scale));
        const leftCoord = left as number;
        const topCoord = top as number;

        imageWrapperRef!.current!.setTransform(leftCoord, topCoord, fixedScale);
    }

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.type === 'click' && isClick) {
            // left click and not dragging
            imageWrapperRef!.current!.zoomIn(0.2);
        } else if (e.type==='contextmenu') {
            // right click
            e.preventDefault();        
            imageWrapperRef!.current!.zoomIn(2);
        }
    }
    
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setMousePositionX(e.pageX);
        setMousePositionY(e.pageY);
    }

    const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
        const diffX = Math.abs(e.pageX - mousePositionX);
        const diffY = Math.abs(e.pageY - mousePositionY);
        
        if (diffX < MOUSE_MOVE_THRESHOLD && diffY < MOUSE_MOVE_THRESHOLD) {
            setIsClick(true);
        } else {
            setIsClick(false);
        }
    }

    const index = idx ? idx : '';
    return (
        <div>
            {aiButtonEnabled && <div className='stereo-viewer-button-wrapper'>
                <Button
                    className={!useAiPhoto ? 'original-button' : 'original-button-not-selected'}
                    onClick={() => { tooglePhoto() }}
                >ORIGINAL</Button>
                <Button
                    type='primary'
                    className={useAiPhoto ? 'original-button' : 'original-button-not-selected'}
                    onClick={() => { tooglePhoto() }}
                >AI</Button>
            </div>}
            <TransformWrapper
                ref={imageWrapperRef}
                panning={{velocityDisabled: true}}
                limitToBounds={false}
                minScale={0}
                wheel={{disabled: !isAdmin || !wheelEnabled, step: 2}}
                pinch={{disabled: !isAdmin, step: 0.2}}
                doubleClick={{disabled: true}}
                maxScale={1000}
            >
                <React.Fragment>
                    <TransformComponent>
                        <div
                            id={index}
                            key={index + left}
                            className={`extra-image image react-draggable`}
                            ref={imageRef}
                            onClick={handleClick}
                            onContextMenu={handleClick}
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                        >
                            <div className="photo-container">
                                <img
                                    role="img"
                                    alt=""
                                    className="photo"
                                    src={mainSrcUrl}
                                    onLoad={(e: SyntheticEvent<HTMLImageElement>)=> {
                                        const target = e.target as HTMLImageElement;
                                        return handleImageLoad(target);
                                        }
                                    }
                                />
                                {(useAiPhoto && overlaySrcUrl !== '') &&
                                    <img
                                        alt=""
                                        className="photo overlay"
                                        src={overlaySrcUrl}
                                    />
                                }
                            </div>
                        </div>
                    </TransformComponent>
                </React.Fragment>
            </TransformWrapper>
        </div>
    );
});

export default StereoViewerImage;
