import { Modal, Button, Form,  Input, Checkbox, Col, Row } from 'antd';
import '../../../static/css/components/new-staff-modal.scss';

// Action imports
import { SaveOutlined } from '@ant-design/icons';
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { INewStaffData, INewStaffFormItems, useAddStaffMutation } from '../../services/staff-api';
import { setIsSalesUser, setIsScienceUser, setShowStaffAddModal } from '../../reducers/doctor-slice';

const NewStaffModal = () => {

    const isVisible = useCare1AppSelector(store => store.doctorSlice.showStaffAddModal);
    const isSalesUser = useCare1AppSelector(store => store.doctorSlice.isSalesUser);
    const isScienceUser = useCare1AppSelector(store => store.doctorSlice.isScienceUser);
   
    const [addStaff] = useAddStaffMutation();

    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const saveButtonText = isSalesUser ? 'Add Sales' : isScienceUser ? 'Add Science User' : 'Add Admin';
    const titleText = isSalesUser ? 'Add New Sales' : isScienceUser ? 'Add New Science User' : 'Add New Admin';

    const handleModalClose = () => {
        dispatch(setShowStaffAddModal(false));
        dispatch(setIsSalesUser(false));
        dispatch(setIsScienceUser(false));
    };

    const addButton = (
        <Button
            key='save-button'
            onClick={() => form.submit()}
            className='save-button'
            icon={<SaveOutlined />}
        >
            {saveButtonText}
        </Button>
    );

    const closeButton = (
        <Button
            key='close-button'
            onClick={handleModalClose}
            className='close-button'
        >
            Cancel
        </Button>
    );

    const onFinish = async (values: INewStaffFormItems) => {
        try {
            const { confirm_password, user_status, ...rest } = values;
            const adminData: INewStaffData = {
                ...rest,
                is_active: values.user_status.includes('active'),
                is_superuser: values.user_status.includes('superuser'),
                is_sales_user: isSalesUser,
                is_science_user: isScienceUser,
            }
            const result = await addStaff(adminData).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: `New ${isSalesUser ? 'Sales' : isScienceUser ? 'Science User' : 'Admin'} successfully added.`,
                    onOk: () => {
                        handleModalClose();
                    }
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    return (
        <Modal
            className='new-staff-modal'
            open={isVisible}
            closable={false}
            title={titleText}
            footer={[closeButton, addButton]}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <Form
                form={form}
                labelCol={{span:5}}
                wrapperCol={{span: 18}}
                className='new-staff-modal-form'
                colon={false}
                requiredMark={false}
                onFinish={onFinish}
            >
                <Form.Item
                    label={`First Name`}
                    name='first_name'
                    initialValue={''}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={`Last Name`}
                    name='last_name'
                    initialValue={''}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Email'
                    name='email'
                    rules={[{
                        type:'email',
                        message: 'Email is not valid',
                    }]}
                    initialValue={''}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={`User Name`}
                    name='username'
                    rules={[{
                        required: true,
                        message: buildRequiredErrorMessage('User Name') }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={`Password`}
                    name='password'
                    rules={[{
                        required: true,
                        message: buildRequiredErrorMessage('Password') }
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name='confirm_password'
                    label='Confirm Password'
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('The new password that you entered do not match!'));
                        },
                    }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label='User Status/Type'
                    name='user_status'
                    initialValue={['active']}
                >
                    <Checkbox.Group className='user-status-type'>
                        <Row>
                            <Col span={12}>
                                <Checkbox value="active">
                                Active
                                </Checkbox>
                            </Col>
                            { (!isSalesUser && !isScienceUser) &&
                                <Col span={12}>
                                    <Checkbox value="superuser">
                                    Superuser
                                    </Checkbox>
                                </Col>
                            }
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default NewStaffModal;
