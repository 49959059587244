import { baseApi } from "./base-api";


export interface IAlertListItem {
    different_exam_date: boolean,
    exam_date: string,
    exam_id: number,
    first_name: string,
    id: number,
    last_name: string,
    notes: string,
    render_exam_date: boolean,
    render_notes: boolean,
}

export interface IAlertListResponseData {
    patients: IAlertListItem[]
}

export const alertListsApi = baseApi.injectEndpoints({

    endpoints: (builder) => ({
        // builder.query <FirstArg, SecondArg>
        // FirstArg is the type of reponse expected from the api call.
        // Second arg is the type of argument passed into the query.
        getNoOMDRAssignedList: builder.query<IAlertListResponseData, void>({
            query: () => 'admin/patients/with_no_omdr_assigned/',
        }),
        getNoOMDCAssignedList: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/with_omdc_status_but_no_omdc_assigned/`,
        }),
        getNoOMDCStatusList: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/with_omdc_assigned_but_no_omdc_status/`,
        }),
        getMultipleExamsOMDCAssignedList: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/with_multiple_exams_having_an_omdc_status/`,
        }),
        getIPCStatusList: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/with_ipc_status/`,
        }),
        getIPCStatusRequestedByOMDList: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/with_ipc_status_requested_by_omd/`,
        }),
        getUrgentList: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/flagged_for_urgent_attention_by_the_od/`,
        }),
        getPrereviewOver2WeeksList: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/prereview_over_2weeks/`,
        }),
        getPrereviewOver3WeeksList: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/prereview_over_3weeks/`,
        }),
        getOMDC6MonthsList: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/omdc_6months/`,
        }),
        getBillingList: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/billing/`,
        }),
        getAlertList: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/alert/`,
        }),
        getConflictingNotesList: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/conflicting_notes_list/`,
        }),
        getOMDRNoTimestampList: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/omdr_reviewed_no_timestamp_list/`,
        }),
        getCatRefChiefComplaint: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/cat_ref_chief_complaint/`,
        }),
        getRetinaEnabledExams: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/retina_enabled_exams/`,
        }),
        getAdminNotesExams: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/admin_notes_list/`,
        }),
        getIpcOnlyExams: builder.query<IAlertListResponseData, void>({
            query: () => `admin/patients/ipc_only_list/`,
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const { useGetNoOMDRAssignedListQuery, useGetNoOMDCAssignedListQuery, useGetMultipleExamsOMDCAssignedListQuery,
    useGetIPCStatusListQuery, useGetUrgentListQuery, useGetOMDC6MonthsListQuery, useGetBillingListQuery,
    useGetAlertListQuery, useGetConflictingNotesListQuery, useGetOMDRNoTimestampListQuery,
    useGetPrereviewOver2WeeksListQuery, useGetPrereviewOver3WeeksListQuery, useGetNoOMDCStatusListQuery,
    useGetCatRefChiefComplaintQuery, useGetRetinaEnabledExamsQuery, useGetAdminNotesExamsQuery,
    useGetIPCStatusRequestedByOMDListQuery,
    useGetIpcOnlyExamsQuery,
} = alertListsApi;