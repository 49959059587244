import { useEffect, useState } from 'react';
import { Col, Row, Button, Divider, Tooltip } from 'antd';
import * as Constants from '../../constants';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import dayjs from 'dayjs';
import { FRONTEND_SHORT_DATE_FORMAT} from '../../constants';
import { AppstoreOutlined } from '@ant-design/icons';

import '../../../static/css/components/ai-insights.scss';
import AdminRetinaScan from './admin-retina-scan';
import AdminAIInsightsHistoricalAnalysis from './admin-ai-insights-historical-analysis';
import AdminAIInsightsModal from './admin-ai-insights-modal';
import PatientExamOCTMaculaImageHistory from '../image-histories/patient-exam-oct-macula-image-history';
import DeepMDLogo from '../../../static/images/logo-deepmd-colored.png';
import View2Button from '../../../static/images/view2.svg';
import View3Button from '../../../static/images/view3.svg';

const AdminAIInsights = () => {

    const examDate = useCare1AppSelector(store => store.examData[Constants.EXAM_DATE]);

    const firstName = useCare1AppSelector(store => store.patientDetails.details.first_name);
    const lastName = useCare1AppSelector(store => store.patientDetails.details.last_name);
    const dob = useCare1AppSelector(store => store.patientDetails.details.dob_date);
    const age = useCare1AppSelector(store => store.patientDetails.details.age);
    const sex = useCare1AppSelector(store => store.patientDetails.details.gender);

    const isADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const isSCIENCEUSER = useCare1AppSelector(store => store.user.isSCIENCEUSER);
    const isValidUser = (isADMIN || isSCIENCEUSER);

    const leftFundus = useCare1AppSelector(store => store.examData.photoUrls.left_fundus_photo);
    const rightFundus = useCare1AppSelector(store => store.examData.photoUrls.right_fundus_photo);

    const rightMlLargeHemePigmentOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_ml_large_heme_pigment_overlay_photo);
    const leftMlLargeHemePigmentOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_ml_large_heme_pigment_overlay_photo);

    const rightMlLargeHemePigmentValue = useCare1AppSelector(store => store.examData.right_ml_large_heme_pigment_value);
    const leftMlLargeHemePigmentValue = useCare1AppSelector(store => store.examData.left_ml_large_heme_pigment_value);

    const rightMlLargeHemePigmentSucceeded = useCare1AppSelector(store => store.examData.right_ml_large_heme_pigment_succeeded);
    const leftMlLargeHemePigmentSucceeded = useCare1AppSelector(store => store.examData.left_ml_large_heme_pigment_succeeded);

    const [useLargeHemePigmentPhoto, setUseLargeHemePigmentPhoto] = useState(true);

    const rightMlMicroAneurysmsOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_ml_micro_aneurysms_overlay_photo);
    const leftMlMicroAneurysmsOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_ml_micro_aneurysms_overlay_photo);

    const rightMlMicroAneurysmsValue = useCare1AppSelector(store => store.examData.right_ml_micro_aneurysms_value);
    const leftMlMicroAneurysmsValue = useCare1AppSelector(store => store.examData.left_ml_micro_aneurysms_value);
        
    const rightMlMicroAneurysmsSucceeded = useCare1AppSelector(store => store.examData.right_ml_micro_aneurysms_succeeded);
    const leftMlMicroAneurysmsSucceeded = useCare1AppSelector(store => store.examData.left_ml_micro_aneurysms_succeeded);
    
    const [useMicroAneurysmsPhoto, setUseMicroAneurysmsPhoto] = useState(true);

    const rightMlHardExudatesOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_ml_hard_exudates_overlay_photo);
    const leftMlHardExudatesOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_ml_hard_exudates_overlay_photo);

    const rightMlHardExudatesValue = useCare1AppSelector(store => store.examData.right_ml_hard_exudates_value);
    const leftMlHardExudatesValue = useCare1AppSelector(store => store.examData.left_ml_hard_exudates_value);
        
    const rightMlHardExudatesSucceeded = useCare1AppSelector(store => store.examData.right_ml_hard_exudates_succeeded);
    const leftMlHardExudatesSucceeded = useCare1AppSelector(store => store.examData.left_ml_hard_exudates_succeeded);

    const [useHardExudatesPhoto, setUseHardExudatesPhoto] = useState(true);

    const [viewMode, setViewMode] = useState('2-view')
    const [viewModeSide, setViewModeSide] = useState('OD')

    const rightMlLargeHemePigmentView3 = useCare1AppSelector(store => store.examData.right_ml_large_heme_pigment_view_3);
    const leftMlLargeHemePigmentView3 = useCare1AppSelector(store => store.examData.left_ml_large_heme_pigment_view_3);

    const rightMlMicroAneurysmsView3 = useCare1AppSelector(store => store.examData.right_ml_micro_aneurysms_view_3);
    const leftMlMicroAneurysmsView3 = useCare1AppSelector(store => store.examData.left_ml_micro_aneurysms_view_3);

    const rightMlHardExudatesView3 = useCare1AppSelector(store => store.examData.right_ml_hard_exudates_view_3);
    const leftMlHardExudatesView3 = useCare1AppSelector(store => store.examData.left_ml_hard_exudates_view_3);

    const [rightView3List, setRightView3List] = useState<any>([]);
    const [leftView3List, setLeftView3List] = useState<any>([]);

    const rightFundusRedChannelPhoto = useCare1AppSelector(store => store.examData.right_fundus_red_channel_photo);
    const rightFundusGreenChannelPhoto = useCare1AppSelector(store => store.examData.right_fundus_green_channel_photo);
    const rightFundusBlueChannelPhoto = useCare1AppSelector(store => store.examData.right_fundus_blue_channel_photo);

    const leftFundusRedChannelPhoto = useCare1AppSelector(store => store.examData.left_fundus_red_channel_photo);
    const leftFundusGreenChannelPhoto = useCare1AppSelector(store => store.examData.left_fundus_green_channel_photo);
    const leftFundusBlueChannelPhoto = useCare1AppSelector(store => store.examData.left_fundus_blue_channel_photo);

    const [rightView4List, setRightView4List] = useState<any>([]);
    const [leftView4List, setLeftView4List] = useState<any>([]);

    // For display purposes, the birth date needs to be converted from full month names to abbreviated month names.
    const shortDOB = dob ? dayjs(dob).format(FRONTEND_SHORT_DATE_FORMAT): '';

    useEffect(() => {
        const combined = rightMlMicroAneurysmsView3.map((micro, index) => {
            const heme = rightMlLargeHemePigmentView3[index];
            const exudates = rightMlHardExudatesView3[index];
            
            return { ...micro, ...heme, ...exudates };
        });
        setRightView3List(combined);
    }, [rightMlLargeHemePigmentView3, rightMlMicroAneurysmsView3, rightMlHardExudatesView3])

    useEffect(() => {
        const combined = leftMlMicroAneurysmsView3.map((micro, index) => {
            const heme = leftMlLargeHemePigmentView3[index];
            const exudates = leftMlHardExudatesView3[index];
            
            return { ...micro, ...heme, ...exudates };
        });
        setLeftView3List(combined);
    }, [leftMlLargeHemePigmentView3, leftMlMicroAneurysmsView3, leftMlHardExudatesView3])

    useEffect(() => {
        const combined = [];
        combined.push({
            photo: rightFundus,
            name: 'FULL COLOUR IMAGE',
            tooltip: '',
        });
        combined.push({
            photo: rightFundusRedChannelPhoto,
            name: 'RED COLOUR CHANNEL',
            tooltip: 'The red filter channel provides a choroidal view and allows for documentation of the choroid and its vessels.',
        });
        combined.push({
            photo: rightFundusGreenChannelPhoto,
            name: 'GREEN COLOUR CHANNEL',
            tooltip: 'The green filter channel highlights subretinal mass, retinal vessels and retinal layers.',
        });
        combined.push({
            photo: rightFundusBlueChannelPhoto,
            name: 'BLUE COLOUR CHANNEL',
            tooltip: 'The blue filter channel displays the nerve filter layers.',
        });

        setRightView4List(combined);
    }, [rightFundus, rightFundusRedChannelPhoto, rightFundusGreenChannelPhoto, rightFundusBlueChannelPhoto])

    useEffect(() => {
        const combined = [];
        combined.push({
            photo: leftFundus,
            name: 'FULL COLOUR IMAGE',
            tooltip: '',
        });
        combined.push({
            photo: leftFundusRedChannelPhoto,
            name: 'RED COLOUR CHANNEL',
            tooltip: 'The red filter channel provides a choroidal view and allows for documentation of the choroid and its vessels.',
        });
        combined.push({
            photo: leftFundusGreenChannelPhoto,
            name: 'GREEN COLOUR CHANNEL',
            tooltip: 'The green filter channel highlights subretinal mass, retinal vessels and retinal layers.',
        });
        combined.push({
            photo: leftFundusBlueChannelPhoto,
            name: 'BLUE COLOUR CHANNEL',
            tooltip: 'The blue filter channel displays the nerve filter layers.',
        });

        setLeftView4List(combined);
    }, [leftFundus, leftFundusRedChannelPhoto, leftFundusGreenChannelPhoto, leftFundusBlueChannelPhoto])


    const disclaimer = 'Disclaimer: AI Retina scans run on Optomap images may see worse results.'

    return (
        <>
        <Row className='ai-insights'>
            <Col span={24}>
                <div>
                    <Row className='title'>
                        <Col span={24}>
                            <div className='title-text'>
                                <span className='heading'>{`${lastName}, ${firstName}`}</span>
                            </div>                  
                        </Col>
                    </Row>
                    <Row className='title'>
                        <Col span={24} className='dob'>
                            <label htmlFor={'DOB'} className="patient-exam-header-label">
                            DOB:
                            </label>
                            <label htmlFor={shortDOB} className="patient-exam-header-content dob-age-sex">
                                {`${shortDOB} (${age}, ${sex})`}
                            </label>
                        </Col>
                    </Row>
                </div>
                <div className='ai-insights-content'>
                    <Row className='title'>
                        <Col span={8}>
                            <div className='title-text'>
                                <img src={DeepMDLogo} className='deepmd-logo' alt='Deep MD Logo' />
                                <span className='heading'>Care1 AI Retina Scan</span>
                                <span className='sub-heading'>(in beta testing)</span>
                            </div>
                        </Col>
                        <Col span={8}>
                        </Col>
                        <Col span={8}>
                            <div className='view-button-container'>
                                <Tooltip
                                    className={'recommendations-tooltip'}
                                    placement='topLeft'
                                    title="Examine fundus photos in 4-view mode, through a red, green, and blue colour channel."
                                >                                
                                    <Button 
                                        className={`view-button view-4-button ${viewMode === '4-view' ? 'view-button-active' : 'view-button-inactive'}`} 
                                        onClick={() => setViewMode('4-view')}
                                    ><AppstoreOutlined /></Button>
                                </Tooltip>
                                <Tooltip
                                    className={'recommendations-tooltip'}
                                    placement='topLeft'
                                    title="Compare change over time by viewing the latest, previous, and very first uploaded scans for each eye in our 3-view mode. "
                                >                                
                                    <Button 
                                        className={`view-button view-3-button ${viewMode === '3-view' ? 'view-button-active' : 'view-button-inactive'}`} 
                                        onClick={() => setViewMode('3-view')}
                                    ><img src={View3Button} /></Button>
                                </Tooltip>
                                <Button 
                                    className={`view-button view-2-button ${viewMode === '2-view' ? 'view-button-active' : 'view-button-inactive'}`} 
                                    onClick={() => setViewMode('2-view')}
                                ><img src={View2Button} /></Button>
                                { viewMode !== '2-view' && 
                                    <>
                                        <Divider type="vertical" className='view-button-divider' />
                                        <Button 
                                            className={`view-button ${viewModeSide === 'OS' ? 'view-button-active' : 'view-button-inactive'}`} 
                                            onClick={() => setViewModeSide('OS')}
                                        >OS</Button>
                                        <Button 
                                            className={`view-button ${viewModeSide === 'OD' ? 'view-button-active' : 'view-button-inactive'}`} 
                                            onClick={() => setViewModeSide('OD')}
                                        >OD</Button>
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                    {viewMode === '2-view' &&
                        <>
                        <Row className='scan-bg'>
                            <Col span={12} className='container-left-offset'>
                                <AdminRetinaScan               
                                    viewMode={viewMode}              
                                    fundusPhoto={rightFundus} 
                                    useLargeHemePigmentPhoto={useLargeHemePigmentPhoto}
                                    mlLargeHemePigmentOverlayPhoto={rightMlLargeHemePigmentOverlayPhoto}
                                    mlLargeHemePigmentValue={rightMlLargeHemePigmentValue}
                                    mlLargeHemePigmentSucceeded={rightMlLargeHemePigmentSucceeded}
                                    useMicroAneurysmsPhoto={useMicroAneurysmsPhoto}
                                    mlMicroAneurysmsOverlayPhoto={rightMlMicroAneurysmsOverlayPhoto}
                                    mlMicroAneurysmsValue={rightMlMicroAneurysmsValue}
                                    mlMicroAneurysmsSucceeded={rightMlMicroAneurysmsSucceeded}
                                    useHardExudatesPhoto={useHardExudatesPhoto}
                                    mlHardExudatesOverlayPhoto={rightMlHardExudatesOverlayPhoto}
                                    mlHardExudatesValue={rightMlHardExudatesValue}
                                    mlHardExudatesSucceeded={rightMlHardExudatesSucceeded}
                                    title={'RIGHT FUNDUS'} 
                                    titleTooltip={''}
                                    examDate={dayjs(examDate).format('D MMM YYYY')} 
                                />
                            </Col>
                            <Col span={12}>
                                <AdminRetinaScan 
                                    viewMode={viewMode}
                                    fundusPhoto={leftFundus} 
                                    useLargeHemePigmentPhoto={useLargeHemePigmentPhoto}
                                    mlLargeHemePigmentOverlayPhoto={leftMlLargeHemePigmentOverlayPhoto}
                                    mlLargeHemePigmentValue={leftMlLargeHemePigmentValue}
                                    mlLargeHemePigmentSucceeded={leftMlLargeHemePigmentSucceeded}
                                    useMicroAneurysmsPhoto={useMicroAneurysmsPhoto}
                                    mlMicroAneurysmsOverlayPhoto={leftMlMicroAneurysmsOverlayPhoto}
                                    mlMicroAneurysmsValue={leftMlMicroAneurysmsValue}
                                    mlMicroAneurysmsSucceeded={leftMlMicroAneurysmsSucceeded}
                                    useHardExudatesPhoto={useHardExudatesPhoto}
                                    mlHardExudatesOverlayPhoto={leftMlHardExudatesOverlayPhoto}
                                    mlHardExudatesValue={leftMlHardExudatesValue}
                                    mlHardExudatesSucceeded={leftMlHardExudatesSucceeded}
                                    title={'LEFT FUNDUS'} 
                                    titleTooltip={''}
                                    examDate={dayjs(examDate).format('D MMM YYYY')} 
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className='disclaimer'>{disclaimer}</Col>
                        </Row>
                        </>
                    }
                    {viewMode === '3-view' &&
                        <>
                        <Row className='scan-bg'>
                            {viewModeSide === 'OD' && rightView3List.map((result: any, index: number) => (
                                <Col span={8} className='container-left-offset' key={index}>
                                    <AdminRetinaScan 
                                        viewMode={viewMode}
                                        fundusPhoto={result['right_ml_large_heme_pigment_fundus_photo']} 
                                        useLargeHemePigmentPhoto={useLargeHemePigmentPhoto}
                                        mlLargeHemePigmentOverlayPhoto={result['right_ml_large_heme_pigment_overlay_photo']}
                                        mlLargeHemePigmentValue={result['right_ml_large_heme_pigment_value']}
                                        mlLargeHemePigmentSucceeded={result['right_ml_large_heme_pigment_succeeded']}
                                        useMicroAneurysmsPhoto={useMicroAneurysmsPhoto}
                                        mlMicroAneurysmsOverlayPhoto={result['right_ml_micro_aneurysms_overlay_photo']}
                                        mlMicroAneurysmsValue={result['right_ml_micro_aneurysms_value']}
                                        mlMicroAneurysmsSucceeded={result['right_ml_micro_aneurysms_succeeded']}
                                        useHardExudatesPhoto={useHardExudatesPhoto}
                                        mlHardExudatesOverlayPhoto={result['right_ml_hard_exudates_overlay_photo']}
                                        mlHardExudatesValue={result['right_ml_hard_exudates_value']}
                                        mlHardExudatesSucceeded={result['right_ml_hard_exudates_succeeded']}
                                        title={''} 
                                        titleTooltip={''}
                                        examDate={result['right_ml_large_heme_pigment_exam_date']} 
                                    />
                                    
                                </Col>
                            ))}

                            {viewModeSide === 'OS' && leftView3List.map((result: any, index: number) => (
                                <Col span={8} className='container-left-offset' key={index}>
                                    <AdminRetinaScan 
                                        viewMode={viewMode}
                                        fundusPhoto={result['left_ml_large_heme_pigment_fundus_photo']} 
                                        useLargeHemePigmentPhoto={useLargeHemePigmentPhoto}
                                        mlLargeHemePigmentOverlayPhoto={result['left_ml_large_heme_pigment_overlay_photo']}
                                        mlLargeHemePigmentValue={result['left_ml_large_heme_pigment_value']}
                                        mlLargeHemePigmentSucceeded={result['left_ml_large_heme_pigment_succeeded']}
                                        useMicroAneurysmsPhoto={useMicroAneurysmsPhoto}
                                        mlMicroAneurysmsOverlayPhoto={result['left_ml_micro_aneurysms_overlay_photo']}
                                        mlMicroAneurysmsValue={result['left_ml_micro_aneurysms_value']}
                                        mlMicroAneurysmsSucceeded={result['left_ml_micro_aneurysms_succeeded']}
                                        useHardExudatesPhoto={useHardExudatesPhoto}
                                        mlHardExudatesOverlayPhoto={result['left_ml_hard_exudates_overlay_photo']}
                                        mlHardExudatesValue={result['left_ml_hard_exudates_value']}
                                        mlHardExudatesSucceeded={result['left_ml_hard_exudates_succeeded']}
                                        title={''} 
                                        titleTooltip={''}
                                        examDate={result['left_ml_large_heme_pigment_exam_date']} 
                                    />                                        
                                </Col>
                            ))}                                
                        </Row>
                        <Row>
                            <Col span={24} className='disclaimer'>{disclaimer}</Col>
                        </Row>
                        </>
                    }
                    {viewMode === '4-view' &&
                        <>
                        <Row className='scan-bg'>
                            {viewModeSide === 'OD' && rightView4List.map((result: any, index: number) => (
                                <Col span={12} className='container-left-offset' key={index}>
                                    <AdminRetinaScan 
                                        viewMode={viewMode}
                                        fundusPhoto={result['photo']} 
                                        useLargeHemePigmentPhoto={false}
                                        mlLargeHemePigmentOverlayPhoto={''}
                                        mlLargeHemePigmentValue={0}
                                        mlLargeHemePigmentSucceeded={false}
                                        useMicroAneurysmsPhoto={false}
                                        mlMicroAneurysmsOverlayPhoto={''}
                                        mlMicroAneurysmsValue={0}
                                        mlMicroAneurysmsSucceeded={false}
                                        useHardExudatesPhoto={false}
                                        mlHardExudatesOverlayPhoto={''}
                                        mlHardExudatesValue={0}
                                        mlHardExudatesSucceeded={false}
                                        title={result['name']} 
                                        titleTooltip={result['tooltip']} 
                                        examDate={'' }
                                    />
                                    
                                </Col>
                            ))}

                            {viewModeSide === 'OS' && leftView4List.map((result: any, index: number) => (
                                <Col span={12} className='container-left-offset' key={index}>
                                    <AdminRetinaScan 
                                        viewMode={viewMode}
                                        fundusPhoto={result['photo']} 
                                        useLargeHemePigmentPhoto={false}
                                        mlLargeHemePigmentOverlayPhoto={''}
                                        mlLargeHemePigmentValue={0}
                                        mlLargeHemePigmentSucceeded={false}
                                        useMicroAneurysmsPhoto={false}
                                        mlMicroAneurysmsOverlayPhoto={''}
                                        mlMicroAneurysmsValue={0}
                                        mlMicroAneurysmsSucceeded={false}
                                        useHardExudatesPhoto={false}
                                        mlHardExudatesOverlayPhoto={''}
                                        mlHardExudatesValue={0}
                                        mlHardExudatesSucceeded={false}
                                        title={result['name']} 
                                        titleTooltip={result['tooltip']} 
                                        examDate={'' }
                                    />                                        
                                </Col>
                            ))}                                
                        </Row>
                        </>
                    }
                </div>
            </Col>
        </Row>
        <Row className='ai-insights'>
            <Col span={12}>
                <AdminAIInsightsHistoricalAnalysis 
                    side="right" 
                    useLargeHemePigmentPhoto={useLargeHemePigmentPhoto}
                    useMicroAneurysmsPhoto={useMicroAneurysmsPhoto}
                    useHardExudatesPhoto={useHardExudatesPhoto}
                />
            </Col>
            <Col span={12}>
                <AdminAIInsightsHistoricalAnalysis 
                    side="left" 
                    useLargeHemePigmentPhoto={useLargeHemePigmentPhoto}
                    useMicroAneurysmsPhoto={useMicroAneurysmsPhoto}
                    useHardExudatesPhoto={useHardExudatesPhoto}
                />
            </Col>
        </Row>
        <Row gutter={8}>
            <PatientExamOCTMaculaImageHistory 
                orderBy={'desc'} 
                showHint={false} 
                showBaselineBorder={false} 
                showEmptyRow={false}
            />
        </Row>
        <AdminAIInsightsModal />
        </>
    );
}

export default AdminAIInsights;
