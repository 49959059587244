import { useCare1AppSelector } from '../../apps/care1-hooks';
import { Col, Radio, Row, Form, Typography } from 'antd';

import { localizedText } from '../../localizedText';
import '../../../static/css/components/patient-exam-billing-rules.scss';
import { useGetOutboundReferralOmdInfoQuery } from '../../services/exam-api';


const AdminBillingRulesOutboundReferral = () => {
    const { NO_TEXT, YES_TEXT } = localizedText;

    const examId = useCare1AppSelector(store => store.examData.id);
    const {data} = useGetOutboundReferralOmdInfoQuery(examId!, {skip: !examId});


    return (
        <Row gutter={[0, 4]}>
            <Col span={24}>
                <Form.Item
                    label='Is this a cataract referral?'
                    name='cat_refer'
                >
                    <Radio.Group
                        className={'sees-omd'}
                        buttonStyle="solid"
                        size="small"
                    >
                        <Radio.Button
                            value={true}
                        >
                            {YES_TEXT.toUpperCase()}
                        </Radio.Button>
                        <Radio.Button
                            value={false}
                        >
                            {NO_TEXT.toUpperCase()}
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item></Col>
            <Col span={24}>
                <Typography.Text style={{fontSize: '0.75rem'}}>Referring OMD: Dr. {data?.omd_name} {data?.omd_contact}</Typography.Text>
            </Col>
            <Col span={24}>
                <Typography.Text style={{fontSize: '0.75rem'}}>Referral sent {data?.ipc_addressed_date}</Typography.Text>
            </Col>
        </Row>
    );
}

export default AdminBillingRulesOutboundReferral;