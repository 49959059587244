import { FolderOpenOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { localizedText } from '../../localizedText';
import { IAlertListItem } from '../../services/alert-lists-api';

type ComponentProps = {
    patientList: IAlertListItem[],
    title: string,
    cn: string,
    onOpenAll: (patientList: IAlertListItem[]) => void,
    onLinkClick: (p: IAlertListItem) => void,
}

const FilteredPatientList = ({patientList, title, cn, onOpenAll, onLinkClick} : ComponentProps) => {
    const { ADMIN_ALERTS_OPEN_ALL } = localizedText;
    return (
        <div className={cn}>
            <div>
                <span dangerouslySetInnerHTML={{__html: title}}></span>
                <Button
                    className="open-all-admin-button"
                    icon={<FolderOpenOutlined />}
                    onClick={() => onOpenAll(patientList)}
                >
                    {ADMIN_ALERTS_OPEN_ALL}
                </Button>
            </div>
            <div>
                {
                    patientList.map((p, idx) => {
                        if (p.different_exam_date) {
                            return <span key={idx}>
                                <span className="link-underline-white">
                                    {idx ? ',' : ''}
                                </span>
                                <span className="link-underline-white" onClick={() => onLinkClick(p)}>
                                    {`${p.first_name} ${p.last_name}`}{p.render_notes ? ` - [${p.notes}] ` : ''}{p.render_exam_date ? ` (${p.exam_date})` : ''}
                                </span>
                            </span>
                        }
                        else {
                            return <span key={idx}>
                                <span className="link-underline">
                                    {idx ? ',' : ''}
                                </span>
                                <span className="link-underline" onClick={() => onLinkClick(p)}>
                                    {`${p.first_name} ${p.last_name}`}{p.render_notes ? ` - [${p.notes}] ` : ''}{p.render_exam_date ? ` (${p.exam_date})` : ''}
                                    </span>
                            </span>
                        }
                    })
                }
            </div>
        </div>)
}

export default FilteredPatientList;