import { Card, Row, Col, Input, DatePicker, Form } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { localizedText } from '../../localizedText';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/admin-gp-referral-dates.scss';
import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);

const AdminGPReferralDates = () => {
    const { GP_REF_DATES, SELECTED_GP_REF_DATE, MANUAL_GP_REF_DATE } = localizedText;

    const gpReferralDate = useCare1AppSelector(store => store.examData.gp_referral_date);
    const manualGpReferralDate = useCare1AppSelector(store => store.examData.manual_gp_referral_date);
    const manualGpReferralDateMoment = manualGpReferralDate !== null && manualGpReferralDate !== ''
        ? dayjs(manualGpReferralDate) : null;

    const dispatch = useCare1AppDispatch();

    const handleDateChange = (date: Dayjs | null) => {
        const d = date?.format('YYYY-MM-DD');
        dispatch(setExamDataValue('manual_gp_referral_date', d));
    }

    return (
        <Card className={'component-input-card gp-referral-dates'} bordered={false}>
            <div className="heading">{GP_REF_DATES}</div>
            <div className='component-body'>
                <Row className='row-one'>
                    <Col span={12}>
                    <div className='date-label'>{SELECTED_GP_REF_DATE}:</div>
                    </Col>
                    <Col span={11}>
                        <Input
                            data-testid='gp-referral-date'
                            disabled
                            value={gpReferralDate}
                        />
                    </Col>
                </Row>
                <Row className='row-two'>
                    <Col span={12}>
                    <div className='date-label'>{MANUAL_GP_REF_DATE}:</div>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            colon={false}
                        >
                            <DatePicker
                                data-testid='manual-gp-referral-date'
                                onChange={date => handleDateChange(date)}
                                value={manualGpReferralDateMoment}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Card>
    )
}

export default AdminGPReferralDates;