import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import '../../../static/css/components/input-number-suffix.scss';


type InputNumberSuffixProps = {
    forwardedRef?: any;
    suffix?: string;
    isCatPostOp?: boolean;
    formatter?: InputNumberProps['formatter'];
} & React.ComponentProps<typeof InputNumber>;

const InputNumberSuffix = React.forwardRef((props: InputNumberSuffixProps, ref: any) => {
    const { forwardedRef, suffix, isCatPostOp, formatter,...rest } = props;
    const catPostOpClassName = isCatPostOp ? 'cat-color' : ' ';
    if (suffix) {
        return (
            <span className="input-number-suffix">
                <InputNumber
                    ref={ref} {...rest}
                    addonAfter={<span className={`ant-input-suffix ${catPostOpClassName}`}>{suffix}</span>}
                />
                
            </span>
        );
    }

    return (
        <InputNumber ref={ref} {...rest} formatter={formatter}/>
    );
});

export default InputNumberSuffix;