import React, { useState, useEffect, ChangeEvent } from 'react';
import { Button, Layout, Modal, Spin } from 'antd';
import { useCare1AppSelector, useCare1AppDispatch } from '../apps/care1-hooks';
import dayjs from 'dayjs';
import { useParams, useNavigate } from 'react-router';

import { OPERATING_TEXT } from '../constants';

import NavigationControlBar from '../components/navigation-control-bar';
import { useGetReviewProcessingReplyDataQuery, useCreateOMDMessagingExamMutation } from '../services/review-processing-api';

import '../../static/css/pages/review-processing-reply-page.scss';
import TextArea from 'antd/es/input/TextArea';

const { Header, Content } = Layout;


const ReviewProcessingReplyPage = () => {

    const dispatch = useCare1AppDispatch();
    const params = useParams();
    const history = useNavigate();

    const guid = String(params["guid"])
    const [inputMessage, setInputMessage] = useState<string>("");

    const { data, isFetching } = useGetReviewProcessingReplyDataQuery(guid);
    const [ createOMDMessagingExam, { isLoading } ] = useCreateOMDMessagingExamMutation();

    const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setInputMessage(e.target.value);
    };

    const handleCancel = () => {
        window.top?.close();
    }

    const handleSendMessage = async () => {
        try {

            if (data !== undefined) {
                // Use the current date for the created exam.
                const currentTime = dayjs().format();
    
                const response = await createOMDMessagingExam({
                    taskGuid: guid,
                    patientId: data.data.patient_id, 
                    fromExamId: data.data.exam_id, 
                    examDate: currentTime, 
                    odMessage: inputMessage}).unwrap();
    
                if (response.success) {
                    Modal.info({
                        className: 'info-modal',
                        title: 'Message Sent',
                        zIndex: 2000,
                    });
                } else {
                    throw new Error(response?.error);
                }
                
            }
            
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                zIndex: 2001,
                title: 'Error',
            });
        }
    };

    return (
        <Layout className='reviewProcessingReplyPage'>
            <Spin
                className="loading-spinner"
                spinning={isFetching || isLoading}
                size="large"
                tip={OPERATING_TEXT}
            >
                <Content>
                    {(!data?.success) &&
                        <>
                            <div className="info">
                                The link has expired
                            </div>
                        </>}
                    {(data?.success) &&
                        <>
                            <div className="info">
                                {data?.data.first_name} {data?.data.last_name} {data?.data.exam_date}
                            </div>
                            <div>
                                <div>
                                    <TextArea
                                        className='message-area'
                                        placeholder="Enter Your Message"
                                        value={inputMessage}
                                        onChange={handleInputChange}
                                        autoSize={{ minRows: 10 }}
                                    />
                                </div>
                                <div className='button-rows'>
                                    <Button
                                        className='cancel-button'
                                        onClick={handleCancel}
                                    >Cancel</Button>

                                    <Button
                                        className='send-button'
                                        type='primary'
                                        onClick={handleSendMessage}
                                        disabled={inputMessage.length === 0}
                                    >Send Reply</Button>
                                </div>
                            </div>
                        </>}
                </Content>
            </Spin>
        </Layout>
    );
}

export default ReviewProcessingReplyPage;
