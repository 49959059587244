import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Modal, Row } from 'antd';
import PatientExamIOPHistoryEntry from './patient-exam-iop-history-entry';
import '../../../static/css/components/patient-exam-iop-history.scss';
import { getIOPHistoryValues, isIOPHistoryValueEmpty, checkEmptyIOPHistoryFields } from '../../helpers/patient-exam-convert';
import DivTagInput from './div-tag-input';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { localizedText } from '../../localizedText';
import * as Constants from '../../constants';
import { addIOPHistoryEntry, deleteIOPHistoryEntry, setIOPHistoryNone, toggleIOPHistoryEntries } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

type ComponentProps = {
    placeholder?: string,
    disabled: boolean,
}

const PatientExamIOPHistory = ({ placeholder, disabled } : ComponentProps) => {
    const { EDIT_BUTTON_TEXT, IOP_HISTORY_TITLE, NONE_TEXT } = localizedText;
    const dispatch = useCare1AppDispatch();

    const iopHistory = useCare1AppSelector(store => store.examData.iop_history);
    const glcDropsList = useCare1AppSelector(store => store.options.glcDropsList);
    const eyeTypes = useCare1AppSelector(store => store.options.eyeTypes);
    const procedureList = useCare1AppSelector(store => store.options.procedureList);
    const machinesList = useCare1AppSelector(store => store.options.machinesList);
    const rluExamIsPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);

    const getGlcDropOptions = () => {
        let options = glcDropsList;
        if (rluExamIsPremium) {
            options = options.concat([['brimonidine_tim', 'brimonidine/timolol'], ['zimed', 'Zimed']]);
        }
        return options;
    }

    const glcDropOptions = getGlcDropOptions();


    // OMD users should not see the checkbox,
    // If there are no IOP History entries then display an entry of "No history"
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);

    // Display the entries with the baseline string format
    const baselineString = userIsOMDR || userIsADMIN;
    const values = getIOPHistoryValues(iopHistory.values, glcDropOptions, procedureList, machinesList, userIsOMDR || userIsOMDC, userIsADMIN);
    const isNone = iopHistory.values[0] && iopHistory.values[0].disabled;

    const [isEditBtnClicked, setIsEditBtnClicked] = useState(false);

    const closeIOPHistoryEntries = () => {
        const { INCOMPLETE_ROW_CONFIRMATION_TITLE, INCOMPLETE_ROW_CONTENT_TEXT, INCOMPLETE_ROW_OK_TEXT,
            INCOMPLETE_ROW_CANCEL_TEXT } = Constants;

        const iopHistoryValues = iopHistory.values;

        let isRowCompleted = true;
        let selectedRow: number | null = null;
        // set isRowCompleted to false only when there are some unfilled fileds, when all fields of
        // a row are empty, it is considered a complete row
        iopHistoryValues.forEach((entry, index) => {
            if (checkEmptyIOPHistoryFields(entry)) {
                isRowCompleted = false;
                selectedRow = index;
            }
        });

        if (!isRowCompleted) {
            Modal.confirm({
                className: 'div-tag-row-incomplete-modal',
                title: INCOMPLETE_ROW_CONFIRMATION_TITLE,
                content: INCOMPLETE_ROW_CONTENT_TEXT,
                icon: <InfoCircleOutlined />,
                okText: INCOMPLETE_ROW_OK_TEXT,
                cancelText: INCOMPLETE_ROW_CANCEL_TEXT,
                cancelButtonProps: { className: 'continue-editing' },
                onOk: () => {
                    dispatch(deleteIOPHistoryEntry(selectedRow!));
                    dispatch(toggleIOPHistoryEntries());
                },
            });
        } else {
            dispatch(toggleIOPHistoryEntries());
        }
    }

    // Side effects that we run after component render.
    useEffect(() => {
        if (values && values.length === 0) {
            dispatch(addIOPHistoryEntry());
        }
        else {
            if (!isNone) {
                const entry = iopHistory.values[iopHistory.values.length - 1];
                if (!isIOPHistoryValueEmpty(entry)) {
                    dispatch(addIOPHistoryEntry());
                }
            }
        }
    }, [dispatch, iopHistory.values, isNone, values])

    const isNoEntries = values.length === 1;
    const entries = iopHistory.values.map((entry, index) => {
        return (
            <PatientExamIOPHistoryEntry
                glcDropsList={glcDropOptions}
                eyeTypes={eyeTypes}
                procedureList={procedureList}
                machinesList={machinesList}
                key={index}
                index={index}
                data={entry}
                size={"small"}
                disabled={disabled}
            />
        );
    })

    return (
        <Card bordered={false} className={'patient-exam-iop-history'}>
            {/* Writeable component is for OD and OMDR */}
            { (userIsOD || userIsOMDR || userIsADMIN) &&
            <Fragment>
                <Row>
                    <Col span={18}>
                        <div className="heading">{IOP_HISTORY_TITLE}</div>
                    </Col>
                    <Col span={6}>
                    {/* IOP-HIST 004 */}
                        { (userIsOMDR && !isEditBtnClicked) &&
                            <Button
                                onClick={()=> setIsEditBtnClicked(true)}
                                type='primary'
                                className='edit-button'
                                icon={<EditOutlined />}
                            >
                                {EDIT_BUTTON_TEXT}
                            </Button>
                        }
                        { (userIsOD || userIsADMIN || (userIsOMDR && isEditBtnClicked)) &&
                            <Checkbox
                                disabled={disabled}
                                checked={isNone}
                                onChange={(e) => dispatch(setIOPHistoryNone(e.target.checked))}
                            >
                                {NONE_TEXT}
                            </Checkbox>
                        }
                    </Col>
                </Row>
                <Row className={disabled ? 'div-tag-disabled' : ''}>
                    {/* IOP-HIST 001, IOP-HIST 002, IOP-HIST 003 */}
                    <DivTagInput
                        isBaselineString={baselineString}
                        disabled={disabled || !!isNone}
                        placeholder={placeholder}
                        onClick={() => {
                            dispatch(toggleIOPHistoryEntries());
                        }}
                        values={values}
                        drawer={!isNone && iopHistory.show}
                        onClose={() => { closeIOPHistoryEntries(); }}
                        entries={entries}
                        emptyValue={ baselineString ? '' : '- mmHg  ()  '}
                    />
                </Row>
            </Fragment>
            }
            {/* Read only component is for OMDC */}
            { userIsOMDC &&
                <Fragment>
                    <Row className={'heading-row'}>
                        <div className="heading">{IOP_HISTORY_TITLE}</div>
                    </Row>
                    <Row>
                        { !isNoEntries &&
                            <DivTagInput
                                disabled={disabled || !!isNone}
                                values={values}
                                emptyValue={'- mmHg  ()  '}
                            />
                        }
                        { isNoEntries &&
                            <DivTagInput
                                disabled={true}
                                values={['No history']}
                            />
                        }
                    </Row>
                </Fragment>
            }
        </Card>
    );
}

export default PatientExamIOPHistory;