import { useState, useMemo, useEffect } from "react";
import Care1LightBox from '../../../components/retina/care1-light-box';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { getFilepathFromBackendMediaUrl } from '../../../helpers/media-image-convert';


const getWindowDimensions = () => {
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    return {
        windowWidth,
        windowHeight
    };
}

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const GptImageIdentifierImg = ({photoUrl, className}: {photoUrl: string, className: string}) => {

    const [lightboxOn, setLightboxOn] = useState(false);

    const sendToggleLightbox = () => {
        setLightboxOn(!lightboxOn);
    }

    const [imageHeight, setImageHeight] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const { windowWidth, windowHeight } = useWindowDimensions();

    // determine whether the height is bigger or width is bigger, only scale images with small width and height
    const SCALE_RATIO = 1.5
    const dropzoneWrapperClassName = useMemo(() => {
        return imageHeight && imageWidth
            ? (imageHeight > imageWidth) && (windowHeight / imageHeight >= SCALE_RATIO)
                ? 'height-constraint-image'
                : (imageHeight < imageWidth) && (windowWidth / imageWidth >= SCALE_RATIO)
                    ? 'width-constraint-image'
                    : ''
            : '';
    }, [imageHeight, imageWidth, windowHeight, windowWidth])

    const download = (file: string) => {
        const fileUrl = getFilepathFromBackendMediaUrl(file);

        const a = document.createElement('a');
        a.href = `${process.env.REACT_APP_BACKENDURL}/data/download/${fileUrl}`;
        a.download = fileUrl.split('/')[fileUrl.split('/').length - 1];

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return (<>
        {photoUrl !== '' && <img
            className={`${className}`}
            src={photoUrl}
            alt=''
            onClick={sendToggleLightbox}
        />}
        {photoUrl !== '' && <Care1LightBox
            field={'photoUrl'}
            isOpen={lightboxOn}
            mainSrc={photoUrl}
            mainSrcThumbnail={photoUrl}
            onCloseRequest={sendToggleLightbox}
            wrapperClassName={dropzoneWrapperClassName}
            /* @ts-ignore:next-line */
            onImageLoad={(src, imageType, image) => {
                setImageHeight(image.naturalHeight);
                setImageWidth(image.naturalWidth);
            }}
            toolbarButtons={[<CloudDownloadOutlined className="care1-lightbox-download-icon" onClick={() => download(photoUrl)} />]}
        />}
    </>);
}

export default GptImageIdentifierImg;
