import { baseApi } from './base-api'

export interface IGptChatNearMissSummaryResponse {
    near_miss_list: {
        summary: {
            rows: [],
            days: [],
        }
    };
}

export interface IGptChatNearMissListResponse {
    near_miss_list: [];
}

export interface IGptChatNearMissListParam {
    historicalPeriodOption: string | undefined,
    fieldNameOption: string | undefined,
    clinicLocationFullNameOption: string | undefined,
}

export interface IGptChatNearMissListFilterOptionsResponse {
    clinic_location_full_name_options: [],
    field_name_options: [],
}

export const gptChatNearMissApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getGptChatNearMissSummary: builder.query<IGptChatNearMissSummaryResponse, void>({
            query: () => 'admin/gpt_chat_near_miss/summary/',

            // Give a tag to staffs so that other API actions can invalidate GptChatNearMissSummary
            providesTags: [{ type: 'GptChatNearMissSummary', id: 'SUMMARY' }]
        }),
        getGptChatNearMissList: builder.query<IGptChatNearMissListResponse, IGptChatNearMissListParam>({
            query: (param) => `admin/gpt_chat_near_miss/list/?historical_period=${param.historicalPeriodOption}&field_name=${param.fieldNameOption}&clinic_location_full_name=${param.clinicLocationFullNameOption}`,

            // Give a tag to staffs so that other API actions can invalidate GptChatNearMissList
            providesTags: [{ type: 'GptChatNearMissList', id: 'LIST' }]
        }),
        getGptChatNearMissListFilterOptions: builder.query<IGptChatNearMissListFilterOptionsResponse, void>({
            query: () => 'admin/gpt_chat_near_miss/list_filter_options/'
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const { useGetGptChatNearMissSummaryQuery, useGetGptChatNearMissListQuery, useGetGptChatNearMissListFilterOptionsQuery } = gptChatNearMissApi;