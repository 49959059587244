import { Col, Flex, Row } from "antd";
import PatientExamOMDCNotes from "./patient-exam-omdc-notes";
import PatientExamFUOptions from "./patient-exam-fu-options";
import OMDSubmitButtons from "../reviewer/omd-submit-buttons";
import SecondOpinionTeleConsultSelectors from "./patient-exam-second-opinion-teleconsult";
import PatientExamOmdConsultantNotesToMOA from "./patient-exam-omd-consultant-notes-to-moa";
import styles from '../../../static/css/components/patient-exam-omd-consultant-band.module.scss'
import commonLayoutStyles from '../../../static/css/components/common-layout.module.scss'

const OmdcReviewerBand = ({disabled}: {disabled: boolean}) => {
    return (
        <Row gutter={8} className={styles.omdConsultantBand}>
            <Col span={12}>
                <PatientExamOMDCNotes disabled={disabled} />
            </Col>
            <Col span={4}>
                <PatientExamOmdConsultantNotesToMOA disabled={disabled} />
            </Col>
            <Col span={4}>
                <Flex vertical gap='small' className={commonLayoutStyles.verticalFlex}>
                    <PatientExamFUOptions disabled={disabled} />
                    <SecondOpinionTeleConsultSelectors disabled={disabled} />
                </Flex>
            </Col>
            <Col span={4}>
                <OMDSubmitButtons disabled={false} />
            </Col>
        </Row>
    );
};

export default OmdcReviewerBand;