import * as Constants from '../constants';
import { IPrereviewAlertOption } from '../reducers/options-slice';
import { IAdminPatientListItemResponse, IODPatientListItemResponse, IOMDCPatientListItemResponse, IOMDRPatientListItemResponse, IPatientList, IGlaucomaProgramPatientListItemResponse } from '../reducers/patient-list-slice';
import { convertUtcToLocal, convertTimeToShortFormatLabel } from './utilities';;

// Assemble the patient list filters into a json object
export function buildODPatientListFilter(patientList: IPatientList, userIsOD: boolean) {
    // Only certain user types need certain filters.
    let examPeriod = '';
    let examStatus = '';

    if (userIsOD) {
        examPeriod = (patientList[Constants.PATIENT_LIST_EXAM_PERIOD_FIELD] !== Constants.ALL_TIME_KEY)
            ? `${Constants.BACKEND_EXAM_PERIOD_FIELD}=${patientList[Constants.PATIENT_LIST_EXAM_PERIOD_FIELD]}` : '';
        examStatus = (patientList[Constants.PATIENT_LIST_EXAM_STATUS_FIELD] !== Constants.ALL_STATUSES_KEY)
            ? `${Constants.BACKEND_EXAM_STATUS_FIELD}=${patientList[Constants.PATIENT_LIST_EXAM_STATUS_FIELD]}` : '';
    }
    const { searchQuery } = patientList;
    // if there is a searchQuery, the string that will be added to the url below is '&name_or_health_number=searchQuery'
    const query = searchQuery ? `&${Constants.PATIENT_SEARCH_VALUE_FIELD}=${searchQuery}` : '';

    const patientGrouping = `${Constants.BACKEND_PATIENT_GROUPING_FIELD}=${patientList[Constants.PATIENT_LIST_PATIENT_GROUPING_FIELD]}`;

    return `?${patientGrouping}${examPeriod ? `&${examPeriod}` : ''}${examStatus ? `&${examStatus}` : ''}${query}`;
}

export function buildOMDRPatientListFilter(patientList: IPatientList) {
    const { omdrStatus, omdrPeriodFilter, searchQuery, omdrStartDate, omdrEndDate} = patientList;

    let params = [];

    omdrStatus !== Constants.ALL_STATUSES_KEY && params.push(`exam_status=${omdrStatus}`);
    omdrPeriodFilter !== Constants.ALL_TIME_KEY && params.push(`exam_date=${omdrPeriodFilter}`);
    searchQuery && params.push(`name_or_health_number=${searchQuery}`)
    if (omdrStartDate && omdrEndDate) {
        params.push(`start_date=${omdrStartDate}`);
        params.push(`end_date=${omdrEndDate}`);
    }

    let paramString = ''
    params && params.forEach((param, index) => (
        index === 0 ? paramString += `?${param}` : paramString += `&${param}`
    ));

    return paramString;
}

// Build the parameter string for the request header
export function buildOMDCPatientListFilter(patientList: IPatientList) {
    const { examPeriod, omdcStatus, searchQuery } = patientList;

    let params = [];

    omdcStatus !== Constants.ALL_STATUSES_KEY && params.push(`exam_status=${omdcStatus}`);
    examPeriod !== Constants.ALL_TIME_KEY && params.push(`exam_date=${examPeriod}`);
    searchQuery && params.push(`name_or_health_number=${searchQuery}`)

    let paramString = ''
    params && params.forEach((param, index) => (
        index === 0 ? paramString += `?${param}` : paramString += `&${param}`
    ));

    return paramString;
}

export function buildADMINPatientListFilter(patientList: IPatientList) {
    const { adminPeriodFilter, adminOdProgramFilter, adminUnflaggedFilter, adminFlaggedFilter,
        adminMiscFilter, adminSearchOMDFilter, adminSearchGPFilter, adminSearchODFilter, adminSearchIGPFilter,
        adminAuditRecordFilter, adminAuditRecordStartDate, adminAuditRecordEndDate, inactivePatientChecked,
        inactiveExamChecked, testPatientChecked
    } = patientList;

    let params = [];

    adminPeriodFilter && params.push(`exam_date=${adminPeriodFilter}`);
    adminOdProgramFilter && params.push(`od_program=${adminOdProgramFilter}`);
    adminUnflaggedFilter && params.push(`unflagged_filter=${adminUnflaggedFilter}`);
    adminFlaggedFilter && params.push(`flagged_filter=${adminFlaggedFilter}`);
    adminMiscFilter && params.push(`misc_filter=${adminMiscFilter}`);
    adminSearchIGPFilter && params.push(`igp=${adminSearchIGPFilter}`);
    adminSearchGPFilter && params.push(`gp=${adminSearchGPFilter}`);
    adminSearchODFilter && params.push(`od=${adminSearchODFilter}`);
    adminSearchOMDFilter && params.push(`omd=${adminSearchOMDFilter}`);
    adminAuditRecordFilter && params.push(`audit_filter=${adminAuditRecordFilter}`);
    adminAuditRecordStartDate && params.push(`start_date=${adminAuditRecordStartDate}`);
    adminAuditRecordEndDate && params.push(`end_date=${adminAuditRecordEndDate}`);

    // If inactive filters are false set request header
    if(params){
        if(!inactivePatientChecked){
            params.push(`no_inactive_patients=true`);
        }
        if(!inactiveExamChecked){
            params.push('no_inactive_exams=true');
        }
        if(!testPatientChecked){
            params.push('no_test_patients=true');
        }
    }

    let paramString = ''
    params && params.forEach((param, index) => (
        index === 0 ? paramString += `?${param}` : paramString += `&${param}`
    ));

    return paramString;
}

export function convertOMDCPatientListToTable(filteredPatientList: IOMDCPatientListItemResponse[]) {
    return filteredPatientList.map((patient) => ({
        // Patient id
        key: patient.id,
        // First name
        first_name: patient.first_name,
        // Last name
        last_name: patient.last_name,
        // DOB
        dob: `${patient.dob} (${patient.age})`,
        // OD
        od: patient.od,
        // Chief complaint
        chief_complaint: patient.chief_complaint,
        // Number of visits
        visits: patient.visits,
        // Exam date. Convert the date from UTC to local time.
        exam_date: convertTimeToShortFormatLabel(convertUtcToLocal(patient.exam_date)),
        // OMDC status
        omdc_status: patient.omdc_status,
        // Latest visit ID
        latest_exam_id: patient.latest_exam_id,
    }));
   
}

export function convertOMDRPatientListToTable(filteredPatientList: IOMDRPatientListItemResponse[]) {

        return filteredPatientList.map((patient) => ({
            // Patient id
            key: patient.id,
            // Flag
            flag: patient.flag,
            // Status
            status: patient.status,
            // Last name
            last_name: patient.last_name,
            // First name
            first_name: patient.first_name,
            // Province
            province: patient.province,
            // OD
            od: patient.od,
            // OMDC
            omdc: patient.omdc,
            // DOB
            dob: `${patient.dob} (${patient.age})`,
            // Chief complaint
            chief_complaint: patient.chief_complaint,
            // GLC Class
            glc_class: patient.glc_class,
            // IOP
            iop: patient.iop,
            // bIOP
            b_iop: patient.b_iop,
            // bDISC
            b_disc: patient.b_disc,
            // bFIELD
            b_field: patient.b_field,
            // Latest Visit (Date)
            latest_visit: convertTimeToShortFormatLabel(convertUtcToLocal(patient.latest_visit)),
            // Latest Visit (ID)
            latest_visit_id: patient.latest_visit_id,
            // Special Notes
            special_notes: patient.special_notes,
            // Alerts
            alerts: patient.alerts,
            // PR Alerts
            pr_alerts: patient.pr_alerts,
            // Number of visits
            visits: patient.visits,
            // PRvwr (Pre-Reviewer)
            prvwr: patient.prvwr,
            // Complexity
            complexity: patient.complexity,
            // isOdOrientating
            isOdOrientating: patient.isOdOrientating,
            // isOdSpecialAttention
            isOdSpecialAttention: patient.isOdSpecialAttention,
            // isOdIntegrated
            isOdIntegrated: patient.isOdIntegrated,
            // Filter type
            filter_type: patient.filter_type,
            // isReferralLetterUploadPei
            isReferralLetterUploadPei: patient.isReferralLetterUploadPei,
        }));

}

export function convertADMINPatientListToTable(filteredPatientList: IAdminPatientListItemResponse[]) {

    return filteredPatientList.map((patient) => ({


        // Exam id
        key: patient.exam_id,
        exam_id: patient.exam_id,
        // Flag
        flag: patient.flag,
        // Pre Reviewer
        pre_reviewer: patient.pre_reviewer,
        // Last name
        last_name: patient.last_name,
        // First name
        first_name: patient.first_name,
        // Ref type
        referral_type: patient.referral_type,
        // Last Exam (Date)
        latest_visit: convertTimeToShortFormatLabel(convertUtcToLocal(patient.latest_visit)),
        // OMDR
        omdr: patient.omdr === 'None' ? '' : patient.omdr,
        // Province
        province: patient.province,
        // OD
        od: patient.od,
        // OD Group Practice
        od_group: patient.od_group,
        // OMDC
        omdc: patient.omdc === 'None' ? '' : patient.omdc,
        // OMDC Status
        omdc_status: patient.omdc_status,
        // DOB
        dob: `${patient.dob} (${patient.age})`,
        age: patient.age,
        // Number of visits
        visits: patient.visits,
        // IGP status
        igp_status: patient.igp_status,
        // IGP
        igp: patient.igp,
        // EGP status
        egp_status: patient.egp_status,
        // PR Alerts
        pr_alerts: patient.pr_alerts,
        // Special Notes
        special_notes: patient.special_notes,
        // Status
        status: patient.status,
        // Alerts
        alerts: patient.alerts,
        // Test Patient
        test_patient: patient.test_patient,
        // OD Integrated (Should we mail out GP letters for this OD?)
        od_integrated: patient.od_integrated,
        // OD Retina Only (Care1 Enabled)
        od_retina_only: patient.od_retina_only,
         // OD Retina Only (Care1 Enabled)
        od_essentials_only: patient.od_essentials_only,
        // Filter type
        filter_type: patient.filter_type,
        // isOdOrientating (Is this OD currently orientating)
        isOdOrientating: patient.isOdOrientating,
        // isOdSpecialAttention (Give this ODs uploads special attention?)
        isOdSpecialAttention: patient.isOdSpecialAttention,
        // Is US Only
        isUsOnly: patient.is_us_only,
        // Is Inactive
        is_inactive: patient.is_inactive,

        // Include fax numbers for the bulk fax requests
        // Fax
        fax: patient.fax,
        // Fax2
        fax2: patient.fax2,
        // IGP Fax
        igp_fax: patient.igp_fax,
        // GP Status
        gp_status: patient.gp_status,
        // whether the patient is deactivated
        patient_deactivated: patient.patient_deactivated,
        // patient visit number
        visit_number: patient.visit_number,

        // https://care1inc.atlassian.net/browse/CP4-342
        red_flag: patient.red_flag,

        submission_date: patient.submission_date ? convertTimeToShortFormatLabel(convertUtcToLocal(patient.submission_date)) : '',

        od_requested_omd: patient.od_requested_omd,
    }));

}

export function convertODPatientListToTable(filteredPatientList: IODPatientListItemResponse[]) {

    return filteredPatientList.map((patient) => ({
        // Patient id or Smart Upload ID
        key: patient[Constants.BACKEND_PATIENT_ID_FIELD],
        // First name
        [Constants.FIRST_NAME_FIELD]: patient[Constants.FIRST_NAME_FIELD],
        // Last name
        [Constants.LAST_NAME_FIELD]: patient[Constants.LAST_NAME_FIELD],
        // DOB
        [Constants.DOB_FIELD]: patient['isSmartUpload']
            ? patient[Constants.DOB_FIELD]
            : `${patient[Constants.DOB_FIELD]} (${
                  patient[Constants.AGE_FIELD]
              })`,
        // AGE
        [Constants.AGE_FIELD]: patient[Constants.AGE_FIELD],
        // OD
        [Constants.DOCTOR_NAME_FIELD]: patient[Constants.DOCTOR_NAME_FIELD],
        // Chief complaint
        [Constants.CHIEF_COMPLAINT_FIELD]: patient[Constants.CHIEF_COMPLAINT_FIELD],
        // Number of visits
        [Constants.NUMBER_VISITS_FIELD]: patient[Constants.NUMBER_VISITS_FIELD],
        // Last visit. Convert the date from UTC to local time.
        [Constants.LAST_VISIT_DATE_FIELD]: patient['isSmartUpload'] ?  patient[Constants.LAST_VISIT_DATE_FIELD] : convertTimeToShortFormatLabel(convertUtcToLocal(patient[Constants.LAST_VISIT_DATE_FIELD])),
        // Latest review. Convert the date from UTC to local time.
        [Constants.LAST_REVIEWED_DATE_FIELD]: convertTimeToShortFormatLabel(convertUtcToLocal(patient[Constants.LAST_REVIEWED_DATE_FIELD])),
        // Review status
        [Constants.LATEST_EXAM_STATUS_FIELD]: patient[Constants.LATEST_EXAM_STATUS_FIELD],
        // Latest visit ID
        [Constants.LATEST_EXAM_ID_FIELD]: patient[Constants.LATEST_EXAM_ID_FIELD],
        // All patient is not selected when page is first loaded
        [Constants.IS_SELECTED]: false,
        // Referral status of the exam.
        referral_status: patient['referral_status'],
        // submission_date
        submission_date: patient['submission_date'] ? convertTimeToShortFormatLabel(convertUtcToLocal(patient['submission_date'])) : '',
        // whether entry is smart upload
        isSmartUpload: patient['isSmartUpload'],
    }));
}

export function convertGlaucomaProgramPatientListToTable(filteredPatientList: IGlaucomaProgramPatientListItemResponse[]) {

    return filteredPatientList.map((patient) => ({
        key: patient.id,
        exam_id: patient.exam_id,
        last_name: patient.last_name,
        first_name: patient.first_name,
        chief_complaint: patient.chief_complaint,
        last_visit: convertTimeToShortFormatLabel(convertUtcToLocal(patient.last_visit)),
        next_suggested_visit: convertTimeToShortFormatLabel(convertUtcToLocal(patient.next_suggested_visit)),
        testing_needed: patient.testing_needed,
        testing_frequency: patient.testing_frequency,
        booking_status: patient.booking_status,
        comments: patient.comments,
        iop: patient.iop,
        cd: patient.cd,
        vf: patient.vf,
    }));

}

// Get the alert color for the passed in alert from the prereview alert options
export function getPreReviewAlertColor(alert: string, prereviewAlertOptions: IPrereviewAlertOption[]){

    const alertOption = prereviewAlertOptions.find(opt => opt.alert === alert);

    return alertOption ? alertOption.color : '';
}

export const getIopClassName = (iop: string) => {
    const ALERT_IOP = 23;
    const WARNING_IOP = 20;
    const NORMAL_IOP = 15;
    const iopValue = Number(iop);
    if (iopValue >= ALERT_IOP) {
        return 'alert-iop';
    } else if (iopValue < ALERT_IOP && iopValue >= WARNING_IOP) {
        return 'warning-iop';
    } else if (iopValue < NORMAL_IOP) {
        return 'normal-iop';
    } else {
        return '';
    }
}

export function getBiopColor(iop: string | number) {
    const iopValue = Number(iop);
    if (iopValue < 15) {
        return 'normal-biop';
    } else if (iopValue >= 15 && iopValue < 21) {
        return '';
    } else if (iopValue >= 21 && iopValue < 24) {
        return 'warning-biop';
    } else if (iopValue >= 24) {
        return 'high-biop';
    }
    return 'empty-biop';
}

export function getGlcTypeColor(iop: string, iopAim: number | null) {
    const iopValue = Number(iop);
    const iopAimValue = Number(iopAim);
    if (iopValue && iopAimValue) {
        const diff = iopValue - iopAimValue;
        if (diff <= 0) {
            return 'normal-glc';
        } else if (diff > 0 && diff <= 2) {
            return 'warning-glc';
        } else if (diff > 2) {
            return 'alert-glc';
        }
        return '';
    }
    return '';
}

export const getGlaucomaProgramIopClassName = (iop: string) => {
    const WARNING_IOP = 21;
    const NORMAL_IOP = 20;
    const iopValue = Number(iop);
    if (iopValue > WARNING_IOP) {
        return 'alert-iop';
    } else if (iopValue <= WARNING_IOP && iopValue >= NORMAL_IOP) {
        return 'warning-iop';
    } else if (iopValue < NORMAL_IOP) {
        return 'normal-iop';
    } else {
        return '';
    }
}

export const getGlaucomaProgramCdClassName = (cd: string) => {
    const WARNING_CD = 0.65;
    const NORMAL_CD = 0.45;
    const cdValue = Number(cd);
    if (cdValue > WARNING_CD) {
        return 'alert-cd';
    } else if (cdValue <= WARNING_CD && cdValue >= NORMAL_CD) {
        return 'warning-cd';
    } else if (cdValue < NORMAL_CD) {
        return 'normal-cd';
    } else {
        return '';
    }
}

export const getGlaucomaProgramVFClassName = (vf: string) => {
    if (vf === 'onl') {
        return 'alert-vf';
    } else if( vf === 'wnl') {
        return 'normal-vf';
    } else {
        return 'warning-vf';
    }
}
