import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { Select } from 'antd';
import '../../../../static/css/components/patient-exam-iop-instrument.scss';
import { VERBOSE_APPLANATION_OPTIONS } from '../../../constants';

type ComponentProps = {
    applanation: string;
    onSelect: (value: string) => void;
    disabled: boolean;
    narrowColumn: boolean;
    verbose: boolean;
    gptResultState: string;
}

const GptPatientExamIOPInstrument = ({ applanation, onSelect, disabled, narrowColumn, verbose, gptResultState }: ComponentProps) => {
    const shortApplanationOptions = useCare1AppSelector(store => store.options.applanations);
    const applanationOptions = verbose ? VERBOSE_APPLANATION_OPTIONS : shortApplanationOptions;

    return (
        <div className={`${gptResultState}`}>
            <div className={`iop-instrument ${narrowColumn ? 'narrow-column' : ''}`}>
                <Select
                    data-testid='iop-instrument-select'
                    size={'small'}
                    disabled={disabled}
                    popupClassName="applanation-list-dropdown"
                    showSearch
                    value={applanation}
                    onSelect={onSelect}
                >
                    {
                        applanationOptions && applanationOptions.map((option: { value: string, label: string }) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))
                    }
                </Select>
            </div>
        </div>
    );
}

export default GptPatientExamIOPInstrument;
