import { baseApi } from './base-api'

export interface IVfNearMissListResponse {
    near_miss_list: [];
}

export interface IGptVisionChatVfNearMissListResponse {
    near_miss_list: {
        summary: {
            rows: [],
            days: [],
        }
    };
}

export const vfNearMissApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getVfNearMissList: builder.query<IVfNearMissListResponse, void>({
            query: () => 'admin/vf_near_miss/list/',

            // Give a tag to staffs so that other API actions can invalidate VfNearMissList
            providesTags: [{type: 'VfNearMissList', id: 'LIST'}]
        }),
        getVfNearMissSummary: builder.query<IGptVisionChatVfNearMissListResponse, void>({
            query: () => 'admin/vf_near_miss/summary/'
        }),
    }),
});



// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const {useGetVfNearMissListQuery, useGetVfNearMissSummaryQuery} = vfNearMissApi;