import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { logout } from './user-slice';
import { convertLocalToPST, getCsrfToken } from '../helpers/utilities';
import { apiRequest } from '../services/api-request';

export interface IRandomSentences {
    '1-2d': string;
    '1-2w': string;
    '1-2x/Wk': string;
    '1m': string;
    '4m': string;
    '4-6m': string;
    '6m': string;
    '6-12m': string;
    '12m': string;
    add_aa: string;
    add_cai: string;
    add_sim: string;
    add_slt: string;
    bb_cai: string;
    betablocker: string;
    different_pg: string;
    different_pg_bb: string;
    effective_drop_sentence: string;
    effective_fu_sentence: string;
    no_gtts: string;
    not_effective_drop_sentence: string;
    not_effective_fu_sentence: string;
    offer_all: string;
    pg_bb: string;
    pg_slt: string;
    prostaglandin: string;
    refrigeration_or_artificial_tears_sentence: string;
    same_gtts: string;
    side_effects_drop_sentence: string;
    slt_date_sentence: string;
    start_drop_sentence: string;
    start_fu_sentence: string;
    start_iop_zone_sentence: string;
    switch_to_bb_cai_pg: string;
    topical_date_sentence: string;
}
export const defaultRandomSentences: IRandomSentences = {
    '1-2d': '',
    '1-2w': '',
    '1-2x/Wk': '',
    '1m': '',
    '4m': '',
    '4-6m': '',
    '6m': '',
    '6-12m': '',
    '12m': '',
    add_aa: '',
    add_cai: '',
    add_sim: '',
    add_slt: '',
    bb_cai: '',
    betablocker: '',
    different_pg: '',
    different_pg_bb: '',
    effective_drop_sentence: '',
    effective_fu_sentence: '',
    no_gtts: '',
    not_effective_drop_sentence: '',
    not_effective_fu_sentence: '',
    offer_all: '',
    pg_bb: '',
    pg_slt: '',
    prostaglandin: '',
    refrigeration_or_artificial_tears_sentence: '',
    same_gtts: '',
    side_effects_drop_sentence: '',
    slt_date_sentence: '',
    start_drop_sentence: '',
    start_fu_sentence: '',
    start_iop_zone_sentence: '',
    switch_to_bb_cai_pg: '',
    topical_date_sentence: '',
}

export interface IInitiateTreatment {
    isInitialTreatmentModalOpen: boolean;
    treatment_drop: string;
    treatment_fu: string;
    treatment_iop_od: string;
    treatment_iop_od_custom: string;
    treatment_iop_os: string;
    treatment_iop_os_custom: string;
    side_effect_drop: string;
    alternate_treatment_drop: string;
    alternate_treatment_fu: string;
    alternate_treatment_fu_custom: string;
    treatment_success_drop: string;
    treatment_success_fu: string;
    treatment_success_fu_custom: string;
    status: string;
    error: string;
    randomSentences: IRandomSentences;
}

const initialState: IInitiateTreatment = {
    isInitialTreatmentModalOpen: false,
    treatment_drop: 'prostaglandin',
    treatment_fu: '1m',
    treatment_iop_od: '',
    treatment_iop_od_custom: '',
    treatment_iop_os: '',
    treatment_iop_os_custom: '',
    side_effect_drop: 'different_pg',
    alternate_treatment_drop: 'pg_bb',
    alternate_treatment_fu: '1m',
    alternate_treatment_fu_custom: '',
    treatment_success_drop: 'same_gtts',
    treatment_success_fu: '4-6m',
    treatment_success_fu_custom: '',
    status: 'idle',
    error: '',
    randomSentences: defaultRandomSentences,
}

export const getRandomSentencesRequest = createAsyncThunk(
    'initiateTreatment/getRandomSentences',
    async (keys: string[], {dispatch, getState, rejectWithValue}) => {
        const {
            user: { csrfToken }, examData: { id, exam_date }} = getState() as {
            user: { csrfToken: string }, examData: { id: number, exam_date: string }};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const data = new FormData();
        data.append('seed_number', id.toString());
        data.append('seed_date', convertLocalToPST(exam_date));
        data.append('keys', JSON.stringify(keys));

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/generate_random_sentences/`;
       
        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const initiateTreatmentTimestampRequest = createAsyncThunk(
    'initiateTreatment/initiateTreatmentTimestamp',
    async (exam_id: number, {dispatch, getState, rejectWithValue}) => {
        const {
            user: { csrfToken }} = getState() as {
            user: { csrfToken: string }};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const data = '{}';

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/${exam_id}/easy_tx_timestamp/`;
       
        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const patientExamInitiateTreatmentSlice = createSlice({
    name: 'initiateTreatment',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setInitiateTreatmentDataValue: (state, action) => {
            // action.payload, e.g. {key:'isInitialTreatmentModalOpen', value: true/false}
            if(action.payload){
                return {...state, [action.payload.key]: action.payload.value}
            }
            return state;
        },
        resetInitiateTreatment: () => initialState,
    },
    extraReducers: (builder) => {

        builder.addCase(getRandomSentencesRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getRandomSentencesRequest.fulfilled, (state, action) => {
            return {
                ...state, 
                randomSentences: {
                    ...state.randomSentences,
                    ...action.payload.data,
                },
                status: 'idle',
            }
        });
        builder.addCase(getRandomSentencesRequest.rejected, (state, action) => {
            state.status = 'failed';

            // handle the rejected case, the value was passed from rejecteWithValue
            Modal.error({
                className: 'info-modal',
                title: `Errors getting random sentences. ${action.payload}`,
            })
        });
        builder.addCase(initiateTreatmentTimestampRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(initiateTreatmentTimestampRequest.fulfilled, (state, action) => {
            state.status = 'idle';
            state.error = '';
        });
        builder.addCase(initiateTreatmentTimestampRequest.rejected, (state, action) => {
            state.status = 'failed';

            // handle the rejected case, the value was passed from rejecteWithValue
            Modal.error({
                className: 'info-modal',
                title: `Errors creating treatment time stamp. ${action.payload}`,
            })
        });
    },
    
});

// Export the actions related to initiate treatment.
export const { resetInitiateTreatment, setInitiateTreatmentDataValue } = patientExamInitiateTreatmentSlice.actions;
export default patientExamInitiateTreatmentSlice.reducer;
