import { SearchOutlined } from '@ant-design/icons';
import { Col, Row, Input, Typography, AutoComplete, Tag, Spin } from 'antd';
import '../../../static/css/components/patient-select-modal.scss'; // Styling Audit - New modal stylesheet
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { useLazyGetPatientsQuery } from '../../services/patient-api';
import { closePatientSelectModal, openNewExamDateModal } from '../../reducers/patient-edit-slice';


type ComponentProps = {
    value: string,
    setValue: (v: string) => void,
}

const PatientSearchBar = ({ value, setValue }: ComponentProps) => {

    const [getPatients] = useLazyGetPatientsQuery();

    const DEBOUNCE_TIMEOUT = 800;
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState<{ value: string; label: JSX.Element }[]>([]);

    const dispatch = useCare1AppDispatch();

    const handleSearch = useCallback(async (value: string) => {
        if (value) {
            try {
                setFetching(true);
                setOptions([]);
                const res = await getPatients({ name: value }).unwrap();
                setFetching(false);
                const autoCompleteOptions = res.map(({ id, name, birthday, phn, caf_personnel, is_mb_patient, service_number, secondary_phn }) => {
                    const phnDisplay = phn ? phn : <Tag className='no-phn'>No Health #</Tag>
                    const secondaryPhnDisplay = secondary_phn ? secondary_phn : <Tag className='no-phn'>No Health #</Tag>
                    const serviceNumberDisplay = service_number ? service_number : <Tag className='no-phn'>No Health #</Tag>
                    return {
                        value: id.toString(),
                        label: (caf_personnel ?
                            <Row justify='space-between'>
                                <Col span={9}><Typography.Text ellipsis>{name}</Typography.Text></Col>
                                <Col span={5}>{birthday}</Col>
                                <Col span={2}><Tag className='caf'>CAF</Tag></Col>
                                <Col span={6} >{serviceNumberDisplay}</Col>
                            </Row>
                            : is_mb_patient ?
                                <>
                                    <Row justify='space-between'>
                                        <Col span={9}><Typography.Text ellipsis>{name}</Typography.Text></Col>
                                        <Col span={5}>{birthday}</Col>
                                        <Col span={2}><Tag className='mb-patient'>MB</Tag></Col>
                                        <Col span={6} >{phnDisplay}</Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col span={9} />
                                        <Col span={5} />
                                        <Col span={2} />
                                        <Col span={6} >{secondaryPhnDisplay}</Col>
                                    </Row>
                                </>
                                :
                                <Row justify='space-between'>
                                    <Col span={9}><Typography.Text ellipsis>{name}</Typography.Text></Col>
                                    <Col span={5}>{birthday}</Col>
                                    <Col span={2} />
                                    <Col span={6} >{phnDisplay}</Col>
                                </Row>
                        )
                    }
                })
                setOptions(autoCompleteOptions);
            } catch (error) {
                console.error(error);
                setFetching(false);
            }
        }
    }, [getPatients])

    const handleDebouncedSearch = useMemo(() => {
        return debounce(handleSearch, DEBOUNCE_TIMEOUT)
    }, [handleSearch])

    const onSelect = (value: string) => {
        onClose();
        // open patient select modal, to be implemented in CP4-147
        dispatch(openNewExamDateModal(value));
    };

    useEffect(() => {
        return () => {
            handleDebouncedSearch.cancel();
        }
    }, [handleDebouncedSearch])


    const onClose = () => {
        setValue('');
        dispatch(closePatientSelectModal());
    }

    const onChange = (data: string) => {
        setValue(data);
    };

    return (
        <AutoComplete
            value={value}
            onSelect={onSelect}
            onSearch={handleDebouncedSearch}
            options={options}
            onChange={onChange}
            notFoundContent={fetching ? <Spin size="small" /> : null}
        >
            <Input.Search
                prefix={<SearchOutlined />}
            />
        </AutoComplete>
    );
}

export default PatientSearchBar;
