import { Modal, Button, Form,  Input } from 'antd';
import '../../../static/css/components/new-staff-modal.scss';

// Action imports
import { SaveOutlined } from '@ant-design/icons';
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { IPasswordData, useChangePasswordMutation } from '../../services/staff-api';
import { setShowPasswordModal } from '../../reducers/doctor-slice';

const PasswordChangeModal = () => {

    const isVisible = useCare1AppSelector(store => store.doctorSlice.showPasswordChangeModal);
    const id = useCare1AppSelector(store => store.doctorSlice.currentStaffId);
    const username = useCare1AppSelector(store => store.doctorSlice.currentUsername);
    
   
    const [changePassword] = useChangePasswordMutation();

    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const handleModalClose = () => {
        dispatch(setShowPasswordModal(false));
    };

    const addButton = (
        <Button
            key='save-button'
            onClick={() => form.submit()}
            className='save-button'
            icon={<SaveOutlined />}
        >
            Save
        </Button>
    );

    const closeButton = (
        <Button
            key='close-button'
            onClick={handleModalClose}
            className='close-button'
        >
            Cancel
        </Button>
    );

    const onFinish = async (values: {password: string, confirm_password: string}) => {
        try {
            const { confirm_password, ...rest } = values;
            const passwordData: IPasswordData = {
                ...rest,
                key: id!,
            }
            const result = await changePassword(passwordData).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'Password successfully changed.',
                    onOk: () => {
                        dispatch(setShowPasswordModal(false));
                    }
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    return (
        <Modal
            className='new-staff-modal password-change-modal'
            open={isVisible}
            closable={false}
            title={`Enter a new password for the user ${username}`}
            footer={[closeButton, addButton]}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <Form
                form={form}
                labelCol={{span:5}}
                wrapperCol={{span: 18}}
                className='new-staff-modal-form'
                colon={false}
                requiredMark={false}
                onFinish={onFinish}
            >
                <Form.Item
                    label={`Password`}
                    name='password'
                    rules={[{
                        required: true,
                        message: buildRequiredErrorMessage('Password') }
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name='confirm_password'
                    label='Confirm Password'
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('The new password that you entered do not match!'));
                        },
                    }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default PasswordChangeModal;
