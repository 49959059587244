import { useEffect, useState } from 'react';
import { Modal,  Form, Row, Button, Input, Col, Tooltip } from 'antd';
import { CloseCircleOutlined, CloudDownloadOutlined, EditOutlined, MailOutlined, SaveOutlined } from '@ant-design/icons';
import '../../../static/css/components/letter-modal.scss'
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { editPatientEmailRequest, getPatientDetailsRequest } from '../../reducers/patient-details-slice';
import { useSendPatientHandoutMutation } from '../../services/exam-api';
import { getPatientHandoutPdfRequest } from '../../reducers/letters-slice';

type ComponentProps = {
    isVisible: boolean,
    setIsVisible: (letterModalVisible: boolean) => void
}

const PatientHandoutEmailModal = ({isVisible, setIsVisible} : ComponentProps) => {
    
    const email = useCare1AppSelector(store => store.patientDetails.details.email);
    const patientId = useCare1AppSelector(store => store.patientDetails.details.id);
    const examId = useCare1AppSelector(store => store.examData.id);

    const dispatch = useCare1AppDispatch();
    const [sendPatientHandout, {isLoading: isPatientHandoutEmailLoading}] = useSendPatientHandoutMutation();
    const [ form ] = Form.useForm();
    const [editMode, setEditMode] = useState(false);

    const formEmailValue = Form.useWatch('email', form);

    const handleCancel = () => {
        setIsVisible(false);
    }

    const handleSendEmail = async () => {
        try {
            const result = await sendPatientHandout({
                examId: examId!,
                email:formEmailValue,
                }).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: `Patient handout email sent successfully.`,
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    const handleEmailUpdate = async () =>{
        try {
            const result = await dispatch(editPatientEmailRequest({
                patientId: patientId!,
                email:formEmailValue,
                })).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: `Patient's email updated successfully.`,
                    onOk: () => {
                        dispatch(getPatientDetailsRequest(patientId!));
                        setEditMode(false);
                    }
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }
   
    useEffect(() => {
        // reset all fields
        form.setFieldsValue({email})
        
        return () => {
            form.resetFields();
        }
    }, [form, email])

    return (
        <Modal
            open={isVisible}
            className='letter-modal patient-handout-email-modal'
            footer={null}
            width={600}
            onCancel={handleCancel}
            title={'Patient Handout'}
        >

        <Form form={form} requiredMark={false}>
            <Row className='entry-row' justify='center'>
                <Col span={22}>
                    <Form.Item
                        label='Email'
                        name='email'
                        rules={[{required: true}]}
                    >
                        <Input
                            size='middle'
                            prefix={<MailOutlined />}
                            disabled={!editMode}
                            placeholder={'Email'}
                        />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    {
                        editMode ? 
                        
                            <Button
                                icon={<SaveOutlined />}
                                className='edit-button'
                                onClick={handleEmailUpdate}
                            />
                        :
                        <Tooltip title={`Click to edit patient's email`}>
                            <Button
                                icon={<EditOutlined />}
                                className='edit-button'
                                onClick={() => setEditMode(true)}
                            />
                        </Tooltip>
                    }
                    
                </Col>
            </Row>
            <Row className='buttons-row' justify='space-between'>
                <Button
                    className='cancel-button'
                    onClick={handleCancel}
                    icon={<CloseCircleOutlined />}
                >
                    Close
                </Button>
                <div>
                    <Button
                        className='generate-button'
                        icon={<CloudDownloadOutlined />}
                        onClick={() => dispatch(getPatientHandoutPdfRequest())}
                    >
                        Download Handout
                    </Button>
                    <Button
                        icon={<MailOutlined />}
                        type='primary'
                        onClick={handleSendEmail}
                        loading={isPatientHandoutEmailLoading}
                    >
                        Email Handout to Patient
                    </Button>
                </div>
            </Row>
            </Form>
        </Modal>
    )
}

export default PatientHandoutEmailModal;