import { useState } from "react";
import { useCare1AppDispatch, useCare1AppSelector } from "../apps/care1-hooks";
import { ISmartUpload, updateSmartUploadData } from "../reducers/smart-upload-slice";


type DataToSave = {
    key: keyof ISmartUpload;
    value: string | boolean | number | null;
}

const useOnBlurSave = (dataToSave: DataToSave) => {
    const smartUploadId = useCare1AppSelector((store) => store.smartUpload.id);

    const [lastSavedData, setLastSavedData] = useState<Record<string, string | boolean | number | null>>({});
    const [error, setError] = useState('');

    const dispatch = useCare1AppDispatch();

    const handleBlur = async () => {
        const {key, value} = dataToSave;

        if (value !== lastSavedData[key]){
            const res = await dispatch(updateSmartUploadData({
                id: smartUploadId,
                [key]: value,
            })).unwrap();
            if (res.success) {
                setLastSavedData(prev => ({
                    ...prev,
                    [key]: value,
                }))
            } else {
                setError(res?.error);
            }
        }
    }

    return {
        handleBlur,
        error
    }
}

export default useOnBlurSave;