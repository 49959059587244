import { useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { DropzoneComponent } from 'react-dropzone-component';
import { photoDropzoneValidityCheck } from '../../../helpers/patient-exam-convert';
import Care1LightBox from '../../retina/care1-light-box';
import TrashButton from '../../../../static/images/trash_button.svg';
import { DELETE_CONFIRMATION_CANCEL_TEXT, DELETE_CONFIRMATION_MODAL_TEXT, DELETE_CONFIRMATION_MODAL_TITLE, DELETE_CONFIRMATION_OK_TEXT, FILE_UPLOAD_FAILED_TEXT } from '../../../constants';
import { getUsInsuranceImageRequest } from '../../../reducers/us-insurance-slice';
import '../../../../static/css/components/patient-insurance-images.scss';
import { dropzoneSlice } from '../../../reducers/dropzone-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';

import * as Dropzone from 'dropzone';
import * as ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';

const displayNone = { display: 'none' };

type UsInsuranceResponse = {
    success: string,
    id: string,
    file: {key: string, url: string}[],
}

const PatientInsuranceImages = ({field}: {field: 'cardBackImage' | 'cardFrontImage'}) => {

    const csrfToken = useCare1AppSelector(store => store.user.csrfToken);
    const currentPhotoUrl = useCare1AppSelector(store => store.usInsurance[field]);
    const dropzone = useCare1AppSelector(store => store.dropzones[field])
    const dispatch = useCare1AppDispatch();
    const insuranceId = useCare1AppSelector(store => store.usInsurance.id);

    const dropzoneInstance = dropzoneSlice[field];

    const { deletePhotoCancelled, deletePhotoConfirm, retrievePhotoRequest, retrievePhotoSuccess, setUploadProgress, toggleLightbox, uploadPhotoFailure, uploadPhotoRequest, uploadPhotoSuccess} = dropzoneInstance.actions;

    const {deleteInsurancePhoto} = dropzoneInstance;

    const [dropzoneObject, setDropzoneObject] = useState<Dropzone | null>(null);

    const extensionIndex = currentPhotoUrl ? currentPhotoUrl.lastIndexOf(".") : -1;
    const photoThumbnailUrl = currentPhotoUrl ? `${currentPhotoUrl.substring(0, extensionIndex)}_largethumb${currentPhotoUrl.substring(extensionIndex)}` : '';

    const fieldText = field === 'cardBackImage' ? 'Back Image' : 'Front Image';
    const backendFiled = field === 'cardBackImage' ? 'card_back_image': 'card_front_image';
    const hasPhoto = dropzone.photo !== '';
    const photoName = hasPhoto ? (dropzone.photo as File).name : '';
    const hasError = dropzone.error !== '';
    const errorMessage = hasError ? dropzone.error : '';
    const inProgress = dropzone.operationRunning;
    const progressPercentage = dropzone.percentage;
    const { lightboxOn } = dropzone;
    const isValid = photoDropzoneValidityCheck(dropzone);
    
    const componentConfig = { postUrl: `${process.env.REACT_APP_BACKENDURL}/data/us/insurance/upload/` };
    const djsConfig = {
        acceptedFiles: 'image/jpeg,image/png,image/gif,image/tiff,image/bmp,application/pdf,application/x-pdf',
        clickable: false,
        createImageThumbnails: false,
        withCredentials: true,
        headers: { 'X-CSRFToken': csrfToken },
        maxFiles: 1,
        paramName: backendFiled,
        params: { insurance: insuranceId },
        // Some of the divs in the preview area are needed by the react dropzone component, so they are permanently
        // hidden, but are not used by us.
        previewTemplate: ReactDOMServer.renderToStaticMarkup(
            <div className="dz-preview dz-file-preview">
                <div className="dz-details" style={displayNone}>
                    <div className="dz-filename"><label data-dz-name="true" /></div>
                </div>
                <div className="dz-progress" style={displayNone}><label className="dz-upload" data-dz-uploadprogress="true" /></div>
                <div className="dz-error-message" style={displayNone}><label data-dz-errormessage="true" /></div>
            </div>,
        ),
    };
    
    const dropzoneMessageClass = classNames({
        'dz-message': true,
        'dz-hide': hasPhoto || hasError,
    });

    const errorMessageClass = classNames({
        'dropzone-error': true,
        'dz-hide': !hasError,
    });

    const progressBarClass = classNames({
        'dropzone-progress-container': true,
        'dz-hide': !inProgress,
    });

    const progressWidth = { width: `${progressPercentage}%` };

    // The preview image is shown if there is no error and nothing is in progress.
    const detailsClass = classNames({
        'dropzone-details': true,
        'dz-hide':  hasError || !hasPhoto,
    });

    // Determine the classes used for the borders of the dropzone.
    // If there is a photo, the dropzone is considered valid, and it's considered invalid if there is no photo.
    // Validity causes a green or red border to show.
    const dropzoneBorderClass = classNames({
        'photo-dropzone': true,
        'photo-dropzone-valid': isValid,
        'photo-dropzone-invalid': !isValid,
    });

    const dropzoneDeleteClass = classNames({
        'dropzone-delete': true,
        'dz-hide': false,
    });

    const sendToggleLightbox = () => {
        dispatch(toggleLightbox());
    };

    const sendDeletePhotoCancelled = () => {
        // Send the action to close the confirmation dialog without doing anything.
        dispatch(deletePhotoCancelled());
    }

    const sendDeletePhotoRequest = async () => {
        try {
            await dispatch(deleteInsurancePhoto(field));
            dispatch(getUsInsuranceImageRequest(insuranceId!));
        } catch (error) {
            console.log('error is ', error);
        }
    }

    const sendDeletePhotoConfirm = () => {
        dispatch(deletePhotoConfirm());
        Modal.confirm({
            className: 'delete-confirmation-modal',
            title: DELETE_CONFIRMATION_MODAL_TITLE,
            content: DELETE_CONFIRMATION_MODAL_TEXT,
            icon: <InfoCircleOutlined />,
            okText: DELETE_CONFIRMATION_OK_TEXT,
            cancelText: DELETE_CONFIRMATION_CANCEL_TEXT,
            cancelButtonProps: { className: 'confirm-exit' },
            onCancel: () => {
                sendDeletePhotoCancelled();
            },
            onOk: () => {
                sendDeletePhotoRequest();
            },
        });
    };

    const onInit = (dropzone: Dropzone) => {
        setDropzoneObject(dropzone);
    }

    const sendUploadPhotoRequest = (event: DragEvent) => {
        // Clear the current file. Sorry, yes I know this makes the class stateful, but this is required by the way
        // the dropzone component works.
        if (dropzoneObject) {
            dropzoneObject.removeAllFiles();
        }
        const file = (event?.dataTransfer?.files.length) ? event.dataTransfer.files[0] : '';
    
        file && dispatch(uploadPhotoRequest(file));   
    }

    const sendRetrievePhotoSuccess = (file: Dropzone.DropzoneFile, dataUrl: string) => {
        // Dispatch a retrieve photo success, since the fact that there is a thumbnail means the retrieve
        // succeeded.
        dispatch(retrievePhotoSuccess({photo: file, thumbnailUrl: dataUrl}));
    }

    const sendUploadProgress = (_: Dropzone.DropzoneFile, percentage: number) => {
        dispatch(setUploadProgress(percentage));
    }

    const sendUploadPhotoFailure = (_: Dropzone.DropzoneFile, requestError: string | Error) => {
        // If this is an error from a request, use the request status.
        let error;
        if (requestError) {
            error = FILE_UPLOAD_FAILED_TEXT;
        } else {
            error = 'Errors uploading photos';
        }

        dispatch(uploadPhotoFailure(error));
    }

    const sendUploadPhotoSuccess = (_: Dropzone.DropzoneFile, response: Object) => {
        dispatch(uploadPhotoSuccess());
        // Also retrieve the updated insurance image after uploading successfully.
        dispatch(getUsInsuranceImageRequest(Number((response as UsInsuranceResponse).id)));
    }

    const eventHandlers = {
        init: onInit,
        drop: sendUploadPhotoRequest,
        thumbnail: sendRetrievePhotoSuccess,
        uploadprogress: sendUploadProgress,
        error: sendUploadPhotoFailure,
        success: sendUploadPhotoSuccess,
    };


    useEffect(() => {
        const photoUrl = currentPhotoUrl ? `${currentPhotoUrl}?r=${Math.random()}` : currentPhotoUrl;
        if (photoUrl && dropzoneObject) {

            dropzoneObject.removeAllFiles();

            const urlNamePortions = photoUrl.split('/');
            const file = { name: urlNamePortions[urlNamePortions.length - 1] } as File;
            dispatch(retrievePhotoRequest(file));
        }
    }, [dispatch, currentPhotoUrl, dropzoneObject, field, retrievePhotoRequest]);


    return (
        /* @ts-ignore:next-line */
        <DropzoneComponent
            class={dropzoneBorderClass}
            id={field}
            config={componentConfig}
            djsConfig={djsConfig}
            eventHandlers={eventHandlers}
        >
            <div className={dropzoneMessageClass}>
                <div className="dropzone-empty-image"></div>
                <div className="dropzone-empty-label">{fieldText}</div>
                <div className="dropzone-empty-text">{'Drag & Drop to upload.'}</div>
                <div className="dropzone-empty-formats" />
            </div>
            <div className={errorMessageClass}>
                <label>{errorMessage}</label>
                <p />
                <label data-testid='image-file' className="dropzone-filename">{photoName}</label>
            </div>

            <div className={detailsClass}>
                <img
                    className="dropzone-image"
                    src={photoThumbnailUrl}
                    alt={photoName}
                    onClick={sendToggleLightbox}
                    onError={
                        (e)=>{
                            // If the the thumbnail image can't be found, just use the full image.
                            if (e.currentTarget.src !== currentPhotoUrl) {
                                e.currentTarget.src=currentPhotoUrl;
                            }
                        }
                    }
                />

                <img
                    className={dropzoneDeleteClass}
                    onClick={sendDeletePhotoConfirm}
                    src={TrashButton}
                    alt="Delete"
                />
                
            </div>
            <div className={progressBarClass}>
                <div className="dropzone-progress" style={progressWidth} />
            </div>
            <Care1LightBox
                field={field}
                isOpen={Boolean(lightboxOn && currentPhotoUrl)}
                mainSrc={currentPhotoUrl}
                mainSrcThumbnail={photoThumbnailUrl}
                onCloseRequest={sendToggleLightbox}
            />
          
        </DropzoneComponent>
    );
};

export default PatientInsuranceImages;