import { Button, Col, Modal, Row, Typography } from 'antd';
import styles from '../../../../static/css/components/smart-upload-confirmation-modal.module.scss';
import { useCare1AppDispatch, useCare1AppSelector} from '../../../apps/care1-hooks';
import { toggleSmartUploadConfirmationModal } from '../../../reducers/smart-upload-slice';

const SmartUploadConfirmationModal = () => {
    const isVisible = useCare1AppSelector(
        (store) => store.smartUpload.smartUploadConfirmationModalOpen
    );

    const dispatch = useCare1AppDispatch();

    const handleModalClose = () => {
        dispatch(toggleSmartUploadConfirmationModal());
    };

    return (
        <Modal
            className={styles.confirmationModal}
            open={isVisible}
            footer={null}
            closeIcon={null}
            keyboard={true}
            onCancel={handleModalClose}
            width={684}
        >
            <Row gutter={[0, 24]} justify='center'>
                <Col span={24}>
                    <Typography.Text className={styles.confirmationModalText}>
                        Our AI is currently processing your submission. Please
                        allow a few days for us to complete the analysis and
                        update the Care1 patient file. 
                    </Typography.Text>
                </Col>

                <Col span={24}>
                    <Button
                        type='primary'
                        className={styles.button}
                        onClick={handleModalClose}
                    >
                        Close
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default SmartUploadConfirmationModal;
