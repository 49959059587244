import React, { useState } from 'react';
import { Flex, Layout, Row, Spin, Tabs, Tag } from 'antd';

import NavigationControlBar from '../components/navigation-control-bar';
import SalesPageWeeklyUploadTab from '../components/sales/sales-page-weekly-upload-tab';
import SalesPageUploadReportTab from '../components/sales/sales-page-upload-report-tab'

import '../../static/css/pages/sales-page.scss';
import '../../static/css/shared/date-picker.scss';


const { Header, Content } = Layout;

const SalesPage: React.FC = () => {

    const defaultTab = 'weeklky-upload';
    const [activeKey, setActiveKey] = useState(defaultTab);

    const tabs = [{
        label: 'Weekly Upload Totals', key: 'weeklky-upload',
        children: <SalesPageWeeklyUploadTab />
    }, {
        label: 'Monthly Upload Metrics', key: 'monthly-upload',
        children: <SalesPageUploadReportTab />
    }];

    return (
        <Layout className='salesReportPage'>
            <Header>
                <NavigationControlBar
                    className="patient-list-navigation-control-bar"
                    breadcrumbItems={[]}
                />
            </Header>
            <Content>
                <div className='sales-page'>
                    <Tabs
                        defaultActiveKey={defaultTab}
                        activeKey={activeKey}
                        items={tabs}
                        onTabClick={(key: string, e: React.KeyboardEvent | React.MouseEvent) => {
                            setActiveKey(key);
                        }}
                    />
                </div>
            </Content>
        </Layout>
    );
}

export default SalesPage;