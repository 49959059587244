import { Fragment, useEffect, useState, useMemo } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Input, Form, Checkbox } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { getTxAlgoKeywordOptions, addTxAlgoKeywordColors, renderTxAlgoKeywordString
    } from '../../helpers/patient-exam-convert';
import * as Constants from '../../constants';
import '../../../static/css/components/patient-exam-medical-history.scss';
import { isCatPostOpSelected } from '../../helpers/diagnosis-convert';
import { localizedText } from '../../localizedText';


const PatientExamMedicalHistory = ({ disabled }: { disabled?: boolean }) => {
    const { HISTORY_SYMPTOMS_TITLE } = localizedText;

    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);

    const examHistory = useCare1AppSelector(store => store.examData[Constants.EXAM_HISTORY_FIELD]);
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const diagnosisEntries = useCare1AppSelector(store => store.diagnosis.entries);
    const catPostOp = useCare1AppSelector(store => store.examData.rr_cat_post_op);
    const isCatPostOp = isCatPostOpSelected(diagnosisEntries, catPostOp);
    const catPostOpClassName = isCatPostOp ? 'cat-color' : ' ';


    const [ form ] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    // Checkbox for admin to toggle in and out of editing mode.
    const [adminIsEditing, setAdminIsEditing] = useState(false);

    // Side effects that we run after component render.
    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            [Constants.EXAM_HISTORY_FIELD]: examHistory,
        }
        form.setFieldsValue(newValidationFieldValues);
    }, [examHistory, form])

    const className = isCatPostOp ? 'component-input-card medical-history cat-post-op'
        : 'component-input-card medical-history';

    // Get the color coded keywords for the component
    const txAlgo3Keywords = useCare1AppSelector(store => store.options.txAlgoKeywords);
    const txAlgoKeywordOptions = useMemo(() => {
        return getTxAlgoKeywordOptions(txAlgo3Keywords, 'historyMedsExam');
    }, [txAlgo3Keywords]);
    const keywordOptions = userIsOMDR || userIsADMIN ? txAlgoKeywordOptions : null;

    // Add the color code tags to the content string
    const applyKeywordColors = useMemo(() => {
        if( (userIsOMDR || userIsADMIN) && keywordOptions){
            return addTxAlgoKeywordColors(keywordOptions, examHistory);
        }
        return null
    }, [keywordOptions, userIsOMDR, examHistory, userIsADMIN]);

    return (
        <Form form={form}>
            <Card className={className} bordered={false}>
                {/* MH 001, 002, 003 */}
                <div className="heading" data-testid='medical-history-title'>
                    {HISTORY_SYMPTOMS_TITLE}
                    { userIsADMIN &&
                        <Checkbox
                            onChange={()=> setAdminIsEditing(!adminIsEditing)}
                            className='admin-edit-checkbox'
                        >
                            Admin Edit
                        </Checkbox>
                    }
                    { isCatPostOp &&
                        <div className={catPostOpClassName}>
                            &nbsp;&nbsp;*input dates of cataract surgery
                        </div>
                    }
                </div>
                    { ( userIsOMDC || userIsOD || (userIsADMIN && adminIsEditing)) &&
                        <Form.Item help="" name={Constants.EXAM_HISTORY_FIELD} initialValue={examHistory}>
                            <Input.TextArea
                                disabled={disabled}
                                className="component-input"
                                data-testid='medical-history'
                                autoSize={false}
                                placeholder={userIsRE ? Constants.EXAM_HISTORY_PLACEHOLDER : ''}
                                allowClear={examHistory === Constants.EXAM_HISTORY_DEFAULT_TEXT}
                                onBlur={() => dispatch(setExamDataValue(Constants.EXAM_HISTORY_FIELD, form.getFieldValue(Constants.EXAM_HISTORY_FIELD)))}
                            />
                        </Form.Item>
                    }
                    { (userIsOMDR || (userIsADMIN && !adminIsEditing)) &&
                        <Form.Item>
                            <div className="component-input color-keyword-div" data-testid='medical-history'>
                                {
                                    applyKeywordColors && applyKeywordColors.map(
                                        (string, index) => (<Fragment key={index}>{renderTxAlgoKeywordString(string)}</Fragment>)
                                    )
                                }
                            </div>
                        </Form.Item>
                    }
            </Card>
        </Form>
    );
}

export default PatientExamMedicalHistory;