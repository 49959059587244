import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { getCsrfToken } from '../helpers/utilities';
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";


export interface IStopWatchState {
  stopwatchNeedReset: boolean;
  stopwatchIsRunning: boolean;
  stopwatchPreReviewTime: number;
  stopwatchResumeExamIdAfterSave: string;
}

const initialState: IStopWatchState = {
  stopwatchNeedReset: false,
  stopwatchIsRunning: false,
  stopwatchPreReviewTime: 0,
  stopwatchResumeExamIdAfterSave: '',
};

export const preReviewTimeTimeUpdate = createAsyncThunk(
  'stopwatch/preReviewTimeTimeUpdate',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const { user: { csrfToken }, examData: { id }, stopwatch } = getState() as { user: { csrfToken: string }, examData: { id: number } , stopwatch: IStopWatchState};

    // Logout if tokens don't match.
    if (csrfToken !== getCsrfToken()) {
      dispatch(logout());
    }

    const URL = `${process.env.REACT_APP_BACKENDURL}/data/admin/pre_review_time/update/`;

    const formData = new FormData();
    formData.append('exam_id', id.toString());
    formData.append('pre_review_time', `${stopwatch.stopwatchPreReviewTime}`);

    try {
      const response = await apiRequest.post(URL, csrfToken, formData);
      return response.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      }
    }
  }
)

export const getPreReviewTimeTimeDetailsRequery = createAsyncThunk(
  'stopwatch/getPreReviewTimeTimeDetailsRequery',
  async (examId: string, {dispatch, getState, rejectWithValue}) => {
      const { user: { csrfToken }} = getState() as { user: {csrfToken: string}};
      // Logout if tokens don't match.
      if (csrfToken !== getCsrfToken()) {
          dispatch(logout());
      }

      const URL = `${process.env.REACT_APP_BACKENDURL}/data/admin/pre_review_time/details/${examId}/`;
      try {
          const response = await apiRequest.get(URL, csrfToken);
          return response.data;
      } catch (error) {
          if (error) {
              return rejectWithValue(error);
          }
      }
  }
)

const stopwatchSlice = createSlice({
  name: 'stopwatch',
  initialState: initialState,
  reducers: {
    stopwatchStart: (state) => {
      state.stopwatchIsRunning = true;
      state.stopwatchNeedReset = false;
    },
    stopwatchPause: (state) => {
      state.stopwatchIsRunning = false;
      state.stopwatchNeedReset = false;
      state.stopwatchResumeExamIdAfterSave = '';
    },
    setStopwatchResumeExamIdAfterSave: (state, action: PayloadAction<string>) => {
      state.stopwatchResumeExamIdAfterSave = action.payload;
    },
    setStopwatchPreReviewTime: (state, action: PayloadAction<number>) => {
      state.stopwatchPreReviewTime = action.payload;
      state.stopwatchNeedReset = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPreReviewTimeTimeDetailsRequery.fulfilled, (state, action) => {
        state.stopwatchPreReviewTime = action.payload.pre_review_time;
        state.stopwatchNeedReset = true;
      });
  }
});

export const { stopwatchStart, stopwatchPause, setStopwatchPreReviewTime, setStopwatchResumeExamIdAfterSave } = stopwatchSlice.actions;

export default stopwatchSlice.reducer;

