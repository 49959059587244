import { useEffect, useMemo } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import update from 'immutability-helper';
import CreatableSelect from 'react-select/creatable';
import { Card, Row, Form, Tooltip } from 'antd';
import { generateDiagnosisListOptions, sortOptions } from '../../helpers/diagnosis-convert';
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';
import { useFormValidation } from '../../context/useFormValidationContext';
import '../../../static/css/components/_patient-exam-chief-complaint.scss';
import { CHIEF_COMPLAINT_PLACEHOLDER_TEXT, DIAGNOSIS_LIST } from '../../constants';
import { localizedText } from '../../localizedText';
import { setDiagnosisValues, IDiagnosisEntry } from '../../reducers/diagnosis-slice';
import { InfoCircleOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

const PatientExamChiefComplaint = ({ disabled, displayInfo = true }: { disabled: boolean, displayInfo?: boolean }) => {
    const { CHIEF_COMPLAINT_HEADING } = localizedText;
    const diagnosisList = useCare1AppSelector(store => store.examData[DIAGNOSIS_LIST]);
    const diagnosisEntries = useCare1AppSelector(store => store.diagnosis.entries);
    const [ form ] = Form.useForm();
    const dispatch = useCare1AppDispatch();
    const { setChiefComplaintForm } = useFormValidation();
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);

    // Required for OD users only
    const userIsOD = useCare1AppSelector(store => store.user.isOD);


    // Side effects that we run after component render.
    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            diagnosisEntries: diagnosisEntries
        };

        form.setFieldsValue(newValidationFieldValues);

        // The component should validate on every render
        form.validateFields();

        // pass the form instance to useFormValidation hooks
        setChiefComplaintForm(form);
        // clean up function when chief complaint component unmounts
        return () => {
            setChiefComplaintForm(null);
        }
    }, [diagnosisEntries, diagnosisEntries.length, form, setChiefComplaintForm])

    // Add the diagnosis list selectable options into the dropdown.
    // useMemo hook is used here because diagnosisList usually does not change once all exam data is
    // loaded, hence the diagnosisListOptions does not change. Before changes, all the diagnosisOptions
    // will be calculated again after a complaint is added/removed. Now the diagnosisOptions will be
    // calculated only once
    const diagnosisListOptions = useMemo(() => {
        const shouldAddBooleanOptions = true;
        // only sort and generate the list for OD users, OMD users do not use diagnosis list options
        return (userIsOD || userIsADMIN) ? sortOptions(generateDiagnosisListOptions(diagnosisList, shouldAddBooleanOptions)) : [];
    }, [diagnosisList, userIsOD, userIsADMIN]);

    // To remove the defaultValue props of the component
    const creatableSelectBase = <CreatableSelect
        isDisabled={disabled}
        className="chief-complaint-select"
        classNamePrefix="chief-complaint"
        isMulti
        options={diagnosisListOptions}
        isClearable={false}
        placeholder={CHIEF_COMPLAINT_PLACEHOLDER_TEXT}
        onChange={(event, actionType) => {
            const selectedDiagnosisValues = event as IDiagnosisEntry[];
            dispatch(setDiagnosisValues(selectedDiagnosisValues, actionType.action, diagnosisList));
        }}
    />;
    const creatableSelectBaseProps = creatableSelectBase.props;
    let creatableSelectProps = {
        ...creatableSelectBaseProps,
    };

    delete creatableSelectProps.defaultValue;
    const creatableSelect = update(creatableSelectBase, {props: {$set: {...creatableSelectProps}}});

    return (
        <Card className={'patient-exam-chief-complaint'} bordered={false}>
            <Form form={form} >
                <Row className='title-row'>
                    <div className='chief-complaint-title'>{CHIEF_COMPLAINT_HEADING}</div>
                    { displayInfo &&
                    <Tooltip 
                        title={'This info was generated from the referral letter using Care1\'s LLM AI and may contain inaccuracies. \
                            If you are suspicious of discrepancies, please (a) report the error, and (b) check the actual letter \
                            from the OD to see the original source of information.'}>
                        <InfoCircleOutlined />
                    </Tooltip>
                    }
                </Row>
                {/* COMPLAINT 001, COMPLAINT 002, COMPLAINT 003, COMPLAINT 004 */}
                <Row>
                    { (userIsOD || userIsADMIN) &&
                        <FormItem
                            name={'diagnosisEntries'}
                            rules={[{
                                required: userIsOD && true,
                                message: buildRequiredErrorMessage('A diagnosis'),
                            }]}
                        >
                            {creatableSelect}
                        </FormItem>
                    }
                    { (userIsOMDR || userIsOMDC) &&
                        <FormItem
                            className='omd-user-display'
                        >
                            { diagnosisEntries && diagnosisEntries.map((entry: IDiagnosisEntry) => {
                                if (entry.label === 'Glc') {
                                    return 'Glaucoma';
                                } else if (entry.label === 'Glc Susp') {
                                    return 'Glaucoma Suspect';
                                } else {
                                    return entry.label;
                                }
                            }).join('; ')}

                        </FormItem>
                    }
                </Row>
            </Form>
        </Card>
    );
}

export default PatientExamChiefComplaint;
