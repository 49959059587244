import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { localizedText } from '../../localizedText';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/patient-exam-omdr-notes.scss';
import ComponentTextareaCard from '../component-textarea-card';

const PatientExamOMDRNotes = ({ disabled }: { disabled: boolean }) => {

    const { OMD_REVIEW_NOTES_PLACEHOLDER, OMDR_REVIEWER_NOTES_HEADING } = localizedText;
    const omdOd = useCare1AppSelector(store => store.examData['omd_od']);
    const dispatch = useCare1AppDispatch();

    return (
        <ComponentTextareaCard
            disabled={disabled}
            title={OMDR_REVIEWER_NOTES_HEADING}
            testId='private-notes-to-od'
            value={omdOd}
            placeholder={OMD_REVIEW_NOTES_PLACEHOLDER}
            lightBlueBackground={true}
            handleChange={(e) => dispatch(
                setExamDataValue('omd_od', e.target.value)
            )}
        />
    );
}

export default PatientExamOMDRNotes;