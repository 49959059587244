import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Modal } from "antd";
import { getCsrfToken } from "../helpers/utilities";
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";



export interface ICataractReferralsDrCountData {
    [name: string]: number[],
}

export interface ICataractReferralsResponseData {
    isLoading: boolean,
    cataractReferrals: ICataractReferralsDrCountData,
    studyFundingCatRef: string,
}

const initialState : ICataractReferralsResponseData = {
    isLoading: false,
    cataractReferrals: {},
    studyFundingCatRef: '',
}

// GET: getCataractReferralsRequest
export const getCataractReferralsRequest = createAsyncThunk(
    'cataractReferrals/getCataractReferrals',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/cataract_referrals/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const cataractReferralsSlice = createSlice({
    name: 'cataractReferrals',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCataractReferralsRequest.pending, (state) => {
            state.isLoading = true;
            state.cataractReferrals = {};
            state.studyFundingCatRef = '';
        });
        builder.addCase(getCataractReferralsRequest.fulfilled, (state, action: PayloadAction<ICataractReferralsResponseData>) => {
            const cataractReferrals = action.payload.cataractReferrals;
            const studyFundingCatRef = action.payload.studyFundingCatRef;

            return {
                ...state,
                isLoading: false,
                cataractReferrals: cataractReferrals,
                studyFundingCatRef: studyFundingCatRef,
            }
        });
        builder.addCase(getCataractReferralsRequest.rejected, (state, action) => {
            state.isLoading = false
            Modal.error({
                className: 'info-modal',
                title: `Errors getting data. ${action.payload}`,
            })
        });
    }
});

export default cataractReferralsSlice.reducer;