import { Tooltip, Flex, Col, Input } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
// import '../../../static/css/components/patient-exam-notes-to-od-and-gp.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { RETINA_REFERRAL_PLACEHOLDER_TEXT } from '../../constants';
import { localizedText } from '../../localizedText';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import ComponentTextareaCard from '../component-textarea-card';
import commonLayoutStyles from '../../../static/css/components/common-layout.module.scss';
import styles from '../../../static/css/components/patient-exam-notes-to-od-and-gp.module.scss'


const PatientExamNotesToODAndGP = ({ disabled }: {disabled: boolean}) => {
    const { OMDR_NOTES_TO_OD_AND_GP_HEADING, EXAM_NOTES_CLICK, PATIENT_EXAM_NOTES_PROVIDED } = localizedText;
    const userIsRE = useCare1AppSelector(store => store.user.isRetinaEnabled);
    const notesToODandGP = useCare1AppSelector(store => store.examData.omd_gp);
    const isReferralLetterUploadPEI = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const examOmdIsRe = useCare1AppSelector(store => store.examData.exam_omd_is_re);
    const rluExamIsPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);
    const userIsOmdr = useCare1AppSelector(store => store.user.isOMDR);
    const userIsAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const examStatus = useCare1AppSelector(store => store.examData.exam_status);
    const omdReferralText = useCare1AppSelector(store => store.examData.omd_referral_text);

    // In the admin and OMDR RE PEI if the exam is RLU/Legacy Standard
    // In the OMDR RE PEI if the exam is RLU Premium AND it’s been assigned to an OMDR RE for review
    // In the admin PEI if the exam has been reviewed by an OMDR RE 
    const displayReferralText = (
        (((userIsRE && userIsOmdr) || (userIsAdmin)) && rluExamIsPremium === false && isReferralLetterUploadPEI === true) ||
        (userIsRE && userIsOmdr && rluExamIsPremium === true && isReferralLetterUploadPEI === true && examOmdIsRe) ||
        (userIsAdmin && examOmdIsRe && examStatus === 'reviewed')
    );

    const dispatch = useCare1AppDispatch();

    const heading = OMDR_NOTES_TO_OD_AND_GP_HEADING;

    const getToolTipText = () => {
        if (userIsRE) {
            return EXAM_NOTES_CLICK;
        }
        else {
            return PATIENT_EXAM_NOTES_PROVIDED;
        }
    }

    const HeadingComponent = () => (
        <>
            <span className="heading">
                {heading}
            </span>
            <Tooltip placement='topLeft' title={getToolTipText()}>
                <InfoCircleOutlined />
            </Tooltip>
        </>
    )

    return (
        <Flex vertical className={commonLayoutStyles.verticalFlex} gap='small' >
            <Col flex='1 1 100%'>
                <ComponentTextareaCard
                    disabled={disabled}
                    title={<HeadingComponent />}
                    testId='notes-to-od-and-gp'
                    value={notesToODandGP}
                    lightBlueBackground={true}
                    handleChange={(e) => dispatch(
                        setExamDataValue('omd_gp', e.target.value)
                    )}
                />
            </Col>
            { displayReferralText &&
                <Col flex='0 1 100px'>
                    <Input.TextArea
                        className={styles.referralText}
                        value={omdReferralText}
                        onChange={(e) => dispatch(
                            setExamDataValue('omd_referral_text', e.target.value)
                        )}
                        placeholder={RETINA_REFERRAL_PLACEHOLDER_TEXT}
                    />
                </Col>
            }
        </Flex>
    );
}

export default PatientExamNotesToODAndGP;