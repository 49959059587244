import { Modal, Button, Form,  Input } from 'antd';
import '../../../static/css/components/od-group-practice-modal.scss';

// Action imports
import { SaveOutlined } from '@ant-design/icons';
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { useAddRegionMutation } from '../../services/doctor-api';
import { setShowNewRegionModal } from '../../reducers/doctor-slice';
import { getOptionsRequest } from '../../reducers/options-slice';

const NewRegionModal = () => {

    const isVisible = useCare1AppSelector(store => store.doctorSlice.showNewRegionModal);

    const [addRegion] = useAddRegionMutation();

    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const handleModalClose = () => {
        dispatch(setShowNewRegionModal(false));
    };

    const closeButton = (
        <Button
            key='close-button'
            onClick={handleModalClose}
            className='close-button'
        >
            Close
        </Button>
    );
    const saveButton = (
        <Button
            key='save-button'
            onClick={() => form.submit()}
            className='save-button'
            icon={<SaveOutlined />}
        >
            Save
        </Button>
    );

    const onFinish = async (values: {name: string}) => {
        try {
            const result = await addRegion(values).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'Region successfully added.',
                    onOk: () => {
                        dispatch(setShowNewRegionModal(false));
                        dispatch(getOptionsRequest());
                    }
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    return (
        <Modal
            className='od-group-practice-modal new-region-modal'
            open={isVisible}
            closable={false}
            title='Add Region'
            footer={[closeButton, saveButton]}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <Form
                form={form}
                labelCol={{span:5}}
                wrapperCol={{span: 18}}
                className='od-group-practice-form'
                colon={false}
                requiredMark={false}
                onFinish={onFinish}
            >
                <Form.Item
                    label={`Region's Name`}
                    name='name'
                    rules={[
                        {required: true,
                            message: buildRequiredErrorMessage('Clinic Name') }
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default NewRegionModal;
