import { Fragment } from 'react';
import { Col, Divider, Row, Tag } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useCare1AppSelector } from '../../../apps/care1-hooks';

const PatientExamPremiumHeaderFlagsIndicator = () => {
    const isExamHighPriority = useCare1AppSelector(store => store.examData.is_urgent);
    const odQuestion = useCare1AppSelector(store => store.examData.od_question);

    return (
        <Fragment>
            <Row className={'premium-header-flags-row'}>
                <Col span={2}>
                    <Divider className={'flags-divider'} type="vertical" />
                </Col>
                <Col span={22} className={'premium-header-flags-indicators'}>
                {
                    isExamHighPriority && 
                    <Tag
                        className='high-priority-flag'
                    >
                        <InfoCircleOutlined />
                        <span className='high-priority-text'>{' High Priority'}</span>
                    </Tag>
                }
                {
                    odQuestion &&
                    <Tag
                        className='od-question-flag'
                    >
                        <InfoCircleOutlined />
                        <span className='od-question-text'>{' OD Has A Question'}</span>
                    </Tag>
                }
                {
                    !isExamHighPriority && !odQuestion &&
                    <Tag
                        className='no-notifications-flag'
                    >
                        <InfoCircleOutlined />
                        <span className='no-notifications-text'>{' No New Notification'}</span>
                    </Tag>
                }
                </Col>
            </Row>
        </Fragment>
    )
}

export default PatientExamPremiumHeaderFlagsIndicator;