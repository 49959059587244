import { Fragment} from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';
import { Row, Card, Col } from 'antd';
import * as Constants from '../constants';

// Components
import AdminGPReferralDates from '../components/admin/admin-gp-referral-dates';
import AdminOctRnflAverage from '../components/admin/admin-oct-rnfl-average';
import AdminOntarioBillingCode from '../components/admin/admin-ontario-billing-code';
import AdminPreReview from '../components/admin/admin-pre-review';
import AdminReferralLetterSelectors from '../components/admin/admin-referral-letter-selectors';
import AdminVfGht from '../components/admin/admin-vf-ght';
import DeepMDBand from '../components/reviewer/deep-md/patient-exam-deep-md';
import OMDSubmitButtons from '../components/reviewer/omd-submit-buttons';
import PatientExamAllergies from '../components/integrated/patient-exam-allergies';
import PatientExamBCVAGraph from '../components/integrated/patient-exam-bcva-graph';
import PatientExamBillingRules from '../components/integrated/patient-exam-billing-rules';
import PatientExamChiefComplaintIC from '../components/integrated/patient-exam-chief-complaint-ic';
import PatientExamComorbiditiesAdmin from '../components/integrated/patient-exam-comorbidities-admin'
import PatientExamConversation from '../components/retina/patient-exam-conversation';
import PatientExamExtraImages from '../components/retina/patient-exam-extra-images';
import PatientExamFundus from '../components/retina/patient-exam-fundus';
import PatientExamFundusImageHistory from '../components/image-histories/patient-exam-fundus-image-history';
import PatientExamGPReferred from '../components/retina/patient-exam-gp-referred';
import PatientExamHeaderAdmin from '../components/admin/patient-exam-header-admin';
import PatientExamIOPHistoryReferralLetter from '../components/integrated/patient-exam-iop-history-referral-letter';
import PatientExamOCTMacula from '../components/retina/patient-exam-oct-macula';
import PatientExamOCTMaculaImageHistory from '../components/image-histories/patient-exam-oct-macula-image-history';
import PatientExamOCTRnfl from '../components/integrated/patient-exam-oct-rnfl';
import PatientExamOCTRNFLImageHistory from '../components/image-histories/patient-exam-oct-rnfl-image-history';
import PatientExamOMDHistory from '../components/integrated/patient-exam-omd-history';
import PatientExamOMDRCDValuesBand from '../components/reviewer/patient-exam-omdr-cd-values-band';
import PatientExamOMDRIOPFinalRecommendations from '../components/reviewer/patient-exam-omdr-iop-final-recommendations';
import PatientExamStereoViewer from '../components/integrated/patient-exam-stereo-viewer';
import PatientExamUntilYesterday from '../components/integrated/patient-exam-until-yesterday';
import PatientExamVF from '../components/integrated/patient-exam-vf';
import PatientExamVFImageHistory from '../components/image-histories/patient-exam-vf-image-history';
import PatientExamGlaucomaScore from '../components/integrated/patient-exam-glaucoma-score';
import PatientExamStandardPremiumSwitch from '../components/integrated/patient-exam-standard-premium-switch';
import GptAISnapshotBand from '../components/admin/gpt/gpt-ai-snapshot-band';

// Helpers
import '../../static/css/pages/admin-patient-exam-page.scss';
// TODO - This RE BCVA style sheet css should be moved to the patient-exam-bcva.scss as applicable, and this file deleted.
import '../../static/css/components/patient-exam-re-bcva.scss';
import '../../static/css/components/admin-gpt-ai-snapshot-band.scss';
import { localizedText } from '../localizedText';

// Actions

import { patientIsGlaucoma } from '../helpers/diagnosis-convert';
import AdminReferralLetterViewer from '../components/admin/admin-referral-letter-viewer';
import ReferralLetterExamMetrics from '../components/integrated/metrics/referral-letter-exam-metrics';
import PatientExamOngoing from '../components/integrated/patient-exam-ongoing';
import IopBcvaGraphBand from '../components/integrated/iop-bcva-graph-band';

import { retrieveGptAiSnapshotBandValues } from '../reducers/gpt-ai-snapshot-slice';
import AdminPeiNotesRow from '../components/admin/admin-pei-notes-row';
import AdminPeiOmdrReRow from '../components/admin/admin-pei-omdr-re-row';
import AdminOmdReportsOptions from '../components/admin/admin-omd-reports-options';
import AdminReconciliationNotes from '../components/admin/admin-reconciliation-notes';

const ADMINReferralLetterExamPage = () => {
    const { BCVA_GRAPH_TEXT,  PHOTO_DROP_TEXT_SHORT } = localizedText;

    const dispatch = useCare1AppDispatch();

    const examIsRE = useCare1AppSelector(store => store.examData.exam_is_retina_only);
    const glc = useCare1AppSelector(store => store.examData.rr_glc);
    const glcS = useCare1AppSelector(store => store.examData.rr_glc_suspect);
    const narrowAngles = useCare1AppSelector(store => store.examData.rr_narrow_angles);
    const diagnosisEntries = useCare1AppSelector(store => store.diagnosis.entries);
    const exam_od_has_pei_toggle = useCare1AppSelector(store => store.examData.exam_od_has_pei_toggle);

    const isGlaucoma = patientIsGlaucoma(diagnosisEntries, { glc, glcS, narrowAngles });

    const visionImpressions = useCare1AppSelector(store => store.gptAiSnapshot.gpt_ai_snapshot_band_impressions);
    const visionOdAssessment = useCare1AppSelector(store => store.gptAiSnapshot.gpt_ai_snapshot_band_od_assessment);
    const visionOdQuestion = useCare1AppSelector(store => store.gptAiSnapshot.gpt_ai_snapshot_band_od_question);
    const visionExamODNotes = useCare1AppSelector(store => store.examData.od_omd);
    const examId = useCare1AppSelector(store => store.examData.id);
    const examStatus = useCare1AppSelector(store => store.examData.exam_status);
    const examOmdIsRE = useCare1AppSelector(store => store.examData.exam_omd_is_re);

    const gptSavedValues = useCare1AppSelector(store => store.gptSetValues.gpt_saved_values);
    const gptNearMissFields = useCare1AppSelector(store => store.gptSetValues.gpt_near_miss_fields);

    const getGptSavedValue = (k: string) => {       
        if (gptSavedValues && gptSavedValues !== '') {
            try {
                const gptSavedValuesJson = JSON.parse(gptSavedValues);
                return gptSavedValuesJson[k];
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }

    const getGptNearMissFields = (k: string) => {
        if (gptNearMissFields && gptNearMissFields !== '') {
            try {
                const gptNearMissFieldsJson = JSON.parse(gptNearMissFields);
                return gptNearMissFieldsJson[k];
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }

    const getVisionAiSnapshotBandValues = () => {
        if (examId != null) {
            dispatch(retrieveGptAiSnapshotBandValues(examId));
        }
    }


    const visionAISnapshotBandProps = {
        snapshotType: 'GPT Vision',
        impressions: visionImpressions,
        odAssessment: visionOdAssessment,
        odQuestion: visionOdQuestion,
        examODNotes: visionExamODNotes,
        impressionsField: 'gpt_ai_snapshot_band_impressions',
        odAssessmentField: 'gpt_ai_snapshot_band_od_assessment',
        odQuestionField: 'gpt_ai_snapshot_band_od_question',
        examODNotesField: 'od_omd',
        getAiSnapshotBandValues: getVisionAiSnapshotBandValues
    };

    return (
        <>
            <Row>
                <PatientExamHeaderAdmin />
            </Row>
            { exam_od_has_pei_toggle &&
                <Row>
                    <PatientExamStandardPremiumSwitch />
                </Row>
            }
            <Row gutter={8} className='referral-letter-row'>
                {/* START | Section 1 - Referral Letter Dropzone */}
                <Col span={12} className="dropzones-container">
                    <Card
                        className='referral-letter-dropzone-container'
                        bordered={false}
                    >   
                        <AdminReferralLetterViewer />
                    </Card>
                </Col>
                {/* END | Section 1 - Referral Letter Dropzone */}
                <Col span={12}>
                    <Row gutter={8}>
                        <Col span={14}>
                            <Row>
                                <PatientExamGPReferred
                                    className="patient-exam-gp-referred"
                                    disabled={false}
                                />
                            </Row>
                            <Row>
                                <PatientExamChiefComplaintIC disabled={false} />
                            </Row>
                        </Col>
                        <Col span={10}>
                            <PatientExamOMDHistory disabled={false} />
                        </Col>
                    </Row>

                    <Row>
                        <ReferralLetterExamMetrics disabled={false} />
                    </Row>

                    <Row gutter={8}>
                        <Col span={4}>
                            <PatientExamAllergies disabled={false} 
                                gptSaved={getGptSavedValue('allergies')}
                                gptNearMiss={getGptNearMissFields('allergies')}
                            />
                        </Col>
                        <Col span={7} className="pei-comorbidities-col">
                            <PatientExamComorbiditiesAdmin disabled={false} />
                        </Col>
                        <Col span={13} className="pei-comorbidities-col">
                            <PatientExamBillingRules disabled={false} />
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12} className="pei-until-yesterday-col">
                            <PatientExamUntilYesterday disabled={false} />
                        </Col>
                        
                        <Col span={12}>
                            <PatientExamOngoing disabled={false} />
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12} className="pei-iop-history-col">
                            <PatientExamIOPHistoryReferralLetter disabled={false} />
                        </Col>
                        <Col span={12} className="pei-iop-history-col">
                            <AdminOmdReportsOptions />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <GptAISnapshotBand {...visionAISnapshotBandProps}/>
                </Col>
            </Row> 
            { !(examOmdIsRE && examStatus === 'reviewed') ? 
                <AdminPeiNotesRow disabled={false} />
                :
                <AdminPeiOmdrReRow />
            }

            <Row className='admin-reconciliation-notes-row'>
                <Col span={12}>
                    <AdminReconciliationNotes />
                </Col>
                <Col span={6}>
                    <AdminOntarioBillingCode
                        doctorType='omdr'
                        doctorTypeLabel='OMDR Ontario Billing Code'
                    />
                </Col>
                <Col span={6}>
                    <AdminOntarioBillingCode
                        doctorType='omdc'
                        doctorTypeLabel='OMDC Ontario Billing Code'
                    />
                </Col>
            </Row>

            {/* START | ADMIN band */}
            <Row gutter={8}>
                <Col span={10}>
                    <AdminReferralLetterSelectors />
                </Col>
                <Col span={7}>
                    <AdminGPReferralDates />
                </Col>
                <Col span={4}>
                    <AdminPreReview />
                </Col>
                <Col span={3}>
                    <OMDSubmitButtons disabled={false} />
                </Col>
            </Row>
            {/* END | ADMIN band */}


            <Row gutter={8} className='photos-row'>
                {/* START | Section 1 - Right images panel */}
                <Col span={6} className="dropzones-container">
                    <PatientExamFundus
                        disabled={false}
                        tabbed={!examIsRE}
                        className="patient-exam-fundus"
                        side={Constants.RIGHT_SIDE_TEXT}
                    />
                </Col>
                {/* END | Section 1 - Right images panel */}
                <Col span={12}>
                    <Row gutter={8}>
                        <Col span={6}>
                            <PatientExamOCTMacula
                                dropText={PHOTO_DROP_TEXT_SHORT}
                                side={Constants.RIGHT_SIDE_TEXT}
                                tabbed={true}
                                disabled={false}
                            />
                            <PatientExamOCTRnfl side={Constants.RIGHT_SIDE_TEXT} disabled={false} />
                        </Col>
                        <Col span={6}>
                            <PatientExamVF side={Constants.RIGHT_SIDE_TEXT} disabled={false} />
                        </Col>
                        <Col span={6}>
                            <PatientExamVF side={Constants.LEFT_SIDE_TEXT} disabled={false} />
                        </Col>
                        <Col span={6}>
                            <PatientExamOCTMacula
                                dropText={PHOTO_DROP_TEXT_SHORT}
                                side={Constants.LEFT_SIDE_TEXT}
                                tabbed={true}
                                disabled={false}

                            />
                            <PatientExamOCTRnfl side={Constants.LEFT_SIDE_TEXT} disabled={false} />
                        </Col>
                    </Row>
                    <Row gutter={8} className='oct-rnfl-selection-row'>
                        <Col span={6}>
                            <AdminOctRnflAverage side={'right'} />
                        </Col>
                        <Col span={6}>
                            <AdminVfGht side={'right'} />
                        </Col>
                        <Col span={6}>
                            <AdminVfGht side={'left'} />
                        </Col>
                        <Col span={6}>
                            <AdminOctRnflAverage side={'left'} />
                        </Col>
                    </Row>
                </Col>
                
               
                {/* START | Section 3 - Left images panel */}
                <Col span={6} className="dropzones-container">
                    <PatientExamFundus
                        disabled={false}
                        tabbed={!examIsRE}
                        className="patient-exam-fundus"
                        side={Constants.LEFT_SIDE_TEXT}
                    />
                </Col>
                
                {/* END | Section 3 - Left images panel */}
            </Row>

            {/* START | OMDR IC DeepMD Band */}
            <Row gutter={8} className='mt-15'>
                <DeepMDBand />
            </Row>
            {/* END | OMDR IC DeepMD  Band */}

            {/* START |PatientExamGlaucomaScore Band */}
            <Row gutter={8}>
                <PatientExamGlaucomaScore />
            </Row>
            {/* END | PatientExamGlaucomaScore  Band */}

            {/* END | Section 5 IC - OMDR IC Reviewer & DeepMD bands */}

            {/* START | CD Values */}
            {!examIsRE && isGlaucoma &&
                <Row>
                    <PatientExamOMDRCDValuesBand />
                </Row>
            }
            {/* END | CD Values */}

            {/* START | Section 6 - Stereo Viewer */}
            {!examIsRE &&
                <PatientExamStereoViewer inGlaucomaWorksheet={false} />
            }
            {/* END | Section 6 RE - Stereo Viewer */}

            {/* START | Final Recommendations Bands */}
            {!examIsRE && isGlaucoma &&
                <Row>
                    <PatientExamOMDRIOPFinalRecommendations />
                </Row>
            }
            {/* END | Final Recommendations Bands */}

            {/* START | Section 7 - Extra images band */}
            <PatientExamExtraImages className="patient-exam-extra-images" disabled={false} />
            {/* END | Section 7 - Extra Images band */}

            {/* START | Section 8a RE exam - Graph Bands */}
            {examIsRE &&
                <Row gutter={8}>
                    <Col span={12}>
                        <Card className="patient-exam-bcva-graph" bordered={false}>
                            <div className="heading">{`${BCVA_GRAPH_TEXT} OD`}</div>
                            <Row>
                                <PatientExamBCVAGraph side={'od'} />
                            </Row>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card className="patient-exam-bcva-graph" bordered={false}>
                            <div className="heading">{`${BCVA_GRAPH_TEXT} OS`}</div>
                            <Row>
                                <PatientExamBCVAGraph side={'os'} />
                            </Row>
                        </Card>
                    </Col>
                </Row>
            }
            {/* END | Section 8a RE exam - Graph Bands */}

            {/* START | Section 8b IC exam - Graph Bands */}
            {!examIsRE &&
                <IopBcvaGraphBand />
            }
            {/* END | Section 8 IC exam - Graph Bands */}

            {/* START | Section 9 - Conversation band */}
            <Row gutter={8}>
                <Col span={24}>
                    <PatientExamConversation />
                </Col>
            </Row>
            {/* END | Section 9 - Conversation band */}

            {!examIsRE &&
                <Fragment>
                    {/* START | Section 10 - VF History band */}
                    <Row gutter={8}>
                        <PatientExamVFImageHistory />
                    </Row>
                    {/* END | Section 10 - VF band */}

                    {/* START | Section 11 - RNFL History band */}
                    <Row gutter={8}>
                        <PatientExamOCTRNFLImageHistory />
                    </Row>
                    {/* END | Section 11 - RNFL History band */}

                    {/* START | Section 12 - Macula History band*/}
                    <Row gutter={8}>
                        <PatientExamOCTMaculaImageHistory orderBy={'asc'} showHint={true} showBaselineBorder={true} showEmptyRow={true} />
                    </Row>
                    {/* END | Section 12 - Macula History band */}
                    {/* START |  Section 13 - Fundus History band */}
                    <Row gutter={8}>
                        <PatientExamFundusImageHistory />
                    </Row>
                    {/* END | Section 13 - Fundus History band */}
                </Fragment>
            }
            {examIsRE &&
                <Fragment>
                    {/* START | Section 12 - Macula History band*/}
                    <Row gutter={8}>
                        <PatientExamOCTMaculaImageHistory orderBy={'asc'} showHint={true} showBaselineBorder={true} showEmptyRow={true} />
                    </Row>
                    {/* END | Section 12 - Macula History band */}
                    {/* START |  Section 13 - Fundus History band */}
                    <Row gutter={8}>
                        <PatientExamFundusImageHistory />
                    </Row>
                    {/* END | Section 13 - Fundus History band */}
                </Fragment>
            }
        </>
    );
}

export default ADMINReferralLetterExamPage;