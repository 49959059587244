import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { logout } from './user-slice';
import { getCsrfToken } from '../helpers/utilities';
import { apiRequest } from '../services/api-request';

export interface IPatientDetailsSlice {
    operating: boolean;
    error: string;
    info: string;
    visible: boolean;
    details: IPatientDetails ;
    examDateSelectPatientId: number | null;
    examDateModalVisible: boolean;
    emailEditLoading: boolean;
}

export interface IPatientDetails {
    id: number | null;
    first_name: string;
    last_name: string;
    gp: string;
    last_omd_reviewed_or_ready_visit_date: string;
    postal_code: string ;
    city: string ;
    primary_phone: string;
    gp_id: string;
    secondary_phn_format: string;
    secondary_phn: null | string;
    secondary_phone: string ;
    email: string ;
    province: string;
    phn: string;
    has_no_phn: boolean;
    gp_number: string ;
    phn_format: string;
    address: string ;
    gp2_number: string ;
    gp_fax_number: string ;
    phn_name: string;
    dob: string;
    gender: string;
    secondary_phn_name: string;
    caf_personnel: boolean;
    exams: string;
    service_number: string ;
    require_secondary_phn: boolean;
    gp2: string;
    patient_deactivated: boolean;
    dob_date: string;
    age: number;
    referral_letter_exam_date_allowed_to_set: string;
    has_no_gp: boolean;
    insurance_type: string;
}

export const initialPatientDetails: IPatientDetails = {
    id: null,
    first_name: '',
    last_name: '',
    gp: '',
    last_omd_reviewed_or_ready_visit_date: '',
    postal_code: '',
    city: '',
    primary_phone: '',
    gp_id: '',
    secondary_phn_format: '',
    secondary_phn: '',
    secondary_phone: '',
    email: '',
    province: '',
    phn: '',
    has_no_phn: false,
    gp_number: '',
    phn_format: '',
    address: '',
    gp2_number: '',
    gp_fax_number: '',
    phn_name: '',
    dob: '',
    gender: '',
    secondary_phn_name: '',
    caf_personnel: false,
    exams: '',
    service_number: '',
    require_secondary_phn: false,
    gp2: '',
    patient_deactivated: false,
    dob_date: '',
    age: 0,
    referral_letter_exam_date_allowed_to_set: '',
    has_no_gp: false,
    insurance_type: '',
}

const initialState: IPatientDetailsSlice = {
    operating: false,
    error: '',
    info: '',
    visible: false,
    details: initialPatientDetails,
    examDateSelectPatientId: null,
    examDateModalVisible: false,
    emailEditLoading: false,
}

export const getPatientDetailsRequest = createAsyncThunk(
    'patientDetailsSlice/getPatientDetails',
    async (patientId: number, {dispatch, getState, rejectWithValue}) => {
        // use 'as' here to type the csrf token
        // rejectWithValue takes the error value so that the errors can be handled
        // in deleteDoctorRequest.rejected, the value is passed as action.payload
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patient/${patientId}/`;
        
        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const editPatientEmailRequest = createAsyncThunk(
    'patientDetailsSlice/editPatientEmailRequest',
    async ({patientId, email}:{patientId: number, email: string}, {dispatch, getState, rejectWithValue}) => {
        // use 'as' here to type the csrf token
        // rejectWithValue takes the error value so that the errors can be handled
        // in deleteDoctorRequest.rejected, the value is passed as action.payload
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patient/email_edit/${patientId}/`;

        const data = JSON.stringify({email});
        
        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)
export const patientDetailsSlice = createSlice({
    name: 'patientDetailsSlice',
    initialState,
    reducers: {
        clearPatientDetailsData: () => initialState,
        openPatientDetailsModal: (state) => {
            state.visible = true;
        },
        closePatientDetailsModal: (state) => {
            state.visible = false;
        },
        openExamDateSelectModal: (state, action: PayloadAction<number>) => {
            state.examDateModalVisible = true;
            state.examDateSelectPatientId = action.payload;
        },
        closeExamDateSelectModal: (state) => {
            state.examDateModalVisible = false;
            state.examDateSelectPatientId = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPatientDetailsRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(getPatientDetailsRequest.fulfilled, (state, action: PayloadAction<IPatientDetails>) => {
            state.operating = false;
            state.details = action.payload;
        });
        builder.addCase(getPatientDetailsRequest.rejected, (state, action) => {
            state.operating = false;
            // handle the rejected case, the value was passed from rejecteWithValue
            Modal.error({
                className: 'info-modal',
                title: `Errors deleting doctor. ${action.payload}`,
            })
        });

        builder.addCase(editPatientEmailRequest.pending, (state) => {
            state.emailEditLoading = true;
        });
        builder.addCase(editPatientEmailRequest.fulfilled, (state) => {
            state.emailEditLoading = false;
        });
        builder.addCase(editPatientEmailRequest.rejected, (state) => {
            state.emailEditLoading = false;
        });
    },
});

export const { clearPatientDetailsData, closeExamDateSelectModal, closePatientDetailsModal, openExamDateSelectModal, openPatientDetailsModal  } = patientDetailsSlice.actions;

export default patientDetailsSlice.reducer;
