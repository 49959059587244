import { Modal, Button, Form } from 'antd';
import '../../../static/css/components/od-group-practice-modal.scss';

// Action imports
import { SaveOutlined } from '@ant-design/icons';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { IOdGroupPracticeDetails, useAddOdGroupPracticeMutation } from '../../services/od-group-practice-api';
import { toggleOdGroupPracticeAddModal } from '../../reducers/doctor-slice';
import { getOptionsRequest } from '../../reducers/options-slice';
import ODGroupPracticeForm from './od-group-practice-form';

const ODGroupPracticeAddModal = () => {

    const isVisible = useCare1AppSelector(store => store.doctorSlice.showGroupPracticeAddModal);

    const [addOdGroupPractice] = useAddOdGroupPracticeMutation();

    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const handleModalClose = () => {
        dispatch(toggleOdGroupPracticeAddModal());
    };

    const closeButton = (
        <Button
            key='close-button'
            onClick={handleModalClose}
            className='close-button'
        >
            Close
        </Button>
    );
    const saveButton = (
        <Button
            key='save-button'
            onClick={() => form.submit()}
            className='save-button'
            icon={<SaveOutlined />}
        >
            Save
        </Button>
    );

    const onFinish = async (values: IOdGroupPracticeDetails) => {
        try {
            const result = await addOdGroupPractice(values).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'OD Group Practice successfully added.',
                    onOk: () => {
                        dispatch(toggleOdGroupPracticeAddModal());
                        dispatch(getOptionsRequest());
                    }
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    return (
        <Modal
            className='od-group-practice-modal'
            open={isVisible}
            closable={false}
            title='Add OD Group Practice'
            footer={[closeButton, saveButton]}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <ODGroupPracticeForm onFinish={onFinish} form={form} />
        </Modal>
    );
}

export default ODGroupPracticeAddModal;
