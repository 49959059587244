import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Tag, Modal, Button } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { useParams, useNavigate } from 'react-router';

import { useStopwatch } from 'react-timer-hook';
import '../../../static/css/components/admin-prereview-stopwatch.scss';
import {
    stopwatchStart, stopwatchPause, setStopwatchResumeExamIdAfterSave,
    setStopwatchPreReviewTime, preReviewTimeTimeUpdate, getPreReviewTimeTimeDetailsRequery
} from '../../reducers/stopwatch-slice';
import * as Constants from '../../constants';

const AdminPrereviewStopwatch = () => {

    const dispatch = useCare1AppDispatch();
    const params = useParams();

    const examId = Number(params[Constants.LATEST_EXAM_ID_FIELD]) || null;

    const stopwatchIsRunning = useCare1AppSelector(store => store.stopwatch.stopwatchIsRunning);
    const stopwatchNeedReset = useCare1AppSelector(store => store.stopwatch.stopwatchNeedReset);
    const stopwatchPreReviewTime = useCare1AppSelector(store => store.stopwatch.stopwatchPreReviewTime);
    const stopwatchResumeExamIdAfterSave = useCare1AppSelector(store => store.stopwatch.stopwatchResumeExamIdAfterSave);

    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({ autoStart: false });

    useEffect(() => {
        const handleBeforeUnload = (event: { preventDefault: () => void; returnValue: string; }) => {
            if (isRunning) {
                Modal.info({
                    className: 'admin-prereview-stopwatch-confirmation-modal',
                    title: 'The Pre review timer is running, please pause the timer before closing the modal'
                })

                event.preventDefault();
                event.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        
        const handleUnload = () => {
            if (isRunning) {
                handlePreReviewTimeTimeUpdate();
            }
        };
        window.addEventListener('unload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
        };
    }, [isRunning]);

    useEffect(() => {
        dispatch(setStopwatchPreReviewTime(totalSeconds));
    }, [totalSeconds]);

    useEffect(() => {
        dispatch(getPreReviewTimeTimeDetailsRequery(`${examId}`));
    }, [])

    useEffect(() => {
        if (stopwatchNeedReset) {
            handleReset();
        }
    }, [stopwatchNeedReset])

    const handlePreReviewTimeTimeUpdate = async () => {
        try {
            const result = await dispatch(preReviewTimeTimeUpdate()).unwrap();
            if (result && result.success) {
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    }

    const handleToggle = (): void => {
        if (isRunning) {
            pause();
            dispatch(stopwatchPause());
            dispatch(setStopwatchPreReviewTime(totalSeconds));
            handlePreReviewTimeTimeUpdate();
        }
        else {
            start();
            dispatch(stopwatchStart());
        }
    }

    const handleReset = (): void => {
        const stopwatchOffset = new Date(); stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + stopwatchPreReviewTime);
        reset(stopwatchOffset, false);

        if (stopwatchResumeExamIdAfterSave !== '' && stopwatchResumeExamIdAfterSave === `${examId}`) {
            start();
            dispatch(stopwatchStart());
        }
        else {
            pause();
            dispatch(stopwatchPause());
        }
    }

    const getButtonText = () => {
        if (isRunning) {
            return 'Pause';
        }
        else {
            if (totalSeconds === 0) {
                return 'Start';
            }
            else {
                return 'Resume';
            }
        }
    }

    return (
        <div className='admin-prereview-stopwatch' style={{ textAlign: 'center' }}>
            <div>
                <span>{minutes}</span>:<span>{seconds}</span>
                <Button
                    type="text"
                    size="small"
                    className="admin-prereview-stopwatch-button"
                    onClick={handleToggle}
                >{getButtonText()}</Button>
            </div>

        </div>
    );
}

export default AdminPrereviewStopwatch;
