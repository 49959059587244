import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppDispatch, RootState } from "../stores/retina-enabled-store";
import { DIAGNOSIS_LIST, PLAQUENIL_DATE, PLAQUENIL_DOSE, DIAGNOSTICS_VALUES, DIAGNOSIS_NARROW_ANGLES, DIAGNOSIS_OPTIC_NERVE, DIAGNOSIS_ERM } from '../constants';
import { addDiagnosisValueFromKeyword, convertBackendBooleanDiagnosesToStateEntries, convertBackendDiagnosisDataToStateEntries, generateDiagnosisListOptions, getSixDiagnosticBooleanValues, saveBooleanDiagnosisEntriesToObject, saveDiagnosisEntriesToString } from '../helpers/diagnosis-convert';
import { IExamData, setExamDataValue } from './patient-exam-slice';

export interface IDiagnosisEntry {
    dosageText?: string;
    label: string;
    type: string;
    value: string;
}
export interface IDiagnosis {
    entries: IDiagnosisEntry[];
    loaded: boolean;
    isPlaquenilModalOpen: boolean;
    plaquenil_dose: string;
    plaquenil_date: string;
}

export interface IDiagnosisValue {
    [key: string]: boolean | string;
}

export interface IDiagnosisListItem {
    id: string;
    diagnosisType: string;
    dosageText?: string;
    isGlaucoma: boolean;
    isOpticNerve: boolean;
    keywords: string[];
}

export interface IDiagnosisOption {
    value: string,
    label: string,
    type: string,
    dosageText?: string,
}

const initialState: IDiagnosis = {
    entries: [],
    loaded: false,
    isPlaquenilModalOpen: false,
    plaquenil_dose: '',
    plaquenil_date: '',
}

type BooleanDiagnosisValuesArray = [keyof IExamData, boolean ];

export function setDiagnosisValues(selectedDiagnosisValues: IDiagnosisEntry[], action:string, diagnosisList: IDiagnosisListItem[]) {

    return(dispatch: AppDispatch) => {
        let values = selectedDiagnosisValues;

        // If the onChange action is selecting an option, then add the diagnosis value from Keyword.
        // Otherwise the action is deselecting the option in which we case we can simply update the passed in values.
        if (action === 'select-option'){
            values = addDiagnosisValueFromKeyword(selectedDiagnosisValues, diagnosisList);
            dispatch(setDiagnosisData(values));
        } else {
            dispatch(setDiagnosisData(values));
        }

        // Set the exam_data store boolean values of the six diagnostic values
        const sixDiagnosticBooleanValues = getSixDiagnosticBooleanValues(values);
        const sixDiagnosticBooleanValuesEntries = Object.entries(sixDiagnosticBooleanValues) as BooleanDiagnosisValuesArray[];
        sixDiagnosticBooleanValuesEntries.forEach(([key, value]) => {
            dispatch(setExamDataValue(key, value));
        });
    }
}

// Load the given string diagnosis data into our diagnostic entries list in the store.
export function loadDiagnosisData(data: IDiagnosisValue) {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const currentState = getState();
        const { isRetinaEnabled, isOMDR, isOMDC } = currentState.user;
        const { exam_is_retina_only, rlu_exam_is_premium, is_referral_letter_upload_pei  } = currentState.examData;
        // Load the diagnosis values from the exam.
        const shouldAddBooleanOptions = (isRetinaEnabled || exam_is_retina_only) || (is_referral_letter_upload_pei && !rlu_exam_is_premium) || (isOMDR || isOMDC);
        const diagnosisListOptions = generateDiagnosisListOptions(currentState.examData[DIAGNOSIS_LIST], shouldAddBooleanOptions);
        let diagnosisEntries = convertBackendDiagnosisDataToStateEntries(data, diagnosisListOptions);

        // Also load the boolean diagnoses from the exam.
        diagnosisEntries = shouldAddBooleanOptions ?  diagnosisEntries.concat(convertBackendBooleanDiagnosesToStateEntries(currentState.examData, diagnosisListOptions))  : diagnosisEntries;
        
        dispatch(loadDiagnosisDataAction(diagnosisEntries));
        if (data[PLAQUENIL_DOSE] && typeof data[PLAQUENIL_DOSE] === 'string' ){
            dispatch(setPlaquenilDose(data[PLAQUENIL_DOSE]));
        }
        if (data[PLAQUENIL_DATE] && typeof data[PLAQUENIL_DATE] === 'string' ){
            dispatch(setPlaquenilDate(data[PLAQUENIL_DATE]));
        }
    };
}

// Set the given diagnostic entries list into a string in the store.
export function saveDiagnosisData(entries: IDiagnosisEntry[]) {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const { user: {isRetinaEnabled, isOD, isADMIN, isOMDR, isOMDC }, examData: {exam_is_retina_only,is_referral_letter_upload_pei, rlu_exam_is_premium } } = getState();
        const isIC = !isRetinaEnabled && isOD;
        const shouldAddBooleanOptions = (isRetinaEnabled || exam_is_retina_only) || (is_referral_letter_upload_pei && !rlu_exam_is_premium) || (isOMDR || isOMDC);

        // We want to convert the entries into a string, then set the exam data store for diagnosis values.
        const diagnosticsValues = saveDiagnosisEntriesToString(entries, shouldAddBooleanOptions);
        if (diagnosticsValues) {
            dispatch(setExamDataValue(DIAGNOSTICS_VALUES, diagnosticsValues));
        }

        if (shouldAddBooleanOptions ){
            // Also, we need to save any special rr_ boolean diagnoses to their own fields in the exam data store.
            const booleanDiagnosisValues = saveBooleanDiagnosisEntriesToObject(entries);
            
            const booleanDiagnosisValuesEntries = Object.entries(booleanDiagnosisValues) as BooleanDiagnosisValuesArray[];
            booleanDiagnosisValuesEntries.forEach(([key, value]) => {
                dispatch(setExamDataValue(key, value));
            });

            // Also, we need to save narrow angles value
            if (entries && entries.find(entry => entry.value === 'narrow_angles')) {
                dispatch(setExamDataValue(DIAGNOSIS_NARROW_ANGLES, true));
            } else {
                dispatch(setExamDataValue(DIAGNOSIS_NARROW_ANGLES, false));
            }
            // Also, we need to save optic nerve value
            if (entries && entries.find(entry => (entry.value === 'disc_drusen' || entry.value === 'optic_nerve'))) {
                dispatch(setExamDataValue(DIAGNOSIS_OPTIC_NERVE, true));
            } else {
                dispatch(setExamDataValue(DIAGNOSIS_OPTIC_NERVE, false));
            }
            // Also, we need to save erm value
            if (entries && entries.find(entry => (entry.value === 'Epiretinal_Membrane' || entry.value === 'rr_erm'))) {
                dispatch(setExamDataValue(DIAGNOSIS_ERM, true));
            } else {
                dispatch(setExamDataValue(DIAGNOSIS_ERM, false));
            }
        }
    };
}


export const diagnosisSlice = createSlice({
    name: 'diagnosisSlice',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setDiagnosisData: (state, action: PayloadAction<IDiagnosisEntry[]>) => {
            return {
                ...state,
                entries: action.payload
            }
        },
        loadDiagnosisDataAction: (state, action: PayloadAction<IDiagnosisEntry[]>) => {
            return {
                ...state,
                entries: action.payload,
                loaded: true,
                plaquenil_dose:'',
                plaquenil_date:'',
            }
        },
        clearPatientExamRoomsData: () => initialState,
        setPlaquenilDose: (state, action: PayloadAction<string>) => {
            state.plaquenil_dose = action.payload;
        },
        setPlaquenilDate: (state, action: PayloadAction<string>) => {
            state.plaquenil_date = action.payload;
        },
        openPlaquenilModal: (state) => {
            state.isPlaquenilModalOpen = true;
        },
        closePlaquenilModal: (state) => {
            state.isPlaquenilModalOpen = false;
        }
    },
   
});
// export the actions related to UsInsurance
export const { setPlaquenilDose, setPlaquenilDate, closePlaquenilModal, openPlaquenilModal,
    setDiagnosisData, loadDiagnosisDataAction
    } = diagnosisSlice.actions;

export default diagnosisSlice.reducer;