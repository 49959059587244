import { useEffect } from 'react';
import { useCare1AppSelector, useCare1AppDispatch } from '../../apps/care1-hooks';
import { FieldData } from 'rc-field-form/lib/interface';
import { Tag, Card, Col, Radio, Row, Select, Form, InputNumber, Tooltip, Space } from 'antd';
import { IExamData, setExamDataValue } from '../../reducers/patient-exam-slice';
import { useFormValidation } from '../../context/useFormValidationContext'
import { allowPosNumberOnlyKeys } from '../../helpers/utilities';
import { InfoCircleOutlined } from '@ant-design/icons';
import { setPreReviewDataValue } from '../../reducers/pre-review-slice';
import { updateGptSetValuesNearMissData } from '../../reducers/gpt-set-values-slice';
import { localizedText } from '../../localizedText';
import { CANT_BILL_OCT_FIELD, CANT_BILL_VF_FIELD, FU_LETTER_FIELD, FU_NUMBER_FIELD, OMD_APT_FIELD,
    OTHER_OMD_FIELD,
} from '../../constants';
import '../../../static/css/components/patient-exam-billing-rules.scss';
import AdminBillingRulesOutboundReferral from '../admin/admin-billing-rules-outbound-referral';

const { CheckableTag } = Tag;

const PatientExamBillingRules = ({ disabled }: { disabled: boolean }) => {
    const { NO_TEXT, YES_TEXT, GOVT_BILL_TEXT, GOVT_BILL_EXPLANATION_TEXT,
        FU_LETTER_TEXT, OTHER_OMD_TEXT, OMD_APT_TEXT, MONTHS_TEXT, WEEKS_TEXT } = localizedText;

    const odGroupPracticeProvince = useCare1AppSelector(store => store.examData.od_group_practice_province);
    const fuNumber = useCare1AppSelector(store => store.examData[FU_NUMBER_FIELD]);
    const otherOmd = useCare1AppSelector(store => store.examData[OTHER_OMD_FIELD]);
    const cantBillOct = useCare1AppSelector(store => store.examData[CANT_BILL_OCT_FIELD]);
    const cantBillVf = useCare1AppSelector(store => store.examData[CANT_BILL_VF_FIELD]);
    const omdApt = useCare1AppSelector(store => store.examData[OMD_APT_FIELD]);
    const fuLetter = useCare1AppSelector(store => store.examData[FU_LETTER_FIELD]);
    const ipc = useCare1AppSelector(store => store.examData.ipc);
    const isAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const isReferralLetterUploadPei = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const isFuUnknown = useCare1AppSelector(store => store.examData.is_fu_unknown);
    const isOutboundReferral = useCare1AppSelector(store => store.examData.is_outbound_referral);
    const isCatRefer = useCare1AppSelector(store => store.examData.cat_refer);

    const [ form ] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    const gptSavedValues = useCare1AppSelector(store => store.gptSetValues.gpt_saved_values);
    const gptNearMissFields = useCare1AppSelector(store => store.gptSetValues.gpt_near_miss_fields);

    // Required for OD users only
    const userIsOD = useCare1AppSelector(store => store.user.isOD);

    const { setBillingRulesForm } = useFormValidation();

    const getGptSavedValue = (k: string) => {
        if (!isAdmin) {
            return false;
        }

        if (gptSavedValues && gptSavedValues !== '') {
            try {
                const gptSavedValuesJson = JSON.parse(gptSavedValues);
                return gptSavedValuesJson[k];
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }

    const getGptNearMissFields = (k: string) => {
        if (!isAdmin) {
            return false;
        }

        if (gptNearMissFields && gptNearMissFields !== '') {
            try {
                const gptNearMissFieldsJson = JSON.parse(gptNearMissFields);
                return gptNearMissFieldsJson[k];
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }

    const gptClassname = (key: string) => {
        if (getGptNearMissFields(key)) {
            return 'exam-gpt-state-red';
        }
        if (getGptSavedValue(key)) {
            return 'exam-gpt-state-yellow';
        }
        return '';
    }

    // Set disabled class name
    const disabledClass = disabled ? ' disabled' : '';

    // Determine if OCT and VF billing rule question is included
    const isOctQuestion = odGroupPracticeProvince === "AB" || odGroupPracticeProvince === "ON";
    const isVfQuestion = odGroupPracticeProvince === "AB" || odGroupPracticeProvince === "MB" || odGroupPracticeProvince === "ON";
    const referralLetterAdminUI = isAdmin && isReferralLetterUploadPei;

    // Side effects that we run after component render.
    useEffect(() => {
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = isOutboundReferral ? {
            [FU_LETTER_FIELD]: fuLetter,
            [FU_NUMBER_FIELD]: fuNumber ? Number(fuNumber) : null,
            cat_refer: isCatRefer,
            is_fu_unknown: isFuUnknown,
        } : {
            [FU_NUMBER_FIELD]: fuNumber ? Number(fuNumber) : null,
            [OTHER_OMD_FIELD]: otherOmd,
            [CANT_BILL_OCT_FIELD]: cantBillOct,
            [CANT_BILL_VF_FIELD]: cantBillVf,
            [OMD_APT_FIELD]: omdApt,
            [FU_LETTER_FIELD]: fuLetter,
            is_fu_unknown: isFuUnknown,
        };
        form.setFieldsValue(newValidationFieldValues);

        // The component should validate on every render
        form.validateFields();
        // pass the form instance to useFormValidation hooks

        setBillingRulesForm(form);
        // clean up function when billingRules component unmounts
        return () => {
            setBillingRulesForm(null);
        }
    }, [fuNumber, otherOmd, cantBillOct, cantBillVf, omdApt, fuLetter, form, setBillingRulesForm, isFuUnknown, isCatRefer, isOutboundReferral ]);


    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0]) {
                    case CANT_BILL_OCT_FIELD:
                    case CANT_BILL_VF_FIELD:
                    case FU_LETTER_FIELD:
                        dispatch(setExamDataValue(field.name[0], field.value));
                        // On a change of F/U unit, the pre-review data should be dirtied.
                        if (isAdmin) {
                            dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));

                            // Also, admins need to set GPT changes.
                            dispatch(updateGptSetValuesNearMissData({id: [field.name[0]].toString() as keyof IExamData, value: field.value}));
                        }
                        break;
                    case FU_NUMBER_FIELD:
                        const fuNumberValue = field.value ? field.value : '';
                        dispatch(setExamDataValue(field.name[0], fuNumberValue.toString()));

                        if (isAdmin) {
                            // Also, admins need to set GPT changes.
                            dispatch(updateGptSetValuesNearMissData({id: [field.name[0]].toString() as keyof IExamData, value: field.value}));
                        }
                        break;
                    case OTHER_OMD_FIELD:
                        dispatch(setExamDataValue(field.name[0], field.value));
                        break;
                    case OMD_APT_FIELD:
                        dispatch(setExamDataValue(field.name[0], field.value));
                        break;
                    case 'is_fu_unknown':
                        dispatch(setExamDataValue(field.name[0], field.value));

                        if (isAdmin) {
                            // Also, admins need to set GPT changes.
                            dispatch(updateGptSetValuesNearMissData({id: [field.name[0]].toString() as keyof IExamData, value: field.value}));
                        }
                        break;
                    default:
                        break;
                }
            }
        });
    };

    const onOtherOmdChanged = (value: string) => {
        // If the other OMD button is selected as yes, the "Past OMD"
        dispatch(setExamDataValue('past_omd', value === 'True'));
    }

    const onReferOmdChanged = (value: string) => {
         // If the in-person OMD appt button is checked, the "OD RQST. Care1 OMD Appt"
        // and "IPC Requested" flags should both be set.
        dispatch(setExamDataValue('od_req_omd', value === 'True'));
        if(value === 'True' && ipc !== 'addressed'){
            dispatch(setExamDataValue('ipc', 'requested'));
        }
        if(value === 'False' && ipc === 'requested'){
            dispatch(setExamDataValue('ipc', ''));
        }
    }

    // max digits of month/weeks value should be 5 based on exam model fu_number field
    const formatValue = (value: string | undefined, info: {
        userTyping: boolean;
        input: string;
      }): string => {
        const regex = /^([1-9]?[0-9]?[0-9]?[0-9]?[0-9]?).*$/;
        const matches = String(value).match(regex);
        return matches && matches[1] ? matches[1] : '';
    }

    let optionsClass = 'minimum-options';
    if (odGroupPracticeProvince === 'AB') {
        optionsClass += ' alberta';
    } else if (odGroupPracticeProvince === 'MB') {
        optionsClass += ' manitoba';
    } else if (odGroupPracticeProvince === 'ON') {
        optionsClass += ' ontario';
    }

    return (
        <Form
            form={form}
            onFieldsChange={onFieldsChange}
            className={`${referralLetterAdminUI ? 'referral-letter-admin-billing-rules':''}`}
            labelAlign='left'
            >
            <Card className={`patient-exam-billing-rules ${optionsClass}${disabledClass}`} bordered={false}>
                {/* BILL 001 */}
                
                { !isOctQuestion && isVfQuestion && odGroupPracticeProvince !== 'ON' && !referralLetterAdminUI &&
                     (
                        <Row className="billing-rule-row billing-questions">
                            {/* BILL 002, BILL 009 */}
                            <Col span={10}>
                                <span className={'billing-label'}>{GOVT_BILL_TEXT}</span>
                                <Tooltip placement='topRight' title={GOVT_BILL_EXPLANATION_TEXT}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </Col>
                            <Col span={14}>
                                <Form.Item
                                    className={'cant-bill-vf'}
                                    name={'cant_bill_vf'}
                                    label={'VF'}
                                    colon={false}
                                    help=""
                                >
                                    <CheckableTag
                                        data-testid='cant-bill-vf'
                                        checked={cantBillVf}
                                        key={'cant_bill_vf'}
                                    >
                                        {`TRUE`}
                                    </CheckableTag>
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                }
                { isOctQuestion && isVfQuestion && odGroupPracticeProvince !== 'ON' && !referralLetterAdminUI &&
                    (
                        <Row className="billing-rule-row billing-questions">
                            {/* BILL 003, BILL 010 */}
                            <Col span={10}>
                                <span className={'billing-label'}>{GOVT_BILL_TEXT}</span>
                                <Tooltip placement='topRight' title={GOVT_BILL_EXPLANATION_TEXT}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    className={'cant-bill-oct'}
                                    name={'cant_bill_oct'}
                                    label={'OCT'}
                                    colon={false}
                                    help=""
                                >
                                    <CheckableTag
                                        data-testid='cant-bill-oct'
                                        checked={cantBillOct}
                                        key={'cant_bill_oct'}
                                    >
                                        {`TRUE`}
                                    </CheckableTag>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    className={'cant-bill-vf'}
                                    name={'cant_bill_vf'}
                                    label={'VF'}
                                    colon={false}
                                    help=""
                                >
                                    <CheckableTag
                                        data-testid='cant-bill-vf'
                                        checked={cantBillVf}
                                        key={'cant_bill_vf'}
                                    >
                                        {`TRUE`}
                                    </CheckableTag>
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                }
                {referralLetterAdminUI &&
                    <Row className="billing-rule-row other-provinces-row-1" gutter={3}>
                        <Col span={24}>
                        <Form.Item 
                            className={`${gptClassname('is_fu_unknown')}`} 
                            label={'FU Unknown'}
                            labelCol={{span: 16}}
                            wrapperCol={{span: 8}}
                            colon={false} 
                            help=""
                            name={'is_fu_unknown'}
                            >
                            <Radio.Group
                                buttonStyle="solid"
                                size="small"
                            >
                                <Radio.Button
                                    disabled={disabled && otherOmd === 'False'}
                                    value={true}
                                >
                                    {YES_TEXT.toUpperCase()}
                                </Radio.Button>
                                <Radio.Button
                                    disabled={disabled && otherOmd === 'True'}
                                    value={false}
                                >
                                {NO_TEXT.toUpperCase()}
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        </Col>
                    </Row>
                    
                }
                { <Row className="billing-rule-row other-provinces-row-1" gutter={3}>
                    <Col span={13}>
                        <span>{FU_LETTER_TEXT}</span>
                    </Col>
                    <Col span={4}>
                        {/* BILL 004 */}
                        <Form.Item
                            className={`fu-number ${gptClassname(FU_NUMBER_FIELD)}`}
                            label={'\xa0'}
                            colon={false}
                            help=""
                            name={FU_NUMBER_FIELD}
                            rules={[{ required: userIsOD && true }]}
                        >
                            <InputNumber
                                data-testid='fu-number'
                                className={'fu-number input-component'}
                                size={'small'}
                                disabled={disabled}
                                onKeyPress={event => allowPosNumberOnlyKeys(event)}
                                formatter={formatValue}
                                controls={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        {/* BILL 005 */}
                        <Form.Item
                            className={`fu-period ${gptClassname('fu_letter')}`}
                            colon={false} help=""
                            name={FU_LETTER_FIELD}
                        >
                            <Select
                                data-testid='fu-period'
                                className={'fu-period'}
                                size="small"
                                popupClassName="fu-letter"
                                disabled={disabled}
                            >
                                <Select.Option value={'month'}>{MONTHS_TEXT}</Select.Option>
                                <Select.Option value={'week'}>{WEEKS_TEXT}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>}
                { !referralLetterAdminUI &&
                    <Row className="billing-rule-row">
                        <Col span={18}>
                            <span>{OTHER_OMD_TEXT}</span>
                        </Col>
                        <Col span={6}>
                            {/* BILL 006 */}
                            <Form.Item 
                                className={'sees-omd'} 
                                label={'\xa0'}
                                colon={false} 
                                help=""
                                name={'other_omd'}
                                rules={[{ required: userIsOD && true }]}
                                >
                                <Radio.Group
                                    className={'sees-omd'}
                                    buttonStyle="solid"
                                    size="small"
                                    onChange={(e) => onOtherOmdChanged(e.target.value)}
                                >
                                    <Radio.Button
                                        disabled={disabled && otherOmd === 'False'}
                                        value="True"
                                    >
                                        {YES_TEXT.toUpperCase()}
                                    </Radio.Button>
                                    <Radio.Button
                                        disabled={disabled && otherOmd === 'True'}
                                        value="False"
                                    >
                                    {NO_TEXT.toUpperCase()}
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                }
                {!(isOutboundReferral && isAdmin) ? 
                <Row className="billing-rule-row">
                    <Col span={!referralLetterAdminUI ? 18 : 15}>
                        <span>{OMD_APT_TEXT}</span>
                    </Col>
                    <Col span={!referralLetterAdminUI ? 6 : 9}>
                         {/* BILL 007, BILL 008 */}
                        <Form.Item 
                            className={'refer-omd'} 
                            label={'\xa0'}
                            colon={false} 
                            help=""
                            name={'refer_in_person_omd_appt'}                            
                            rules={[{ required: userIsOD && true }]}
                            >
                            <Radio.Group
                                className={'refer-omd'}
                                buttonStyle="solid"
                                size="small"
                                onChange={(e) => onReferOmdChanged(e.target.value)}
                            >
                                <Radio.Button
                                    disabled={disabled && omdApt === "False"}
                                    test-id="refer-omd-yes"
                                    className='yes'
                                    value="True"
                                >
                                    {YES_TEXT.toUpperCase()}
                                </Radio.Button>
                                <Radio.Button
                                    disabled={disabled && omdApt === "True"}
                                    test-id="refer-omd-no"
                                    className='no'
                                    value="False"
                                >
                                    {NO_TEXT.toUpperCase()}
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                :
                <AdminBillingRulesOutboundReferral />
            }
            </Card>
        </Form>
    );
}

export default PatientExamBillingRules;