import { Tag } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { setOverrideActions, setPreReviewDataValue } from '../../reducers/pre-review-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

const { CheckableTag } = Tag;

const AdminBillingAddressed = () => {

    const billingAddressed = useCare1AppSelector(store => store.examData.billing_addressed);

    const dispatch = useCare1AppDispatch();

    const handleChange = (checked: boolean) => {
        dispatch(setExamDataValue('billing_addressed', checked));
        dispatch(setOverrideActions(['Addressed', checked]));
        dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
    }

    return (
        <div
            className='billing-addressed'
        >
            <CheckableTag
                checked={billingAddressed}
                onChange={(checked) => handleChange(checked)}
            >
                Addressed
            </CheckableTag>
        </div>
    );
}

export default AdminBillingAddressed;