import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { CloseCircleOutlined, FileSyncOutlined, FileDoneOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { goToExamInNewTab, isOutboundPreReviewedExam, setExamDataValue } from '../../reducers/patient-exam-slice';
import { useFormValidation } from '../../context/useFormValidationContext';

import '../../../static/css/components/omd-submit-buttons.scss';
import '../../../static/css/components/exam-exit-confirmation-modal.scss';
import { localizedText } from '../../localizedText';
import { removeExamFromTab } from '../../reducers/patient-exam-rooms-slice';
import { saveExamData, submitExam } from '../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { useCheckShouldDisableSubmitButtonQuery, useSetExamValidationReviewMutation } from '../../services/exam-api';
import { CONFIRMATION_CANCEL_TEXT, CONFIRMATION_OK_TEXT, EXIT_CONFIRMATION_TITLE, EXIT_CONTENT_TEXT, PATIENT_EXAM_URL, ROOT_URL } from '../../constants';

const OMDSubmitButtons = ({disabled}: {disabled: boolean}) => {
    const { CANCEL_BUTTON_TEXT, SUBMIT_BUTTON_TEXT, SAVE_BUTTON_TEXT, 
        OMD_SUBMIT_TOOLTIP } = localizedText;
    const dispatch = useCare1AppDispatch();
    const history = useNavigate();
    const examDirty = useCare1AppSelector(store => store.examData.dirty);
    const currentPatientExamRoom = useCare1AppSelector(store => store.patientExams.currentExamRoom);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const examId = useCare1AppSelector(store => store.examData.id);
    const examStatus = useCare1AppSelector(store => store.examData.exam_status);
    
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const btnRef = useRef<HTMLButtonElement>(null); // get the submit button reference

    // check whether the assigned OMD (OMDR or OMDC) is the same as current logged in user
    // if not, disable the cancel and submit button
    const {data: currentUserNotMatchingAssignedOMD} = useCheckShouldDisableSubmitButtonQuery(examId!, {skip: !(userIsOMDR || userIsOMDC) || !examId});
    
    const [setExamValidationReview] = useSetExamValidationReviewMutation();

    const { untilYesterdayForm, ongoingForm, comorbiditiesForm,instrumentForm, chiefComplaintForm
        } = useFormValidation();

    // currently, for OMDC and OMDR, the components that need to be validated are untilYesterday,
    // ongoing, comorbidities, instrument
    const getValidationFunctions = () => {
        // Get all of the validation functions that we need from the properties that we are given.
        return [
            untilYesterdayForm && untilYesterdayForm.validateFields(),
            ongoingForm && ongoingForm.validateFields(),
            comorbiditiesForm && comorbiditiesForm.validateFields(),
            instrumentForm && instrumentForm.validateFields(),
            chiefComplaintForm && chiefComplaintForm.validateFields(),
        ]
    }

    const handleOmdcSubmit = () => {
        Promise.all(getValidationFunctions())
            .then(() => {
                dispatch(setExamDataValue('omdc_status', 'reviewed'));
                return dispatch(saveExamData());
            })
            .then((res) => {
                return setExamValidationReview(examId!).unwrap();
            })
            .then((result) => {
                if (result?.success) {
                    history(ROOT_URL);
                } else if (result?.error) throw Error(result?.error);
            })
            .catch((error) => {
                const message = error instanceof Error ? error?.message : error;
                Modal.error({
                    className: 'info-modal',
                    title: `Cannot submit exam, ${message}`,
                });
            });
    };

    const handleSubmit = () => {
        if (userIsOMDC) {
            handleOmdcSubmit();
        } else {
            Promise.all(getValidationFunctions())
            .then(() => dispatch(submitExam(history)))
            .catch((error) => {
                Modal.error({
                    className: 'info-modal',
                    title: 'Cannot submit chart due to validation errors!',
                });
            });
        }
    };

    const handleKeyPress = useCallback((e: KeyboardEvent) => {
        // when Ctrl + Shift + X are clicked , manually trigger 'click' submit button
        const ctrlShiftXPressed = e.code === 'KeyX' && e.ctrlKey && e.shiftKey;
        if (ctrlShiftXPressed){
            btnRef!.current!.click();
        }
    },[])

    useEffect(() => {
        // add event listener to handle keyboard shortcut
        window.addEventListener('keydown', handleKeyPress);

        return () => {
            // remove keypress event listener
            window.removeEventListener('keydown', handleKeyPress);
        }
    }, [handleKeyPress])

    const handleSave = (pullNextExam: boolean) => {
        if (userIsADMIN) {
            if(examStatus === 'ready' && dispatch(isOutboundPreReviewedExam())){
                dispatch(submitExam(history));
            } else {
                dispatch(saveExamData());
            }
        } else {
            Promise.all(getValidationFunctions())
            .then((values) => {
                dispatch(saveExamData());
                // if Save & Next button is clicked, pull the next exam in the queue
                if(pullNextExam){
                    if (userIsOMDR) {
                        const examIdsStr = localStorage.getItem('exam_ids') as string;
                        let examIds;
                        try{
                            examIds = JSON.parse(examIdsStr);
                        } catch(e) {
                            console.log(e);
                        }
                        if (examIds && examIds.length) {
                            // remove the first item in examIds and save it to nextExamInQueue
                            const nextExamInQueue = examIds.shift();
                            // save the updated examIds to local storage
                            localStorage.setItem('exam_ids', JSON.stringify(examIds));
                            const examUrl = `${PATIENT_EXAM_URL}/${nextExamInQueue}`;
                            goToExamInNewTab(examUrl);
                        } else {
                            Modal.info({
                                className: 'info-modal',
                                title: 'Your changes have been saved, you may close this tab. There are no more patients to load.',
                            })
                        }
                    }
                }
            })
            .catch((errors) => {
                // We need to show the errors to the user in a popup.
                Modal.error({
                    className: 'info-modal',
                    title: `Cannot save chart due to validation errors! ${errors}`,
                });
            });
        }
    }

    const handleCancel = () => {
        if (examDirty) {
            Modal.confirm({
                className: 'exam-exit-confirmation-modal',
                title: EXIT_CONFIRMATION_TITLE,
                content: EXIT_CONTENT_TEXT,
                icon: <InfoCircleOutlined />,
                okText: CONFIRMATION_OK_TEXT,
                cancelText: CONFIRMATION_CANCEL_TEXT,
                cancelButtonProps: {
                    className: 'confirm-exit',
                },
                onOk: () => {
                    dispatch(removeExamFromTab(currentPatientExamRoom, history));
                },
            });
        } else {
            dispatch(removeExamFromTab(currentPatientExamRoom, history));
        }
    }

    return (
        <Card className={`omd-submit-buttons${disabled ? ' disabled' : ''}`} bordered={false}>
            <Col className={'buttons-col'}>

                {userIsADMIN &&
                    <Fragment>
                        <div className="btn-save">
                            <Button
                                disabled={disabled}
                                onClick={() => handleSave(false)}
                                className="save-button"
                                type="primary"
                                icon={<FileSyncOutlined />}
                            >
                                {SAVE_BUTTON_TEXT}
                            </Button>
                        </div>
                        <div className="btn-cancel">
                            <Button
                                disabled={disabled}
                                onClick={handleCancel}
                                className="cancel-button"
                                type="default"
                                icon={<CloseCircleOutlined />}
                            >
                                {CANCEL_BUTTON_TEXT}
                            </Button>
                        </div>
                    </Fragment>
                }

                {!userIsADMIN &&
                    <Fragment>
                        <div className="btn-cancel">
                            <Button
                                disabled={disabled || currentUserNotMatchingAssignedOMD}
                                onClick={handleCancel}
                                className="cancel-button"
                                type="default"
                                icon={<CloseCircleOutlined />}
                            >
                                {CANCEL_BUTTON_TEXT}
                            </Button>
                        </div>
                        <div className="omd-btn-save">
                            <Button
                                onClick={() => handleSave(false)}
                                className="save-button"
                                type="primary"
                                icon={<FileSyncOutlined />}
                            >
                                {SAVE_BUTTON_TEXT}
                            </Button>
                        </div>
                        <div className="btn-submit">
                            <Button
                                disabled={false}
                                onClick={handleSubmit}
                                type="primary"
                                icon={<FileDoneOutlined />}
                                ref={btnRef}
                            >
                                {SUBMIT_BUTTON_TEXT}
                                <Tooltip
                                    className="omdr-select-tooltip"
                                    placement='topLeft'
                                    title={OMD_SUBMIT_TOOLTIP}
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </Button>

                        </div>
                    </Fragment>
                }
            </Col>
        </Card>
    );
}

export default OMDSubmitButtons;