import { useCallback, useEffect } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import {Form,  Button, Modal, Col, Row, Input, Spin } from 'antd';
import * as Constants from '../../constants';
import '../../../static/css/components/_help-desk-modal.scss';

// Helpers
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';
import { getODReviewReminderEmailExams, IODReviewReminderEmailForm, setODReviewReminderEmailField, setODReviewReminderEmailModalOpen, submitODReviewReminderEmailRequest } from '../../reducers/od-review-reminder-email-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { FieldData } from 'rc-field-form/lib/interface';
import { localizedText } from '../../localizedText';

const ODReviewReminderEmailModal = () => {
    const { HELP_DESK_REQUEST, CANCEL_BUTTON_TEXT, SUBMIT_BUTTON_TEXT, } = localizedText;
    
    const examIds = useCare1AppSelector(store => store.odReviewReminderEmail.examIds);
    const tos = useCare1AppSelector(store => store.odReviewReminderEmail.tos);
    const body = useCare1AppSelector(store => store.odReviewReminderEmail.body);
    const subject = useCare1AppSelector(store => store.odReviewReminderEmail.subject);
    const isVisible = useCare1AppSelector(store => store.odReviewReminderEmail.isODReviewReminderEmailModalOpen);
    const isOperating = useCare1AppSelector(store => store.odReviewReminderEmail.isOperating);

    const dispatch = useCare1AppDispatch();
    const [ form ] = Form.useForm();

    const handleCancel = () => {
        dispatch(setODReviewReminderEmailModalOpen(false));
        // Reset fields and clear any validation error messages so that if reopened the error
        // messages will not display
        form.resetFields();
    }

    const handleSubmit = useCallback(async () => {
        try {
            await form.validateFields();
            dispatch(submitODReviewReminderEmailRequest())
            .then(() => dispatch(setODReviewReminderEmailModalOpen(false)));
        } catch (error){
            console.log('Send OD to Review Reminder Email Request Error', error);
        }
    }, [dispatch, form]);

    useEffect(() => {
        let newValidationFieldValues = {} as IODReviewReminderEmailForm ;
        newValidationFieldValues.tos = tos;
        newValidationFieldValues.body = body;
        newValidationFieldValues.subject = subject;
        form.setFieldsValue(newValidationFieldValues);
    },  [tos, body, subject, form]);

    useEffect(() => {
        if (examIds) {
            dispatch(getODReviewReminderEmailExams(examIds));
        }
    },  [examIds, dispatch]);

    const onFieldsChange = (fields: FieldData[]) => {
        // Step 1: Update the Patient Edit store
        fields.forEach((field: FieldData) => {
            if (Array.isArray(field.name) && field.name[0] && !field.validating) {
                switch (field.name[0] as keyof IODReviewReminderEmailForm) {
                    case 'subject':
                    case 'tos':
                    case 'body':
                        dispatch(setODReviewReminderEmailField({
                            field: field.name[0],
                            value: field.value,
                        }));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    return (

        <Modal
            className='help-desk-modal'
            open={isVisible}
            width="840"
            closable={false}
            footer={null}
        >
            <Spin
                className="loading-spinner"
                spinning={isOperating}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Row className="header-section" align="middle">
                    <div className="modal-title">
                    {HELP_DESK_REQUEST}
                    </div>

                    <Button
                        className="submit-request-button"
                        icon={<SaveOutlined />}
                        onClick={handleSubmit}
                    >
                        {SUBMIT_BUTTON_TEXT}
                    </Button>

                    <Button
                        className="cancel-button"
                        onClick={handleCancel}
                    >
                        {CANCEL_BUTTON_TEXT}
                    </Button>
                </Row>
                <Form
                    className='help-desk-form'
                    form={form}
                    onFieldsChange={onFieldsChange}
                    labelCol={{span: 24}}
                    wrapperCol={{span: 24}}
                >
                    <Row gutter={24}>
                        <Col span={24} className='request-details'>
                            <Form.Item
                                name={'tos'}
                                label={'To'}
                                hasFeedback
                                colon={false}
                                validateTrigger={'onBlur'}
                                rules={[{
                                    required: true,
                                    whitespace: true,
                                    message: buildRequiredErrorMessage('Email to'),
                                }]}
                            >
                                <Input.TextArea
                                    className='width-100-percent'
                                    rows={2}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24} className='request-details'>
                            <Form.Item
                                name={'subject'}
                                label={'Email subject'}
                                hasFeedback
                                colon={false}
                                validateTrigger={'onBlur'}
                                rules={[{
                                    required: true,
                                    whitespace: true,
                                    message: buildRequiredErrorMessage('Email subject'),
                                }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24} className='request-details'>
                            <Form.Item
                                name={'body'}
                                label={'Body'}
                                hasFeedback
                                colon={false}
                                validateTrigger={'onBlur'}
                                rules={[{
                                    required: true,
                                    whitespace: true,
                                    message: buildRequiredErrorMessage('Email body'),
                                }]}
                            >
                                <Input.TextArea
                                    rows={17}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}

export default ODReviewReminderEmailModal;