
import { Card, Row, Col, Tag } from 'antd';
import '../../../../static/css/components/admin-selectors.scss';
import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { localizedText } from '../../../localizedText';

const { CheckableTag } = Tag;

const ReadonlyAdminSelectors = () => {
    const { 
        CURT_UPCOMING_OMD, OD_HAS_QUEST_ISSUE,         
        ADMIN_SELECTORS_TEXT, HXDHX_OD_TEXT, HXDHX_OS_TEXT } = localizedText;
    
    const dhxOd = useCare1AppSelector(store => store.examData.dhx_od);
    const dhxOs = useCare1AppSelector(store => store.examData.dhx_os);
    const odQuestion = useCare1AppSelector(store => store.examData.od_question);
    const cuOmd = useCare1AppSelector(store => store.examData.cu_omd);
    const cuOmdAdminOverride = useCare1AppSelector(store => store.examData.cu_omd_admin_override);
    
    const shouldCuOmdSelected = cuOmdAdminOverride ?? cuOmd;

    return (
        <Card className={'component-input-card admin-selectors'} bordered={false}>
            <div className="heading">PEI {ADMIN_SELECTORS_TEXT}</div>
            <div className='component-body'>
                <Row>
                    <Col>
                        <CheckableTag
                            checked={dhxOd}
                        >
                            {HXDHX_OD_TEXT}
                        </CheckableTag>
                    </Col>
                    <Col>
                        <CheckableTag
                            checked={odQuestion}
                        >
                            {OD_HAS_QUEST_ISSUE}
                        </CheckableTag>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CheckableTag
                            checked={dhxOs}
                        >
                            {HXDHX_OS_TEXT}
                        </CheckableTag>
                    </Col>
                    <Col>
                        <CheckableTag
                            checked={shouldCuOmdSelected}
                        >
                            {CURT_UPCOMING_OMD}
                        </CheckableTag>
                    </Col>
                </Row>
            </div>
        </Card>
    )
}

export default ReadonlyAdminSelectors;