import { Layout, Spin } from 'antd';
import FilteredPatientList from '../components/admin/filtered-patient-list';
import NavigationControlBar from '../components/navigation-control-bar';
import * as Constants from '../constants';
import '../../static/css/pages/admin-alert-lists-page.scss'
import { useGetAlertListQuery, useGetBillingListQuery, useGetConflictingNotesListQuery, useGetIPCStatusListQuery,
    useGetMultipleExamsOMDCAssignedListQuery, useGetNoOMDCAssignedListQuery, useGetNoOMDRAssignedListQuery,
    useGetOMDC6MonthsListQuery, useGetOMDRNoTimestampListQuery, useGetUrgentListQuery, IAlertListItem,
    useGetPrereviewOver2WeeksListQuery, useGetPrereviewOver3WeeksListQuery, useGetNoOMDCStatusListQuery,
    useGetCatRefChiefComplaintQuery, useGetRetinaEnabledExamsQuery, useGetAdminNotesExamsQuery,
    useGetIPCStatusRequestedByOMDListQuery,
    useGetIpcOnlyExamsQuery,
    } from '../services/alert-lists-api';
import { localizedText } from '../localizedText';
import { goToExamInNewTab, goToExamInNewTabWithTarget } from '../reducers/patient-exam-slice';

const { Header, Content } = Layout;

const AdminAlertListsPage  = () => {
    const { ADMIN_ALERTS_NO_OMDR, ADMIN_ALERTS_WITH_OMDC, ADMIN_ALERTS_MULTIPLE_EXAMS,
        ADMIN_ALERTS_IPC_STATUS, ADMIN_ALERTS_PRIORITY_OD, ADMIN_ALERTS_URGENT_PREREVIEW,
        ADMIN_ALERTS_OMDC_6MONTHS, ADMIN_ALERTS_BILLING_NOTES, ADMIN_ALERTS_IOP_35,
        ADMIN_ALERTS_LIST_CONFLICTING, ADMIN_ALERTS_OMD_TIMESTAMP, ADMIN_ALERTS_WITH_NO_OMDC_STATUS,
        ADMIN_ALERTS_IPC_STATUS_REQUESTED_BY_OMD,
    } = localizedText;

    // Get data from RTL Query hooks on the alertListsAPI
    const { data: noOMDRAssignedList = { patients: []}, isFetching: isFetch1 } = useGetNoOMDRAssignedListQuery();
    const { data: noOMDCAssignedList = { patients: []}, isFetching: isFetch2 } = useGetNoOMDCAssignedListQuery();
    const { data: multipleExamsOMDCAssignedList = { patients: []}, isFetching: isFetch3 } = useGetMultipleExamsOMDCAssignedListQuery();
    const { data: IPCStatusList = { patients: []}, isFetching: isFetch4 } = useGetIPCStatusListQuery();
    const { data: urgentList = { patients: []}, isFetching: isFetch5 } = useGetUrgentListQuery();
    const { data: OMDC6MonthsList = { patients: []}, isFetching: isFetch8 } = useGetOMDC6MonthsListQuery();
    const { data: billingList = { patients: []}, isFetching: isFetch9 } = useGetBillingListQuery();
    const { data: alertList = { patients: []}, isFetching: isFetch10 } = useGetAlertListQuery();
    const { data: conflictNotestList = { patients: []}, isFetching: isFetch11 } = useGetConflictingNotesListQuery();
    const { data: OMDRNoTimestampList = { patients: []}, isFetching: isFetch12 } = useGetOMDRNoTimestampListQuery();
    const { data: prereviewOver2Weeks = { patients: []}, isFetching: isFetch13 } = useGetPrereviewOver2WeeksListQuery();
    const { data: prereviewOver3Weeks = { patients: []}, isFetching: isFetch14 } = useGetPrereviewOver3WeeksListQuery();
    const { data: noOMDCStatusList = { patients: []}, isFetching: isFetch15 } = useGetNoOMDCStatusListQuery();
    const { data: catRefChiefComplaintList = { patients: []}, isFetching: isFetch16 } = useGetCatRefChiefComplaintQuery();
    const { data: retinaEnabledExams = { patients: []}, isFetching: isFetch17 } = useGetRetinaEnabledExamsQuery();
    const { data: adminNotesList = { patients: []}, isFetching: adminNotesListFetching } = useGetAdminNotesExamsQuery();
    const { data: IPCStatusRequestedByOMDList = { patients: []}, isFetching: isIPCStatusRequestedByOMDListFetching } = useGetIPCStatusRequestedByOMDListQuery();
    const { data: ipcOnlyList = { patients: []}, isFetching: ipcOnlyListFetching } = useGetIpcOnlyExamsQuery();

    const onLinkClick = (record: IAlertListItem) => {
        const examUrl = `${Constants.PATIENT_EXAM_URL}/${record.exam_id}`;
        goToExamInNewTab(examUrl);
    }

    // Open all exam of a list in separate Chrome tabs
    const openAll = (patientList: IAlertListItem[]) => {
        patientList.forEach((patientEntry: IAlertListItem) => {
            // We want to create a new room for each exam
            if (patientEntry.exam_id) {
                const examUrl = `${Constants.PATIENT_EXAM_URL}/${patientEntry.exam_id}`;
                const target = `target-${patientEntry.exam_id}`;
                goToExamInNewTabWithTarget(examUrl, target);
            }
        });
    }

    return (
        <Layout>
            <Spin
                className="loading-spinner"
                spinning={ isFetch1 || isFetch2 || isFetch3 || isFetch4 || isFetch5 || isFetch8 ||
                    isFetch9 || isFetch10 || isFetch11 || isFetch12 || isFetch13 || isFetch14 ||
                    isFetch15 || isFetch16 || isFetch17 || adminNotesListFetching || isIPCStatusRequestedByOMDListFetching}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Header>
                    <NavigationControlBar className="patient-list-navigation-control-bar" breadcrumbItems={undefined}/>
                </Header>
                <Content>
                    <div className='admin-alert-lists-page'>
                    <div className="patient-list-table patient-list-table-admin">
                            <div>
                                <FilteredPatientList
                                    patientList={noOMDRAssignedList.patients}
                                    title={ADMIN_ALERTS_NO_OMDR}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>

                            <div>
                                <FilteredPatientList
                                    patientList={noOMDCAssignedList.patients}
                                    title={ADMIN_ALERTS_WITH_OMDC}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>

                            <div>
                                <FilteredPatientList
                                    patientList={noOMDCStatusList.patients}
                                    title={ADMIN_ALERTS_WITH_NO_OMDC_STATUS}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>

                            <div>
                                <FilteredPatientList
                                    patientList={multipleExamsOMDCAssignedList.patients}
                                    title={ADMIN_ALERTS_MULTIPLE_EXAMS}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                            <div>
                                <FilteredPatientList
                                    patientList={IPCStatusList.patients}
                                    title={ADMIN_ALERTS_IPC_STATUS}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                            <div>
                                <FilteredPatientList
                                    patientList={IPCStatusRequestedByOMDList.patients}
                                    title={ADMIN_ALERTS_IPC_STATUS_REQUESTED_BY_OMD}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                            <div>
                                <FilteredPatientList
                                    patientList={ipcOnlyList.patients}
                                    title={'List of patients with IPC Only status'}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                            <div>
                                <FilteredPatientList
                                    patientList={catRefChiefComplaintList.patients}
                                    title={'List of patients with Cat Ref chief complaint'}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                            <div>
                                <FilteredPatientList
                                    patientList={urgentList.patients}
                                    title={ADMIN_ALERTS_PRIORITY_OD}
                                    cn="admin-patients-admin-list-yellow"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                            <div>
                                <FilteredPatientList
                                    patientList={prereviewOver2Weeks.patients}
                                    title={ADMIN_ALERTS_URGENT_PREREVIEW}
                                    cn="admin-patients-admin-list-yellow"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                            <div>
                                <FilteredPatientList
                                    patientList={prereviewOver3Weeks.patients}
                                    title={`Critical to pre-review (<a style="color:red">patients with latest exam not pre-reviewed and set to ready for OMD over 3 weeks ago</a> or <a style="color:#ccf1f1"> have an not pre-reviewed exam ready for OMD that's not the most recent exam</a>):`}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                            <div>
                                <FilteredPatientList
                                    patientList={OMDC6MonthsList.patients}
                                    title={ADMIN_ALERTS_OMDC_6MONTHS}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                            <div>
                                <FilteredPatientList
                                    patientList={billingList.patients}
                                    title={ADMIN_ALERTS_BILLING_NOTES}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                            <div>
                                <FilteredPatientList
                                    patientList={adminNotesList.patients}
                                    title='Outstanding Admin Notes'
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                            <div>
                                <FilteredPatientList
                                    patientList={alertList.patients}
                                    title={ADMIN_ALERTS_IOP_35}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                            <div>
                                <FilteredPatientList
                                    patientList={conflictNotestList.patients}
                                    title={ADMIN_ALERTS_LIST_CONFLICTING}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                            <div>
                                <FilteredPatientList
                                    patientList={OMDRNoTimestampList.patients}
                                    title={ADMIN_ALERTS_OMD_TIMESTAMP}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                            <div>
                                <FilteredPatientList
                                    patientList={retinaEnabledExams.patients}
                                    title={'List of Retina Enabled patients who have not been pre-reviewed'}
                                    cn="admin-patients-admin-list-red"
                                    onOpenAll={openAll}
                                    onLinkClick={onLinkClick}
                                />
                            </div>
                        </div>
                    </div>
                </Content>
            </Spin>
        </Layout>
    );
}

export default AdminAlertListsPage;