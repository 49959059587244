// the chat components codes mainly from 
// https://github.com/fatihbaltaci/chatgpt-clone

import { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Modal, Spin } from 'antd';
import { IMessageEvent, w3cwebsocket } from 'websocket';
import '../../../../static/css/components/admin-gpt-vision-vf.scss';
import { v4 as uuidv4 } from 'uuid';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { fetchGptVisionVfGhtPrompt, fetchAutoGptVisionVfGhtResponse } from '../../../reducers/gpt-vision-slice';
import GptUI from '../gpt/gpt-ui';
import GptVfGht from './gpt-vf-ght';
import Care1LightBox from '../../retina/care1-light-box';
import { getFilepathFromBackendMediaUrl } from '../../../helpers/media-image-convert';


interface Message {
    input: string;
    output: string;
    meta: string;
}

type ComponentProps = {
    side: string,
    photo: string,
    ght: string,
    setGht: (val: string) => void,
}

const GptVisionVfGht = ({ side, photo, ght, setGht }: ComponentProps) => {
    const dispatch = useCare1AppDispatch();

    const webSocketRef = useRef<w3cwebsocket | null>(null);
    const reconnectIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [messageHistory, setMessageHistory] = useState<Message[]>([]);
    const [inputMessage, setInputMessage] = useState<string>("");
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [isAssistantTyping, setIsAssistantTyping] = useState<boolean>(false);

    const [lightboxOn, setLightboxOn] = useState(false);

    const examId = useCare1AppSelector(store => store.examData.id);

    const ghtRemoveNonEnglish = (t: string) => {
        // Remove single-character words
        t = t.replace(/\b\w\b/g, '');
        // Remove non-English characters
        t = t.replace(/[^a-zA-Z]+/g, '');
        // Remove whitespace
        t = t.replace(/\s+/g, '');
        return t.toLowerCase();
    }

    const extractVfGhtValue = (inputString: string) => {
        if (!inputString) {
            return 'na';
        }

        const cleanText = ghtRemoveNonEnglish(inputString);

        if (cleanText.includes('abnormallyhigh')) {
            return 'ahs';
        } else if (cleanText.includes('withinnormallimit')) {
            return 'wnl';
        } else if (cleanText.includes('borderlinegeneral')) {
            return 'b-grs';
        } else if (cleanText.includes('generalreduction')) {
            return 'grs';
        } else if (cleanText.includes('borderline')) {
            return 'b';
        } else if (cleanText.includes('outsidenormallimit')) {
            return 'onl';
        }

        if (cleanText === 'ahs') {
            return 'ahs';
        } else if (cleanText === 'wnl') {
            return 'wnl';
        } else if (cleanText === 'b-grs') {
            return 'b-grs';
        } else if (cleanText === 'grs') {
            return 'grs';
        } else if (cleanText === 'b') {
            return 'b';
        } else if (cleanText === 'onl') {
            return 'onl';
        }

        return 'na';
    }

    const setExamDataValues = (results: { [x: string]: string; }) => {

        if (results.hasOwnProperty('GHT')) {
            setGht(extractVfGhtValue(results['GHT']));
        }
        else if (results.hasOwnProperty('ght')) {
            setGht(extractVfGhtValue(results['ght']));
        }
        else {
            setGht('na');
        }

    }

    const handleSocketOnMessage = (text: string) => {
        let outputStr = '';
        let metaStr = '';
        try {
            const obj = JSON.parse(text);
            outputStr = obj['message']['output'];
            metaStr = `${obj['message']['meta']}`;

            setMessageHistory(h => {
                const m = [...h];
                if (m.length > 0) {
                    m[m.length - 1].output = outputStr;
                    m[m.length - 1].meta = metaStr;
                    return m;
                }
                else {
                    return [{ input: inputMessage, output: outputStr, meta: metaStr }];
                }
            });

            setIsAssistantTyping(false);
        }
        catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting GPT Vision output',
            });
        };

        if (outputStr !== '') {
            try {
                // Use regex to extract the JSON object part
                const jsonPattern = /{[^]*}/;
                const jsonMatches = outputStr.match(jsonPattern);

                if (jsonMatches) {
                    const jsonResponse = jsonMatches[0];
                    const outputObj = JSON.parse(jsonResponse);
                    setExamDataValues(outputObj);

                } else {
                    console.log("No JSON object found in the text.");
                    throw new Error("No JSON object found in the text.");
                }
            }
            catch (error) {
                const message = (error instanceof Error) ? error?.message : error;
                Modal.error({
                    className: 'info-modal',
                    content: message as string,
                    title: 'Errors parsing GPT Vision output',
                });
            };
        }
    }

    const connectWebSocket = () => {

        if (webSocketRef.current) {
            console.log(`${webSocketRef.current.readyState} : ${WebSocket.OPEN}`);
        }

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
            console.log('WebSocket already connected');
            return;
        }

        const uuid = uuidv4();
        console.log(uuid);

        const socket = new w3cwebsocket(`${process.env.REACT_APP_WEBSOCKETURL}/gpt/vision/vf_ght/${uuid}`);

        socket.onopen = (): void => {
            console.log('WebSocket connected');
            // Clear the reconnect interval if the connection is successfully established
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };

        socket.onmessage = (message: IMessageEvent): void => {
            console.log('WebSocket: onmessage');
            handleSocketOnMessage(message.data.toString());
        };

        socket.onclose = () => {
            console.log('WebSocket closed');
            // Set up a reconnect interval to attempt reconnection
            setReconnectInterval();
        };

        webSocketRef.current = socket;
    };

    const setReconnectInterval = (): void => {
        // Clear any existing reconnect interval
        clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        // Set a new reconnect interval (e.g., 5 seconds)
        reconnectIntervalRef.current = setInterval(connectWebSocket, 5000);
    };

    useEffect(() => {
        connectWebSocket();

        // Function to update the window height on window resize
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        // Add event listener to window resize
        window.addEventListener("resize", handleResize);

        // Get deidentified Referral Letter
        handleReset();

        // // Get Gpt response
        getAutoGptResponse();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);

            // Close the WebSocket connection when the component unmounts
            if (webSocketRef.current) {
                webSocketRef.current.close();
            }
            // Clear the reconnect interval when the component unmounts
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messageHistory]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    const formatMessageContent = (content: string): string => {
        return content;
    }

    const sendMessage = () => {

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {

            console.log('WebSocket: sendMessage');
            webSocketRef.current.send(JSON.stringify({
                'message': {
                    'input': inputMessage,
                    'image_url': `${getFilepathFromBackendMediaUrl(photo)}`,
                    'history': []
                }
            }));
            setMessageHistory(() => {
                return [{ input: inputMessage, output: '', meta: '' }];
            });
            setIsAssistantTyping(true);
        }
    }

    const getAutoGptResponse = async () => {

        if (examId === null) {
            return
        }
        
        try {
            const result = await dispatch(fetchAutoGptVisionVfGhtResponse()).unwrap();
            if (result && result.success) {
                if (side === 'right' || side === 'od') {
                    if (result.od_gpt_vf_ght_response !== '') {
                        handleSocketOnMessage(result.od_gpt_vf_ght_response);
                    }
                }
                else if (side === 'left' || side === 'os') {
                    if (result.os_gpt_vf_ght_response !== '') {
                        handleSocketOnMessage(result.os_gpt_vf_ght_response);
                    }
                }
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {

        }
    }

    const handleReset = async () => {

        if (examId === null) {
            return
        }

        try {
            const result = await dispatch(fetchGptVisionVfGhtPrompt()).unwrap();
            if (result && result.success) {
                setInputMessage(result.gpt_vision_vf_ght_prompts);
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting GPT Vision Oct Vf Ght prompts',
            });
        }
    }


    return (
        <Spin
            className='loading-spinner'
            size='large'
            spinning={false}
        >
            <div className="chat-container gpt-vision-vf">
                <Row>
                    {(side === 'right' || side === 'od') &&
                        <Col span={12}>
                            <img src={`${process.env.REACT_APP_PUBLIC_MEDIA_URL}${getFilepathFromBackendMediaUrl(photo)}`} onClick={() => setLightboxOn(true)} />
                            <Care1LightBox
                                field={'gpt-vision-vf'}
                                isOpen={lightboxOn}
                                mainSrc={`${process.env.REACT_APP_PUBLIC_MEDIA_URL}${getFilepathFromBackendMediaUrl(photo)}`}
                                mainSrcThumbnail={`${process.env.REACT_APP_PUBLIC_MEDIA_URL}${getFilepathFromBackendMediaUrl(photo)}`}
                                onCloseRequest={() => setLightboxOn(false)}
                            />
                        </Col>
                    }
                    <Col span={12}>
                        <Row>
                            <Col
                                span={24}>
                                <GptUI
                                    messageHistory={messageHistory}
                                    inputMessage={inputMessage}
                                    setInputMessage={setInputMessage}
                                    sendMessage={sendMessage}
                                    formatMessageContent={formatMessageContent}
                                    isAssistantTyping={isAssistantTyping}
                                    messagesEndRef={messagesEndRef}
                                />
                                <Row>
                                    <Col>
                                        <Button
                                            className={''}
                                            onClick={handleReset}
                                            type="primary"
                                            size="large"
                                        >
                                            Reset
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <GptVfGht
                                    ght={ght}
                                    setGht={setGht}
                                />
                            </Col>
                        </Row>
                    </Col>
                    {(side === 'left' || side === 'os') &&
                        <Col span={12}>
                            <img src={`${process.env.REACT_APP_PUBLIC_MEDIA_URL}${getFilepathFromBackendMediaUrl(photo)}`} onClick={() => setLightboxOn(true)} />
                            <Care1LightBox
                                field={'gpt-vision-vf'}
                                isOpen={lightboxOn}
                                mainSrc={`${process.env.REACT_APP_PUBLIC_MEDIA_URL}${getFilepathFromBackendMediaUrl(photo)}`}
                                mainSrcThumbnail={`${process.env.REACT_APP_PUBLIC_MEDIA_URL}${getFilepathFromBackendMediaUrl(photo)}`}
                                onCloseRequest={() => setLightboxOn(false)}
                            />
                        </Col>
                    }
                </Row>
            </div>
        </Spin>)
}

export default GptVisionVfGht;
