import { useCare1AppSelector, useCare1AppDispatch } from '../../apps/care1-hooks';
import { Radio, Col, Typography, Flex } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { localizedText } from '../../localizedText';
// import '../../../static/css/components/patient-exam-billing-rules.scss';
import PatientExamBillingRulesReferralOmd from './outbound-referral-omd';
import ComponentRadioGroup from '../common/component-radio-group';
import { RadioChangeEvent } from 'antd/lib';

const PatientExamUploadTypeOutboundReferral = ({
    disabled,
}: {
    disabled: boolean;
}) => {
    const { NO_TEXT, YES_TEXT } = localizedText;

    const catRefer = useCare1AppSelector((store) => store.examData.cat_refer);
    const dispatch = useCare1AppDispatch();

    const handleCatReferChange = (e: RadioChangeEvent) => {
        dispatch(setExamDataValue('cat_refer', e.target.value));
        dispatch(setExamDataValue('rr_cataract', e.target.value));
    };

    return (
        <>
            <Col span={24}>
                <Flex justify='space-between' align='center'>
                    <Typography.Text>
                        Is this a cataract referral?
                    </Typography.Text>
                    <ComponentRadioGroup
                        isWarning
                        value={catRefer}
                        handleRadioGroupChange={handleCatReferChange}
                    >
                        <Radio.Button disabled={disabled} value={true}>
                            {YES_TEXT.toUpperCase()}
                        </Radio.Button>
                        <Radio.Button disabled={disabled} value={false}>
                            {NO_TEXT.toUpperCase()}
                        </Radio.Button>
                    </ComponentRadioGroup>
                </Flex>
            </Col>
            <Col span={24}>
                <PatientExamBillingRulesReferralOmd disabled={disabled} />
            </Col>
        </>
    );
};

export default PatientExamUploadTypeOutboundReferral;
