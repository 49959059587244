import { useEffect } from 'react';
import { Form,  Input, Select, FormInstance} from 'antd';
import '../../../static/css/components/od-group-practice-modal.scss';

// Action imports
import { useCare1AppSelector } from '../../apps/care1-hooks';
import { ITechnicianDetail, useGetTechnicianDetailQuery } from '../../services/od-group-practice-api';

type AppProps = {
    onFinish: (values: ITechnicianDetail) => Promise<void>,
    form: FormInstance,
}

const TechnicianForm = ({onFinish, form}: AppProps) => {

    const showTechnicianEditModal = useCare1AppSelector(store => store.doctorSlice.showTechnicianEditModal);
    const odGroupPracticeList = useCare1AppSelector(store => store.options.odgrouppractices);
    const currentTechnicianId = useCare1AppSelector(store => store.doctorSlice.currentTechnician);
    const statuses = useCare1AppSelector(store => store.options.odStatuses);

    const statusOptions = statuses.filter(status => status.value !== 'on_hold');

    const skipQuery = currentTechnicianId && showTechnicianEditModal ? false : true;

    const {data} = useGetTechnicianDetailQuery(currentTechnicianId!, {skip: skipQuery});

    useEffect(() => {
        if (data) {
            const {key, ...formData} = data;
            form.setFieldsValue({
                ...formData,
            });
        }
        
    }, [data, form])


    return (
        <Form
            form={form}
            labelCol={{span:5}}
            wrapperCol={{span: 18}}
            className='od-group-practice-form technician-form'
            colon={false}
            requiredMark={false}
            onFinish={onFinish}
        >
            <Form.Item
                label='First Name'
                name='first_name'
                initialValue={''}>
                <Input />
            </Form.Item>
            
            <Form.Item
                label='Last Name'
                name='last_name'
                initialValue={''}>
                <Input />
            </Form.Item>
            <Form.Item
                label='Email'
                name='email'
                rules={[
                    {required: true},
                    {type: 'email'},
                ]}
                initialValue={''}>
                <Input />
            </Form.Item>
            <Form.Item label='OD Group Practice' name='od_group_practice' initialValue={null}>
                <Select
                    showSearch
                    allowClear
                    optionFilterProp='label'
                    options={odGroupPracticeList}
                />
            </Form.Item>
            <Form.Item label='Status' name='status' initialValue={null}>
                <Select
                    showSearch
                    allowClear
                    optionFilterProp='label'
                    options={statusOptions}
                />
            </Form.Item>
            
        </Form>
           
    );
}

export default TechnicianForm;
