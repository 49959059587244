import React, { useState } from 'react';
import { DropzoneComponent, DropzoneComponentHandlers } from 'react-dropzone-component';
import {
    THUMBNAIL_WIDTH, THUMBNAIL_HEIGHT, EXTRA_IMAGES,
    API_UPLOAD_IMG_URL
} from '../../constants';
import '../../../static/css/components/photo-dropzone.scss';
import { localizedText } from '../../localizedText';
import { dropzoneSlice } from '../../reducers/dropzone-slice';
import { retrievePhotoUrls } from '../../reducers/patient-exam-slice';
import { Modal } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import * as Dropzone from 'dropzone';
import * as ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';

const displayNone = { display: 'none' };


type ComponentProps = {
    disabled: boolean,
    isODReadOnly: boolean,
}

const PhotoDropzoneExtraImage = ({ disabled, isODReadOnly }: ComponentProps) => {

    const { EXTRA_DRAG_DROP_TEXT } = localizedText;
    const dispatch = useCare1AppDispatch();

    const examId = useCare1AppSelector(store => store.examData.id);
    const dropzone = useCare1AppSelector(store => store.dropzones[EXTRA_IMAGES]);
    const csrfToken = useCare1AppSelector(store => store.user.csrfToken);

    const [dropzoneObject, setDropzoneObject] = useState<null | Dropzone>(null);
    const extraImageDropzone = dropzoneSlice[EXTRA_IMAGES];

    const { setUploadProgress, uploadPhotoFailure, uploadPhotoRequest, uploadPhotoSuccess} = extraImageDropzone.actions;

    const acceptFileFormat = 'image/jpeg,image/jpg,image/png,image/gif,image/bmp,application/pdf,application/x-pdf,text/plain,text/rtf,application/rtf,application/msword';

    let componentConfig = { postUrl: `${process.env.REACT_APP_BACKENDURL}${API_UPLOAD_IMG_URL}` };
    if (disabled) {
        componentConfig = { postUrl: 'no-url' };
    }

    const onInit = (dropzone: Dropzone) => {
        setDropzoneObject(dropzone);
    }

    const sendUploadPhotoRequest = (e: DragEvent) => {
        if (!disabled) {

            const file = (e?.dataTransfer?.files.length) ? e.dataTransfer.files[0] : null;

            if (file) {
                try {
                    const ext = file.name.split('.').pop();
                    if (ext) {
                        if (acceptFileFormat.indexOf(ext.toLowerCase()) !== -1) {
                            
                            // Clear the current file. Sorry, yes I know this makes the class stateful, but this is required by the way
                            // the dropzone component works.
                            if (dropzoneObject) {
                                dropzoneObject.removeAllFiles();
                            }
    
                            dispatch(uploadPhotoRequest(file));
                        }
                    }
                }
                catch {
    
                }
            }
            
        }
    }

    const sendUploadPhotoSuccess = (_: Dropzone.DropzoneFile, response: Object) => {
        dispatch(uploadPhotoSuccess());

        // Re-fetch extra images
        dispatch(retrievePhotoUrls(examId!));
    }

    const sendUploadPhotoFailure = (file:Dropzone.DropzoneFile, requestError: string | Error) => {
        try {
            const ext = file.name.split('.').pop();
            if (ext) {
                if (acceptFileFormat.indexOf(ext.toLowerCase()) === -1) {

                    const message = 'This photo is not supported';
                    Modal.error({
                        className: 'info-modal',
                        content: message,
                        title: 'Photo not supported',
                    });      
                    return;
                }
            }
        }
        catch {

        }

        // If this is an error from a request, use the request status.
        let error: string;
        if (requestError) {
            error = `Errors uploading photos, ${requestError}`;
        } else {
            error = 'Errors uploading photos';
        }
        dispatch(uploadPhotoFailure(error));
    }

    const sendUploadProgress = (_: Dropzone.DropzoneFile, percentage: number) => {
        dispatch(setUploadProgress(percentage));
    }

    let eventHandlers: DropzoneComponentHandlers  = {
        init: onInit,
        drop: sendUploadPhotoRequest,
        uploadprogress: sendUploadProgress,
        error: sendUploadPhotoFailure,
        success: sendUploadPhotoSuccess,
    };
    if (disabled) {
        eventHandlers = {
            init: onInit,
        };
    }

    const hasError = dropzone.error !== '';
    const errorMessage = hasError ? dropzone.error : '';
    const inProgress = dropzone.operationRunning;
    const progressPercentage = dropzone.percentage;

    const djsConfig: Dropzone.DropzoneOptions = {
        acceptedFiles: acceptFileFormat,
        clickable: false,
        createImageThumbnails: false,
        withCredentials: true,
        headers: { 'X-CSRFToken': csrfToken },
        maxFiles: 50,
        uploadMultiple: true,
        paramName: 'none',
        params: { exam: examId },
        thumbnailWidth: THUMBNAIL_WIDTH,
        thumbnailHeight: THUMBNAIL_HEIGHT,
        thumbnailMethod: 'contain',
        // Some of the divs in the preview area are needed by the react dropzone component, so they are permanently
        // hidden, but are not used by us.
        previewTemplate: ReactDOMServer.renderToStaticMarkup(
            <div className="dz-preview dz-file-preview">
                <div className="dz-details" style={displayNone}>
                    <div className="dz-filename"><label data-dz-name="true" /></div>
                    <img data-dz-thumbnail alt="" />
                </div>
                <div className="dz-progress" style={displayNone}>
                    <label className="dz-upload" data-dz-uploadprogress="true" />
                </div>
                <div className="dz-error-message" style={displayNone}>
                    <label data-dz-errormessage="true" />
                </div>
            </div>,
        ),
    };

    // Determine the classes used for the picture preview zone.
    // By default nothing is displayed, but in the case of an error, the error is displayed
    const errorMessageClass = classNames({
        'dropzone-error': true,
        'dz-hide': !hasError,
    });

    // If an operation is in progress, the progress bar is shown.
    const progressBarClass = classNames({
        'dropzone-progress-container': true,
        'dz-hide': !inProgress,
    });

    const progressWidth = { width: `${progressPercentage}% ` };

    // The dropzone message (e.g. drog photos here...) is only displayed if there are no photos or errors.
    const dropzoneMessageClass = classNames({
        'dz-message': true,
        'dz-hide': inProgress || hasError,
    });

    return (
        examId ?
        <div>
            {/* @ts-ignore:next-line */}
            <DropzoneComponent
                className={`photo-dropzone extra-image-dropzone
                    ${isODReadOnly || disabled ? 'photo-dropzone-disabled' : ''}`}
                config={componentConfig}
                djsConfig={djsConfig}
                eventHandlers={eventHandlers}
                
            >
                <div className={dropzoneMessageClass}>
                    <div className="dropzone-empty-image"></div>
                    <div className="dropzone-empty-text">{EXTRA_DRAG_DROP_TEXT}</div>
                </div>
                <div className={errorMessageClass}>
                    <label>{errorMessage}</label>
                    <p />
                </div>
                <div className={progressBarClass}>
                    <div className="dropzone-progress" style={progressWidth} />
                </div>
            </DropzoneComponent>
        </div>
        :
        null
    );
}

export default PhotoDropzoneExtraImage;
