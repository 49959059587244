import { useEffect, useState } from 'react';
import { Table, Modal } from 'antd';
import * as Constants from '../../constants';
import '../../../static/css/components/patient-list-table-omdr-re.scss';
import '../../../static/css/shared/patient-list-table.scss';

// Component imports
import ExamStatusTag from '../retina/exam-status-tag';
import PatientDetailsModal from '../retina/patient-details-modal';
import LatestVisitButton from '../retina/button-latest-visit';

// Action imports
import { getOMDRPatientListRequest, IOMDRPatientListItem, setPatientListFieldData } from '../../reducers/patient-list-slice';
import { clearPatientDetailsData, getPatientDetailsRequest, openPatientDetailsModal } from '../../reducers/patient-details-slice';

// Helper imports
import { compareStrings, sortDates } from '../../helpers/sorting';
import { getPreReviewAlertColor } from '../../helpers/patient-list-convert';
import { getOptionsRequest } from '../../reducers/options-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { ColumnsType } from 'antd/es/table';
import { localizedText } from '../../localizedText';
import { toggleNotAuthorizedForExam } from '../../reducers/user-slice';


const PatientListTableOMDRRE = () => {
    const { OF_TEXT, ITEMS_TEXT } = localizedText;

    const tableList = useCare1AppSelector(store => store.patientList.omdrTableList);
    const showDetails = useCare1AppSelector(store => store.patientDetails.visible);
    const notAuthorizedForExam = useCare1AppSelector(store => store.user.notAuthorizedForExam);
    const prereviewAlertOptions = useCare1AppSelector(store => store.options.prereviewAlerts);

    const dispatch = useCare1AppDispatch();

    const [ scrollClass, setScrollClass ] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // Set the default filter values
        dispatch(setPatientListFieldData({ key: 'omdrStatus', value: 'ready' }));
        dispatch(setPatientListFieldData({ key: 'omdrPeriodFilter', value: 'all_time' }));
        dispatch(setPatientListFieldData({ key: 'searchQuery', value: '' }));

        // Make Requests
        dispatch(getOptionsRequest());
        dispatch(getOMDRPatientListRequest());

        if (notAuthorizedForExam) {
            Modal.info({
                className: 'info-modal',
                title: Constants.UNAUTHORIZED_MODAL_TITLE,
                content: Constants.UNAUTHORIZED_MODAL_CONTENT,
                onOk: () => {
                    dispatch(toggleNotAuthorizedForExam(false));
                },
            });
        }

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onTableRowClick = (record: IOMDRPatientListItem) => {
        dispatch(clearPatientDetailsData());
        dispatch(getPatientDetailsRequest(record.key))
            .then(() => dispatch(openPatientDetailsModal()));
    }

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }


    const columns: ColumnsType<IOMDRPatientListItem> = [{
        className: 'status-column',
        title: 'Status',
        dataIndex: 'status',
        sorter: (a, b) => compareStrings(a.status, b.status),
        render: (text) => <ExamStatusTag className="patient-exam-status-tag" examStatusKey={text} />,
    }, {
        className: 'last-name-column',
        title: 'Last Name',
        dataIndex: 'last_name',
        sorter: (a, b) => compareStrings(a.last_name, b.last_name),
    }, {
        className: 'first-name-column',
        title: 'First Name',
        dataIndex: 'first_name',
        sorter: (a, b) => compareStrings(a.first_name, b.first_name),
    }, {
        className: 'dob-column',
        title: 'DOB',
        dataIndex: 'dob',
        sorter: (a, b) => {
            const firstDate = a.dob ? a.dob : '';
            const secondDate = b.dob ? b.dob : '';
            return sortDates(firstDate, secondDate);
        },
    }, {
        className: 'od-column',
        title: 'OD',
        dataIndex: 'od',
        sorter: (a, b) => compareStrings(a.od, b.od),
    }, {
        className: 'complaint-column',
        title: 'Chief Complaint',
        dataIndex: 'chief_complaint',
        sorter: (a, b) => compareStrings(a.chief_complaint, b.chief_complaint),
    }, {
        className: 'latest-visit-column',
        title: 'Latest Visit',
        dataIndex: 'latest_visit',
        sorter: (a, b) => {
            const firstDate = a.latest_visit ? a.latest_visit : '';
            const secondDate = b.latest_visit ? b.latest_visit : '';
            return sortDates(firstDate, secondDate);
        },
    }, {
        className: 'pre-review-alerts-column',
        title: 'Comments',
        dataIndex: 'pr_alerts',
        render: (data) => {
            return data && data.map((alert: string, index: number) => {

                // Get the prereview alert color and assign it as a class name
                const color = getPreReviewAlertColor(alert, prereviewAlertOptions);

                return (
                    <span
                        className={`${color}`}
                        key={`pre_review_alert_${index}`}
                    >
                        {/* add comma between each alert */}
                        {`${index ? ', ' : ''}${alert}`}
                    </span>
                )
            })
        },
    }, {
        className: 'visits-column',
        title: '# Visits',
        dataIndex: 'visits',
        sorter: (a, b) => a.visits - b.visits,
    }, {
        className: 'view-latest-visit-column',
        title: 'Exam',
        dataIndex: 'latest_visit_id',
        render: (text) => {
            if (text) {
                return <LatestVisitButton className="button-latest-visit" buttonText={'Open Latest Visit'}
                    visitID={text} newTab={true} />;
            }
        },
    }];

    return (
        <div className="patient-list-table patient-list-table-omdr user-is-re">
            <div className={scrollClass}>
                <Table
                    columns={columns}
                    scroll={{ x: true }}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 30,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    dataSource={tableList}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                onTableRowClick(record);
                            },
                        };
                    }}
                />
                {showDetails && <PatientDetailsModal />}
            </div>
        </div>
    );
}

export default PatientListTableOMDRRE;