import { Col, Radio, Row, Select, Tooltip, Typography, } from 'antd';
import styles from '../../../static/css/components/report-toggle.module.scss';
import {
    useCare1AppDispatch,
    useCare1AppSelector,
} from '../../apps/care1-hooks';
import { useGetOdRequestedOmdOptionsQuery } from '../../services/doctor-api';
import { isOmdc } from '../../reducers/doctor-slice';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import ComponentRadioGroup from '../common/component-radio-group';
import { RadioChangeEvent } from 'antd/lib';

const PatientExamReportToggle = ({ disabled }: { disabled: boolean }) => {
    const examId = useCare1AppSelector((store) => store.examData.id);
    const highPriority = useCare1AppSelector(
        (store) => store.examData.is_urgent
    );
    const odRequestedOmd = useCare1AppSelector(
        (store) => store.examData.od_requested_omd
    );
    const odWantsOmdReport = useCare1AppSelector(
        (store) => store.examData.od_wants_omd_report
    );
    const omds = useCare1AppSelector((store) => store.options.omds);

    const dispatch = useCare1AppDispatch();

    const { data } = useGetOdRequestedOmdOptionsQuery(examId!);

    const highPriorityAiText =
        'High priority cases will always receive an OMD Consult ';

    const message = highPriority
        ? 'Patient chart will be sent to the chosen OMD or next available OMD for review.'
        : isOmdc(omds, odRequestedOmd)
        ? 'If the requested OMD is not available in the next 7 days, a review from the next available OMD will be provided in the meantime. The requested OMD will still review when they have time.'
        : '';
    
    const handleReportTypeChange = (e: RadioChangeEvent) => {
        dispatch(setExamDataValue('od_wants_omd_report', e.target.value));
        dispatch(setExamDataValue('od_requested_omd', null));
        dispatch(setExamDataValue('is_urgent', false));
    }

    return (
        <>
            <Row
                justify='space-between'
                align='middle'
                className={styles.reportToggle}
            >
                <Col span={13}>
                    <Typography.Text>Report Type</Typography.Text>
                    <ComponentRadioGroup
                        value={odWantsOmdReport}
                        handleRadioGroupChange={handleReportTypeChange}
                        className={styles.reportType}
                        isWarning={highPriority}
                    >
                        <Tooltip
                            placement='topLeft'
                            title={highPriority ?
                                highPriorityAiText :
                                'An AI Report will provide only Care1 AI services and may include an Ophthalmology review as validation of the Care1 AI.'
                            }
                        >
                            <Radio.Button
                                disabled={disabled || highPriority}
                                value={false}
                                style={{minWidth: '80px', textAlign: 'center'}}
                            >
                                AI
                            </Radio.Button>
                        </Tooltip>
                        <Tooltip
                            placement='topLeft'
                            title={'An OMD + AI Report will provide an Ophthalmology consult along with Care1’s AI services.'}
                        >
                            <Radio.Button
                                disabled={disabled}
                                value={true}
                            >
                                AI + OMD
                            </Radio.Button>
                        </Tooltip>
                    </ComponentRadioGroup>
                </Col>
                <Col span={10}>
                    <Select
                        placeholder='Next Available OMD'
                        options={data}
                        optionFilterProp='label'
                        value={odRequestedOmd}
                        allowClear
                        disabled={disabled || odWantsOmdReport === false}
                        onChange={(value) =>
                            dispatch(
                                setExamDataValue('od_requested_omd', value)
                            )
                        }
                    />
                </Col>
                <Col span={24}>
                    <Typography.Text className={styles.odRequestedOmdMessage}>
                        {message}
                    </Typography.Text>
                </Col>
            </Row>
        </>
    );
};

export default PatientExamReportToggle;
