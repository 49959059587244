import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Modal } from 'antd';
import { getCsrfToken } from "../helpers/utilities";
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";


export interface IGptAISnapshotPrompt {
    gpt_ai_snapshot_disease_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    gpt_ai_snapshot_disease_prompts: string,
    gpt_ai_snapshot_disease_images: string[],
    gpt_ai_snapshot_ocular_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    gpt_ai_snapshot_ocular_prompts: string,
    gpt_ai_snapshot_ocular_images: string[],
    gpt_ai_snapshot_assessment_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    gpt_ai_snapshot_assessment_prompts: string,
    gpt_ai_snapshot_assessment_images: string[],
    gpt_ai_snapshot_systemic_treatment_important_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    gpt_ai_snapshot_systemic_treatment_important_prompts: string,
    gpt_ai_snapshot_systemic_treatment_important_images: string[],
    gpt_ai_snapshot_band_impressions: string[],
    gpt_ai_snapshot_band_od_assessment: string,
    gpt_ai_snapshot_band_od_question: string,
    gpt_chat_ai_snapshot_disease_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    gpt_chat_ai_snapshot_disease_prompts: string,
    gpt_chat_ai_snapshot_ocular_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    gpt_chat_ai_snapshot_ocular_prompts: string,
    gpt_chat_ai_snapshot_assessment_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    gpt_chat_ai_snapshot_assessment_prompts: string,
    gpt_chat_ai_snapshot_systemic_treatment_important_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    gpt_chat_ai_snapshot_systemic_treatment_important_prompts: string,
    gpt_chat_ai_snapshot_band_impressions: string[],
    gpt_chat_ai_snapshot_band_od_assessment: string,
    gpt_chat_ai_snapshot_band_od_question: string,
}

const initialState: IGptAISnapshotPrompt = {
    gpt_ai_snapshot_disease_status: 'idle',
    gpt_ai_snapshot_disease_prompts: '',
    gpt_ai_snapshot_disease_images: [],
    gpt_ai_snapshot_ocular_status: 'idle',
    gpt_ai_snapshot_ocular_prompts: '',
    gpt_ai_snapshot_ocular_images: [],
    gpt_ai_snapshot_assessment_status: 'idle',
    gpt_ai_snapshot_assessment_prompts: '',
    gpt_ai_snapshot_assessment_images: [],
    gpt_ai_snapshot_systemic_treatment_important_status: 'idle',
    gpt_ai_snapshot_systemic_treatment_important_prompts: '',
    gpt_ai_snapshot_systemic_treatment_important_images: [],
    gpt_ai_snapshot_band_impressions: [],
    gpt_ai_snapshot_band_od_assessment: '',
    gpt_ai_snapshot_band_od_question: '',
    gpt_chat_ai_snapshot_disease_status: 'idle',
    gpt_chat_ai_snapshot_disease_prompts: '',
    gpt_chat_ai_snapshot_ocular_status: 'idle',
    gpt_chat_ai_snapshot_ocular_prompts: '',
    gpt_chat_ai_snapshot_assessment_status: 'idle',
    gpt_chat_ai_snapshot_assessment_prompts: '',
    gpt_chat_ai_snapshot_systemic_treatment_important_status: 'idle',
    gpt_chat_ai_snapshot_systemic_treatment_important_prompts: '',
    gpt_chat_ai_snapshot_band_impressions: [],
    gpt_chat_ai_snapshot_band_od_assessment: '',
    gpt_chat_ai_snapshot_band_od_question: '',
};

export const fetchAutoGptAISnapshotDiseaseResponse = createAsyncThunk(
    'gptVision/fetchAutoGptAISnapshotDiseaseResponse',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_ai_snapshot_disease_response/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptAISnapshotDiseasePrompt = createAsyncThunk(
    'gptVision/fetchGptAISnapshotDiseasePrompt',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_ai_snapshot_disease_prompts/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptAISnapshotDiseaseImages = createAsyncThunk(
    'gptVision/fetchGptAISnapshotDiseaseImages',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_ai_snapshot_disease_images/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchAutoGptAISnapshotOcularResponse = createAsyncThunk(
    'gptVision/fetchAutoGptAISnapshotOcularResponse',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_ai_snapshot_ocular_response/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptAISnapshotOcularPrompt = createAsyncThunk(
    'gptVision/fetchGptAISnapshotOcularPrompt',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_ai_snapshot_ocular_prompts/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptAISnapshotOcularImages = createAsyncThunk(
    'gptVision/fetchGptAISnapshotOcularImages',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_ai_snapshot_ocular_images/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchAutoGptAISnapshotAssessmentResponse = createAsyncThunk(
    'gptVision/fetchAutoGptAISnapshotAssessmentResponse',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_ai_snapshot_assessment_response/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptAISnapshotAssessmentPrompt = createAsyncThunk(
    'gptVision/fetchGptAISnapshotAssessmentPrompt',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_ai_snapshot_assessment_prompts/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptAISnapshotAssessmentImages = createAsyncThunk(
    'gptVision/fetchGptAISnapshotAssessmentImages',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_ai_snapshot_assessment_images/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchAutoGptAISnapshotSystemicTreatmentImportantResponse = createAsyncThunk(
    'gptVision/fetchAutoGptAISnapshotSystemicTreatmentImportantResponse',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_ai_snapshot_systemic_treatment_important_response/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptAISnapshotSystemicTreatmentImportantPrompt = createAsyncThunk(
    'gptVision/fetchGptAISnapshotSystemicTreatmentImportantPrompt',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_ai_snapshot_systemic_treatment_important_prompts/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptAISnapshotSystemicTreatmentImportantImages = createAsyncThunk(
    'gptVision/fetchGptAISnapshotSystemicTreatmentImportantImages',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_ai_snapshot_systemic_treatment_important_images/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptChatAISnapshotDiseaseResponse = createAsyncThunk(
    'gptVision/fetchGptChatAISnapshotDiseaseResponse',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_chat_ai_snapshot_disease_response/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptChatAISnapshotDiseasePrompt = createAsyncThunk(
    'gptVision/fetchGptChatAISnapshotDiseasePrompt',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_chat_ai_snapshot_disease_prompts/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptChatAISnapshotOcularResponse = createAsyncThunk(
    'gptVision/fetchGptChatAISnapshotOcularResponse',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_chat_ai_snapshot_ocular_response/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptChatAISnapshotOcularPrompt = createAsyncThunk(
    'gptVision/fetchGptChatAISnapshotOcularPrompt',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_chat_ai_snapshot_ocular_prompts/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptChatAISnapshotAssessmentResponse = createAsyncThunk(
    'gptVision/fetchGptChatAISnapshotAssessmentResponse',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_chat_ai_snapshot_assessment_response/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptChatAISnapshotAssessmentPrompt = createAsyncThunk(
    'gptVision/fetchGptChatAISnapshotAssessmentPrompt',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_chat_ai_snapshot_assessment_prompts/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptChatAISnapshotSystemicTreatmentImportantResponse = createAsyncThunk(
    'gptVision/fetchGptChatAISnapshotSystemicTreatmentImportantResponse',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_chat_ai_snapshot_systemic_treatment_important_response/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const fetchGptChatAISnapshotSystemicTreatmentImportantPrompt = createAsyncThunk(
    'gptVision/fetchGptChatAISnapshotSystemicTreatmentImportantPrompt',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_chat_ai_snapshot_systemic_treatment_important_prompts/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const retrieveGptAiSnapshotBandValues = createAsyncThunk(
    'gptVision/retrieveGptAiSnapshotBandValues',
    async (id: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: { csrfToken: string }};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_ai_snapshot_band_values/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const retrieveGptChatAiSnapshotBandValues = createAsyncThunk(
    'gptVision/retrieveGptChatAiSnapshotBandValues',
    async (id: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: { csrfToken: string }};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/gpt_chat_ai_snapshot_band_values/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const gptAISnapshotSlice = createSlice({
    name: 'gptAISnapshot',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setAISnapshotDataValue: (state, action) => {
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                dirty: true,
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGptAISnapshotDiseasePrompt.pending, (state) => {
                state.gpt_ai_snapshot_disease_status = 'loading';
                state.gpt_ai_snapshot_disease_prompts = '';
            })
            .addCase(fetchGptAISnapshotDiseasePrompt.fulfilled, (state, action) => {
                state.gpt_ai_snapshot_disease_status = 'succeeded';
                state.gpt_ai_snapshot_disease_prompts = action.payload.gpt_ai_snapshot_disease_prompts;
            })
            .addCase(fetchGptAISnapshotDiseasePrompt.rejected, (state) => {
                state.gpt_ai_snapshot_disease_status = 'failed';
                state.gpt_ai_snapshot_disease_prompts = '';
            })
            .addCase(fetchGptAISnapshotDiseaseImages.pending, (state) => {
                state.gpt_ai_snapshot_disease_status = 'loading';
                state.gpt_ai_snapshot_disease_prompts = '';
            })
            .addCase(fetchGptAISnapshotDiseaseImages.fulfilled, (state, action) => {
                state.gpt_ai_snapshot_disease_status = 'succeeded';
                state.gpt_ai_snapshot_disease_images = action.payload.gpt_ai_snapshot_disease_images;
            })
            .addCase(fetchGptAISnapshotDiseaseImages.rejected, (state) => {
                state.gpt_ai_snapshot_disease_status = 'failed';
                state.gpt_ai_snapshot_disease_prompts = '';
            })
            .addCase(fetchGptAISnapshotOcularPrompt.pending, (state) => {
                state.gpt_ai_snapshot_ocular_status = 'loading';
                state.gpt_ai_snapshot_ocular_prompts = '';
            })
            .addCase(fetchGptAISnapshotOcularPrompt.fulfilled, (state, action) => {
                state.gpt_ai_snapshot_ocular_status = 'succeeded';
                state.gpt_ai_snapshot_ocular_prompts = action.payload.gpt_ai_snapshot_ocular_prompts;
            })
            .addCase(fetchGptAISnapshotOcularPrompt.rejected, (state) => {
                state.gpt_ai_snapshot_ocular_status = 'failed';
                state.gpt_ai_snapshot_ocular_prompts = '';
            })
            .addCase(fetchGptAISnapshotOcularImages.pending, (state) => {
                state.gpt_ai_snapshot_ocular_status = 'loading';
                state.gpt_ai_snapshot_ocular_prompts = '';
            })
            .addCase(fetchGptAISnapshotOcularImages.fulfilled, (state, action) => {
                state.gpt_ai_snapshot_ocular_status = 'succeeded';
                state.gpt_ai_snapshot_ocular_images = action.payload.gpt_ai_snapshot_ocular_images;
            })
            .addCase(fetchGptAISnapshotOcularImages.rejected, (state) => {
                state.gpt_ai_snapshot_ocular_status = 'failed';
                state.gpt_ai_snapshot_ocular_prompts = '';
            })
            .addCase(fetchGptAISnapshotAssessmentPrompt.pending, (state) => {
                state.gpt_ai_snapshot_assessment_status = 'loading';
                state.gpt_ai_snapshot_assessment_prompts = '';
            })
            .addCase(fetchGptAISnapshotAssessmentPrompt.fulfilled, (state, action) => {
                state.gpt_ai_snapshot_assessment_status = 'succeeded';
                state.gpt_ai_snapshot_assessment_prompts = action.payload.gpt_ai_snapshot_assessment_prompts;
            })
            .addCase(fetchGptAISnapshotAssessmentPrompt.rejected, (state) => {
                state.gpt_ai_snapshot_assessment_status = 'failed';
                state.gpt_ai_snapshot_assessment_prompts = '';
            })
            .addCase(fetchGptAISnapshotAssessmentImages.pending, (state) => {
                state.gpt_ai_snapshot_assessment_status = 'loading';
                state.gpt_ai_snapshot_assessment_prompts = '';
            })
            .addCase(fetchGptAISnapshotAssessmentImages.fulfilled, (state, action) => {
                state.gpt_ai_snapshot_assessment_status = 'succeeded';
                state.gpt_ai_snapshot_assessment_images = action.payload.gpt_ai_snapshot_assessment_images;
            })
            .addCase(fetchGptAISnapshotAssessmentImages.rejected, (state) => {
                state.gpt_ai_snapshot_assessment_status = 'failed';
                state.gpt_ai_snapshot_assessment_prompts = '';
            })
            .addCase(fetchGptAISnapshotSystemicTreatmentImportantPrompt.pending, (state) => {
                state.gpt_ai_snapshot_systemic_treatment_important_status = 'loading';
                state.gpt_ai_snapshot_systemic_treatment_important_prompts = '';
            })
            .addCase(fetchGptAISnapshotSystemicTreatmentImportantPrompt.fulfilled, (state, action) => {
                state.gpt_ai_snapshot_systemic_treatment_important_status = 'succeeded';
                state.gpt_ai_snapshot_systemic_treatment_important_prompts = action.payload.gpt_ai_snapshot_systemic_treatment_important_prompts;
            })
            .addCase(fetchGptAISnapshotSystemicTreatmentImportantPrompt.rejected, (state) => {
                state.gpt_ai_snapshot_systemic_treatment_important_status = 'failed';
                state.gpt_ai_snapshot_systemic_treatment_important_prompts = '';
            })
            .addCase(fetchGptAISnapshotSystemicTreatmentImportantImages.pending, (state) => {
                state.gpt_ai_snapshot_systemic_treatment_important_status = 'loading';
                state.gpt_ai_snapshot_systemic_treatment_important_prompts = '';
            })
            .addCase(fetchGptAISnapshotSystemicTreatmentImportantImages.fulfilled, (state, action) => {
                state.gpt_ai_snapshot_systemic_treatment_important_status = 'succeeded';
                state.gpt_ai_snapshot_systemic_treatment_important_images = action.payload.gpt_ai_snapshot_systemic_treatment_important_images;
            })
            .addCase(fetchGptAISnapshotSystemicTreatmentImportantImages.rejected, (state) => {
                state.gpt_ai_snapshot_systemic_treatment_important_status = 'failed';
                state.gpt_ai_snapshot_systemic_treatment_important_prompts = '';
            })
            .addCase(fetchGptChatAISnapshotDiseasePrompt.pending, (state) => {
                state.gpt_chat_ai_snapshot_disease_status = 'loading';
                state.gpt_chat_ai_snapshot_disease_prompts = '';
            })
            .addCase(fetchGptChatAISnapshotDiseasePrompt.fulfilled, (state, action) => {
                state.gpt_chat_ai_snapshot_disease_status = 'succeeded';
                state.gpt_chat_ai_snapshot_disease_prompts = action.payload.gpt_chat_ai_snapshot_disease_prompts;
            })
            .addCase(fetchGptChatAISnapshotDiseasePrompt.rejected, (state) => {
                state.gpt_chat_ai_snapshot_disease_status = 'failed';
                state.gpt_chat_ai_snapshot_disease_prompts = '';
            })
            .addCase(fetchGptChatAISnapshotOcularPrompt.pending, (state) => {
                state.gpt_chat_ai_snapshot_ocular_status = 'loading';
                state.gpt_chat_ai_snapshot_ocular_prompts = '';
            })
            .addCase(fetchGptChatAISnapshotOcularPrompt.fulfilled, (state, action) => {
                state.gpt_chat_ai_snapshot_ocular_status = 'succeeded';
                state.gpt_chat_ai_snapshot_ocular_prompts = action.payload.gpt_chat_ai_snapshot_ocular_prompts;
            })
            .addCase(fetchGptChatAISnapshotOcularPrompt.rejected, (state) => {
                state.gpt_chat_ai_snapshot_ocular_status = 'failed';
                state.gpt_chat_ai_snapshot_ocular_prompts = '';
            })
            .addCase(fetchGptChatAISnapshotAssessmentPrompt.pending, (state) => {
                state.gpt_chat_ai_snapshot_assessment_status = 'loading';
                state.gpt_chat_ai_snapshot_assessment_prompts = '';
            })
            .addCase(fetchGptChatAISnapshotAssessmentPrompt.fulfilled, (state, action) => {
                state.gpt_chat_ai_snapshot_assessment_status = 'succeeded';
                state.gpt_chat_ai_snapshot_assessment_prompts = action.payload.gpt_chat_ai_snapshot_assessment_prompts;
            })
            .addCase(fetchGptChatAISnapshotAssessmentPrompt.rejected, (state) => {
                state.gpt_chat_ai_snapshot_assessment_status = 'failed';
                state.gpt_chat_ai_snapshot_assessment_prompts = '';
            })
            .addCase(fetchGptChatAISnapshotSystemicTreatmentImportantPrompt.pending, (state) => {
                state.gpt_chat_ai_snapshot_systemic_treatment_important_status = 'loading';
                state.gpt_chat_ai_snapshot_systemic_treatment_important_prompts = '';
            })
            .addCase(fetchGptChatAISnapshotSystemicTreatmentImportantPrompt.fulfilled, (state, action) => {
                state.gpt_chat_ai_snapshot_systemic_treatment_important_status = 'succeeded';
                state.gpt_chat_ai_snapshot_systemic_treatment_important_prompts = action.payload.gpt_chat_ai_snapshot_systemic_treatment_important_prompts;
            })
            .addCase(fetchGptChatAISnapshotSystemicTreatmentImportantPrompt.rejected, (state) => {
                state.gpt_chat_ai_snapshot_systemic_treatment_important_status = 'failed';
                state.gpt_chat_ai_snapshot_systemic_treatment_important_prompts = '';
            })
            builder.addCase(retrieveGptAiSnapshotBandValues.pending, (state) => {
                state.gpt_ai_snapshot_disease_status = 'loading';
            });
            builder.addCase(retrieveGptAiSnapshotBandValues.fulfilled, (state, action) => {
                const data = action.payload;
                state.gpt_ai_snapshot_disease_status = 'succeeded';
                state.gpt_ai_snapshot_band_impressions = data.gpt_ai_snapshot_band_impressions;
                state.gpt_ai_snapshot_band_od_assessment = data.gpt_ai_snapshot_band_od_assessment;
                state.gpt_ai_snapshot_band_od_question = data.gpt_ai_snapshot_band_od_question;
            });
            builder.addCase(retrieveGptAiSnapshotBandValues.rejected, (state, action) => {
                state.gpt_ai_snapshot_disease_status = 'failed'
                Modal.error({
                    className: 'info-modal',
                    title: `Errors getting GPT AI snapshot data. ${action.payload}`,
                })
            });
            builder.addCase(retrieveGptChatAiSnapshotBandValues.pending, (state) => {
                state.gpt_chat_ai_snapshot_disease_status = 'loading';
            });
            builder.addCase(retrieveGptChatAiSnapshotBandValues.fulfilled, (state, action) => {
                const data = action.payload;
                state.gpt_chat_ai_snapshot_disease_status = 'succeeded';
                state.gpt_chat_ai_snapshot_band_impressions = data.gpt_chat_ai_snapshot_band_impressions;
                state.gpt_chat_ai_snapshot_band_od_assessment = data.gpt_chat_ai_snapshot_band_od_assessment;
                state.gpt_chat_ai_snapshot_band_od_question = data.gpt_chat_ai_snapshot_band_od_question;
            });
            builder.addCase(retrieveGptChatAiSnapshotBandValues.rejected, (state, action) => {
                state.gpt_chat_ai_snapshot_disease_status = 'failed'
                Modal.error({
                    className: 'info-modal',
                    title: `Errors getting GPT Chat AI snapshot data. ${action.payload}`,
                })
            });
    },
});

export const { setAISnapshotDataValue } = gptAISnapshotSlice.actions;

export default gptAISnapshotSlice.reducer;
