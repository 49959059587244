import { Fragment } from 'react';
import { Card, Row, Tag, Tooltip } from 'antd';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import { getTxAlgo3Request, resetTxAlgo3 } from '../../reducers/txalgo3-slice';
import { InfoCircleOutlined } from '@ant-design/icons';
import { setOverrideActions, setPreReviewDataValue } from '../../reducers/pre-review-slice';
import { localizedText } from '../../localizedText';
import { useSaveExamDataRrGlcSuspectRequestMutation } from '../../services/exam-api';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/patient-exam-omd-reviewer-selectors.scss';

const { CheckableTag } = Tag;

const PatientExamOMDReviewerSelectors = () => {
    const { REVIEWER_SELECTORS_TEXT, OPTION_ALLOWS_GLCSUS } = localizedText;
    const reviewSelectors = useCare1AppSelector(store => store.options.reviewSelectors);
    const selectedOmdNoteOptions = useCare1AppSelector(store => store.examData.selected_omd_note_options);
    const isGlcSus = useCare1AppSelector(store => store.examData.rr_glc_suspect);
    const odDiagnosedGlaucoma = useCare1AppSelector(store => store.examData.odDiagnosedGlaucoma);
    const examId = useCare1AppSelector(store => store.examData.id);
    const isAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const omdrSetGlcSusDiagnosis = useCare1AppSelector(store => store.examData.omdrSetGlcSusDiagnosis);

    const [saveExamDataRrGlcSuspectRequest] = useSaveExamDataRrGlcSuspectRequestMutation();

    const dispatch = useCare1AppDispatch();

    const onReviewSelectorChange = (checked: boolean, id: number, name: string) => {
        if(checked) {
            dispatch(setExamDataValue('selected_omd_note_options', [...selectedOmdNoteOptions, id]));
        } else {
            dispatch(setExamDataValue('selected_omd_note_options', selectedOmdNoteOptions.filter(option => option !== id)));
        }
        isAdmin && name !== 'Reassuring Exam' && dispatch(setOverrideActions([name, checked]));
        isAdmin && name === 'Reassuring Exam' && dispatch(setOverrideActions(['N EXAM', checked]));
        // On a change of OMDR note template checkboxes, the pre-review data should be dirtied.
        isAdmin && dispatch(setPreReviewDataValue({key: 'isDirty', value: true}));
    }

    // The OMDR needs to be able to add a Glaucoma diagnosis to a patient that the OD did not diagnose as Glaucoma
    // The OMDR also needs to deselect in case of accidentally selecting and adding the Glaucoma diagnosis
    const addGlcSusDiagnosisToPatient = (checked: boolean) => {
        if (!isAdmin && examId) {
            if(checked){
                dispatch(setExamDataValue('is_glaucoma', true));
                dispatch(setExamDataValue('rr_glc_suspect', true));
                // Set a special flag so that the rr_glc_suspect can be set again in the saveExamData function
                // since the diagnosis form is not available here.
                dispatch(setExamDataValue('omdrSetGlcSusDiagnosis', true))

                // Save the value to the exam on the backend
                saveExamDataRrGlcSuspectRequest({examId, rrGlcSuspect: 'true'})

                // Get the txAlgo3 data after the backend rr_glc_suspect has been set to true
                .then(() => {
                    dispatch(resetTxAlgo3());
                    dispatch(getTxAlgo3Request(examId));
                })
            } else {
                // This block handles the case where the OMDR has selected Glaucoma Diagnosis, but then decided to deselect.
                dispatch(setExamDataValue('is_glaucoma', false));
                dispatch(setExamDataValue('rr_glc_suspect', false));
                dispatch(setExamDataValue('omdrSetGlcSusDiagnosis', false));

                // Se the backend glc-sus back to false
                saveExamDataRrGlcSuspectRequest({examId, rrGlcSuspect: 'false'});
                dispatch(resetTxAlgo3());
            }
        }
    }

    return (
        <Card className={'component-input-card omd-reviewer-selectors'} bordered={false}>
            <Row>
                <div className="heading">{REVIEWER_SELECTORS_TEXT}</div>
                {/* If the exam is not a Glaucoma patient then display the option for
                    the user to select the IC UI view */}
                { (((!odDiagnosedGlaucoma || omdrSetGlcSusDiagnosis) && !isAdmin) || isAdmin)  &&
                    <Fragment>
                        <CheckableTag
                            data-testid='add-glcsus-tag'
                            className={`add-glcsus-diagnosis ${isAdmin ? 'glcsus-tag-disabled' : ''}`}
                            checked={isGlcSus}
                            onChange={(checked) => addGlcSusDiagnosisToPatient(checked)}
                        >
                            {`Suspicious for Glaucoma`}
                        </CheckableTag>
                        <Tooltip placement='topLeft' title={OPTION_ALLOWS_GLCSUS}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Fragment>
                }
            </Row>
            <div className='component-body'>
                {
                    reviewSelectors.map(({id, name}) => (
                        <CheckableTag
                            key={id}
                            className={name.toLowerCase().replace(' ', '-')}
                            checked={selectedOmdNoteOptions.indexOf(id) > -1}
                            onChange={(checked) => onReviewSelectorChange(checked, id, name)}
                        >
                            {name}
                        </CheckableTag>
                    ))
                }
            </div>
        </Card>
    );

}

export default PatientExamOMDReviewerSelectors;