import { getGlcTypeColor } from "./patient-list-convert";
import { IOMDRPatientListItem } from '../reducers/patient-list-slice';

interface IOMDRPatientListItemIOP {
    os_iop: string,
    od_alert: boolean,
    od_iop: string,
    os_alert: boolean
};

export function compareStrings(a: string | null | undefined, b: string | null | undefined) {

    // Sort null values to the end
    if(a === null || a === undefined) {
        return 1;
    } else if(b === null || b === undefined)  {
        return -1;
    }

    // Remove extra spaces and sort as lower case
    const first = a.trim().toLowerCase();
    const second = b.trim().toLowerCase();

    // Sort empty values to the end
    if(first === "") {
        return 1;
    } else if(second === "") {
        return -1;
    } else {
        return first < second ? -1 : first > second ? 1 : 0;
    }
}

export function sortDates(a: string, b: string) {
    // Assume that the incoming values are strings containing dates. Empty or null dates are always considered higher,
    // so are put at the end when sorted ascending. Otherwise earlier dates are sorted to lower positions.
    const firstDate = a ? new Date(a) : null;
    const secondDate = b ? new Date(b) : null;

    // When either date is null, pick the other one as the earlier value.
    if (firstDate == null) {
        return 1;
    } else if (secondDate == null) {
        return -1;
    }

    return (firstDate.valueOf() - secondDate.valueOf());
}

export const sortOMDRReferralLetterPatientList = (patientList:IOMDRPatientListItem[], omdrStatus: string) => {
    const rluList = patientList.filter(patient => patient.isReferralLetterUploadPei);
    const nonRluList = patientList.filter(patient => !patient.isReferralLetterUploadPei);

    // sort rluList and nonRluList and then combine them
    const sortedRluList = sortOMDRICPatientList(rluList, omdrStatus);
    const sortedNonRluList = sortOMDRICPatientList(nonRluList, omdrStatus);

    // referral letter upload exams should appear before all of the non referral letter upload exams
    const sortedList = [...sortedRluList, ...sortedNonRluList];
    return sortedList;
}

// Sorting algorithm for the OMDR IC patient list page.
// The function takes in a patient list and status and returns the sorted list.
// All lists are sorted by Alerts, and then by different metrics based on the status filter.
export const sortOMDRICPatientList = (patientList: IOMDRPatientListItem[], omdrStatus: string) => {

    const getAlertPriorities = (a: IOMDRPatientListItem, b: IOMDRPatientListItem) => {
        // All lists are sorted by
        // 1. Alerts
        // 2. PR Alerts
        if(getAlertPriorityIndex(b.alerts) > getAlertPriorityIndex(a.alerts)){
            return 1;
        } else if (getAlertPriorityIndex(b.alerts) < getAlertPriorityIndex(a.alerts)){
            return -1;
        } else if(getPRAlertPriorityIndex(b.pr_alerts) > getPRAlertPriorityIndex(a.pr_alerts)){
            return 1;
        } else if (getPRAlertPriorityIndex(b.pr_alerts) < getPRAlertPriorityIndex(a.pr_alerts)){
            return -1;
        }

        return 0;
    }

    let sortedList = [...patientList]

    sortedList.sort((a, b) => {

        if (omdrStatus === 'retina1' || omdrStatus === 'retina2'){
            // 1. Sort by Alerts
            // 2. Sort by PR Alerts
            // 3. Sort by Referral Type (Chief Complaint)
            // 4. New RQ not in Legacy: Sort by exam ascending so that the oldest exams are reviewed before the newest
            // exams (Note: Legacy does not sort beyond Referral Type.)

            const alertValue = getAlertPriorities(a, b);
            if(alertValue !== 0){
                return alertValue
            } else if(a.chief_complaint > b.chief_complaint) {
                return 1;
            } else if(a.chief_complaint < b.chief_complaint) {
                return -1;
            } else if (a.latest_visit_id > b.latest_visit_id){
                return 1;
            } else if (a.latest_visit_id < b.latest_visit_id){
                return -1;
            }
        } else if (omdrStatus === 'glc_p' || omdrStatus === 'glc_bo' || omdrStatus === 'glc_bt'){
            // 1. Sort by Alerts
            // 2. Sort by PR Alerts
            // 3. Sort by IOP
            // 4. Sort bu bDISC

            const alertValue = getAlertPriorities(a, b);
            if(alertValue !== 0){
                return alertValue
            } else if(getGLCClassPriorityIndex(a.glc_class.od_iop, a.glc_class.od_iop_aim)
                > getGLCClassPriorityIndex(b.glc_class.od_iop, b.glc_class.od_iop_aim)){
                return 1;
            } else if (getGLCClassPriorityIndex(a.glc_class.od_iop, a.glc_class.od_iop_aim)
                < getGLCClassPriorityIndex(b.glc_class.od_iop, b.glc_class.od_iop_aim)){
                return -1;
            } else if(getIOPPiorityIndex(b.iop, true) > getIOPPiorityIndex(a.iop, true)){
                return 1;
            } else if(getIOPPiorityIndex(b.iop, true) < getIOPPiorityIndex(a.iop, true)){
                return -1;
            } else if(a.b_disc.length > 0 && b.b_disc.length > 0 && getbDISCPriorityIndex(b.b_disc[0].current, b.b_disc[1].current)
                > getbDISCPriorityIndex(a.b_disc[0].current, a.b_disc[1].current)){
                return 1;
            } else if(a.b_disc.length > 0 && b.b_disc.length > 0 && getbDISCPriorityIndex(b.b_disc[0].current, b.b_disc[1].current)
                < getbDISCPriorityIndex(a.b_disc[0].current, a.b_disc[1].current)){
                return -1;
            } else if(b.b_disc.length > 0) {
                return 1;
            } else if(a.b_disc.length > 0) {
                return -1;
            }
        } else if(omdrStatus === 'special_attention'){
            // 1. Sort by Alerts
            // 2. Sort by PR Alerts
            // 3. Sort by Is OD Orientating
            // 4. Sort by IOP

            const alertValue = getAlertPriorities(a, b);
            if(alertValue !== 0){
                return alertValue;
            } else if(a.isOdOrientating && !b.isOdOrientating){
               return -1;
            } else if(b.isOdOrientating && !a.isOdOrientating){
               return 1;
            }  else if(getIOPPiorityIndex(b.iop, true) > getIOPPiorityIndex(a.iop, true)){
                return 1;
            } else if(getIOPPiorityIndex(b.iop, true) < getIOPPiorityIndex(a.iop, true)){
                return -1;
            } else if (getIOPPiorityIndex(b.iop, true) === getIOPPiorityIndex(a.iop, true)){
                if((Number(b.iop.od_iop) + Number(b.iop.os_iop)) > (Number(a.iop.od_iop) + Number(a.iop.os_iop))){
                    return 1
                } else {
                    return 0;
                }
            }
        } else {
            // For the other five statuses:
            // Ready for OMD, GoodDisc/GoodVF, BadDisc/GoodVF, GoodDisc/BadVF, BadDisc/BadVF,
            // 1. Sort by Alerts
            // 2. Sort by PR Alerts
            // 3. Sort by IOP

            const alertValue = getAlertPriorities(a, b);
            if(alertValue !== 0){
                return alertValue
            } else if(getIOPPiorityIndex(b.iop, true) > getIOPPiorityIndex(a.iop, true)){
               return 1;
            }
            else if(getIOPPiorityIndex(b.iop, true) < getIOPPiorityIndex(a.iop, true)){
               return -1;
            } else if (getIOPPiorityIndex(b.iop, true) === getIOPPiorityIndex(a.iop, true)){
                if((Number(b.iop.od_iop) + Number(b.iop.os_iop)) > (Number(a.iop.od_iop) + Number(a.iop.os_iop))){
                    return 1
                } else {
                    return 0;
                }
            }
        }

        return 0;
    })

    return sortedList;
}

// Takes an Alerts string and returns the priority index based on the alert.
const getAlertPriorityIndex = (alerts: string) => {

    if(!alerts){
        return 0;
    }
    else if(alerts.includes('GP Referred')){
        return 2;
    } else {
        return 1;
    }
}

// Takes a prAlerts array and return the priority index value based on the highest valued alert in the array.
const getPRAlertPriorityIndex = (prAlerts: string[]) => {

    let highestAlert = 0;

    prAlerts.forEach(prAlert => {
        if(highestAlert < 12 && prAlert.includes('OMDR has question')){
            highestAlert = 12;
        } else if(highestAlert < 11 && prAlert.includes('GP Referred')){
            highestAlert = 11;
        } else if(highestAlert < 10 && prAlert.includes('OD Has Question')){
            highestAlert = 10;
        } else if(highestAlert < 9 && prAlert.includes('Multiple Visits Waiting For Review')){
            highestAlert = 9;
        } else if(highestAlert < 8 && prAlert.includes('OD Request OMD Appointment')){
            highestAlert = 8;
        } else if(highestAlert < 7 && prAlert.includes('Current/Upcoming OMD')){
            highestAlert = 7;
        } else if(highestAlert < 6 && prAlert.includes('IOP >')){
            highestAlert = 6;
        } else if(highestAlert < 5 && prAlert.includes('OD Started')){
            highestAlert = 5;
        } else if(highestAlert < 4 && prAlert.includes('Past OMD')){
            highestAlert = 4;
        } else if(highestAlert < 3 && prAlert.includes('Multiple Retina 1 Pathologies')){
            highestAlert = 3;
        } else if(highestAlert < 2 && prAlert.includes('IOP Significantly')){
            highestAlert = 2;
        } else if(highestAlert < 1 && prAlert.includes('IOP Slightly')){
            highestAlert = 1;
        }
    })

    return highestAlert;
}

// Return the priority index for sorting by IOP value (the highest of the left and right IOP)
// IF onlyOneSideHasPriority if the value should be high prioritized because it only has one side iop.
const getIOPPiorityIndex = (iop: IOMDRPatientListItemIOP, onlyOneSidePriority: boolean) => {

    if(!iop.os_iop && !iop.od_iop){
        return 999;
    } else if(onlyOneSidePriority && (!iop.os_iop || !iop.od_iop)) {
        return 998;
    } else {
        return Math.max(Number(iop.os_iop), Number(iop.od_iop));
    }
}

// Return the priority index for sorting by glc class type.
// The logic is implemented for get the GLC type color, so calling that logic here.
const getGLCClassPriorityIndex = (iopSideValue: string, iopAim: null | number) => {

    const leftTypeColor = getGlcTypeColor(iopSideValue, iopAim);
    const rightTypeColor = getGlcTypeColor(iopSideValue, iopAim);

    const getIndex = (sideColorType: string) => {
        return sideColorType === 'normal-glc' ? 3
        : sideColorType === 'warning-glc' ? 2
        : sideColorType === 'alert-glc' ? 1
        : 0
    }

    // Return the max index
    return Math.max(getIndex(leftTypeColor), getIndex(rightTypeColor));
}

// Return the priority index based on the bDisc color on the left and right.
const getbDISCPriorityIndex = (bDiscRight: string, bDiscLeft: string) => {
    const discValues = [bDiscRight, bDiscLeft];

    const countOccurrences = (disc: string) => discValues.reduce((a, v) => (v === disc ? a + 1 : a), 0);
    const green = countOccurrences('G');
    const yellow = countOccurrences('Y');
    const red = countOccurrences('R');

    if(red === 2){
        return 10;
    } else if(red === 1 && yellow === 1){
        return 9;
    } else if(red === 1){
        return 8;
    } else if(yellow === 2){
        return 7;
    }else if(yellow === 1){
        return 6;
    } else if(green === 2){
        return 5;
    } else if(green === 1){
        return 4;
    } else {
        return 0;
    }
}