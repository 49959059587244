import React from 'react';
import { Table, Row, Col } from 'antd';
import { getOMDNameByID } from '../../helpers/doctor-convert';
import '../../../static/css/shared/patient-list-table.scss';
import '../../../static/css/components/admin-omdc-status-table.scss';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import { ColumnsType } from 'antd/es/table';
import { IOMDCStatusListItem } from '../../reducers/admin-review-lists-slice';
import { IOmdOption } from '../../reducers/options-slice';
import { localizedText } from '../../localizedText';

const OMDCTable = () => {
    const { OMDC_STATUS_TEXT, ADMIN_OMDC_READY_OMDC, ADMIN_READY_TOTAL,    
        ADMIN_OMDC_READY_CAT, ADMIN_OMDC_READY_RETINA, ADMIN_OMDC_READY_GLC, 
        ADMIN_REVIEWED_TOTAL, ADMIN_OMDC_REVIEWED_CAT, ADMIN_OMDC_REVIEWED_RETINA,    
        ADMIN_OMDC_REVIEWED_GLC, ADMIN_OMDC_PROCESSED } = localizedText;
    const tableList = useCare1AppSelector(store => store.adminReviewLists.omdcStatusList);
    const omds: IOmdOption[] = useCare1AppSelector(store => store.options.omds);
    const omdcStatusLoading = useCare1AppSelector(store => store.adminReviewLists.omdcStatusLoading);

    interface IPatientsToReviewTable extends IOMDCStatusListItem {
        key: React.Key,
    }

    const columns :  ColumnsType<IPatientsToReviewTable> =
        [
            {
                className: 'omdc category',
                title: ADMIN_OMDC_READY_OMDC,
                dataIndex: 'omdc',
                render: (text) => {
                    return getOMDNameByID(Number(text), omds);
                }
            }, {
                className: 'ready-total category',
                title: ADMIN_READY_TOTAL,
                dataIndex: 'readyTotal',
                render: (text) => text || 0
            }, {
                className: 'ready-cataracts category',
                title: ADMIN_OMDC_READY_CAT,
                dataIndex: 'readyCataracts',
                render: (text) => text || 0
            }, {
                className: 'ready-early-retina category',
                title: ADMIN_OMDC_READY_RETINA,
                dataIndex: 'readyEarlyRetina',
                render: (text) => text || 0,
            }, {
                className: 'ready-glc-sus category',
                title: ADMIN_OMDC_READY_GLC,
                dataIndex: 'readyGlcSus',
                render: (text) => text || 0,
            }, {
                className: 'reviewed-total category',
                title: ADMIN_REVIEWED_TOTAL,
                dataIndex: 'reviewedTotal',
                render: (text) => text ? (<span className='color-red'>{text}</span>) : 0
            }, {
                className: 'reviewed-cataracts category',
                title: ADMIN_OMDC_REVIEWED_CAT,
                dataIndex: 'reviewedCataracts',
                render: (text) => text || 0
            }, {
                className: 'reviewed-early-retina category',
                title: ADMIN_OMDC_REVIEWED_RETINA,
                dataIndex: 'reviewedEarlyRetina',
                render: (text) => text || 0,
            }, {
                className: 'reviewed-glc-sus category',
                title: ADMIN_OMDC_REVIEWED_GLC,
                dataIndex: 'reviewedGlcSus',
                render: (text) => text || 0,
            }, {
                className: 'processed-past-six-months category',
                title: ADMIN_OMDC_PROCESSED,
                dataIndex: 'processedLastSixMonths',
                render: (text) => text || 0
            }
        ]

    return (
        <div className={`patient-list-table omdc-status`}>
            <div className="omdc-status-table  patient-list-table-omdr">
                <div className="omdc-status-table-title" data-testid='omdc-status-table-title'>
                    {OMDC_STATUS_TEXT}
                </div>
                <Row>
                    <Col span={24}>
                        <Table
                            size='small'
                            columns={columns}
                            dataSource={tableList.map((item, index) => {
                                return { ...item, key: index };
                            })}
                            pagination={false}
                            loading={omdcStatusLoading}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default OMDCTable;