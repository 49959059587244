import { useNavigate } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Col, Modal, Select } from 'antd';
import * as Constants from '../constants';
import '../../static/css/components/exam-exit-confirmation-modal.scss';
import '../../static/css/components/_patient-exam-previous-visits.scss';
import { localizedText } from '../localizedText';
import { goToExam, goToExamInNewTab, lengthEqual } from '../reducers/patient-exam-slice';
import { useCare1AppSelector } from '../apps/care1-hooks';

const PatientExamPreviousVisits = () => {
    const { PATIENT_VISIT_NUMBER_TEXT, CONFIRMATION_CANCEL_TEXT, 
        CONFIRMATION_OK_TEXT, OF_TEXT, EXIT_CONFIRMATION_TITLE, EXIT_CONTENT_TEXT } = localizedText;

    const userIsAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const visitNumber = useCare1AppSelector(store => store.examData[Constants.VISIT_NUMBER]);
    const totalVisit = useCare1AppSelector(store => store.examData[Constants.TOTAL_VISIT_NUMBER]);
    const exams = useCare1AppSelector(store => store.examData[Constants.EXAMS], lengthEqual);
    const examId = useCare1AppSelector(store => store.examData[Constants.EXAM_ID]);
    const examDirty = useCare1AppSelector(store => store.examData.dirty);
    const isAdmin = useCare1AppSelector(store => store.user.isADMIN);
    const currentExamInactive = useCare1AppSelector(store => store.examData.is_inactive);

    // Redux and React Router Hooks
    const history = useNavigate();

    const sendGoToExamWithConfirm = (examId: number) => {
   
        if (examDirty) {
            Modal.confirm({
                className: 'exam-exit-confirmation-modal',
                title: EXIT_CONFIRMATION_TITLE,
                content: EXIT_CONTENT_TEXT,
                icon: <InfoCircleOutlined />,
                okText: CONFIRMATION_OK_TEXT,
                cancelText: CONFIRMATION_CANCEL_TEXT,
                cancelButtonProps: {
                    className: 'confirm-exit',
                },
                onOk: () => {
                    goToExam(examId, history);
                },
            });
        } else {
            goToExam(examId, history);
        }
    }

    return (
        <Row className="patient-exam-previous-visits">
            {/* NavBar 006 */}
            <Col span={12} className='patient-visits'>
                <label className={`patient-visit-of-count`} data-testid='visit-count'>{`${PATIENT_VISIT_NUMBER_TEXT} ${visitNumber} ${OF_TEXT} ${totalVisit}`}</label>
            </Col>
            <Col span={12}>
                {/* NavBar 007 */}
                <Select
                    size="small"
                    className={`patient-exam-visits-selection  ${currentExamInactive && isAdmin ? 'inactive-exam': ''}`}
                    data-testid='selected-visit'
                    placeholder={`Select previous visit`}
                    onChange={(value) => {
                        // If user is Admin, open the exam in a new tab and stay in current PEI.
                        // This achieved by using the Force Background Chrome extension
                        if(userIsAdmin){
                            goToExamInNewTab(value.toString());
                        } else {
                            sendGoToExamWithConfirm(value);
                        }
                    }}
                    value={examId}
                    dropdownStyle={{minWidth: '170px'}}
                    popupClassName='previous-visits-dropdown'
                >
                    {
                        exams && exams.map((exam) => (
                            <Select.Option
                                key={exam.id}
                                value={exam.id}
                                className={isAdmin && exam.is_inactive ? 'inactive-visit': ''}>
                                {exam[Constants.REACT_DROPDOWN_LABEL]}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Col>
        </Row>
    );
}

export default PatientExamPreviousVisits;