import { useEffect } from 'react';
import { Select, Form } from 'antd';
import { OD_SELECT_PLACEHOLDER_TEXT, OPTOMETRIST, OPTOMETRIST_OPTIONS, REACT_DROPDOWN_LABEL, REACT_DROPDOWN_VALUE
    } from '../../../constants';
import { getODDropdownFiltered, getODNameFromOdOptions } from '../../../helpers/patient-exam-convert';
import { lengthEqual } from '../../../reducers/patient-exam-slice';
import { buildRequiredErrorMessage } from '../../../helpers/patient-edit-convert';
import { useFormValidation } from '../../../context/useFormValidationContext';

import { setExamDataValue } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { FieldData } from 'rc-field-form/lib/interface';
import { useLazyGetDefaultOmdQuery } from '../../../services/doctor-api';


const { Option } = Select;

const PatientExamHeaderODSelect = () => {
    const optometristOptions = useCare1AppSelector(store => store.examData[OPTOMETRIST_OPTIONS], lengthEqual);
    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);
    const OD = useCare1AppSelector(store => store.examData[OPTOMETRIST]);
    const examStatusPrevious = useCare1AppSelector(store => store.examData.exam_status_previous);

    const [getDefaultOmd] = useLazyGetDefaultOmdQuery();

    // For OMDC get the OD name
    const odName = (userIsOMDC || userIsOMDR) && OD ? getODNameFromOdOptions(optometristOptions, OD) : null;

    // Get the list of ODs for the dropdown.
    // If the user is RE or IC then get the filtered list which has the hidden_from_dropdown ODs removed.
    // and the already saved OD option
    // If the user is OMDC or OMDR then use the full OD list including the hidden_from_dropdown ODs.
    const optomestristList = userIsOD ? getODDropdownFiltered(optometristOptions, OD) : optometristOptions;

    // As per RQs the component should be disabled if the previous status is 'ready' or 'reviewed'
    // unless user is ADMIN
    const isDisabled = !userIsADMIN && examStatusPrevious && (examStatusPrevious === 'ready' || examStatusPrevious === 'reviewed');

    const [ form ] = Form.useForm();
    const dispatch = useCare1AppDispatch();
    const { setODSelectForm } = useFormValidation();

    useEffect(()=>{
        // Get values for Ant Design fields from the store whenever values update.
        form.setFieldsValue({
            patientExamODSelect: OD
        });

        // The component should validate on every render
        form.validateFields();

        // pass the form instance to useFormValidation hooks
        setODSelectForm(form);
        // clean up function when od select component unmounts
        return () => {
            setODSelectForm(null);
        }
    }, [OD, form, setODSelectForm])

    // Ant Design handler. This is called whenever a field is changed by the user using Ant Design controls.
    const onFieldsChange = (fields: FieldData[]) => {

        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name.length && !field.validating) {
                switch (field.name[0]) {
                    case 'patientExamODSelect':
                        if (field.value !== OD && field.value !== null) {
                            dispatch(setExamDataValue(OPTOMETRIST, field.value));
                            getDefaultOmd(field.value as number);
                        }
                        break;
                    default:
                        break;
                }
            }
        });
    }

    return (
        <Form form={form} onFieldsChange={onFieldsChange}>
            { (userIsOD || userIsADMIN) &&
                /* EH-OD-SELECT 001 to 013 */
                <Form.Item
                    hasFeedback help=""
                    name="patientExamODSelect"
                    rules={[{ required: true, message: buildRequiredErrorMessage('An OD') }]}>
                    <Select
                        data-testid='pei-header-od-select'
                        className="patient-exam-od-select"
                        showSearch
                        size="small"
                        disabled={Boolean(isDisabled)}
                        placeholder={OD_SELECT_PLACEHOLDER_TEXT}
                        filterOption={(input, option) => option?.props.children.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0}
                    >
                        {
                            optomestristList && optomestristList.map((option) => (
                                <Option
                                    key={option[REACT_DROPDOWN_VALUE]}
                                    value={option[REACT_DROPDOWN_VALUE]}
                                >
                                    {`Dr. ${option[REACT_DROPDOWN_LABEL]}`}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            }
            { (userIsOMDC || userIsOMDR) &&
                <Form.Item>
                    <div className={'patient-exam-od-select'}>
                        <label className="patient-exam-header-content" data-testid='pei-header-od-select'>
                            {odName && `Dr. ${odName}`}
                        </label>
                    </div>

                </Form.Item>
            }
        </Form>
    )
}

export default PatientExamHeaderODSelect;