import { useEffect, useState } from 'react';
import { Row, Col, Table, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/od-dashboard.scss';


type ComponentProps = {
    title: string,
    previous_month_count: number,
    this_month_count: number,
}

const VisitsUploaded = ({ title, previous_month_count, this_month_count }: ComponentProps) => {

    const dispatch = useCare1AppDispatch();

    return (
        <div className="visits-uploaded">
            <Row>
                <Col span={24}>
                    <div className='title'>
                        {title}
                    </div>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <div className='number-container'>
                        <div>
                            Previous Month
                        </div>
                        <div className='count'>
                            {previous_month_count}
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className='number-container'>
                        <div>
                            Current Month
                        </div>
                        <div className={`count ${this_month_count > previous_month_count ? 'more-count' : ''}`}>
                            {this_month_count}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default VisitsUploaded