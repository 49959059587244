import {
    useCare1AppDispatch,
    useCare1AppSelector,
} from '../../apps/care1-hooks';
import ComponentTextareaCard from '../component-textarea-card';

const AdminReconciliationNotes = () => {
    const adminReconciliationNotes = useCare1AppSelector(
        (store) => store.examData.smart_upload_admin_reconciliation_notes
    );
    const dispatch = useCare1AppDispatch();

    return (
        <ComponentTextareaCard
            title={'Admin Reconciliation Notes'}
            testId='admin_reconciliation_notes'
            value={adminReconciliationNotes}
            lightBlueBackground={true}
            handleChange={(e) => {}}
        />
    );
};

export default AdminReconciliationNotes;
