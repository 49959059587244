import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Row, Col, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../static/css/components/admin-vf-near-miss-list-table.scss';
import {
    InfoCircleOutlined, GlobalOutlined
    } from '@ant-design/icons';
import { compareStrings } from '../../helpers/sorting';
import { localizedText } from '../../localizedText';
import { useGetCsvNearMissSummaryQuery, useGetCsvNearMissListQuery, useGetCsvNearMissListFilterOptionsQuery } from '../../services/admin-csv-near-miss-api';

interface ICsvNearMissLogListTable {
    key: React.Key;
    id: string;
    log_date: string,
    exam_date: string,
    exam_id: string,
    field_name: string,
    original_value: string,
    corrected_value: string,
    clinic_location_full_name: string,
}

interface IKeyValuePair {
    key: string;
    value: string;
}

type AppProps = {
    filterFieldNameOption: string[],
    filterClinicLocationFullNameOption: string[],
    filterHistoricalPeriodOption: IKeyValuePair[],
}

const AdminCsvNearMissLogTable = ({filterFieldNameOption, filterClinicLocationFullNameOption, filterHistoricalPeriodOption }: AppProps) => {
    const { OF_TEXT, ITEMS_TEXT } = localizedText;

    const [scrollClass, setScrollClass] = useState('');

    const [historicalPeriodOption, setHistoricalPeriodOption] = useState('last_180_days');
    const [fieldNameOption, setFieldNameOption] = useState<string | undefined>(undefined);
    const [clinicLocationFullNameOption, setClinicLocationFullNameOption] = useState<string | undefined>(undefined);

    const { data: nearMissData = { 
        near_miss_list: [] 
    }, isFetching: nearMissIsFetching } = useGetCsvNearMissListQuery({
        historicalPeriodOption: historicalPeriodOption,
        fieldNameOption: fieldNameOption,
        clinicLocationFullNameOption: clinicLocationFullNameOption,
    });
    
    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const handleExamSelection = (record: ICsvNearMissLogListTable) => {
        window.open(`https://start.care1.ca/patient-exam/${record.exam_id}?tab=gpt-vision`, '_blank')?.focus();
    }

    const nameColumns: ColumnsType<ICsvNearMissLogListTable> = [
        {
            className: 'log_date-column',
            title: 'Log Date',
            dataIndex: 'log_date',
            sorter: (a, b) => compareStrings(a['log_date'], b['log_date']),
            render: (text, record) => <div data-testid={`'log_date'-${record.key}`}>{text}</div>
        }, {
            className: 'exam_date-column',
            title: 'Exam Date',
            dataIndex: 'exam_date',
            sorter: (a, b) => compareStrings(a['exam_date'], b['exam_date']),
            render: (text, record) => <div data-testid={`'exam_date'-${record.key}`}>{text}</div>
        }, {
            className: 'exam_id-column',
            title: 'Exam',
            dataIndex: 'exam_id',
            sorter: (a, b) => compareStrings(a['exam_id'], b['exam_id']),
            render: (text, record) => <div data-testid={`'exam_id'-${record.key}`}><a onClick={() => handleExamSelection(record)}>{text}</a></div>
        }, {
            className: 'field_name-column',
            title: 'Field name',
            dataIndex: 'field_name',
            sorter: (a, b) => compareStrings(a['field_name'], b['field_name']),
            render: (text, record) => <div data-testid={`'field_name'-${record.key}`}>{text}</div>
        }, {
            className: 'original_value-column',
            title: 'Original value',
            dataIndex: 'original_value',
            sorter: (a, b) => compareStrings(a['original_value'], b['original_value']),
            render: (text, record) => <div data-testid={`'original_value'-${record.key}`}>{text}</div>
        }, {
            className: 'corrected_value-column',
            title: 'Corrected value',
            dataIndex: 'corrected_value',
            sorter: (a, b) => compareStrings(a['corrected_value'], b['corrected_value']),
            render: (text, record) => <div data-testid={`'corrected_value'-${record.key}`}>{text}</div>
        }, {
            className: 'clinic_location_full_name-column',
            title: 'Clinic location',
            dataIndex: 'clinic_location_full_name',
            sorter: (a, b) => compareStrings(a['clinic_location_full_name'], b['clinic_location_full_name']),
            render: (text, record) => <div data-testid={`'clinic_location_full_name'-${record.key}`}>{text}</div>
        }
    ];

    const renderTitle = () => {
        const title = 'Csv Near Miss Log';
        return (
            <div>
            <span className='title'>{title} </span>
            <span className='title-tooltip'>
                <Tooltip
                    className={'recommendations-tooltip'}
                    placement='topLeft'
                    title="A near miss is logged when an Admin modifies and corrects the GPT suggested value."
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </span>
            </div>);
    }

    return (
        <div className={'vf-near-miss-list-table'}>
            <Row>
                <Col span={4}>
                    <Select
                        className={`dropdown-filter`}
                        value={fieldNameOption}
                        allowClear={true}
                        placeholder="Field name"
                        onChange={(value) => setFieldNameOption(value)}
                        filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {filterFieldNameOption && filterFieldNameOption.map((option) => (
                            <Select.Option className='option-selection' key={option} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={4}>
                    <Select
                        className={`dropdown-filter`}
                        value={clinicLocationFullNameOption}
                        allowClear={true}
                        placeholder="Clinic location"
                        onChange={(value) => setClinicLocationFullNameOption(value)}
                        filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {filterClinicLocationFullNameOption && filterClinicLocationFullNameOption.map((option) => (
                            <Select.Option className='option-selection' key={option} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={4}>
                    <Select
                        className={`dropdown-filter`}
                        value={historicalPeriodOption}
                        onSelect={(value) => setHistoricalPeriodOption(value)}
                        filterOption={(input, option) => option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {filterHistoricalPeriodOption && filterHistoricalPeriodOption.map((option) => (
                            <Select.Option className='option-selection' key={option.key} value={option.key}>
                                {option.value}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <Row className='log-table'>
                <Col span={24}>
                    <div>{renderTitle()}</div>
                    <div className={scrollClass}>
                        <Table
                            columns={nameColumns}
                            pagination={{
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '30', '50', '100', '200'],
                                defaultPageSize: 30,
                                showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                            }}
                            dataSource={nearMissData.near_miss_list}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default AdminCsvNearMissLogTable;