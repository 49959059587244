import React, { useRef} from 'react';
import Lightbox, {ILightBoxProps} from 'react-18-image-lightbox';

const Care1LightBox = (props: ILightBoxProps & {isOpen: boolean, field: string}) => {

    const lightboxRef = useRef<Lightbox>(null);

    const handleAfterClose = () => {
        if (lightboxRef.current) {
            const lightboxCurrentRef = lightboxRef.current as Omit<Lightbox, 'setState'> & { setState: React.Dispatch<React.SetStateAction<Record<string, boolean>>>}
            lightboxCurrentRef.setState({isClosing: false});
        }
    }

    return (
        <Lightbox
            ref={lightboxRef}
            {...props}
            reactModalProps={{ 
                isOpen: props['isOpen'] ,
                onAfterClose: handleAfterClose,
            }}
        />
    )
}

export default Care1LightBox;