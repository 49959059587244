import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { AppDispatch } from '../../../stores/retina-enabled-store';
import { Col, InputNumber } from 'antd';
import ExamMetricValidationModal from './exam-metric-validation-modal';
import { allowPosNumberOnlyKeys } from '../../../helpers/utilities';
import * as Constants from '../../../constants';
import { setDtcValues } from '../../../reducers/patient-exam-slice';

type ComponentProps = {
    currentValue: number | null;
    onDtcValueChange: (column: number, value: number | null) => void;
    column: number;
    disabled?: boolean;
    side: 'od' | 'os';
    dispatch: AppDispatch;
    dtcValues: {od?: number | null, os?: number | null }[];
    placeholder: string;
    colSpan?: number;
    addonBefore?: string | undefined;
    isSmartUpload?: boolean;
}

export default function PatientExamDtcIopEntry({ currentValue, onDtcValueChange, column, disabled,
    side, dispatch, dtcValues, placeholder, colSpan = 8, addonBefore = undefined, isSmartUpload
 }: ComponentProps) {

    // Yellow warning border should display for OD users only
    const userIsOD = useCare1AppSelector(store => store.user.isOD);

    const validateDtcIopValue = () => {
        let value = null;
        if (dtcValues && dtcValues.length > column && side in dtcValues[column]) {
            value = dtcValues[column][side];
        }
        if (value && typeof (value) === 'number' && (value < 0 || value > 70)) {
            const newDtcValues = dtcValues.map((dtcValue, index) => index === column ?
                {...dtcValue, [side]: null} : dtcValue);
            dispatch(setDtcValues(newDtcValues));
            ExamMetricValidationModal(Constants.IOP_VALIDATION_MSG);
        }
    };

    // Field has warning if both sides are empty.
    const otherSide = side === 'od' ? 'os' : 'od';
    const hasWarning = userIsOD && !currentValue
        && !(dtcValues && dtcValues.length > 0 && dtcValues[column] && dtcValues[column][otherSide])
        ? 'has-warning' : '';

    return (
        <Col span={colSpan}>
            <div className={`dtc-iop-entry ${side}-${column + 1} ${hasWarning}`}>
                {/* Use onKeyPress event to prevent letters */}
                <InputNumber
                    className={`dtc-iop-${side}-${column + 1}`}
                    data-testid={`dtc-iop-${side}-${column + 1}`}
                    size={isSmartUpload ? 'large' : 'small'}
                    disabled={disabled}
                    step={1}
                    value={currentValue}
                    onKeyPress={event => allowPosNumberOnlyKeys(event)}
                    onChange={(valueAsNumber) => onDtcValueChange(column, valueAsNumber)}
                    onBlur={validateDtcIopValue}
                    placeholder={placeholder}
                    addonBefore={addonBefore}
                    controls={false}
                />
            </div>
        </Col>
    );
}
