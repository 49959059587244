import { Fragment, useMemo } from 'react';
import { Card, Form, Input } from 'antd';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import { getTxAlgoKeywordOptions, addTxAlgoKeywordColors, renderTxAlgoKeywordString
} from '../../helpers/patient-exam-convert';
import * as Constants from '../../constants';
import '../../../static/css/components/patient-exam-history-meds-exams.scss';
import { localizedText } from '../../localizedText';


const PatientExamHistoryMedsExams = ({ disabled }: { disabled: boolean }) => {
    const { PATIENT_EXAM_HISTORY_MEDS_EXAMS_TITLE } = localizedText;
    const examHistory = useCare1AppSelector(store => store.examData[Constants.EXAM_HISTORY_FIELD]);
    const examNotes = useCare1AppSelector(store => store.examData[Constants.EXAM_NOTES_FIELD]);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);

    const examHistoryMed = `${examHistory}\n\n${examNotes}`;

    // Get the color coded keywords for the component
    const txAlgo3Keywords = useCare1AppSelector(store => store.options.txAlgoKeywords);
    const txAlgoKeywordOptions = useMemo(() => {
        return getTxAlgoKeywordOptions(txAlgo3Keywords, 'historyMedsExam');
    }, [txAlgo3Keywords]);

    // Add the color code tags to the content string
    const applyKeywordColors = useMemo(() => {
        if(txAlgoKeywordOptions){
            return  addTxAlgoKeywordColors(txAlgoKeywordOptions, examHistoryMed)
        }
        return null;
    }, [txAlgoKeywordOptions, examHistoryMed]);

    return (
        <Card className="component-input-card history-meds-exams" bordered={false}>
            {/* PT-EXAM-HX-MEDS-EXAMS 001, 0002, 003, 004, 005, 006 */ }
            <div className="heading">{PATIENT_EXAM_HISTORY_MEDS_EXAMS_TITLE}</div>
            { userIsOMDC &&
                <Input.TextArea
                    disabled={disabled}
                    className="component-input"
                    autoSize={false}
                    value={examHistoryMed}
                />
            }
            { userIsOMDR  &&
                <Form.Item>
                    <div className="component-input color-keyword-div" data-testid='history-meds-exams'>
                        {
                            applyKeywordColors && applyKeywordColors.map(
                                (string, index) => (
                                    <Fragment key={index}>
                                        {renderTxAlgoKeywordString(string)}
                                    </Fragment>
                                )
                            )
                        }
                    </div>
                </Form.Item>
            }
        </Card>
    );
}

export default PatientExamHistoryMedsExams;