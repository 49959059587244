import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { getCsrfToken } from '../helpers/utilities';
import { userService } from '../services/user-service';


export interface IUser {
    userName: string;
    userID: number | null;
    displayName: string;
    isLoggedIn: boolean;
    error: string;
    odPractice: number | null;
    odPracticeName: string;
    odProvince: string;
    odEmail: string;
    omdProvince: number | null;
    isRetinaEnabled: boolean;
    isPERC: boolean;
    isOD: boolean;
    isOMDG: boolean;
    isOMDC: boolean;
    isOMDR: boolean;
    isADMIN: boolean;
    isSALES: boolean;
    isSCIENCEUSER: boolean;
    isIGP: boolean;
    isSuperuser: boolean;
    doctorID: number | null;
    notAuthorizedForExam: boolean;
    csrfToken: string;
    isUsOnly: boolean;
    firstName: string;
    lastName: string;
    odUsesReferralLetterPei: boolean;
    hasGlaucomaProgram: boolean;
    odHasSmartUpload: boolean;
    odSendGpLetters: boolean;
}

export interface IUserResponse {
    username: string;
    user_id: number;
    od_group: number;
    od_group_name: string;
    od_province: string;
    od_email: string;
    omd_province: number;
    retina_only: boolean;
    perc: boolean;
    od: boolean;
    omdg: boolean;
    is_omdc: boolean;
    is_omdr: boolean;
    is_admin: boolean;
    is_sales: boolean;
    is_science_user: boolean;
    is_igp: boolean;
    is_superuser: boolean;
    doctor_id: number;
    csrfToken: string;
    is_us_only: boolean;
    first_name: string;
    last_name: string;
    od_uses_referral_letter_pei: boolean;
    od_has_glaucoma_program: boolean;
    od_has_smart_upload: boolean;
    od_send_gp_letters: boolean;
}

const userName = localStorage.getItem('userName');

const initialState: IUser = {
    userName: userName || '',
    userID: null,
    displayName: '',
    isLoggedIn: false,
    error: '',
    odPractice: null,
    odPracticeName: '',
    odProvince: '',
    odEmail: '',
    omdProvince: null,
    isRetinaEnabled: false,
    isPERC: false,
    isOD: false,
    isOMDG: false,
    isOMDC: false,
    isOMDR: false,
    isADMIN: false,
    isSALES: false,
    isSCIENCEUSER: false,
    isIGP: false,
    isSuperuser: false,
    doctorID: null,
    notAuthorizedForExam: false,
    csrfToken: '',
    isUsOnly: false,
    firstName: '',
    lastName: '',
    odUsesReferralLetterPei: false,
    hasGlaucomaProgram: false,
    odHasSmartUpload: false,
    odSendGpLetters: false,
}


export interface ILogin {
    userName: string;
    password: string;
    saveUserAsDefault: boolean;
}

export const login = createAsyncThunk(
    'userSlice/login',
    async (loginData: ILogin, {dispatch, getState, rejectWithValue}) => {
        // rejectWithValue takes the error value so that the errors can be handled

        const {userName, password, saveUserAsDefault} = loginData;

        // Save the user name to local storage if we are told to during login via the "Remember Me" checkbox. Otherwise
        // clear it.
        if (saveUserAsDefault) {
            localStorage.setItem('userName', userName);
        } else {
            localStorage.removeItem('userName');
        }

        try {
            const response = await userService.login(userName, password, saveUserAsDefault);
            return response;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const logout = createAsyncThunk(
    'userSlice/logout',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }} = getState() as { user: {csrfToken: string}};
        try {
            const response = await userService.logout(csrfToken);
            return response;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        toggleNotAuthorizedForExam: (state, action: PayloadAction<boolean>) => {
            state.notAuthorizedForExam = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
        });
        builder.addCase(login.fulfilled, (state, action: PayloadAction<IUserResponse>) => {
            const csrfToken = getCsrfToken();
            state.userID = action.payload.user_id;
            state.userName = action.payload.username;
            state.displayName = `${action.payload.first_name} ${action.payload.last_name}`;
            state.odEmail = action.payload.od_email;
            state.odPractice = action.payload.od_group;
            state.odPracticeName = action.payload.od_group_name;
            state.odProvince = action.payload.od_province;
            state.omdProvince = action.payload.omd_province;
            state.isRetinaEnabled = action.payload.retina_only;
            state.isPERC = action.payload.perc;
            state.isOD = action.payload.od;
            state.isOMDG = action.payload.omdg;
            state.isOMDC = action.payload.is_omdc;
            state.isOMDR = action.payload.is_omdr;
            state.isADMIN = action.payload.is_admin;
            state.isSALES = action.payload.is_sales;
            state.isSCIENCEUSER = action.payload.is_science_user;
            state.isIGP = action.payload.is_igp;
            state.isSuperuser = action.payload.is_superuser;
            state.doctorID = action.payload.doctor_id;
            state.isUsOnly = action.payload.is_us_only;
            state.csrfToken = csrfToken;
            state.firstName = action.payload.first_name;
            state.lastName = action.payload.last_name;
            state.error = '';
            state.isLoggedIn = true;
            state.notAuthorizedForExam = false;
            state.odUsesReferralLetterPei = action.payload.od_uses_referral_letter_pei;
            state.hasGlaucomaProgram = action.payload.od_has_glaucoma_program;
            state.odHasSmartUpload = action.payload.od_has_smart_upload;
            state.odSendGpLetters = action.payload.od_send_gp_letters;
        });
        builder.addCase(login.rejected, (state, action) => {
            // handle the rejected case, the value was passed from rejecteWithValue
            state.isLoggedIn = false;
            state.displayName = '';
        });
        builder.addCase(logout.pending, (state) => {
        });
        builder.addCase(logout.fulfilled, (state) => {
            state.displayName = '';
            state.isLoggedIn = false;
            state.isPERC = false;
            state.isOD = false;
            state.isOMDG = false;
            state.isOMDC = false;
            state.isOMDR = false;
            state.isADMIN = false;
            state.isSALES = false;
            state.isSCIENCEUSER = false;
            state.isIGP = false;
            state.doctorID = null;
            state.isUsOnly = false;
        });
        builder.addCase(logout.rejected, (state, action) => {
            // handle the rejected case, the value was passed from rejecteWithValue
            state.isLoggedIn = false;
            state.displayName = '';
            Modal.error({
                className: 'info-modal',
                title: `Errors logging out. ${action.payload}`,
            })
        });
    },
});

export const { toggleNotAuthorizedForExam } = userSlice.actions;

export default userSlice.reducer;
