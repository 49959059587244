import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getCsrfToken } from "../helpers/utilities";
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";


export interface IMaculaAbnormalityUI {
    x: number,
    y: number,
    width: number,
    height: number,
    stroke: string,
    id: string,
}

export interface IMaculaAbnormalityResultsItem {
    image: string,
    img_info: {
        macula: {
            x: number,
            y: number
        },
        optic_nerve: {
            x: number,
            y: number
        }
    },
    num_all_pixels: number,
    num_blue_pixels: number,
    point: number,
}

export interface IMaculaAbnormalityResults {
    right: IMaculaAbnormalityResultsItem[],
    left: IMaculaAbnormalityResultsItem[],
    error: '',
}

export interface IMaculaAbnormalityUIStates {
    right_macula_x?: number,
    right_macula_y?: number,
    right_macula_width?: number,
    right_macula_height?: number,
    right_optic_nerve_x?: number,
    right_optic_nerve_y?: number,
    right_optic_nerve_width?: number,
    right_optic_nerve_height?: number,
    left_macula_x?: number,
    left_macula_y?: number,
    left_macula_width?: number,
    left_macula_height?: number,
    left_optic_nerve_x?: number,
    left_optic_nerve_y?: number,
    left_optic_nerve_width?: number,
    left_optic_nerve_height?: number,
}

export interface IMaculaAbnormalityForm {
    exam_id: string,
    right_macula_x?: number,
    right_macula_y?: number,
    right_macula_width?: number,
    right_macula_height?: number,
    right_optic_nerve_x?: number,
    right_optic_nerve_y?: number,
    right_optic_nerve_width?: number,
    right_optic_nerve_height?: number,
    left_macula_x?: number,
    left_macula_y?: number,
    left_macula_width?: number,
    left_macula_height?: number,
    left_optic_nerve_x?: number,
    left_optic_nerve_y?: number,
    left_optic_nerve_width?: number,
    left_optic_nerve_height?: number,
}

export interface IMaculaAbnormality {
    api_status: 'idle' | 'loading' | 'succeeded' | 'failed',
    situational_awareness_status: string,
    situational_awareness_ui_states?: IMaculaAbnormalityUIStates,
    macula_abnormality_results?: IMaculaAbnormalityResults,
    show_modal: boolean,
    edit_exam_id: string,
    edit_right_fundus_photo: string,
    edit_left_fundus_photo: string,
    edit_situational_awareness_ui_states?: IMaculaAbnormalityUIStates,
}

const initialState: IMaculaAbnormality = {
    api_status: 'idle',
    situational_awareness_status: '',
    situational_awareness_ui_states: undefined,
    macula_abnormality_results: undefined,
    show_modal: false,
    edit_exam_id: '',
    edit_right_fundus_photo: '',
    edit_left_fundus_photo: '',
    edit_situational_awareness_ui_states: undefined,
};


export const fetchMaculaAbnormality = createAsyncThunk(
    'maculaAbnormality/fetch',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken }, examData: { id } } = getState() as { user: { csrfToken: string }, examData: { id: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/abnormality/${id}/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const submitMaculaAbnormalityRequest = createAsyncThunk(
    'maculaAbnormality/submitMaculaAbnormalityRequest',
    async (data: IMaculaAbnormalityForm, { dispatch, getState, rejectWithValue }) => {
        const { user: { csrfToken } } = getState() as { user: { csrfToken: string, userID: number } };

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const formData = new FormData();
        formData.append('exam_id', `${data['exam_id']}`);
        
        formData.append('right_macula_x', `${data['right_macula_x']}`);
        formData.append('right_macula_y', `${data['right_macula_y']}`);
        formData.append('right_macula_width', `${data['right_macula_width']}`);
        formData.append('right_macula_height', `${data['right_macula_height']}`);
        formData.append('right_optic_nerve_x', `${data['right_optic_nerve_x']}`);
        formData.append('right_optic_nerve_y', `${data['right_optic_nerve_y']}`);
        formData.append('right_optic_nerve_width', `${data['right_optic_nerve_width']}`);
        formData.append('right_optic_nerve_height', `${data['right_optic_nerve_height']}`);

        formData.append('left_macula_x', `${data['left_macula_x']}`);
        formData.append('left_macula_y', `${data['left_macula_y']}`);
        formData.append('left_macula_width', `${data['left_macula_width']}`);
        formData.append('left_macula_height', `${data['left_macula_height']}`);
        formData.append('left_optic_nerve_x', `${data['left_optic_nerve_x']}`);
        formData.append('left_optic_nerve_y', `${data['left_optic_nerve_y']}`);
        formData.append('left_optic_nerve_width', `${data['left_optic_nerve_width']}`);
        formData.append('left_optic_nerve_height', `${data['left_optic_nerve_height']}`);

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/abnormality_scan/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, formData);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const maculaAbnormalitySlice = createSlice({
    name: 'maculaAbnormality',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setModalEditData: (state, action) => {
            // sample action.payload {key:'memberId', value: '12345'}
            if(action.payload){
                return {...state, [action.payload.key]: action.payload.value}
            }
            return {...state};
        },
        openModal: (state) => {
            state.show_modal = true;
        },
        closeModal: (state) => {
            state.show_modal = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitMaculaAbnormalityRequest.pending, (state) => {
                state.api_status = 'loading';
            })
            .addCase(submitMaculaAbnormalityRequest.fulfilled, (state) => {
                state.api_status = 'succeeded';
            })
            .addCase(submitMaculaAbnormalityRequest.rejected, (state) => {
                state.api_status = 'failed';
            })
            .addCase(fetchMaculaAbnormality.pending, (state) => {
                state.api_status = 'loading';
            })
            .addCase(fetchMaculaAbnormality.fulfilled, (state, action) => {
                state.api_status = 'succeeded';

                state.situational_awareness_status = action.payload.situational_awareness_status
                state.situational_awareness_ui_states = action.payload.situational_awareness_ui_states
                state.macula_abnormality_results = action.payload.macula_abnormality_results
            })
            .addCase(fetchMaculaAbnormality.rejected, (state) => {
                state.api_status = 'failed';
            });
    },
});

export const { setModalEditData, openModal, closeModal } = maculaAbnormalitySlice.actions;

export default maculaAbnormalitySlice.reducer;