import { useState } from 'react';
import {
    CloseCircleOutlined, LeftOutlined, RightOutlined, SyncOutlined, ZoomInOutlined, ZoomOutOutlined, CloudDownloadOutlined,
} from '@ant-design/icons';
import { pdfjs, Page, Document } from 'react-pdf';
import {
    PDF_VIEWER_DEFAULT_SCALE, PDF_VIEWER_MAX_SCALE, PDF_VIEWER_MIN_SCALE, PDF_VIEWER_SCALE_MULTIPLIER,
} from '../../constants';
import { getFilepathFromBackendMediaUrl } from '../../helpers/media-image-convert';
import '../../../static/css/components/pdf-viewer.scss';

type ComponentProps = {
    file: string,
    onPDFLoadSuccess: (numPages: number) => void,
    onPDFLoadError: () => void,
    onClose: () => void,
    isGPTTab?: boolean,
}

const options = {
    standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

const PDFViewer = ({ file, onPDFLoadSuccess, onPDFLoadError, onClose, isGPTTab=false }: ComponentProps) => {

    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(isGPTTab ? 1 : PDF_VIEWER_DEFAULT_SCALE);

    const onPDFLoadSuccessLocal = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
        onPDFLoadSuccess(numPages);
    }

    const zoomIn = () => {
        if (scale < PDF_VIEWER_MAX_SCALE) {
            setScale(scale * PDF_VIEWER_SCALE_MULTIPLIER);
        }
    }

    const zoomOut = () => {
        if (scale > PDF_VIEWER_MIN_SCALE) {
            setScale(scale / PDF_VIEWER_SCALE_MULTIPLIER);
        }
    }

    const zoomReset = () => {
        setScale(PDF_VIEWER_DEFAULT_SCALE);
    }

    const goToPrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    const goToNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    }

    const download = () => {
        const fileUrl = getFilepathFromBackendMediaUrl(file);

        const a = document.createElement('a');
        a.href = `${process.env.REACT_APP_BACKENDURL}/data/download/${fileUrl}`;
        a.download = fileUrl.split('/')[fileUrl.split('/').length - 1];

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

    return (
        <div className="pdfViewerContent">
            <div className="pdfViewerControlsContainer">
                <div className="pdfViewerControls">
                    <LeftOutlined
                        className="control-icon"
                        onClick={goToPrevPage}
                        disabled={pageNumber === 1} />
                    <span className="page-num">
                        {pageNumber}
                        /
                        {numPages}
                    </span>
                    <RightOutlined
                        className="control-icon"
                        onClick={goToNextPage}
                        disabled={pageNumber === numPages} />
                    <ZoomInOutlined
                        className="control-icon"
                        onClick={zoomIn}
                        disabled={scale > PDF_VIEWER_MAX_SCALE} />
                    <SyncOutlined
                        className="control-icon"
                        onClick={zoomReset}
                        disabled={scale === PDF_VIEWER_DEFAULT_SCALE} />
                    <ZoomOutOutlined
                        className="control-icon"
                        onClick={zoomOut}
                        disabled={scale < PDF_VIEWER_MIN_SCALE} />
                    <CloudDownloadOutlined
                        className="control-icon"
                        onClick={download}
                    />
                    <CloseCircleOutlined className="control-icon" onClick={onClose} />
                </div>
            </div>
            { file && 
            <Document options={options} file={file} onLoadSuccess={onPDFLoadSuccessLocal} onLoadError={onPDFLoadError}>
                <Page pageNumber={pageNumber} scale={scale} renderMode={isGPTTab ? 'canvas' : 'canvas'} />
            </Document>
            }
        </div>
    );
}
export default PDFViewer;
