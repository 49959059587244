import { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { Checkbox, Col, Input, Select, Row, Spin, Form, Button } from 'antd';
import { buildRequiredErrorMessage } from '../../helpers/patient-edit-convert';
import '../../../static/css/components/admin-doctor-edit-form.scss';
import { defaultIGPDetail, IIGPDetails, useGetIGPDetailQuery } from '../../services/doctor-api';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { EMAIL_PLACEHOLDER_TEXT, ERROR_INVALID_PHONE_FORMAT, OPERATING_TEXT, PHONE_MASK_FORMAT, PHONE_PLACEHOLDER_TEXT } from '../../constants';
import { IOmdNetworkOption } from '../../reducers/options-slice';
import { setCurrentStaffId, setCurrentUsername, setShowPasswordModal } from '../../reducers/doctor-slice';

const FormItem = Form.Item;

type AppProps = {
    title: string,
    buttonText: string,
    mode: 'edit' | 'add',
    onFinish: (values: IIGPDetails) => Promise<void>,
    onFinishFailed: () => void,
}

const IGPEditForm = ({ title, buttonText, mode, onFinish, onFinishFailed }: AppProps) => {

    const omdNetworks: IOmdNetworkOption[] = useCare1AppSelector(store => store.options.omd_networks);
    const currentIgpId = useCare1AppSelector(store => store.doctorSlice.currentIgp);
    const showIgpEditModal = useCare1AppSelector(store => store.doctorSlice.showIgpEditModal);

    const [form] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    const skipQuery = currentIgpId && mode==='edit' && showIgpEditModal ? false : true;

    const {data = {igp: {...defaultIGPDetail}}, isFetching, isSuccess} = useGetIGPDetailQuery(currentIgpId as number, {skip: skipQuery });

    const {igp: {first_name: firstName, last_name: lastName, username, email, user_id}} = data;

    // make the column span the whole column witdh
    const fullColWidth = { span: 24 };

    useEffect(() => {
        if (isSuccess) {
            form.setFieldsValue({
                ...data.igp,
            });
        }
       
    }, [data, form, isSuccess])

    const handlePasswordChange = () => {
        dispatch(setShowPasswordModal(true));
        dispatch(setCurrentStaffId(user_id));
        dispatch(setCurrentUsername(username));
    }

    return (
        <Form
            className="admin-doctor-edit-form"
            form={form}
            onFinish={(values) => onFinish({...values, email: mode === 'edit' ? email : values.email})}
            onFinishFailed={onFinishFailed}
            initialValues={defaultIGPDetail}
        >
            <Spin
                className="loading-spinner"
                spinning={isFetching}
                size="large"
                tip={OPERATING_TEXT}
            >
                <Row className="header-section" align="middle">
                    <div className="modal-title">
                        {title}
                    </div>
                </Row>
                {mode === 'edit' &&
                    <Row className='read-only-details' gutter={24}>
                        <Col>
                            <div className="label-column">Username</div>
                        </Col>
                        <Col>
                            <div className="value-column">{username}</div>
                        </Col>
                        <Col>
                            <div className="label-column">Lastname</div>
                        </Col>
                        <Col>
                            <div className="value-column">{lastName}</div>
                        </Col>
                        <Col>
                            <div className="label-column">Firstname</div>
                        </Col>
                        <Col>
                            <div className="value-column">{firstName}</div>
                        </Col>
                        <Col>
                            <div className="label-column">Email:</div>
                        </Col>
                        <Col>
                            <div className="value-column">{email}</div>
                        </Col>
                    </Row>
                }
                {mode === 'add' &&
                    <>
                        <Row gutter={24}>
                            <Col span={8}>
                                <FormItem
                                    label={'Username'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'username'}
                                    rules={[{
                                        required: true,
                                        message: buildRequiredErrorMessage('Username')
                                    }]}
                                >
                                    <Input
                                        data-testid='username-input'
                                        className={"username-input"}
                                        placeholder={'Enter username'}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label={'First name'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'first_name'}
                                    rules={[{
                                        required: true,
                                        message: buildRequiredErrorMessage('First name')
                                    }]}
                                >
                                    <Input
                                        data-testid='first-name-input'
                                        className={"first-name-input"}
                                        placeholder={'Enter first name'}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label={'Last name'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'last_name'}
                                    rules={[{
                                        required: true,
                                        message: buildRequiredErrorMessage('Last name')
                                    }]}
                                >
                                    <Input
                                        data-testid='last-name-input'
                                        className={"last-name-input"}
                                        placeholder={'Enter last name'}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <FormItem
                                    label={'Password'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'password'}
                                    rules={[{
                                        required: true,
                                        message: buildRequiredErrorMessage('Password')
                                    }]}
                                >
                                    <Input.Password
                                        data-testid='password-input'
                                        className={"password-input"}
                                        placeholder={'Enter password'}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label={'Password confirmation'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'password_confirm'}
                                    dependencies={['password']}
                                    rules={[{
                                        required: true,
                                        message: 'Please confirm your password!'
                                    },
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                          return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                      },
                                    })]}
                                >
                                    <Input.Password
                                        data-testid='password-confirm-input'
                                        className={"password-confirm-input"}
                                        placeholder={'Enter password again'}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                {/* Test 006 */}
                                <FormItem
                                    label={'Email'}
                                    labelCol={fullColWidth}
                                    hasFeedback
                                    colon={false}
                                    name={'email'}
                                >
                                    <Input
                                        data-testid='email-input'
                                        className={"email-address-input"}
                                        placeholder={EMAIL_PLACEHOLDER_TEXT}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </>
                }
                <Row gutter={24}>
                    <Col span={8}>
                        {/* PE 005 */}
                        <FormItem
                            label={"Telephone"}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'telephone'}
                            validateTrigger={'onBlur'}
                            rules={[{
                                pattern: /^[^_]+$/,
                                message: ERROR_INVALID_PHONE_FORMAT,
                            }]}
                        >
                            <InputMask
                                data-testid='phone-input'
                                className={'phone-number-input ant-input'}
                                mask={PHONE_MASK_FORMAT}
                                placeholder={PHONE_PLACEHOLDER_TEXT}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        {/* PE 005 */}
                        <FormItem
                            label={'Fax'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'fax'}
                            validateTrigger={'onBlur'}
                            rules={[{
                                pattern: /^[^_]+$/,
                                message: ERROR_INVALID_PHONE_FORMAT,
                            }]}
                        >
                            <InputMask
                                data-testid='fax-input'
                                className={'fax-number-input ant-input'}
                                mask={PHONE_MASK_FORMAT}
                                placeholder={'Enter fax number'}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        {/* Test 006 */}
                        <FormItem
                            label={'License number'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'license_number'}
                        >
                            <Input
                                data-testid='license-number-input'
                                className={"license-number-input"}
                                placeholder={'Enter license number'}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            label={'OMD network'}
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'omdnetwork'}
                            rules={[{
                                required: true,
                                message: buildRequiredErrorMessage('OMD network')
                            }]}
                        >
                            <Select
                                placeholder={'Select OMD network'}
                                className="omd-network-input"
                                data-testid='omd-network-select'
                            >
                                {omdNetworks.length > 0 && omdNetworks.map(on => {
                                    return (
                                        <Select.Option key={on.id} value={on.id}>
                                            {on.name}
                                        </Select.Option>
                                    );
                                })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                    { mode === 'edit' &&
                        <Col span={8}>
                            <FormItem
                                label={'Password'}
                                labelCol={fullColWidth}
                            >
                                <Button
                                    className='btn-edit'
                                    onClick={handlePasswordChange}
                                >Edit</Button>
                            </FormItem>
                        </Col>
                    }
                    
                    <Col className='checkbox-item' span={4}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'is_migrated'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='is-migrated-input'
                                className={"is-migrated-input"}
                            >
                                Is migrated?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col className='checkbox-item' span={4}>
                        {/* Test 006 */}
                        <FormItem
                            labelCol={fullColWidth}
                            hasFeedback
                            colon={false}
                            name={'us_only'}
                            valuePropName="checked"
                        >
                            <Checkbox
                                data-testid='us-only-input'
                                className={"us-only-input"}
                            >
                                US only?
                            </Checkbox>
                        </FormItem>
                    </Col>
                    
                </Row>
                <Row justify={'end'}>
                    <Col span={5}>
                        <Button className='save-doctor-button' type="primary" htmlType="submit">
                            {buttonText}
                        </Button>
                    </Col>

                </Row>
            </Spin>
        </Form>
    );

}

export default IGPEditForm;
