import { createSlice } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router';
import { AppDispatch, RootState } from "../stores/retina-enabled-store";
import { ROOT_URL } from '../constants';
import { editAndGoToExamInInternalTab } from './patient-exam-slice';
import { IAdminPatientListItem } from './patient-list-slice';

export interface IPatientExamRooms {
    currentExamRoom: string;
    rooms: IExamRoom[];
    shouldDisplayMultiTabs: boolean;
}

export interface IExamRoomPatient {
    key: number;
    exam_id: number;
    flag: boolean;
    pre_reviewer: string;
    last_name: string;
    first_name: string;
    referral_type: string;
    latest_visit: string;
    omdr: string;
    province: string;
    od: string;
    od_group: string;
    omdc: number | '';
    omdc_status: string;
    dob: string;
    age: number;
    visits: number;
    igp_status: string;
    igp: string;
    egp_status: string;
    pr_alerts: string[];
    special_notes: string;
    status: string;
    alerts: string;
    test_patient: boolean
    od_integrated: boolean;
    od_retina_only: boolean;
    od_essentials_only: boolean;
    filter_type: string;
    isOdOrientating: boolean;
    isOdSpecialAttention: boolean;
    isUsOnly: boolean;
    is_inactive: boolean;
    fax: string;
    fax2: string;
    igp_fax: string;
    gp_status: string;
}

export interface IExamRoom {
    examId: number;
    patient: IExamRoomPatient | IAdminPatientListItem;
    patientName: string;
}

const initialState: IPatientExamRooms = {
    currentExamRoom: '',
    rooms: [],
    shouldDisplayMultiTabs: false,
}

export const removeExamFromTab = (targetKey:string, navigate: NavigateFunction) => (dispatch: AppDispatch, getState: () => RootState) => {

    const {patientExams: {currentExamRoom, rooms}} = getState();

    // If in internal tabs view
    if(rooms.length){
        // save the index in exam rooms array that has been removed

        let lastIndex = 0;
        rooms.forEach((exam, i) => {
            if(exam.examId === Number(targetKey)) {
                lastIndex = i;
            }
        });
        // remove the exam from exam rooms
        const newExamRooms = rooms.filter(exam => exam.examId !== Number(targetKey));

        // if the active tab (current exam) is removed, set the next tab (next exam) as active
        // if the active tab (current exam) is the right-most tab, set the previous item as
        // active tab
        if(currentExamRoom === targetKey && newExamRooms && newExamRooms.length){
            let currentExamId;
            if (lastIndex < newExamRooms.length){
                currentExamId = newExamRooms[lastIndex].examId;
            } else {
                currentExamId = newExamRooms[newExamRooms.length-1].examId;
            }
            dispatch(editAndGoToExamInInternalTab(currentExamId.toString(), navigate));
        }
        dispatch(setPatientExamRoomsData({rooms: newExamRooms}));
    } else {
        navigate(ROOT_URL);
    }
};


export const patientExamRoomsSlice = createSlice({
    name: 'patientExamRoomsSlice',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPatientExamRoomsData: (state, action) => {
            // sample action.payload {key:'memberId', value: '12345'}
            if (action.payload) {
                return {
                    ...state,
                    ...action.payload
                }
            }
        },
        clearPatientExamRoomsData: () => initialState,
        addToExamRooms: (state, action) => {
            if (action.payload) {
                const newRooms = state.rooms.find(exam => exam.examId === action.payload.examId) ?
                state.rooms : [...state.rooms, action.payload];
                return {
                    ...state,
                    rooms: newRooms
                }
            }
        },
        removeFromExamRooms: (state, action) => {
            return {
                ...state,
                rooms: state.rooms.filter(exam => exam.examId !== action.payload)
            }
        },
        openMultiTabsView: (state) => {
            state.shouldDisplayMultiTabs = true;
        },
        closeMultiTabsView: (state) => {
            state.shouldDisplayMultiTabs = false;
        }

    },

});
// export the actions related to UsInsurance
export const { addToExamRooms, removeFromExamRooms, clearPatientExamRoomsData, setPatientExamRoomsData, openMultiTabsView, closeMultiTabsView
    } = patientExamRoomsSlice.actions;

export default patientExamRoomsSlice.reducer;