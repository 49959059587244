import { ChangeEvent, Fragment } from 'react';
import { Card, Checkbox, Col, Input, Row } from 'antd';
import { localizedText } from '../../../localizedText';
import '../../../../static/css/components/patient-exam-allergies.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type ComponentProps = {
    allergies: string;
    onAllergiesChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    allergiesNone: boolean;
    onAllergiesNoneChange: (e: CheckboxChangeEvent) => void;
    gptResultState: { [x: string]: string };
}

const GptPatientExamAllergies = ({ allergies, onAllergiesChange, allergiesNone, onAllergiesNoneChange,
    gptResultState }: ComponentProps) => {
    const { ALLERGIES_TITLE, NKDA_TEXT } = localizedText;

    return (
        <Card className={'component-input-card patient-exam-allergies gpt-patient-exam-allergies'} bordered={false}>
            <Fragment>
                <Row className="checkboxes mb-0-5rem">
                    <Col className="col-heading">
                        <div className="heading">{ALLERGIES_TITLE}</div>
                    </Col>
                    <div className={`${gptResultState['allergiesNone']}`}>
                        <Col className="col-nkda">
                            <Checkbox
                                data-testid='none-checkbox'
                                disabled={allergies.length > 0}
                                checked={allergiesNone}
                                onChange={onAllergiesNoneChange}
                            >
                                {NKDA_TEXT}
                            </Checkbox>
                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col>
                        <div className={`${gptResultState['allergies']}`}>
                            <Input.TextArea
                                data-testid='allergies-input'
                                disabled={allergiesNone}
                                className="component-input"
                                autoSize={false}
                                value={allergies}
                                onChange={onAllergiesChange}
                            />
                        </div>
                    </Col>
                </Row>
            </Fragment>
        </Card >
    );
}

export default GptPatientExamAllergies;
