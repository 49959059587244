import { Row, Col } from 'antd';
import PatientExamOMDRRETextInjector from './patient-exam-omdr-re-text-injector';
import PatientExamNotesToODAndGP from './patient-exam-notes-to-od-and-gp';
import PatientExamNotesToMOA from './patient-exam-notes-to-moa';
import PatientExamFUOptionsOMDRRE from './patient-exam-fu-options-omdr-re';
import PatientExamSecondOpinionSelectors from './patient-exam-second-opinion-selectors';
import OMDSubmitButtons from './omd-submit-buttons';

const OmdrReReviewerBand = ({ isOMDReviewed }: { isOMDReviewed: boolean }) => {
    return (
        <Row gutter={8}>
            <Col span={3}>
                <PatientExamOMDRRETextInjector disabled={isOMDReviewed} />
            </Col>
            <Col span={12}>
                <PatientExamNotesToODAndGP disabled={isOMDReviewed} />
            </Col>
            <Col span={5}>
                <PatientExamNotesToMOA disabled={isOMDReviewed} />
            </Col>
            <Col span={4}>
                <PatientExamFUOptionsOMDRRE disabled={isOMDReviewed} />
                <PatientExamSecondOpinionSelectors disabled={isOMDReviewed} />
                <OMDSubmitButtons disabled={isOMDReviewed} />
            </Col>
        </Row>
    );
};

export default OmdrReReviewerBand;
