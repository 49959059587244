import { useEffect } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Col, Row, } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import MaculaHistoryImage from './macula-history-image';
import * as Constants from '../../constants';
import { IMaculaHistoricalAnalysisItem, fetchMaculaHistoricalAnalysis } from '../../reducers/macula-historical-analysis-slice';

const MaculaHistoricalAnalysis = ({ side, imageCount }: { side: string, imageCount: number }) => {

    const dispatch = useCare1AppDispatch();

    const examId = useCare1AppSelector(store => store.examData[Constants.EXAM_ID]);

    const right_macula_historical_analysis = useCare1AppSelector(store => store.maculaHistoricalAnalysis.right_macula_historical_analysis);
    const left_macula_historical_analysis = useCare1AppSelector(store => store.maculaHistoricalAnalysis.left_macula_historical_analysis);

    const bandColumnWidth = 6;
    const IMAGES_PER_ROW = 4;

    useEffect(() => {
        if (examId) {
            dispatch(fetchMaculaHistoricalAnalysis());
        }
    }, [examId, dispatch]);


    const getImageCards = () => {
        let currentImages: IMaculaHistoricalAnalysisItem[];

        if (side === Constants.RIGHT_SIDE_TEXT) {
            currentImages = right_macula_historical_analysis || [];
        } else {
            currentImages = left_macula_historical_analysis || [];
        }

        const imageSlice = [...currentImages].reverse();

        const sideImageCards = [];

        imageSlice.forEach((image, index) => {
           
            const isAwarenessSet = image.photo !== '';
            let photoUrl = image.photo;
            if (photoUrl === '') {
                photoUrl = side === 'right' ? image.right_fundus_photo : image.left_fundus_photo;
            }

            sideImageCards.push(
                <Col key={index} className={'image-col'} span={bandColumnWidth}>
                    <Card key={index} bordered={false}>
                        <MaculaHistoryImage
                            isAwarenessSet={isAwarenessSet}
                            side={side}
                            photoUrl={photoUrl}
                            examDate={image.exam_date_utc}
                            idx={index}
                            style={{}}
                            borderClass={''}
                            record={image}
                        />
                    </Card>
                </Col>
            );
        });

        // Display blank image for empty images
        const numImages = sideImageCards.length;
        for (let i = numImages; i < IMAGES_PER_ROW; i += 1) {
            sideImageCards.push(
                <Col key={i} span={bandColumnWidth}>
                    <Card key={i} bordered={false}>
                        <div className='blank-history-image'>
                            <PictureOutlined />
                        </div>
                    </Card>
                </Col>
            );
        }

        return sideImageCards;
    }

    
    const heading = side === 'right' ? 'Right Historical Analysis' : 'Left Historical Analysis';

    return (
        <Card className={`patient-exam-fundus-history images-history-card`}  bordered={false}>

            <div className="maculaHistoryHeading">
                <span>{heading}</span>
            </div>

            <Card bordered={false}>
                {/* OMDs top row should be oldest images and remaining rows should be newest images */}
                <>
                    <Row className='image-row imageRow1'>
                        { getImageCards()}
                    </Row>
                </>
            </Card>
        </Card>
    );
}

export default MaculaHistoricalAnalysis;