import { Menu, Row, Badge } from 'antd';
import type { MenuProps } from 'antd';
import { useCare1AppDispatch } from '../../apps/care1-hooks';

import '../../../static/css/components/patient-list-menu-science.scss';
import { localizedText } from '../../localizedText';

const PatientListMenuScience = () => {
    
    type MenuItem = Required<MenuProps>['items'][number];
    
    const dispatch = useCare1AppDispatch();

    const handleScienceOctRnflNearMissSelection = () => {
        window.open('/admin_oct_rnfl_near_miss/', '_blank')?.focus();

    }

    const handleScienceVfNearMissSelection = () => {
        window.open('/admin_vf_near_miss/', '_blank')?.focus();

    }

    const handleScienceGptChatNearMissSelection = () => {
        window.open('/admin_gpt_chat_near_miss/', '_blank')?.focus();

    }

    const handleScienceCsvNearMissSelection = () => {
        window.open('/admin_csv_near_miss/', '_blank')?.focus();

        
    }

    const handleHealthCheckSelection  = () => {
        window.open('/services-health-check/', '_blank')?.focus();
        
    }

    const items: MenuItem[] = [
        {label: 'OCT RNFL Near Miss', key: 'science_oct_rnfl_near_miss', onClick: handleScienceOctRnflNearMissSelection},
        {label: 'VF Near Miss', key: 'science_vf_near_miss', onClick: handleScienceVfNearMissSelection},
        {label: 'Exam Metrics Near Miss', key: 'science_gpt_chat_near_miss', onClick: handleScienceGptChatNearMissSelection},
        {label: 'CSV Near Miss', key: 'science_csv_near_miss', onClick: handleScienceCsvNearMissSelection},
        {label: 'Health Check', key: 'health_check_dashboard', onClick: handleHealthCheckSelection},
    ]

    return (
        <Row className='patient-list-menu-science'>
            <Menu
                mode='horizontal'
                items={items}
            />
                
        </Row>

    )
}

export default PatientListMenuScience;