import { Modal, Button, Form } from 'antd';
import '../../../static/css/components/od-group-practice-modal.scss';

// Action imports
import { SaveOutlined } from '@ant-design/icons';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { toggleOutboundOmdEditModal } from '../../reducers/doctor-slice';
import OutboundOmdForm from './outbound-omd-form';
import { IOutboundOMDDetails, useEditOutboundOmdMutation } from '../../services/doctor-api';

const OutboundOmdEditModal = () => {

    const isVisible = useCare1AppSelector(store => store.doctorSlice.showOutboundOmdEditModal);
    const referralOmd = useCare1AppSelector(store => store.examData.referral_omd);


    const [editOutboundOmd] = useEditOutboundOmdMutation();

    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const handleModalClose = () => {
        dispatch(toggleOutboundOmdEditModal());
    };

    const closeButton = (
        <Button
            key='close-button'
            onClick={handleModalClose}
            className='close-button'
        >
            Close
        </Button>
    );
    const saveButton = (
        <Button
            key='save-button'
            onClick={() => form.submit()}
            className='save-button'
            icon={<SaveOutlined />}
        >
            Save
        </Button>
    );

    const onFinish = async (values: IOutboundOMDDetails) => {
        try {
            // we use the editOmd function after the form is complete and submit/save button is clicked
            // unwrap() gives the access to error or success payload
            const result = await editOutboundOmd({...values, id: referralOmd}).unwrap();
            if (result.success) {
                Modal.info({
                    className: 'info-modal',
                    title: 'OMD updated successfully.',
                    onOk: () => {
                        dispatch(toggleOutboundOmdEditModal());
                    }
                });
            } else {
                throw new Error(result?.error);
            }
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Error',
            });
        }
    };

    return (
        <Modal
            className='od-group-practice-modal clinic-location-modal'
            open={isVisible}
            closable={false}
            title={`Edit OMD`}
            footer={[closeButton, saveButton]}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <OutboundOmdForm onFinish={onFinish} form={form} />
        </Modal>
    );
}

export default OutboundOmdEditModal;
