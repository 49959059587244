import { useParams, useNavigate } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { setHelpDeskField, setHelpDeskModalOpen } from '../../reducers/help-desk-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { convertTimeToLongFormatLabel } from '../../helpers/utilities';
import * as Constants from '../../constants';

type ComponentProps = {
    text: string,
    afterOpenEvent?: () => void
}

const HelpDeskButton = ({text, afterOpenEvent} : ComponentProps) => {

    const odUserEmail = useCare1AppSelector(store => store.user.odEmail);

    const params = useParams();
    const paramsExamId = Number(params[Constants.LATEST_EXAM_ID_FIELD]) || null;

    const patientFirstname  = useCare1AppSelector(store => store.patientDetails.details.first_name);
    const patientLastname  = useCare1AppSelector(store => store.patientDetails.details.last_name);
    const examDate  = useCare1AppSelector(store => store.examData.exam_date);
    const examId  = useCare1AppSelector(store => store.examData.id);

    // Redux and React Router Hooks
    const dispatch = useCare1AppDispatch();

    const defaultExamContent = () => {

        if (paramsExamId !== null) {
            if (paramsExamId === examId) {
                const value = 'This help desk request is for the following patient/exam:\n\n' +
                    `Patient Name: ${patientFirstname} ${patientLastname}\n` +
                    `Visit Date: ${convertTimeToLongFormatLabel(examDate)}\n` +
                    `Exam ID: ${examId}\n\n` +
                    `Please provide more info here:`;
        
                dispatch(setHelpDeskField({field: 'helpRequest', value: value}));
            }
        }
    }

    return (
        <Button
            className='help-desk-button'
            data-testid='help-desk-button'
            icon={<MailOutlined />}
            onClick={(event) => {
                event.stopPropagation();
                dispatch(setHelpDeskModalOpen(true));
                if(odUserEmail){
                    dispatch(setHelpDeskField({field: 'contactEmail', value: odUserEmail}));
                }
                if (afterOpenEvent) {
                    afterOpenEvent();
                }
                else {
                    defaultExamContent();
                }
            }}
            type='primary'
        >
            {text}
        </Button>
    );
}

export default HelpDeskButton;
