import { useEffect, useState } from 'react';
import { Row, Col, Table, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Column } from '@ant-design/plots';

import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import '../../../static/css/components/od-dashboard.scss';
import { IOdDashboardLastSixMonthsUploadsResponseItem } from '../../services/od-dashboard-api';

type ComponentProps = {
    title: string,
    data: IOdDashboardLastSixMonthsUploadsResponseItem[],
}

const VisitsUploadedGraph = ({ title, data }: ComponentProps) => {

    const dispatch = useCare1AppDispatch();

    const config = {
        theme: "classicDark",
        data,
        padding: 'auto',
        xField: 'month',
        yField: 'total_uploaded',
        legend: false,
        axis: {
            x: {
                labelStroke: 'white',
                lineStroke: 'white',
                tickStroke: 'white',
                tickStrokeOpacity: 1,
                line: true,
                tick: true,
            },
            y: {
                labelStroke: 'white',
                lineStroke: 'white',
                tickStroke: 'white',
                tickStrokeOpacity: 1,
                tickLineDash: [1, 0],
                lineLineDash: [1, 0],
                labelLineDash: [1, 0],
                label: true,
                line: true,
                tick: true,
            },
        },
        interaction: {
            tooltip: {
                render: (e: any, { title, items }: any) => {
                    const currentBar = data.filter((d) => d.month === title)[0];
                    return (
                        <div key={title} className='bar-row'>
                            <Row>
                                <Col span={3} className='bar-value'>{currentBar.total_uploaded}</Col>
                                <Col span={21} className='bar-name'>Total Uploaded</Col>
                            </Row>
                            {currentBar.od_odgrouppractice_province !== 'MB' && <Row>
                                <Col span={3} className='bar-value'>{currentBar.uploads_with_oct}</Col>
                                <Col span={21} className='bar-name'>Uploads with OCT</Col>
                            </Row>}
                            {currentBar.od_odgrouppractice_province === 'MB' && <Row>
                                <Col span={3} className='bar-value'>{currentBar.uploads_with_mini_dtc}</Col>
                                <Col span={21} className='bar-name'>Uploads with Mini-DTC</Col>
                            </Row>}
                            <Row>
                                <Col span={3} className='bar-value'>{currentBar.cataract_referrals}</Col>
                                <Col span={21} className='bar-name'>Cataract Outbound Referrals</Col>
                            </Row>
                        </div>
                    );
                },
            },
        },
    };

    return (
        <div className="visits-uploaded-graph">
            <Row>
                <Col span={24}>
                    <div className='title'>
                        {title}
                    </div>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <div className='graph-container'>
                        <Column {...config} />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default VisitsUploadedGraph