import { Fragment, useEffect, useState, useMemo } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import { Card, Input, Form, Row, Checkbox } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { getTxAlgoKeywordOptions, addTxAlgoKeywordColors, renderTxAlgoKeywordString,
    } from '../../helpers/patient-exam-convert';
import { localizedText } from '../../localizedText';
import { setExamDataValue } from '../../reducers/patient-exam-slice';
import '../../../static/css/components/patient-exam-impressions.scss';

const PatientExamImpressions = ({ disabled }: { disabled?: boolean }) => {
    const { EXAM_IMPRESSION_TITLE } = localizedText;

    const userIsOD = useCare1AppSelector(store => store.user.isOD);
    const userIsOMDC = useCare1AppSelector(store => store.user.isOMDC);
    const userIsOMDR = useCare1AppSelector(store => store.user.isOMDR);
    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);

    const [ form ] = Form.useForm();
    const dispatch = useCare1AppDispatch();

    // Checkbox for admin to toggle in and out of editing mode.
    const [adminIsEditing, setAdminIsEditing] = useState(false);

    const impression = useCare1AppSelector(store => store.examData.impression);
  

    // Get the color coded keywords for the impression component
    const txAlgo3Keywords = useCare1AppSelector(store => store.options.txAlgoKeywords);
    const txAlgoKeywordOptions = useMemo(() => {
        return getTxAlgoKeywordOptions(txAlgo3Keywords, 'impressions');
    }, [txAlgo3Keywords]);
    const keywordOptions = userIsOMDR || userIsADMIN ? txAlgoKeywordOptions : null;

    // Add the color code tags to the impressions string
    const applyKeywordColorsImpression = useMemo(() => {
        return keywordOptions && (userIsOMDR || userIsADMIN)
            ? addTxAlgoKeywordColors(keywordOptions, impression) : null;
    }, [keywordOptions, impression, userIsOMDR, userIsADMIN]);

    // The component's own state now holds Ant Design field properties, including values, errors, etc.
    const [validationFields, setValidationFields] = useState<FieldData[]>([]);

    // equivalent of componentDidUpdate in this case
    useEffect(()=>{
        // Get values for Ant Design fields from the store whenever values update.
        let newValidationFieldValues = {
            impression: impression,
        }
        form.setFieldsValue(newValidationFieldValues);
    }, [impression, form])

    // Ant Design handler. This is called whenever a field is changed by the user using Ant Design controls.
    const onFieldsChange = (fields: FieldData[], allFields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name.length && !field.validating) {
                switch (field.name[0]) {
                    case 'impression':
                        dispatch(setExamDataValue(field.name[0], field.value));
                        break;
                    default:
                        break;
                }
            }
        });

        // We can simply overwrite all fields to update the state to all current values.
        setValidationFields(allFields);
    }

    return (
        <Form form={form} onFieldsChange={onFieldsChange} fields={validationFields}>
            <Card className={'component-input-card input-impression'} bordered={false}>
            <div className="heading">
                {EXAM_IMPRESSION_TITLE}
                { userIsADMIN &&
                    <Checkbox
                        onChange={()=> setAdminIsEditing(!adminIsEditing)}
                        className='admin-edit-checkbox'
                    >
                        Admin Edit
                    </Checkbox>
                }
            </div>
                { (userIsOD || (userIsADMIN && adminIsEditing)) &&
                <Fragment>
                    <Form.Item help="" name='impression' initialValue={impression}>
                        {/* IMP 001, 002 */}
                        <Input.TextArea
                            data-testid='impression'
                            className="component-input"
                            autoSize={false}
                            placeholder="impression"
                            disabled={disabled}
                        />
                    </Form.Item>
                </Fragment>
            }
            {userIsOMDC &&
                // For OMDC & OMDR merge the four impressions into a numerical list
                // {IMP 006, 007, 008, 009}
                <div className='merged-impression-content'>
                    {impression && <Row data-testid='impression' className='merged-impression'>{impression}</Row>}
                </div>
            }
            {(userIsOMDR || (userIsADMIN && !adminIsEditing)) &&
                // For OMDR & Admin merge the four impressions into a numerical list and get the color keywords
                <div className='merged-impression-content'>
                    { impression &&
                        <Row className='merged-impression omdr'>
                            {
                                applyKeywordColorsImpression?.map(
                                    (string, index) => (<Fragment key={index}>{renderTxAlgoKeywordString(string)}</Fragment>)
                                )
                            }
                        </Row>
                    }
                </div>
            }
            </Card>
        </Form>
    );
}

export default PatientExamImpressions;