import { Fragment } from 'react';
import { Tabs } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ConfirmExitModal from './confirm-exit-modal';
import '../../static/css/shared/pei-exam-room-tabs.scss';
import { removeExamFromTab } from '../reducers/patient-exam-rooms-slice';
import { editAndGoToExamInInternalTab } from '../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';


const ExamTabs = () => {
    const MAX_NUMBER_TABS = 10;
    const patientExamRooms = useCare1AppSelector(store => store.patientExams.rooms);
    const currentPatientExamRoom = useCare1AppSelector(store => store.patientExams.currentExamRoom);
    const examDirty = useCare1AppSelector(store => store.examData.dirty);
    const shouldDisplayMultiTabs = useCare1AppSelector(store => store.patientExams.shouldDisplayMultiTabs);

    const dispatch = useCare1AppDispatch();
    const history = useNavigate();

    const switchCurrentPatientExamRoom = (key: string) => {
        // When a patient exam room tab is clicked, go to that exam instead.
        // However, when the exam is dirty, prompt first.
        if (examDirty) {
            ConfirmExitModal((confirmed) => {
                if(confirmed){
                    dispatch(editAndGoToExamInInternalTab(key, history));
                }
            })
        } else {
            dispatch(editAndGoToExamInInternalTab(key, history));
        }
    }

    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: 'add' | 'remove') => {
        if (action === 'remove') {
            if(examDirty){
                ConfirmExitModal((confirmed) => {
                    if(confirmed) {
                        dispatch(removeExamFromTab((targetKey as string), history));
                    }
                })
            } else {
                dispatch(removeExamFromTab((targetKey as string), history));
            }
        }
    };

    const tabsClassName = patientExamRooms && patientExamRooms.length > MAX_NUMBER_TABS ? 'max-tabs' : '';

    return(
        <>
            { (shouldDisplayMultiTabs && patientExamRooms && patientExamRooms.length) &&
                <Tabs
                    className={`patient-exam-tabs ${tabsClassName}`}
                    activeKey={currentPatientExamRoom.toString()}
                    type="editable-card"
                    onTabClick={switchCurrentPatientExamRoom}
                    hideAdd
                    onEdit={onEdit}
                >
                    {
                        patientExamRooms && patientExamRooms.map((examRoom) => {
                            return (
                                <Tabs.TabPane
                                    tab={
                                        <Fragment>
                                            <FileTextOutlined />
                                            <div className='patient-name'>{examRoom.patientName}</div>
                                        </Fragment>
                                    }
                                    key={examRoom.examId}
                                    closable
                                />
                            );
                        })
                    }
                </Tabs>
            }
        </>
    )
}

export default ExamTabs;
