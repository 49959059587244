import { useEffect, useState } from 'react';
import { Table} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

import { localizedText } from '../../localizedText';
import { IUploadReportItem } from '../../services/upload-report-api';
import '../../../static/css/components/sales-integration-report-table.scss';


interface IUploadReportTableItemWithKey extends IUploadReportItem {
    key: number;
}

interface ComponentProps {
    uploadReport: IUploadReportItem[] | undefined;
    uploadMonthColumn: string[] | undefined;
    colName: string | undefined;
}

const UploadReportTable = ({ uploadReport, uploadMonthColumn, colName }: ComponentProps) => {

    const { OF_TEXT, ITEMS_TEXT } = localizedText;

    const [scrollClass, setScrollClass] = useState('');

    const reports: IUploadReportTableItemWithKey[] | undefined = uploadReport?.map((v: IUploadReportItem) => { return { ...v, key: v['id'] } });

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const formatDateRange = (month: string): string => {
        return month;
    };

    const columns: ColumnsType<IUploadReportTableItemWithKey> = [{
        fixed: 'left',
        className: 'report-type-column',
        title: colName,
        dataIndex: colName,
        width: '8.5%',
        // sorter: (a, b) => compareStrings(a['program'], b['program']),
        render: (text, record) => <div data-testid={`report-type-${record.id}`}>{text}</div>
    }];

    // add week column
    uploadMonthColumn?.forEach((v, i) => {
        columns.push({
            className: `${v}-column`,
            title: formatDateRange(v),
            dataIndex: v,
            render: (text, record) => <div data-testid={`${v}-${record.id}`}>{text === 0 ? '' : text}</div>
        });
    });

    return (
        <div className={'patient-list-table sales-integration-report-table tableContent'}>
            <div className={scrollClass}>
                <Table
                    columns={columns}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '100', '200'],
                        defaultPageSize: 30,
                        showTotal: (total, range) => `${range[0]}-${range[1]} ${OF_TEXT} ${total} ${ITEMS_TEXT}`,
                    }}
                    dataSource={reports}
                    scroll={{ x: 800 + (uploadMonthColumn == undefined ? 0 : uploadMonthColumn.length) * 170 }}
                    sticky={true}
                />
            </div>
        </div>
    );

}

export default UploadReportTable;
