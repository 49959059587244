import React from 'react';
import { Select, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useCare1AppSelector } from '../../apps/care1-hooks';
import { IODStatusOption } from '../../reducers/options-slice';

interface DropdownProps {
    onSelect: (value: string) => void;
    defaultSelectedKey: string;
    selectedKey: string;
}

const SalesPageOdGroupPracticeStatusFilter: React.FC<DropdownProps> = ({ onSelect, defaultSelectedKey, selectedKey }) => {
    const odGroupPracticeStatuses: IODStatusOption[] = useCare1AppSelector(store => store.options.odStatuses);

    const handleMenuClick = (value: string): void => {
        onSelect(value);
    }

    return (
        <div className='od-group-practice-status-filter'>
            <Select
                className='dropdown-filter dropdown-filter-od-group-practice-status-filter'
                popupClassName='patient-list-dropdown'
                defaultValue={defaultSelectedKey}
                onSelect={handleMenuClick}
                value={selectedKey}
            >
                {odGroupPracticeStatuses && odGroupPracticeStatuses.map((odGroupPracticeStatus) => (
                    <Select.Option key={odGroupPracticeStatus.value} value={odGroupPracticeStatus.value}>
                        {odGroupPracticeStatus.label}
                    </Select.Option>
                ))}
                <Select.Option key='all' value='all'>
                    {'All ODs'}
                </Select.Option>
            </Select>
            <Tooltip
                className='sales-report-filter-tooltip'
                placement='right'
                overlayInnerStyle={{width: "530px"}}
                title={
                    <div className='sales-page-tooltip-div'>
                        <ul>
                            <li>Active doctors and clinics are those who are currently paying software fees to Care1.</li>
                            <li>Oh hold doctors and clinics may or may not currently be paying software fees. These doctors and clinics have, for some reason, asked that their accounts be placed On hold. For example, maternity leave.</li>
                            <li>Inactive doctors and clinics are those who have left Care1. They are no longer paying software fees.</li>
                        </ul>
                    </div>
                }
            >
                <InfoCircleOutlined />
            </Tooltip>
        </div>
    );
};

export default SalesPageOdGroupPracticeStatusFilter;
