import React, { useEffect, useState } from 'react';
import { Table, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { EditOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { FIRST_NAME_FIELD, LAST_NAME_FIELD } from '../../../constants';
import { localizedText } from '../../../localizedText';
import { compareStrings } from '../../../helpers/sorting';
import { IUploadReconcilerPatientMatchingTableItem } from '../../../services/upload-reconciler-api';
import LatestVisitButton from '../../retina/button-latest-visit';

import '../../../../static/css/components/patient-matching-list-table.scss';

type AppProps = {
    tableList: IUploadReconcilerPatientMatchingTableItem[],
    patientMatchingOps: {[k: number]: string},
    setPatientMatchingOps: (v: {[k: number]: string}) => void,
}

const patientMatchingActionOptions = [
    {
        value: 'na',
        label: 'N/A',
    },
    {
        value: 'target',
        label: 'Target',
    },
    {
        value: 'delete',
        label: 'Delete',
    },
]

const PatientMatchingListTable = ({ tableList, patientMatchingOps, setPatientMatchingOps }: AppProps) => {
    const { OF_TEXT, ITEMS_TEXT } = localizedText;

    const [scrollClass, setScrollClass] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const getOp = (id: number) => {
        if (id in patientMatchingOps) {
            return patientMatchingOps[id];
        }
        return 'na';
    }

    const setOp = (id: number, v: string) => {
        const res = {...patientMatchingOps};

        let targetSet = false;
        for (let i=0; i<tableList.length; i++) {
            if (getOp(tableList[i].id) == 'target') {
                targetSet = true;
                break;
            }
        }

        if (targetSet === false) {
            res[id] = v;
        }
        else {
            if (v !== 'target') {
                res[id] = v;
            }
        }

        setPatientMatchingOps(res);
    }

    const nameColumns: ColumnsType<IUploadReconcilerPatientMatchingTableItem> = [
        {
            className: 'patient_name-column',
            title: 'Patient Name',
            dataIndex: 'patient_name',
            sorter: (a, b) => compareStrings(`${a}`, `${b}`),
            render: (text, record) => <div data-testid={`'patient_name'-${record.key}`}>{text}</div>
        },
        {
            className: 'dob-column',
            title: 'DOB',
            dataIndex: 'dob',
            sorter: (a, b) => compareStrings(`${a}`, `${b}`),
            render: (text, record) => <div data-testid={`'dob'-${record.key}`}>{text}</div>
        },
        {
            className: 'insurance-column',
            title: 'PHN',
            dataIndex: 'insurance',
            sorter: (a, b) => compareStrings(`${a}`, `${b}`),
            render: (text, record) => <div data-testid={`'insurance'-${record.key}`}>{text}</div>
        },
        {
            className: 'view-column',
            title: '',
            dataIndex: 'exam_id',
            render: (text, record) => <LatestVisitButton
                className="button-latest-visit"
                buttonText={'View'}
                visitID={text}
                newTab={true}
            />,
        },
        {
            className: 'action-column',
            title: 'Merge Patients',
            width: '30px',
            render: (text, record) =>
                <Select
                    className="patient-matching-action-select"
                    optionFilterProp="label"
                    options={patientMatchingActionOptions}
                    defaultValue={'na'}
                    value={getOp(record.id)}
                    onChange={(v) => setOp(record.id, v)}
                />,
        }
    ];

    return (
        <div className={'patient-matching-list-table'}>
            <div className={scrollClass}>
                <Table
                    columns={nameColumns}
                    dataSource={tableList}
                    pagination={false}
                />
            </div>
        </div>
    );
}

export default PatientMatchingListTable;