import { useEffect } from 'react';
import { Col, InputNumber, Row, Form } from 'antd';
import { setExamDataValue } from '../../../reducers/patient-exam-slice';
import { allowPosNumberOnlyKeys } from '../../../helpers/utilities';
import ExamMetricValidationModal from './exam-metric-validation-modal';
import { ISHIHARA_VALIDATION_MSG, ISHIHARA2_VALIDATION_MSG, OD_ISHIHARA2_FIELD, OD_ISHIHARA_FIELD, OS_ISHIHARA2_FIELD,
    OS_ISHIHARA_FIELD } from '../../../constants';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { FieldData } from 'rc-field-form/lib/interface';

type ComoponentProps = {
    disabled?: boolean,
    side: 'od' | 'os',
    ishiharaPlaceholderValue?: string,
    ishiharaPlaceholderValue2?: string,
}

const PatientExamIshihara = ({ disabled, side, ishiharaPlaceholderValue, ishiharaPlaceholderValue2 }: ComoponentProps) => {

    const od_ishihara = useCare1AppSelector(store => store.examData[OD_ISHIHARA_FIELD]);
    const os_ishihara = useCare1AppSelector(store => store.examData[OS_ISHIHARA_FIELD]);
    const od_ishihara2 = useCare1AppSelector(store => store.examData[OD_ISHIHARA2_FIELD]);
    const os_ishihara2 = useCare1AppSelector(store => store.examData[OS_ISHIHARA2_FIELD]);
    const ishiharaField = side === 'od' ? OD_ISHIHARA_FIELD : OS_ISHIHARA_FIELD;
    const ishihara2Field = side === 'od' ? OD_ISHIHARA2_FIELD : OS_ISHIHARA2_FIELD;
    const ishiharaValue = side === 'od' ? od_ishihara : os_ishihara;
    const ishihara2Value = side === 'od' ? od_ishihara2 : os_ishihara2;

    // Yellow warning border should display for OD users only
    const userIsOD = useCare1AppSelector(store => store.user.isOD);

    const dispatch = useCare1AppDispatch();
    const [form] = Form.useForm();

    const validateIshiharaValue = () => {
        const value = Number(ishiharaValue);
        if (typeof (value) === 'number' && (value < 0 || value > 30)) {
            dispatch(setExamDataValue(ishiharaField, ''));
            ExamMetricValidationModal(ISHIHARA_VALIDATION_MSG);
        }
    };

    const validateIshihara2Value = () => {
        const value = Number(ishihara2Value);
        if (typeof (value) === 'number' && (value < 0 || value > 30)) {
            dispatch(setExamDataValue(ishihara2Field, ''));
            ExamMetricValidationModal(ISHIHARA2_VALIDATION_MSG);
        }
    };

    useEffect(()=>{
        form.setFieldsValue({
            [ishiharaField]: ishiharaValue,
            [ishihara2Field]: ishihara2Value
        });
    }, [ishiharaField, ishihara2Field, ishiharaValue, ishihara2Value, form])

    const onFieldsChange = (fields: FieldData[]) => {
        fields.forEach((field) => {
            if (Array.isArray(field.name) && field.name.length && !field.validating) {
                switch (field.name[0]) {
                    case OD_ISHIHARA_FIELD:
                    case OS_ISHIHARA_FIELD:
                    case OD_ISHIHARA2_FIELD:
                    case OS_ISHIHARA2_FIELD:
                        // Format value to a max of two digits
                        dispatch(setExamDataValue(field.name[0], formatValue(field.value)));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    // All Ishihara entries should be in the format of # or ##
    const formatValue = (value: string | number | undefined) => {
        const regex = /^([0-9]?)([0-9]?).*$/;
        const matches = String(value).match(regex);
        return matches && matches[1] && matches[2] ? (`${matches[1]}${matches[2]}`)
            : matches && matches[1] ? matches[1]
            : '';
    }

    return (
        <Form form={form} onFieldsChange={onFieldsChange}>
            <Row gutter={2}>
                <Col span={11} className="ishihara">
                    <Form.Item
                        help=""
                        validateStatus={ userIsOD && !od_ishihara && !os_ishihara ? 'warning' : '' }
                        name={ishiharaField}
                        initialValue={ishiharaValue}
                    >
                        {/* ISH 001, ISH 002, ISH 004 ISH 005, ISH 007 */}
                        {/* Use onKeyPress event to prevent letters */}
                        {/* Use formatter to enforce a max of two digits */}
                        <InputNumber
                            disabled={disabled}
                            className="input-component"
                            data-testid={ishiharaField}
                            size={'small'}
                            step={1}
                            placeholder={ishiharaPlaceholderValue}
                            onKeyPress={event => allowPosNumberOnlyKeys(event)}
                            formatter={formatValue}
                            onBlur={validateIshiharaValue}
                        />
                    </Form.Item>
                </Col>
                <Col span={2} className="ishihara-divider">
                    /
                </Col>
                <Col span={11} className="ishihara2">
                    <Form.Item
                        help=""
                        validateStatus={ !od_ishihara2 && !os_ishihara2 ? 'warning' : '' }
                        name={ishihara2Field}
                        initialValue={ishihara2Value}
                    >
                        {/* ISH 003, ISH 006 */}
                        {/* Use onKeyPress event to prevent letters */}
                        {/* Use formatter to enforce a max of two digits */}
                        <InputNumber
                            disabled={disabled}
                            className="input-component"
                            data-testid={ishihara2Field}
                            size={'small'}
                            step={1}
                            placeholder={ishiharaPlaceholderValue2}
                            onKeyPress={event => allowPosNumberOnlyKeys(event)}
                            formatter={formatValue}
                            onBlur={validateIshihara2Value}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

export default PatientExamIshihara;