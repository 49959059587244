import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { downloadBinaryFile } from '../helpers/patient-exam-convert';
import dayjs, { Dayjs } from 'dayjs';
import { Modal } from 'antd';
import { ERROR_MESSAGE_TITLE, GP_LETTERS_LABEL, IGP_LETTERS_LABEL } from '../constants';
import { getCsrfToken } from '../helpers/utilities';
import { logout } from './user-slice';
import { apiRequest, httpFileRequest } from '../services/api-request';
import { IExamRoom, removeFromExamRooms, IExamRoomPatient } from './patient-exam-rooms-slice';
import { IOptions } from './options-slice';
import { examApi } from '../services/exam-api';
import { RootState } from "../stores/retina-enabled-store";


export interface IOmdList {
    key: number;
    name: string;
}

export interface ILettersRequest {
    examData: {
        id: number;
    },
    user: {
        csrfToken: string;
    },
    letter: {
        ipcOmdc: string;
        email?: string;
        fax?: string;
        message?: string;
        currentLetterType?: string;
    }
}
export interface IIpcOmdcRequest extends ILettersRequest{
    letter: {
        ipcOmdc: string;
        ipcDate: Dayjs;
    }
}

export type IBulkFaxLetterType = 'omdrgp' | 'omdcgp' | 'omdrgpenabled' | 'rluomdrgp' | 'rluomdcgp';
export type IBulkFaxIGPLetterType = 'omdrintgp' | 'rluomdrintgp';

export interface ILettersInitialState {
    letterModalOpen: boolean;
    currentLetterType: string;
    currentLetterLabel: string;
    actionType: string;
    ipcOmdc: string;
    ipcDate: Dayjs | '';
    email: string;
    fax: string;
    message: string;
    status: string;
    error: string;
    omdList: IOmdList[],
}
// TODO move IExamRoom and IExamRoomPatient interface to future exam room slice


// Can be used in admin patient list table


const initialState: ILettersInitialState = {
    letterModalOpen: false,
    currentLetterType: '',
    currentLetterLabel: '',
    actionType: '',
    ipcOmdc: '',
    ipcDate: '',
    email: '',
    fax: '',
    message: 'Please see the attached referral.',
    status: 'idle',
    error: '',
    omdList: [],
};

export const getLetterRequest = createAsyncThunk(
    'letter/getLetterRequest',
    async (type: string, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }, examData: {id} } = getState() as { user: {csrfToken: string}, examData: {id: number}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/${id}/letter/${type}/`;
        const data = '{}'
        try {
            // const response = await apiRequest.post(URL, csrfToken, data);
            const response = await httpFileRequest('POST', URL, csrfToken, data);
            return response;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const getRluOmdLetterRequest = createAsyncThunk(
    'letter/geRlutLetterRequest',
    async (_:void, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }, examData: {id} } = getState() as RootState;
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/omd_letter/${id}`;
        const data = '{}'
        try {
            // const response = await apiRequest.post(URL, csrfToken, data);
            const response = await httpFileRequest('POST', URL, csrfToken, data);
            return response;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const getPatientHandoutPdfRequest = createAsyncThunk(
    'letter/getPatientHandoutPdfRequest',
    async (_:void, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }, examData: {id} } = getState() as RootState;
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patient_handout_pdf/${id}`;
        try {
            const response = await httpFileRequest('POST', URL, csrfToken);
            return response;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const sendRluOmdEmailOrFaxRequest = createAsyncThunk(
    'letter/sendRluOmdEmailOrFax',
    async (type:string, {dispatch, getState, rejectWithValue}) => {
        
        const { examData: { id }, user: { csrfToken }, letter: {email, fax, message, ipcOmdc}} = getState() as ILettersRequest;

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const filteredFaxNumber = fax && fax.replace(/\D/g, '');

        const formData = new FormData();
        formData.append('message_type', type);
        formData.append('message_content', message ?? '');
        formData.append('email_address', email ?? '');
        formData.append('fax_number', filteredFaxNumber ?? '');
        formData.append('omd', ipcOmdc);

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/admin/exam/${id}/rlu_omd_email_or_fax/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, formData);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const sendFaxRequest = createAsyncThunk(
    'letter/sendFax',
    async (data: {examId: number, type: string}, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/${data.examId}/fax/${data.type}/`;

        try {
            const response = await apiRequest.post(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const getOmdListRequest = createAsyncThunk(
    'letter/getOmdList',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }} = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/omds/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const getOmdContactRequest = createAsyncThunk(
    'letter/getOmdContact',
    async (omd_id: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }} = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/omds/${omd_id}/contact/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const getAdminLetterIpcInfoRequest = createAsyncThunk(
    'letter/getAdminLetterIpcInfo',
    async (examId:number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }} = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/letters/admin_letter_ipc_info/${examId}`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const setExamIpcOmdcRequest = createAsyncThunk(
    'letter/setExamIpcOmdc',
    async (_, {dispatch, getState, rejectWithValue}) => {
        
        const { user: { csrfToken }, examData: {id}, letter: {ipcOmdc, ipcDate} } = getState() as IIpcOmdcRequest;
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }
        const formData = new FormData();
        formData.append('id', id.toString());
        formData.append('ipc_omdc', ipcOmdc);
        formData.append('ipc_date', ipcDate.format('YYYY-MM-DD'));

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/set_ipc_omdc/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, formData);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const getIpcOmdcDataRequest = createAsyncThunk(
    'letter/getIpcOmdcData',
    async (id: number, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }} = getState() as { user: {csrfToken: string}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/${id}/ipc_omdc_data/`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const sendEmailOrFaxRequest = createAsyncThunk(
    'letter/sendEmailOrFax',
    async (type:string, {dispatch, getState, rejectWithValue}) => {
        
        const { examData: { id }, user: { csrfToken }, letter: {email, fax, message, currentLetterType, ipcOmdc}} = getState() as ILettersRequest;

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const filteredFaxNumber = fax && fax.replace(/\D/g, '');

        const formData = new FormData();
        formData.append('message_type', type);
        formData.append('message_content', message ?? '');
        formData.append('email_address', email ?? '');
        formData.append('fax_number', filteredFaxNumber ?? '');
        formData.append('letter_type', currentLetterType ?? '');
        formData.append('omd', ipcOmdc);

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/admin/exam/${id}/email_or_fax/`;

        try {
            const response = await apiRequest.post(URL, csrfToken, formData);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const sendBulkFaxRequest = createAsyncThunk(
    'letter/sendBulkFax',
    async ({examData} : {examData: {id:Number, fax_type:String}[]}, {dispatch, getState, rejectWithValue}) => {
        
        const { user: { csrfToken }} = getState() as { user: {csrfToken: string}};

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/admin/bulkfax/`;
        const data = JSON.stringify({exam_data: examData});

        try {
            const response = await apiRequest.post(URL, csrfToken, data);
            const res = response.data;
            if (res['success']) {
                examData.forEach(exam => {
                    dispatch(removeFromExamRooms(exam.id));
                })       
            } else {
                const errorInfo = `An error has occurred. ${res.error}`;
                Modal.error({
                    className: 'info-modal',
                    title: ERROR_MESSAGE_TITLE,
                    content: errorInfo,
                });
            }
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const bulkSendFaxLetters = createAsyncThunk(
    'letter/bulkSendFaxLetters',
    async (letterType: IBulkFaxLetterType, {dispatch, getState}) => {
        
        const { options: {doNotFaxNumbers}, patientExams: {rooms : examRooms} } = getState() as {options: {doNotFaxNumbers: string[]}, patientExams: {rooms: IExamRoom[]}}
        const gp1FaxExams: Omit<IExamRoom, 'patient'>[] = [];
        const gp2FaxExams: Omit<IExamRoom, 'patient'>[] = [];
        const unsentFaxPatientNames: string[] = [];

        examRooms.forEach(({examId, patientName, patient}) => {

            let sendingFax1 = false;
            let sendingFax2 = false;
            const faxNum = patient['fax'];
            const faxNum2 = patient['fax2'];

            // Also record the list of patient GPs that are not faxed due to being on the no-fax list.
            if (doNotFaxNumbers.find(n => n === faxNum)) {
                unsentFaxPatientNames.push(patientName + " GP 1");
            } else if (faxNum) {
                // For fax numbers not on the no-fax list, add them to the faxes to send list.
                gp1FaxExams.push({examId, patientName});
                sendingFax1 = true;
            }

            // OMDR-GP and OMDC-GP faxes also need to check the secondary GP.
            if (doNotFaxNumbers.find(n => n === faxNum2)) {
                unsentFaxPatientNames.push(patientName + " GP 2");
            } else if (faxNum2) {
                // For fax numbers not on the no-fax list, add them to the faxes to send list.
                gp2FaxExams.push({examId, patientName});
                sendingFax2 = true;
            }

            // If all possible GPs for a patient are in the no fax list, uncheck the patient.
            if (faxNum && !sendingFax1) {
                if ((faxNum2 && !sendingFax2) || !faxNum2) {
                    dispatch(removeFromExamRooms(examId));
                }
            }
            if (faxNum2 && !sendingFax2) {
                if ((faxNum && !sendingFax1) || !faxNum) {
                    dispatch(removeFromExamRooms(examId));
                }
            }

        })

        if (unsentFaxPatientNames.length !== 0) {
            const patientNames = unsentFaxPatientNames.join(',');
            const error = 'Faxes for the following patients will not be sent, because the fax number(s) match numbers in the do not call list:\n\n' + patientNames;

            Modal.error({
                className: 'info-modal',
                title: ERROR_MESSAGE_TITLE,
                content: error,
            });
        }

        const promptMsg = `Are you sure you want to send ${gp1FaxExams.length} ${GP_LETTERS_LABEL[letterType]}${gp2FaxExams.length ? ` and ${gp2FaxExams.length} ${GP_LETTERS_LABEL[letterType]}2` : ''} letter faxes?`;
        Modal.confirm({
            className: 'bulk-operation-confirmation-modal',
            title: '',
            content: promptMsg,
            okText: 'OK',
            cancelText: 'CANCEL',
            cancelButtonProps: {
                className: 'confirm-exit',
            },
            onOk: async () => {
                const gp1ExamIds = gp1FaxExams.map(exam => ({id: exam.examId, fax_type: letterType}));
                const gp2ExamIds = gp2FaxExams.map(exam => ({id: exam.examId, fax_type: `${letterType}2`}));

                gp1ExamIds.length && await dispatch(sendBulkFaxRequest({examData: gp1ExamIds}))
                gp2ExamIds.length && await dispatch(sendBulkFaxRequest({examData: gp2ExamIds}))
            },
        });
    }
)

export const faxOMDRGPIGPLetters = createAsyncThunk(
    'letter/faxOMDRGPIGPLetters',
    async (data: { examRooms: IExamRoom[], intLetterType: IBulkFaxIGPLetterType }, {dispatch, getState}) => {
        
        const { options } = getState() as {options: IOptions};
        const doNotFaxNumbers = options['doNotFaxNumbers'];
        const unsentFaxPatientNames: string[] = [];
        const gpFaxesToSend: IExamRoom[] = [];

        data.examRooms.forEach((exam) => {
            const patient = exam['patient'] as IExamRoomPatient;
            const faxIgp = patient['igp_fax'];

            // Also record the list of patient GPs that are not faxed due to being on the no-fax list.
            if (doNotFaxNumbers.find(n => n === faxIgp)) {
                unsentFaxPatientNames.push(exam['patientName'] + " IGP");
            }
            else if (faxIgp) {
                // For fax numbers not on the no-fax list, add them to the faxes to send list.
                gpFaxesToSend.push(exam);
            }
        })

        if (unsentFaxPatientNames.length !== 0) {
            const patientNames = unsentFaxPatientNames.join(',');
            const error = 'Faxes for the following patients will not be sent, because the fax number(s) match numbers in the do not call list:\n\n' + patientNames;

            Modal.error({
                title: ERROR_MESSAGE_TITLE,
                content: error,
            });
        }

        const promptMsg = `Are you sure you want to send ${gpFaxesToSend.length} ${IGP_LETTERS_LABEL[data.intLetterType]} letter faxes?`;

        Modal.confirm({
            className: 'bulk-operation-confirmation-modal',
            title: '',
            content: promptMsg,
            okText: 'OK',
            cancelText: 'CANCEL',
            cancelButtonProps: {
                className: 'confirm-exit',
            },
            onOk: async () => {
                // First, check if OD assignment is correct before sending.
                const validGpFaxesToSend = gpFaxesToSend.filter((exam) => {
                    const gp_status = exam['patient']['gp_status'];
                    const od_integrated = exam['patient']['od_integrated'];
                    const od_retina_only = exam['patient']['od_retina_only'];
                    const od_essentials_only = exam['patient']['od_essentials_only'];

                    if ((od_integrated === true && od_retina_only === true && od_essentials_only === true)
                        || (od_integrated === false && od_retina_only === false && od_essentials_only === false)) {

                        const error = 'Fax for patient ' + exam['patientName'] + ' to their internal GP was not sent due to problem with OD assignment. Please check OD status (Integrated vs Retina Enabled) and try again.';
                        Modal.error({
                            title: ERROR_MESSAGE_TITLE,
                            content: error,
                        });

                        return false;
                    }
                    else if (gp_status !== "reviewed") {
                        const error = 'Fax for patient ' + exam['patientName'] + ' to their GP was not sent. Internal GP have not been reviewed yet.';
                        Modal.error({
                            title: ERROR_MESSAGE_TITLE,
                            content: error,
                        });

                        return false;
                    }

                    return true;
                })
                .map((exam) => {
                    const od_retina_only = exam['patient']['od_retina_only'];
                    const letterType = od_retina_only ? 'omdrintgpenabled': data.intLetterType;

                    return {
                        id: exam.examId,
                        fax_type: letterType,
                    }
                })

                if (validGpFaxesToSend.length) {
                    const resData = await dispatch(sendBulkFaxRequest({examData: validGpFaxesToSend})).unwrap();
                    let examsToSetIgpStatus: {id:number, fax_type:String}[] = [];

                    if (resData && resData.success) {
                        // For a fully successful bullk fax, set all IGP statuses.
                        examsToSetIgpStatus = validGpFaxesToSend;
                        Modal.info({
                            className: 'info-modal',
                            title: `${validGpFaxesToSend.length} faxes successfully sent.`,
                        });
                    }
                    else if (!resData.success && resData.failed_exams) {
                        // Only set IGP statuses of exams that succeeded.
                        examsToSetIgpStatus = validGpFaxesToSend.filter(exam=>!resData.failed_exams.includes(exam.id));
                    }

                    examsToSetIgpStatus.forEach(async (exam) => {
                        try {
                            const igpStatusResponse = await dispatch(examApi.endpoints.examSetInternalGpStatus.initiate({
                                examId: exam.id,
                                gpStatus: 'letter_sent',
                            })).unwrap();
                            if (igpStatusResponse['error']) {
                                const error = 'Failed to set exam' + exam.id + 'to GP letter sent.';
                                throw new Error(error);
                            }
                        } catch(error) {
                            const message = (error instanceof Error) ?  error?.message : error;

                            Modal.error({
                                className: 'info-modal',
                                title: ERROR_MESSAGE_TITLE,
                                content: message as string,
                            });
                        };
                    })
                }
            },
        });
    }
)

export const getGPConsultLetter = createAsyncThunk(
    'letter/getGPConsultLetter',
    async (doctorName: string, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken, isRetinaEnabled }, examData: {id, is_referral_letter_upload_pei},  } = getState() as { user: {csrfToken: string, isRetinaEnabled: boolean}, examData: {id: number, is_referral_letter_upload_pei: boolean}};
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        // Standard RLU exams also generate an RE consult letter.
        const letterType = isRetinaEnabled || is_referral_letter_upload_pei ? 'omdrgpenabled' :'omdrgp';

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/exam/consult_letter/gp/`;

        const formData = new FormData();
        formData.append('letter_type', letterType);
        formData.append('exam_id', id.toString());
        formData.append('doctor_name', doctorName);
        try {
            const response = await httpFileRequest('POST', URL, csrfToken, formData);
            return response;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

export const letterSlice = createSlice({
    name: 'letter',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setLetterDataValue: (state, action) => {
            // sample action.payload {key:'memberId', value: '12345'}
            if(action.payload){
                return {...state, [action.payload.key]: action.payload.value}
            }
            return {...state};
        },
        resetLetter: () => initialState,
        toggleLetterModal: (state) => {
            state.letterModalOpen = !state.letterModalOpen;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getLetterRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getLetterRequest.fulfilled, (state, action) => {
            state.status = 'success';
            const data = action.payload;
            if( data && data.filename && data.docx) {
                downloadBinaryFile(data.docx, data.filename);
            }
        });
        builder.addCase(getLetterRequest.rejected, (state, action) => {
            state.status = 'failed';

            // handle the rejected case, the value was passed from rejecteWithValue
            Modal.error({
                className: 'info-modal',
                title: `Errors getting letter. ${action.payload}`,
            })
        });
        builder.addCase(getRluOmdLetterRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getRluOmdLetterRequest.fulfilled, (state, action) => {
            state.status = 'success';
            const data = action.payload;
            if( data && data.filename && data.docx) {
                downloadBinaryFile(data.docx, data.filename);
            }
        });
        builder.addCase(getRluOmdLetterRequest.rejected, (state, action) => {
            state.status = 'failed';

            // handle the rejected case, the value was passed from rejecteWithValue
            Modal.error({
                className: 'info-modal',
                title: `Errors getting letter. ${action.payload}`,
            })
        });
        builder.addCase(sendFaxRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(sendFaxRequest.fulfilled, (state) => {
            state.status = 'success';
            Modal.info({
                className: 'info-modal',
                title: `Fax successfully sent.`,
            });
            
        });
        builder.addCase(sendFaxRequest.rejected, (state, action) => {
            state.status = 'failed';
        });
        builder.addCase(getOmdListRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getOmdListRequest.fulfilled, (state, action) => {
            state.status = 'success';
            state.omdList = action.payload['omd_list'];
        });
        builder.addCase(getOmdListRequest.rejected, (state, action) => {
            state.status = 'failed';
        });
        builder.addCase(getOmdContactRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getOmdContactRequest.fulfilled, (state, action) => {
            state.status = 'success';
            state.email = action.payload.data['email'];
            state.fax = action.payload.data['fax'];
        });
        builder.addCase(getOmdContactRequest.rejected, (state, action) => {
            state.status = 'failed';
        });
        builder.addCase(getAdminLetterIpcInfoRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getAdminLetterIpcInfoRequest.fulfilled, (state, action) => {
            state.status = 'success';
            const {name, od_grouppractice_fax, od_grouppractice_phone, od_name } = action.payload.data;
            const bodyMessage = 
            `Patient Name: ${name}\n` + 
            `Referring Optometrist: Dr. ${od_name}\n` + 
            `Referring Clinic Phone: ${od_grouppractice_phone}\n` +
            `Referring Clinic Fax: ${od_grouppractice_fax}\n` + 
            `Reason for Referral:\n\n\n` + 
            `Please see the attached referral`;
            state.message = bodyMessage;
        });
        builder.addCase(getAdminLetterIpcInfoRequest.rejected, (state, action) => {
            state.status = 'failed';
        });
        builder.addCase(setExamIpcOmdcRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(setExamIpcOmdcRequest.fulfilled, (state, action) => {
            state.status = 'success';
        });
        builder.addCase(setExamIpcOmdcRequest.rejected, (state, action) => {
            state.status = 'failed';
        });
        builder.addCase(getIpcOmdcDataRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getIpcOmdcDataRequest.fulfilled, (state, action) => {
            state.status = 'success';
            state.ipcOmdc = action.payload.data['ipc_omdc'];
            const date = action.payload.data['ipc_date']
            state.ipcDate = date ? dayjs(date) : '';
        });
        builder.addCase(getIpcOmdcDataRequest.rejected, (state, action) => {
            state.status = 'failed';
        });
        builder.addCase(getGPConsultLetter.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getGPConsultLetter.fulfilled, (state, action) => {
            state.status = 'success';
            const data = action.payload;
            if( data && data.filename && data.docx) {
                downloadBinaryFile(data.docx, data.filename);
            }
        });
        builder.addCase(getGPConsultLetter.rejected, (state, action) => {
            state.status = 'failed';

            // handle the rejected case, the value was passed from rejecteWithValue
            Modal.error({
                className: 'info-modal',
                title: `Errors getting letter. ${action.payload}`,
            })
        });
        builder.addCase(sendEmailOrFaxRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(sendEmailOrFaxRequest.fulfilled, (state, action) => {
            state.status = 'success';
        });
        builder.addCase(sendEmailOrFaxRequest.rejected, (state, action) => {
            state.status = 'failed';

            // handle the rejected case, the value was passed from rejecteWithValue
            Modal.error({
                className: 'info-modal',
                title: `Errors getting letter. ${action.payload}`,
            })
        });
        builder.addCase(sendRluOmdEmailOrFaxRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(sendRluOmdEmailOrFaxRequest.fulfilled, (state, action) => {
            state.status = 'success';
        });
        builder.addCase(sendRluOmdEmailOrFaxRequest.rejected, (state, action) => {
            state.status = 'failed';

            // handle the rejected case, the value was passed from rejecteWithValue
            Modal.error({
                className: 'info-modal',
                title: `Errors getting letter. ${action.payload}`,
            })
        });

        builder.addCase(getPatientHandoutPdfRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getPatientHandoutPdfRequest.fulfilled, (state, action) => {
            state.status = 'success';
            const data = action.payload;
            if( data && data.filename && data.docx) {
                downloadBinaryFile(data.docx, data.filename);
            }
        });
        builder.addCase(getPatientHandoutPdfRequest.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});

export const { resetLetter, setLetterDataValue, toggleLetterModal } = letterSlice.actions;

export default letterSlice.reducer;