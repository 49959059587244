import { Col, Flex, Row } from "antd";
import PatientExamNotesToODCarryOver from "../reviewer/patient-exam-notes-to-od-carry-over";
import AdminPrivateNotes from "./admin-private-notes";
import PatientExamOMDReviewerSelectors from "../reviewer/patient-exam-omd-reviewer-selectors";
import PatientExamOMDRNotes from "../reviewer/patient-exam-omdr-notes";
import PatientExamNotesToODAndGP from "../reviewer/patient-exam-notes-to-od-and-gp";
import PatientExamCpt from "../reviewer/us/patient-exam-cpt";
import PatientExamIcd10 from "../reviewer/us/patient-exam-icd-10";
import PatientExamFollowUpWithOMD from "../reviewer/patient-exam-fu-with-omd";
import PatientExamSecondOpinionSelectors from "../reviewer/patient-exam-second-opinion-selectors";
import PatientExamNotesToMOA from "../reviewer/patient-exam-notes-to-moa";
import { useCare1AppSelector } from "../../apps/care1-hooks";
import PatientExamFUOptions from "../consultant/patient-exam-fu-options";
import PatientExamOMDCNotes from "../consultant/patient-exam-omdc-notes";
import styles from '../../../static/css/components/admin-pei-notes-row.module.scss'
import commonLayoutStyles from '../../../static/css/components/common-layout.module.scss';

const AdminPeiNotesRow = ({disabled}: {disabled: boolean}) => {
    const examIsUsOnly = useCare1AppSelector(store => store.examData.exam_is_us_only);

    return (
        <Row gutter={4} className={`${styles.adminPeiNotesRow} ${examIsUsOnly ? 'is-us-only' : ''}`}>
            <Col span={5}>
                <Flex vertical gap='small' className={commonLayoutStyles.verticalFlex}>
                    <Col flex={5}>
                        <PatientExamNotesToODCarryOver disabled={false} />
                    </Col>
                    <Col flex={2}>
                        <AdminPrivateNotes />
                    </Col>
                </Flex>
            </Col>
            <Col span={12}>
                    <Flex vertical gap='small' className={commonLayoutStyles.verticalFlex}>
                        <Col span={24} flex={'0 1 86px'}>
                            <PatientExamOMDReviewerSelectors />
                        </Col>
                        <Col span={24} flex={'1 1 100%'}>
                            <Flex className={commonLayoutStyles.verticalFlex}>
                                <Col span={12}>
                                    <PatientExamOMDRNotes disabled={false} />
                                </Col>
                                <Col span={12}>
                                    <PatientExamNotesToODAndGP disabled={false} />
                                </Col>
                            </Flex>
                        </Col>
                    </Flex>
                {examIsUsOnly &&
                    <Row gutter={4}>
                        <Col span={12}>
                            <PatientExamCpt />
                        </Col>
                        <Col span={12}>
                            <PatientExamIcd10 />
                        </Col>
                    </Row>
                }
            </Col>
            <Col span={7}>
                <Row gutter={[8, 8]}>
                    <Col span={10}>
                        <PatientExamFollowUpWithOMD />
                    </Col>
                    <Col span={14}>
                        <PatientExamSecondOpinionSelectors disabled={false} />
                    </Col>
                    <Col span={10}>
                        <PatientExamNotesToMOA disabled={false} />
                    </Col>
                    <Col span={14}>
                        <PatientExamFUOptions disabled={false} />
                    </Col>
                    <Col span={24} style={{height: '107px'}}>
                        <PatientExamOMDCNotes disabled={false} />
                    </Col>
                </Row>
            </Col>
                    
        </Row>
    );
};

export default AdminPeiNotesRow;