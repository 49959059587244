// the chat components codes mainly from 
// https://github.com/fatihbaltaci/chatgpt-clone

import { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Modal, Spin } from 'antd';
import { IMessageEvent, w3cwebsocket } from 'websocket';
import '../../../../static/css/components/admin-gpt-image-identifier-chat.scss';
import { v4 as uuidv4 } from 'uuid';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { fetchGptImageIdentifierChatPrompt } from '../../../reducers/gpt-image-identifier-slice';
import { getFilepathFromBackendMediaUrl } from '../../../helpers/media-image-convert';
import GptUI from '../gpt/gpt-ui';
import { GptResultType } from './gpt-image-identifier-itemtypes';


interface Message {
    input: string;
    output: string;
    meta: string;
}

type ComponentProps = {
    rightFundus: string,
    leftFundus: string,
    rightStereoFundus: string,
    leftStereoFundus: string,
    rightVfPhoto: string,
    leftVfPhoto: string,
    rightOctRnflPhoto: string,
    leftOctRnflPhoto: string,
    rightOCTMacula: string,
    leftOCTMacula: string,
    onChatResult: (image_urls: string[], output: GptResultType) => void,
    onReset: () => void,
}

const GptImageIdentifierChat = ({ rightFundus, leftFundus, rightStereoFundus, leftStereoFundus,
    rightVfPhoto, leftVfPhoto, rightOctRnflPhoto, leftOctRnflPhoto,
    rightOCTMacula, leftOCTMacula, 
    onChatResult, onReset }: ComponentProps) => {
    const dispatch = useCare1AppDispatch();

    const webSocketRef = useRef<w3cwebsocket | null>(null);
    const reconnectIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [messageHistory, setMessageHistory] = useState<Message[]>([]);
    const [inputMessage, setInputMessage] = useState<string>("");
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [isAssistantTyping, setIsAssistantTyping] = useState<boolean>(false);

    const examId = useCare1AppSelector(store => store.examData.id);

    const handleSocketOnMessage = (text: string) => {
        let outputStr = '';
        let base64ImageList = [];
        let metaStr = '';
        try {
            const obj = JSON.parse(text);
            outputStr = obj['message']['output'];
            base64ImageList = obj['message']['base64_image_list'];
            metaStr = `${obj['message']['meta']}`;

            setMessageHistory(h => {
                const m = [...h];
                if (m.length > 0) {
                    m[m.length - 1].output = outputStr;
                    m[m.length - 1].meta = metaStr;
                    return m;
                }
                else {
                    return [{ input: inputMessage, output: outputStr, meta: metaStr }];
                }
            });

            setIsAssistantTyping(false);
        }
        catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting GPT Vision output',
            });
        };

        if (outputStr !== '' && base64ImageList !== null && base64ImageList !== undefined && base64ImageList !== '') {
            try {
                // Use regex to extract the JSON object part
                const jsonPattern = /{[^]*}/;
                const jsonMatches = outputStr.match(jsonPattern);

                if (jsonMatches) {
                    const jsonResponse = jsonMatches[0];
                    const outputObj = JSON.parse(jsonResponse);
                    
                    if (Array.isArray(base64ImageList)) {
                        onChatResult([...base64ImageList], outputObj);
                    }

                } else {
                    console.log("No JSON object found in the text.");
                    throw new Error("No JSON object found in the text.");
                }
            }
            catch (error) {
                const message = (error instanceof Error) ? error?.message : error;
                Modal.error({
                    className: 'info-modal',
                    content: message as string,
                    title: 'Errors parsing GPT Vision output',
                });
            };
        }
    }

    const connectWebSocket = () => {

        if (webSocketRef.current) {
            console.log(`${webSocketRef.current.readyState} : ${WebSocket.OPEN}`);
        }

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
            console.log('WebSocket already connected');
            return;
        }

        const uuid = uuidv4();
        console.log(uuid);

        const socket = new w3cwebsocket(`${process.env.REACT_APP_WEBSOCKETURL}/gpt/vision/image_identifier/${uuid}`);

        socket.onopen = (): void => {
            console.log('WebSocket connected');
            // Clear the reconnect interval if the connection is successfully established
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };

        socket.onmessage = (message: IMessageEvent): void => {
            console.log('WebSocket: onmessage');
            handleSocketOnMessage(message.data.toString());
        };

        socket.onclose = () => {
            console.log('WebSocket closed');
            // Set up a reconnect interval to attempt reconnection
            setReconnectInterval();
        };

        webSocketRef.current = socket;
    };

    const setReconnectInterval = (): void => {
        // Clear any existing reconnect interval
        clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        // Set a new reconnect interval (e.g., 5 seconds)
        reconnectIntervalRef.current = setInterval(connectWebSocket, 5000);
    };

    useEffect(() => {
        connectWebSocket();

        // Function to update the window height on window resize
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        // Add event listener to window resize
        window.addEventListener("resize", handleResize);

        // Get deidentified Referral Letter
        handleReset();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);

            // Close the WebSocket connection when the component unmounts
            if (webSocketRef.current) {
                webSocketRef.current.close();
            }
            // Clear the reconnect interval when the component unmounts
            clearInterval(reconnectIntervalRef.current as NodeJS.Timeout);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messageHistory]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    const formatMessageContent = (content: string): string => {
        return content;
    }

    const sendMessage = () => {

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {

            const image_urls: string[] = [];
            if (rightFundus !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(rightFundus));
            }
            if (leftFundus !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(leftFundus));
            }
            if (rightStereoFundus !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(rightStereoFundus));
            }
            if (leftStereoFundus !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(leftStereoFundus));
            }
            if (rightVfPhoto !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(rightVfPhoto));
            }
            if (leftVfPhoto !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(leftVfPhoto));
            }
            if (rightOctRnflPhoto !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(rightOctRnflPhoto));
            }
            if (leftOctRnflPhoto !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(leftOctRnflPhoto));
            }
            if (rightOCTMacula !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(rightOCTMacula));
            }
            if (leftOCTMacula !== '') {
                image_urls.push(getFilepathFromBackendMediaUrl(leftOCTMacula));
            }
            
            console.log('WebSocket: sendMessage');
            webSocketRef.current.send(JSON.stringify({
                'message': {
                    'exam_id': examId, 
                    'input': inputMessage,
                    'image_urls': image_urls,
                    'history': []
                }
            }));
            setMessageHistory(() => {
                return [{ input: inputMessage, output: '', meta: '' }];
            });
            setIsAssistantTyping(true);
        }
    }

    const handleReset = async () => {

        onReset();

        setMessageHistory([]);

        if (examId === null) {
            return
        }

        try {
            const result = await dispatch(fetchGptImageIdentifierChatPrompt()).unwrap();
            if (result && result.success) {
                setInputMessage(result.gpt_image_identifier_chat_prompts);
            }
            else {
                throw new Error(result?.error);
            }

        } catch (error) {
            const message = (error instanceof Error) ? error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: message as string,
                title: 'Errors getting GPT Image Identifier prompts',
            });
        }

    }


    return (
        <Spin
            className='loading-spinner'
            size='large'
            spinning={false}
        >
            <div className="chat-container gpt-image-identifier-chat">
                <Row>
                    <Col span={24}>
                        <Row>
                            <Col
                                span={24}>
                                <GptUI
                                    messageHistory={messageHistory}
                                    inputMessage={inputMessage}
                                    setInputMessage={setInputMessage}
                                    sendMessage={sendMessage}
                                    formatMessageContent={formatMessageContent}
                                    isAssistantTyping={isAssistantTyping}
                                    messagesEndRef={messagesEndRef}
                                />
                                <Row>
                                    <Col span={3}>
                                        <Button
                                            className={''}
                                            onClick={sendMessage}
                                            type="primary"
                                            size="large"
                                        >
                                            Process Images
                                        </Button>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={2}>
                                        <Button
                                            className={''}
                                            onClick={handleReset}
                                            type="primary"
                                            size="large"
                                        >
                                            Reset
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Spin>)
}

export default GptImageIdentifierChat;
