import { useEffect, useCallback, useRef } from 'react';
// Libraries
import { useCare1AppSelector } from '../../apps/care1-hooks';
import { Spin } from 'antd';

// Components
import GlaucomaProgramPatientListControlBar from './glaucoma-program-patient-list-control-bar';
import GlaucomaProgramPatientListTable from './glaucoma-program-patient-list-table';

import LatencyMetrics, { LatencyMetricsHandle } from '../../latency-metrics';

// Helpers
import '../../../static/css/pages/_patient-list-page.scss';
import { OPERATING_TEXT } from '../../constants';
import { useGetGlaucomaProgramPatientListQuery } from '../../services/glaucoma-program-api';
import { IGlaucomaProgramFilters } from '../../reducers/glaucoma-program-slice';

const GlaucomaProgramContents = () => {

    const latencyMetricsRef = useRef<LatencyMetricsHandle>(null);
    if (latencyMetricsRef.current) {
        latencyMetricsRef.current.processLog(performance.timeOrigin + performance.now(), "logic", "GlaucomaProgramContents::trigger");
    }

    const odFilter = useCare1AppSelector(store => store.glaucomaProgram.odFilter);
    const nextVisitFilter = useCare1AppSelector(store => store.glaucomaProgram.nextVisitFilter);
    const bookingStatusFilter = useCare1AppSelector(store => store.glaucomaProgram.bookingStatusFilter);
    const vfFilter = useCare1AppSelector(store => store.glaucomaProgram.vfFilter);
    const iopCompareFilter = useCare1AppSelector(store => store.glaucomaProgram.iopCompareFilter);
    const iopCompareNumberFilter = useCare1AppSelector(store => store.glaucomaProgram.iopCompareNumberFilter);
    const cdCompareFilter = useCare1AppSelector(store => store.glaucomaProgram.cdCompareFilter);
    const cdCompareNumberFilter = useCare1AppSelector(store => store.glaucomaProgram.cdCompareNumberFilter);

    let filterData: IGlaucomaProgramFilters = {};

    if (odFilter) {
        filterData.od = odFilter;
    }

    if (nextVisitFilter) {
        filterData.next_visit = nextVisitFilter;
    }

    if (bookingStatusFilter) {
        filterData.booking_status = bookingStatusFilter;
    }

    if (vfFilter) {
        filterData.vf = vfFilter;
    }

    if (iopCompareFilter && iopCompareNumberFilter) {
        filterData.iop_compare = iopCompareFilter;
        filterData.iop_number = iopCompareNumberFilter;
    }

    if (cdCompareFilter && cdCompareNumberFilter) {
        filterData.cd_compare = cdCompareFilter;
        filterData.cd_number = cdCompareNumberFilter;
    }

    const {data, isFetching} = useGetGlaucomaProgramPatientListQuery(filterData);

    useEffect(() => {
        if (latencyMetricsRef.current) {
            latencyMetricsRef.current.startTimerCheck(!isFetching);
        }
    }, [isFetching])

    const getLatenctMetricsName = () => {
        return 'od_glc_program_list_load';
    }

    return (
        <div>
            <LatencyMetrics key={getLatenctMetricsName()} 
                ref={latencyMetricsRef} 
                examId={''} 
                metricsName={getLatenctMetricsName()} 
                timerMs={2000} 
                consloeLog={true} 
            />
            <Spin
                className="loading-spinner"
                spinning={ isFetching }
                size="large"
                tip={OPERATING_TEXT}
            >
                <div className={`glaucoma-program-patient-list-page`}>
                    <GlaucomaProgramPatientListControlBar />
                    <GlaucomaProgramPatientListTable tableList={data!} />
                </div>
            </Spin>
        </div>
    );
}

export default GlaucomaProgramContents;
